import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { LogModule } from '../log/log.module';
import { ImportComponent } from './containers/import/import.component';
import { OldImportFormComponent } from './components/import-form-old/old-import-form.component';
import { ImportFormEj2Component } from './components/import-form-ej2/import-form-ej2.component';
import { ImportFormKendoComponent } from './components/import-form-kendo/import-form-kendo.component';
import { ImportFormComponent } from './components/import-form/import-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MyCommonModule, UiModule, LogModule],
  declarations: [
    ImportComponent,
    OldImportFormComponent,
    ImportFormEj2Component,
    ImportFormKendoComponent,
    ImportFormComponent,
  ],
  exports: [ImportComponent],
  entryComponents: [ImportComponent],
})
export class ImportModule {}
