import { Pipe, PipeTransform } from '@angular/core';
import { luhnIsValid } from '../../../classes/utils/utils';

@Pipe({
  name: 'luhn',
})
export class LuhnPipe implements PipeTransform {
  transform(value: string | number): boolean {
    return luhnIsValid(value);
  }
}
