/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/old-reports-form/old-reports-form.component.ngfactory";
import * as i3 from "../../components/old-reports-form/old-reports-form.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/common.service";
import * as i6 from "../../../../shared/modules/my-common/services/config.service";
import * as i7 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i8 from "../../../../shared/sdk/services/custom/MyUtils";
import * as i9 from "../../../../shared/modules/ui/services/push-notifications.service";
import * as i10 from "../../components/reports-form/reports-form.component.ngfactory";
import * as i11 from "../../components/reports-form/reports-form.component";
import * as i12 from "@angular/common";
import * as i13 from "./reports.component";
import * as i14 from "@angular/router";
var styles_ReportsComponent = [i0.styles];
var RenderType_ReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
function View_ReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-old-reports-form", [], null, null, null, i2.View_OldReportsFormComponent_0, i2.RenderType_OldReportsFormComponent)), i1.ɵdid(2, 4440064, null, 0, i3.OldReportsFormComponent, [i4.LoggerService, i5.CommonService, i6.ConfigService, i7.PusherService, i8.MyUtilsApi, i9.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ReportsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reports-form", [], null, null, null, i10.View_ReportsFormComponent_0, i10.RenderType_ReportsFormComponent)), i1.ɵdid(2, 4440064, null, 0, i11.ReportsFormComponent, [i4.LoggerService, i5.CommonService, i6.ConfigService, i7.PusherService, i9.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i12.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_1)), i1.ɵdid(3, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_2)), i1.ɵdid(5, 16384, null, 0, i12.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mode; _ck(_v, 1, 0, currVal_0); var currVal_1 = "OLD"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i1.ɵdid(1, 114688, null, 0, i13.ReportsComponent, [i14.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsComponentNgFactory = i1.ɵccf("app-reports", i13.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
