import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import {
  Document,
  DocumentType,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  Person,
  Signature,
  SignatureApi,
  Vehicle,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import moment from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { BASE_COLUMNS } from '../../classes/enums';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss'],
})
export class VehicleGridComponent extends ABaseComponent implements OnInit {
  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = new Date();
  showType: 'Mileage' | 'Transports' | 'Facility' | 'Driver' | 'Facility-Driver' = 'Mileage';
  showUtilizationDS = [
    { value: 'utilized', title: 'All Utilized Vehicles For Period' },
    { value: 'un-utilized', title: 'All Un-Utilized Active Vehicles For Period' },
  ];
  showUtilization: 'utilized' | 'un-utilized' = 'utilized';

  vehiclesMoved = true;
  vehiclesTransportedClients = true;

  dso: DataSourceOptions;
  gridColumns: any[] = [...BASE_COLUMNS];

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Vehicle[]> = new EventEmitter<Vehicle[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
    const self = this;

    this.buildDso();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  filter() {
    this.grid.instance.refresh();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  getColor(data, col) {
    if (!col) return '';
    const day = moment(col.caption, 'MM/DD/YYYY').format('YYYYMMDD');
    if (!data[day]) return '';
    const dist = data[day].distance || 0;
    const signs = data[day].signaturesCount || 0;
    if (dist > 0 && signs > 0) return 'green';
    if (signs > 0) return 'red';
    if (dist > 30) return 'orange';
    if (dist > 0) return 'yellow';
  }

  getTitle(data, col) {
    if (!col) return '';
    const day = moment(col.caption, 'MM/DD/YYYY').format('YYYYMMDD');
    if (!data[day]) return '';
    return `${col.caption}
#${data.internalId}
Status: ${data[day].status || ''}
Facility:  ${data[day].facility}
Driver:  ${data[day].driver}
Miles: ${data[day].mileage || 0}
Transports: ${data[day].transports || 0}`;
  }

  async buildDso() {
    const so = this.dss.getStoreOptions(Vehicle, Vehicle, false);
    so.customHeaders = headersAllTenantsAppend;
    const store = new CustomStore(so);

    dxStoreLoadHooks(store, undefined, async (args: any[], [vehs]: any[]) => {
      const drvFilter = { where: { employeePositionId: { inq: [39, 40] } }, include: ['person'] };
      const [facilities, drivers, last, days, lastNotes] = await Promise.all([
        this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise(),
        this.dss.getApi<EmployeeApi>(Employee).find<Employee>(drvFilter, headersAllTenantsAppend).toPromise(),
        gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getLastGeoTabAggregate()).pipe().toPromise(),
        gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getVehicleGeotabDaysAggregate()).pipe().toPromise(),
        (this.showUtilization == 'un-utilized' &&
          gqlMongoLoad(this.dss, 'AnyNote', {}, this.getLastAnyNoteAggregate()).pipe().toPromise()) ||
          [],
      ]);

      const empls = await this.dss
        .getApi<EmployeeApi>(Employee)
        .find<Employee>({ where: { userId: { inq: lastNotes.map(n => n.createdById) } }, include: ['person'] })
        .toPromise();
      const emplMap = empls.reduce((p, v) => ({ ...p, [v.userId]: new FullNamePipe(this.config).transform(v) }), {});

      const facilityMap = facilities.reduce((p, v) => ({ ...p, [v.id]: v.shortname }), {});
      const driversMap = drivers.reduce(
        (p, v: Employee) => ({ ...p, [v.id]: new FullNamePipe(this.config).transform(v) }),
        {},
      );

      const lastMap = last.reduce(
        (p, v) => ({ ...p, [v.internalId]: { lastDate: moment(v.lastDate, 'YYYYMMDD') } }),
        {},
      );
      const daysMap = this.makeDaysMap(days, facilityMap, driversMap);
      const lastNotesMap = lastNotes.reduce(
        (p, v) => ({ ...p, [v.vehicleId]: { ...v, createdBy: emplMap[v.createdById] } }),
        {},
      );
      const res = this.makeResultCollection(vehs, daysMap, lastMap, lastNotesMap);
      this.buildColumns();
      return [res];
    });

    this.dso = { store } as DataSourceOptions;
  }

  buildColumns() {
    this.gridColumns = [...BASE_COLUMNS];
    if (this.showUtilization == 'utilized') {
      for (let i = 0; i <= moment(this.selectedToValue).diff(moment(this.selectedFromValue), 'days'); i++) {
        const day = moment(this.selectedFromValue).clone().add(i, 'days');
        this.gridColumns.push({
          dataField: `${day.format('YYYYMMDD')}.${this.showType.toLowerCase().replace('-', '_')}`,
          caption: day.format('MM/DD/YYYY ddd'),
          cellTemplate: 'day_cell',
        });
      }
    } else {
      this.gridColumns.push(
        { dataField: `lastNote.subject`, caption: 'Last Note' },
        { dataField: `lastNoteDate`, caption: 'Last Note Date', dataType: 'date' },
        { dataField: `lastNote.createdBy`, caption: 'Last Note Created By' },
      );
    }
  }

  makeResultCollection(vehs, daysMap, mapLast, lastNotesMap) {
    return vehs
      .filter(
        v =>
          (this.showUtilization == 'utilized' &&
            (!this.vehiclesMoved || (daysMap[v.internalId] && daysMap[v.internalId].activeGeoDays)) &&
            (!this.vehiclesTransportedClients || (daysMap[v.internalId] && daysMap[v.internalId].activeSignDays))) ||
          (this.showUtilization == 'un-utilized' &&
            v.state == 'ACTIVE' &&
            (!this.vehiclesMoved || !daysMap[v.internalId] || !daysMap[v.internalId].activeGeoDays) &&
            (!this.vehiclesTransportedClients || !daysMap[v.internalId] || !daysMap[v.internalId].activeSignDays)),
      )
      .map(v => ({
        ...v,
        ...mapLast[v.internalId],
        ...daysMap[v.internalId],
        lastNote: lastNotesMap[v.id],
        lastNoteDate: lastNotesMap[v.id] && (lastNotesMap[v.id].dateOfEvent || lastNotesMap[v.id].updatedAt),
        totalDistance: (daysMap[v.internalId] && daysMap[v.internalId].totalDistance) || undefined,
        totalSignatures: (daysMap[v.internalId] && daysMap[v.internalId].totalSigns) || undefined,
        activeGeoDays: (daysMap[v.internalId] && daysMap[v.internalId].activeGeoDays) || undefined,
        activeSignDays: (daysMap[v.internalId] && daysMap[v.internalId].activeSignDays) || undefined,
      }));
  }

  makeDaysMap(days, facilityMap, driversMap) {
    return days.reduce((pm, month) => {
      const [totalDistance, activeGeoDays, totalSigns, activeSignDays, preparedDays] = month.days.reduce(
        ([pDist, pActDays, pSigns, pSignActDays, pDays], day) => {
          const dist = (day.distance && +day.distance / 1.60934) || 0;
          const signs = day.signaturesCount || 0;
          const mileage = (dist > 1 && Math.round(dist)) || '';
          const transports = day.signaturesCount || '';
          const facility = facilityMap[day.tenantId] || '';
          const driver = driversMap[day.employeeId] || '';
          const facility_driver = `${facilityMap[day.tenantId] || ''}-${driversMap[day.employeeId] || ''}`;
          const preparedDay = { ...day, mileage, transports, facility, driver, facility_driver };
          return [
            pDist + dist,
            pActDays + ((dist > 1 && 1) || 0),
            pSigns + signs,
            pSignActDays + ((signs > 1 && 1) || 0),
            ((dist > 1 || signs > 1) && { ...pDays, [day.day]: preparedDay }) || pDays,
          ];
        },
        [
          (pm[month.internalId] && pm[month.internalId].totalDistance) || 0,
          (pm[month.internalId] && pm[month.internalId].activeGeoDays) || 0,
          (pm[month.internalId] && pm[month.internalId].totalSigns) || 0,
          (pm[month.internalId] && pm[month.internalId].activeSignDays) || 0,
          {},
        ],
      );
      return {
        ...pm,
        [month.internalId]: {
          ...pm[month.internalId],
          totalDistance: Math.round(totalDistance),
          totalSigns,
          activeGeoDays,
          activeSignDays,
          ...preparedDays,
        },
      };
    }, {});
  }

  getVehicleGeotabDaysAggregate() {
    const mFormat = v => +moment(v).format('YYYYMM');
    const dFormat = v => +moment(v).format('YYYYMMDD');
    return [
      {
        $match: {
          month: { $gte: mFormat(this.selectedFromValue), $lte: mFormat(this.selectedToValue) },
          days: { $type: 'array' },
        },
      },
      {
        $project: {
          _id: 0,
          internalId: 1,
          month: 1,
          days: {
            $filter: {
              input: '$days',
              as: 'item',
              cond: {
                $and: [
                  { $gte: ['$$item.day', dFormat(this.selectedFromValue)] },
                  { $lte: ['$$item.day', dFormat(this.selectedToValue)] },
                ],
              },
            },
          },
        },
      },
      {
        $project: {
          _id: 0,
          internalId: 1,
          month: 1,
          'days.tenantId': 1,
          'days.employeeId': 1,
          'days.signaturesCount': 1,
          'days.status': 1,
          'days.distance': 1,
          'days.day': 1,
        },
      },
    ];
  }

  getLastGeoTabAggregate() {
    return [
      { $match: { month: { $gte: +moment().subtract(30, 'days').format('YYYYMM') } } },
      { $unwind: '$days' },
      { $project: { internalId: 1, distance: '$days.distance', day: '$days.day' } },
      { $match: { distance: { $gte: 1 } } },
      { $sort: { day: 1 } },
      { $group: { _id: '$internalId', last: { $last: '$$ROOT' } } },
      {
        $project: { internalId: '$last.internalId', lastDate: '$last.day' },
      },
    ];
  }

  getLastAnyNoteAggregate() {
    return [
      { $sort: { vehicleId: 1, dateOfEvent: 1, updatedAt: 1 } },
      { $group: { _id: '$vehicleId', last: { $last: '$$ROOT' } } },
      {
        $project: {
          _id: 0,
          vehicleId: '$last.vehicleId',
          dateOfEvent: '$last.dateOfEvent',
          subject: '$last.subject',
          description: '$last.description',
          updatedAt: '$last.updatedAt',
          createdById: '$last.createdById',
        },
      },
    ];
  }
}
