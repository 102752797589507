<div mat-dialog-title style="display: flex">
  <span>
    Creating account for
    <app-object-title [modelName]="'Employee'" [modelId]="employee.id"></app-object-title>
  </span>

  <span style="flex: auto"></span>

  <button
    mat-icon-button
    type="button"
    [mat-dialog-close]="false"
    matTooltip="Close"
    color="primary"
    style="margin: -1em">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <dx-form #form [formData]="account" [showValidationSummary]="true">

    <dxi-item dataField="username">
      <dxi-validation-rule
        type="required"
        message="Username is required"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="async"
        message="Username is already registered"
        [validationCallback]="asyncUsernameValidation"
      ></dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="email">
      <dxi-validation-rule
        type="required"
        message="Email is required"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="email"
        message="Email is invalid"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="async"
        message="Email is already registered"
        [validationCallback]="asyncEmailValidation"
      ></dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="password">
      <dxi-validation-rule
        type="required"
        message="Password is required"
      ></dxi-validation-rule>

      <dxi-validation-rule
        type="stringLength"
        [min]="6"
        [max]="10"
        message="Password must have from 6 to 10 symbols"
      ></dxi-validation-rule>

    </dxi-item>

  </dx-form>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="createUser()">Submit</button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>
