import { Component, EventEmitter, OnInit, Output } from '@angular/core';
//
import { Vehicle } from '../../../../shared/sdk';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent implements OnInit {
  @Output() mySelected: EventEmitter<Vehicle[]> = new EventEmitter<Vehicle[]>();

  constructor() {}

  ngOnInit() {}

  grid_onSelected(items: Vehicle[]) {
    this.mySelected.emit(items);
  }
}
