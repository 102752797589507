import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import identity from 'lodash-es/identity';
import moment from 'moment/moment';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService } from '../../../../shared/sdk';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-mtm-not-assigned-trips',
  templateUrl: './mtm-not-assigned-trips.component.html',
  styleUrls: ['./mtm-not-assigned-trips.component.scss'],
})
export class MtmNotAssignedTripsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  dsCollection: string;

  selectedDate: Date = moment().subtract(1, 'days').toDate();
  selectedFilter: 'All Unassigned' | 'Safe Cancel' = 'Safe Cancel';
  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private pusher: PusherService,
    private dialog: MatDialog,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '8c68c052-db6c-4300-8228-19aa0786319d',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.buildDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  ngAfterViewInit(): void {}

  grid_onToolbarPreparing(e) {
    (e.toolbarOptions.items as any[]).unshift(
      {
        name: 'selectDate',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        location: 'before',
        sortIndex: 0,
        showText: 'inMenu',
        options: {
          firstDayOfWeek: 1,
          value: this.selectedDate,
          displayFormat: 'shortDate',
          pickerType: 'calendar',
          type: 'date',
          useMaskBehavior: true,
          onValueChanged: this.dateBox_onValueChanged.bind(this),
        },
      },
      {
        name: 'filter',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        location: 'before',
        sortIndex: 0,
        showText: 'always',
        options: {
          dataSource: ['All Unassigned', 'Safe Cancel'],
          value: this.selectedFilter,
          onValueChanged: this.filter__onSelectionChanged.bind(this),
        },
      },
      {
        name: 'filterBtn',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'before',
        sortIndex: 0,
        showText: 'always',
        options: {
          icon: 'fas fa-filter',
          text: 'Filter',
          hint: 'Filter',
          onClick: this.filter_onClick.bind(this),
        },
      },
      // {
      //   name: 'turnbackBatch',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     icon: 'fas fa-cancel',
      //     text: 'Turnback Selected',
      //     hint: 'Turnback Selected TripIDs',
      //     onClick: this.turnbackTrips_onClick.bind(this),
      //   },
      // },
      {
        name: 'cancelBatch',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-cancel',
          text: 'Cancel Selected',
          hint: 'Cancel Selected TripIDs',
          onClick: this.cancelTrips_onClick.bind(this),
        },
      },
    );
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (oc(e).data._cancelled(false)) {
        (e.cellElement as HTMLElement).classList.add('cell-gray');
        (e.cellElement as HTMLElement).style.textDecoration = 'line-through';
      }

      if (oc(e).data.__mciAssigned(false)) {
        (e.cellElement as HTMLElement).classList.add('cell-warning');
      } else if (oc(e).data.__mciUsed(false)) {
        (e.cellElement as HTMLElement).classList.add('cell-yellow');
      }
    }
  }

  dateBox_onValueChanged(e) {
    this.selectedDate = e.value;
  }

  filter__onSelectionChanged(e: any) {
    this.selectedFilter = e.value;
  }

  filter_onClick(e) {
    this.$filterEvent$.next(true);
  }

  turnbackTrips_onClick(e) {
    const title = 'Confirm turnback';
    const msg = 'Are you sure want to turnback selected trips?';

    from(confirm(msg, title))
      .pipe(
        filter(identity),
        tap(() => this.ui.showLoading()),
        switchMap(async () => await this.grid.instance.getSelectedRowsData()),
        switchMap((selectedItems: any[]) =>
          this.pusher.rpc(
            'TURNBACK_MTM_TRIPS',
            {
              tripIds: selectedItems.filter(t => !t._cancelled).map(t => t._tripId),
              useRunService: true,
            },
            true,
            hAll,
          ),
        ),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        tap(() => this.$filterEvent$.next(true)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  cancelTrips_onClick(e) {
    const title = 'Confirm cancel';
    const msg = 'Are you sure want to cancel selected trips?';

    from(confirm(msg, title))
      .pipe(
        filter(identity),
        tap(() => this.ui.showLoading()),
        switchMap(async () => await this.grid.instance.getSelectedRowsData()),
        switchMap((selectedItems: any[]) =>
          this.pusher.rpc(
            'CANCEL_MTM_TRIPS',
            {
              tripIds: selectedItems.filter(t => !t._cancelled).map(t => t._tripId),
              useRunService: true,
            },
            true,
            hAll,
          ),
        ),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        tap(() => this.$filterEvent$.next(true)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        switchMap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Building Dataset...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.dso = [];
        }),
        switchMap(() =>
          this.pusher
            .rpc(
              'GET_NOT_ASSIGNED_MTM_TRIPS',
              {
                yyyymmdd: Number(moment(this.selectedDate).format('YYYYMMDD')),
                safe: this.selectedFilter === 'Safe Cancel',
                useRunService: true,
              },
              true,
              hAll,
            )
            .pipe(
              switchMap(collection => {
                this.dsCollection = collection;

                const mongoSchema: any = {
                  _date: 'datetime',
                  "Member's Date of Birth": 'datetime',
                  'Appointment Date': 'datetime',
                  _ctime: 'datetime',
                  _rtime: 'datetime',
                };

                return of({
                  store: this.dss.createMongoStore(collection, mongoSchema),
                } as DataSourceOptions);
              }),
              tap(ds => (this.dso = ds)),
            ),
        ),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(async () => {
          // await this.grid.instance.deselectAll();
          // this.grid.instance.clearSelection();
          // this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
