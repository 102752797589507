import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { FacilityEditComponent } from './components/facility-edit/facility-edit.component';
import { FacilityFormComponent } from './components/facility-form/facility-form.component';
import { FacilityGridComponent } from './components/facility-grid/facility-grid.component';
import { FacilityListComponent } from './components/facility-list/facility-list.component';
//
import { FacilityComponent } from './containers/facility/facility.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MyCommonModule, UiModule],
  declarations: [
    FacilityComponent,
    FacilityListComponent,
    FacilityGridComponent,
    FacilityFormComponent,
    FacilityEditComponent,
  ],
  exports: [FacilityComponent, FacilityEditComponent],
  entryComponents: [FacilityComponent, FacilityFormComponent],
})
export class FacilityModule {}
