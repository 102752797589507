import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import Guid from 'devextreme/core/guid';
import UploadInfo from 'devextreme/file_management/upload_info';
import DxFileUploader from 'devextreme/ui/file_uploader';
import DxValidator from 'devextreme/ui/validator';
import fromPairs from 'lodash-es/fromPairs';
import head from 'lodash-es/head';
import isEmpty from 'lodash-es/isEmpty';
import noop from 'lodash-es/noop';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
//
import { Container, ContainerApi, LoggerService, LoopBackAuth, MyFile, MyUserApi, MyUtilsApi } from '../../../sdk';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from '../../my-common/services/common.service';
import { ConfigService } from '../../my-common/services/config.service';

export interface UploadableFile extends File {
  uploaded: MyFile;
  url: string;
  gcFile: string;
  gcBucket: string;
  error: string;
}

export interface UploadHelperHandleOptions {
  // container?: string;
  // useImport?: boolean;
  folder?: string;
  extra?: any;

  onValueChanged?: (e: {
    component: DxFileUploader;
    element: Element;
    value: File[];
    previousValue: File[];
    event: Event;
  }) => void;

  onUploadStarted?: (e: {
    component: DxFileUploader;
    element: Element;
    file: File;
    event: Event;
    request: XMLHttpRequest;
  }) => void;

  onUploadAborted?: (e: {
    component: DxFileUploader;
    element: Element;
    file: File;
    event: Event;
    request: XMLHttpRequest;
  }) => void;

  onUploadError?: (e: {
    component: DxFileUploader;
    element: Element;
    file: File;
    event: Event;
    request: XMLHttpRequest;
  }) => void;

  onUploaded?: (e: {
    component: DxFileUploader;
    element: Element;
    file: File;
    event: Event;
    request: XMLHttpRequest;
  }) => void;
}

@Injectable()
export class UploadHelperService {
  static SIGNED_URL_PLACEHOLDER = '##SIGNED_URL_PLACEHOLDER##';

  constructor(
    @Inject(LoggerService) protected logger: LoggerService,
    @Inject(CommonService) protected common: CommonService,
    @Inject(ConfigService) protected config: ConfigService,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
    @Inject(MyUserApi) protected userApi: MyUserApi,
    @Inject(ContainerApi) public api: ContainerApi,
    @Inject(MyUtilsApi) public utilsApi: MyUtilsApi,
  ) {
    DxFileUploader.defaultOptions({ options: this.defaultOptions });
  }

  get defaultHeaders() {
    return {
      'ngsw-bypass': 'true',
      // 'X-Access-Token': this.auth.getAccessTokenId() + '',
      // 'X-Current-Tenant': this.auth.getCurrentTenant() + '',
    };
  }

  get defaultOptions(): any {
    return {
      // uploadUrl: UploadHelperService.SIGNED_URL_PLACEHOLDER,
      uploadMethod: 'PUT',
      // ...this.buildUploadOptions(),
      uploadHeaders: this.defaultHeaders,
      multiple: false,
      uploadMode: 'instantly',
      // uploadMode: 'useButtons',
      accept: '*',
    };
  }

  validationCallback = params => {
    // console.log('validationCallback: ', params);
    const files: UploadableFile[] = params.value || [];
    const rule: any = params.rule || {};
    const validator: DxValidator = params.validator;

    const errors = files.filter(f => !!f.error).map(f => f.error);
    // if (files.length === 0)
    //   errors.unshift('There are no files uploaded');

    if (errors.length) {
      rule.message = head(errors);
      rule.isValid = false;
    } else {
      rule.isValid = true;
    }

    return rule.isValid;
  };

  handle(instance: DxFileUploader, options: UploadHelperHandleOptions = {}, validator?: DxValidator): void {
    const defaultOptions: UploadHelperHandleOptions = { extra: {} };
    options = { ...defaultOptions, ...options };

    instance.option(options.extra);

    // if (options.useImport) {
    //   instance.option(this.buildImportOptions());
    // } else if (options.container) {
    //   instance.option(this.buildUploadOptions(options.container));
    // }

    instance.option('chunkSize', 256 * 1024); // 256KB
    instance.option('uploadChunk', (file: UploadableFile, uploadInfo: UploadInfo) =>
      this.uploadChunk(options.folder, file, uploadInfo),
    );

    const events = {
      disposing: (e: { component: DxFileUploader; element: any }) => {
        Object.keys(events).forEach(eventName => e.component.off(eventName));
      },

      optionChanged: (e: { component: DxFileUploader; [key: string]: any }) => {
        if (e.name === 'uploadHeaders' && !Object.keys(this.defaultHeaders).every(k => k in e.value)) {
          //          console.log(e);
          e.component.option({ uploadHeaders: { ...this.defaultHeaders, ...e.value } });
        }
      },
      valueChanged: e => {
        // console.log('valueChanged: ', e);
        const uploader: DxFileUploader = e.component;
        const files: UploadableFile[] = e.value;
        const prevFiles: UploadableFile[] = e.previousValue;

        // files.filter(f => isEmpty(f.uploaded)).forEach(f => (f.error = `File [${f.name}] not uploaded!`));
        //
        // // uploader.option('disabled', true);
        // void this.filesCleanUp$(files, prevFiles)
        //   .pipe(
        //     tap(() => {
        //       this.logger.log('files clean up done');
        //       // uploader.option('disabled', false);
        //     }),
        //   )
        //   .toPromise();

        return (options.onValueChanged || noop)(e);
      },
      uploadStarted: e => {
        // console.log('uploadStarted: ', e);
        const uploader: DxFileUploader = e.component;
        const file: UploadableFile = e.file;
        const request: XMLHttpRequest = e.request;

        file.error = `File [${file.name}] is uploading!`;

        validator && validator.validate();

        return (options.onUploadStarted || noop)(e);
      },
      uploadAborted: e => {
        const file: UploadableFile = e.file;
        file.error = `File [${file.name}] upload aborted!`;

        validator && validator.validate();

        return (options.onUploadAborted || noop)(e);
      },
      uploadError: e => {
        const file: UploadableFile = e.file;
        const resp = this.parseResponse(oc(e).request.response());
        const err = oc(resp).error.message() || oc(resp).error('Upload error!');
        file.error = err;

        validator && validator.validate();

        return (options.onUploadError || noop)(e);
      },
      uploaded: e => {
        // console.log('uploaded: ', e);

        this.handleUploaded(e, validator);
        return (options.onUploaded || noop)(e);
      },
    };

    instance.on(events);
  }

  handleUploaded(e, validator?: DxValidator): any {
    const uploader: DxFileUploader = e.component;
    const file: UploadableFile = e.file;

    // const resp = this.parseResponse(oc(e).request.response());
    // const myFiles: MyFile[] = this.dxUploadResponseToMyFileModel(resp, uploader.option('name'));
    // const myFile: MyFile = head(myFiles.filter(f => f.originalFilename === file.name));

    const myFile: MyFile = new MyFile({
      originalFilename: file.name,
      container: file.gcBucket,
      filename: file.gcFile,
    });

    file.uploaded = myFile;
    file.error = undefined;
    validator && validator.validate();
    return myFile;
  }

  // buildUploadOptions(container: string = null): { uploadUrl: string } {
  //   const uploadUrl: string = this.getUploadUrl(container);
  //   return { uploadUrl };
  // }

  // buildImportOptions(): { uploadUrl: string } {
  //   const uploadUrl: string = this.getImportUrl();
  //   return { uploadUrl };
  // }

  // getDownloadUrl(container: string, filename: string): string {
  //   return `${this.getApiPath()}/gs/${container}/${filename}`;
  // }

  /**
   * https://cloud.google.com/storage/docs/performing-resumable-uploads#xml-api
   */
  private async uploadChunk(folder: string, file: UploadableFile, uploadInfo: UploadInfo) {
    // console.log('uploadChunk:', file, uploadInfo);

    let promise: Promise<any>;

    if (uploadInfo.chunkIndex === 0) {
      promise = this.api
        .getUploadUrl(folder, new Guid().valueOf() + '/' + file.name, undefined, true)
        .toPromise()
        .then(({ url, bucket: gcBucket, file: gcFile }) => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'ngsw-bypass': 'true', // bypass service worker
              'content-type': file.type,
              'x-goog-resumable': 'start',
            },
          }).then(({ headers }) => {
            file.gcBucket = gcBucket;
            file.gcFile = gcFile;
            uploadInfo.customData.signedUrl = url;
            uploadInfo.customData.resumableUrl = headers.get('location');
          });
        });
    } else {
      promise = Promise.resolve();
    }

    promise = promise.then(() => {
      const chunkSize = uploadInfo.chunkBlob.size;
      const chunkFirstByte = uploadInfo.bytesUploaded;
      const chunkLastByte = chunkFirstByte + chunkSize - 1;

      return fetch(uploadInfo.customData.resumableUrl, {
        method: 'PUT',
        headers: {
          'ngsw-bypass': 'true', // bypass service worker
          'Content-Length': '' + chunkSize,
          'Content-Range': `bytes ${chunkFirstByte}-${chunkLastByte}/${file.size}`,
        },
        body: uploadInfo.chunkBlob,
      });
    });

    return promise;
  }

  private parseResponse(strResponse: string): any {
    try {
      return JSON.parse(strResponse);
    } catch (err) {
      return strResponse || {};
    }
  }

  private dxUploadResponseToMyFileModel(response: any, dxUploaderName: string): MyFile[] {
    const fileInfos = response.result.files[dxUploaderName] || [];

    return fileInfos.map(
      fi =>
        new MyFile({
          originalFilename: fi.originalFilename,
          container: fi.container,
          filename: fi.name,
        }),
    );
  }

  // private getApiPath(): string {
  //   return this.common.buildUrlPath(Container);
  // }

  // private getUploadUrl(container: string = null): string {
  //   const prefix = oc(this.config).vars.CONTAINER_PREFIX('_');
  //   container = container ? container : prefix + this.auth.getCurrentUserId();
  //   return `${this.getApiPath()}/${container}/upload`;
  // }

  // private getImportUrl(): string {
  //   return `${this.getApiPath()}/import`;
  // }

  private getFilesForRemove(files: UploadableFile[], prevFiles: UploadableFile[]): UploadableFile[] {
    return (
      prevFiles
        // .filter(pf => !!pf.uploaded)
        .filter(pf => !isEmpty(pf.uploaded))
        .filter(pf => !files.find(f => f.name === pf.name))
    );
  }

  private filesCleanUp$(files: UploadableFile[], prevFiles: UploadableFile[]): Observable<any> {
    return of(this.getFilesForRemove(files, prevFiles)).pipe(
      mergeMap(ufs => Promise.all(ufs.map(uf => this.removeFile$(uf).toPromise()))),
    );
  }

  private removeFile$(file: UploadableFile): Observable<void> {
    // if (!!file.uploaded) {

    if (!isEmpty(file.uploaded)) {
      return this.api.removeFile(file.uploaded.container, file.uploaded.filename).pipe(tap(() => delete file.uploaded));
    } else {
      return of();
    }
  }

  replaceUploadWithSignedRequest(req: HttpRequest<FormData>) {
    const fileInfo = (req.body as FormData).get('files') as File;
    // console.log(fileInfo);

    let extensionHeaders = fromPairs(
      req.headers
        .keys()
        .filter(k => k.startsWith('x-goog-'))
        .map(k => [k, req.headers.get(k)]),
    );

    // const contentType = !isEmpty(fileInfo.type) ? fileInfo.type : 'application/octet-stream';

    extensionHeaders = {
      ...extensionHeaders,
      // 'x-goog-meta-filename': file.name,
      // 'content-type': contentType,
    };

    return this.api
      .getGcpSignedUrl(
        fileInfo.name,
        'write',
        // contentType,
        JSON.stringify(extensionHeaders),
      )
      .pipe(
        // switchMap(async (signedUrl: string) => {
        //   const reader = new FileReader();
        //
        //   const binaryString = await new Promise<string>((resolve, reject) => {
        //     reader.onload = (e) => resolve(reader.result as string);
        //     reader.onerror = (e) => reject(reader.error);
        //     reader.readAsBinaryString(file);
        //   });
        //
        //   return [signedUrl, binaryString];
        // }),

        // switchMap(async ({url: signedUrl, uploadId, file: f, extensionHeaders: extHeaders}) => {
        //   return await fetch(signedUrl, {
        //     method: 'PUT',
        //     headers: {
        //       ...extHeaders,
        //     },
        //     body: fileInfo,
        //   })
        //     .then((resp) => resp.text())
        //     .then((data) => new HttpResponse({status: 200, body: data}));
        // }),

        map(({ url: signedUrl, file: f, uploadId, extensionHeaders: extHeaders }) => {
          // console.log(signedUrl, f, extHeaders);
          const headers = new HttpHeaders(extHeaders);
          // headers = headers.set('x-goog-meta-foo', 'bar');
          return new HttpRequest('PUT', signedUrl, fileInfo, {
            headers,
            withCredentials: false,
            reportProgress: true,
            responseType: 'text',
          });
        }),
      );
  }
}
