import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { LoggerService } from '../../../../shared/sdk';
import { LoopBackFilter, Person } from '../../../../shared/sdk/models';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { PERSON_SEXES } from '../../../employee/classes/enums';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
})
export class PersonFormComponent extends ABaseFormComponent<Person> {
  personSexesDS = PERSON_SEXES;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Person>,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);
  }

  get addressesFormArray(): FormArray {
    return this.form.get('contact.addresses') as FormArray;
  }

  get phonesFormArray(): FormArray {
    return this.form.get('contact.phones') as FormArray;
  }

  get emailsFormArray(): FormArray {
    return this.form.get('contact.emails') as FormArray;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [{ contact: ['addresses', 'phones', 'emails'] }],
    };
  }

  protected get ModelClass(): any {
    return Person;
  }

  protected get dateFields(): string[] {
    return ['dob'];
  }

  protected buildForm(): void {
    this.formConfigMap.set('contact.addresses', {
      id: [],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [],
      contactId: [],
    });

    this.formConfigMap.set('contact.phones', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('contact.emails', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('contact', {
      id: [],
      notes: [],
      addresses: this.fb.array([]),
      phones: this.fb.array([]),
      emails: this.fb.array([]),
    });

    this.formConfigMap.set('data', {
      er: [],
      title: [],
      nativeLang: [],
      nativeLangName: [],
    });

    this.formConfigMap.set('', {
      id: [],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [],
      nickname: [],
      sex: [],
      dob: [],
      notes: [],
      contactId: [],
      data: this.fb.group(this.formConfigMap.get('data')),
      contact: this.fb.group(this.formConfigMap.get('contact')),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
