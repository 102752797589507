import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import { safeJsonParse } from '../../../../shared/classes/utils/string.utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService, MobileDevice, MyUtils, MyUtilsApi } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-push-to-mobile',
  templateUrl: './dlg-push-to-mobile.component.html',
  styleUrls: ['./dlg-push-to-mobile.component.scss'],
})
export class DlgPushToMobileComponent extends ABaseComponent implements OnInit {
  payload = '{\n"notification": {\n"title":"hello",\n"body":"world"\n}\n}';

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgPushToMobileComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: { mobile: MobileDevice },
    private dss: DataSourceService,
  ) {
    super(logger);
  }

  push() {
    void this.dss
      .getApi<MyUtilsApi>(MyUtils)
      .pushToMobile(this.data.mobile.deviceUid, JSON.parse(this.payload))
      .toPromise()
      .then(() => this.dialogRef.close(true))
      .catch(err => notify(err.message, 'error', 5000));
  }
}
