
declare var Object: any;
export interface EmployeeDocExpCntViewInterface {
  "firstname"?: string;
  "lastname"?: string;
  "dob"?: string;
  "employeePosition"?: string;
  "status"?: string;
  "tenantId"?: number;
  "minDocExp"?: string;
  "docsCount"?: number;
  "id"?: number;
}

export class EmployeeDocExpCntView implements EmployeeDocExpCntViewInterface {
  "firstname": string;
  "lastname": string;
  "dob": string;
  "employeePosition": string;
  "status": string;
  "tenantId": number;
  "minDocExp": string;
  "docsCount": number;
  "id": number;
  constructor(data?: EmployeeDocExpCntViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeeDocExpCntView`.
   */
  public static getModelName() {
    return "EmployeeDocExpCntView";
  }

  /**
  * This method creates an instance of EmployeeDocExpCntView for dynamic purposes.
  **/
  public static factory(data: EmployeeDocExpCntViewInterface): EmployeeDocExpCntView{
    return new EmployeeDocExpCntView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeeDocExpCntView',
      plural: 'EmployeeDocExpCntViews',
      path: 'EmployeeDocExpCntViews',
      idName: 'id',
      properties: {
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "dob": {
          name: 'dob',
          type: 'string'
        },
        "employeePosition": {
          name: 'employeePosition',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "minDocExp": {
          name: 'minDocExp',
          type: 'string'
        },
        "docsCount": {
          name: 'docsCount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
