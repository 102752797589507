<dx-drop-down-box
  #dropDownBox
  [width]="width"
  [placeholder]="placeholder"
  [showClearButton]="showClearButton"
  [(opened)]="opened"
  [(disabled)]="disabled"
  [(value)]="dropDownBox_value"
  (valueChange)="dropDownBox_valueChange($event)"
  (onValueChanged)="dropDownBox_onValueChanged($event)"
  [dataSource]="dataSource"
  [valueExpr]="valueExpr"
  [displayExpr]="displayExpr"
  (onFocusOut)="dropDownBox_onFocusOut($event)">
  <dxo-drop-down-options [width]="popup_width" [height]="popup_height"></dxo-drop-down-options>

  <!-- /// -->

  <ng-container *dxTemplate="let data of 'content'">
    <dx-data-grid
      #dataGrid
      height="100%"
      [columnChooser]="{ enabled: false }"
      [searchPanel]="{ visible: false }"
      [filterRow]="{ visible: true }"
      [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"

      [pager]="{ visible: false }"
      [scrolling]="{ mode: 'infinite' }"
      [dataSource]="dataSource"
      [remoteOperations]="remoteOperations"

      [hoverStateEnabled]="true"
      [showBorders]="true"
      [selection]="grid_selection"

      [columns]="grid_columns"
      [(selectedRowKeys)]="grid_selectedRowKeys"
      (onSelectionChanged)="grid_onSelectionChanged($event)"
      (onCellPrepared)="grid_cellPrepared.emit($event)"
    ></dx-data-grid>

    <!--      [paging]="{ enabled: true }"-->
  </ng-container>

  <!-- /// -->

  <ng-content></ng-content>
</dx-drop-down-box>
