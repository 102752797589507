<div mat-dialog-title style="display: flex">
  <span>
    <app-object-title [modelName]="'Facility'" [modelId]="data.sv?.tenantId"></app-object-title>
    | mci: {{data.sv?.consumer_mci}}
    | <app-object-title [modelName]="'Consumer'" [modelId]="data.sv?.consumerId"></app-object-title>
  </span>

  <span style="flex: auto"></span>
</div>

<div mat-dialog-content>
  <dx-scroll-view height="480" [useNative]="true">
    <h2>Select Target Facility</h2>
    <dx-data-grid
      #grid1
      width="100%"
      height="300"
      [dataSource]="ds1"
      [columns]="gridColumns1"
      [columnChooser]="{ enabled: false }"
      [searchPanel]="{ visible: false }"
      [hoverStateEnabled]="true"
      [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
      [pager]="{ visible: false }"
      [selection]="gridSelection1"
      [paging]="gridPaging1"
      [filterRow]="gridFilterRow1"
      [scrolling]="gridScrolling1"
      [(selectedRowKeys)]="selectedTenantIds"
      (onSelectionChanged)="grid1_onSelectionChanged($event)"
    ></dx-data-grid>

    <br>

    <h2>Select Target Client</h2>
    <dx-data-grid
      #grid2
      width="100%"
      height="400"
      [dataSource]="ds2$ |async"
      [columns]="gridColumns2"
      [columnChooser]="{ enabled: false }"
      [searchPanel]="{ visible: false }"
      [hoverStateEnabled]="true"
      [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
      [pager]="{ visible: false }"
      [selection]="gridSelection2"
      [paging]="gridPaging2"
      [filterRow]="gridFilterRow2"
      [scrolling]="gridScrolling2"
      [(selectedRowKeys)]="selectedConsumerIds"
      (onSelectionChanged)="grid2_onSelectionChanged($event)"
    ></dx-data-grid>
  </dx-scroll-view>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="[selectedTenant, selectedConsumer]"
          [disabled]="!selectedTenant || !selectedConsumer" cdkFocusInitial
  >Select
  </button>
</div>
