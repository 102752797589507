import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { select } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import fromPairs from 'lodash-es/fromPairs';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import isString from 'lodash-es/isString';
import isUndefined from 'lodash-es/isUndefined';
import last from 'lodash-es/last';
import unset from 'lodash-es/unset';
import moment, { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { differenceDeep, getPathsByKey } from '../../../../shared/classes/utils/object.utils';
import {
  Address,
  Consumer,
  ConsumerEmPerson,
  ConsumerView,
  EmailAddress,
  Facility,
  Note,
  Phone,
} from '../../../../shared/sdk/models';
import { LoggerService, MyUserApi, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { WeekDaysPipe } from '../../../../shared/modules/ui/pipes/week-days.pipe';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { getUser } from '../../../../store/reducers/sign';
import { CONSUMER_STATUSES } from '../../../consumer/classes/enums';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-consumer-change',
  templateUrl: './consumer-change.component.html',
  styleUrls: ['./consumer-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumerChangeComponent extends ABaseComponent implements OnInit {
  isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  statuses = CONSUMER_STATUSES;
  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);
  clientDso$: Observable<DataSourceOptions> = of([]);
  usersDso$: Observable<ArrayStore>;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
    private gridHelper: GridHelperService,
    private dss: DataSourceService,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '1eb3a1c3-83fc-4c69-af90-56422e078ab7',
    };

    this.isSU$ = this.config.isSU$;
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
    this.clientDso$ = this.buildClientDataSource();

    this.usersDso$ = this.userApi.getUsernames().pipe(map(arr => new ArrayStore(arr)));
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  lastAttendanceNoteExpr = e => {
    const lastNote: Note = last(oc(e)._entity.relatedNotes([]));

    const info = lastNote
      ? [
          [
            lastNote.infoBy ? `by ${lastNote.infoBy}` : '',
            lastNote.infoDate ? `on ${utc(lastNote.infoDate).format('YYYY/MM/DD')}` : '',
          ]
            .filter(chnk => !isEmpty(chnk))
            .join(' '),
          lastNote.followUpDate ? `Follow up on ${utc(lastNote.followUpDate).format('YYYY/MM/DD')}` : '',
        ]
          .filter(chnk => !isEmpty(chnk))
          .join(', ')
      : '';

    return lastNote
      ? [
          moment(lastNote.dateTime).format('YYYY/MM/DD HH:mm'),
          lastNote.author,
          lastNote.text + (!isEmpty(info) ? ` (${info})` : ''),
        ].join(' : ')
      : '';
  };

  private buildDataSource() {
    const self = this;
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'EntityActionLog_Consumer';
        const aggregate = [
          {
            $addFields: {
              _entity: { $cond: [{ $eq: ['$action', 'UPDATE'] }, '$entity.after', '$entity'] },
              _changed_status: {
                $cond: [
                  {
                    $and: [{ $eq: ['$action', 'UPDATE'] }, { $ne: ['$entity.before.status', '$entity.after.status'] }],
                  },
                  true,
                  false,
                ],
              },
              _changed_mci: {
                $cond: [
                  {
                    $and: [{ $eq: ['$action', 'UPDATE'] }, { $ne: ['$entity.before.mci', '$entity.after.mci'] }],
                  },
                  true,
                  false,
                ],
              },
            },
          },
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).pipe().toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'EntityActionLog_Consumer';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'EntityActionLog_Consumer';
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // filter: ['state', '=', 'ACTIVE'],
      sort: [{ selector: 'ctime', desc: true }],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      // filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'ctime', desc: true }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildClientDataSource() {
    const so = this.dss.getStoreOptions(Consumer, ConsumerView, false);
    (so as LoopBackStoreOptions).customHeaders = headersAllTenantsAppend;
    const store: CustomStore = new CustomStore(so);
    const dso: DataSourceOptions = { store };
    return of(dso);
  }

  getDiff(data) {
    const before =
      data.action === 'UPDATE'
        ? data.entity.before
        : data.action === 'CREATE'
          ? undefined
          : data.action === 'DELETE'
            ? data.entity
            : undefined;

    const after =
      data.action === 'UPDATE'
        ? data.entity.after
        : data.action === 'CREATE'
          ? data.entity
          : data.action === 'DELETE'
            ? undefined
            : undefined;

    [before, after]
      .filter(e => !!e)
      .forEach(e => {
        const _paths = [
          ...getPathsByKey(e, 'ctime', { recursive: true }),
          ...getPathsByKey(e, 'utime', { recursive: true }),
          // ...getPathsByKey(e, 'hash', {recursive: true}),
        ];

        _paths.forEach(p => {
          unset(e, p);
        });
      });

    const diff = differenceDeep<any>(after || {}, before || {}, true);

    data._diff = diff;

    const results = [];

    Object.entries<any>(this.filterPaths(diff)).forEach(([p, o]) => {
      const b = o.expr ? o.expr(get(before, p)) : get(before, p);
      const a = o.expr ? o.expr(get(after, p)) : get(after, p);
      results.push({
        title: o.t || o,
        before: isString(b) ? (!isEmpty(b) ? b : '-') : JSON.stringify(isUndefined(b) ? null : b),
        after: isString(a) ? (!isEmpty(a) ? a : '-') : JSON.stringify(isUndefined(a) ? null : a),
      });
    });

    // const paths = reverse(getPathsByKey(diff, null, {recursive: true}));
    // paths.forEach((p) => {
    //   const val = get(diff, p);
    // });

    return results;
  }

  getData(data) {
    const results = [];

    Object.entries<any>(this.filterPaths(data.entity)).forEach(([p, o]) => {
      const e = o.expr ? o.expr(get(data.entity, p)) : get(data.entity, p);
      results.push({
        title: o.t || o,
        value: isString(e) ? (!isEmpty(e) ? e : '-') : JSON.stringify(isUndefined(e) ? null : e),
      });
    });

    return results;
  }

  private filterPaths(source) {
    let filtered: any = fromPairs(
      Object.entries({
        'person.firstname': 'First Name',
        'person.lastname': 'Last Name',
        'person.middlename': 'Middle Name',
        'person.nickname': 'Nickname',
        'person.dob': 'DOB',
        'person.sex': 'Sex',
        'person.notes': 'Client Notes',
        'person.data.nativeLang': 'Native Language',
        'person.data.nativeLangName': 'Native Language Name',

        status: 'Status',
        mci: 'MCI',
        clientSince: 'Client Since',
        internalID: 'Internal ID',
        repetitiveMode: 'Client Type',
        weekDays: { t: 'Week Days', expr: wd => new WeekDaysPipe().transform(wd) },
        defaultDestination: 'Default Facility',
        transpCodes: 'Transportation Codes',
        transpInstrs: 'Transportation Instructions',
        // notes: 'Attendance Note',
        'data.unableToSign': 'Client Unable To Sign',
        'data.icd10': 'ICD-10',
        facilityID: 'Facility ID',

        'program.name': 'Program',

        // 'specialInstrs',
        // 'daysNotes',
        // 'brokerNotes',
        // 'activeMco',
        // 'activeBroker',

        c10nAgency: 'Coordination Agency',
        cwName: 'Case Worker Name',
        cwPhone: 'Case Worker Phone',
        cwFax: 'Case Worker Fax',
        cwEmail: 'Case Worker Email',
        cwNote: 'Case Worker Note',
      }).filter(([p]) => get(source, p)),
    );

    // 'person.contact.addresses.0',
    // 'person.contact.phones.0',
    // 'person.contact.emails.0',
    // 'emRelations.0.relation',
    // 'emRelations.0.person',

    // console.log(get(source, 'person.contact.addresses', []));

    filtered = {
      ...filtered,
      ...this.filterContactPaths(get(source, 'person.contact', {}), 'person.contact.'),
      ...this.filterEmContacts(get(source, 'emRelations', {}), 'emRelations.'),
      ...this.filterNotes(get(source, 'relatedNotes', {}), 'relatedNotes.'),
    };

    // console.log(filtered);

    return filtered;
  }

  private filterContactPaths(source, pathPrefix, titlePrefix = '') {
    const filtered: any = {};

    {
      const entities = get(source, 'addresses', {});
      const addresses: { [idx: string]: Address } = isArray(entities)
        ? (entities as any[]).reduce((o, elm, idx) => ({ ...o, ['' + idx]: elm }), {})
        : entities || {};
      if (!isEmpty(addresses)) {
        Object.entries(addresses).forEach(([idx, a]) => {
          filtered[pathPrefix + 'addresses.' + idx] = {
            t: titlePrefix + 'Address' + (Object.entries(addresses).length === 1 ? '' : ' ' + (Number(idx) + 1)),
            expr: (_a: Address) => (_a ? [_a.street, _a.city, _a.state, _a.zip].join(', ') : undefined),
          };
        });
      }
    }

    {
      const entities = get(source, 'phones', {});
      const phones: { [idx: string]: Phone } = isArray(entities)
        ? (entities as any[]).reduce((o, elm, idx) => ({ ...o, ['' + idx]: elm }), {})
        : entities || {};
      if (!isEmpty(phones)) {
        Object.entries(phones).forEach(([idx, p]) => {
          filtered[pathPrefix + 'phones.' + idx] = {
            t: titlePrefix + 'Phone' + (Object.entries(phones).length === 1 ? '' : ' ' + (Number(idx) + 1)),
            expr: (_p: Phone) => (_p ? [_p.label, _p.value].filter(v => !isNil(v)).join(': ') : undefined),
          };
        });
      }
    }

    {
      const entities = get(source, 'emails', {});
      const emails: { [idx: string]: EmailAddress } = isArray(entities)
        ? (entities as any[]).reduce((o, elm, idx) => ({ ...o, ['' + idx]: elm }), {})
        : Object.values(entities || {});
      if (!isEmpty(emails)) {
        Object.entries(emails).forEach(([idx, e]) => {
          filtered[pathPrefix + 'emails.' + idx] = {
            t: titlePrefix + 'Email' + (Object.entries(emails).length === 1 ? '' : ' ' + (Number(idx) + 1)),
            expr: (_e: EmailAddress) => (_e ? [_e.label, _e.value].filter(v => !isNil(v)).join(': ') : undefined),
          };
        });
      }
    }

    return filtered;
  }

  private filterEmContacts(source, prefix) {
    const filtered: any = {};

    const people: { [idx: string]: ConsumerEmPerson } = isArray(source)
      ? (source as any[]).reduce((o, elm, idx) => ({ ...o, ['' + idx]: elm }), {})
      : source || {};
    if (!isEmpty(people)) {
      Object.entries(people).forEach(([idx, em]) => {
        const titlePrefix =
          'Related Contact' + (Object.entries(people).length === 1 ? ': ' : ' ' + (Number(idx) + 1) + ': ');

        let _filtered: any = fromPairs(
          Object.entries({
            relation: titlePrefix + 'Relation',

            'person.data.title': titlePrefix + 'Title',
            'person.firstname': titlePrefix + 'First Name',
            'person.middlename': titlePrefix + 'Middle Name',
            'person.lastname': titlePrefix + 'Last Name',
            'person.nickname': titlePrefix + 'Nickname',
            'person.dob': titlePrefix + 'DOB',
            'person.sex': titlePrefix + 'Sex',
            'person.data.er': titlePrefix + 'Emergency Contact',
            'person.data.nativeLang': titlePrefix + 'Native Language',
            'person.data.nativeLangName': titlePrefix + 'Native Language Name',

            'data.unableToSign': titlePrefix + 'Client Unable To Sign',
            'data.icd10': titlePrefix + 'Native Language',
          }).filter(([p]) => get(em, p)),
        );

        _filtered = {
          ..._filtered,
          ...this.filterContactPaths(get(em, 'person.contact', {}), 'person.contact.', titlePrefix),
        };

        Object.entries(_filtered).forEach(([p, o]) => {
          filtered[prefix + idx + '.' + p] = o;
        });
      });
    }

    return filtered;
  }

  private filterNotes(source, prefix) {
    const filtered: any = {};

    const notes: { [idx: string]: Note } = isArray(source)
      ? (source as any[]).reduce((o, elm, idx) => ({ ...o, ['' + idx]: elm }), {})
      : source || {};
    if (!isEmpty(notes)) {
      Object.entries(notes).forEach(([idx, n]) => {
        const titlePrefix =
          'Attendance Note' + (Object.entries(notes).length === 1 ? ': ' : ' ' + (Number(idx) + 1) + ': ');

        const _filtered: any = fromPairs(
          Object.entries({
            dateTime: { t: titlePrefix + 'Time', expr: t => (t ? moment(t).format('YYYY/MM/DD HH:mm') : undefined) },
            author: titlePrefix + 'Author',
            text: titlePrefix + 'Text',
          }).filter(([p]) => get(n, p)),
        );

        Object.entries(_filtered).forEach(([p, o]) => {
          filtered[prefix + idx + '.' + p] = o;
        });
      });
    }

    return filtered;
  }
}
