import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { Consumer, LoggerService, Vehicle } from '../../../../shared/sdk';
import { HelperService as VehicleHelperService } from '../../../vehicle/services/helper.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dlg-select-vehicle',
  templateUrl: './dlg-select-vehicle.component.html',
  styleUrls: ['./dlg-select-vehicle.component.scss'],
  providers: [HelperService, VehicleHelperService],
})
export class DlgSelectVehicleComponent implements OnInit {
  @Input() ds: DataSourceOptions | any[] = [];
  @Input() gridColumns: any[] = [
    { dataField: 'internalId', caption: 'Internal ID' },
    { dataField: 'make', caption: 'Make' },
    { dataField: 'model', caption: 'Model' },
    { dataField: 'state', caption: 'State' },
  ];

  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgSelectVehicleComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { filter; recIds },
    protected logger: LoggerService,
    private dss: DataSourceService,
    public helper: HelperService,
    public vehicleHelper: VehicleHelperService,
  ) {}

  ngOnInit() {
    this.buildDataSource();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;
    so.customFilter = {
      where: oc(this.data).filter({ state: 'ACTIVE' }),
      order: ['internalId ASC'],
    };

    this.ds = {
      store: new CustomStore(so),
    } as DataSourceOptions;
  }

  grid_onSelectionChanged(e) {}
}
