import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, startWith, switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { ConsumerApi, SignatureApi } from '../../../sdk';
import { headersAllTenantsAppend } from '../../../classes/utils/utils';
import isEmpty from 'lodash-es/isEmpty';
import { HttpHeaders } from '@angular/common/http';

@Pipe({
  name: 'signatureLink',
})
export class SignatureLinkPipe implements PipeTransform {
  constructor(private consumerApi: ConsumerApi, private signatureApi: SignatureApi) {}

  transform(id: number | string, type: 'CONS' | 'SIGN' | 'FILE', convert?: string): Observable<string> {
    function customHeaders(headers: HttpHeaders) {
      return headersAllTenantsAppend(headers).append('ngsw-bypass', '1');
    }

    const c$ = this.consumerApi.getSignMediaLink(id, convert, customHeaders);
    const s$ = this.signatureApi.getSignLink(id, convert, customHeaders);
    const i$ = this.signatureApi.getLink(id, convert, customHeaders);

    const oMap = {
      CONS: c$,
      SIGN: s$,
      FILE: i$,
    };

    return of(id).pipe(
      filter(_id => !isEmpty(_id)),
      distinctUntilChanged(),
      switchMap(_id =>
        oMap[type].pipe(
          // map(url => url + '&ngsw-bypass'),
          catchError(err => of('/assets/images/error-img.png')),
          startWith('/assets/images/loader.gif'),
        ),
      ),
      startWith(''),
    );
  }
}
