<div mat-dialog-content>
  <mat-form-field style="width: 100%">
    <input
      #inputElement
      matInput
      type="text"
      placeholder="Unlock Note"
      [(ngModel)]="value"
      cdkFocusInitial />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value" [disabled]="!locked">Unlock</button>
</div>
