import { ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { isEmpty } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';

export abstract class ABaseConsistencyCheckComponent extends ABaseComponent {
  dsoColl: string;
  dso: DataSource | undefined = new DataSource([]);
  grid_stateStoring: any;
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  protected mongoSchema: any = { 'service date': 'datetime' };
  protected checkIdx: number;

  protected constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected pusher: PusherService,
    protected common: CommonService,
    protected dss: DataSourceService,
    protected gridHelper: GridHelperService,
  ) {
    super(logger);
  }

  protected buildDataSource() {
    return of(true).pipe(
      tap(() => {
        this.grid.instance.endCustomLoading();
        this.grid.instance.beginCustomLoading('Filtering...');
      }),

      switchMap(() =>
        this.pusher.rpc(
          'GET_INCONSISTENCY_CHECK',
          { idx: this.checkIdx, useRunJob: true },
          true,
          headersAllTenantsAppend,
        ),
      ),

      map(coll => {
        this.dsoColl = coll;
        return new DataSource(this.dss.createMongoStore(coll, this.mongoSchema));
      }),

      tap(() => {
        this.grid.instance.endCustomLoading();
      }),

      catchError(err => {
        notify(err.message, 'error', 5000);
        return of(null);
      }),

      tap(dso => (this.dso = dso)),

      takeUntil(this.$onDestroy$),
    );
  }

  async grid_onInitialized(e) {
    await this.buildDataSource().toPromise();

    this.gridHelper.handle(e.component, {
      onGridRefresh: async () => this.buildDataSource().toPromise(),
    });
  }

  async grid_toolbar_fix_onClick(type: number) {
    await of(true)
      .pipe(
        switchMap(async () => this.grid.instance.getSelectedRowsData()),
        map(docs => ({ type, coll: this.dsoColl, docs })),

        filter(args => !isEmpty(args.coll) || !isEmpty(args.docs)),

        tap(() => this.ui.showLoading()),
        switchMap(args =>
          this.pusher.rpc('FIX_INCONSISTENCY_CHECK', { ...args, useRunJob: true }, true, headersAllTenantsAppend),
        ),
        tap(() => this.ui.hideLoading()),

        switchMap(() => this.buildDataSource()),

        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .toPromise();
  }

  safeGetSource(doc) {
    return oc(doc)._source() || doc || {};
  }
}
