import { EventEmitter, HostListener, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryComponent } from 'devextreme-angular/ui/validation-summary';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject, Observable } from 'rxjs';
import { oc } from 'ts-optchain';
//
import { BaseLoopBackApi, LoggerService } from '../../../../sdk';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { FormHelperService } from '../../services/form-helper.service';
import { ABaseModelLoaderComponent } from './a-base-model-loader.component';

export abstract class ABaseFormComponent<
  M,
  A extends BaseLoopBackApi = BaseLoopBackApi,
> extends ABaseModelLoaderComponent<M, A> {
  formConfigMap: Map<string, any> = new Map<string, any>();
  form: FormGroup;

  $windowTitle$: BehaviorSubject<string>;
  $windowSubTitle$: BehaviorSubject<string>;

  tabsTitles;
  selectedTabIdx = 0;
  selectedTab: any;

  resetableForm = true;
  collapsibleForm = true;

  @Output() validateFails: EventEmitter<any> = new EventEmitter();
  @Output() beforeSubmitting: EventEmitter<any> = new EventEmitter();
  @Output() afterSubmitted: EventEmitter<M> = new EventEmitter();
  @Output() beforeSetFormValues: EventEmitter<M> = new EventEmitter();
  @Output() afterSetFormValues: EventEmitter<M> = new EventEmitter();

  @Output() submittingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(DxValidationGroupComponent) validationGroups: QueryList<DxValidationGroupComponent>;
  @ViewChildren(DxValidatorComponent) validators: QueryList<DxValidatorComponent>;
  @ViewChild(DxValidationSummaryComponent, { static: true }) validationSummary: DxValidationSummaryComponent;

  protected constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<M>,
  ) {
    super(logger, dss);

    this.buildForm();
  }

  private _formLoading = false;
  get formLoading(): boolean {
    return this._formLoading;
  }

  @Input()
  set formLoading(value: boolean) {
    if (value !== this._formLoading) {
      this._formLoading = value;
      this.formLoadingChange.emit(value);
    }
  }

  private _submitting = false;
  get submitting(): boolean {
    return this._submitting;
  }

  @Input()
  set submitting(value: boolean) {
    if (value !== this._submitting) {
      this._submitting = value;
      this.submittingChange.emit(value);
    }
  }

  protected get observeModels(): any[] {
    return [];
  }

  protected abstract get dateFields(): string[];

  protected get mongoDatePaths(): string[] {
    return [];
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(e?): Observable<boolean> | Promise<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    console.log(e);

    return oc(this.form).pristine(true);
  }

  addFormArrayItem(path: string, idx?: number): void {
    const fa = this.form.get(path) as FormArray;
    const fg = this.fb.group(cloneDeep(this.formConfigMap.get(path)));
    if (idx !== undefined) fa.insert(idx, fg);
    else fa.push(fg);
  }

  removeFormArrayItem(path: string, idx: number): void {
    const fa = this.form.get(path) as FormArray;
    fa.removeAt(idx);
  }

  async submitFormAsync(): Promise<M> {
    this.error = null;
    const isValid = await this.validateAsync();

    try {
      this.submitting = true;
      if (isValid) {
        const data = Object.assign({}, this.form.value);
        const [instance, id] = await this.submitDataAsync(data);
        this.modelId = id;
        return instance;
      } else {
        this.validateFails.emit();
      }
    } catch (err) {
      this.error = err;
      this.modelLoadingError.emit(err);
      // notify(this.errorMessage, 'error', 5000);
    } finally {
      setTimeout(() => (this.submitting = false));
    }
  }

  reset(): void {
    this.error = null;

    setTimeout(() => {
      // this.buildForm();
      this.setFormValues(this.model, true, true);
    });
  }

  protected abstract buildForm(): void;

  protected async afterModelLoadedAsync(model: M): Promise<void> {
    await super.afterModelLoadedAsync(model);

    try {
      this.formLoading = true;

      if (this.modelId) {
        await this.beforeSetFormValuesAsync(model);
        this.beforeSetFormValues.emit(model);

        // this.buildForm();
        this.setFormValues(model, true, true);

        await this.afterSetFormValuesAsync(model, this.form);
        this.afterSetFormValues.emit(model);
      }
    } finally {
      setTimeout(() => (this.formLoading = false));
    }
  }

  protected async beforeSetFormValuesAsync(model: M): Promise<void> {}

  protected async afterSetFormValuesAsync(model: M, form: FormGroup): Promise<void> {}

  private async deleteRelated(model, modelProp, path): Promise<any> {
    const RelatedModel = modelProp.constructor;
    const ModelDef = this.ModelClass.getModelDefinition();
    const RelatedModelDef = RelatedModel.getModelDefinition();

    const rel = ModelDef.relations[path];

    if (rel && rel.modelThrough) {
      return this.dss.getApi(this.ModelClass)['unlink' + RelatedModelDef.plural](model.id, modelProp.id).toPromise();
    } else {
      return this.dss.getApi(RelatedModel).deleteById(modelProp.id).toPromise();
    }
  }

  private async deleteRelatedRecursive(model: any, form: FormGroup): Promise<any> {
    return Promise.all(
      this.helper.getPathFormArrayPairs(form, this.formConfigMap).map(async ([path, formArray]) => {
        // find removed related entities
        return Promise.all(
          get(model, path, [])
            .filter(modelProp => modelProp.constructor && modelProp.constructor.getModelName)
            .map(modelProp => {
              const formProp = formArray.controls.find(itm => itm.value.id === modelProp.id) as FormGroup;
              if (!formProp) {
                return this.deleteRelated(model, modelProp, path);
              }
              return this.deleteRelatedRecursive(modelProp, formProp);
            }),
        );
      }),
    );
  }

  protected async beforeSubmittingAsync(data: any): Promise<void> {
    if (this.model) {
      await this.deleteRelatedRecursive(this.model, this.form);
    }
  }

  protected async afterSubmittedAsync(data: any, obj: any): Promise<void> {}

  protected async processFormValueAsync(data: any): Promise<any> {
    return this.helper.processFormValueAsync(this.model, data, {
      datePaths: this.dateFields,
      mongoDatePaths: this.mongoDatePaths,
    });
  }

  protected setFormValues(
    model: M,
    isReset: boolean = true,
    resetValidators: boolean = false,
    subForm: FormGroup = null,
  ): void {
    if (!this.form) {
      return;
    }
    const f = subForm || this.form;

    if (resetValidators && isReset && !subForm) {
      if (this.validationGroups && this.validationGroups.length) {
        this.validationGroups.forEach(vg => vg && vg.instance && vg.instance.reset());
      }
      if (this.validators && this.validators.length) {
        this.validators.forEach(v => v && v.instance && v.instance.reset());
      }
    }

    if (this.validationSummary) {
      this.validationSummary.items = [];
    }

    if (!model) {
      f.reset();
    } else {
      if (isReset) {
        f.reset(model);
      } else {
        f.patchValue(model);
      }
    }

    // set FormArray controls
    this.helper.getPathFormArrayPairs(f, this.formConfigMap).forEach(([path, ac]) => {
      const items: any[] = get(model, path, []);

      while (ac.length > 0) {
        ac.removeAt(0);
      }

      items.forEach(item => {
        const fg = this.fb.group(cloneDeep(this.formConfigMap.get(path)));
        this.setFormValues(item, isReset, resetValidators, fg);
        ac.push(fg);
      });
    });

    if (isReset) {
      f.markAsPristine();
      f.markAsUntouched();
    }
  }

  public async validateAsync(): Promise<boolean> {
    let validateResults = [];

    this.helper.withEachFormControl(this.form, c => {
      c.markAsTouched({ onlySelf: true });
    });
    this.form.updateValueAndValidity();

    if (this.validationGroups && this.validationGroups.length) {
      validateResults = [
        ...validateResults,
        ...this.validationGroups.map(vg => vg && vg.instance && vg.instance.validate()),
      ];
    }

    if (this.validators && this.validators.length) {
      validateResults = [...validateResults, ...this.validators.map(v => v && v.instance && v.instance.validate())];
    }

    const invalidResults = validateResults.filter(res => !res.isValid);

    // invalidResults.forEach((res) =>
    //   console.error(res.validationRules || res.validationRule));

    // if (!this.form.valid) {
    //   console.error(this.getControlErrors(this.form));
    // }

    // console.log(invalidResults.length, this.form.valid);
    // console.log(invalidResults);

    return isEmpty(invalidResults) && this.form.valid;
  }

  private getControlErrors(control?: AbstractControl) {
    if (control instanceof FormControl) {
      return control.errors;
    } else {
      return (
        control instanceof FormGroup
          ? Object.entries(control.controls).map(([f, fc]) => [f, this.getControlErrors(fc)])
          : control instanceof FormArray
            ? control.controls.map((fc, idx) => [`${idx}`, this.getControlErrors(fc)])
            : []
      )
        .map(([f, fe]) => {
          if (isArray(fe)) {
            return fe.map(([_f, _fe]) => [`${f}.${_f}`, _fe]);
          } else {
            return [[f, fe]];
          }
        })
        .reduce((a, b) => a.concat(b), [])
        .filter(([f, fe]) => !!fe);
    }
  }

  private async submitDataAsync(data: any): Promise<[M, number | string]> {
    data = await this.processFormValueAsync(data);

    await this.beforeSubmittingAsync(data);
    this.beforeSubmitting.emit(data);

    const model = new this.ModelClass(data);
    // this.logger.log(model);
    const [instance, id] = await this.helper.saveModelAsync(model);

    this.form.markAsPristine();
    this.form.markAsUntouched();

    await this.afterSubmittedAsync(data, instance);
    this.afterSubmitted.emit(instance);

    return [instance, id];
  }
}
