<kendo-splitter>
  <kendo-splitter-pane [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-auto-dispatch-list (mySelected)="list_onSelected($event)"></app-auto-dispatch-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane size="50%" [collapsible]="true" [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-auto-dispatch-details-tabs [message]="selected$ | async"></app-auto-dispatch-details-tabs>
  </kendo-splitter-pane>
</kendo-splitter>

