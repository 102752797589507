import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { Subscription } from 'rxjs';
import { Facility, FacilityApi, InternalStorage, LoopBackAuth, MyUtilsApi, Vehicle } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { HelperService } from '../../services/helper.service';
import { HelperService as HelperManifestService } from '../../../trip-manifest/services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import moment, { duration } from 'moment-timezone';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { LoadOptions } from 'devextreme/data/load_options';
import CustomStore from 'devextreme/data/custom_store';
import flatMap from 'lodash-es/flatMap';

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss'],
  providers: [HelperService, HelperManifestService],
})
export class VehicleGridComponent implements OnInit, OnDestroy {
  dso: DataSourceOptions;
  vehiclesMap: any;
  driversMap: any;
  goeTabDevicesMap: any;
  goeTabDevicesTimestamp = 0;
  selectedRowsData: Vehicle[] = [];
  STATES = ['ACTIVE', 'INACTIVE'];
  markerBaseUrl = '/assets/images/';

  grid_stateStoring: any;
  modifiedSubscription: Subscription;
  isBase = false;

  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    public config: ConfigService,
    private dss: DataSourceService,
    public helper: HelperService,
    public manifestHelper: HelperManifestService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    private myUtilsAPI: MyUtilsApi,
    private internalStorage: InternalStorage,
    private facilityApi: FacilityApi,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('00a07308-3a34-4ef0-b45f-112089c105d5');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '439b7e1f-9720-46cc-9082-b8a3108fc283',
    };

    this.buildData();
  }

  async ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Vehicle.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    // this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  grid_onSelectionChanged(e: any): void {
    this.selectedRowsData = e.selectedRowsData;
    this.mySelected.emit(this.getMarkers());
  }

  grid_onContentReady(e: any) {
    if (!e.component.getSelectedRowKeys().length) e.component.selectAll();
  }

  async buildData() {
    await this.setIsBase();
    await this.buildDataGrid();
  }

  async setIsBase() {
    const tenantId = this.auth.getCurrentTenant();
    const tenant = await this.facilityApi.findById<Facility>(tenantId).toPromise();
    this.isBase = tenant.type === 'BASE';
  }

  async buildDataGrid() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false);
    if (!this.isBase) {
      const date = moment().format('YYYY-MM-DD');
      const manifest = await this.manifestHelper.api.getCurrentManifest(date).toPromise();
      const vIds = [...new Set((manifest.data || []).map(d => d.v))];
      so.customFilter = { where: { id: { inq: vIds } } };
    }
    const store = new CustomStore(so);
    dxStoreLoadHooks(store, async (obj: LoadOptions) => {
      if (Date.now() - this.goeTabDevicesTimestamp > 10000) {
        this.goeTabDevicesTimestamp = Date.now();
        this.goeTabDevicesMap = await this.getGeoTabDevices();
        this.mySelected.emit(this.getMarkers());
        const { vehiclesMap, driversMap } = await this.getVehiclesWithDrivers();
        this.vehiclesMap = vehiclesMap;
        this.driversMap = driversMap;
      }
      return [obj];
    });

    this.dso = { store } as DataSourceOptions;
  }

  getMarkers(): any[] {
    const lastIgnition = ({ on, off }) => {
      const a = [
        `<br/><em>Last Ignition On:</em> ${(on && this.getTimeAgo(on)) || 'long time ago'}`,
        `<br/><em>Last Ignition Off:</em> ${(off && this.getTimeAgo(off)) || 'long time ago'}`,
      ];
      return (on < off ? a : [a[1], a[0]]).join('');
    };
    return this.selectedRowsData
      .filter((v: Vehicle) => this.goeTabDevicesMap[v.vin])
      .map((v: Vehicle) => [v, this.goeTabDevicesMap[v.vin]])
      .map(([v, d]) => {
        const states = {
          moving: [this.markerBaseUrl + 'marker-selected-device.png', 'In Motion'],
          notMoving: [null, 'Vehicle not moving'],
          disabled: [this.markerBaseUrl + 'marker-gray.png', 'Not moving for more than 3 hours'],
        };
        const [iconSrc, state] =
          (d.currentStateDuration > '03:00:00' && states['disabled']) ||
          (d.speed && states['moving']) ||
          states['notMoving'];
        return {
          vin: v.vin,
          tooltip: {
            text:
              `${v.internalId} ${v.make} ${v.model}` +
              `<br/><em>State:</em> ${state}` +
              `<br/><em>Updated:</em> ${this.getTimeAgo(d.dateTime)}` +
              `<br/><em>Speed:</em> ${d.speed}` +
              `<br/><em>State Duration:</em> ${d.currentStateDuration}` +
              lastIgnition(d.lastIgnition) +
              `<br/><em>Status:</em> ${v.status}`,
            isShown: false,
          },
          iconSrc,
          location: { lat: d.latitude, lng: d.longitude },
        };
      });
  }

  getFacilityCellValue = (v: Vehicle) => {
    return (this.vehiclesMap[v.id] && this.vehiclesMap[v.id].tenant.shortname) || '';
  };

  getDriverCellValue = (v: Vehicle) => {
    const driver = this.vehiclesMap[v.id] && this.driversMap[this.vehiclesMap[v.id].firstTrip.e];
    return (driver && `${driver.person.firstname} ${driver.person.lastname}`) || '';
  };

  async getVehiclesWithDrivers(): Promise<{ [key: string]: any }> {
    return await this.manifestHelper.api
      .getVehiclesWithDriversFromFirstManifest(moment().format('YYYY-MM-DD'))
      .toPromise();
  }

  getGeoTabLocationCellValue = (v: Vehicle) =>
    (this.goeTabDevicesMap[v.vin] &&
      `${this.goeTabDevicesMap[v.vin].latitude},${this.goeTabDevicesMap[v.vin].longitude}`) ||
    '';

  getMapURL(location: string) {
    return `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
  }

  getGeoTabDateTimeCellValue = (v: any) =>
    (this.goeTabDevicesMap[v.vin] && moment(this.goeTabDevicesMap[v.vin].dateTime).format('M/D/YYYY hh:mm:ss a')) || '';

  sortGeoTabTime = (v: any) =>
    (this.goeTabDevicesMap[v.vin] && -1 * new Date(this.goeTabDevicesMap[v.vin].dateTime).getTime()) || '';

  getGeoTabLastIgnition = (v: any) => ({
    ...(this.goeTabDevicesMap[v.vin] && this.goeTabDevicesMap[v.vin].lastIgnition),
  });

  getGeoTabLastIgnitionOnCellValue = (v: any) => this.getGeoTabLastIgnition(v).on || '';

  getGeoTabLastIgnitionOffCellValue = (v: any) => this.getGeoTabLastIgnition(v).off || '';

  sortLastIgnitionOn = (v: any) => -1 * new Date(this.getGeoTabLastIgnition(v).on || 0).getTime();

  sortLastIgnitionOff = (v: any) => -1 * new Date(this.getGeoTabLastIgnition(v).off || 0).getTime();

  getTimeAgo(time: string) {
    return (time && duration(moment(new Date(time)).diff(moment())).humanize(true)) || '';
  }

  async getGeoTabDevices(attempt = 0): Promise<{ [key: string]: any }> {
    try {
      const geoTabAuth = this.internalStorage.get('geoTabAuth') || (await this.myUtilsAPI.geoTabAuth().toPromise());
      this.internalStorage.set('geoTabAuth', geoTabAuth);

      const api = new GeotabApi(geoTabAuth);
      const req = [
        ['Get', { typeName: 'Device' }],
        ['Get', { typeName: 'DeviceStatusInfo' }],
        [
          'Get',
          {
            typeName: 'StatusData',
            search: {
              diagnosticSearch: {
                id: 'DiagnosticIgnitionId',
              },
              // deviceSearch: {
              //   id: 'b78',
              // },
              fromDate: moment().subtract(1, 'days'),
            },
          },
        ],
      ];
      const [[devices, deviceStatuses, ignitions], last] = await Promise.all([
        api.multiCall(req),
        gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getLastGeoTabAggregate()).pipe().toPromise(),
      ]);

      const mLast = last.reduce((p, v) => ({ ...p, [v.vin]: v.lastIgnition }), {});
      const dsMap = deviceStatuses.reduce((p, ds) => ({ ...p, [ds.device.id]: ds }), {});
      ignitions.forEach(ign => {
        if (
          dsMap[ign.device.id] &&
          (!dsMap[ign.device.id][`ignition_${ign.data}`] || dsMap[ign.device.id][`ignition_${ign.data}`] < ign.dateTime)
        ) {
          dsMap[ign.device.id][`ignition_${ign.data}`] = ign.dateTime;
        }
      });
      return devices.reduce(
        (p, { id, vehicleIdentificationNumber: vin }) =>
          (vin &&
            dsMap[id] && {
              ...p,
              [vin]: {
                dateTime: dsMap[id].dateTime,
                latitude: dsMap[id].latitude,
                longitude: dsMap[id].longitude,
                speed: dsMap[id].speed,
                currentStateDuration: dsMap[id].currentStateDuration,
                lastIgnition: {
                  ...((dsMap[id][`ignition_1`] && { on: dsMap[id][`ignition_1`] }) ||
                    (mLast[vin] && { on: mLast[vin].on })),
                  ...((dsMap[id][`ignition_0`] && { off: dsMap[id][`ignition_0`] }) ||
                    (mLast[vin] && { off: mLast[vin].off })),
                },
              },
            }) ||
          p,
        {},
      );
    } catch (err) {
      const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
      if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.') throw msg;
      if (attempt) return {};
      this.internalStorage.remove('geoTabAuth');
      return await this.getGeoTabDevices(1);
    }
  }

  getLastGeoTabAggregate() {
    return [
      { $match: {} },
      { $unwind: '$days' },
      {
        $project: {
          vin: 1,
          distance: '$days.distance',
          day: '$days.day',
          lastIgnition: { on: '$days.firstEngineOn', off: '$days.lastEngineOff' },
        },
      },
      { $match: { distance: { $gte: 1 } } },
      { $sort: { day: 1 } },
      { $group: { _id: '$vin', last: { $last: '$$ROOT' } } },
      { $project: { _id: 0, vin: '$last.vin', lastIgnition: '$last.lastIgnition' } },
    ];
  }
}

