import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MyFileApi } from '../../../sdk';

@Pipe({
  name: 'fileSignedUrl',
})
export class FileSignedUrlPipe implements PipeTransform {
  constructor(private fileApi: MyFileApi) {}

  transform(f: any): Observable<string> {
    if (isNaN(f)) return this.fileApi.getReadSignedUrlByFile(f);
    return this.fileApi.getReadSignedUrl(f).pipe(catchError(err => of('')));
  }
}
