<dx-drop-down-box
  #dropDownBox
  [placeholder]="placeholder"
  [showClearButton]="showClearButton"
  [width]="width"
  [(opened)]="opened"
  [(disabled)]="disabled"
  [ngClass]="{ 'dx-invalid': invalid }"
  [dataSource]="dataSource"
  [valueExpr]="valueExpr"
  [displayExpr]="displayExpr"
  [(value)]="gridBoxValue"
  (onValueChanged)="dropDownBox_onValueChanged($event)"
  (onFocusOut)="focusOut.emit($event)"
  (onClosed)="closed.emit($event)">
  <dxo-drop-down-options [width]="popupWidth" [height]="popupHeight"></dxo-drop-down-options>

  <ng-container *dxTemplate="let data of 'content'">
    <dx-data-grid
      #dataGrid
      [selection]="gridSelection"
      [columns]="gridColumns"
      [dataSource]="dataSource"
      [height]="'100%'"
      [hoverStateEnabled]="true"
      [columnChooser]="{ enabled: false }"
      [searchPanel]="{ visible: false }"
      [filterRow]="{ visible: true }"
      [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
      [paging]="{ enabled: true }"
      [pager]="{ visible: false }"
      [scrolling]="{ mode: 'infinite' }"
      [(selectedRowKeys)]="gridSelectedRowKeys"
      (onSelectionChanged)="grid_onSelectionChanged($event)"
      (onCellPrepared)="cellPrepared.emit($event)">
    </dx-data-grid>
  </ng-container>
</dx-drop-down-box>
