import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { share } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
//
import { PersonFormComponent } from '../../../../../modules/person/components/person-form/person-form.component';
import { LoopBackFilter, Person } from '../../../../sdk/models';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { UiService } from '../../services/ui.service';
import { CUSTOM_FIELD_TYPES } from '../custom-fields/enums';
import sortBy from 'lodash-es/sortBy';

@Component({
  selector: 'app-form-array-group',
  templateUrl: './form-array-group.component.html',
  styleUrls: ['./form-array-group.component.scss'],
})
export class FormArrayGroupComponent implements OnInit, AfterViewInit {
  customFieldTypes = CUSTOM_FIELD_TYPES;

  @Input() labels: string[] = [];
  @Input() formArray: FormArray;
  @Input() entityName: string;
  @Input() icon: string;
  @Input() verticalButtons = false;
  @Input() compact = false;

  @Output() addItem: EventEmitter<void> = new EventEmitter();
  @Output() removeItem: EventEmitter<number> = new EventEmitter();

  private persons: Map<number, [BehaviorSubject<number>, Observable<Person>]> = new Map();

  constructor(protected dialog: MatDialog, protected dss: DataSourceService, protected ui: UiService) {}

  get mainControls() {
    return this.formArray.controls.filter(ctrl => ctrl.get('label') && isEmpty(ctrl.get('label').value));
  }

  get labeledControls() {
    return this.formArray.controls.filter(ctrl => ctrl.get('label') && !isEmpty(ctrl.get('label').value));
  }

  get sortedControls() {
    return sortBy(this.formArray.controls, [
      ctrl => ctrl.get('label') && !isEmpty(ctrl.get('label').value),
      ctrl => ctrl.get('label') && ctrl.get('label').value,
    ]);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  isPersonER(person: Person): boolean {
    return !!(person && get(person, 'data.er'));
  }

  editPerson_onClick(ctrl: FormControl, modelId: number | string) {
    this.ui
      .openEditDialog({
        modelId,
        ModelClass: Person,
        FormComponentClass: PersonFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(({ id }) => {
        if (id) {
          this.nextPerson(id);
          ctrl.get('personId').setValue(id);
        }
      });
  }

  getPerson$(personIdStr: string): Observable<Person> {
    const personId: number = Number(personIdStr);

    if (!this.persons.has(personId)) {
      const filter: LoopBackFilter = { fields: { id: true, firstname: true, lastname: true, data: true } };
      const _$pId$ = new BehaviorSubject(personId);
      const _p$ = _$pId$.pipe(
        switchMap(id =>
          id
            ? this.dss
                .getApi(Person)
                .findById<Person>(id, filter)
                .pipe(catchError(() => of(null)))
            : of(null),
        ),
        share(),
      );

      this.persons.set(personId, [_$pId$, _p$]);
    }

    const [$pId$, p$] = this.persons.get(personId);

    if ($pId$.getValue() !== personId) {
      $pId$.next(personId);
    }

    return p$;
  }

  relation_box_onInitialized(ctrl: FormGroup) {
    // console.log(ctrl);
  }

  private nextPerson(id: any): void {
    const personId = Number(id);
    if (this.persons.has(personId)) {
      const [$pId$] = this.persons.get(personId);
      $pId$ && $pId$.next(personId);
    }
  }
}
