import { createSelector } from '@ngrx/store';
import { Config } from '../../../shared/sdk/models';
import {
  Actions,
  LOAD_CONFIG,
  LOAD_CONFIG_FAIL,
  LOAD_CONFIG_SUCCESS,
  LoadConfigFail,
  LoadConfigSuccess,
  SAVE_CONFIG,
  SAVE_CONFIG_FAIL,
  SAVE_CONFIG_SUCCESS,
  SaveConfigFail,
  SaveConfigSuccess,
  SET_CONFIG,
} from '../../actions/core';

export interface ConfigState {
  config: Config;
  error: any | null;
  pending: boolean;
}

export const initialState: ConfigState = {
  config: null,
  error: null,
  pending: false,
};

export function configReducer(state = initialState, action: Actions): ConfigState {
  switch (action.type) {
    case LOAD_CONFIG:
    case SAVE_CONFIG: {
      return {
        ...state,
        error: null,
        pending: true,
      } as ConfigState;
    }

    case LOAD_CONFIG_SUCCESS:
    case SAVE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: (action as LoadConfigSuccess | SaveConfigSuccess).payload,
        error: null,
        pending: false,
      } as ConfigState;
    }

    case LOAD_CONFIG_FAIL:
    case SAVE_CONFIG_FAIL: {
      return {
        ...state,
        // config: null,
        error: (action as LoadConfigFail | SaveConfigFail).payload,
        pending: false,
      } as ConfigState;
    }

    case SET_CONFIG: {
      return {
        ...state,
        config: action.payload,
      } as ConfigState;
    }

    default: {
      return state;
    }
  }
}

export const configGetPending = (state: ConfigState) => state.pending;
export const configGetError = (state: ConfigState) => state.error;
export const configGetErrorMessage = createSelector(configGetError, err => (err ? err.message || err : ''));

export const configGetConfig = (state: ConfigState) => state.config;
