/* tslint:disable:member-ordering */
import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { LoggerService } from '../../../../sdk';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import fromPairs from 'lodash-es/fromPairs';
import isNil from 'lodash-es/isNil';
import { oc } from 'ts-optchain';

type MapType = { [key: string]: any };

@Component({
  selector: 'app-map-value-box',
  templateUrl: './map-value-box.component.html',
  styleUrls: ['./map-value-box.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: forwardRef(() => MapValueBoxComponent) }],
})
export class MapValueBoxComponent implements MatFormFieldControl<MapType>, ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;

  parts: FormGroup;

  stateChanges = new Subject<void>();

  focused = false;

  errorState = false;

  controlType = 'app-map-value-box';

  @HostBinding('attr.id') id = `app-map-value-box-${MapValueBoxComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when select has been touched` */
  _onTouched = () => {};

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    public logger: LoggerService,
  ) {
    // Setting the value accessor directly (instead of using
    // the providers) to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.parts = this.fb.group(fromPairs(this.labels.map(l => [l, null])));

    this.parts.valueChanges.subscribe(parts => {
      this._onChange(fromPairs(this.labels.map(l => [l, parts[l]])) as MapType);
    });
  }

  @Input() labels: string[] = [];
  @Input() source: { v: any; t: any }[] = [];

  private _placeholder: string;
  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set placeholder(plh) {
    setTimeout(() => {
      this._placeholder = plh;
      this.stateChanges.next();
    });
  }

  private _required = false;
  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    setTimeout(() => {
      this._required = coerceBooleanProperty(req);
      this.stateChanges.next();
    });
  }

  private _disabled = false;
  get disabled() {
    return this._disabled;
  }

  @Input()
  set disabled(dis) {
    setTimeout(() => {
      this._disabled = coerceBooleanProperty(dis);
      this.stateChanges.next();
    });
  }

  private _readonly = false;
  get readonly() {
    return this._readonly;
  }

  @Input()
  set readonly(val) {
    setTimeout(() => {
      this._readonly = coerceBooleanProperty(val);
      this.stateChanges.next();
    });
  }

  get value(): MapType | null {
    const n = this.parts.value;
    return fromPairs(this.labels.map(l => [l, n[l]])) as MapType;
  }

  @Input()
  set value(val: MapType | null) {
    val = val || (fromPairs(this.labels.map(l => [l, null])) as MapType);

    setTimeout(() => {
      this.parts.setValue(fromPairs(this.labels.map(l => [l, oc(val)[l](null)])));
      this.stateChanges.next();
    });
  }

  get empty() {
    const n = this.parts.value;
    return this.labels.map(l => n[l]).every(v => isNil(v));
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }
}
