<div mat-dialog-content>
  <mat-form-field>
    <input matInput type='number' placeholder='Hot' [(ngModel)]='value.W1759' />
  </mat-form-field>
  <br/>
  <mat-form-field>
    <input matInput type='number' placeholder='Frozen' [(ngModel)]='value.W1760' />
  </mat-form-field>
  <br/>
  <mat-form-field>
    <input matInput type='number' placeholder='Sandwich' [(ngModel)]='value.W1761' />
  </mat-form-field>
  <br/>
  <mat-form-field>
    <input matInput type='number' placeholder='Emergency' [(ngModel)]='value.W1762' />
  </mat-form-field>
  <br/>
  <mat-form-field>
    <input matInput type='number' placeholder='Special' [(ngModel)]='value.W1764' />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]='false'>Cancel</button>
  <button mat-button [mat-dialog-close]='value'>Set</button>
</div>
