
declare var Object: any;
export interface AlertInterface {
  "text": string;
  "fixed"?: boolean;
  "hush"?: boolean;
  "checkerGUID": string;
  "objectFQN": string;
  "objectType": string;
  "objectId": number;
  "id"?: number;
  "alertCheckerId"?: number;
  alertChecker?: any;
}

export class Alert implements AlertInterface {
  "text": string;
  "fixed": boolean;
  "hush": boolean;
  "checkerGUID": string;
  "objectFQN": string;
  "objectType": string;
  "objectId": number;
  "id": number;
  "alertCheckerId": number;
  alertChecker: any;
  constructor(data?: AlertInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Alert`.
   */
  public static getModelName() {
    return "Alert";
  }

  /**
  * This method creates an instance of Alert for dynamic purposes.
  **/
  public static factory(data: AlertInterface): Alert{
    return new Alert(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Alert',
      plural: 'Alerts',
      path: 'Alerts',
      idName: 'id',
      properties: {
        "text": {
          name: 'text',
          type: 'string'
        },
        "fixed": {
          name: 'fixed',
          type: 'boolean',
          default: false
        },
        "hush": {
          name: 'hush',
          type: 'boolean',
          default: false
        },
        "checkerGUID": {
          name: 'checkerGUID',
          type: 'string'
        },
        "objectFQN": {
          name: 'objectFQN',
          type: 'string'
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "alertCheckerId": {
          name: 'alertCheckerId',
          type: 'number'
        },
      },
      relations: {
        alertChecker: {
          name: 'alertChecker',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'alertCheckerId',
          keyTo: 'id'
        },
      }
    }
  }
}
