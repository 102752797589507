import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
import moment, { duration } from 'moment';
export class VehicleDetailsTabActivityComponent extends ABaseModelPaneWToolbarComponent {
    constructor(logger, ui, dss, dialog, helper, docHelper) {
        super(logger, ui, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.helper = helper;
        this.docHelper = docHelper;
        this.caption = 'Activity';
    }
    getDuration(seconds) {
        return (seconds && moment.utc(duration(seconds, 'seconds').as('milliseconds')).format('HH:mm:ss')) || '';
    }
    get ModelClass() {
        return Vehicle;
    }
    get FormComponent() {
        return VehicleFormComponent;
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.dso = this.buildDSO(model);
        });
    }
    buildDSO(model) {
        return {
            load: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const filter = { where: { vin: model.vin }, fields: { positions: false } };
                const data = yield this.dss.getApi(VehicleGeotab).find(filter).toPromise();
                return this.prepareData(data);
            }),
        };
    }
    prepareData(data) {
        const days = data
            .filter(d => d.days)
            .flatMap(d => d.days)
            .map(day => {
            const d = '' + day.day;
            const date = `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(-2)}`;
            return Object.assign({}, day, { id: day.day, date: date, distance: Math.round(day.distance / 1.60934), onDutyDuration: this.getDuration(+day.onDutyDuration), onDutyEngineOnDuration: this.getDuration(+day.onDutyEngineOnDuration), onDutyEngineOffDuration: this.getDuration(+day.onDutyEngineOffDuration), onDutyIdleDuration: this.getDuration(+day.onDutyIdleDuration), onDutyIdle20Periods: day.onDutyIdle20Periods, 
                // onDutyIdle20Duration: this.getDuration(+day.onDutyIdle20Duration),
                onDutyIdleAbove20Duration: this.getDuration(+day.onDutyIdleAbove20Duration), onDutyIdle30Periods: day.onDutyIdle30Periods, 
                // onDutyIdle30Duration: this.getDuration(+day.onDutyIdle30Duration),
                onDutyIdleAbove30Duration: this.getDuration(+day.onDutyIdleAbove30Duration), onDutyDrivingDuration: this.getDuration(+day.onDutyDrivingDuration), totalTimeOut: this.getDuration(+day.totalTimeOut), alerts: (day.alerts || []).join(', ') });
        });
        return days;
    }
}
