<div mat-dialog-content>
  <h2>Select Target Facility</h2>
  <dx-data-grid
    #grid1
    width="100%"
    height="300"
    [dataSource]="ds1"
    [columns]="gridColumns1"

    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [pager]="{ visible: false }"
    [selection]="gridSelection1"
    [paging]="gridPaging1"
    [filterRow]="gridFilterRow1"
    [scrolling]="gridScrolling1"
    [showBorders]="true"
    [(selectedRowKeys)]="selectedRowKeys"

    (onSelectionChanged)="grid1_onSelectionChanged($event)"
  ></dx-data-grid>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="selectedFacility" [disabled]="!selectedFacility" cdkFocusInitial>Select</button>
</div>
