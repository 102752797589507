import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
//
import { Consumer, TripManifest, TripManifestRec } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-drop-down-grid-consumers',
  templateUrl: './drop-down-grid-consumers.component.html',
  styleUrls: ['./drop-down-grid-consumers.component.scss'],
  providers: [ConsumerHelperService, HelperService],
})
export class DropDownGridConsumersComponent implements OnChanges, OnInit, OnDestroy {
  consumerCellClassMap: Map<number, any> = new Map();

  @Input() ds: any;

  @Input() gridColumns: any[] = [
    { dataField: 'status', caption: 'Status' },
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
    { dataField: 'person_nickname', caption: 'Nickname' },
  ];
  @Input() manifest: TripManifest;

  @Input() value: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    protected logger: LoggerService,
    private dss: DataSourceService,
    // protected cd: ChangeDetectorRef,
    public helper: HelperService,
    public consumerHelper: ConsumerHelperService,
  ) {
    this.buildDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('manifest' in changes) {
      this.buildConsumersCellClassMap();
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    // this.cd.detach();
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.data && e.data.id) {
      const cnsId = e.data.id;
      if (this.consumerCellClassMap.has(cnsId)) {
        const opts = this.consumerCellClassMap.get(cnsId);
        (e.cellElement as HTMLElement).classList.add(`cell-${opts.cellClass}`);
        (e.cellElement as HTMLElement).setAttribute('title', opts.title);
      }
    }
  }

  private buildDataSource() {
    this.ds = {
      store: this.dss.getStore(Consumer, null, false),
      filter: [
        ['status', '<>', 'INACTIVE'],
        // ['source', '=', null],
      ],
    };
  }

  private buildConsumersCellClassMap() {
    const recs: TripManifestRec[] = this.manifest.data || [];
    const cnsMap: Map<number, Map<string, Set<string>>> = this.helper.getManifestConsumerTripsMap(recs);

    this.consumerCellClassMap = new Map();
    cnsMap.forEach((destinationMap, cnsId) => {
      this.consumerCellClassMap.set(cnsId, {
        title: '· This Consumer already assigned',
        cellClass: 'gray',
      });

      if (!destinationMap.has('RESIDENCE')) {
        this.consumerCellClassMap.set(cnsId, {
          title: '· No Round Trip',
          cellClass: 'warning',
        });
      }

      destinationMap.forEach(recSet => {
        if (recSet.size > 1) {
          this.consumerCellClassMap.set(cnsId, {
            title: '· This Consumer assigned to several drivers',
            cellClass: 'danger',
          });
        }
      });
    });
  }
}
