<dx-data-grid
  #grid
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>
  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>
  <dxo-export [enabled]="true" fileName="employees-ext-export"></dxo-export>

  <!-- Columns -->
  <dxi-column caption="First Name" dataField="person.firstname" dataType="string"></dxi-column>
  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string"></dxi-column>
  <dxi-column caption="Phone" dataType="string" [calculateCellValue]="getPhoneCellValue"></dxi-column>
  <dxi-column caption="Email" dataType="string" [calculateCellValue]="getEmailCellValue"></dxi-column>
  <dxi-column caption="Today’s Last Vehicle" dataField="todaysVehicle" dataType="number"></dxi-column>
  <dxi-column
    caption="Today’s Scheduled Time"
    dataField="schedule.startTime"
    dataType="date"
    format="shortTime"></dxi-column>
  <dxi-column caption="Today’s Actual Start Time" dataField="ed.value" dataType="date" format="shortTime"></dxi-column>
  <dxi-column caption="Today’s Facility" dataField="facility.name" dataType="string"></dxi-column>
  <dxi-column caption="Last Date on Duty" dataField="last.date" dataType="date"></dxi-column>
  <dxi-column caption="Last Vehicle in use" dataField="last.vehicle.internalId" dataType="number"></dxi-column>

  <!-- Templates -->
</dx-data-grid>

