<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Employee Assigned</div>
      <div class="dx-field-value-static">
        <ng-container *ngIf="$employee$ | async as employee; else noEmployeeBlock">
          Facility: {{ employee?.tenant.shortname }} / {{ employee?.person.firstname }}
          {{ employee?.person.lastname }} ({{ employee?.employeePosition.name }})
        </ng-container>
        <ng-template #noEmployeeBlock>Undefined</ng-template>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Employees</div>
      <div class="dx-field-value">
        <app-employee-drop-down-grid
          formControlName="employeeId"
          [value]="($employee$ | async)?.id"
          [filter]="[]"
          [dataSource]="employeeDso$ | async"
          [grid_columns]="[
            {
              dataField: 'tenantId',
              caption: 'Tenant',
              lookup: {
                dataSource: eFacilityDso$ | async,
                valueExpr: 'id',
                displayExpr: 'shortname'
              }
            },
            { dataField: 'person_firstname', caption: 'Firstname' },
            { dataField: 'person_lastname', caption: 'Lastname' },
            {
              dataField: 'userId',
              caption: 'User',
              lookup: {
                dataSource: eUserDso$ | async,
                valueExpr: 'id',
                displayExpr: 'username'
              }
            }
          ]"
          [showClearButton]="true"></app-employee-drop-down-grid>
      </div>
    </div>

    <div class="dx-fieldset">
      <dx-toolbar>
        <dxi-item location="after">
          <dx-button type="normal" text="Assign Employee" (onClick)="assignEmployee($event)"></dx-button>
        </dxi-item>
      </dx-toolbar>
    </div>

    <hr />
    <br />

    <div class="dx-field">
      <div class="dx-field-label">Assign To Facilities <mat-icon title="Ability to login to ADC">info</mat-icon></div>
      <div class="dx-field-value">
        <dx-tag-box
          formControlName="facilities"
          [dataSource]="facilityDso"
          [value]="$assignedFacilities$ | async"
          valueExpr="id"
          displayExpr="shortname"
          itemTemplate="facilityItem"
          [grouped]="true"
          [showSelectionControls]="true"
          [disabled]="tagBoxAssignedDisabled"
        >
          <div *dxTemplate="let f of 'facilityItem'">
            <strong>{{ f.name }}</strong> [{{ f.type }}: {{ f.shortname }}]
          </div>
        </dx-tag-box>
      </div>
    </div>

    <div class="dx-fieldset">
      <dx-toolbar>
        <dxi-item location="after">
          <dx-button type="normal" text="Assign to" (onClick)="assignFacilities($event)"></dx-button>
        </dxi-item>
        <dxi-item location="after">
          <dx-button type="normal" text="Assign to Both" (onClick)="assignBothFacilities($event)"></dx-button>
        </dxi-item>
      </dx-toolbar>
    </div>

    <hr />
    <br />

    <div class="dx-field">
      <div class="dx-field-label">
        Visible From Facilities <mat-icon title="Visible from ADC employee (driver) assign list">info</mat-icon>
      </div>
      <div class="dx-field-value">
        <dx-tag-box
          formControlName="fromFacilities"
          [dataSource]="facilityDso"
          [value]="($employee$ | async)?.tenantIds"
          valueExpr="id"
          displayExpr="shortname"
          itemTemplate="facilityItem"
          [grouped]="true"
          [showSelectionControls]="true"
          [disabled]="tagBoxVisibleFromDisabled"
        >
          <div *dxTemplate="let f of 'facilityItem'">
            <strong>{{ f.name }}</strong> [{{ f.type }}: {{ f.shortname }}]
          </div>
        </dx-tag-box>
      </div>
    </div>

    <div class="dx-fieldset">
      <dx-toolbar>
        <dxi-item location="after">
          <dx-button type="normal" text="Assign" (onClick)="visibleFromFacilities($event)"></dx-button>
        </dxi-item>
      </dx-toolbar>
    </div>

    <!-- /// -->

    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <p class="alert-danger" *ngIf="error">
      {{ error }}
    </p>
  </div>
</dx-validation-group>
