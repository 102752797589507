<dx-tab-panel
  width="100%"
  height="100%"
  #tabPanel
  [dataSource]="tabs"
  [(selectedIndex)]="selectedIndex"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="true"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'summary'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-info [modelId]="modelId"></app-vehicle-details-tab-info>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'notes'">
    <app-vehicle-details-tab-notes [modelId]="modelId"></app-vehicle-details-tab-notes>
  </div>

  <div *dxTemplate="let data of 'activity'">
    <app-vehicle-details-tab-activity [modelId]="modelId"></app-vehicle-details-tab-activity>
  </div>

  <div *dxTemplate="let data of 'routes'">
    <div class="fullscreen">
      <app-vehicle-details-tab-routes [modelId]="modelId"></app-vehicle-details-tab-routes>
    </div>
  </div>

  <div *dxTemplate="let data of 'specs'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-specs [modelId]="modelId"></app-vehicle-details-tab-specs>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'equipment'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-equipment [modelId]="modelId"></app-vehicle-details-tab-equipment>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'document'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-document [modelId]="modelId"></app-vehicle-details-tab-document>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'maintenanceRecs'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-maintenance-recs [modelId]="modelId"></app-vehicle-details-tab-maintenance-recs>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'financials'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-financials [modelId]="modelId"></app-vehicle-details-tab-financials>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'claims'">
    <dx-scroll-view height="100%">
      <app-vehicle-details-tab-claims [modelId]="modelId"></app-vehicle-details-tab-claims>
    </dx-scroll-view>
  </div>
</dx-tab-panel>
