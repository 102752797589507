import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
//
import { BACK, FORWARD, GO } from '../actions/router';
export class RouterEffects {
    constructor(actions$, router, location) {
        this.actions$ = actions$;
        this.router = router;
        this.location = location;
        this.navigate$ = this.actions$.pipe(ofType(GO), map((action) => action.payload), tap(({ path, query: queryParams, extras }) => this.router.navigate(path, Object.assign({ queryParams }, extras))));
        this.navigateBack$ = this.actions$.pipe(ofType(BACK), tap(() => this.location.back()));
        this.navigateForward$ = this.actions$.pipe(ofType(FORWARD), tap(() => this.location.forward()));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], RouterEffects.prototype, "navigate$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], RouterEffects.prototype, "navigateBack$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], RouterEffects.prototype, "navigateForward$", void 0);
