<div mat-dialog-content>
  <mat-form-field style="width: 100%">
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="value.status">
      <mat-option *ngFor="let status of STATUSES" [value]="status.v">
        {{ status.t }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br />

  <mat-form-field style="width: 100%">
    <input matInput type="text" placeholder="Status Note" [(ngModel)]="value.note" />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="value">Apply</button>
</div>
