
declare var Object: any;
export interface RouterInterface {
  "id"?: string;
}

export class Router implements RouterInterface {
  "id": string;
  constructor(data?: RouterInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Router`.
   */
  public static getModelName() {
    return "Router";
  }

  /**
  * This method creates an instance of Router for dynamic purposes.
  **/
  public static factory(data: RouterInterface): Router{
    return new Router(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Router',
      plural: 'Routers',
      path: 'Routers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
