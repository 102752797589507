import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//
import { MyCommonModule } from '../shared/modules/my-common/my-common.module';
import { UiModule } from '../shared/modules/ui/ui.module';
//
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

import { AppComponent } from './containers/app/app.component';
import { EmptyComponent } from './containers/empty/empty.component';
import { FixedComponent } from './containers/fixed/fixed.component';
import { NotFoundComponent } from './containers/not-found/not-found.component';
import { ScrollableComponent } from './containers/scrollable/scrollable.component';
import { CheckACLPipe } from './pipes/check-acl.pipe';
import { LoadingComponent } from './containers/loading/loading.component';

@NgModule({
  imports: [CommonModule, RouterModule, MyCommonModule, UiModule],
  declarations: [
    AppComponent,
    NotFoundComponent,
    DashboardComponent,
    SidebarComponent,
    ToolbarComponent,
    ScrollableComponent,
    FixedComponent,
    EmptyComponent,
    LoadingComponent,

    CheckACLPipe,
  ],
  exports: [AppComponent, NotFoundComponent, EmptyComponent, LoadingComponent],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
