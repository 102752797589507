/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../../../node_modules/devextreme-angular/ui/load-panel/devextreme-angular-ui-load-panel.ngfactory";
import * as i4 from "devextreme-angular/core";
import * as i5 from "devextreme-angular/ui/load-panel";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "../../../shared/sdk/services/custom/logger.service";
import * as i10 from "@ngrx/store";
import * as i11 from "../../../shared/modules/my-common/services/common.service";
import * as i12 from "../../../shared/modules/ui/services/push-notifications.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [["name", "popup"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "popup"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "dx-load-panel", [["shadingColor", "rgba(0,0,0,0.4)"]], null, null, null, i3.View_DxLoadPanelComponent_0, i3.RenderType_DxLoadPanelComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(8, 7323648, null, 0, i5.DxLoadPanelComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { closeOnOutsideClick: [0, "closeOnOutsideClick"], shading: [1, "shading"], shadingColor: [2, "shadingColor"], showIndicator: [3, "showIndicator"], showPane: [4, "showPane"], visible: [5, "visible"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = false; var currVal_1 = true; var currVal_2 = "rgba(0,0,0,0.4)"; var currVal_3 = true; var currVal_4 = true; var currVal_5 = i1.ɵunv(_v, 8, 5, i1.ɵnov(_v, 9).transform(_co.loadingVisible$)); _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i9.LoggerService, i10.Store, i11.CommonService, i12.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
