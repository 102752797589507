<div [formGroup]="parts" [ngStyle]="{ opacity: readonly ? 0.5 : 1 }">
  <ng-container *ngFor="let weekday of ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']; let isFirst = first">
    <!--    <span *ngIf="!isFirst">&ndash;</span>-->
    <div class="item">
      <label>{{ weekday }}</label>
      <input
        matInput
        type="number"
        size="2"
        maxlength="2"
        min="0"
        max="99"
        [required]="required"
        [disabled]="disabled"
        [readonly]="readonly"
        [formControlName]="weekday"
        (blur)="_onTouched()" />
    </div>
  </ng-container>
</div>
