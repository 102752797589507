/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./facility.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/facility-list/facility-list.component.ngfactory";
import * as i3 from "../../components/facility-list/facility-list.component";
import * as i4 from "./facility.component";
var styles_FacilityComponent = [i0.styles];
var RenderType_FacilityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FacilityComponent, data: {} });
export { RenderType_FacilityComponent as RenderType_FacilityComponent };
export function View_FacilityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-facility-list", [], null, null, null, i2.View_FacilityListComponent_0, i2.RenderType_FacilityListComponent)), i1.ɵdid(1, 114688, null, 0, i3.FacilityListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FacilityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-facility", [], null, null, null, View_FacilityComponent_0, RenderType_FacilityComponent)), i1.ɵdid(1, 114688, null, 0, i4.FacilityComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FacilityComponentNgFactory = i1.ɵccf("app-facility", i4.FacilityComponent, View_FacilityComponent_Host_0, {}, {}, []);
export { FacilityComponentNgFactory as FacilityComponentNgFactory };
