import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { MatDialogRef } from '@angular/material/dialog';
export class DlgAddEmployeeBackupComponent {
    constructor(logger, dialogRef, data) {
        this.logger = logger;
        this.dialogRef = dialogRef;
        this.data = data;
        this.resetableForm = false;
        this.collapsibleForm = false;
        this.notBackUpEmployees$ = of([]);
        this.selectBoxdisplayExpression = (e) => (e ? `${e.person.firstname} ${e.person.lastname}` : null);
        this.onSave = () => this.dialogRef.close(this.selected);
    }
    ngOnInit() {
        this.notBackUpEmployees$ = of(this.data.notBackUpEmployees);
    }
    beforeSubmittingAsync(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
}
