<app-content-w-header header="Fleet Acquisition Related Contacts">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <div style="flex: auto; position: relative">
      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
        <dx-box direction="col" height="100%" width="100%">
          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
            <dx-data-grid
              height="100%"
              [showBorders]="true"
              [showRowLines]="true"
              [dataSource]="dso"
              [remoteOperations]="true"
              [rowAlternationEnabled]='true'
            >
              <!-- Settings -->

              <dxo-group-panel [visible]="false"></dxo-group-panel>
              <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
              <dxo-search-panel [visible]="true"></dxo-search-panel>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-pager [visible]="false" ></dxo-pager>

              <dxo-scrolling [mode]="'infinite'"></dxo-scrolling>
              <dxo-selection [mode]="'none'"></dxo-selection>

              <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

              <!-- Columns -->

              <dxi-column caption="Type" dataType="string" dataField="type">
                <dxo-lookup [dataSource]="['DEALER', 'CREDITOR']"></dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>

              <dxi-column caption="Business Name" dataType="string" dataField="businessName">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-column>

              <dxi-column caption="Address" dataType="string" dataField="address"></dxi-column>

              <dxi-column caption="Phone" dataType="string" dataField="phone"></dxi-column>
            </dx-data-grid>
          </dxi-item>
        </dx-box>
      </div>
    </div>
  </div>
</app-content-w-header>
