<table [ngClass]="{ wrapped: wrap, unwrapped: !wrap }">
  <ng-container *ngFor="let note of model?.relatedNotes">
    <tr>
      <td>
        <div [title]="note.text">
          <span class="badge"> {{ note.dateTime | date: 'yyyy/MM/dd HH:mm' }}</span>
          : {{ note.author || 'Unknown' }} : {{ note.text }} {{ info(note) }}
        </div>
      </td>
    </tr>
  </ng-container>
</table>
