import { NgModule } from '@angular/core';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';

const MODULES = [UploaderModule];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class MySyncfusionModule {}
