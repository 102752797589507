import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import notify from 'devextreme/ui/notify';
import { compact, identity } from 'lodash-es';
import head from 'lodash-es/head';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';
import moment, { utc } from 'moment';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoByKey, gqlMongoDoc } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { AuthServiceApi, LoggerService } from '../../../../shared/sdk';
import {
  AuthClaimService,
  IAuthMember,
  IChcE9yDoc,
  IClaimData,
  INavinetAuthDoc,
  TAppMealClaimSet,
} from '../../services/auth-claim.service';

@Component({
  selector: 'app-dlg-edit-claim',
  templateUrl: './dlg-edit-claim.component.html',
  styleUrls: ['./dlg-edit-claim.component.scss'],
  providers: [AuthClaimService],
})
export class DlgEditClaimComponent extends ABaseComponent implements OnInit {
  claimData: IClaimData;
  auth: INavinetAuthDoc;
  claimSet: TAppMealClaimSet;
  e9yDoc: IChcE9yDoc;
  rate: number;
  mode: string;

  @ViewChild('form', { static: false }) form: DxFormComponent;

  public payerLabel: any;
  public authLabel: any;
  validate = true;

  constructor(
    protected logger: LoggerService,
    private dss: DataSourceService,
    private ui: UiService,
    private api: AuthServiceApi,
    public config: ConfigService,
    private dialogRef: MatDialogRef<DlgEditClaimComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      date: Date;
      member: IAuthMember;
      authId: string;
      cells: { startDate: Date }[];
    },
  ) {
    super(logger);
  }

  ngOnInit() {
    // console.log(this.data);
    this.ui.showLoading();
    void this.buildForm$()
      .toPromise()
      .finally(() => this.ui.hideLoading());
  }

  buildForm$() {
    return combineLatest([this.getAuth$(), this.getClaimSet$()]).pipe(
      switchMap(([auth, claimSet]) =>
        combineLatest([
          this.getE9yDoc$(claimSet),
          this.api.getRate(moment(this.data.date).format('YYYY-MM-DD'), oc(auth).Code()),
        ]).pipe(map(([e9yDoc, rate]) => ({ auth, claimSet, e9yDoc, rate }))),
      ),
      map(({ auth, claimSet, e9yDoc, rate }) => {
        const memberCl = oc(this.data.member)
          .claims([])
          .find(cl => cl.auth._id === auth._id);

        this.auth = auth;
        this.claimSet = claimSet;
        this.e9yDoc = e9yDoc;
        this.rate = rate;

        this.payerLabel =
          'Preselected Payer [' +
          [oc(e9yDoc).response.payer.payorIdentification(), oc(e9yDoc).response.payer.name()].join(' | ') +
          ']';

        this.authLabel =
          'Preselected Auth [' +
          [
            oc(auth).AuthNumberFacets(),
            utc(oc(auth).StartDT()).format('L') + '-' + utc(oc(auth).EndDT()).format('L'),
          ].join(' | ') +
          ']';

        const clReq = oc(claimSet).claim.request();
        const clMember = oc(clReq).subscriber();

        const e9yResp = oc(e9yDoc).response();
        const e9yMember = oc(e9yResp).subscriber();

        const anyE9yResp = oc(this.getActiveE9yDocs())[0].response();
        const anyE9yMember = oc(anyE9yResp).subscriber();

        this.claimData = {
          claimId: oc(claimSet)._id(),

          correction: {
            claimControlNumber: oc(memberCl).claim.statusClaimID(),
          },

          authId: oc(claimSet).auth._id() || oc(auth)._id(),

          authID:
            oc(clReq).claimInformation.claimSupplementalInformation.priorAuthorizationNumber() ||
            oc(claimSet).auth.AuthNumberFacets() ||
            oc(auth).AuthNumberFacets(),

          payerID: oc(clReq).tradingPartnerServiceId() || oc(e9yDoc).e9y(),

          rate,
          chargeAmount: Number(oc(clReq).claimInformation.claimChargeAmount() || 0),

          subscriber: {
            mci: oc(claimSet).auth._mci() || oc(auth)._mci(),
            memberID:
              oc(clMember).memberId() || oc(e9yMember).memberId() || oc(anyE9yMember).memberId() || oc(auth).MemberID(),
            firstname:
              oc(clMember).firstName() ||
              oc(e9yMember).firstName() ||
              oc(anyE9yMember).firstName() ||
              oc(auth).FirstName(),
            lastname:
              oc(clMember).lastName() || oc(e9yMember).lastName() || oc(anyE9yMember).lastName() || oc(auth).LastName(),
            gender: oc(clMember).gender() || oc(e9yMember).gender() || oc(anyE9yMember).gender(),
            dob: head(
              compact([oc(clMember).dateOfBirth(), oc(e9yMember).dateOfBirth(), oc(anyE9yMember).dateOfBirth()]).map(
                d => moment(d, 'YYYYMMDD').format('YYYY-MM-DD'),
              ),
            ),
          },

          address: {
            address1: oc(e9yMember).address.address1() || oc(anyE9yMember).address.address1(),
            address2: oc(e9yMember).address.address2() || oc(anyE9yMember).address.address2(),

            city: oc(e9yMember).address.city() || oc(anyE9yMember).address.city(),
            state: oc(e9yMember).address.state() || oc(anyE9yMember).address.state(),
            zip: oc(e9yMember).address.postalCode() || oc(anyE9yMember).address.postalCode(),
          },

          service: {
            dateBegin: oc(clReq).claimInformation.serviceLines[0].serviceDate() || head(this.dates),
            dateEnd: oc(clReq).claimInformation.serviceLines[0].serviceDateEnd() || last(this.dates),
            procedureCode:
              oc(clReq).claimInformation.serviceLines[0].professionalService.procedureCode() ||
              oc(claimSet).auth.Code() ||
              oc(auth).Code(),
            unitCount:
              oc(clReq).claimInformation.serviceLines[0].professionalService.serviceUnitCount() ||
              oc(claimSet).meta.authUnits() ||
              oc(auth).AuthUnitsApproved() ||
              0,
            chargeAmount: Number(
              oc(clReq).claimInformation.serviceLines[0].professionalService.lineItemChargeAmount() || 0,
            ),
          },
        };
      }),
    );
  }

  get dates() {
    const selectedDates = sortBy(
      oc(this.data)
        .cells([])
        .map(c => moment(c.startDate).format('YYYY-MM-DD')),
      identity,
    );

    if (selectedDates.length > 1) return selectedDates;

    const date = moment(this.data.date).format('YYYY-MM-DD');

    const som = utc(date).startOf('month').format('YYYY-MM-DD');
    const eom = utc(date).endOf('month').format('YYYY-MM-DD');

    const asd = this.auth ? utc(this.auth.StartDT).format('YYYY-MM-DD') : undefined;
    const aed = this.auth ? utc(this.auth.EndDT).format('YYYY-MM-DD') : undefined;

    return [last(sortBy(compact([asd, som]), identity)), head(sortBy(compact([aed, eom]), identity))];
  }

  getActiveE9yDocs() {
    return oc(this.data)
      .member.e9y([])
      .filter(doc => oc(doc).active());
  }

  getE9yDoc$ = (claimSet: TAppMealClaimSet) => {
    let e9yDocs: IChcE9yDoc[];

    e9yDocs = [
      ...oc(claimSet)
        .e9ies([])
        .map(e => e.e9y),
      ...oc(this.data).member.e9y([]),
    ];

    e9yDocs = e9yDocs.filter(doc => oc(doc).active());

    const e9yDoc = oc(claimSet).claim.request()
      ? e9yDocs.find(d => d.e9y === oc(claimSet).claim.request.tradingPartnerServiceId())
      : e9yDocs[0];

    const key = oc(e9yDoc)._id();
    return key ? gqlMongoByKey<IChcE9yDoc>(this.dss, 'ChcEligibilityDataCache', key) : of<null>(null);
  };

  getAuth$ = () => {
    const key = oc(this.data).authId();
    return key ? gqlMongoByKey<INavinetAuthDoc>(this.dss, 'ExportsAuthsDataCache', key) : of<null>(null);
  };

  getClaimSet$ = () => {
    const authKey = oc(this.data).authId();
    const yyyymm = Number(moment(this.data.date).format('YYYYMM'));
    return authKey
      ? gqlMongoDoc<TAppMealClaimSet>(this.dss, 'MealClaims', { 'auth._id': authKey, 'meta.yyyymm': yyyymm })
      : of<null>(null);
  };

  onFieldDataChanged(e) {
    // console.log(e);

    if (['service.unitCount', 'rate'].includes(e.dataField)) {
      const amount = oc(this.form.formData).rate(0) * oc(this.form.formData).service.unitCount(0);

      this.form.instance.updateData('service.chargeAmount', amount);
      this.form.instance.updateData('chargeAmount', amount);
    }
  }

  submit() {
    // console.log(this.form.formData);

    this.ui.showLoading();
    void this.api
      .submitClaim(this.form.formData, this.validate, this.mode)
      .pipe(
        tap(() => notify('Claim has been processed', 'success', 5000)),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(claimId => this.dialogRef.close(claimId)),
      )
      .toPromise()
      .finally(() => this.ui.hideLoading());
  }
}
