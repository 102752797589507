/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-select-escort.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/devextreme-angular/ui/data-grid/devextreme-angular-ui-data-grid.ngfactory";
import * as i4 from "devextreme-angular/core";
import * as i5 from "devextreme-angular/ui/data-grid";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./dlg-select-escort.component";
import * as i12 from "../../../../shared/sdk/services/custom/logger.service";
import * as i13 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_DlgSelectEscortComponent = [i0.styles];
var RenderType_DlgSelectEscortComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgSelectEscortComponent, data: {} });
export { RenderType_DlgSelectEscortComponent as RenderType_DlgSelectEscortComponent };
export function View_DlgSelectEscortComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "dx-data-grid", [["height", "400"]], null, [[null, "onSelectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectionChanged" === en)) {
        var pd_0 = (_co.grid_onSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxDataGridComponent_0, i3.RenderType_DxDataGridComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.IterableDifferHelper, i4.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(7, 7323648, [["grid", 4]], 2, i5.DxDataGridComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.IterableDifferHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { columnChooser: [0, "columnChooser"], columns: [1, "columns"], dataSource: [2, "dataSource"], editing: [3, "editing"], filterRow: [4, "filterRow"], height: [5, "height"], hoverStateEnabled: [6, "hoverStateEnabled"], pager: [7, "pager"], paging: [8, "paging"], scrolling: [9, "scrolling"], searchPanel: [10, "searchPanel"], selection: [11, "selection"] }, { onSelectionChanged: "onSelectionChanged" }), i1.ɵqud(603979776, 1, { columnsChildren: 1 }), i1.ɵqud(603979776, 2, { sortByGroupSummaryInfoChildren: 1 }), i1.ɵpod(10, { enabled: 0 }), i1.ɵpod(11, { allowAdding: 0, allowUpdating: 1, allowDeleting: 2 }), i1.ɵpod(12, { visible: 0 }), i1.ɵpod(13, { visible: 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dialogRef.close(i1.ɵnov(_v, 18).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(18, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).dialogRef.close(i1.ɵnov(_v, 22).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(22, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Assign"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 10, 0, false); var currVal_1 = _co.gridColumns; var currVal_2 = _co.ds; var currVal_3 = _ck(_v, 11, 0, false, false, false); var currVal_4 = _co.gridFilterRow; var currVal_5 = "400"; var currVal_6 = true; var currVal_7 = _ck(_v, 12, 0, false); var currVal_8 = _co.gridPaging; var currVal_9 = _co.gridScrolling; var currVal_10 = _ck(_v, 13, 0, false); var currVal_11 = _co.gridSelection; _ck(_v, 7, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_16 = false; _ck(_v, 18, 0, currVal_16); var currVal_21 = ((i1.ɵnov(_v, 7) == null) ? null : i1.ɵnov(_v, 7).selectedRowKeys); _ck(_v, 22, 0, currVal_21); }, function (_ck, _v) { var currVal_12 = (i1.ɵnov(_v, 17).disabled || null); var currVal_13 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); var currVal_14 = (i1.ɵnov(_v, 18).ariaLabel || null); var currVal_15 = i1.ɵnov(_v, 18).type; _ck(_v, 16, 0, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_17 = (i1.ɵnov(_v, 21).disabled || null); var currVal_18 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); var currVal_19 = (i1.ɵnov(_v, 22).ariaLabel || null); var currVal_20 = i1.ɵnov(_v, 22).type; _ck(_v, 20, 0, currVal_17, currVal_18, currVal_19, currVal_20); }); }
export function View_DlgSelectEscortComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-select-escort", [], null, null, null, View_DlgSelectEscortComponent_0, RenderType_DlgSelectEscortComponent)), i1.ɵdid(1, 114688, null, 0, i11.DlgSelectEscortComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i12.LoggerService, i13.DataSourceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgSelectEscortComponentNgFactory = i1.ɵccf("app-dlg-select-escort", i11.DlgSelectEscortComponent, View_DlgSelectEscortComponent_Host_0, { ds: "ds", gridColumns: "gridColumns", gridSelection: "gridSelection", gridPaging: "gridPaging", gridFilterRow: "gridFilterRow", gridScrolling: "gridScrolling" }, {}, []);
export { DlgSelectEscortComponentNgFactory as DlgSelectEscortComponentNgFactory };
