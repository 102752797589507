import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxButtonComponent } from 'devextreme-angular/ui/button';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-button-w-drop-down',
  templateUrl: './button-w-drop-down.component.html',
  styleUrls: ['./button-w-drop-down.component.scss'],
})
export class ButtonWDropDownComponent implements OnInit {
  target: string;

  @Input() menuItems: DevExpress.ui.dxContextMenuItem[];
  @Input() text: string;
  @Input() hint: string;
  @Input() icon: string;
  @Input() processing = false;

  @Output() action: EventEmitter<any> = new EventEmitter();

  @ViewChild(DxButtonComponent, { static: true }) btn: DxButtonComponent;
  @ViewChild(DxContextMenuComponent, { static: true }) menu: DxContextMenuComponent;

  constructor(private ui: UiService) {
    this.target = this.ui.genId();
  }

  ngOnInit() {}

  ctx_onItemClick(e: any) {
    // console.log(e.itemData);
    this.action.emit(e.itemData);
  }
}
