import { Component } from '@angular/core';
import { LoggerService } from '../../../../../shared/sdk';
import { CommonService } from '../../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import { ABaseConsistencyCheckComponent } from '../a-base-consistency-check.component';

@Component({
  selector: 'app-consistency-check2',
  templateUrl: './consistency-check2.component.html',
  styleUrls: ['./consistency-check2.component.scss'],
})
export class ConsistencyCheck2Component extends ABaseConsistencyCheckComponent {
  protected mongoSchema: any = { 'service date': 'datetime', '_claim._ctime': 'datetime', '_claim._rtime': 'datetime' };
  protected checkIdx = 2;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    public config: ConfigService,
    protected pusher: PusherService,
    protected notification: PushNotificationsService,
    protected gridHelper: GridHelperService,
    protected common: CommonService,
    protected dss: DataSourceService,
  ) {
    super(logger, ui, pusher, common, dss, gridHelper);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b9f5a009-f44e-4890-b52c-42ef5f1c4fa6',
    };
  }

  grid_onToolbarPreparing(e) {}

  grid_onContextMenuPreparing(e) {}

  grid_onCellPrepared(e) {}
}
