import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(value: string[], glue: string = ','): string {
    return value && value.length ? value.join(glue) : '';
  }
}
