/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./offline-overlay.component";
var styles_OfflineOverlayComponent = [i0.styles];
var RenderType_OfflineOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineOverlayComponent, data: { "animation": [{ type: 7, name: "visible", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: null, timings: "100ms linear" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: "100ms linear" }], options: null }], options: {} }] } });
export { RenderType_OfflineOverlayComponent as RenderType_OfflineOverlayComponent };
export function View_OfflineOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[24, "@visible", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["connecting to server..."]))], function (_ck, _v) { var currVal_4 = "accent"; var currVal_5 = 60; _ck(_v, 2, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_OfflineOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offline-overlay", [], null, null, null, View_OfflineOverlayComponent_0, RenderType_OfflineOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i7.OfflineOverlayComponent, [], null, null)], null, null); }
var OfflineOverlayComponentNgFactory = i1.ɵccf("app-offline-overlay", i7.OfflineOverlayComponent, View_OfflineOverlayComponent_Host_0, {}, {}, []);
export { OfflineOverlayComponentNgFactory as OfflineOverlayComponentNgFactory };
