<app-content-w-header header="ADC Attendance Stats">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="display: flex">
      <!--      <dx-toolbar>-->
      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>
      <!--        </dxi-item>-->

      <div class="dx-fieldset" style="display: flex">
        <dx-select-box
          width="150px"
          [dataSource]="[
            { t: 'Show All Clients', v: 'ALL' },
            { t: 'Show clients attended', v: 'ATTND' }
          ]"
          displayExpr="t"
          valueExpr="v"
          [(value)]="showAllOrAttended"></dx-select-box>
      </div>

      <!--        <dxi-item location="after" locateInMenu="auto">-->
      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
      <!--        </dxi-item>-->

      <div
        style="display: inline-block"
        *ngIf="!!attendanceReportingModeMap[config?.config?.adcAttendanceReportingMode]">
        <dx-button
          [icon]="adcAttendanceProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="adcAttendanceProgress"
          [text]="attendanceReportingModeMap[config?.config?.adcAttendanceReportingMode].lbl"
          (onClick)="
            getAdcAttendancePdf($event, attendanceReportingModeMap[config?.config?.adcAttendanceReportingMode].settings)
          "></dx-button>
      </div>

      <div style="display: inline-block" *ngIf="!!config?.config?.adcAttendanceSummary">
        <dx-button
          [icon]="adcAttendance1ExcelProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-excel'"
          [disabled]="adcAttendance1ExcelProgress"
          [text]="'ADC Attendance'"
          (onClick)="getAdcAttendanceStatsGrid($event, false)"></dx-button>
      </div>

      <div style="display: inline-block" *ngIf="!!config?.config?.adcAttendanceSummary$">
        <dx-button
          [icon]="adcAttendance2ExcelProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-excel'"
          [disabled]="adcAttendance2ExcelProgress"
          [text]="'ADC Attendance ($)'"
          (onClick)="getAdcAttendanceStatsGrid($event, true)"></dx-button>
      </div>

      <div
        style="display: inline-block"
        *ngIf="mealsReportEnabled('cacfp_daily_v2')"
      >
        <dx-button
          [icon]="cacfpDailyProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="cacfpDailyProgress"
          text="Daily CACFP - V/X/W"
          (onClick)="getDailyCacfpPdf(2)"></dx-button>
      </div>

      <div
        style="display: inline-block"
        *ngIf="mealsReportEnabled('cacfp_daily_v3')"
      >
        <dx-button
          [icon]="cacfpDailyProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="cacfpDailyProgress"
          text="Daily CACFP - 1/2/3"
          (onClick)="getDailyCacfpPdf(3)"></dx-button>
      </div>

      <!--      </dx-toolbar>-->
    </div>

    <div>
      <span style="flex: auto"></span>

      <div>
        <dx-check-box text="Show Column Totals" [(value)]="showColumnTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Column Grand Totals" [(value)]="showColumnGrandTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box>
      </div>
    </div>
  </mat-card>

  <dx-pivot-grid
    class="content"
    width="100%"
    height="100%"
    [dataSource]="dso"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showColumnTotals]="showColumnTotals"
    [showRowTotals]="showRowTotals"
    [showColumnGrandTotals]="showColumnGrandTotals"
    [showRowGrandTotals]="showRowGrandTotals"
    [stateStoring]="grid_stateStoring"
    (onCellPrepared)="onCellPrepared($event)"
    (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true" [fileName]="exportFileName"></dxo-export>

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true">
    </dxo-field-panel>

    <dxo-scrolling [useNative]="true"></dxo-scrolling>
  </dx-pivot-grid>
</app-content-w-header>
