import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment, { duration } from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';

@Component({
  selector: 'app-vehicle-details-tab-notes',
  templateUrl: './vehicle-details-tab-notes.component.html',
  styleUrls: ['./vehicle-details-tab-notes.component.scss'],
  providers: [],
})
export class VehicleDetailsTabNotesComponent extends ABaseComponent {
  filter: any;
  objectType: string;
  objectId: number;
  @Input()
  set vehicle(v: Vehicle) {
    if (v) this.filter = { vehicleId: v.id };
    this.objectType = Vehicle.getModelName();
    this.objectId = v.id;
  }

  constructor(protected logger: LoggerService) {
    super(logger);
  }
}
