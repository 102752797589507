import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi, TCustomHeaders, TCustomOptions } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeePosition } from '../../models/EmployeePosition';
import { Employee } from '../../models/Employee';


/**
 * Api services for the `EmployeePosition` model.
 */
@Injectable()
export class EmployeePositionApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Find a related item by id for employees.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {any} fk Foreign key for employees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public findByIdEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for employees.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {any} fk Foreign key for employees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for employees.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {any} fk Foreign key for employees
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public updateByIdEmployees(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries employees of EmployeePosition.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public getEmployees(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in employees of this model.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public createEmployees(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all employees of this model.
   *
   * @param {any} id EmployeePosition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteEmployees(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts employees of EmployeePosition.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countEmployees(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Persist model entity with related entities
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public myCreateWithRelated(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/myCreateWithRelated";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check wheres filters and update/delete. all in one transaction. Stop after first filter model exists.
   *
   * @param {object} data Request data.
   *
   *  - `wheres` – `{any}` - filter array
   *
   *  - `data` – `{any}` - data array according to filter array order. null - for delete, data object - for update.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public checkModifyDelete(wheres: any = {}, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/checkModifyDelete";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        wheres: wheres,
        data: data
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get model related tenant
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} filter 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public myGetTenant(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/myGetTenant";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update model entity with related entities
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public mySaveWithRelated(id: any, data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/mySaveWithRelated";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in employees of this model.
   *
   * @param {any} id EmployeePosition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `EmployeePosition` object.)
   * </em>
   */
  public createManyEmployees(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/EmployeePositions/:id/employees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeePosition`.
   */
  public getModelName() {
    return "EmployeePosition";
  }
}
