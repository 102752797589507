<dx-box direction="col">
  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
    <dx-toolbar [items]="toolbarItems"></dx-toolbar>
  </dxi-item>

  <dxi-item [ratio]="1" [baseSize]="'auto'">
    <app-employee-ext-details-tabs [modelId]="modelId"></app-employee-ext-details-tabs>
  </dxi-item>
</dx-box>

