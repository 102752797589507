<app-content-w-header header="ADC Closed Days">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <dx-select-box
        [dataSource]="['Any', 'Open', 'Close']"
        [(value)]="dayStatus"
        placeholder="Any Status"
        [showClearButton]="true"></dx-select-box>

      <dx-select-box
        [dataSource]="facilityDso$ | async"
        displayExpr="name"
        valueExpr="id"
        [(value)]="facilityId"
        placeholder="All Facilities"
        [showClearButton]="true"></dx-select-box>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>
  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="dso$ | async"
    [stateStoring]="grid_stateStoring"
    [repaintChangesOnly]="true"
    [errorRowEnabled]="true"
    [searchPanel]="{ visible: false }"
    [headerFilter]="{ visible: true }"
    [filterRow]="{ visible: true }"
    [filterPanel]="{ visible: true }"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    (onCellPrepared)="grid_onCellPrepared($event)">
    <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

    <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

    <dxo-selection mode="none"></dxo-selection>

    <!--    <dxo-export-->
    <!--      [enabled]="true"-->
    <!--      fileName="claims-export"-->
    <!--    ></dxo-export>-->

    <!-- region Columns -->

    <dxi-column caption="Facility" dataField="tenantId" dataType="string" [fixed]="true" fixedPosition="left">
      <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="name"> </dxo-lookup>
    </dxi-column>

    <dxi-column *ngFor="let date of dates" caption="{{ date }}" [dataField]="date" cellTemplate="cellTpl"></dxi-column>

    <!--    <dxi-column caption="Marker"-->
    <!--                dataField="marker"-->
    <!--                dataType="string"-->
    <!--    ></dxi-column>-->

    <!--    <dxi-column caption="Value"-->
    <!--                dataField="value"-->
    <!--                dataType="string"-->
    <!--    ></dxi-column>-->

    <!-- endregion Columns -->

    <!-- region Templates -->

    <ng-container *dxTemplate="let cellInfo of 'cellTpl'">
      <ng-container *ngIf="cellInfo.data[cellInfo.column.dataField]">
        <div [title]="cellInfo.data[cellInfo.column.dataField].marker">
          <small>{{ cellInfo.data[cellInfo.column.dataField].marker }}</small>
        </div>
        <div [title]="cellInfo.data[cellInfo.column.dataField].value">
          <small>{{ cellInfo.data[cellInfo.column.dataField].value }}</small>
        </div>
      </ng-container>
    </ng-container>

    <!-- endregion Templates -->
  </dx-data-grid>
</app-content-w-header>
