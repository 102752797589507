<div mat-dialog-title style="display: flex">
  <span>
    <app-object-title [modelName]="'Employee'" [modelId]="employeeId"></app-object-title>
    [<app-object-title [modelName]="'MyUser'" [modelId]="employee?.userId"></app-object-title>]
  </span>

  <span style="flex: auto"></span>

  <button
    mat-icon-button
    type="button"
    [mat-dialog-close]="false"
    matTooltip="Close"
    color="primary"
    style="margin: -1em">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="dx-fieldset">
    <label>Assign To</label>

    <dx-tag-box
      #facilityTagBox
      [dataSource]="facilityDso"
      [value]="assignedFacilities"
      valueExpr="id"
      displayExpr="shortname"
      itemTemplate="facilityItem"
      [grouped]="true"
      [showSelectionControls]="true"
      [disabled]="tagBoxDisabled"
      (onInitialized)="tagBox_onInitialized($event)"
    >
      <div *dxTemplate="let f of 'facilityItem'">
        <strong>{{ f.name }}</strong> [{{ f.type }}: {{ f.shortname }}]
      </div>
    </dx-tag-box>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="assignFacilities()">Assign</button>
  <button mat-button (click)="resetFacilities()">Reset</button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>
