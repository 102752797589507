<app-content-w-header header='Task Manager'>
  <dx-data-grid
    class='content'
    #grid
    height='100%'
    width='100%'
    [dataSource]='dso'
    [stateStoring]='grid_stateStoring'

    [repaintChangesOnly]='true'
    [columnAutoWidth]='true'
    [showBorders]='true'

    (onInitialized)='grid_onInitialized($event)'
    (onToolbarPreparing)='grid_onToolbarPreparing($event)'
    (onCellPrepared)='grid_onCellPrepared($event)'
    (onRowRemoving)='grid_onRowRemoving($event)'
  >
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing
      mode='cell'
      [allowAdding]='false'
      [allowUpdating]='false'
      [allowDeleting]='true'
      [useIcons]='true'
    ></dxo-editing>

    <dxo-master-detail [enabled]='true' template='detail'></dxo-master-detail>

    <!-- region Columns -->
    <dxi-column type="buttons" caption="actions" [showInColumnChooser]="false"></dxi-column>

    <dxi-column dataField='job' dataType='string'></dxi-column>

    <dxi-column dataField='abort' dataType='boolean'></dxi-column>

    <dxi-column dataField='ttl' dataType='string'></dxi-column>

    <dxi-column caption='User' dataField='userId' dataType='number'>
      <dxo-lookup [dataSource]='usersDso$ | async' displayExpr='username' valueExpr='id'></dxo-lookup>
    </dxi-column>

    <dxi-column dataField='datetime'
                dataType='date'
                format='shortDateShortTime'
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
    ></dxi-column>

    <dxi-column dataField='ago' dataType='string'></dxi-column>

    <!--  endregion  -->

    <!-- region Templates -->

    <div *dxTemplate="let info of 'detail'"><pre>{{ info?.data?.args | json }}</pre></div>

    <!--  endregion  -->

  </dx-data-grid>
</app-content-w-header>
