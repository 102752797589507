import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import mapKeys from 'lodash-es/mapKeys';
import { oc } from 'ts-optchain';
import { LoggerService, Signature } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-edit-units',
  templateUrl: './dlg-edit-units.component.html',
  styleUrls: ['./dlg-edit-units.component.scss'],
})
export class DlgEditUnitsComponent implements OnInit, AfterViewInit {
  value: { [key: string]: number };

  codeMealTypeMap = {
    HOT: 'W1759',
    FROZEN: 'W1760',
    SANDWICH: 'W1761',
    EMERGENCY: 'W1762',
    SPECIAL: 'W1764',
  };

  constructor(
    private dialogRef: MatDialogRef<DlgEditUnitsComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      signature?: Signature;
    },
    protected logger: LoggerService,
  ) {
    this.value = mapKeys(oc(data).signature.meta.mealDroppedCountList({}), (v, k) => this.codeMealTypeMap[k] || k);
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}
}
