
declare var Object: any;
export interface MyJobInterface {
  "id"?: string;
}

export class MyJob implements MyJobInterface {
  "id": string;
  constructor(data?: MyJobInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyJob`.
   */
  public static getModelName() {
    return "MyJob";
  }

  /**
  * This method creates an instance of MyJob for dynamic purposes.
  **/
  public static factory(data: MyJobInterface): MyJob{
    return new MyJob(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyJob',
      plural: 'MyJob',
      path: '/j',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
