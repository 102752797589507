import {
  Document,
  MyUser
} from '../index';

declare var Object: any;
export interface MyFileInterface {
  "container": string;
  "originalFilename": string;
  "filename": string;
  "uploadedAt"?: Date;
  "dtime"?: Date;
  "id"?: number;
  "documentId"?: number;
  "uploadedById"?: number;
  document?: Document;
  user?: MyUser;
}

export class MyFile implements MyFileInterface {
  "container": string;
  "originalFilename": string;
  "filename": string;
  "uploadedAt": Date;
  "dtime": Date;
  "id": number;
  "documentId": number;
  "uploadedById": number;
  document: Document;
  user: MyUser;
  constructor(data?: MyFileInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyFile`.
   */
  public static getModelName() {
    return "MyFile";
  }

  /**
  * This method creates an instance of MyFile for dynamic purposes.
  **/
  public static factory(data: MyFileInterface): MyFile{
    return new MyFile(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyFile',
      plural: 'MyFiles',
      path: 'MyFiles',
      idName: 'id',
      properties: {
        "container": {
          name: 'container',
          type: 'string'
        },
        "originalFilename": {
          name: 'originalFilename',
          type: 'string'
        },
        "filename": {
          name: 'filename',
          type: 'string'
        },
        "uploadedAt": {
          name: 'uploadedAt',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "documentId": {
          name: 'documentId',
          type: 'number'
        },
        "uploadedById": {
          name: 'uploadedById',
          type: 'number'
        },
      },
      relations: {
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'uploadedById',
          keyTo: 'id'
        },
      }
    }
  }
}
