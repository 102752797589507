import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import notify from 'devextreme/ui/notify';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import { Employee, LoggerService, TripManifest } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { REASSIGN_MODE } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-toolbar-reassign-btn',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './toolbar-reassign-btn.component.html',
  styleUrls: ['./toolbar-reassign-btn.component.scss'],
})
export class ToolbarReassignBtnComponent implements OnChanges {
  ctxMenuItems: any[];
  popupVisible = false;
  popup2Visible = false;
  selectedAction: string;

  tripNumber: number;

  driversDSO: DataSourceOptions;
  gridColumns = [
    { dataField: 'person_firstname', caption: 'FirstName' },
    { dataField: 'person_lastname', caption: 'LastName' },
    { dataField: 'person_nickname', caption: 'Nickname' },
  ];

  @Input() manifest: TripManifest;
  @Input() selectedDriverId: number | string;
  @Input() grid: DxDataGridComponent;

  processing = false;

  @Output() processingChange: EventEmitter<boolean> = new EventEmitter();
  @Output() reassigning: EventEmitter<any> = new EventEmitter();
  @Output() reassigned: EventEmitter<any> = new EventEmitter();

  constructor(protected logger: LoggerService, public helper: HelperService, private dss: DataSourceService) {
    this.buildRelatedDs(this.selectedDriverId);
    this.buildContextMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedDriverId' in changes) {
      this.buildRelatedDs(this.selectedDriverId);
    }

    if (['manifest', 'selectedDriverId', 'grid'].some(p => p in changes)) {
      this.buildContextMenu();
    }
  }

  ctx_onAction(data) {
    this.selectedAction = data.id;

    if (this.selectedAction === REASSIGN_MODE.SET_TRIP_NUM) {
      this.popup2Visible = true;
    } else {
      this.popupVisible = true;
    }
  }

  setTrip(num) {
    this.popup2Visible = false;
    const recIds = this.grid && this.grid.selectedRowKeys;

    if (!recIds || !recIds.length) {
      notify('No items selected', 'error', 3000);
      return;
    }

    this.setTripRecordsAsync(recIds, num).catch(err => notify(err.message || err, 'error', 3000));
  }

  popup_onSelectionChanged(ids: any[]) {
    let from;
    const target = head<number>(ids);
    switch (this.selectedAction) {
      case REASSIGN_MODE.FROM_EMPLOYEE: {
        from = this.selectedDriverId;
        if (!from) {
          notify('No from driver selected', 'error', 3000);
          return;
        }
        break;
      }
      case REASSIGN_MODE.SELECTED: {
        from = this.grid && this.grid.selectedRowKeys;
        if (!from || !from.length) {
          notify('No items selected', 'error', 3000);
          return;
        }
        break;
      }
    }

    this.reassignRecordsAsync(from, target).catch(err => notify(err.message || err, 'error', 3000));
  }

  private async reassignRecordsAsync(from: any, toEmployeeId: number): Promise<void> {
    try {
      this.processing = true;
      this.processingChange.emit(true);
      this.reassigning.emit();

      await this.helper.api.reassignManifest(this.manifest.id, from, toEmployeeId).toPromise();
    } catch (err) {
      notify(err.message || err, 'error', 3000);
    } finally {
      setTimeout(() => {
        this.processing = false;
        this.processingChange.emit(false);
        this.reassigned.emit();
      });
    }
  }

  private async setTripRecordsAsync(recIds: any[], tripNumber: number): Promise<void> {
    try {
      this.processing = true;
      this.processingChange.emit(true);
      this.reassigning.emit();

      await this.helper.api.setTripNumber(this.manifest.id, recIds, tripNumber).toPromise();
    } catch (err) {
      notify(err.message || err, 'error', 3000);
    } finally {
      setTimeout(() => {
        this.processing = false;
        this.processingChange.emit(false);
        this.reassigned.emit();
      });
    }
  }

  private buildRelatedDs(employeeId: number | string): void {
    this.driversDSO = {
      store: this.dss.getStore(Employee, null, false),
      filter: [
        this.helper.buildDriversFilter('employeePosition_name'),
        ['status', '=', 'ACTIVE'],
        employeeId ? ['id', '<>', employeeId] : undefined,
      ],
    };
  }

  private buildContextMenu() {
    this.ctxMenuItems = [
      {
        text: 'Reassign driver trips',
        icon: 'fas fa-share',
        id: REASSIGN_MODE.FROM_EMPLOYEE,
        disabled: !get(this.manifest, 'id') || !this.selectedDriverId,
      },
      {
        text: 'Reassign selected trips',
        icon: 'fas fa-share',
        id: REASSIGN_MODE.SELECTED,
        disabled: false,
        // disabled: !_get(this.manifest, 'id') || (this.grid && this.grid.instance.getSelectedRowKeys().length),
      },
      {
        text: 'Set trip number',
        icon: 'fas fa-any',
        id: REASSIGN_MODE.SET_TRIP_NUM,
        disabled: false,
      },
    ];
  }
}
