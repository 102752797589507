<div class="dx-fieldset">
  <div class="dx-field">
    <dx-toolbar>
      <dxi-item text="From" [location]="'before'" [template]="'startDateTpl'"></dxi-item>
      <dxi-item text="-" [location]="'before'"></dxi-item>
      <dxi-item text="To" [location]="'before'" [template]="'endDateTpl'"></dxi-item>

      <!---->

      <ng-container *dxTemplate="let itemData of 'startDateTpl'">
        <dx-date-box [(value)]="selectedStartValue"></dx-date-box>
      </ng-container>

      <ng-container *dxTemplate="let itemData of 'endDateTpl'">
        <dx-date-box [(min)]="selectedStartValue" [(value)]="selectedEndValue"></dx-date-box>
      </ng-container>
    </dx-toolbar>
  </div>

  <div class="dx-field">
    <app-consumer-drop-down-grid [(value)]="consumerId"></app-consumer-drop-down-grid>
  </div>

  <div class="dx-field">
    <dx-button text="Cancel" (onClick)="cancel($event)"></dx-button>
    <dx-button text="Submit" type="default" (onClick)="submit($event)"></dx-button>
  </div>
</div>
