import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
//
import { Facility } from '../../../../shared/sdk/models';
import { ConsumerApi, LoggerService } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-assign-from-broker',
  templateUrl: './assign-from-broker.component.html',
  styleUrls: ['./assign-from-broker.component.scss'],
})
export class AssignFromBrokerComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  selectedDate: Date = new Date();
  private $selectedDate$: BehaviorSubject<Date> = new BehaviorSubject(this.selectedDate);

  private store: ArrayStore = new ArrayStore();
  dso$: Observable<ArrayStore> = of(this.store);
  facilityDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: ConsumerApi,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('70682d4a-9a14-499b-815c-a1d1d81d0502');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '5123ef26-0634-4c08-9d4b-98d2c9613a13',
    };
  }

  ngOnInit() {
    this.dso$ = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  dateBox_onValueChanged(e) {
    setTimeout(() => {
      this.selectedDate = e.value;
      this.$selectedDate$.next(this.selectedDate);
    });
  }

  validateBtn_onClick(e) {
    const date = moment(this.selectedDate).format('YYYY-MM-DD');
    // this.api.validateBrokerData(date)
    //   .toPromise()
    //   .catch((err) => notify(err.message || err, 'error', 5000));
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        name: 'selectDate',
        locateInMenu: 'auto',
        widget: 'dxDateBox',
        location: 'before',
        sortIndex: 10,
        showText: 'inMenu',
        options: {
          value: this.selectedDate,
          onValueChanged: this.dateBox_onValueChanged.bind(this),
        },
      },
      {
        name: 'callToValidate',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'before',
        sortIndex: 10,
        showText: 'always',
        options: {
          text: 'Validate Broker`s Data',
          hint: 'Validates Broker`s trips data and send report to manager',
          onClick: this.validateBtn_onClick.bind(this),
        },
      },
    );
  }

  private buildDataSource() {
    return null;
    //     return this.$selectedDate$.pipe(
    //       tap(() => this.common.incPending()),
    //       map((date) => moment(date).format('YYYY-MM-DD')),
    //       switchMap((date) => this.api.getUnassignedFromBroker(date).pipe(
    //         catchError((err) => {
    //           notify(err.message || err, 'error', 5000);
    //           return of([]);
    //         }))
    //       ),
    //       map((data: any[]) => {
    //         console.log(data);
    //
    //         data.forEach((rec) => {
    //           rec._id = rec.source + '_' + rec.mci;
    //
    //           (rec as any).getAddress = (function () {
    //             const address = get(this, 'person.contact.addresses.0', {});
    //             return ['street', 'city', 'state', 'zip']
    //               .map((k) => address[k])
    //               .filter((v) => !isEmpty(v))
    //               .join(', ');
    //           }).bind(rec);
    //
    //           (rec as any).getPhone = (function () {
    //             return (get(this, 'person.contact.phones.0.value'));
    //           }).bind(rec);
    //         });
    //
    //         this.store = new ArrayStore({
    //           key: '_id',
    //           data: data,
    //         });
    //
    //         dxStoreUpdateHooks(this.store,
    //           async (key: any, values: any) => {
    // //            console.log('before: ', [key, values]);
    //
    //             const [rec] = await deferredAsPromise(this.store.byKey(key));
    //
    //             const id = await this.api.assignFromBroker({...rec, ...values}).toPromise();
    //
    //             if (!id) {
    //               throw new Error('Update failed');
    //             }
    //
    //             return [key, values];
    //           },
    //           async ([key, values], [resValues, resKey]) => {
    // //            console.log('after: ', [resValues, resKey]);
    //
    //             setTimeout(() => this.store.remove(key));
    //
    //             return [resValues, resKey];
    //           }
    //         );
    //
    //         return this.store;
    //       }),
    //       tap(() => this.common.decPending()),
    //     );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'name' }],
    } as DataSourceOptions;
    return of(dso);
  }
}
