import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxTabPanelComponent } from 'devextreme-angular/ui/tab-panel';

@Component({
  selector: 'app-vehicle-details-tabs',
  templateUrl: './vehicle-details-tabs.component.html',
  styleUrls: ['./vehicle-details-tabs.component.scss'],
})
export class VehicleDetailsTabsComponent implements OnInit {
  selectedIndex = 0;
  tabs: any[] = [
    {
      title: 'Info',
      template: 'summary',
    },
    {
      title: 'Notes',
      template: 'notes',
    },
    {
      title: 'Activity',
      template: 'activity',
    },
    {
      title: 'Trips History',
      template: 'routes',
    },
    {
      title: 'Specs',
      template: 'specs',
    },
    {
      title: 'Equipment',
      template: 'equipment',
    },
    {
      title: 'Docs',
      template: 'document',
    },
    {
      title: 'Maintenance Records',
      template: 'maintenanceRecs',
    },
    {
      title: 'Financials',
      template: 'financials',
    },
    {
      title: 'Claims',
      template: 'claims',
    },
  ];

  @Input() modelId: number | string;

  @ViewChild('tabPanel', { static: true }) tabPanel: DxTabPanelComponent;

  constructor() {}

  ngOnInit() {}

  repaint() {
    this.tabPanel.instance.repaint();
  }
}
