import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
//
import { Facility, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { FACILITY_TYPES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import { FacilityFormComponent } from '../facility-form/facility-form.component';
const ɵ0 = window;
export class FacilityGridComponent {
    constructor(logger, config, ui, dss, sss, helper, gridHelper, dialog, window) {
        this.logger = logger;
        this.config = config;
        this.ui = ui;
        this.dss = dss;
        this.sss = sss;
        this.helper = helper;
        this.gridHelper = gridHelper;
        this.dialog = dialog;
        this.window = window;
        this.types = FACILITY_TYPES;
        // form: any;
        this.mySelected = new EventEmitter();
        // this.grid_stateStoring = this.sss.buildOptions('0e6b343b-34cd-41c9-af4a-bac9127ba238');
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '0bad34ad-cb16-434c-87dd-3c9f9083c1da',
        };
        this.dso = this.dss.getDataSourceOptions(Facility);
        // this.buildForm();
        // this.dataSource.on({
        //   'changed': (e) => console.log('changed', e),
        //   'loadError': (e) => console.log('loadError', e),
        // });
        //
        // this.dataSource.store().on({
        //   'inserted': (e) => console.log('inserted', e),
        //   'loaded': (e) => console.log('loaded', e),
        //   'modified': (e) => console.log('modified', e),
        //   'removed': (e) => console.log('removed', e),
        //   'updated': (e) => console.log('updated', e),
        // });
    }
    ngOnInit() {
        this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
            if ([Facility.getModelName()].includes(modelName)) {
                if (this.grid) {
                    this.grid.instance.refresh();
                }
            }
        });
    }
    ngOnDestroy() {
        this.modifiedSubscription.unsubscribe();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
    }
    grid_onDataErrorOccurred(e) {
        notify(e.error, 'error', 3000);
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            name: 'newFacility',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'after',
            sortIndex: 30,
            showText: 'inMenu',
            options: {
                icon: 'fas fa-plus',
                text: 'New facility',
                hint: 'Create new facility',
                onClick: this.toolbarNew_onClick.bind(this),
            },
        });
    }
    grid_onEditingStart(e) {
        e.cancel = true;
        // this.logger.log(e);
        const title = `Edit: ${this.helper.displayExpr(e.data)}`;
        // this.form.items[0].caption = title;
        this.ui.openEditDialog({
            modelId: e.key,
            ModelClass: Facility,
            FormComponentClass: FacilityFormComponent,
            title,
        });
    }
    toolbarNew_onClick() {
        this.ui.openEditDialog({
            modelId: null,
            ModelClass: Facility,
            FormComponentClass: FacilityFormComponent,
        });
    }
}
export { ɵ0 };
