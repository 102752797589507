<dx-box direction="col" height="100%" width="100%">
  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'" style="z-index: 1">
    <div class="dx-swatch-material" style="background-color: transparent" *ngIf="header">
      <h2>
        <a mat-icon-button matTooltip="Go Back" (click)="backClick()" *ngIf="hasBack">
          <mat-icon>arrow_back</mat-icon> </a
        ><span>{{ header }}</span>
      </h2>
    </div>

    <ng-content select=".subheader"></ng-content>
  </dxi-item>

  <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
    <div class="wrapper">
      <div class="content">
        <ng-content select=".content"></ng-content>
      </div>
    </div>
  </dxi-item>
</dx-box>
