/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-perf-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-perf-grid/employee-perf-grid.component.ngfactory";
import * as i3 from "../../../services/helper.service";
import * as i4 from "../../../../../shared/sdk/services/custom/Employee";
import * as i5 from "../../../../../shared/sdk/services/custom/EmployeeView";
import * as i6 from "../../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../../shared/sdk/services/custom/Config";
import * as i10 from "../../../../../shared/sdk/services/custom/Facility";
import * as i11 from "../../../../../shared/sdk/services/custom/MyFile";
import * as i12 from "../../../../../shared/modules/my-common/services/datasource.service";
import * as i13 from "../employee-perf-grid/employee-perf-grid.component";
import * as i14 from "../../../../../shared/sdk/services/custom/logger.service";
import * as i15 from "../../../../../shared/modules/ui/services/ui.service";
import * as i16 from "../../../../../shared/modules/my-common/services/state-store.service";
import * as i17 from "../../../../../shared/modules/ui/services/grid-helper.service";
import * as i18 from "@angular/material/dialog";
import * as i19 from "./employee-perf-list.component";
var styles_EmployeePerfListComponent = [i0.styles];
var RenderType_EmployeePerfListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePerfListComponent, data: {} });
export { RenderType_EmployeePerfListComponent as RenderType_EmployeePerfListComponent };
export function View_EmployeePerfListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-employee-perf-grid", [], null, [[null, "mySelected"], [null, "mySelectedRange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("mySelectedRange" === en)) {
        var pd_1 = (_co.date_onSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EmployeePerfGridComponent_0, i2.RenderType_EmployeePerfGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.EmployeeApi, i5.EmployeeViewApi, i6.VehicleApi, i7.ConfigService, i8.CommonService, i9.ConfigApi, i10.FacilityApi, i11.MyFileApi, i12.DataSourceService]), i1.ɵdid(2, 245760, null, 0, i13.EmployeePerfGridComponent, [i14.LoggerService, i7.ConfigService, i8.CommonService, i15.UiService, i12.DataSourceService, i16.StateStoreService, i3.HelperService, i17.GridHelperService, i18.MatDialog], null, { mySelected: "mySelected", mySelectedRange: "mySelectedRange" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EmployeePerfListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-perf-list", [], null, null, null, View_EmployeePerfListComponent_0, RenderType_EmployeePerfListComponent)), i1.ɵdid(1, 114688, null, 0, i19.EmployeePerfListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeePerfListComponentNgFactory = i1.ɵccf("app-employee-perf-list", i19.EmployeePerfListComponent, View_EmployeePerfListComponent_Host_0, {}, { mySelected: "mySelected", mySelectedRange: "mySelectedRange" }, []);
export { EmployeePerfListComponentNgFactory as EmployeePerfListComponentNgFactory };
