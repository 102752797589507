import { Component, Inject, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Vehicle, MyUser } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import CustomStore from 'devextreme/data/custom_store';
import { AutoRepairShop, Employee, EmployeeView, LoopBackAuth } from 'src/app/shared/sdk';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { Observable, of } from 'rxjs';
import { LoopBackStoreOptions } from 'src/app/shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';

@Component({
  selector: 'app-vehicle-details-tab-notes',
  templateUrl: './vehicle-details-tab-notes.component.html',
  styleUrls: ['./vehicle-details-tab-notes.component.scss'],
  providers: [],
})
export class VehicleDetailsTabNotesComponent extends ABaseComponent {
  @Input() templateFormTop?: TemplateRef<any>;
  @Input() templateFormBottom?: TemplateRef<any>;

  filter: any;
  @Input() extraFields: any;
  objectType: string;
  objectId: number;
  subTitle: string;
  @Input()
  set vehicle(v: Vehicle) {
    if (v) {
      this.filter = { vehicleId: v.id };
      this.objectType = Vehicle.getModelName();
      this.objectId = v.id;
      this.subTitle = ' for vehicle #' + v.internalId;
    }
  }

  constructor(protected logger: LoggerService) {
    super(logger);
  }

  autoRepairShop_displayExpr(e: AutoRepairShop) {
    return (e && `${e.name}`) || '';
  }

  assignedFleetManager_displayExpr(e: EmployeeView) {
    return (e && `${e.person_firstname} ${e.person_lastname}`) || '';
  }
}

