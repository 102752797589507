export const VEHICLE_STATUSES: any[] = [
  { ID: 'ACTIVE', Name: 'ACTIVE' },
  { ID: 'INACTIVE', Name: 'INACTIVE' },
];

export const BASE_COLUMNS = [
  { dataField: 'internalId', caption: 'Vehicle ID', dataType: 'number' },
  {
    dataField: 'state',
    caption: 'State',
    dataType: 'string',
    lookup: { dataSource: VEHICLE_STATUSES, valueExpr: 'Name', displayExpr: 'ID' },
  },
  { dataField: 'status', caption: 'Status', dataType: 'string' },
  { dataField: 'year', caption: 'Year', dataType: 'number' },
  { dataField: 'make', caption: 'Make', dataType: 'string' },
  { dataField: 'model', caption: 'Model', dataType: 'string' },
  { dataField: 'grossWeight', caption: 'Gross Weight', dataType: 'number' },
  { dataField: 'totalDistance', caption: 'Period Mileage', dataType: 'number' },
  { dataField: 'totalSignatures', caption: 'Period Transports', dataType: 'number' },
  { dataField: 'activeGeoDays', caption: 'Active Days By Geotab', dataType: 'number' },
  { dataField: 'activeSignDays', caption: 'Active Usage Days By Signature', dataType: 'number' },
  { dataField: 'lastDate', caption: 'Last Date On Duty', dataType: 'date' },
];
