import { AfterViewInit, Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DxLookupComponent } from 'devextreme-angular/ui/lookup';
import DevExpress from 'devextreme/bundles/dx.all';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
//
import {
  Consumer,
  ConsumerView,
  Document,
  DocumentType,
  Employee,
  EmployeeView,
  Facility,
  LoggerService,
  MyUserApi,
  Person,
  Vehicle,
} from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { getFQNByModelName, getModelByFQN } from '../../../../shared/modules/ui/components/custom-fields/utils';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';
import { ABaseDocumentFormComponentWithCustomsFields } from '../../classes/a-base-document-form-with-cf.component';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { ObjectTitleComponent } from 'src/app/shared/modules/ui/components/object-title/object-title.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
  providers: [HelperService],
})
export class DocumentFormComponent
  extends ABaseDocumentFormComponentWithCustomsFields
  implements OnChanges, OnDestroy, AfterViewInit
{
  documentTypeDataSource: DataSource;
  documentTypeDisplayExpr: any;
  documentTypeSearchExpr: any;
  objectDS: DataSource;

  objectDisplayExpr: any;
  objectSearchExpr: any;

  documentTypeName = '';
  objectName = '';

  @ViewChild('objectLookup', { static: false }) objectLookup: DxLookupComponent;
  @ViewChild('objectTitle', { static: false }) objectTitle: ObjectTitleComponent;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected uploadHelper: UploadHelperService,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Document>,
    public docHelper: HelperService,
    protected myUserApi: MyUserApi,
  ) {
    super(logger, fb, uploadHelper, dss, helper);

    this.buildDataSources();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.objectTitle)
      this.objectTitle.objectTitle$.subscribe(title => {
        if (title && title !== '...') {
          this.objectName = title;
          this.setName();
        }
      });
  }

  private _objectId: number;
  get objectId(): number {
    return this._objectId;
  }

  @Input()
  set objectId(value: number) {
    this._objectId = value;
    this.form && this.form.get('objectId').reset(value);
  }

  private _objectType: string;
  get objectType(): string {
    return this._objectType;
  }

  @Input()
  set objectType(value: string) {
    this._objectType = value;
    this.form && this.form.get('objectType').reset(value);
    this.buildDataSources();
  }

  private _documentTypeId: number;
  get documentTypeId(): number {
    return this._documentTypeId;
  }
  @Input()
  set documentTypeId(value: number) {
    this._documentTypeId = value;
    this.form && this.form.get('documentTypeId').reset(value);
  }

  private _documentName: string;
  @Input()
  set documentName(value: string) {
    this._documentName = value;
    this.setName();
  }

  get objectLookupTitle() {
    return 'Select ' + this.form.get('objectType').value;
  }

  protected get dateFields(): string[] {
    return [];
  }

  onDocTypeSelectionChanged(e): void {
    const objectType = e.selectedItem ? getModelByFQN(e.selectedItem.objectFQN).getModelName() : null;
    if (e.selectedItem && objectType !== this.form.get('objectType').value) {
      this.form.get('objectId').reset();
    }

    this.form.get('objectType').setValue(objectType);

    // console.log(e.selectedItem);
    if (e.selectedItem) {
      this.documentTypeName = e.selectedItem.name;
      this.setName();
      this.form.get('notes').setValue(e.selectedItem.defaultNotes);
    }

    this.buildObjectDataSource(this.form.get('objectType').value, this.form.get('objectId').value);
  }

  protected setName() {
    if (this._documentName || (this.objectName && this.documentTypeName)) {
      const val =
        this._documentName ||
        `${this.objectName} - ${this.documentTypeName} - UPL ${moment().format('YYYYMMDDHHmmss')}`;
      this.form.get('name').setValue(val);
    }
  }
  protected setFormValues(model: Document, isReset: boolean = true, resetValidators: boolean = true): void {
    super.setFormValues(model, isReset, resetValidators);

    if (this.form && !model) {
      this.form.get('objectId').reset(this.objectId);
      this.form.get('objectType').reset(this.objectType);
    }
  }

  protected async processFormValueAsync(data: any): Promise<any> {
    data = await super.processFormValueAsync(data);
    const newData: Document = Object.assign({}, data);

    // TODO: set owner on server
    if (!newData.ownerId) {
      newData.ownerId = Number(this.myUserApi.getCurrentToken().userId);
    }

    return newData;
  }

  private buildDataSources(): void {
    const dso: DataSourceOptions = {
      store: this.dss.getStore(DocumentType, null, false),
      filter: this.objectType
        ? [
            ['objectFQN', '=', getFQNByModelName(this.objectType)],
            ['inactive', '<>', true],
          ]
        : ['inactive', '<>', true],
      sort: [
        { selector: 'objectFQN', desc: true },
        { selector: 'name', desc: true },
      ],
      group: 'objectFQN',
      postProcess: this.docHelper.docTypeDSGrouping,
    };

    this.documentTypeDataSource = this.dss.getDataSourceFromDSO(dso);
    this.documentTypeDisplayExpr = (e: DocumentType) => (e ? `${e.name}` : '');
    this.documentTypeSearchExpr = ['name', 'objectFQN'];
  }

  private buildObjectDataSource(objectType: string, objectId: number): void {
    const isNew = !this.objectDS;
    this.form.get('objectId').enable();

    this.objectLookup && this.objectLookup.instance && this.objectLookup.instance.beginUpdate();

    switch (objectType) {
      case Facility.getModelName():
        this.objectDS = this.dss.getDataSource(Facility);
        this.objectSearchExpr = ['name', 'shortname'];
        this.objectDisplayExpr = (o: Facility) => (o ? `[${o.shortname}] ${o.name}` : '');
        break;

      case Employee.getModelName():
        this.objectDS = this.dss.getDataSource(Employee);
        this.objectSearchExpr = ['person_firstname', 'person_lastname'];
        this.objectDisplayExpr = (o: EmployeeView) =>
          o ? `[${o.employeePosition_name}] ${o.person_firstname} ${o.person_lastname}` : '';
        break;

      case Vehicle.getModelName():
        this.objectDS = this.dss.getDataSource(Vehicle);
        this.objectSearchExpr = ['internalId'];
        this.objectDisplayExpr = (o: Vehicle) => (o ? `#${o.internalId}` : '');
        break;

      case Consumer.getModelName():
        this.objectDS = this.dss.getDataSource(Consumer);
        this.objectSearchExpr = ['person_firstname', 'person_lastname'];
        this.objectDisplayExpr = (o: ConsumerView) => (o ? `${o.person_firstname} ${o.person_lastname}` : '');
        break;

      case Person.getModelName():
        this.objectDS = this.dss.getDataSource(Person);
        this.objectSearchExpr = ['firstname', 'lastname'];
        this.objectDisplayExpr = (o: Person) => (o ? `${o.firstname} ${o.lastname}` : '');
        break;

      default:
        this.form.get('objectId').disable();
        this.objectDS = new DataSource([]);
        this.objectSearchExpr = '';
        this.objectDisplayExpr = '';
    }

    if (this.objectLookup && this.objectLookup.instance) {
      // if (!isNew) this.objectLookup.instance.reset();
    }

    this.objectLookup && this.objectLookup.instance && this.objectLookup.instance.endUpdate();
  }
}
