import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { ConsumerModule } from '../consumer/consumer.module';
import { EmployeeModule } from '../employee/employee.module';
import { EmployeeSignStatsComponent } from './components/employee-sign-stats/employee-sign-stats.component';
import { FuelRefillGridComponent } from './components/fuel-refill-grid/fuel-refill-grid.component';
import { MealsClaimsPivotComponent } from './components/meals-claims-pivot/meals-claims-pivot.component';
import { BillingCommonV2Component } from './components/processing/billing-common-v2/billing-common-v2.component';
import { BillingCommonComponent } from './components/processing/billing-common/billing-common.component';
import { BillingMealsComponent } from './components/processing/billing-meals/billing-meals.component';
import { RemittancePivotComponent } from './components/remittance-pivot/remittance-pivot.component';
import { ServicesComponent } from './components/services/services.component';
import { SignatureStatsComponent } from './components/signature-stats/signature-stats.component';
import { StatsComponent } from './components/stats/stats.component';
import { BillingComponent } from './containers/billing/billing.component';
import { DlgEditServiceStatusComponent } from './dialogs/dlg-cancel-service/dlg-edit-service-status.component';
import { DlgChangeAdcWithClientComponent } from './dialogs/dlg-change-adc-w-client/dlg-change-adc-w-client.component';
import { DlgChangeAdcComponent } from './dialogs/dlg-change-adc/dlg-change-adc.component';
import { DlgEditAmountComponent } from './dialogs/dlg-edit-amount/dlg-edit-amount.component';
import { DlgEditCardNumberComponent } from './dialogs/dlg-edit-card-number/dlg-edit-card-number.component';
import { DlgEditServiceTypeComponent } from './dialogs/dlg-edit-service-type/dlg-edit-service-type.component';
import { DlgEditTimesComponent } from './dialogs/dlg-edit-times/dlg-edit-times.component';
import { DlgEditUnitsComponent } from './dialogs/dlg-edit-units/dlg-edit-units.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,

    MyCommonModule,
    UiModule,

    NgxJsonViewerModule,
    ConsumerModule,
    EmployeeModule,
  ],
  declarations: [
    BillingComponent,
    ServicesComponent,
    StatsComponent,
    SignatureStatsComponent,
    EmployeeSignStatsComponent,
    FuelRefillGridComponent,
    RemittancePivotComponent,

    DlgEditAmountComponent,
    DlgEditCardNumberComponent,
    DlgEditTimesComponent,
    DlgEditUnitsComponent,
    DlgEditServiceTypeComponent,
    DlgEditServiceStatusComponent,
    DlgEditUnitsComponent,
    BillingCommonComponent,
    BillingMealsComponent,
    DlgChangeAdcComponent,
    DlgChangeAdcWithClientComponent,

    MealsClaimsPivotComponent,
    BillingCommonV2Component,
  ],
  exports: [
    ServicesComponent,
    StatsComponent,

    DlgEditAmountComponent,
    DlgEditCardNumberComponent,
    DlgEditTimesComponent,
    DlgEditUnitsComponent,
    DlgEditServiceTypeComponent,
    DlgEditServiceStatusComponent,
    DlgChangeAdcComponent,
    DlgChangeAdcWithClientComponent,
  ],
  entryComponents: [
    ServicesComponent,
    StatsComponent,

    DlgEditAmountComponent,
    DlgEditCardNumberComponent,
    DlgEditTimesComponent,
    DlgEditUnitsComponent,
    DlgEditServiceTypeComponent,
    DlgEditServiceStatusComponent,
    DlgChangeAdcComponent,
    DlgChangeAdcWithClientComponent,
  ],
})
export class BillingModule {}
