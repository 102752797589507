<div [formGroup]="parts" [ngStyle]="{ opacity: readonly ? 0.5 : 1 }">
  <ng-container *ngFor="let l of labels; let isFirst = first">
    <div class="item">
      <label>{{ l }}</label>
      <mat-select [disabled]="disabled" [formControlName]="l" (blur)="_onTouched()">
        <mat-option>Select...</mat-option>
        <mat-option *ngFor="let itm of source" [value]="itm.v">
          {{ itm.t }}
        </mat-option>
      </mat-select>
    </div>
  </ng-container>
</div>
