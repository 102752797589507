<dx-data-grid
  #grid
  class="content"
  height="100%"
  width="100%"
  [dataSource]="dso"
  [twoWayBindingEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxi-column
    caption="Time"
    dataField="createdAt"
    dataType="date"
    format="shortDateShortTime"
    [allowFiltering]="false"></dxi-column>
  <dxi-column caption="From Date" dataField="fromDate" dataType="date" format="shortDate"></dxi-column>
  <dxi-column caption="To Date" dataField="toDate" dataType="date" format="shortDate"></dxi-column>
  <dxi-column
    caption="Cloning Content"
    cellTemplate="type_cell"
    dataField="createdManifest"
    [calculateFilterExpression]="cloningContent_calculateFilterExpression">
    <dxo-lookup [dataSource]="['Manifest', 'Schedules', 'Mixt']"></dxo-lookup>
  </dxi-column>
  <dxi-column caption="Cloning Mode" dataField="isManual" dataType="boolean" cellTemplate="mode_cell">
    <dxo-lookup
      [dataSource]="[
        { ID: true, Name: 'Manual' },
        { ID: false, Name: 'Auto' }
      ]"
      displayExpr="Name"
      valueExpr="ID"></dxo-lookup>
  </dxi-column>
  <dxi-column caption="User" dataField="user" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column caption="Tenant" dataField="tenant" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column
    caption="Created Manifest"
    dataField="createdManifest"
    cellTemplate="manifest_cell"
    [allowSorting]="false"
    [allowFiltering]="false"></dxi-column>
  <dxi-column
    caption="From Manifest"
    dataField="fromManifest"
    cellTemplate="manifest_cell"
    [allowSorting]="false"
    [allowFiltering]="false"></dxi-column>
  <dxi-column
    caption="Created Schedules"
    dataField="createdSchedules.length"
    [allowSorting]="false"
    [allowFiltering]="false"></dxi-column>
  <!-- Templates -->
  <ng-container *dxTemplate="let cellInfo of 'manifest_cell'">
    <a *ngIf="cellInfo.value" class="show-loop-link" (click)="manifest_onClick(cellInfo.value, cellInfo.data.tenant)"
      >Manifest</a
    >
  </ng-container>
  <ng-container *dxTemplate="let cellInfo of 'type_cell'">
    {{ cellInfo.data.createdManifest ? (cellInfo.data.createdSchedules ? 'Mixt' : 'Manifest') : 'Schedules' }}
  </ng-container>
  <ng-container *dxTemplate="let cellInfo of 'mode_cell'">
    {{ cellInfo.value ? 'Manual' : 'Auto' }}
  </ng-container>
</dx-data-grid>

