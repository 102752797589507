import { Component, OnInit, ViewChild } from '@angular/core';
import { DxTabPanelComponent } from 'devextreme-angular/ui/tab-panel';
import { VehicleCheckUp } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';

@Component({
  selector: 'app-daily-checks-details-tabs',
  templateUrl: './daily-checks-details-tabs.component.html',
  styleUrls: ['./daily-checks-details-tabs.component.scss'],
})
export class DailyChecksDetailsTabsComponent extends ABaseModelLoaderComponent<VehicleCheckUp> implements OnInit {
  selectedIndex = 0;
  tabs: any[] = [
    {
      title: 'V-CHECK SUMMARY',
      template: 'summary',
    },
    {
      title: 'FULL REPORT',
      template: 'full',
    },
    {
      title: 'PICTURES',
      template: 'pics',
    },
    {
      title: 'VECH INFO',
      template: 'vehicle',
    },
  ];

  @ViewChild('tabPanel', { static: true }) tabPanel: DxTabPanelComponent;

  constructor(protected logger: LoggerService, protected dss: DataSourceService) {
    super(logger, dss);
  }

  ngOnInit() {}

  repaint() {
    this.tabPanel.instance.repaint();
  }

  protected get ModelClass(): any {
    return VehicleCheckUp;
  }
}
