import {OnDestroy, OnInit} from '@angular/core';
import {Subject, Unsubscribable} from 'rxjs';
import {LoggerService} from '../../../../sdk';

export abstract class ABaseComponent implements OnInit, OnDestroy {
  private subscriptions: Unsubscribable[] = [];
  protected $onDestroy$ = new Subject<void>();

  protected constructor(
    protected logger: LoggerService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.$onDestroy$.next();
    this.unsubscribe();
  }

  // protected subscribe(...args: Array<Subscribable<any> | SubscriptionLike>): void {
  //   args
  //     .filter(subsn => !!subsn)
  //     .forEach(subsn => {
  //       if (subsn instanceof Observable) {
  //         this.subscriptions.push((subsn as Subscribable<any>).subscribe());
  //       } else {
  //         this.subscriptions.push((subsn as Subscription));
  //       }
  //     });
  // }

  private unsubscribe(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
