<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">Weekly Hours</div>
      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <app-employee-working-time-grid [selectedDate]="data.selectedDate"></app-employee-working-time-grid>
</div>

