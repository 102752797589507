import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../../../shared/sdk';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dlg-edit-daily-note',
  templateUrl: './dlg-edit-daily-note.component.html',
  styleUrls: ['./dlg-edit-daily-note.component.scss'],
  providers: [HelperService],
})
export class DlgEditDailyNoteComponent implements OnInit {
  value = '';

  constructor(
    private dialogRef: MatDialogRef<DlgEditDailyNoteComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private helper: HelperService,
    protected logger: LoggerService,
  ) {
    this.value = data.value || '';
  }

  ngOnInit() {}
}
