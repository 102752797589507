import * as tslib_1 from "tslib";
import { OnDestroy, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import sortBy from 'lodash-es/sortBy';
import { takeUntil } from 'rxjs/operators';
//
import { AnyNote, Document, LoggerService } from '../../../../../sdk';
import { DataSourceService } from '../../../../my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../services/grid-helper.service';
import { UiService } from '../../../services/ui.service';
import { HelperService as DocumentHelperService } from 'src/app/modules/document/services/helper.service';
import { DocumentFormComponent } from 'src/app/modules/document/components/document-form/document-form.component';
import { HelperService } from 'src/app/modules/employee/services/helper.service';
import { AnyNoteFormComponent } from '../any-note-form/any-note-form.component';
const GENERIC_DOCUMENT_TYPE_ID = 130;
export class AnyNotesPageDocumentsComponent extends ABaseModelPaneWToolbarComponent {
    constructor(logger, ui, dss, dialog, helper, docHelper, gridHelper) {
        super(logger, ui, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.helper = helper;
        this.docHelper = docHelper;
        this.gridHelper = gridHelper;
        this.caption = 'Related Documents';
        this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
            if ([Document.getModelName()].includes(modelName)) {
                if (this.grid) {
                    this.grid.instance.refresh();
                }
            }
        });
    }
    get ModelClass() {
        return AnyNote;
    }
    get FormComponent() {
        return AnyNoteFormComponent;
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {});
    }
    grid_onEditingStart(e) {
        e.cancel = true;
        this.ui.openEditDialog({
            modelId: e.data.id,
            ModelClass: Document,
            FormComponentClass: DocumentFormComponent,
        });
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            name: 'addDoc',
            widget: 'dxButton',
            locateInMenu: 'always',
            location: 'after',
            sortIndex: 30,
            options: {
                text: 'Add Doc',
                hint: 'Add Doc',
                onClick: () => {
                    this.ui
                        .openEditDialog({
                        modelId: null,
                        inputs: {
                            objectType: this.objectType,
                            objectId: this.objectId,
                            documentTypeId: GENERIC_DOCUMENT_TYPE_ID,
                            documentName: this.documentName,
                        },
                        ModelClass: Document,
                        FormComponentClass: DocumentFormComponent,
                    })
                        .afterClosed()
                        .toPromise()
                        .then((doc) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (!doc)
                            return;
                        const store = this.dss.getStore(AnyNote);
                        this.model.documents = [...new Set([doc.id, ...(this.model.documents || [])])];
                        yield store.update(this.model.id, { documents: this.model.documents });
                    }));
                },
            },
        });
        e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.dso = this.buildDSO(model);
        });
    }
    buildDSO(model) {
        const filter = ['id', 'inq', model.documents || []];
        return { store: this.dss.getStore(Document, null, false), filter };
    }
}
