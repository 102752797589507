import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { oc } from 'ts-optchain';
import { LoggerService, Signature } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-unlock-service',
  templateUrl: './dlg-unlock-service.component.html',
  styleUrls: ['./dlg-unlock-service.component.scss'],
})
export class DlgUnlockServiceComponent implements OnInit, AfterViewInit {
  value: string;

  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DlgUnlockServiceComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { signature: Signature },
    protected logger: LoggerService,
  ) {}

  get locked() {
    return oc(this.data).signature.meta.locked(false);
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => this.inputElement.nativeElement.select());
  }
}
