import {
  Consumer
} from '../index';

declare var Object: any;
export interface ProgramInterface {
  "name": string;
  "state": string;
  "order"?: number;
  "description"?: string;
  "meta"?: any;
  "dtime"?: Date;
  "id"?: number;
  consumers?: Consumer[];
}

export class Program implements ProgramInterface {
  "name": string;
  "state": string;
  "order": number;
  "description": string;
  "meta": any;
  "dtime": Date;
  "id": number;
  consumers: Consumer[];
  constructor(data?: ProgramInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Program`.
   */
  public static getModelName() {
    return "Program";
  }

  /**
  * This method creates an instance of Program for dynamic purposes.
  **/
  public static factory(data: ProgramInterface): Program{
    return new Program(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Program',
      plural: 'Programs',
      path: 'Programs',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        consumers: {
          name: 'consumers',
          type: 'Consumer[]',
          model: 'Consumer',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'programId'
        },
      }
    }
  }
}
