import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import DevExpress from 'devextreme/bundles/dx.all';
import { confirm } from 'devextreme/ui/dialog';
import {
  Consumer,
  Employee,
  EmployeePosition,
  EmployeeView,
  EmployeeViewApi,
  LoggerService,
  LoopBackFilter,
} from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../classes/enums';
import DataSource = DevExpress.data.DataSource;

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
})
export class EmployeeFormComponent extends ABaseFormComponent<Employee> {
  types = EMPLOYEE_TYPES;
  statuses = EMPLOYEE_STATUSES;
  personSexesDS = PERSON_SEXES;

  positionDS: DataSource;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Employee>,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);

    this.positionDS = dss.getDataSource(EmployeePosition);
  }

  get addressesFormArray(): FormArray {
    return this.form.get('person.contact.addresses') as FormArray;
  }

  get phonesFormArray(): FormArray {
    return this.form.get('person.contact.phones') as FormArray;
  }

  get emailsFormArray(): FormArray {
    return this.form.get('person.contact.emails') as FormArray;
  }

  get emRelationsFormArray(): FormArray {
    return this.form.get('emRelations') as FormArray;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        { person: { contact: ['addresses', 'phones', 'emails'] } },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
      ],
    };
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get dateFields(): string[] {
    return ['interviewedAt', 'hiredAt', 'separatedAt', 'person.dob'];
  }

  protected async processFormValueAsync(data: any): Promise<any> {
    const duplicates = !this.modelId
      ? await this.dss
          .getApi<EmployeeViewApi>(EmployeeView)
          .find<EmployeeView>({
            where: {
              ...(this.modelId ? { [Consumer.getModelDefinition().idName]: { neq: this.modelId } } : {}),
              person_firstname: { like: ((data.person.firstname as string) || '').trim().toLowerCase() },
              person_lastname: { like: ((data.person.lastname as string) || '').trim().toLowerCase() },
              // person_dob: ,
            },
            limit: 1,
          })
          .toPromise()
      : [];

    if (duplicates.length) {
      const res = await confirm(
        'Employee with the same name already exists.<br>Abort employee creation?',
        'Duplicate found',
      );

      if (res === true) throw new Error('This employee already exists');
    }

    return super.processFormValueAsync(data);
  }

  protected buildForm(): void {
    this.formConfigMap.set('person.contact.addresses', {
      id: [],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [],
      contactId: [],
    });

    this.formConfigMap.set('person.contact.phones', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('person.contact.emails', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('person.contact', {
      id: [],
      notes: [],
      addresses: this.fb.array([]),
      phones: this.fb.array([]),
      emails: this.fb.array([]),
    });

    this.formConfigMap.set('person.data', {
      nativeLang: [],
      nativeLangName: [],
      dl: [],
    });

    this.formConfigMap.set('person', {
      id: [],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [],
      nickname: [],
      sex: [],
      dob: [],
      notes: [],
      contactId: [],
      data: this.fb.group(this.formConfigMap.get('person.data')),
      contact: this.fb.group(this.formConfigMap.get('person.contact')),
    });

    this.formConfigMap.set('emRelations', {
      id: [],
      relation: [],
      employeeId: [],
      personId: ['', Validators.required],
    });

    this.formConfigMap.set('data', {
      title: [],
    });

    this.formConfigMap.set('', {
      id: [],
      status: ['', Validators.required],
      type: [],
      interviewedAt: [],
      hiredAt: [],
      separatedAt: [],
      allowFastVehicleCheckout: [false],
      allowMultiCheckin: [false],
      notes: [],
      employeePositionId: ['', Validators.required],
      personId: [],
      data: this.fb.group(this.formConfigMap.get('data')),
      person: this.fb.group(this.formConfigMap.get('person')),
      emRelations: this.fb.array([]),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
