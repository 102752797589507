import { Component, EventEmitter, OnInit, Output } from '@angular/core';
//
import { DocumentType } from '../../../../shared/sdk';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss'],
})
export class DocumentTypeListComponent implements OnInit {
  @Output() mySelected: EventEmitter<DocumentType[]> = new EventEmitter<DocumentType[]>();

  constructor() {}

  ngOnInit() {}

  grid_onSelected(items: DocumentType[]) {
    this.mySelected.emit(items);
  }
}
