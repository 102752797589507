import { Component, Inject, Input, OnInit } from '@angular/core';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Consumer, Employee, EmployeeView, LoggerService } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import CustomStore from 'devextreme/data/custom_store';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-dlg-select-employee',
  templateUrl: './dlg-select-employee.component.html',
  styleUrls: ['./dlg-select-employee.component.scss'],
})
export class DlgSelectEmployeeComponent implements OnInit {
  @Input() ds: DataSourceOptions;
  @Input() gridColumns: any[] = [
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
    { dataField: 'employeePosition_name', caption: 'Position' },
    { dataField: 'status', caption: 'Status' },
  ];

  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgSelectEmployeeComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {
    this.buildDataSource();
  }

  ngOnInit() {}

  private buildDataSource() {
    const so = this.dss.getStoreOptions(Employee, EmployeeView, false) as LoopBackStoreOptions<any, any>;
    if (this.data.skipTenantCheck) {
      so.customHeaders = headersAllTenantsAppend;
    }

    this.ds = {
      store: new CustomStore(so),
      filter: oc(this.data).filter([]),
      sort: [{ selector: 'person_lastname' }],
    } as DataSourceOptions;
  }
}
