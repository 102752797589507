import * as tslib_1 from "tslib";
import { AfterViewInit, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { oc } from 'ts-optchain';
import { Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../../consumer/services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
export class VehicleDetailsTabInfoComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, dialog, common) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.dialog = dialog;
        this.common = common;
        this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
    }
    ngOnInit() {
        super.ngOnInit();
        this.caption = 'Vehicle Info';
    }
    ngAfterViewInit() { }
    get ModelClass() {
        return Vehicle;
    }
    get FormComponent() {
        return VehicleFormComponent;
    }
    get filter() {
        return { include: [{ relation: 'anyNotes', scope: { order: 'updatedAt DESC', include: 'createdBy' } }] };
    }
    get observeModels() {
        return [this.ModelClass];
    }
    qrPrint() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.common.printQr(oc(this.model).vin('')).toPromise();
        });
    }
}
