import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '../../../services/helper.service';
import { Employee, EmployeePosition, LoggerService, LoopBackFilter, Person } from 'src/app/shared/sdk';
import { ABaseModelLoaderComponent } from 'src/app/shared/modules/ui/components/abstract/a-base-model-loader.component';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
//

@Component({
  selector: 'app-employee-ext-details',
  templateUrl: './employee-ext-details.component.html',
  styleUrls: ['./employee-ext-details.component.scss'],
  providers: [HelperService],
})
export class EmployeeExtDetailsComponent
  extends ABaseModelLoaderComponent<Employee>
  implements OnInit, OnChanges, OnDestroy
{
  toolbarItems: any[] = [];

  constructor(protected logger: LoggerService, protected dss: DataSourceService, private helper: HelperService) {
    super(logger, dss);

    this.buildToolbarItems();

    this.afterLoaded.pipe(takeUntil(this.$onDestroy$)).subscribe((model: Employee) => this.buildToolbarItems(model));
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['employeePosition', 'person'],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, Person, EmployeePosition];
  }

  private buildToolbarItems(model: Employee = null) {
    if (model) {
      this.toolbarItems = [
        {
          location: 'before',
          locateInMenu: 'never',
          text: model.status,
        },
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.helper.displayExpr(model),
        },
        {
          location: 'after',
          locateInMenu: 'never',
          text: model.employeePosition.name,
        },
      ];
    } else {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.errorMessage || 'Not selected',
        },
      ];
    }
  }
}

