<div>
  <ng-container *ngIf="form">
    <div style="display: flex; align-items: center">
      <div style="flex: auto">
        <a
          mat-button
          color="primary"
          style="float: right"
          (click)="addNoteClick($event, true)"
          *ngIf="!addNoteEnabled"
          >ADD NOTE</a
        >
        <a
          mat-button
          color="primary"
          style="float: right"
          (click)="addNoteClick($event, false)"
          *ngIf="addNoteEnabled"
          >CLOSE NOTE</a
        >
      </div>
    </div>

    <ng-container [formGroup]="notesFormArray" *ngIf="addNoteEnabled">
      <div *ngFor="let form1 of notesFormArray.controls; let idx = index">
        <ng-container [formGroup]="form1">
          <div>
            <div class="dx-field">
              <label class="_dx-field-label">Subject:</label>
              <div class="_dx-field-value">
                <dx-text-box formControlName="subject">
                  <dx-validator name="Subject">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </div>
            <div class="dx-field">
              <label class="_dx-field-label">Description:</label>
              <div class="_dx-field-value">
                <dx-text-area formControlName="description">
                  <dx-validator name="Description">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-area>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <table [ngClass]="{ wrapped: wrap, unwrapped: !wrap }">
    <ng-container *ngFor="let note of notes">
      <tr>
        <td>
          <div [title]="note.description">
            <span class="badge"> {{ note.createdAt | date: 'yyyy/MM/dd HH:mm' }}</span>
            : {{ note.createdBy?.username || 'Unknown'}} : {{ note.description }}
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>