import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Facility, FacilityApi, InternalStorage, MyUtilsApi, Vehicle } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { HelperService } from '../../services/helper.service';
import { HelperService as HelperManifestService } from '../../../trip-manifest/services/helper.service';
import moment, { duration } from 'moment-timezone';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import CustomStore from 'devextreme/data/custom_store';
export class VehicleGridComponent {
    constructor(config, dss, helper, manifestHelper, gridHelper, dialog, myUtilsAPI, internalStorage, facilityApi, auth) {
        this.config = config;
        this.dss = dss;
        this.helper = helper;
        this.manifestHelper = manifestHelper;
        this.gridHelper = gridHelper;
        this.dialog = dialog;
        this.myUtilsAPI = myUtilsAPI;
        this.internalStorage = internalStorage;
        this.facilityApi = facilityApi;
        this.auth = auth;
        this.goeTabDevicesTimestamp = 0;
        this.selectedRowsData = [];
        this.STATES = ['ACTIVE', 'INACTIVE'];
        this.markerBaseUrl = '/assets/images/';
        this.isBase = false;
        this.mySelected = new EventEmitter();
        this.getFacilityCellValue = (v) => {
            return (this.vehiclesMap[v.id] && this.vehiclesMap[v.id].tenant.shortname) || '';
        };
        this.getDriverCellValue = (v) => {
            const driver = this.vehiclesMap[v.id] && this.driversMap[this.vehiclesMap[v.id].firstTrip.e];
            return (driver && `${driver.person.firstname} ${driver.person.lastname}`) || '';
        };
        this.getGeoTabLocationCellValue = (v) => (this.goeTabDevicesMap[v.vin] &&
            `${this.goeTabDevicesMap[v.vin].latitude},${this.goeTabDevicesMap[v.vin].longitude}`) ||
            '';
        this.getGeoTabDateTimeCellValue = (v) => (this.goeTabDevicesMap[v.vin] && moment(this.goeTabDevicesMap[v.vin].dateTime).format('M/D/YYYY hh:mm:ss a')) || '';
        this.sortGeoTabTime = (v) => (this.goeTabDevicesMap[v.vin] && -1 * new Date(this.goeTabDevicesMap[v.vin].dateTime).getTime()) || '';
        this.getGeoTabLastIgnition = (v) => (Object.assign({}, (this.goeTabDevicesMap[v.vin] && this.goeTabDevicesMap[v.vin].lastIgnition)));
        this.getGeoTabLastIgnitionOnCellValue = (v) => this.getGeoTabLastIgnition(v).on || '';
        this.getGeoTabLastIgnitionOffCellValue = (v) => this.getGeoTabLastIgnition(v).off || '';
        this.sortLastIgnitionOn = (v) => -1 * new Date(this.getGeoTabLastIgnition(v).on || 0).getTime();
        this.sortLastIgnitionOff = (v) => -1 * new Date(this.getGeoTabLastIgnition(v).off || 0).getTime();
        // this.grid_stateStoring = this.sss.buildOptions('00a07308-3a34-4ef0-b45f-112089c105d5');
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '439b7e1f-9720-46cc-9082-b8a3108fc283',
        };
        this.buildData();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
                if ([Vehicle.getModelName()].includes(modelName)) {
                    if (this.grid) {
                        this.grid.instance.refresh();
                    }
                }
            });
        });
    }
    ngOnDestroy() {
        this.modifiedSubscription.unsubscribe();
    }
    repaint() {
        // this.grid && this.grid.instance && this.grid.instance.repaint();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
    }
    grid_onToolbarPreparing(e) { }
    grid_onSelectionChanged(e) {
        this.selectedRowsData = e.selectedRowsData;
        this.mySelected.emit(this.getMarkers());
    }
    grid_onContentReady(e) {
        if (!e.component.getSelectedRowKeys().length)
            e.component.selectAll();
    }
    buildData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.setIsBase();
            yield this.buildDataGrid();
        });
    }
    setIsBase() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const tenantId = this.auth.getCurrentTenant();
            const tenant = yield this.facilityApi.findById(tenantId).toPromise();
            this.isBase = tenant.type === 'BASE';
        });
    }
    buildDataGrid() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const so = this.dss.getStoreOptions(Vehicle, undefined, false);
            if (!this.isBase) {
                const date = moment().format('YYYY-MM-DD');
                const manifest = yield this.manifestHelper.api.getCurrentManifest(date).toPromise();
                const vIds = [...new Set((manifest.data || []).map(d => d.v))];
                so.customFilter = { where: { id: { inq: vIds } } };
            }
            const store = new CustomStore(so);
            dxStoreLoadHooks(store, (obj) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (Date.now() - this.goeTabDevicesTimestamp > 10000) {
                    this.goeTabDevicesTimestamp = Date.now();
                    this.goeTabDevicesMap = yield this.getGeoTabDevices();
                    this.mySelected.emit(this.getMarkers());
                    const { vehiclesMap, driversMap } = yield this.getVehiclesWithDrivers();
                    this.vehiclesMap = vehiclesMap;
                    this.driversMap = driversMap;
                }
                return [obj];
            }));
            this.dso = { store };
        });
    }
    getMarkers() {
        const lastIgnition = ({ on, off }) => {
            const a = [
                `<br/><em>Last Ignition On:</em> ${(on && this.getTimeAgo(on)) || 'long time ago'}`,
                `<br/><em>Last Ignition Off:</em> ${(off && this.getTimeAgo(off)) || 'long time ago'}`,
            ];
            return (on < off ? a : [a[1], a[0]]).join('');
        };
        return this.selectedRowsData
            .filter((v) => this.goeTabDevicesMap[v.vin])
            .map((v) => [v, this.goeTabDevicesMap[v.vin]])
            .map(([v, d]) => {
            const states = {
                moving: [this.markerBaseUrl + 'marker-selected-device.png', 'In Motion'],
                notMoving: [null, 'Vehicle not moving'],
                disabled: [this.markerBaseUrl + 'marker-gray.png', 'Not moving for more than 3 hours'],
            };
            const [iconSrc, state] = (d.currentStateDuration > '03:00:00' && states['disabled']) ||
                (d.speed && states['moving']) ||
                states['notMoving'];
            return {
                vin: v.vin,
                tooltip: {
                    text: `${v.internalId} ${v.make} ${v.model}` +
                        `<br/><em>State:</em> ${state}` +
                        `<br/><em>Updated:</em> ${this.getTimeAgo(d.dateTime)}` +
                        `<br/><em>Speed:</em> ${d.speed}` +
                        `<br/><em>State Duration:</em> ${d.currentStateDuration}` +
                        lastIgnition(d.lastIgnition) +
                        `<br/><em>Status:</em> ${v.status}`,
                    isShown: false,
                },
                iconSrc,
                location: { lat: d.latitude, lng: d.longitude },
            };
        });
    }
    getVehiclesWithDrivers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.manifestHelper.api
                .getVehiclesWithDriversFromFirstManifest(moment().format('YYYY-MM-DD'))
                .toPromise();
        });
    }
    getMapURL(location) {
        return `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
    }
    getTimeAgo(time) {
        return (time && duration(moment(new Date(time)).diff(moment())).humanize(true)) || '';
    }
    getGeoTabDevices(attempt = 0) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const geoTabAuth = this.internalStorage.get('geoTabAuth') || (yield this.myUtilsAPI.geoTabAuth().toPromise());
                this.internalStorage.set('geoTabAuth', geoTabAuth);
                const api = new GeotabApi(geoTabAuth);
                const req = [
                    ['Get', { typeName: 'Device' }],
                    ['Get', { typeName: 'DeviceStatusInfo' }],
                    [
                        'Get',
                        {
                            typeName: 'StatusData',
                            search: {
                                diagnosticSearch: {
                                    id: 'DiagnosticIgnitionId',
                                },
                                // deviceSearch: {
                                //   id: 'b78',
                                // },
                                fromDate: moment().subtract(1, 'days'),
                            },
                        },
                    ],
                ];
                const [[devices, deviceStatuses, ignitions], last] = yield Promise.all([
                    api.multiCall(req),
                    gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getLastGeoTabAggregate()).pipe().toPromise(),
                ]);
                const mLast = last.reduce((p, v) => (Object.assign({}, p, { [v.vin]: v.lastIgnition })), {});
                const dsMap = deviceStatuses.reduce((p, ds) => (Object.assign({}, p, { [ds.device.id]: ds })), {});
                ignitions.forEach(ign => {
                    if (dsMap[ign.device.id] &&
                        (!dsMap[ign.device.id][`ignition_${ign.data}`] || dsMap[ign.device.id][`ignition_${ign.data}`] < ign.dateTime)) {
                        dsMap[ign.device.id][`ignition_${ign.data}`] = ign.dateTime;
                    }
                });
                return devices.reduce((p, { id, vehicleIdentificationNumber: vin }) => (vin &&
                    dsMap[id] && Object.assign({}, p, { [vin]: {
                        dateTime: dsMap[id].dateTime,
                        latitude: dsMap[id].latitude,
                        longitude: dsMap[id].longitude,
                        speed: dsMap[id].speed,
                        currentStateDuration: dsMap[id].currentStateDuration,
                        lastIgnition: Object.assign({}, ((dsMap[id][`ignition_1`] && { on: dsMap[id][`ignition_1`] }) ||
                            (mLast[vin] && { on: mLast[vin].on })), ((dsMap[id][`ignition_0`] && { off: dsMap[id][`ignition_0`] }) ||
                            (mLast[vin] && { off: mLast[vin].off }))),
                    } })) ||
                    p, {});
            }
            catch (err) {
                const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
                if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.')
                    throw msg;
                if (attempt)
                    return {};
                this.internalStorage.remove('geoTabAuth');
                return yield this.getGeoTabDevices(1);
            }
        });
    }
    getLastGeoTabAggregate() {
        return [
            { $match: {} },
            { $unwind: '$days' },
            {
                $project: {
                    vin: 1,
                    distance: '$days.distance',
                    day: '$days.day',
                    lastIgnition: { on: '$days.firstEngineOn', off: '$days.lastEngineOff' },
                },
            },
            { $match: { distance: { $gte: 1 } } },
            { $sort: { day: 1 } },
            { $group: { _id: '$vin', last: { $last: '$$ROOT' } } },
            { $project: { _id: 0, vin: '$last.vin', lastIgnition: '$last.lastIgnition' } },
        ];
    }
}
