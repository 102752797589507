import {
  Facility,
  Consumer,
  MyUser
} from '../index';

declare var Object: any;
export interface NoteInterface {
  "text": string;
  "dateTime"?: Date;
  "author"?: string;
  "infoBy": string;
  "infoDate"?: any;
  "followUpDate"?: any;
  "source"?: string;
  "meta"?: any;
  "id"?: number;
  "tenantId"?: number;
  "consumerId"?: number;
  "userId"?: number;
  tenant?: Facility;
  consumer?: Consumer;
  user?: MyUser;
}

export class Note implements NoteInterface {
  "text": string;
  "dateTime": Date;
  "author": string;
  "infoBy": string;
  "infoDate": any;
  "followUpDate": any;
  "source": string;
  "meta": any;
  "id": number;
  "tenantId": number;
  "consumerId": number;
  "userId": number;
  tenant: Facility;
  consumer: Consumer;
  user: MyUser;
  constructor(data?: NoteInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Note`.
   */
  public static getModelName() {
    return "Note";
  }

  /**
  * This method creates an instance of Note for dynamic purposes.
  **/
  public static factory(data: NoteInterface): Note{
    return new Note(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Note',
      plural: 'Notes',
      path: 'Notes',
      idName: 'id',
      properties: {
        "text": {
          name: 'text',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "author": {
          name: 'author',
          type: 'string'
        },
        "infoBy": {
          name: 'infoBy',
          type: 'string'
        },
        "infoDate": {
          name: 'infoDate',
          type: 'any'
        },
        "followUpDate": {
          name: 'followUpDate',
          type: 'any'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'consumerId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
