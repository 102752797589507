<div class="avatar-container">
  <button
    class="close-button"
    mat-icon-button
    type="button"
    [mat-dialog-close]="false"
    matTooltip="Close"
    color="primary">
    <mat-icon aria-label="Close">close</mat-icon>
  </button>

  <dx-file-uploader>
    <dx-validator name="File" #uploadValidator>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule
        type="custom"
        [reevaluate]="true"
        [validationCallback]="validationCallback"></dxi-validation-rule>
    </dx-validator>
  </dx-file-uploader>
  <img
    id="image"
    *ngIf="sanitizedUrl || person.personalPhoto?.org; else placeholder"
    [src]="sanitizedUrl || (person.personalPhoto.org | fileSignedUrl | async)"
    (load)="onImageLoad($event)" />
  <ng-template #placeholder>
    <div class="org-placeholder"></div>
  </ng-template>
  <div class="crop-actions">
    <label for="avatar-input-file">
      <input type="file" id="avatar-input-file" accept="image/*" (change)="onFileChange($event)" #fileInput />
      <button mat-button style="margin-right: 1rem" class="button" (click)="fileInput.click()">
        Select Image File
      </button>
    </label>
    <span *ngIf="sanitizedUrl || person.personalPhoto?.org">
      <button style="margin-right: 1rem" mat-flat-button (click)="reset()">Reset</button>
      <button color="primary" mat-flat-button (click)="crop()">Save</button>
    </span>
  </div>
</div>

