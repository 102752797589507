import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';

@Component({
  selector: 'app-dlg-set-trip-number',
  templateUrl: './dlg-set-trip-number.component.html',
  styleUrls: ['./dlg-set-trip-number.component.scss'],
})
export class DlgSetTripNumberComponent implements OnInit {
  tripNumber: number;

  constructor(
    private dialogRef: MatDialogRef<DlgSetTripNumberComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {}

  ngOnInit() {}
}
