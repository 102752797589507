<kendo-splitter orientation="vertical">
  <kendo-splitter-pane [scrollable]="false" size="450px">
    <div class="title">Notes {{ subTitle }}</div>
    <dx-data-grid
      height="100%"
      width="auto"
      [dataSource]="dso"
      [remoteOperations]="false"
      (onSelectionChanged)="grid_onSelectionChanged($event)"
      (onToolbarPreparing)="grid_onToolbarPreparing($event)">
      <!-- Settings -->
      <dxo-editing [allowAdding]="false" [allowDeleting]="allowDeleting$ | async" [allowUpdating]="false"></dxo-editing>
      <dxo-export [enabled]="true" fileName="notes-export"></dxo-export>
      <!-- Columns -->
      <dxi-column
        caption="Date Of Note Entry"
        dataField="updatedAt"
        dataType="date"
        format="shortDateShortTime"></dxi-column>
      <dxi-column caption="Date Of Event" dataField="dateOfEvent" dataType="date" format="shortDate"></dxi-column>
      <dxi-column caption="Note By" dataField="fullName" dataType="string"></dxi-column>
      <dxi-column caption="Subject" dataField="subject" dataType="string"></dxi-column>
    </dx-data-grid>
  </kendo-splitter-pane>

  <kendo-splitter-pane [collapsible]="true" [scrollable]="false">
    <dx-tab-panel
      [dataSource]="tabs"
      height="100%"
      [loop]="false"
      [animationEnabled]="false"
      [showNavButtons]="true"
      [swipeEnabled]="false">
      <div *dxTemplate="let data of 'note'">
        <div class="note-tab">
          <div style="position: relative">
            <dx-button
              *ngIf="selectedNote"
              style="position: absolute; top: 10px; right: 10px; z-index: 1000"
              type="normal"
              text="Edit Note"
              (onClick)="onEditNote()"></dx-button>
            <div class="dx-fieldset" *ngIf="selectedNote">
              <div class="dx-field" *ngIf="notificationGroupUserList">
                <div class="dx-field-label">Notification Group</div>
                <div class="dx-field-value-static">{{ notificationGroupUserList }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Date</div>
                <div class="dx-field-value-static">{{ selectedNote.updatedAt | date : 'long' }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Created By</div>
                <div class="dx-field-value-static">{{ selectedNote.fullName }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Subject</div>
                <div class="dx-field-value-static">{{ selectedNote.subject }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Description</div>
                <div class="dx-field-value-static">{{ selectedNote.description }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Related Note</div>
                <div class="dx-field-value-static">{{ noteFormat(selectedNote.relatedNote) }}</div>
              </div>
              <ng-container *ngTemplateOutlet="templateNote; context: { item: selectedNote }"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div *dxTemplate="let data of 'comments'">
        <ng-container *ngIf="selectedNote">
          <div class="comment-form">
            <dx-text-area #comment placeholder="Write a comment"></dx-text-area>
            <dx-button icon="message" text="Add" (onClick)="addComment(comment)"></dx-button>
          </div>
          <dx-data-grid
            class="comments-grid"
            width="auto"
            [dataSource]="selectedNote.comments"
            [remoteOperations]="false">
            <!-- Settings -->
            <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="true" fileName="notes-export"></dxo-export>
            <!-- Columns -->
            <dxi-column caption="Date" dataField="createdAt" dataType="date" format="shortDateShortTime"></dxi-column>
            <dxi-column caption="Comment By" dataField="commentBy" dataType="string"></dxi-column>
            <dxi-column caption="Comment" dataField="comment" dataType="string"></dxi-column>
          </dx-data-grid>
        </ng-container>
      </div>

      <div *dxTemplate="let data of 'docs'">
        <app-any-notes-page-documents
          *ngIf="selectedNote"
          [modelId]="selectedNote.id"
          [objectType]="objectType"
          [objectId]="objectId"
          [documentName]="documentName"></app-any-notes-page-documents>
      </div>
    </dx-tab-panel>
  </kendo-splitter-pane>
</kendo-splitter>

