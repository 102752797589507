<app-content-w-header header="Settings" xmlns="http://www.w3.org/1999/html">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <div style="flex: auto; position: relative">
      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
        <dx-scroll-view>
          <mat-card>
            <div class="dx-fieldset-header">MTM Trips</div>
            <div class="dx-fieldset">
              <fieldset>
                <legend style="text-decoration: underline">Enable MTM Trips</legend>
                <div class="dx-field">
                  <div class="dx-field-label">Enable</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="enabledComponentsValueChanged()"
                        [(value)]="enabledComponents.mtmTrips"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </mat-card>

          <mat-card>
            <div class="dx-fieldset-header">Auto Scheduling</div>
            <fieldset>
              <legend style="text-decoration: underline">Options</legend>
              <form
                [formGroup]="formSchedulingSettings"
                (ngSubmit)="formScheduling_ngSubmit($event)"
                style="flex: auto; display: flex; flex-direction: column">
                <div class="dx-field">
                  <div class="dx-field-label">Prepare Time</div>
                  <div class="dx-field-value">
                    <dx-number-box formControlName="startPrepareDuration" placeholder="40">
                      <dx-validator>
                        <dxi-validation-rule
                          type="range"
                          min="10"
                          max="60"
                          message="Prepare Time value is out of range  [10..60]"></dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Finish Time</div>
                  <div class="dx-field-value">
                    <dx-number-box formControlName="finishPrepareDuration" placeholder="40">
                      <dx-validator>
                        <dxi-validation-rule
                          type="range"
                          min="10"
                          max="60"
                          message="Finish Time value is out of range [10..60]"></dxi-validation-rule>
                      </dx-validator>
                    </dx-number-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Overtime Optimization when building route</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        formControlName="overtimeOptimization"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
                <div class="dx-fieldset">
                  <dx-validation-summary></dx-validation-summary>
                  <dx-toolbar>
                    <dxi-item location="after">
                      <dx-button type="normal" text="Reset" (onClick)="resetScheduling_onClick($event)"></dx-button>
                    </dxi-item>

                    <dxi-item location="after">
                      <dx-button
                        type="default"
                        text="Submit"
                        [useSubmitBehavior]="true"
                        [icon]="processingSch ? 'fas fa-circle-notch fa-spin' : null"
                        [disabled]="processingSch"></dx-button>
                    </dxi-item>
                  </dx-toolbar>
                </div>
              </form>
            </fieldset>
          </mat-card>

          <mat-card>
            <div class="dx-fieldset-header">Router</div>
            <div class="dx-fieldset">
              <fieldset>
                <legend style="text-decoration: underline">Enable Router</legend>
                <div class="dx-field">
                  <div class="dx-field-label">Enable</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="enabledComponentsValueChanged()"
                        [(value)]="enabledComponents.router"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </mat-card>

          <mat-card>
            <div class="dx-fieldset-header">Manifest</div>
            <div class="dx-fieldset">
              <fieldset>
                <legend style="text-decoration: underline">Options</legend>
                <div class="dx-field">
                  <div class="dx-field-label">Highlight Trips of Subcription Clients</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="manifestSettingsChanged()"
                        [(value)]="manifestSettings.highlightSubscriptionTrips"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Show Number of Subscription Trips</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="manifestSettingsChanged()"
                        [(value)]="manifestSettings.showSubscriptionTrips"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Show Drop Off Time</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="manifestSettingsChanged()"
                        [(value)]="manifestSettings.showDropOffTime"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Add MTM ETD trips to manifest automatically</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="manifestSettingsChanged()"
                        [(value)]="manifestSettings.saveMtmTripsToManifest"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </mat-card>

          <mat-card>
            <div class="dx-fieldset-header">Auto Dispatch</div>
            <div class="dx-fieldset">
              <fieldset>
                <legend style="text-decoration: underline">Enable Auto Dispatch</legend>
                <div class="dx-field">
                  <div class="dx-field-label">Enable</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        (onValueChanged)="enabledComponentsValueChanged()"
                        [(value)]="enabledComponents.autoDispatch"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend style="text-decoration: underline">Email Assignment</legend>
                <div class="dx-field">
                  <div class="dx-field-label">Assigned Email</div>
                  <div class="dx-field-value">
                    <dx-text-box [value]="assignedEmail" [disabled]="true"></dx-text-box>
                  </div>
                </div>

                <div class="dx-fieldset">
                  <dx-validation-summary></dx-validation-summary>
                  <dx-toolbar>
                    <dxi-item location="after">
                      <dx-button
                        [disabled]="!refresh_token"
                        type="normal"
                        text="Start Watching"
                        (onClick)="startWatching()"></dx-button>
                    </dxi-item>
                    <dxi-item location="after">
                      <dx-button
                        [disabled]="!refresh_token"
                        type="normal"
                        text="Stop Watching"
                        (onClick)="stopWatching()"></dx-button>
                    </dxi-item>
                    <dxi-item location="after">
                      <a class="btn dx-toolbar-button" [href]="gmailPushAuthUrl">
                        <dx-button type="default" text="Assign/Re-assign"></dx-button>
                      </a>
                    </dxi-item>
                  </dx-toolbar>
                </div>
              </fieldset>

              <fieldset>
                <legend style="text-decoration: underline">Options</legend>
                <form
                  [formGroup]="form"
                  (ngSubmit)="form_ngSubmit($event)"
                  style="flex: auto; display: flex; flex-direction: column">
                  <div class="dx-field">
                    <div class="dx-field-label">Max Trip Mileage</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="maxTripMileage" placeholder="15">
                        <dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="10"
                            max="100"
                            message="Max Trip Mileage value is out of range  [10..100]"></dxi-validation-rule>
                        </dx-validator>
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Max Client Weight</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="maxClientWeight" placeholder="250">
                        <dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="200"
                            max="300"
                            message="Max Client Weight value is out of range [200..300]"></dxi-validation-rule>
                        </dx-validator>
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Counties List</div>
                    <div class="dx-field-value">
                      <dx-text-box
                        formControlName="countiesList"
                        placeholder="Philadelphia, Chester, Delaware, Montgomery, Buck"></dx-text-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Shift Preparation Time</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="shiftPreparationDuration" placeholder="30">
                        <dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="10"
                            max="120"
                            message="Shift Preparation Time value is out of range [10..120]"></dxi-validation-rule>
                        </dx-validator>
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Shift Finalization Time</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="shiftFinalizationDuration" placeholder="20">
                        <dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="10"
                            max="120"
                            message="Shift Finalization Time value is out of range [10..120]"></dxi-validation-rule>
                        </dx-validator>
                      </dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Pickup Onboarding Time</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="boardingDuration" placeholder="10"
                        ><dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="5"
                            max="40"
                            message="Pickup Onboarding Time value is out of range [5..40]"></dxi-validation-rule> </dx-validator
                      ></dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">DropOff OffLoadning Time</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="offBoardingDuration" placeholder="10"
                        ><dx-validator>
                          <dxi-validation-rule
                            type="range"
                            min="5"
                            max="40"
                            message="DropOff OffLoadning Time value is out of range [5..40]"></dxi-validation-rule> </dx-validator
                      ></dx-number-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">AutDispatch Reply CC Emails</div>
                    <div class="dx-field-value">
                      <dx-text-box
                        formControlName="replyToEmails"
                        placeholder="email1,Full Name <email2>,email3"></dx-text-box>
                    </div>
                  </div>
                  <div class="dx-field">
                    <div class="dx-field-label">Keep Manifest Trips</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="keepManifestTrips"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-fieldset">
                    <dx-validation-summary></dx-validation-summary>
                    <dx-toolbar>
                      <dxi-item location="after">
                        <dx-button type="normal" text="Reset" (onClick)="reset_onClick($event)"></dx-button>
                      </dxi-item>

                      <dxi-item location="after">
                        <dx-button
                          type="default"
                          text="Submit"
                          [useSubmitBehavior]="true"
                          [icon]="processing ? 'fas fa-circle-notch fa-spin' : null"
                          [disabled]="processing"></dx-button>
                      </dxi-item>
                    </dx-toolbar>
                  </div>
                </form>
              </fieldset>
            </div>
          </mat-card>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</app-content-w-header>

