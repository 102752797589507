import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
//
import { DocumentType, LoggerService } from '../../../../shared/sdk';
import { LoopBackFilter } from '../../../../shared/sdk/models';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { CUSTOM_FIELD_TYPES } from '../../../../shared/modules/ui/components/custom-fields/enums';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { DOC_TYPE_OBJECT_FQN } from '../../classes/enums';

@Component({
  selector: 'app-document-type-form',
  templateUrl: './document-type-form.component.html',
  styleUrls: ['./document-type-form.component.scss'],
})
export class DocumentTypeFormComponent extends ABaseFormComponent<DocumentType> {
  objectFQNs = DOC_TYPE_OBJECT_FQN;
  customFieldTypes = CUSTOM_FIELD_TYPES;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<DocumentType>,
  ) {
    super(logger, fb, dss, helper);

    this.form.valueChanges.subscribe(console.log);
  }

  get customFieldsFormArray(): FormArray {
    return this.form.get('customFields') as FormArray;
  }

  protected get ModelClass(): any {
    return DocumentType;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['customFields'],
    };
  }

  protected buildForm(): void {
    this.formConfigMap.set('customFields', {
      id: [],
      label: ['', Validators.required],
      field: ['', Validators.required],
      type: ['', Validators.required],
      optional: [false],
      order: [0],

      meta: this.fb.group({
        items: [],
        eqField: [],
        defaultValue: [],
      }),

      objectType: [DocumentType.getModelName(), Validators.required],
      objectId: [],
    });

    this.formConfigMap.set('', {
      id: [],
      name: ['', Validators.required],
      defaultNotes: [],
      objectFQN: ['', Validators.required],
      // frequent: [{value: false}],
      order: [0],
      inactive: [false],
      visibility: [],
      type: [],
      expAlert: [false],
      missingDocAlert: [false],

      customFields: this.fb.array([]),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
