import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentType } from '../../../../shared/sdk';
import { ComponentCanDeactivate } from '../../../../shared/modules/my-common/guards/pending-changes.guard';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss'],
})
export class DocumentTypeComponent implements OnInit, ComponentCanDeactivate {
  $selectedIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  selectedId$: Observable<number> = new Observable();

  constructor() {
    this.selectedId$ = this.$selectedIds$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
  }

  ngOnInit() {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return true;
  }

  list_onSelected(items: DocumentType[]) {
    // console.log(items);

    if (items && items.length) {
      this.$selectedIds$.next(items.map(item => item.id));
    } else {
      this.$selectedIds$.next([]);
    }
  }
}
