<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">Overwrite Manifest</div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <p>
    Please confirm that you want to overwrite manifest for {{ data.selectedDate }}. Existing manifest for
    {{ data.selectedDate }} will be lost and new proposed manifest will be applied.
  </p>
  <label>
    <input type="checkbox" [(ngModel)]="overwriteSchedules" /> Overwrite Employee Schedule with new proposed Work
    Start/Finish Information
  </label>
  <br /><br />
  <button mat-button color="primary" (click)="confirmOverwriteManifestDialog()">Yes</button>
  <button mat-button [mat-dialog-close]="false">No</button>
</div>

