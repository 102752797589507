import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
//
import { AlertComponent } from './containers/alert/alert.component';
import { AlertListComponent } from './components/alert-list/alert-list.component';

@NgModule({
  imports: [CommonModule, MyCommonModule, UiModule],
  declarations: [AlertComponent, AlertListComponent],
  exports: [AlertComponent],
  entryComponents: [AlertComponent],
})
export class AlertModule {}
