/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-ext-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-ext-grid/employee-ext-grid.component.ngfactory";
import * as i3 from "../../../services/helper.service";
import * as i4 from "../../../../../shared/sdk/services/custom/Employee";
import * as i5 from "../../../../../shared/sdk/services/custom/EmployeeView";
import * as i6 from "../../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../../shared/sdk/services/custom/Config";
import * as i10 from "../../../../../shared/sdk/services/custom/Facility";
import * as i11 from "../../../../../shared/sdk/services/custom/MyFile";
import * as i12 from "../../../../../shared/modules/my-common/services/datasource.service";
import * as i13 from "../employee-ext-grid/employee-ext-grid.component";
import * as i14 from "../../../../../shared/sdk/services/custom/logger.service";
import * as i15 from "../../../../../shared/modules/ui/services/ui.service";
import * as i16 from "../../../../../shared/modules/my-common/services/state-store.service";
import * as i17 from "../../../../../shared/modules/ui/services/grid-helper.service";
import * as i18 from "@angular/material/dialog";
import * as i19 from "./employee-ext-list.component";
var styles_EmployeeExtListComponent = [i0.styles];
var RenderType_EmployeeExtListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeExtListComponent, data: {} });
export { RenderType_EmployeeExtListComponent as RenderType_EmployeeExtListComponent };
export function View_EmployeeExtListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-employee-ext-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EmployeeExtGridComponent_0, i2.RenderType_EmployeeExtGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.EmployeeApi, i5.EmployeeViewApi, i6.VehicleApi, i7.ConfigService, i8.CommonService, i9.ConfigApi, i10.FacilityApi, i11.MyFileApi, i12.DataSourceService]), i1.ɵdid(2, 245760, null, 0, i13.EmployeeExtGridComponent, [i14.LoggerService, i7.ConfigService, i8.CommonService, i15.UiService, i12.DataSourceService, i16.StateStoreService, i3.HelperService, i3.HelperService, i17.GridHelperService, i18.MatDialog], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EmployeeExtListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-ext-list", [], null, null, null, View_EmployeeExtListComponent_0, RenderType_EmployeeExtListComponent)), i1.ɵdid(1, 114688, null, 0, i19.EmployeeExtListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeExtListComponentNgFactory = i1.ɵccf("app-employee-ext-list", i19.EmployeeExtListComponent, View_EmployeeExtListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { EmployeeExtListComponentNgFactory as EmployeeExtListComponentNgFactory };
