import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import DevExpress from 'devextreme/bundles/dx.all';
//
import { Document, Employee, EmployeePayroll, EmployeePayrollApi, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { dxStoreLoadHooks } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import CustomStore from 'devextreme/data/custom_store';
import { EmployeePayrollEditFormComponent } from './employee-payroll-edit-form/employee-payroll-edit-form.component';
import moment from 'moment';

@Component({
  selector: 'app-employee-details-tab-payroll',
  templateUrl: './employee-details-tab-payroll.component.html',
  styleUrls: ['./employee-details-tab-payroll.component.scss'],
  providers: [DocumentHelperService],
})
export class EmployeeDetailsTabPayrollComponent
  extends ABaseModelPaneWToolbarComponent<Employee>
  implements OnInit, OnChanges, OnDestroy
{
  PAYMENT_ARRANGEMENT = { hourly: 'Hourly', salary: 'Salary', contractor: 'Contractor' };
  historyDSO: DataSourceOptions;
  payroll: EmployeePayroll;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    public docHelper: DocumentHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger, ui, dss);
  }

  onClickEditPayroll = () => {
    this.ui
      .openEditDialog({
        modelId: this.payroll && this.payroll.id,
        inputs: { employeeId: this.model.id },
        ModelClass: EmployeePayroll,
        FormComponentClass: EmployeePayrollEditFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(payroll => {
        this.historyDSO = this.buildHistoryDSO(this.model);
      });
  };

  protected get ModelClass(): any {
    return Employee;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Employee>> {
    return EmployeeFormComponent;
  }

  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.historyDSO = this.buildHistoryDSO(model);
  }

  private buildHistoryDSO(model: Employee): DataSourceOptions {
    const so = this.dss.getStoreOptions(EmployeePayroll, null, false);
    so.customFilter = { where: { employeeId: model.id } };
    const store = new CustomStore(so);
    dxStoreLoadHooks(store, undefined, async (args: any[], [[payroll]]: EmployeePayroll[][]) => {
      this.payroll = (payroll && { ...payroll }) || undefined;
      const rateText = (pa, { rate, rate1 }) => {
        if (pa === 'hourly') return `${rate} dollars per hour`;
        if (pa === 'salary') return `${rate} dollars per year`;
        if (pa === 'contractor') return `${rate} dollars, plus ${rate1} per mile`;
      };
      return [
        ((payroll && payroll.history) || []).map(({ paymentArrangement: pa, ...v }, i) => ({
          id: i,
          ...v,
          paymentArrangement: this.PAYMENT_ARRANGEMENT[pa],
          rateText: rateText(pa, v),
          period:
            (pa === 'contractor' &&
              `${moment(v.start).format('MM/DD/YYYY')} - ${moment(v.end).format('MM/DD/YYYY')}`) ||
            '',
        })),
      ];
    });

    return { store } as DataSourceOptions;
  }
}
