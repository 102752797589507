<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">
        {{ data?.title }}
      </div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="content">
  <dx-data-grid [dataSource]="dso$ | async" [remoteOperations]="false" [errorRowEnabled]="false">
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxi-column caption="Time" dataField="ctime" dataType="date" format="shortDateShortTime"></dxi-column>
    <dxi-column caption="Device" dataField="device" format="string"></dxi-column>
    <dxi-column caption="User" dataField="user" format="string"></dxi-column>
    <dxi-column caption="Status" dataField="status"></dxi-column>
    <dxi-column caption="Scheduling Mode" dataField="mode"></dxi-column>
    <dxi-column caption="Work Start Time" dataField="startTime" dataType="date" format="shortTime"></dxi-column>
    <dxi-column
      caption="Calculation Time"
      dataField="startTimeCalculatedAt"
      dataType="date"
      format="shortDateShortTime"
      [allowFiltering]="false"></dxi-column>
    <!-- <dxi-column caption="Work Finish Time" dataField="finishTime" dataType="date" format="shortTime"></dxi-column> -->
    <!-- <dxi-column
      caption="Calculation Time"
      dataField="finishTimeCalculatedAt"
      dataType="date"
      format="shortDateShortTime"
      [allowFiltering]="false"></dxi-column> -->
    <dxi-column caption="Work Start Location" dataField="startLocationAddress" dataType="string"></dxi-column>
    <!-- <dxi-column caption="Work Finish Location" dataField="finishLocationAddress" dataType="string"></dxi-column> -->
    <dxi-column caption="First PU Time" dataField="startTripTime" dataType="date" format="shortTime"></dxi-column>
    <!-- <dxi-column caption="Larst DO Time" dataField="finishTripTime" dataType="date" format="shortTime"></dxi-column> -->
    <dxi-column caption="Start Duration" dataField="startTravelDuration"></dxi-column>
    <!-- <dxi-column caption="Finish Duration" dataField="finishTravelDuration"></dxi-column> -->
    <dxi-column caption="Start PU Name" dataField="startTripConsumerName" dataType="string"></dxi-column>
    <dxi-column caption="Start PU Location" dataField="startTripLocationAddress" dataType="string"></dxi-column>
    <!-- <dxi-column caption="Finish DO Name" dataField="finishTripConsumerName" dataType="string"></dxi-column> -->
    <!-- <dxi-column caption="Finish DO Location" dataField="finishTripLocationAddress" dataType="string"></dxi-column> -->
  </dx-data-grid>
</div>

