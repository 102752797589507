<div class="filter-panel">
  <dx-select-box
    width="180px"
    height="30px"
    [dataSource]="weeks"
    displayExpr="name"
    valueExpr="value"
    [(value)]="week"
    placeholder="Select a week"
    (onValueChanged)="calendar_onValueChanged($event)"></dx-select-box>

  <span class="estimate-time-label">Show Estimate time:</span>
  <span *ngFor="let day of days" class="day">
    <div>{{ day.caption }}</div>
    <div>
      <input
        type="checkbox"
        [checked]="day.checked"
        (change)="day_onChanged($event, day)"
        [disabled]="!day.estimated" />
    </div>
  </span>
</div>
<dx-data-grid
  #grid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>
  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>
  <dxo-export [enabled]="true" fileName="employees-perf-export"></dxo-export>

  <!-- Columns -->
  <dxi-column caption="First Name" dataField="person.firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string"></dxi-column>

  <dxi-column caption="Sex" dataField="person.sex" dataType="string">
    <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Position" dataField="employeePosition.name" dataType="string"></dxi-column>

  <dxi-column
    *ngIf="days[0].checked"
    [caption]="getDay(0)"
    dataField="monMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[1].checked"
    [caption]="getDay(1)"
    dataField="tueMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[2].checked"
    [caption]="getDay(2)"
    dataField="wedMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[3].checked"
    [caption]="getDay(3)"
    dataField="thuMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[4].checked"
    [caption]="getDay(4)"
    dataField="friMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[5].checked"
    [caption]="getDay(5)"
    dataField="satMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    *ngIf="days[6].checked"
    [caption]="getDay(6)"
    dataField="sunMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column caption="Total" dataField="totalMinutes" cellTemplate="hours_cell" [allowFiltering]="false"></dxi-column>

  <dxi-column
    caption="Regular"
    dataField="regularMinutes"
    cellTemplate="hours_cell"
    [allowFiltering]="false"></dxi-column>

  <dxi-column
    caption="Overtime"
    dataField="overtimeMinutes"
    cellTemplate="overtime_cell"
    sortOrder="desc"
    [allowFiltering]="false"></dxi-column>

  <dxi-column caption="Working Days" dataField="days" [allowFiltering]="false"></dxi-column>

  <!-- Templates -->
  <ng-container *dxTemplate="let cellInfo of 'hours_cell'">
    <span>{{ getHours(cellInfo.value) }}</span>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'overtime_cell'">
    <span [ngClass]="cellInfo.data.class">{{ getHours(cellInfo.value) }}</span>
  </ng-container>
</dx-data-grid>

