import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
//
import { DecPending, IncPending } from '../../../../store/actions/core';
import { AppState } from '../../../../store/reducers';
import { CookieBrowser, LoopBackAuth, LoopBackConfig } from '../../../sdk';
import { LoggerService } from '../../../sdk/services/custom';
import { QrCodePipe } from '../pipes/qr-code.pipe';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    @Inject(LoggerService) public logger: LoggerService,
    @Inject(Store) public store: Store<AppState>,
    @Inject(LoopBackAuth) public auth: ExtLoopBackAuth, // @Inject(CookieBrowser) public cookie: CookieBrowser,
  ) {
    //    this.overrideNativeMethods();
  }

  buildUrlPath(Model: any): string {
    return [LoopBackConfig.getPath(), LoopBackConfig.getApiVersion(), Model.getModelDefinition().path].join('/');
  }

  incPending() {
    this.store.dispatch(new IncPending());
  }

  decPending() {
    this.store.dispatch(new DecPending());
  }

  printHtmlSrc(document: Document, source: string) {
    return of(source).pipe(
      map(src => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        return { src, iframe };
      }),
      switchMap(
        ({ src, iframe }) =>
          new Promise<HTMLIFrameElement>((resolve, reject) => {
            iframe.onload = () => resolve(iframe);
            iframe.onerror = err => reject(err);
            iframe.src = source;
          }),
      ),
      tap(iframe => {
        iframe.contentWindow.print();
        setTimeout(() => iframe.parentNode.removeChild(iframe));
      }),
    );
  }

  printHtml(document: Document, html: string) {
    return of(html).pipe(
      map(_html => {
        const blob = new Blob([_html], { type: 'text/html' });
        return URL.createObjectURL(blob);
      }),
      switchMap(src => this.printHtmlSrc(document, src)),
    );
  }

  /**
   * some third party modules uses ajax request (dx-upload)
   */
  private overrideNativeMethods() {
    (send => {
      XMLHttpRequest.prototype.send = function (...args) {
        const xhr = this;

        // !!! DO NOT USE with CORS
        //        xhr.withCredentials = true;

        // let headers: HttpHeaders = new HttpHeaders();
        // headers = self.authenticate(headers);
        // headers.keys().forEach(key => xhr.setRequestHeader(key, headers.get(key)));

        // console.log('XMLHttpRequest: ', xhr);

        return send.apply(xhr, args);
      };
    })(XMLHttpRequest.prototype.send);
  }

  printQr(string: string) {
    const base64_1 = new QrCodePipe().transform(string, { size: 100 });
    const base64_2 = new QrCodePipe().transform(string, { size: 200 });
    const base64_3 = new QrCodePipe().transform(string, { size: 300 });

    return this.printHtml(
      document,
      `<html><body>
<img src="${base64_1}"/>
<img src="${base64_2}"/>
<img src="${base64_3}"/>
</body></html>`,
    );
  }

  printSizedQr(string: string, sizes: number[]) {
    const base64Images = sizes.map(s => new QrCodePipe().transform(string, { size: s }));

    return this.printHtml(
      document,
      `<html><body>` + base64Images.map(i => `<img src="${i}"/>`).join('&nbsp;') + `</body></html>`,
    );
  }
}
