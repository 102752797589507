import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import { Subscription } from 'rxjs';
import { DocumentType, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { DOC_TYPE_OBJECT_FQN } from '../../classes/enums';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-document-type-grid',
  templateUrl: './document-type-grid.component.html',
  styleUrls: ['./document-type-grid.component.scss'],
})
export class DocumentTypeGridComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  docTypesObjectFQNs = DOC_TYPE_OBJECT_FQN;

  dso: DataSourceOptions;
  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<DocumentType[]> = new EventEmitter<DocumentType[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('336a27d8-3735-4c7a-ab35-fcac209c3ded');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '857d0f87-2fc7-4900-a89d-f7c41109e1b4',
    };

    this.dso = dss.getDataSourceOptions(DocumentType);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dss.modifiedEvent.subscribe(modelName => {
        if ([DocumentType.getModelName()].includes(modelName)) {
          if (this.grid && this.grid.instance) {
            this.grid.instance.refresh();
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      filter: this.savingFieldFilter,
      mapper: this.savingFieldMapper,
    });
  }

  grid_onSelectionChanged(e: any): void {
    // this.log.log(data);
    this.mySelected.emit(e.selectedRowsData);
  }

  savingFieldFilter = (key, value) => {
    return !key.startsWith('_');
  };

  savingFieldMapper = (key, value): [string, any] => {
    if (isString(value) && isEmpty(value)) {
      value = null;
    }

    return [key, value];
  };
}
