import { Inject, Injectable } from '@angular/core';
import { Facility, FacilityView } from '../../../shared/sdk/models';
import { FacilityApi, FacilityViewApi } from '../../../shared/sdk/services/custom';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';

@Injectable()
export class HelperService {
  displayExpr = (f: Facility | FacilityView): string => {
    return f.name || '';
  };

  constructor(
    @Inject(FacilityApi) public api: FacilityApi,
    @Inject(FacilityViewApi) public viewApi: FacilityViewApi,
    @Inject(ConfigService) public config: ConfigService,
  ) {}
}
