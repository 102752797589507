import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Vehicle } from '../../../../shared/sdk/models';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-vehicle-drop-down-grid',
  templateUrl: './vehicle-drop-down-grid.component.html',
  styleUrls: ['./vehicle-drop-down-grid.component.scss'],
  providers: [
    HelperService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehicleDropDownGridComponent),
      multi: true,
    },
  ],
})
export class VehicleDropDownGridComponent implements OnInit, ControlValueAccessor {
  _onChange: any;
  _onTouched: any;

  @Input() dataSource: any;
  @Input() grid_columns: any[] = [
    { dataField: 'internalId', caption: 'Internal Id' },
    //    {dataField: 'make', caption: 'Make'},
    //    {dataField: 'model', caption: 'Model'},
  ];
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() width: any = 'auto';
  @Input() value: any;
  @Input() filter: any = [['status', '=', 'ACTIVE']];

  @Input() showClearButton = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Output() grid_cellPrepared: EventEmitter<any> = new EventEmitter();

  constructor(private dss: DataSourceService, public config: ConfigService, public helper: HelperService) {
    this.buildDataSource();
  }

  ngOnInit() {
    this.valueChange.subscribe(value => this._onChange && this._onChange(value));
    this.focusOut.subscribe(() => this._onTouched && this._onTouched());
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private buildDataSource() {
    this.dataSource =
      this.dataSource ||
      ({
        store: this.dss.getStore(Vehicle, null, false),
        filter: this.filter,
      } as DataSourceOptions);
  }
}
