<ng-container *ngIf="value !== undefined">
  <div class="dx-field">
    <div class="dx-field-label" [title]="title || field">{{ title || field }}</div>

    <div class="dx-field-value-static" [ngStyle]="{ color: issues?.length ? 'red' : '' }">
      <ng-container [ngSwitch]="desc?.type">
        <ng-container *ngSwitchCase="'boolean'">{{ value | yesNo }}</ng-container>
        <ng-container *ngSwitchCase="'number'">
          {{ value }}
          {{ desc?.range ? ' of ' + desc?.range[1] : '' }}
          {{ desc?.unit ? ' ' + desc?.unit : '' }}
        </ng-container>
        <ng-container *ngSwitchCase="'date'">{{ value | utc: 'YYYY-MM-DD' }}</ng-container>
        <ng-container *ngSwitchCase="'datetime'">{{ value | local: 'YYYY-MM-DD HH:mm' }}</ng-container>
        <ng-container *ngSwitchDefault>{{ value }}</ng-container>
      </ng-container>

      <ng-container *ngIf="issues?.length > 0 && model[desc?.issueText]?.length">
        <div>
          <em>{{ model[desc?.issueText] }}</em>
        </div>
      </ng-container>

      <ng-container *ngIf="model.pictures[field]?.length > 0">
        <div style="display: flex; flex-wrap: wrap">
          <ng-container *ngFor="let pic of model.pictures[field]">
            <div style="flex-basis: 25%">
              <img
                style="width: 99%; border: 1px solid white; cursor: zoom-in"
                [src]="pic | pictureLink: 'resize-200' | async | sanitizeUrl"
                (click)="showPic(pic)" />
            </div>
          </ng-container>
        </div>
        <hr />
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!desc">
  <div class="dx-field">
    <div class="dx-field-label" [title]="title || field">{{ title || field }}</div>

    <div class="dx-field-value-static">
      <ng-container *ngIf="model.pictures[field]?.length > 0">
        <div style="display: flex; flex-wrap: wrap">
          <ng-container *ngFor="let pic of model.pictures[field]">
            <div style="flex-basis: 25%">
              <img
                style="width: 99%; border: 1px solid white; cursor: zoom-in"
                [src]="pic | pictureLink: 'resize-200' | async | sanitizeUrl"
                (click)="showPic(pic)" />
            </div>
          </ng-container>
        </div>
        <hr />
      </ng-container>
    </div>
  </div>
</ng-container>

<dx-popup
  [width]="'100%'"
  [height]="'100%'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupVisible">
  <div
    *dxTemplate="let data of 'content'"
    style="cursor: zoom-out; background-position: center; background-repeat: no-repeat; background-size: contain"
    [style.background-image]="'url(\'' + (currentPic | pictureLink: '':true | async) + '\')' | sanitizeStyle"
    (click)="popupVisible = false"></div>
</dx-popup>
