import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { DocumentModule } from '../document/document.module';
//
import { ConsumerDetailsTabDocumentComponent } from './components/consumer-details-tab-document/consumer-details-tab-document.component';
import { ConsumerDetailsTabInstructionsComponent } from './components/consumer-details-tab-instructions/consumer-details-tab-instructions.component';
import { ConsumerDetailsTabProfileComponent } from './components/consumer-details-tab-profile/consumer-details-tab-profile.component';
import { ConsumerDetailsTabsComponent } from './components/consumer-details-tabs/consumer-details-tabs.component';
import { ConsumerDetailsComponent } from './components/consumer-details/consumer-details.component';
import { ConsumerFormComponent } from './components/consumer-form/consumer-form.component';
import { ConsumerGridComponent } from './components/consumer-grid/consumer-grid.component';
import { ConsumerListComponent } from './components/consumer-list/consumer-list.component';
import { ConsumerComponent } from './containers/consumer/consumer.component';
import { AssignFromBrokerComponent } from './components/assign-from-broker/assign-from-broker.component';
import { CtsBrokerTripsComponent } from './components/cts-broker-trips/cts-broker-trips.component';
import { MtmBrokerTripsComponent } from './components/mtm-broker-trips/mtm-broker-trips.component';
import { BrokerTripsComponent } from './containers/broker-trips/broker-trips.component';
import { AllConsumerGridComponent } from './components/all-consumer-grid/all-consumer-grid.component';
import { HhaRecordsComponent } from './components/hha-records/hha-records.component';
import { EligibilityGridComponent } from './components/eligibility-grid/eligibility-grid.component';
import { ConsumerNotesComponent } from './components/consumer-notes/consumer-notes.component';
import { MtmClaimsComponent } from './components/mtm-claims/mtm-claims.component';
import { CtsClaimsComponent } from './components/cts-claims/cts-claims.component';
import { ConsumerMasterDetailsComponent } from './components/consumer-master-details/consumer-master-details.component';
import { LgtcBrokerTripsComponent } from './components/lgtc-broker-trips/lgtc-broker-trips.component';
import { LgtcClientsComponent } from './components/lgtc-clients/lgtc-clients.component';
import { DlgCalendarComponent } from './dialogs/dlg-calendar/dlg-calendar.component';
import { MtmNotAssignedTripsComponent } from './components/mtm-not-assigned-trips/mtm-not-assigned-trips.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [
    ConsumerComponent,
    ConsumerListComponent,
    ConsumerDetailsComponent,
    ConsumerGridComponent,
    ConsumerDetailsTabsComponent,
    ConsumerDetailsTabProfileComponent,
    ConsumerDetailsTabInstructionsComponent,
    ConsumerDetailsTabDocumentComponent,
    ConsumerFormComponent,
    AssignFromBrokerComponent,
    BrokerTripsComponent,
    AllConsumerGridComponent,
    HhaRecordsComponent,
    EligibilityGridComponent,
    ConsumerNotesComponent,
    MtmBrokerTripsComponent,
    CtsBrokerTripsComponent,
    LgtcBrokerTripsComponent,
    MtmClaimsComponent,
    CtsClaimsComponent,
    ConsumerMasterDetailsComponent,
    LgtcClientsComponent,
    DlgCalendarComponent,
    MtmNotAssignedTripsComponent,
  ],
  exports: [
    ConsumerComponent,
    AssignFromBrokerComponent,
    BrokerTripsComponent,
    AllConsumerGridComponent,
    EligibilityGridComponent,
    HhaRecordsComponent,
    ConsumerNotesComponent,
    MtmBrokerTripsComponent,
    MtmNotAssignedTripsComponent,
    CtsBrokerTripsComponent,
    LgtcBrokerTripsComponent,
    MtmClaimsComponent,
    CtsClaimsComponent,
    ConsumerMasterDetailsComponent,
    LgtcClientsComponent,
  ],
  entryComponents: [
    ConsumerComponent,
    ConsumerFormComponent,
    AssignFromBrokerComponent,
    ConsumerNotesComponent,
    DlgCalendarComponent,
  ],
})
export class ConsumerModule {}
