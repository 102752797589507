
declare var Object: any;
export interface AcquisitionRelatedContactInterface {
  "type"?: string;
  "businessName"?: string;
  "address"?: string;
  "phone"?: string;
  "meta"?: any;
  "id"?: any;
}

export class AcquisitionRelatedContact implements AcquisitionRelatedContactInterface {
  "type": string;
  "businessName": string;
  "address": string;
  "phone": string;
  "meta": any;
  "id": any;
  constructor(data?: AcquisitionRelatedContactInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AcquisitionRelatedContact`.
   */
  public static getModelName() {
    return "AcquisitionRelatedContact";
  }

  /**
  * This method creates an instance of AcquisitionRelatedContact for dynamic purposes.
  **/
  public static factory(data: AcquisitionRelatedContactInterface): AcquisitionRelatedContact{
    return new AcquisitionRelatedContact(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AcquisitionRelatedContact',
      plural: 'AcquisitionRelatedContacts',
      path: 'AcquisitionRelatedContacts',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "businessName": {
          name: 'businessName',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
