<div>
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onCellPrepared)="grid_onCellPrepared($event)">
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-selection mode="multiple" selectAllMode="page" showCheckBoxesMode="always"></dxo-selection>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-export [enabled]="true" fileName="LGTC-Broker-Trips"></dxo-export>

    <!-- region Columns -->

    <dxi-column
      caption="Assigned"
      dataField="_manifestRecId"
      dataType="string"
      [customizeText]="manifestRecCustomizeText"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true">
      <dxo-header-filter [dataSource]="assignedHeaderFilter"></dxo-header-filter>
    </dxi-column>

    <dxi-column
      caption="Broker"
      dataField="_broker"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Date"
      dataField="_date"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [customizeText]="config.customizeShortDateText"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Trip Number"
      dataField="_tripId"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Program"
      dataField="program"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="AWS"
      dataField="aws"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client Name"
      dataField="client_name"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Weekdays"
      dataField="weekdays"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Name"
      dataField="pu_name"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Address"
      dataField="pu_address"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Apt"
      dataField="pu_apt"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Bldg?"
      dataField="pu_bldg?"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU City"
      dataField="pu_city"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU State"
      dataField="pu_state"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Zip"
      dataField="pu_zip"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Phone"
      dataField="pu_phone"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Time"
      dataField="pu_time"
      dataType="datetime"
      format="shortTime"
      [editorOptions]="{ type: 'time', pickerType: 'native' }"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Name"
      dataField="do_name"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Address"
      dataField="do_address"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Apt"
      dataField="do_apt"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Bldg"
      dataField="do_bldg?"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO City"
      dataField="do_city"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO State"
      dataField="do_state"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Zip"
      dataField="do_zip"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Phone"
      dataField="do_phone"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Time"
      dataField="do_time"
      dataType="datetime"
      format="shortTime"
      [editorOptions]="{ type: 'time', pickerType: 'native' }"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Will Call Time"
      dataField="wc_time"
      dataType="datetime"
      format="shortTime"
      [editorOptions]="{ type: 'time', pickerType: 'native' }"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client DOB"
      dataField="client_dob"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client Age"
      dataField="client_age"
      dataType="number"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client Sex"
      dataField="client_sex"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client Phone"
      dataField="client_phone"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Escort"
      dataField="aesc"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Miles"
      dataField="miles"
      dataType="number"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Notes"
      dataField="notes"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="PU Dir"
      dataField="pu_dir"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="DO Dir"
      dataField="do_dir"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Client ID"
      dataField="client_id"
      dataType="string"
      [allowSorting]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <!-- -->

    <dxi-column
      caption="Imported Date"
      dataField="_ctime"
      dataType="datetime"
      format="shortDateShortTime"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Updated Date"
      dataField="_rtime"
      dataType="datetime"
      format="shortDateShortTime"
      [allowHeaderFiltering]="false"></dxi-column>

    <!-- -->

    <dxi-column
      caption="Facility"
      dataType="string"
      cellTemplate="facilityCellTpl"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <!--  endregion  -->

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'facilityCellTpl'">
      {{ cellInfo.data._facility }}
    </ng-container>
  </dx-data-grid>
</div>
