<div class="toolbar">
  <div class="dx-fieldset">
    <div style="display: flex; align-items: center">
      <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
      <span>&nbsp;&ndash;&nbsp;</span>
      <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
    </div>
    <label>Utilization:</label>
    <dx-select-box [dataSource]="showUtilizationDS" valueExpr="value" displayExpr="title" [(value)]="showUtilization" placeholder="Show" width="250px"></dx-select-box>
    <label>Show:</label>
    <dx-select-box [dataSource]="['Mileage', 'Transports', 'Facility', 'Driver', 'Facility-Driver']" [(value)]="showType" placeholder="Show" width="110px"></dx-select-box>
    <div style="display: inline-block">
      <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
    </div>
  </div>

  <div class="dx-fieldset" style="display: flex">
    <div>
      <dx-check-box text="Vehicles Moved" [(value)]="vehiclesMoved"></dx-check-box>
    </div>
    <div>
      <dx-check-box text="Vehicles Transported Clients" [(value)]="vehiclesTransportedClients"></dx-check-box>
    </div>
  </div>
</div>

<dx-data-grid
  #grid
  [dataSource]="dso"
  [columns]="gridColumns"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  (onInitialized)="grid_onInitialized($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="false" [showInfo]="true" [showNavigationButtons]="false"></dxo-pager>

  <dxo-export [enabled]="true" fileName="vehicles-ext-export"></dxo-export>

  <!-- Columns -->

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'day_cell'">
    <div [class]="'cell ' + getColor(cellInfo.data, cellInfo.column)" [title]="getTitle(cellInfo.data, cellInfo.column)">&nbsp;{{cellInfo.value}}</div>
  </ng-container>

</dx-data-grid>
