import {
  AccessToken,
  Employee,
  Document,
  MyFile
} from '../index';

declare var Object: any;
export interface MyUserInterface {
  "status": string;
  "data"?: any;
  "mfaEmailEnabled"?: boolean;
  "mfaSmsEnabled"?: boolean;
  "lastLoginTime"?: Date;
  "lastOnlineTime"?: Date;
  "dtime"?: Date;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "password"?: string;
  accessTokens?: AccessToken[];
  employee?: Employee;
  documents?: Document[];
  files?: MyFile[];
}

export class MyUser implements MyUserInterface {
  "status": string;
  "data": any;
  "mfaEmailEnabled": boolean;
  "mfaSmsEnabled": boolean;
  "lastLoginTime": Date;
  "lastOnlineTime": Date;
  "dtime": Date;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "password": string;
  accessTokens: AccessToken[];
  employee: Employee;
  documents: Document[];
  files: MyFile[];
  constructor(data?: MyUserInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyUser`.
   */
  public static getModelName() {
    return "MyUser";
  }

  /**
  * This method creates an instance of MyUser for dynamic purposes.
  **/
  public static factory(data: MyUserInterface): MyUser{
    return new MyUser(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyUser',
      plural: 'MyUsers',
      path: 'MyUsers',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "mfaEmailEnabled": {
          name: 'mfaEmailEnabled',
          type: 'boolean'
        },
        "mfaSmsEnabled": {
          name: 'mfaSmsEnabled',
          type: 'boolean'
        },
        "lastLoginTime": {
          name: 'lastLoginTime',
          type: 'Date'
        },
        "lastOnlineTime": {
          name: 'lastOnlineTime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'AccessToken[]',
          model: 'AccessToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'ownerId'
        },
        files: {
          name: 'files',
          type: 'MyFile[]',
          model: 'MyFile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'uploadedById'
        },
      }
    }
  }
}
