<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label" title="Fire Extinguisher Installed">Fire Extinguisher Installed</div>
        <div class="dx-field-value-static">
          {{ model?.fireExtinguisherInstalled | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label" title="Fire Extinguisher Exp. Date">Fire Extinguisher Exp. Date</div>
        <div class="dx-field-value-static">
          {{ model?.fireExtinguisherExp }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label" title="Fire Extinguisher Installed">Fire Extinguisher 2 Installed</div>
        <div class="dx-field-value-static">
          {{ model?.fireExtinguisher2Installed | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label" title="Fire Extinguisher Exp. Date">Fire Extinguisher 2 Exp. Date</div>
        <div class="dx-field-value-static">
          {{ model?.fireExtinguisher2Exp }}
        </div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">GPS Unit Installed</div>
        <div class="dx-field-value-static">
          {{ model?.gpsUnitInstalled | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">GPS Bypass Used</div>
        <div class="dx-field-value-static">
          {{ model?.gpsBypassUsed | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">GPS Unit Model</div>
        <div class="dx-field-value-static">
          {{ model?.gpsUnitModel }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">GPS Serial Number</div>
        <div class="dx-field-value-static">
          {{ model?.gpsSerialNumber }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">GPS Installed Date</div>
        <div class="dx-field-value-static">
          {{ model?.gpsInstalledDate }}
        </div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">Camera Installed</div>
        <div class="dx-field-value-static">
          {{ model?.cameraInstalled | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Camera Model</div>
        <div class="dx-field-value-static">
          {{ model?.cameraModel }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Camera Serial Number</div>
        <div class="dx-field-value-static">
          {{ model?.cameraSerialNumber }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Camera Installed Date</div>
        <div class="dx-field-value-static">
          {{ model?.cameraInstalledDate }}
        </div>
      </div>
    </div>
  </ng-container>
</app-details-tab>
