import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxToolbarComponent } from 'devextreme-angular/ui/toolbar';
import { takeUntil } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackFilter, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { HelperService } from '../../services/helper.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-trips-audit-details',
  templateUrl: './trips-audit-details.component.html',
  styleUrls: ['./trips-audit-details.component.scss'],
  providers: [HelperService],
})
export class TripsAuditDetailsComponent
  extends ABaseModelLoaderComponent<Vehicle>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() data: any = { markers: [], routes: [] };
  mapCenter = { lat: 39.98887229222728, lng: -75.15665435443266 };
  mapZoom = 11;
  autoAdjust = true;

  @ViewChild('toolbar', { static: true }) toolbar: DxToolbarComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
    private helper: HelperService,
  ) {
    super(logger, dss);
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  protected get observeModels(): any[] {
    return [this.ModelClass];
  }

  repaint() {
    // this.toolbar.instance.repaint();
    // this.details.repaint();
  }
}
