/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/modules/my-common/pipes/file-signed-url.pipe";
import * as i4 from "../../../../shared/sdk/services/custom/MyFile";
import * as i5 from "./personal-photo.component";
import * as i6 from "../../../../shared/sdk/services/custom/logger.service";
import * as i7 from "@angular/material/dialog";
var styles_PersonalPhotoComponent = [i0.styles];
var RenderType_PersonalPhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalPhotoComponent, data: {} });
export { RenderType_PersonalPhotoComponent as RenderType_PersonalPhotoComponent };
function View_PersonalPhotoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "image-container"]], [[4, "backgroundImage", null]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + (i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.person.personalPhoto.avatar)))) || "/assets/images/avatar-placeholder.png")) + ")"); _ck(_v, 0, 0, currVal_0); }); }
function View_PersonalPhotoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "image-container avatar-placeholder"]], null, null, null, null, null))], null, null); }
export function View_PersonalPhotoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FileSignedUrlPipe, [i4.MyFileApi]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "avatar-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPhotoComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["placeholder", 2]], null, 0, null, View_PersonalPhotoComponent_2)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "icon-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCropper() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["edit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.person.personalPhoto == null) ? null : _co.person.personalPhoto.avatar); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_PersonalPhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-photo", [], null, null, null, View_PersonalPhotoComponent_0, RenderType_PersonalPhotoComponent)), i1.ɵdid(1, 114688, null, 0, i5.PersonalPhotoComponent, [i6.LoggerService, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPhotoComponentNgFactory = i1.ɵccf("app-personal-photo", i5.PersonalPhotoComponent, View_PersonalPhotoComponent_Host_0, { person: "person" }, {}, []);
export { PersonalPhotoComponentNgFactory as PersonalPhotoComponentNgFactory };
