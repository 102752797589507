<div [ngSwitch]="mode$ | async">
  <ng-container *ngSwitchCase="'SET_TENANT'">
    <app-select-tenant-popup
      class="old-dx-swatch-material"
      [popupVisible]="true"
      [ids]="userTenants$ | async"
      [isSU]="isSU$ | async"
      [isPending]="isSetTenantPending$ | async"
      [error]="setTenantError$ | async"
      (mySetTenant)="onSetTenant($event)"
      (myCancel)="onSetTenantCancel($event)"
    ></app-select-tenant-popup>
  </ng-container>

  <ng-container *ngSwitchCase="'LOGOUT'">
    <mat-card>
      <mat-card-header>
        <mat-card-title><h2>Sign Out</h2></mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-signout-form
          [signedUser]="signedUser$ | async"
          [isSubmitting]="isLogoutPending$ | async"
          [error]="logoutError$ | async"
          (mySubmit)="onLogout($event)"></app-signout-form>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <mat-card>
      <mat-card-header>
        <mat-card-title><h2>Dispatch App</h2></mat-card-title>
        <mat-card-subtitle>Sign In to your account</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-signin-form
          [isSubmitting]="isLoginPending$ | async"
          [mfaSecret]="mfaSecret$ | async"
          [signedUser]="signedUser$ | async"
          [error]="loginError$ | async"
          (mySubmit)="onLogin($event)"></app-signin-form>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
