import { Inject, Injectable } from '@angular/core';
import { InternalStorage, LoggerService, State, StateApi } from '../../../sdk';
import { map } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { Observable, of } from 'rxjs';

@Injectable()
export class StateStoreService {
  constructor(
    @Inject(LoggerService) private logger: LoggerService,
    @Inject(StateApi) private api: StateApi,
    private internalStorage: InternalStorage,
  ) {}

  get resetState() {
    return {
      filterValue: null,
      searchText: '',
      focusedRowKey: null,
      selectedRowKeys: [],
      selectionFilter: undefined,
    };
  }

  buildOptions(
    key: string,
    savingTimeout: number = 1000,
    storageType: 'remote' | 'local' = 'remote',
    extState = {},
  ): any {
    const load$: (key: string) => Observable<any> =
      storageType === 'remote' ? this.load$.bind(this) : this.load.bind(this);
    const save$: (key: string, stateData: any) => Observable<any> =
      storageType === 'remote' ? this.save$.bind(this) : this.save.bind(this);

    return {
      enabled: true,
      savingTimeout,
      storageKey: key,
      type: 'custom',

      customLoad(): Promise<any> {
        return load$(this.storageKey).toPromise();
      },

      customSave(gridState): Promise<any> {
        return save$(this.storageKey, { ...gridState, ...extState }).toPromise();
      },
    };
  }

  private load(key: string) {
    const state = this.internalStorage.get('widget_state_' + key);
    // console.log('load state', state);
    return of(state);
  }

  private load$(key: string) {
    return this.api.find<State>({ where: { key }, limit: 1 }).pipe(map(([st]) => oc(st).data({})));
  }

  private save(key: string, stateData: any) {
    // console.log('save state', stateData);
    this.internalStorage.set('widget_state_' + key, stateData);
    return of(stateData);
  }

  private save$(key: string, stateData: any) {
    const state: State = new State({ key, data: stateData });
    return this.api.upsertWithWhere<State>({ key }, state);
  }
}
