import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
import moment, { duration } from 'moment';

@Component({
  selector: 'app-vehicle-details-tab-activity',
  templateUrl: './vehicle-details-tab-activity.component.html',
  styleUrls: ['./vehicle-details-tab-activity.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class VehicleDetailsTabActivityComponent
  extends ABaseModelPaneWToolbarComponent<Vehicle>
  implements OnInit, OnDestroy
{
  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
  ) {
    super(logger, ui, dss);
    this.caption = 'Activity';
  }

  getDuration(seconds: number) {
    return (seconds && moment.utc(duration(seconds, 'seconds').as('milliseconds')).format('HH:mm:ss')) || '';
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Vehicle>> {
    return VehicleFormComponent;
  }
  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.dso = this.buildDSO(model);
  }

  private buildDSO(model: Vehicle) {
    return {
      load: async () => {
        const filter = { where: { vin: model.vin }, fields: { positions: false } };
        const data = await this.dss.getApi(VehicleGeotab).find<VehicleGeotab>(filter).toPromise();
        return this.prepareData(data);
      },
    } as DataSourceOptions;
  }

  private prepareData(data: any) {
    const days = data
      .filter(d => d.days)
      .flatMap(d => d.days)
      .map(day => {
        const d = '' + day.day;
        const date = `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(-2)}`;
        return {
          ...day,
          id: day.day,
          date: date,
          distance: Math.round(day.distance / 1.60934),
          onDutyDuration: this.getDuration(+day.onDutyDuration),
          onDutyEngineOnDuration: this.getDuration(+day.onDutyEngineOnDuration),
          onDutyEngineOffDuration: this.getDuration(+day.onDutyEngineOffDuration),
          onDutyIdleDuration: this.getDuration(+day.onDutyIdleDuration),
          onDutyIdle20Periods: day.onDutyIdle20Periods,
          // onDutyIdle20Duration: this.getDuration(+day.onDutyIdle20Duration),
          onDutyIdleAbove20Duration: this.getDuration(+day.onDutyIdleAbove20Duration),
          onDutyIdle30Periods: day.onDutyIdle30Periods,
          // onDutyIdle30Duration: this.getDuration(+day.onDutyIdle30Duration),
          onDutyIdleAbove30Duration: this.getDuration(+day.onDutyIdleAbove30Duration),
          onDutyDrivingDuration: this.getDuration(+day.onDutyDrivingDuration),
          totalTimeOut: this.getDuration(+day.totalTimeOut),
          alerts: (day.alerts || []).join(', '),
        };
      });
    return days;
  }
}
