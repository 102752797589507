import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../services/config.service';
import { of } from 'rxjs';
import intersection from 'lodash-es/intersection';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "../../../sdk/services/core/auth.service";
import * as i4 from "../services/config.service";
export class DashboardGuard {
    constructor(router, store, auth, config) {
        this.router = router;
        this.store = store;
        this.auth = auth;
        this.config = config;
    }
    canActivate(next, state) {
        const url = state.url;
        return this.checkAsync(url);
    }
    checkAsync(url) {
        return this.config.roles$.pipe(switchMap(roles => {
            if (intersection(['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'], roles).length > 0) {
                this.router.navigate(['/dashboard/vehicles-backup']);
                return of(false);
            }
            else if (intersection(['CLERK', 'MANAGER'], roles).length > 0) {
                this.router.navigate(['/dashboard/scheduling/manifest']);
                return of(false);
            }
            else {
                this.router.navigate(['dashboard', 'empty']);
                return of(false);
            }
        }));
    }
}
DashboardGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardGuard_Factory() { return new DashboardGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.LoopBackAuth), i0.ɵɵinject(i4.ConfigService)); }, token: DashboardGuard, providedIn: "root" });
