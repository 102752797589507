import {
  Employee,
  Facility
} from '../index';

declare var Object: any;
export interface AssignedToFacilityInterface {
  "assignedAt": any;
  "dtime"?: Date;
  "id"?: number;
  "employeeId"?: number;
  "facilityId"?: number;
  employee?: Employee;
  facility?: Facility;
}

export class AssignedToFacility implements AssignedToFacilityInterface {
  "assignedAt": any;
  "dtime": Date;
  "id": number;
  "employeeId": number;
  "facilityId": number;
  employee: Employee;
  facility: Facility;
  constructor(data?: AssignedToFacilityInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AssignedToFacility`.
   */
  public static getModelName() {
    return "AssignedToFacility";
  }

  /**
  * This method creates an instance of AssignedToFacility for dynamic purposes.
  **/
  public static factory(data: AssignedToFacilityInterface): AssignedToFacility{
    return new AssignedToFacility(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssignedToFacility',
      plural: 'AssignedToFacilities',
      path: 'AssignedToFacilities',
      idName: 'id',
      properties: {
        "assignedAt": {
          name: 'assignedAt',
          type: 'any'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
      }
    }
  }
}
