/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-reassign-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/button-w-drop-down/button-w-drop-down.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/button-w-drop-down/button-w-drop-down.component";
import * as i4 from "../../../../shared/modules/ui/services/ui.service";
import * as i5 from "../trip-manifest-employee-popup/trip-manifest-employee-popup.component.ngfactory";
import * as i6 from "../trip-manifest-employee-popup/trip-manifest-employee-popup.component";
import * as i7 from "../../../../../../node_modules/devextreme-angular/ui/popup/devextreme-angular-ui-popup.ngfactory";
import * as i8 from "devextreme-angular/core";
import * as i9 from "devextreme-angular/ui/popup";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../../../node_modules/devextreme-angular/ui/number-box/devextreme-angular-ui-number-box.ngfactory";
import * as i12 from "@angular/forms";
import * as i13 from "devextreme-angular/ui/number-box";
import * as i14 from "../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory";
import * as i15 from "devextreme-angular/ui/button";
import * as i16 from "./toolbar-reassign-btn.component";
import * as i17 from "../../../../shared/sdk/services/custom/logger.service";
import * as i18 from "../../services/helper.service";
import * as i19 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_ToolbarReassignBtnComponent = [i0.styles];
var RenderType_ToolbarReassignBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarReassignBtnComponent, data: {} });
export { RenderType_ToolbarReassignBtnComponent as RenderType_ToolbarReassignBtnComponent };
export function View_ToolbarReassignBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-w-drop-down", [["hint", "Reassign"], ["icon", "fas fa-share"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.ctx_onAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonWDropDownComponent_0, i2.RenderType_ButtonWDropDownComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonWDropDownComponent, [i4.UiService], { menuItems: [0, "menuItems"], hint: [1, "hint"], icon: [2, "icon"], processing: [3, "processing"] }, { action: "action" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-trip-manifest-employee-popup", [], null, [[null, "visibleChange"], [null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.popupVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChanged" === en)) {
        var pd_1 = (_co.popup_onSelectionChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TripManifestEmployeePopupComponent_0, i5.RenderType_TripManifestEmployeePopupComponent)), i1.ɵdid(3, 114688, null, 0, i6.TripManifestEmployeePopupComponent, [], { gridDataSource: [0, "gridDataSource"], gridColumns: [1, "gridColumns"], visible: [2, "visible"] }, { selectionChanged: "selectionChanged", visibleChange: "visibleChange" }), (_l()(), i1.ɵeld(4, 0, null, null, 26, "dx-popup", [["height", "auto"], ["title", "Set Trip Number"], ["width", "auto"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.popup2Visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DxPopupComponent_0, i7.RenderType_DxPopupComponent)), i1.ɵprd(512, null, i8.DxTemplateHost, i8.DxTemplateHost, []), i1.ɵprd(512, null, i8.WatcherHelper, i8.WatcherHelper, []), i1.ɵprd(512, null, i8.IterableDifferHelper, i8.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i8.NestedOptionHost, i8.NestedOptionHost, []), i1.ɵdid(9, 7323648, null, 1, i9.DxPopupComponent, [i1.ElementRef, i1.NgZone, i8.DxTemplateHost, i8.WatcherHelper, i8.IterableDifferHelper, i8.NestedOptionHost, i10.TransferState, i1.PLATFORM_ID], { closeOnOutsideClick: [0, "closeOnOutsideClick"], dragEnabled: [1, "dragEnabled"], height: [2, "height"], showTitle: [3, "showTitle"], title: [4, "title"], visible: [5, "visible"], width: [6, "width"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { toolbarItemsChildren: 1 }), (_l()(), i1.ɵeld(11, 0, null, 0, 19, "div", [["class", "dx-fieldset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "dx-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "dx-field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trip Number"])), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "dx-field-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "dx-number-box", [], null, [[null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.tripNumber = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_DxNumberBoxComponent_0, i11.RenderType_DxNumberBoxComponent)), i1.ɵprd(5120, null, i12.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.DxNumberBoxComponent]), i1.ɵprd(512, null, i8.DxTemplateHost, i8.DxTemplateHost, []), i1.ɵprd(512, null, i8.WatcherHelper, i8.WatcherHelper, []), i1.ɵprd(512, null, i8.IterableDifferHelper, i8.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i8.NestedOptionHost, i8.NestedOptionHost, []), i1.ɵdid(22, 7323648, null, 1, i13.DxNumberBoxComponent, [i1.ElementRef, i1.NgZone, i8.DxTemplateHost, i8.WatcherHelper, i8.IterableDifferHelper, i8.NestedOptionHost, i10.TransferState, i1.PLATFORM_ID], { value: [0, "value"] }, { valueChange: "valueChange", onBlur: "onBlur" }), i1.ɵqud(603979776, 2, { buttonsChildren: 1 }), (_l()(), i1.ɵeld(24, 0, null, null, 6, "div", [["class", "flow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "span", [["style", "flex: auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 4, "dx-button", [["text", "Set"], ["type", "default"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.setTrip(_co.tripNumber) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_DxButtonComponent_0, i14.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i8.DxTemplateHost, i8.DxTemplateHost, []), i1.ɵprd(512, null, i8.WatcherHelper, i8.WatcherHelper, []), i1.ɵprd(512, null, i8.NestedOptionHost, i8.NestedOptionHost, []), i1.ɵdid(30, 7323648, null, 0, i15.DxButtonComponent, [i1.ElementRef, i1.NgZone, i8.DxTemplateHost, i8.WatcherHelper, i8.NestedOptionHost, i10.TransferState, i1.PLATFORM_ID], { text: [0, "text"], type: [1, "type"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ctxMenuItems; var currVal_1 = "Reassign"; var currVal_2 = "fas fa-share"; var currVal_3 = _co.processing; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.driversDSO; var currVal_5 = _co.gridColumns; var currVal_6 = _co.popupVisible; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = true; var currVal_8 = false; var currVal_9 = "auto"; var currVal_10 = true; var currVal_11 = "Set Trip Number"; var currVal_12 = _co.popup2Visible; var currVal_13 = "auto"; _ck(_v, 9, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.tripNumber; _ck(_v, 22, 0, currVal_14); var currVal_15 = "Set"; var currVal_16 = "default"; _ck(_v, 30, 0, currVal_15, currVal_16); }, null); }
export function View_ToolbarReassignBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toolbar-reassign-btn", [], null, null, null, View_ToolbarReassignBtnComponent_0, RenderType_ToolbarReassignBtnComponent)), i1.ɵdid(1, 573440, null, 0, i16.ToolbarReassignBtnComponent, [i17.LoggerService, i18.HelperService, i19.DataSourceService], null, null)], null, null); }
var ToolbarReassignBtnComponentNgFactory = i1.ɵccf("app-toolbar-reassign-btn", i16.ToolbarReassignBtnComponent, View_ToolbarReassignBtnComponent_Host_0, { manifest: "manifest", selectedDriverId: "selectedDriverId", grid: "grid" }, { processingChange: "processingChange", reassigning: "reassigning", reassigned: "reassigned" }, []);
export { ToolbarReassignBtnComponentNgFactory as ToolbarReassignBtnComponentNgFactory };
