import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { LoopBackFilter, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { VEHICLE_STATUSES } from 'src/app/modules/fleet/classes/const';
export class VehicleChangeStatusFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, config, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.helper = helper;
        this.resetableForm = false;
        this.collapsibleForm = false;
        this.statuses = Object.keys(VEHICLE_STATUSES);
        this.vehicleStatuses = VEHICLE_STATUSES;
    }
    get ModelClass() {
        return Vehicle;
    }
    get dateFields() {
        return [];
    }
    get filter() {
        return {};
    }
    ngOnInit() {
        super.ngOnInit();
    }
    status_onSelectionChanged(e) {
        setTimeout(() => !VEHICLE_STATUSES[e.selectedItem].subStatuses.includes(this.form.get('subStatus').value) &&
            this.form.get('subStatus').setValue(VEHICLE_STATUSES[e.selectedItem].subStatuses[0] || ''), 100);
    }
    afterSetFormValuesAsync(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    afterSubmittedAsync(data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    buildForm() {
        this.formConfigMap.set('', {
            id: [],
            status: [],
            subStatus: [],
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
}
