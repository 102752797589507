import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { captureException } from '@sentry/browser';
import { combineLatest, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
//
import { LoggerService } from '../../../shared/sdk';
import { CommonService } from '../../../shared/modules/my-common/services/common.service';
import { PushNotificationsService } from '../../../shared/modules/ui/services/push-notifications.service';
import { AppState } from '../../../store/reducers';
import { getPendingCount, isLoadPanelVisible, isOnline } from '../../../store/reducers/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isOnline$: Observable<boolean>;
  isLoadPanelVisible$: Observable<boolean>;
  pendingCount$: Observable<number>;

  loadingVisible$: Observable<boolean>;

  constructor(
    private logger: LoggerService,
    private store: Store<AppState>,
    private common: CommonService,
    private notificationService: PushNotificationsService,
  ) {
    this.isOnline$ = this.common.store.select(isOnline);
    this.isLoadPanelVisible$ = this.common.store.select(isLoadPanelVisible);
    this.pendingCount$ = this.common.store.select(getPendingCount);

    this.loadingVisible$ = combineLatest([this.isLoadPanelVisible$, this.pendingCount$]).pipe(
      map(([loadingPanelVisible, pendingCount]) => loadingPanelVisible || pendingCount > 0),
      delay(1),
    );
  }

  ngOnInit(): void {
    this.logger.info('App Component has been loaded');
    this.notificationService.requestPermission().catch(captureException);
    // this.notificationService.subscribeToNotifications().catch(console.error);
    //
    // navigator.serviceWorker.ready.then(function (registration) {
    //   registration.showNotification('Vibration Sample', {
    //     body: 'Buzz! Buzz!',
    //     icon: '../images/touch/chrome-touch-icon-192x192.png',
    //     vibrate: [200, 100, 200, 100, 200, 100, 200],
    //     tag: 'vibration-sample'
    //   });
    // });
  }

  ngOnDestroy(): void {}
}
