import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { HelperService as ConsumerHelperService } from '../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../employee/services/helper.service';
import { HelperService as VehicleHelperService } from '../vehicle/services/helper.service';
//
import { DlgCalendarComponent } from './dialogs/dlg-calendar/dlg-calendar.component';
import { DropDownGridConsumersComponent } from './components/drop-down-grid-consumers/drop-down-grid-consumers.component';
import { DropDownGridEmployeesComponent } from './components/drop-down-grid-employees/drop-down-grid-employees.component';
import { ToolbarCopyBtnComponent } from './components/toolbar-copy-btn/toolbar-copy-btn.component';
import { ToolbarNotifyBtnComponent } from './components/toolbar-notify-btn/toolbar-notify-btn.component';
import { ToolbarPrintBtnComponent } from './components/toolbar-print-btn/toolbar-print-btn.component';
import { ToolbarReassignBtnComponent } from './components/toolbar-reassign-btn/toolbar-reassign-btn.component';
import { TripManifestAssignmentComponent } from './components/trip-manifest-assignment/trip-manifest-assignment.component';
import { TripManifestEmployeePopupComponent } from './components/trip-manifest-employee-popup/trip-manifest-employee-popup.component';
import { TripManifestMainToolbarComponent } from './components/trip-manifest-main-toolbar/trip-manifest-main-toolbar.component';
import { TripManifestManifestComponent } from './components/trip-manifest-manifest/trip-manifest-manifest.component';
import { TripManifestSubToolbarComponent } from './components/trip-manifest-sub-toolbar/trip-manifest-sub-toolbar.component';
import { TripManifestTabsComponent } from './components/trip-manifest-tabs/trip-manifest-tabs.component';
import { TripManifestComponent } from './containers/trip-manifest/trip-manifest.component';
import { DlgEditDailyNoteComponent } from './dialogs/dlg-edit-daily-note/dlg-edit-daily-note.component';
import { DropDownGridVehiclesComponent } from './components/drop-down-grid-vehicles/drop-down-grid-vehicles.component';
import { DlgSelectVehicleComponent } from './dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
import { DlgPrintReportComponent } from './dialogs/dlg-print-report/dlg-print-report.component';
import { DlgSetTripNumberComponent } from './dialogs/dlg-set-trip-number/dlg-set-trip-number.component';
import { FormsModule } from '@angular/forms';
import { DlgSelectDriverComponent } from './dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgMapComponent } from './dialogs/dlg-map/dlg-map.component';
import { ManifestMapComponent } from './components/manifest-map/manifest-map.component';
import { DlgSelectEscortComponent } from './dialogs/dlg-select-escort/dlg-select-escort.component';
import { RouterComponent } from './components/router-maker/router.component';
import { DlgRoutesMapComponent } from './components/router-maker/dlg-routes-map/dlg-routes-map.component';
import { DlgOverwriteManifestConfirm } from './components/router-maker/dlg-overwrite-manifest-confirm/dlg-overwrite-manifest-confirm.component';
import { DlgRestoreManifestComponent } from './dialogs/dlg-restore-manifest/dlg-restore-manifest.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, MyCommonModule, UiModule],
  declarations: [
    DlgCalendarComponent,
    DlgEditDailyNoteComponent,
    DlgPrintReportComponent,
    DlgSelectVehicleComponent,
    DlgSetTripNumberComponent,
    DropDownGridConsumersComponent,
    DropDownGridEmployeesComponent,
    DropDownGridVehiclesComponent,
    ToolbarCopyBtnComponent,
    ToolbarNotifyBtnComponent,
    ToolbarPrintBtnComponent,
    ToolbarReassignBtnComponent,
    TripManifestAssignmentComponent,
    RouterComponent,
    TripManifestComponent,
    TripManifestEmployeePopupComponent,
    TripManifestMainToolbarComponent,
    TripManifestManifestComponent,
    TripManifestSubToolbarComponent,
    TripManifestTabsComponent,
    DlgSelectDriverComponent,
    DlgMapComponent,
    DlgSelectEscortComponent,
    DlgRoutesMapComponent,
    DlgOverwriteManifestConfirm,
    ManifestMapComponent,
    DlgRestoreManifestComponent,
  ],
  exports: [TripManifestComponent, DropDownGridVehiclesComponent],
  providers: [EmployeeHelperService, ConsumerHelperService, VehicleHelperService],
  entryComponents: [
    TripManifestComponent,
    DlgCalendarComponent,
    DlgEditDailyNoteComponent,
    DlgPrintReportComponent,
    DlgSelectVehicleComponent,
    DlgSetTripNumberComponent,
    DlgSelectDriverComponent,
    DlgMapComponent,
    DlgSelectEscortComponent,
    DlgRoutesMapComponent,
    DlgOverwriteManifestConfirm,
    DlgRestoreManifestComponent,
  ],
})
export class TripManifestModule {}

