import {
  Contact,
  GeoPoint
} from '../index';

declare var Object: any;
export interface AddressInterface {
  "street": string;
  "city": string;
  "state": string;
  "zip"?: string;
  "label"?: string;
  "location"?: GeoPoint;
  "default"?: boolean;
  "order"?: number;
  "meta"?: any;
  "dtime"?: Date;
  "id"?: number;
  "contactId"?: number;
  contact?: Contact;
}

export class Address implements AddressInterface {
  "street": string;
  "city": string;
  "state": string;
  "zip": string;
  "label": string;
  "location": GeoPoint;
  "default": boolean;
  "order": number;
  "meta": any;
  "dtime": Date;
  "id": number;
  "contactId": number;
  contact: Contact;
  constructor(data?: AddressInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Address`.
   */
  public static getModelName() {
    return "Address";
  }

  /**
  * This method creates an instance of Address for dynamic purposes.
  **/
  public static factory(data: AddressInterface): Address{
    return new Address(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Address',
      plural: 'Addresses',
      path: 'Addresses',
      idName: 'id',
      properties: {
        "street": {
          name: 'street',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "default": {
          name: 'default',
          type: 'boolean',
          default: false
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "contactId": {
          name: 'contactId',
          type: 'number'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'contactId',
          keyTo: 'id'
        },
      }
    }
  }
}
