import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { intersectionBy, isNil, isNumber, uniqBy } from 'lodash-es';
import isEmpty from 'lodash-es/isEmpty';
import uniq from 'lodash-es/uniq';
import moment, { utc } from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { Employee, PostShiftReport, Vehicle, VehicleCheckUp } from '../../../../shared/sdk/models';
import { LoopBackAuth } from '../../../../shared/sdk/services/core';
import {
  EmployeeApi,
  LoggerService,
  MyUtilsApi,
  PostShiftReportApi,
  VehicleApi,
  VehicleCheckUpApi,
} from '../../../../shared/sdk/services/custom';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { HumanizePipe } from '../../../../shared/modules/my-common/pipes/humanize.pipe';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DlgAddCommentComponent } from '../../dialogs/dlg-add-comment/dlg-add-comment.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { DlgEditOdometerComponent } from '../../dialogs/dlg-edit-odometer/dlg-edit-odometer.component';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-daily-checks',
  templateUrl: './daily-checks.component.html',
  styleUrls: ['./daily-checks.component.scss'],
})
export class DailyChecksComponent extends ABaseComponent implements OnInit, AfterViewInit {
  ds: DataSource;
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);
  vehicleLocationDso$: Observable<DataSourceOptions> = of([]);

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  grid_stateStoring;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  @Output() mySelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public employeeHelper: EmployeeHelperService,
    private ui: UiService,
    private pusher: PusherService,
    private api: VehicleCheckUpApi,
    private utilsApi: MyUtilsApi,
    private dialog: MatDialog,
    private notification: PushNotificationsService,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b97edcef-574e-4872-bce6-9f3295d51a0d',
    };

    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
    this.vehicleLocationDso$ = this.buildVehicleLocationDataSource();
  }

  ngOnInit() {
    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Loading...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.ds = new DataSource([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.ds = new DataSource(as);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.$filterEvent$.next(true);
  }

  private buildDataSource() {
    return of(true).pipe(
      map(() => {
        const from = this.selectedFromValue;
        const to = this.selectedToValue;

        const fromMoment = from && moment(moment(from).format('YYYY-MM-DD'));
        const toMoment = to && moment(moment(to).add(1, 'days').format('YYYY-MM-DD'));

        // if (!fromMoment || !toMoment) {
        //   throw new Error('Period should be defined');
        // }

        if (toMoment.diff(fromMoment, 'days') > 3) {
          throw new Error('Period should be less or equal to 2 days');
        }

        // const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        // const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromInc: fromMoment.utc().toDate(),
          toExcl: toMoment.utc().toDate(),
        };
      }),

      // switchMap((fltr) =>
      //   this.pusher.rpc('GET_CLAIMS', fltr, true, headersAllTenantsAppend),
      // ),

      // switchMap((url) => this.http.get(url, {
      //   responseType: 'json',
      //   withCredentials: false,
      // })),

      switchMap(fltr => {
        const col = 'VehicleChecks_view';
        return gqlMongoLoad(
          this.dss,
          col,
          {
            filter: [
              ...(fltr.fromInc ? [['dateTime', '>', fltr.fromInc]] : []),
              ...(fltr.toExcl ? [['dateTime', '<=', fltr.toExcl]] : []),
            ],
          },
          [],
        ).pipe(
          switchMap(async (docs: (VehicleCheckUp & { checkout: PostShiftReport })[]) => {
            const vehicleApi = this.dss.getApi<VehicleApi>(Vehicle);
            const employeeApi = this.dss.getApi<EmployeeApi>(Employee);

            const vehicles = await vehicleApi
              .find<Vehicle>(
                {
                  where: { id: { inq: uniq(docs.map(d => d.vehicleId)) } },
                },
                headersAllTenantsAppend,
              )
              .toPromise();

            const employees = await employeeApi
              .find<Employee>(
                {
                  where: { id: { inq: uniq(docs.map(d => d.employeeId)) } },
                  include: ['person'],
                },
                headersAllTenantsAppend,
              )
              .toPromise();

            docs.forEach(doc => {
              doc.vehicle = vehicles.find(v => v.id === doc.vehicleId);
              doc.employee = employees.find(e => e.id === doc.employeeId);
            });

            return docs;
          }),
        );
        // .toPromise();

        // return this.api.find({
        //   where: {
        //     and: [
        //       ...(fltr.fromInc ? [{dateTime: {gte: fltr.fromInc}}] : []),
        //       ...(fltr.toExcl ? [{dateTime: {lt: fltr.toExcl}}] : []),
        //     ]
        //   }
        // });
      }),

      map((recs: VehicleCheckUp[]) => {
        function _sharedFields(_vc: VehicleCheckUp & { checkout: PostShiftReport }) {
          return intersectionBy(oc(_vc).summary.vcDesc([]), oc(_vc).checkout.summary.vcDesc([]), 'field').map(
            (meta: any) => meta.field as string,
          );
        }

        recs.forEach((r: any) => {
          r.getAlert = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return '';
          }.bind(r);

          r.getEmployeeName = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return oc(_self).employee.person.firstname() + ' ' + oc(_self).employee.person.lastname();
          }.bind(r);

          r.getExpired = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            // const sf = _sharedFields(_self);
            return uniq([
              // ...difference(oc(_self).summary.vcDescSummary.expirations([]), sf),
              ...oc(_self).summary.vcDescSummary.expirations([]),
              ...oc(_self).checkout.summary.vcDescSummary.expirations([]),
            ]);
          }.bind(r);

          r.getIssues = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            // const sf = _sharedFields(_self);
            return uniq([
              ...oc(_self).summary.vcDescSummary.issues([]),
              ...oc(_self).checkout.summary.vcDescSummary.issues([]),
            ]);
          }.bind(r);

          r.getMissingStuff = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            // const sf = _sharedFields(_self);
            return uniq([
              ...oc(_self).summary.vcDescSummary.missingStuff([]),
              ...oc(_self).checkout.summary.vcDescSummary.missingStuff([]),
            ]);
          }.bind(r);

          r.getInteriorClean = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return _self.interiorClean ? 'Y' : '';
          }.bind(r);

          r.getExteriorWashed = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return oc(_self).checkout.haveWashedExterior() ? 'Y' : '';
          }.bind(r);

          r.getDesinfection = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return _self.IHaveDisinfectedSurfacesOfContact ? 'Y' : '';
          }.bind(r);

          r.getCheckInLocation = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return _self.location;
          }.bind(r);

          r.getCheckOutLocation = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            return oc(_self).checkout.location();
          }.bind(r);

          r.getCheckInDuration = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            const min = oc(_self).durationTime();
            return !isNaN(min) ? new HumanizePipe().transform(min, { units: ['h', 'm'] }) : '';
          }.bind(r);

          r.getCheckOutDuration = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            const min = oc(_self).checkout.durationTime();
            return !isNaN(min) ? new HumanizePipe().transform(min, { units: ['h', 'm'] }) : '';
          }.bind(r);

          r.getDailyOdometer = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            const ciOdometer = oc(_self).odometer();
            const coOdometer = oc(_self).checkout.odometer();
            return !isNaN(ciOdometer) && !isNaN(coOdometer) ? Number(coOdometer - ciOdometer).toFixed(2) : undefined;
          }.bind(r);

          r.getCiCoDurationValue = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            if (isEmpty(_self.checkout)) {
              return;
            }
            return utc(_self.checkout.completedDateTime).diff(_self.startedDateTime, 'minutes');
          }.bind(r);

          r.getCiCoDuration = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };

            if (isEmpty(_self.checkout)) {
              return;
            }
            const minutes = utc(_self.checkout.completedDateTime).diff(_self.startedDateTime, 'minutes');

            // const fn = moment.relativeTimeRounding();
            // moment.relativeTimeRounding((val) => round(val, 2));
            const dur = !isNaN(minutes) ? new HumanizePipe().transform(minutes) : '';
            // moment.relativeTimeRounding(fn);

            return dur;
          }.bind(r);

          r.getEngineIdleTime = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            const min = oc(_self).engineIdleRunDurationTime();
            return !isNaN(min) ? new HumanizePipe().transform(min, { units: ['h', 'm'] }) : '';
          }.bind(r);

          r.getFmComments = function () {
            const _self = this as VehicleCheckUp & { checkout: PostShiftReport };
            const commentObjs = oc(_self).meta.fmComments([]);
            return commentObjs
              .filter(o => o.comment)
              .map(o => `${o.comment}`)
              .join('\n');
          }.bind(r);
        });

        const aso: ArrayStoreOptions = {
          // key: VehicleCheckUp.getModelDefinition().idName,
          key: '_id',
          data: recs,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      // {
      //   name: 'newUser',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'inMenu',
      //   options: {
      //     icon: 'fas fa-user-plus',
      //     text: 'New User',
      //     hint: 'Create New User',
      //     // onClick: this.grid_toolbar_new_onClick.bind(this),
      //   },
      // },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildStatusReport',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-report',
          text: 'Build Fleet Status Report',
          hint: 'Build Fleet Status Report v1',
          onClick: this.grid_toolbar_buildStatusReport_onClick.bind(this),
        },
      },
    );
  }

  grid_onSelectionChanged(e) {
    // console.log(e);
    this.mySelected.emit(e.selectedRowKeys);
  }

  grid_toolbar_buildStatusReport_onClick() {
    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('L1_FLEET_STATUS_REPORT_V1', { useRunService: true })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleLocationDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'location' }],
      postProcess: (data: Vehicle[]) => uniqBy(data, 'location'),
    } as DataSourceOptions;
    return of(dso);
  }

  addComment(e, cellInfo) {
    // console.log(cellInfo);

    void this.dialog
      .open(DlgAddCommentComponent, {
        // width: '250px',
        hasBackdrop: true,
        data: { value: '' },
      })
      .afterClosed()
      .pipe(
        filter(result => result !== false),
        tap(() => this.ui.showLoading()),
        switchMap(result =>
          this.api.setComment(cellInfo.key, result).pipe(
            tap(
              () =>
                (cellInfo.data.meta.fmComments = [
                  ...oc(cellInfo.data).meta.fmComments([]),
                  {
                    userId: this.auth.getCurrentUserId(),
                    comment: result,
                  },
                ]),
            ),
            tap(() => {
              // this.mySelected.emit(this.grid.selectedRowKeys);
              this.dss.modifiedEvent.emit(VehicleCheckUp.getModelName());
            }),
          ),
        ),
        catchError(err => of(notify(err.message || err, 'error', 3000))),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }

  editOdometer(e, cellInfo) {
    // console.log(cellInfo);

    const model = cellInfo.data;

    const ci: VehicleCheckUp = model;
    const co: PostShiftReport | undefined = oc(model).checkout();

    void this.dialog
      .open(DlgEditOdometerComponent, {
        // width: '250px',
        hasBackdrop: true,
        data: {
          ci: !isNil(ci),
          co: !isNil(co),
          ciOdo: oc(ci).odometer(),
          coOdo: oc(co).odometer(),
        },
      })
      .afterClosed()
      .pipe(
        filter(result => result !== false),
        tap(() => this.ui.showLoading()),
        switchMap(async result => {
          if (ci && isNumber(result.ciOdo) && result.ciOdo !== ci.odometer) {
            await this.dss
              .getApi<VehicleCheckUpApi>(VehicleCheckUp)
              .patchAttributes((ci as any)._id, { odometer: result.ciOdo }, headersAllTenantsAppend)
              .pipe(
                tap(() => {
                  ci.odometer = result.ciOdo;
                  this.dss.modifiedEvent.emit(VehicleCheckUp.getModelName());
                }),
              )
              .toPromise();
          }

          if (co && isNumber(result.coOdo) && result.coOdo !== co.odometer) {
            await this.dss
              .getApi<PostShiftReportApi>(PostShiftReport)
              .patchAttributes((co as any)._id, { odometer: result.coOdo }, headersAllTenantsAppend)
              .pipe(
                tap(() => {
                  co.odometer = result.coOdo;
                  this.dss.modifiedEvent.emit(PostShiftReport.getModelName());
                }),
              )
              .toPromise();
          }

          this.grid.instance.repaintRows([cellInfo.rowIndex]);
        }),
        catchError(err => of(notify(err.message || err, 'error', 3000))),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }
}
