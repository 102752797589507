import {
  Employee
} from '../index';

declare var Object: any;
export interface EmployeePayrollInterface {
  "employeeId": number;
  "paymentArrangement": string;
  "rate"?: number;
  "rate1"?: number;
  "start"?: Date;
  "end"?: Date;
  "dateTime"?: Date;
  "stimuliEnabled"?: boolean;
  "stimuliAmount"?: number;
  "stimuliAfterTripNumber"?: number;
  "approvedBy"?: string;
  "notes"?: string;
  "history"?: any;
  "id"?: any;
  employee?: Employee;
}

export class EmployeePayroll implements EmployeePayrollInterface {
  "employeeId": number;
  "paymentArrangement": string;
  "rate": number;
  "rate1": number;
  "start": Date;
  "end": Date;
  "dateTime": Date;
  "stimuliEnabled": boolean;
  "stimuliAmount": number;
  "stimuliAfterTripNumber": number;
  "approvedBy": string;
  "notes": string;
  "history": any;
  "id": any;
  employee: Employee;
  constructor(data?: EmployeePayrollInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeePayroll`.
   */
  public static getModelName() {
    return "EmployeePayroll";
  }

  /**
  * This method creates an instance of EmployeePayroll for dynamic purposes.
  **/
  public static factory(data: EmployeePayrollInterface): EmployeePayroll{
    return new EmployeePayroll(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeePayroll',
      plural: 'EmployeePayrolls',
      path: 'EmployeePayrolls',
      idName: 'id',
      properties: {
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "paymentArrangement": {
          name: 'paymentArrangement',
          type: 'string'
        },
        "rate": {
          name: 'rate',
          type: 'number'
        },
        "rate1": {
          name: 'rate1',
          type: 'number'
        },
        "start": {
          name: 'start',
          type: 'Date'
        },
        "end": {
          name: 'end',
          type: 'Date'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "stimuliEnabled": {
          name: 'stimuliEnabled',
          type: 'boolean'
        },
        "stimuliAmount": {
          name: 'stimuliAmount',
          type: 'number'
        },
        "stimuliAfterTripNumber": {
          name: 'stimuliAfterTripNumber',
          type: 'number'
        },
        "approvedBy": {
          name: 'approvedBy',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "history": {
          name: 'history',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
      }
    }
  }
}
