import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CloneLogGridComponent } from '../clone-log-grid/clone-log-grid.component';

@Component({
  selector: 'app-clone-log-list',
  templateUrl: './clone-log-list.component.html',
  styleUrls: ['./clone-log-list.component.scss'],
})
export class CloneLogListComponent implements OnInit {
  @Output() mySelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild(CloneLogGridComponent, { static: true }) grid: CloneLogGridComponent;

  constructor() {}

  ngOnInit() {}

  grid_onSelected(keys: number[]) {
    this.mySelected.emit(keys);
  }

  repaint() {
    this.grid.repaint();
  }
}

