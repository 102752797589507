<app-content-w-header header='AutoValidation Log'>
  <mat-card class='toolbar subheader'>
    <dx-date-box width='100px' [(value)]='selectedDate' (onValueChanged)='onDateValueChanged($event)'></dx-date-box>
  </mat-card>

  <dx-data-grid
    class='content'
    height='100%'
    width='100%'
    [columnResizingMode]="'widget'"
    [columnAutoWidth]='true'
    [dataSource]='dso'
    [twoWayBindingEnabled]='false'
    [stateStoring]='grid_stateStoring'
    [dateSerializationFormat]='null'
    [headerFilter]='{ visible: true }'

    (onInitialized)='grid_onInitialized($event)'
    (onToolbarPreparing)='grid_onToolbarPreparing($event)'
  >

    <dxo-editing mode='none' [allowAdding]='false' [allowUpdating]='false' [allowDeleting]='false'></dxo-editing>

    <dxo-grouping [contextMenuEnabled]='true'></dxo-grouping>
    <dxo-group-panel [visible]='true'></dxo-group-panel>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-export
      [enabled]='true'
      [excelFilterEnabled]='true'
      [allowExportSelectedData]='true'
    ></dxo-export>

    <dxo-selection mode='multiple' selectAllMode="allPages"></dxo-selection>

    <dxo-master-detail [enabled]='true' template='detail'></dxo-master-detail>

    <!-- region Columns -->

    <dxi-column caption='Facility' dataField='sign.tenantId'>
      <dxo-lookup [dataSource]='facilityDso$ | async' valueExpr='id' displayExpr='shortname'></dxo-lookup>
    </dxi-column>

    <dxi-column dataField='action' dataType='string'></dxi-column>

    <dxi-column caption='TripID'>

      <dxi-column
        caption='Date'
        dataField='__trip._date'
        dataType='date'
        format='shortDate'
        [customizeText]='config.customizeShortDateText'
        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      ></dxi-column>

      <dxi-column caption='Trip Number' dataField='__trip._tripId' dataType='string'></dxi-column>

      <dxi-column caption='Trip Type' dataField='__trip.Trip Type' dataType='string'></dxi-column>

      <dxi-column caption='Trip Status' dataField='__trip.Trip Status' dataType='string'></dxi-column>

      <dxi-column caption='Medicaid Number' dataField='__trip._mci' dataType='string'></dxi-column>
      <dxi-column caption="Member's Last Name" dataField="__trip.Member's Last Name" dataType='string'></dxi-column>
      <dxi-column caption="Member's First Name" dataField="__trip.Member's First Name" dataType='string'></dxi-column>
      <dxi-column
        caption="Member's Date of Birth"
        dataField="__trip.Member's Date of Birth"
        dataType='date'
        format='shortDate'
        [customizeText]='config.customizeShortDateText'
        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      ></dxi-column>

      <dxi-column caption='Delivery Name' dataField='__trip.Delivery Name' dataType='string'></dxi-column>
      <dxi-column caption='Origin' dataField='__trip.__action.formatted' dataType='string'></dxi-column>
      <dxi-column caption='Destination' dataField='__trip.__action.formatted' dataType='string'></dxi-column>

      <dxi-column
        caption='Appointment Date'
        dataField='__trip.Appointment Date'
        dataType='date'
        format='shortDate'
        [customizeText]='config.customizeShortDateText'
        [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      ></dxi-column>

      <dxi-column
        caption='Appointment Time'
        dataField='__trip.Appointment Time'
        dataType='string'
      ></dxi-column>

      <dxi-column
        dataField='__trip.__tripDateTime'
        dataType='date'
        format='shortDateShortTime'
        [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
      ></dxi-column>

    </dxi-column>

    <dxi-column caption='Signature'>
      <dxi-column caption='Marker' dataField='sign.marker'></dxi-column>
      <dxi-column caption='Service Type' dataField='sign.vServiceType'></dxi-column>
    </dxi-column>

    <!-- -->

    <dxi-column caption='Vehicle' dataField='sign.vehicle_internalId' dataType='number'></dxi-column>
    <dxi-column caption='VIN' dataField='sign.vehicle_vin' dataType='string'></dxi-column>

    <dxi-column caption='Driver Firstname' dataField='sign.employee_person_firstname'></dxi-column>
    <dxi-column caption='Driver Lastname' dataField='sign.employee_person_lastname'></dxi-column>
    <dxi-column caption='Driver DL' dataField='sign.employee_person_data.dl'></dxi-column>

    <dxi-column caption='Consumer Firstname' dataField='sign.consumer_person_firstname'></dxi-column>
    <dxi-column caption='Consumer Lastname' dataField='sign.consumer_person_lastname'></dxi-column>
    <dxi-column caption='Consumer Broker' dataField='broker'></dxi-column>

    <dxi-column dataField='__action.times.dateTime'
                dataType='date'
                format='shortDateShortTime'
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
    ></dxi-column>

    <dxi-column dataField='ctime'
                dataType='date'
                format='shortDateShortTime'
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
    ></dxi-column>

    <dxi-column caption='TabletAction/ServerSave Diff (min)' dataField='__action.times.ctimeDiff'
                dataType='number'></dxi-column>

    <dxi-column caption='GeoTabApi/TrID Diff (m)' dataField='__trip.__action.diff.api' dataType='number'>
      <dxo-format type='fixedPoint' [precision]='0'></dxo-format>
    </dxi-column>

    <dxi-column caption='GeoTabLog/TrID Diff (m)' dataField='__trip.__action.diff.log' dataType='number'>
      <dxo-format type='fixedPoint' [precision]='0'></dxo-format>
    </dxi-column>

    <dxi-column caption='DriverTab/TrID Diff (m)' dataField='__trip.__action.diff.tab' dataType='number'>
      <dxo-format type='fixedPoint' [precision]='0'></dxo-format>
    </dxi-column>

    <dxi-column caption='Manifest/TrID Diff (m)' dataField='__trip.__action.diff.service' dataType='number'>
      <dxo-format type='fixedPoint' [precision]='0'></dxo-format>
    </dxi-column>

    <dxi-column dataField='hasGeoTabDevice' dataType='boolean' [showEditorAlways]='false'></dxi-column>
    <dxi-column dataField='tripsCount' dataType='number'></dxi-column>
    <dxi-column dataField='__fullTrip.withPu' dataType='boolean' [showEditorAlways]='false'></dxi-column>
    <dxi-column dataField='__trip.__action.diff.min' dataType='number' [showEditorAlways]='false'>
      <dxo-format type='fixedPoint' [precision]='0'></dxo-format>
    </dxi-column>
    <dxi-column dataField='__valid' dataType='boolean' [showEditorAlways]='false'
                title='should be sent to MTM'></dxi-column>

    <!--  endregion   -->

    <!-- region Templates -->

    <div *dxTemplate="let info of 'detail'">
      <dx-tab-panel>
        <dxi-item title='Raw Data'>
          <ngx-json-viewer [json]='info.data' [expanded]='false'></ngx-json-viewer>
        </dxi-item>

        <dxi-item title='On Map'>
          <mat-toolbar>
            <span>{{ info.data.action }}
              at {{ info.data?.__action?.times?.dateTime | local:'HH:mm:ss':true }}</span>
            <span>&nbsp;</span>
            <dx-button text='Refresh' (onClick)='mapMarkersRebuild_click(info.data)'></dx-button>
          </mat-toolbar>

          <div style='width: 800px; height: 500px;' (wheel)='map_wheel($event)'>
            <dx-map
              [width]='800'
              [height]='500'
              provider='google'
              [apiKey]='config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER'
              [controls]='true'
              [autoAdjust]='false'
              [zoom]='11'
              [center]='mapCenters[info.data._id] || null'
              [markers]='markers[info.data._id] || []'
              [routes]='routes[info.data._id] || []'
              (onInitialized)='map_onInitialized($event, info.data)'
              (onReady)='map_onReady($event, info.data)'
              (onDisposing)='map_onDisposing($event, info.data)'
            ></dx-map>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </div>

    <!--  endregion   -->

  </dx-data-grid>
</app-content-w-header>
