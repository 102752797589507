import {
  Employee,
  GeoPoint
} from '../index';

declare var Object: any;
export interface MobileDeviceInterface {
  "deviceUid"?: string;
  "geolocation"?: any;
  "geolocationPoint"?: GeoPoint;
  "lastTimeGeolocation"?: Date;
  "geotabLocation"?: any;
  "geotabLocationPoint"?: GeoPoint;
  "lastTimeGeotabLocation"?: Date;
  "status"?: string;
  "phoneNumber"?: string;
  "modelNumber"?: string;
  "serialNumber"?: string;
  "imei"?: string;
  "connection"?: string;
  "sim"?: string;
  "label"?: string;
  "lastRequest"?: Date;
  "assignedLocation"?: string;
  "lastDriverAppUser"?: string;
  "lastDriverAppUserId"?: number;
  "lastDispatchAppUser"?: string;
  "lastDispatchAppUserId"?: number;
  "driverAppVer"?: string;
  "dispatchAppVer"?: string;
  "deploymentGroup"?: string;
  "lastCIDateTime"?: Date;
  "lastVehicle"?: string;
  "lastVehicleId"?: number;
  "deviceInfo"?: any;
  "deviceTime"?: any;
  "headers"?: any;
  "incognito"?: boolean;
  "note"?: string;
  "meta"?: any;
  "id"?: any;
  "assignedEmployee"?: number;
  employee?: Employee;
}

export class MobileDevice implements MobileDeviceInterface {
  "deviceUid": string;
  "geolocation": any;
  "geolocationPoint": GeoPoint;
  "lastTimeGeolocation": Date;
  "geotabLocation": any;
  "geotabLocationPoint": GeoPoint;
  "lastTimeGeotabLocation": Date;
  "status": string;
  "phoneNumber": string;
  "modelNumber": string;
  "serialNumber": string;
  "imei": string;
  "connection": string;
  "sim": string;
  "label": string;
  "lastRequest": Date;
  "assignedLocation": string;
  "lastDriverAppUser": string;
  "lastDriverAppUserId": number;
  "lastDispatchAppUser": string;
  "lastDispatchAppUserId": number;
  "driverAppVer": string;
  "dispatchAppVer": string;
  "deploymentGroup": string;
  "lastCIDateTime": Date;
  "lastVehicle": string;
  "lastVehicleId": number;
  "deviceInfo": any;
  "deviceTime": any;
  "headers": any;
  "incognito": boolean;
  "note": string;
  "meta": any;
  "id": any;
  "assignedEmployee": number;
  employee: Employee;
  constructor(data?: MobileDeviceInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MobileDevice`.
   */
  public static getModelName() {
    return "MobileDevice";
  }

  /**
  * This method creates an instance of MobileDevice for dynamic purposes.
  **/
  public static factory(data: MobileDeviceInterface): MobileDevice{
    return new MobileDevice(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MobileDevice',
      plural: 'MobileDevices',
      path: 'MobileDevices',
      idName: 'id',
      properties: {
        "deviceUid": {
          name: 'deviceUid',
          type: 'string'
        },
        "geolocation": {
          name: 'geolocation',
          type: 'any',
          default: <any>null
        },
        "geolocationPoint": {
          name: 'geolocationPoint',
          type: 'GeoPoint'
        },
        "lastTimeGeolocation": {
          name: 'lastTimeGeolocation',
          type: 'Date'
        },
        "geotabLocation": {
          name: 'geotabLocation',
          type: 'any',
          default: <any>null
        },
        "geotabLocationPoint": {
          name: 'geotabLocationPoint',
          type: 'GeoPoint'
        },
        "lastTimeGeotabLocation": {
          name: 'lastTimeGeotabLocation',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'ACTIVE'
        },
        "phoneNumber": {
          name: 'phoneNumber',
          type: 'string'
        },
        "modelNumber": {
          name: 'modelNumber',
          type: 'string'
        },
        "serialNumber": {
          name: 'serialNumber',
          type: 'string'
        },
        "imei": {
          name: 'imei',
          type: 'string'
        },
        "connection": {
          name: 'connection',
          type: 'string'
        },
        "sim": {
          name: 'sim',
          type: 'string'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "lastRequest": {
          name: 'lastRequest',
          type: 'Date'
        },
        "assignedLocation": {
          name: 'assignedLocation',
          type: 'string'
        },
        "lastDriverAppUser": {
          name: 'lastDriverAppUser',
          type: 'string'
        },
        "lastDriverAppUserId": {
          name: 'lastDriverAppUserId',
          type: 'number'
        },
        "lastDispatchAppUser": {
          name: 'lastDispatchAppUser',
          type: 'string'
        },
        "lastDispatchAppUserId": {
          name: 'lastDispatchAppUserId',
          type: 'number'
        },
        "driverAppVer": {
          name: 'driverAppVer',
          type: 'string'
        },
        "dispatchAppVer": {
          name: 'dispatchAppVer',
          type: 'string'
        },
        "deploymentGroup": {
          name: 'deploymentGroup',
          type: 'string'
        },
        "lastCIDateTime": {
          name: 'lastCIDateTime',
          type: 'Date'
        },
        "lastVehicle": {
          name: 'lastVehicle',
          type: 'string'
        },
        "lastVehicleId": {
          name: 'lastVehicleId',
          type: 'number'
        },
        "deviceInfo": {
          name: 'deviceInfo',
          type: 'any',
          default: <any>null
        },
        "deviceTime": {
          name: 'deviceTime',
          type: 'any',
          default: <any>null
        },
        "headers": {
          name: 'headers',
          type: 'any',
          default: <any>null
        },
        "incognito": {
          name: 'incognito',
          type: 'boolean',
          default: false
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "assignedEmployee": {
          name: 'assignedEmployee',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'assignedEmployee',
          keyTo: 'id'
        },
      }
    }
  }
}
