import {
  Address,
  Phone,
  EmailAddress,
  Facility,
  Person
} from '../index';

declare var Object: any;
export interface ContactInterface {
  "notes"?: string;
  "dtime"?: Date;
  "id"?: number;
  addresses?: Address[];
  phones?: Phone[];
  emails?: EmailAddress[];
  facility?: Facility;
  person?: Person;
}

export class Contact implements ContactInterface {
  "notes": string;
  "dtime": Date;
  "id": number;
  addresses: Address[];
  phones: Phone[];
  emails: EmailAddress[];
  facility: Facility;
  person: Person;
  constructor(data?: ContactInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Contact`.
   */
  public static getModelName() {
    return "Contact";
  }

  /**
  * This method creates an instance of Contact for dynamic purposes.
  **/
  public static factory(data: ContactInterface): Contact{
    return new Contact(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Contact',
      plural: 'Contacts',
      path: 'Contacts',
      idName: 'id',
      properties: {
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        addresses: {
          name: 'addresses',
          type: 'Address[]',
          model: 'Address',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
        phones: {
          name: 'phones',
          type: 'Phone[]',
          model: 'Phone',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
        emails: {
          name: 'emails',
          type: 'EmailAddress[]',
          model: 'EmailAddress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'contactId'
        },
      }
    }
  }
}
