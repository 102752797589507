<ng-container [formGroup]="formArray">
  <div class="flow-hor">
    <mat-icon style="margin: 10px 10px 0 0">{{ icon }}</mat-icon>

    <div style="flex: auto">
      <button
        mat-icon-button
        type="button"
        color="accent"
        [matTooltip]="'Add ' + entityName"
        (click)="addItem.emit()"
        *ngIf="!formArray.controls.length">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <!---->

      <dx-box
        direction="row"
        *ngFor="
          let ctrl of entityName === 'custom field' ? formArray.controls : sortedControls;
          let idx = index;
          let isLast = last
        "
        [formGroupName]="idx">
        <dxi-item [ratio]="1" [shrink]="1" baseSize="auto" style="justify-content: center">
          <ng-container
            *ngTemplateOutlet="item; context: { $implicit: ctrl, idx: idx, entityName: entityName }"></ng-container>
        </dxi-item>

        <dxi-item [ratio]="0" [shrink]="0" baseSize="auto">
          <button mat-icon-button type="button" matTooltip="Remove" (click)="removeItem.emit(idx)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </dxi-item>

        <dxi-item [ratio]="0" [shrink]="0" baseSize="auto">
          <button
            mat-icon-button
            type="button"
            color="accent"
            [matTooltip]="'Add ' + entityName"
            (click)="addItem.emit()"
            [ngStyle]="{ visibility: isLast ? 'visible' : 'hidden', opacity: 1 }">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </dxi-item>
      </dx-box>
    </div>
  </div>
</ng-container>

<ng-template #item let-ctrl let-idx="idx" let-entityName="entityName">
  <ng-container [ngSwitch]="entityName">
    <ng-container *ngSwitchCase="'phone'">
      <dx-box direction="row" [formGroup]="ctrl">
        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <mat-form-field>
            <input matInput type="tel" required formControlName="value" placeholder="Phone" />
          </mat-form-field>
        </dxi-item>

        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <mat-form-field>
            <input matInput formControlName="label" placeholder="Label" />
          </mat-form-field>
        </dxi-item>
      </dx-box>
    </ng-container>

    <ng-container *ngSwitchCase="'email'">
      <dx-box direction="row" [formGroup]="ctrl">
        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <mat-form-field>
            <input matInput type="email" required formControlName="value" placeholder="Email" />
          </mat-form-field>
        </dxi-item>

        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <mat-form-field>
            <input matInput formControlName="label" placeholder="Label" />
          </mat-form-field>
        </dxi-item>
      </dx-box>
    </ng-container>

    <ng-container *ngSwitchCase="'place'">
      <ng-container *ngIf="!compact">
        <dx-box direction="row" [formGroup]="ctrl">
          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" *ngIf="labels && labels.length">
            <mat-form-field style="width: 100%">
              <input matInput formControlName="label" placeholder="Label" [matAutocomplete]="labelAuto" />
            </mat-form-field>

            <mat-autocomplete #labelAuto="matAutocomplete">
              <mat-option *ngFor="let option of labels" [value]="option">{{ option }}</mat-option>
            </mat-autocomplete>
          </dxi-item>

          <dxi-item [ratio]="3" [shrink]="1" [baseSize]="'auto'">
            <mat-form-field style="width: 100%">
              <input matInput required formControlName="street" placeholder="Street" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
            <mat-form-field style="width: 12em">
              <input matInput required formControlName="city" placeholder="City" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
            <mat-form-field style="width: 3em">
              <input matInput required formControlName="state" placeholder="State" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
            <mat-form-field style="width: 4em">
              <input matInput formControlName="zip" placeholder="Zip" />
            </mat-form-field>
          </dxi-item>
        </dx-box>
      </ng-container>

      <ng-container *ngIf="compact">
        <dx-box direction="col" [formGroup]="ctrl">
          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
            <mat-form-field style="width: 100%">
              <input matInput required formControlName="street" placeholder="Street" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
            <dx-box direction="row">
              <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
                <mat-form-field style="width: 100%">
                  <input matInput required formControlName="city" placeholder="City" />
                </mat-form-field>
              </dxi-item>

              <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
                <mat-form-field style="width: 3em">
                  <input matInput required formControlName="state" placeholder="State" />
                </mat-form-field>
              </dxi-item>

              <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
                <mat-form-field style="width: 4em">
                  <input matInput formControlName="zip" placeholder="Zip" />
                </mat-form-field>
              </dxi-item>
            </dx-box>
          </dxi-item>
        </dx-box>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'relation'">
      <dx-box direction="row" [formGroup]="ctrl" (onInitialized)="relation_box_onInitialized(ctrl)">
        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <mat-form-field floatLabel="always" style="width: 100%">
            <input
              matInput
              style="width: 0; visibility: hidden"
              #personId
              formControlName="personId"
              placeholder="Related Person"
              required
              [readonly]="true" />

            <app-object-title [modelId]="personId.value" [modelName]="'Person'"></app-object-title>

            <mat-chip matPrefix matTooltip="Emergency Contact" style="margin-right: 0.5em"
              >{{ isPersonER(getPerson$(personId.value) | async) ? 'ER' : '' }}
            </mat-chip>

            <mat-icon
              matSuffix
              matTooltip="Edit person"
              (click)="editPerson_onClick(ctrl, personId.value)"
              style="margin-right: 0.5em"
              >edit
            </mat-icon>
          </mat-form-field>

          <!--<div class="flow-hor" style="padding-bottom: 1.25em;">-->
          <!--<app-drop-down-grid-persons-->
          <!--style="flex: auto;"-->
          <!--[ngClass]="{'dx-invalid': ctrl.get('personId').invalid}"-->
          <!--[invalid]="ctrl.get('personId').invalid"-->
          <!--formControlName="personId"-->
          <!--&gt;</app-drop-down-grid-persons>-->

          <!--<dx-button-->
          <!--icon="edit"-->
          <!--matTooltip="Edit person"-->
          <!--(onClick)="editPerson_onClick(ctrl, ctrl.get('personId').value)"-->
          <!--&gt;</dx-button>-->
          <!--</div>-->
        </dxi-item>

        <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'50%'">
          <ng-container *ngIf="labels && labels.length > 0; else noLabels">
            <mat-form-field style="width: 100%">
              <mat-select formControlName="relation" placeholder="Relation">
                <mat-option *ngFor="let option of labels" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-template #noLabels>
            <mat-form-field style="width: 100%">
              <input matInput formControlName="relation" placeholder="Relation" [matAutocomplete]="relationLabelAuto" />
            </mat-form-field>

            <mat-autocomplete #relationLabelAuto="matAutocomplete">
              <mat-option *ngFor="let option of labels" [value]="option">{{ option }}</mat-option>
            </mat-autocomplete>
          </ng-template>
        </dxi-item>
      </dx-box>
    </ng-container>

    <ng-container *ngSwitchCase="'custom field'">
      <ng-container [formGroup]="ctrl">
        <dx-box direction="row">
          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" style="justify-content: flex-end">
            <mat-form-field>
              <input matInput required formControlName="label" placeholder="Label" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" style="justify-content: flex-end">
            <mat-form-field>
              <input matInput required formControlName="field" placeholder="System Name" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" style="justify-content: flex-end">
            <mat-form-field>
              <mat-select required placeholder="Type" formControlName="type">
                <mat-option *ngFor="let ft of customFieldTypes" [value]="ft.ID">
                  {{ ft.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'" style="justify-content: center; padding-left: 0.5em">
            <mat-checkbox formControlName="optional">Opt </mat-checkbox>
          </dxi-item>
        </dx-box>

        <dx-box direction="row" [formGroup]="ctrl.get('meta')">
          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" style="justify-content: flex-end">
            <mat-form-field>
              <input
                matInput
                [type]="['NUMBER', 'CHECKBOX'].includes(ctrl.get('type').value) ? 'number' : 'text'"
                formControlName="defaultValue"
                placeholder="Default" />
            </mat-form-field>
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'" style="justify-content: flex-end">
            <mat-form-field>
              <input matInput formControlName="eqField" placeholder="Equal field" />
            </mat-form-field>
          </dxi-item>

          <dxi-item
            [ratio]="3"
            [shrink]="1"
            [baseSize]="'auto'"
            style="justify-content: flex-end"
            *ngIf="ctrl.get('type').value === 'DROPDOWN'">
            <dx-tag-box
              [acceptCustomValue]="true"
              formControlName="items"
              placeholder="Items"
              stylingMode="underlined"
              style="margin-bottom: 15px"></dx-tag-box>

            <!--            <mat-form-field>-->
            <!--              <mat-chip-list #itemList>-->
            <!--                <input-->
            <!--                       [matChipInputFor]="itemList"-->
            <!--                       formControlName="items"-->
            <!--                       placeholder="Items"-->
            <!--                >-->
            <!--              </mat-chip-list>-->
            <!--            </mat-form-field>-->
          </dxi-item>
        </dx-box>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
