import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import notify from 'devextreme/ui/notify';
import { noop } from 'lodash-es';
import get from 'lodash-es/get';
import uniqBy from 'lodash-es/uniqBy';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
//
import { getCurrentTenant } from 'src/app/store/reducers/sign';
import { oc } from 'ts-optchain';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import {
  Consumer,
  Employee,
  LoggerService,
  LoopBackFilter,
  TripManifest,
  TripManifestRec,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { NOTIFY_MODE } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-trip-manifest-manifest',
  templateUrl: './trip-manifest-manifest.component.html',
  styleUrls: ['./trip-manifest-manifest.component.scss'],
  providers: [HelperService, ConsumerHelperService, EmployeeHelperService],
})
export class TripManifestManifestComponent extends ABaseModelLoaderComponent<Employee> implements OnInit, OnChanges {
  // private dateManifestMap: Map<string, number> = new Map();
  validationSummary: any[];
  grid_stateStoring: any;
  gridTarget: string;
  // loadingVisible = false;

  // toolbarItems: any[];

  reloadManifest$: Subject<void> = new Subject<void>();
  manifest: TripManifest;
  validateManifest$: Subject<void> = new Subject<void>();

  // employeeMap: Map<number, any> = new Map();
  // consumerMap: Map<number, any> = new Map();

  ds: DataSource;
  driversDSO: DataSourceOptions;
  consumerDSO: DataSourceOptions;
  desDS: any[] = [];

  // notifying = false;

  // mdSnackBarRef: MatSnackBarRef<SnackBarCopyManifestComponent>;
  @Input() selectedDate: Date = new Date();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    public helper: HelperService,
    private common: CommonService,
    private gridHelper: GridHelperService,
    public employeeHelper: EmployeeHelperService,
    public consumerHelper: ConsumerHelperService,
    private sss: StateStoreService,
    private ui: UiService,
    protected cd: ChangeDetectorRef,
    protected logger: LoggerService,
    protected dss: DataSourceService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    super(logger, dss);

    this.gridTarget = this.ui.genId();

    this.buildOtherDs();
    this.setSubscriptions();
  }

  get exportFileName(): string {
    return this.helper.exportManifestFileName(this.model);
  }

  get dailyNoteVisible(): boolean {
    if (!this.manifest) {
      return false;
    }

    return ((this.manifest.data || []) as TripManifestRec[]).filter(rec => rec.dn && rec.dn.length).length > 0;
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['employeePosition', 'person'],
    };
  }

  employee_calculateCellValue = (rowData: any): string => {
    const employee: Employee = get(rowData, HelperService.REC_FIELD_MAP.employee, undefined);
    return this.employeeHelper.displayExpr(employee);
  };

  consumer_calculateCellValue = (rowData: any): string => {
    const consumer: Consumer = get(rowData, HelperService.REC_FIELD_MAP.consumer, undefined);
    return this.consumerHelper.displayExpr(consumer);
  };

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.reloadManifest$.next();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // this.helper.buildDateManifestMapAsync()
    //   .then(map => this.dateManifestMap = map)
    //   .catch(this.logger.error);
  }

  grid_repaint(): void {
    // setTimeout(() => this.grid && this.grid.instance && this.grid.instance.repaint());
    this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      // stateStoreGUID: null,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e: any): void {
    e.toolbarOptions.items
      .unshift
      //      {
      //        name: 'printCtrl',
      //        template: 'printCtrl',
      //        location: 'after',
      //        sortIndex: 30,
      //        locateInMenu: 'never',
      //        // widget: 'dxButton',
      //        // showText: 'inMenu',
      //        // options: {
      //        //   icon: 'fas fa-print',
      //        //   text: 'Print manifest',
      //        //   hint: 'Print manifest',
      //        //   onClick: this.printManifest_onClick.bind(this),
      //        // },
      //      },
      ();
  }

  grid_onCellPrepared(e) {
    const fieldValidationCodesMap = {
      [HelperService.REC_FIELD_MAP.destination]: ['NO_DESTINATION'],
      [HelperService.REC_FIELD_MAP.time]: ['NO_TIME'],
      [HelperService.REC_FIELD_MAP.trip]: ['NO_TRIP'],
      [HelperService.REC_FIELD_MAP.employeeId]: ['NOT_EXIST_EMPLOYEES', 'NOT_ACTIVE_EMPLOYEES'],
      [HelperService.REC_FIELD_MAP.vehicleId]: [
        'VEHICLE_SEVERAL_ASSIGNMENTS',
        'VEHICLE_MULTI_ASSIGNMENTS',
        'EMPLOYEE_VEHICLE_NO_ASSIGNMENT',
      ],
      [HelperService.REC_FIELD_MAP.consumerId]: ['DUPLICATE', 'NOT_EXIST_CONSUMERS', 'NOT_ACTIVE_CONSUMERS'],
    };

    if (Object.keys(fieldValidationCodesMap).includes(e.column.dataField)) {
      const codes = fieldValidationCodesMap[e.column.dataField];
      this.helper.processCellWarnings(this.validationSummary, e, codes);
    }
  }

  printBtn_onAction(action) {
    if (!this.manifest.id) {
      notify('No manifest exists', 'error', 3000);
      return;
    }

    const recs: TripManifestRec[] = this.manifest.data;
    const ids: number[] = query(recs || [])
      .groupBy(HelperService.REC_FIELD_MAP.employeeId)
      .toArray()
      .filter(({ key }) => (this.modelId ? key === this.modelId : true))
      .filter(({ key }) => key !== this.employeeHelper.selfEntity.id)
      .map(({ key }) => key);

    const settings = {
      mode: action,
      employeeIds: ids,
    };

    this.toggleLoadingPanelVisible(true);
    this.helper
      .printManifestAsync(this.manifest, settings)
      .catch(err => notify(err.message || err, 'error', 8000))
      .then(() => this.toggleLoadingPanelVisible(false));
  }

  printManifest_onClick() {
    if (this.manifest && this.manifest.id) {
      const recs: TripManifestRec[] = this.manifest.data;
      const ids: number[] = query(recs || [])
        .groupBy(HelperService.REC_FIELD_MAP.employeeId)
        .toArray()
        .filter(({ key }) => (this.modelId ? key === this.modelId : true))
        .filter(({ key }) => key !== this.employeeHelper.selfEntity.id)
        .map(({ key }) => key);

      this.toggleLoadingPanelVisible(true);
      this.helper
        .printManifestAsync(this.manifest, { mode: 'manifest', employeeIds: ids })
        // .catch(err => notify(err.message || err, 'error', 3000))
        .then(() => this.toggleLoadingPanelVisible(false));
    }
  }

  calculateSummary(options) {
    // Calculating "TotalConsumers"
    if (options.name === 'TotalConsumers') {
      if (options.summaryProcess === 'start') {
        // Initializing "totalValue" here
        options.totalValue = new Set<number>();
      }
      if (options.summaryProcess === 'calculate') {
        // Modifying "totalValue" here
        options.totalValue.add(options.value.c || options.value.id || options.value);
      }
      if (options.summaryProcess === 'finalize') {
        // Assigning the final value to "totalValue" here
        options.totalValue = options.totalValue.size;
      }
    }
  }

  private afterDataSourceChanged = () => {
    this.validateManifest$.next();
  };

  private setSubscriptions(): void {
    const currentTenant$ = this.common.store.select(getCurrentTenant);

    currentTenant$
      .pipe(
        switchMap(async (tenantId: number) => {
          this.desDS = await this.helper.getDestinationsDsAsync(tenantId);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    const loadManifest = (source: Observable<void>) =>
      source.pipe(
        tap(() => this.ui.showLoading()),
        switchMap(() => this.helper.api.getCurrentManifest(asShortDate(this.selectedDate))),
        switchMap(async (manifest: TripManifest) => {
          const ds: DataSource = await this.buildMainDsAsync(manifest);
          this.manifest = manifest;
          this.ds = ds;
          return manifest;
        }),
        tap(() => this.ui.hideLoading()),
        catchError(err => {
          this.ui.hideLoading();
          return of(err);
        }),
      );

    this.reloadManifest$
      .pipe(
        // prepare
        switchMap(async () => {
          this.ds = new DataSource([]);
        }),
        // process
        loadManifest,
        // finally
        switchMap(async manifest => {
          //
        }),
        catchError(err => of(notify(err.message || err, 'error', 3000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.validateManifest$
      .pipe(
        exhaustMap(() =>
          this.helper.validateManifest$(this.manifest).pipe(
            tap(res => {
              this.validationSummary = res;
              this.grid_repaint();
            }),
            catchError(err => of(notify(err.message || err, 'error', 5000))),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private async buildMainDsAsync(manifest: TripManifest): Promise<DataSource> {
    const as: ArrayStore = await this.helper.buildArrayStoreAsync(manifest);

    const dso: DataSourceOptions = {
      store: as,
      filter: this.modelId ? [HelperService.REC_FIELD_MAP.employeeId, '=', this.modelId] : undefined,
      onChanged: this.afterDataSourceChanged,
    } as DataSourceOptions;

    return new DataSource(dso);
  }

  private buildOtherDs(): void {
    this.driversDSO = {
      store: this.dss.getStore(Employee, null, false),
      filter: this.helper.buildDriversFilter('employeePosition_name'),
    };
    this.consumerDSO = {
      store: this.dss.getStore(Consumer, null, false),
    };
  }

  private toggleLoadingPanelVisible(value: boolean): void {
    if (value) {
      this.ui.showLoading();
    } else {
      this.ui.hideLoading();
    }
  }
}
