<app-drop-down-grid
  [dataSource]="ds"
  [gridColumns]="gridColumns"
  [placeholder]="'Select Consumer...'"
  [showClearButton]="false"
  [valueExpr]="'id'"
  [displayExpr]="consumerHelper.displayExpr"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (valueChanged)="valueChanged.emit($event)"
  (cellPrepared)="onCellPrepared($event)"
  [popupWidth]="350"
  [popupHeight]="300"></app-drop-down-grid>
