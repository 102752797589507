import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AcquisitionRelatedContact } from 'src/app/shared/sdk/models/AcquisitionRelatedContact';
import { AcquisitionRelatedContactApi } from 'src/app/shared/sdk/services/custom/AcquisitionRelatedContact';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../../shared/modules/ui/services/form-helper.service';
import { EmployeePayroll, FacilityView, LoopBackFilter, Person, Vehicle } from '../../../../../shared/sdk/models';
import { FacilityViewApi, LoggerService, PersonApi, VehicleApi } from '../../../../../shared/sdk/services/custom';

@Component({
  selector: 'app-employee-payroll-edit-form',
  templateUrl: './employee-payroll-edit-form.component.html',
  styleUrls: ['./employee-payroll-edit-form.component.scss'],
})
export class EmployeePayrollEditFormComponent extends ABaseFormComponent<EmployeePayroll> implements OnInit {
  $windowTitle$ = new BehaviorSubject<string>('Edit Payroll');

  resetableForm = false;
  collapsibleForm = false;

  PAYMENT_ARRANGEMENTS = [
    { value: 'hourly', text: 'Hourly' },
    { value: 'salary', text: 'Salary' },
    { value: 'contractor', text: 'Contractor' },
  ];

  @Input() employeeId: number;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    protected helper: FormHelperService<EmployeePayroll>,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);
  }

  protected get ModelClass(): any {
    return EmployeePayroll;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public async validateAsync(): Promise<boolean> {
    return true;
  }

  protected async afterSetFormValuesAsync(model, form: FormGroup) {}

  protected async afterSubmittedAsync(data: any, id: string | number): Promise<void> {}

  protected async beforeSubmittingAsync(payroll: EmployeePayroll): Promise<void> {
    await super.beforeSubmittingAsync(payroll);
    payroll.employeeId = this.employeeId;
    if (!this.model) return;
    const { paymentArrangement, rate, rate1, start, end, approvedBy, notes } = this.model;
    payroll.history = [
      ...(this.model.history || []),
      { paymentArrangement, rate, rate1, start, end, approvedBy, notes },
    ];
  }

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      paymentArrangement: [],
      rate: [],
      rate1: [],
      start: [],
      end: [],
      stimuliEnabled: [],
      stimuliAmount: [],
      stimuliAfterTripNumber: [],
      approvedBy: [],
      notes: [],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
