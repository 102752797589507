import { createSelector } from '@ngrx/store';
import { IEnvVars } from '../../../shared/modules/my-common/services/config.service';
import { Actions, LOAD_ENVVARS, LOAD_ENVVARS_FAIL, LOAD_ENVVARS_SUCCESS, LoadEnvVarsSuccess } from '../../actions/core';

export interface EnvVarsState {
  vars: IEnvVars;
  error: any | null;
  pending: boolean;
}

export const initialState: EnvVarsState = {
  vars: null,
  error: null,
  pending: false,
};

export function envVarsReducer(state = initialState, action: Actions): EnvVarsState {
  switch (action.type) {
    case LOAD_ENVVARS: {
      return {
        ...state,
        error: null,
        pending: true,
      } as EnvVarsState;
    }

    case LOAD_ENVVARS_SUCCESS: {
      return {
        ...state,
        vars: (action as LoadEnvVarsSuccess).payload,
        error: null,
        pending: false,
      } as EnvVarsState;
    }

    case LOAD_ENVVARS_FAIL: {
      return {
        ...state,
        vars: null,
        error: action.payload,
        pending: false,
      } as EnvVarsState;
    }

    default: {
      return state;
    }
  }
}

export const envVarsGetPending = (state: EnvVarsState) => state.pending;
export const envVarsGetError = (state: EnvVarsState) => state.error;
export const envVarsGetErrorMessage = createSelector(envVarsGetError, err => (err ? err.message || err : ''));

export const envVarsGetVars = (state: EnvVarsState) => state.vars;
