import { Pipe, PipeTransform } from '@angular/core';
import isPlainObject from 'lodash-es/isPlainObject';
import toPairs from 'lodash-es/toPairs';
import fromPairs from 'lodash-es/fromPairs';

@Pipe({
  name: 'filterJson',
})
export class FilterJson implements PipeTransform {
  transform(value: { [key: string]: any }): any {
    return isPlainObject(value)
      ? fromPairs(
          toPairs(value)
            .filter(([k, v]) => !k.startsWith('_'))
            .map(([k, v]) => [k, this.transform(v)]),
        )
      : value;
  }
}
