import {
  Facility,
  MyUser,
  Consumer,
  Employee,
  Vehicle,
  GeoPoint
} from '../index';

declare var Object: any;
export interface SignatureInterface {
  "requestID": string;
  "manifestRecId"?: string;
  "scheduledTime"?: string;
  "pickupTime"?: string;
  "dropoffTime"?: string;
  "arrivedTime"?: string;
  "imgFileId"?: string;
  "captured"?: boolean;
  "unableToSign"?: boolean;
  "oneWayOnly"?: boolean;
  "dateTimeOfService": Date;
  "vdate"?: any;
  "weekday"?: number;
  "vServiceType"?: string;
  "vTripId"?: string;
  "vAppName"?: string;
  "vTotalMealsUnits"?: number;
  "vUnits"?: number;
  "dateTime": Date;
  "type": string;
  "marker"?: string;
  "status"?: string;
  "validationState"?: string;
  "location"?: GeoPoint;
  "meta"?: any;
  "note"?: string;
  "id"?: number;
  "tenantId"?: number;
  "userId"?: number;
  "consumerId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  tenant?: Facility;
  user?: MyUser;
  consumer?: Consumer;
  employee?: Employee;
  vehicle?: Vehicle;
}

export class Signature implements SignatureInterface {
  "requestID": string;
  "manifestRecId": string;
  "scheduledTime": string;
  "pickupTime": string;
  "dropoffTime": string;
  "arrivedTime": string;
  "imgFileId": string;
  "captured": boolean;
  "unableToSign": boolean;
  "oneWayOnly": boolean;
  "dateTimeOfService": Date;
  "vdate": any;
  "weekday": number;
  "vServiceType": string;
  "vTripId": string;
  "vAppName": string;
  "vTotalMealsUnits": number;
  "vUnits": number;
  "dateTime": Date;
  "type": string;
  "marker": string;
  "status": string;
  "validationState": string;
  "location": GeoPoint;
  "meta": any;
  "note": string;
  "id": number;
  "tenantId": number;
  "userId": number;
  "consumerId": number;
  "employeeId": number;
  "vehicleId": number;
  tenant: Facility;
  user: MyUser;
  consumer: Consumer;
  employee: Employee;
  vehicle: Vehicle;
  constructor(data?: SignatureInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Signature`.
   */
  public static getModelName() {
    return "Signature";
  }

  /**
  * This method creates an instance of Signature for dynamic purposes.
  **/
  public static factory(data: SignatureInterface): Signature{
    return new Signature(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Signature',
      plural: 'Signatures',
      path: 'Signatures',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "manifestRecId": {
          name: 'manifestRecId',
          type: 'string'
        },
        "scheduledTime": {
          name: 'scheduledTime',
          type: 'string'
        },
        "pickupTime": {
          name: 'pickupTime',
          type: 'string'
        },
        "dropoffTime": {
          name: 'dropoffTime',
          type: 'string'
        },
        "arrivedTime": {
          name: 'arrivedTime',
          type: 'string'
        },
        "imgFileId": {
          name: 'imgFileId',
          type: 'string'
        },
        "captured": {
          name: 'captured',
          type: 'boolean',
          default: false
        },
        "unableToSign": {
          name: 'unableToSign',
          type: 'boolean',
          default: false
        },
        "oneWayOnly": {
          name: 'oneWayOnly',
          type: 'boolean',
          default: false
        },
        "dateTimeOfService": {
          name: 'dateTimeOfService',
          type: 'Date'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
        "vServiceType": {
          name: 'vServiceType',
          type: 'string'
        },
        "vTripId": {
          name: 'vTripId',
          type: 'string'
        },
        "vAppName": {
          name: 'vAppName',
          type: 'string'
        },
        "vTotalMealsUnits": {
          name: 'vTotalMealsUnits',
          type: 'number'
        },
        "vUnits": {
          name: 'vUnits',
          type: 'number'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "marker": {
          name: 'marker',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "validationState": {
          name: 'validationState',
          type: 'string',
          default: 'PENDING'
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'consumerId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
      }
    }
  }
}
