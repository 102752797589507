<dx-data-grid
  #grid
  height="100%"
  width="100%"
  [dataSource]="dso"
  [errorRowEnabled]="true"
  [stateStoring]="grid_stateStoring"
  [showRowLines]="true"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onExporting)="grid_onExporting($event)">
  <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

  <dxo-export [enabled]="true"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Staff or Participant Name" dataField="getName" dataType="string"></dxi-column>

  <dxi-column
    caption="Staff arrival in the screening area"
    dataField="getEmployeeArrivalTime"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column
    caption="Participant arrival in screening area"
    dataField="getClientArrivalTime"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column
    caption="The Center must document staff and participant temperatures ≥ 100.0°F"
    dataField="temperature"
    dataType="number"></dxi-column>

  <dxi-column
    caption="Participants who were refused entry or returned home due to exhibiting symptoms of virus"
    dataField="getClientRefused"
    dataType="string"></dxi-column>

  <dxi-column
    caption="Staff who were refused entry or returned home due to exhibiting symptoms of virus"
    dataField="getEmployeeRefused"
    dataType="string"></dxi-column>

  <dxi-column
    caption="Participants returned home due to measures to maintain social distancing"
    dataField="getClientRetHome"
    dataType="string"></dxi-column>

  <dxi-column
    caption="Staff returned home when measures to maintain social distancing due to reduced staffing ratio"
    dataField="getEmployeeRetHome"
    dataType="string"></dxi-column>

  <dxi-column
    caption="Documentation of daily sanitization measures in day program areas as recommended by the CDC"
    dataField="getAdcCovid19Measures"
    dataType="string"></dxi-column>

  <dxi-column caption="PPE issued to staff" dataField="getEmployeePpe" dataType="string"></dxi-column>

  <dxi-column caption="PPE issued to participants" dataField="getClientPpe" dataType="string"></dxi-column>

  <dxi-column
    caption="Reporting Mode"
    dataField="getReportingMode"
    dataType="string"
    [allowHeaderFiltering]="true"></dxi-column>

  <!--    <dxi-column caption="Date"-->
  <!--                dataField="vdate"-->
  <!--                dataType="date"-->
  <!--                format="shortDate"-->
  <!--                [editorOptions]="{type: 'date', displayFormat: 'shortDate'}"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Arrival Time"-->
  <!--                dataField="arrivalTime"-->
  <!--                dataType="date"-->
  <!--                format="shortDateShortTime"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Temperature (F)"-->
  <!--                dataField="temperature"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Temperature Above Normal"-->
  <!--                dataField="tempAboveNorm"-->
  <!--                dataType="boolean"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="is Absent"-->
  <!--                dataField="isAbsent"-->
  <!--                dataType="boolean"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Entry Refused"-->
  <!--                dataField="entryRefused"-->
  <!--                dataType="boolean"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="PPE Issued"-->
  <!--                dataField="ppeIssued"-->
  <!--                dataType="boolean"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Staff"-->
  <!--                dataField="getEmployee"-->
  <!--                dataType="string"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Client"-->
  <!--                dataField="getConsumer"-->
  <!--                dataType="string"-->
  <!--    ></dxi-column>-->

  <!-- Templates -->
</dx-data-grid>
