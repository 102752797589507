import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, of } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AcquisitionRelatedContact } from 'src/app/shared/sdk/models/AcquisitionRelatedContact';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { FacilityView, Person, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { VEHICLE_STATUSES } from 'src/app/modules/fleet/classes/const';
export class VehicleFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, config, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.helper = helper;
        this.STATES = ['ACTIVE', 'INACTIVE'];
        this.statuses = Object.keys(VEHICLE_STATUSES);
        this.vehicleStatuses = VEHICLE_STATUSES;
        this.TYPES = ['AMBULANCE', 'BUS', 'WC BUS', 'VAN', 'WC VAN', 'WC/STRETCHER VAN', 'SEDAN', 'SUV'];
        this.HAS_LIFT_RAMP = ['None', 'Electric Lift', 'Electric Ramp', 'Manual Ramp'];
        this.CONFIGS = ['14 S', '10 S 2 WC', '12 S 1 WC'];
        this.OWNERSHIPS = ['Owned-Paid', 'Owned-Financed', 'Leased'];
        this.OWNERSHIP_STATUS = [
            'Active Lease',
            'Active Finance',
            'Own - Purchase after Lease',
            'Own - Paid Off Finance',
            'Own - Cash Purchase',
            'Decommissioned - In Possession  (left for part or extreme backup)',
            'Decommissioned - Returned After Lease',
            'Decommissioned - Sold',
            'Decommissioned - Donated',
            'Decommissioned - Totalled',
            'Other',
        ];
        this.$windowTitle$ = new BehaviorSubject('');
        this.$windowSubTitle$ = new BehaviorSubject('');
        this.tabsTitles = ['Vehicle Info', 'Config', 'Equipment', 'Maintenance Records', 'Financials'];
        this.resetableForm = false;
        this.collapsibleForm = false;
        this.locationsDs$ = of([]);
        this.facilityDso$ = of([]);
        this.dealerDso = of([]);
        this.creditorDso = of([]);
        this.personDso = of([]);
        this.selectedAcquisitionForm = null;
        this.setState(FORM_STATE.COLLAPSED);
        this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
        // this.locationsDs$ = of(oc(this.config).config.vehicleLocationList([]));
        this.facilityDso$ = this.buildFacilityDataSource();
        this.dealerDso = this.buildContactDataSource('DEALER');
        this.creditorDso = this.buildContactDataSource('CREDITOR');
        this.personDso = this.buildPersonDataSource();
        this.model$
            .pipe(tap(model => {
            if (model) {
                const title = ['#' + model.internalId, model.state].join(' - ');
                const subTitle = [
                    model.type,
                    `${model.year} ${model.make} ${model.model}`,
                    `VIN ${oc(model)
                        .vin('')
                        .substr(oc(model).vin('').length - 6)}`,
                    `SEATS:${model.passengerCapacity || 0}, WC:${model.wheelchairCapacity || 0}`,
                ].join(' - ');
                this.$windowTitle$.next('Edit: ' + title);
                this.$windowSubTitle$.next(subTitle);
            }
            else {
                this.$windowTitle$.next('Add New Vehicle');
                this.$windowSubTitle$.next('');
            }
        }), takeUntil(this.$onDestroy$))
            .subscribe();
        this.locationsDs$ = this.model$.pipe(startWith(null), map(model => {
            return uniq([...(model ? [model.location] : []), ...oc(this.config).config.vehicleLocationList([])]).sort();
        }));
    }
    get acquisitionsFormArray() {
        return this.form.get('acquisitions');
    }
    get anyNotesFormArray() {
        return this.form.get('anyNotes');
    }
    get internalId() {
        return this.form.get('internalId').value;
    }
    get ModelClass() {
        return Vehicle;
    }
    get dateFields() {
        return [
            // 'registrationExp',
            'inspectionExp',
            'purchaseDate',
            'titleDate',
            'gpsInstalledDate',
            'cameraInstalledDate',
            'originalDate',
            'maturityDate',
            'lastMechanicalCheck',
            'lastOutsideWash',
            'lastInsideCleanup',
            'acquisitionDate',
            'fireExtinguisherExp',
        ];
    }
    get filter() {
        return {
            include: [
                {
                    relation: 'anyNotes',
                    scope: {
                        order: 'updatedAt DESC',
                        where: { $or: [{ acquisitionId: { $exists: false } }, { acquisitionId: null }] },
                        include: 'createdBy',
                    },
                },
                {
                    relation: 'acquisitions',
                    scope: {
                        order: 'date DESC',
                        include: [{ relation: 'anyNotes', scope: { order: 'updatedAt DESC', include: 'createdBy' } }],
                    },
                },
            ],
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.addAcquisitionForm();
    }
    status_onSelectionChanged(e) {
        setTimeout(() => !VEHICLE_STATUSES[e.selectedItem].subStatuses.includes(this.form.get('subStatus').value) &&
            this.form.get('subStatus').setValue(VEHICLE_STATUSES[e.selectedItem].subStatuses[0] || ''), 100);
    }
    addAcquisitionForm() {
        if (this.selectedAcquisitionForm) {
            this.validateAsync();
            if (!this.selectedAcquisitionForm.valid)
                return;
        }
        this.addFormArrayItem('acquisitions', 0);
        this.selectedAcquisitionForm = this.acquisitionsFormArray.controls[0];
    }
    removeSelectedAcquisitionForm() {
        const idx = this.acquisitionsFormArray.controls.findIndex(form => form === this.selectedAcquisitionForm);
        this.removeFormArrayItem('acquisitions', idx);
        this.selectedAcquisitionForm = this.acquisitionsFormArray.controls[0];
    }
    onAcquisitionFormChanged(event) {
        this.selectedAcquisitionForm = event.value;
    }
    getCurrentAcquisitionName() {
        const form = this.acquisitionsFormArray.controls[0];
        const date = (form.value.date && ` - ${new Date(form.value.date).toLocaleDateString('en-US')}`) || '';
        return `${this.model.internalId} - ${form.value.type || ''}${date}`;
    }
    afterSetFormValuesAsync(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.acquisitionsFormArray.value.length)
                this.addFormArrayItem('acquisitions');
            this.selectedAcquisitionForm = this.acquisitionsFormArray.controls[0];
        });
    }
    afterSubmittedAsync(data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Workaround. Refresh the form to get just created IDs if they exist. Otherwise, you will create new items with every next save.
            let refresh = !!this.acquisitionsFormArray.value.find(itm => !itm.id || (itm.anyNotes && itm.anyNotes.find(i => !i.id)));
            refresh = refresh || (!!this.anyNotesFormArray && this.anyNotesFormArray.value.find(itm => !itm.id));
            if (refresh)
                this.refresh();
        });
    }
    beforeSubmittingAsync(data) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, data);
        });
    }
    buildForm() {
        this.formConfigMap.set('meta', {});
        this.formConfigMap.set('acquisitions', {
            id: [],
            vehicleId: [],
            date: [],
            type: [],
            dealer: [],
            creditor: [],
            fullPrice: [],
            principalBalance: [],
            termInMonth: [],
            loanPayment: [],
            frequencyOfLoanPayment: [],
            paymentDates: [],
            firstPaymentDate: [],
            lastPaymentDate: [],
            annualInterestRate: [],
            contract: [],
            contractDate: [],
            leaseMileageLimit: [],
            buyoutOption: [],
            balloonAmount: [],
            notes: [],
            signorPersonId: [],
            creditorPersonId: [],
            salePersonId: [],
            file: [],
            anyNotes: this.fb.array([]),
        });
        this.formConfigMap.set('', {
            id: [],
            internalId: [],
            assignedFacilityId: [],
            assignedFacility: [],
            acquisitions: this.fb.array([]),
            anyNotes: this.fb.array([]),
            state: [],
            status: [],
            subStatus: [],
            location: [],
            make: [],
            model: [],
            year: [],
            vin: [],
            titleNumber: [],
            licenseState: [],
            licensePlateNumber: [],
            // registrationExp: [],
            inspectionExp: [],
            color: [],
            note: [],
            //
            fuel: [],
            type: [],
            wheelchairAccessible: [false],
            stretcherAccessible: [false],
            hasLiftRamp: ['None'],
            liftHandPumpHandle: [false],
            passengerCapacity: [],
            cdl: [false],
            wheelchairCapacity: [],
            possibleConfigs: [],
            hvacUnits: [],
            emergencyExitDoor: [false],
            engineType: [],
            tireSize: [],
            bodyCompany: [],
            grossWeight: [],
            autoEmBraking: [false],
            laneDepWarning: [false],
            configNote: [],
            //
            seatBeltCutter: [false],
            firstAidKit: [false],
            bloodBornePathogenKit: [false],
            emTriangles: [false],
            umbrella: [false],
            cleaningSupplies: [false],
            broom: [false],
            ppe: [false],
            fireExtinguisherInstalled: [false],
            fireExtinguisherExp: [],
            fireExtinguisher2Installed: [false],
            fireExtinguisher2Exp: [],
            wcHooksNum: [],
            wcSeatBeltsNum: [],
            wsMountGPSHolder: [false],
            stepStool: [false],
            jumpCables: [false],
            ezPassTransponder: [false],
            ezHandGPS: [false],
            seatBeltExt: [false],
            thermometer: [false],
            kubotaKey: [false],
            usbCharger: [false],
            usbChargingWire: [false],
            foldableWheelchair: [false],
            foldableTransportChair: [false],
            stairChair2Men: [false],
            stairwayWCCarrier: [false],
            snowShovel: [false],
            wsBrush: [false],
            salt: [false],
            sanitizerDispenser: [false],
            buCameraInstalled: [false],
            gpsUnitInstalled: [false],
            gpsBypassUsed: [false],
            gpsSerialNumber: [],
            gpsUnitModel: [],
            gpsInstalledDate: [],
            cameraInstalled: [false],
            cameraModel: [],
            cameraSerialNumber: [],
            cameraInstalledDate: [],
            covid19Shield: [false],
            //
            lastMechanicalCheck: [],
            lastOutsideWash: [],
            lastInsideCleanup: [],
            nextTransmOilChangeMiles: [],
            //
            acquisitionDate: [],
            purchaseDate: [],
            seller: [],
            titleDate: [],
            acquisitionType: [],
            ownership: [],
            ownershipStatus: [],
            creditorName: [],
            contractNumber: [],
            contractInterest: [],
            contractLimitations: [],
            originalAmount: [],
            originalDate: [],
            maturityDate: [],
            contractMonthlyAmount: [],
            subLeaseMonthlyRate: [],
            subLeaseDailyRate: [],
            meta: this.fb.group(this.formConfigMap.get('meta')),
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
    buildFacilityDataSource() {
        return this.dss
            .getApi(FacilityView)
            .find({
            where: { type: { inq: ['ADC', 'BASE', 'MEALS'] } },
            order: ['typeOrder DESC', 'type ASC', 'shortname ASC'],
        })
            .pipe(map(items => {
            const _items = items.map(f => ({ ID: f.shortname, TITLE: f.name }));
            _items.unshift(...oc(this.config)
                .config.destinationList([])
                .filter(d => ['FACILITY', 'SCHOOL'].includes(d.group))
                .map(d => ({ ID: d.short, TITLE: d.name })));
            return _items;
        }));
    }
    buildContactDataSource(type) {
        return this.dss.getApi(AcquisitionRelatedContact).find({
            where: { type: type },
            order: ['businessName'],
        });
    }
    buildPersonDataSource() {
        return this.dss.getApi(Person).find({
            order: ['firstname', 'lastname'],
        });
    }
}
