import {
  Facility,
  Employee,
  Vehicle
} from '../index';

declare var Object: any;
export interface VehicleGeotabEventInterface {
  "vehicleId"?: number;
  "internalId"?: number;
  "vin"?: string;
  "tenantId"?: number;
  "employeeId"?: number;
  "deviceId"?: string;
  "eventType"?: string;
  "eventDetails"?: string;
  "latitude"?: number;
  "longitude"?: number;
  "loop"?: any;
  "speed"?: number;
  "address"?: string;
  "dateTime"?: Date;
  "serialNumber"?: string;
  "rule"?: string;
  "speedLimit"?: number;
  "diagnostic"?: string;
  "assetInspectionDefect"?: string;
  "supervisorsComment"?: string;
  "id"?: any;
  tenant?: Facility;
  employee?: Employee;
  vehicle?: Vehicle;
}

export class VehicleGeotabEvent implements VehicleGeotabEventInterface {
  "vehicleId": number;
  "internalId": number;
  "vin": string;
  "tenantId": number;
  "employeeId": number;
  "deviceId": string;
  "eventType": string;
  "eventDetails": string;
  "latitude": number;
  "longitude": number;
  "loop": any;
  "speed": number;
  "address": string;
  "dateTime": Date;
  "serialNumber": string;
  "rule": string;
  "speedLimit": number;
  "diagnostic": string;
  "assetInspectionDefect": string;
  "supervisorsComment": string;
  "id": any;
  tenant: Facility;
  employee: Employee;
  vehicle: Vehicle;
  constructor(data?: VehicleGeotabEventInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `VehicleGeotabEvent`.
   */
  public static getModelName() {
    return "VehicleGeotabEvent";
  }

  /**
  * This method creates an instance of VehicleGeotabEvent for dynamic purposes.
  **/
  public static factory(data: VehicleGeotabEventInterface): VehicleGeotabEvent{
    return new VehicleGeotabEvent(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VehicleGeotabEvent',
      plural: 'VehicleGeotabEvents',
      path: 'VehicleGeotabEvents',
      idName: 'id',
      properties: {
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
        "internalId": {
          name: 'internalId',
          type: 'number'
        },
        "vin": {
          name: 'vin',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "deviceId": {
          name: 'deviceId',
          type: 'string'
        },
        "eventType": {
          name: 'eventType',
          type: 'string'
        },
        "eventDetails": {
          name: 'eventDetails',
          type: 'string'
        },
        "latitude": {
          name: 'latitude',
          type: 'number'
        },
        "longitude": {
          name: 'longitude',
          type: 'number'
        },
        "loop": {
          name: 'loop',
          type: 'any'
        },
        "speed": {
          name: 'speed',
          type: 'number'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "serialNumber": {
          name: 'serialNumber',
          type: 'string'
        },
        "rule": {
          name: 'rule',
          type: 'string'
        },
        "speedLimit": {
          name: 'speedLimit',
          type: 'number'
        },
        "diagnostic": {
          name: 'diagnostic',
          type: 'string'
        },
        "assetInspectionDefect": {
          name: 'assetInspectionDefect',
          type: 'string'
        },
        "supervisorsComment": {
          name: 'supervisorsComment',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
      }
    }
  }
}
