import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//
import { Document, LoopBackFilter, MyUser } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { USER_STATUSES } from '../../classes/enums';

@Component({
  selector: 'app-mfa-form',
  templateUrl: './mfa-form.component.html',
  styleUrls: ['./mfa-form.component.scss'],
})
export class MfaFormComponent extends ABaseFormComponent<MyUser> {
  statuses = USER_STATUSES;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected config: ConfigService,
    protected helper: FormHelperService<MyUser>,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  protected get ModelClass(): any {
    return MyUser;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      mfaEmailEnabled: [''],
      mfaSmsEnabled: [''],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }

  protected async beforeModelLoadingAsync(id: number | string): Promise<void> {
    await super.beforeModelLoadingAsync(id);

    this.buildForm();
  }
}
