import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
//
import { Person } from '../../../../shared/sdk/models';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-person-drop-down-grid',
  templateUrl: './person-drop-down-grid.component.html',
  styleUrls: ['./person-drop-down-grid.component.scss'],
  providers: [
    HelperService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonDropDownGridComponent),
      multi: true,
    },
  ],
})
export class PersonDropDownGridComponent implements OnInit, ControlValueAccessor {
  _onChange: any;
  _onTouched: any;

  @Input() dataSource: any;
  @Input() remoteOperations: any;
  @Input() grid_columns: any[] = [
    { dataField: 'firstname', caption: 'Firstname' },
    { dataField: 'lastname', caption: 'Lastname' },
    { dataField: 'nickname', caption: 'Nickname' },
  ];
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() value: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();

  constructor(private dss: DataSourceService, public config: ConfigService, public helper: HelperService) {
    this.buildDataSource();
  }

  ngOnInit() {
    this.valueChange.subscribe(value => this._onChange && this._onChange(value));
    this.focusOut.subscribe(() => this._onTouched && this._onTouched());
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private buildDataSource() {
    this.dataSource = {
      store: this.dss.getStore(Person, null, false),
    };
  }
}
