import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { VehicleDetailsComponent } from './components/vehicle-details/vehicle-details.component';
import { VehicleGridComponent } from './components/vehicle-grid/vehicle-grid.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { VehicleLocationComponent } from './containers/vehicle-location/vehicle-location.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [VehicleLocationComponent, VehicleListComponent, VehicleGridComponent, VehicleDetailsComponent],
  exports: [VehicleLocationComponent],
  entryComponents: [VehicleLocationComponent],
})
export class VehicleLocationModule {}
