import { createSelector } from '@ngrx/store';
import { Actions, CHECK_AUTH, CHECK_AUTH_FAIL, CHECK_AUTH_SUCCESS } from '../../actions/sign';

export interface CheckAuthState {
  error: any | null;
  pending: boolean;
}

export const initialState: CheckAuthState = {
  error: null,
  pending: false,
};

export function checkAuthReducer(state = initialState, action: Actions): CheckAuthState {
  switch (action.type) {
    case CHECK_AUTH: {
      return {
        ...state,
        error: null,
        pending: true,
      } as CheckAuthState;
    }

    case CHECK_AUTH_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
      } as CheckAuthState;
    }

    case CHECK_AUTH_FAIL: {
      return {
        ...state,
        error: action.payload || 'Server unavailable',
        pending: false,
      } as CheckAuthState;
    }

    default: {
      return state;
    }
  }
}

export const checkAuthGetPending = (state: CheckAuthState) => state.pending;
export const checkAuthGetError = (state: CheckAuthState) => state.error;
export const checkAuthGetErrorMessage = createSelector(checkAuthGetError, err => (err ? err.message || err : ''));
