import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil } from 'rxjs/operators';
//
import { Document, DocumentView, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';
import { DocumentFormComponent } from '../document-form/document-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-document-grid',
  templateUrl: './document-grid.component.html',
  styleUrls: ['./document-grid.component.scss'],
  providers: [HelperService],
})
export class DocumentGridComponent extends ABaseComponent implements OnInit {
  dso: DataSourceOptions;
  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('116fbc0e-dc08-41e8-82bd-949fc1c7fd07');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'f2bc713e-c8f6-4310-a52f-d37b63ca0689',
    };

    this.dso = this.dss.getDataSourceOptions(Document);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([Document.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newDocument',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-plus',
        text: 'New document',
        hint: 'Create new document',
        onClick: this.grid_toolbar_newDocument_onClick.bind(this),
      },
    });
  }

  grid_onSelectionChanged(event: any): void {
    // this.log.log(data);
    this.mySelected.emit(event.selectedRowKeys);
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const title = `Edit: ${this.helper.displayExpr(e.data)}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
      title,
    });
  }

  grid_toolbar_newDocument_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
    });
  }
}
