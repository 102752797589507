<kendo-splitter orientation="horizontal">
  <kendo-splitter-pane [scrollable]="false">
    <kendo-splitter orientation="vertical">
      <kendo-splitter-pane [scrollable]="false">
        <div style="width: 100%; height: 100%; position: relative; display: flex;">
          <dx-data-grid
            style="position: absolute;"
            #memberGrid

            width="100%"
            height="100%"

            [searchPanel]="{ visible: false }"
            [headerFilter]="{ visible: true }"
            [filterRow]="{ visible: true }"
            [filterPanel]="{ visible: true }"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [dataSource]="memberDso"
            [stateStoring]="grid_stateStoring"
            [dateSerializationFormat]="null"

            (onInitialized)="grid_onInitialized($event)"
            (onCellPrepared)="grid_onCellPrepared($event)"
            (onToolbarPreparing)="grid_onToolbarPreparing($event)"
            (onSelectionChanged)="grid_onSelectionChanged($event)"
          >
            <dxo-export [enabled]="true"></dxo-export>

            <dxo-selection mode="single"></dxo-selection>

            <dxo-editing
              mode="cell"
              [allowAdding]="false"
              [allowUpdating]="false"
              [allowDeleting]="false"
            ></dxo-editing>

            <!--    <dxo-sorting mode="none"></dxo-sorting>-->
            <!--      <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>-->

            <!-- region Columns -->

            <dxi-column caption="Broker" dataField="last._broker" dataType="string"></dxi-column>
            <dxi-column caption="MedicaidID" dataField="last._mci" dataType="string"></dxi-column>
            <dxi-column caption="MemberID" dataField="last.MemberID" dataType="string"></dxi-column>
            <dxi-column caption="FirstName" dataField="last.FirstName" dataType="string"></dxi-column>
            <dxi-column caption="LastName" dataField="last.LastName" dataType="string"></dxi-column>

            <dxi-column
              caption="DOB"
              dataField="last.MemberDOB"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
            ></dxi-column>

            <dxi-column caption="Phone" dataField="last.Phone" dataType="string"></dxi-column>

            <dxi-column caption="E9y" dataField="__e9y" cellTemplate="e9y_cell"
                        [filterOperations]='["=" , "<>", "contains", "notcontains", "anyof", "noneof", "isblank", "isnotblank"]'
                        [calculateFilterExpression]="e9y_calculateFilterExpression"
            >
              <dxo-lookup [dataSource]="e9yCodeDs$ | async"></dxo-lookup>
            </dxi-column>

            <dxi-column caption="E9y Name" dataField="__e9yNames" cellTemplate="e9yNames_cell"
                        [filterOperations]='["=" , "<>", "contains", "notcontains", "anyof", "noneof", "isblank", "isnotblank"]'
                        [calculateFilterExpression]="e9y_calculateFilterExpression"
            >
              <dxo-lookup [dataSource]="e9yNameDs$ | async"></dxo-lookup>
            </dxi-column>

            <dxi-column caption="E9y Count" dataField="__e9yCount" dataType="number"></dxi-column>

            <dxi-column caption="Claims" dataField="__claims_statuses" cellTemplate="claims_cell"
                        [filterOperations]='["=" , "<>", "contains", "notcontains", "anyof", "noneof", "isblank", "isnotblank"]'
                        [calculateFilterExpression]="claims_calculateFilterExpression"
            >
              <dxo-lookup [dataSource]="claimStatusDs"></dxo-lookup>
            </dxi-column>

            <dxi-column caption="Claims Count" dataField="__claimCount" dataType="number"></dxi-column>

            <dxi-column caption="Auths" dataField="authCount" dataType="number"></dxi-column>

            <dxi-column caption="Auth Units" dataField="authUnits" dataType="number"></dxi-column>

            <dxi-column caption="Units" dataField="__units" dataType="number"></dxi-column>

            <dxi-column caption="Tenant" dataField="last._tenantId">
              <dxo-lookup [dataSource]="facilityDso" valueExpr="id" displayExpr="shortname"></dxo-lookup>
            </dxi-column>

            <!-- endregion -->

            <!-- region Templates -->

            <div *dxTemplate="let member of 'detail'">
            </div>

            <ng-container *dxTemplate="let cellInfo of 'e9y_cell'">
          <span [title]="cellInfo.data.__e9y || [] | join: ', '" style="font-size: 7pt;">
            <span class="badge" *ngFor="let item of cellInfo.data.__e9y || []"
            >{{ item || 'other' }}</span>
          </span>
            </ng-container>

            <ng-container *dxTemplate="let cellInfo of 'e9yNames_cell'">
          <span [title]="cellInfo.data.__e9yNames || [] | join: ', '" style="font-size: 7pt;">
            <span class="badge" *ngFor="let item of cellInfo.data.__e9yNames || []"
            >{{ item || 'other' }}</span>
          </span>
            </ng-container>

            <ng-container *dxTemplate="let cellInfo of 'claims_cell'">
          <span [title]="cellInfo.data.__claims_codes || [] | join: ', '" style="font-size: 6.5pt;">
            <span class="badge" *ngFor="let item of cellInfo.data.claims || []"
            >{{ item?.meta?.code }}|{{ item?.claim?.status }}|{{ item?.meta?.authUnits }}</span>
          </span>
            </ng-container>

            <!-- endregion -->

          </dx-data-grid>
        </div>
      </kendo-splitter-pane>

      <kendo-splitter-pane [collapsible]="true" [scrollable]="false" size="40%">
        <div style="width: 100%; height: 100%; position: relative; display: flex; flex-direction: column;">
          <div style="margin: 0.5em;">
        <span style="margin: 0.5em; font-size: 10pt;" *ngIf="selectedMember?.last">
          <strong>{{ selectedMember?.last?.Name }}</strong>
          (
          DOB: <strong>{{ selectedMember?.last?.MemberDOB | utc: 'L' }}</strong>;
          MemberID: <strong>{{ selectedMember?.last?.MemberID }}</strong>;
          MedicaidID: <strong>{{ selectedMember?.last?.MedicaidID }}</strong>
          )
        </span>
          </div>

          <div style="flex: auto; margin: 0.5em; position: relative;">
            <dx-tab-panel width="100%" height="100%" style="position: absolute;">

              <dxi-item title="Auths">
                <dx-data-grid
                  #authGrid

                  width="100%"
                  height="100%"

                  [searchPanel]="{ visible: false }"
                  [headerFilter]="{ visible: true }"
                  [filterRow]="{ visible: true }"
                  [filterPanel]="{ visible: true }"
                  [columnResizingMode]="'widget'"
                  [columnAutoWidth]="true"
                  [grouping]="{ autoExpandAll: true }"

                  keyExpr="_id"
                  [dataSource]="authDso"
                  [stateStoring]="authGrid_stateStoring"

                  (onSelectionChanged)="authGrid_onSelectionChanged($event)"
                  (onContextMenuPreparing)="authGrid_onContextMenuPreparing($event)"
                  (onCellPrepared)="authGrid_onCellPrepared($event)"
                >

                  <dxo-editing
                    mode="cell"
                    [allowAdding]="false"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                  ></dxo-editing>

                  <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

                  <!-- region Columns -->

                  <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

                  <dxi-column caption="MemberID" dataField="MemberID" dataType="string" [visible]="false"></dxi-column>
                  <dxi-column caption="MedicaidID" dataField="MedicaidID" dataType="string"
                              [visible]="false"></dxi-column>
                  <dxi-column caption="Name" dataField="Name" dataType="string" [visible]="false"></dxi-column>
                  <dxi-column caption="LastName" dataField="LastName" dataType="string" [visible]="false"></dxi-column>
                  <dxi-column caption="FirstName" dataField="FirstName" dataType="string"
                              [visible]="false"></dxi-column>

                  <dxi-column
                    caption="MemberDOB"
                    dataField="MemberDOB"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                    [visible]="false"
                  ></dxi-column>

                  <dxi-column caption="Code" dataField="Code" dataType="string" [fixed]="true"
                              [groupIndex]="0"></dxi-column>

                  <dxi-column
                    caption="StartDT"
                    dataField="StartDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                    [fixed]="true"
                  ></dxi-column>

                  <dxi-column
                    caption="EndDT"
                    dataField="EndDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                    [fixed]="true"
                  ></dxi-column>

                  <dxi-column caption="Rule" dataField="_manifest" cellTemplate="rule_cell" [fixed]="true"></dxi-column>
                  <dxi-column caption="Claims" dataField="__claims.length" dataType="number"></dxi-column>
                  <dxi-column caption="Claim Status Notes" dataField="__claims" dataType="string"
                              cellTemplate="notes_cell"></dxi-column>

                  <dxi-column caption="Frequency" dataField="Frequency" dataType="string"></dxi-column>
                  <dxi-column caption="Note" dataField="Note" dataType="string"></dxi-column>

                  <dxi-column caption="AuthUnitsApproved" dataField="AuthUnitsApproved" dataType="number"></dxi-column>

                  <dxi-column caption="AuthNumberFacets" dataField="AuthNumberFacets" dataType="string"></dxi-column>
                  <dxi-column caption="AuthNumberUMI" dataField="AuthNumberUMI" dataType="string"></dxi-column>

                  <dxi-column caption="Phone" dataField="Phone" dataType="string" [allowSorting]="false"></dxi-column>
                  <dxi-column caption="IOType" dataField="IOType" dataType="string"></dxi-column>

                  <dxi-column caption="RequestType" dataField="RequestType" dataType="string"></dxi-column>
                  <dxi-column caption="RequestStatus" dataField="RequestStatus" dataType="string"></dxi-column>

                  <dxi-column caption="RSProviderName" dataField="RSProviderName" dataType="string"></dxi-column>

                  <dxi-column caption="ProviderType" dataField="ProviderType" dataType="string"></dxi-column>
                  <dxi-column caption="ProviderName" dataField="ProviderName" dataType="string"></dxi-column>
                  <dxi-column caption="Duration" dataField="Duration" dataType="number"></dxi-column>

                  <dxi-column caption="Valid" dataField="_valid" dataType="boolean"></dxi-column>

                  <dxi-column caption="Service Coordinator">
                    <dxi-column caption="ServiceCoordinatorName" dataField="ServiceCoordinatorName"
                                dataType="string"></dxi-column>
                    <dxi-column caption="ServiceCoordinatorPhone" dataField="ServiceCoordinatorPhone"
                                dataType="string"></dxi-column>
                    <dxi-column caption="ServiceCoordinatorEmail" dataField="ServiceCoordinatorEmail"
                                dataType="string"></dxi-column>
                  </dxi-column>

                  <dxi-column
                    caption="AuthLastModifiedDT"
                    dataField="AuthLastModifiedDT"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <dxi-column
                    caption="Imported Date"
                    dataField="_ctime"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <dxi-column
                    caption="Updated Date"
                    dataField="_rtime"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <dxi-column caption="Tenant" dataField="_tenantId">
                    <dxo-lookup [dataSource]="facilityDso" valueExpr="id" displayExpr="shortname"></dxo-lookup>
                  </dxi-column>

                  <!-- endregion -->

                  <!-- region Templates -->

                  <ng-container *dxTemplate="let cellInfo of 'notes_cell'">
          <span style="font-size: 7pt;">
            <ng-container *ngFor="let cl of cellInfo.data.__claims || []"
            >{{ cl?.claim?.statusNote }}<br /></ng-container>
          </span>
                  </ng-container>

                  <ng-container *dxTemplate="let cellInfo of 'rule_cell'">
                    <table class="rules" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>mo</td>
                        <td>tu</td>
                        <td>we</td>
                        <td>th</td>
                        <td>fr</td>
                        <td>sa</td>
                        <td>su</td>
                        <td>tot</td>
                      </tr>
                      <tr>
                        <td>{{ cellInfo.value?.M }}</td>
                        <td>{{ cellInfo.value?.T }}</td>
                        <td>{{ cellInfo.value?.W }}</td>
                        <td>{{ cellInfo.value?.Th }}</td>
                        <td>{{ cellInfo.value?.F }}</td>
                        <td>{{ cellInfo.value?.Sa }}</td>
                        <td>{{ cellInfo.value?.Su }}</td>
                        <td>{{ cellInfo.value?.Tot }}</td>
                      </tr>
                    </table>
                  </ng-container>

                  <!-- endregion -->

                </dx-data-grid>
              </dxi-item>

              <dxi-item title="Navinet Claims">
                <dx-data-grid
                  #statusGrid

                  width="100%"
                  height="100%"

                  keyExpr="_id"
                  [dataSource]="nnClaimsDso"
                  [stateStoring]="nnClaims_stateStoring"

                  [searchPanel]="{ visible: false }"
                  [headerFilter]="{ visible: true }"
                  [filterRow]="{ visible: true }"
                  [filterPanel]="{ visible: true }"
                  [columnResizingMode]="'widget'"
                  [columnAutoWidth]="true"
                  [grouping]="{ autoExpandAll: true }"

                  (onCellPrepared)="nnClaims_onCellPrepared($event)"
                >
                  <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

                  <!-- region Columns -->

                  <dxi-column dataField="Tax ID" dataType="string"></dxi-column>
                  <dxi-column dataField="ClaimID" dataType="string"></dxi-column>
                  <dxi-column dataField="MemberID" dataType="string"></dxi-column>
                  <dxi-column dataField="MedicaidID" dataType="string"></dxi-column>
                  <dxi-column dataField="Name" dataType="string"></dxi-column>

                  <dxi-column
                    dataField="SVCStartDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                  ></dxi-column>

                  <dxi-column
                    dataField="SVCEndDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                  ></dxi-column>

                  <dxi-column
                    dataField="ClaimRCVDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                  ></dxi-column>

                  <dxi-column dataField="Payment Check Number" dataType="string"></dxi-column>
                  <dxi-column dataField="ClaimStatus" dataType="string"></dxi-column>
                  <dxi-column dataField="BillAmount" dataType="number"></dxi-column>
                  <dxi-column dataField="PaymentAMT" dataType="number"></dxi-column>
                  <dxi-column dataField="DENYAMT" dataType="number"></dxi-column>

                  <dxi-column
                    dataField="PaymentDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                  ></dxi-column>

                  <dxi-column dataField="EOBEXPLCD" dataType="string"></dxi-column>
                  <dxi-column dataField="EOBEXPDESC" dataType="string"></dxi-column>
                  <dxi-column dataField="DISEXPCD" dataType="string"></dxi-column>
                  <dxi-column dataField="DISEXPDSC" dataType="string"></dxi-column>

                  <!-- endregion -->

                </dx-data-grid>
              </dxi-item>

              <dxi-item title="Eligibilities">
                <dx-scroll-view height="100%" width="100%">
                  <ngx-json-viewer [json]="e9yDso || {}" [expanded]="false"></ngx-json-viewer>
                </dx-scroll-view>
              </dxi-item>

              <dxi-item title="Claim Set">
                <dx-scroll-view height="100%" width="100%">
                  <ngx-json-viewer [json]="clSetDso || {}" [expanded]="false"></ngx-json-viewer>
                </dx-scroll-view>
              </dxi-item>

            </dx-tab-panel>
          </div>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </kendo-splitter-pane>

  <kendo-splitter-pane [collapsible]="true" [scrollable]="false" size="550px">
    <div style="width: 100%; height: 100%; position: relative; display: flex; flex-direction: column">
      <div style="position: relative; flex: auto; margin: 0.5em;">
        <dx-scheduler
          #scheduler
          style="position: absolute"
          width="100%"
          height="100%"

          [views]="['month']"
          currentView="month"
          [dataSource]="appointments"
          [currentDate]="selectedDate"
          [min]="min"
          [max]="max"

          [firstDayOfWeek]="1"
          [startDayHour]="6"
          [endDayHour]="23"
          [showAllDayPanel]="false"
          [editing]="false"

          (mouseup)="scheduler_mouseup($event)"
          (onAppointmentDblClick)="scheduler_onAppointmentDblClick($event)"
          (onCellContextMenu)="scheduler_onCellContextMenu($event)"
        >

          <dxi-resource
            fieldExpr="rescode"
            label="Code"
            [dataSource]="resourceDs"
            [useColorAsDefault]="true"
          ></dxi-resource>

        </dx-scheduler>

        <dx-context-menu
          [items]="scheduler_ctxMenuItems"
          [disabled]="scheduler_ctxMenuDisabled"
          [target]="'.dx-scheduler-date-table-cell'"
          (onItemClick)="schedulerCtxMenu_OnItemClick($event)"
          [width]="200"
        ></dx-context-menu>
      </div>

      <div style="position: relative; margin: 0.5em;">
        <table class="summary" *ngIf="selectedPeriod[0] !== selectedPeriod[1]">
          <ng-container *ngFor="let code of procedureCodes">
            <tr *ngIf="(subCounters[code].a + subCounters[code].s + subCounters[code].c1) > 0">
              <td>{{ code }} ({{ selectedPeriod[0] | utc:'MM/DD' }}-{{ selectedPeriod[1] | utc:'MM/DD' }})</td>
              <td>auths:</td>
              <td>{{ subCounters[code].a }}</td>
              <td>signed units:</td>
              <td>{{ subCounters[code].s }}</td>
              <td>claims:</td>
              <td>
                <span title="Submitted Units">{{ subCounters[code].c1 }}</span>
                /
                <span title="Paid Units">{{ subCounters[code].c2 }}</span>
              </td>
            </tr>
          </ng-container>
        </table>

        <table class="summary">
          <ng-container *ngFor="let code of procedureCodes">
            <tr *ngIf="(counters[code].a + counters[code].s + counters[code].c1) > 0">
              <td>{{ code }}</td>
              <td>auths:</td>
              <td>{{ counters[code].a }}</td>
              <td>signed units:</td>
              <td>{{ counters[code].s }}</td>
              <td>claims:</td>
              <td>
                <span title="Submitted Units">{{ counters[code].c1 }}</span>
                /
                <span title="Paid Units">{{ counters[code].c2 }}</span>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>



