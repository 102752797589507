import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment, { duration } from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { InternalStorage } from 'src/app/shared/sdk';

@Component({
  selector: 'app-vehicle-details-tab-locations',
  templateUrl: './vehicle-details-tab-locations.component.html',
  styleUrls: ['./vehicle-details-tab-locations.component.scss'],
  providers: [],
})
export class VehicleDetailsTabLocationsComponent extends ABaseComponent {
  _markers: any[];

  @Input()
  set markers(ms: any[]) {
    this._markers = ms;
    this.changeHandle();
  }
  get markers() {
    return this._markers;
  }
  mapCenter = { lat: 39.98887229222728, lng: -75.15665435443266 };
  mapZoom = 11;
  autoAdjust = true;

  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private internalStorage: InternalStorage,
    private myUtilsAPI: MyUtilsApi,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  async changeHandle() {}
}
