<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Internal ID</div>
        <div class="dx-field-value-static">
          {{ model?.internalId }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Insurance</div>
        <div class="dx-field-value-static">
          {{ model?.state }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Status</div>
        <div class="dx-field-value-static">
          {{ model?.status }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Location</div>
        <div class="dx-field-value-static">
          {{ model?.location }}
        </div>
      </div>

      <div style="display: flex; flex-direction: row">
        <div class="dx-field" style="flex: auto">
          <div class="dx-field-label" title="Registration Exp. Date">Registration Exp. Date</div>
          <div class="dx-field-value-static">
            {{ model?.registrationExp }}
          </div>
        </div>

        <div class="dx-field" style="flex: auto">
          <div class="dx-field-label" title="Inspection Exp. Date">Inspection Exp. Date</div>
          <div class="dx-field-value-static">
            {{ model?.inspectionExp }}
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Make</div>
        <div class="dx-field-value-static">
          {{ model?.make }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Model</div>
        <div class="dx-field-value-static">
          {{ model?.model }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Year</div>
        <div class="dx-field-value-static">
          {{ model?.year }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">VIN</div>
        <div class="dx-field-value-static">
          {{ model?.vin }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Title Number</div>
        <div class="dx-field-value-static">
          {{ model?.titleNumber }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label" title="License Plate Number / State">License Plate Number / State</div>
        <div class="dx-field-value-static">{{ model?.licensePlateNumber }} / {{ model?.licenseState }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Color</div>
        <div class="dx-field-value-static">
          {{ model?.color }}
        </div>
      </div>

      <!---->

      <div class="dx-field">
        <div class="dx-field-label">Note</div>
        <div class="dx-field-value-static">
          {{ model?.note }}
        </div>
      </div>
    </div>
  </ng-container>
</app-details-tab>
