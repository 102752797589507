import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { clone } from 'lodash-es';
import difference from 'lodash-es/difference';
import groupBy from 'lodash-es/groupBy';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combinations } from '../../../../shared/classes/utils/object.utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { Facility, LoggerService, MyUserApi, Role } from '../../../../shared/sdk';

@Component({
  selector: 'app-roles-form',
  templateUrl: './roles-form.component.html',
  styleUrls: ['./roles-form.component.scss'],
})
export class RolesFormComponent extends ABaseComponent implements OnInit {
  // form: FormGroup;
  error = null;

  facilityDso: DataSourceOptions = [];
  rolesDso: DataSourceOptions = [];

  $userRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  $currentRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  $tenantGroups$: BehaviorSubject<[string, string[]][]> = new BehaviorSubject<[string, string[]][]>([]);
  $roleGroups$: BehaviorSubject<[string, string[]][]> = new BehaviorSubject<[string, string[]][]>([]);

  rolesForRemove: { [r: string]: boolean } = {};
  rolesToAdd: { [r: string]: boolean } = {};
  addingRole = false;
  groupedBy = 'byFacility';

  $reloadRoles$: Subject<any> = new Subject();

  @Input() userId: number | string;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected userApi: MyUserApi,
    protected dss: DataSourceService,
  ) {
    super(logger);

    // this.buildForm();

    this.buildRolesDataSource();
    this.facilityDso = this.buildFacilityDataSource();
  }

  ngOnInit() {
    this.rolesForRemove = {};
    this.rolesToAdd = {};

    this.$reloadRoles$
      .pipe(
        startWith(true),
        switchMap(() => this.userApi.getRoles(this.userId)),
        tap((roles: string[]) => {
          roles = roles.filter(r => !r.startsWith('$'));
          roles = roles.map(r => (r.includes(':') ? r : r + ':' + '0'));

          this.rolesForRemove = {};
          this.rolesToAdd = {};

          this.$userRoles$.next(roles);
          this.$currentRoles$.next(roles);
        }),
        catchError(err => of(null)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$currentRoles$
      .pipe(
        tap(roles => {
          this.$tenantGroups$.next(this.groupRolesByTenant(clone(roles)));
          this.$roleGroups$.next(this.groupRolesByName(clone(roles)));
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  submitForm(): Observable<any> {
    this.error = null;

    // if (this.form.valid) {

    const [savedRoles, newRoles] = [
      this.$userRoles$.value,
      this.$currentRoles$.value.filter(r => !this.rolesForRemove[r]),
    ].map(roles => roles.map(r => (r.split(':')[1] === '0' ? r.split(':')[0] : r)));

    const forDelete = difference(savedRoles, newRoles);
    const forAdd = difference(newRoles, savedRoles);

    // console.log(forDelete, forAdd);

    return of(true).pipe(
      switchMap(() => (forDelete.length ? this.userApi.unmapFromRoles(this.userId, forDelete) : of(null))),
      switchMap(() => (forAdd.length ? this.userApi.mapToRoles(this.userId, forAdd) : of(null))),
      tap(() => this.$reloadRoles$.next(true)),
    );

    // } else {
    //   return throwError(new Error('Invalid form value'));
    // }
  }

  addRole() {
    this.addingRole = true;
  }

  setRoles(roles?: string[], fIds?: string[]) {
    let pairs: [string, string][] = [];

    if (isEmpty(fIds) && !isEmpty(roles)) {
      pairs = combinations(roles, ['0']);
    } else if (!isEmpty(fIds) && !isEmpty(roles)) {
      pairs = combinations(roles, fIds);
    }

    if (!isEmpty(pairs)) {
      const savedRoles: string[] = this.$userRoles$.value;
      const curRoles = this.$currentRoles$.value;

      const newRoles = pairs.map(([role, fId]) => role + ':' + fId);
      newRoles.forEach(r => (this.rolesForRemove[r] = false));

      const allRoles = uniq([...curRoles, ...newRoles]);

      this.rolesToAdd = {};
      const forAdd = difference(allRoles, savedRoles);
      forAdd.forEach(r => (this.rolesToAdd[r] = true));

      this.$currentRoles$.next(allRoles);
    }

    this.addingRole = false;
  }

  removeRole(role?: string, fId?: string) {
    const curRoles = this.$currentRoles$.value;
    if (role && fId) {
      this.rolesForRemove[role + ':' + fId] = !this.rolesForRemove[role + ':' + fId];
    } else if (!role && fId) {
      curRoles.filter(r => r.endsWith(':' + fId)).forEach(r => (this.rolesForRemove[r] = !this.rolesForRemove[r]));
    } else if (!fId && role) {
      curRoles.filter(r => r.startsWith(role + ':')).forEach(r => (this.rolesForRemove[r] = !this.rolesForRemove[r]));
    }
  }

  private groupRolesByTenant(roles: string[]) {
    roles = roles.map(r => (r.includes(':') ? r : r + ':' + '0'));
    const entries = Object.entries(groupBy(roles, r => r.split(':')[1]));

    return sortBy<[string, string[]]>(
      entries.map(([fId, fRoles]) => [fId, fRoles.map(r => r.split(':')[0]).sort()]),
      ([fId]) => fId,
    );
  }

  private groupRolesByName(roles: string[]) {
    roles = roles.map(r => (r.includes(':') ? r : r + ':' + '0'));
    const entries = Object.entries(groupBy(roles, r => r.split(':')[0]));

    return sortBy<[string, string[]]>(
      entries.map(([fRole, fRoles]) => [fRole, fRoles.map(r => r.split(':')[1]).sort()]),
      ([fRole]) => fRole,
    );
  }

  private ungroupRoles(entries: [string, string[]][]) {
    return entries.map(([fId, roleNames]) => roleNames.map(r => r + ':' + fId)).flat();
  }

  // protected buildForm(): void {
  //   this.form = this.fb.group({
  //     roles: [],
  //   });
  // }

  private buildRolesDataSource() {
    return this.userApi
      .getAllRoles()
      .pipe(
        tap((roles: Role[]) => {
          roles = roles.filter(r => !r.name.includes(':'));
          roles = sortBy(roles, 'name');
          this.rolesDso = roles;
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      postProcess(records: any[]) {
        records = Object.entries(groupBy(records, 'type')) //
          .map(([key, items]) => ({ key, items }));
        return records;
      },
    } as DataSourceOptions;

    return dso;
  }
}
