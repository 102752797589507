<dx-data-grid height="100%" width="auto" [dataSource]="dso" [remoteOperations]="false">
  <!-- Settings -->

  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-export [enabled]="true" fileName="history-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Settings" [calculateDisplayValue]="settings_calculateValue"></dxi-column>
  <dxi-column caption="Pick State" dataField="Pick State" dataType="string"></dxi-column>
  <dxi-column caption="Pick County" dataField="Pick County" dataType="string"></dxi-column>
  <dxi-column caption="Date" dataField="Date" dataType="string"></dxi-column>
  <dxi-column caption="APPOINT TIME" dataField="APPOINT TIME/RR TIME" dataType="string"></dxi-column>
  <dxi-column caption="Customer" dataField="Customer" dataType="string"></dxi-column>
  <dxi-column caption="Mode" dataField="meta.repetitiveMode" dataType="string"></dxi-column>
  <dxi-column caption="Leg" dataField="leg"></dxi-column>

  <!-- Templates -->
</dx-data-grid>

