import * as tslib_1 from "tslib";
import { Validators } from '@angular/forms';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { CustomFieldsComponent } from '../../../shared/modules/ui/components/custom-fields/custom-fields.component';
import { getValuePropNameByFieldType } from '../../../shared/modules/ui/components/custom-fields/utils';
//
import { Document, DocumentType, ObjectCFV, Vehicle, } from '../../../shared/sdk';
import { ABaseDocumentFormComponentWithUploader } from './a-base-document-form-with-uploader.component';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
export class ABaseDocumentFormComponentWithCustomsFields extends ABaseDocumentFormComponentWithUploader {
    constructor(logger, fb, uploadHelper, dss, helper) {
        super(logger, fb, uploadHelper, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.uploadHelper = uploadHelper;
        this.dss = dss;
        this.helper = helper;
        this.customFields = [];
        this.form
            .get('documentTypeId')
            .valueChanges.pipe(takeUntil(this.$onDestroy$))
            .subscribe((docTypeId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.customFields = [];
            this.customFields = yield this.getDocTypeCustomFields(docTypeId);
        }));
    }
    get ModelClass() {
        return Document;
    }
    get filter() {
        return {
            include: ['documentType', 'owner', 'forObject', { customFieldValues: ['customField'] }, 'customFields'],
        };
    }
    buildForm() {
        this.formConfigMap.set('', {
            id: [],
            name: ['', Validators.required],
            notes: [],
            objectType: [],
            objectId: [],
            documentTypeId: ['', Validators.required],
            _cfv: this.fb.array([]),
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
    setFormValues(model, isReset = true, resetValidators = true) {
        super.setFormValues(model, isReset, resetValidators);
        if (this.customFieldsCtrl && isReset) {
            this.customFieldsCtrl.resetForm();
        }
    }
    beforeSubmittingAsync(data) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, data);
            const documentApi = this.dss.getApi(Document);
            const objectCFVApi = this.dss.getApi(ObjectCFV);
            // clear model custom fields
            if (this.model) {
                const cfvs = (yield documentApi.getCustomFieldValues(this.modelId).toPromise()) || [];
                yield Promise.all(cfvs.map((cfv) => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield objectCFVApi.deleteById(cfv.id).toPromise(); })));
            }
        });
    }
    afterSubmittedAsync(data, obj) {
        const _super = Object.create(null, {
            afterSubmittedAsync: { get: () => super.afterSubmittedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterSubmittedAsync.call(this, data, obj);
            const documentApi = this.dss.getApi(Document);
            const objectCFVApi = this.dss.getApi(ObjectCFV);
            // reset model custom fields
            const cfs = this.customFields;
            yield Promise.all(cfs
                .map(cf => [cf, this.buildCustomFieldValue(cf)])
                .filter(([cf, cfv]) => !!cfv)
                .map(([cf, cfv]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (cf['field'] === 'regCardExpDate' && obj.objectType == 'Vehicle') {
                    const api = yield this.dss.getApi(Vehicle);
                    const veh = yield api.findById(obj.objectId).toPromise();
                    veh['registrationExp'] = cfv['valueDate'];
                    yield api.mySaveWithRelated(veh.id, veh, headersAllTenantsAppend).toPromise().catch(console.error);
                }
                yield documentApi.linkCustomFields(obj.id, cf.id, cfv).toPromise().catch(console.error);
            })));
        });
    }
    getDocTypeCustomFields(docTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const api = this.dss.getApi(DocumentType);
            return docTypeId ? (yield api.getCustomFields(docTypeId).toPromise()) || [] : [];
        });
    }
    buildCustomFieldValue(cf) {
        const idx = this.customFields.findIndex(_cf => _cf.id === cf.id);
        const valuePropName = getValuePropNameByFieldType(cf.type);
        let value = this.customFieldsCtrl ? this.customFieldsCtrl.formArray.at(idx).get(cf.field).value : undefined;
        if (!value) {
            return undefined;
        }
        if (['DATE', 'EXPIRATION_DATE'].includes(cf.type)) {
            value = moment(value).format('YYYY-MM-DD');
        }
        return {
            objectType: Document.getModelName(),
            [valuePropName]: value,
        };
    }
}
