import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AutoDispatchLog } from 'src/app/shared/sdk';

@Component({
  selector: 'app-clone-log-details-tabs',
  templateUrl: './clone-log-details-tabs.component.html',
  styleUrls: ['./clone-log-details-tabs.component.scss'],
})
export class CloneLogDetailsTabsComponent implements OnInit {
  selectedIndex = 0;
  tabs: any[] = [{ title: 'Created Employee Schedules', template: 'createdSchedules' }];

  @Input() log: any;

  constructor() {}

  ngOnInit() {}

  repaint() {}
}

