import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
//
import { ConsumerDropDownGridComponent } from '../../../modules/consumer/components/consumer-drop-down-grid/consumer-drop-down-grid.component';
import { EmployeeDropDownGridComponent } from '../../../modules/employee/components/employee-drop-down-grid/employee-drop-down-grid.component';
import { PersonDropDownGridComponent } from '../../../modules/person/components/person-drop-down-grid/person-drop-down-grid.component';
import { VehicleDropDownGridComponent } from '../../../modules/vehicle/components/vehicle-drop-down-grid/vehicle-drop-down-grid.component';
//
import { MyCommonModule } from '../my-common/my-common.module';
import { MyDevExtremeModule } from '../third-party/my-dev-extreme.module';
import { MyFontAwesomeModule } from '../third-party/my-font-awesome.module';
import { MyKendoModule } from '../third-party/my-kendo.module';
import { MyMaterialModule } from '../third-party/my-material.module';
import { MySyncfusionModule } from '../third-party/my-syncfusion.module';
//
import { ButtonWDropDownComponent } from './components/button-w-drop-down/button-w-drop-down.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { DetailsTabComponent } from './components/details-tab/details-tab.component';
import { DropDownGridNewComponent } from './components/drop-down-grid-new/drop-down-grid-new.component';
import { DropDownGridOldComponent } from './components/drop-down-grid-old/drop-down-grid-old.component';
import { DropDownGridComponent } from './components/drop-down-grid/drop-down-grid.component';
import { FormArrayGroupComponent } from './components/form-array-group/form-array-group.component';
import { FormDialogTemplateComponent } from './components/form-dialog-template/form-dialog-template.component';
import { ObjectTitleComponent } from './components/object-title/object-title.component';
import { OfflineOverlayComponent } from './components/offline-overlay/offline-overlay.component';
import { SnackBarTemplateComponent } from './components/snack-bar-template/snack-bar-template.component';
import { LuhnValidatorDirective, TestComponent } from './components/test/test.component';
import { WeekdayNumberBoxComponent } from './components/weekday-number-box/weekday-number-box.component';
//
import { FillContentLayoutComponent } from './layout/fill-content-layout/fill-content-layout.component';
import { FilterJson } from './pipes/filter-json.pipe';
//
import { FullNamePipe } from './pipes/full-name.pipe';
import { SanitizeStylePipe } from './pipes/sanitize-style.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { WeekDaysPipe } from './pipes/week-days.pipe';
//
import { FormHelperService } from './services/form-helper.service';
import { GridHelperService } from './services/grid-helper.service';
import { UiService } from './services/ui.service';
import { UploadHelperService } from './services/upload-helper.service';
import { WeekdayCheckboxComponent } from './components/weekday-checkbox/weekday-checkbox.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ContentWHeaderComponent } from './components/content-w-header/content-w-header.component';
import {
  DlgConfirmSignatureForClientSelectComponent,
  DlgConfirmSignatureSelectComponent,
  DlgSelectSignatureComponent,
} from './components/dlg-select-signature/dlg-select-signature.component';
import { TimeGridComponent } from './components/time-grid/time-grid.component';
import { MapValueBoxComponent } from './components/map-value-box/map-value-box.component';
import { ShowLoopDialogComponent } from './components/show-loop-dialog/show-loop-dialog.component';
import { DlgShowJsonComponent } from './components/dlg-show-json/dlg-show-json.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MyFontAwesomeModule,
    MyMaterialModule,
    MyDevExtremeModule,
    MyKendoModule,
    MySyncfusionModule,
    NgxJsonViewerModule,

    MyCommonModule,
  ],
  declarations: [
    ButtonWDropDownComponent,
    CustomFieldsComponent,
    DetailsTabComponent,
    DropDownGridComponent,
    FillContentLayoutComponent,
    FormDialogTemplateComponent,
    SnackBarTemplateComponent,
    TestComponent,
    FormArrayGroupComponent,
    OfflineOverlayComponent,
    WeekdayNumberBoxComponent,
    WeekdayCheckboxComponent,
    TimeGridComponent,
    DropDownGridOldComponent,
    DropDownGridNewComponent,
    PersonDropDownGridComponent,
    ConsumerDropDownGridComponent,
    EmployeeDropDownGridComponent,
    VehicleDropDownGridComponent,
    ObjectTitleComponent,
    ContentWHeaderComponent,
    DlgSelectSignatureComponent,
    DlgConfirmSignatureSelectComponent,
    DlgConfirmSignatureForClientSelectComponent,
    DlgShowJsonComponent,

    LuhnValidatorDirective,

    FullNamePipe,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    SanitizeStylePipe,
    WeekDaysPipe,
    FilterJson,
    MapValueBoxComponent,
    ShowLoopDialogComponent,
  ],
  exports: [
    MyFontAwesomeModule,
    MyMaterialModule,
    MyDevExtremeModule,
    MyKendoModule,
    MySyncfusionModule,

    ButtonWDropDownComponent,
    CustomFieldsComponent,
    DetailsTabComponent,
    DropDownGridComponent,
    FillContentLayoutComponent,
    TestComponent,
    FormArrayGroupComponent,
    OfflineOverlayComponent,
    WeekdayNumberBoxComponent,
    WeekdayCheckboxComponent,
    TimeGridComponent,
    DropDownGridOldComponent,
    DropDownGridNewComponent,
    PersonDropDownGridComponent,
    ConsumerDropDownGridComponent,
    EmployeeDropDownGridComponent,
    VehicleDropDownGridComponent,
    ObjectTitleComponent,
    ContentWHeaderComponent,
    DlgSelectSignatureComponent,
    DlgConfirmSignatureSelectComponent,
    DlgConfirmSignatureForClientSelectComponent,
    DlgShowJsonComponent,

    LuhnValidatorDirective,

    FullNamePipe,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    SanitizeStylePipe,
    WeekDaysPipe,
    FilterJson,
    MapValueBoxComponent,
    ShowLoopDialogComponent,
  ],
  entryComponents: [
    FormDialogTemplateComponent,
    SnackBarTemplateComponent,
    OfflineOverlayComponent,
    DlgSelectSignatureComponent,
    DlgConfirmSignatureSelectComponent,
    DlgConfirmSignatureForClientSelectComponent,
    DlgShowJsonComponent,
    TestComponent,
    ShowLoopDialogComponent,
  ],
  providers: [],
})
export class UiModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiModule,
      providers: [
        UiService,
        GridHelperService,
        UploadHelperService,
        FormHelperService,
        { provide: 'Window', useValue: window },
      ],
    };
  }
}
