import { Component, ViewChild } from '@angular/core';
import head from 'lodash-es/head';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { oc } from 'ts-optchain';
//
import { ConsumerDetailsComponent } from '../../components/consumer-details/consumer-details.component';
import { ConsumerListComponent } from '../../components/consumer-list/consumer-list.component';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.scss'],
})
export class ConsumerComponent {
  $selectedIds$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedId$: Observable<any> = new Observable();

  @ViewChild(ConsumerListComponent, { static: true }) consumerList: ConsumerListComponent;
  @ViewChild(ConsumerDetailsComponent, { static: true }) consumerDetails: ConsumerDetailsComponent;

  constructor() {
    this.selectedId$ = this.$selectedIds$.pipe(map(ids => head(ids)));
  }

  list_onSelected(keys: any[]) {
    this.$selectedIds$.next(oc(keys)([]));
  }
}
