<div>
  <div class="options">
    <div class="option">
      <dx-check-box text="Show Totals Prior" [(value)]="showTotalsPrior"></dx-check-box>
    </div>
    <div class="option">
      <dx-check-box text="Data Field Headers in Rows" [(value)]="rowsDataFieldArea"></dx-check-box>
    </div>
    <div class="option">
      <dx-check-box text="Tree Row Header Layout" [(value)]="treeHeaderLayout"></dx-check-box>
    </div>
    <div class="option"><dx-check-box text="Show Column Totals" [(value)]="showColumnTotals"></dx-check-box></div>
    <div class="option"><dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box></div>
    <div class="option">
      <dx-check-box text="Show Column Grand Totals" [(value)]="showColumnGrandTotals"></dx-check-box>
    </div>
    <div class="option"><dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box></div>
  </div>

  <dx-pivot-grid
    width="100%"
    height="100%"
    [dataSource]="dataSource"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showTotalsPrior]="showTotalsPrior ? 'both' : 'none'"
    [dataFieldArea]="rowsDataFieldArea ? 'row' : 'column'"
    [rowHeaderLayout]="treeHeaderLayout ? 'tree' : 'standard'"
    [showColumnTotals]="showColumnTotals"
    [showRowTotals]="showRowTotals"
    [showColumnGrandTotals]="showColumnGrandTotals"
    [showRowGrandTotals]="showRowGrandTotals"
    [stateStoring]="grid_stateStoring">
    <dxo-field-chooser applyChangesMode="onDemand"></dxo-field-chooser>

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true">
    </dxo-field-panel>

    <!--    <dxo-scrolling mode="virtual" [useNative]="true"></dxo-scrolling>-->
    <dxo-scrolling [useNative]="true"></dxo-scrolling>

    <dxo-export [enabled]="true" [fileName]="exportFileName"></dxo-export>
  </dx-pivot-grid>
</div>
