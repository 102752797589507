export const CONSUMER_STATUSES: any[] = [
  { ID: 'ACTIVE', Name: 'ACTIVE' },
  { ID: 'INACTIVE', Name: 'INACTIVE' },
  { ID: 'PENDING', Name: 'PENDING' },
  // {ID: 'BLOCKED', Name: 'BLOCKED'},
  { ID: 'ON_HOLD', Name: 'ON HOLD' },
  // {ID: 'VISITOR', Name: 'VISITOR'},
];

// export const CONSUMER_INSTRUCTIONS: any[] = [
//   {ID: 'SA', Name: 'SA'},
//   {ID: 'SPECIAL_ATTENTION', Name: 'SPECIAL ATTENTION'},
//   {ID: 'SPECIAL_ATTN', Name: 'SPECIAL ATTN'},
//   {ID: 'SPEC_ATTN', Name: 'SPEC ATTN'},
//   {ID: 'BLIND', Name: 'BLIND'},
//   {ID: 'DEAF', Name: 'DEAF'},
// ];

export const CONSUMER_TRANSPORTATION_CODES = [
  { ID: 'AC', Name: 'ADVANCE CALL' },
  { ID: 'ATTN', Name: 'ATTENDANT' },
  { ID: 'CANE', Name: 'CANE' },
  { ID: 'CRTCH', Name: 'CRUTCH' },
  { ID: 'DNLA', Name: "DON'T LEAVE ALONE" },
  { ID: 'EWC', Name: 'ELECTR WC' },
  { ID: 'HAID', Name: 'HEARING AID' },
  { ID: 'SA', Name: 'SPECIAL ATTN' },
  { ID: 'STPS', Name: 'STEPS' },
  { ID: 'STRTCR', Name: 'STRETCHER' },
  { ID: 'UL', Name: 'USE LIFT' },
  { ID: 'VIMP', Name: 'VISION IMP' },
  { ID: 'WC', Name: 'WHEELCHAIR' },
  { ID: 'WC2S', Name: 'WC TO SEAT' },
  { ID: 'WLKR', Name: 'WALKER' },
  { ID: 'STPSTL', Name: 'STEP STOOL' },
  { ID: 'LRV', Name: 'LOW RIDING VEHICLE' },
  { ID: 'SCTR', Name: 'SCOOTER' },
];

export const CONSUMER_LANGS = [
  'English',
  'Russian',
  'Korean',
  'Albanian',
  'Chinese',
  'Chinese-Mandarin',
  'Chinese-Cantonese',
  'Chinese-Hunanese',
  'Vietnamese',
  'Indian',
  'Indian-Hindi',
  'Indian-Malayalam',
  'Indian-Gujarati',
  'Indian-Bengali',
  'Indian-Telugu',
  'Indian-Marathi',
  'Urdu',
  'Ukrainian',
  'Creole',
  'OTHER',
];
