import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { get } from 'lodash-es';
import { LoggerService, TripManifestRec } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from 'src/app/modules/schedule/services/helper.service';

@Component({
  selector: 'app-dlg-manifest-grid',
  templateUrl: './dlg-manifest-grid.component.html',
  styleUrls: ['./dlg-manifest-grid.component.scss'],
  providers: [ConsumerHelperService, HelperService],
})
export class DlgManifestGridComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgManifestGridComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public helper: HelperService,
    public consumerHelper: ConsumerHelperService,
  ) {}

  ngOnInit() {}

  getDriverFullName = (trip: TripManifestRec): string => {
    const empl = this.data.driversMap[trip.e];
    return empl ? `${empl.person_firstname} ${empl.person_lastname}` : '';
  };

  getConsumerFullName = (trip: TripManifestRec): string =>
    this.consumerHelper.displayExpr(this.data.consumersMap[trip.c]);

  getFullAddress = (trip: TripManifestRec): string =>
    this.helper.getFullAddress((get(this.data.consumersMap[trip.c], `person.contact.addresses`) || [])[0]);
  getMainPhone = (trip: TripManifestRec): string =>
    get((get(this.data.consumersMap[trip.c], `person.contact.phones`) || [])[0], 'value');
}

