/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consumer-drop-down-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/drop-down-grid-new/drop-down-grid-new.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../shared/modules/ui/components/drop-down-grid-new/drop-down-grid-new.component";
import * as i5 from "../../../../shared/sdk/services/custom/logger.service";
import * as i6 from "./consumer-drop-down-grid.component";
import * as i7 from "../../services/helper.service";
import * as i8 from "../../../../shared/sdk/services/custom/Consumer";
import * as i9 from "../../../../shared/sdk/services/custom/ConsumerView";
import * as i10 from "../../../../shared/modules/my-common/services/config.service";
import * as i11 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i12 from "@angular/common/http";
import * as i13 from "../../../../shared/modules/my-common/services/common.service";
import * as i14 from "../../../../shared/modules/ui/services/ui.service";
import * as i15 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_ConsumerDropDownGridComponent = [i0.styles];
var RenderType_ConsumerDropDownGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsumerDropDownGridComponent, data: {} });
export { RenderType_ConsumerDropDownGridComponent as RenderType_ConsumerDropDownGridComponent };
export function View_ConsumerDropDownGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-drop-down-grid-new", [], null, [[null, "valueChange"], [null, "valueChanged"], [null, "grid_cellPrepared"], [null, "focusout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.valueChange.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChanged" === en)) {
        var pd_2 = (_co.valueChanged.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("grid_cellPrepared" === en)) {
        var pd_3 = (_co.grid_cellPrepared.emit($event) !== false);
        ad = (pd_3 && ad);
    } if (("focusout" === en)) {
        var pd_4 = (_co.focusOut.emit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_DropDownGridNewComponent_0, i2.RenderType_DropDownGridNewComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DropDownGridNewComponent]), i1.ɵdid(2, 114688, null, 0, i4.DropDownGridNewComponent, [i5.LoggerService], { dataSource: [0, "dataSource"], remoteOperations: [1, "remoteOperations"], valueExpr: [2, "valueExpr"], displayExpr: [3, "displayExpr"], placeholder: [4, "placeholder"], showClearButton: [5, "showClearButton"], grid_columns: [6, "grid_columns"], popup_width: [7, "popup_width"], popup_height: [8, "popup_height"], disabled: [9, "disabled"], value: [10, "value"] }, { valueChange: "valueChange", valueChanged: "valueChanged", grid_cellPrepared: "grid_cellPrepared" }), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.remoteOperations; var currVal_2 = "id"; var currVal_3 = _co.helper.displayExpr; var currVal_4 = "Select Consumer..."; var currVal_5 = _co.showClearButton; var currVal_6 = _co.grid_columns; var currVal_7 = 350; var currVal_8 = 300; var currVal_9 = _co.disabled; var currVal_10 = _co.value; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_ConsumerDropDownGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-consumer-drop-down-grid", [], null, null, null, View_ConsumerDropDownGridComponent_0, RenderType_ConsumerDropDownGridComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.ConsumerDropDownGridComponent]), i1.ɵprd(512, null, i7.HelperService, i7.HelperService, [i8.ConsumerApi, i9.ConsumerViewApi, i10.ConfigService, i11.PusherService, i12.HttpClient, i13.CommonService, i14.UiService]), i1.ɵdid(3, 114688, null, 0, i6.ConsumerDropDownGridComponent, [i15.DataSourceService, i10.ConfigService, i7.HelperService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ConsumerDropDownGridComponentNgFactory = i1.ɵccf("app-consumer-drop-down-grid", i6.ConsumerDropDownGridComponent, View_ConsumerDropDownGridComponent_Host_0, { dataSource: "dataSource", remoteOperations: "remoteOperations", grid_columns: "grid_columns", disabled: "disabled", invalid: "invalid", value: "value", filter: "filter", showClearButton: "showClearButton" }, { valueChange: "valueChange", valueChanged: "valueChanged", focusOut: "focusOut", grid_cellPrepared: "grid_cellPrepared" }, ["*"]);
export { ConsumerDropDownGridComponentNgFactory as ConsumerDropDownGridComponentNgFactory };
