/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-notify-coord.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/devextreme-angular/ui/text-box/devextreme-angular-ui-text-box.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "devextreme-angular/ui/text-box";
import * as i6 from "devextreme-angular/core";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../../../node_modules/devextreme-angular/ui/text-area/devextreme-angular-ui-text-area.ngfactory";
import * as i9 from "devextreme-angular/ui/text-area";
import * as i10 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./dlg-notify-coord.component";
import * as i15 from "../../../../shared/sdk/services/custom/logger.service";
var styles_DlgNotifyCoordComponent = [i0.styles];
var RenderType_DlgNotifyCoordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgNotifyCoordComponent, data: {} });
export { RenderType_DlgNotifyCoordComponent as RenderType_DlgNotifyCoordComponent };
export function View_DlgNotifyCoordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, [" Notify Email\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 20, "div", [["class", "dx-fieldset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subject"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "dx-text-box", [], null, [[null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.subject = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_DxTextBoxComponent_0, i3.RenderType_DxTextBoxComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DxTextBoxComponent]), i1.ɵprd(512, null, i6.DxTemplateHost, i6.DxTemplateHost, []), i1.ɵprd(512, null, i6.WatcherHelper, i6.WatcherHelper, []), i1.ɵprd(512, null, i6.IterableDifferHelper, i6.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i6.NestedOptionHost, i6.NestedOptionHost, []), i1.ɵdid(14, 7323648, null, 1, i5.DxTextBoxComponent, [i1.ElementRef, i1.NgZone, i6.DxTemplateHost, i6.WatcherHelper, i6.IterableDifferHelper, i6.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], { value: [0, "value"] }, { valueChange: "valueChange", onBlur: "onBlur" }), i1.ɵqud(603979776, 1, { buttonsChildren: 1 }), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Body"])), (_l()(), i1.ɵeld(19, 0, null, null, 6, "dx-text-area", [], null, [[null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 25).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.body = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_DxTextAreaComponent_0, i8.RenderType_DxTextAreaComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.DxTextAreaComponent]), i1.ɵprd(512, null, i6.DxTemplateHost, i6.DxTemplateHost, []), i1.ɵprd(512, null, i6.WatcherHelper, i6.WatcherHelper, []), i1.ɵprd(512, null, i6.IterableDifferHelper, i6.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i6.NestedOptionHost, i6.NestedOptionHost, []), i1.ɵdid(25, 7323648, null, 0, i9.DxTextAreaComponent, [i1.ElementRef, i1.NgZone, i6.DxTemplateHost, i6.WatcherHelper, i6.IterableDifferHelper, i6.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], { height: [0, "height"], value: [1, "value"], valueChangeEvent: [2, "valueChangeEvent"] }, { valueChange: "valueChange", onBlur: "onBlur" }), (_l()(), i1.ɵeld(26, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(27, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).dialogRef.close(i1.ɵnov(_v, 30).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(29, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(30, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(33, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Submit"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.subject; _ck(_v, 14, 0, currVal_1); var currVal_2 = 200; var currVal_3 = _co.body; var currVal_4 = "keyup"; _ck(_v, 25, 0, currVal_2, currVal_3, currVal_4); var currVal_9 = false; _ck(_v, 30, 0, currVal_9); var currVal_12 = "primary"; _ck(_v, 33, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_5 = (i1.ɵnov(_v, 29).disabled || null); var currVal_6 = (i1.ɵnov(_v, 29)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 30).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 30).type; _ck(_v, 28, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = (i1.ɵnov(_v, 33).disabled || null); var currVal_11 = (i1.ɵnov(_v, 33)._animationMode === "NoopAnimations"); _ck(_v, 32, 0, currVal_10, currVal_11); }); }
export function View_DlgNotifyCoordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-notify-coord", [], null, null, null, View_DlgNotifyCoordComponent_0, RenderType_DlgNotifyCoordComponent)), i1.ɵdid(1, 245760, null, 0, i14.DlgNotifyCoordComponent, [i15.LoggerService, i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgNotifyCoordComponentNgFactory = i1.ɵccf("app-dlg-notify-coord", i14.DlgNotifyCoordComponent, View_DlgNotifyCoordComponent_Host_0, {}, {}, []);
export { DlgNotifyCoordComponentNgFactory as DlgNotifyCoordComponentNgFactory };
