<h2 mat-dialog-title>Image will be assigned to client default signature</h2>

<mat-dialog-content>
<!--  <mat-card>-->
<!--    <mat-checkbox [(ngModel)]='value.toAllSignatures'>Assign to All Client Signatures</mat-checkbox>-->
<!--  </mat-card>-->
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button [mat-dialog-close]='value' color='primary' mat-button>Assign</button>
</mat-dialog-actions>
