<app-drop-down-grid-new
  [placeholder]="'Select Employee...'"
  [grid_columns]="grid_columns"
  [showClearButton]="showClearButton"
  [disabled]="disabled"
  [width]="width"
  [dataSource]="dataSource"
  [remoteOperations]="remoteOperations"
  [valueExpr]="'id'"
  [displayExpr]="helper.displayExpr"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (valueChanged)="valueChanged.emit($event)"
  (focusout)="focusOut.emit($event)"
  (grid_cellPrepared)="grid_cellPrepared.emit($event)"
  [popup_width]="350"
  [popup_height]="300"
></app-drop-down-grid-new>
