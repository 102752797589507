import { Component, OnInit, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { from, Observable, of } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { select } from '@ngrx/store';
import { getUser } from '../../../../store/reducers/sign';
import { catchError, filter, finalize, first, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  Consumer,
  ConsumerApi,
  ConsumerView,
  Facility,
  LoggerService,
  MyUserApi,
  MyUtilsApi,
} from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as ConsumerHelperService, HelperService } from '../../services/helper.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { MatDialog } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { oc } from 'ts-optchain';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import identity from 'lodash-es/identity';

@Component({
  selector: 'app-lgtc-clients',
  templateUrl: './lgtc-clients.component.html',
  styleUrls: ['./lgtc-clients.component.scss'],
})
export class LgtcClientsComponent extends ABaseComponent implements OnInit {
  isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso: any;
  consumerDso: any;

  broker = 'LGTC';

  consumersHeaderFilter: any;

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '077cb29a-cd71-4a78-9d70-e7aef9fc8fc0',
    };

    this.isSU$ = this.config.isSU$;

    this.consumersHeaderFilter = [
      {
        text: 'Not Assigned',
        value: ['_consumerIds', '$size', 0],
      },
      {
        text: 'Assigned',
        value: ['_consumerIds', '$size', 1],
      },
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso = this.buildFacilityDataSource();
    this.consumerDso = this.buildConsumerDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'updateClients',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'export',
        text: 'Update Clients Data',
        hint: 'Update all clients data from broker',
        onClick: () => {
          const bClients: any[] = this.grid.instance.getSelectedRowsData();

          this.grid.instance.beginCustomLoading('...');
          from(bClients)
            .pipe(
              mergeMap(bc => this.dss.getApi<ConsumerApi>(Consumer).cloneFromBroker(this.broker, bc._id, true)),
              catchError(err => of(notify(err.message, 'error'))),
              finalize(() => this.grid.instance.endCustomLoading()),
              takeUntil(this.$onDestroy$),
            )
            .subscribe();
        },
      },
    });
  }

  noOneAssigned(e) {
    return e.row.data._consumerIds.length === 0;
  }

  someOneAssigned(e) {
    return e.row.data._consumerIds.length !== 0;
  }

  createClientClick = e => {
    this.grid.instance.beginCustomLoading('...');
    this.dss
      .getApi<ConsumerApi>(Consumer)
      .cloneFromBroker(this.broker, e.row.key, false)
      .pipe(
        first(),
        tap(client => {
          // e.row.data._consumerIds = [client.id];
          this.grid.instance.cellValue(e.row.rowIndex, '_consumerIds', [client.id]);
        }),
        switchMap(client =>
          this.dss.getApi<ConsumerApi>(Consumer).assignSystemClientsToBrokerOne(this.broker, e.row.key, [client.id]),
        ),
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  };

  updateClientClick = e => {
    this.grid.instance.beginCustomLoading('...');
    this.dss
      .getApi<ConsumerApi>(Consumer)
      .cloneFromBroker(this.broker, e.row.key, true)
      .pipe(
        first(),
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  };

  unAssignClientClick = e => {
    from(confirm('Are you sure?', 'Unassign client'))
      .pipe(
        first(),
        filter(identity),
        tap(() => this.grid.instance.beginCustomLoading('...')),
        switchMap(() =>
          this.dss.getApi<ConsumerApi>(Consumer).assignSystemClientsToBrokerOne(this.broker, e.row.key, []),
        ),
        catchError(err => of(notify(err.message, 'error'))),
        tap(client => {
          // e.row.data._consumerIds = [];
          this.grid.instance.cellValue(e.row.rowIndex, '_consumerIds', []);
        }),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  };

  private buildDataSource() {
    const self = this;
    const store = new CustomStore({
      key: '_id',
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = this.broker + '_ExportsConsumersCache';
        const aggregate = [
          // {
          //   $addFields: {_ctime: {$toDate: '$_id'}},
          // }
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate)
          .pipe(
            switchMap(async (docs: any[]) => {
              return docs;
            }),
          )
          .toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = this.broker + '_ExportsConsumersCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      update: async (key: any | string | number, values: any): Promise<any> => {
        console.log(key, values);

        await this.dss
          .getApi<ConsumerApi>(Consumer)
          .assignSystemClientsToBrokerOne(this.broker, key, oc(values)._consumerIds([]))
          .toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = this.broker + '_ExportsConsumersCache';
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      filter: ['_broker', '=', this.broker],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    };
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    return {
      store,
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, ConsumerView, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  clientDropDown_onValueChanged(cellInfo, e) {
    cellInfo.setValue([e.value]);
  }
}
