/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clone-log-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../clone-log-grid/clone-log-grid.component.ngfactory";
import * as i3 from "../clone-log-grid/clone-log-grid.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../shared/modules/my-common/services/common.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i9 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i10 from "../../../../shared/modules/ui/services/ui.service";
import * as i11 from "./clone-log-list.component";
var styles_CloneLogListComponent = [i0.styles];
var RenderType_CloneLogListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloneLogListComponent, data: {} });
export { RenderType_CloneLogListComponent as RenderType_CloneLogListComponent };
export function View_CloneLogListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-clone-log-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CloneLogGridComponent_0, i2.RenderType_CloneLogGridComponent)), i1.ɵdid(2, 4440064, [[1, 4]], 0, i3.CloneLogGridComponent, [i4.LoggerService, i5.ConfigService, i6.CommonService, i7.MatDialog, i8.DataSourceService, i9.GridHelperService, i10.UiService], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_CloneLogListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clone-log-list", [], null, null, null, View_CloneLogListComponent_0, RenderType_CloneLogListComponent)), i1.ɵdid(1, 114688, null, 0, i11.CloneLogListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CloneLogListComponentNgFactory = i1.ɵccf("app-clone-log-list", i11.CloneLogListComponent, View_CloneLogListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { CloneLogListComponentNgFactory as CloneLogListComponentNgFactory };
