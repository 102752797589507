<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Last Mechanical Check</div>
        <div class="dx-field-value-static">
          {{ model?.lastMechanicalCheck }}
        </div>
      </div>
    </div>
  </ng-container>
</app-details-tab>
