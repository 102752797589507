import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { isEmpty, isNumber } from 'lodash-es';
import uniq from 'lodash-es/uniq';
import moment from 'moment';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ALoadOptionsConverter } from '../../../../../shared/classes/loopback-custom-store/generic/load-options-converters/ALoadOptionsConverter';
import { LoopBackStoreOptions } from '../../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { hasAmbTrips, headersAllTenantsAppend } from '../../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../../shared/modules/ui/components/abstract/a-base.component';
import { DlgSelectSignatureComponent } from '../../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerApi,
  ConsumerView,
  ConsumerViewApi,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  MyUtilsApi,
  Signature,
  SignatureApi,
  SignatureConsUniqImgView,
  Vehicle,
  VehicleApi,
} from '../../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../../employee/services/helper.service';
import { SERVICE_TYPE } from '../../../../trip-manifest/classes/enums';
import { DlgSelectDriverComponent } from '../../../../trip-manifest/dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgSelectVehicleComponent } from '../../../../trip-manifest/dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
import { DlgEditServiceStatusComponent } from '../../../dialogs/dlg-cancel-service/dlg-edit-service-status.component';
import { DlgChangeAdcWithClientComponent } from '../../../dialogs/dlg-change-adc-w-client/dlg-change-adc-w-client.component';
import { DlgChangeAdcComponent } from '../../../dialogs/dlg-change-adc/dlg-change-adc.component';
import { DlgEditServiceTypeComponent } from '../../../dialogs/dlg-edit-service-type/dlg-edit-service-type.component';
import { DlgEditTimesComponent } from '../../../dialogs/dlg-edit-times/dlg-edit-times.component';

/**
 * @deprecated
 */
@Component({
  selector: 'app-billing-common',
  templateUrl: './billing-common.component.html',
  styleUrls: ['./billing-common.component.scss'],
})
export class BillingCommonComponent extends ABaseComponent implements OnInit {
  $items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  dso$ = of(new DataSource([]));

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  $cmpAddresses$: BehaviorSubject<(number | { key: number; trip: any })[]> = new BehaviorSubject([]);

  facilityDso$: Observable<DataSourceOptions> = of([]);
  consumerDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  facilitySubDso$: Observable<DataSourceOptions> = of([]);
  consumerSubDso$: Observable<DataSourceOptions> = of([]);
  employeeSubDso$: Observable<DataSourceOptions> = of([]);
  vehicleSubDso$: Observable<DataSourceOptions> = of([]);

  tripDsoMap: Map<number, any> = new Map();
  serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
  validationStates = [
    { v: 'PENDING', t: 'Not Validated' },
    { v: 'VALID', t: 'Valid' },
    { v: 'INVALID', t: 'Invalid' },
    { v: 'ISSUES', t: 'Issues' },

    { v: 'FULL_AUTO_VALIDATED', t: 'Full Auto Validated' },
    { v: 'PARTIAL_AUTO_VALIDATED', t: 'Partial Auto Validated' },
  ];

  ClaimStatuses = [
    'Billed',
    'Authorized',
    'Approved',
    'Processing',
    'Pending',
    'Transmitted',
    'Review',
    'Incomplete',
    'Paid',
    'Denied',
  ];

  grid_stateStoring: any;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  facilityId?: number;
  driverId?: number;
  clientId?: number;

  validationState?: string;
  mci?: string;
  broker?: string;
  serviceType?: string;
  claimStatus?: string;
  mco?: string;
  hasUnusedTrips?: boolean;
  hasTripAssigned?: boolean;
  existTripIds?: boolean;
  hasClaim?: boolean;

  mealPhotoPopover = {};
  addrMismatchPopover = {};
  // billingStatus?: string;
  // claimState?: string;
  // clientName?: string;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  // @ViewChild('from', {static: true}) fromDateBox: DxDateBoxComponent;
  // @ViewChild('to', {static: true}) toDateBox: DxDateBoxComponent;

  private vehicleDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  private driverDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  constructor(
    protected logger: LoggerService,
    private router: Router,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    private signatureApi: SignatureApi,
    private facilityApi: FacilityApi,
    private utilsApi: MyUtilsApi,
    private consumerApi: ConsumerApi,
    private vehicleApi: VehicleApi,
    private employeeApi: EmployeeApi,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = this.sss.buildOptions(
      'cada515d-e86a-485c-853a-71f43dfd36c6',
      1000,
      'local',
      this.sss.resetState,
    );

    this.dso$ = this.$items$.pipe(
      map(items => {
        const aso: ArrayStoreOptions = {
          key: Signature.getModelDefinition().idName,
          data: items,
        } as ArrayStoreOptions;

        // console.log(recs.length);

        return new DataSource(new ArrayStore(aso));
      }),
      tap(() => {
        if (this.$items$.getValue()) {
          this.facilitySubDso$ = this.buildFacilitySubDataSource();
          this.consumerSubDso$ = this.buildConsumerSubDataSource();
          this.employeeSubDso$ = this.buildEmployeeSubDataSource();
          this.vehicleSubDso$ = this.buildVehicleSubDataSource();
        }
      }),
    );

    if (this.$items$.getValue()) {
      this.facilityDso$ = this.buildFacilityDataSource();
      this.consumerDso$ = this.buildConsumerDataSource();
      this.employeeDso$ = this.buildEmployeeDataSource();
      this.vehicleDso$ = this.buildVehicleDataSource();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          this.grid.instance.clearFilter();
          this.grid.instance.clearSelection();
          // await this.grid.instance.deselectAll();

          const curState = { ...this.grid.instance.state(), ...this.sss.resetState };
          // console.log('curState', curState);
          this.grid.instance.state(curState);

          this.$items$.next([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
          ),
        ),
        tap(items => {
          this.$items$.next(items);
          // this.grid.instance.refresh();
        }),
        tap(async () => {
          // this.grid.instance.clearFilter();
          // this.grid.instance.clearSelection();
          // await this.grid.instance.deselectAll();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$cmpAddresses$
      .pipe(
        mergeMap(keyOrObj => keyOrObj),
        tap(keyOrObj => {
          this.grid.instance
            .getDataSource()
            .store()
            .push([
              { type: 'update', data: { _addrMismatch: '...' }, key: isNumber(keyOrObj) ? keyOrObj : keyOrObj.key },
            ]);
        }),
        // tap(keyOrObj => console.log(keyOrObj)),
        mergeMap(keyOrObj => {
          const key = isNumber(keyOrObj) ? keyOrObj : keyOrObj.key;
          const trip = isNumber(keyOrObj) ? undefined : keyOrObj.trip;

          // console.log(trip);

          const signApi = this.dss.getApi<SignatureApi>(Signature);
          return signApi.getManifestAndTripAddresses(key, oc(trip)._tripId(), headersAllTenantsAppend).pipe(
            tap(res => {
              // console.log(res);

              this.grid.instance
                .getDataSource()
                .store()
                .push([{ type: 'update', data: { _addresses: res }, key }]);

              const mismatch = {
                to: res.service.to !== res.trip.to,
                from: res.service.from !== res.trip.from,
                both: res.service.to !== res.trip.to && res.service.from !== res.trip.from,
                swapped: res.service.to === res.trip.from || res.service.from === res.trip.to,
              };

              if (res.service)
                this.grid.instance
                  .getDataSource()
                  .store()
                  .push([
                    {
                      type: 'update',
                      data: {
                        _addrMismatch: mismatch.both
                          ? mismatch.swapped
                            ? 'swapped'
                            : 'both'
                          : mismatch.to
                            ? 'dest'
                            : mismatch.from
                              ? 'origin'
                              : 'valid',
                      },
                      key,
                    },
                  ]);

              // this.grid.instance.repaint();
            }),
            catchError(err => of(console.error(err))),
          );
        }),

        catchError(err => of(console.error(err))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      map(() => {
        const fromIncl = this.selectedFromValue;
        const toExcl = this.selectedToValue;

        const fromMoment = fromIncl && moment(fromIncl);
        const toMoment = toExcl && moment(toExcl).add(1, 'days');

        if (!fromMoment || !toMoment) {
          throw new Error('Period should be defined');
        }

        if (toMoment.diff(fromMoment, 'months') > 1) {
          throw new Error('Period should be less or equal to 1 month');
        }
        const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromIncl: strFrom,
          toExcl: strTo,

          serviceType: this.serviceType,
          mco: this.mco,
          broker: this.broker,
          hasUnusedTrips: this.hasUnusedTrips,
          existTripIds: this.existTripIds,
          hasTrip: this.hasTripAssigned,
          hasClaim: this.hasClaim,
          claimStatus: this.claimStatus,
          mci: this.mci,
          validationState: this.validationState,

          facilityIds: this.facilityId ? [this.facilityId] : [],
          driverIds: this.driverId ? [this.driverId] : [],
          clientIds: this.clientId ? [this.clientId] : [],
        };
      }),

      switchMap(fltr => this.pusher.rpc('GET_CLAIMS', { ...fltr, useRunService: true }, true, headersAllTenantsAppend)),

      switchMap(url =>
        this.http.get(url, {
          responseType: 'json',
          withCredentials: false,
        }),
      ),

      // map(coll => this.dss.createMongoStore(coll)),
      // switchMap(store => store.load()),

      map((recs: any[]) => {
        this.tripDsoMap.clear();

        (recs || []).forEach(r => {
          this.tripDsoMap.set(r.id, r._trips);

          r.getClaimStatus = function () {
            const _self = this;
            return oc(_self)._claim.STATUS() || oc(_self)._claim['Reimbursement Status']();
          }.bind(r);

          r.getLastName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's Last Name"]() ||
              oc(_self)._trip['Member Last Name']() ||
              oc(_self)._trip._lastname() ||
              oc(_self)._client.lastname()
            );
          }.bind(r);

          r.getFirstName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's First Name"]() ||
              oc(_self)._trip['Member First Name']() ||
              oc(_self)._trip._firstname() ||
              oc(_self)._client.firstname()
            );
          }.bind(r);

          r.getMci = function () {
            const _self = this;
            return oc(_self)._trip._mci() || oc(_self)._client.mci();
          }.bind(r);

          r.getBatchCount = function () {
            const _self = this;
            return oc(_self)._batchIds([]).length;
          }.bind(r);

          r.getBatchStatus = function () {
            const _self = this;
            return oc(_self)._currBatchStatus() || oc(_self)._lastBatchStatus();
          }.bind(r);

          r.getServiceType = function () {
            const _self = this;
            return oc(_self).meta.serviceType(SERVICE_TYPE.PARATRANSIT);
          }.bind(r);

          r.getMco = function () {
            const _self = this;
            return oc(_self)._client.mco();
          }.bind(r);

          r.getBroker = function () {
            const _self = this;
            return oc(_self)._client.broker();
          }.bind(r);

          r.getOrigin = function () {
            const _self = this;
            return oc(_self)._rec.o();
          }.bind(r);

          r.getDestination = function () {
            const _self = this;
            return oc(_self)._rec.d();
          }.bind(r);

          {
            const arrTime = oc(r).arrivedTime() ? moment(oc(r).arrivedTime(), 'HH:mm:ss') : null;
            const puTime = oc(r).pickupTime() ? moment(oc(r).pickupTime(), 'HH:mm:ss') : null;
            const doTime = oc(r).dropoffTime() ? moment(oc(r).dropoffTime(), 'HH:mm:ss') : null;

            const lgtcPu = oc(r)._trip.pu_time() ? moment(oc(r)._trip.pu_time(), 'HH:mm') : null;

            r._puDoWarnings = {
              warn1: puTime && doTime && doTime.diff(puTime, 'minutes', true) < 5,
              warn2: puTime && doTime && doTime.diff(puTime, 'minutes', true) > 120,
              warn3: puTime && lgtcPu && Math.abs(lgtcPu.diff(puTime, 'minutes', true)) > 60,
            };
            r._puDoWarnings.title = Object.entries(r._puDoWarnings)
              .filter(([k, v]) => !!v)
              .map(([k, v]) => {
                return {
                  warn1: 'DO and PU diff less than 5 min. ',
                  warn2: 'DO and PU diff more than 65 min. ',
                  warn3: `Broker PU (${oc(r)._trip.pu_time()}) and Driver PU diff more than 60 min. `,
                }[k];
              })
              .join('\n');
          }
        });

        // recs = recs.filter((r) => !this.mco || oc(r)._client.mco() === this.mco);
        return recs;
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  getTripDso(e) {
    // console.log(e);
    return [];
  }

  tripId_onSelectionChanged(cellInfo, e) {
    // console.log(cellInfo, e.selectedItem);

    const ds = this.grid.instance.getDataSource();
    const as = ds.store() as ArrayStore;
    as.push([{ type: 'update', data: { _trip: e.selectedItem }, key: cellInfo.key }]);

    this.$cmpAddresses$.next([{ key: cellInfo.key, trip: e.selectedItem }]);
  }

  tripId_onValueChanged(cellInfo, e) {
    // console.log('tripId_onValueChanged', e);
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildFacilitySubDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [
        ALoadOptionsConverter.inq('id', uniq((this.$items$.getValue() || []).map(r => r.tenantId))),
        // ['id', 'inq', uniq(this.$items$.getValue().map((r) => r.tenantId))],
      ],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerSubDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.consumerId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeSubDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.employeeId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleSubDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.vehicleId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  vehicleData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.vehicleDsMap[id]) {
      self.vehicleDsMap[id] = {};
    }

    if (!self.vehicleDsMap[id].fullInstance) {
      const inst$ = self.vehicleApi.findById(id, {}, headersAllTenantsAppend);

      self.vehicleDsMap[id].fullInstance = inst$;
    }

    return self.vehicleDsMap[id].fullInstance;
  }

  driverData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.driverDsMap[id]) {
      self.driverDsMap[id] = {};
    }

    if (!self.driverDsMap[id].fullInstance) {
      const inst$ = self.employeeApi.findById(
        id,
        {
          include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
        },
        headersAllTenantsAppend,
      );

      self.driverDsMap[id].fullInstance = inst$;
    }

    return self.driverDsMap[id].fullInstance;
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);

    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        // disabled: this.$showFromBroker$.value,
        name: 'changeAdc',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          // icon: 'fas fa-folder-plus',
          text: 'Change ADC',
          hint: 'Change ADC for selected',
          onClick: this.grid_toolbar_changeAdc_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'assignTripIds',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          // icon: 'fas fa-folder-plus',
          text: 'Assign TripIDs to Trips',
          hint: 'Assign TripIDs to Trips',
          onClick: this.grid_toolbar_assignTripIds_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildBatch',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-folder-plus',
          text: 'Build Paratransit Batch',
          hint: 'Build Paratransit Claims Batch',
          onClick: this.grid_toolbar_buildBatch_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildClaims',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-folder-plus',
          text: 'Build Billed Claims',
          hint: 'Build Billed Claims (Create fake billed claims)',
          onClick: this.grid_toolbar_buildBilledClaims_onClick.bind(this),
        },
      },
      // {
      //   // disabled: this.$showFromBroker$.value,
      //   name: 'buildBatch',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     icon: 'fas fa-folder-plus',
      //     text: 'Build Meals Batch',
      //     hint: 'Build Meals Claims Batch',
      //     onClick: this.grid_toolbar_buildMealsBatch_onClick.bind(this),
      //   }
      // },
      // {
      //   // disabled: this.$showFromBroker$.value,
      //   name: 'buildMealsReq',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     icon: 'fas fa-build',
      //     text: 'Meals Trips Req',
      //     hint: 'Build Meals Trips Request Files',
      //     onClick: this.grid_toolbar_buildMealsReq_onClick.bind(this),
      //   }
      // },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildCtsOrderReq',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-build',
          text: 'Build CTS Report',
          hint: 'Build CTS Export Report',
          onClick: this.grid_toolbar_buildCtsReport_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildMtmAuthsToAddReq',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-build',
          text: 'Build MTM Auths Request',
          hint: 'Build MTM Auths Request',
          onClick: this.grid_toolbar_buildMtmAuthsToAdd_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildSchoolReport',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-file-signature',
          text: 'Build School Report',
          hint: 'Build School Report PDF',
          onClick: this.grid_toolbar_buildSchoolReport_onClick.bind(this),
        },
      },
    );
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      // this.logger.log(e);
      // const eId = get(e.row.data, HelperService.REC_FIELD_MAP.employeeId);
      // const cId = get(e.row.data, HelperService.REC_FIELD_MAP.consumerId);

      e.items = [
        {
          text: 'Change Signature Image',
          onItemClick: () => {
            void this.dialog
              .open<
                any,
                any,
                {
                  data: SignatureConsUniqImgView;
                  toAllSignatures?: boolean;
                  toClient?: boolean;
                }
              >(DlgSelectSignatureComponent, {
                hasBackdrop: true,
                data: {
                  signature: e.row.data,
                },
              })
              .afterClosed()
              .pipe(
                switchMap(v =>
                  iif(
                    () => !!oc(v).data(),
                    of(v).pipe(
                      tap(() => this.ui.showLoading()),

                      switchMap(res => {
                        // if (this.data.signature) {
                        return this.dss
                          .getApi<SignatureApi>(Signature)
                          .patchAttributes(e.row.data.id, { imgFileId: res.data.imgFileId }, headersAllTenantsAppend)
                          .pipe(
                            tap(() => {
                              e.row.data.imgFileId = res.data.imgFileId;
                              this.grid.instance.repaint();
                            }),
                          );
                        // } else if (this.data.consumer) {
                        //   return this.consApi.patchAttributes(
                        //     this.data.consumer.id,
                        //     {signImgFileId: res.data.imgFileId},
                        //     headersAllTenantsAppend,
                        //   );
                        // }
                      }),
                      catchError(err => of(notify(err.message, 'error', 5000))),

                      tap(() => this.ui.hideLoading()),
                    ),
                  ),
                ),
              )
              .toPromise();
          },
        },
        {
          text: 'Change Arr/PU/DO Time',
          onItemClick: () => {
            void this.dialog
              .open(DlgEditTimesComponent, {
                hasBackdrop: true,
                data: {
                  signature: e.row.data,
                },
              })
              .afterClosed()
              .pipe(
                switchMap(v =>
                  iif(
                    () => !!v,
                    of(v).pipe(
                      tap(() => this.ui.showLoading()),
                      map(res => ({
                        scheduledTime: res.scheduledTime ? moment(res.scheduledTime).format('HH:mm:ss') : null,
                        arrivedTime: res.arrivedTime ? moment(res.arrivedTime).format('HH:mm:ss') : null,
                        pickupTime: res.pickupTime ? moment(res.pickupTime).format('HH:mm:ss') : null,
                        dropoffTime: res.dropoffTime ? moment(res.dropoffTime).format('HH:mm:ss') : null,
                      })),
                      // tap((res) => console.log(res)),

                      switchMap(res => {
                        return this.dss
                          .getApi<SignatureApi>(Signature)
                          .patchAttributes(
                            e.row.data.id,
                            {
                              arrivedTime: res.arrivedTime,
                              pickupTime: res.pickupTime,
                              dropoffTime: res.dropoffTime,
                            },
                            headersAllTenantsAppend,
                          )
                          .pipe(
                            tap(() => {
                              e.row.data.arrivedTime = res.arrivedTime;
                              e.row.data.pickupTime = res.pickupTime;
                              e.row.data.dropoffTime = res.dropoffTime;
                              this.grid.instance.repaint();
                            }),
                          );
                      }),
                      catchError(err => of(notify(err.message, 'error', 5000))),

                      tap(() => this.ui.hideLoading()),
                    ),
                  ),
                ),
              )
              .toPromise();
          },
        },
        {
          text: 'Change Vehicle',
          onItemClick: () => {
            void this.dialog
              .open(DlgSelectVehicleComponent, {
                width: '450px',
                maxHeight: '650px',
                hasBackdrop: true,
                data: {
                  recIds: [(e.row.data as Signature).vehicleId],
                  filter: {},
                },
              })
              .afterClosed()
              .pipe(
                filter(keys => keys !== false && keys && keys.length === 1),
                tap(() => this.ui.showLoading()),
                map(keys => keys[0]),
                switchMap(vId => {
                  return this.dss
                    .getApi<SignatureApi>(Signature)
                    .patchAttributes(e.row.data.id, { vehicleId: vId }, headersAllTenantsAppend)
                    .pipe(
                      tap(() => {
                        e.row.data.vehicleId = vId;
                        const items = this.$items$.getValue();
                        this.$items$.next(items);
                        this.grid.instance.refresh();
                      }),
                    );
                }),
                catchError(err => of(notify(err.message, 'error', 5000))),

                tap(() => this.ui.hideLoading()),
              )
              .toPromise();
          },
        },
        {
          text: 'Change Driver',
          onItemClick: () => {
            void this.dialog
              .open(DlgSelectDriverComponent, {
                width: '450px',
                maxHeight: '650px',
                hasBackdrop: true,
                data: {
                  recIds: [(e.row.data as Signature).employeeId],
                  filter: {
                    or: [
                      { tenantId: e.row.data.tenantId },
                      { tenantIds: { $json_e_c: { $: JSON.stringify(e.row.data.tenantId) } } },
                    ],
                  },
                  skipTenantCheck: true,
                },
              })
              .afterClosed()
              .pipe(
                filter(keys => keys !== false && keys && keys.length === 1),
                tap(() => this.ui.showLoading()),
                map(keys => keys[0]),
                switchMap(eId => {
                  return this.dss
                    .getApi<SignatureApi>(Signature)
                    .patchAttributes(
                      e.row.data.id,
                      {
                        employeeId: eId,
                      },
                      headersAllTenantsAppend,
                    )
                    .pipe(
                      tap(() => {
                        e.row.data.employeeId = eId;
                        const items = this.$items$.getValue();
                        this.$items$.next(items);
                        this.grid.instance.refresh();
                      }),
                    );
                }),
                catchError(err => of(notify(err.message, 'error', 5000))),

                tap(() => this.ui.hideLoading()),
              )
              .toPromise();
          },
        },
        {
          text: 'Change ADC',
          onItemClick: () => {
            void this.dialog
              .open(DlgChangeAdcWithClientComponent, {
                width: '750px',
                maxHeight: '650px',
                hasBackdrop: true,
                data: {
                  sv: e.row.data,
                },
              })
              .afterClosed()
              .pipe(
                filter(pair => pair && pair.length && pair[0] && pair[1]),
                tap(() => this.ui.showLoading()),
                switchMap(([f, cv]: [Facility, ConsumerView]) => {
                  return this.dss
                    .getApi<SignatureApi>(Signature)
                    .patchAttributes(
                      e.row.data.id,
                      {
                        tenantId: f.id,
                        consumerId: cv.id,
                      },
                      headersAllTenantsAppend,
                    )
                    .pipe(
                      tap(() => {
                        e.row.data.tenantId = f.id;
                        e.row.data.consumerId = cv.id;
                        const items = this.$items$.getValue();
                        this.$items$.next(items);
                        this.grid.instance.refresh();
                      }),
                    );
                }),
                catchError(err => of(notify(err.message, 'error', 5000))),
                tap(() => this.ui.hideLoading()),
              )
              .toPromise();
          },
        },
        {
          text: 'Change Service Type',
          onItemClick: () => {
            void this.dialog
              .open(DlgEditServiceTypeComponent, {
                hasBackdrop: true,
                data: {
                  signature: e.row.data,
                },
              })
              .afterClosed()
              .pipe(
                switchMap(v =>
                  iif(
                    () => !!v,
                    of(v).pipe(
                      tap(() => this.ui.showLoading()),
                      // tap((st) => console.log(st)),

                      switchMap(st => {
                        return this.dss
                          .getApi<SignatureApi>(Signature)
                          .updateServiceType(e.row.data.id, st, headersAllTenantsAppend)
                          .pipe(
                            tap(inst => {
                              e.row.data.marker = inst.marker;
                              e.row.data.meta.serviceType = inst.meta.serviceType;
                              e.row.data.vServiceType = inst.vServiceType;
                              this.grid.instance.repaint();
                            }),
                          );
                      }),
                      catchError(err => of(notify(err.message, 'error', 5000))),
                      tap(() => this.ui.hideLoading()),
                    ),
                  ),
                ),
              )
              .toPromise();
          },
        },
        {
          text: 'Edit Service Record Status',
          onItemClick: () => {
            void this.dialog
              .open(DlgEditServiceStatusComponent, {
                width: '400px',
                // maxHeight: '650px',
                hasBackdrop: true,
                data: e.row.data as Signature,
              })
              .afterClosed()
              .pipe(
                switchMap(v =>
                  iif(
                    () => !!v,
                    of(v).pipe(
                      tap(() => this.ui.showLoading()),
                      // tap((st) => console.log(st)),

                      switchMap(({ status, note }) => {
                        return this.dss
                          .getApi<SignatureApi>(Signature)
                          .patchAttributes(
                            e.row.data.id,
                            {
                              status,
                              note,
                            },
                            headersAllTenantsAppend,
                          )
                          .pipe(
                            tap(() => {
                              e.row.data.status = status;
                              e.row.data.note = note;
                              e.row.data._cancelled = ((status as string) || '').startsWith('X');
                              const items = this.$items$.getValue();
                              this.$items$.next(items);
                              this.grid.instance.refresh();
                            }),
                          );
                      }),

                      catchError(err => of(notify(err.message, 'error', 5000))),
                      tap(() => this.ui.hideLoading()),
                    ),
                  ),
                ),
              )
              .toPromise();
          },
        },
      ];
    }
  }

  grid_onCellPrepared(e) {
    // console.log(e);
    if (e.rowType === 'data' && e.column.dataField === '_addrMismatch') {
      if (oc(e).data._addrMismatch('') === '...') {
        (e.cellElement as HTMLElement).classList.add('cell-blue');
      } else if (oc(e).data._addrMismatch('') === 'valid') {
        (e.cellElement as HTMLElement).classList.add('cell-green');
      } else if (!isEmpty(oc(e).data._addrMismatch(''))) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      } else {
        (e.cellElement as HTMLElement).classList.remove('cell-blue', 'cell-trip-warning');
      }
    }

    if (e.rowType === 'data' && e.column.dataField === '_trip._tripId') {
      // const items = this.grid.instance.getDataSource().items();
      // const count = items.filter((i) => oc(i)._trip._tripId() === oc(e).data._trip._tripId()).length;
      // if (count > 1) (e.cellElement as HTMLElement).classList.add('cell-trip-warning');

      if (!oc(e).data.vTripId()) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      }
    }
  }

  grid_onSaved(e) {
    // this.grid.instance.repaint();
  }

  grid_onSelectionChanged(e) {
    // console.log(e);
    // this.$cmpAddresses$.next(e.selectedRowKeys);

    this.$cmpAddresses$.next(
      (e.currentSelectedRowKeys as number[])
        .map(key => [key, (e.selectedRowsData as any[]).find(data => data.id === key)])
        .map(([key, data]) => ({ key, trip: oc(data)._trip() || oc(data)._possibleTrip() })),
    );
  }

  grid_toolbar_changeAdc_onClick() {
    if (this.grid.instance.getSelectedRowKeys().length === 0) return;

    void this.dialog
      .open(DlgChangeAdcComponent, {
        width: '750px',
        maxHeight: '650px',
        hasBackdrop: true,
        data: {
          keys: this.grid.instance.getSelectedRowKeys(),
        },
      })
      .afterClosed()
      .pipe(
        filter((f: Facility) => !!f),
        tap(() => this.ui.showLoading()),
        switchMap(async f => {
          const pairs = await Promise.all(
            this.grid.instance.getSelectedRowsData().map(async rowData => {
              const [c] = await this.dss
                .getApi<ConsumerViewApi>(ConsumerView)
                .find<ConsumerView>(
                  {
                    where: {
                      and: [
                        { tenantId: f.id },
                        {
                          or: [
                            {
                              and: [{ mci: { neq: null } }, { mci: oc(rowData)._client.mci() }],
                            },
                            {
                              and: [
                                { person_firstname: oc(rowData)._client.firstname() },
                                { person_lastname: oc(rowData)._client.lastname() },
                                { person_dob: oc(rowData)._client.dob() },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  headersAllTenantsAppend,
                )
                .toPromise();

              if (c) {
                await this.dss
                  .getApi<SignatureApi>(Signature)
                  .patchAttributes(
                    rowData.id,
                    {
                      tenantId: f.id,
                      consumerId: c.id,
                    },
                    headersAllTenantsAppend,
                  )
                  .toPromise();

                rowData.tenantId = f.id;
                rowData.consumerId = c.id;

                rowData._client = {
                  mci: c.mci,
                  firstname: c.person_firstname,
                  lastname: c.person_lastname,
                  dob: c.person_dob,
                  mco: c.activeMco,
                  broker: c.activeBroker,
                };

                return [rowData.id, true];
              } else {
                return [rowData.id, false];
              }
            }),
          );

          const items = this.$items$.getValue();
          this.$items$.next(items);
          this.grid.instance.refresh();

          this.grid.instance.selectRows(
            pairs.filter(([, replaced]) => !replaced).map(([key]) => key),
            false,
          );

          alert(
            'Selected signatures cannot be changed automatically.' +
              ' Corresponding client not found in target facility.',
          );
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }

  grid_toolbar_assignTripIds_onClick() {
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    of()
      .pipe(
        tap(() => this.ui.showLoading()),
        switchMap(async () => {
          const api = this.dss.getApi<SignatureApi>(Signature);

          for (const pair of selectedPairs) {
            const [sId, tripId] = pair;

            await api
              .findById<Signature>(sId, {}, headersAllTenantsAppend)
              .pipe(switchMap(s => api.patchAttributes(sId, { meta: { ...s.meta, tripId } }, headersAllTenantsAppend)))
              .toPromise();
          }
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_buildBatch_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    // console.log(this.grid.instance.getDataSource().sort());

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    if (selectedPairs.some(([sId, tId]) => !tId)) {
      notify('There are some services with no TripID assigned', 'error', 5000);
      return;
    }

    notify('Batch Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Batch Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();
    ds.store().push(
      selectedPairs.map(([sId]) => ({
        type: 'update',
        data: { _currBatchStatus: 'PENDING' },
        key: sId,
      })),
    );

    this.pusher
      .rpc('BUILD_CLAIMS_BATCH', {
        // signatureIds: selectedIds,
        // tripIds: selectedTripIds,
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() =>
          ds.store().push(
            selectedPairs.map(([sId]) => ({
              type: 'update',
              data: { _currBatchStatus: 'IN BATCH' },
              key: sId,
            })),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // this.ui.openEditDialog({
    //   modelId: null,
    //   ModelClass: Consumer,
    //   FormComponentClass: ConsumerFormComponent,
    // });
  }

  grid_toolbar_buildBilledClaims_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());

    const sItems = this.grid.instance.getSelectedRowsData();

    // console.log(this.grid.instance.getDataSource().sort());

    if (sItems.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    if (sItems.some(s => s.vServiceType !== SERVICE_TYPE.MEALS && isEmpty(s.vTripId))) {
      notify('There are some services with no TripID assigned', 'error', 5000);
      return;
    }

    const selectedPairs = sItems.map(s => [s.id, s.vTripId] as [number, string]);

    notify('Billed Claims Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Billed Claims Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();

    this.pusher
      .rpc('BUILD_BILLED_CLAIMS', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        switchMap(url =>
          this.http.get(url, {
            responseType: 'json',
            withCredentials: false,
          }),
        ),
        tap((claims: any[]) =>
          ds.store().push(
            claims.map(cl => ({
              type: 'update',
              data: { _claim: cl },
              key: cl._sId,
            })),
          ),
        ),
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(console.log);
  }

  grid_toolbar_buildMealsBatch_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    // console.log(this.grid.instance.getDataSource().sort());

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Meals Batch Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Meals Batch Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();
    ds.store().push(
      selectedPairs.map(([sId]) => ({ type: 'update', data: { _currBatchStatus: 'PENDING' }, key: sId })),
    );

    this.pusher
      .rpc('BUILD_CLAIMS_BATCH', {
        // signatureIds: selectedIds,
        // tripIds: selectedTripIds,
        pairs: selectedPairs,
        serviceType: 'MEALS',
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        tap(() => {
          ds.store().push(
            selectedPairs.map(([sId]) => ({ type: 'update', data: { _currBatchStatus: 'IN BATCH' }, key: sId })),
          );
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }

  grid_toolbar_buildCtsReport_onClick() {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'CTS Order Batch Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();
    ds.store().push(
      selectedIds.map(sId => ({
        type: 'update',
        data: { _currBatchStatus: 'PENDING' },
        key: sId,
      })),
    );

    this.pusher
      .rpc('BUILD_CTS_ORDER_BATCH', {
        signatureIds: selectedIds,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() =>
          ds.store().push(
            selectedIds.map(sId => ({
              type: 'update',
              data: { _currBatchStatus: 'IN BATCH' },
              key: sId,
            })),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_buildMtmAuthsToAdd_onClick() {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'MTM Auths to Add Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();
    // ds.store().push(selectedIds.map(sId => ({
    //   type: 'update',
    //   data: {_currBatchStatus: 'PENDING'},
    //   key: sId,
    // })));

    this.pusher
      .rpc('BUILD_MTM_AUTHS_TO_ADD', {
        signatureIds: selectedIds,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),

        // tap(() => ds.store().push(selectedIds.map((sId) => ({
        //   type: 'update',
        //   data: {_currBatchStatus: 'IN BATCH'},
        //   key: sId,
        // })))),

        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_buildMealsReq_onClick() {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Meals Trips Request Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_MEALS_TRIPS_REQ', {
        signatureIds: selectedIds,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap(({ urls }) => {
          (urls || []).forEach(url => window.open(url));
        }),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => {
        // console.log(res);
      });
  }

  grid_toolbar_buildSchoolReport_onClick() {
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_SCHOOL_REPORT_RPC', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap((uris: string[]) => (uris || []).forEach(uri => window.open(uri))),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }
}
