/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/devextreme-angular/ui/load-panel/devextreme-angular-ui-load-panel.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/load-panel";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./loading.component";
var styles_LoadingComponent = [i0.styles];
var RenderType_LoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingComponent, data: {} });
export { RenderType_LoadingComponent as RenderType_LoadingComponent };
export function View_LoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "dx-load-panel", [], null, null, null, i2.View_DxLoadPanelComponent_0, i2.RenderType_DxLoadPanelComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(4, 7323648, null, 0, i4.DxLoadPanelComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { closeOnOutsideClick: [0, "closeOnOutsideClick"], visible: [1, "visible"] }, null)], function (_ck, _v) { var currVal_0 = false; var currVal_1 = true; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, View_LoadingComponent_0, RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i6.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingComponentNgFactory = i1.ɵccf("app-loading", i6.LoadingComponent, View_LoadingComponent_Host_0, {}, {}, []);
export { LoadingComponentNgFactory as LoadingComponentNgFactory };
