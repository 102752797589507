import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { AcquisitionRelatedContact, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-acquisition-contacts',
  templateUrl: './acquisition-contacts.component.html',
  styleUrls: ['./acquisition-contacts.component.scss'],
})
export class AcquisitionContactsComponent extends ABaseComponent {
  dso: any;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
  ) {
    super(logger);

    this.dso = this.buildDataSource();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(AcquisitionRelatedContact, undefined, false);
    so.noSql = true;
    so.customFilter = {
      order: ['type', 'businessName'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return dso;
  }
}
