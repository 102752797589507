import { Component, Input, OnInit } from '@angular/core';
import { isString } from 'lodash-es';
import { oc } from 'ts-optchain';
import { PostShiftReport, VehicleCheckUp } from '../../../../shared/sdk/models';

@Component({
  selector: 'app-check-report-field',
  templateUrl: './check-report-field.component.html',
  styleUrls: ['./check-report-field.component.scss'],
})
export class CheckReportFieldComponent implements OnInit {
  @Input() model: VehicleCheckUp | PostShiftReport | undefined;
  @Input() meta: any | undefined;
  @Input() fullDoc: (VehicleCheckUp & { checkout: PostShiftReport }) | undefined;

  popupVisible = false;
  currentPic = '';

  propTitleMap = {
    dashboard: 'Dashboard',
  };

  get desc(): any {
    return oc(this.meta).desc();
  }

  get field(): string {
    return isString(this.meta) ? this.meta : oc(this.meta).field();
  }

  get value(): any {
    return oc(this.model)[this.field]();
  }

  get title(): string {
    return isString(this.meta) ? this.propTitleMap[this.meta] || this.meta : oc(this.desc).title();
  }

  get issues(): any[] {
    return oc(this.meta).value.issues([]);
  }

  constructor() {}

  ngOnInit() {}

  showPic(pic: string) {
    this.currentPic = pic;
    this.popupVisible = true;
  }
}
