/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/devextreme-angular/ui/map/devextreme-angular-ui-map.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/map";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../../node_modules/devextreme-angular/ui/load-indicator/devextreme-angular-ui-load-indicator.ngfactory";
import * as i7 from "devextreme-angular/ui/load-indicator";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./dlg-map.component";
import * as i15 from "../../../../shared/sdk/services/custom/logger.service";
import * as i16 from "../../../../shared/modules/my-common/services/config.service";
import * as i17 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_DlgMapComponent = [i0.styles];
var RenderType_DlgMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgMapComponent, data: {} });
export { RenderType_DlgMapComponent as RenderType_DlgMapComponent };
function View_DlgMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "dx-map", [["height", "500"], ["provider", "google"], ["width", "100%"]], null, [[null, "onInitialized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInitialized" === en)) {
        var pd_0 = (_co.map_onInitialized($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxMapComponent_0, i2.RenderType_DxMapComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.IterableDifferHelper, i3.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(5, 7323648, [[1, 4], ["map", 4]], 3, i4.DxMapComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.IterableDifferHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { apiKey: [0, "apiKey"], autoAdjust: [1, "autoAdjust"], controls: [2, "controls"], height: [3, "height"], markers: [4, "markers"], provider: [5, "provider"], routes: [6, "routes"], width: [7, "width"], zoom: [8, "zoom"] }, { onInitialized: "onInitialized" }), i1.ɵqud(603979776, 2, { centerChildren: 1 }), i1.ɵqud(603979776, 3, { markersChildren: 1 }), i1.ɵqud(603979776, 4, { routesChildren: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER; var currVal_1 = true; var currVal_2 = true; var currVal_3 = "500"; var currVal_4 = _co.markers; var currVal_5 = "google"; var currVal_6 = _co.routes; var currVal_7 = "100%"; var currVal_8 = 13; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_DlgMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["style", "margin: auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "dx-load-indicator", [], null, null, null, i6.View_DxLoadIndicatorComponent_0, i6.RenderType_DxLoadIndicatorComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(6, 7323648, null, 0, i7.DxLoadIndicatorComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
export function View_DlgMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { map: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgMapComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingTpl", 2]], null, 0, null, View_DlgMapComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_6 = false; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_DlgMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-map", [], null, null, null, View_DlgMapComponent_0, RenderType_DlgMapComponent)), i1.ɵdid(1, 245760, null, 0, i14.DlgMapComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA, i15.LoggerService, i16.ConfigService, i17.DataSourceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgMapComponentNgFactory = i1.ɵccf("app-dlg-map", i14.DlgMapComponent, View_DlgMapComponent_Host_0, {}, {}, []);
export { DlgMapComponentNgFactory as DlgMapComponentNgFactory };
