/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-details-tab-notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/any-notes-page/any-notes-page.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/any-notes-page/any-notes-page.component";
import * as i4 from "../../../../shared/modules/my-common/services/common.service";
import * as i5 from "../../../../shared/sdk/services/custom/MyUser";
import * as i6 from "../../../../shared/sdk/services/custom/logger.service";
import * as i7 from "../../../../shared/modules/ui/services/ui.service";
import * as i8 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../../shared/modules/my-common/services/config.service";
import * as i11 from "../../../../shared/sdk/services/core/auth.service";
import * as i12 from "./vehicle-details-tab-notes.component";
var styles_VehicleDetailsTabNotesComponent = [i0.styles];
var RenderType_VehicleDetailsTabNotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleDetailsTabNotesComponent, data: {} });
export { RenderType_VehicleDetailsTabNotesComponent as RenderType_VehicleDetailsTabNotesComponent };
export function View_VehicleDetailsTabNotesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-any-notes-page", [], null, null, null, i2.View_AnyNotesPageComponent_0, i2.RenderType_AnyNotesPageComponent)), i1.ɵdid(1, 245760, null, 0, i3.AnyNotesPageComponent, [i4.CommonService, i5.MyUserApi, i6.LoggerService, i7.UiService, i8.DataSourceService, i9.MatDialog, i10.ConfigService, i11.LoopBackAuth], { filter: [0, "filter"], objectType: [1, "objectType"], objectId: [2, "objectId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter; var currVal_1 = _co.objectType; var currVal_2 = _co.objectId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VehicleDetailsTabNotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-details-tab-notes", [], null, null, null, View_VehicleDetailsTabNotesComponent_0, RenderType_VehicleDetailsTabNotesComponent)), i1.ɵdid(1, 245760, null, 0, i12.VehicleDetailsTabNotesComponent, [i6.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleDetailsTabNotesComponentNgFactory = i1.ɵccf("app-vehicle-details-tab-notes", i12.VehicleDetailsTabNotesComponent, View_VehicleDetailsTabNotesComponent_Host_0, { vehicle: "vehicle" }, {}, []);
export { VehicleDetailsTabNotesComponentNgFactory as VehicleDetailsTabNotesComponentNgFactory };
