<div style="display: flex">
  <div style="flex-basis: 370px">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        Personal Info
        <dx-button
          icon="fas fa-file-pdf"
          (onClick)="getPdfForm($event)"
          [matTooltip]="'Get MTM transportation form'"></dx-button>
      </div>

      <div class="dx-field" *ngIf="model?.mci">
        <div class="dx-field-label">QR Code</div>
        <div class="dx-field-value-static">
          <img id="qrcode" [src]="modelWithDetails$.getValue()?.mci | qrCode" />
          <dx-button
            icon="fas fa-print"
            matTooltip="print qr code"
            (onClick)="helper.printQrCard(modelWithDetails$.getValue()?.id).toPromise()"></dx-button>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Signature</div>
        <div class="dx-field-value-static">
          <a mat-button (click)="changeSign_onClick($event)" *ngIf="model?.signImgFileId; else noSignatureBlock"
            ><img
              style="max-height: 30px; max-width: 150px;"
              [src]="model?.signImgFileId | signatureLink: 'FILE':'resize--30/trim' | async | sanitizeUrl"
          /></a>

          <ng-template #noSignatureBlock>
            <dx-button
              icon="fas fa-signature"
              matTooltip="Replace Signature"
              (onClick)="changeSign_onClick($event)"></dx-button>
          </ng-template>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">First Name</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.firstname }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Name</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.lastname }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Middle Name</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.middlename }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">DOB</div>
        <div class="dx-field-value-static">
          <time>{{ modelWithDetails$.getValue()?.person?.dob | date: 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Sex</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.sex }}
        </div>
      </div>

      <div class="dx-field" *ngIf="!!modelWithDetails$.getValue()?.source">
        <div class="dx-field-label">Originally Referred By</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.source }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Client Since</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.clientSince | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Client Notes</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.notes }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Native Language</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.data['nativeLang'] }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Native Language Name</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.person?.data['nativeLangName'] }}
        </div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: modelWithDetails$.getValue()?.person?.contact?.addresses,
          type: 'address',
          types: 'addresses',
          header: 'Address'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: modelWithDetails$.getValue()?.person?.contact?.phones,
          type: 'phone',
          types: 'phones',
          header: 'Phone'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: modelWithDetails$.getValue()?.person?.contact?.emails,
          type: 'email',
          types: 'emails',
          header: 'Email'
        }
      "></ng-container>
  </div>

  <div style="flex-basis: 400px">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Participation Info</div>

      <div class="dx-field">
        <div class="dx-field-label">Facility</div>
        <div class="dx-field-value-static">
          <app-object-title
            [modelName]="'Facility'"
            [modelId]="modelWithDetails$.getValue()?.tenantId"
          ></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Status</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.status }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Internal ID</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.internalID }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MCI</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.mci }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Program</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.program?.name }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MCO</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.activeMco }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Broker</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.activeBroker }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Client Type</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.repetitiveMode }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Week Days</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.weekDays | weekDays }}
        </div>
      </div>

      <div class="dx-field">
        <div class="_dx-field-label">Attendance Notes</div>
        <div class="_dx-field-value-static">
          <app-consumer-notes [model]="model" [wrap]="false" style="max-width: 200px"></app-consumer-notes>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Default Facility</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.defaultDestination }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Transportation Codes</div>
        <div class="dx-field-value-static">
          <span *ngFor="let item of modelWithDetails$.getValue()?.transpCodes || []; last as isLast"
            >{{ transpCodesMap[item] || item }}<span *ngIf="!isLast">, </span></span
          >
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Transportation Instructions</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.transpInstrs }}
        </div>
      </div>

      <br />
      <div class="dx-fieldset-header">Coordination Agency Info</div>

      <div class="dx-field">
        <label class="dx-field-label">Coordination Agency</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.c10nAgency }}
        </div>
      </div>

      <div class="dx-field">
        <label class="dx-field-label">Case Worker Name</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.cwName }}
        </div>
      </div>

      <div class="dx-field">
        <label class="dx-field-label">Case Worker Phone</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.cwPhone }}
        </div>
      </div>

      <div class="dx-field">
        <label class="dx-field-label">Case Worker Fax</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.cwFax }}
        </div>
      </div>

      <div class="dx-field">
        <label class="dx-field-label">Case Worker Email</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.cwEmail }}
        </div>
      </div>

      <div class="dx-field">
        <label class="_dx-field-label">Case Worker Note</label>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?.cwNote }}
        </div>
      </div>
    </div>
  </div>

  <div style="flex-basis: 400px">
    <div class="dx-fieldset">
      <!--        <div class="dx-fieldset-header">Participation Info</div>-->

      <div class="dx-field">
        <div class="dx-field-label">Client Created On</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.ctime | date: 'fullDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Client Updated On</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.utime | date: 'fullDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">First Date On Schedule</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.firstDateOnSchedule | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Date On Schedule</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.lastDateOnSchedule | date: 'mediumDate' }}
        </div>
      </div>

      <!--      <div class="dx-field">-->
      <!--        <div class="dx-field-label">First Unit Date</div>-->
      <!--        <div class="dx-field-value-static">-->
      <!--          {{ (modelWithDetails$.getValue())?._details?.firstDateUnit | date:'mediumDate' }}-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="dx-field">-->
      <!--        <div class="dx-field-label">Last Unit Date</div>-->
      <!--        <div class="dx-field-value-static">-->
      <!--          {{ (modelWithDetails$.getValue())?._details?.lastDateUnit | date:'mediumDate' }}-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dx-field">
        <div class="dx-field-label">First E-Signature Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.firstESignDate | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last E-Signature Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.lastESignDate | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">CTS First TripID Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.firstDateCtsTripID | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">CTS Last TripID Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.lastDateCtsTripID | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MTM First TripID Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.firstDateMtmTripID | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MTM Last TripID Date</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._details?.lastDateMtmTripID | date: 'mediumDate' }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Special Needs</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.getValue()?._brokerClient?.lastTrip['Special Needs'] }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Inst / Directions / Driver Notes</div>
        <div class="dx-field-value-static">
          {{
            modelWithDetails$.getValue()?._brokerClient?.lastTrip['Driver Notes'] ||
              modelWithDetails$.getValue()?._brokerClient?.lastTrip['Inst / Directions'] ||
              modelWithDetails$.getValue()?._brokerClient?.lastTrip['Instructions-Directions']
          }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Broker Notes</div>
        <div class="dx-field-value-static">
          <pre>{{ modelWithDetails$.getValue()?.brokerNotes }}</pre>
        </div>
      </div>

    </div>
  </div>

  <div style="flex: auto"></div>
</div>

<ng-template #fieldSet let-items let-header="header" let-type="type" let-types="types">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header" *ngIf="items && items.length">{{ header }}</div>

    <ng-container *ngFor="let item of items">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ item?.label || 'Main' }}
        </div>
        <div class="dx-field-value-static">
          <span
            class="fas"
            [ngClass]="{
              'fa-phone': type === 'phone',
              'fa-at': type === 'email',
              'fa-map-marker-alt': type === 'address'
            }"></span>
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'phone'">
              <a href="tel:{{ item?.value }}">
                {{ item?.value }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
              <a href="mailto:{{ item?.value }}">
                {{ item?.value }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="'address'">
              <address style="display: inline">
                {{ item?.street }}, {{ item?.city }}, {{ item?.state }}, {{ item?.zip }}
              </address>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!items?.length">
      <!--There are no {{types}}-->
    </ng-container>
  </div>
</ng-template>
