import { LoadOptions } from 'devextreme/data/load_options';
import isString from 'lodash-es/isString';
import { LoopBackFilter } from '../../../../sdk';
import isEmpty from 'lodash-es/isEmpty';
import { ALoadOptionsConverter } from './ALoadOptionsConverter';
import escapeRegExp from 'lodash-es/escapeRegExp';
import { oc } from 'ts-optchain';
import isArray from 'lodash-es/isArray';
import fromPairs from 'lodash-es/fromPairs';

export class LoopBackLoadOptionsConverter extends ALoadOptionsConverter {
  protected operatorMap = {
    and: 'and',
    or: 'or',
  };

  constructor(options: { useRegExp: boolean; noSql: boolean } = { useRegExp: false, noSql: false }) {
    super(options);
  }

  convert(loadOptions: LoadOptions) {
    // console.log('LoadOptions:', loadOptions);

    let order: string[] = null;
    let where: any = null;

    if (loadOptions.sort && !isEmpty(loadOptions.sort)) {
      order = (loadOptions.sort as Array<any>)
        .filter(({ desc, selector }) => isString(selector))
        .map(({ desc, selector }) => `${selector} ${desc ? 'DESC' : 'ASC'}`);
    }

    if (loadOptions.filter && !isEmpty(loadOptions.filter)) {
      where = this.loadOptionsFilterToWhere(loadOptions.filter as any[]);
      // console.log(where);
    }

    //

    const filter: LoopBackFilter = {
      ...(oc(loadOptions).skip() ? { skip: oc(loadOptions).skip() } : {}),
      ...(oc(loadOptions).take() ? { limit: oc(loadOptions).take() } : {}),
    } as LoopBackFilter;

    if (where && !isEmpty(where)) {
      filter.where = where;
    }

    if (order && !isEmpty(order)) {
      filter.order = order;
    }

    if (!isEmpty(loadOptions.select)) {
      let fields = loadOptions.select;
      if (isString(fields)) fields = [fields];
      if (isArray(fields)) fields = fromPairs(fields.map(f => [f, true]));
      filter.fields = fields;
    }

    // console.log('loadOptions To LB:', this.loadOptions, filter);

    return !isEmpty(filter) ? filter : {};
  }

  singleFilterToWhereRule(filter: [string | null, string, any], not?: boolean): any {
    if (!filter || !this.isSingleFilterRule(filter)) {
      throw new Error(`Not valid filter rule: ${JSON.stringify(filter)}`);
    }

    const useRegExp = this.options.useRegExp;
    const noSql = this.options.noSql;

    const mapValueFn = (_operator, _value) => {
      switch (_operator) {
        case 'startswith':
          return useRegExp
            ? !not
              ? `/^${escapeRegExp(_value)}/i`
              : `/^(?!${escapeRegExp(_value)}).*/i`
            : noSql
            ? `^${escapeRegExp(_value)}.*`
            : `${_value}%`;
        case 'endswith':
          return useRegExp
            ? !not
              ? `/${escapeRegExp(_value)}$/i`
              : `/.*(?<!.${escapeRegExp(_value)})/i`
            : noSql
            ? `.*${escapeRegExp(_value)}$`
            : `%${_value}`;
        case 'contains':
          return useRegExp
            ? !not
              ? `/${escapeRegExp(_value)}/i`
              : `/^((?!${escapeRegExp(_value)}).)*$/i`
            : noSql
            ? `.*${escapeRegExp(_value)}.*`
            : `%${_value}%`;
        case 'notcontains':
          return useRegExp
            ? !not
              ? `/^((?!${escapeRegExp(_value)}).)*$/i`
              : `/${escapeRegExp(_value)}/i`
            : noSql
            ? `.*${escapeRegExp(_value)}.*`
            : `%${_value}%`;
      }
      return _value;
    };

    // simple filter rule
    const operatorMap = {
      '=': 'eq',
      '<>': 'neq',
      '>': 'gt',
      '>=': 'gte',
      '<': 'lt',
      '<=': 'lte',
    };
    Object.assign(
      operatorMap,
      useRegExp
        ? { startswith: 'regexp', endswith: 'regexp', contains: 'regexp', notcontains: 'regexp' }
        : { startswith: 'like', endswith: 'like', contains: 'like', notcontains: 'nlike' },
    );

    // tslint:disable-next-line:prefer-const
    let [field, operator, value] = filter;

    if (isString(value)) {
      value = value.toString().replace(/^\s+/g, '');
      value = value.toString().replace(/\s+$/g, '');
    }

    // tslint:disable-next-line:prefer-const
    let [newField, newOperator, newValue] = [field, operatorMap[operator] || operator, mapValueFn(operator, value)];

    const operatorInverseMap = {
      eq: 'neq',
      neq: 'eq',
      gt: 'lte',
      gte: 'lt',
      lt: 'gte',
      lte: 'gt',
      like: 'nlike',
      nlike: 'like',
      regexp: 'regexp',
    };

    if (not && operatorInverseMap[newOperator]) {
      not = false;
      newOperator = operatorInverseMap[newOperator];
    }

    let where: any = {};

    if (['like', 'nlike'].includes(newOperator) && noSql) {
      where = { [newField]: { [newOperator]: newValue, options: 'i' } };
    } else {
      where = { [newField]: { [newOperator]: newValue } };
    }

    if (not) {
      const [k, v] = Object.entries(where)[0];
      where = { [k]: { not: v } };
    }

    return where;
  }
}
