import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoopBackFilter, Person, PersonView } from '../../../shared/sdk/models';
import { PersonApi, PersonViewApi } from '../../../shared/sdk/services/custom';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { FullNamePipe } from '../../../shared/modules/ui/pipes/full-name.pipe';

@Injectable()
export class HelperService {
  displayExpr = (p: Person | PersonView): string => {
    return new FullNamePipe(this.config).transform(p);
  };

  constructor(
    @Inject(PersonApi) public api: PersonApi,
    @Inject(PersonViewApi) public viewApi: PersonViewApi,
    @Inject(ConfigService) public config: ConfigService,
  ) {}

  loadDetails$(personId: number, include: any = { contact: ['addresses', 'phones', 'emails'] }): Observable<Person> {
    const filter: LoopBackFilter = {
      include,
    } as LoopBackFilter;
    return this.api.findById<Person>(personId, filter).pipe(catchError(err => of(null)));
  }
}
