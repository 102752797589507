import * as tslib_1 from "tslib";
import { OnInit, Type } from '@angular/core';
import { ABaseModelLoaderComponent } from './a-base-model-loader.component';
export class ABaseModelPaneWToolbarComponent extends ABaseModelLoaderComponent {
    constructor(logger, ui, dss) {
        super(logger, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.toolbarItems = [];
        this.showAdd = true;
        this.showEdit = true;
    }
    ngOnInit() {
        super.ngOnInit();
        this.caption = this.caption || this.ModelClass.getModelName() + ' info';
        this.buildToolbarItems();
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.buildToolbarItems(model);
        });
    }
    buildToolbarItems(model) {
        this.toolbarItems = [
            {
                location: 'before',
                locateInMenu: 'never',
                text: this.caption,
            },
            ...(this.showEdit
                ? [
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                            text: 'Edit Info',
                            hint: 'Edit info',
                            disabled: !model,
                            onClick: () => this.onEditClickHandler(),
                        },
                    },
                ]
                : []),
            ...(this.showAdd
                ? [
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                            text: 'Add New',
                            hint: 'Add new',
                            visible: !model,
                            onClick: () => this.onAddClickHandler(),
                        },
                    },
                ]
                : []),
        ];
    }
    onAddClickHandler() {
        this.ui.openEditDialog({
            modelId: null,
            ModelClass: this.ModelClass,
            FormComponentClass: this.FormComponent,
        });
    }
    onEditClickHandler() {
        this.ui.openEditDialog({
            modelId: this.modelId,
            ModelClass: this.ModelClass,
            FormComponentClass: this.FormComponent,
        });
    }
}
