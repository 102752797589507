import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee } from 'src/app/shared/sdk';

@Component({
  selector: 'app-dlg-add-employee-backup.component',
  templateUrl: './dlg-add-employee-backup.component.html',
  styleUrls: ['./dlg-add-employee-backup.component.scss'],
})
export class DlgAddEmployeeBackupComponent implements OnInit {
  resetableForm = false;
  collapsibleForm = false;
  selected;

  notBackUpEmployees$: Observable<DataSourceOptions> = of([]);

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgAddEmployeeBackupComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.notBackUpEmployees$ = of(this.data.notBackUpEmployees);
  }

  selectBoxdisplayExpression = (e: Employee | undefined) => (e ? `${e.person.firstname} ${e.person.lastname}` : null);

  onSave = () => this.dialogRef.close(this.selected);

  protected async beforeSubmittingAsync(data: any): Promise<void> {}
}

