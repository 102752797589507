<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Name</div>
        <div class="dx-field-value-static">{{ model?.name }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Object FQN</div>
        <div class="dx-field-value-static">{{ model?.objectFQN }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Inactive</div>
        <div class="dx-field-value-static">{{ model?.inactive | yesNo }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Type</div>
        <div class="dx-field-value-static">{{ model?.type }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Visibility</div>
        <div class="dx-field-value-static">{{ model?.visibility }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Exp Alert</div>
        <div class="dx-field-value-static">{{ model?.expAlert | yesNo }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Missing Doc Alert</div>
        <div class="dx-field-value-static">{{ model?.missingDocAlert | yesNo }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Default Notes</div>
        <div class="dx-field-value-static">{{ model?.defaultNotes }}</div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Field List</div>

      <ng-container *ngIf="model?.customFields?.length; else noFieldsBlock">
        <ng-container *ngFor="let field of model?.customFields; first as isFirst">
          <!--<hr *ngIf="!isFirst">-->
          <div class="dx-field">
            <div class="dx-field-label">{{ field?.label }}</div>
            <div class="dx-field-value-static">
              <em *ngIf="field?.optional">optional</em> field <strong>{{ field?.field }}</strong> of type
              <strong>[{{ field?.type }}]</strong>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noFieldsBlock>No items</ng-template>
    </div>
  </ng-container>
</app-details-tab>
