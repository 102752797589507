import {
  CustomField
} from '../index';

declare var Object: any;
export interface ObjectCFVInterface {
  "objectType"?: string;
  "objectId": number;
  "value"?: string;
  "valueBool"?: boolean;
  "valueInt"?: number;
  "valueFloat"?: number;
  "valueString"?: string;
  "valueDate"?: any;
  "valueTime"?: string;
  "valueDatetime"?: Date;
  "valueText"?: string;
  "valueJSON"?: any;
  "id"?: number;
  "customFieldId"?: number;
  customField?: CustomField;
  forObject?: any;
}

export class ObjectCFV implements ObjectCFVInterface {
  "objectType": string;
  "objectId": number;
  "value": string;
  "valueBool": boolean;
  "valueInt": number;
  "valueFloat": number;
  "valueString": string;
  "valueDate": any;
  "valueTime": string;
  "valueDatetime": Date;
  "valueText": string;
  "valueJSON": any;
  "id": number;
  "customFieldId": number;
  customField: CustomField;
  forObject: any;
  constructor(data?: ObjectCFVInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ObjectCFV`.
   */
  public static getModelName() {
    return "ObjectCFV";
  }

  /**
  * This method creates an instance of ObjectCFV for dynamic purposes.
  **/
  public static factory(data: ObjectCFVInterface): ObjectCFV{
    return new ObjectCFV(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ObjectCFV',
      plural: 'ObjectCFVs',
      path: 'ObjectCFVs',
      idName: 'id',
      properties: {
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "valueBool": {
          name: 'valueBool',
          type: 'boolean'
        },
        "valueInt": {
          name: 'valueInt',
          type: 'number'
        },
        "valueFloat": {
          name: 'valueFloat',
          type: 'number'
        },
        "valueString": {
          name: 'valueString',
          type: 'string'
        },
        "valueDate": {
          name: 'valueDate',
          type: 'any'
        },
        "valueTime": {
          name: 'valueTime',
          type: 'string'
        },
        "valueDatetime": {
          name: 'valueDatetime',
          type: 'Date'
        },
        "valueText": {
          name: 'valueText',
          type: 'string'
        },
        "valueJSON": {
          name: 'valueJSON',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "customFieldId": {
          name: 'customFieldId',
          type: 'number'
        },
      },
      relations: {
        customField: {
          name: 'customField',
          type: 'CustomField',
          model: 'CustomField',
          relationType: 'belongsTo',
                  keyFrom: 'customFieldId',
          keyTo: 'id'
        },
        forObject: {
          name: 'forObject',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'objectId',
          keyTo: 'id'
        },
      }
    }
  }
}
