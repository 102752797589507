import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-edit-card-number',
  templateUrl: './dlg-edit-card-number.component.html',
  styleUrls: ['./dlg-edit-card-number.component.scss'],
})
export class DlgEditCardNumberComponent implements OnInit, AfterViewInit {
  value: string;

  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DlgEditCardNumberComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
  ) {
    this.value = data.value;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => this.inputElement.nativeElement.select());
  }
}
