<dx-map
        #map
        width="100%"
        height="100%"
        provider="google"
        [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
        [controls]="true"
        [autoAdjust]="autoAdjust"
        [center]="mapCenter"
        [zoom]="mapZoom"
        [markers]="markers"></dx-map>