import { Component, OnInit } from '@angular/core';
//
import DataSource from 'devextreme/data/data_source';
//
import { EmployeePosition, LoggerService } from 'src/app/shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';

@Component({
  selector: 'app-employee-position-grid',
  templateUrl: './employee-position-grid.component.html',
  styleUrls: ['./employee-position-grid.component.scss'],
})
export class EmployeePositionGridComponent implements OnInit {
  ds: DataSource;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    this.ds = dss.getDataSource(EmployeePosition);
  }

  ngOnInit() {}

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      // stateStoreGUID: '25e66792-3ed5-4ae5-afe7-70924a0a70fa',
    });
  }
}

