import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Employee, Facility, Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { EmployeeApi, FacilityApi, LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment, { duration } from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';

@Component({
  selector: 'app-auto-dispatch-details-tab-denied',
  templateUrl: './auto-dispatch-details-tab-denied.component.html',
  styleUrls: ['./auto-dispatch-details-tab-denied.component.scss'],
  providers: [],
})
export class AutoDispatchDetailsTabDeniedComponent extends ABaseComponent {
  _message: any;

  @Input()
  set message(m: any) {
    this._message = m;
    this.changeHandle();
  }
  get message() {
    return this._message;
  }

  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  changeHandle() {
    if (this.message) this.dso = this.message.denied;
  }
}

