<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-field">
      <div class="_dx-field-label" title="Notes">Attendance Notes</div>
      <div class="_dx-field-value-static">
        <!--        {{ model?.notes }}-->
        <app-consumer-notes [model]="model"></app-consumer-notes>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label" title="Person Notes">Person Notes</div>
      <div class="dx-field-value-static">
        {{ model?.person?.notes }}
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label" title="Contact Notes">Contact Notes</div>
      <div class="dx-field-value-static">
        {{ model?.person?.contact?.notes }}
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label" title="Special Instructions">Special Instructions</div>
      <div class="dx-field-value-static">
        {{ model?.specialInstrs }}
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label" title="Transportation Instructions">Transportation Instructions</div>
      <div class="dx-field-value-static">
        <span class="badge" *ngFor="let item of model?.transpInstrs | split">{{ item }}</span>
      </div>
    </div>
  </ng-container>
</app-details-tab>
