import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';
import { FileInfo, SelectEvent, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService } from '../../../../shared/sdk';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import notify from 'devextreme/ui/notify';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';

@Component({
  selector: 'app-dlg-import-card',
  templateUrl: './dlg-import-card.component.html',
  styleUrls: ['./dlg-import-card.component.scss'],
})
export class DlgImportCardComponent extends ABaseComponent implements OnInit {
  saveUrl = UploadHelperService.SIGNED_URL_PLACEHOLDER;
  uploadDisabled = false;
  processing = false;
  done = false;
  imported = false;
  response;
  error;
  files: FileInfo[];

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgImportCardComponent, any>,
    private dss: DataSourceService,
    @Inject(MAT_DIALOG_DATA) private data: { jobName: string },
    private pusher: PusherService,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  uploader_select(e: SelectEvent) {
    console.log('select:', e);

    this.files = e.files;

    this.done = false;
    this.error = undefined;
    this.response = undefined;
  }

  uploader_upload(e: UploadEvent) {
    console.log('upload:', e);

    e.headers = e.headers.set('x-goog-meta-job_uuid', e.files[0].uid);
  }

  uploader_success(e: SuccessEvent) {
    console.log('success:', e);

    this.uploadDisabled = true;
    this.processing = true;

    from(e.files)
      .pipe(
        switchMap((f: FileInfo) => this.pusher.requestResponse(f.uid)),
        switchMap(({ file, bucket }) => this.pusher.rpc(this.data.jobName, { file, bucket, useRunService: true })),
        tap((res: string) => {
          this.response = res;
          this.done = true;
          this.imported = true;
        }),
        catchError((err: Error) => {
          this.error = err.message;
          return of(notify(err.message, 'error', 5000));
        }),
        tap(() => {
          this.uploadDisabled = false;
          this.processing = false;
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
