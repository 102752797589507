<dx-data-grid
  #grid
  [dataSource]="dso$ | async"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-group-panel [visible]="false"></dxo-group-panel>

  <dxo-editing [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [mode]="'batch'"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <dxi-column caption="Broker" dataField="source" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="MCI" dataField="mci" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="First Name" dataField="person.firstname" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="DOB"
    dataField="person.dob"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [allowEditing]="false"></dxi-column>

  <dxi-column caption="Address" dataField="getAddress" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Phone" dataField="getPhone" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Facility" dataField="tenantId" [showEditorAlways]="true">
    <dxo-lookup [dataSource]="facilityDso$ | async" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <!-- Templates -->
</dx-data-grid>
