import { ChangeDetectorRef, Component } from '@angular/core';
//
import { Document } from '../../../../shared/sdk';
import { LoopBackFilter } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss'],
})
export class DocumentDetailsComponent extends ABaseModelLoaderComponent<Document> {
  toolbarItems: any[];

  constructor(protected cd: ChangeDetectorRef, protected logger: LoggerService, protected ds: DataSourceService) {
    super(logger, ds);

    this.buildToolbarItems();
  }

  protected get ModelClass(): any {
    return Document;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['documentType', 'owner'],
    };
  }

  protected onModelLoaded(model: any): void {
    this.buildToolbarItems(model);
  }

  private buildToolbarItems(model: Document = null) {
    if (model) {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: `${model.name}`,
        },
        {
          location: 'after',
          locateInMenu: 'never',
          text: model.documentType.name,
        },
      ];
    } else {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.errorMessage || 'Not selected',
        },
      ];
    }
  }
}
