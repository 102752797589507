import { Component, EventEmitter, OnInit, Output } from '@angular/core';
//
import { EmployeeView } from '../../../../shared/sdk';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent implements OnInit {
  @Output() mySelected: EventEmitter<EmployeeView[]> = new EventEmitter<EmployeeView[]>();

  constructor() {}

  ngOnInit() {}

  grid_onSelected(items: EmployeeView[]) {
    this.mySelected.emit(items);
  }
}
