import {
  Employee,
  Facility
} from '../index';

declare var Object: any;
export interface TrainedAtFacilityInterface {
  "trainedAt": any;
  "dtime"?: Date;
  "id"?: number;
  "employeeId"?: number;
  "facilityId"?: number;
  employee?: Employee;
  facility?: Facility;
}

export class TrainedAtFacility implements TrainedAtFacilityInterface {
  "trainedAt": any;
  "dtime": Date;
  "id": number;
  "employeeId": number;
  "facilityId": number;
  employee: Employee;
  facility: Facility;
  constructor(data?: TrainedAtFacilityInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `TrainedAtFacility`.
   */
  public static getModelName() {
    return "TrainedAtFacility";
  }

  /**
  * This method creates an instance of TrainedAtFacility for dynamic purposes.
  **/
  public static factory(data: TrainedAtFacilityInterface): TrainedAtFacility{
    return new TrainedAtFacility(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TrainedAtFacility',
      plural: 'TrainedAtFacilities',
      path: 'TrainedAtFacilities',
      idName: 'id',
      properties: {
        "trainedAt": {
          name: 'trainedAt',
          type: 'any'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
      }
    }
  }
}
