import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeView } from '../../../../shared/sdk';

@Component({
  selector: 'app-employee-hr-certs',
  templateUrl: './employee-hr-certs.component.html',
  styleUrls: ['./employee-hr-certs.component.scss'],
})
export class EmployeeHRCertsComponent implements OnInit {
  $selectedIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  selectedId$: Observable<number> = new Observable();

  constructor() {
    this.selectedId$ = this.$selectedIds$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
  }

  ngOnInit() {}

  list_onSelected(items: EmployeeView[]) {
    if (items && items.length) {
      this.$selectedIds$.next(items.map(item => item.id));
    } else {
      this.$selectedIds$.next([]);
    }
  }

  split_dragEnd(e) {
    // dxEvents.trigger(window, 'resize');
  }

  split_transitionEnd(e) {
    // dxEvents.trigger(window, 'resize');
  }
}

