<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Document Type Info</div>

    <div class="dx-field">
      <div class="dx-field-label">Name *</div>
      <div class="dx-field-value">
        <dx-text-box autofocus formControlName="name">
          <dx-validator name="Document Type Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Object FQN *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="objectFQNs" displayExpr="Name" valueExpr="ID" formControlName="objectFQN">
          <dx-validator name="Object FQN">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Inactive</div>
      <div class="dx-field-value">
        <dx-check-box formControlName="inactive"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Visibility *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="['public', 'internal']" formControlName="visibility">
          <dx-validator name="Visibility">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Type *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="['operational', 'financial', 'other']" formControlName="type">
          <dx-validator name="Type">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Exp Alert</div>
      <div class="dx-field-value">
        <dx-check-box formControlName="expAlert"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Missing Doc Alert</div>
      <div class="dx-field-value">
        <dx-check-box formControlName="missingDocAlert"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Default Notes</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="defaultNotes">
        </dx-text-box>
      </div>
    </div>

    <br />
    <mat-divider style="margin: 0 -34px"></mat-divider>
    <br /><br />

    <div class="dx-fieldset-header">Custom Fields</div>

    <ng-container formArrayName="customFields">
      <br />

      <app-form-array-group
        entityName="custom field"
        icon="subject"
        [formArray]="customFieldsFormArray"
        (addItem)="addFormArrayItem('customFields')"
        (removeItem)="removeFormArrayItem('customFields', $event)"></app-form-array-group>
    </ng-container>

    <!-- /// -->
    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <!--    <p class="alert-danger" *ngIf="errorMessage">-->
    <!--      {{ errorMessage }}-->
    <!--    </p>-->
  </div>
</dx-validation-group>
