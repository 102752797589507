import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  checkAuthGetError,
  checkAuthGetErrorMessage,
  checkAuthGetPending,
  checkAuthReducer,
  CheckAuthState,
} from './sign/check-auth';
import {
  infoGetAccessToken,
  infoGetCurrentTenant,
  infoGetEnabledComponents,
  infoGetMFASecret,
  infoGetRoles,
  infoGetTenant,
  infoGetTenants,
  infoGetUser,
  infoIsSignedIn,
  infoIsSU,
  infoReducer,
  InfoState,
} from './sign/info';
import { loginGetError, loginGetErrorMessage, loginGetPending, loginReducer, LoginState } from './sign/login';
import { logoutGetError, logoutGetErrorMessage, logoutGetPending, logoutReducer, LogoutState } from './sign/logout';
import { tenantGetError, tenantGetErrorMessage, tenantGetPending, tenantReducer, TenantState } from './sign/tenant';

export interface SignState {
  info: InfoState;
  tenant: TenantState;
  checkAuth: CheckAuthState;
  login: LoginState;
  logout: LogoutState;
}

export const signReducers: ActionReducerMap<SignState> = {
  info: infoReducer,
  tenant: tenantReducer,
  checkAuth: checkAuthReducer,
  login: loginReducer,
  logout: logoutReducer,
};

/**
 * Selectors
 */

export const getSignState = createFeatureSelector<SignState>('sign');

//

export const selectSignInfoState = createSelector(getSignState, (state: SignState) => state.info);
export const getAccessToken = createSelector(selectSignInfoState, infoGetAccessToken);
export const getUser = createSelector(selectSignInfoState, infoGetUser);
export const isSignedIn = createSelector(selectSignInfoState, infoIsSignedIn);
export const getMFASecret = createSelector(selectSignInfoState, infoGetMFASecret);

export const getCurrentTenant = createSelector(selectSignInfoState, infoGetCurrentTenant);
export const getTenants = createSelector(selectSignInfoState, infoGetTenants);
export const getTenant = createSelector(selectSignInfoState, infoGetTenant);
export const getRoles = createSelector(selectSignInfoState, infoGetRoles);
export const isSU = createSelector(selectSignInfoState, infoIsSU);
export const getEnabledComponents = createSelector(selectSignInfoState, infoGetEnabledComponents);

//

export const selectCheckAuthState = createSelector(getSignState, (state: SignState) => state.checkAuth);
export const getCheckAuthPending = createSelector(selectCheckAuthState, checkAuthGetPending);
export const getCheckAuthError = createSelector(selectCheckAuthState, checkAuthGetError);
export const getCheckAuthErrorMessage = createSelector(selectCheckAuthState, checkAuthGetErrorMessage);

//

export const selectLoginState = createSelector(getSignState, (state: SignState) => state.login);
export const getLoginPending = createSelector(selectLoginState, loginGetPending);
export const getLoginError = createSelector(selectLoginState, loginGetError);
export const getLoginErrorMessage = createSelector(selectLoginState, loginGetErrorMessage);

//

export const selectTenantState = createSelector(getSignState, (state: SignState) => state.tenant);
export const getTenantPending = createSelector(selectTenantState, tenantGetPending);
export const getTenantError = createSelector(selectTenantState, tenantGetError);
export const getTenantErrorMessage = createSelector(selectTenantState, tenantGetErrorMessage);

//

export const selectLogoutState = createSelector(getSignState, (state: SignState) => state.logout);
export const getLogoutPending = createSelector(selectLogoutState, logoutGetPending);
export const getLogoutError = createSelector(selectLogoutState, logoutGetError);
export const getLogoutErrorMessage = createSelector(selectLogoutState, logoutGetErrorMessage);
