import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmployeeView } from 'src/app/shared/sdk';
//
@Component({
  selector: 'app-employee-perf-list',
  templateUrl: './employee-perf-list.component.html',
  styleUrls: ['./employee-perf-list.component.scss'],
})
export class EmployeePerfListComponent implements OnInit {
  @Output() mySelected: EventEmitter<EmployeeView[]> = new EventEmitter<EmployeeView[]>();
  @Output() mySelectedRange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  grid_onSelected(items: EmployeeView[]) {
    this.mySelected.emit(items);
  }

  date_onSelected(range: any) {
    this.mySelectedRange.emit(range);
  }
}

