<div class="form-acquisition">
  <div class="dx-field" *ngIf="formArray.value.length > 1">
    <div class="dx-field-label">Acquisitions</div>
    <div class="dx-field-value acquisitions-select">
      <dx-select-box
        id="custom-templates"
        [dataSource]="formArray.controls"
        fieldTemplate="field"
        (onValueChanged)="handleChange($event)">
        <div *dxTemplate="let data of 'field'">
          <div class="custom-item">
            <dx-text-box
              class="product-name"
              [value]="getAcquisitionName(selectedForm)"
              [readOnly]="true"></dx-text-box>
          </div>
        </div>
        <div *dxTemplate="let data of 'item'">
          <div class="custom-item">
            <div class="product-name">
              {{ getAcquisitionName(data) }}
            </div>
          </div>
        </div>
      </dx-select-box>

      <dx-button (onClick)="remove.emit()">Remove</dx-button>
    </div>
  </div>

  <ng-container [formGroup]="formArray">
    <div *ngFor="let form of formArray.controls; let idx = index">
      <div [class]="(selectedForm !== form && 'hidden-form') || ''">
        <ng-container [formGroup]="form">
          <input type="hidden" formControlName="file" #file />

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Date</div>
            <div class="dx-field-value">
              <dx-date-box formControlName="date" dateSerializationFormat="yyyy-MM-dd">
                <dx-validator name="Acquisition Date">
                </dx-validator>
              </dx-date-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Type</div>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="ACQUISITION_TYPE" formControlName="type" #acquisitionType>
                <dx-validator name="Acquisition Type">
                </dx-validator>
              </dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Seller/Dealer</div>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="dealerDso" formControlName="dealer" valueExpr="businessName" displayExpr="businessName" [showClearButton]="true"></dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Seller/Dealer's Salesperson</div>
            <div class="dx-field-value person-field-value">
              <app-employee-drop-down-grid formControlName="salePersonId" [showClearButton]="true"></app-employee-drop-down-grid>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Creditor</div>
            <div class="dx-field-value">
              <dx-select-box [dataSource]="creditorDso" formControlName="creditor" valueExpr="businessName" displayExpr="businessName" [showClearButton]="true"></dx-select-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Creditor's Representative</div>
            <div class="dx-field-value person-field-value">
              <app-employee-drop-down-grid formControlName="creditorPersonId" [showClearButton]="true"></app-employee-drop-down-grid>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Signor</div>
            <div class="dx-field-value person-field-value">
              <app-employee-drop-down-grid formControlName="signorPersonId" [showClearButton]="true"></app-employee-drop-down-grid>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Full Price</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="fullPrice"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Principal Balance</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="principalBalance"></dx-text-box>
            </div>
          </div>

          <ng-container *ngIf="form.value['type'] !== 'Cash Purchase'">
            <div class="dx-field">
              <div class="dx-field-label">Acquisition Term In Month</div>
              <div class="dx-field-value">
                <dx-select-box
                  [dataSource]="ACQUISITION_TERM_IN_MONTH"
                  formControlName="termInMonth"
                  [showClearButton]="true"></dx-select-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Loan Payment (Principal & Interest)</div>
              <div class="dx-field-value">
                <dx-text-box formControlName="loanPayment"></dx-text-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Frequency Of Loan Payment</div>
              <div class="dx-field-value">
                <dx-select-box
                  [dataSource]="ACQUISITION_FREQUENCY_OF_LOAN_PAYMEN"
                  formControlName="frequencyOfLoanPayment"
                  [showClearButton]="true"></dx-select-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Payment Dates</div>
              <div class="dx-field-value">
                <dx-text-box formControlName="paymentDates"></dx-text-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition First Payment Date</div>
              <div class="dx-field-value">
                <dx-date-box formControlName="firstPaymentDate" dateSerializationFormat="yyyy-MM-dd"></dx-date-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Last Payment Date</div>
              <div class="dx-field-value">
                <dx-date-box formControlName="lastPaymentDate" dateSerializationFormat="yyyy-MM-dd"></dx-date-box>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Annual Interest Rate</div>
              <div class="dx-field-value">
                <dx-text-box formControlName="annualInterestRate"></dx-text-box>
              </div>
            </div>
          </ng-container>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Contract</div>
            <div class="dx-field-value">
              <dx-text-area formControlName="contract"></dx-text-area>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Acquisition Contract Date</div>
            <div class="dx-field-value">
              <dx-date-box formControlName="contractDate" dateSerializationFormat="yyyy-MM-dd"></dx-date-box>
            </div>
          </div>

          <div class="dx-field" *ngIf="!['Cash Purchase', 'Finance'].includes(form.value['type'])">
            <div class="dx-field-label">Acquisition Lease Mileage Limit</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="leaseMileageLimit"></dx-text-box>
            </div>
          </div>

          <ng-container *ngIf="form.value['type'] !== 'Cash Purchase'">
            <div class="dx-field">
              <div class="dx-field-label">Acquisition Buyout Option</div>
              <div class="dx-field-value">
                <dx-text-area formControlName="buyoutOption"></dx-text-area>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Acquisition Buyout/Balloon Amount</div>
              <div class="dx-field-value">
                <dx-text-box formControlName="balloonAmount"></dx-text-box>
              </div>
            </div>
          </ng-container>

          <div class="dx-field">
            <div class="dx-field-label">Documents</div>
            <div class="dx-field-value file-uploader-value">
              <div>
                <div class="file" *ngFor="let f of form.value.file || []; let idx = index">
                  <span class="name">{{ f.originalFilename }}</span>
                  <button mat-icon-button type="button" matTooltip="Remove" (click)="removeFile(idx, form)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
              </div>
              <dx-file-uploader (onFilesUploaded)="handleFilesUploaded($event)" (onUploaded)="handleUploaded($event, form)" [multiple]="true">
                <dx-validator name="Document File">
                  <!--<dxi-validation-rule type="required"></dxi-validation-rule>-->
                  <dxi-validation-rule
                    type="custom"
                    [reevaluate]="true"
                    [validationCallback]="validationCallback"></dxi-validation-rule>
                </dx-validator>
              </dx-file-uploader>
            </div>
          </div>

          <div class="dx-field" *ngIf="form.value.id">
            <div class="dx-field-label">Acquisition Notes</div>
            <div class="dx-field-value">
              <app-any-notes
                [form]="form"
                [notes]="getAnyNotes(form)" 
                [configMap]="{vehicleId: vehicle.id, acquisitionId: []}"
                (validateAsync)="validateAsync"
              ></app-any-notes>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
