import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import head from 'lodash-es/head';
import isNil from 'lodash-es/isNil';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { oc } from 'ts-optchain';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { FullNamePipe } from '../../../../shared/modules/ui/pipes/full-name.pipe';
import { LoggerService, MyUserView, MyUtils, MyUtilsApi } from '../../../../shared/sdk';
export class NotificationsFormComponent extends ABaseComponent {
    constructor(logger, common, fb, dss, config) {
        super(logger);
        this.logger = logger;
        this.common = common;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.processing = false;
        this.usersDso = [];
        this.formSettingsMap = new Map();
        this.searchExpr = ['username', 'email', 'person_firstname', 'person_lastname'];
        this.displayExpr = (u) => `${new FullNamePipe(this.config).transform(u) || u.username} - ${u.__empEmail || u.email}`;
        this.buildDataSource();
        this.buildForm();
        void this.loadSettings();
    }
    form_ngSubmit(e) {
        e.preventDefault();
        void (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.processing = true;
            if (this.form.valid) {
                const data = this.form.value;
                yield this.dss.getApi(MyUtils).mongoSaveNotificationSettings(data).toPromise();
                this.form.markAsPristine();
                notify('Done!', 'success');
            }
            else {
                notify('There are not valid fields', 'warning', 5000);
            }
        }))()
            .catch(err => notify(err.message, 'error', 5000))
            .finally(() => (this.processing = false));
        return false;
    }
    reset_onClick(e) {
        setTimeout(() => this.form.reset(this.defaultData));
    }
    buildForm() {
        this.formSettingsMap.set('', {
            opsAlertUsers: [],
            opsAlertUsersIndividual: [],
            opsAlertUsersIndividualSms: [],
            opsAlertUsersCheckedInWithOtherVehicle: [],
            opsAlertUsersNewClientAutoCreated: [],
            opsAlertUsersClientUpdate: [],
            opsAlertUsersEntityActions: [],
            opsAlertUsersMinutesLate: [],
            opsAlertUsersIndividualMinutesLate: [],
            fleetStatusReportUsers: [],
            fleetBackupReportUsers: [],
            fleetMaintenanceReportUsers: [],
            hrNotificationUsers: [],
            hrPtoRequestNotificationUsers: [],
            unresolvedEtdIssuesUsers: [],
            vehicleStatusChangeNotificationUsers: [],
            vehicleBackup2AssignedAutoChangeNotificationUsers: [],
        });
        this.form = this.fb.group(this.formSettingsMap.get(''));
        this.defaultData = this.form.value;
    }
    buildDataSource() {
        const so = this.dss.getStoreOptions(MyUserView);
        so.customFilter = {
            where: {
                status: 'ACTIVE',
                or: ['SU', 'CLERK', 'MANAGER', 'BILLER', 'ADMIN', 'HR_ADMIN'].map(r => ({
                    _roles: { $json_e_c: { $: JSON.stringify(r) } },
                })),
            },
            include: [
                {
                    relation: 'employee',
                    scope: {
                        fields: ['id', 'personId'],
                        include: [
                            {
                                relation: 'person',
                                scope: {
                                    fields: ['id', 'contactId'],
                                    include: [
                                        {
                                            relation: 'contact',
                                            scope: {
                                                fields: ['id'],
                                                include: [
                                                    {
                                                        relation: 'emails',
                                                        scope: {
                                                            fields: ['id', 'label', 'value'],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
            order: ['person_firstname ASC', 'person_lastname ASC', 'username ASC'],
        };
        const dso = {
            store: new CustomStore(so),
            map: (u) => (Object.assign({}, u, { __empEmail: oc(oc(u)
                    .employee.person.contact.emails([])
                    .find(e => e.label === '' || isNil(e.label)) || head(oc(u).employee.person.contact.emails([]))).value() })),
        };
        this.usersDso = dso;
    }
    loadSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const settings = (yield gqlMongoLoad(this.dss, 'NotificationSettings', {}, []).pipe().toPromise())[0] || {};
            this.form.get('opsAlertUsers').setValue(settings.opsAlertUsers);
            this.form.get('opsAlertUsersIndividual').setValue(settings.opsAlertUsersIndividual);
            this.form.get('opsAlertUsersIndividualSms').setValue(settings.opsAlertUsersIndividualSms);
            this.form.get('opsAlertUsersCheckedInWithOtherVehicle').setValue(settings.opsAlertUsersCheckedInWithOtherVehicle);
            this.form.get('opsAlertUsersNewClientAutoCreated').setValue(settings.opsAlertUsersNewClientAutoCreated);
            this.form.get('opsAlertUsersClientUpdate').setValue(settings.opsAlertUsersClientUpdate);
            this.form.get('opsAlertUsersEntityActions').setValue(settings.opsAlertUsersEntityActions);
            this.form.get('opsAlertUsersMinutesLate').setValue(settings.opsAlertUsersMinutesLate);
            this.form.get('opsAlertUsersIndividualMinutesLate').setValue(settings.opsAlertUsersIndividualMinutesLate);
            this.form.get('fleetStatusReportUsers').setValue(settings.fleetStatusReportUsers);
            this.form.get('fleetBackupReportUsers').setValue(settings.fleetBackupReportUsers);
            this.form.get('fleetMaintenanceReportUsers').setValue(settings.fleetMaintenanceReportUsers);
            this.form.get('hrNotificationUsers').setValue(settings.hrNotificationUsers);
            this.form.get('hrPtoRequestNotificationUsers').setValue(settings.hrPtoRequestNotificationUsers);
            this.form.get('unresolvedEtdIssuesUsers').setValue(settings.unresolvedEtdIssuesUsers);
            this.form.get('vehicleStatusChangeNotificationUsers').setValue(settings.vehicleStatusChangeNotificationUsers);
            this.form
                .get('vehicleBackup2AssignedAutoChangeNotificationUsers')
                .setValue(settings.vehicleBackup2AssignedAutoChangeNotificationUsers);
        });
    }
}
