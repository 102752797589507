import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DxDataGrid from 'devextreme/ui/data_grid';
import differenceBy from 'lodash-es/differenceBy';
import isString from 'lodash-es/isString';
import moment, { duration } from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap, repeat, takeUntil } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService, MyUserApi, MyUtils, MyUtilsApi } from '../../../../shared/sdk';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent extends ABaseComponent implements OnInit {
  dso: DataSource;
  as: ArrayStore;

  usersDso$: Observable<ArrayStore> = of(new ArrayStore());

  grid_stateStoring: any;
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    private router: Router,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    private userApi: MyUserApi,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7d31c78a-4a45-4b52-8bf2-8081022af7cd',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.usersDso$ = this.userApi.getUsernames().pipe(map(arr => new ArrayStore(arr)));

    this.buildDataSource();
  }

  grid_onInitialized(e) {}

  grid_onToolbarPreparing(e) {}

  grid_onCellPrepared(e) {
    const cellElement: HTMLElement = e.cellElement;
    const component: DxDataGrid = e.component;

    if (e.rowType === 'data') {
      if (e.column.dataField === 'job') {
        const styleFn = () => {
          if (e.data.abort === true) {
            cellElement.style.textDecoration = 'line-through';
            // cellElement.style.color = 'darkgray';
          }
        };

        styleFn();
        e.watch(() => e.data.abort, styleFn);
      }

      //

      if (e.column.dataField === 'ago') {
        const styleFn = () => {
          cellElement.style.backgroundColor = undefined;
          cellElement.style.color = 'black';
          if (duration(moment().diff(moment(e.data.timestamp))).asHours() > 5) {
            cellElement.style.backgroundColor = 'FireBrick';
            cellElement.style.color = 'white';
          } else if (duration(moment().diff(moment(e.data.timestamp))).asHours() > 1) {
            cellElement.style.backgroundColor = 'LightCoral';
          } else if (duration(moment().diff(moment(e.data.timestamp))).asMinutes() > 15) {
            cellElement.style.backgroundColor = 'Khaki';
          }
        };

        styleFn();
        e.watch(() => e.data.agoSec, styleFn);
      }
    }
  }

  grid_onRowRemoving(e: any): void {
    e.cancel = true;
    void this.dss.getApi<MyUtilsApi>(MyUtils).jobsAbort(e.key).toPromise();
  }

  private buildDataSource() {
    this.as = new ArrayStore({
      key: 'key',
      data: [],
    });

    this.dso = new DataSource({
      store: this.as,
      reshapeOnPush: true,
      // postProcess(data: any[]) {
      //   data.forEach(itm => {
      //     itm.datetime = moment(itm.timestamp).toDate();
      //   });
      //   return data;
      // },
    });

    this.dss
      .getApi<MyUtilsApi>(MyUtils)
      .jobsList()
      .pipe(
        map((rows: [string, any, number][]) =>
          rows.map(([key, val, ttl]) => ({
            key,
            job: isString(val) ? val : oc(val).job('UNKNOWN'),
            timestamp: oc(val).timestamp(),
            datetime: oc(val).timestamp() ? moment(val.timestamp).toDate() : undefined,
            ago: oc(val).timestamp() ? duration(moment(val.timestamp).diff(moment())).humanize(true) : undefined,
            agoSec: oc(val).timestamp() ? duration(moment(val.timestamp).diff(moment())).asSeconds() : undefined,
            ttl: duration(ttl).humanize(),
            userId: oc(val).userId() || oc(val).options.accessToken.userId(),
            args: oc(val).args({}),
            abort: oc(val).abort(false),
          })),
        ),
        mergeMap(async (newData: any[]) => {
          const oldData: any[] = await this.as.load();

          const forRemove = differenceBy(oldData, newData, 'key');
          const forAdd = differenceBy(newData, oldData, 'key');

          this.as.push([
            ...forRemove.map(itm => ({ type: 'remove', key: itm.key })),
            ...forAdd.map(itm => ({ type: 'insert', data: itm, key: itm.key })),
            ...newData.map(itm => ({
              type: 'update',
              data: itm,
              key: itm.key,
            })),
          ]);
        }),
        catchError(err => of(null)),
        delay(2000),
        repeat(),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
