<div class="filter-panel">
  <dx-date-box #from width="100px" [(value)]="selectedDate" (onValueChanged)="calendar_onValueChanged($event)">
  </dx-date-box>
</div>

<dx-data-grid
  class="content"
  width="100%"
  [dataSource]="dso"
  [twoWayBindingEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxi-column caption="Alert Time" dataField="createdAt" dataType="date" format="shortDateShortTime"></dxi-column>
  <dxi-column caption="Scheduled Time" dataField="schedule.startTime" dataType="date" format="shortTime"></dxi-column>
  <dxi-column caption="Actual Start Time" dataField="punchInTime" dataType="date" format="shortTime"></dxi-column>
  <dxi-column caption="Minutes Late" [calculateDisplayValue]="calculateTardiness"></dxi-column>
  <dxi-column caption="Employee Name" dataField="driver.fullName"></dxi-column>
  <dxi-column caption="Start Location" dataField="schedule.startLocationAddress"></dxi-column>
  <dxi-column caption="Facility" dataField="tenant.shortname"></dxi-column>
  <dxi-column caption="First Pickup Time" dataField="pickUpTime"></dxi-column>
  <dxi-column caption="First Pickup Address" dataField="pickUpAddress"></dxi-column>
  <dxi-column caption="Delay Time" dataField="late"></dxi-column>
  <dxi-column caption="Sent To" [calculateDisplayValue]="calculateSentTo"></dxi-column>

  <!-- Templates -->
</dx-data-grid>

