/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/import-form/import-form.component.ngfactory";
import * as i3 from "../../components/import-form/import-form.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/common.service";
import * as i6 from "../../../../shared/modules/my-common/services/config.service";
import * as i7 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i8 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i9 from "../../../../shared/sdk/services/custom/MyUtils";
import * as i10 from "../../../../shared/modules/ui/services/push-notifications.service";
import * as i11 from "./import.component";
var styles_ImportComponent = [i0.styles];
var RenderType_ImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportComponent, data: {} });
export { RenderType_ImportComponent as RenderType_ImportComponent };
export function View_ImportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { importForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-import-form", [], null, null, null, i2.View_ImportFormComponent_0, i2.RenderType_ImportFormComponent)), i1.ɵdid(2, 4440064, [[1, 4]], 0, i3.ImportFormComponent, [i4.LoggerService, i5.CommonService, i6.ConfigService, i7.PusherService, i8.UploadHelperService, i9.MyUtilsApi, i10.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-import", [], null, null, null, View_ImportComponent_0, RenderType_ImportComponent)), i1.ɵdid(1, 4308992, null, 0, i11.ImportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportComponentNgFactory = i1.ɵccf("app-import", i11.ImportComponent, View_ImportComponent_Host_0, {}, {}, []);
export { ImportComponentNgFactory as ImportComponentNgFactory };
