<app-button-w-drop-down
  hint="Reassign"
  icon="fas fa-share"
  [processing]="processing"
  [menuItems]="ctxMenuItems"
  (action)="ctx_onAction($event)"></app-button-w-drop-down>

<app-trip-manifest-employee-popup
  [(visible)]="popupVisible"
  (selectionChanged)="popup_onSelectionChanged($event)"
  [gridDataSource]="driversDSO"
  [gridColumns]="gridColumns">
</app-trip-manifest-employee-popup>

<dx-popup
  width="auto"
  height="auto"
  title="Set Trip Number"
  [showTitle]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popup2Visible">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Trip Number</div>
      <div class="dx-field-value">
        <dx-number-box [(value)]="tripNumber"></dx-number-box>
      </div>
    </div>

    <div class="flow">
      <span style="flex: auto"></span>
      <dx-button text="Set" type="default" (onClick)="setTrip(tripNumber)"></dx-button>
    </div>
  </div>
</dx-popup>
