import * as tslib_1 from "tslib";
import { TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import CustomStore from 'devextreme/data/custom_store';
import { intersection, sortBy } from 'lodash-es';
import moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { dxStoreLoadHooks } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { Employee, EmployeeApi } from 'src/app/shared/sdk';
import { getUser } from 'src/app/store/reducers/sign';
import { AnyNote, LoggerService, MyUser, MyUserApi } from '../../../../sdk';
import { CommonService } from '../../../my-common/services/common.service';
import { ConfigService } from '../../../my-common/services/config.service';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { FullNamePipe } from '../../pipes/full-name.pipe';
import { UiService } from '../../services/ui.service';
import { AnyNoteFormComponent } from './any-note-form/any-note-form.component';
export class AnyNotesPageComponent extends ABaseComponent {
    constructor(common, userApi, logger, ui, dss, dialog, config, auth) {
        super(logger);
        this.common = common;
        this.userApi = userApi;
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.config = config;
        this.auth = auth;
        this.extraFields = {};
        this.subTitle = '';
        this.documentName = '';
        this.notificationGroupUserList = '';
        this.tabs = [
            { title: 'Note', template: 'note' },
            { title: 'Comments', template: 'comments' },
            { title: 'Docs', template: 'docs' },
        ];
        this.user = this.auth.getCurrentUserData();
        this.signedUser$ = this.common.store.pipe(select(getUser));
        this.myRoles$ = this.config.roles$;
        this.allowDeleting$ = this.myRoles$.pipe(switchMap(roles => of(!!intersection(roles, ['SU', 'ADMIN']).length)));
    }
    set filter(filter) {
        if (filter)
            this.dso = this.buildDSO(filter);
        this._filter = filter;
    }
    grid_onToolbarPreparing(e) {
        // console.log(e);
        e.toolbarOptions.items.unshift({
            name: 'addNote',
            widget: 'dxButton',
            locateInMenu: 'auto',
            location: 'before',
            sortIndex: 30,
            options: {
                icon: 'plus',
                text: 'Add Note',
                hint: 'Add Note',
                onClick: () => {
                    if (!this._filter)
                        return;
                    this.ui
                        .openEditDialog({
                        modelId: null,
                        title: 'Add Note' + this.subTitle,
                        inputs: {
                            fields: Object.assign({}, this._filter, this.extraFields, { createdById: this.user.id }),
                            templateFormTop: this.templateFormTop,
                            templateFormBottom: this.templateFormBottom,
                            dso: this.dso,
                        },
                        ModelClass: AnyNote,
                        FormComponentClass: AnyNoteFormComponent,
                    })
                        .afterClosed()
                        .toPromise()
                        .then(note => {
                        this.dso = this.buildDSO(this._filter);
                    });
                },
            },
        });
        e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
    }
    grid_onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedNote = e.selectedRowsData[0];
            this.documentName = `${this.noteFormat(this.selectedNote).slice(0, 34)} | document`;
            this.notificationGroupUserList = '';
            if (this.selectedNote.notificationGroupUserIds && this.selectedNote.notificationGroupUserIds.length) {
                const users = yield this.dss
                    .getApi(MyUser)
                    .find({ where: { id: { inq: this.selectedNote.notificationGroupUserIds } } })
                    .toPromise();
                this.notificationGroupUserList = users.map(u => u.username).join(', ');
            }
        });
    }
    noteFormat(note) {
        return (note && `${moment(note.updatedAt).format('M/D/YYYY, h:mm A')} | ${note.subject}`) || '';
    }
    onEditNote() {
        this.ui
            .openEditDialog({
            modelId: this.selectedNote.id,
            title: 'Edit Note' + this.subTitle,
            inputs: {
                fields: Object.assign({}, this._filter, this.extraFields, { createdById: this.user.id }),
                templateFormTop: this.templateFormTop,
                templateFormBottom: this.templateFormBottom,
                dso: this.dso,
            },
            ModelClass: AnyNote,
            FormComponentClass: AnyNoteFormComponent,
        })
            .afterClosed()
            .toPromise()
            .then(note => {
            this.selectedNote = Object.assign({}, this.selectedNote, note);
            this.dso = this.buildDSO(this._filter);
        });
    }
    addComment(comment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!comment.value)
                return;
            const store = this.dss.getStore(AnyNote);
            const c = { createdAt: Date.now(), commentBy: this.user.username, comment: comment.value };
            this.selectedNote.comments = [c, ...(this.selectedNote.comments || [])];
            yield store.update(this.selectedNote.id, { comments: this.selectedNote.comments });
            comment.value = '';
        });
    }
    buildDSO(filter) {
        const so = this.dss.getStoreOptions(AnyNote, null, false);
        so.customFilter = {
            where: filter,
            include: [
                'facility',
                'vehicle',
                'createdBy',
                'relatedNote',
                'resolutionManager',
                'autoRepairShop',
                'assignedFleetManager',
            ],
            order: 'updatedAt DESC',
        };
        const store = new CustomStore(so);
        dxStoreLoadHooks(store, undefined, (args, [notes]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const empls = yield this.dss
                .getApi(Employee)
                .find({ where: { userId: { inq: notes.map(n => n.createdById) } }, include: ['person'] })
                .toPromise();
            const emplMap = empls.reduce((p, v) => (Object.assign({}, p, { [v.userId]: new FullNamePipe(this.config).transform(v) })), {});
            return [notes.map(n => (Object.assign({}, n, { fullName: emplMap[n.createdById] || n.createdBy.username })))];
        }));
        return { store };
    }
}
