import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    return value === true ? 'YES' : value === false ? 'NO' : 'NO';
  }
}
