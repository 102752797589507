<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field">
      <dx-file-uploader>
        <dx-validator name="Document File" #uploadValidator>
          <dxi-validation-rule *ngIf="!model" type="required"></dxi-validation-rule>
          <dxi-validation-rule
            type="custom"
            [reevaluate]="true"
            [validationCallback]="validationCallback"></dxi-validation-rule>
        </dx-validator>
      </dx-file-uploader>
    </div>

    <br/>
    <mat-divider style="margin: 0 -34px"></mat-divider>
    <br/><br/>

    <div class="dx-field">
      <div class="dx-field-label">Document Type</div>
      <div class="dx-field-value" *ngIf="!model; else staticDocTypeBlock">
        <dx-lookup
          #docTypeLookup
          formControlName="documentTypeId"
          [disabled]="!!documentTypeId"
          [dataSource]="documentTypeDataSource"
          [displayExpr]="documentTypeDisplayExpr"
          [searchExpr]="documentTypeSearchExpr"
          [valueExpr]="'id'"
          [grouped]="true"
          (onSelectionChanged)="onDocTypeSelectionChanged($event)">
          <dx-validator name="Document Type">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-lookup>
      </div>
      <ng-template #staticDocTypeBlock>
        <div class="dx-field-value-static">[{{ model?.documentType?.objectFQN }}] {{ model?.documentType?.name }}</div>
      </ng-template>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Object</div>
      <div class="dx-field-value" *ngIf="!model && !objectId; else staticObjectBlock">
        <ng-container [ngSwitch]="form.get('objectType').value">
          <ng-container *ngSwitchCase="'Person'">
            <app-person-drop-down-grid formControlName="objectId"></app-person-drop-down-grid>
          </ng-container>

          <ng-container *ngSwitchCase="'Consumer'">
            <app-consumer-drop-down-grid formControlName="objectId"></app-consumer-drop-down-grid>
          </ng-container>

          <ng-container *ngSwitchCase="'Employee'">
            <app-employee-drop-down-grid formControlName="objectId"></app-employee-drop-down-grid>
          </ng-container>

          <ng-container *ngSwitchCase="'Vehicle'">
            <app-vehicle-drop-down-grid formControlName="objectId"></app-vehicle-drop-down-grid>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <dx-lookup
              #objectLookup
              formControlName="objectId"
              [title]="objectLookupTitle"
              [dataSource]="objectDS"
              [displayExpr]="objectDisplayExpr"
              [searchExpr]="objectSearchExpr"
              valueExpr="id">
              <dx-validator name="Related Object">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-lookup>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #staticObjectBlock>
        <div class="dx-field-value-static">
          <app-object-title #objectTitle
            [modelName]="form?.get('objectType')?.value"
            [modelId]="form?.get('objectId')?.value"></app-object-title>
        </div>
      </ng-template>
    </div>

    <div class="dx-fieldset-header">Document Info</div>

    <div class="dx-field">
      <div class="dx-field-label">Name</div>
      <div class="dx-field-value">
        <dx-text-box autofocus formControlName="name">
          <dx-validator name="Document Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Notes</div>
      <div class="dx-field-value">
        <dx-text-area formControlName="notes"></dx-text-area>
      </div>
    </div>

    <ng-container formArrayName="_cfv">
      <app-custom-fields
        [objectType]="form?.get('objectType')?.value"
        [objectId]="form?.get('objectId')?.value"
        [formArray]="form?.get('_cfv')"
        [customFields]="customFields"
        [customFieldValues]="model?.customFieldValues"></app-custom-fields>
    </ng-container>

    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <!--    <p class="alert-danger" *ngIf="errorMessage">-->
    <!--      {{ errorMessage }}-->
    <!--    </p>-->
  </div>
</dx-validation-group>
