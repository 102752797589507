<app-any-notes-page
  [objectType]="objectType"
  [objectId]="objectId"
  [filter]="filter"
  [extraFields]="extraFields"
  [templateFormTop]="templateFormTop"
  [templateFormBottom]="templateFormBottom"
  [templateNote]="templateNote"
  [subTitle]="subTitle"></app-any-notes-page>

<ng-template #templateNote let-item="item">
  <div class="dx-field">
    <div class="dx-field-label">ID</div>
    <div class="dx-field-value-static">{{ item.vehicle?.internalId || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Note Category</div>
    <div class="dx-field-value-static">{{ item.noteCategory || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Service Type</div>
    <div class="dx-field-value-static">{{ item.serviceType || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Date Off Duty</div>
    <div class="dx-field-value-static">{{ item.dateOffDuty | date : 'longDate' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Date Returned On Duty</div>
    <div class="dx-field-value-static">{{ item.dateReturnedOnDuty | date : 'longDate' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">AutoRepair Shop</div>
    <div class="dx-field-value-static">
      {{ item.autoRepairShop?.name || '' }}
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Assigned Fleet Manager</div>
    <div class="dx-field-value-static">
      {{ item.assignedFleetManager?.person_firstname || '' }} {{ item.assignedFleetManager?.person_lastname || '' }}
    </div>
  </div>
</ng-template>

