
declare var Object: any;
export interface CommonInterface {
  "id"?: string;
}

export class Common implements CommonInterface {
  "id": string;
  constructor(data?: CommonInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Common`.
   */
  public static getModelName() {
    return "Common";
  }

  /**
  * This method creates an instance of Common for dynamic purposes.
  **/
  public static factory(data: CommonInterface): Common{
    return new Common(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Common',
      plural: 'Common',
      path: '/c',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
