<div>
  <dx-data-grid
    #grid
    width="100%"
    height="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso$ | async"
    [errorRowEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [twoWayBindingEnabled]="false"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onCellPrepared)="grid_onCellPrepared($event)">
    <dxo-search-panel [visible]="config.isSU$ | async"></dxo-search-panel>

    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <!-- region Columns -->

    <dxi-column
      caption="Tenant"
      dataField="tenantId"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowSearch]="false">
      <dxo-lookup [dataSource]="facilityDso" displayExpr="shortname" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <!--<dxi-column caption="Person">-->
    <dxi-column
      caption="First Name"
      dataField="person_firstname"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Last Name"
      dataField="person_lastname"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Nickname"
      dataField="person_nickname"
      dataType="string"
      [visible]="false"
      [allowHeaderFiltering]="false"
      [allowSearch]="false"></dxi-column>

    <dxi-column
      caption="Sex"
      dataField="person_sex"
      dataType="string"
      [visible]="false"
      [allowHeaderFiltering]="false"
      [allowSearch]="false">
      <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="DOB"
      dataField="person_dob"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Native Lang"
      dataField="person_data.nativeLang"
      dataType="string"
      [visible]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Native Lang Name"
      dataField="person_data.nativeLangName"
      dataType="string"
      [visible]="false"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"></dxi-column>
    <!--</dxi-column>-->

    <dxi-column caption="Status" dataField="status" dataType="string" [allowHeaderFiltering]="false">
      <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Internal ID"
      dataField="internalID"
      dataType="number"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Facility ID"
      dataField="facilityID"
      dataType="string"
      [visible]="config.get('consumerMoreFields', []).includes('facilityID')"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="MCI" dataField="mci" dataType="string" [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Last Eligibility"
      dataField="lastEligibility"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="MCO" dataField="activeMco" dataType="string" [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="Broker" dataField="activeBroker" dataType="string" [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Default Destination"
      dataField="defaultDestination"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Broker Notes"
      dataField="brokerNotes"
      dataType="string"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Last Attendance Note"
      dataField="lastNote_text"
      dataType="string"
      [calculateDisplayValue]="helper.attendanceNoteDisplayExpr"
      [allowHeaderFiltering]="false"
      [allowSearch]="false"></dxi-column>

    <dxi-column
      caption="Signature"
      dataField="signImgFileId"
      dataType="string"
      cellTemplate="signature_cell"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true">
      <dxo-header-filter [dataSource]="signatureHeaderFilter"></dxo-header-filter>
    </dxi-column>

    <dxi-column
      caption="Special Instructions"
      dataField="specialInstrs"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Transportation Instructions"
      dataField="transpInstrs"
      dataType="string"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Transportation Codes"
      dataField="transpCodes"
      dataType="string"
      cellTemplate="tagBox_cell"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <!--  endregion -->

    <!-- region Templates -->

    <ng-container *dxTemplate="let cellInfo of 'tagBox_cell'">
      <span [title]="cellInfo.value | join: ', '">
        <span class="badge" *ngFor="let item of cellInfo.value">{{ transpCodesMap[item] || item }}</span>
      </span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'signature_cell'">
      <img
        style="max-height: 20px"
        [src]="cellInfo.data?.id | signatureLink: 'CONS':'resize--20/trim' | async | sanitizeUrl" />
    </ng-container>

    <div *dxTemplate="let cons of 'detail'">
      <dx-tab-panel>
        <dxi-item [title]="cons.data.person_firstname + ' ' + cons.data.person_lastname + '\'s Info'">
          <app-consumer-master-details [modelId]="cons.key"></app-consumer-master-details>
        </dxi-item>

        <dxi-item title="Eligibility">
          <ng-container
            *ngTemplateOutlet="
              personEligibility;
              context: { $implicit: consumerEligibility(cons.key) | async }
            "></ng-container>
        </dxi-item>

        <dxi-item title="Active Meals Auths">
          <ng-container
            *ngTemplateOutlet="
              personMealsAuths;
              context: { $implicit: consumerMealsAuths(cons.data) | async }
            "></ng-container>
        </dxi-item>

        <dxi-item title="MTM Trips">
          <dx-data-grid
            width="100%"
            height="100%"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [dataSource]="mtmBrokerTripsDS(cons.data.mci)"
            [twoWayBindingEnabled]="false"
            (onInitialized)="detailGrid_onInitialized($event)">
            <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

            <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              storageKey="83e3e218-c358-48c7-8608-cdc3d60379e7"></dxo-state-storing>

            <dxo-sorting mode="none"></dxo-sorting>

            <!-- region Columns -->

            <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

            <dxi-column
              caption="Date"
              dataField="_date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Trip Number" dataField="_tripId" dataType="string"></dxi-column>

            <dxi-column caption="Trip Type" dataField="Trip Type" dataType="string"></dxi-column>

            <dxi-column caption="Trip Status" dataField="Trip Status" dataType="string"></dxi-column>

            <dxi-column caption="Delivery Name" dataField="Delivery Name" dataType="string"></dxi-column>

            <dxi-column caption="Delivery Address" dataField="Delivery Address" dataType="string"></dxi-column>

            <dxi-column caption="Delivery City" dataField="Delivery City" dataType="string"></dxi-column>

            <dxi-column caption="Delivery State" dataField="Delivery State" dataType="string"></dxi-column>

            <dxi-column caption="Delivery Zip Code" dataField="Delivery Zip Code" dataType="string"></dxi-column>

            <dxi-column
              caption="Delivery Phone Number"
              dataField="Delivery Phone Number"
              dataType="string"></dxi-column>

            <dxi-column caption="Member's Last Name" dataField="Member's Last Name" dataType="string"></dxi-column>

            <dxi-column caption="Member's First Name" dataField="Member's First Name" dataType="string"></dxi-column>

            <dxi-column caption="Medicaid Number" dataField="_mci" dataType="string"></dxi-column>

            <dxi-column
              caption="Member's Date of Birth"
              dataField="Member's Date of Birth"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Special Needs" dataField="Special Needs" dataType="string"></dxi-column>

            <dxi-column caption="Inst / Directions" dataField="Inst / Directions" dataType="string"></dxi-column>

            <dxi-column
              caption="Appointment Date"
              dataField="Appointment Date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column
              caption="Appointment Day of Week"
              dataField="Appointment Day of Week"
              dataType="string"></dxi-column>

            <dxi-column caption="Appointment Time" dataField="Appointment Time" dataType="string"></dxi-column>

            <dxi-column caption="Will Call Flag" dataField="Will Call Flag" dataType="boolean"></dxi-column>

            <dxi-column
              caption="Member's Phone Number"
              dataField="Member's Phone Number"
              dataType="string"></dxi-column>

            <dxi-column caption="Member's Alt Phone" dataField="Member's Alt Phone" dataType="string"></dxi-column>

            <dxi-column caption="Wheelchair Flag" dataField="Wheelchair Flag" dataType="boolean"></dxi-column>

            <dxi-column
              caption="Crutches / Walker / Cane Flag"
              dataField="Crutches / Walker / Cane Flag"
              dataType="boolean"></dxi-column>

            <dxi-column
              caption="Number of Car Seats Required"
              dataField="Number of Car Seats Required"
              dataType="string"></dxi-column>

            <dxi-column caption="Pregnant Flag" dataField="Pregnant Flag" dataType="boolean"></dxi-column>

            <dxi-column
              caption="Number of Additional Passengers"
              dataField="Number of Additional Passengers"
              dataType="number"></dxi-column>

            <dxi-column
              caption="Additional Passengers With Appointments"
              dataField="Additional Passengers With Appointments"
              dataType="number"></dxi-column>

            <dxi-column caption="Trip Mileage" dataField="Trip Mileage" dataType="number"></dxi-column>

            <dxi-column caption="Trip Cost" dataField="Trip Cost" dataType="number" *ngIf="config.isSU$ | async"></dxi-column>

            <dxi-column caption="Pickup Address" dataField="Pickup Address" dataType="string"></dxi-column>

            <dxi-column caption="Pickup City" dataField="Pickup City" dataType="string"></dxi-column>

            <dxi-column caption="Pickup State" dataField="Pickup State" dataType="string"></dxi-column>

            <dxi-column caption="Pickup Zip Code" dataField="Pickup Zip Code" dataType="string"></dxi-column>

            <dxi-column caption="Return Time" dataField="Return Time" dataType="string"></dxi-column>

            <dxi-column caption="Attendant Flag" dataField="Attendant Flag" dataType="string"></dxi-column>

            <dxi-column
              caption="Imported Date"
              dataField="_ctime"
              dataType="date"
              format="shortDateShortTime"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

            <dxi-column
              caption="Updated Date"
              dataField="_rtime"
              dataType="date"
              format="shortDateShortTime"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

            <!-- endregion -->

            <!-- region Templates -->
            <!-- endregion -->
          </dx-data-grid>
        </dxi-item>

        <dxi-item title="CTS Trips">
          <dx-data-grid
            width="100%"
            height="100%"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [dataSource]="ctsBrokerTripsDS(cons.data.mci)"
            [twoWayBindingEnabled]="false"
            (onInitialized)="detailGrid_onInitialized($event)">
            <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

            <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              storageKey="fd26358e-547d-4fd3-a39c-a97d7fdbbc91"></dxo-state-storing>

            <dxo-sorting mode="none"></dxo-sorting>

            <!-- region Columns -->

            <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

            <dxi-column
              caption="Date"
              dataField="_date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Trip Number" dataField="_tripId" dataType="string"></dxi-column>

            <dxi-column caption="Destination Name" dataField="Destination Name" dataType="string"></dxi-column>

            <dxi-column caption="Destination Address" dataField="Destination Address" dataType="string"></dxi-column>

            <dxi-column caption="Destination City" dataField="Destination City" dataType="string"></dxi-column>

            <dxi-column caption="Destination State" dataField="Destination State" dataType="string"></dxi-column>

            <dxi-column caption="Destination Zip Code" dataField="Destination Zip Code" dataType="string"></dxi-column>

            <dxi-column
              caption="Destination Phone Number"
              dataField="Destination Phone Number"
              dataType="string"></dxi-column>

            <dxi-column caption="Member Last Name" dataField="Member Last Name" dataType="string"></dxi-column>

            <dxi-column caption="Member First Name" dataField="Member First Name" dataType="string"></dxi-column>

            <dxi-column caption="Medicaid Number" dataField="_mci" dataType="string"></dxi-column>

            <dxi-column caption="Member Number" dataField="Member Number" dataType="string"></dxi-column>

            <dxi-column
              caption="Member Date of Birth"
              dataField="Member Date of Birth"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Special Needs" dataField="Special Needs" dataType="string"></dxi-column>

            <dxi-column
              caption="Instructions-Directions"
              dataField="Instructions-Directions"
              dataType="string"></dxi-column>

            <dxi-column
              caption="Appointment Date"
              dataField="Appointment Date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Appointment Time" dataField="Appointment Time" dataType="string"></dxi-column>

            <dxi-column caption="Member Phone Number" dataField="Member Phone Number" dataType="string"></dxi-column>

            <!-- -->

            <dxi-column caption="MODE-WC-AMB-STR" dataField="MODE-WC-AMB-STR" dataType="string"></dxi-column>

            <dxi-column
              caption="Number of Car Seats Required"
              dataField="Number of Car Seats Required"
              dataType="string"></dxi-column>

            <dxi-column caption="ESCORT" dataField="ESCORT Y-N" dataType="boolean"></dxi-column>

            <dxi-column caption="Attendant" dataField="Attendant Y-N" dataType="boolean"></dxi-column>

            <dxi-column caption="Trip Mileage" dataField="Trip Mileage" dataType="number"></dxi-column>

            <dxi-column caption="Trip Cost" dataField="Trip Cost" dataType="number" *ngIf="config.isSU$ | async"></dxi-column>

            <dxi-column caption="Inbound-Outbound" dataField="Inbound-Outbound" dataType="string"></dxi-column>

            <!-- -->

            <dxi-column caption="PICKUP LOCATION" dataField="PICKUP LOCATION" dataType="string"></dxi-column>

            <dxi-column caption="Pickup Address" dataField="Pickup Address" dataType="string"></dxi-column>

            <dxi-column caption="Pickup City" dataField="Pickup City" dataType="string"></dxi-column>

            <dxi-column caption="Pickup State" dataField="Pickup State" dataType="string"></dxi-column>

            <dxi-column caption="Pickup Zip Code" dataField="Pickup Zip Code" dataType="string"></dxi-column>

            <dxi-column
              caption="Imported Date"
              dataField="_ctime"
              dataType="date"
              format="shortDateShortTime"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

            <dxi-column
              caption="Updated Date"
              dataField="_rtime"
              dataType="date"
              format="shortDateShortTime"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

            <!--  endregion  -->

            <!-- region Templates -->
            <!-- endregion -->
          </dx-data-grid>
        </dxi-item>

        <dxi-item title="On Schedule">
          <dx-data-grid
            width="100%"
            height="100%"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [dataSource]="consumerManifests(cons.data.id) | async"
            [twoWayBindingEnabled]="false"
            (onInitialized)="detailGrid_onInitialized($event)">
            <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

            <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              storageKey="ef551b6d-a381-4a04-9613-30ed5c8a8b4e"></dxo-state-storing>

            <!--            <dxo-sorting mode="none"></dxo-sorting>-->

            <!-- region Columns -->

            <dxi-column caption="Facility" dataField="tenantId" dataType="string">
              <dxo-lookup [dataSource]="facilityDso" displayExpr="shortname" valueExpr="id"></dxo-lookup>
            </dxi-column>

            <dxi-column
              caption="Date"
              dataField="date"
              dataType="date"
              format="shortDate"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
              [sortIndex]="0"
              sortOrder="desc"></dxi-column>

            <!-- endregion -->

            <!-- region Templates -->
            <!-- endregion -->
          </dx-data-grid>
        </dxi-item>

        <dxi-item title="Attendance (by E-Signature)">
          <dx-data-grid
            width="100%"
            height="100%"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [dataSource]="consumerSignatureStats(cons.data.id) | async"
            [twoWayBindingEnabled]="false"
            (onInitialized)="detailGrid_onInitialized($event)">
            <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

            <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              storageKey="228c8535-f152-436b-b037-f3a6a0ffd826"></dxo-state-storing>

            <!--            <dxo-sorting mode="none"></dxo-sorting>-->

            <!-- region Columns -->

            <dxi-column
              caption="Date"
              dataField="vdate"
              dataType="date"
              format="shortDate"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
              [sortIndex]="0"
              sortOrder="desc"></dxi-column>

            <dxi-column caption="Weekday" dataField="weekday" dataType="string">
              <dxo-lookup [dataSource]="weekdaysDso" displayExpr="Name" valueExpr="ID"></dxo-lookup>
            </dxi-column>

            <dxi-column
              caption="Trip IDs"
              dataField="_trips"
              dataType="string"
              [allowSorting]="false"
              [allowFiltering]="false"
              [allowHeaderFiltering]="false"
              [allowSearch]="false"></dxi-column>

            <!-- endregion -->

            <!-- region Templates -->
            <!-- endregion -->
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
    </div>

    <!-- endregion -->
  </dx-data-grid>
</div>

<!-- Templates -->

<ng-template #personEligibility let-model>
  <dx-data-grid
    width="100%"
    height="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [showBorders]="true"
    [dataSource]="model"
    [twoWayBindingEnabled]="false"
    (onInitialized)="detailGrid_onInitialized($event)">
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="4faca041-1992-487e-be3a-d54e296ea26e"></dxo-state-storing>

    <!--            <dxo-sorting mode="none"></dxo-sorting>-->

    <!-- region Columns -->

    <dxi-column caption="Month" dataField="_month" dataType="string"></dxi-column>

    <dxi-column caption="Month Name" dataField="_monthName" dataType="string"></dxi-column>

    <dxi-column caption="MCO" dataField="mco" dataType="string"></dxi-column>

    <dxi-column caption="Broker" dataField="_broker" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string"></dxi-column>

    <dxi-column caption="Error" dataField="error" dataType="string"></dxi-column>

    <!-- endregion -->

    <!-- region Templates -->
    <!-- endregion -->
  </dx-data-grid>
</ng-template>

<ng-template #personMealsAuths let-model>
  <details style="margin: 0.5em">
    <summary>Member Info</summary>

    <div style="display: flex; flex-direction: row">
      <div class="dx-card">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Navinet Member Info</div>

          <ng-container *ngIf="model?.authClient as member; else noMemberBlock">
            <div class="dx-field">
              <label class="dx-field-label">Member Name:</label>
              <div class="dx-field-value-static">
                {{ member.name?.first }}
                {{ member.name?.last }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member DOB:</label>
              <div class="dx-field-value-static">
                {{ member.dob | utc: 'L' }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member ID:</label>
              <div class="dx-field-value-static">
                {{ member._clientId }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Member Phone:</label>
              <div class="dx-field-value-static">
                {{ member.phones[0] | onlyDigits }}
              </div>
            </div>
          </ng-container>

          <ng-template #noMemberBlock>No Info</ng-template>
        </div>
      </div>

      <div class="dx-card">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">CHC Member Info</div>

          <ng-container *ngIf="model?.chcEligibility as e9y; else noEligibilityBlock">
            <div class="dx-field">
              <label class="dx-field-label">Subscriber Member ID:</label>
              <div class="dx-field-value-static">
                {{ e9y?.subscriber?.memberId }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Subscriber Name:</label>
              <div class="dx-field-value-static">
                {{ e9y?.subscriber?.firstName }}
                {{ e9y?.subscriber?.lastName }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Subscriber Gender:</label>
              <div class="dx-field-value-static">
                {{ e9y?.subscriber?.gender }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Subscriber DOB:</label>
              <div class="dx-field-value-static">
                {{ e9y?.subscriber?.dateOfBirth }}
              </div>
            </div>

            <div class="dx-field">
              <label class="dx-field-label">Subscriber Address:</label>
              <div class="dx-field-value-static">
                {{ e9y?.subscriber?.address?.address1 }}
                {{ e9y?.subscriber?.address?.address2 }}, {{ e9y?.subscriber?.address?.city }},
                {{ e9y?.subscriber?.address?.state }}
                {{ e9y?.subscriber?.address?.postalCode }}
              </div>
            </div>
          </ng-container>

          <ng-template #noEligibilityBlock>No Info</ng-template>
        </div>
      </div>

      <div style="flex: auto"></div>
    </div>
  </details>

  <dx-data-grid
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [showBorders]="true"
    [dataSource]="model?.auths"
    [twoWayBindingEnabled]="false"
    (onInitialized)="detailGrid_onInitialized($event)">
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="c8a185c4-2b6f-4148-9cd9-d8c679d85cdb"></dxo-state-storing>

    <!--            <dxo-sorting mode="none"></dxo-sorting>-->

    <!-- region Columns -->

    <dxi-column caption="Code" dataField="Code" dataType="string"></dxi-column>

    <dxi-column caption="MemberID" dataField="MemberID" dataType="string"></dxi-column>

    <dxi-column caption="MedicaidID" dataField="MedicaidID" dataType="string"></dxi-column>

    <dxi-column caption="Name" dataField="Name" dataType="string"></dxi-column>

    <dxi-column caption="Phone" dataField="Phone" dataType="string"></dxi-column>

    <dxi-column
      caption="MemberDOB"
      dataField="MemberDOB"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="AuthNumberFacets" dataField="AuthNumberFacets" dataType="string"></dxi-column>

    <dxi-column
      caption="StartDT"
      dataField="StartDT"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column
      caption="EndDT"
      dataField="EndDT"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="AuthUnitsApproved" dataField="AuthUnitsApproved" dataType="number"></dxi-column>

    <dxi-column caption="AuthUnitsCompleted" dataField="_estimated.done" dataType="number"></dxi-column>

    <dxi-column caption="Note" dataField="Note" dataType="string"></dxi-column>

    <dxi-column caption="ServiceCoordinatorName" dataField="ServiceCoordinatorName" dataType="string"></dxi-column>

    <dxi-column caption="ServiceCoordinatorPhone" dataField="ServiceCoordinatorPhone" dataType="string"></dxi-column>

    <dxi-column caption="ServiceCoordinatorEmail" dataField="ServiceCoordinatorEmail" dataType="string"></dxi-column>

    <!-- endregion -->

    <!-- region Templates -->
    <!-- endregion -->
  </dx-data-grid>
</ng-template>
