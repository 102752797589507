import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import difference from 'lodash-es/difference';
import head from 'lodash-es/head';
import isEmpty from 'lodash-es/isEmpty';
import { LoggerService } from '../../../../sdk/services/custom';

@Component({
  selector: 'app-drop-down-grid-new',
  templateUrl: './drop-down-grid-new.component.html',
  styleUrls: ['./drop-down-grid-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownGridNewComponent),
      multi: true,
    },
  ],
})
export class DropDownGridNewComponent implements OnInit, ControlValueAccessor {
  @Input() dataSource: any;
  @Input() remoteOperations: any;
  @Input() valueExpr: any;
  @Input() displayExpr: any;
  @Input() placeholder = 'Select...';
  @Input() showClearButton = false;
  @Input() width = 'auto';

  @Input() grid_columns: any;
  @Input() grid_selection: any = { mode: 'single', showCheckBoxesMode: 'none' };

  @Input() popup_width: any;
  @Input() popup_height: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() grid_cellPrepared: EventEmitter<any> = new EventEmitter();

  @ViewChild(DxDropDownBoxComponent, { static: true }) dropDownBox: DxDropDownBoxComponent;
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  opened = false;
  @Input() disabled: boolean;

  _onChange: any;
  _onTouched: any;

  constructor(public logger: LoggerService) {}

  private _value: any;
  get value(): any {
    return this.dropDownBox_value;
  }

  @Input()
  set value(value: any) {
    setTimeout(() => {
      this.dropDownBox_value = value;
    });
  }

  get dropDownBox_value(): number {
    return this._value;
  }

  set dropDownBox_value(value: number) {
    setTimeout(() => {
      if (value !== this._value) {
        this._value = value;
        this._grid_selectedRowKeys = value ? [value] : [];
      }
    });
  }

  private _grid_selectedRowKeys: number[] = [];

  get grid_selectedRowKeys(): number[] {
    return this._grid_selectedRowKeys;
  }

  set grid_selectedRowKeys(keys: number[]) {
    setTimeout(() => {
      if (difference(keys, this._grid_selectedRowKeys).length > 0) {
        this._grid_selectedRowKeys = keys;
        this._value = isEmpty(keys) ? null : head(keys);
      }
    });
  }

  ngOnInit(): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.dropDownBox.disabled = isDisabled;
  }

  grid_onSelectionChanged(e) {
    setTimeout(() => {
      this.opened = false;
    });
  }

  dropDownBox_valueChange(e) {
    setTimeout(() => {
      this.valueChange.emit(e);
    });
  }

  dropDownBox_onValueChanged(e) {
    setTimeout(() => {
      this.valueChanged.emit(e);
      this._onChange && this._onChange(e.value);
    });
  }

  dropDownBox_onFocusOut(e) {
    this._onTouched && this._onTouched();
  }
}
