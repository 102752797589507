/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-edit-validation-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dlg-edit-validation-state.component";
var styles_DlgEditValidationStateComponent = [i0.styles];
var RenderType_DlgEditValidationStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgEditValidationStateComponent, data: {} });
export { RenderType_DlgEditValidationStateComponent as RenderType_DlgEditValidationStateComponent };
export function View_DlgEditValidationStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dlg-edit-validation-state works!"]))], null, null); }
export function View_DlgEditValidationStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-edit-validation-state", [], null, null, null, View_DlgEditValidationStateComponent_0, RenderType_DlgEditValidationStateComponent)), i1.ɵdid(1, 114688, null, 0, i2.DlgEditValidationStateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgEditValidationStateComponentNgFactory = i1.ɵccf("app-dlg-edit-validation-state", i2.DlgEditValidationStateComponent, View_DlgEditValidationStateComponent_Host_0, {}, {}, []);
export { DlgEditValidationStateComponentNgFactory as DlgEditValidationStateComponentNgFactory };
