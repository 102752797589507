import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import DxDataGrid from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { AutoDispatchLog, AutoDispatchLogApi, LoggerService, LoopBackAuth, MyUser } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';

@Component({
  selector: 'app-auto-dispatch-grid',
  templateUrl: './auto-dispatch-grid.component.html',
  styleUrls: ['./auto-dispatch-grid.component.scss'],
})
export class AutoDispatchGridComponent implements OnInit, OnDestroy {
  markerBaseUrl = '/assets/images/';
  dso: DataSourceOptions;
  grid_stateStoring: any;
  user: MyUser;
  modifiedSubscription: Subscription;

  @Output() mySelected: EventEmitter<AutoDispatchLog[]> = new EventEmitter<AutoDispatchLog[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    this.user = this.auth.getCurrentUserData();
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '439b7e1f-9720-46cc-9083-b9a3108ff296',
    };

    this.dso = this.buildDso();
  }

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([AutoDispatchLog.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });

    (e.component as DxDataGrid).deselectAll();
    this.mySelected.emit([]);
  }

  grid_onSelectionChanged(event: any): void {
    const items = (event.component as DxDataGrid).getDataSource().items();

    if (items.find(i => event.currentSelectedRowKeys.includes(i.id))) {
      this.mySelected.emit(event.selectedRowsData);
    }
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data') {
      e.items = [
        {
          text: `Reprocess the file for ${moment(e.row.data.incomingAt).format('MM/DD/YYYY h:mm A')}`,
          onItemClick: async () => {
            await this.dss
              .getApi<AutoDispatchLogApi>(AutoDispatchLog)
              .reprocessEmailAsync(e.row.data.historyId)
              .toPromise();
          },
        },
      ];
    }
  }

  getDaysLoad = ({ daysLoadMap: dlm }: any): string =>
    dlm &&
    Object.keys(dlm)
      .map(k => `${k.slice(5).replace('-', '/')}=[${dlm[k].vehicles}Vehs, ${dlm[k].averageLoad}%]`)
      .join(', ');

  buildDso() {
    const so = this.dss.getStoreOptions(AutoDispatchLog, null, false);
    so.customFilter = {
      order: 'incomingAt DESC, replyAt DESC',
    };
    const store = new CustomStore(so);

    return { store } as DataSourceOptions;
  }
}

