<dx-data-grid
  #grid
  width="100%"
  height="100%"
  [dataSource]="dso"
  [stateStoring]="grid_stateStoring"
  [searchPanel]="{ visible: false }"
  [headerFilter]="{ visible: true }"
  [filterRow]="{ visible: true }"
  [filterPanel]="{ visible: true }"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

  <dxo-export [enabled]="true" fileName="Multi assigned trip ids"></dxo-export>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

  <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

  <dxo-load-panel [shading]="true"></dxo-load-panel>

  <!-- region Columns -->

  <dxi-column caption="trip id" dataField="_trip._tripId" dataType="string"></dxi-column>

  <dxi-column
    caption="auth service date"
    dataField="_trip._date"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [customizeText]="config.customizeShortDateText"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="auth type" dataField="_trip['Trip Type']" dataType="string"></dxi-column>

  <dxi-column caption="auth status" dataField="_trip['Trip Status']" dataType="string"></dxi-column>

  <dxi-column caption="created" dataField="_trip._ctime" dataType="datetime" format="shortDateShortTime"></dxi-column>

  <dxi-column caption="replaced" dataField="_trip._rtime" dataType="datetime" format="shortDateShortTime"></dxi-column>

  <!--  -->

  <dxi-column
    caption="claim date"
    dataField="_claim._date"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [customizeText]="config.customizeShortDateText"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="claim status" dataField="_claim.STATUS" dataType="string"></dxi-column>

  <dxi-column caption="claim member" dataField="_claim.MEMBERNAME" dataType="string"></dxi-column>

  <!--  -->
  <dxi-column
    caption="signature date"
    dataField="_sign.vdate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [customizeText]="config.customizeShortDateText"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="signature service type" dataField="_sign.vServiceType" dataType="string"></dxi-column>

  <dxi-column caption="signature marker" dataField="_sign.marker" dataType="string"></dxi-column>

  <dxi-column caption="signature status" dataField="_sign.status" dataType="string"></dxi-column>

  <dxi-column caption="signature validation state" dataField="_sign.validationState" dataType="string"></dxi-column>

  <!-- endregion Columns -->

  <!-- region Templates -->

  <div *dxTemplate="let info of 'detail'">
    <dx-tab-panel>
      <dxi-item title="Trip">
        <pre>{{ safeGetSource(info.data._trip) | filterJson | json }}</pre>
      </dxi-item>

      <dxi-item title="Claim">
        <pre>{{ safeGetSource(info.data._claim) | filterJson | json }}</pre>
      </dxi-item>

      <dxi-item title="Signatures">
        <dx-data-grid [dataSource]="info.data._signs">
          <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

          <dxi-column dataField="tenantId">
            <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"></dxo-lookup>
          </dxi-column>

          <dxi-column dataField="vdate" dataType="string"></dxi-column>
          <dxi-column dataField="vServiceType" dataType="string"></dxi-column>
          <dxi-column dataField="vTripId" dataType="string"></dxi-column>
          <dxi-column dataField="validationState" dataType="string"></dxi-column>

          <dxi-column dataField="consumer_person_firstname" dataType="string"></dxi-column>
          <dxi-column dataField="consumer_person_lastname" dataType="string"></dxi-column>

          <dxi-column dataField="employee_person_firstname" dataType="string"></dxi-column>
          <dxi-column dataField="employee_person_lastname" dataType="string"></dxi-column>

          <dxi-column dataField="vehicleId">
            <dxo-lookup [dataSource]="vehicleDso$ | async" valueExpr="id" displayExpr="internalId"></dxo-lookup>
          </dxi-column>

          <dxi-column dataField="meta.appName" dataType="string"></dxi-column>

          <dxi-column dataField="scheduledTime" dataType="string"></dxi-column>
          <dxi-column dataField="pickupTime" dataType="string"></dxi-column>
          <dxi-column dataField="dropoffTime" dataType="string"></dxi-column>

          <dxi-column dataField="dateTime"></dxi-column>
          <dxi-column dataField="marker" dataType="string"></dxi-column>
          <dxi-column dataField="type" dataType="string"></dxi-column>
          <dxi-column dataField="captured" dataType="string"></dxi-column>
          <dxi-column dataField="status" dataType="string"></dxi-column>
        </dx-data-grid>
      </dxi-item>
    </dx-tab-panel>
  </div>

  <!-- endregion Templates -->
</dx-data-grid>
