import Cropper from 'cropperjs';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LoggerService, Person, PersonalPhoto, PersonalPhotoApi } from '../../../../shared/sdk';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadHelperService } from 'src/app/shared/modules/ui/services/upload-helper.service';
import { DxFileUploaderComponent, DxValidatorComponent } from 'devextreme-angular';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';

@Component({
  selector: 'app-personal-photo-cropper',
  templateUrl: './personal-photo-cropper.component.html',
  styleUrls: ['./personal-photo-cropper.component.scss'],
})
export class PersonalPhotoCropperComponent implements OnInit {
  cropper!: Cropper;
  imageLoaded = false;
  sanitizedUrl: any;
  orgFile: File | null = null;
  uploadingFiles: any = {};
  uploadedFiles: any = {};

  @ViewChild(DxFileUploaderComponent, /* TODO: check static flag */ { static: false })
  uploader: DxFileUploaderComponent;
  @ViewChild('uploadValidator', /* TODO: check static flag */ { static: false }) uploadValidator: DxValidatorComponent;

  validationCallback = this.uploadHelper.validationCallback;
  constructor(
    public dialogRef: MatDialogRef<PersonalPhotoCropperComponent>,
    protected uploadHelper: UploadHelperService,
    private dss: DataSourceService,
    @Inject(MAT_DIALOG_DATA) public person: Person,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    // this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.image);
  }

  ngAfterViewInit() {
    // this.initCropper();
    this.uploadHelper.handle(
      this.uploader.instance,
      {
        folder: 'pictures',
        onUploaded: async e => {
          if (this.uploader.value.every(file => file.uploaded)) {
            const data = this.uploader.value
              .map(file => file.uploaded)
              .reduce((p, f) => ({ ...p, [f.originalFilename.split('_')[1]]: f }), { personId: this.person.id });
            // this.uploadHelper.getDownloadUrl(d.files_0_container, d.files_0_filename);
            const personalPhoto = (await this.dss
              .getApi<PersonalPhotoApi>(PersonalPhoto)
              .upsertWithWhere({ personId: this.person.id }, data)
              .toPromise()) as PersonalPhoto;
            this.person.personalPhoto = personalPhoto;
            this.dialogRef.close(personalPhoto);
          }
          // this.form.markAsDirty();
        },
      },
      this.uploadValidator && this.uploadValidator.instance,
    );
  }

  fileName = (type: string, ext: string) =>
    `${this.person.id}_${type}_${this.person.firstname}_${this.person.lastname}.${ext}`.replace(/[^\w_\.]/g, '_');

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const ext = files[0].name.split('.').pop();
      this.orgFile = new File([files[0]], this.fileName('org', ext), { type: files[0].type });
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.orgFile));
      this.resetInput();
    }
  }

  resetInput() {
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onImageLoad(event: any) {
    this.imageLoaded = true;
    this.initCropper();
    console.log('image loaded');
  }

  initCropper() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    const image = document.getElementById('image') as HTMLImageElement;
    image.crossOrigin = 'anonymous';
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      checkCrossOrigin: false,
      guides: false,
    });
  }

  // make the crop box rounded

  getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas');
    var context: any = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  //get the cropped image and closes the dialog
  //returning an url or null if no image

  async crop() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    let roundedImage = document.createElement('img');

    if (roundedImage) {
      const avatarFile = await new Promise((resolve, reject) => {
        roundedCanvas.toBlob((blob: Blob) => {
          const f = new File([blob], this.fileName('avatar', 'png'), {
            type: 'image/png',
          });
          resolve(f);
        }, 'image/png');
      });

      this.uploader.value = [...(this.orgFile ? [this.orgFile] : []), avatarFile];
      this.uploader.instance.upload();

      // const file = URL.createObjectURL(roundedCanvas.toBlob((blob: any) => blob, 'image/png'));
    } else {
      return this.dialogRef.close(null);
    }
  }

  // resets the cropper
  reset() {
    this.cropper.clear();
    this.cropper.crop();
  }
}

