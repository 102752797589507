import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import notify from 'devextreme/ui/notify';
import { intersection } from 'lodash-es';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { environment } from '../../../../../environments/environment';
import { LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';

@Component({
  selector: 'app-adc-meals-stats',
  templateUrl: './adc-meals-stats.component.html',
  styleUrls: ['./adc-meals-stats.component.scss'],
})
export class AdcMealsStatsComponent extends ABaseComponent implements OnInit {
  selectedDateValue?: Date = moment().toDate();
  selectedMonthValue?: Date = moment().toDate();
  selectedFromValue?: Date = moment().startOf('month').toDate();
  selectedToValue?: Date = moment().toDate();
  maxToValue?: Date = moment(this.selectedFromValue).add(1, 'week').toDate();

  adcMealsProgress = false;
  adcMealsCountProgress = false;

  @ViewChild('date', { static: true }) dateBox: DxDateBoxComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    private dss: DataSourceService,
    private pusher: PusherService,
    public config: ConfigService,
  ) {
    super(logger);
  }

  intervalChanged(e1, e2) {
    if (this.selectedFromValue) this.maxToValue = moment(this.selectedFromValue).add(1, 'week').toDate();
  }

  mealsReportEnabled(...reportIds: string[]) {
    return intersection(oc(reportIds)([]), oc(this.config).config.adcMealsReportingMode([])).length > 0;
  }

  getDailyCacfpPdf(e: any): void {
    // const _from = this.selectedFromValue;
    // const _to = this.selectedToValue;

    const _date = this.selectedDateValue;

    const fromInclMoment = _date && moment(_date);
    const toExclMoment = _date && moment(_date).add(1, 'days');

    const fromIncl = fromInclMoment && fromInclMoment.format('YYYY-MM-DD');
    const toExcl = toExclMoment && toExclMoment.format('YYYY-MM-DD');

    of(true)
      .pipe(
        tap(() => (this.adcMealsProgress = true)),

        switchMap(() =>
          this.pusher.rpc(
            'ADC_MEALS_REQ',
            {
              from: fromIncl,
              to: toExcl,
              settings: {},
              useRunService: true,
            },
            false,
          ),
        ),
        tap(({ uri }) => window.open(uri)),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.adcMealsProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  getMonthlyMealsCount(e: any): void {
    // const _from = this.selectedFromValue;
    // const _to = this.selectedToValue;

    const _date = this.selectedMonthValue;

    of(true)
      .pipe(
        tap(() => (this.adcMealsCountProgress = true)),

        switchMap(() =>
          this.pusher.rpc(
            'ADC_MEALS_COUNT_GRID_REQ',
            {
              date: moment(_date).format('YYYY-MM-DD'),
              useRunService: true,
            },
            false,
          ),
        ),
        tap(({ uri }) => window.open(uri)),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.adcMealsCountProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  getMonthlyMealsCount2(e: any): void {
    const _date = this.selectedMonthValue;

    of(true)
      .pipe(
        tap(() => (this.adcMealsCountProgress = true)),

        switchMap(() =>
          this.pusher.rpc(
            'ADC_MEALS_COUNT_GRID_V2_REQ',
            {
              date: moment(_date).format('YYYY-MM-DD'),
              useRunService: true,
            },
            false,
          ),
        ),
        tap(({ uri }) => window.open(uri)),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.adcMealsCountProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  getMonthlyMealsCount3(e: any): void {
    const _date = this.selectedMonthValue;

    of(true)
      .pipe(
        tap(() => (this.adcMealsCountProgress = true)),

        switchMap(() =>
          this.pusher.rpc(
            'ADC_MEALS_COUNT_GRID_V3_REQ',
            {
              date: moment(_date).format('YYYY-MM-DD'),
              useRunService: true,
            },
            false,
          ),
        ),
        tap(({ uri }) => window.open(uri)),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.adcMealsCountProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
