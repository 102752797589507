<app-content-w-header header="Generate Invoice">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="display: flex">
      <dx-select-box
        label="Week"
        labelMode="floating"
        [dataSource]="weekDs$ | async"
        [placeholder]="dsLoading ? 'Loading...' : 'Select week...'"
        [disabled]="dsLoading"
        valueExpr="v"
        displayExpr="t"
        [(value)]="selectedWeekValue"
      ></dx-select-box>

      <div style="display: inline-block">
        <dx-button
          style="text-align: left"
          type="default"
          text="Generate"
          [icon]="generating ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="generating"
          (onClick)="generate()"
        ></dx-button>
      </div>
    </div>
  </mat-card>

  <div class="content" width="100%" height="100%"></div>
</app-content-w-header>
