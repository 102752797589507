<dx-button
  [id]="target"
  [hint]="hint"
  [text]="text"
  [icon]="!processing ? icon : 'fas fa-circle-notch fa-spin'"
  [disabled]="processing"></dx-button>

<dx-context-menu
  [items]="menuItems"
  [showEvent]="'dxclick'"
  [target]="'#' + target"
  [position]="{ at: 'left bottom', my: 'left top', offset: '0 3', collision: 'fit' }"
  (onItemClick)="ctx_onItemClick($event)"></dx-context-menu>
