<dx-data-grid
  #grid
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="employees-ext-export"></dxo-export>

  <!-- Columns -->

  <!--<dxi-column caption="Person">-->

  <dxi-column caption="First Name" dataField="person.firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string"></dxi-column>

  <dxi-column caption="Sex" dataField="person.sex" dataType="string">
    <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Date Of Birth" dataField="person.dob" dataType="date" format="shortDate"></dxi-column>

  <dxi-column caption="Email" dataType="string" [calculateCellValue]="getEmailCellValue"></dxi-column>

  <dxi-column caption="Phone" dataType="string" [calculateCellValue]="getPhoneCellValue"></dxi-column>

  <!--</dxi-column>-->

  <dxi-column caption="Status" dataField="status" dataType="string">
    <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string">
    <dxo-lookup [dataSource]="types" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Position" dataField="employeePosition.name" dataType="string"></dxi-column>

  <dxi-column caption="Last Date On Duty" dataField="last.date" dataType="date"></dxi-column>

  <dxi-column caption="Last Vehicle Used" dataField="last.vehicle.internalId" dataType="number"></dxi-column>

  <dxi-column caption="Last Facility" dataField="lastTenant.shortname" dataType="string"></dxi-column>

  <dxi-column caption="DL Exp Date" dataField="dlExp" dataType="date"></dxi-column>

  <dxi-column caption="CPR Card Exp Date" dataField="cprExp" dataType="date"></dxi-column>

  <dxi-column caption="Medical Card Exp Date" dataField="mecExp" dataType="date"></dxi-column>

  <dxi-column caption="Compliance Events last 90 days" dataField="compliance" dataType="number"></dxi-column>

  <dxi-column caption="Safety Events last 90 days" dataField="safety" dataType="number"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit" [visible]="isEditDeleteVisible"></dxi-button>
    <dxi-button name="delete" [visible]="isEditDeleteVisible"></dxi-button>
  </dxi-column>

  <!-- Templates -->
</dx-data-grid>

