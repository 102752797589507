<div class="filter-panel">
  <div class="dx-field">
    <div class="dx-field-label">Filter</div>
    <div class="dx-field-value">
      <dx-select-box
        width="200px"
        [dataSource]="filterDso"
        displayExpr="title"
        valueExpr="value"
        [(value)]="filter"
        [showClearButton]="true"
        (onSelectionChanged)="expiring_onSelectionChanged($event)">
      </dx-select-box>
    </div>
  </div>
</div>

<dx-data-grid
  #grid
  [dataSource]="dso"
  [stateStoring]="grid_stateStoring"
  [columns]="gridColumns"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  (onInitialized)="grid_onInitialized($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="vehicles-ext-export"></dxo-export>

  <!-- Columns -->

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'add_cell'">
    <button
      class="btn dx-toolbar-button"
      type="button"
      matTooltip="Add Document"
      (click)="addDoc_onClick(cellInfo.data, null)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        class="bi bi-file-earmark-plus"
        viewBox="0 0 16 16">
        <path
          d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
        <path
          d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
      </svg>
    </button>
    <button
      class="btn dx-toolbar-button"
      type="button"
      matTooltip="Related Documents"
      (click)="relatedDocs_onClick(cellInfo.data)">
      <i class="dx-icon-fields"></i>
    </button>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'exp_cell'">
    <button
      *ngIf="!cellInfo.value || !(cellInfo.value.value || cellInfo.value.name)"
      class="btn small"
      (click)="addDoc_onClick(cellInfo.data, cellInfo.column)">
      +
    </button>
    <div
      [class]="
        ((!cellInfo.value || !(cellInfo.value.value || cellInfo.value.name)) &&
          ((filter && filter[2] === 'missing' && isAlerted(cellInfo) && 'red') || 'gray')) ||
        ''
      ">
      <a
        *ngIf="cellInfo.value && (cellInfo.value.value || cellInfo.value.name)"
        target="_blank"
        [class]="'show-doc-link ' + (isAlerted(cellInfo) ? 'yellow' : '')"
        [href]="cellInfo.value.url"
        >{{ cellInfo.value.name || dateFormat(cellInfo.value.value) }}
      </a>
      &nbsp;
    </div>
  </ng-container>
</dx-data-grid>
