import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  mode: any;

  constructor(private route: ActivatedRoute) {
    this.mode = route.snapshot.data.mode;
  }

  ngOnInit() {}
}
