import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import getCenterOfBounds from 'geolib/es/getCenterOfBounds';
import isValidCoordinate from 'geolib/es/isValidCoordinate';
import { compact, flatten, flattenDeep, isEmpty, sortBy } from 'lodash-es';
import mapKeys from 'lodash-es/mapKeys';
import moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService, MyUserApi, MyUtilsApi } from '../../../../shared/sdk';
import { getUser } from '../../../../store/reducers/sign';
import { HelperService } from '../../../consumer/services/helper.service';

type IWithActionObject = any;

@Component({
  selector: 'app-trip-trace',
  templateUrl: './trip-trace.component.html',
  styleUrls: ['./trip-trace.component.scss'],
})
export class TripTraceComponent extends ABaseComponent implements OnInit, AfterViewInit {
  selectedDate?: Date = moment().toDate();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);

  markerBaseUrl = '/assets/images/';
  mapCenters: any = {};
  markers: any = {};
  routes: any = {};
  private map: any;

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'bd08a005-be12-4cb8-94db-be8bdc31f518',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const col = '_TestMtmTripTrace';
    const aggregate = [
      {
        $match: {
          date: moment(this.selectedDate).format('YYYY-MM-DD'),
        },
      },
    ];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // filter: ['_broker', '=', 'MTM'],
      // sort: [{ selector: '_date', desc: true }],
      map(doc: any) {
        self.buildMarkers(doc);
        return doc;
      },
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildMarkers(doc: IWithActionObject, options = { trips: true, geo: true }) {
    const isPU = doc.action === 'pu';
    const isDO = doc.action === 'do';

    const locations = oc(doc).__action.locations();
    const [apiLoc, logLoc, tabLoc] = [
      {
        ...oc(locations).api.location(),
        timeCollected: oc(locations).api.timestamp(),
      },

      {
        ...oc(locations).log.location(),
        timeCollected: oc(locations).log.timestamp(),
      },
      {
        ...oc(locations).tab.location(),
        timeCollected: oc(locations).tab.timestamp(),
      },
    ].map(l => this.normalizeLocation(l));

    const [tApiLoc, tLogLoc, tStopLoc] = [
      oc(doc).__trip.__action.apiRec(),
      oc(doc).__trip.__action.logRec(),
      oc(doc).__trip.__action.gtStop.data(),
    ].map(l => this.normalizeLocation(l));

    const _markers = [
      {
        iconSrc: this.markerBaseUrl + 'marker-scheduled.png',
        location: oc(doc).__action.location(),
        tooltip: {
          isShown: true,
          text: this.objToHtml({
            '': 'Scheduled',
            Time: oc(doc).sign.scheduledTime(),
            Type: oc(doc).sign.vServiceType(),
            Marker: oc(doc).sign.marker(),
            Address: oc(doc).__action.formatted('-'),
          }),
        },
      },

      ...(options.trips
        ? flattenDeep(
            oc(doc)
              .trips([])
              .map(t => {
                const textData1 = {
                  'MTM TripID': t._tripId,
                  Time: moment(t._time, 'HHmm').format('HH:mm'),
                  Type: t['Trip Type'],
                  Address: oc(t).__action.formatted('-'),
                };

                const textData2 = { '': 'From TripID', ...textData1 };

                return compact([
                  {
                    iconSrc:
                      this.markerBaseUrl +
                      (oc(doc).__trip._id() === t._id ? 'marker-mtm2.png' : 'marker-mtm2-gray.png'),
                    location: oc(t).__action.location(),
                    tooltip: {
                      isShown: true,
                      text: this.objToHtml(textData1),
                    },
                  },
                  //
                  (isPU && oc(t).pickLongitude()) || (isDO && oc(t).dropLongitude())
                    ? {
                        iconSrc:
                          this.markerBaseUrl +
                          (oc(doc).__trip._id() === t._id ? 'marker-mtm.png' : 'marker-mtm-gray.png'),
                        location: isPU
                          ? {
                              lng: oc(t).pickLongitude(),
                              lat: oc(t).pickLatitude(),
                            }
                          : isDO
                          ? {
                              lng: oc(t).dropLongitude(),
                              lat: oc(t).dropLatitude(),
                            }
                          : undefined,
                        tooltip: {
                          isShown: true,
                          text: this.objToHtml(textData2),
                        },
                      }
                    : undefined,
                ]);
              }),
          )
        : []),

      ...(options.geo
        ? [
            {
              iconSrc: this.markerBaseUrl + 'marker-pin-api.png',
              location: tApiLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From GeoTab Api',
                  'Collected at': this.toLocalTime(oc(tApiLoc).timestamp()),
                }),
              },
            },

            {
              iconSrc: this.markerBaseUrl + 'marker-pin-log.png',
              location: tLogLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From GeoTab Log',
                  'Collected at': this.toLocalTime(oc(tLogLoc).timestamp()),
                }),
              },
            },

            {
              iconSrc: this.markerBaseUrl + 'marker-stop.png',
              location: tStopLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From GeoTab Stop',
                  'Collected at': this.toLocalTime(oc(tStopLoc).timestamp()),
                }),
              },
            },

            {
              iconSrc: this.markerBaseUrl + 'marker-pin-tab.png',
              location: tabLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From Driver Tablet',
                  'Collected at': this.toLocalTime(oc(tabLoc).timestamp()),
                  'Distance from TrID (m)': oc(doc).__trip.__action.diff.tab(),
                }),
              },
            },

            {
              iconSrc: this.markerBaseUrl + 'marker-pin-api.png',
              location: apiLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From GeoTab Api',
                  'Collected at': this.toLocalTime(oc(apiLoc).timestamp()),
                  'Distance from TrID (m)': oc(doc).__trip.__action.diff.api(),
                }),
              },
            },

            {
              iconSrc: this.markerBaseUrl + 'marker-pin-log.png',
              location: logLoc,
              tooltip: {
                isShown: true,
                text: this.objToHtml({
                  '': 'From GeoTab Log',
                  'Collected at': this.toLocalTime(oc(logLoc).timestamp()),
                  // 'Collected at': moment
                  //   .tz(oc(logLoc).dateTime(), this.config.get('timezone'))
                  //   .add(oc(logLoc).duration(0), 'seconds')
                  //   .format('HH:mm:ss'),
                  // Duration: `${(oc(logLoc).duration(0) / 60).toFixed(2)} <small>min since</small> ${moment
                  //   .tz(oc(logLoc).dateTime(), this.config.get('timezone'))
                  //   .format('HH:mm:ss')}`,
                  'Distance from TrID loc (m)': oc(doc).__trip.__action.diff.log(),
                }),
              },
            },
          ]
        : []),
    ].filter((m: any) => isValidCoordinate(oc(m).location({})));

    const _routes = flatten(
      (oc(doc).trips([]) as any[]).map(t => {
        const routes = [
          {
            locations: sortBy([tApiLoc, tStopLoc, apiLoc, tLogLoc, logLoc, tabLoc], 'timestamp'),
          },
        ];

        routes.forEach(route => {
          route.locations = route.locations.filter(loc => loc && isValidCoordinate(loc));
        });

        return routes;
      }),
    ).filter(r => r.locations.length >= 2);

    this.markers[doc._id] = _markers;
    this.routes[doc._id] = _routes;
    this.mapCenters[doc._id] =
      _markers.length > 0
        ? mapKeys(
            getCenterOfBounds(_markers.map((m: any) => m.location)),
            (v, k) => ({ latitude: 'lat', longitude: 'lng' }[k]),
          )
        : undefined;
  }

  buildPoly(doc: any) {
    if (doc.adcZone) {
      // @ts-ignore
      const adcZone = new google.maps.Polygon({
        paths: (doc.adcZone.points as { x; y }[]).map(o => ({ lng: o.x, lat: o.y })),
        strokeColor: '#ff0000',
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      });

      adcZone.setMap(this.map);
    }
  }

  private normalizeLocation(loc?) {
    const dateTime =
      oc(loc).timeCollected() ||
      oc(loc).__dateTime() ||
      oc(loc)._dateTime() ||
      oc(loc).dateTime() ||
      oc(loc).timestamp();

    const obj = loc
      ? {
          lat: oc(loc).latitude() || loc.lat,
          lng: oc(loc).longitude() || loc.lng,
          timestamp: moment(dateTime).isValid() ? moment(dateTime).toDate() : undefined,
        }
      : undefined;

    return obj && isValidCoordinate(obj) ? obj : undefined;
  }

  private objToHtml(obj) {
    return Object.entries(obj)
      .map(([k, v]) => (isEmpty(k) ? `<small>${v}</small>` : `<small>${k}:</small> ${v}`))
      .join('<br>');
  }

  private toLocalTime(dateTime) {
    return dateTime && moment(dateTime).isValid()
      ? moment.tz(dateTime, this.config.get('timezone')).format('HH:mm:ss')
      : '';
  }

  onDateValueChanged(e) {
    this.dso = this.buildDataSource();
  }

  map_onInitialized(e, doc) {
    console.log(e);
  }

  map_onReady(e, doc) {
    console.log(e);
    this.map = e.originalMap;
    this.buildPoly(doc);
  }

  map_onDisposing(e, doc) {
    console.log(e);
  }

  map_wheel(e) {
    // console.log(e);
    // e.preventDefault();
    e.stopPropagation();
  }

  mapChckTrips_onChange(doc) {
    const self = this;
    return e => {
      self.buildMarkers(doc);
    };
  }

  mapChckGeo_onChange(doc) {
    const self = this;
    return e => {
      self.buildMarkers(doc);
    };
  }

  mapMarkersRebuild_click(doc) {
    this.buildMarkers(doc);
  }
}
