<dx-popup
  #employeePopup
  [showTitle]="true"
  [title]="title"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="visible"
  (onShowing)="popup_onShowing($event)"
  (onShown)="popup_onShown($event)"
  (onHidden)="popup_onHidden($event)">
  <div *dxTemplate="let data of 'content'">
    <ng-container *ngTemplateOutlet="popupContent"></ng-container>
  </div>
</dx-popup>

<ng-template #popupContent>
  <ng-container *ngIf="gridEnabled">
    <dx-data-grid
      #employeeGrid
      [dataSource]="gridDataSource"
      [columns]="gridColumns"
      [height]="'100%'"
      [columnChooser]="{ enabled: false }"
      [searchPanel]="{ visible: false }"
      [hoverStateEnabled]="true"
      [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
      [pager]="{ visible: false }"
      [selection]="gridSelection"
      [paging]="gridPaging"
      [filterRow]="gridFilterRow"
      [scrolling]="gridScrolling"
      (onSelectionChanged)="grid_onSelectionChanged($event)"></dx-data-grid>
  </ng-container>
</ng-template>
