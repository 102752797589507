/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/alert-list/alert-list.component.ngfactory";
import * as i3 from "../../components/alert-list/alert-list.component";
import * as i4 from "../../../../shared/modules/my-common/services/common.service";
import * as i5 from "../../../../shared/sdk/services/custom/Alert";
import * as i6 from "./alert.component";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-list", [], null, null, null, i2.View_AlertListComponent_0, i2.RenderType_AlertListComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AlertListComponent, [i1.ElementRef, i4.CommonService, i5.AlertApi], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i6.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i6.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
