/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drop-down-grid-consumers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/drop-down-grid/drop-down-grid.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/drop-down-grid/drop-down-grid.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../consumer/services/helper.service";
import * as i6 from "../../../../shared/sdk/services/custom/Consumer";
import * as i7 from "../../../../shared/sdk/services/custom/ConsumerView";
import * as i8 from "../../../../shared/modules/my-common/services/config.service";
import * as i9 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../../shared/modules/my-common/services/common.service";
import * as i12 from "../../../../shared/modules/ui/services/ui.service";
import * as i13 from "../../services/helper.service";
import * as i14 from "../../../../shared/sdk/services/custom/TripManifest";
import * as i15 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i16 from "../../../employee/services/helper.service";
import * as i17 from "../../../vehicle/services/helper.service";
import * as i18 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i19 from "./drop-down-grid-consumers.component";
var styles_DropDownGridConsumersComponent = [i0.styles];
var RenderType_DropDownGridConsumersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropDownGridConsumersComponent, data: {} });
export { RenderType_DropDownGridConsumersComponent as RenderType_DropDownGridConsumersComponent };
export function View_DropDownGridConsumersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-drop-down-grid", [], null, [[null, "valueChange"], [null, "valueChanged"], [null, "cellPrepared"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.valueChange.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChanged" === en)) {
        var pd_2 = (_co.valueChanged.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("cellPrepared" === en)) {
        var pd_3 = (_co.onCellPrepared($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_DropDownGridComponent_0, i2.RenderType_DropDownGridComponent)), i1.ɵdid(1, 114688, null, 0, i3.DropDownGridComponent, [i4.LoggerService], { dataSource: [0, "dataSource"], placeholder: [1, "placeholder"], showClearButton: [2, "showClearButton"], valueExpr: [3, "valueExpr"], displayExpr: [4, "displayExpr"], popupWidth: [5, "popupWidth"], popupHeight: [6, "popupHeight"], gridColumns: [7, "gridColumns"], value: [8, "value"] }, { valueChange: "valueChange", valueChanged: "valueChanged", cellPrepared: "cellPrepared" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ds; var currVal_1 = "Select Consumer..."; var currVal_2 = false; var currVal_3 = "id"; var currVal_4 = _co.consumerHelper.displayExpr; var currVal_5 = 350; var currVal_6 = 300; var currVal_7 = _co.gridColumns; var currVal_8 = _co.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DropDownGridConsumersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-drop-down-grid-consumers", [], null, null, null, View_DropDownGridConsumersComponent_0, RenderType_DropDownGridConsumersComponent)), i1.ɵprd(512, null, i5.HelperService, i5.HelperService, [i6.ConsumerApi, i7.ConsumerViewApi, i8.ConfigService, i9.PusherService, i10.HttpClient, i11.CommonService, i12.UiService]), i1.ɵprd(512, null, i13.HelperService, i13.HelperService, [i10.HttpClient, i14.TripManifestApi, i11.CommonService, i8.ConfigService, i15.DataSourceService, "Window", i16.HelperService, i5.HelperService, i17.HelperService, i9.PusherService, i18.UploadHelperService]), i1.ɵdid(3, 770048, null, 0, i19.DropDownGridConsumersComponent, [i4.LoggerService, i15.DataSourceService, i13.HelperService, i5.HelperService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DropDownGridConsumersComponentNgFactory = i1.ɵccf("app-drop-down-grid-consumers", i19.DropDownGridConsumersComponent, View_DropDownGridConsumersComponent_Host_0, { ds: "ds", gridColumns: "gridColumns", manifest: "manifest", value: "value" }, { valueChange: "valueChange", valueChanged: "valueChanged" }, []);
export { DropDownGridConsumersComponentNgFactory as DropDownGridConsumersComponentNgFactory };
