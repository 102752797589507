import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { TripsAuditDetailsComponent } from './components/trips-audit-details/trips-audit-details.component';
import { TripsAuditGridComponent } from './components/trips-audit-grid/trips-audit-grid.component';
import { TripsAuditListComponent } from './components/trips-audit-list/trips-audit-list.component';
import { TripsAuditComponent } from './containers/trips-audit/trips-audit.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [TripsAuditComponent, TripsAuditListComponent, TripsAuditGridComponent, TripsAuditDetailsComponent],
  exports: [TripsAuditComponent],
  entryComponents: [TripsAuditComponent],
})
export class TripsAuditModule {}
