import {
  BaseLoopBackApi,
  Consumer,
  CustomField,
  Document,
  DocumentType,
  Employee,
  Facility,
  ObjectCFV,
  Person,
  Vehicle,
} from '../../../../sdk';
import { DataSourceService } from '../../../my-common/services/datasource.service';

export async function getObjectCFVAsync(
  api: BaseLoopBackApi,
  objectType: string,
  objectId: number,
  customFieldId: number,
): Promise<ObjectCFV> {
  return await api
    .findOne<ObjectCFV>({ where: { objectType, objectId, customFieldId } })
    .toPromise()
    .catch(err => null);
}

export function getCustomFieldById(fields: CustomField[], id: number): CustomField {
  if (!fields) {
    return null;
  }
  const cfs = fields.filter(cf => cf.id === id);
  return cfs && cfs.length ? cfs[0] : null;
}

export function getCustomFieldByName(fields: CustomField[], name: string): CustomField {
  if (!fields) {
    return null;
  }
  const cfs = fields.filter(cf => cf.field === name);
  return cfs && cfs.length ? cfs[0] : null;
}

export function getObjectCFVByCFId(model: any, customFieldId: number): ObjectCFV {
  if (!model) {
    return null;
  }

  const cfvs = model ? model.customFieldValues.filter((cfv: ObjectCFV) => cfv.customFieldId === customFieldId) : [];

  return cfvs && cfvs.length ? cfvs[0] : null;
}

export function getObjectCFVValueFromModel(model: any, customFieldId: number): any {
  if (!model) {
    return null;
  }

  const cf = getCustomFieldById(model.customFields, customFieldId);
  const cfv = getObjectCFVByCFId(model, customFieldId);

  if (!cf || !cfv) {
    return null;
  }

  return cfv[getValuePropNameByFieldType(cf.type)];
}

export function getObjectCFVValueByCF(cfv: ObjectCFV, cf: CustomField): any {
  return cfv[getValuePropNameByFieldType(cf.type)];
}

export function getValuePropNameByFieldType(type: string): string {
  const map: any = {
    CHECKBOX: 'valueBool',
    INT: 'valueInt',
    NUMBER: 'valueFloat',
    STRING: 'valueString',
    DATE: 'valueDate',
    TIME: 'valueTime',
    DATETIME: 'valueDatetime',
    TEXT: 'valueText',
    JSON: 'valueJSON',
    DROPDOWN: 'valueString',
    EXPIRATION_DATE: 'valueDate',
    WEEKDAY_NUMBER_MAP: 'valueJSON',
  };
  return map[type] ? map[type] : 'value';
}

export function getModelByFQN(objectFQN: string): any {
  const map = {
    FACILITY: Facility,
    VEHICLE: Vehicle,
    EMPLOYEE: Employee,
    CONSUMER: Consumer,
    PERSON: Person,
    DOCUMENT: Document,
    DOCUMENT_TYPE: DocumentType,
  };
  return map[objectFQN] ? map[objectFQN] : null;
}

export function getFQNByModelName(objectType: string): any {
  const map = {
    [Facility.getModelName()]: 'FACILITY',
    [Vehicle.getModelName()]: 'VEHICLE',
    [Employee.getModelName()]: 'EMPLOYEE',
    [Consumer.getModelName()]: 'CONSUMER',
    [Person.getModelName()]: 'PERSON',
    [Document.getModelName()]: 'DOCUMENT',
    [DocumentType.getModelName()]: 'DOCUMENT_TYPE',
  };
  return map[objectType] ? map[objectType] : null;
}

export function objectToString(object: any, objectType: string): string {
  if (!object) {
    return null;
  }
  const map = {
    [Vehicle.getModelName()]: (o: any) => `${o.internalId}`,
    [Employee.getModelName()]: (o: any) => `${o.person.firstname} ${o.person.lastname}`,
    [Consumer.getModelName()]: (o: any) => `${o.person.firstname} ${o.person.lastname}`,
    [Person.getModelName()]: (o: any) => `${o.firstname} ${o.lastname}`,
    [Facility.getModelName()]: (o: any) => `${o.name}`,
    [Document.getModelName()]: (o: any) => `${o.name}`,
  };
  return map[objectType] ? map[objectType](object) : null;
}

export async function getObjectAsync(objectType: string, objectId: number, dss: DataSourceService): Promise<any> {
  const map = {
    [Vehicle.getModelName()]: async (id: number) => await dss.getApi(Vehicle).findById(id).toPromise(),
    [Employee.getModelName()]: async (id: number) =>
      await dss.getApi(Employee).findById(id, { include: 'person' }).toPromise(),
    [Consumer.getModelName()]: async (id: number) =>
      await dss.getApi(Consumer).findById(id, { include: 'person' }).toPromise(),
    [Person.getModelName()]: async (id: number) => await dss.getApi(Person).findById(id).toPromise(),
    [Facility.getModelName()]: async (id: number) => await dss.getApi(Facility).findById(id).toPromise(),
    [Document.getModelName()]: async (id: number) => await dss.getApi(Document).findById(id).toPromise(),
  };

  return map[objectType] ? await map[objectType](objectId) : null;
}
