import * as tslib_1 from "tslib";
import { FormBuilder } from '@angular/forms';
import { DriverSchedule, Facility, GeoPoint, LoggerService, MyUtils, MyUtilsApi, } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { get } from 'lodash-es';
import moment from 'moment';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { HelperService } from '../../services/helper.service';
import { environment } from 'src/environments/environment';
import { BACKUP_DRIVER, SCHEDULE_STATUSES } from '../../classes/enums';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
const BASE_LOCATION = environment.baseLocation && JSON.parse(environment.baseLocation);
const CHURCH_LOCATION = environment.churchLocation && JSON.parse(environment.churchLocation);
export class ScheduleFormComponent extends ABaseFormComponent {
    constructor(logger, config, fb, dss, scheduleHelper, helper, auth) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.config = config;
        this.fb = fb;
        this.dss = dss;
        this.scheduleHelper = scheduleHelper;
        this.helper = helper;
        this.auth = auth;
        this.mapVelues = {
            startZoom: undefined,
            startLocation: { lat: 39.98659463651634, lng: -75.13570998561525 },
            startLocationRoute: [],
            startLocationMarker: [],
            finishZoom: undefined,
            finishLocation: { lat: 39.98659463651634, lng: -75.13570998561525 },
            finishLocationRoute: [],
            finishLocationMarker: [],
        };
        this.locations = [
            ...(BASE_LOCATION ? [{ name: 'Base', value: 'base' }] : []),
            ...(CHURCH_LOCATION ? [{ name: 'St. Nicholas Church', value: 'church' }] : []),
            ...(CHURCH_LOCATION ? [{ name: 'Facility', value: 'facility' }] : []),
            { name: 'Other', value: 'other' },
        ];
        this.timeValues = {
            start: '',
            startPrepareDuration: 40,
            finish: '',
            finishPrepareDuration: 40,
        };
        this.statuses = SCHEDULE_STATUSES;
        this.isToastVisible = false;
        this.toastMessage = '';
        this.toastType = 'info'; // can be 'info', 'warning', 'error', or 'success'
        this.formLoaded = false;
        this.status_onSelectionChanged = ({ selectedItem: { ID: value } }) => {
            if (value === BACKUP_DRIVER) {
                this.form.get('startLocation').setValue('other');
                this.form.get('finishLocation').setValue('other');
                this.form.get('startLocationAddress').setValue('');
                this.form.get('finishLocationAddress').setValue('');
                this.form.get('startLocationCoords').setValue(null);
                this.form.get('finishLocationCoords').setValue(null);
                this.timeValues.start = '';
                this.timeValues.finish = '';
            }
        };
        this.statusValidationCallback = (options) => {
            if (!this.data.manifest && options.value === 'DRIVER') {
                options.rule.message = 'Please enter first pickup time and address on Manifest for this driver.';
                return false;
            }
            return true;
        };
        this.locationMarker = ({ lat, lng }, startFinish) => {
            const firstLast = startFinish === 'start' ? 'first' : 'last';
            // const loc = get(this.data, `${firstLast}TripConsumer.person.contact.addresses[0].location`);
            const addr = get(this.data, `${firstLast}TripConsumer.person.contact.addresses[0]`);
            let iconSrc = `/assets/images/marker-${startFinish === 'start' ? 'current' : 'facility'}.png`;
            let markers = [{ iconSrc, location: `${lat}, ${lng}` }];
            if (!addr)
                return markers;
            iconSrc = `/assets/images/marker-${startFinish === 'start' ? 'pickup' : 'current'}.png`;
            markers.push({
                iconSrc,
                location: addr.location ? `${addr.location.lat}, ${addr.location.lng}` : this.scheduleHelper.getFullAddress(addr),
            });
            return markers;
        };
        this.location_onSelectionChanged = (e, startFinish) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!e.selectedItem)
                return;
            const loc = e.selectedItem.value;
            let address = this.form.get(`${startFinish}LocationAddress`).value;
            let coords = this.form.get(`${startFinish}LocationCoords`).value;
            if (loc === 'base') {
                address = BASE_LOCATION.address;
                coords = BASE_LOCATION.coords;
            }
            if (loc === 'church') {
                address = CHURCH_LOCATION.address;
                coords = CHURCH_LOCATION.coords;
            }
            if (loc === 'facility') {
                let facilityId = this.form.get(`${startFinish}FacilityId`).value;
                if ((!facilityId || !this.facilityMap[facilityId]) && Object.keys(this.facilityMap).length === 1) {
                    facilityId = Object.keys(this.facilityMap)[0];
                    this.form.get(`${startFinish}FacilityId`).setValue(facilityId);
                }
                if (facilityId) {
                    const a = this.facilityMap[facilityId].contact.addresses[0];
                    address = this.scheduleHelper.getFullAddress(a);
                    coords = a.location;
                }
            }
            if (address && coords) {
                yield this.setMapAndTripValues(coords, startFinish);
                this.form.get(`${startFinish}LocationAddress`).setValue(address);
                this.mapVelues[`${startFinish}Zoom`] = 17;
                this.mapVelues[`${startFinish}Location`] = coords;
            }
        });
        this.locationAddress_onChange = (e, startFinish) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.form.get(`${startFinish}Location`).value !== 'other')
                return;
            const address = e.value;
            if (!address)
                return;
            const { results: [{ geometry }], } = yield this.dss.getApi(MyUtils).geocode(address).toPromise();
            if (!geometry)
                return;
            const coords = { lat: geometry.location.lat, lng: geometry.location.lng };
            yield this.setMapAndTripValues(coords, startFinish);
            this.mapVelues[`${startFinish}Zoom`] = 17;
            this.mapVelues[`${startFinish}Location`] = coords;
        });
        this.facility_onSelectionChanged = ({ selectedItem: { id: facilityId } }, startFinish) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.form.get(`${startFinish}Location`).value !== 'facility')
                return;
            const a = this.facilityMap[facilityId].contact.addresses[0];
            yield this.setMapAndTripValues(a.location, startFinish);
            this.form.get(`${startFinish}LocationAddress`).setValue(this.scheduleHelper.getFullAddress(a));
            this.mapVelues[`${startFinish}Zoom`] = 17;
            this.mapVelues[`${startFinish}Location`] = a.location;
        });
        this.dateBox_onValueChanged = ({ value }, startFinish) => this.form.get(`${startFinish}Time`).setValue((value && moment(value).format('HH:mm:ss')) || null);
        this.setState(FORM_STATE.COLLAPSED);
    }
    get dateFields() {
        return [];
    }
    get ModelClass() {
        return DriverSchedule;
    }
    getRoute({ lat, lng }, startFinish) {
        const firstLast = startFinish === 'start' ? 'first' : 'last';
        // const loc = get(this.data, `${firstLast}TripConsumer.person.contact.addresses[0].location`);
        const addr = get(this.data, `${firstLast}TripConsumer.person.contact.addresses[0]`);
        if (!addr)
            return [];
        const from = [lat, lng];
        const to = addr.location ? [addr.location.lat, addr.location.lng] : this.scheduleHelper.getFullAddress(addr);
        const locations = [from, to];
        if (startFinish !== 'start')
            locations.reverse();
        return [{ weight: 4, color: 'blue', opacity: 0.8, mode: '', locations }];
    }
    getTravelDuration({ lat, lng }, startFinish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const firstLast = startFinish === 'start' ? 'first' : 'last';
            const tl = this.form.get(`${startFinish}TripLocation`).value;
            const tla = this.form.get(`${startFinish}TripLocationAddress`).value;
            const tcn = this.form.get(`${startFinish}TripConsumerName`).value;
            const tt = this.form.get(`${startFinish}TripTime`).value;
            const td = this.form.get(`${startFinish}TravelDuration`).value;
            const consumerAddr = get(this.data, `${firstLast}TripConsumer.person.contact.addresses[0]`);
            const tripTime = get(this.data, `manifest.${firstLast}Trip.${(startFinish === 'start' && 't') || 'dot'}`);
            if (!consumerAddr || !tripTime)
                return [tl, tla, tcn, tt, td];
            const { lat: lt, lng: lg } = consumerAddr.location || {};
            const to = lt ? `${lt}, ${lg}` : this.scheduleHelper.getFullAddress(consumerAddr);
            const toAddr = this.scheduleHelper.getFullAddress(consumerAddr);
            const cd = this.form.get(`${startFinish}LocationCoords`).value;
            // if (cd && tl && tt && td && cd.lat === lat && cd.lng === lng && tl === to && tt === tripTime)
            // return [tl, tla, tcn, tt, td];
            const p = { startFinish, from: `${lat}, ${lng}`, to, selectedDate: this.selectedDate, tripTime };
            const travelDuration = yield this.scheduleHelper.getTravelDuration(p);
            const cPerson = get(this.data, `${firstLast}TripConsumer.person`);
            const consumerName = `${cPerson.firstname} ${cPerson.lastname}`;
            return [to, toAddr, consumerName, tripTime, travelDuration];
        });
    }
    setMapAndTripValues(coords, startFinish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.formLoaded)
                return;
            this.mapVelues[`${startFinish}LocationMarker`] = this.locationMarker(coords, startFinish);
            this.mapVelues[`${startFinish}LocationRoute`] = this.getRoute(coords, startFinish);
            const [to, toAddr, consumerName, tripTime, travelDuration] = yield this.getTravelDuration(coords, startFinish);
            this.form.get(`${startFinish}LocationCoords`).setValue(coords);
            this.form.get(`${startFinish}TripLocation`).setValue(to);
            this.form.get(`${startFinish}TripLocationAddress`).setValue(toAddr);
            this.form.get(`${startFinish}TripConsumerName`).setValue(consumerName);
            this.form.get(`${startFinish}TripTime`).setValue(tripTime);
            this.form.get(`${startFinish}TravelDuration`).setValue(travelDuration);
        });
    }
    map_onClick({ location: coords }, startFinish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.setMapAndTripValues(coords, startFinish);
            const { results: [{ formatted_address }], } = yield this.dss.getApi(MyUtils).reverseGeocode(`${coords.lat}, ${coords.lng}`).toPromise();
            this.form.get(`${startFinish}LocationAddress`).setValue(formatted_address.replace(', USA', ''));
            this.form.get(`${startFinish}Location`).setValue('other');
        });
    }
    propose_onClick(startFinish) {
        const firstLast = startFinish === 'start' ? 'first' : 'last';
        let time = get(this.data, `manifest.${firstLast}Trip.${(startFinish === 'start' && 't') || 'dot'}`);
        if (!time) {
            this.toastMessage = `To use propose, please enter ${firstLast} pickup time and address on Manifest for this driver.`;
            this.toastType = 'warning';
            this.isToastVisible = true;
            return;
        }
        const duration = this.form.get(`${startFinish}TravelDuration`).value + this.timeValues[`${startFinish}PrepareDuration`];
        time = moment(time, 'HH:mm:ss');
        time =
            startFinish === 'start'
                ? time.subtract(duration, 'minutes').startOf('minute')
                : time.add(duration, 'minutes').endOf('minute');
        const mins = (startFinish === 'start' ? Math.floor(time.minutes() / 10) : Math.ceil(time.minutes() / 10)) * 10;
        time.minutes(mins);
        if (this.form.get(`${startFinish}Time`).value !== time.format('HH:mm:ss')) {
            this.form.get(`${startFinish}TimeCalculatedAt`).setValue(new Date());
            const { lat, lng } = this.form.get(`${startFinish}LocationCoords`).value;
            this.form.get(`${startFinish}LocationCalculated`).setValue(`${lat}, ${lng}`);
        }
        this.timeValues[startFinish] = (time && time.toDate()) || null;
    }
    beforeSubmittingAsync(model) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, model);
            model.facilityId = model.startFacilityId || this.auth.getCurrentTenant();
        });
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            const _a = (yield gqlMongoLoad(this.dss, 'AutoSchedulingSettings', {}, []).pipe().toPromise())[0] || {}, { _id: id } = _a, aSch = tslib_1.__rest(_a, ["_id"]);
            this.timeValues.startPrepareDuration = aSch.startPrepareDuration || this.timeValues.startPrepareDuration;
            this.timeValues.finishPrepareDuration = aSch.finishPrepareDuration || this.timeValues.finishPrepareDuration;
            this.formLoaded = true;
            if (!model) {
                this.form.get('date').setValue(`${moment(this.selectedDate).format('YYYY-MM-DD')}T16:00:00.000Z`);
                this.form.get('driverId').setValue(this.data.id);
            }
            yield this.initValues(model, 'start');
            yield this.initValues(model, 'finish');
            this.mapVelues[`finishZoom`] = this.mapVelues[`finishZoom`] || 11;
        });
    }
    initValues(model, startFinish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const firstLast = startFinish === 'start' ? 'first' : 'last';
            const facilityId = get(this.data, `manifest.${firstLast}Trip.facilityId`);
            this.form.get(`${startFinish}FacilityId`).setValue(facilityId);
            if (model) {
                const time = model[`${startFinish}Time`] && moment(model[`${startFinish}Time`], 'HH:mm:ss');
                this.timeValues[startFinish] = (time && time.toDate()) || null;
                let loc = 'other';
                const address = this.form.get(`${startFinish}LocationAddress`).value;
                if (BASE_LOCATION && address === BASE_LOCATION.address)
                    loc = 'base';
                else if (CHURCH_LOCATION && address === CHURCH_LOCATION.address)
                    loc = 'church';
                else {
                    if (facilityId && this.facilityMap[facilityId]) {
                        const a = this.facilityMap[facilityId].contact.addresses[0];
                        if (a && address === this.scheduleHelper.getFullAddress(a))
                            loc = 'facility';
                    }
                }
                this.form.get(`${startFinish}Location`).setValue(loc);
            }
            else {
                const coords = this.form.get(`${startFinish}LocationCoords`).value;
                if (coords) {
                    yield this.setMapAndTripValues(coords, startFinish);
                    this.mapVelues[`${startFinish}Zoom`] = 17;
                    this.mapVelues[`${startFinish}Location`] = coords;
                }
            }
        });
    }
    buildForm() {
        this.formConfigMap.set('', {
            id: [],
            driverId: [],
            status: [''],
            date: [],
            startFacilityId: [''],
            startLocation: ['base'],
            startTime: [''],
            startTimeCalculatedAt: [null],
            startLocationAddress: [BASE_LOCATION ? BASE_LOCATION.address : ''],
            startLocationCoords: [BASE_LOCATION ? BASE_LOCATION.coords : ''],
            startLocationCalculated: [],
            startTripLocation: [],
            startTripLocationAddress: [''],
            startTripConsumerName: [''],
            startTripTime: [],
            startTravelDuration: [],
            startInstructions: [''],
            finishFacilityId: [''],
            finishLocation: ['base'],
            finishTime: [null],
            finishTimeCalculatedAt: [null],
            finishLocationAddress: [''],
            finishLocationCoords: [],
            finishLocationCalculated: [],
            finishTripLocation: [],
            finishTripLocationAddress: [''],
            finishTripConsumerName: [''],
            finishTripTime: [],
            finishTravelDuration: [],
            finishInstructions: [''],
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
}
