<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <dx-switch
    [(value)]="full"
    width="100"
    hint="Full Report / Issues Only"
    switchedOnText="Full Report"
    switchedOffText="Issues Only"></dx-switch>

  <ng-container *ngIf="!!fullDoc">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Summary</div>

      <div class="dx-field">
        <div class="dx-field-label">Check In By</div>
        <div class="dx-field-value-static">
          <app-object-title [modelId]="fullDoc?.employeeId" [modelName]="'Employee'"></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Username</div>
        <div class="dx-field-value-static">
          <app-object-title [modelId]="fullDoc?.userId" [modelName]="'MyUser'" [field]="'username'"></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Vehicle</div>
        <div class="dx-field-value-static">
          #{{ model?.vehicle?.internalId }} - {{ model?.vehicle?.state }} - {{ model?.vehicle?.type }} -
          {{ model?.vehicle?.year }} {{ model?.vehicle?.make }} {{ model?.vehicle?.model }} - VIN
          {{ model?.vehicle?.vin?.substr(model?.vehicle?.vin?.length - 6) }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In Location</div>
        <div class="dx-field-value-static">{{ fullDoc?.location }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Location</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.location }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift Begin At</div>
        <div class="dx-field-value-static">{{ fullDoc?._pi | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift End At</div>
        <div class="dx-field-value-static">{{ fullDoc?._po | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?._shiftDuration | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">Check In Begin At</div>
        <div class="dx-field-value-static">{{ fullDoc?.startedDateTime | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In End At</div>
        <div class="dx-field-value-static">{{ fullDoc?.completedDateTime | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?.durationTime | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Begin At</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.startedDateTime | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out End At</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.completedDateTime | local: 'YYYY-MM-DD HH:mm' }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.durationTime | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Vehicle Usage Duration</div>
        <div class="dx-field-value-static">
          {{ fullDoc?.checkout?._vehicleUsageDuration | humanize: { units: ['h', 'm'] } }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Device UID</div>
        <div class="dx-field-value-static">{{ fullDoc?._mobileDevice?.deviceUid }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Device Label</div>
        <div class="dx-field-value-static">{{ fullDoc?._mobileDevice?.label }}</div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">Days Since Last Check</div>
        <div class="dx-field-value-static">{{ fullDoc?.daysSinceLastCheck }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Changed During Day</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.changedDuringDay | yesNo }}</div>
      </div>

      <br />

      <ng-container
        *ngFor="
          let entry of [
            ['Check In', fullDoc, ciFields, descAndReportMap?.ci],
            ['Check Out', fullDoc?.checkout, coFields, descAndReportMap?.co]
          ];
          index as idx
        ">
        <div class="dx-fieldset-header">
          {{ entry[0] }}
          (
          <app-object-title
            *ngIf="idx === 0 || fullDoc?.checkout; else noCheckHeader"
            [modelId]="entry[1]?.employeeId"
            [modelName]="'Employee'"></app-object-title>

          <ng-template #noCheckHeader> Empty </ng-template>
          )
        </div>

        <ng-container *ngIf="!!entry[1]">
          <ng-container *ngFor="let fieldset of entry[2]; index as fsIdx">
            <fieldset>
              <legend>{{ fieldset[0] }}</legend>

              <ng-container *ngFor="let field of fieldset[1]; index as fIdx">
                <ng-container *ngIf="fsIdx === 0 && fIdx === 0">
                  <app-check-report-field
                    *ngIf="full"
                    [model]="entry[1]"
                    [meta]="'dashboard'"
                    [fullDoc]="fullDoc"></app-check-report-field>
                </ng-container>

                <app-check-report-field
                  *ngIf="full ? true : entry[3]?.get(field)?.value?.issues.length"
                  [model]="entry[1]"
                  [meta]="entry[3]?.get(field)"
                  [fullDoc]="fullDoc"></app-check-report-field>
              </ng-container>
            </fieldset>
          </ng-container>
        </ng-container>

        <br />
      </ng-container>
    </div>
  </ng-container>
</app-details-tab>
