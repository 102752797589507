<div class="avatar-container">
  <div
    class="image-container"
    *ngIf="person.personalPhoto?.avatar; else placeholder"
    [style.backgroundImage]="
      'url(' + ((person.personalPhoto.avatar | fileSignedUrl | async) || '/assets/images/avatar-placeholder.png') + ')'
    "></div>

  <ng-template #placeholder>
    <div class="image-container avatar-placeholder"></div>
  </ng-template>

  <div class="icon-wrapper" (click)="openCropper()">
    <i class="material-icons icon">edit</i>
  </div>
</div>

