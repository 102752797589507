<app-drop-down-grid-new
  [grid_columns]="grid_columns"
  [placeholder]="'Select Person...'"
  [showClearButton]="true"
  [disabled]="disabled"
  [dataSource]="dataSource"
  [remoteOperations]="remoteOperations"
  [valueExpr]="'id'"
  [displayExpr]="helper.displayExpr"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  (valueChanged)="valueChanged.emit($event)"
  (focusout)="focusOut.emit($event)"
  [popup_width]="350"
  [popup_height]="300"
></app-drop-down-grid-new>
