import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { captureMessage } from '@sentry/browser';
import { createStore, Options } from 'devextreme-aspnet-data-nojquery';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { isEmpty, merge } from 'lodash-es';
import camelCase from 'lodash-es/camelCase';
import { oc } from 'ts-optchain';
import { environment } from '../../../../../environments/environment';
//
import { LoopBackExtendedStoreOptions } from '../../../classes/loopback-custom-store/generic/store-options/LoopBackExtendedStoreOptions';
import { LoopBackRelatedStoreOptions } from '../../../classes/loopback-custom-store/generic/store-options/LoopBackRelatedStoreOptions';
import { LoopBackStoreOptions } from '../../../classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { getAltOrCommonHost } from '../../../classes/utils/utils';
import { BaseLoopBackApi, LoopBackConfig, MyUtils, SDKModels } from '../../../sdk';
import { ExtSDKModels, IModelClass } from '../../ext-sdk/services/ext-sdk-models.service';
import { CommonService } from './common.service';
//
import CustomStoreOptions = DevExpress.data.CustomStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import Store = DevExpress.data.Store;

interface IOptions {
  api?: BaseLoopBackApi;
  viewApi?: BaseLoopBackApi;
  storeOptions?: CustomStoreOptions;
  store?: CustomStore;
  children?: Map<string, IOptions>;
}

@Injectable()
export class DataSourceService {
  public modifiedEvent: EventEmitter<string> = new EventEmitter<string>();
  private map: Map<string, IOptions> = new Map(); // key: model

  constructor(
    @Inject(Injector) private injector: Injector,
    @Inject(SDKModels) public models: ExtSDKModels,
    @Inject(CommonService) private common: CommonService,
  ) {
    Object.entries(this.models.getAllApiClasses()).forEach(([modelName, modelAPIClass]) => {
      this.map.set(modelName, { api: this.injector.get<any>(modelAPIClass as any) });
    });
  }

  getApi<T extends BaseLoopBackApi>(ModelClass: IModelClass): T {
    const modelName = ModelClass.getModelName();
    const options: IOptions = this.safeGetOptions(modelName);

    if (!this.models.get(modelName)) {
      return null;
    }

    if (!options.api) {
      const apiName = camelCase(modelName) + 'Api';
      console.error(`API ${apiName} for model ${modelName} not injected.`);
    }

    return options.api as T;
  }

  getViewApi<T extends BaseLoopBackApi>(ModelClass: IModelClass): T {
    const modelName = ModelClass.getModelName();
    const viewModelName = modelName + 'View';
    const options: IOptions = this.safeGetOptions(modelName);
    const viewOptions: IOptions = this.safeGetOptions(viewModelName);

    if (!this.models.get(viewModelName)) {
      return null;
    }

    if (!viewOptions.api) {
      const apiName = camelCase(viewModelName) + 'Api';
      console.error(`API ${apiName} for model ${viewModelName} not injected.`);
    } else if (!options.viewApi) {
      options.viewApi = viewOptions.api;
    }

    return options.viewApi as T;
  }

  getStoreOptions<T extends IModelClass, V extends IModelClass>(
    ModelClass: T,
    ViewModelClass?: V,
    useStore: boolean = true,
  ) {
    const modelName = ModelClass.getModelName();

    if (useStore) {
      const options: IOptions = this.safeGetOptions(modelName);
      if (!options.storeOptions) {
        options.storeOptions = this.getSO<T, V>(ModelClass, ViewModelClass);
        // options.storeOptions.errorHandler = (error) => notify(error, 'error', 3000);
      }

      return options.storeOptions as LoopBackStoreOptions<T, V>;
    } else {
      return this.getSO<T, V>(ModelClass, ViewModelClass);
    }
  }

  getChildStoreOptions(
    ModelClass: IModelClass,
    relation: string,
    id: number = 0,
    useStore: boolean = true,
  ): CustomStoreOptions {
    const modelName = ModelClass.getModelName();
    id = id || 0;

    if (useStore) {
      const childOptions: IOptions = this.safeGetChildOptions(modelName, relation);

      if (!childOptions.storeOptions) {
        childOptions.storeOptions = new LoopBackRelatedStoreOptions(this.models, this.getApi(ModelClass), relation, id);
        // childOptions.storeOptions.errorHandler = (error) => notify(error, 'error', 3000);
      } else {
        (childOptions.storeOptions as LoopBackRelatedStoreOptions<any>).setRelatedId(id);
      }

      return childOptions.storeOptions;
    } else {
      return new LoopBackRelatedStoreOptions(this.models, this.getApi(ModelClass), relation, id);
    }
  }

  getStore(ModelClass: IModelClass, ViewModelClass?: IModelClass, useStore: boolean = true): CustomStore {
    const modelName = ModelClass.getModelName();

    if (useStore) {
      const options: IOptions = this.safeGetOptions(modelName);

      if (!options.store) {
        const store: CustomStore = new CustomStore(this.getStoreOptions(ModelClass, ViewModelClass, useStore));
        store.on('modified', () => this.modifiedEvent.emit(modelName));

        options.store = store;
      }

      return options.store;
    } else {
      return new CustomStore(this.getStoreOptions(ModelClass, ViewModelClass, useStore));
    }
  }

  getChildStore(ModelClass: IModelClass, relation: string, id: number = 0, useStore: boolean = true): CustomStore {
    const modelName = ModelClass.getModelName();
    const childModelName = ModelClass.getModelDefinition().relations[relation].model;
    id = id || 0;

    if (useStore) {
      const childOptions: IOptions = this.safeGetChildOptions(modelName, relation);

      if (!childOptions.store) {
        const store: CustomStore = new CustomStore(this.getChildStoreOptions(ModelClass, relation, id, useStore));
        store.on('modified', () => this.modifiedEvent.emit(childModelName));
        childOptions.store = store;
      } else {
        const storeOptions: CustomStoreOptions = childOptions.storeOptions;

        if (id !== (storeOptions as LoopBackRelatedStoreOptions<any>).getRelatedId()) {
          childOptions.store.off('modified');

          const store: CustomStore = new CustomStore(this.getChildStoreOptions(ModelClass, relation, id, useStore));
          store.on('modified', () => this.modifiedEvent.emit(childModelName));
          childOptions.store = store;
        }
      }

      return childOptions.store;
    } else {
      return new CustomStore(this.getChildStoreOptions(ModelClass, relation, id, useStore));
    }
  }

  getDataSourceOptions(
    ModelClass: IModelClass,
    ViewModelClass?: IModelClass,
    useStore: boolean = true,
  ): DataSourceOptions {
    const store = this.getStore(ModelClass, ViewModelClass, useStore);
    return { store } as DataSourceOptions;
  }

  getDataSource(ModelClass: IModelClass, ViewModelClass?: IModelClass, useStore: boolean = true): DataSource {
    const store = this.getStore(ModelClass, ViewModelClass, useStore);
    return new DataSource({ store } as DataSourceOptions);
  }

  getChildDataSource(ModelClass: IModelClass, relation: string, id: number = 0, useStore: boolean = true): DataSource {
    const store = this.getChildStore(ModelClass, relation, id, useStore);
    return new DataSource({ store } as DataSourceOptions);
  }

  getDataSourceFromSO(so: CustomStoreOptions): DataSource {
    return new DataSource(so);
  }

  getDataSourceFromStore(store: Store): DataSource {
    return new DataSource({ store } as DataSourceOptions);
  }

  getDataSourceFromDSO(dso: DataSourceOptions): DataSource {
    return new DataSource(dso);
  }

  private safeGetOptions(modelName: string): IOptions {
    if (!this.map.has(modelName)) {
      this.map.set(modelName, {});
    }

    return this.map.get(modelName);
  }

  private safeGetChildOptions(modelName: string, relation: string): IOptions {
    const options = this.safeGetOptions(modelName);

    if (!('children' in options)) {
      options.children = new Map();
    }

    if (!options.children.has(relation)) {
      options.children.set(relation, {});
    }

    return options.children.get(relation);
  }

  private getSO<T extends IModelClass, V extends IModelClass>(ModelClass: T, ViewModelClass?: V) {
    return new (this.getApi<any>(ModelClass).mySaveWithRelated ? LoopBackExtendedStoreOptions : LoopBackStoreOptions)<
      T,
      V
    >(
      this.models,
      this.getApi(ModelClass),
      ViewModelClass ? this.getApi(ViewModelClass) : this.getViewApi(ModelClass) || this.getApi(ModelClass),
    );
  }

  // graphql(q: { query: string; variables: any }) {
  //   return this.getApi<MyUtilsApi>(MyUtils)
  //     .graphql(q)
  //     .pipe(
  //       map(({ errors, data }) => {
  //         if (errors) {
  //           const error = new Error('GraphQL: ' + errors[0].message);
  //           captureException(error, {
  //             extra: {
  //               query: q.query,
  //               variables: q.variables,
  //               ...fromPairs(errors.map((e, idx) => ['error:' + idx, e])),
  //             },
  //           });
  //           throw error;
  //         } else {
  //           return data;
  //         }
  //       }),
  //     );
  // }

  createMongoStore(collection: string, schema?: Dictionary<any>, options: Options = {}, useCloud: boolean = true) {
    const self = this;

    const basePath = useCloud ? getAltOrCommonHost() : LoopBackConfig.getPath();
    const path = [basePath, LoopBackConfig.getApiVersion(), MyUtils.getModelDefinition().path].join('/');

    const defOptions: Options = {
      key: '_id',
      loadUrl: `${path}/query/` + collection,
      updateUrl: `${path}/mongo/` + collection,
      deleteUrl: `${path}/mongo/` + collection,

      onBeforeSend(operation, ajaxSettings) {
        // console.log('operation', operation);
        ajaxSettings.headers = {
          ...ajaxSettings.headers,
          'X-Access-Token': '' + self.common.auth.getAccessTokenId(),
          'X-Mongo-Key': oc(options).key('_id'),
        };

        if (!isEmpty(schema)) {
          ajaxSettings.data = {
            ...ajaxSettings.data,
            schema: JSON.stringify(schema),
          };
        }
      },

      errorHandler(error) {
        let msg = oc(error).message();
        try {
          const msgObj = JSON.parse(msg);
          msg = oc(msgObj).error.message() || oc(msgObj).message();
        } catch (err) {}

        captureMessage(msg, {
          extra: {
            collection,
            error,
          },
          level: 'error',
        });

        notify(msg, 'error', 5000);
      },
    };

    const merged: Options & { onBeforeSend1?; onBeforeSend2? } = merge({}, defOptions, options);

    if (options.onBeforeSend) {
      merged.onBeforeSend1 = options.onBeforeSend;
      merged.onBeforeSend2 = defOptions.onBeforeSend;

      // tslint:disable-next-line:only-arrow-functions
      merged.onBeforeSend = function (operation, ajaxSettings) {
        merged.onBeforeSend1(operation, ajaxSettings);
        merged.onBeforeSend2(operation, ajaxSettings);
      };
    }

    return createStore(merged);
  }
}
