import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { Consumer, Employee, EmployeePosition, EmployeeView, LoggerService, } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../classes/enums';
export class EmployeeFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.helper = helper;
        this.types = EMPLOYEE_TYPES;
        this.statuses = EMPLOYEE_STATUSES;
        this.personSexesDS = PERSON_SEXES;
        this.setState(FORM_STATE.COLLAPSED);
        this.positionDS = dss.getDataSource(EmployeePosition);
    }
    get addressesFormArray() {
        return this.form.get('person.contact.addresses');
    }
    get phonesFormArray() {
        return this.form.get('person.contact.phones');
    }
    get emailsFormArray() {
        return this.form.get('person.contact.emails');
    }
    get emRelationsFormArray() {
        return this.form.get('emRelations');
    }
    get filter() {
        return {
            include: [
                { person: { contact: ['addresses', 'phones', 'emails'] } },
                { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
            ],
        };
    }
    get ModelClass() {
        return Employee;
    }
    get dateFields() {
        return ['interviewedAt', 'hiredAt', 'separatedAt', 'person.dob'];
    }
    processFormValueAsync(data) {
        const _super = Object.create(null, {
            processFormValueAsync: { get: () => super.processFormValueAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const duplicates = !this.modelId
                ? yield this.dss
                    .getApi(EmployeeView)
                    .find({
                    where: Object.assign({}, (this.modelId ? { [Consumer.getModelDefinition().idName]: { neq: this.modelId } } : {}), { person_firstname: { like: (data.person.firstname || '').trim().toLowerCase() }, person_lastname: { like: (data.person.lastname || '').trim().toLowerCase() } }),
                    limit: 1,
                })
                    .toPromise()
                : [];
            if (duplicates.length) {
                const res = yield confirm('Employee with the same name already exists.<br>Abort employee creation?', 'Duplicate found');
                if (res === true)
                    throw new Error('This employee already exists');
            }
            return _super.processFormValueAsync.call(this, data);
        });
    }
    buildForm() {
        this.formConfigMap.set('person.contact.addresses', {
            id: [],
            street: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: [],
            contactId: [],
        });
        this.formConfigMap.set('person.contact.phones', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('person.contact.emails', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('person.contact', {
            id: [],
            notes: [],
            addresses: this.fb.array([]),
            phones: this.fb.array([]),
            emails: this.fb.array([]),
        });
        this.formConfigMap.set('person.data', {
            nativeLang: [],
            nativeLangName: [],
            dl: [],
        });
        this.formConfigMap.set('person', {
            id: [],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            middlename: [],
            nickname: [],
            sex: [],
            dob: [],
            notes: [],
            contactId: [],
            data: this.fb.group(this.formConfigMap.get('person.data')),
            contact: this.fb.group(this.formConfigMap.get('person.contact')),
        });
        this.formConfigMap.set('emRelations', {
            id: [],
            relation: [],
            employeeId: [],
            personId: ['', Validators.required],
        });
        this.formConfigMap.set('data', {
            title: [],
        });
        this.formConfigMap.set('', {
            id: [],
            status: ['', Validators.required],
            type: [],
            interviewedAt: [],
            hiredAt: [],
            separatedAt: [],
            allowFastVehicleCheckout: [false],
            allowMultiCheckin: [false],
            notes: [],
            employeePositionId: ['', Validators.required],
            personId: [],
            data: this.fb.group(this.formConfigMap.get('data')),
            person: this.fb.group(this.formConfigMap.get('person')),
            emRelations: this.fb.array([]),
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
}
