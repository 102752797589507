import { Component, Input, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { MyUser } from 'src/app/shared/sdk';
import { getCurrentTenant, getUser } from 'src/app/store/reducers/sign';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-employee-details-tabs',
  templateUrl: './employee-details-tabs.component.html',
  styleUrls: ['./employee-details-tabs.component.scss'],
})
export class EmployeeDetailsTabsComponent implements OnInit {
  signedUser$: Observable<MyUser>;
  myRoles$: Observable<string[]>;
  tenantId$: Observable<number>;
  tabs$: Observable<any[]>;

  @Input() modelId: number | string;

  constructor(
    private common: CommonService,
    private config: ConfigService,
  ) {
    this.signedUser$ = this.common.store.pipe(select(getUser));
    this.tenantId$ = this.common.store.pipe(select(getCurrentTenant));
    this.myRoles$ = this.config.roles$;
    this.buildItems();
  }

  private buildItems() {
    this.tabs$ = combineLatest([this.myRoles$, this.tenantId$]).pipe(
      map(([roles, tenantId]) => {
        return [
          { title: 'Profile', template: 'profile' },
          { title: 'Notes', template: 'notes' },
          ...((tenantId === 7 && [
            { title: 'Employment', template: 'employee' },
            ...((roles.includes('HR_ADMIN') && [{ title: 'Payroll', template: 'payroll' }]) || []),
            { title: 'GeoTab Events', template: 'events' },
            { title: 'Docs', template: 'document' },
          ]) ||
            []),
        ];
      }),
    );
    this.tabs$.subscribe();
  }

  ngOnInit() {}
}
