<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <br />

  <dx-data-grid width="auto" [dataSource]="dso" [remoteOperations]="false">
    <!-- Settings -->

    <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

    <dxo-export [enabled]="true" fileName="activity-export"></dxo-export>

    <!-- Columns -->

    <dxi-column width="75px" caption="Date" dataField="date" dataType="date" sortOrder="desc" format="shortDate"></dxi-column>

    <dxi-column caption="First Engine On" dataField="firstEngineOn" dataType="date" format="shortDateShortTime"></dxi-column>
    
    <dxi-column caption="Last Engine Off" dataField="lastEngineOff" dataType="date" format="shortDateShortTime"></dxi-column>

    <dxi-column caption="First Start" dataField="firstStart" dataType="date" format="shortDateShortTime"></dxi-column>
    
    <dxi-column caption="Last Stop" dataField="lastStop" dataType="date" format="shortDateShortTime"></dxi-column>

    <dxi-column caption="GeoFence First Left" dataField="firstLeft" dataType="date" format="shortDateShortTime"></dxi-column>

    <dxi-column caption="GeoFence Last Return" dataField="lastReturn" dataType="date" format="shortDateShortTime"></dxi-column>

    <dxi-column caption="GeoFence Total Time Out" dataField="totalTimeOut"></dxi-column>

    <dxi-column caption="Distance" dataField="distance"></dxi-column>

    <dxi-column caption="On Duty Duration" dataField="onDutyDuration"></dxi-column>

    <dxi-column caption="on Duty Engine On Duration" dataField="onDutyEngineOnDuration"></dxi-column>

    <dxi-column caption="On Duty Engine Off Duration" dataField="onDutyEngineOffDuration"></dxi-column>

    <dxi-column caption="On Duty Idle Duration" dataField="onDutyIdleDuration"></dxi-column>
    
    <dxi-column caption="20 Min Excess Idle periods" dataField="onDutyIdle20Periods"></dxi-column>
    
    <dxi-column caption="20 Min Excess IDLE Time" dataField="onDutyIdleAbove20Duration"></dxi-column>
    
    <dxi-column caption="30 Min Excess Idle periods" dataField="onDutyIdle30Periods"></dxi-column>

    <dxi-column caption="30 Min Excess IDLE Time" dataField="onDutyIdleAbove30Duration"></dxi-column>

    <dxi-column caption="On Duty Driving Duration" dataField="onDutyDrivingDuration"></dxi-column>

    <dxi-column caption="Alerts" dataField="alerts"></dxi-column>

  </dx-data-grid>
</app-details-tab>
