<app-content-w-header [header]="'Destination List' + (dirty ? ' *' : '')">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <div style="flex: auto; position: relative">
      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
        <dx-box direction="col" height="100%" width="100%">
          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
            <!--                  <mat-toolbar>Destination List<span *ngIf="form.dirty"> *</span></mat-toolbar>-->
          </dxi-item>

          <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
            <dx-data-grid
              height="100%"
              [showBorders]="true"
              [showRowLines]="true"
              [dataSource]="destinationListDS$ | async"
              [remoteOperations]="false"
              (onSaved)="grid_onSaved($event)">
              <!-- Settings -->

              <dxo-group-panel [visible]="false"></dxo-group-panel>
              <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
              <dxo-search-panel [visible]="false"></dxo-search-panel>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-pager [visible]="false"></dxo-pager>

              <dxo-scrolling [mode]="'infinite'"></dxo-scrolling>
              <dxo-selection [mode]="'none'"></dxo-selection>

              <dxo-editing mode="cell" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

              <!-- Columns -->

              <dxi-column caption="Group" dataType="string" dataField="group">
                <dxo-lookup [dataSource]="['FACILITY', 'SCHOOL', 'OTHER', 'ONE_TIME']"></dxo-lookup>
              </dxi-column>

              <dxi-column caption="Short Name" dataType="string" dataField="short">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <!--                <dxi-validation-rule type="pattern" pattern="^\S.+\S$" message="No space around allowed"></dxi-validation-rule>-->
              </dxi-column>

              <dxi-column caption="Name" dataType="string" dataField="name"></dxi-column>

              <dxi-column caption="Address" dataType="string" dataField="address"></dxi-column>

              <dxi-column caption="Phone" dataType="string" dataField="phone"></dxi-column>
            </dx-data-grid>
          </dxi-item>

          <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
            <div class="dx-fieldset">
              <dx-toolbar>
                <dxi-item location="after">
                  <dx-button type="normal" text="Reset" (onClick)="reset_onClick($event)"></dx-button>
                </dxi-item>

                <dxi-item location="after">
                  <dx-button
                    type="default"
                    text="Submit"
                    [icon]="processing ? 'fas fa-circle-notch fa-spin' : null"
                    [disabled]="processing || disabled"
                    (onClick)="submit_onClick($event)"></dx-button>
                </dxi-item>
              </dx-toolbar>
            </div>
          </dxi-item>
        </dx-box>
      </div>
    </div>
  </div>
</app-content-w-header>
