import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { DocumentModule } from '../document/document.module';
import { DlgEmployeeDocumentGridComponent } from '../employee/components/dlg-employee-document-grid/dlg-employee-document-grid.component';
import { DlgManifestGridComponent } from './components/dlg-manifest-grid/dlg-manifest-grid.component';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import { ScheduleGridComponent } from './components/schedule-grid/schedule-grid.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { ScheduleComponent } from './containers/schedule/schedule.component';
import { MtmTripsComponent } from './components/mtm-trips/mtm-trips.component';
import { DxToastModule } from 'devextreme-angular';
import { DlgAddEmployeeBackupComponent } from './components/dlg-add-employee-backup/dlg-add-employee-backup.component';
import { DlgCalendarComponent } from './components/dlg-calendar/dlg-calendar.component';
import { DlgScheduleHistoryGridComponent } from './components/dlg-schedule-history-grid/dlg-schedule-history-grid.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule, DxToastModule],
  declarations: [
    ScheduleComponent,
    ScheduleListComponent,
    ScheduleGridComponent,
    ScheduleFormComponent,
    MtmTripsComponent,
    DlgManifestGridComponent,
    DlgScheduleHistoryGridComponent,
    DlgAddEmployeeBackupComponent,
    DlgCalendarComponent,
  ],
  exports: [ScheduleComponent],
  entryComponents: [
    ScheduleComponent,
    DlgEmployeeDocumentGridComponent,
    ScheduleFormComponent,
    DlgManifestGridComponent,
    DlgScheduleHistoryGridComponent,
    DlgAddEmployeeBackupComponent,
    DlgCalendarComponent,
  ],
})
export class ScheduleModule {}

