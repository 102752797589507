/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-print-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/button-w-drop-down/button-w-drop-down.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/button-w-drop-down/button-w-drop-down.component";
import * as i4 from "../../../../shared/modules/ui/services/ui.service";
import * as i5 from "./toolbar-print-btn.component";
import * as i6 from "../../../../shared/sdk/services/custom/logger.service";
import * as i7 from "../../../../shared/modules/my-common/services/config.service";
var styles_ToolbarPrintBtnComponent = [i0.styles];
var RenderType_ToolbarPrintBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarPrintBtnComponent, data: {} });
export { RenderType_ToolbarPrintBtnComponent as RenderType_ToolbarPrintBtnComponent };
export function View_ToolbarPrintBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-w-drop-down", [["hint", "Print report"], ["icon", "fas fa-print"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.ctx_onAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonWDropDownComponent_0, i2.RenderType_ButtonWDropDownComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonWDropDownComponent, [i4.UiService], { menuItems: [0, "menuItems"], hint: [1, "hint"], icon: [2, "icon"], processing: [3, "processing"] }, { action: "action" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ctxMenuItems; var currVal_1 = "Print report"; var currVal_2 = "fas fa-print"; var currVal_3 = _co.processing; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ToolbarPrintBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toolbar-print-btn", [], null, null, null, View_ToolbarPrintBtnComponent_0, RenderType_ToolbarPrintBtnComponent)), i1.ɵdid(1, 245760, null, 0, i5.ToolbarPrintBtnComponent, [i6.LoggerService, i7.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarPrintBtnComponentNgFactory = i1.ɵccf("app-toolbar-print-btn", i5.ToolbarPrintBtnComponent, View_ToolbarPrintBtnComponent_Host_0, { processing: "processing" }, { action: "action" }, []);
export { ToolbarPrintBtnComponentNgFactory as ToolbarPrintBtnComponentNgFactory };
