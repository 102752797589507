<app-content-w-header header="Stats">
  <mat-card class="toolbar subheader">
    <div class="options">
      <div class="option">
        <dx-check-box text="Show Totals Prior" [(value)]="showTotalsPrior"></dx-check-box>
      </div>
      <div class="option">
        <dx-check-box text="Data Field Headers in Rows" [(value)]="rowsDataFieldArea"></dx-check-box>
      </div>
      <div class="option">
        <dx-check-box text="Tree Row Header Layout" [(value)]="treeHeaderLayout"></dx-check-box>
      </div>
    </div>

    <div class="dx-fieldset" style="">
      <!--      <dx-toolbar>-->
      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>
      <!--        </dxi-item>-->

      <div>
        <dx-check-box text="Get Broker Trips" [(value)]="getBrokerTrips"></dx-check-box>
      </div>

      <div>
        <dx-check-box [visible]="getBrokerTrips" text="Get Dummy Trips" [(value)]="getDummyTrips"></dx-check-box>
      </div>

      <div>
        <dx-check-box text="Get Scheduled Trips" [(value)]="getScheduledTrips"></dx-check-box>
      </div>

      <!--        <dxi-item location="after" locateInMenu="auto">-->
      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filterClaims()"></dx-button>
      </div>
      <!--        </dxi-item>-->

      <!--      </dx-toolbar>-->
    </div>
  </mat-card>

  <dx-pivot-grid
    class="content"
    width="100%"
    height="100%"
    [dataSource]="dso"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showTotalsPrior]="showTotalsPrior ? 'both' : 'none'"
    [dataFieldArea]="rowsDataFieldArea ? 'row' : 'column'"
    [rowHeaderLayout]="treeHeaderLayout ? 'tree' : 'standard'"
    [stateStoring]="grid_stateStoring">
    <!--    <dxo-field-chooser-->
    <!--      applyChangesMode="onDemand"-->
    <!--    ></dxo-field-chooser>-->

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true">
    </dxo-field-panel>

    <dxo-scrolling [useNative]="true"></dxo-scrolling>
  </dx-pivot-grid>

  <!--  <dx-data-grid-->
  <!--    class="content"-->
  <!--    height="100%"-->

  <!--    [dataSource]="dso"-->
  <!--    [stateStoring]="grid_stateStoring"-->
  <!--    [twoWayBindingEnabled]="false"-->
  <!--    [repaintChangesOnly]="true"-->

  <!--    [errorRowEnabled]="false"-->
  <!--    [searchPanel]="{visible: false}"-->
  <!--    [headerFilter]="{visible: true}"-->
  <!--    [filterRow]="{visible: true}"-->
  <!--    [filterPanel]="{visible: true}"-->
  <!--  >-->
  <!--    <dxo-editing-->
  <!--      [allowAdding]="false"-->
  <!--      [allowDeleting]="false"-->
  <!--      [allowUpdating]="false"-->
  <!--    ></dxo-editing>-->

  <!--    <dxo-master-detail-->
  <!--      [enabled]="false"-->
  <!--      template="detail"-->
  <!--    ></dxo-master-detail>-->

  <!--    <dxo-paging-->
  <!--      [pageSize]="config?.config?.itemsPerPage || 50"-->
  <!--    ></dxo-paging>-->

  <!--    <dxo-selection-->
  <!--      [mode]="'multiple'"-->
  <!--      [selectAllMode]="'allPages'"-->
  <!--    ></dxo-selection>-->

  <!--    &lt;!&ndash; region Columns &ndash;&gt;-->

  <!--    &lt;!&ndash;<dxi-column type="selection" [sortIndex]="0"></dxi-column>&ndash;&gt;-->

  <!--    <dxi-column caption="Facility"-->
  <!--                dataField="tenantId"-->
  <!--                dataType="string"-->
  <!--    >-->
  <!--      <dxo-lookup-->
  <!--        [dataSource]="facilityDso$ | async"-->
  <!--        valueExpr="id"-->
  <!--        displayExpr="shortname">-->
  <!--      </dxo-lookup>-->
  <!--    </dxi-column>-->

  <!--    <dxi-column caption="Clients Signed"-->
  <!--                dataField="signedClients"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Signatures Collected"-->
  <!--                dataField="signatures"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Auth Trips"-->
  <!--                dataField="authTrips"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="UnAuth Trips"-->
  <!--                dataField="unAuthTrips"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Dummy Trips"-->
  <!--                dataField="dummyTrips"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Ineligible Trips"-->
  <!--                dataField="ineligibleTrips"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="TripIDs Received (from Broker)"-->
  <!--                dataField="receivedTrips"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Trips Scheduled (on Manifest)"-->
  <!--                dataField="scheduled"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Did Not Attend"-->
  <!--                dataField="didNotAttend"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    <dxi-column caption="Missing Signatures"-->
  <!--                dataField="missingSignatures"-->
  <!--                dataType="number"-->
  <!--    ></dxi-column>-->

  <!--    &lt;!&ndash;    &ndash;&gt;-->

  <!--    &lt;!&ndash; endregion Columns &ndash;&gt;-->

  <!--    &lt;!&ndash; region Templates &ndash;&gt;-->

  <!--    &lt;!&ndash; endregion Templates &ndash;&gt;-->

  <!--  </dx-data-grid>-->
</app-content-w-header>

<!-- Templates -->
