<dx-data-grid
  width="auto"
  [dataSource]="dso"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="false"
  (onInitialized)="grid_onInitialized($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <!-- Settings -->

  <dxo-editing [allowAdding]="false" [allowDeleting]="true" [allowUpdating]="true"></dxo-editing>

  <dxo-group-panel [visible]="false"></dxo-group-panel>

  <dxo-header-filter [visible]="false"></dxo-header-filter>

  <dxo-paging [pageSize]="10"></dxo-paging>

  <!-- Columns -->

  <dxi-column caption="Name" dataField="name" [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="Type" dataField="documentType_name" [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="Object Type" dataField="documentType_objectFQN" [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="File"
    dataField="files_0_originalFilename"
    cellTemplate="href_cell"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Uploaded At"
    dataField="files_0_uploadedAt"
    dataType="date"
    format="shortDateShortTime"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Uploaded By"
    dataField="files_0_uploadedById"
    [allowHeaderFiltering]="false"
    [visible]="false"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'href_cell'">
    <a
      target="_blank"
      [href]="cellInfo.data.files_0_id | fileSignedUrl | async | sanitizeUrl"
      title="{{ cellInfo.displayValue }}"
      >{{ cellInfo.text }}</a
    >
  </ng-container>
</dx-data-grid>

