import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { compact, isNil } from 'lodash-es';
import moment, { utc } from 'moment/moment';
import { Options, RRule } from 'rrule';
import { oc } from 'ts-optchain';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../shared/classes/loopback-custom-store/generic/store.utils';
import { isAlpha } from '../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../shared/modules/my-common/services/datasource.service';
import { ConsumerInterface, SignatureInterface } from '../../../shared/sdk';

export interface IChcClaimRequest {
  controlNumber?: string;
  tradingPartnerServiceId?: string;
  submitter?: {
    organizationName?: string;
    contactInformation?: {
      name?: string;
      phoneNumber?: string;
    };
  };
  receiver?: {
    organizationName?: string;
  };
  billing?: {
    providerType?: string;
    organizationName?: string;
    employerId?: string; // Provider tax identification number.
    commercialNumber?: string;
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      postalCode?: string;
    };
  };
  subscriber?: {
    paymentResponsibilityLevelCode?: 'P';
    memberId?: string;
    firstName?: string;
    lastName?: string;
    gender?: 'M' | 'F';
    dateOfBirth?: string; // YYYYMMDD
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      postalCode?: string;
    };
  };
  claimInformation?: {
    claimFilingCode?: 'CI'; // Comercial Insurance
    patientControlNumber?: string; // GUID
    claimChargeAmount?: string;
    placeOfServiceCode?: '12';
    claimFrequencyCode?: '1' | '7'; // 22. Resubmission Code
    signatureIndicator?: 'Y'; // Signature on file
    planParticipationCode?: 'A'; // Accept Assignment
    benefitsAssignmentCertificationIndicator?: 'Y'; // 13
    releaseInformationCode?: 'Y'; // 12
    claimSupplementalInformation?: {
      priorAuthorizationNumber?: string;
      claimControlNumber?: string; // for correction
    };
    healthCareCodeInformation?: {
      diagnosisTypeCode?: 'ABK';
      diagnosisCode?: 'R69';
    }[];
    serviceLines?: {
      serviceDate?: string; // YYYYMMDD
      serviceDateEnd?: string; // YYYYMMDD
      professionalService?: {
        procedureIdentifier?: 'HC';
        measurementUnit?: 'UN';
        procedureCode?: string;
        serviceUnitCount?: string;
        lineItemChargeAmount?: string;
        compositeDiagnosisCodePointers?: {
          diagnosisCodePointers?: string[];
        };
      };
    }[];
  };
}

export interface IChcE9yDoc {
  _id: string;
  hash: string;
  request: {
    yyyymm: number;
    yyyymmdd: number;
    date: string;
    payerID: string;
    memberID?: string;
    mci?: string;
    firstname: string;
    lastname: string;
    dob: string;
  };
  response?: IChcE9yResponse;
  e9y?: string;
  active: boolean;
  ctime: Date;
}

export interface INavinetAuthDoc {
  _id;
  _mci: string;
  _manifest: any;

  _tenantId?: number;
  _replaced?: boolean;
  __claims?: TAppMealClaimSet[];

  MemberID;
  MedicaidID;
  Name;
  LastName;
  FirstName;
  Phone;
  MemberDOB;
  IOType;
  RequestType;
  RequestStatus;
  AuthNumberFacets;
  AuthNumberUMI;
  StartDT;
  EndDT;
  AuthUnitsApproved;
  RSProviderName;
  Code;
  ProviderType;
  ProviderName;
  Duration;
  Frequency;
  Note;
  ServiceCoordinatorName;
  ServiceCoordinatorPhone;
  ServiceCoordinatorEmail;
  AuthLastModifiedDT;
}

export interface IChcE9yResponse {
  controlNumber?: string;
  reassociationKey?: string;
  tradingPartnerServiceId?: string;
  provider?: {
    providerName?: string;
    entityIdentifier?: string;
    entityType?: string;
    npi?: string;
  };
  subscriber: {
    memberId?: string;
    firstName?: string;
    lastName?: string;
    gender?: 'M' | 'F';
    entityIdentifier?: string;
    entityType?: 'Person';
    dateOfBirth?: string; // YYYYMMDD
    address?: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      postalCode?: string;
    };
    aaaErrors?: any[];
  };
  payer?: {
    entityIdentifier?: 'Payer';
    entityType?: 'Non-Person Entity';
    name?: string;
    payorIdentification?: string;
    contactInformation?: NodeJS.Dict<any>;
  };
  planStatus?: NodeJS.Dict<any>[];
  planInformation?: {
    hicNumber?: string;
    idCardNumber?: string; // mci
    medicaidRecipientIdNumber?: string; // mci
  };
  benefitsInformation?: {
    [k: string]: any;
    code?: string;
    name?: string;
  }[];
}

export interface IChcClaimResponse {
  status: string;
  controlNumber: string;
  tradingPartnerServiceId: string;
  claimReference: {
    correlationId: string;
    submitterId: string;
    customerClaimNumber: string;
    patientControlNumber: string;
    timeOfResponse: string;
    claimType: 'PRO';
    formatVersion: '5010';
    rhclaimNumber: string;
  };
  editStatus: string;
  payer: {
    payerName: string;
    payerID: string;
  };
}

export interface IAppMealAuthSet {
  auth: INavinetAuthDoc;
  consumer?: ConsumerInterface;
  e9ies: { e9y?: IChcE9yDoc; d: string }[];
  signs: SignatureInterface[];

  meta: {
    hash: string;
    mci: string;
    code: string;
    yyyymm: number;
    yyyymmdd: number;
    billingRange: [number, number]; // [yyyymmdd, yyyymmdd]
    authUnits: number;
    signUnits: number;
  };
}

export interface IAppMealClaim {
  guid?: string;
  request?: IChcClaimRequest;
  response?: IChcClaimResponse;
  userId?: number;
  ctime: Date;
  utime: Date;
  status:
    | 'BUILD'
    | 'FAILED'
    | 'PREPARED'
    | 'BILLED'
    | 'PENDED'
    | 'ACCEPTED'
    | 'PAID'
    | 'ACCEPTED:PARTIAL'
    | 'PAID:PARTIAL'
    | 'REJECTED';

  statusDoc?: any;
  statusClaimID?: string;
  statusCode?: string;
  statusNote?: string;

  corrections?: IAppMealClaim[];
}

export type TAppMealClaimSet = IAppMealAuthSet & {
  _id: string;
  claim?: IAppMealClaim;
  removed?: IAppMealClaim[];
};

export interface IClaimData {
  claimId?: string;
  authId: string;
  authID: string;
  payerID: string;
  rate: number;
  chargeAmount: number;
  correction?: {
    claimFrequencyCode?: '1' | '2' | '3' | '7';
    claimControlNumber?: string;
  };
  subscriber: {
    memberID: string;
    mci: string;
    firstname: string;
    lastname: string;
    gender: 'M' | 'F';
    dob: string; // YYYY-MM-DD
  };
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  service: {
    dateBegin: string; // YYYY-MM-DD
    dateEnd: string; // YYYY-MM-DD
    procedureCode: string;
    unitCount: number;
    chargeAmount: number;
  };
}

export interface IAuthMember {
  last: INavinetAuthDoc;

  __units: number; // meals units count

  auths: INavinetAuthDoc[];
  authCount: number;
  authUnits: number;

  e9y: (IChcE9yDoc | undefined)[];
  __e9y: (string | undefined)[];
  __e9yNames: (string | undefined)[];
  __e9yCount: number;

  claims: TAppMealClaimSet[];
  __claimCount: number;
  __claims_codes: string[];
  __claims_authUnits: number[];
  __claims_statuses: (string | undefined)[];

  statuses: any[];

  __state: string;
}

@Injectable()
export class AuthClaimService {
  constructor(private dss: DataSourceService) {}

  getProcedureCodes() {
    return isAlpha() ? ['W1894', 'W1895'] : ['W1759', 'W1760', 'W1761', 'W1762', 'W1764'];
  }

  getPayerIDs() {
    return ['8121', '6751', '7740', '2803'];
  }

  getClaimStatuses() {
    return [
      'BUILD',
      'FAILED',
      'PREPARED',
      'BILLED',
      'PENDED',
      'ACCEPTED',
      'PAID',
      'ACCEPTED:PARTIAL',
      'PAID:PARTIAL',
      'REJECTED',
    ];
  }

  getCodeMap() {
    return {
      W1759: 'Hot',
      W1760: 'Frozen',
      W1761: 'Sandwich',
      W1762: 'Emergency',
      W1764: 'Special',
      //
      W1894: 'Installation',
      W1895: 'Maintenance',
    };
  }

  codeDisplayExpr = (item: any) => {
    return item ? item._id + ' - ' + this.getCodeMap()[item._id] : undefined;
  };

  buildCodeDso(dss: DataSourceService) {
    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },
        },
      },
      {
        $group: { _id: '$Code' },
      },
      {
        $sort: { _id: 1 },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(dss, col, loadOptions, aggregate).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(dss, col, key).toPromise();
      },
    });
    return { store } as DataSourceOptions;
  }

  getResourceDataSet() {
    return [
      {
        text: 'Hot',
        id: 'a:W1759',
        color: '#da756b',
      },
      {
        text: 'Frozen',
        id: 'a:W1760',
        color: '#da756b',
      },
      {
        text: 'Sandwich',
        id: 'a:W1761',
        color: '#da756b',
      },
      {
        text: 'Emergency',
        id: 'a:W1762',
        color: '#da756b',
      },
      {
        text: 'Special',
        id: 'a:W1764',
        color: '#da756b',
      },
      {
        text: 'Hot',
        id: 's:W1759',
        color: '#2eb3d2',
      },
      {
        text: 'Frozen',
        id: 's:W1760',
        color: '#2eb3d2',
      },
      {
        text: 'Special',
        id: 's:W1764',
        color: '#2eb3d2',
      },
      {
        text: 'Hot',
        id: 's?:W1759',
        color: '#265a78',
      },
      {
        text: 'Frozen',
        id: 's?:W1760',
        color: '#265a78',
      },
      {
        text: 'Special',
        id: 's?:W1764',
        color: '#265a78',
      },
      {
        text: 'Hot',
        id: 'c:W1759',
        color: '#c266c9',
      },
      {
        text: 'Frozen',
        id: 'c:W1760',
        color: '#c266c9',
      },
      {
        text: 'Special',
        id: 'c:W1764',
        color: '#c266c9',
      },
    ];
  }

  getAuthRRule(auth: INavinetAuthDoc) {
    const _map1 = {
      Su: RRule.SU,
      M: RRule.MO,
      T: RRule.TU,
      W: RRule.WE,
      Th: RRule.TH,
      F: RRule.FR,
      Sa: RRule.SA,
    };

    const _map2 = {
      yearly: RRule.YEARLY,
      monthly: RRule.MONTHLY,
      weekly: RRule.WEEKLY,
    };

    const from = moment(utc(auth.StartDT).format('YYYY-MM-DD'));
    const to = moment(utc(auth.EndDT).format('YYYY-MM-DD'));

    const rOpts: Partial<Options> = {
      freq: _map2[auth.Frequency.toLowerCase()],
      byweekday: compact(
        Object.entries(oc(auth)._manifest({}))
          .filter(([k, v]) => !isNil(v) && v > 0)
          .map(([k, v]) => _map1[k]),
      ),
      dtstart: from.clone().hours(12).toDate(),
      until: to.clone().hours(12).toDate(),
    };

    return new RRule(rOpts);
  }
}
