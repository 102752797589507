<div mat-dialog-content>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Service Type</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="value" [dataSource]="serviceTypes"></dx-select-box>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value">Set</button>
</div>
