import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
//
import { DocumentDetailsTabComponent } from './components/document-details-tab/document-details-tab.component';
import { DocumentDetailsTabsComponent } from './components/document-details-tabs/document-details-tabs.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { DocumentFormComponent } from './components/document-form/document-form.component';
import { DocumentGridComponent } from './components/document-grid/document-grid.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentComponent } from './containers/document/document.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule],
  declarations: [
    DocumentComponent,
    DocumentListComponent,
    DocumentGridComponent,
    DocumentDetailsComponent,
    DocumentDetailsTabsComponent,
    DocumentDetailsTabComponent,
    DocumentFormComponent,
  ],
  exports: [DocumentComponent],
  entryComponents: [DocumentComponent, DocumentFormComponent],
})
export class DocumentModule {}
