import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Signature, Vehicle, VehicleGeotab, Facility } from '../../../../shared/sdk/models';
import { LoggerService, MyUtilsApi, } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
import moment from 'moment';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { InternalStorage } from 'src/app/shared/sdk';
export const TIMEZONE = 'America/New_York';
const EVENTS = ['Pickups', 'Dropoffs'];
const SOURCES = ['Tablet GPS', 'Geotab GPS'];
export class VehicleDetailsTabRoutesComponent extends ABaseModelPaneWToolbarComponent {
    constructor(logger, ui, dss, dialog, config, internalStorage, myUtilsAPI) {
        super(logger, ui, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.config = config;
        this.internalStorage = internalStorage;
        this.myUtilsAPI = myUtilsAPI;
        this.markerBaseUrl = '/assets/images/';
        this.markers = [];
        this.routes = [];
        this.selectedDate = new Date();
        this.positions = [];
        this.signatures = [];
        this.events = [...EVENTS];
        this.selectedEvents = [...EVENTS];
        this.sources = [...SOURCES];
        this.selectedSources = [...SOURCES];
        this.clients = [];
        this.selectedClients = [];
        this.loops = [];
        this.selectedLoop = undefined;
        this.tenants = [];
        this.tenantsMap = {};
        this.caption = 'Trips History';
    }
    get ModelClass() {
        return Vehicle;
    }
    get FormComponent() {
        return VehicleFormComponent;
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.model = model;
            this.initData();
            yield this.getData();
            yield this.prepareMapData();
        });
    }
    onDateValueChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.initData();
            yield this.getData();
            yield this.prepareMapData();
        });
    }
    initData() {
        this.loops = [];
        this.selectedLoop = undefined;
        this.tenantsMap = {};
    }
    onValueChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (e.event)
                yield this.prepareMapData(false);
        });
    }
    getData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const start = { $dateFromString: { dateString: moment(this.selectedDate).startOf('day').toISOString() } };
            const end = { $dateFromString: { dateString: moment(this.selectedDate).endOf('day').toISOString() } };
            const stages = [
                { $match: { vin: this.model.vin, month: +moment(this.selectedDate).format('YYYYMM') } },
                {
                    $project: Object.assign({ vin: 1, deviceId: 1, month: 1, internalId: 1 }, { positions: {
                            $filter: Object.assign({ input: '$positions', as: 'position' }, { cond: { $and: [{ $gte: ['$$position.dateTime', start] }, { $lte: ['$$position.dateTime', end] }] } }),
                        } }),
                },
            ];
            const data = yield this.dss.getApi(VehicleGeotab).aggregate(stages).toPromise();
            this.positions = (data[0] || {}).positions || [];
            this.signatures = yield this.dss
                .getApi(Signature)
                .find({
                where: {
                    vehicleId: this.model.id,
                    vdate: moment(this.selectedDate).format('YYYY-MM-DD'),
                },
                include: [{ employee: 'person' }, { consumer: 'person' }],
            }, headersAllTenantsAppend)
                .toPromise();
            if (this.signatures[0]) {
                const clientsMap = this.signatures.reduce((p, s) => {
                    if (s.consumer && s.consumer.person) {
                        const { firstname, lastname } = s.consumer.person;
                        return Object.assign({}, p, { [s.consumer.id]: `${firstname} ${lastname}` });
                    }
                    return p;
                }, {});
                this.clients = Object.values(clientsMap);
                this.selectedClients = [...this.clients];
            }
        });
    }
    prepareMapData(populateLoops = true) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('prepareMapData');
            console.log(this.selectedLoop);
            let routes = [];
            let locations = [];
            let markers = [];
            const { markers: signMarkers, ePerson } = this.getSignatureMarkers();
            if (this.positions.length > 1) {
                markers = [...markers, ...this.getStartFinishMarkers(this.positions, ePerson)];
            }
            let loopNumber = 0;
            let loopRoutes = [];
            let loopMarkers = [];
            let loops = [];
            let prevPos = null;
            this.positions.forEach((pos, i) => {
                const location = [pos.latitude, pos.longitude];
                locations.push(location);
                if (pos.speed === 0 && pos.duration > 90) {
                    loopMarkers.push(this.getStopMarker(pos, ePerson));
                }
                const isNewLoop = prevPos && prevPos.loop && pos && pos.loop && prevPos.loop.loopId !== pos.loop.loopId;
                if (isNewLoop) {
                    const endDate = pos.loop.startDate;
                    loops.push(Object.assign({}, prevPos.loop, { endDate, name: moment(prevPos.loop.startDate).format('LT') }));
                    this.tenantsMap[prevPos.tenantId] = Object.assign({ tenantId: prevPos.tenantId, latitude: prevPos.latitude, longitude: prevPos.longitude }, prevPos.tenantPosition);
                }
                if (isNewLoop || locations.length > 24 || i === this.positions.length - 1) {
                    loopRoutes.push(this.getRoute(locations, loopNumber));
                    locations = [location];
                }
                if (isNewLoop || i === this.positions.length - 1) {
                    if (!this.selectedLoop || (isNewLoop && prevPos.loop.loopId == this.selectedLoop.loopId)) {
                        routes = [...routes, ...loopRoutes];
                        markers = [...markers, ...loopMarkers];
                    }
                    loopRoutes = [];
                    loopMarkers = [];
                    loopNumber++;
                }
                prevPos = pos;
            });
            if (populateLoops) {
                this.loops = [...loops];
                this.tenants = [];
                if (Object.keys(this.tenantsMap).length) {
                    this.tenants = yield this.dss
                        .getApi(Facility)
                        .find({
                        where: { id: { inq: Object.keys(this.tenantsMap) } },
                    }, headersAllTenantsAppend)
                        .toPromise();
                }
            }
            this.routes = [...routes];
            this.markers = [...this.getTenantMarkers(), ...markers, ...signMarkers];
        });
    }
    getRoute(locations, loopNumber) {
        const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
        return {
            weight: 4,
            color: colors[loopNumber % colors.length],
            opacity: 0.8,
            mode: '',
            locations: [...locations],
        };
    }
    getSignatureMarkers() {
        let ePerson = {};
        let markers = [];
        this.signatures.forEach(s => {
            ePerson = (s.employee && s.employee.person) || ePerson;
            const cPerson = (s.consumer && s.consumer.person) || {};
            const { firstname, lastname } = cPerson;
            if (s.meta.pickUpDeviceLocation &&
                this.selectedEvents.includes('Pickups') &&
                this.selectedClients.includes(`${firstname} ${lastname}`)) {
                const tc = moment.tz(`${s.vdate} ${s.pickupTime}`, TIMEZONE).utc().format();
                if (this.selectedSources.includes('Tablet GPS') &&
                    (!this.selectedLoop || (this.selectedLoop.startDate < tc && tc < this.selectedLoop.endDate))) {
                    markers.push(this.getSignatureMarker(true, s.meta.pickUpDeviceLocation, tc, ePerson, cPerson));
                }
                if (this.selectedSources.includes('Geotab GPS')) {
                    const p = this.findStopPosition(tc);
                    if (p)
                        markers.push(this.getSignatureMarkerTmp(true, p, ePerson, cPerson));
                }
            }
            if (s.meta.dropOffDeviceLocation &&
                this.selectedEvents.includes('Dropoffs') &&
                this.selectedClients.includes(`${firstname} ${lastname}`)) {
                const tc = moment.tz(`${s.vdate} ${s.dropoffTime}`, TIMEZONE).utc().format();
                if (this.selectedSources.includes('Tablet GPS') &&
                    (!this.selectedLoop || (this.selectedLoop.startDate < tc && tc < this.selectedLoop.endDate))) {
                    markers.push(this.getSignatureMarker(false, s.meta.dropOffDeviceLocation, tc, ePerson, cPerson));
                }
                if (this.selectedSources.includes('Geotab GPS')) {
                    const p = this.findStopPosition(tc);
                    if (p)
                        markers.push(this.getSignatureMarkerTmp(false, p, ePerson, cPerson));
                }
            }
        });
        return { markers, ePerson };
    }
    findStopPosition(time) {
        return (time &&
            this.positions.find(({ dateTime, duration, speed, loop }) => !speed &&
                moment(dateTime).add(duration + 60, 'seconds') > moment(time) &&
                (!this.selectedLoop || (loop && loop.loopId == this.selectedLoop.loopId))));
    }
    getSignatureMarker(isPickUp, { lat, lng, isGeoTab }, tc, ePerson, cPerson) {
        return {
            iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}.png`,
            location: `${lat}, ${lng}`,
            tooltip: {
                text: `<strong>${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}</strong>` + // Header
                    `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
                    `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
                    `<br/>${(isGeoTab && 'GeoTab') || ''}`,
                isShown: false,
            },
        };
    }
    getSignatureMarkerTmp(isPickUp, { latitude, longitude, dateTime, duration, speed, loop }, ePerson, cPerson) {
        return {
            iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}-tmp.png`,
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
                    `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
                    `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
                    `<br/><em>Speed:</em> ${speed}` +
                    `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
                    `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}` +
                    `<br/>GeoTab`,
                isShown: false,
            },
        };
    }
    getStopMarker({ latitude, longitude, dateTime, duration, loop }, ePerson) {
        return {
            iconSrc: this.markerBaseUrl + 'marker-stop.png',
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
                    `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
                    `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
                    `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}`,
                isShown: false,
            },
        };
    }
    getTenantMarkers() {
        return this.tenants.map(t => ({
            iconSrc: `${this.markerBaseUrl}marker-facility.png`,
            location: `${this.tenantsMap[t.id].latitude}, ${this.tenantsMap[t.id].longitude}`,
            tooltip: { text: `${t.name}`, isShown: false },
        }));
    }
    getStartFinishMarkers(positions, ePerson) {
        const isToday = moment(this.selectedDate).isSame(new Date(), 'day');
        this.selectedDate;
        return [
            Object.assign({}, positions[0], { iconSrc: this.markerBaseUrl + 'marker-start.png', isStart: true }),
            Object.assign({}, positions[positions.length - 1], { iconSrc: this.markerBaseUrl + `marker-${isToday ? 'current' : 'finish'}.png` }),
        ].map(({ dateTime, speed, currentStateDuration, isDriving, latitude, longitude, iconSrc, isStart }) => ({
            iconSrc,
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/><em>${isStart ? 'Start' : isToday ? '' : 'Finish'} Time:</em> ${moment(dateTime).format('LT')}` +
                    `<br/><em>Driving:</em> ${(isDriving && 'yes') || 'not'}` +
                    `<br/><em>Speed:</em> ${speed}` +
                    `<br/><em>State Duration:</em> ${moment.duration(currentStateDuration, 'seconds').humanize()}`,
                isShown: false,
            },
        }));
    }
    getGeoTabZonePoints(id, attempt = 0) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const geoTabAuth = this.internalStorage.get('geoTabAuth') || (yield this.myUtilsAPI.geoTabAuth().toPromise());
                this.internalStorage.set('geoTabAuth', geoTabAuth);
                const api = new GeotabApi(geoTabAuth);
                const [[zone]] = yield api.multiCall([['Get', { typeName: 'Zone', search: { id } }]]);
                return (zone && zone.points.map(({ x, y }) => ({ latitude: y, longitude: x }))) || null;
            }
            catch (err) {
                const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
                if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.')
                    throw msg;
                if (attempt)
                    return {};
                this.internalStorage.remove('geoTabAuth');
                return yield this.getGeoTabZonePoints(id, 1);
            }
        });
    }
}
