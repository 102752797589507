import { Pipe, PipeTransform } from '@angular/core';
import trim from 'lodash-es/trim';

@Pipe({
  name: 'trim',
})
export class TrimPipe implements PipeTransform {
  transform(value: string): string {
    return value && value.length ? trim(value) : value;
  }
}
