import { HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import { LoadOptions } from 'devextreme/data/load_options';
import { ExtSDKModels } from '../../../../modules/ext-sdk/services/ext-sdk-models.service';
import { BaseLoopBackApi, SDKModels, TCustomOptions } from '../../../../sdk';
import { capitalizeFirstLetter } from '../../../utils/string.utils';
import { LoopBackLoadOptionsConverter } from '../load-options-converters/LoopBackLoadOptionsConverter';
import { errorMapFn } from './LoopBackStoreOptions';
import CustomStoreOptions = DevExpress.data.CustomStoreOptions;

export class LoopBackRelatedStoreOptions<T> implements CustomStoreOptions {
  useDefaultSearch = true;
  cacheRawData = false;

  noSql = false;
  useRegExp = false;
  customHeaders: { [name: string]: string } = {};
  customFilter: {
    where?: any;
    fields?: string | string[] | { [property: string]: boolean };
    include?: string | string[] | any[];
    order?: string | string[];
  } = {};
  customOptions: TCustomOptions = {};

  constructor(
    @Inject(SDKModels) protected models: ExtSDKModels,
    protected loopBackRelatedApi: BaseLoopBackApi,
    protected relation: string,
    protected relatedId: any | number | string,
  ) {}

  get key() {
    return this.models.get(this.loopBackRelatedApi.getModelName()).getModelDefinition().idName;
  }

  protected applyHeaders(headers: HttpHeaders): HttpHeaders {
    Object.entries(this.customHeaders || {}).forEach(([k, v]) => (headers = headers.append(k, v)));
    return headers;
  }

  public setRelatedId = (id: any | number | string) => (this.relatedId = id);

  public getRelatedId = (): any | number | string => this.relatedId;

  /**
   *
   */
  byKey = (key: any | string | number): Promise<T> =>
    this.loopBackRelatedApi[`findById${capitalizeFirstLetter(this.relation)}`](
      this.relatedId,
      key,
      this.applyHeaders.bind(this),
      this.customOptions,
    )
      .toPromise()
      .catch(error => errorMapFn(error, 'Data Loading Error'));

  /**
   *
   */
  load = (options: LoadOptions): Promise<T[]> =>
    this.loopBackRelatedApi[`get${capitalizeFirstLetter(this.relation)}`](
      this.relatedId,
      new LoopBackLoadOptionsConverter({ useRegExp: this.useRegExp, noSql: this.noSql }).convert(options),
      this.applyHeaders.bind(this),
      this.customOptions,
    )
      .toPromise()
      .catch(error => errorMapFn(error, 'Data Loading Error'));

  /**
   *
   */
  totalCount = (loadOptions: { filter?: any; group?: any }): Promise<number> =>
    this.loopBackRelatedApi[`count${capitalizeFirstLetter(this.relation)}`](
      this.relatedId,
      new LoopBackLoadOptionsConverter({ useRegExp: this.useRegExp, noSql: this.noSql }).loadOptionsFilterToWhere(
        loadOptions.filter,
      ),
      this.applyHeaders.bind(this),
      this.customOptions,
    )
      .toPromise()
      .then(({ count }) => count)
      .catch(error => errorMapFn(error, 'Data Count Error'));

  /**
   *
   */
  insert = (values: any): Promise<any> =>
    this.loopBackRelatedApi[`create${capitalizeFirstLetter(this.relation)}`](
      this.relatedId,
      values as any,
      this.applyHeaders.bind(this),
      this.customOptions,
    )
      .toPromise()
      .catch(error => errorMapFn(error, 'Data Insert Error'));

  /**
   *
   */
  update = (key: any | string | number, values: any): Promise<any> =>
    (Object.keys(values).length === 0
      ? this.loopBackRelatedApi[`findById${capitalizeFirstLetter(this.relation)}`](
          this.relatedId,
          key,
          this.applyHeaders.bind(this),
          this.customOptions,
        )
      : this.loopBackRelatedApi[`updateById${capitalizeFirstLetter(this.relation)}`](
          this.relatedId,
          key,
          values,
          this.applyHeaders.bind(this),
          this.customOptions,
        )
    )
      .toPromise()
      .catch(error => errorMapFn(error, 'Data Update Error'));

  /**
   *
   */
  remove = (key: any | string | number): Promise<void> =>
    this.loopBackRelatedApi[`destroyById${capitalizeFirstLetter(this.relation)}`](
      this.relatedId,
      key,
      this.applyHeaders.bind(this),
      this.customOptions,
    )
      .toPromise()
      .then(() => undefined)
      .catch(error => errorMapFn(error, 'Data Remove Error'));
}
