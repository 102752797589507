import { Component, Inject, Input } from '@angular/core';
import { Vehicle, MyUser } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';

@Component({
  selector: 'app-vehicle-details-tab-notes',
  templateUrl: './vehicle-details-tab-notes.component.html',
  styleUrls: ['./vehicle-details-tab-notes.component.scss'],
  providers: [],
})
export class VehicleDetailsTabNotesComponent extends ABaseComponent {
  filter: any;
  objectType: string;
  objectId: number;
  @Input()
  set modelId(modelId: number) {
    if (modelId) this.filter = { vehicleId: modelId };
    this.objectType = Vehicle.getModelName();
    this.objectId = modelId;
  }

  @Input() extraFields: any;

  constructor(protected logger: LoggerService, private dss: DataSourceService) {
    super(logger);
  }
}

