<div mat-dialog-content>
  <mat-form-field>
    <textarea #edit [value]='value' matInput placeholder='Edit comment'></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button [mat-dialog-close]='false' mat-button>Cancel</button>
  <button [mat-dialog-close]='edit.value | trim' cdkFocusInitial mat-button>Ok</button>
</div>
