import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaustMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { DailyChecksDetailsComponent } from '../../components/daily-checks-details/daily-checks-details.component';
import { DailyChecksComponent } from '../../components/daily-checks/daily-checks.component';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
})
export class CheckListComponent extends ABaseComponent implements OnInit {
  $selectedIds$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedId$: Observable<any> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(DailyChecksComponent, { static: true }) grid: DailyChecksComponent;
  @ViewChild(DailyChecksDetailsComponent, { static: true }) details: DailyChecksDetailsComponent;

  constructor(protected logger: LoggerService) {
    super(logger);

    this.selectedId$ = this.$selectedIds$.pipe(map(ids => oc(ids)[0]()));

    this.$resize$
      .pipe(
        exhaustMap(() =>
          of(true).pipe(
            delay(1000),
            tap(() => {
              // this.grid.repaint();
              this.details.repaint();
            }),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(ids: any[]) {
    if (ids && ids.length) {
      this.$selectedIds$.next(ids);
    } else {
      this.$selectedIds$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}
