import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { FleetComponent } from './containers/fleet/fleet.component';
import { DailyChecksComponent } from './components/daily-checks/daily-checks.component';
import { CheckListComponent } from './containers/check-list/check-list.component';
import { DailyChecksDetailsComponent } from './components/daily-checks-details/daily-checks-details.component';
import { DailyChecksDetailsTabsComponent } from './components/daily-checks-details-tabs/daily-checks-details-tabs.component';
import { DailyChecksDetailsTabVehicleComponent } from './components/daily-checks-details-tab-vehicle/daily-checks-details-tab-vehicle.component';
import { DailyChecksDetailsTabInfoComponent } from './components/daily-checks-details-tab-info/daily-checks-details-tab-info.component';
import { DailyChecksDetailsTabFullComponent } from './components/daily-checks-details-tab-full/daily-checks-details-tab-full.component';
import { DailyChecksDetailsTabPicsComponent } from './components/daily-checks-details-tab-pics/daily-checks-details-tab-pics.component';
import { CheckReportFieldComponent } from './components/check-report-field/check-report-field.component';
import { DlgAddCommentComponent } from './dialogs/dlg-add-comment/dlg-add-comment.component';
import { VehicleUtilisationComponent } from './components/vehicle-utilisation/vehicle-utilisation.component';
import { DlgEditOdometerComponent } from './dialogs/dlg-edit-odometer/dlg-edit-odometer.component';

@NgModule({
  declarations: [
    FleetComponent,
    DailyChecksComponent,
    CheckListComponent,
    DailyChecksDetailsComponent,
    DailyChecksDetailsTabsComponent,
    DailyChecksDetailsTabVehicleComponent,
    DailyChecksDetailsTabInfoComponent,
    DailyChecksDetailsTabFullComponent,
    DailyChecksDetailsTabPicsComponent,
    CheckReportFieldComponent,
    VehicleUtilisationComponent,
    DlgAddCommentComponent,
    DlgEditOdometerComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, MyCommonModule, UiModule],
  entryComponents: [DlgAddCommentComponent, DlgEditOdometerComponent],
})
export class FleetModule {}
