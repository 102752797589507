<app-content-w-header header="MTM Payment Schedule Pivot">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>

    <div class="dx-fieldset" style="display: flex">
      <div>
        <dx-check-box text="Show Column Totals" [(value)]="showColumnTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Column Grand Totals" [(value)]="showColumnGrandTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box>
      </div>
    </div>
  </mat-card>

  <dx-pivot-grid
    class="content"
    width="100%"
    height="100%"
    [dataSource]="dataSource"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showTotalsPrior]="showTotalsPrior ? 'both' : 'none'"
    [dataFieldArea]="rowsDataFieldArea ? 'row' : 'column'"
    [rowHeaderLayout]="treeHeaderLayout ? 'tree' : 'standard'"
    [showColumnTotals]="showColumnTotals"
    [showRowTotals]="showRowTotals"
    [showColumnGrandTotals]="showColumnGrandTotals"
    [showRowGrandTotals]="showRowGrandTotals"
    [stateStoring]="grid_stateStoring">
    <dxo-field-chooser applyChangesMode="onDemand"></dxo-field-chooser>

    <dxo-export [enabled]="true" [fileName]="'MTM Payment Schedule Pivot'"></dxo-export>

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true">
    </dxo-field-panel>

    <dxo-scrolling [useNative]="true"></dxo-scrolling>
  </dx-pivot-grid>
</app-content-w-header>
