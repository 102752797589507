import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { SDKModels } from '../../../../sdk/services/custom';
import { ExtSDKModels } from '../../../ext-sdk/services/ext-sdk-models.service';
import { ConfigService } from '../../../my-common/services/config.service';

@Component({
  selector: 'app-object-title',
  templateUrl: './object-title.component.html',
  styleUrls: ['./object-title.component.scss'],
})
export class ObjectTitleComponent implements OnInit, OnChanges {
  @Input() modelName: string;
  @Input() modelId: number | string;
  @Input() field: string;

  objectTitle$: Observable<string>;

  constructor(
    @Inject(SDKModels) private models: ExtSDKModels,
    @Inject(ConfigService) public config: ConfigService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('app-object-title:', this.modelId);

    this.objectTitle$ =
      this.modelName && this.modelId && this.models.get(this.modelName)
        ? this.config.hasAnyRole$(['SU', 'ADMIN', 'MANAGER', 'BILLER']).pipe(
            // tap((has) => console.log(has)),
            switchMap(has =>
              this.models.getObjectTitle(this.models.get(this.modelName), this.modelId, this.field, has),
            ),
            startWith('...'),
          )
        : of('');
  }

  ngOnInit() {}
}
