import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-details-tabs',
  templateUrl: './document-details-tabs.component.html',
  styleUrls: ['./document-details-tabs.component.scss'],
})
export class DocumentDetailsTabsComponent implements OnInit {
  @Input() modelId: number | string;

  tabs: any[] = [
    {
      title: 'Details',
      template: 'details',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
