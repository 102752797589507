import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import sortBy from 'lodash-es/sortBy';
import moment, { utc } from 'moment';
import { Observable, of } from 'rxjs';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-mtm-api-logs',
  templateUrl: './mtm-api-logs.component.html',
  styleUrls: ['./mtm-api-logs.component.scss'],
})
export class MtmApiLogsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  // isSU$: Observable<boolean>;

  selectedDate?: string = moment().format('YYYY-MM-DD');

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);
  mtmGpsV2Statuses = [
    { id: 1, t: '1 - Ping' },
    { id: 2, t: '2 - Start Trip' },
    { id: 3, t: '3 - Pick Arrive' },
    { id: 4, t: '4 - Pick Perform' },
    { id: 6, t: '6 - Drop Arrive' },
    { id: 7, t: '7 - Drop Perform' },
    { id: 9, t: '9 - Member No Show' },
    { id: 10, t: '10 - Member Cancel' },
  ];

  statusMap = { 1: 'ping', 2: 'start', 3: 'pua', 4: 'pup', 6: 'doa', 7: 'dop', 9: 'ns', 10: 'c' };

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7776d037-26fa-4e37-ab41-34076ee8b4b5',
    };

    // this.isSU$ = this.common.store.pipe(
    //   select(getUser),
    //   switchMap(u => (!!u ? this.dss.getApi<MyUserApi>(MyUser).getRoles(u.id) : of([]))),
    //   map((roles: string[]) => roles.includes('SU')),
    // );
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    // this.gridHelper.handle(e.component, {
    //   flatToTreeObject: false,
    //   copyIdsOnSaving: false,
    //   selectRowOnEdit: false,
    //   notifyErrors: true,
    // });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      name: 'refresh',
      locateInMenu: 'auto',
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        text: 'Refresh',
        hint: 'Refresh',
        onClick: () => e.component.refresh(),
      },
    });
  }

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const col = '_TestMtmTripTrace';
    const aggregate = [
      {
        $match: {
          date: utc(this.selectedDate).format('YYYY-MM-DD'),
          '__trip._tripId': { $ne: null },
          action: 'pu',
          // __valid: true,
        },
      },
      {
        $lookup: {
          from: '_MtmApiLog',
          localField: '__trip._tripId',
          foreignField: 'params.tripInfo.0.tripId',
          as: 'calls',
        },
      },
      {
        $addFields: {
          callsCount: { $size: '$calls' },

          hasFailed: {
            $reduce: { input: '$calls', initialValue: false, in: { $or: ['$$value', '$$this.failed'] } },
          },

          allFailed: {
            $reduce: { input: '$calls', initialValue: true, in: { $and: ['$$value', '$$this.failed'] } },
          },

          statusSet: {
            $reduce: {
              input: '$calls',
              initialValue: [],
              in: { $setUnion: ['$$value', [{ $toString: '$$this.params.status' }]] },
            },
          },
        },
      },
      {
        $addFields: {
          statusesString: {
            $reduce: { input: '$statusSet', initialValue: '', in: { $concat: ['$$value', '$$this', '|'] } },
          },
        },
      },
      {
        $match: {
          callsCount: { $gt: 0 },
        },
      },
    ];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    return {
      store,
      map(itm: any) {
        itm._statuses = (itm.statusSet as string[])
          .map(s => self.statusMap['' + s] || s)
          .sort()
          .join(', ');

        itm.calls = sortBy(itm.calls, 'params.dateTimeCollected');

        return itm;
      },
    } as DataSourceOptions;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  onDateValueChanged(e) {
    this.dso = this.buildDataSource();
  }
}
