import {
  Facility,
  MyUser
} from '../index';

declare var Object: any;
export interface DayServiceInterface {
  "requestID": string;
  "dateTime"?: Date;
  "date"?: any;
  "timeInt"?: number;
  "marker"?: string;
  "flag"?: boolean;
  "value"?: string;
  "meta"?: any;
  "id"?: number;
  "tenantId"?: number;
  "userId"?: number;
  tenant?: Facility;
  user?: MyUser;
}

export class DayService implements DayServiceInterface {
  "requestID": string;
  "dateTime": Date;
  "date": any;
  "timeInt": number;
  "marker": string;
  "flag": boolean;
  "value": string;
  "meta": any;
  "id": number;
  "tenantId": number;
  "userId": number;
  tenant: Facility;
  user: MyUser;
  constructor(data?: DayServiceInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DayService`.
   */
  public static getModelName() {
    return "DayService";
  }

  /**
  * This method creates an instance of DayService for dynamic purposes.
  **/
  public static factory(data: DayServiceInterface): DayService{
    return new DayService(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DayService',
      plural: 'DayServices',
      path: 'DayServices',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "date": {
          name: 'date',
          type: 'any'
        },
        "timeInt": {
          name: 'timeInt',
          type: 'number'
        },
        "marker": {
          name: 'marker',
          type: 'string'
        },
        "flag": {
          name: 'flag',
          type: 'boolean'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
