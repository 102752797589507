/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trip-manifest.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/trip-manifest-tabs/trip-manifest-tabs.component.ngfactory";
import * as i3 from "../../../employee/services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Employee";
import * as i5 from "../../../../shared/sdk/services/custom/EmployeeView";
import * as i6 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../shared/sdk/services/custom/Config";
import * as i10 from "../../../../shared/sdk/services/custom/Facility";
import * as i11 from "../../../../shared/sdk/services/custom/MyFile";
import * as i12 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i13 from "../../../consumer/services/helper.service";
import * as i14 from "../../../../shared/sdk/services/custom/Consumer";
import * as i15 from "../../../../shared/sdk/services/custom/ConsumerView";
import * as i16 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i17 from "@angular/common/http";
import * as i18 from "../../../../shared/modules/ui/services/ui.service";
import * as i19 from "../../services/helper.service";
import * as i20 from "../../../../shared/sdk/services/custom/TripManifest";
import * as i21 from "../../../vehicle/services/helper.service";
import * as i22 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i23 from "../../components/trip-manifest-tabs/trip-manifest-tabs.component";
import * as i24 from "../../../../shared/sdk/services/custom/logger.service";
import * as i25 from "./trip-manifest.component";
var styles_TripManifestComponent = [i0.styles];
var RenderType_TripManifestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripManifestComponent, data: {} });
export { RenderType_TripManifestComponent as RenderType_TripManifestComponent };
export function View_TripManifestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-trip-manifest-tabs", [], null, null, null, i2.View_TripManifestTabsComponent_0, i2.RenderType_TripManifestTabsComponent)), i1.ɵprd(4608, null, i3.HelperService, i3.HelperService, [i4.EmployeeApi, i5.EmployeeViewApi, i6.VehicleApi, i7.ConfigService, i8.CommonService, i9.ConfigApi, i10.FacilityApi, i11.MyFileApi, i12.DataSourceService]), i1.ɵprd(4608, null, i13.HelperService, i13.HelperService, [i14.ConsumerApi, i15.ConsumerViewApi, i7.ConfigService, i16.PusherService, i17.HttpClient, i8.CommonService, i18.UiService]), i1.ɵprd(4608, null, i19.HelperService, i19.HelperService, [i17.HttpClient, i20.TripManifestApi, i8.CommonService, i7.ConfigService, i12.DataSourceService, "Window", i3.HelperService, i13.HelperService, i21.HelperService, i16.PusherService, i22.UploadHelperService]), i1.ɵdid(4, 49152, null, 0, i23.TripManifestTabsComponent, [i24.LoggerService, i1.ChangeDetectorRef], null, null)], null, null); }
export function View_TripManifestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trip-manifest", [], null, null, null, View_TripManifestComponent_0, RenderType_TripManifestComponent)), i1.ɵdid(1, 114688, null, 0, i25.TripManifestComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripManifestComponentNgFactory = i1.ɵccf("app-trip-manifest", i25.TripManifestComponent, View_TripManifestComponent_Host_0, {}, {}, []);
export { TripManifestComponentNgFactory as TripManifestComponentNgFactory };
