import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { PivotGridDataSourceField, PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import { chunk, flatten } from 'lodash-es';
import uniq from 'lodash-es/uniq';
import moment, { utc } from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import {
  Consumer,
  ConsumerView,
  ConsumerViewApi,
  LoggerService,
  MealsAdcSignatureStats,
  MealsAdcSignatureStatsApi,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-meals-facility-stats',
  templateUrl: './meals-facility-stats.component.html',
  styleUrls: ['./meals-facility-stats.component.scss'],
})
export class MealsFacilityStatsComponent extends ABaseComponent implements OnInit {
  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'id', visible: false },
      { dataField: 'tenantId', visible: false },
      { dataField: 'consumerId', visible: false },
      { dataField: 'vdate', visible: false },

      { caption: 'Year', dataField: 'vdate', dataType: 'date', groupInterval: 'year', displayFolder: 'date' },
      {
        caption: 'Month',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'month',
        displayFolder: 'date',
      },
      {
        caption: 'Day of Week',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: PivotCaption.Day, area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        displayFolder: 'date',
        area: 'column',

        selector: data => {
          const dateMoment = utc(data.vdate);
          return [dateMoment.format('MM/DD/YYYY'), dateMoment.format('dd')].join('-');
        },
      },

      {
        caption: 'Client',
        area: 'row',
        dataField: '__client',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Current MCO',
        dataField: '__curMco',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'MCO',
        dataField: '__mco',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Validation State',
        area: 'row',
        dataField: 'validationState',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },

      {
        caption: 'Total Units',
        area: 'data',
        dataField: 'units',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Hot',
        area: 'data',
        dataField: 'mu1',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Special',
        area: 'data',
        dataField: 'mu2',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Frozen',
        area: 'data',
        dataField: 'mu3',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Sandwich',
        area: 'data',
        dataField: 'mu4',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Emergency',
        area: 'data',
        dataField: 'mu5',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },
    ];
  }

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  dso = {
    store: new ArrayStore({ data: [] }),
    fields: this.pivotFields,
  } as PivotGridDataSourceOptions;

  grid_stateStoring: any;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  selectedFromValue?: Date = moment().startOf('month').toDate();
  selectedToValue?: Date = moment().toDate();

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    protected logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    private pusher: PusherService,
    public common: CommonService,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '2205b75d-66c8-4cf2-a718-9bfcd4940591',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    return of(true).pipe(
      switchMap(() =>
        this.dss.getApi<MealsAdcSignatureStatsApi>(MealsAdcSignatureStats).find<MealsAdcSignatureStats>({
          where: {
            and: [...(fromInc ? [{ vdate: { gte: fromInc } }] : []), ...(toExcl ? [{ vdate: { lt: toExcl } }] : [])],
          },
        }),
      ),

      switchMap(async stats => {
        const cIds = uniq(stats.map(stat => stat.consumerId));

        const clients = flatten(
          await Promise.all(
            chunk(cIds, 40).map(chnk =>
              this.dss
                .getViewApi<ConsumerViewApi>(Consumer)
                .find<ConsumerView>(
                  {
                    where: { id: { inq: chnk } },
                  },
                  (headers: HttpHeaders) => {
                    // if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                    //   headers = headersAllTenantsAppend(headers);
                    // }
                    return headers;
                  },
                )
                .toPromise(),
            ),
          ),
        );
        const clientsMap = new Map(clients.map(c => [c.id, c] as [number, ConsumerView]));

        // this.clients = clients;

        stats.forEach(stat => {
          const client = clientsMap.get(stat.consumerId);

          (stat as any).__client = client ? this.consumerHelper.displayExpr(client, '$L, $F $D') : 'UNKNOWN';
          (stat as any).__curMco = oc(client).activeMco('UNKNOWN');
          (stat as any).__mco = oc(oc(client).eligibility({})[utc(stat.vdate).format('YYYY-MM')]).value();
        });

        return stats;
      }),

      map(stats => {
        const aso: ArrayStoreOptions = {
          key: 'id',
          data: stats,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      'Units For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join(' - '),
    ].join(' ');
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  onCellPrepared(e) {
    // console.log(e);

    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'D') {
      // console.log(e);

      const value = e.cell.value;
      if (!isNaN(value)) {
        e.cellElement.style.backgroundColor = 'lightGreen';
      }
    }
  }

  onExporting(e) {}
}
