import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import groupBy from 'lodash-es/groupBy';
import head from 'lodash-es/head';
import intersection from 'lodash-es/intersection';
import isEmpty from 'lodash-es/isEmpty';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import moment, { utc } from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { Employee, EmployeeView, Facility, Signature, Vehicle } from '../../../../shared/sdk/models';
import {
  EmployeeViewApi,
  FacilityApi,
  LoggerService,
  SignatureApi,
  VehicleApi,
} from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import PivotGridDataSourceField = DevExpress.data.PivotGridDataSourceField;
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-vehicle-attendance',
  templateUrl: './vehicle-attendance.component.html',
  styleUrls: ['./vehicle-attendance.component.scss'],
  providers: [EmployeeHelperService],
})
export class VehicleAttendanceComponent extends ABaseComponent implements OnInit {
  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  alerts: string[] = [];

  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'id', visible: false },
      { dataField: 'tenantId', visible: false },
      { dataField: 'consumerId', visible: false },
      { dataField: 'employeeId', visible: false },
      { dataField: 'vehicleId', visible: false },
      { dataField: 'vdate', visible: false },

      { caption: 'Year', dataField: 'vdate', dataType: 'date', groupInterval: 'year', displayFolder: 'date' },
      { caption: 'Month', dataField: 'vdate', dataType: 'date', groupInterval: 'month', displayFolder: 'date' },
      {
        caption: 'Day of Week',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        displayFolder: 'date',
        area: 'column',
        selector: data => {
          const dateMoment = utc(data.vdate);
          return [dateMoment.format('YYYY-MM-DD'), dateMoment.format('dd')].join(' ');
        },
      },

      { caption: 'Tenant', area: 'row', dataField: '__tenant', dataType: 'string', isMeasure: false },
      { caption: 'Driver', area: 'row', dataField: '__driver', dataType: 'string', isMeasure: false },
      // {caption: 'Vehicle', area: 'data', dataField: '__vehicle', dataType: 'string', isMeasure: false},

      {
        caption: 'Vehicles',
        area: 'data',
        dataType: 'string',
        dataField: '__vehicle',
        summaryType: 'custom',
        isMeasure: true,
        calculateCustomSummary(options) {
          switch (options.summaryProcess) {
            case 'start':
              // Initializing "totalValue" here
              options.totalValue = new Set();
              break;
            case 'calculate':
              // Modifying "totalValue" here
              (options.totalValue as Set<string>).add(options.value);
              break;
            case 'finalize':
              // Assigning the final value to "totalValue" here
              options.totalValue = Array.from((options.totalValue as Set<string>).values())
                .sort()
                .map(v => ',' + v)
                .join('');
              break;
          }
        },
      },

      {
        caption: 'Vehicles Count',
        area: 'data',
        dataType: 'string',
        dataField: '__vehicle',
        summaryType: 'custom',
        isMeasure: true,
        calculateCustomSummary(options) {
          switch (options.summaryProcess) {
            case 'start':
              // Initializing "totalValue" here
              options.totalValue = [];
              break;
            case 'calculate':
              // Modifying "totalValue" here
              (options.totalValue as Array<string>).push(options.value);
              break;
            case 'finalize':
              // Assigning the final value to "totalValue" here
              options.totalValue = uniq(options.totalValue as Array<string>).length;
              break;
          }
        },
      },
      // {caption: 'Signatures Collected', area: 'data', dataField: 'count', dataType: 'number', summaryType: 'sum', isMeasure: true},
    ];
  }

  dso: any = {
    store: [],
    fields: this.pivotFields,
  } as DataSourceOptions;

  // facilityDso$: Observable<DataSourceOptions> = of([]);
  // employeeDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = false;
  showRowGrandTotals = false;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = moment().toDate();

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      'Fleet Attendance For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join(' - '),
    ].join(' ');
  }

  constructor(
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private gridHelper: GridHelperService,
    private dss: DataSourceService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private api: SignatureApi,
    public common: CommonService,
    public employeeHelper: EmployeeHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'd86f056b-4fd8-4970-a02d-44e0e1e4dfc3',
    };

    // this.facilityDso$ = this.buildFacilityDataSource();
    // this.employeeDso$ = this.buildEmployeeDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
          this.alerts = [];
        }),
        switchMap(() =>
          this.buildAlerts().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
            tap(alerts => {
              this.alerts = alerts;
            }),
          ),
        ),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
            tap(as => {
              this.dso = {
                store: as,
                fields: this.pivotFields,
              } as PivotGridDataSourceOptions;
              // this.grid.instance.refresh();
            }),
          ),
        ),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    return combineLatest([this.config.roles$, this.dss.getApi<FacilityApi>(Facility).find<Facility>()]).pipe(
      switchMap(([roles, facilities]) =>
        combineLatest([
          of(facilities),

          this.dss.getViewApi<EmployeeViewApi>(Employee).find<EmployeeView>({}, (headers: HttpHeaders) => {
            if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
              headers = headersAllTenantsAppend(headers);
            }
            return headers;
          }),

          this.dss.getApi<VehicleApi>(Vehicle).find<Vehicle>({}, (headers: HttpHeaders) => {
            if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
              headers = headersAllTenantsAppend(headers);
            }
            return headers;
          }),

          this.api.find<Signature>(
            {
              where: {
                and: [
                  ...(fromInc ? [{ vdate: { gte: fromInc } }] : []),
                  ...(toExcl ? [{ vdate: { lt: toExcl } }] : []),
                  {
                    marker: {
                      inq: [
                        'TO_ADC',
                        'FROM_ADC',
                        'TO_APPT',
                        'FROM_APPT',
                        'TO_EVENT',
                        'FROM_EVENT',
                        'TO_SCHOOL',
                        'FROM_SCHOOL',
                        'TO_AMB',
                        'FROM_AMB',
                      ],
                    },
                  },
                  { consumerId: { neq: null } },
                  { type: { nin: ['R', 'V', 'C'] } },
                  { status: { nlike: 'X%' } },
                ],
              },
              fields: {
                id: true,
                tenantId: true,
                consumerId: true,
                employeeId: true,
                vehicleId: true,
                vdate: true,
              },
            },
            (headers: HttpHeaders) => {
              if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                headers = headersAllTenantsAppend(headers);
              }
              return headers;
            },
          ),
        ]),
      ),

      map(([facilities, employees, vehicles, signatures]) => {
        signatures.forEach(sign => {
          const facility = facilities.find(f => f.id === sign.tenantId);
          const driver = employees.find(e => e.id === sign.employeeId);
          const vehicle = vehicles.find(v => v.id === sign.vehicleId);

          if (!driver || !vehicle) {
            console.log(sign);
          }

          (sign as any).__tenant = oc(facility).shortname('UNKNOWN');
          (sign as any).__driver = driver ? this.employeeHelper.displayExpr(driver) : 'UNKNOWN';
          (sign as any).__vehicle = oc(vehicle).internalId() ? '' + oc(vehicle).internalId() : 'UNKNOWN';
        });

        return signatures.filter(sign => (sign as any).__tenant !== 'UNKNOWN');
        // .filter((sign) => (sign as any).__driver !== 'UNKNOWN')
      }),

      map(stats => {
        const aso: ArrayStoreOptions = {
          key: 'id',
          data: stats,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  private buildAlerts() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    return combineLatest([
      this.config.roles$,

      this.dss.getApi<FacilityApi>(Facility).find<Facility>(),

      this.config.hasAnyRole$(['SU', 'MANAGER', 'BILLER']).pipe(
        switchMap(hasRole =>
          this.dss.getApi<VehicleApi>(Vehicle).find<Vehicle>({}, (headers: HttpHeaders) => {
            if (hasRole) {
              headers = headersAllTenantsAppend(headers);
            }
            return headers;
          }),
        ),
      ),
    ]).pipe(
      switchMap(([roles, facilities, vehicles]) =>
        combineLatest([
          of(roles),
          of(facilities),
          of(vehicles),
          this.api.find<Signature>(
            {
              where: {
                and: [
                  ...(fromInc ? [{ vdate: { gte: fromInc } }] : []),
                  ...(toExcl ? [{ vdate: { lt: toExcl } }] : []),
                  {
                    marker: {
                      inq: [
                        'TO_ADC',
                        'FROM_ADC',
                        'TO_APPT',
                        'FROM_APPT',
                        'TO_EVENT',
                        'FROM_EVENT',
                        'TO_SCHOOL',
                        'FROM_SCHOOL',
                        'TO_AMB',
                        'FROM_AMB',
                      ],
                    },
                  },
                  { consumerId: { neq: null } },
                  { vehicleId: { neq: null } },
                  { type: { nin: ['R', 'V', 'C'] } },
                  { status: { nlike: 'X%' } },
                ],
              },
              fields: {
                id: true,
                tenantId: true,
                consumerId: true,
                employeeId: true,
                vehicleId: true,
                vdate: true,
              },
            },
            (headers: HttpHeaders) => {
              if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                headers = headersAllTenantsAppend(headers);
              }
              return headers;
            },
          ),
        ]),
      ),
      map(([roles, facilities, vehicles, signatures]) => {
        const alerts: string[] = [];

        signatures.forEach(sign => {
          const facility = facilities.find(f => f.id === sign.tenantId);
          const vehicle = vehicles.find(v => v.id === sign.vehicleId);

          sign.tenant = facility;
          sign.vehicle = vehicle;
        });

        const byDate = groupBy(signatures, 'vdate');
        Object.entries(byDate).forEach(([date, byDateSigns]) => {
          const byVehicle = groupBy(
            byDateSigns.filter(s => !!s.vehicleId),
            'vehicleId',
          );

          const duplicated = Object.entries(byVehicle)
            .map(
              ([vId, signs]) =>
                [
                  vId,
                  uniqBy(
                    signs.filter(s => !!s.tenantId),
                    'tenantId',
                  ),
                ] as [any, Signature[]],
            )
            .filter(([vId, signs]) => signs.length > 1);
          duplicated.forEach(([vId, signs]) => {
            const vehicle = oc(head(signs)).vehicle();
            const tenants = signs.map(s => oc(s).tenant.shortname());
            alerts.push(
              [
                moment(date).format('M/D/YYYY'),
                ` - Vehicle ${oc(vehicle).internalId()} on `,
                tenants.join(' and '),
              ].join(''),
            );
          });
        });

        //

        const todaySigns = signatures.filter(s => s.vdate === moment().format('YYYY-MM-DD'));
        const todayVehiclesSigns = uniqBy(todaySigns, 'vehicleId');

        todayVehiclesSigns
          .filter(s => ['DECOMMISSIONED', 'IN MAINTENANCE'].includes(oc(s).vehicle.status()))
          .forEach(vs => {
            const vehicle = oc(vs).vehicle();
            const tenants = todaySigns.filter(s => s.vehicleId === vs.vehicleId).map(s => oc(s).tenant.shortname());

            alerts.push(
              [
                moment(vs.vdate).format('M/D/YYYY'),
                ` - Vehicle ${oc(vehicle).internalId()} is ${oc(vehicle).status()} but is used on `,
                tenants.join(' and '),
              ].join(''),
            );
          });

        todayVehiclesSigns
          .filter(s => ['INACTIVE'].includes(oc(s).vehicle.state()))
          .forEach(vs => {
            const vehicle = oc(vs).vehicle();
            const tenants = todaySigns.filter(s => s.vehicleId === vs.vehicleId).map(s => oc(s).tenant.shortname());

            alerts.push(
              [
                moment(vs.vdate).format('M/D/YYYY'),
                ` - Vehicle ${oc(vehicle).internalId()} is ${oc(vehicle).state()} but is used on `,
                tenants.join(' and '),
              ].join(''),
            );
          });

        return alerts;
      }),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const store = this.dss.getStore(Employee);
    const dso: DataSourceOptions = {
      store,
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  onCellPrepared(e) {
    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'GT' && e.cell.text.indexOf(',') >= 0) {
      e.cell.text = '...';
      e.cellElement.innerText = '...';
    } else if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.text.indexOf(',') >= 0) {
      e.cellElement.style.fontSize = '0.8em';
      e.cellElement.innerHTML = e.cell.text
        .split(',')
        .filter(v => !isEmpty(v))
        .map(v => `<span class="badge">${v}</span>`)
        .join(',');
    }
  }
}
