<form [formGroup]="form" (ngSubmit)="form_ngSubmit()" novalidate>
  <div class="dx-fieldset">
    <!--    <div class="dx-fieldset-header">Sign In</div>-->

    <mat-form-field>
      <input matInput placeholder="Username" formControlName="username" required />
      <mat-error *ngIf="form.get('username').invalid">{{ getErrorMessage('username', 'Username') }}</mat-error>
    </mat-form-field>

    <!--    <div class="dx-field">-->
    <!--      <dx-text-box autofocus placeholder="Username" formControlName="username" stylingMode="underlined">-->
    <!--        <dx-validator>-->
    <!--          <dxi-validation-rule type="required" message="Username is required"></dxi-validation-rule>-->
    <!--        </dx-validator>-->
    <!--      </dx-text-box>-->
    <!--    </div>-->

    <mat-form-field>
      <input matInput [type]="passHide ? 'password' : 'text'" placeholder="Password" formControlName="password" required />
      <mat-icon matSuffix (click)="passHide = !passHide">{{passHide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="form.get('password').invalid">{{ getErrorMessage('password', 'Password') }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="form.get('mfaSecret').value">
      <input matInput placeholder="Verification Code" formControlName="mfaToken" />
      <mat-error *ngIf="form.get('password').invalid">{{ getErrorMessage('password', 'Password') }}</mat-error>
    </mat-form-field>
    
    <div class="email-sent" *ngIf="form.get('mfaSecret').value && this.signedUser">
      Code was sent to your email "{{getEmail()}}"
    </div>

    <div class="dx-field" *ngIf="form.get('mfaSecret').value">
      <div class="dx-field"><dx-switch formControlName="mfaRememberForThisBrowser" switchedOnText="YES" switchedOffText="NO"></dx-switch> Remember for this device</div>
    </div>

    <!--    <div class="dx-field">-->
    <!--      <dx-text-box mode="password" placeholder="Password" formControlName="password" stylingMode="underlined">-->
    <!--        <dx-validator>-->
    <!--          <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>-->
    <!--        </dx-validator>-->
    <!--      </dx-text-box>-->
    <!--    </div>-->

    <!--    <p>-->
    <!--      <dx-validation-summary></dx-validation-summary>-->
    <!--    </p>-->

    <p class="alert-danger" *ngIf="error">{{ error }}</p>

    <button type="submit" mat-raised-button color="primary" [disabled]="isSubmitting">LOGIN</button>

    <!--    <dx-toolbar>-->
    <!--      <dxi-item [location]="'center'">-->
    <!--        <dx-button type="default" text="Login" [useSubmitBehavior]="true"-->
    <!--                   [icon]="isSubmitting ? 'fas fa-circle-notch fa-spin' : null"-->
    <!--                   [disabled]="isSubmitting"></dx-button>-->
    <!--      </dxi-item>-->

    <!--      <dxi-item [location]="'center'">-->
    <!--        <dx-button type="normal" text="Reset" (onClick)="reset_onClick()"-->
    <!--                   [disabled]="form.pristine"></dx-button>-->
    <!--      </dxi-item>-->
    <!--    </dx-toolbar>-->
  </div>
</form>
