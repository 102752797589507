<app-content-w-header header="Roles">
  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="ds"
    [errorRowEnabled]="true"
    (onInitialized)="grid_onInitialized($event)">
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

    <dxo-editing mode="cell" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!-- Columns -->

    <dxi-column caption="Name" dataField="name" dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Description" dataField="description" dataType="string"></dxi-column>
  </dx-data-grid>
</app-content-w-header>
