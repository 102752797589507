import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService, MyUser } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { Login, Logout, SetTenant } from '../../../../store/actions/sign';
import {
  getCurrentTenant,
  getLoginError,
  getLoginPending,
  getLogoutError,
  getLogoutPending,
  getMFASecret,
  getTenantError,
  getTenantPending,
  getTenants,
  getUser,
  isSignedIn,
  isSU,
} from '../../../../store/reducers/sign';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-sign',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
})
export class SignComponent implements OnInit {
  isSignedIn$: Observable<boolean>;
  signedUser$: Observable<MyUser>;
  mfaSecret$: Observable<string>;
  currentTenant$: Observable<number>;
  userTenants$: Observable<number[]>;
  isSU$: Observable<boolean>;

  loginError$: Observable<string>;
  setTenantError$: Observable<string>;
  logoutError$: Observable<string>;

  isLoginPending$: Observable<boolean>;
  isSetTenantPending$: Observable<boolean>;
  isLogoutPending$: Observable<boolean>;

  mode$: Observable<string>;
  forceSign$: Observable<boolean>;

  constructor(
    private logger: LoggerService,
    private common: CommonService,
    private route: ActivatedRoute,
    private title: Title,
  ) {
    this.isSignedIn$ = this.common.store.pipe(select(isSignedIn));
    this.signedUser$ = this.common.store.pipe(select(getUser));
    this.currentTenant$ = this.common.store.pipe(select(getCurrentTenant));
    this.userTenants$ = this.common.store.pipe(select(getTenants));
    this.isSU$ = this.common.store.pipe(select(isSU));
    this.mfaSecret$ = this.common.store.pipe(select(getMFASecret));

    this.loginError$ = this.common.store.pipe(select(getLoginError));
    this.setTenantError$ = this.common.store.pipe(select(getTenantError));
    this.logoutError$ = this.common.store.pipe(select(getLogoutError));

    this.isLoginPending$ = this.common.store.pipe(select(getLoginPending));
    this.isSetTenantPending$ = this.common.store.pipe(select(getTenantPending));
    this.isLogoutPending$ = this.common.store.pipe(select(getLogoutPending));

    this.forceSign$ = this.route.queryParamMap.pipe(map((params: ParamMap) => params.has('forceSign')));

    this.mode$ = combineLatest([this.isSignedIn$, this.currentTenant$, this.forceSign$]).pipe(
      map(([signed, tenant, forceSign]) => {
        // console.log(signed, tenant, forceSign);

        if (!signed || forceSign) {
          return 'LOGIN';
        } else if (!tenant) {
          return 'SET_TENANT';
        } else {
          return 'LOGOUT';
        }
      }),
    );
  }

  ngOnInit(): void {
    this.title.setTitle('Sign Form');
  }

  onLogin(user: MyUser): void {
    // this.logger.log(user);
    this.common.store.dispatch(new Login(user));
  }

  onSetTenant(tenantId: number): void {
    // this.logger.log(tenantId);
    this.common.store.dispatch(new SetTenant(tenantId));
  }

  onSetTenantCancel(e): void {
    this.common.store.dispatch(new Logout());
  }

  onLogout(user: MyUser): void {
    // this.logger.log(user);
    this.common.store.dispatch(new Logout());
  }
}
