import { Component, Inject, Input, OnInit } from '@angular/core';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee, EmployeeView, LoggerService } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';

@Component({
  selector: 'app-dlg-select-escort',
  templateUrl: './dlg-select-escort.component.html',
  styleUrls: ['./dlg-select-escort.component.scss'],
})
export class DlgSelectEscortComponent implements OnInit {
  @Input() ds: DataSourceOptions;
  @Input() gridColumns: any[] = [
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
  ];

  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgSelectEscortComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {
    this.buildDataSource();
  }

  ngOnInit() {}

  private buildDataSource() {
    this.ds = {
      store: this.dss.getStore(Employee, EmployeeView, false),
      filter: ['state', '=', 'ACTIVE'],
      sort: [{ selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  grid_onSelectionChanged(e) {}
}
