import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import fromPairs from 'lodash-es/fromPairs';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import set from 'lodash-es/set';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { oc } from 'ts-optchain';
//
import {
  BaseLoopBackApi,
  Consumer,
  Document,
  Employee,
  Facility,
  LoopBackFilter,
  MyUser,
  MyUserApi,
  Person,
  SDKBrowserModule,
  SDKModels,
  Vehicle,
} from '../../../sdk';
import isFunction from 'lodash-es/isFunction';
import { headersAllTenantsAppend } from '../../../classes/utils/utils';

export interface IModelClass<T = any> {
  new (...args: any[]): T;

  getModelName(): string;

  factory(data: any): T;

  getModelDefinition(): {
    name: string;
    plural: string;
    path: string;
    idName: string;
    properties: any;
    relations: any;
  };
}

export interface ModelApiClasses {
  [name: string]: typeof BaseLoopBackApi;
}

export interface ModelApiMap {
  [name: string]: BaseLoopBackApi;
}

@Injectable()
export class ExtSDKModels extends SDKModels {
  constructor(@Inject(Injector) private injector: Injector) {
    super();
  }

  private get modelApiClasses(): ModelApiClasses {
    return SDKBrowserModule.forRoot()
      .providers.filter((p: any) => p.prototype instanceof BaseLoopBackApi)
      .filter((p: any) => p.prototype.getModelName)
      .reduce((o, api: any) => ({ ...o, [api.prototype.getModelName()]: api }), {});
  }

  private get modelApiMap(): ModelApiMap {
    return fromPairs(
      Object.entries(this.modelApiClasses).map(([modelName, api]) => [modelName, this.injector.get<any>(api as any)]),
    );
  }

  getAllApiClasses(): ModelApiClasses {
    return this.modelApiClasses;
  }

  getApiMap(): ModelApiMap {
    return this.modelApiMap;
  }

  getApiClass(modelName: string): typeof BaseLoopBackApi {
    return this.modelApiClasses[modelName];
  }

  getApi(modelName: string): BaseLoopBackApi {
    return this.modelApiMap[modelName];
  }

  getIdName(modelName: string): string {
    return this.get(modelName).getModelDefinition().idName;
  }

  fixModelNestedTypes(model: any): any {
    if (model && model.constructor && model.constructor.getModelDefinition) {
      const definition = model.constructor.getModelDefinition();

      Object.keys(definition.relations).forEach(key => {
        const relation = definition.relations[key];
        const ModelClass = this.get(relation.model);
        let related = get(model, key);

        if (ModelClass && isArray(related)) {
          set(
            model,
            key,
            related.map(rel => {
              rel = new ModelClass(rel);
              this.fixModelNestedTypes(rel);
              return rel;
            }),
          );
        } else if (ModelClass && related) {
          related = new ModelClass(related);
          this.fixModelNestedTypes(related);
          set(model, key, related);
        }
      });
    }

    return model;
  }

  getObject<M>(
    ModelClass: any,
    id: any,
    filter: LoopBackFilter = {},
    headersObj: { [key: string]: string } | ((headers: HttpHeaders) => HttpHeaders) = {},
  ): Observable<M> {
    return this.getApi(ModelClass.getModelName())
      .findById<M>(id, filter, (headers: HttpHeaders) => {
        if (isFunction(headersObj)) {
          headers = headersObj(headers);
        } else {
          Object.entries(headersObj).forEach(([k, v]) => (headers = headers.append(k, v)));
        }

        return headers;
      })
      .pipe
      // tap((e) => console.log(e)),
      ();
  }

  getObjectTitle<M>(ModelClass: any, id: any, field?: string, allTenants = false): Observable<string> {
    const filter =
      ModelClass === Employee || ModelClass === Consumer
        ? { include: 'person' }
        : ModelClass === MyUser
        ? { include: [{ employee: ['person'] }] }
        : {};

    const modelTitleMap = {
      [MyUser.getModelName()]: (o: MyUser) =>
        o.username + (oc(o).employee.person() ? ` [${o.employee.person.firstname} ${o.employee.person.lastname}]` : ''),
      [Employee.getModelName()]: (o: any) => `${o.person.firstname} ${o.person.lastname}`,
      [Consumer.getModelName()]: (o: any) => `${o.person.firstname} ${o.person.lastname}`,
      [Person.getModelName()]: (o: any) => `${o.firstname} ${o.lastname}`,
      [Facility.getModelName()]: (o: any) => `${o.name}`,
      [Document.getModelName()]: (o: any) => `${o.name}`,
      [Vehicle.getModelName()]: (o: any) => `${o.internalId}`,
    };

    const valFn = field ? o => o[field] : modelTitleMap[ModelClass.getModelName()] || (o => null);

    return ModelClass.getModelName() === MyUser.getModelName()
      ? (this.getApi(ModelClass.getModelName()) as MyUserApi)
          .getUsernames(
            JSON.stringify({
              where: { [MyUser.getModelDefinition().idName]: id },
            }),
          )
          .pipe(map((objs: any[]) => valFn(objs[0])))
      : this.getObject<M>(ModelClass, id, filter, allTenants ? headersAllTenantsAppend : {}).pipe(
          map((obj: any) => valFn(obj)),
        );
  }
}
