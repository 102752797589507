import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { EmployeeView, Vehicle } from '../../../../shared/sdk/models';
import { TripsAuditGridComponent } from '../trips-audit-grid/trips-audit-grid.component';

@Component({
  selector: 'app-trips-audit-list',
  templateUrl: './trips-audit-list.component.html',
  styleUrls: ['./trips-audit-list.component.scss'],
})
export class TripsAuditListComponent implements OnInit {
  @Output() mySelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(TripsAuditGridComponent, { static: true }) grid: TripsAuditGridComponent;

  constructor() {}

  ngOnInit() {}

  grid_onSelected(data: any) {
    this.mySelected.emit(data);
  }

  repaint() {
    this.grid.repaint();
  }
}
