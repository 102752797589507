<div class="filter-panel">
  <dx-date-box [(max)]="dateTo" [(value)]="dateFrom" (onValueChanged)="calendar_onValueChanged($event)"></dx-date-box>
  <span>&nbsp;&ndash;&nbsp;</span>
  <dx-date-box [(min)]="dateFrom" [(value)]="dateTo" (onValueChanged)="calendar_onValueChanged($event)"></dx-date-box>
</div>

<dx-data-grid
  #grid
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="employees-perf-export"></dxo-export>

  <!-- Columns -->

  <!--<dxi-column caption="Person">-->

  <dxi-column caption="First Name" dataField="person.firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string"></dxi-column>

  <dxi-column caption="Sex" dataField="person.sex" dataType="string">
    <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Position" dataField="employeePosition.name" dataType="string"></dxi-column>

  <dxi-column caption="Days On Duty" dataField="days" dataType="number"></dxi-column>

  <dxi-column
    caption="Hours Worked"
    dataField="onDutyDuration"
    dataType="number"
    cellTemplate="duration_cell"></dxi-column>

  <dxi-column caption="Miles Driven" dataField="distance" dataType="number"></dxi-column>

  <dxi-column
    caption="Driving Time"
    dataField="onDutyDrivingDuration"
    dataType="number"
    cellTemplate="duration_cell"></dxi-column>

  <dxi-column
    caption="Idle Time"
    dataField="onDutyIdleDuration"
    dataType="number"
    cellTemplate="duration_cell"></dxi-column>

  <dxi-column
    caption="Compliance"
    dataField="compliance"
    dataType="number"
    cellTemplate="geoTabComplianceEvents_cell"></dxi-column>

  <dxi-column caption="Safety" dataField="safety" dataType="number" cellTemplate="geoTabSafetyEvents_cell"></dxi-column>

  <dxi-column caption="Tardiness" dataField="tardiness" dataType="number" cellTemplate="tardiness_cell"></dxi-column>

  <dxi-column caption="Vehicles Used" dataField="internalIds" dataType="string"></dxi-column>

  <dxi-column caption="Transports Performed" dataField="signaturesCount" dataType="string"></dxi-column>

  <!-- Templates -->
  <ng-container *dxTemplate="let cellInfo of 'duration_cell'">
    <span>{{ getDuration(cellInfo.value) }}</span>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'geoTabComplianceEvents_cell'">
    <a
      class="link"
      [id]="'grid_compliance' + cellInfo.data.id"
      style="cursor: pointer"
      (click)="showPopup_onClick('#grid_compliance' + cellInfo.data.id, cellInfo.data, 'Compliance')"
      title="Click"
      >{{ cellInfo.data.compliance }}</a
    >
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'geoTabSafetyEvents_cell'">
    <a
      class="link"
      [id]="'grid_safety' + cellInfo.data.id"
      style="cursor: pointer"
      (click)="showPopup_onClick('#grid_safety' + cellInfo.data.id, cellInfo.data, 'Safety')"
      title="Click"
      >{{ cellInfo.data.safety }}</a
    >
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'tardiness_cell'">
    <a
      class="link"
      [id]="'grid_tardiness' + cellInfo.data.id"
      style="cursor: pointer"
      (click)="showPopup_onClick('#grid_tardiness' + cellInfo.data.id, cellInfo.data, 'Tardiness')"
      title="Click"
      >{{ cellInfo.value }}</a
    >
  </ng-container>
</dx-data-grid>

<dx-popup
  [width]="750"
  [height]="280"
  [showTitle]="true"
  [title]="popupTitle"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible">
  <dxo-position at="bottom" my="right" [of]="popupPositionOf" collision="fit"></dxo-position>
  <div *dxTemplate="let data of 'content'">
    <ng-container *ngTemplateOutlet="popupContent"></ng-container>
  </div>
</dx-popup>

<ng-template #eventsPopupContent>
  <dx-data-grid
    [dataSource]="eventsDataSource"
    [height]="'100%'"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [filterRow]="false">
    <dxi-column
      width="125px"
      caption="Date"
      dataField="dateTime"
      dataType="date"
      sortOrder="desc"
      format="shortDateShortTime"></dxi-column>

    <dxi-column width="75px" caption="Type" dataField="eventType" dataType="string"></dxi-column>

    <dxi-column width="175px" caption="Event" dataField="rule" dataType="string"></dxi-column>

    <dxi-column caption="Details" [calculateCellValue]="getDetailsCellValue"></dxi-column>
  </dx-data-grid>
</ng-template>

<ng-template #tardinessPopupContent>
  <dx-data-grid
    [dataSource]="tardinessDataSource"
    [height]="'100%'"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [filterRow]="false">
    <dxi-column caption="Date" dataField="date" dataType="date" format="shortDate"></dxi-column>
    <dxi-column caption="Time" dataField="createdAt" dataType="date" format="shortDateShortTime"></dxi-column>
    <dxi-column caption="Start Time" dataField="schedule.startTime" dataType="date" format="shortTime"></dxi-column>
    <dxi-column caption="Real Start Time" dataField="punchInTime" dataType="date" format="shortTime"></dxi-column>
    <dxi-column caption="Minutes Late" [calculateDisplayValue]="calculateTardiness"></dxi-column>
    <dxi-column caption="Start Location" dataField="schedule.startLocationAddress"></dxi-column>
    <dxi-column caption="Facility" dataField="tenant.shortname"></dxi-column>
    <dxi-column caption="Delay Time" dataField="late"></dxi-column>
  </dx-data-grid>
</ng-template>

