import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular/ui/list';
import { interval, Observable } from 'rxjs';
import { exhaustMap, map, startWith, tap } from 'rxjs/operators';
import { Alert, AlertApi } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';

//

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit, AfterViewInit {
  alerts$: Observable<Alert[]>;

  alertCount = 0;
  loadingVisible = false;

  @ViewChild(DxListComponent, { static: true }) list: DxListComponent;

  constructor(private elRef: ElementRef, private common: CommonService, private alertApi: AlertApi) {}

  get position(): any {
    const of = this.list && this.list.instance ? this.list.instance.element() : null;
    return { of };
  }

  ngOnInit() {
    this.alerts$ = interval(2000).pipe(
      exhaustMap(() => this.alertApi.find<Alert>({ where: { hush: false, fixed: false } })),
      map(alerts => {
        this.alertCount = alerts.length;
        return this.buildItems(alerts);
      }),
      tap(() => {
        this.loadingVisible = false;
      }),
      startWith([]),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadingVisible = true;
    }, 0);
  }

  private buildItems(alerts: Alert[]): any[] {
    const alertMap: Map<string, Alert[]> = new Map<string, Alert[]>();
    (alerts || []).forEach(alert => {
      if (!alertMap.has(alert.objectFQN)) {
        alertMap.set(alert.objectFQN, []);
      }

      alertMap.get(alert.objectFQN).push(alert);
    });

    const items = [];
    alertMap.forEach((v, k) =>
      items.push({
        key: this.fqnMap(k) + ` (${v.length})`,
        count: v.length,
        items: v.map(alert => alert.text),
      }),
    );
    return items;
  }

  private fqnMap(objectFQN: string): string {
    // TODO: remove hardcode
    switch (objectFQN) {
      case 'DOCUMENT':
        return 'Documents Alerts';
    }
    return objectFQN;
  }
}
