import { Inject, Injectable } from '@angular/core';
import { Vehicle, VehicleApi } from '../../../shared/sdk';

@Injectable()
export class HelperService {
  constructor(@Inject(VehicleApi) public api: VehicleApi) {}

  displayExpr = (e: Vehicle): string => {
    return e && e.internalId ? `${e.internalId}` : '';
    //    return e ? `[${e.internalId}] ${e.make} ${e.model}` : 'Undefined';
  };
}
