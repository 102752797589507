import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { LoggerService } from '../../../../shared/sdk/services/custom';

@Component({
  selector: 'app-dlg-print-report',
  templateUrl: './dlg-print-report.component.html',
  styleUrls: ['./dlg-print-report.component.scss'],
})
export class DlgPrintReportComponent implements OnInit {
  consumerId: any;

  selectedStartValue: Date = moment().startOf('month').toDate();
  selectedEndValue: Date = moment().startOf('month').add(1, 'days').toDate();

  constructor(
    private dialogRef: MatDialogRef<DlgPrintReportComponent, { consumerIds: any[]; from: string; to: string }>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
  ) {}

  ngOnInit() {}

  cancel(e) {
    this.dialogRef.close();
  }

  submit(e) {
    const start = this.selectedStartValue;
    const end = this.selectedEndValue;

    const startMoment = moment(start);
    const endMoment = moment(end).add(1, 'days');

    if (!this.consumerId) {
      return;
    }

    this.dialogRef.close({
      consumerIds: [this.consumerId],
      from: startMoment.format('YYYY-MM-DD'),
      to: endMoment.format('YYYY-MM-DD'),
    });
  }
}
