<app-content-w-header header="Meal Claims Pivot">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="display: flex">
      <div>
        <dx-check-box text="Show Column Totals" [(value)]="showColumnTotals"></dx-check-box>
      </div>

      <div>
        <dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box>
      </div>

      <div>
        <dx-check-box text="Show Column Grand Totals" [(value)]="showColumnGrandTotals"></dx-check-box>
      </div>

      <div>
        <dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box>
      </div>
    </div>
  </mat-card>

  <dx-pivot-grid
    class="content"
    width="100%"
    height="100%"
    [dataSource]="dso"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showColumnTotals]="showColumnTotals"
    [showRowTotals]="showRowTotals"
    [showColumnGrandTotals]="showColumnGrandTotals"
    [showRowGrandTotals]="showRowGrandTotals"
    [stateStoring]="grid_stateStoring"
  >
    <dxo-export [enabled]="true" [fileName]="exportFileName"></dxo-export>

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true"
    ></dxo-field-panel>

    <dxo-scrolling [useNative]="true"></dxo-scrolling>
  </dx-pivot-grid>
</app-content-w-header>
