<!--<h1>Sign Out</h1>-->

<p>
  Logged in as <strong>{{ signedUser?.username }}</strong
  >.
</p>

<p class="alert-danger" *ngIf="error">{{ error }}</p>

<button type="submit" mat-raised-button color="primary" [disabled]="isSubmitting" (click)="onLogout()">LOGOUT</button>

<!--<dx-button style="width: 100%" type="default" text="Logout" (onClick)="onLogout()"-->
<!--           [icon]="isSubmitting ? 'fas fa-circle-notch fa-spin' : null"-->
<!--           [disabled]="isSubmitting"></dx-button>-->
