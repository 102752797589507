import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { EmployeeView, Vehicle } from '../../../../shared/sdk/models';
import { VehicleGridComponent } from '../vehicle-grid/vehicle-grid.component';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent implements OnInit {
  @Output() mySelected: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() markers: EventEmitter<Vehicle[]> = new EventEmitter<Vehicle[]>();

  @ViewChild(VehicleGridComponent, { static: true }) grid: VehicleGridComponent;

  constructor() {}

  ngOnInit() {}

  grid_onSelected(keys: number[]) {
    this.mySelected.emit(keys);
  }

  grid_markers(ms: any[]) {
    this.markers.emit(ms);
  }

  repaint() {
    this.grid.repaint();
  }
}
