import {
  MyUser
} from '../index';

declare var Object: any;
export interface StateInterface {
  "key": string;
  "data"?: any;
  "id"?: number;
  "userId"?: number;
  user?: MyUser;
}

export class State implements StateInterface {
  "key": string;
  "data": any;
  "id": number;
  "userId": number;
  user: MyUser;
  constructor(data?: StateInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `State`.
   */
  public static getModelName() {
    return "State";
  }

  /**
  * This method creates an instance of State for dynamic purposes.
  **/
  public static factory(data: StateInterface): State{
    return new State(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'State',
      plural: 'States',
      path: 'States',
      idName: 'id',
      properties: {
        "key": {
          name: 'key',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
