<!--<as-split direction="horizontal">-->
<!--  <as-split-area [size]="60">-->
<!--    <app-vehicle-list (mySelected)="list_onSelected($event)"></app-vehicle-list>-->
<!--  </as-split-area>-->

<!--  <as-split-area [size]="40">-->
<!--    <app-vehicle-details [modelId]="selectedId$ | async"></app-vehicle-details>-->
<!--  </as-split-area>-->
<!--</as-split>-->

<kendo-splitter>
  <kendo-splitter-pane [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-vehicle-list (mySelected)="list_onSelected($event)"></app-vehicle-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane size="40%" [collapsible]="true" [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-vehicle-details [markers]="markers"></app-vehicle-details>
  </kendo-splitter-pane>
</kendo-splitter>
