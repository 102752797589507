<mat-card>
  <mat-card-title>404: Not Found</mat-card-title>

  <mat-card-content>
    <p>Hey! It looks like this page doesn't exist yet.</p>
  </mat-card-content>

  <mat-card-actions>
    <button color='primary' mat-raised-button routerLink='/'>Take Me Home</button>
  </mat-card-actions>
</mat-card>
