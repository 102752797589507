import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AcquisitionRelatedContact } from 'src/app/shared/sdk/models/AcquisitionRelatedContact';
import { AcquisitionRelatedContactApi } from 'src/app/shared/sdk/services/custom/AcquisitionRelatedContact';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { FacilityView, LoopBackFilter, Person, Vehicle } from '../../../../shared/sdk/models';
import { FacilityViewApi, LoggerService, PersonApi, VehicleApi } from '../../../../shared/sdk/services/custom';
import { VEHICLE_STATUSES } from 'src/app/modules/fleet/classes/const';

@Component({
  selector: 'app-vehicle-change-status-form',
  templateUrl: './vehicle-change-status-form.component.html',
  styleUrls: ['./vehicle-change-status-form.component.scss'],
})
export class VehicleChangeStatusFormComponent extends ABaseFormComponent<Vehicle> implements OnInit {
  resetableForm = false;
  collapsibleForm = false;
  statuses = Object.keys(VEHICLE_STATUSES);
  vehicleStatuses = VEHICLE_STATUSES;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    protected helper: FormHelperService<Vehicle>,
  ) {
    super(logger, fb, dss, helper);
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  status_onSelectionChanged(e) {
    setTimeout(
      () =>
        !VEHICLE_STATUSES[e.selectedItem].subStatuses.includes(this.form.get('subStatus').value) &&
        this.form.get('subStatus').setValue(VEHICLE_STATUSES[e.selectedItem].subStatuses[0] || ''),
      100,
    );
  }

  protected async afterSetFormValuesAsync(model, form: FormGroup) {}

  protected async afterSubmittedAsync(data: any, id: string | number): Promise<void> {}

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      status: [],
      subStatus: [],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}

