import { AfterViewInit, Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';

@Component({
  selector: 'app-old-reports-form',
  templateUrl: './old-reports-form.component.html',
  styleUrls: ['./old-reports-form.component.scss'],
})
export class OldReportsFormComponent extends ABaseComponent implements OnInit, AfterViewInit {
  selectedStartValue: Date = moment().startOf('month').toDate();
  selectedEndValue: Date = moment().startOf('month').add(1, 'days').toDate();

  tenantType: string;

  periodItems = [
    {
      type: 'FILL_BROKER_TRIPS_DATA',
      caption: 'Fill Broker Data',
      description:
        'If MTM Exports files uploaded,' +
        ' use this action to immediately assign TripIDs for selected period,' +
        ' otherwise uploaded files will be processed nightly at 3:00 AM.',
      onClick: e => this.fillBrokerTrips_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_NON_ADC_UNASSIGNED_REPORTS',
      caption: 'Generate NON-ADC Unassigned Reports',
      description:
        'Trips from MTM Export file where delivery address is not one of priority ADC, ' +
        'useful to catch random appointment trips, not entered into manifest.',
      onClick: e => this.nonAdcGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_UNASSIGNED_REPORTS',
      caption: 'Generate Unassigned Reports',
      description:
        'Per facility report, shows unassigned TripID,' +
        ' meaning TripID was issued by MTM but client was not placed on manifest.',
      onClick: e => this.unassignedGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_NO_MCI_CONSUMERS_REPORT',
      caption: 'Generate No MCI Consumer Report',
      description: 'List of consumers on manifest with missing MCI data. No MCI means - we can not bill.',
      onClick: e => this.noMciGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_NO_TRIP_ID_CONSUMERS_REPORT',
      caption: 'Generate No Trip ID Consumers Report',
      description: 'List of active consumers with no Trip ID on manifest for selected date period.',
      onClick: e => this.noTripIdGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_NO_TRIP_ID_CONSUMERS_FOR_MCO_REPORT',
      caption: 'MTM FULL - Active clients with MTM No TripIDs for selected period',
      description:
        'List of active portal clients who don’t have TripIDs (in “S” - scheduled status)' +
        ' from MTM for selected period, used for submission to broker.',
      onClick: e => this.noTripIdForMcoGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_MTM_DENIED_TRIPS_REPORT',
      caption: 'Generate MTM Denied Trips Report',
      description: 'List of active consumers with denied trips for selected date period.',
      onClick: e => this.mtmDeniedTripsGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_CLAIMS_SHEETS',
      caption: 'Generate MTM Claims Reports',
      description: 'Daily Per Facility Excel file for MTM batch claims billing.',
      onClick: e => this.mtmGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_PERIOD_MAP_BILLING_REPORTS',
      caption: 'Generate MTM Billing Status Report',
      description:
        'Google sheet with color-coded claims statuses for selected date period, with separate per facility tabs.',
      onClick: e => this.billingGen_onClick(e),
      notificationOptions: {},
    },
  ];

  regularItems = [
    {
      type: 'GENERATE_NEW_CONSUMERS_REPORT',
      caption: 'Generate New Consumer Report',
      description:
        'Shows Not entered into the portal consumers who have MTM TripID,' +
        ' useful to find new consumers on priority ADCs.',
      onClick: e => this.newConsGen_onClick(e),
      notificationOptions: {},
    },
    {
      type: 'GENERATE_NEVER_ASSIGNED_CONSUMERS_REPORT',
      caption: 'Generate Never Assigned Consumer Report',
      description: 'List of active consumers with MCI data never assigned on manifest.',
      onClick: e => this.neverAssignedGen_onClick(e),
      notificationOptions: {},
    },
  ];

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    public config: ConfigService,
    private pusher: PusherService,
    private utilsApi: MyUtilsApi,
    private notification: PushNotificationsService,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();

    this.config.tenantType$.pipe(takeUntil(this.$onDestroy$)).subscribe(t => (this.tenantType = t));
  }

  ngAfterViewInit(): void {}

  fillBrokerTrips_onClick(e) {
    this.genPeriodReport('FILL_BROKER_TRIPS_DATA', {
      title: 'Done!',
      opts: {
        body: 'Fill Broker Trips Done!',
        requireInteraction: true,
      },
    });
  }

  unassignedGen_onClick(e) {
    this.genPeriodReport('GENERATE_UNASSIGNED_REPORTS', {
      title: 'Done!',
      opts: {
        body: 'Unassigned Trips reports generated!',
        requireInteraction: true,
      },
    });
  }

  nonAdcGen_onClick(e) {
    this.genPeriodReport('GENERATE_NON_ADC_UNASSIGNED_REPORTS', {
      title: 'Done!',
      opts: {
        body: 'Non ADC Unassigned Trips reports generated!',
        requireInteraction: true,
      },
    });
  }

  mtmGen_onClick(e) {
    this.genPeriodReport('GENERATE_CLAIMS_SHEETS', {
      title: 'Done!',
      opts: {
        body: 'MTM Claims reports generated!',
        requireInteraction: true,
      },
    });
  }

  newConsGen_onClick(e) {
    this.genPeriodReport('GENERATE_NEW_CONSUMERS_REPORT', {
      title: 'Done!',
      opts: {
        body: 'New Consumers report generated!',
        requireInteraction: true,
      },
    });
  }

  noMciGen_onClick(e) {
    this.genPeriodReport('GENERATE_NO_MCI_CONSUMERS_REPORT', {
      title: 'Done!',
      opts: {
        body: 'No MCI Consumers report generated!',
        requireInteraction: true,
      },
    });
  }

  neverAssignedGen_onClick(e) {
    this.genPeriodReport('GENERATE_NEVER_ASSIGNED_CONSUMERS_REPORT', {
      title: 'Done!',
      opts: {
        body: 'Never Assigned Consumers report generated!',
        requireInteraction: true,
      },
    });
  }

  noTripIdGen_onClick(e) {
    this.genPeriodReport('GENERATE_NO_TRIP_ID_CONSUMERS_REPORT', {
      title: 'Done!',
      opts: {
        body: 'No Trip ID Consumers report generated!',
        requireInteraction: true,
      },
    });
  }

  noTripIdForMcoGen_onClick(e) {
    this.genPeriodReport('GENERATE_NO_TRIP_ID_CONSUMERS_FOR_MCO_REPORT', {
      title: 'Done!',
      opts: {
        body: 'MTM FULL LIST - NO TRIP ID FOR AUTHORIZED CONSUMERS generated!',
        requireInteraction: true,
      },
    });
  }

  mtmDeniedTripsGen_onClick(e) {
    this.genPeriodReport('GENERATE_MTM_DENIED_TRIPS_REPORT', {
      title: 'Done!',
      opts: {
        body: 'MTM Denied Trips generated!',
        requireInteraction: true,
      },
    });
  }

  billingGen_onClick(e) {
    this.genPeriodReport('GENERATE_PERIOD_MAP_BILLING_REPORTS', {
      title: 'Done!',
      opts: {
        body: 'MTM Billing report generated!',
        requireInteraction: true,
      },
    });
  }

  private genPeriodReport(jobName: string, notification: { title: string; opts?: NotificationOptions }) {
    const start = this.selectedStartValue;
    const end = this.selectedEndValue;

    // console.log(start, end);

    const startMoment = moment(start);
    const endMoment = moment(end).add(1, 'days');

    notify('Report Requested');

    this.pusher
      .rpc(jobName, {
        from: startMoment.format('YYYY-MM-DD'),
        to: endMoment.format('YYYY-MM-DD'),
        broker: 'MTM',
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification(notification)),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
