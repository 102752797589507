import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Facility, LoggerService, Vehicle } from '../../../../../shared/sdk';
import { CommonService } from '../../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import { ABaseConsistencyCheckComponent } from '../a-base-consistency-check.component';

@Component({
  selector: 'app-consistency-check6',
  templateUrl: './consistency-check6.component.html',
  styleUrls: ['./consistency-check6.component.scss'],
})
export class ConsistencyCheck6Component extends ABaseConsistencyCheckComponent {
  protected mongoSchema: any = {
    '_trip._date': 'datetime',
    '_claim._date': 'datetime',
    '_sign.vdate': 'datetime',
    '_trip._ctime': 'datetime',
    '_trip._rtime': 'datetime',
  };
  protected checkIdx = 6;

  facilityDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    public config: ConfigService,
    protected pusher: PusherService,
    protected notification: PushNotificationsService,
    protected gridHelper: GridHelperService,
    protected common: CommonService,
    protected dss: DataSourceService,
  ) {
    super(logger, ui, pusher, common, dss, gridHelper);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'dc757e3c-e2db-4970-9973-3ce533a23555',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false);

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  grid_onToolbarPreparing(e) {}

  grid_onContextMenuPreparing(e) {}

  grid_onCellPrepared(e) {}
}
