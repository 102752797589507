import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import notify from 'devextreme/ui/notify';
import get from 'lodash-es/get';
import { of } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
//
import { getCurrentTenant } from 'src/app/store/reducers/sign';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import { Consumer, Employee, LoggerService, } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HelperService } from '../../services/helper.service';
export class TripManifestManifestComponent extends ABaseModelLoaderComponent {
    constructor(helper, common, gridHelper, employeeHelper, consumerHelper, sss, ui, cd, logger, dss, snackBar, dialog) {
        super(logger, dss);
        this.helper = helper;
        this.common = common;
        this.gridHelper = gridHelper;
        this.employeeHelper = employeeHelper;
        this.consumerHelper = consumerHelper;
        this.sss = sss;
        this.ui = ui;
        this.cd = cd;
        this.logger = logger;
        this.dss = dss;
        this.snackBar = snackBar;
        this.dialog = dialog;
        // loadingVisible = false;
        // toolbarItems: any[];
        this.reloadManifest$ = new Subject();
        this.validateManifest$ = new Subject();
        this.desDS = [];
        // notifying = false;
        // mdSnackBarRef: MatSnackBarRef<SnackBarCopyManifestComponent>;
        this.selectedDate = new Date();
        this.employee_calculateCellValue = (rowData) => {
            const employee = get(rowData, HelperService.REC_FIELD_MAP.employee, undefined);
            return this.employeeHelper.displayExpr(employee);
        };
        this.consumer_calculateCellValue = (rowData) => {
            const consumer = get(rowData, HelperService.REC_FIELD_MAP.consumer, undefined);
            return this.consumerHelper.displayExpr(consumer);
        };
        this.afterDataSourceChanged = () => {
            this.validateManifest$.next();
        };
        this.gridTarget = this.ui.genId();
        this.buildOtherDs();
        this.setSubscriptions();
    }
    get exportFileName() {
        return this.helper.exportManifestFileName(this.model);
    }
    get dailyNoteVisible() {
        if (!this.manifest) {
            return false;
        }
        return (this.manifest.data || []).filter(rec => rec.dn && rec.dn.length).length > 0;
    }
    get ModelClass() {
        return Employee;
    }
    get filter() {
        return {
            include: ['employeePosition', 'person'],
        };
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.reloadManifest$.next();
    }
    ngOnInit() {
        super.ngOnInit();
        // this.helper.buildDateManifestMapAsync()
        //   .then(map => this.dateManifestMap = map)
        //   .catch(this.logger.error);
    }
    grid_repaint() {
        // setTimeout(() => this.grid && this.grid.instance && this.grid.instance.repaint());
        this.grid.instance.repaint();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            // stateStoreGUID: null,
            notifyErrors: true,
        });
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items
            .unshift();
    }
    grid_onCellPrepared(e) {
        const fieldValidationCodesMap = {
            [HelperService.REC_FIELD_MAP.destination]: ['NO_DESTINATION'],
            [HelperService.REC_FIELD_MAP.time]: ['NO_TIME'],
            [HelperService.REC_FIELD_MAP.trip]: ['NO_TRIP'],
            [HelperService.REC_FIELD_MAP.employeeId]: ['NOT_EXIST_EMPLOYEES', 'NOT_ACTIVE_EMPLOYEES'],
            [HelperService.REC_FIELD_MAP.vehicleId]: [
                'VEHICLE_SEVERAL_ASSIGNMENTS',
                'VEHICLE_MULTI_ASSIGNMENTS',
                'EMPLOYEE_VEHICLE_NO_ASSIGNMENT',
            ],
            [HelperService.REC_FIELD_MAP.consumerId]: ['DUPLICATE', 'NOT_EXIST_CONSUMERS', 'NOT_ACTIVE_CONSUMERS'],
        };
        if (Object.keys(fieldValidationCodesMap).includes(e.column.dataField)) {
            const codes = fieldValidationCodesMap[e.column.dataField];
            this.helper.processCellWarnings(this.validationSummary, e, codes);
        }
    }
    printBtn_onAction(action) {
        if (!this.manifest.id) {
            notify('No manifest exists', 'error', 3000);
            return;
        }
        const recs = this.manifest.data;
        const ids = query(recs || [])
            .groupBy(HelperService.REC_FIELD_MAP.employeeId)
            .toArray()
            .filter(({ key }) => (this.modelId ? key === this.modelId : true))
            .filter(({ key }) => key !== this.employeeHelper.selfEntity.id)
            .map(({ key }) => key);
        const settings = {
            mode: action,
            employeeIds: ids,
        };
        this.toggleLoadingPanelVisible(true);
        this.helper
            .printManifestAsync(this.manifest, settings)
            .catch(err => notify(err.message || err, 'error', 8000))
            .then(() => this.toggleLoadingPanelVisible(false));
    }
    printManifest_onClick() {
        if (this.manifest && this.manifest.id) {
            const recs = this.manifest.data;
            const ids = query(recs || [])
                .groupBy(HelperService.REC_FIELD_MAP.employeeId)
                .toArray()
                .filter(({ key }) => (this.modelId ? key === this.modelId : true))
                .filter(({ key }) => key !== this.employeeHelper.selfEntity.id)
                .map(({ key }) => key);
            this.toggleLoadingPanelVisible(true);
            this.helper
                .printManifestAsync(this.manifest, { mode: 'manifest', employeeIds: ids })
                // .catch(err => notify(err.message || err, 'error', 3000))
                .then(() => this.toggleLoadingPanelVisible(false));
        }
    }
    calculateSummary(options) {
        // Calculating "TotalConsumers"
        if (options.name === 'TotalConsumers') {
            if (options.summaryProcess === 'start') {
                // Initializing "totalValue" here
                options.totalValue = new Set();
            }
            if (options.summaryProcess === 'calculate') {
                // Modifying "totalValue" here
                options.totalValue.add(options.value.c || options.value.id || options.value);
            }
            if (options.summaryProcess === 'finalize') {
                // Assigning the final value to "totalValue" here
                options.totalValue = options.totalValue.size;
            }
        }
    }
    setSubscriptions() {
        const currentTenant$ = this.common.store.select(getCurrentTenant);
        currentTenant$
            .pipe(switchMap((tenantId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.desDS = yield this.helper.getDestinationsDsAsync(tenantId);
        })), takeUntil(this.$onDestroy$))
            .subscribe();
        const loadManifest = (source) => source.pipe(tap(() => this.ui.showLoading()), switchMap(() => this.helper.api.getCurrentManifest(asShortDate(this.selectedDate))), switchMap((manifest) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const ds = yield this.buildMainDsAsync(manifest);
            this.manifest = manifest;
            this.ds = ds;
            return manifest;
        })), tap(() => this.ui.hideLoading()), catchError(err => {
            this.ui.hideLoading();
            return of(err);
        }));
        this.reloadManifest$
            .pipe(
        // prepare
        switchMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ds = new DataSource([]);
        })), 
        // process
        loadManifest, 
        // finally
        switchMap((manifest) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            //
        })), catchError(err => of(notify(err.message || err, 'error', 3000))), takeUntil(this.$onDestroy$))
            .subscribe();
        this.validateManifest$
            .pipe(exhaustMap(() => this.helper.validateManifest$(this.manifest).pipe(tap(res => {
            this.validationSummary = res;
            this.grid_repaint();
        }), catchError(err => of(notify(err.message || err, 'error', 5000))))), takeUntil(this.$onDestroy$))
            .subscribe();
    }
    buildMainDsAsync(manifest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const as = yield this.helper.buildArrayStoreAsync(manifest);
            const dso = {
                store: as,
                filter: this.modelId ? [HelperService.REC_FIELD_MAP.employeeId, '=', this.modelId] : undefined,
                onChanged: this.afterDataSourceChanged,
            };
            return new DataSource(dso);
        });
    }
    buildOtherDs() {
        this.driversDSO = {
            store: this.dss.getStore(Employee, null, false),
            filter: this.helper.buildDriversFilter('employeePosition_name'),
        };
        this.consumerDSO = {
            store: this.dss.getStore(Consumer, null, false),
        };
    }
    toggleLoadingPanelVisible(value) {
        if (value) {
            this.ui.showLoading();
        }
        else {
            this.ui.hideLoading();
        }
    }
}
