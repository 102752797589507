import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../../../shared/modules/my-common/guards/pending-changes.guard';
import { SettingsFormComponent } from '../../components/settings-form/settings-form.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(SettingsFormComponent, { static: false }) form: SettingsFormComponent;

  constructor() {}

  ngOnInit() {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return !this.form || !this.form.canDeactivate || this.form.canDeactivate();
  }
}
