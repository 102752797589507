import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaust, exhaustMap, tap, timeout } from 'rxjs/operators';
import { delayAsync } from '../../../../shared/classes/utils/promise.utils';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { VehicleListComponent } from '../../components/vehicle-list/vehicle-list.component';
import { Vehicle } from 'src/app/shared/sdk';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle-backup.component.html',
  styleUrls: ['./vehicle-backup.component.scss'],
})
export class VehicleBackupComponent extends ABaseComponent implements OnInit {
  $selectedArray$: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
  $markersArray$: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
  selected$: Observable<Vehicle> = new Observable();
  markers$: Observable<Vehicle[]> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(VehicleListComponent, { static: true }) grid: VehicleListComponent;

  constructor(protected logger: LoggerService) {
    super(logger);

    this.selected$ = this.$selectedArray$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
    this.markers$ = this.$markersArray$.pipe(map(ids => (ids && ids.length ? ids : [])));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(vehicles: Vehicle[]) {
    if (vehicles && vehicles.length) {
      this.$selectedArray$.next(vehicles);
    } else {
      this.$selectedArray$.next([]);
    }
  }

  list_markers(markers: any[]) {
    if (markers && markers.length) {
      this.$markersArray$.next(markers);
    } else {
      this.$markersArray$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}
