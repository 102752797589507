import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { select } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
//
import { getConfig, getEnvVars } from '../../../../store/reducers/core';
import { Config } from '../../../sdk';
import { CommonService } from './common.service';
import { IEnvVars } from './config.service';

@Injectable()
export class DashboardCanLoad implements CanLoad {
  constructor(private common: CommonService) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.common.store.pipe(select(getEnvVars)), this.common.store.pipe(select(getConfig))]).pipe(
      map(([vars, config]: [IEnvVars, Config]) => !isEmpty(vars) && !isEmpty(config)),
      distinctUntilChanged(),
      // take(1),
    );
  }
}
