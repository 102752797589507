import Store from 'devextreme/data/abstract_store';
import CustomStore, { CustomStoreOptions } from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import isEmpty from 'lodash-es/isEmpty';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataSourceService } from '../../../modules/my-common/services/datasource.service';
import { MyUtils, MyUtilsApi, TCustomHeaders, TCustomOptions } from '../../../sdk';
import { deferredAsPromise, promiseAsDeferred } from '../../utils/promise.utils';
import { getAltOrCommonHost } from '../../utils/utils';
import { MongoLoadOptionsConverter } from './load-options-converters/MongoLoadOptionsConverter';
import { errorMapFn } from './store-options/LoopBackStoreOptions';

function hookAround(
  store: Store,
  method: string,
  hookBeforeAsync: (...args: any[]) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  const _store_method = store[method].bind(store);
  store[method] = newMethod.bind(store);

  function newMethod(...args): Promise<any> & JQueryPromise<any> {
    const self = this;

    const promise = (async () => {
      if (hookBeforeAsync) args = await hookBeforeAsync.bind(self)(...args);
      let res: any[] = await deferredAsPromise(_store_method.bind(self)(...args));
      if (hookAfterAsync) res = await hookAfterAsync.bind(self)(args, res);
      return res;
    })();

    return promiseAsDeferred(promise).promise() as any;
  }
}

export function dxStoreByKeyHooks(
  store: Store,
  hookBeforeAsync: (key: any) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'byKey', hookBeforeAsync, hookAfterAsync);
}

export function dxStoreLoadHooks(
  store: Store,
  hookBeforeAsync: (obj: LoadOptions) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'load', hookBeforeAsync, hookAfterAsync);
}

export function dxStoreTotalCountHooks(
  store: Store,
  hookBeforeAsync: (obj: LoadOptions) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'totalCount', hookBeforeAsync, hookAfterAsync);
}

export function dxStoreInsertHooks(
  store: Store,
  hookBeforeAsync: (values: any) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'insert', hookBeforeAsync, hookAfterAsync);
}

export function dxStoreUpdateHooks(
  store: Store,
  hookBeforeAsync: (key: any, values: any) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'update', hookBeforeAsync, hookAfterAsync);
}

export function dxStoreRemoveHooks(
  store: Store,
  hookBeforeAsync: (key: any) => Promise<any[]> = null,
  hookAfterAsync: (args: any[], res: any[]) => Promise<any[]> = null,
): void {
  hookAround(store, 'remove', hookBeforeAsync, hookAfterAsync);
}

//////////////////////////////////////////////////////////////////////

// region mongo utils

export function getDistinct$(dss: DataSourceService, collection: string, prop: string) {
  const aggregate = [{ $group: { _id: `$${prop}` } }, { $match: { _id: { $ne: null } } }];
  return gqlMongoLoad(dss, collection, {}, aggregate).pipe(map(docs => docs.map(d => d._id)));
}

export function gqlMongoLoad<T = any>(
  dss: DataSourceService,
  collection: string,
  loadOptions: LoadOptions,
  aggregate: any[] = [],
  customOptions?: TCustomOptions,
  customHeaders?: TCustomHeaders,
): Observable<T[]> {
  let q = new MongoLoadOptionsConverter().convert(loadOptions);
  if (!isEmpty(aggregate)) {
    q = { ...q, aggregate };
  }

  // console.log(loadOptions, q);

  return dss
    .getApi<MyUtilsApi>(MyUtils)
    .getMongoDocs({ collection, q }, customHeaders, {
      path: getAltOrCommonHost(),
      ...customOptions,
    })
    .pipe(
      catchError(err => {
        errorMapFn(err, 'Data Loading Error');
        return of([]);
      }),
    );
}

export function gqlMongoCount(
  dss: DataSourceService,
  collection: string,
  loadOptions: LoadOptions,
  aggregate: any[] = [],
  customOptions?: TCustomOptions,
  customHeaders?: TCustomHeaders,
): Observable<number> {
  let q = new MongoLoadOptionsConverter().convert(loadOptions);
  if (!isEmpty(aggregate)) {
    q = { ...q, aggregate };
  }

  return dss
    .getApi<MyUtilsApi>(MyUtils)
    .getMongoCount({ collection, q }, customHeaders, {
      path: getAltOrCommonHost(),
      ...customOptions,
    })
    .pipe(
      map(count => (!isNaN(Number(count)) ? Number(count) : 0)),
      catchError(err => {
        errorMapFn(err, 'Data Loading Error');
        return of(0);
      }),
    );
}

export function gqlMongoDoc<T = any>(
  dss: DataSourceService,
  collection: string,
  where: any,
  customOptions?: TCustomOptions,
  customHeaders?: TCustomHeaders,
): Observable<T | null> {
  const q = { where };

  return dss
    .getApi<MyUtilsApi>(MyUtils)
    .getMongoDoc({ collection, q }, customHeaders, {
      path: getAltOrCommonHost(),
      ...customOptions,
    })
    .pipe(
      catchError(err => {
        errorMapFn(err, 'Data Loading Error');
        return of(null);
      }),
    );
}

export function gqlMongoByKey<T = any>(
  dss: DataSourceService,
  collection: string,
  key: any | string | number,
  customOptions?: TCustomOptions,
  customHeaders?: TCustomHeaders,
): Observable<T | null> {
  const q = { where: { _id: key } };

  return dss
    .getApi<MyUtilsApi>(MyUtils)
    .getMongoDoc({ collection, q }, customHeaders, {
      path: getAltOrCommonHost(),
      ...customOptions,
    })
    .pipe(
      catchError(err => {
        errorMapFn(err, 'Data Loading Error');
        return of(null);
      }),
    );
}

export function gqlMongoStore(
  dss: DataSourceService,
  collection: string,
  aggregate: any[],
  options: CustomStoreOptions = {},
) {
  return new CustomStore({
    useDefaultSearch: true,
    cacheRawData: false,
    key: '_id',
    load: async (loadOptions: LoadOptions): Promise<any> =>
      gqlMongoLoad(dss, collection, loadOptions, aggregate).toPromise(),
    totalCount: async (loadOptions: LoadOptions): Promise<number> =>
      gqlMongoCount(dss, collection, loadOptions, aggregate).toPromise(),
    byKey: async (key: any | string | number): Promise<any> => gqlMongoByKey(dss, collection, key).toPromise(),

    ...options,
  });
}

// endregion
