<h2 mat-dialog-title>
  Select <span *ngIf="this?.data?.consumer">default</span> signature image for
  <app-object-title modelName="Consumer" [modelId]="consumerId"></app-object-title>
</h2>

<mat-dialog-content #target>
  <dx-tile-view
    width="100%"
    direction="horizontal"
    [dataSource]="ds$ | async"
    [itemMargin]="10"
    [baseItemHeight]="30"
    [showScrollbar]="true"
    itemTemplate="tile"
    (onItemClick)="tile_onItemClick($event)">
    <div class="tile" *dxTemplate="let data of 'tile'">
      <img
        style="margin: auto; max-width: 100%; max-height: 100%; display: block"
        [src]="data.imgFileId | signatureLink: 'FILE':'resize--30/trim' | async | sanitizeUrl" />
    </div>
  </dx-tile-view>

  <dx-load-panel [position]="{ of: target }" [visible]="loading" [closeOnOutsideClick]="false"></dx-load-panel>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-paginator
    [length]="totalSigns$ | async"
    [pageSize]="pageSize$.getValue()"
    [pageIndex]="pageIndex$.getValue()"
    (page)="on_page($event)"></mat-paginator>
</mat-dialog-actions>
