import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService, MyUser, MyUtils, MyUtilsApi } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { IAutoDispatchSettings } from '../../services/helper.service';

@Component({
  selector: 'app-schedule-settings',
  templateUrl: './schedule-settings.component.html',
  styleUrls: ['./schedule-settings.component.scss'],
  providers: [EmployeeHelperService],
})
export class ScheduleSettingsComponent extends ABaseComponent implements OnInit {
  processing = false;
  processingSch = false;
  form: FormGroup;
  formSchedulingSettings: FormGroup;

  gmailPushAuthUrl = '';
  enabledComponents = { autoDispatch: false, mtmTrips: false, router: false };
  manifestSettings = {
    showSubscriptionTrips: false,
    highlightSubscriptionTrips: false,
    showDropOffTime: false,
    saveMtmTripsToManifest: false,
  };
  assignedEmail = '';
  refresh_token = '';

  private formSettingsMap: Map<string, any> = new Map<string, any>();
  private formSchedulingSettingsMap: Map<string, any> = new Map<string, any>();
  private defaultData: any;
  private defaultDataScheduling: any;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    public employeeHelper: EmployeeHelperService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(logger);
    this.buildForm();
    this.loadSettings();
  }
  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      if (params.code) {
        const { assignedEmail, refresh_token } = await this.dss
          .getApi<MyUtilsApi>(MyUtils)
          .getGmailPushTokenByCode(params.code)
          .toPromise();
        await this.dss
          .getApi<MyUtilsApi>(MyUtils)
          .mongoSaveAutoDispatchSettings({ assignedEmail, refresh_token })
          .toPromise();
        this.assignedEmail = assignedEmail;
        this.refresh_token = refresh_token;
        this.router.navigate(['/dashboard/settings/auto-dispatch']);
      }
    });
  }

  displayExpr = (u: MyUser): string => `${this.employeeHelper.displayExpr(u as any) || u.username} - ${u.email}`;

  formScheduling_ngSubmit(e): boolean {
    e.preventDefault();
    void (async () => {
      this.processingSch = true;
      if (this.formSchedulingSettings.valid) {
        const { startPrepareDuration, finishPrepareDuration, overtimeOptimization } = this.formSchedulingSettings.value;
        const data: any = {
          startPrepareDuration,
          finishPrepareDuration,
          overtimeOptimization,
        };
        await this.dss.getApi<MyUtilsApi>(MyUtils).mongoSaveAutoSchedulingSettings(data).toPromise();
        this.formSchedulingSettings.markAsPristine();
        notify('Done!', 'success');
      } else {
        notify('There are not valid fields', 'warning', 5000);
      }
    })()
      .catch(err => notify(err.message, 'error', 5000))
      .finally(() => (this.processingSch = false));
    return false;
  }

  form_ngSubmit(e): boolean {
    e.preventDefault();

    void (async () => {
      this.processing = true;
      if (this.form.valid) {
        const {
          maxTripMileage,
          maxClientWeight,
          countiesList,
          shiftPreparationDuration: shiftPreparation,
          shiftFinalizationDuration: shiftFinalization,
          boardingDuration: boarding,
          offBoardingDuration: offBoarding,
          replyToEmails,
          keepManifestTrips,
        } = this.form.value;
        const data: IAutoDispatchSettings = {
          maxTripMileage,
          maxClientWeight,
          countiesList,
          duration: { shiftPreparation, shiftFinalization, boarding, offBoarding },
          replyToEmails,
          keepManifestTrips,
        };
        await this.dss.getApi<MyUtilsApi>(MyUtils).mongoSaveAutoDispatchSettings(data).toPromise();
        this.form.markAsPristine();
        notify('Done!', 'success');
      } else {
        notify('There are not valid fields', 'warning', 5000);
      }
    })()
      .catch(err => notify(err.message, 'error', 5000))
      .finally(() => (this.processing = false));
    return false;
  }

  reset_onClick(e): void {
    setTimeout(() => this.form.reset(this.defaultData));
  }

  resetScheduling_onClick(e): void {
    setTimeout(() => this.formSchedulingSettings.reset(this.defaultDataScheduling));
  }

  async manifestSettingsChanged() {
    await this.dss.getApi<MyUtilsApi>(MyUtils).mongoSaveManifestSettings(this.manifestSettings).toPromise();
  }

  async enabledComponentsValueChanged() {
    await this.dss.getApi<MyUtilsApi>(MyUtils).mongoSaveEnabledComponents(this.enabledComponents).toPromise();
  }

  async startWatching() {
    await this.dss.getApi<MyUtilsApi>(MyUtils).setGmailPushToStartWatching(this.refresh_token).toPromise();
  }

  async stopWatching() {
    await this.dss.getApi<MyUtilsApi>(MyUtils).setGmailPushToStopWatching(this.refresh_token).toPromise();
  }

  private buildForm() {
    this.formSettingsMap.set('', {
      maxTripMileage: [''],
      maxClientWeight: [''],
      countiesList: [''],
      shiftPreparationDuration: [''],
      shiftFinalizationDuration: [''],
      boardingDuration: [''],
      offBoardingDuration: [''],
      replyToEmails: [''],
      keepManifestTrips: [''],
    });
    this.form = this.fb.group(this.formSettingsMap.get(''));
    this.defaultData = this.form.value;

    this.formSchedulingSettingsMap.set('', {
      startPrepareDuration: [''],
      finishPrepareDuration: [''],
      overtimeOptimization: [''],
    });
    this.formSchedulingSettings = this.fb.group(this.formSchedulingSettingsMap.get(''));
    this.defaultDataScheduling = this.formSchedulingSettings.value;
  }

  private async loadSettings() {
    const { _id, ...ec }: any = (await gqlMongoLoad(this.dss, 'EnabledComponents', {}, []).pipe().toPromise())[0] || {};
    this.enabledComponents = ec;

    const { _id: id1, ...ms }: any =
      (await gqlMongoLoad(this.dss, 'ManifestSettings', {}, []).pipe().toPromise())[0] || {};
    this.manifestSettings = ms;

    const { _id: id, ...autoScheduling }: any =
      (await gqlMongoLoad(this.dss, 'AutoSchedulingSettings', {}, []).pipe().toPromise())[0] || {};
    this.formSchedulingSettings.get('startPrepareDuration').setValue(autoScheduling.startPrepareDuration);
    this.formSchedulingSettings.get('finishPrepareDuration').setValue(autoScheduling.finishPrepareDuration);
    this.formSchedulingSettings.get('overtimeOptimization').setValue(autoScheduling.overtimeOptimization);

    const { duration, ...settings }: IAutoDispatchSettings = (
      await gqlMongoLoad(this.dss, 'AutoDispatchSettings', {}, []).pipe().toPromise()
    )[0] || { duration: {} };
    this.assignedEmail = settings.assignedEmail;
    this.refresh_token = settings.refresh_token;
    this.form.get('maxTripMileage').setValue(settings.maxTripMileage);
    this.form.get('maxClientWeight').setValue(settings.maxClientWeight);
    this.form.get('countiesList').setValue(settings.countiesList);
    if (duration) {
      this.form.get('shiftPreparationDuration').setValue(duration.shiftPreparation);
      this.form.get('shiftFinalizationDuration').setValue(duration.shiftFinalization);
      this.form.get('boardingDuration').setValue(duration.boarding);
      this.form.get('offBoardingDuration').setValue(duration.offBoarding);
    }
    this.form.get('replyToEmails').setValue(settings.replyToEmails);
    this.form.get('keepManifestTrips').setValue(settings.keepManifestTrips);
    this.gmailPushAuthUrl = await this.dss.getApi<MyUtilsApi>(MyUtils).getGmailPushAuthUrl().toPromise();
  }
}
