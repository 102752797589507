import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Role } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss'],
})
export class UsersRolesComponent extends ABaseComponent implements OnInit {
  ds: DataSource;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    this.ds = dss.getDataSource(Role);
  }

  ngOnInit() {}

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      // stateStoreGUID: 'd3cca09c-fe2c-46e4-8f70-91cd82f499a8',
    });
  }
}
