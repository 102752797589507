<h2 mat-dialog-title cdkDrag cdkDragRootElement='.cdk-overlay-pane' cdkDragHandle>
  <span>
    <svg width='16px' fill='currentColor' viewBox='0 0 24 24'>
      <path
        d='M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z'></path>
      <path d='M0 0h24v24H0z' fill='none'></path>
    </svg>
  </span>
  Claim Submission
</h2>

<div mat-dialog-content>
  <!--  <dx-scroll-view [useNative]='true'>-->

  <ng-container *ngIf='claimSet?.claim?.request'>
    <details>
      <summary>Submitted Claim Data</summary>

      <details style='padding-left: 1em;'>
        <summary>REQUEST</summary>
        <ngx-json-viewer [json]='claimSet?.claim?.request || {}' [expanded]='false'></ngx-json-viewer>
      </details>

      <details style='padding-left: 1em;'>
        <summary>RESPONSE</summary>
        <ngx-json-viewer [json]='claimSet?.claim?.response || {}' [expanded]='false'></ngx-json-viewer>
      </details>
    </details>

    <br />
  </ng-container>

  <ng-container>
    <mat-radio-group [(ngModel)]='mode'>
      <mat-radio-button *ngIf='claimSet?.claim?.response' value='Resubmit'>Resubmit</mat-radio-button>
      <mat-radio-button value='Adjust'>Adjust</mat-radio-button>
      <mat-radio-button value='Void'>Void</mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <dx-form
    #form
    [formData]='claimData'
    [disabled]='!claimData'
    (onFieldDataChanged)='onFieldDataChanged($event)'
  >
    <dxi-item *ngIf='mode === "Adjust" || mode === "Void"'
              dataField='correction.claimControlNumber'
    >
      <dxo-label text='Payer Claim ID'></dxo-label>
    </dxi-item>

    <dxi-item itemType='group' cssClass='form-group' [colCount]='2'>
      <dxi-item itemType='group'>
        <dxi-item
          dataField='payerID'
        >
          <!--            editorType='dxAutocomplete'-->
          <!--            [editorOptions]="{ dataSource: payerIDSource }"-->
          <dxo-label location='top' alignment='left' [text]='payerLabel'></dxo-label>
        </dxi-item>

        <dxi-item dataField='authID'>
          <dxo-label location='top' alignment='left' [text]='authLabel'></dxo-label>
        </dxi-item>
      </dxi-item>

      <dxi-item itemType='group'>
        <dxi-item
          dataField='rate'
          editorType='dxNumberBox'
        >
          <dxo-label location='top' alignment='left' text='Rate'></dxo-label>
        </dxi-item>

        <dxi-item
          dataField='chargeAmount'
          editorType='dxNumberBox'
          [editorOptions]='{ readOnly: true }'
        >
          <dxo-label location='top' alignment='left' text='Charge Amount'></dxo-label>
        </dxi-item>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType='group' cssClass='form-group' caption='Service Line' [colCount]='5'>
      <dxi-item
        dataField='service.dateBegin'
        editorType='dxDateBox'
        [label]='{ location: "top", alignment: "left", text: "From"}'
        [editorOptions]="{ type: 'date', dateSerializationFormat: 'yyyy-MM-dd', displayFormat: 'shortDate' }"
      ></dxi-item>

      <dxi-item
        dataField='service.dateEnd'
        editorType='dxDateBox'
        [label]='{ location: "top", alignment: "left", text: "To" }'
        [editorOptions]="{ type: 'date', dateSerializationFormat: 'yyyy-MM-dd', displayFormat: 'shortDate' }"
      ></dxi-item>

      <dxi-item
        dataField='service.chargeAmount'
        editorType='dxNumberBox'
        [label]='{ location: "top", alignment: "left", text: "Charge Amount" }'
        [editorOptions]='{ readOnly: true }'
      ></dxi-item>

      <dxi-item
        dataField='service.procedureCode'
        [label]='{ location: "top", alignment: "left", text: "Code"}'
      >
        <!--          editorType='dxAutocomplete'-->
        <!--          [editorOptions]='{ readOnly: false, dataSource: procedureCodesSource }'-->
      </dxi-item>

      <dxi-item
        dataField='service.unitCount'
        editorType='dxNumberBox'
        [label]='{ location: "top", alignment: "left", text: "Units" }'
      ></dxi-item>
    </dxi-item>

    <dxi-item
      itemType='group'
      cssClass='form-group'
      caption='Subscriber'
    >

      <dxi-item itemType='group' [colCount]='2'>
        <dxi-item itemType='group'>
          <dxi-item dataField='subscriber.memberID' [label]='{ text: "Member ID" }'></dxi-item>
          <dxi-item dataField='subscriber.firstname' [label]='{ text: "First Name" }'></dxi-item>
          <dxi-item dataField='subscriber.lastname' [label]='{ text: "Last Name" }'></dxi-item>
        </dxi-item>

        <dxi-item itemType='group'>
          <dxi-item itemType='empty'></dxi-item>
          <dxi-item dataField='subscriber.gender' [label]='{ text: "Gender" }'></dxi-item>
          <dxi-item
            dataField='subscriber.dob'
            editorType='dxDateBox'
            [label]='{ text: "DOB" }'
            [editorOptions]="{ type: 'date', dateSerializationFormat: 'yyyy-MM-dd', displayFormat: 'shortDate' }"
          ></dxi-item>

        </dxi-item>
      </dxi-item>

      <dxi-item itemType='group' caption='Address' [colCount]='2'>
        <dxi-item itemType='group'>
          <dxi-item dataField='address.address1'>
            <dxo-label text='Address1'></dxo-label>
          </dxi-item>

          <dxi-item dataField='address.address2'>
            <dxo-label text='Address2'></dxo-label>
          </dxi-item>

          <dxi-item dataField='address.city'>
            <dxo-label text='City'></dxo-label>
          </dxi-item>
        </dxi-item>

        <dxi-item itemType='group'>
          <dxi-item dataField='address.state'>
            <dxo-label text='State'></dxo-label>
          </dxi-item>

          <dxi-item dataField='address.zip'>
            <dxo-label text='Zip'></dxo-label>
          </dxi-item>
        </dxi-item>
      </dxi-item>

    </dxi-item>

  </dx-form>

  <!--  </dx-scroll-view>-->
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]='false'>Cancel</button>
  <button mat-button color='primary' (click)='submit()'>Submit</button>
  <mat-checkbox [(ngModel)]='validate'>Validate</mat-checkbox>
</div>
