<app-content-w-header header="Cancel unused MTM trips">
  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onCellPrepared)="grid_onCellPrepared($event)"
  >

    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-search-panel [visible]="false"></dxo-search-panel>

    <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

    <!-- Columns -->

    <dxi-column caption="Broker" dataField="_broker" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Date"
      dataField="_date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="true"></dxi-column>

    <dxi-column caption="Trip Number" dataField="_tripId" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Trip Type" dataField="Trip Type" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Trip Status" dataField="Trip Status" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member's Last Name"
      dataField="Member's Last Name"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member's First Name"
      dataField="Member's First Name"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Medicaid Number" dataField="_mci" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member's Date of Birth"
      dataField="Member's Date of Birth"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Delivery">

      <dxi-column caption="Delivery Name" dataField="Delivery Name" dataType="string"
                  [allowSorting]="false"></dxi-column>

      <dxi-column
        caption="Delivery Address"
        dataField="Delivery Address"
        dataType="string"
        [allowSorting]="false"></dxi-column>

      <dxi-column caption="Delivery City" dataField="Delivery City" dataType="string"
                  [allowSorting]="false"></dxi-column>

      <dxi-column
        caption="Delivery State"
        dataField="Delivery State"
        dataType="string"
        [allowSorting]="false"></dxi-column>

      <dxi-column
        caption="Delivery Zip Code"
        dataField="Delivery Zip Code"
        dataType="string"
        [allowSorting]="false"></dxi-column>

      <dxi-column
        caption="Delivery Phone Number"
        dataField="Delivery Phone Number"
        dataType="string"
        [allowSorting]="false"></dxi-column>

    </dxi-column>

    <dxi-column caption="Special Needs" dataField="Special Needs" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Inst / Directions"
      dataField="Inst / Directions"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Appointment Date"
      dataField="Appointment Date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Appointment Day of Week"
      dataField="Appointment Day of Week"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Appointment Time"
      dataField="Appointment Time"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Will Call Flag"
      dataField="Will Call Flag"
      dataType="boolean"
      [allowSorting]="false"
      [showEditorAlways]="false"
    ></dxi-column>

    <dxi-column
      caption="Member's Phone Number"
      dataField="Member's Phone Number"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member's Alt Phone"
      dataField="Member's Alt Phone"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Wheelchair Flag"
      dataField="Wheelchair Flag"
      dataType="boolean"
      [allowSorting]="false"
      [showEditorAlways]="false"></dxi-column>

    <dxi-column
      caption="Crutches / Walker / Cane Flag"
      dataField="Crutches / Walker / Cane Flag"
      dataType="boolean"
      [allowSorting]="false"
      [showEditorAlways]="false"></dxi-column>

    <dxi-column
      caption="Number of Car Seats Required"
      dataField="Number of Car Seats Required"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Pregnant Flag"
      dataField="Pregnant Flag"
      dataType="boolean"
      [allowSorting]="false"
      [showEditorAlways]="false"></dxi-column>

    <dxi-column
      caption="Number of Additional Passengers"
      dataField="Number of Additional Passengers"
      dataType="number"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Additional Passengers With Appointments"
      dataField="Additional Passengers With Appointments"
      dataType="number"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Trip Mileage" dataField="Trip Mileage" dataType="number" [allowSorting]="false"></dxi-column>


    <dxi-column caption="Pickup">

      <dxi-column
        caption="Pickup Address"
        dataField="Pickup Address"
        dataType="string"
        [allowSorting]="false"></dxi-column>

      <dxi-column caption="Pickup City" dataField="Pickup City" dataType="string" [allowSorting]="false"></dxi-column>

      <dxi-column caption="Pickup State" dataField="Pickup State" dataType="string" [allowSorting]="false"></dxi-column>

      <dxi-column
        caption="Pickup Zip Code"
        dataField="Pickup Zip Code"
        dataType="string"
        [allowSorting]="false"></dxi-column>

    </dxi-column>

    <dxi-column caption="Return Time" dataField="Return Time" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Attendant Flag"
      dataField="Attendant Flag"
      dataType="boolean"
      [allowSorting]="false"
      [showEditorAlways]="false"></dxi-column>

    <dxi-column
      caption="Imported Date"
      dataField="_ctime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <dxi-column
      caption="Updated Date"
      dataField="_rtime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <!-- Templates -->

  </dx-data-grid>
</app-content-w-header>
