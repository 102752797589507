import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//
import { confirm } from 'devextreme/ui/dialog';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import { LoggerService } from '../../../../shared/sdk';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dlg-calendar',
  templateUrl: './dlg-calendar.component.html',
  styleUrls: ['./dlg-calendar.component.scss'],
  providers: [HelperService],
})
export class DlgCalendarComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgCalendarComponent, string>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private helper: HelperService,
    protected logger: LoggerService,
  ) {}

  ngOnInit() {}

  calendarClass = ({ date, view }) => {
    const l = this.data.length;
    if (view !== 'month' || !l) return '';
    const colors = [
      { cls: 'day-green', rate: 0.95 },
      { cls: 'day-yellow', rate: 0.8 },
      { cls: 'day-orange', rate: 0.5 },
      { cls: 'day-red', rate: 0.01 },
    ];
    const d = asShortDate(date);
    const r = (this.data.calendarMap[d] || 0) / l;
    return (colors.find(({ rate }) => r >= rate) || { cls: '' }).cls;
  };

  calendarTitle = ({ date, view }) => {
    const l = this.data.length;
    if (view !== 'month' || !l) return '';
    const d = asShortDate(date);
    const c = this.data.calendarMap[d] || 0;
    const p = Math.ceil((c / l) * 100);
    return `${p > 100 ? 100 : p}%`;
  };

  calendar_onValueChanged(e) {
    // this.logger.log(e);
    confirm('Are you sure?', 'Confirm selection').then(dialogResult => {
      if (dialogResult) this.dialogRef.close(e.value);
    });
  }
}
