import { Input, OnInit, Type } from '@angular/core';
//
import { BaseLoopBackApi, LoggerService } from '../../../../sdk';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { UiService } from '../../services/ui.service';
import { ABaseFormComponent } from './a-base-form.component';
import { ABaseModelLoaderComponent } from './a-base-model-loader.component';

export abstract class ABaseModelPaneWToolbarComponent<M, A extends BaseLoopBackApi = BaseLoopBackApi>
  extends ABaseModelLoaderComponent<M, A>
  implements OnInit
{
  toolbarItems: any[] = [];

  @Input() caption: string;

  protected showAdd = true;
  protected showEdit = true;

  protected constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
  ) {
    super(logger, dss);
  }

  protected abstract get FormComponent(): Type<ABaseFormComponent<M>>;

  ngOnInit(): void {
    super.ngOnInit();

    this.caption = this.caption || this.ModelClass.getModelName() + ' info';

    this.buildToolbarItems();
  }

  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.buildToolbarItems(model);
  }

  protected buildToolbarItems(model?: M): void {
    this.toolbarItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        text: this.caption,
      },
      ...(this.showEdit
        ? [
            {
              location: 'after',
              widget: 'dxButton',
              options: {
                text: 'Edit Info',
                hint: 'Edit info',
                disabled: !model,
                onClick: () => this.onEditClickHandler(),
              },
            },
          ]
        : []),
      ...(this.showAdd
        ? [
            {
              location: 'after',
              widget: 'dxButton',
              options: {
                text: 'Add New',
                hint: 'Add new',
                visible: !model,
                onClick: () => this.onAddClickHandler(),
              },
            },
          ]
        : []),
    ];
  }

  protected onAddClickHandler(): void {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: this.ModelClass,
      FormComponentClass: this.FormComponent,
    });
  }

  protected onEditClickHandler(): void {
    this.ui.openEditDialog({
      modelId: this.modelId,
      ModelClass: this.ModelClass,
      FormComponentClass: this.FormComponent,
    });
  }
}
