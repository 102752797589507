<dx-tab-panel
  width="100%"
  height="100%"
  #tabPanel
  [dataSource]="tabs"
  [(selectedIndex)]="selectedIndex"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="true"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'accepted'">
    <app-auto-dispatch-details-tab-accepted [message]="message"></app-auto-dispatch-details-tab-accepted>
  </div>
  <div *dxTemplate="let data of 'denied'">
    <app-auto-dispatch-details-tab-denied [message]="message"></app-auto-dispatch-details-tab-denied>
  </div>
  <div *dxTemplate="let data of 'acceptedAlt'">
    <app-auto-dispatch-details-tab-accepted-alt [message]="message"></app-auto-dispatch-details-tab-accepted-alt>
  </div>
</dx-tab-panel>

