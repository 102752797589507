import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import DxDataGrid from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { Facility, LoggerService, LoopBackAuth, Vehicle } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss'],
  providers: [HelperService],
})
export class VehicleGridComponent implements OnInit, OnDestroy {
  dso: DataSourceOptions;
  STATES = ['ACTIVE', 'INACTIVE'];

  grid_stateStoring: any;
  modifiedSubscription: Subscription;

  facilityDso: DataSourceOptions = [];

  @Output() mySelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('00a07308-3a34-4ef0-b45f-112089c105d5');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '439b7e1f-9720-46cc-9082-b8a3108fc282',
    };

    const dso = this.dss.getDataSourceOptions(Vehicle);
    dso.filter = ['tenantId', '=', this.auth.getCurrentTenant()];
    this.dso = dso;

    this.facilityDso = this.buildFacilityDataSource();
  }

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Vehicle.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });

    (e.component as DxDataGrid).deselectAll();
    this.mySelected.emit([]);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newVehicle',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-plus',
        text: 'New vehicle',
        hint: 'Create new vehicle',
        onClick: this.grid_toolbar_newVehicle_onClick.bind(this),
      },
    });
  }

  grid_onSelectionChanged(event: any): void {
    const items = (event.component as DxDataGrid).getDataSource().items();

    if (items.find(i => event.currentSelectedRowKeys.includes(i.id))) {
      this.mySelected.emit(event.currentSelectedRowKeys);
    }
  }

  grid_onContentReady(event) {
    //    this.mySelected.emit((event.component as DxDataGrid).getSelectedRowsData());
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const title = `Edit: ${this.helper.displayExpr(e.data)}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Vehicle,
      FormComponentClass: VehicleFormComponent,
      title,
    });
  }

  grid_toolbar_newVehicle_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Vehicle,
      FormComponentClass: VehicleFormComponent,
    });
  }

  grid_getStatusCellValue = (v: Vehicle) => v.status + ((v.status === 'BACKUP' && ` - ${v.subStatus}`) || '');

  grid_calculateFilterStatusExpression = function (filterValue, selectedFilterOperation, target) {
    const column = this as any;
    console.log(column.dataField, filterValue, selectedFilterOperation, target);

    if (selectedFilterOperation === 'contains') {
      const filterExpression = [
        ['status', 'like', `%${filterValue}%`],
        'or',
        [['status', '=', 'BACKUP'], 'and', ['subStatus', 'like', `%${filterValue}%`]],
      ];
      return filterExpression;
    }

    return column.defaultCalculateFilterExpression.apply(column, arguments);
  };

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return dso;
  }
}
