import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { last } from 'lodash-es';
import head from 'lodash-es/head';
import intersection from 'lodash-es/intersection';
import uniq from 'lodash-es/uniq';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Facility, FacilityApi, LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';

@Component({
  selector: 'app-meals-generate-invoice',
  templateUrl: './meals-generate-invoice.component.html',
  styleUrls: ['./meals-generate-invoice.component.scss'],
})
export class MealsGenerateInvoiceComponent extends ABaseComponent implements OnInit {
  weekDs$: Observable<any>;
  dsLoading = true;

  private locale = 'en';

  selectedWeekValue?: Date = moment().locale(this.locale).startOf('week').toDate();

  generating = false;
  $generateEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    protected logger: LoggerService,
    private pusher: PusherService,
    private common: CommonService,
    public config: ConfigService,
    protected dss: DataSourceService,
    private notification: PushNotificationsService,
  ) {
    super(logger);

    this.weekDs$ = this.buildWeeksDs();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$generateEvent$
      .pipe(
        filter(arg => arg),

        tap(() => {
          this.generating = true;
        }),

        map(() => {
          const from = this.selectedWeekValue;

          const fromMoment = from && moment(from).locale(this.locale);
          const toMoment = fromMoment.clone().add(1, 'week').startOf('week');

          if (!fromMoment || !toMoment) {
            throw new Error('Period should be defined');
          }

          if (toMoment.diff(fromMoment, 'week') > 1) {
            throw new Error('Period should be less or equal to 1 week');
          }
          const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
          const strTo = toMoment && toMoment.format('YYYY-MM-DD');

          return {
            fromIncl: strFrom,
            toExcl: strTo,
          };
        }),

        tap(() => notify('You will receive Invoice on your email in a few minutes, thank you.', 'info', 5000)),
        switchMap(fltr => this.pusher.rpc('GENERATE_MEALS_REPORT_RPC', { ...fltr, useRunService: true }, true)),
        tap(() => this.notification.done('Meals Invoice Generation Done!')),
        catchError(err => of(this.notification.error(err, 'Generation Failed'))),

        tap(() => {
          this.generating = false;
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildWeeksDs(weekCount: number = 51) {
    this.dsLoading = true;

    let current = moment().locale(this.locale);
    const weeksMeta: [Moment, Moment, Moment][] = [];

    for (let i = 0; i < weekCount; i++) {
      const fromIncl = current.clone().startOf('week');
      const toExcl = fromIncl.clone().add(1, 'w').startOf('week');
      const toIncl = fromIncl.clone().endOf('week');

      weeksMeta.push([fromIncl, toExcl, toIncl]);

      current = current.subtract(1, 'week');
    }

    return this.dss
      .getApi<FacilityApi>(Facility)
      .getSignsDates({
        and: [
          { tenantId: this.common.auth.getCurrentTenant() },
          { vdate: { gte: last(weeksMeta)[0].format('YYYY-MM-DD') } },
          { vdate: { lt: head(weeksMeta)[1].format('YYYY-MM-DD') } },
        ],
      })
      .pipe(
        // map((dates: string[]) => uniq(dates.map(d => moment(d).locale(this.locale).format('YY_w')))),
        //  map((dates: string[]) => uniq(dates.map(d => moment(d).locale(this.locale).format('YY_w')))),
        map((dates: string[]) => {
          return weeksMeta
            .filter(meta =>
              dates.some(d =>
                moment(d).isBetween(meta[0].format('YYYY-MM-DD'), meta[1].format('YYYY-MM-DD'), undefined, '[)'),
              ),
            )
            .map(meta => ({
              t: `${meta[0].format('w')} (${meta[0].format('M/D/YY')} - ${meta[2].format('M/D/YY')})`,
              v: meta[0].toDate(),
            }));
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(res => {
          // console.log(res);
          this.dsLoading = false;
        }),
      );
  }

  generate() {
    this.$generateEvent$.next(true);
  }
}
