<div>
  <dx-data-grid
    #grid
    width="100%"
    height="100%"
    [dataSource]="dso$ | async"
    [errorRowEnabled]="false"
    [stateStoring]="grid_stateStoring"
    (onInitialized)="grid_onInitialized($event)"
    (onEditingStart)="grid_onEditingStart($event)">
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-editing [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-export
      [enabled]="true"
      [fileName]="$facilityName$.value + '_Clients_Eligibility_Generated_On_' + now"
      [excelFilterEnabled]="true"
      [allowExportSelectedData]="true"></dxo-export>

    <!-- Columns -->

    <dxi-column caption="Internal ID" dataField="internalID" dataType="number"></dxi-column>

    <!--<dxi-column caption="Person">-->
    <dxi-column caption="First Name" dataField="person_firstname" dataType="string"></dxi-column>

    <dxi-column caption="Last Name" dataField="person_lastname" dataType="string"></dxi-column>

    <dxi-column
      caption="DOB"
      dataField="person_dob"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <!--</dxi-column>-->

    <dxi-column caption="MCI" dataField="mci" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string">
      <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Program" dataField="programId" dataType="string">
      <dxo-lookup [dataSource]="programDso" displayExpr="name" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <!--    -->

    <dxi-column
      *ngFor="let date of dates"
      [caption]="date.format('YY-MMM') + ' MCO'"
      [dataField]="mcoDataField(date)"
      [calculateSortValue]="mcoSortField(date)"
      [calculateDisplayValue]="'eligibility.' + date.format('YYYY-MM') + '.value'"
      cellTemplate="mcoCellTpl"
      [allowSearch]="false"
      [allowSorting]="true"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="Coordination Agency" dataField="c10nAgency" dataType="string"></dxi-column>

    <dxi-column caption="Case Worker Name" dataField="cwName" dataType="string"></dxi-column>

    <dxi-column caption="Case Worker Phone" dataField="cwPhone" dataType="string"></dxi-column>

    <dxi-column caption="Case Worker Email" dataField="cwEmail" dataType="string"></dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'mcoCellTpl'">
      <span
        [ngClass]="{
          'mco-warning': !!cellInfo.value?.status,
          'mco-error': !!cellInfo.value?.error
        }"
        >{{ cellInfo.value?.value || '' }}</span
      >
    </ng-container>
  </dx-data-grid>
</div>
