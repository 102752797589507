<dx-data-grid
  #grid
  [dataSource]="dso$ | async"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-group-panel [visible]="false"></dxo-group-panel>

  <dxo-editing mode="batch" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <dxi-column caption="Internal ID" dataField="internalId" dataType="number" [allowEditing]="false"></dxi-column>

  <dxi-column caption="State" dataField="state" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Status" dataField="status" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Make" dataField="make" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Model" dataField="model" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="Year" dataField="year" dataType="number" [allowEditing]="false"></dxi-column>

  <dxi-column caption="VIN" dataField="vin" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column caption="License State" dataField="licenseState" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="License Plate Number"
    dataField="licensePlateNumber"
    dataType="string"
    [allowEditing]="false"></dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Wheel Chair Accessible"
    dataField="wheelchairAccessible"
    dataType="boolean"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Stretcher Accessible"
    dataField="stretcherAccessible"
    dataType="boolean"
    [allowEditing]="false"></dxi-column>

  <!---->

  <dxi-column
    caption="Gross Weight"
    dataField="grossWeight"
    dataType="number"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Passenger Capacity"
    dataField="passengerCapacity"
    dataType="number"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Wheelchair Capacity"
    dataField="wheelchairCapacity"
    dataType="number"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Possible Configs"
    dataField="possibleConfigs"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Has Lift/Ramp"
    dataField="hasLiftRamp"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column caption="CDL" dataField="cdl" dataType="boolean" [visible]="false" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Ownership"
    dataField="ownership"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Registration Exp."
    dataField="registrationExp"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Inspection Exp."
    dataField="inspectionExp"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Last Mechanical Check"
    dataField="lastMechanicalCheck"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Body Company"
    dataField="bodyCompany"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Tire Size"
    dataField="tireSize"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column caption="Fuel" dataField="fuel" dataType="string" [visible]="false" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Engine Type"
    dataField="engineType"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Seller"
    dataField="seller"
    dataType="string"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Acquisition Date"
    dataField="purchaseDate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Title Date"
    dataField="titleDate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"
    [allowEditing]="false"></dxi-column>

  <dxi-column caption="Note" dataField="note" dataType="string" [visible]="false" [allowEditing]="false"></dxi-column>

  <dxi-column
    caption="Facility"
    dataField="tenantIds"
    [showEditorAlways]="false"
    cellTemplate="tagBox_cell"
    editCellTemplate="tagBox_editCell">
  </dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'tagBox_cell'">
    <span *ngFor="let id of cellInfo.value">
      <app-object-title [modelId]="id" [modelName]="'Facility'"></app-object-title>
      <span>, </span>
    </span>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'tagBox_editCell'">
    <dx-tag-box
      [dataSource]="facilityDso$ | async"
      [value]="cellInfo.value"
      valueExpr="id"
      displayExpr="name"
      (onValueChanged)="tagBox_onValueChanged(cellInfo, $event)"></dx-tag-box>
  </ng-container>
</dx-data-grid>
