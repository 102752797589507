import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import DxDataGrid from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import {
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  LoopBackAuth,
  Signature,
  SignatureApi,
  Vehicle,
} from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import moment, { duration } from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { VehicleFormComponent } from 'src/app/modules/vehicle/components/vehicle-form/vehicle-form.component';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';
import { VehicleAddFormComponent } from '../vehicle-add-form/vehicle-add-form.component';

const GROUPS = [
  [null, null, '', ''], //undefined
  [null, null, 'EMERGENCY ONLY', 'red'],
  [1, 6, '1-6', 'black'],
  [7, 9, '7-9', 'magenta'],
  [10, 14, '10-14', 'green'],
  [15, 999, 'CDL/14+', 'blue'],
];

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss'],
})
export class VehicleGridComponent implements OnInit, OnDestroy {
  markerBaseUrl = '/assets/images/';
  dso: DataSourceOptions;
  grid_stateStoring: any;
  modifiedSubscription: Subscription;

  facilityDso: DataSourceOptions = [];

  @Output() mySelected: EventEmitter<Vehicle[]> = new EventEmitter<Vehicle[]>();
  @Output() markers: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '439b7e1f-9720-46cc-9082-b8a3108fc223',
    };

    const so = this.dss.getStoreOptions(Vehicle, Vehicle, false);
    so.customFilter = {
      where: { tenantId: this.auth.getCurrentTenant(), status: 'BACKUP' },
      include: [{ relation: 'anyNotes', scope: { order: 'updatedAt DESC' } }],
    };
    const store = new CustomStore(so);
    dxStoreLoadHooks(store, undefined, async (args: any[], [vehicles]: any[]) => {
      const [facilities, last, positions, checkedInToday, onBoardToday] = await Promise.all([
        this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise(),
        gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getLastGeoTabAggregate(vehicles)).pipe().toPromise(),
        gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getGeoTabLastPositionAggregate(vehicles)).pipe().toPromise(),
        gqlMongoLoad(this.dss, 'VehicleCheckUp', {}, this.getCheckedInTodayAggregate(vehicles)).pipe().toPromise(),
        this.dss
          .getApi<SignatureApi>(Signature)
          .find<Signature>(
            {
              where: {
                and: [
                  {
                    vdate: moment().format('YYYY-MM-DD'),
                  },
                  { vehicleId: { inq: vehicles.map(v => v.id) } },
                ],
              },
            },
            headersAllTenantsAppend,
          )
          .toPromise(),
      ]);
      const drivers = await this.dss
        .getApi<EmployeeApi>(Employee)
        .find<Employee>(
          { where: { id: { inq: [...new Set(checkedInToday.map(v => v.employeeId))] } }, include: ['person'] },
          headersAllTenantsAppend,
        )
        .toPromise();

      const mFacility = facilities.reduce((p, v) => ({ ...p, [v.id]: v.shortname }), {});
      const mDriver = drivers.reduce(
        (p, { id, person: { firstname, lastname } }) => ({ ...p, [id]: `${firstname} ${lastname}` }),
        {},
      );
      const mLast = last.reduce((p, v) => ({ ...p, [v.internalId]: v.lastDate }), {});
      const mPosition = positions.reduce((p, v) => ({ ...p, [v.internalId]: v.lastPosition }), {});
      const mCkedIn = checkedInToday.reduce((p, v) => ({ ...p, [v.vehicleId]: v.employeeId }), {});
      const mOnBrdToday = onBoardToday
        .filter(v => !['C', 'V'].includes(v.type))
        .reduce((p, v) => ({ ...p, [v.vehicleId]: v.tenantId }), {});
      const res = vehicles.map(v => ({
        ...v,
        ymm: `${v.year} ${v.make} ${v.model}`,
        wheelchairAccessible: (v.wheelchairAccessible && 'WC') || '',
        cdl: (v.cdl && 'CDL') || '',
        isCheckedInToday: mDriver[mCkedIn[v.id]] || '',
        isOnBoardedToday: mFacility[mOnBrdToday[v.id]] || '',
        lastDate: mLast[v.internalId] && moment(mLast[v.internalId], 'YYYYMMDD'),
        lastLoop: mCkedIn[v.id] && mOnBrdToday[v.id] && mPosition[v.internalId] && mPosition[v.internalId].loop,
        lastPosition: mPosition[v.internalId],
        positionTime:
          (mPosition[v.internalId] &&
            duration(moment(mPosition[v.internalId].dateTime).diff(moment())).humanize(true)) ||
          '',
        positionTimeSort: (mPosition[v.internalId] && -1 * new Date(mPosition[v.internalId].dateTime).getTime()) || '',
        lastNote: v.anyNotes && v.anyNotes[0],
        group: GROUPS.find(
          ([min, max, title]) =>
            (title && v.subStatus && v.subStatus.toUpperCase() == title) ||
            !v.passengerCapacity ||
            (min <= v.passengerCapacity && v.passengerCapacity <= max),
        )[2],
        groupSort: v.subStatus && v.subStatus.toUpperCase() === 'EMERGENCY ONLY' ? 1000 : v.passengerCapacity,
      }));
      this.markers.emit(this.getMarkers(res));
      return [res];
    });
    this.dso = { store } as DataSourceOptions;
  }

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Vehicle.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });

    (e.component as DxDataGrid).deselectAll();
    this.mySelected.emit([]);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newVehicle',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-plus',
        text: 'Change Vehicle Status to “Backup”',
        hint: 'Change Vehicle Status to “Backup”',
        onClick: this.grid_toolbar_newVehicle_onClick.bind(this),
      },
    });
  }

  grid_toolbar_newVehicle_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Vehicle,
      FormComponentClass: VehicleAddFormComponent,
    });
  }

  grid_onSelectionChanged(event: any): void {
    const items = (event.component as DxDataGrid).getDataSource().items();

    if (items.find(i => event.currentSelectedRowKeys.includes(i.id))) {
      this.mySelected.emit(event.selectedRowsData);
    }
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      (e.cellElement as HTMLElement).style.color = GROUPS.find(([, , title]) => e.data.group === title)[3] as string;
    }
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const title = `Edit: ${e.data.internalId}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Vehicle,
      FormComponentClass: VehicleFormComponent,
      title,
    });
  }

  getMapURL = v => {
    const location = `${v.latitude},${v.longitude}`;
    return `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
  };

  showLoop_onClick = v => {
    this.dialog.open(ShowLoopDialogComponent, {
      panelClass: 'loop-dialog',
      backdropClass: 'loop-dialog',
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      data: {
        vin: v.vin,
        date: v.lastLoop.startDate,
        loopId: v.lastLoop.loopId,
        vehicle: {
          text: `Time: ${moment(v.dateTime).format('lll')}<br/>${v.rule}<br/>`,
          latitude: v.lastPosition.latitude,
          longitude: v.lastPosition.longitude,
          icon: 'marker-current.png',
        },
        title: `#${v.internalId}, ${v.isCheckedInToday}, ${v.isOnBoardedToday} - ${moment(
          v.lastPosition.dateTime,
        ).format('M/D/YYYY h:mm A')}`,
      },
    });
  };

  getCheckedInTodayAggregate(vehicles: Vehicle[]) {
    return [
      {
        $match: {
          vdate: +moment().format('YYYYMMDD'),
          vehicleId: { $in: vehicles.map(v => v.id) },
        },
      },
      { $group: { _id: '$vehicleId', last: { $last: '$$ROOT' } } },
      { $project: { _id: 0, vehicleId: '$_id', employeeId: '$last.employeeId' } },
    ];
  }

  getLastGeoTabAggregate(vehicles: Vehicle[]) {
    return [
      { $match: { internalId: { $in: vehicles.map(v => v.internalId) } } },
      { $unwind: '$days' },
      { $project: { internalId: 1, distance: '$days.distance', day: '$days.day' } },
      { $match: { distance: { $gte: 1 } } },
      { $sort: { day: 1 } },
      { $group: { _id: '$internalId', last: { $last: '$$ROOT' } } },
      {
        $project: { internalId: '$last.internalId', lastDate: '$last.day' },
      },
    ];
  }

  getGeoTabLastPositionAggregate(vehicles: Vehicle[]) {
    return [
      { $match: { internalId: { $in: vehicles.map(v => v.internalId) }, month: +moment().format('YYYYMM') } },
      { $project: { internalId: 1, lastPosition: 1 } },
      { $match: { lastPosition: { $exists: true } } },
      { $sort: { 'lastPosition.dateTime': 1 } },
      { $group: { _id: '$internalId', last: { $last: '$$ROOT' } } },
      {
        $project: {
          _id: 0,
          internalId: '$_id',
          lastPosition: '$last.lastPosition',
        },
      },
    ];
  }

  getTimeAgo(time: string) {
    return (time && duration(moment(new Date(time)).diff(moment())).humanize(true)) || '';
  }

  getMarkers(vehicles: any[]): any[] {
    return vehicles
      .filter((v: any) => v.lastPosition)
      .map(v => ({
        vin: v.vin,
        tooltip: {
          text:
            `${v.internalId} ${v.make} ${v.model}` +
            `<br/><em>last time location:</em> ${this.getTimeAgo(v.lastPosition.dateTime)}`,
          isShown: false,
        },
        iconSrc: this.markerBaseUrl + 'marker-gray.png' || null,
        location: { lat: v.lastPosition.latitude, lng: v.lastPosition.longitude },
      }));
  }
}
