import {
  Employee
} from '../index';

declare var Object: any;
export interface EmployeePositionInterface {
  "name": string;
  "order"?: number;
  "predefined"?: boolean;
  "dtime"?: Date;
  "id"?: number;
  employees?: Employee[];
}

export class EmployeePosition implements EmployeePositionInterface {
  "name": string;
  "order": number;
  "predefined": boolean;
  "dtime": Date;
  "id": number;
  employees: Employee[];
  constructor(data?: EmployeePositionInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeePosition`.
   */
  public static getModelName() {
    return "EmployeePosition";
  }

  /**
  * This method creates an instance of EmployeePosition for dynamic purposes.
  **/
  public static factory(data: EmployeePositionInterface): EmployeePosition{
    return new EmployeePosition(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeePosition',
      plural: 'EmployeePositions',
      path: 'EmployeePositions',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "predefined": {
          name: 'predefined',
          type: 'boolean',
          default: false
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        employees: {
          name: 'employees',
          type: 'Employee[]',
          model: 'Employee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'employeePositionId'
        },
      }
    }
  }
}
