<dx-validation-group [formGroup]="form">
  <dx-toast [(visible)]="isToastVisible" [message]="toastMessage" [type]="toastType" [displayTime]="5000"> </dx-toast>
  <div class="columns">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Employee Status</div>
        <div class="dx-field-value">
          <dx-select-box
            [dataSource]="statuses"
            displayExpr="Name"
            valueExpr="ID"
            formControlName="status"
            (onSelectionChanged)="status_onSelectionChanged($event)">
            <dx-validator name="Start Location">
              <dxi-validation-rule type="custom" [validationCallback]="statusValidationCallback"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Start Location</div>
        <div class="dx-field-value">
          <dx-select-box
            #startLocation
            [dataSource]="locations"
            formControlName="startLocation"
            displayExpr="name"
            valueExpr="value"
            (onSelectionChanged)="location_onSelectionChanged($event, 'start')"></dx-select-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Start Location Address</div>
        <div class="dx-field-value">
          <dx-text-box
            formControlName="startLocationAddress"
            [disabled]="startLocation.value !== 'other'"
            (onValueChanged)="locationAddress_onChange($event, 'start')">
            <dx-validator name="Start Location">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">First Pickup</div>
        <div class="dx-field-value time-with-consumer" *ngIf="data.manifest?.firstTrip">
          <dx-date-box [disabled]="true" [value]="data.manifest.firstTrip.t" type="time"></dx-date-box>
          - {{ data.firstTripConsumer | fullName }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">First Pickup Address</div>
        <div class="dx-field-value">
          <dx-text-box
            [disabled]="true"
            [value]="
              scheduleHelper.getFullAddress(data.firstTripConsumer?.person?.contact?.addresses[0])
            "></dx-text-box>
        </div>
      </div>

      <dx-map
        *ngIf="mapVelues.startZoom"
        [width]="500"
        provider="google"
        [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
        [controls]="true"
        [autoAdjust]="true"
        [zoom]="mapVelues.startZoom"
        [center]="mapVelues.startLocation"
        [markers]="mapVelues.startLocationMarker"
        [routes]="mapVelues.startLocationRoute"
        (onClick)="map_onClick($event, 'start')"></dx-map>

      <div class="dx-field duration">Travel Time: {{ form.controls.startTravelDuration.value }} min</div>

      <div class="dx-field duration">Prepare Time: {{ timeValues.startPrepareDuration }} min</div>

      <div class="dx-field">
        <div class="dx-field-label">Start Time</div>
        <div class="dx-field-value time-with-propose">
          <dx-button
            stylingMode="outlined"
            text="Propose"
            type="normal"
            (onClick)="propose_onClick('start')"></dx-button>
          <dx-date-box
            [(value)]="timeValues.start"
            type="time"
            [showClearButton]="true"
            [interval]="10"
            (onValueChanged)="dateBox_onValueChanged($event, 'start')">
            <dx-validator name="Start Time">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Start Instructions</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="startInstructions"></dx-text-box>
        </div>
      </div>

      <!-- <div class="dx-field" [hidden]="startFacilityBox.value"> -->
      <div class="dx-field" *ngIf="facilities?.length && !data.manifest?.firstTrip?.facilityId">
        <div class="dx-field-label">Start Facility</div>
        <div class="dx-field-value">
          <dx-select-box
            #startFacilityBox
            [dataSource]="facilities"
            displayExpr="name"
            valueExpr="id"
            formControlName="startFacilityId"
            placeholder="Facility"
            (onSelectionChanged)="facility_onSelectionChanged($event, 'start')">
          </dx-select-box>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field"><div class="dx-field-label"></div></div>
      <div class="dx-field">
        <div class="dx-field-label">Finish Location</div>
        <div class="dx-field-value">
          <dx-select-box
            #finishLocation
            [dataSource]="locations"
            formControlName="finishLocation"
            displayExpr="name"
            valueExpr="value"
            (onSelectionChanged)="location_onSelectionChanged($event, 'finish')"></dx-select-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Finish Location Address</div>
        <div class="dx-field-value">
          <dx-text-box
            formControlName="finishLocationAddress"
            [disabled]="finishLocation.value !== 'other'"
            (onValueChanged)="locationAddress_onChange($event, 'finish')"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Dropoff</div>
        <div class="dx-field-value time-with-consumer" *ngIf="data.manifest?.lastTrip">
          <dx-date-box [disabled]="true" [value]="data.manifest.lastTrip.dot" type="time"></dx-date-box>
          - {{ data.lastTripConsumer | fullName }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Dropoff Address</div>
        <div class="dx-field-value">
          <dx-text-box
            [disabled]="true"
            [value]="scheduleHelper.getFullAddress(data.lastTripConsumer?.person?.contact?.addresses[0])"></dx-text-box>
        </div>
      </div>

      <dx-map
        *ngIf="mapVelues.finishZoom"
        [width]="500"
        provider="google"
        [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
        [controls]="true"
        [autoAdjust]="true"
        [zoom]="mapVelues.finishZoom"
        [center]="mapVelues.finishLocation"
        [markers]="mapVelues.finishLocationMarker"
        [routes]="mapVelues.finishLocationRoute"
        (onClick)="map_onClick($event, 'finish')"></dx-map>

      <div class="dx-field duration">Travel Time: {{ form.controls.finishTravelDuration.value }} min</div>

      <div class="dx-field duration">Finish Time: {{ timeValues.finishPrepareDuration }} min</div>

      <div class="dx-field">
        <div class="dx-field-label">Finish Time</div>
        <div class="dx-field-value time-with-propose">
          <dx-button
            stylingMode="outlined"
            text="Propose"
            type="normal"
            (onClick)="propose_onClick('finish')"></dx-button>
          <dx-date-box
            [(value)]="timeValues.finish"
            type="time"
            [showClearButton]="true"
            [interval]="10"
            (onValueChanged)="dateBox_onValueChanged($event, 'finish')"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Finish Instructions</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="finishInstructions"></dx-text-box>
        </div>
      </div>

      <!-- <div class="dx-field" [hidden]="finishFacilityBox.value"> -->
      <div class="dx-field" *ngIf="facilities?.length && !data.manifest?.lastTrip?.facilityId">
        <div class="dx-field-label">Finish Facility</div>
        <div class="dx-field-value">
          <dx-select-box
            #finishFacilityBox
            [dataSource]="facilities"
            displayExpr="name"
            valueExpr="id"
            formControlName="finishFacilityId"
            placeholder="Facility"
            (onSelectionChanged)="facility_onSelectionChanged($event, 'finish')">
          </dx-select-box>
        </div>
      </div>
    </div>
  </div>
</dx-validation-group>

