/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-fleet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/employee-fleet/employee-fleet-grid/employee-fleet-grid.component.ngfactory";
import * as i3 from "../../services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Employee";
import * as i5 from "../../../../shared/sdk/services/custom/EmployeeView";
import * as i6 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../shared/sdk/services/custom/Config";
import * as i10 from "../../../../shared/sdk/services/custom/Facility";
import * as i11 from "../../../../shared/sdk/services/custom/MyFile";
import * as i12 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i13 from "../../components/employee-fleet/employee-fleet-grid/employee-fleet-grid.component";
import * as i14 from "../../../../shared/sdk/services/custom/logger.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "./employee-fleet.component";
var styles_EmployeeFleetComponent = [i0.styles];
var RenderType_EmployeeFleetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeFleetComponent, data: {} });
export { RenderType_EmployeeFleetComponent as RenderType_EmployeeFleetComponent };
export function View_EmployeeFleetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-employee-fleet-grid", [], null, null, null, i2.View_EmployeeFleetGridComponent_0, i2.RenderType_EmployeeFleetGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.EmployeeApi, i5.EmployeeViewApi, i6.VehicleApi, i7.ConfigService, i8.CommonService, i9.ConfigApi, i10.FacilityApi, i11.MyFileApi, i12.DataSourceService]), i1.ɵdid(2, 245760, null, 0, i13.EmployeeFleetGridComponent, [i14.LoggerService, i7.ConfigService, i8.CommonService, i3.HelperService, i12.DataSourceService, i3.HelperService, i15.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EmployeeFleetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-fleet", [], null, null, null, View_EmployeeFleetComponent_0, RenderType_EmployeeFleetComponent)), i1.ɵdid(1, 114688, null, 0, i16.EmployeeFleetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeFleetComponentNgFactory = i1.ɵccf("app-employee-fleet", i16.EmployeeFleetComponent, View_EmployeeFleetComponent_Host_0, {}, {}, []);
export { EmployeeFleetComponentNgFactory as EmployeeFleetComponentNgFactory };
