import { HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import { LoadOptions } from 'devextreme/data/load_options';
import isEmpty from 'lodash-es/isEmpty';
import isFunction from 'lodash-es/isFunction';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ExtSDKModels } from '../../../../modules/ext-sdk/services/ext-sdk-models.service';
import { BaseLoopBackApi, LoopBackFilter, MyUserApi, SDKModels, TCustomOptions } from '../../../../sdk';
import { LoopBackLoadOptionsConverter } from '../load-options-converters/LoopBackLoadOptionsConverter';
import CustomStoreOptions = DevExpress.data.CustomStoreOptions;

export function errorMapFn(error: any, defaultMsg: string): void {
  throw new Error(error && error.message ? error.message : error ? error : defaultMsg);
}

export class LoopBackStoreOptions<T = any, V = any> implements CustomStoreOptions {
  useDefaultSearch = true;
  cacheRawData = false;

  noSql = false;
  useRegExp = false;
  customHeaders: { [name: string]: string } | ((headers: HttpHeaders) => HttpHeaders) = {};
  customFilter: {
    where?: any;
    fields?: string | string[] | { [property: string]: boolean };
    include?: string | string[] | any[];
    order?: string | string[];
  } = {};
  customOptions: TCustomOptions = {};

  constructor(
    @Inject(SDKModels) protected models: ExtSDKModels,
    protected loopBackApi: BaseLoopBackApi,
    protected loopBackViewApi: BaseLoopBackApi,
  ) {}

  get key() {
    return this.models.get(this.loopBackApi.getModelName()).getModelDefinition().idName;
  }

  protected applyHeaders(headers: HttpHeaders): HttpHeaders {
    if (isFunction(this.customHeaders)) {
      headers = this.customHeaders(headers);
    } else {
      Object.entries(this.customHeaders || {}).forEach(([k, v]) => (headers = headers.append(k, v)));
    }

    return headers;
  }

  protected mergeWheres(where: { [key: string]: any } | undefined) {
    if (!isEmpty(oc(this.customFilter).where())) {
      where = isEmpty(where)
        ? this.customFilter.where
        : {
            and: [this.customFilter.where, where],
          };
    }

    return where;
  }

  protected mergeFilters(loadOptionsFilter: LoopBackFilter) {
    loadOptionsFilter.where = this.mergeWheres(loadOptionsFilter.where);

    if (!isEmpty(oc(this.customFilter).fields())) {
      loadOptionsFilter.fields = loadOptionsFilter.fields || this.customFilter.fields;
    }

    if (!isEmpty(oc(this.customFilter).include())) {
      loadOptionsFilter.include = loadOptionsFilter.include || this.customFilter.include;
    }

    if (!isEmpty(oc(this.customFilter).order())) {
      loadOptionsFilter.order = loadOptionsFilter.order || this.customFilter.order;
    }

    return loadOptionsFilter;
  }

  /**
   *
   */
  byKey = (key: any | string | number): Promise<T | null | undefined> =>
    this.loopBackViewApi
      .findById<T>(
        key,
        {
          fields: this.customFilter.fields,
          include: this.customFilter.include,
        },
        this.applyHeaders.bind(this),
        this.customOptions,
      )
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Loading Error');
          return of(null);
        }),
      )
      .toPromise();

  /**
   *
   */
  load = (loadOptions: LoadOptions): Promise<V[]> =>
    this.loopBackViewApi
      .find<V>(
        this.mergeFilters.bind(this)(
          new LoopBackLoadOptionsConverter({ useRegExp: this.useRegExp, noSql: this.noSql }).convert(loadOptions),
        ),
        this.applyHeaders.bind(this),
        this.customOptions,
      )
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Loading Error');
          return of([]);
        }),
      )
      .toPromise();

  /**
   *
   */
  totalCount = (loadOptions: { filter?: any; group?: any }): Promise<number> =>
    this.loopBackViewApi
      .count(
        this.mergeWheres.bind(this)(
          new LoopBackLoadOptionsConverter({ useRegExp: this.useRegExp, noSql: this.noSql }).loadOptionsFilterToWhere(
            loadOptions.filter,
          ),
        ),
        this.applyHeaders.bind(this),
        this.customOptions,
      )
      .pipe(
        map(({ count }) => count),
        catchError(err => {
          errorMapFn(err, 'Data Count Error');
          return of(0);
        }),
      )
      .toPromise();

  /**
   *
   */
  insert = (values: any): Promise<any> =>
    this.loopBackApi
      .create<T>(
        this.models.get(this.loopBackApi.getModelName()).factory(values),
        this.applyHeaders.bind(this),
        this.customOptions,
      )
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Insert Error');
          return of(null);
        }),
      )
      .toPromise();

  /**
   *
   */
  update = (key: any | string | number, values: any): Promise<any> =>
    (Object.keys(values).length === 0
      ? // for values data just get the record
        this.loopBackApi.findById<T>(
          key,
          {
            fields: this.customFilter.fields,
            include: this.customFilter.include,
          },
          this.applyHeaders.bind(this),
          this.customOptions,
        )
      : (this.loopBackApi as any).patchAttributes(key, values, this.applyHeaders.bind(this), this.customOptions)
    )
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Update Error');
          return of(null);
        }),
      )
      .toPromise();

  /**
   *
   */
  remove = (key: any | string | number): Promise<void> =>
    this.loopBackApi
      .deleteById<T>(key, this.applyHeaders.bind(this), this.customOptions)
      .pipe(
        map(() => undefined),
        catchError(err => {
          errorMapFn(err, 'Data Remove Error');
          return of(undefined);
        }),
      )
      .toPromise();
}
