<div mat-dialog-content>
  <dx-data-grid
    #grid
    height="400"
    [dataSource]="ds"
    [columns]="gridColumns"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [selection]="gridSelection"
    [paging]="gridPaging"
    [filterRow]="gridFilterRow"
    [scrolling]="gridScrolling"
    (onSelectionChanged)="grid_onSelectionChanged($event)"></dx-data-grid>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="grid?.selectedRowKeys" cdkFocusInitial>Assign</button>
</div>
