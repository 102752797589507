import {
  Vehicle
} from '../index';

declare var Object: any;
export interface VehicleGeotabInterface {
  "vin"?: number;
  "month"?: number;
  "internalId"?: number;
  "days"?: Array<any>;
  "positions"?: Array<any>;
  "id"?: any;
  vehicle?: Vehicle;
}

export class VehicleGeotab implements VehicleGeotabInterface {
  "vin": number;
  "month": number;
  "internalId": number;
  "days": Array<any>;
  "positions": Array<any>;
  "id": any;
  vehicle: Vehicle;
  constructor(data?: VehicleGeotabInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `VehicleGeotab`.
   */
  public static getModelName() {
    return "VehicleGeotab";
  }

  /**
  * This method creates an instance of VehicleGeotab for dynamic purposes.
  **/
  public static factory(data: VehicleGeotabInterface): VehicleGeotab{
    return new VehicleGeotab(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VehicleGeotab',
      plural: 'VehicleGeotabs',
      path: 'VehicleGeotabs',
      idName: 'id',
      properties: {
        "vin": {
          name: 'vin',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "internalId": {
          name: 'internalId',
          type: 'number'
        },
        "days": {
          name: 'days',
          type: 'Array&lt;any&gt;'
        },
        "positions": {
          name: 'positions',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vin',
          keyTo: 'id'
        },
      }
    }
  }
}
