import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { AnyNote, MyUser } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import CustomStore from 'devextreme/data/custom_store';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { Observable, of } from 'rxjs';
import { LoopBackStoreOptions } from 'src/app/shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { Employee, EmployeeView, Vehicle } from '../../../../shared/sdk';

@Component({
  selector: 'app-employee-details-tab-notes',
  templateUrl: './employee-details-tab-notes.component.html',
  styleUrls: ['./employee-details-tab-notes.component.scss'],
  providers: [],
})
export class EmployeeDetailsTabNotesComponent extends ABaseComponent {
  filter: any;
  objectType: string;
  objectId: number;

  @Input()
  set modelId(modelId: number) {
    if (modelId) {
      this.filter = { employeeId: modelId };
      this.objectType = Employee.getModelName();
      this.objectId = modelId;
    }
  }

  extraFields = {
    vehicleId: [],
    noteCategory: 'Safety/Compliance Note',
    noteType: 'Other',
    dateOfEvent: new Date(),
    dateOfAcknowledgement: new Date(),
    resolutionManagerId: [],
    employeeAtFault: false,
    insuranceCompanyInformed: false,
    insuranceClaimCreated: false,
  };

  vehicleDso$: Observable<DataSourceOptions> = of([]);
  resolutionManagerDso$: Observable<DataSourceOptions> = of([]);

  constructor(protected logger: LoggerService, private dss: DataSourceService) {
    super(logger);
    this.vehicleDso$ = this.buildVehicleDataSource();
    this.resolutionManagerDso$ = this.buildResolutionManagerDataSource();
  }

  resolutionManager_displayExpr(e: EmployeeView) {
    return (e && `${e.person_firstname} ${e.person_lastname}`) || '';
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;
    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildResolutionManagerDataSource() {
    const dso = this.dss.getDataSourceOptions(Employee);
    dso.filter = ['employeePosition_id', '=', 36];
    dso.sort = [{ selector: 'person_firstname' }, { selector: 'person_lastname' }];
    return of(dso);
  }
}

