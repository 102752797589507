<app-content-w-header
  [header]="((modelId$ | async) ? 'Edit User' : 'New User') + (userForm?.form?.dirty ? ' *' : '')"
  [hasBack]="true"
  (back)="cancel_onClick()">
  <dx-scroll-view class="content">
    <mat-card>
      <form (ngSubmit)="form_ngSubmit($event)" autocomplete="off">
        <app-users-form [modelId]="modelId$ | async"></app-users-form>

        <div class="dx-fieldset">
          <dx-toolbar>
            <dxi-item location="before">
              <dx-button
                type="normal"
                text="Reset"
                (onClick)="reset_onClick($event)"
                [disabled]="userForm?.form?.pristine || userForm?.submitting"></dx-button>
            </dxi-item>

            <dxi-item location="before">
              <dx-button
                type="default"
                text="Submit"
                [useSubmitBehavior]="true"
                [disabled]="userForm?.submitting"></dx-button>
            </dxi-item>
          </dx-toolbar>
        </div>
      </form>
    </mat-card>

    <!--      -->
    <mat-card *ngIf="modelId$ | async">
      <app-roles-form #rolesForm [userId]="modelId$ | async"></app-roles-form>

      <div class="dx-fieldset">
        <dx-toolbar>
          <dxi-item location="before">
            <dx-button type="normal" text="Set Roles" (onClick)="setRoles_onClick($event)"></dx-button>
          </dxi-item>
        </dx-toolbar>
      </div>
    </mat-card>

    <!--      -->
    <mat-card>
      <form (ngSubmit)="form_MfaSubmit($event)" autocomplete="off">
        <app-mfa-form [modelId]="modelId$ | async"></app-mfa-form>

        <div class="dx-fieldset">
          <dx-toolbar>
            <dxi-item location="before">
              <dx-button type="normal" text="Submit" [useSubmitBehavior]="true" [disabled]="userForm?.submitting"></dx-button>
            </dxi-item>
          </dx-toolbar>
        </div>
      </form>
    </mat-card>

    <!--      -->
    <mat-card *ngIf="modelId$ | async">
      <app-assign-employee-form #assignEmployeeForm [userId]="modelId$ | async"></app-assign-employee-form>
    </mat-card>

    <!--      -->
    <mat-card *ngIf="modelId$ | async">
      <app-password-form #passwordForm [userId]="modelId$ | async"></app-password-form>

      <div class="dx-fieldset">
        <dx-toolbar>
          <dxi-item location="before">
            <dx-button type="normal" text="Set Password" (onClick)="setPassword_onClick($event)"></dx-button>
          </dxi-item>
        </dx-toolbar>
      </div>
    </mat-card>

    <dx-load-panel
      [visible]="userForm?.loading || userForm?.formLoading || userForm?.submitting"
      [message]="userForm?.submitting ? 'Submitting...' : 'Loading...'"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="false"></dx-load-panel>
  </dx-scroll-view>
</app-content-w-header>

<!--<dx-box direction="col" height="100%" width="100%">-->
<!--  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">-->
<!--    <mat-toolbar>-->
<!--      <a mat-icon-button matTooltip="Go Back" (click)="cancel_onClick()">-->
<!--        <mat-icon>arrow_back</mat-icon>-->
<!--      </a>-->
<!--      {{ (modelId$ | async) ? 'Edit' : 'New' }} User-->
<!--      <span *ngIf="userForm?.form?.dirty">&nbsp;*</span>-->
<!--    </mat-toolbar>-->
<!--  </dxi-item>-->

<!--  <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">-->
<!--    -->
<!--  </dxi-item>-->
<!--</dx-box>-->
