<app-content-w-header header="Authentications Log">
  <dx-data-grid
    class="content" height="100%" width="100%" [dataSource]="dso" [twoWayBindingEnabled]="false" [stateStoring]="grid_stateStoring"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  >
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxi-column caption="Time" dataField="time" dataType="date" format="shortDateShortTime"></dxi-column>
    <dxi-column caption="App Name" dataField="appName"></dxi-column>
    <dxi-column caption="Username" dataField="user.username"></dxi-column>
    <dxi-column caption="Email" dataField="user.email"></dxi-column>
    <dxi-column caption="IP" dataField="ip"></dxi-column>
    <dxi-column caption="Token Sent" dataField="tokenSent"></dxi-column>
    <dxi-column caption="Token Entered" dataField="tokenEntered"></dxi-column>
    <dxi-column caption="MFA Remembered" dataField="mfaRememberForThisBrowser" dataType="string" cellTemplate="YesNoCellTpl" [calculateFilterExpression]="calculateYesFilterExpression"></dxi-column>
    <dxi-column caption="Succeed" dataField="succeed" dataType="string" cellTemplate="YesNoCellTpl" [calculateFilterExpression]="calculateYesFilterExpression"></dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'YesNoCellTpl'">
      {{cellInfo.value && "YES" || ''}}
    </ng-container>

  </dx-data-grid>
</app-content-w-header>
