<dx-tab-panel
  width="100%"
  height="100%"
  [dataSource]="tabs"
  [selectedIndex]="0"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="false"
  [swipeEnabled]="false">
  <ng-container *dxTemplate="let data of 'profile'">
    <dx-scroll-view height="100%">
      <app-consumer-details-tab-profile [modelId]="modelId"></app-consumer-details-tab-profile>
    </dx-scroll-view>
  </ng-container>

  <ng-container *dxTemplate="let data of 'document'">
    <dx-scroll-view height="100%">
      <app-consumer-details-tab-document [modelId]="modelId"></app-consumer-details-tab-document>
    </dx-scroll-view>
  </ng-container>

  <ng-container *dxTemplate="let data of 'instructions'">
    <dx-scroll-view height="100%">
      <app-consumer-details-tab-instructions [modelId]="modelId"></app-consumer-details-tab-instructions>
    </dx-scroll-view>
  </ng-container>
</dx-tab-panel>
