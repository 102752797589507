import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi, TCustomHeaders, TCustomOptions } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Container } from '../../models/Container';


/**
 * Api services for the `Container` model.
 */
@Injectable()
export class ContainerApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getContainers(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public createContainer(options: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers";
    let _routeParams: any = {};
    let _postBody: any = {
      options: options
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `` – `{}` - 
   */
  public destroyContainer(container: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container";
    let _routeParams: any = {
      container: container
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getContainer(container: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container";
    let _routeParams: any = {
      container: container
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getFiles(container: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container/files";
    let _routeParams: any = {
      container: container
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @param {string} file 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getFile(container: any, file: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container/files/:file";
    let _routeParams: any = {
      container: container,
      file: file
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @param {string} file 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `` – `{}` - 
   */
  public removeFile(container: any, file: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container/files/:file";
    let _routeParams: any = {
      container: container,
      file: file
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `result` – `{object}` - 
   */
  public upload(container: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container/upload";
    let _routeParams: any = {
      container: container
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} container 
   *
   * @param {string} file 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public download(container: any, file: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/:container/download/:file";
    let _routeParams: any = {
      container: container,
      file: file
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get GCP Signed URL
   *
   * @param {string} filename 
   *
   * @param {string} action 
   *
   * @param {string} extensionHeaders 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getGcpSignedUrl(filename: any, action: any, extensionHeaders: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/getGcpSignedUrl";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filename !== 'undefined' && filename !== null) _urlParams.filename = filename;
    if (typeof action !== 'undefined' && action !== null) _urlParams.action = action;
    if (typeof extensionHeaders !== 'undefined' && extensionHeaders !== null) _urlParams.extensionHeaders = extensionHeaders;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get GCP Upload URL
   *
   * @param {string} bucket 
   *
   * @param {string} filename 
   *
   * @param {boolean} overwrite 
   *
   * @param {boolean} resumable 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getUploadUrl(bucket: any, filename: any = {}, overwrite: any = {}, resumable: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/getUploadUrl";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof bucket !== 'undefined' && bucket !== null) _urlParams.bucket = bucket;
    if (typeof filename !== 'undefined' && filename !== null) _urlParams.filename = filename;
    if (typeof overwrite !== 'undefined' && overwrite !== null) _urlParams.overwrite = overwrite;
    if (typeof resumable !== 'undefined' && resumable !== null) _urlParams.resumable = resumable;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get GCP Download URL
   *
   * @param {boolean} redirect 
   *
   * @param {string} convert 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getDownloadUrl(redirect: any = {}, convert: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/gs/*";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof redirect !== 'undefined' && redirect !== null) _urlParams.redirect = redirect;
    if (typeof convert !== 'undefined' && convert !== null) _urlParams.convert = convert;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Upload Any Apk
   *
   * @param {string} folder 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public uploadApk(folder: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/apk/:folder";
    let _routeParams: any = {
      folder: folder
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Last Uploaded Apk Info
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public getApkInfo(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/apkInfo/*";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Download Any Apk
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Container` object.)
   * </em>
   */
  public downloadApk(customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Containers/apk/*";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Container`.
   */
  public getModelName() {
    return "Container";
  }
}
