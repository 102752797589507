<!--<as-split direction="horizontal">-->
<!--  <as-split-area [size]="60">-->
<!--    <app-consumer-list (mySelected)="list_onSelected($event)"></app-consumer-list>-->
<!--  </as-split-area>-->

<!--  <as-split-area [size]="40">-->
<!--    <app-consumer-details [modelId]="selectedId$ | async"></app-consumer-details>-->
<!--  </as-split-area>-->
<!--</as-split>-->

<kendo-splitter>
  <kendo-splitter-pane [scrollable]='false'>
    <app-consumer-list (mySelected)='list_onSelected($event)'></app-consumer-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane [collapsible]='true' [scrollable]='false' size='40%'>
    <app-consumer-details [modelId]='selectedId$ | async'></app-consumer-details>
  </kendo-splitter-pane>
</kendo-splitter>

<!--<ejs-splitter>-->
<!--  <e-panes>-->

<!--    <e-pane size='60%'  min='40%'>-->
<!--      <ng-template #content>-->
<!--        <div class="content">-->
<!--          <app-consumer-list (mySelected)="list_onSelected($event)"></app-consumer-list>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </e-pane>-->

<!--    <e-pane size='40%' min='10%' [collapsible]='true'>-->
<!--      <ng-template #content>-->
<!--        <div class="content">-->
<!--          <app-consumer-details [modelId]="selectedId$ | async"></app-consumer-details>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </e-pane>-->

<!--  </e-panes>-->
<!--</ejs-splitter>-->
