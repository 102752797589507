import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  Covid19Checks,
  Covid19ChecksApi,
  Facility,
  LoggerService,
  LoopBackAuth,
  Signature,
  SignatureApi,
} from '../../../../shared/sdk';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { oc } from 'ts-optchain';
import { DlgSelectEmployeeComponent } from '../../dialogs/dlg-select-employee/dlg-select-employee.component';
import uniq from 'lodash-es/uniq';
import identity from 'lodash-es/identity';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-covid19-checks-daily',
  templateUrl: './covid19-checks-daily.component.html',
  styleUrls: ['./covid19-checks-daily.component.scss'],
})
export class Covid19ChecksDailyComponent extends ABaseComponent implements OnInit {
  dso = new DataSource([]);

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  facility: Facility | undefined;

  @Input() selectedDateValue?: Date = new Date();

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
    private dss: DataSourceService,
    private covidChecksApi: Covid19ChecksApi,
    public consumerHelper: ConsumerHelperService,
    public empHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'fdc716a6-15bc-4de2-a27e-15d3584f2d07',
    };
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.dso = new DataSource([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = new DataSource(as);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      switchMap(() => this.config.tenant$),

      map((tenant: Facility) => {
        if (!this.selectedDateValue) {
          throw new Error('Date should be defined');
        }

        // if (!this.facilityId) {
        //   throw new Error('Facility should be defined');
        // }

        this.facility = tenant;

        const date = this.selectedDateValue;
        const dateMoment = date && moment(date);
        const strDate = dateMoment && dateMoment.format('YYYY-MM-DD');

        return {
          date: strDate,
          facilityIds: [tenant.id],
        };
      }),

      switchMap(fltr => {
        return this.covidChecksApi.find<Covid19Checks>(
          {
            where: {
              vdate: fltr.date,
              tenantId: { inq: fltr.facilityIds },
            },
            include: [{ employee: 'person' }, { consumer: 'person' }],
          },
          headersAllTenantsAppend,
        );
      }),

      map((recs: Covid19Checks[]) => {
        recs.forEach(r => {
          (r as any)._adcCovid19Measures = this.config.get('adcCovid19Measures', '');

          (r as any).getEmployee = function () {
            const _self = this;
            return oc(_self).employee.person.firstname() + ' ' + oc(_self).employee.person.lastname();
          }.bind(r);

          (r as any).getConsumer = function () {
            const _self = this;
            return oc(_self).consumer.person.firstname() + ' ' + oc(_self).consumer.person.lastname();
          }.bind(r);

          (r as any).getName = function () {
            const _self = this;
            const participant = oc(_self).consumer() || oc(_self).employee();

            return oc(participant).person.firstname() + ' ' + oc(participant).person.lastname();
          }.bind(r);

          (r as any).getEmployeeArrivalTime = function () {
            const _self = this;
            const participant = oc(_self).employee();
            return participant ? oc(_self).arrivalTime() : undefined;
          }.bind(r);

          (r as any).getClientArrivalTime = function () {
            const _self = this;
            const participant = oc(_self).consumer();
            return participant ? oc(_self).arrivalTime() : undefined;
          }.bind(r);

          (r as any).getEmployeeRefused = function () {
            const _self = this;
            const participant = oc(_self).employee();
            return participant && oc(_self).entryRefused() ? 'REFUSED' : '';
          }.bind(r);

          (r as any).getClientRefused = function () {
            const _self = this;
            const participant = oc(_self).consumer();
            return participant && oc(_self).entryRefused() ? 'REFUSED' : '';
          }.bind(r);

          (r as any).getEmployeeRetHome = function () {
            const _self = this;
            const participant = oc(_self).employee();
            return participant ? 'NO' : '';
          }.bind(r);

          (r as any).getClientRetHome = function () {
            const _self = this;
            const participant = oc(_self).consumer();
            return participant ? 'NO' : '';
          }.bind(r);

          (r as any).getEmployeePpe = function () {
            const _self = this;
            const participant = oc(_self).employee();
            return participant && _self.ppeIssued ? 'YES' : '';
          }.bind(r);

          (r as any).getClientPpe = function () {
            const _self = this;
            const participant = oc(_self).consumer();
            return participant && _self.ppeIssued ? 'YES' : '';
          }.bind(r);

          (r as any).getAdcCovid19Measures = function () {
            const _self = this;
            return _self._adcCovid19Measures;
          }.bind(r);

          (r as any).getReportingMode = function () {
            const _self = this;
            return oc(_self).meta.covidReportMode('basic');
          }.bind(r);
        });

        const aso: ArrayStoreOptions = {
          key: Covid19Checks.getModelDefinition().idName,
          data: recs,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      // disabled: this.$showFromBroker$.value,
      name: 'buildExtReport',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'far fa-file-pdf',
        text: 'Build Extended Covid19 Report',
        hint: 'Build Extended Covid19 Report',
        onClick: () => {
          if (this.grid.instance.getSelectedRowKeys().length === 0) {
            notify('No items selected', 'error', 5000);
            return;
          }

          if (!this.selectedDateValue) {
            notify('No date selected', 'error', 5000);
            return;
          }
          const strDate = moment(this.selectedDateValue).format('YYYY-MM-DD');

          this.dss
            .getApi<SignatureApi>(Signature)
            .find<Signature>({
              where: {
                vdate: strDate,
                type: 'S',
              },
              fields: { id: true, employeeId: true },
            })
            .pipe(
              switchMap(itms =>
                this.dialog
                  .open(DlgSelectEmployeeComponent, {
                    width: '450px',
                    maxHeight: '650px',
                    hasBackdrop: true,
                    data: {
                      title: 'Select Signed Staff',
                      filter: ['id', 'inq', uniq(itms.map(s => s.employeeId))],
                    },
                  })
                  .afterClosed()
                  .pipe(
                    filter(identity),
                    tap(async ([empId]) => this.buildExtendedReport(empId)),
                  ),
              ),
              catchError(err => of(notify(err.message || err, 'error', 5000))),
              takeUntil(this.$onDestroy$),
            )
            .subscribe();
        },
      },
    });
  }

  grid_onExporting(e) {
    e.cancel = true;

    if (!this.selectedDateValue) {
      throw new Error('Date should be defined');
    }

    // if (!this.facilityId) {
    //   throw new Error('Facility should be defined');
    // }

    const date = this.selectedDateValue;
    const dateMoment = date && moment(date);
    const strDate = dateMoment && dateMoment.format('YYYY-MM-DD');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`COVID-19 Report`, {
      // pageSetup: {fitToPage: true, fitToWidth: 1},
    });

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 6, column: 1 },
      keepColumnWidths: false,
    })
      .then(dataGridRange => {
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 10;
        worksheet.getColumn(3).width = 10;
        worksheet.getColumn(4).width = 7;
        worksheet.getColumn(7).width = 5;
        worksheet.getColumn(8).width = 5;
        worksheet.getColumn(10).width = 5;
        worksheet.getColumn(11).width = 5;

        // header
        // https://github.com/exceljs/exceljs#rows
        const headerRow1 = worksheet.getRow(2);
        const headerRow2 = worksheet.getRow(3);
        const headerRow3 = worksheet.getRow(5);
        // headerRow1.height = 30;

        // https://github.com/exceljs/exceljs#merged-cells
        worksheet.mergeCells(2, 1, 2, 7);
        worksheet.mergeCells(2, 8, 2, 11);
        worksheet.mergeCells(3, 1, 3, 7);
        worksheet.mergeCells(3, 8, 3, 11);
        worksheet.mergeCells(5, 2, 5, 11);

        // https://github.com/exceljs/exceljs#value-types
        headerRow1.getCell(1).value = 'Older Adult Daily Living Center';
        headerRow1.getCell(8).value = 'Center: ' + this.facility.legalName;

        headerRow2.getCell(1).value = 'COVID-19 ReopeningGuidance Checklist';
        headerRow2.getCell(8).value = 'Date(s): ' + dateMoment.format('L');

        // https://github.com/exceljs/exceljs#fonts
        // headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
        // https://github.com/exceljs/exceljs#alignment
        // headerRow.getCell(1).alignment = {horizontal: 'center'};

        headerRow3.height = 20;
        headerRow3.getCell(1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        headerRow3.getCell(2).value = 'COVID-19 Documentation Tool';
        headerRow3.getCell(2).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        // data
        const headersRow = worksheet.getRow(dataGridRange.from.row);
        headersRow.height = 200;
        for (let col = dataGridRange.from.column; col <= dataGridRange.to.column; col++) {
          headersRow.getCell(col).alignment = {
            vertical: 'bottom',
          };

          headersRow.getCell(col).font = {
            bold: true,
          };

          if (col !== dataGridRange.from.column) {
            headersRow.getCell(col).alignment = {
              wrapText: true,
              textRotation: 90,
              // shrinkToFit: true,
            };
            headersRow.getCell(col).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00F0F0F0' },
            };
          }
        }

        for (let row = dataGridRange.from.row; row <= dataGridRange.to.row; row++) {
          const dataRow = worksheet.getRow(row);
          for (let col = dataGridRange.from.column; col <= dataGridRange.to.column; col++) {
            dataRow.getCell(col).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          }
        }

        // footer
        // const footerRowIndex = dataGridRange.to.row + 2;
        // const footerRow = worksheet.getRow(footerRowIndex);
        // worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);
        //
        // footerRow.getCell(1).value = 'Dispatch.app';
        // footerRow.getCell(1).font = {color: {argb: 'BFBFBF'}, italic: true};
        // footerRow.getCell(1).alignment = {horizontal: 'right'};
      })
      .then(() => {
        // https://github.com/exceljs/exceljs#writing-xlsx
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${this.facility.shortname} - ${strDate} COVID-19 Report.xlsx`,
          );
        });
      });
  }

  buildExtendedReport(eId: number) {
    if (!this.selectedDateValue) {
      notify('No date selected', 'error', 5000);
      return;
    }
    const strDate = moment(this.selectedDateValue).format('YYYY-MM-DD');

    const selectedKeys: any[] = this.grid.instance.getSelectedRowKeys();
    if (selectedKeys.length === 0) {
      notify('No items selected', 'error', 5000);
      return;
    }

    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_COVID_EXT_REPORT_RPC', {
        date: strDate,
        checkIds: selectedKeys,
        employeeId: eId,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap((uri: string) => window.open(uri)),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }
}
