<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      locateInMenu="never"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"></dxi-item>

    <dxi-item location="before" locateInMenu="never" cssClass="header-title" *ngIf="title" [text]="title"></dxi-item>

    <dxi-item location="before" locateInMenu="never">
      <a style="cursor: pointer" (click)="selectTenant_onClick()">
        <strong>
          <ng-container *ngIf="currentTenant$ | async as tenant; else noTenantBlock">
            [{{ tenant?.name }}]
          </ng-container>
          <ng-template #noTenantBlock>Undefined</ng-template>
        </strong>
      </a>
    </dxi-item>

    <dxi-item location="before" locateInMenu="never">
      <ng-container *ngIf="isBiller$ | async">
        <div>Last Billing Date: {{ lastBillingDate$ | async }}</div>
        <div>(130 days ago)</div>
      </ng-container>
    </dxi-item>

    <dxi-item location="center" locateInMenu="never">
      <ng-container *ngIf="signedUser$ | async as user; else noUserBlock">
        Logged in as <strong>{{ user?.username }}</strong>

        <ng-container *ngIf="signedEmployee$ | async as employee">
          [{{ employee?.person?.firstname }} {{ employee?.person?.lastname }}]
        </ng-container>

        <ng-container *ngIf="!env.production">
          {{ config.roles$ | async | json }}
        </ng-container>
      </ng-container>
      <ng-template #noUserBlock>Unauthorised</ng-template>
    </dxi-item>

    <dxi-item location="after" locateInMenu="never" widget="dxButton">
      <a routerLink="/dashboard/alerts" routerLinkActive="active"> You`ve got {{ alertCount }} alerts!</a>
    </dxi-item>

    <dxi-item
      location="after"
      locateInMenu="always"
      widget="dxButton"
      [options]="{
        text: 'Select Tenant',
        stylingMode: 'text',
        onClick: selectTenant_onClick
      }"></dxi-item>

    <dxi-item
      location="after"
      locateInMenu="always"
      [visible]="(isSignedIn$ | async) === false"
      widget="dxButton"
      [options]="{
        text: 'Login',
        stylingMode: 'text',
        onClick: login_onClick
      }"></dxi-item>

    <!--    <dxi-item-->
    <!--      location="after"-->
    <!--      locateInMenu="always"-->
    <!--      [visible]="isSignedIn$ | async"-->
    <!--      widget="dxButton"-->
    <!--      [options]="{-->
    <!--                text: 'Login Another',-->
    <!--                stylingMode: 'text',-->
    <!--                onClick: loginOther_onClick-->
    <!--            }"-->
    <!--    ></dxi-item>-->

    <dxi-item
      location="after"
      locateInMenu="always"
      [visible]="isSignedIn$ | async"
      widget="dxButton"
      [options]="{
        text: 'Logout',
        stylingMode: 'text',
        onClick: logout_onClick
      }"></dxi-item>

    <!--    <dxi-item-->
    <!--      location="after"-->
    <!--      locateInMenu="auto"-->
    <!--      menuItemTemplate="menuItem">-->
    <!--      <div *dxTemplate="let data of 'item'">-->
    <!--        <dx-button-->
    <!--          class="user-button authorization"-->
    <!--          width="170px"-->
    <!--          height="100%"-->
    <!--          stylingMode="text">-->
    <!--          <div *dxTemplate="let data of 'content'">-->
    <!--            &lt;!&ndash;          <app-user-panel [menuItems]="userMenuItems" menuMode="context"></app-user-panel>&ndash;&gt;-->
    <!--          </div>-->
    <!--        </dx-button>-->
    <!--      </div>-->
    <!--    </dxi-item>-->
    <!-- -->
    <!--    <div *dxTemplate="let data of 'menuItem'">-->
    <!--      &lt;!&ndash;    <app-user-panel [menuItems]="userMenuItems" menuMode="list"></app-user-panel>&ndash;&gt;-->
    <!--    </div>-->
  </dx-toolbar>
</header>
