import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaust, exhaustMap, tap, timeout } from 'rxjs/operators';
import { delayAsync } from '../../../../shared/classes/utils/promise.utils';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { VehicleDetailsComponent } from '../../components/vehicle-details/vehicle-details.component';
import { VehicleListComponent } from '../../components/vehicle-list/vehicle-list.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle-location.component.html',
  styleUrls: ['./vehicle-location.component.scss'],
})
export class VehicleLocationComponent extends ABaseComponent implements OnInit {
  markers: any[] = [];

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(VehicleListComponent, { static: true }) grid: VehicleListComponent;
  @ViewChild(VehicleDetailsComponent, { static: true }) details: VehicleDetailsComponent;

  constructor(protected logger: LoggerService) {
    super(logger);

    this.$resize$
      .pipe(
        exhaustMap(() =>
          of(true).pipe(
            delay(1000),
            tap(() => {
              // this.grid.repaint();
              this.details.repaint();
            }),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(markers: any[]) {
    this.markers = markers;
    // if (ids && ids.length) {
    //   this.$selectedIds$.next(ids);
    // } else {
    //   this.$selectedIds$.next([]);
    // }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}
