import { AfterViewInit, Component, Directive, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import moment from 'moment';
//
import { HelperService as ConsumerHelperService } from '../../../../../modules/consumer/services/helper.service';
import { luhnIsValid } from '../../../../classes/utils/utils';
import { Consumer } from '../../../../sdk/models';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { SnackBarTemplateComponent } from '../snack-bar-template/snack-bar-template.component';

@Directive({
  selector: '[appLuhn]',
  providers: [{ provide: NG_VALIDATORS, useExisting: LuhnValidatorDirective, multi: true }],
})
export class LuhnValidatorDirective implements Validator {
  @Input() appLuhn: boolean;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appLuhn ? luhnValidator()(control) : null;
  }
}

export function luhnValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValidLuhn = luhnIsValid(control.value);
    return !isValidLuhn ? { luhn: { value: control.value } } : null;
  };
}

//

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit, AfterViewInit {
  @ViewChild('validator', { static: false }) validator: DxValidatorComponent;

  form: FormGroup;

  @Input() ds: any;

  @Input() grid_columns: any[] = [
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
    { dataField: 'person_nickname', caption: 'Nickname' },
  ];

  maxDate = moment().endOf('month').toDate();

  fromDate;
  toDate;

  boxValue;
  providerNpi;
  providerMa;
  providerOon;

  recNumber;
  ssnNumber;
  dob;

  constructor(
    public consumerHelper: ConsumerHelperService,
    private dss: DataSourceService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.form = this.fb.group({
      test: [],
      files: [],
      weekcheck: [],
      week: [],
      cns: [3],
    });

    this.ds = {
      store: this.dss.getStore(Consumer, null, false),
    };
  }

  numericValidator(val) {
    return /^\d+$/g.test(val);
  }

  ngOnInit() {
    const snackBarRef = this.snackBar.openFromComponent(SnackBarTemplateComponent, {
      data: { actions: ['1', '2'], message: 'hello' },
    });

    snackBarRef.instance.action.subscribe(o => {
      console.log(o);
      snackBarRef.dismissWithAction();
    });
  }

  ngAfterViewInit(): void {}
}
