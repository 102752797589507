import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';
//
import { Facility, FacilityView, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { FACILITY_TYPES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import { FacilityFormComponent } from '../facility-form/facility-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-facility-grid',
  templateUrl: './facility-grid.component.html',
  styleUrls: ['./facility-grid.component.scss'],
  providers: [{ provide: 'Window', useValue: window }, HelperService],
})
export class FacilityGridComponent implements OnInit, OnDestroy {
  types = FACILITY_TYPES;

  dso: DataSourceOptions;
  grid_stateStoring: any;
  modifiedSubscription: Subscription;

  // form: any;

  @Output() mySelected: EventEmitter<FacilityView[]> = new EventEmitter<FacilityView[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    @Inject('Window') public window: Window,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('0e6b343b-34cd-41c9-af4a-bac9127ba238');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '0bad34ad-cb16-434c-87dd-3c9f9083c1da',
    };

    this.dso = this.dss.getDataSourceOptions(Facility);
    // this.buildForm();

    // this.dataSource.on({
    //   'changed': (e) => console.log('changed', e),
    //   'loadError': (e) => console.log('loadError', e),
    // });
    //
    // this.dataSource.store().on({
    //   'inserted': (e) => console.log('inserted', e),
    //   'loaded': (e) => console.log('loaded', e),
    //   'modified': (e) => console.log('modified', e),
    //   'removed': (e) => console.log('removed', e),
    //   'updated': (e) => console.log('updated', e),
    // });
  }

  ngOnInit(): void {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Facility.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
      // mapper: this.savingFieldMapper,
      // processor: this.saveDataProcessor,
    });
  }

  grid_onDataErrorOccurred(e) {
    notify(e.error, 'error', 3000);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newFacility',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-plus',
        text: 'New facility',
        hint: 'Create new facility',
        onClick: this.toolbarNew_onClick.bind(this),
      },
    });
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    // this.logger.log(e);
    const title = `Edit: ${this.helper.displayExpr(e.data)}`;
    // this.form.items[0].caption = title;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Facility,
      FormComponentClass: FacilityFormComponent,
      title,
    });
  }

  toolbarNew_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Facility,
      FormComponentClass: FacilityFormComponent,
    });
  }

  // savingFieldMapper = (key, value): [string, any] => {
  //   if (isString(value) && isEmpty(value))
  //     value = null;
  //
  //   return [key, value];
  // };
  //
  // saveDataProcessor = (data) => {
  //   if (data.id) {
  //     data.contact = data.contact || {};
  //   }
  //
  //   return data;
  // };

  // private buildForm() {
  //   this.form = {
  //     colCount: 2,
  //     items: [{
  //       colSpan: 2,
  //       itemType: 'group',
  //       caption: 'General',
  //       items: [
  //         {dataField: 'name', isRequired: true, editorOptions: {placeholder: 'Full Facility Name'}},
  //         {dataField: 'shortname', isRequired: true, editorOptions: {placeholder: 'Short Facility Name'}},
  //         {dataField: 'type', isRequired: true},
  //         {dataField: 'notes', isRequired: false},
  //       ]
  //     }, {
  //       disabled: true,
  //       itemType: 'group',
  //       caption: 'Address',
  //       items: [
  //         'contact_addresses_0_street',
  //         'contact_addresses_0_city',
  //         'contact_addresses_0_state',
  //         'contact_addresses_0_zip',
  //       ]
  //     }, {
  //       disabled: true,
  //       itemType: 'group',
  //       caption: 'Contact',
  //       items: [
  //         'contact_phones_0_value',
  //         'contact_emails_0_value',
  //       ]
  //     }]
  //   };
  // }
}
