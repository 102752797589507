export const CUSTOM_FIELD_TYPES: any[] = [
  { ID: 'STRING', Name: 'STRING' },
  { ID: 'NUMBER', Name: 'NUMBER' },
  { ID: 'CHECKBOX', Name: 'CHECKBOX' },
  { ID: 'DATE', Name: 'DATE' },
  { ID: 'EXPIRATION_DATE', Name: 'EXPIRATION_DATE' },
  // {ID: 'DATETIME', Name: 'DATETIME'},
  // {ID: 'TIME', Name: 'TIME'},
  // {ID: 'LOOKUP', Name: 'LOOKUP'},
  { ID: 'DROPDOWN', Name: 'DROPDOWN' },
  { ID: 'WEEKDAY_NUMBER_MAP', Name: 'WEEKDAY_NUMBER_MAP' },
];
