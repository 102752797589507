<dx-data-grid
  #grid
  height="100%"
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onContentReady)="grid_onContentReady($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="500"></dxo-paging>

  <dxo-export [enabled]="true" fileName="vehicles-locations-export"></dxo-export>

  <dxo-selection selectAllMode="allPages" showCheckBoxesMode="onClick" mode="multiple"></dxo-selection>

  <!-- Columns -->

  <dxi-column caption="Internal ID" dataField="internalId" dataType="number"></dxi-column>

  <dxi-column
    caption="Driver on Schedule"
    [dataField]="manifestHelper.getRecFieldMap.employeeId"
    [calculateCellValue]="getDriverCellValue"
    dataType="string">
  </dxi-column>

  <dxi-column
    *ngIf="isBase"
    caption="Facility on Schedule"
    dataField="tenantIds"
    [calculateCellValue]="getFacilityCellValue"
    dataType="string">
  </dxi-column>

  <dxi-column caption="State" dataField="state" dataType="string">
    <dxo-lookup [dataSource]="STATES"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Status" dataField="status" dataType="string"></dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string"></dxi-column>

  <dxi-column caption="Location" dataField="location" dataType="string"></dxi-column>

  <dxi-column *ngIf="isBase" caption="Assigned Facility" dataField="assignedFacility" dataType="string"></dxi-column>

  <dxi-column caption="Year" dataField="year" dataType="number"></dxi-column>

  <dxi-column caption="Make" dataField="make" dataType="string"></dxi-column>

  <dxi-column caption="Model" dataField="model" dataType="string"></dxi-column>

  <dxi-column caption="VIN" dataField="vin" dataType="string"></dxi-column>

  <dxi-column caption="License Plate Number" dataField="licensePlateNumber" dataType="string"></dxi-column>

  <dxi-column
    caption="Last CI Date"
    dataField="meta.lastCIDate"
    dataType="date"
    format="shortDate"
    selectedFilterOperation="between"></dxi-column>

  <dxi-column
    caption="Last CI Odometer"
    dataField="meta.lastCIOdometer"
    dataType="number"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <dxi-column
    caption="GeoTab GPS Location"
    [calculateCellValue]="getGeoTabLocationCellValue"
    dataType="date"
    cellTemplate="geoTabLocation_cell"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <dxi-column
    caption="GeoTab GPS Time"
    [calculateCellValue]="getGeoTabDateTimeCellValue"
    cellTemplate="geoTabTimeAgo_cell"
    dataType="date"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="true"
    [calculateSortValue]="sortGeoTabTime"></dxi-column>

  <dxi-column
    caption="Last Ignition On"
    [calculateCellValue]="getGeoTabLastIgnitionOnCellValue"
    cellTemplate="geoTabTimeAgo_cell"
    dataType="date"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="true"
    sortOrder="desc"
    [calculateSortValue]="sortLastIgnitionOn"></dxi-column>

  <dxi-column
    caption="Last Ignition Off"
    [calculateCellValue]="getGeoTabLastIgnitionOffCellValue"
    cellTemplate="geoTabTimeAgo_cell"
    dataType="date"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="true"
    [calculateSortValue]="sortLastIgnitionOff"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'geoTabLocation_cell'">
    <a *ngIf="cellInfo.value" target="_blank" [href]="getMapURL(cellInfo.value)" title="link">link</a>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'geoTabTimeAgo_cell'">
    <span>{{ getTimeAgo(cellInfo.value) }}</span>
  </ng-container>
</dx-data-grid>

