import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-template',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './snack-bar-template.component.html',
  styleUrls: ['./snack-bar-template.component.scss'],
})
export class SnackBarTemplateComponent {
  @Output() action: EventEmitter<{ action: string; data: any }> = new EventEmitter();

  constructor(
    // private snackBarRef: MatSnackBarRef<SnackBarTemplateComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      actions: string[];
    },
  ) {}

  doAction(action: string) {
    // this.snackBarRef.dismissWithAction();
    this.action.emit({ action, data: this.data });
  }
}
