/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-position-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-position-grid/employee-position-grid.component.ngfactory";
import * as i3 from "../employee-position-grid/employee-position-grid.component";
import * as i4 from "../../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../../shared/modules/my-common/services/datasource.service";
import * as i7 from "../../../../../shared/modules/ui/services/grid-helper.service";
import * as i8 from "./employee-position-list.component";
var styles_EmployeePositionListComponent = [i0.styles];
var RenderType_EmployeePositionListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePositionListComponent, data: {} });
export { RenderType_EmployeePositionListComponent as RenderType_EmployeePositionListComponent };
export function View_EmployeePositionListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-position-grid", [], null, null, null, i2.View_EmployeePositionGridComponent_0, i2.RenderType_EmployeePositionGridComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmployeePositionGridComponent, [i4.LoggerService, i5.ConfigService, i6.DataSourceService, i7.GridHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EmployeePositionListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-position-list", [], null, null, null, View_EmployeePositionListComponent_0, RenderType_EmployeePositionListComponent)), i1.ɵdid(1, 114688, null, 0, i8.EmployeePositionListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeePositionListComponentNgFactory = i1.ɵccf("app-employee-position-list", i8.EmployeePositionListComponent, View_EmployeePositionListComponent_Host_0, {}, {}, []);
export { EmployeePositionListComponentNgFactory as EmployeePositionListComponentNgFactory };
