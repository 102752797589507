import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { TripManifestMainToolbarComponent } from '../trip-manifest-main-toolbar/trip-manifest-main-toolbar.component';
import { HelperService } from '../../services/helper.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';

@Component({
  selector: 'app-trip-manifest-tabs',
  templateUrl: './trip-manifest-tabs.component.html',
  styleUrls: ['./trip-manifest-tabs.component.scss'],
  providers: [HelperService, ConsumerHelperService, EmployeeHelperService],
})
export class TripManifestTabsComponent {
  @ViewChild(TripManifestMainToolbarComponent, { static: true }) mainToolbarComponent: TripManifestMainToolbarComponent;

  constructor(protected logger: LoggerService, protected cd: ChangeDetectorRef) {}

  onManifestCreated(e) {
    if (this.mainToolbarComponent) {
      this.mainToolbarComponent.buildDateManifestMap();
    }
  }

  onManifestDeleted(e) {
    if (this.mainToolbarComponent) {
      this.mainToolbarComponent.buildDateManifestMap();
    }
  }
}
