import {
  Acquisition,
  Vehicle,
  Consumer,
  Employee,
  Facility,
  MyUser,
  EmployeeView,
  AutoRepairShop
} from '../index';

declare var Object: any;
export interface AnyNoteInterface {
  "consumerId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  "acquisitionId"?: number;
  "relatedNoteId"?: string;
  "subject"?: string;
  "description"?: string;
  "createdAt"?: Date;
  "createdById"?: number;
  "updatedAt"?: Date;
  "updatedById"?: number;
  "tenantId"?: number;
  "notificationGroupUserIds"?: Array<any>;
  "notifyUsers"?: Array<any>;
  "documents"?: Array<any>;
  "comments"?: Array<any>;
  "noteCategory"?: string;
  "noteType"?: string;
  "dateOfEvent"?: Date;
  "dateOfAcknowledgement"?: Date;
  "resolutionManagerId"?: number;
  "employeeAtFault"?: boolean;
  "insuranceCompanyInformed"?: boolean;
  "insuranceClaimCreated"?: boolean;
  "serviceType"?: string;
  "dateOffDuty"?: Date;
  "dateReturnedOnDuty"?: Date;
  "autoRepairShopId"?: any;
  "assignedFleetManagerId"?: number;
  "id"?: any;
  "facilityId"?: number;
  acquisition?: Acquisition;
  vehicle?: Vehicle;
  consumer?: Consumer;
  employee?: Employee;
  relatedNote?: AnyNote;
  facility?: Facility;
  createdBy?: MyUser;
  updatedBy?: MyUser;
  resolutionManager?: EmployeeView;
  assignedFleetManager?: EmployeeView;
  autoRepairShop?: AutoRepairShop;
}

export class AnyNote implements AnyNoteInterface {
  "consumerId": number;
  "employeeId": number;
  "vehicleId": number;
  "acquisitionId": number;
  "relatedNoteId": string;
  "subject": string;
  "description": string;
  "createdAt": Date;
  "createdById": number;
  "updatedAt": Date;
  "updatedById": number;
  "tenantId": number;
  "notificationGroupUserIds": Array<any>;
  "notifyUsers": Array<any>;
  "documents": Array<any>;
  "comments": Array<any>;
  "noteCategory": string;
  "noteType": string;
  "dateOfEvent": Date;
  "dateOfAcknowledgement": Date;
  "resolutionManagerId": number;
  "employeeAtFault": boolean;
  "insuranceCompanyInformed": boolean;
  "insuranceClaimCreated": boolean;
  "serviceType": string;
  "dateOffDuty": Date;
  "dateReturnedOnDuty": Date;
  "autoRepairShopId": any;
  "assignedFleetManagerId": number;
  "id": any;
  "facilityId": number;
  acquisition: Acquisition;
  vehicle: Vehicle;
  consumer: Consumer;
  employee: Employee;
  relatedNote: AnyNote;
  facility: Facility;
  createdBy: MyUser;
  updatedBy: MyUser;
  resolutionManager: EmployeeView;
  assignedFleetManager: EmployeeView;
  autoRepairShop: AutoRepairShop;
  constructor(data?: AnyNoteInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AnyNote`.
   */
  public static getModelName() {
    return "AnyNote";
  }

  /**
  * This method creates an instance of AnyNote for dynamic purposes.
  **/
  public static factory(data: AnyNoteInterface): AnyNote{
    return new AnyNote(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AnyNote',
      plural: 'AnyNotes',
      path: 'AnyNotes',
      idName: 'id',
      properties: {
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
        "acquisitionId": {
          name: 'acquisitionId',
          type: 'number'
        },
        "relatedNoteId": {
          name: 'relatedNoteId',
          type: 'string'
        },
        "subject": {
          name: 'subject',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "createdById": {
          name: 'createdById',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedById": {
          name: 'updatedById',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "notificationGroupUserIds": {
          name: 'notificationGroupUserIds',
          type: 'Array&lt;any&gt;'
        },
        "notifyUsers": {
          name: 'notifyUsers',
          type: 'Array&lt;any&gt;'
        },
        "documents": {
          name: 'documents',
          type: 'Array&lt;any&gt;'
        },
        "comments": {
          name: 'comments',
          type: 'Array&lt;any&gt;'
        },
        "noteCategory": {
          name: 'noteCategory',
          type: 'string'
        },
        "noteType": {
          name: 'noteType',
          type: 'string'
        },
        "dateOfEvent": {
          name: 'dateOfEvent',
          type: 'Date'
        },
        "dateOfAcknowledgement": {
          name: 'dateOfAcknowledgement',
          type: 'Date'
        },
        "resolutionManagerId": {
          name: 'resolutionManagerId',
          type: 'number'
        },
        "employeeAtFault": {
          name: 'employeeAtFault',
          type: 'boolean',
          default: false
        },
        "insuranceCompanyInformed": {
          name: 'insuranceCompanyInformed',
          type: 'boolean'
        },
        "insuranceClaimCreated": {
          name: 'insuranceClaimCreated',
          type: 'boolean'
        },
        "serviceType": {
          name: 'serviceType',
          type: 'string'
        },
        "dateOffDuty": {
          name: 'dateOffDuty',
          type: 'Date'
        },
        "dateReturnedOnDuty": {
          name: 'dateReturnedOnDuty',
          type: 'Date'
        },
        "autoRepairShopId": {
          name: 'autoRepairShopId',
          type: 'any'
        },
        "assignedFleetManagerId": {
          name: 'assignedFleetManagerId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
      },
      relations: {
        acquisition: {
          name: 'acquisition',
          type: 'Acquisition',
          model: 'Acquisition',
          relationType: 'belongsTo',
                  keyFrom: 'acquisitionId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'consumerId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        relatedNote: {
          name: 'relatedNote',
          type: 'AnyNote',
          model: 'AnyNote',
          relationType: 'belongsTo',
                  keyFrom: 'relatedNoteId',
          keyTo: 'id'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'createdById',
          keyTo: 'id'
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'updatedById',
          keyTo: 'id'
        },
        resolutionManager: {
          name: 'resolutionManager',
          type: 'EmployeeView',
          model: 'EmployeeView',
          relationType: 'belongsTo',
                  keyFrom: 'resolutionManagerId',
          keyTo: 'id'
        },
        assignedFleetManager: {
          name: 'assignedFleetManager',
          type: 'EmployeeView',
          model: 'EmployeeView',
          relationType: 'belongsTo',
                  keyFrom: 'assignedFleetManagerId',
          keyTo: 'id'
        },
        autoRepairShop: {
          name: 'autoRepairShop',
          type: 'AutoRepairShop',
          model: 'AutoRepairShop',
          relationType: 'belongsTo',
                  keyFrom: 'autoRepairShopId',
          keyTo: 'id'
        },
      }
    }
  }
}
