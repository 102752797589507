/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auto-dispatch-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../auto-dispatch-grid/auto-dispatch-grid.component.ngfactory";
import * as i3 from "../auto-dispatch-grid/auto-dispatch-grid.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../shared/modules/ui/services/ui.service";
import * as i7 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i8 from "../../../../shared/modules/my-common/services/state-store.service";
import * as i9 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../shared/sdk/services/core/auth.service";
import * as i12 from "./auto-dispatch-list.component";
var styles_AutoDispatchListComponent = [i0.styles];
var RenderType_AutoDispatchListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutoDispatchListComponent, data: {} });
export { RenderType_AutoDispatchListComponent as RenderType_AutoDispatchListComponent };
export function View_AutoDispatchListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-auto-dispatch-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AutoDispatchGridComponent_0, i2.RenderType_AutoDispatchGridComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i3.AutoDispatchGridComponent, [i4.LoggerService, i5.ConfigService, i6.UiService, i7.DataSourceService, i8.StateStoreService, i9.GridHelperService, i10.MatDialog, i11.LoopBackAuth], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AutoDispatchListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auto-dispatch-list", [], null, null, null, View_AutoDispatchListComponent_0, RenderType_AutoDispatchListComponent)), i1.ɵdid(1, 114688, null, 0, i12.AutoDispatchListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoDispatchListComponentNgFactory = i1.ɵccf("app-auto-dispatch-list", i12.AutoDispatchListComponent, View_AutoDispatchListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { AutoDispatchListComponentNgFactory as AutoDispatchListComponentNgFactory };
