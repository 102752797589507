import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//
import { Employee, EmployeePayroll, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import { dxStoreLoadHooks } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import CustomStore from 'devextreme/data/custom_store';
import { EmployeePayrollEditFormComponent } from './employee-payroll-edit-form/employee-payroll-edit-form.component';
import moment from 'moment';
export class EmployeeDetailsTabPayrollComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, docHelper, dialog) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.docHelper = docHelper;
        this.dialog = dialog;
        this.PAYMENT_ARRANGEMENT = { hourly: 'Hourly', salary: 'Salary', contractor: 'Contractor' };
        this.onClickEditPayroll = () => {
            this.ui
                .openEditDialog({
                modelId: this.payroll && this.payroll.id,
                inputs: { employeeId: this.model.id },
                ModelClass: EmployeePayroll,
                FormComponentClass: EmployeePayrollEditFormComponent,
            })
                .afterClosed()
                .toPromise()
                .then(payroll => {
                this.historyDSO = this.buildHistoryDSO(this.model);
            });
        };
    }
    get ModelClass() {
        return Employee;
    }
    get FormComponent() {
        return EmployeeFormComponent;
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.historyDSO = this.buildHistoryDSO(model);
        });
    }
    buildHistoryDSO(model) {
        const so = this.dss.getStoreOptions(EmployeePayroll, null, false);
        so.customFilter = { where: { employeeId: model.id } };
        const store = new CustomStore(so);
        dxStoreLoadHooks(store, undefined, (args, [[payroll]]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.payroll = (payroll && Object.assign({}, payroll)) || undefined;
            const rateText = (pa, { rate, rate1 }) => {
                if (pa === 'hourly')
                    return `${rate} dollars per hour`;
                if (pa === 'salary')
                    return `${rate} dollars per year`;
                if (pa === 'contractor')
                    return `${rate} dollars, plus ${rate1} per mile`;
            };
            return [
                ((payroll && payroll.history) || []).map((_a, i) => {
                    var { paymentArrangement: pa } = _a, v = tslib_1.__rest(_a, ["paymentArrangement"]);
                    return (Object.assign({ id: i }, v, { paymentArrangement: this.PAYMENT_ARRANGEMENT[pa], rateText: rateText(pa, v), period: (pa === 'contractor' &&
                            `${moment(v.start).format('MM/DD/YYYY')} - ${moment(v.end).format('MM/DD/YYYY')}`) ||
                            '' }));
                }),
            ];
        }));
        return { store };
    }
}
