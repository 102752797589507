import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { castArray } from 'lodash-es';
import { ConfigService } from '../services/config.service';

@Pipe({
  name: 'someRole',
})
export class SomeRolePipe implements PipeTransform {
  constructor(
    private ref: ChangeDetectorRef,
    private config: ConfigService,
  ) {}

  transform(value: string | string[]): boolean {
    const roles = castArray(value);
    const result$ = this.config.hasAnyRole$(roles);
    return new AsyncPipe(this.ref).transform(result$);
  }
}
