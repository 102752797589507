import {
  Contact,
  FacilityEmPerson,
  Person,
  Employee,
  Document
} from '../index';

declare var Object: any;
export interface FacilityInterface {
  "status": string;
  "name": string;
  "legalName": string;
  "shortname": string;
  "type": string;
  "data"?: any;
  "timezoneOffset"?: number;
  "geotabZoneId"?: string;
  "notes"?: string;
  "dtime"?: Date;
  "id"?: number;
  "contactId"?: number;
  contact?: Contact;
  emRelations?: FacilityEmPerson[];
  emPeople?: Person[];
  trainedEmployees?: Employee[];
  assignedEmployees?: Employee[];
  relatedDocuments?: Document[];
}

export class Facility implements FacilityInterface {
  "status": string;
  "name": string;
  "legalName": string;
  "shortname": string;
  "type": string;
  "data": any;
  "timezoneOffset": number;
  "geotabZoneId": string;
  "notes": string;
  "dtime": Date;
  "id": number;
  "contactId": number;
  contact: Contact;
  emRelations: FacilityEmPerson[];
  emPeople: Person[];
  trainedEmployees: Employee[];
  assignedEmployees: Employee[];
  relatedDocuments: Document[];
  constructor(data?: FacilityInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Facility`.
   */
  public static getModelName() {
    return "Facility";
  }

  /**
  * This method creates an instance of Facility for dynamic purposes.
  **/
  public static factory(data: FacilityInterface): Facility{
    return new Facility(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Facility',
      plural: 'Facilities',
      path: 'Facilities',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'string',
          default: 'ACTIVE'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "legalName": {
          name: 'legalName',
          type: 'string'
        },
        "shortname": {
          name: 'shortname',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "timezoneOffset": {
          name: 'timezoneOffset',
          type: 'number',
          default: -4
        },
        "geotabZoneId": {
          name: 'geotabZoneId',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "contactId": {
          name: 'contactId',
          type: 'number'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'contactId',
          keyTo: 'id'
        },
        emRelations: {
          name: 'emRelations',
          type: 'FacilityEmPerson[]',
          model: 'FacilityEmPerson',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'facilityId'
        },
        emPeople: {
          name: 'emPeople',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'FacilityEmPerson',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'facilityId'
        },
        trainedEmployees: {
          name: 'trainedEmployees',
          type: 'Employee[]',
          model: 'Employee',
          relationType: 'hasMany',
          modelThrough: 'TrainedAtFacility',
          keyThrough: 'employeeId',
          keyFrom: 'id',
          keyTo: 'facilityId'
        },
        assignedEmployees: {
          name: 'assignedEmployees',
          type: 'Employee[]',
          model: 'Employee',
          relationType: 'hasMany',
          modelThrough: 'AssignedToFacility',
          keyThrough: 'employeeId',
          keyFrom: 'id',
          keyTo: 'facilityId'
        },
        relatedDocuments: {
          name: 'relatedDocuments',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
      }
    }
  }
}
