import { Component, Input, OnInit } from '@angular/core';
import { LoggerService, Person } from '../../../../shared/sdk';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PersonalPhotoCropperComponent } from '../personal-photo-cropper/personal-photo-cropper.component';

@Component({
  selector: 'app-personal-photo',
  templateUrl: './personal-photo.component.html',
  styleUrls: ['./personal-photo.component.scss'],
})
export class PersonalPhotoComponent implements OnInit {
  @Input() person: Person;

  constructor(protected logger: LoggerService, private dialog: MatDialog) {}

  ngOnInit() {}

  openCropper() {
    const dialogRef = this.dialog.open(PersonalPhotoCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: this.person,
    });

    dialogRef.afterClosed().subscribe(personalPhoto => {
      // if (personalPhoto) {
      // }
    });
  }
}

