<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Employment Status</div>
        <div class="dx-field-value-static">
          {{ model?.status }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Interview Date</div>
        <div class="dx-field-value-static">
          <time>{{ model?.interviewedAt | date: 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Date Hired</div>
        <div class="dx-field-value-static">
          <time>{{ model?.hiredAt | date: 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Employment Type</div>
        <div class="dx-field-value-static">
          {{ model?.type }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.separatedAt">
        <div class="dx-field-label">Date Separated</div>
        <div class="dx-field-value-static">
          <time>{{ model?.separatedAt | date: 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Person Notes</div>
        <div class="dx-field-value-static">
          {{ model?.notes }}
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Notices</div>
      <dx-data-grid
        [dataSource]="noticeDSO"
        [showColumnLines]="false"
        [showRowLines]="false"
        [showBorders]="false"
        (onToolbarPreparing)="onNoticesToolbarPreparing($event)">
        <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

        <dxo-group-panel [visible]="false"></dxo-group-panel>

        <dxo-header-filter [visible]="false"></dxo-header-filter>

        <dxo-filter-row [visible]="false"></dxo-filter-row>

        <dxo-paging [pageSize]="10"></dxo-paging>

        <!-- Columns -->

        <dxi-column caption="Name" dataField="name" [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="File"
          dataField="files_0_originalFilename"
          cellTemplate="href_cell"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Uploaded At"
          dataField="files_0_uploadedAt"
          dataType="date"
          format="shortDateShortTime"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column caption="Uploaded By" dataField="files_0_uploadedById" [allowHeaderFiltering]="false"></dxi-column>

        <!-- Templates -->

        <ng-container *dxTemplate="let cellInfo of 'href_cell'">
          <a
            target="_blank"
            [href]="cellInfo.data.files_0_id | fileSignedUrl | async | sanitizeUrl"
            title="{{ cellInfo.displayValue }}"
            >{{ cellInfo.text }}</a
          >
        </ng-container>
      </dx-data-grid>
    </div>
  </ng-container>
</app-details-tab>
