import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';
import { type } from '../../shared/classes/utils/utils';

export const GO = type('[Router] Go');
export const BACK = type('[Router] Back');
export const FORWARD = type('[Router] Forward');

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    },
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export type Actions = Go | Back | Forward;
