<app-content-w-header header="Fuel Card Report">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <dx-select-box
        [dataSource]="facilityDso$ | async"
        displayExpr="name"
        valueExpr="id"
        [(value)]="facilityId"
        placeholder="Facility"
        [showClearButton]="true"
        (onSelectionChanged)="facility_onSelectionChanged($event)"></dx-select-box>

      <dx-select-box
        width="80px"
        [dataSource]="vehicleDso$ | async"
        displayExpr="internalId"
        valueExpr="id"
        [(value)]="vehicleId"
        placeholder="Vehicle"
        [showClearButton]="true"
        [searchEnabled]="true"></dx-select-box>

      <app-employee-drop-down-grid
        [(value)]="driverId"
        [filter]="[]"
        [dataSource]="employeeDso$ | async"
        [grid_columns]="[
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso$ | async,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"></app-employee-drop-down-grid>

      <dx-text-box width="100px" [(value)]="cardName" placeholder="Fuel Card Name"></dx-text-box>

      <dx-number-box width="100px" [(value)]="amount" placeholder="Amount"></dx-number-box>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filterDs()"></dx-button>
      </div>

      <span style="flex: auto"></span>

      <span *ngIf="selectedTabIndex === 0">Page Total Amount: {{ pageTotal | currency: '$' }}</span>
    </div>
  </mat-card>

  <dx-tab-panel class="content" width="100%" height="100%" [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex">
    <div *dxTemplate="let data of 'grid'">
      <dx-data-grid
        class="content"
        width="100%"
        height="100%"
        [dataSource]="gridDso"
        [stateStoring]="grid_stateStoring"
        [twoWayBindingEnabled]="false"
        [repaintChangesOnly]="true"
        [errorRowEnabled]="false"
        [searchPanel]="{ visible: false }"
        [headerFilter]="{ visible: false }"
        [filterRow]="{ visible: true }"
        [filterPanel]="{ visible: true }"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [(filterValue)]="filterValue"
        (onInitialized)="grid_onInitialized($event)"
        (onCellPrepared)="grid_onCellPrepared($event)"
        (onToolbarPreparing)="grid_onToolbarPreparing($event)"
        (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
        (onSaved)="grid_onSaved($event)">
        <dxo-editing mode="none" [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

        <dxo-remote-operations
          [filtering]="true"
          [sorting]="true"
          [paging]="true"
          [summary]="true"></dxo-remote-operations>

        <dxo-paging [enabled]="true" [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="true"
          [allowedPageSizes]="[20, 50, 100]"
          [showInfo]="true">
        </dxo-pager>

        <!--    <dxo-selection-->
        <!--      [mode]="'multiple'"-->
        <!--      [selectAllMode]="'allPages'"-->
        <!--    ></dxo-selection>-->

        <!--    <dxo-export-->
        <!--      [enabled]="true"-->
        <!--      fileName="claims-export"-->
        <!--    ></dxo-export>-->

        <!-- region Columns -->

        <!--<dxi-column type="selection" [sortIndex]="0"></dxi-column>-->

        <!--    <dxi-column caption="User"-->
        <!--                dataField="userId"-->
        <!--    ></dxi-column>-->

        <dxi-column
          caption="Date"
          dataField="vdate"
          dataType="date"
          format="shortDate"
          [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

        <dxi-column
          caption="DateTime"
          dataField="dateTime"
          dataType="datetime"
          format="shortDateShortTime"
          [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

        <dxi-column caption="Facility" dataField="employee_tenantId" dataType="string">
          <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"> </dxo-lookup>
        </dxi-column>

        <dxi-column caption="Vehicle" dataField="vehicleId" [visible]="false" [showInColumnChooser]="false">
          <dxo-lookup [dataSource]="vehicleDso$ | async" valueExpr="id" displayExpr="internalId"> </dxo-lookup>
        </dxi-column>

        <dxi-column caption="Vehicle" dataField="vehicle_internalId" dataType="string"></dxi-column>

        <dxi-column caption="Employee" dataField="employeeId" [visible]="false" [showInColumnChooser]="false">
          <dxo-lookup [dataSource]="employeeDso$ | async" valueExpr="id" [displayExpr]="employeeHelper.displayExpr">
          </dxo-lookup>
        </dxi-column>

        <dxi-column caption="Driver Firstname" dataField="employee_person_firstname" dataType="string"></dxi-column>

        <dxi-column caption="Driver Lastname" dataField="employee_person_lastname" dataType="string"></dxi-column>

        <dxi-column caption="Fuel Card Number" dataField="cardNum" dataType="string"></dxi-column>

        <!--    <dxi-column caption="Fuel Card Name"-->
        <!--                dataType="string"-->
        <!--                cellTemplate="cardName_cell"-->
        <!--                [allowSearch]="false"-->
        <!--                [allowFiltering]="false"-->
        <!--                [allowHeaderFiltering]="false"-->
        <!--                [allowSorting]="false"-->
        <!--    ></dxi-column>-->

        <dxi-column
          caption="Fuel Card Name"
          dataType="string"
          dataField="_fc.cardName"
          [allowSearch]="false"
          [allowFiltering]="false"
          [allowHeaderFiltering]="false"
          [allowSorting]="false"></dxi-column>

        <dxi-column
          caption="Odometer"
          dataField="odometer"
          dataType="number"
          [format]="{ useGrouping: true }"></dxi-column>

        <dxi-column
          caption="Amount"
          dataField="amount"
          dataType="number"
          [format]="{ type: 'currency', precision: 2 }"></dxi-column>

        <dxi-column
          caption="Receipt"
          dataField="receiptImgFileId"
          dataType="string"
          cellTemplate="receipt_cell"
          [allowSearch]="false"
          [allowFiltering]="false"
          [allowHeaderFiltering]="false"
          [allowSorting]="false"></dxi-column>

        <!-- endregion Columns -->

        <!--    <dxo-summary [calculateCustomSummary]="calculateCustomSummary">-->
        <!--      <dxi-total-item-->
        <!--        name="AmountRowsSummary"-->
        <!--        summaryType="custom"-->
        <!--        showInColumn="amount"-->
        <!--        valueFormat="currency"-->
        <!--      ></dxi-total-item>-->
        <!--    </dxo-summary>-->

        <!-- region Templates -->

        <ng-container *dxTemplate="let cellInfo of 'cardName_cell'">
          {{ cellInfo.data._cardName$ | async }}
        </ng-container>

        <ng-container *dxTemplate="let cellInfo of 'receipt_cell'">
          <img
            [id]="'img_' + cellInfo.data.id"
            style="margin: -5px 0; cursor: zoom-in; max-height: 18px"
            (mouseenter)="popover[cellInfo.data.id] = true"
            (mouseleave)="popover[cellInfo.data.id] = false"
            (click)="popup[cellInfo.data.id] = !popup[cellInfo.data.id]"
            [src]="cellInfo.value | frReceiptImgLink: 'resize--18' | async | sanitizeUrl" />

          <dx-popover [target]="'#img_' + cellInfo.data.id" [visible]="popover[cellInfo.data.id]" position="left">
            <div *dxTemplate="let data = model; of: 'content'">
              <img [src]="cellInfo.value | frReceiptImgLink: 'resize--600' | async | sanitizeUrl" />
            </div>
          </dx-popover>

          <dx-popup
            *ngIf="popup[cellInfo.data.id]"
            [width]="'100%'"
            [height]="'100%'"
            [showTitle]="false"
            [dragEnabled]="false"
            [closeOnOutsideClick]="true"
            [(visible)]="popup[cellInfo.data.id]">
            <div
              *dxTemplate="let data of 'content'"
              style="
                cursor: zoom-out;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
              "
              [style.background-image]="
                'url(\'' + (cellInfo.value | frReceiptImgLink: '':true | async) + '\')' | sanitizeStyle
              "
              (click)="popup[cellInfo.data.id] = false"></div>
          </dx-popup>
        </ng-container>

        <!-- endregion Templates -->
      </dx-data-grid>
    </div>

    <div *dxTemplate="let data of 'pivot'">
      <dx-pivot-grid
        width="100%"
        height="100%"
        [dataSource]="pivotDso"
        [allowExpandAll]="true"
        [allowSortingBySummary]="true"
        [allowSorting]="true"
        [allowFiltering]="true"
        [showColumnTotals]="showColumnTotals"
        [showRowTotals]="showRowTotals"
        [showColumnGrandTotals]="showColumnGrandTotals"
        [showRowGrandTotals]="showRowGrandTotals"
        [stateStoring]="pivot_stateStoring"
        (onContextMenuPreparing)="pivot_onContextMenuPreparing($event)">
        <dxo-field-panel
          [showDataFields]="false"
          [showRowFields]="true"
          [showColumnFields]="true"
          [showFilterFields]="true"
          [allowFieldDragging]="true"
          [visible]="true">
        </dxo-field-panel>

        <dxo-scrolling [useNative]="true"></dxo-scrolling>
      </dx-pivot-grid>
    </div>
  </dx-tab-panel>
</app-content-w-header>
