<app-content-w-header header="ADC Meals Reports">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="display: flex" *ngIf="mealsReportEnabled('cacfp_w_total')">
      <dx-date-box #date width="100px" [(value)]="selectedDateValue"></dx-date-box>

      <div style="display: inline-block" *ngIf="mealsReportEnabled('cacfp_w_total')">
        <dx-button
          [icon]="adcMealsProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="adcMealsProgress || !date.isValid"
          [text]="'Get CACFP Report'"
          (onClick)="getDailyCacfpPdf($event)"></dx-button>
      </div>
    </div>

    <div class="dx-fieldset" style="display: flex" *ngIf="mealsReportEnabled('cacfp_monthly', 'cacfp_monthly_v2', 'cacfp_monthly_v3')">
      <dx-date-box #month width="100px" displayFormat="monthAndYear" [(value)]="selectedMonthValue">
        <dxo-calendar-options maxZoomLevel="year" minZoomLevel="century"></dxo-calendar-options>
      </dx-date-box>

      <div style="display: inline-block" *ngIf="mealsReportEnabled('cacfp_monthly')">
        <dx-button
          [icon]="adcMealsCountProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="adcMealsCountProgress || !month.isValid"
          [text]="'CACFP Monthly Meal Count'"
          (onClick)="getMonthlyMealsCount($event)"></dx-button>
      </div>

      <div style="display: inline-block" *ngIf="mealsReportEnabled('cacfp_monthly_v2')">
        <dx-button
          [icon]="adcMealsCountProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="adcMealsCountProgress || !month.isValid"
          [text]="'CACFP Monthly Meal Count v2'"
          (onClick)="getMonthlyMealsCount2($event)"></dx-button>
      </div>

      <div style="display: inline-block" *ngIf="mealsReportEnabled('cacfp_monthly_v3')">
        <dx-button
          [icon]="adcMealsCountProgress ? 'fas fa-spinner fa-spin' : 'fas fa-file-pdf'"
          [disabled]="adcMealsCountProgress || !month.isValid"
          [text]="'CACFP Monthly Meal Count v3'"
          (onClick)="getMonthlyMealsCount3($event)"></dx-button>
      </div>

    </div>
  </mat-card>
</app-content-w-header>
