import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import fromPairs from 'lodash-es/fromPairs';
import sum from 'lodash-es/sum';
import { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { AuthService, AuthServiceApi, ConsumerView, ConsumerViewApi } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-auth-edit',
  templateUrl: './dlg-auth-edit.component.html',
  styleUrls: ['./dlg-auth-edit.component.scss'],
})
export class DlgAuthEditComponent implements OnInit {
  submitting = false;

  auth: any = {};

  mealsCodes = [
    { id: 'W1759', t: 'hot - W1759' },
    { id: 'W1760', t: 'frozen - W1760' },
    { id: 'W1761', t: 'sandwich - W1761' },
    { id: 'W1762', t: 'emergency - W1762' },
    { id: 'W1764', t: 'special - W1764' },
  ];
  consumerDso: DataSourceOptions = [];

  isGridBoxOpened: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DlgAuthEditComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { auth: any },
    private dss: DataSourceService,
  ) {
    this.consumerDso = this.buildConsumerDataSource();

    this.prepareFormData(data.auth);
  }

  ngOnInit() {}

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(ConsumerView, ConsumerView, false);
    so.customFilter = {
      where: { facility_type: 'MEALS' },
      fields: { eligibility: false },
    };
    so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  gridBox_displayExpr(item: ConsumerView) {
    return (
      item &&
      [
        `[${item.facility_shortname}]`,
        item.person_firstname,
        item.person_lastname,
        '| DOB:' + utc(item.person_dob).format('L'),
        '| MCI:' + item.mci,
      ].join(' ')
    );
  }

  onGridBoxOptionChanged(e) {
    if (e.name === 'value') {
      this.isGridBoxOpened = false;
      this.ref.detectChanges();
    }
  }

  ngModelChange(e) {
    const _map = {
      su: 'Su',
      mo: 'M',
      tu: 'T',
      we: 'W',
      th: 'Th',
      fr: 'F',
      sa: 'Sa',
    };

    this.auth.Note =
      Object.entries(_map)
        .map(([k, v]) => `${v}-${e[k]}`)
        .join(' ') +
      ' Tot-' +
      sum(Object.values(e));
  }

  reset_click(e, form: DxFormComponent): void {
    form.instance.resetValues();
  }

  cancel_click(e, form: DxFormComponent): void {
    this.dialogRef.close(false);
  }

  submit_click(e, form: DxFormComponent): void {
    const validateRes = form.instance.validate();

    if (validateRes.isValid) {
      this.submitting = true;
      void this.prepareData()
        .pipe(
          switchMap(data =>
            this.dss.getApi<AuthServiceApi>(AuthService).saveAuthorization(data, headersAllTenantsAppend),
          ),
          tap(res => this.dialogRef.close(res)),
          catchError(err => of(notify(err.message, 'error', 5000))),
          tap(() => (this.submitting = false)),
        )
        .toPromise();
    }
  }

  private prepareFormData(data) {
    const auth = oc(data)({});

    if (auth._consumerId) auth._consumerIds = [auth._consumerId];
    if (auth.StartDT) auth.StartDT = utc(auth.StartDT).format('YYYY-MM-DD');
    if (auth.EndDT) auth.EndDT = utc(auth.EndDT).format('YYYY-MM-DD');

    const _map = {
      Su: 'su',
      M: 'mo',
      T: 'tu',
      W: 'we',
      Th: 'th',
      F: 'fr',
      Sa: 'sa',
    };

    if (auth.Note)
      auth._manifest = fromPairs(
        oc(auth.Note as string)('')
          .split(' ')
          .map(pair => pair.split('-') as [string, string])
          .map(([k, v]) => [_map[k] || k, Number(v)]),
      );

    this.auth = auth;
  }

  private prepareData(): Observable<any> {
    const [cId] = this.auth._consumerIds;

    return this.dss
      .getApi<ConsumerViewApi>(ConsumerView)
      .findById<ConsumerView>(cId, {}, headersAllTenantsAppend)
      .pipe(
        map(cons => {
          return {
            _id: this.auth._id,

            _clientId: this.auth.MemberID,
            _mci: cons.mci,
            _tenantId: cons.tenantId,
            _consumerId: cons.id,

            MemberID: this.auth.MemberID,
            MedicaidID: cons.mci,
            FirstName: cons.person_firstname,
            LastName: cons.person_lastname,
            Name: [cons.person_firstname, cons.person_lastname].join(' '),
            MemberDOB: cons.person_dob,
            // Phone: '',

            StartDT: this.auth.StartDT,
            EndDT: this.auth.EndDT,

            AuthNumberFacets: this.auth.AuthNumberFacets,
            Code: this.auth.Code,
            Frequency: 'Weekly',
            AuthUnitsApproved: this.auth.AuthUnitsApproved,
            Note: this.auth.Note,

            ServiceCoordinatorName: this.auth.ServiceCoordinatorName,
            ServiceCoordinatorPhone: this.auth.ServiceCoordinatorPhone,
            ServiceCoordinatorEmail: this.auth.ServiceCoordinatorEmail,
          };
        }),
      );
  }
}
