<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Document Type</div>
        <div class="dx-field-value-static">{{ model?.documentType?.name }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Object Type</div>
        <div class="dx-field-value-static">{{ model?.objectType }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Object</div>
        <div class="dx-field-value-static">
          <app-object-title [modelName]="model?.objectType" [modelId]="model?.objectId"></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Name</div>
        <div class="dx-field-value-static">{{ model?.name }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Owner</div>
        <div class="dx-field-value-static">{{ model?.owner?.username }}</div>
      </div>

      <br />
      <div class="dx-fieldset-header">Custom Fields</div>

      <ng-container *ngFor="let cfv of model?.customFieldValues">
        <div class="dx-field">
          <div class="dx-field-label">{{ cfv?.customField?.label }}</div>
          <div class="dx-field-value-static" [ngSwitch]="cfv?.customField?.type">
            <ng-container *ngSwitchCase="'DATE'">{{ cfv?.value | date: 'mediumDate' }}</ng-container>
            <ng-container *ngSwitchDefault>{{ cfv?.value }}</ng-container>
          </div>
        </div>
      </ng-container>

      <br />
      <div class="dx-fieldset-header">Other</div>

      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value-static">
          {{ model?.notes }}
        </div>
      </div>
    </div>

    <!--<pre>{{model|json}}</pre>-->
  </ng-container>
</app-details-tab>
