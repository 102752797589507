import {
  Vehicle,
  Employee,
  AnyNote
} from '../index';

declare var Object: any;
export interface AcquisitionInterface {
  "vehicleId": number;
  "date"?: any;
  "type"?: string;
  "fullPrice"?: number;
  "principalBalance"?: number;
  "termInMonth"?: number;
  "loanPayment"?: number;
  "frequencyOfLoanPayment"?: string;
  "paymentDates"?: string;
  "firstPaymentDate"?: any;
  "lastPaymentDate"?: any;
  "annualInterestRate"?: number;
  "contract"?: string;
  "contractDate"?: any;
  "leaseMileageLimit"?: number;
  "buyoutOption"?: string;
  "balloonAmount"?: number;
  "notes"?: string;
  "creditorPersonId"?: number;
  "signorPersonId"?: number;
  "salePersonId"?: number;
  "file"?: any;
  "dealer"?: string;
  "creditor"?: string;
  "id"?: number;
  vehicle?: Vehicle;
  creditorPerson?: Employee;
  signorPerson?: Employee;
  salePerson?: Employee;
  anyNotes?: AnyNote[];
}

export class Acquisition implements AcquisitionInterface {
  "vehicleId": number;
  "date": any;
  "type": string;
  "fullPrice": number;
  "principalBalance": number;
  "termInMonth": number;
  "loanPayment": number;
  "frequencyOfLoanPayment": string;
  "paymentDates": string;
  "firstPaymentDate": any;
  "lastPaymentDate": any;
  "annualInterestRate": number;
  "contract": string;
  "contractDate": any;
  "leaseMileageLimit": number;
  "buyoutOption": string;
  "balloonAmount": number;
  "notes": string;
  "creditorPersonId": number;
  "signorPersonId": number;
  "salePersonId": number;
  "file": any;
  "dealer": string;
  "creditor": string;
  "id": number;
  vehicle: Vehicle;
  creditorPerson: Employee;
  signorPerson: Employee;
  salePerson: Employee;
  anyNotes: AnyNote[];
  constructor(data?: AcquisitionInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Acquisition`.
   */
  public static getModelName() {
    return "Acquisition";
  }

  /**
  * This method creates an instance of Acquisition for dynamic purposes.
  **/
  public static factory(data: AcquisitionInterface): Acquisition{
    return new Acquisition(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Acquisition',
      plural: 'Acquisitions',
      path: 'Acquisitions',
      idName: 'id',
      properties: {
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'any'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "fullPrice": {
          name: 'fullPrice',
          type: 'number'
        },
        "principalBalance": {
          name: 'principalBalance',
          type: 'number'
        },
        "termInMonth": {
          name: 'termInMonth',
          type: 'number'
        },
        "loanPayment": {
          name: 'loanPayment',
          type: 'number'
        },
        "frequencyOfLoanPayment": {
          name: 'frequencyOfLoanPayment',
          type: 'string'
        },
        "paymentDates": {
          name: 'paymentDates',
          type: 'string'
        },
        "firstPaymentDate": {
          name: 'firstPaymentDate',
          type: 'any'
        },
        "lastPaymentDate": {
          name: 'lastPaymentDate',
          type: 'any'
        },
        "annualInterestRate": {
          name: 'annualInterestRate',
          type: 'number'
        },
        "contract": {
          name: 'contract',
          type: 'string'
        },
        "contractDate": {
          name: 'contractDate',
          type: 'any'
        },
        "leaseMileageLimit": {
          name: 'leaseMileageLimit',
          type: 'number'
        },
        "buyoutOption": {
          name: 'buyoutOption',
          type: 'string'
        },
        "balloonAmount": {
          name: 'balloonAmount',
          type: 'number'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "creditorPersonId": {
          name: 'creditorPersonId',
          type: 'number'
        },
        "signorPersonId": {
          name: 'signorPersonId',
          type: 'number'
        },
        "salePersonId": {
          name: 'salePersonId',
          type: 'number'
        },
        "file": {
          name: 'file',
          type: 'any',
          default: <any>null
        },
        "dealer": {
          name: 'dealer',
          type: 'string'
        },
        "creditor": {
          name: 'creditor',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
        creditorPerson: {
          name: 'creditorPerson',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'creditorPersonId',
          keyTo: 'id'
        },
        signorPerson: {
          name: 'signorPerson',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'signorPersonId',
          keyTo: 'id'
        },
        salePerson: {
          name: 'salePerson',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'salePersonId',
          keyTo: 'id'
        },
        anyNotes: {
          name: 'anyNotes',
          type: 'AnyNote[]',
          model: 'AnyNote',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'acquisitionId'
        },
      }
    }
  }
}
