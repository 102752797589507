import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { chunk, flatten } from 'lodash-es';
import intersection from 'lodash-es/intersection';
import uniq from 'lodash-es/uniq';
import moment, { utc } from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { discretizeDates, discretizeMonths } from '../../../../shared/classes/utils/time.utils';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import {
  Consumer,
  ConsumerView,
  ConsumerViewApi,
  DayService,
  DayServiceApi,
  Employee,
  EmployeeView,
  EmployeeViewApi,
  Facility,
  FacilityApi,
  LoggerService,
  TripSignatureStats,
  TripSignatureStatsApi,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { SERVICE_TYPE } from '../../../trip-manifest/classes/enums';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import PivotGridDataSourceField = DevExpress.data.PivotGridDataSourceField;
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;

@Component({
  selector: 'app-signature-stats',
  templateUrl: './signature-stats.component.html',
  styleUrls: ['./signature-stats.component.scss'],
})
export class SignatureStatsComponent extends ABaseComponent implements OnInit {
  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'id', visible: false },
      { dataField: 'vdate', visible: false },
      { dataField: 'tenantId', visible: false },
      { dataField: 'consumerId', visible: false },
      { dataField: 'employeeId', visible: false },

      { caption: 'Year', dataField: 'vdate', dataType: 'date', groupInterval: 'year', displayFolder: 'date' },
      { caption: 'Month', dataField: 'vdate', dataType: 'date', groupInterval: 'month', displayFolder: 'date' },
      {
        caption: 'Day of Week',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        displayFolder: 'date',
        area: 'column',
        selector: data => utc(data.vdate).utc().format(this.dateColumnFormat),
      },

      {
        caption: 'Service Type',
        area: 'row',
        dataField: 'serviceType',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
        areaIndex: 0,
      },
      {
        caption: 'App Name',
        area: 'row',
        dataField: 'appName',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
        areaIndex: 0,
      },
      { caption: 'Tenant', area: 'row', dataField: '__tenant', dataType: 'string', isMeasure: false, areaIndex: 1 },
      {
        caption: 'Eligibility',
        area: 'row',
        dataField: '__clientEligibility',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      { caption: 'Client', area: 'row', dataField: '__client', dataType: 'string', isMeasure: false, expanded: true },
      { caption: 'MCI', area: 'row', dataField: '__mci', dataType: 'string', isMeasure: false, expanded: true },
      { caption: 'DOB', area: 'row', dataField: '__dob', dataType: 'string', isMeasure: false, expanded: false },
      { caption: 'Driver', area: 'row', dataField: '__driver', dataType: 'string', isMeasure: false, expanded: false },

      {
        caption: 'Signatures Collected',
        area: 'data',
        dataField: 'count',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Units',
        area: 'data',
        dataField: 'units',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },
    ];
  }

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private dateColumnFormat = 'YYYY-MM-DD dd';

  dso: any = {
    store: [],
    fields: this.pivotFields,
  } as DataSourceOptions;

  facilityDso$: Observable<DataSourceOptions> = of([]);

  facilitySet: Set<string>;

  grid_stateStoring: any;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  exportToExcelProcessing = false;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = moment().toDate();

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      'Stats For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join(' - '),
    ].join(' ');
  }

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private ui: UiService,
    public common: CommonService,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '49fb7dcf-9d9e-41a1-b9a7-1bd73a634757',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    const months = discretizeMonths(fromInc, toExcl);
    const days = discretizeDates(fromInc, toExcl);

    return combineLatest([this.config.roles$, this.dss.getApi<FacilityApi>(Facility).find<Facility>()]).pipe(
      switchMap(([roles, facilities]) =>
        combineLatest([
          of(roles),
          of(facilities),

          // this.dss.getViewApi<ConsumerViewApi>(Consumer)
          //   .find<ConsumerView>({}, (headers: HttpHeaders) => {
          //     if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
          //       headers = headersAllTenantsAppend(headers);
          //     }
          //     return headers;
          //   }),
          //
          // this.dss.getViewApi<EmployeeViewApi>(Employee)
          //   .find<EmployeeView>({}, (headers: HttpHeaders) => {
          //     if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
          //       headers = headersAllTenantsAppend(headers);
          //     }
          //     return headers;
          //   }),

          this.dss.getApi<TripSignatureStatsApi>(TripSignatureStats).find<TripSignatureStats>(
            {
              where:
                fromInc && toExcl
                  ? {
                      and: [
                        ...(fromInc ? [{ vdate: { gte: fromInc } }] : []),
                        ...(toExcl ? [{ vdate: { lt: toExcl } }] : []),
                      ],
                    }
                  : undefined,
            },
            (headers: HttpHeaders) => {
              if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                headers = headersAllTenantsAppend(headers);
              }
              return headers;
            },
          ),

          this.dss.getApi<DayServiceApi>(DayService).find<DayService>(
            {
              where: {
                and: [
                  { marker: 'CLOSE_DAY' },
                  ...(fromInc ? [{ date: { gte: fromInc } }] : []),
                  ...(toExcl ? [{ date: { lt: toExcl } }] : []),
                ],
              },
            },
            (headers: HttpHeaders) => {
              if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                headers = headersAllTenantsAppend(headers);
              }
              return headers;
            },
          ),
        ]),
      ),

      switchMap(async ([roles, facilities, signaturesStats, dayServices]) => {
        this.facilitySet = new Set(facilities.map(f => f.shortname));
        const facilitiesMap = new Map(facilities.map(f => [f.id, f] as [number, Facility]));

        const cIds = uniq(signaturesStats.map(stat => stat.consumerId));
        const eIds = uniq(signaturesStats.map(stat => stat.employeeId));

        const clients = flatten(
          await Promise.all(
            chunk(cIds, 40).map(chnk =>
              this.dss
                .getViewApi<ConsumerViewApi>(Consumer)
                .find<ConsumerView>(
                  {
                    where: { id: { inq: chnk } },
                  },
                  (headers: HttpHeaders) => {
                    if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                      headers = headersAllTenantsAppend(headers);
                    }
                    return headers;
                  },
                )
                .toPromise(),
            ),
          ),
        );

        const drivers = flatten(
          await Promise.all(
            chunk(eIds, 40).map(chnk =>
              this.dss
                .getViewApi<EmployeeViewApi>(Employee)
                .find<EmployeeView>(
                  {
                    where: { id: { inq: chnk } },
                  },
                  (headers: HttpHeaders) => {
                    if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                      headers = headersAllTenantsAppend(headers);
                    }
                    return headers;
                  },
                )
                .toPromise(),
            ),
          ),
        );

        const clientsMap = new Map(clients.map(c => [c.id, c] as [number, ConsumerView]));
        const driversMap = new Map(drivers.map(d => [d.id, d] as [number, EmployeeView]));

        signaturesStats.forEach(stat => {
          const facility = facilitiesMap.get(stat.tenantId);
          const client = clientsMap.get(stat.consumerId);
          const driver = driversMap.get(stat.employeeId);
          const dayService = dayServices.find(ds => ds.tenantId === stat.tenantId && ds.date === stat.vdate);

          stat.serviceType = stat.serviceType || SERVICE_TYPE.PARATRANSIT;
          if (stat.serviceType === 'null') stat.serviceType = SERVICE_TYPE.PARATRANSIT;

          (stat as any).__tenant = oc(facility).shortname('UNKNOWN');
          (stat as any).__client = client ? this.consumerHelper.displayExpr(client, '$L, $F') : 'UNKNOWN';
          (stat as any).__driver = driver ? this.employeeHelper.displayExpr(driver, '$L, $F') : 'UNKNOWN';

          (stat as any).__mci = oc(client).mci('UNKNOWN');
          (stat as any).__dob = oc(client).person_dob('UNKNOWN');
          (stat as any).__facilityID = oc(client).facilityID('UNKNOWN');
          (stat as any).__internalID = oc(client).internalID('UNKNOWN');
          (stat as any).__clientEligibility = months
            .map(m => oc(oc(client).eligibility({}))[m].value('NONE'))
            .join('/');
          (stat as any).__closed = oc(dayService).flag(false);
        });

        return signaturesStats;
      }),

      map(stats => {
        const aso: ArrayStoreOptions = {
          key: 'id',
          data: stats,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  exportToExcel() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    const fromIncl = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    notify('Extended Export To Excel Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Extended Export To Excel Done!',
      requireInteraction: true,
    };

    this.exportToExcelProcessing = true;

    this.pusher
      .rpc('EMAIL_SIGNATURES_TO_XLSX', {
        fromIncl,
        toExcl,
        useRunService: true,
      })
      .pipe(
        tap(({ uri }) => window.open(uri)),
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => (this.exportToExcelProcessing = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  pivot_onCellPrepared(e) {
    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'D') {
      // console.log(e);

      const value = e.cell.value;
      if (!isNaN(value) && value > 0) {
        e.cellElement.style.backgroundColor = 'lightGreen';
      } else if (value === 0) {
        // e.cell.text = '';
      }
    }

    // if (
    //   e.area === 'data' &&
    //   e.cell.columnType === 'D' &&
    //   e.cell.rowType === 'D'
    // ) {
    //   // console.log(e);
    //
    //   const as = this.dso.store as ArrayStore;
    //
    //   const rowPath = last(e.cell.rowPath as any[]);
    //   const colPath = last(e.cell.columnPath as any[]);
    //   const value = e.cell.value;
    //
    //   if (rowPath && colPath && value) {
    //     let facility: string;
    //     let date: string;
    //
    //     if (this.facilitySet.has(rowPath) && moment(colPath,  this.dateColumnFormat).isValid()) {
    //       facility = rowPath;
    //       date = utc(colPath, this.dateColumnFormat).utc().format('YYYY-MM-DD');
    //     }
    //
    //     if (this.facilitySet.has(colPath) && moment(rowPath,  this.dateColumnFormat).isValid()) {
    //       facility = colPath;
    //       date = utc(rowPath, this.dateColumnFormat).utc().format('YYYY-MM-DD');
    //     }
    //
    //     if (facility && date) {
    //       const recs: SignatureStats[] = as.createQuery()
    //         .filter([['__tenant', '=', facility], 'and', ['vdate', '=', date]])
    //         .toArray();
    //
    //       if (oc(head(recs) as any).__closed(false)) {
    //         e.cellElement.style.backgroundColor = 'lightGreen';
    //       } else {
    //         // e.cellElement.style.backgroundColor = 'lightcoral';
    //       }
    //     }
    //   }
    // }
  }
}
