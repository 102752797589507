import { Component, OnDestroy, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import sortBy from 'lodash-es/sortBy';
import { takeUntil } from 'rxjs/operators';
//
import { Consumer, Document, LoggerService, Person } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentFormComponent } from '../../../document/components/document-form/document-form.component';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-consumer-details-tab-document',
  templateUrl: './consumer-details-tab-document.component.html',
  styleUrls: ['./consumer-details-tab-document.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class ConsumerDetailsTabDocumentComponent
  extends ABaseModelPaneWToolbarComponent<Consumer>
  implements OnDestroy
{
  dso: DataSourceOptions;
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
    private gridHelper: GridHelperService,
  ) {
    super(logger, ui, dss);

    this.caption = 'Related Documents';

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([Document.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Consumer>> {
    return ConsumerFormComponent;
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onEditingStart(e) {
    e.cancel = true;

    this.ui.openEditDialog({
      modelId: e.data.id,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
    });
  }

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift(
      {
        name: 'addConsumerDoc',
        widget: 'dxButton',
        locateInMenu: 'always',
        location: 'after',
        sortIndex: 30,
        // showText: 'inMenu',
        options: {
          // icon: '',
          text: 'Add Consumer Doc',
          hint: 'Add Consumer Doc',
          // disabled: !this.model,
          onClick: () => {
            if (!this.model) {
              return;
            }

            this.ui.openEditDialog({
              modelId: null,
              inputs: {
                objectType: Consumer.getModelName(),
                objectId: this.modelId,
              },
              ModelClass: Document,
              FormComponentClass: DocumentFormComponent,
            });
          },
        },
      },
      // {
      //   name: 'addPersonDoc',
      //   widget: 'dxButton',
      //   locateInMenu: 'always',
      //   location: 'after',
      //   sortIndex: 30,
      //   // showText: 'inMenu',
      //   options: {
      //     // icon: '',
      //     text: 'Add Person Doc',
      //     hint: 'Add Person Doc',
      //     // disabled: !this.model,
      //     onClick: () => {
      //       if (!this.model) {
      //         return;
      //       }
      //
      //       this.ui.openEditDialog({
      //         modelId: null,
      //         inputs: {
      //           objectType: Person.getModelName(),
      //           objectId: this.model ? this.model.personId : null,
      //         },
      //         ModelClass: Document,
      //         FormComponentClass: DocumentFormComponent,
      //       });
      //     },
      //   }
      // },
    );

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.dso = model ? this.buildDSO(model) : undefined;
  }

  protected buildToolbarItems(model: any = null): void {
    this.toolbarItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        text: this.caption,
      },
    ];
  }

  private buildDSO(model: Consumer): DataSourceOptions {
    const filter = model
      ? [
          [
            ['objectId', '=', model.id],
            ['objectType', '=', Consumer.getModelName()],
          ],
          'or',
          [
            ['objectId', '=', model.personId],
            ['objectType', '=', Person.getModelName()],
          ],
        ]
      : undefined;

    return {
      store: this.dss.getStore(Document, null, false),
      filter,
    } as DataSourceOptions;
  }
}
