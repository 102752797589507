import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UploadHelperService } from '../shared/modules/ui/services/upload-helper.service';

@Injectable()
export class SignedUrlHttpInterceptorService implements HttpInterceptor {
  constructor(@Inject(UploadHelperService) protected uploadHelper: UploadHelperService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // https://cloud.google.com/storage/docs/access-control/signed-urls
    if (req.url === UploadHelperService.SIGNED_URL_PLACEHOLDER) {
      return this.uploadHelper
        .replaceUploadWithSignedRequest(req)
        .pipe(switchMap(reqOrRes => (reqOrRes instanceof HttpRequest ? next.handle(reqOrRes) : of(reqOrRes))));
    } else {
      return next.handle(req);
    }
  }
}
