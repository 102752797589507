import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import moment, { utc } from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService, Vehicle } from '../../../../shared/sdk';

@Component({
  selector: 'app-vehicle-utilisation',
  templateUrl: './vehicle-utilisation.component.html',
  styleUrls: ['./vehicle-utilisation.component.scss'],
})
export class VehicleUtilisationComponent extends ABaseComponent implements OnInit {
  dso = new DataSource([]);
  dataSource: PivotGridDataSourceOptions;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = new Date();
  facility?: number;
  vehicleId?: number;
  ownership?: string;
  status?: string;

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = false;

  collection = 'VehicleUtilisation_PivotData';

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;

  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private ui: UiService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '8764fcfe-7af9-4b3c-9ca9-28c7a2c064c7',
    };

    this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => {
          this.buildDataSource();
        }),
        // switchMap(() => this.buildDataSource().pipe(
        //   catchError((err) => {
        //     notify(err.message, 'error', 5000);
        //     return of(new ArrayStore({data: []}));
        //   }),
        //   )
        // ),
        // tap((as) => {
        //   this.dso = {
        //     store: as,
        //     fields: this.pivotFields,
        //   } as PivotGridDataSourceOptions;
        //   // this.grid.instance.refresh();
        // }),
        delay(1000),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildFilter() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    const fromInc = fromMoment && utc(fromMoment.format('YYYY-MM-DD')).toISOString();
    const toExcl = toMoment && utc(toMoment.format('YYYY-MM-DD')).toISOString();

    return { fromInc, toExcl };
  }

  private buildDataSource() {
    const filterObj = this.buildFilter();
    const _filter = [
      ['date', '>=', filterObj.fromInc],
      ['date', '<', filterObj.toExcl],
      ...(this.vehicleId ? [['vehicleId', '=', this.vehicleId]] : []),
      ...(this.facility ? [['tenant', '=', this.facility]] : []),
      ...(this.status ? [['state', '=', this.status]] : []),
      ...(this.ownership ? [['ownership', '=', this.ownership]] : []),
    ];
    this.dataSource = {
      remoteOperations: true,
      store: this.dss.createMongoStore(this.collection, { date: 'datetime' }),
      filter: _filter,
      fields: [
        { dataField: '_id', visible: false },

        // {
        //   caption: 'Year', dataField: 'date', dataType: 'date', groupInterval: 'year',
        //   displayFolder: 'date',
        // },
        // {
        //   caption: 'Month', dataField: 'date', dataType: 'date', groupInterval: 'month',
        //   displayFolder: 'date',
        // },
        // {
        //   caption: 'Day of Week', dataField: 'date', dataType: 'date', groupInterval: 'dayOfWeek',
        //   displayFolder: 'date',
        // },
        // {
        //   // caption: 'Day',
        //   area: 'column',
        //   // dataType: 'date',
        //   // groupInterval: 'day',
        //   // dataField: 'date',
        //   // displayFolder: 'date',
        //   format: 'MM/dd/yyyy EE',
        //   selector(data) {
        //     const year = new Date(data.date).getUTCFullYear();
        //     const month = new Date(data.date).getUTCMonth();
        //     const date = new Date(data.date).getUTCDate();
        //     return new Date(year, month, date).toDateString();
        //   }
        // },
        // {
        //   caption: 'Day',
        //   displayFolder: 'date',
        //   area: 'column',
        //   format: 'MM/dd/yyyy EE',
        //   selector(data) {
        //     const year = new Date(data.date).getUTCFullYear();
        //     const month = new Date(data.date).getUTCMonth();
        //     const date = new Date(data.date).getUTCDate();
        //     return new Date(year, month, date);
        //   }
        // },

        {
          dataField: 'date',
          dataType: 'date',
          area: 'column',
          groupName: 'dateGroup',
          isMeasure: false,
        },
        {
          groupName: 'dateGroup',
          groupIndex: 0,
          format: 'MM/dd/yyyy EE',
          selector(data) {
            const year = moment(data.date).get('year');
            const month = moment(data.date).get('month');
            const days = moment(data.date).get('days');
            return new Date(year, month, days);
          },
        },

        {
          dataField: 'internalId',
          dataType: 'string',
          caption: 'Veh ID',
          isMeasure: false,
          area: 'row',
          expanded: true,
        },
        {
          dataField: 'vehicle',
          dataType: 'string',
          caption: 'Veh Desc',
          isMeasure: false,
          area: 'row',
          expanded: true,
        },
        {
          dataField: 'state',
          dataType: 'string',
          caption: 'Status',
          isMeasure: false,
          area: 'row',
          expanded: true,
        },
        {
          dataField: 'ownership',
          dataType: 'string',
          caption: 'Ownership',
          isMeasure: false,
          area: 'row',
          expanded: true,
        },
        {
          dataField: 'tenant',
          dataType: 'string',
          caption: 'Tenant',
          isMeasure: false,
          area: 'row',
        },

        {
          dataField: 'used',
          dataType: 'number',
          caption: 'Used',
          isMeasure: true,
          area: 'data',
          summaryType: 'count',
        },

        {
          dataField: 'odometerBefore',
          dataType: 'number',
          caption: 'First Mileage',
          isMeasure: true,
          summaryType: 'min',
        },
        {
          dataField: 'odometerAfter',
          dataType: 'number',
          caption: 'Last Mileage',
          isMeasure: true,
          summaryType: 'max',
        },
        {
          dataField: 'deltaOdometer',
          dataType: 'number',
          caption: 'Period Mileage',
          isMeasure: true,
          area: 'data',
          summaryType: 'sum',
        },
      ],
    } as PivotGridDataSourceOptions;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
    return of(dso);
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);
  }

  vehicle_onSelectionChanged(e) {
    // console.log(e.selectedItem);
  }

  grid_onToolbarPreparing(e) {
    // e.toolbarOptions.items.unshift({
    //   // disabled: this.$showFromBroker$.value,
    //   name: 'buildBatch',
    //   locateInMenu: 'auto',
    //   widget: 'dxButton',
    //   location: 'after',
    //   sortIndex: 30,
    //   showText: 'inMenu',
    //   options: {
    //     icon: 'fas fa-folder-plus',
    //     text: 'Build Paratransit Batch',
    //     hint: 'Build Paratransit Claims Batch',
    //     onClick: this.grid_toolbar_buildBatch_onClick.bind(this),
    //   }
    // }, {
    //   // disabled: this.$showFromBroker$.value,
    //   name: 'buildBatch',
    //   locateInMenu: 'auto',
    //   widget: 'dxButton',
    //   location: 'after',
    //   sortIndex: 30,
    //   showText: 'inMenu',
    //   options: {
    //     icon: 'fas fa-folder-plus',
    //     text: 'Build Meals Batch',
    //     hint: 'Build Meals Claims Batch',
    //     onClick: this.grid_toolbar_buildMealsBatch_onClick.bind(this),
    //   }
    // }, {
    //   // disabled: this.$showFromBroker$.value,
    //   name: 'buildMealsReq',
    //   locateInMenu: 'auto',
    //   widget: 'dxButton',
    //   location: 'after',
    //   sortIndex: 30,
    //   showText: 'inMenu',
    //   options: {
    //     icon: 'fas fa-build',
    //     text: 'Meals Trips Req',
    //     hint: 'Build Meals Trips Request Files',
    //     onClick: this.grid_toolbar_buildMealsReq_onClick.bind(this),
    //   }
    // });
  }

  filter() {
    this.$filterEvent$.next(true);
  }
}
