<dx-toolbar [items]="toolbarItems">
  <!-- Templates -->

  <div *dxTemplate="let data of 'manifestDateBox'">
    <dx-date-box #dateBox [value]="selectedDate" [max]="maxDate" (onValueChanged)="dateBox_onValueChanged($event)">
      <!-- Templates -->
      <ng-container *dxTemplate="let data of 'cell'">
        <span [ngClass]="{ 'manifest-exists': manifestExist(data) }">
          {{ data.text }}
        </span>
      </ng-container>
    </dx-date-box>
  </div>

  <!-- /// -->

  <div *dxTemplate="let data of 'employeeDropDown'">
    <app-employee-drop-down-grid
      [width]="200"
      [dataSource]="driversDSO"
      [showClearButton]="true"
      [value]="selectedDriver"
      (valueChanged)="dropDownGrid_onValueChanged($event)"></app-employee-drop-down-grid>
  </div>

  <!-- /// -->

  <div *dxTemplate="let data of 'tabs'">
    <dx-tabs
      #tabs
      [items]="tabItems"
      [selectedIndex]="selectedIndex"
      (onSelectionChanged)="tabs_onSelectionChanged($event)"></dx-tabs>
  </div>
</dx-toolbar>
<hr />
