<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="empl-avatar">
      <app-personal-photo [person]="model?.person"></app-personal-photo>
      <div class="icon-print-wrapper" (click)="helper.printIdCard(model).toPromise()">
        <i class="material-icons icon">print</i>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Account</div>
        <div class="dx-field-value-static">

          <ng-container *ngIf="model?.userId; else noUserBlock">
            <app-object-title [modelName]="'MyUser'" [modelId]="model?.userId"></app-object-title>
            <span>&nbsp;</span>
            <dx-button
              *ngIf="canAssignFacility"
              text="Assign To Facility"
              (onClick)="reassignTenant_onClick()"
            ></dx-button>
          </ng-container>

          <ng-template #noUserBlock>
            no user account
            <span>&nbsp;</span>
            <dx-button
              *ngIf="canCreateAccount"
              text="Create Account"
              (onClick)="createAccount_onClick()"
            ></dx-button>
          </ng-template>

        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Name</div>
        <div class="dx-field-value-static">
          {{ model | fullName }}
          <ng-container *ngIf="model?.person?.nickname"><em>aka</em> {{ model?.person?.nickname }}</ng-container>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">DOB</div>
        <div class="dx-field-value-static">
          <time>{{ model?.person?.dob | date : 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.sex">
        <div class="dx-field-label">Sex</div>
        <div class="dx-field-value-static">
          {{ model?.person?.sex }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.data?.nativeLang">
        <div class="dx-field-label">Native Language</div>
        <div class="dx-field-value-static">
          {{ model?.person?.data?.nativeLang }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.data?.nativeLangName">
        <div class="dx-field-label">Native Language Name</div>
        <div class="dx-field-value-static">
          {{ model?.person?.data?.nativeLangName }}
        </div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.phones,
          type: 'phone',
          types: 'phones',
          header: 'Phone'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.emails,
          type: 'email',
          types: 'emails',
          header: 'Email'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.addresses,
          type: 'address',
          types: 'addresses',
          header: 'Address'
        }
      "></ng-container>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Related Contacts</div>

      <ng-container *ngFor="let emr of model?.emRelations; let idx = index">
        <div class="dx-field">
          <div class="dx-field-label">{{ emr?.relation }}</div>
          <div class="dx-field-value-static">
            <abbr *ngIf="emr?.person?.data?.er" title="Emergency Contact">ER</abbr>
            {{ emr?.person | fullName }}
            <span *ngIf="emr?.person?.contact?.phones?.length"><br /></span>
            <ng-container *ngFor="let phone of emr?.person?.contact?.phones; let isLast = last">
              <span
              >{{ phone?.label || 'Main' }}:&nbsp;<a href="tel:{{ phone?.value }}">{{ phone?.value }}</a></span
              >
              <span *ngIf="!isLast"><br /></span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Other</div>

      <!-- TODO: DL -->

      <!-- TODO: SSN -->

      <div class="dx-field">
        <div class="dx-field-label">Person Notes</div>
        <div class="dx-field-value-static">
          {{ model?.person?.notes }}
        </div>
      </div>
    </div>
  </ng-container>
</app-details-tab>

<!-- Templates -->

<ng-template #fieldSet let-items let-header="header" let-type="type" let-types="types">
  <div class="dx-fieldset">
    <!--<div class="dx-fieldset-header">{{header}}</div>-->

    <ng-container *ngFor="let item of items">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ item?.label || 'Main' }}
        </div>
        <div class="dx-field-value-static">
          <span
            class="fas"
            [ngClass]="{
              'fa-phone': type === 'phone',
              'fa-at': type === 'email',
              'fa-map-marker-alt': type === 'address'
            }"></span>
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'phone'">
              <a href="tel:{{ item?.value }}">{{ item?.value }}</a>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
              <a href="mailto:{{ item?.value }}">{{ item?.value }}</a>
            </ng-container>

            <ng-container *ngSwitchCase="'address'">
              <address style="display: inline">
                {{ item?.street }}, {{ item?.city }}, {{ item?.state }}, {{ item?.zip }}
              </address>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!items?.length">
      <!--There are no {{types}}-->
    </ng-container>
  </div>
</ng-template>

