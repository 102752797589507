import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
//
import { ErrorHandler, InternalStorage, LoopBackAuth, SDKBrowserModule, SDKModels, StorageBrowser } from '../../sdk';
import { ExtLoopBackAuth } from './services/ext-sdk-auth.service';
import { ExtSdkErrorHandler } from './services/ext-sdk-error.handler';
import { ExtSDKModels } from './services/ext-sdk-models.service';

@NgModule({
  imports: [CommonModule, SDKBrowserModule],
  declarations: [],
  exports: [SDKBrowserModule],
  providers: [
    { provide: ErrorHandler, useClass: ExtSdkErrorHandler },
    //    {provide: SDKModels, useClass: ExtSDKModels}, // dep loop
    //    {provide: LoopBackAuth, useClass: ExtLoopBackAuth}, // dep loop
  ],
})
export class ExtSdkModule extends SDKBrowserModule {
  static forRoot(
    internalStorageProvider: any = {
      provide: InternalStorage,
      useClass: StorageBrowser,
    },
  ): ModuleWithProviders {
    return {
      ngModule: ExtSdkModule,
      providers: [
        ...SDKBrowserModule.forRoot(internalStorageProvider).providers, // .filter((p) => p !== LoopBackAuth && p !== SDKModels),

        //       {provide: ErrorHandler, useClass: ExtSdkErrorHandler},
        { provide: SDKModels, useClass: ExtSDKModels },
        { provide: LoopBackAuth, useClass: ExtLoopBackAuth },

        ExtSDKModels,
        ExtLoopBackAuth,
      ],
    };
  }
}
