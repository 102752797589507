import * as tslib_1 from "tslib";
import Cropper from 'cropperjs';
import { OnInit } from '@angular/core';
import { Person, PersonalPhoto, PersonalPhotoApi } from '../../../../shared/sdk';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadHelperService } from 'src/app/shared/modules/ui/services/upload-helper.service';
import { DxFileUploaderComponent, DxValidatorComponent } from 'devextreme-angular';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
export class PersonalPhotoCropperComponent {
    constructor(dialogRef, uploadHelper, dss, person, sanitizer) {
        this.dialogRef = dialogRef;
        this.uploadHelper = uploadHelper;
        this.dss = dss;
        this.person = person;
        this.sanitizer = sanitizer;
        this.imageLoaded = false;
        this.orgFile = null;
        this.uploadingFiles = {};
        this.uploadedFiles = {};
        this.validationCallback = this.uploadHelper.validationCallback;
        this.fileName = (type, ext) => `${this.person.id}_${type}_${this.person.firstname}_${this.person.lastname}.${ext}`.replace(/[^\w_\.]/g, '_');
    }
    ngOnInit() {
        // this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.image);
    }
    ngAfterViewInit() {
        // this.initCropper();
        this.uploadHelper.handle(this.uploader.instance, {
            folder: 'pictures',
            onUploaded: (e) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.uploader.value.every(file => file.uploaded)) {
                    const data = this.uploader.value
                        .map(file => file.uploaded)
                        .reduce((p, f) => (Object.assign({}, p, { [f.originalFilename.split('_')[1]]: f })), { personId: this.person.id });
                    // this.uploadHelper.getDownloadUrl(d.files_0_container, d.files_0_filename);
                    const personalPhoto = (yield this.dss
                        .getApi(PersonalPhoto)
                        .upsertWithWhere({ personId: this.person.id }, data)
                        .toPromise());
                    this.person.personalPhoto = personalPhoto;
                    this.dialogRef.close(personalPhoto);
                }
                // this.form.markAsDirty();
            }),
        }, this.uploadValidator && this.uploadValidator.instance);
    }
    onFileChange(event) {
        const files = event.target.files;
        if (files.length > 0) {
            const ext = files[0].name.split('.').pop();
            this.orgFile = new File([files[0]], this.fileName('org', ext), { type: files[0].type });
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.orgFile));
            this.resetInput();
        }
    }
    resetInput() {
        const input = document.getElementById('avatar-input-file');
        if (input) {
            input.value = '';
        }
    }
    onImageLoad(event) {
        this.imageLoaded = true;
        this.initCropper();
        console.log('image loaded');
    }
    initCropper() {
        if (this.cropper) {
            this.cropper.destroy();
        }
        const image = document.getElementById('image');
        image.crossOrigin = 'anonymous';
        this.cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 1,
            checkCrossOrigin: false,
            guides: false,
        });
    }
    // make the crop box rounded
    getRoundedCanvas(sourceCanvas) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;
        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
    }
    //get the cropped image and closes the dialog
    //returning an url or null if no image
    crop() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const croppedCanvas = this.cropper.getCroppedCanvas();
            const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
            let roundedImage = document.createElement('img');
            if (roundedImage) {
                const avatarFile = yield new Promise((resolve, reject) => {
                    roundedCanvas.toBlob((blob) => {
                        const f = new File([blob], this.fileName('avatar', 'png'), {
                            type: 'image/png',
                        });
                        resolve(f);
                    }, 'image/png');
                });
                this.uploader.value = [...(this.orgFile ? [this.orgFile] : []), avatarFile];
                this.uploader.instance.upload();
                // const file = URL.createObjectURL(roundedCanvas.toBlob((blob: any) => blob, 'image/png'));
            }
            else {
                return this.dialogRef.close(null);
            }
        });
    }
    // resets the cropper
    reset() {
        this.cropper.clear();
        this.cropper.crop();
    }
}
