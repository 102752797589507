import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
//
import { ConsumerView } from '../../../../shared/sdk/models';
import { ConsumerGridComponent } from '../consumer-grid/consumer-grid.component';

@Component({
  selector: 'app-consumer-list',
  templateUrl: './consumer-list.component.html',
  styleUrls: ['./consumer-list.component.scss'],
})
export class ConsumerListComponent implements OnInit {
  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(ConsumerGridComponent, { static: true }) consumerGrid: ConsumerGridComponent;

  constructor() {}

  ngOnInit() {}

  grid_onSelected(keys: any[]) {
    this.mySelected.emit(keys);
  }
}
