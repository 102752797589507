import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { AuthorizationGridComponent } from './components/authorization-grid/authorization-grid.component';
import { AuthorizationsComponent } from './components/authorizations/authorizations.component';
import { AuthClaimComponent } from './components/auths-claims/auth-claim.component';
import { UnassignedClientsComponent } from './components/unassigned-clients/unassigned-clients.component';
import { ServiceAuthorizationComponent } from './containers/service-authorization/service-authorization.component';
import { RenewAuthsComponent } from './components/renew-auths/renew-auths.component';
import { DlgAuthEditComponent } from './dialogs/dlg-auth-edit/dlg-auth-edit.component';
import { DlgEditClaimComponent } from './dialogs/dlg-edit-claim/dlg-edit-claim.component';
import { ExpiredAuthorizationsComponent } from './components/expired-authorizations/expired-authorizations.component';
import { DlgNotifyCoordComponent } from './dialogs/dlg-notify-coord/dlg-notify-coord.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,

    MyCommonModule,
    UiModule,

    NgxJsonViewerModule,
  ],
  declarations: [
    UnassignedClientsComponent,
    ServiceAuthorizationComponent,
    RenewAuthsComponent,
    AuthorizationGridComponent,
    AuthorizationsComponent,
    ExpiredAuthorizationsComponent,
    AuthClaimComponent,

    DlgAuthEditComponent,
    DlgEditClaimComponent,
    DlgNotifyCoordComponent,
  ],
  exports: [DlgAuthEditComponent, DlgEditClaimComponent, DlgNotifyCoordComponent],
  entryComponents: [DlgAuthEditComponent, DlgEditClaimComponent, DlgNotifyCoordComponent],
})
export class ServiceAuthorizationModule {}
