<div [formGroup]="parts" [ngStyle]="{ opacity: readonly ? 0.5 : 1 }">
  <ng-container *ngFor="let l of labels; let isFirst = first">
    <!--    <span *ngIf="!isFirst">&ndash;</span>-->
    <div class="item">
      <label>{{ l }}</label>
      <input
        matInput
        type="time"
        [required]="required"
        [disabled]="disabled"
        [readonly]="readonly"
        [formControlName]="l"
        (blur)="_onTouched()" />
    </div>
  </ng-container>
</div>
