<dx-box direction="col">
  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
    <app-trip-manifest-sub-toolbar
      [modelId]="modelId"
      [selectedDate]="selectedDate"
      [manifest]="manifest"
      [validationSummary]="validationSummary"></app-trip-manifest-sub-toolbar>
  </dxi-item>

  <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
    <div style="position: relative; flex: auto">
      <div style="position: absolute; display: flex; top: 0; bottom: 0; left: 0; right: 0">
        <dx-data-grid
          #grid
          [id]="gridTarget"
          [width]="'100%'"
          [height]="'100%'"
          [errorRowEnabled]="false"
          [dataSource]="ds"
          (onInitialized)="grid_onInitialized($event)"
          (onToolbarPreparing)="grid_onToolbarPreparing($event)"
          (onCellPrepared)="grid_onCellPrepared($event)">
          <dxo-export [enabled]="true" [fileName]="exportFileName" [allowExportSelectedData]="true"></dxo-export>

          <dxo-group-panel [visible]="true"></dxo-group-panel>

          <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

          <!-- Columns -->

          <dxi-column
            caption="Destination"
            [dataField]="helper.getRecFieldMap.destination"
            [fixed]="true"
            [fixedPosition]="'left'"
            [groupIndex]="0">
            <dxo-lookup [dataSource]="desDS" [displayExpr]="'Name'" [valueExpr]="'ID'"></dxo-lookup>
          </dxi-column>

          <dxi-column
            caption="Driver"
            [dataField]="helper.getRecFieldMap.employee"
            dataType="object"
            [calculateCellValue]="employee_calculateCellValue"
            [groupIndex]="1"></dxi-column>

          <dxi-column
            caption="Trip"
            [dataField]="helper.getRecFieldMap.trip"
            dataType="number"
            [editorOptions]="{ min: 1, max: helper.getMaxTrips, showSpinButtons: true }"
            [groupIndex]="2"
            width="40"></dxi-column>

          <dxi-column
            caption="Time"
            [dataField]="helper.getRecFieldMap.time"
            dataType="date"
            format="shortTime"
            [editorOptions]="{
              type: 'time',
              interval: helper.getTimeInterval,
              displayFormat: null,
              pickerType: 'rollers'
            }"
            width="90"></dxi-column>

          <dxi-column caption="#" [dataField]="helper.getRecFieldMap.seat" dataType="number" width="40"></dxi-column>

          <dxi-column
            caption="Consumer"
            [dataField]="helper.getRecFieldMap.consumer"
            dataType="object"
            [calculateCellValue]="consumer_calculateCellValue"></dxi-column>

          <dxi-column caption="Address" dataType="string" dataField="getFullAddress"></dxi-column>

          <dxi-column caption="Phone" dataType="string" dataField="getMainPhone"></dxi-column>

          <dxi-column
            caption="Instructions"
            dataType="string"
            dataField="getTranspInstrs"
            cellTemplate="tagBox_cell"></dxi-column>

          <dxi-column
            caption="Daily Note"
            [dataField]="helper.getRecFieldMap.dailyNote"
            dataType="string"
            [showInColumnChooser]="true"
            [visible]="dailyNoteVisible">
          </dxi-column>

          <!-- Summary -->

          <dxo-summary [calculateCustomSummary]="calculateSummary">
            <dxi-group-item
              column="consumer"
              summaryType="custom"
              name="TotalConsumers"
              displayFormat="Total consumers: {0}"></dxi-group-item>

            <dxi-group-item
              column="Trip"
              summaryType="max"
              displayFormat="Trips: {0}"
              [showInGroupFooter]="false"
              [alignByColumn]="true"></dxi-group-item>

            <dxi-total-item
              column="consumer"
              summaryType="custom"
              name="TotalConsumers"
              displayFormat="Total consumers: {0}"></dxi-total-item>
          </dxo-summary>

          <!-- Templates -->

          <div *dxTemplate="let data of 'printCtrl'">
            <app-toolbar-print-btn (action)="printBtn_onAction($event)"></app-toolbar-print-btn>
          </div>

          <!-- /// -->

          <ng-container *dxTemplate="let cellInfo of 'tagBox_cell'">
            <span [title]="cellInfo.value | split | trimArr | join: ', '">
              <span class="badge" *ngFor="let item of cellInfo.value | split">{{ item }}</span>
            </span>
          </ng-container>
        </dx-data-grid>
      </div>
    </div>
  </dxi-item>
</dx-box>
