<dx-popup
  [visible]="popupVisible"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [dragEnabled]="false"
  [width]="'30%'"
  [height]="'60%'"
  [position]="{ of: null }"
  title="Select Tenant">
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [dataSource]="dso"
      [selectedItems]="[]"
      [disabled]="isPending"
      valueExpr="id"
      displayExpr="shortname"
      itemTemplate="facilityItem"
      [grouped]="grouped"
      selectionMode="single"
      pageLoadMode="scrollBottom"
      (onSelectionChanged)="list_onSelectionChanged($event)"
    >
      <div *dxTemplate="let item of 'group'">
        <small class="f-group">{{ item.key }}</small>
      </div>
      <div *dxTemplate="let f of 'facilityItem'">
        <strong>{{ f.name }} </strong>
        <small>[{{ f.type }}: {{ f.shortname }}]</small>
      </div>
    </dx-list>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [visible]="isSU"
    [options]="{ text: 'Skip', onClick: skip_onClick }">
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{ text: 'Cancel', onClick: cancel_onClick }">
  </dxi-toolbar-item>
</dx-popup>
