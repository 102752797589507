import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import isNil from 'lodash-es/isNil';
import moment, { Moment, utc } from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
//
import { Consumer, Person, Program } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { CONSUMER_STATUSES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-eligibility-grid',
  templateUrl: './eligibility-grid.component.html',
  styleUrls: ['./eligibility-grid.component.scss'],
})
export class EligibilityGridComponent extends ABaseComponent implements OnInit, OnDestroy {
  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);

    const startOfYear = utc().startOf('y').utc();
    const dates: Moment[] = [];

    const curMonth = moment().startOf('month');
    const fromMonth = curMonth.clone().subtract(1, 'year').startOf('month');

    for (const m = fromMonth.clone(); curMonth.diff(m, 'month') >= 0; m.add(1, 'month')) {
      dates.push(m.clone());
    }
    this.dates = dates;

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '66c2c0d6-162f-42e7-b1ee-ee2c225b874a',
    };

    this.dso$ = this.buildDataSource();

    const store = this.dss.getStore(Program);
    this.programDso = {
      store,
      sort: [{ selector: 'name' }],
    };
  }

  get now() {
    return moment().format('YYYY_MM_DD');
  }

  dso$: Observable<DataSourceOptions> = of([]);
  programDso: DataSourceOptions = [];
  statuses = CONSUMER_STATUSES;

  grid_stateStoring: any;
  modifiedSubscription: Subscription;

  dates: Moment[];
  mciMap: Map<string, any> = new Map();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  $facilityName$ = new BehaviorSubject('FACILITY');

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Consumer.getModelName(), Person.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });

    this.config.tenant$
      .pipe(
        map(f => (f.name || 'FACILITY').replace(' ', '_')),
        tap(name => {
          if (this.$facilityName$.value !== name) {
            this.$facilityName$.next(name);
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;

    const subTitle = [
      moment.utc(e.data.person_dob).isValid()
        ? 'DOB:' + moment.utc(e.data.person_dob).utc().format('MM/DD/YYYY')
        : undefined,
      e.data.mci ? 'MCI:' + e.data.mci : undefined,
      e.data.status ? e.data.status : undefined,
      // e.data.activeMco ? e.data.activeMco : undefined,
      // e.data.activeBroker ? e.data.activeBroker : undefined,
    ]
      .filter(i => !isNil(i))
      .join(' - ');
    const title = `Edit: ${this.helper.displayExpr(e.data)}${subTitle.length ? ' - ' + subTitle : ''}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Consumer,
      FormComponentClass: ConsumerFormComponent,
      title,
    });
  }

  mcoDataField(date: Moment) {
    return 'eligibility.' + date.format('YYYY-MM');
  }

  mcoSortField(date) {
    return 'eligibility.\\"' + date.format('YYYY-MM') + '\\".value';
  }

  private buildDataSource() {
    const dso = this.dss.getDataSourceOptions(Consumer);
    // dso.postProcess = (data: Array<any>): Array<any> => {
    //
    //   data.forEach((c) => {
    //     Object.values(c.eligibility || {}).forEach((e: any) => {
    //       e._value = e.mco || e.status || e.error;
    //     });
    //   });
    //
    //   return data;
    // };

    return of(dso);
  }
}
