import {
  Employee,
  Facility,
  GeoPoint
} from '../index';

declare var Object: any;
export interface TripInfoInterface {
  "requestID": string;
  "date"?: string;
  "startTripTime"?: string;
  "tripNum"?: number;
  "origin"?: string;
  "originLocation"?: GeoPoint;
  "originAddress"?: string;
  "originInstructions"?: string;
  "destination"?: string;
  "destinationLocation"?: GeoPoint;
  "destinationAddress"?: string;
  "destinationInstructions"?: string;
  "polyline"?: string;
  "meta"?: any;
  "employeeId": number;
  "facilityId": number;
  "id"?: any;
  employee?: Employee;
  facility?: Facility;
}

export class TripInfo implements TripInfoInterface {
  "requestID": string;
  "date": string;
  "startTripTime": string;
  "tripNum": number;
  "origin": string;
  "originLocation": GeoPoint;
  "originAddress": string;
  "originInstructions": string;
  "destination": string;
  "destinationLocation": GeoPoint;
  "destinationAddress": string;
  "destinationInstructions": string;
  "polyline": string;
  "meta": any;
  "employeeId": number;
  "facilityId": number;
  "id": any;
  employee: Employee;
  facility: Facility;
  constructor(data?: TripInfoInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `TripInfo`.
   */
  public static getModelName() {
    return "TripInfo";
  }

  /**
  * This method creates an instance of TripInfo for dynamic purposes.
  **/
  public static factory(data: TripInfoInterface): TripInfo{
    return new TripInfo(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TripInfo',
      plural: 'TripInfos',
      path: 'TripInfos',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'string'
        },
        "startTripTime": {
          name: 'startTripTime',
          type: 'string'
        },
        "tripNum": {
          name: 'tripNum',
          type: 'number'
        },
        "origin": {
          name: 'origin',
          type: 'string'
        },
        "originLocation": {
          name: 'originLocation',
          type: 'GeoPoint'
        },
        "originAddress": {
          name: 'originAddress',
          type: 'string'
        },
        "originInstructions": {
          name: 'originInstructions',
          type: 'string'
        },
        "destination": {
          name: 'destination',
          type: 'string'
        },
        "destinationLocation": {
          name: 'destinationLocation',
          type: 'GeoPoint'
        },
        "destinationAddress": {
          name: 'destinationAddress',
          type: 'string'
        },
        "destinationInstructions": {
          name: 'destinationInstructions',
          type: 'string'
        },
        "polyline": {
          name: 'polyline',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
      }
    }
  }
}
