/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snack-bar-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./snack-bar-template.component";
import * as i8 from "@angular/material/snack-bar";
var styles_SnackBarTemplateComponent = [i0.styles];
var RenderType_SnackBarTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackBarTemplateComponent, data: {} });
export { RenderType_SnackBarTemplateComponent as RenderType_SnackBarTemplateComponent };
function View_SnackBarTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "snackbar-action"], ["color", "accent"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
export function View_SnackBarTemplateComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(0, null, ["", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackBarTemplateComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.data == null) ? null : _co.data.actions); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 0, 0, currVal_0); }); }
export function View_SnackBarTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snack-bar-template", [], null, null, null, View_SnackBarTemplateComponent_0, RenderType_SnackBarTemplateComponent)), i1.ɵdid(1, 49152, null, 0, i7.SnackBarTemplateComponent, [i8.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var SnackBarTemplateComponentNgFactory = i1.ɵccf("app-snack-bar-template", i7.SnackBarTemplateComponent, View_SnackBarTemplateComponent_Host_0, {}, { action: "action" }, []);
export { SnackBarTemplateComponentNgFactory as SnackBarTemplateComponentNgFactory };
