import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-content-w-header',
  templateUrl: './content-w-header.component.html',
  styleUrls: ['./content-w-header.component.scss'],
})
export class ContentWHeaderComponent {
  @Input() header: string;
  @Input() hasBack: boolean;

  @Output() back: EventEmitter<any> = new EventEmitter();

  constructor() {}

  backClick() {
    this.back.emit();
  }
}
