<h2 mat-dialog-title>
  Notify Email
</h2>

<div mat-dialog-content>

  <div class="dx-fieldset">
    <!--    <div class="dx-fieldset-header">Personal Info</div>-->

    <label>Subject</label>
    <dx-text-box [(value)]="subject"></dx-text-box>

    <br/>

    <label>Body</label>
    <dx-text-area
      [(value)]="body"
      [height]="200"
      [valueChangeEvent]='"keyup"'
    ></dx-text-area>

  </div>


</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="submit()">Submit</button>
</div>
