<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset" *ngIf="!acquisition">
      No created acquisitions
    </div>
    <div class="dx-fieldset" *ngIf="acquisition">
      <div class="dx-field">
        <div class="dx-field-label">Current Ownership Status</div>
        <div class="dx-field-value-static">
          {{ model?.ownershipStatus}}
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">Acquisition Date</div>
        <div class="dx-field-value-static">
          {{ acquisition?.date | date: 'M/d/yyyy'}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Type</div>
        <div class="dx-field-value-static">
          {{ acquisition?.type }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Seller/Dealer</div>
        <div class="dx-field-value-static">
          {{ acquisition?.dealer }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Seller/Dealer's Salesperson</div>
        <div class="dx-field-value-static">
          {{ acquisition?.salePerson?.person.firstname}} {{acquisition?.salePerson?.person.lastname }}
        </div>
      </div>
      
      <div class="dx-field">
        <div class="dx-field-label">Acquisition Creditor</div>
        <div class="dx-field-value-static">
          {{ acquisition?.creditor}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Creditor's Representative</div>
        <div class="dx-field-value-static">
          {{ acquisition?.creditorPerson?.person.firstname}} {{acquisition?.creditorPerson?.person.lastname }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Signor</div>
        <div class="dx-field-value-static">
          {{ acquisition?.signorPerson?.person.firstname}} {{acquisition?.signorPerson?.person.lastname }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Full Price</div>
        <div class="dx-field-value-static">
          {{ acquisition?.fullPrice}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Principal Balance</div>
        <div class="dx-field-value-static">
          {{ acquisition?.principalBalance}}
        </div>
      </div>

      <ng-container *ngIf="acquisition?.type !== 'Cash Purchase'">
        <div class="dx-field">
          <div class="dx-field-label">Acquisition Term In Month</div>
          <div class="dx-field-value-static">
            {{ acquisition?.termInMonth}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Loan Payment (Principal & Interest)</div>
          <div class="dx-field-value-static">
            {{ acquisition?.loanPayment}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Frequency Of Loan Payment</div>
          <div class="dx-field-value-static">
            {{ acquisition?.frequencyOfLoanPayment}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Payment Dates</div>
          <div class="dx-field-value-static">
            {{ acquisition?.paymentDates}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition First Payment Date</div>
          <div class="dx-field-value-static">
            {{ acquisition?.firstPaymentDate | date: 'M/d/yyyy'}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Last Payment Date</div>
          <div class="dx-field-value-static">
            {{ acquisition?.lastPaymentDate | date: 'M/d/yyyy'}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Annual Interest Rate</div>
          <div class="dx-field-value-static">
            {{ acquisition?.annualInterestRate}}
          </div>
        </div>
      </ng-container>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Contract</div>
        <div class="dx-field-value-static">
          {{ acquisition?.contract}}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Acquisition Contract Date</div>
        <div class="dx-field-value-static">
          {{ acquisition?.contractDate | date: 'M/d/yyyy'}}
        </div>
      </div>

      <div class="dx-field" *ngIf="!['Cash Purchase', 'Finance'].includes(acquisition?.type)">
        <div class="dx-field-label">Acquisition Lease Mileage Limit</div>
        <div class="dx-field-value-static">
          {{ acquisition?.leaseMileageLimit}}
        </div>
      </div>

      <ng-container *ngIf="acquisition?.type !== 'Cash Purchase'">
        <div class="dx-field">
          <div class="dx-field-label">Acquisition Buyout Option</div>
          <div class="dx-field-value-static">
            {{ acquisition?.buyoutOption}}
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Acquisition Buyout/Balloon Amount</div>
          <div class="dx-field-value-static">
            {{ acquisition?.balloonAmount}}
          </div>
        </div>
      </ng-container>

      <div class="dx-field">
        <div class="dx-field-label">Documents</div>
        <div class="dx-field-value-static">
          <div class="file" *ngFor="let f of acquisition?.file || []; let idx = index">
            <a target="_blank" [href]="f | fileSignedUrl | async | sanitizeUrl" [title]="f.originalFilename">{{ f.originalFilename }}</a>
          </div>
        </div>
      </div>

      <div class="dx-field" *ngIf="acquisition?.anyNotes?.length">
        <div class="_dx-field-label">Acquisition Notes</div>
        <div class="_dx-field-value-static">
          <app-any-notes [notes]="acquisition?.anyNotes"></app-any-notes>
        </div>
      </div>

    </div>
  </ng-container>
</app-details-tab>
