import { createSelector } from '@ngrx/store';
import { Actions, SET_TENANT, SET_TENANT_FAIL, SET_TENANT_SUCCESS } from '../../actions/sign';

export interface TenantState {
  error: any | null;
  pending: boolean;
}

export const initialState: TenantState = {
  error: null,
  pending: false,
};

export function tenantReducer(state = initialState, action: Actions): TenantState {
  switch (action.type) {
    case SET_TENANT: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case SET_TENANT_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case SET_TENANT_FAIL: {
      return {
        ...state,
        error: action.payload || 'Server unavailable',
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const tenantGetPending = (state: TenantState) => state.pending;
export const tenantGetError = (state: TenantState) => state.error;
export const tenantGetErrorMessage = createSelector(tenantGetError, err => (err ? err.message || err : ''));
