import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaust, exhaustMap, tap, timeout } from 'rxjs/operators';
import { delayAsync } from '../../../../shared/classes/utils/promise.utils';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { TripsAuditDetailsComponent } from '../../components/trips-audit-details/trips-audit-details.component';
import { TripsAuditListComponent } from '../../components/trips-audit-list/trips-audit-list.component';

@Component({
  selector: 'app-trips-audit',
  templateUrl: './trips-audit.component.html',
  styleUrls: ['./trips-audit.component.scss'],
})
export class TripsAuditComponent extends ABaseComponent implements OnInit {
  data: any = { markers: [], routes: [] };

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(TripsAuditListComponent, { static: true }) grid: TripsAuditListComponent;
  @ViewChild(TripsAuditDetailsComponent, { static: true }) details: TripsAuditDetailsComponent;

  constructor(protected logger: LoggerService) {
    super(logger);

    this.$resize$
      .pipe(
        exhaustMap(() =>
          of(true).pipe(
            delay(1000),
            tap(() => {
              // this.grid.repaint();
              this.details.repaint();
            }),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(data: any) {
    this.data = data;
    // if (ids && ids.length) {
    //   this.$selectedIds$.next(ids);
    // } else {
    //   this.$selectedIds$.next([]);
    // }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}
