import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
//
import { DataSourceOptions } from 'devextreme/data/data_source';
import moment, { duration } from 'moment';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { HelperService } from '../../../services/helper.service';
import { LoggerService } from 'src/app/shared/sdk';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';

@Component({
  selector: 'app-employee-perf-details',
  templateUrl: './employee-perf-details.component.html',
  styleUrls: ['./employee-perf-details.component.scss'],
  providers: [HelperService],
})
export class EmployeePerfDetailsComponent {
  _selectedRange: any;
  _employeeId: number;

  @Input()
  set selectedRange(v: any) {
    this._selectedRange = v;
    this.changeHandle();
  }
  get selectedRange() {
    return this._selectedRange;
  }

  @Input()
  set employeeId(v: number) {
    this._employeeId = v;
    this.changeHandle();
  }
  get employeeId() {
    return this._employeeId;
  }

  dso: DataSourceOptions;
  eventsDataSource: DataSourceOptions;
  eventsPopupVisible = false;
  eventsPopupPositionOf: string;

  constructor(protected logger: LoggerService, protected dss: DataSourceService) {}

  changeHandle() {
    if (this.employeeId && this.selectedRange && this.selectedRange.dateFrom) this.dso = this.buildDSO();
  }

  getDuration(seconds: number) {
    return (seconds && moment.utc(duration(seconds, 'seconds').as('milliseconds')).format('HH:mm:ss')) || '';
  }

  private buildDSO() {
    return {
      load: async () => {
        const [geotab, events] = await Promise.all([
          gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getGeoTabDaysAggregate()).pipe().toPromise(),
          gqlMongoLoad(this.dss, 'VehicleGeotabEvent', {}, this.getEventCountsAggregate()).pipe().toPromise(),
        ]);
        return this.prepareData(geotab, events);
      },
    } as DataSourceOptions;
  }

  private prepareData(geotab: any, events: any) {
    const eventsMap = events.reduce((p, v) => ({ ...p, [v._id]: v }), {});
    const days = geotab.map(day => {
      const d = '' + day.day;
      const date = `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(-2)}`;
      return {
        ...day,
        ...eventsMap[day.day],
        id: day.day,
        date: date,
        onDutyDuration: this.getDuration(+day.onDutyDuration),
        distance: Math.round(day.distance),
        onDutyDrivingDuration: this.getDuration(+day.onDutyDrivingDuration),
        onDutyIdleDuration: this.getDuration(+day.onDutyIdleDuration),
      };
    });
    return days;
  }

  showEvents_onClick = async (id, v, type) => {
    const events = await gqlMongoLoad(this.dss, 'VehicleGeotabEvent', {}, this.getEventsAggregate(v.date, type))
      .pipe()
      .toPromise();
    this.eventsDataSource = events;
    this.eventsPopupPositionOf = id;
    this.eventsPopupVisible = true;
  };

  getDetailsCellValue = v => {
    if (v.rule == 'Idling') return 'IDLE for more than 30 minutes';
    return v.diagnostic || `Speed:${v.speed}, Limit:${v.speedLimit}`;
  };

  getGeoTabDaysAggregate() {
    const from = moment(this.selectedRange.dateFrom);
    const to = moment(this.selectedRange.dateTo);
    return [
      { $match: { month: { $gte: +from.format('YYYYMM'), $lte: +to.format('YYYYMM') } } },
      { $unwind: '$days' },
      {
        $match: {
          'days.day': { $gte: +from.format('YYYYMMDD'), $lte: +to.format('YYYYMMDD') },
          'days.employeeId': this.employeeId,
        },
      },
      {
        $project: {
          day: '$days.day',
          onDutyDuration: '$days.onDutyDuration',
          distance: '$days.distance',
          onDutyDrivingDuration: '$days.onDutyDrivingDuration',
          onDutyIdleDuration: '$days.onDutyIdleDuration',
          internalId: 1,
          signaturesCount: '$days.signaturesCount',
        },
      },
    ];
  }

  getEventCountsAggregate() {
    const from = moment(this.selectedRange.dateFrom);
    const to = moment(this.selectedRange.dateTo);
    return [
      {
        $match: {
          employeeId: this.employeeId,
          $expr: {
            $and: [
              { $gte: ['$dateTime', { $dateFromString: { dateString: from.toISOString() } }] },
              { $lte: ['$dateTime', { $dateFromString: { dateString: to.toISOString() } }] },
            ],
          },
        },
      },
      {
        $project: {
          day: { $toInt: { $dateToString: { format: '%Y%m%d', date: '$dateTime' } } },
          compliance: { $cond: [{ $eq: ['$eventType', 'Compliance'] }, 1, 0] },
          safety: { $cond: [{ $eq: ['$eventType', 'Safety'] }, 1, 0] },
        },
      },
      {
        $group: { _id: '$day', compliance: { $sum: '$compliance' }, safety: { $sum: '$safety' } },
      },
    ];
  }

  getEventsAggregate(date, type) {
    return [
      {
        $match: {
          employeeId: this.employeeId,
          eventType: type,
          $expr: {
            $and: [
              { $gte: ['$dateTime', { $dateFromString: { dateString: `${date}T00:00:00.000Z` } }] },
              { $lte: ['$dateTime', { $dateFromString: { dateString: `${date}T23:59:59.000Z` } }] },
            ],
          },
        },
      },
    ];
  }
}

