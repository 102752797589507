import {
  Document,
  CustomField
} from '../index';

declare var Object: any;
export interface DocumentTypeInterface {
  "name": string;
  "objectFQN": string;
  "order"?: number;
  "frequent"?: boolean;
  "predefined"?: boolean;
  "inactive"?: boolean;
  "visibility"?: string;
  "type"?: string;
  "defaultNotes"?: string;
  "expAlert"?: boolean;
  "missingDocAlert"?: boolean;
  "dtime"?: Date;
  "id"?: number;
  documents?: Document[];
  customFields?: CustomField[];
}

export class DocumentType implements DocumentTypeInterface {
  "name": string;
  "objectFQN": string;
  "order": number;
  "frequent": boolean;
  "predefined": boolean;
  "inactive": boolean;
  "visibility": string;
  "type": string;
  "defaultNotes": string;
  "expAlert": boolean;
  "missingDocAlert": boolean;
  "dtime": Date;
  "id": number;
  documents: Document[];
  customFields: CustomField[];
  constructor(data?: DocumentTypeInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentType`.
   */
  public static getModelName() {
    return "DocumentType";
  }

  /**
  * This method creates an instance of DocumentType for dynamic purposes.
  **/
  public static factory(data: DocumentTypeInterface): DocumentType{
    return new DocumentType(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentType',
      plural: 'DocumentTypes',
      path: 'DocumentTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "objectFQN": {
          name: 'objectFQN',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "frequent": {
          name: 'frequent',
          type: 'boolean',
          default: false
        },
        "predefined": {
          name: 'predefined',
          type: 'boolean',
          default: false
        },
        "inactive": {
          name: 'inactive',
          type: 'boolean',
          default: false
        },
        "visibility": {
          name: 'visibility',
          type: 'string',
          default: 'public'
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'other'
        },
        "defaultNotes": {
          name: 'defaultNotes',
          type: 'string'
        },
        "expAlert": {
          name: 'expAlert',
          type: 'boolean',
          default: false
        },
        "missingDocAlert": {
          name: 'missingDocAlert',
          type: 'boolean',
          default: false
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'documentTypeId'
        },
        customFields: {
          name: 'customFields',
          type: 'CustomField[]',
          model: 'CustomField',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
      }
    }
  }
}
