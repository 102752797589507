<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="fullDoc">
    <!--    <pre>{{descAndReport | json}}</pre>-->

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Check In By</div>
        <div class="dx-field-value-static">
          <app-object-title [modelId]="fullDoc?.employeeId" [modelName]="'Employee'"></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Username</div>
        <div class="dx-field-value-static">
          <app-object-title [modelId]="fullDoc?.userId" [modelName]="'MyUser'" [field]="'username'"></app-object-title>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Vehicle</div>
        <div class="dx-field-value-static">
          #{{ model?.vehicle?.internalId }} - {{ model?.vehicle?.state }} - {{ model?.vehicle?.type }} -
          {{ model?.vehicle?.year }} {{ model?.vehicle?.make }} {{ model?.vehicle?.model }} - VIN
          {{ model?.vehicle?.vin?.substr(model?.vehicle?.vin?.length - 6) }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In Location</div>
        <div class="dx-field-value-static">{{ fullDoc?.location }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Location</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.location }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift Begin At</div>
        <div class="dx-field-value-static">{{ fullDoc?._pi | local: 'YYYY-MM-DD HH:mm':true }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift End At</div>
        <div class="dx-field-value-static">{{ fullDoc?._po | local: 'YYYY-MM-DD HH:mm':true }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Shift Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?._shiftDuration | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">Check In Begin At</div>
        <div class="dx-field-value-static">{{ fullDoc?.startedDateTime | local: 'YYYY-MM-DD HH:mm':true }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In End At</div>
        <div class="dx-field-value-static">{{ fullDoc?.completedDateTime | local: 'YYYY-MM-DD HH:mm':true }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Check In Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?.durationTime | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Begin At</div>
        <div class="dx-field-value-static">
          {{ fullDoc?.checkout?.startedDateTime | local: 'YYYY-MM-DD HH:mm':true }}
        </div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out End At</div>
        <div class="dx-field-value-static">
          {{ fullDoc?.checkout?.completedDateTime | local: 'YYYY-MM-DD HH:mm':true }}
        </div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Check Out Duration</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.durationTime | humanize: { units: ['h', 'm'] } }}</div>
      </div>

      <br />

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Vehicle Usage Duration</div>
        <div class="dx-field-value-static">
          {{ fullDoc?.checkout?._vehicleUsageDuration | humanize: { units: ['h', 'm'] } }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Device UID</div>
        <div class="dx-field-value-static">{{ fullDoc?._mobileDevice?.deviceUid }}</div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Device Label</div>
        <div class="dx-field-value-static">{{ fullDoc?._mobileDevice?.label }}</div>
      </div>

      <br />

      <div class="dx-field">
        <div class="dx-field-label">Days Since Last Check</div>
        <div class="dx-field-value-static">{{ fullDoc?.daysSinceLastCheck }}</div>
      </div>

      <div class="dx-field" *ngIf="fullDoc?.checkout">
        <div class="dx-field-label">Changed During Day</div>
        <div class="dx-field-value-static">{{ fullDoc?.checkout?.changedDuringDay | yesNo }}</div>
      </div>

      <ng-container *ngIf="fullDoc?.meta?.fmComments?.length">
        <hr />
        <div class="dx-field">
          <div class="dx-field-label">Fleet Manager Comments</div>
          <div class="dx-field-value-static">
            <ng-container *ngFor="let cObj of fullDoc?.meta?.fmComments">
              <div>
                <span style="font-style: italic"
                  ><app-object-title [modelId]="cObj?.userId" [modelName]="'MyUser'"></app-object-title>:</span
                >
                {{ cObj?.comment }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <hr />

      <div class="dx-fieldset-header">{{ summary?.expirations?.length }} Expired Items</div>
      <ol>
        <ng-container *ngFor="let entry of summary?.expirations">
          <li>
            <p>{{ entry[1].text }}</p>
            <p>{{ entry[1].action }}</p>
          </li>
        </ng-container>
      </ol>

      <div class="dx-fieldset-header">{{ summary?.issues?.length }} Vehicle Check Issues</div>
      <ol>
        <ng-container *ngFor="let entry of summary?.issues">
          <li>
            <p>{{ entry[1].text }}</p>
            <p>{{ entry[1].action }}</p>
          </li>
        </ng-container>
      </ol>

      <div class="dx-fieldset-header">{{ summary?.missingStuff?.length }} Missing Stuff</div>
      <ol>
        <ng-container *ngFor="let entry of summary?.missingStuff">
          <li>
            <p>{{ entry[1].text }}</p>
            <p>{{ entry[1].action }}</p>
          </li>
        </ng-container>
      </ol>

      <div class="dx-fieldset-header">{{ summary?.other?.length }} Other Issues</div>
      <ol>
        <ng-container *ngFor="let entry of summary?.other">
          <li>
            <p>{{ entry[1].text }}</p>
            <p>{{ entry[1].action }}</p>
          </li>
        </ng-container>
      </ol>

      <ng-container *ngIf="fullDoc?._baseEmRelations?.length">
        <hr />
        <ng-container *ngFor="let p of fullDoc?._baseEmRelations">
          <div class="dx-field">
            <div class="dx-field-label">{{ p?.data?._relation }}</div>
            <div class="dx-field-value-static">{{ p?.firstname }} {{ p?.lastname }}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</app-details-tab>
