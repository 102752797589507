import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { AppState } from '../../../../store/reducers';
import { getCurrentTenant } from '../../../../store/reducers/sign';
import { FacilityFormComponent } from '../facility-form/facility-form.component';

@Component({
  selector: 'app-facility-edit',
  templateUrl: './facility-edit.component.html',
  styleUrls: ['./facility-edit.component.scss'],
})
export class FacilityEditComponent implements OnInit {
  modelId$: Observable<any>;

  @ViewChild(FacilityFormComponent, { static: true }) facilityForm: FacilityFormComponent;

  constructor(
    private logger: LoggerService,
    private store: Store<AppState>,
  ) {
    this.modelId$ = this.store.pipe(select(getCurrentTenant));
  }

  ngOnInit() {}

  form_ngSubmit(e): void {
    this.facilityForm &&
      this.facilityForm
        .submitFormAsync()
        .then(facility => {
          facility && notify('Done!', 'success');
        })
        .catch(this.logger.error);
  }

  reset_onClick(e): void {
    this.facilityForm && this.facilityForm.reset();
  }
}
