<app-content-w-header header="Generate Report">
  <dx-scroll-view class="content">
    <!--<form #formCtrl="ngForm" [formGroup]="form">-->
    <div class="dx-fieldset" style="margin: 0 40px">
      <!--    <div class="dx-fieldset-header">Generate Report</div>-->

      <ng-container
        *ngIf="(['SU', 'MANAGER'] | someRole) && tenantType === 'BASE'">
        <dx-toolbar>
          <dxi-item text="From" [location]="'before'" [template]="'startDateTpl'"></dxi-item>
          <dxi-item text="-" [location]="'before'"></dxi-item>
          <dxi-item text="To" [location]="'before'" [template]="'endDateTpl'"></dxi-item>

          <!---->

          <ng-container *dxTemplate="let itemData of 'startDateTpl'">
            <dx-date-box [(value)]="selectedStartValue"></dx-date-box>
          </ng-container>

          <ng-container *dxTemplate="let itemData of 'endDateTpl'">
            <dx-date-box [(min)]="selectedStartValue" [(value)]="selectedEndValue"></dx-date-box>
          </ng-container>
        </dx-toolbar>

        <div *ngFor="let item of periodItems">
          <br />
          <dx-button type="default" [text]="item.caption" (onClick)="item.onClick($event)"></dx-button>
          <div class="itm-description">
            {{ item.description }}
          </div>
        </div>

        <br />
        <hr />

        <div *ngFor="let item of regularItems">
          <br />
          <dx-button type="default" [text]="item.caption" (onClick)="item.onClick($event)"></dx-button>
          <div class="itm-description">
            {{ item.description }}
          </div>
        </div>
      </ng-container>
    </div>
  </dx-scroll-view>
  <!--</form>-->
</app-content-w-header>
