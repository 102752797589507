<app-content-w-header header="Users">
  <dx-data-grid
    class="content"
    #grid
    height="100%"
    [dataSource]="dso"
    [stateStoring]="grid_stateStoring"
    [repaintChangesOnly]="true"
    [errorRowEnabled]="false"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onEditingStart)="grid_onEditingStart($event)">
    <dxo-editing
      mode="popup"
      [allowAdding]="false"
      [allowUpdating]="true"
      [allowDeleting]="true"
      [useIcons]="true"></dxo-editing>

    <dxo-export [enabled]="true" fileName="users"></dxo-export>

    <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

    <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

    <!-- Columns -->

    <!--<dxi-column type="selection" [sortIndex]="0"></dxi-column>-->

    <dxi-column caption="Username" dataField="username" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Email" dataField="email" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Last Name" dataField="person_lastname" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="First Name" dataField="person_firstname" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Facilities"
      dataField="_facilityIds"
      dataType="string"
      cellTemplate="facilitiesCellTpl"
      [allowEditing]="false"
      [calculateFilterExpression]="facilities_calculateFilterExpression"
      [calculateDisplayValue]="facilities_calculateValue">
      <!--                [calculateCellValue]="facilities_calculateCellValue"-->
      <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Visible From Facilities"
      dataField="employee_tenantIds"
      dataType="string"
      cellTemplate="visibleFromFacilitiesCellTpl"
      [allowEditing]="false"
      [calculateFilterExpression]="visibleFromFacilities_calculateFilterExpression"
      [calculateDisplayValue]="visibleFromFacilities_calculateValue">
      <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Roles"
      dataField="_roles"
      dataType="string"
      cellTemplate="rolesCellTpl"
      [allowEditing]="false"
      [filterOperations]='["=" , "<>", "contains", "notcontains", "anyof", "noneof", "isblank", "isnotblank"]'
      [calculateFilterExpression]="roles_calculateFilterExpression"
      [calculateDisplayValue]="roles_calculateValue"
    >
      <!--                [calculateCellValue]="roles_calculateCellValue"-->
      <!--      <dxo-lookup [dataSource]="roleDso$ | async" valueExpr="id" displayExpr="name"></dxo-lookup>-->
    </dxi-column>

    <dxi-column caption="Status" dataField="status" dataType="string">
      <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="MFA Email" dataField="mfaEmailEnabled" dataType="string" cellTemplate="YesNoCellTpl" [calculateFilterExpression]="calculateYesFilterExpression"></dxi-column>
    <dxi-column caption="MFA SMS" dataField="mfaSmsEnabled" dataType="string" cellTemplate="YesNoCellTpl" [calculateFilterExpression]="calculateYesFilterExpression"></dxi-column>
    <dxi-column caption="Last Login" dataField="lastLoginTime" dataType="date" format="shortDateShortTime"></dxi-column>
    <dxi-column caption="Last Online" dataField="lastOnlineTime" dataType="date" format="shortDateShortTime"></dxi-column>

    <!--<dxi-column caption="Email Verified"-->
    <!--dataField="emailVerified"-->
    <!--dataType="boolean"-->
    <!--&gt;</dxi-column>-->

    <dxi-column type="buttons" caption="actions" [showInColumnChooser]="false">
      <!--      <dxi-button-->
      <!--        text="Block"-->
      <!--        hint="Block"-->
      <!--        icon="clear"-->
      <!--        [visible]="isBlockIconVisible"-->
      <!--        [onClick]="blockIconClick"></dxi-button>-->
      <dxi-button text="Activate" hint="Activate" icon="check" [visible]="isActivateIconVisible" [onClick]="activateIconClick"></dxi-button>
      <dxi-button template="logoutBtn">
        <ng-container *dxTemplate="let cellInfo of 'logoutBtn'">
          <dx-button hint="Logout User" icon="fas fa-sign-out-alt" (onClick)="grid_onLogoutUser(cellInfo)"></dx-button>
        </ng-container>
      </dxi-button>
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'YesNoCellTpl'">
      {{ cellInfo.value && "YES" || '' }}
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'facilitiesCellTpl'">
      <span [title]="cellInfo?.data?._facilities || [] | orderBy | join: ', '">
        <span class="badge" *ngFor="let item of cellInfo?.data?._facilities || [] | orderBy">{{ item }}</span>
      </span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'visibleFromFacilitiesCellTpl'">
      <span [title]="(cellInfo?.data?._visibleFromFacilities$ | async) || [] | orderBy | join: ', '">
        <span class="badge" *ngFor="let item of (cellInfo?.data?._visibleFromFacilities$ | async) || [] | orderBy">{{
            item
          }}</span>
      </span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'rolesCellTpl'">
      <span [title]="cellInfo?.data?._roles || [] | orderBy | join: ', '">
        <span class="badge" *ngFor="let item of cellInfo?.data?._roles || [] | orderBy">{{ item }}</span>
      </span>
    </ng-container>
  </dx-data-grid>
</app-content-w-header>
