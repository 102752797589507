import { Component } from '@angular/core';
import { LoggerService } from '../../../../../shared/sdk';
import { CommonService } from '../../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import { ABaseConsistencyCheckComponent } from '../a-base-consistency-check.component';

@Component({
  selector: 'app-consistency-check1',
  templateUrl: './consistency-check1.component.html',
  styleUrls: ['./consistency-check1.component.scss'],
})
export class ConsistencyCheck1Component extends ABaseConsistencyCheckComponent {
  protected mongoSchema: any = { 'service date': 'datetime', '_trip._ctime': 'datetime', '_trip._rtime': 'datetime' };
  protected checkIdx = 1;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    public config: ConfigService,
    protected pusher: PusherService,
    protected notification: PushNotificationsService,
    protected gridHelper: GridHelperService,
    protected common: CommonService,
    protected dss: DataSourceService,
  ) {
    super(logger, ui, pusher, common, dss, gridHelper);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'd7564239-b8cb-48b8-b4ef-23568df2f0aa',
    };
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'fix',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'before',
      showText: 'always',
      options: {
        // icon: 'far fa-fix',
        text: 'Fix',
        hint: 'Fix common issues',
        onClick: () => this.grid_toolbar_fix_onClick(1),
      },
    });
  }

  grid_onContextMenuPreparing(e) {}

  grid_onCellPrepared(e) {}
}
