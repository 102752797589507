<dx-data-grid [dataSource]="ds" [errorRowEnabled]="true" (onInitialized)="grid_onInitialized($event)">
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

  <dxo-editing mode="cell" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <dxi-column caption="Name" dataField="name" dataType="string" [allowHeaderFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
