import { ApplicationRef, Inject, Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { captureException } from '@sentry/browser';
import { alert } from 'devextreme/ui/dialog';
import { concat, interval, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, exhaustMap, first, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from '../shared/sdk';

/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 1 minute.
 * 3. Whenever an update is available, it activates the update.
 */
@Injectable({
  providedIn: 'root',
})
export class SwUpdatesService implements OnDestroy {
  private $onDestroy$ = new Subject<void>();
  private checkInterval = 60_000; // 1 minute

  constructor(
    @Inject(LoggerService) private logger: LoggerService,
    private appRef: ApplicationRef,
    private swu: SwUpdate,
  ) {
    if (!swu.isEnabled) {
      this.log('SwUpdate not enabled');
      return;
    }

    const appIsStable$ = appRef.isStable.pipe(
      distinctUntilChanged(),
      tap(v => this.log('stable: ' + v)),
      first(v => v === true),
    );

    // Periodically check for updates (after the app is stabilized).
    concat(appIsStable$, interval(this.checkInterval))
      .pipe(
        exhaustMap(async () => {
          this.log('Checking for update...');
          await this.swu.checkForUpdate();
        }),
        catchError(err => of(captureException(err))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // Activate available updates.
    this.swu.available
      .pipe(
        tap(evt => this.log('Update available:', evt)),
        exhaustMap(() => {
          return alert('There is app update available. The page will be reloaded.', 'Update available').then(async () =>
            this.swu.activateUpdate(),
          );
        }),
        catchError(err => of(captureException(err))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // Activated updates.
    this.swu.activated
      .pipe(
        tap(evt => {
          this.log('Update activated:', evt);
          document.location.reload();
        }),
        catchError(err => of(captureException(err))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.$onDestroy$.next();
  }

  private log(...message: any) {
    const timestamp = new Date().toString();
    console.log(`[SwUpdates - ${timestamp}]:`, ...message);
  }
}
