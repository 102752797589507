<div class="toolbar">
  <dx-date-box width="100px" [(value)]="selectedDate" (onValueChanged)="onDateValueChanged()">
    <dxo-calendar-options maxZoomLevel="month" minZoomLevel="year"></dxo-calendar-options>
  </dx-date-box>
  <dx-select-box
    width="100px"
    [items]="loops"
    [(value)]="selectedLoop"
    displayExpr="name"
    (onValueChanged)="onValueChanged($event)"
    placeholder="Choose Loop"
    [showClearButton]="true"
  ></dx-select-box>
  <dx-tag-box
    width="100px"
    [items]="clients"
    [(value)]="selectedClients"
    [showSelectionControls]="true"
    [maxDisplayedTags]="0"
    (onValueChanged)="onValueChanged($event)"
  ></dx-tag-box>
  <dx-tag-box 
    width="143px"
    [items]="events"
    [(value)]="selectedEvents"
    [showSelectionControls]="true"
    (onValueChanged)="onValueChanged($event)"
  ></dx-tag-box>
  <dx-tag-box width="180px"
    [items]="sources"
    [(value)]="selectedSources"
    [showSelectionControls]="true"
    (onValueChanged)="onValueChanged($event)"
  ></dx-tag-box>
</div>
<dx-map
    width="100%"
    height="100%"
    provider="google"
    [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
    [autoAdjust]="true"
    [zoom]="14"
    [markers]="markers"
    [routes]="routes">
</dx-map>