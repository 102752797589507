import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { dxStoreUpdateHooks } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { Facility, LoggerService, Vehicle, VehicleApi } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-vehicle-to-facility',
  templateUrl: './vehicle-to-facility.component.html',
  styleUrls: ['./vehicle-to-facility.component.scss'],
})
export class VehicleToFacilityComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso$: Observable<ArrayStore>;
  facilityDso$: Observable<DataSourceOptions>;

  store: ArrayStore;

  constructor(
    private logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: VehicleApi,
  ) {
    // this.grid_stateStoring = this.sss.buildOptions('9d547ff7-f73e-419f-8174-f28f03573708');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '9cb2d9d8-00e7-4f59-9635-f76c7a3faa8f',
    };
  }

  ngOnInit() {
    this.dso$ = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    //    e.toolbarOptions.items.unshift(
    //    );
  }

  tagBox_onValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }

  private buildDataSource() {
    return this.api.find<VehicleApi>({}).pipe(
      tap(() => this.common.incPending()),
      catchError(err => {
        notify(err.message || err, 'error', 5000);
        return of([]);
      }),
      map((vehicles: Vehicle[]) => {
        //        console.log(data);

        // vehicles.forEach((rec) => {
        //   (rec as any).tmpTenants = (rec.assignedToTenant || '')
        //     .split('_')
        //     .filter((id) => !isEmpty('' + id))
        //     .map((id) => Number(id))
        //     .filter((id) => !isNaN(id) && id > 0)
        //   ;
        // });

        this.store = new ArrayStore({
          key: 'id',
          data: vehicles,
        });

        dxStoreUpdateHooks(
          this.store,
          async (key: any, values: any) => {
            //            console.log('before: ', [key, values]);

            // const data = {assignedToTenant: '_' + (values.tmpTenants || []).join('_') + '_'};
            const data = { tenantIds: oc(values).tenantIds([]) };
            await this.api.patchAttributes(key, data).toPromise();

            return [key, values];
          },
          async ([key, values], [resValues, resKey]) => {
            //            console.log('after: ', [resValues, resKey]);

            return [resValues, resKey];
          },
        );

        return this.store;
      }),
      tap(() => this.common.decPending()),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso = {
      store,
      //      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'name' }],
    } as DataSourceOptions;
    return of(dso);
  }
}
