/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-calendar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/devextreme-angular/ui/calendar/devextreme-angular-ui-calendar.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "devextreme-angular/ui/calendar";
import * as i6 from "devextreme-angular/core";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../services/helper.service";
import * as i9 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i10 from "./dlg-calendar.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../../shared/sdk/services/custom/logger.service";
var styles_DlgCalendarComponent = [i0.styles];
var RenderType_DlgCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgCalendarComponent, data: {} });
export { RenderType_DlgCalendarComponent as RenderType_DlgCalendarComponent };
function View_DlgCalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.calendarClass(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarTitle(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_2); }); }
export function View_DlgCalendarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "dx-calendar", [], null, [[null, "onValueChanged"], [null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("onValueChanged" === en)) {
        var pd_2 = (_co.calendar_onValueChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_DxCalendarComponent_0, i3.RenderType_DxCalendarComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DxCalendarComponent]), i1.ɵprd(512, null, i6.DxTemplateHost, i6.DxTemplateHost, []), i1.ɵprd(512, null, i6.WatcherHelper, i6.WatcherHelper, []), i1.ɵprd(512, null, i6.IterableDifferHelper, i6.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i6.NestedOptionHost, i6.NestedOptionHost, []), i1.ɵdid(6, 7323648, null, 0, i5.DxCalendarComponent, [i1.ElementRef, i1.NgZone, i6.DxTemplateHost, i6.WatcherHelper, i6.IterableDifferHelper, i6.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], null, { onValueChanged: "onValueChanged", valueChange: "valueChange", onBlur: "onBlur" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DlgCalendarComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i6.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { _ck(_v, 6, 0); var currVal_0 = "cell"; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_DlgCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-dlg-calendar", [], null, null, null, View_DlgCalendarComponent_0, RenderType_DlgCalendarComponent)), i1.ɵprd(512, null, i8.HelperService, i8.HelperService, [i9.DataSourceService]), i1.ɵdid(2, 114688, null, 0, i10.DlgCalendarComponent, [i11.MatDialogRef, i11.MAT_DIALOG_DATA, i8.HelperService, i12.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DlgCalendarComponentNgFactory = i1.ɵccf("app-dlg-calendar", i10.DlgCalendarComponent, View_DlgCalendarComponent_Host_0, {}, {}, []);
export { DlgCalendarComponentNgFactory as DlgCalendarComponentNgFactory };
