<app-content-w-header header="Vehicle Utilisation Pivot">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <!--      <dx-toolbar>-->
      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>
      <!--        </dxi-item>-->

      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <dx-select-box
        width="80px"
        [dataSource]="vehicleDso$ | async"
        displayExpr="internalId"
        valueExpr="id"
        [(value)]="vehicleId"
        placeholder="Vehicle"
        [showClearButton]="true"
        [searchEnabled]="true"
        (onSelectionChanged)="vehicle_onSelectionChanged($event)"></dx-select-box>
      <!--        </dxi-item>-->

      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <dx-select-box
        [dataSource]="['Owned-Paid', 'Owned-Financed', 'Leased']"
        [(value)]="ownership"
        [showClearButton]="true"
        placeholder="Ownership"
        width="110px"></dx-select-box>
      <!--        </dxi-item>-->

      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <dx-select-box
        [dataSource]="facilityDso$ | async"
        displayExpr="name"
        valueExpr="shortname"
        [(value)]="facility"
        placeholder="Facility"
        [showClearButton]="true"
        [searchEnabled]="true"
        (onSelectionChanged)="facility_onSelectionChanged($event)"></dx-select-box>
      <!--        </dxi-item>-->

      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <dx-select-box
        width="130px"
        [dataSource]="['ACTIVE', 'INACTIVE']"
        [(value)]="status"
        [showClearButton]="true"
        placeholder="Current Status"></dx-select-box>
      <!--        </dxi-item>-->

      <!--        <dxi-item location="after" locateInMenu="auto">-->
      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
      <!--        </dxi-item>-->

      <!--      </dx-toolbar>-->
    </div>

    <div class="dx-fieldset" style="display: flex">
      <div>
        <dx-check-box text="Show Column Totals" [(value)]="showColumnTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Column Grand Totals" [(value)]="showColumnGrandTotals"></dx-check-box>
      </div>
      <div>
        <dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box>
      </div>
    </div>
  </mat-card>

  <dx-pivot-grid
    class="content"
    width="100%"
    height="100%"
    [dataSource]="dataSource"
    [allowExpandAll]="true"
    [allowSortingBySummary]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [showTotalsPrior]="showTotalsPrior ? 'both' : 'none'"
    [dataFieldArea]="rowsDataFieldArea ? 'row' : 'column'"
    [rowHeaderLayout]="treeHeaderLayout ? 'tree' : 'standard'"
    [showColumnTotals]="showColumnTotals"
    [showRowTotals]="showRowTotals"
    [showColumnGrandTotals]="showColumnGrandTotals"
    [showRowGrandTotals]="showRowGrandTotals"
    [stateStoring]="grid_stateStoring">
    <dxo-field-chooser applyChangesMode="onDemand"></dxo-field-chooser>

    <dxo-field-panel
      [showDataFields]="false"
      [showRowFields]="true"
      [showColumnFields]="true"
      [showFilterFields]="true"
      [allowFieldDragging]="true"
      [visible]="true">
    </dxo-field-panel>

    <!--    <dxo-scrolling mode="virtual" [useNative]="true"></dxo-scrolling>-->
    <dxo-scrolling [useNative]="true"></dxo-scrolling>

    <!--    <dxo-export [enabled]="true" [fileName]="exportFileName"></dxo-export>-->
  </dx-pivot-grid>
</app-content-w-header>
