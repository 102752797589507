import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../../consumer/services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';

@Component({
  selector: 'app-vehicle-details-tab-claims',
  templateUrl: './vehicle-details-tab-claims.component.html',
  styleUrls: ['./vehicle-details-tab-claims.component.scss'],
})
export class VehicleDetailsTabClaimsComponent
  extends ABaseModelPaneWToolbarComponent<Vehicle>
  implements OnInit, OnChanges, OnDestroy
{
  isBiller$: Observable<boolean>;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger, ui, dss);

    this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.caption = 'Vehicle Claims';
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Vehicle>> {
    return VehicleFormComponent;
  }

  protected get observeModels(): any[] {
    return [this.ModelClass];
  }
}
