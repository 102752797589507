<dx-data-grid
  #grid
  height="100%"
  [dataSource]="signatureDso$ | async"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="500"></dxo-paging>

  <dxo-export [enabled]="true" fileName="vehicles-locations-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Facility" dataField="tenant.shortname"></dxi-column>

  <dxi-column caption="DATE" dataField="dateTime" dataType="date" format="shortDate"></dxi-column>

  <dxi-column caption="Vehicle" dataField="meta.vehicle.internalId"></dxi-column>

  <dxi-column caption="Driver" dataField="employee" cellTemplate="driverTemplate"></dxi-column>

  <dxi-column caption="Client First Name" dataField="consumer.person.firstname"></dxi-column>

  <dxi-column caption="Client Last Name" dataField="consumer.person.lastname"></dxi-column>

  <dxi-column caption="Trip Marker" dataField="marker"></dxi-column>

  <dxi-column caption="Pickup Time" dataField="pickupTime" dataType="date" format="shortTime"></dxi-column>

  <dxi-column caption="Drop-off Time" dataField="dropoffTime" dataType="date" format="shortTime"></dxi-column>

  <dxi-column caption="BROKER" dataField="broker" dataType="string"></dxi-column>

  <dxi-column caption="TRIPID" dataField="meta.tripId"></dxi-column>


  <!-- Templates -->

  <div *dxTemplate="let item of 'driverTemplate'">
    <span *ngIf="item.value && item.value.person">{{ item.value.person.firstname }} {{ item.value.person.lastname }}</span>
  </div>
</dx-data-grid>
