import {
  Contact,
  Employee,
  Consumer,
  Document,
  PersonalPhoto
} from '../index';

declare var Object: any;
export interface PersonInterface {
  "firstname": string;
  "lastname": string;
  "middlename"?: string;
  "nickname"?: string;
  "sex"?: string;
  "dob"?: any;
  "data"?: any;
  "notes"?: string;
  "dtime"?: Date;
  "id"?: number;
  "contactId"?: number;
  contact?: Contact;
  employee?: Employee;
  consumer?: Consumer;
  emForEmployee?: Employee[];
  emForConsumer?: Consumer[];
  relatedDocuments?: Document[];
  personalPhoto?: PersonalPhoto;
}

export class Person implements PersonInterface {
  "firstname": string;
  "lastname": string;
  "middlename": string;
  "nickname": string;
  "sex": string;
  "dob": any;
  "data": any;
  "notes": string;
  "dtime": Date;
  "id": number;
  "contactId": number;
  contact: Contact;
  employee: Employee;
  consumer: Consumer;
  emForEmployee: Employee[];
  emForConsumer: Consumer[];
  relatedDocuments: Document[];
  personalPhoto: PersonalPhoto;
  constructor(data?: PersonInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Person`.
   */
  public static getModelName() {
    return "Person";
  }

  /**
  * This method creates an instance of Person for dynamic purposes.
  **/
  public static factory(data: PersonInterface): Person{
    return new Person(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Person',
      plural: 'People',
      path: 'People',
      idName: 'id',
      properties: {
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "middlename": {
          name: 'middlename',
          type: 'string'
        },
        "nickname": {
          name: 'nickname',
          type: 'string'
        },
        "sex": {
          name: 'sex',
          type: 'string'
        },
        "dob": {
          name: 'dob',
          type: 'any'
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "contactId": {
          name: 'contactId',
          type: 'number'
        },
      },
      relations: {
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'contactId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'personId'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'personId'
        },
        emForEmployee: {
          name: 'emForEmployee',
          type: 'Employee[]',
          model: 'Employee',
          relationType: 'hasMany',
          modelThrough: 'EmployeeEmPerson',
          keyThrough: 'employeeId',
          keyFrom: 'id',
          keyTo: 'personId'
        },
        emForConsumer: {
          name: 'emForConsumer',
          type: 'Consumer[]',
          model: 'Consumer',
          relationType: 'hasMany',
          modelThrough: 'ConsumerEmPerson',
          keyThrough: 'consumerId',
          keyFrom: 'id',
          keyTo: 'personId'
        },
        relatedDocuments: {
          name: 'relatedDocuments',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
        personalPhoto: {
          name: 'personalPhoto',
          type: 'PersonalPhoto',
          model: 'PersonalPhoto',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'personId'
        },
      }
    }
  }
}
