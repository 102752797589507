import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { Observable, of } from 'rxjs';
//
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { Facility } from '../../../../shared/sdk/models';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-hha-records',
  templateUrl: './hha-records.component.html',
  styleUrls: ['./hha-records.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HhaRecordsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  HHA_PAYERS = [
    { ID: '14475', Name: 'UPMC' },
    { ID: '16999', Name: 'PHW' },
    { ID: '20154', Name: 'KEYSTONE' },
  ];
  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);

  private demDsMap: {
    [patientId: string]: {
      hhaAuthDS?: any;
    };
  } = {};

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('5d65cd9e-c274-4653-9f05-63f0445d59e8');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'cc94cd26-721e-4325-b2c1-b0fab282b288',
    };
  }

  // calcCellDateValue(rowData: any): Date {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().toDate() : rowData[column.dataField];
  // }
  //
  // calcDisplayDateValue(rowData: any): string {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().format('YYYY-MM-DD') : rowData[column.dataField];
  // }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  detailGrid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: true,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'ExportsExchangeDataCache';
        return (
          gqlMongoLoad(self.dss, col, loadOptions)
            // .pipe(
            //   // switchMap(async (docs: any[]) => {
            //   //   await Promise.all(
            //   //     docs
            //   //       .map(async (doc) => {}),
            //   //   );
            //   //   return docs;
            //   // }),
            // )
            .toPromise()
        );
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'ExportsExchangeDataCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'ExportsExchangeDataCache';
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      sort: [{ selector: '_source' }, { selector: '_mci' }],
      filter: ['_source', '=', 'HHA.PAT_DEM'],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    };
    return dso;
  }

  authDS(patientId: string) {
    const self = this;

    if (!self.demDsMap[patientId]) {
      self.demDsMap[patientId] = {};
    }

    if (!self.demDsMap[patientId].hhaAuthDS) {
      const store = new CustomStore({
        useDefaultSearch: true,
        cacheRawData: false,
        load: async (loadOptions: LoadOptions): Promise<any> => {
          const col = 'ExportsExchangeDataCache';
          return (
            gqlMongoLoad(self.dss, col, loadOptions)
              // .pipe()
              .toPromise()
          );
        },
        byKey: async (key: any | string | number): Promise<any> => {
          const col = 'ExportsExchangeDataCache';
          return gqlMongoByKey(self.dss, col, key).toPromise();
        },
        totalCount: async (loadOptions: LoadOptions): Promise<number> => {
          const col = 'ExportsExchangeDataCache';
          return gqlMongoCount(self.dss, col, loadOptions).toPromise();
        },
      });

      const dso: DataSourceOptions = {
        store,
        filter: [
          ['_source', '=', 'HHA.PAT_AUTH'],
          ['_patientId', '=', patientId],
        ],
        // postProcess: (data: Array<any>): Array<any> => {
        //   return data;
        // },
      };

      self.demDsMap[patientId].hhaAuthDS = dso;
    }

    return self.demDsMap[patientId].hhaAuthDS;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    };
    return of(dso);
  }
}
