<div mat-dialog-content>
  <mat-form-field>
    <input #inputElement matInput type="number" placeholder="Amount" [(ngModel)]="value" cdkFocusInitial />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value">Set</button>
</div>
