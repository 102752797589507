<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <br />

  <dx-data-grid width="auto" height="900px" [dataSource]="dso$ | async">
    <!-- Settings -->

    <dxo-editing mode="cell" [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="true"></dxo-editing>

    <dxo-export [enabled]="true" fileName="activity-export"></dxo-export>

    <!-- Columns -->

    <dxi-column caption="Date" dataField="dateTime" dataType="date" sortOrder="desc" format="shortDateShortTime" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Tenant" dataField="tenant.name" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Vehicle" dataField="internalId" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Type" dataField="eventType" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Event" dataField="rule" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column caption="Details" [calculateCellValue]="getDetailsCellValue" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Location"
      [calculateCellValue]="getGeoTabLocationCellValue"
      cellTemplate="geoTabLocation_cell"
      [allowSearch]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Supervisors Comment" dataField="supervisorsComment" dataType="string"></dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'geoTabLocation_cell'">
      <a *ngIf="cellInfo.value" target="_blank" class="show-loop-link" [href]="getMapURL(cellInfo.value)" title="link">link</a>&nbsp;
      <a *ngIf="cellInfo.data.loop" class="show-loop-link" (click)="showLoop_onClick(cellInfo.data)" title="loop">loop</a>
    </ng-container>

    

  </dx-data-grid>
</app-details-tab>
