import { Pipe, PipeTransform } from '@angular/core';
import orderBy from 'lodash-es/orderBy';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform<T = any>(values: T[], props?: Array<keyof T>, orders?: Array<'asc' | 'desc'>): T[] {
    return values ? orderBy(values, props, orders) : values;
  }
}
