/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-calendar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/devextreme-angular/ui/calendar/devextreme-angular-ui-calendar.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "devextreme-angular/ui/calendar";
import * as i5 from "devextreme-angular/core";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./dlg-calendar.component";
import * as i8 from "@angular/material/dialog";
var styles_DlgCalendarComponent = [i0.styles];
var RenderType_DlgCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgCalendarComponent, data: {} });
export { RenderType_DlgCalendarComponent as RenderType_DlgCalendarComponent };
export function View_DlgCalendarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "dx-calendar", [], null, [[null, "onValueChanged"], [null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("onValueChanged" === en)) {
        var pd_2 = (_co.calendar_onValueChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_DxCalendarComponent_0, i2.RenderType_DxCalendarComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DxCalendarComponent]), i1.ɵprd(512, null, i5.DxTemplateHost, i5.DxTemplateHost, []), i1.ɵprd(512, null, i5.WatcherHelper, i5.WatcherHelper, []), i1.ɵprd(512, null, i5.IterableDifferHelper, i5.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i5.NestedOptionHost, i5.NestedOptionHost, []), i1.ɵdid(6, 7323648, null, 0, i4.DxCalendarComponent, [i1.ElementRef, i1.NgZone, i5.DxTemplateHost, i5.WatcherHelper, i5.IterableDifferHelper, i5.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], null, { onValueChanged: "onValueChanged", valueChange: "valueChange", onBlur: "onBlur" })], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
export function View_DlgCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-calendar", [], null, null, null, View_DlgCalendarComponent_0, RenderType_DlgCalendarComponent)), i1.ɵdid(1, 114688, null, 0, i7.DlgCalendarComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgCalendarComponentNgFactory = i1.ɵccf("app-dlg-calendar", i7.DlgCalendarComponent, View_DlgCalendarComponent_Host_0, {}, {}, []);
export { DlgCalendarComponentNgFactory as DlgCalendarComponentNgFactory };
