<div>
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
  >
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!-- Columns -->

    <dxi-column caption="Export Source Type" dataField="source" dataType="string"></dxi-column>

    <dxi-column caption="State" dataField="state" dataType="string"></dxi-column>

    <dxi-column caption="Message" dataField="error.msg" dataType="string"></dxi-column>

    <dxi-column caption="Meta">
      <dxi-column caption="File" dataField="meta.origFileName" dataType="string"></dxi-column>

      <dxi-column caption="File Size" dataField="meta.contentLength" dataType="number">
        <dxo-format [formatter]="lengthFormatter"></dxo-format>
      </dxi-column>

      <dxi-column caption="IP" dataField="meta.remoteAddress" dataType="string"></dxi-column>

      <dxi-column caption="New Docs" dataField="meta.newDocs" dataType="number"></dxi-column>

      <dxi-column caption="Total Docs" dataField="meta.totalDocs" dataType="number"></dxi-column>

      <dxi-column caption="New Docs Dates Only" dataField="meta.dates" dataType="object" cellTemplate="dates_cell"></dxi-column>

      <dxi-column caption="User" dataField="meta.userId" dataType="number">
        <dxo-lookup [dataSource]="usersDso$ | async" valueExpr="id" displayExpr="username"></dxo-lookup>
      </dxi-column>
    </dxi-column>

    <!--    <dxi-column caption="Created Date"-->
    <!--                dataField="ctime"-->
    <!--                dataType="date"-->
    <!--                format="shortDateShortTime"-->
    <!--                [editorOptions]="{type: 'date', displayFormat: 'shortDateShortTime'}"-->
    <!--    ></dxi-column>-->

    <dxi-column
      caption="Upload Date"
      dataField="ctime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    ></dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'dates_cell'">
      <span [title]="cellInfo.value || [] | join: ', '">
        <span class="badge" *ngFor="let item of cellInfo.value || []">{{ item }}</span>
      </span>
    </ng-container>
  </dx-data-grid>
</div>
