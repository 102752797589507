<dx-tab-panel
  width="100%"
  height="100%"
  #tabPanel
  [dataSource]="tabs"
  [(selectedIndex)]="selectedIndex"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="true"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'summary'">
    <dx-scroll-view height="100%" *ngIf="selectedIndex === 0">
      <app-daily-checks-details-tab-info [modelId]="modelId"></app-daily-checks-details-tab-info>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'full'">
    <dx-scroll-view height="100%" *ngIf="selectedIndex === 1">
      <app-daily-checks-details-tab-full [modelId]="modelId"></app-daily-checks-details-tab-full>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'pics'">
    <dx-scroll-view height="100%" *ngIf="selectedIndex === 2">
      <app-daily-checks-details-tab-pics [modelId]="modelId"></app-daily-checks-details-tab-pics>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'vehicle'">
    <dx-scroll-view height="100%" *ngIf="selectedIndex === 3">
      <app-daily-checks-details-tab-vehicle [modelId]="model?.vehicleId"></app-daily-checks-details-tab-vehicle>
    </dx-scroll-view>
  </div>
</dx-tab-panel>
