import { Component, ViewChild } from '@angular/core';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import { PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import moment from 'moment';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-remittance-pivot',
  templateUrl: './remittance-pivot.component.html',
  styleUrls: ['./remittance-pivot.component.scss'],
})
export class RemittancePivotComponent extends ABaseComponent {
  @ViewChild(DxPivotGridComponent, { static: false }) grid: DxPivotGridComponent;

  dso: any;
  grid_stateStoring: any;

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  showColumnTotals = true;
  showRowTotals = true;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  collection = 'RemittancePivotData';
  exportFileName = 'Remittance_Pivot_' + moment().format('YYYY-MM-DD HH:mm:ss');

  constructor(public logger: LoggerService, protected dss: DataSourceService) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '1f0b4f8f-8cf3-4d84-a371-552c7ec6e1dc',
    };

    this.dso = {
      remoteOperations: true,
      store: this.dss.createMongoStore(this.collection),
      fields: [
        { dataField: '_id', visible: false },
        { area: 'column', dataField: 'tripDate', dataType: 'date', groupInterval: 'year' },
        { area: 'column', dataField: 'tripDate', dataType: 'date', groupInterval: 'quarter' },
        { area: 'column', dataField: 'tripDate', dataType: 'date', groupInterval: 'month' },
        { dataField: 'tripDate', dataType: 'date', groupInterval: 'day' },

        { dataField: 'broker', dataType: 'string', caption: 'Broker', isMeasure: false, area: 'row' },
        { dataField: 'service', dataType: 'string', caption: 'Service', isMeasure: false, area: 'row' },
        // {dataField: 'tripId', dataType: 'string', caption: 'TripID', isMeasure: false},

        // ...([
        //   {caption: 'Count', area: 'data'},
        // ].map((i) => ({
        //   ...i,
        //   dataType: 'number', summaryType: 'count', isMeasure: true,
        // }))),

        ...[{ dataField: 'count', caption: 'Count', area: 'data' }].map(i => ({
          ...i,
          dataType: 'number',
          summaryType: 'sum',
          isMeasure: true,
        })),

        ...[{ dataField: 'amount', caption: '$ Amount', area: 'data' }].map(i => ({
          ...i,
          dataType: 'number',
          summaryType: 'sum',
          format: 'currency',
          isMeasure: true,
        })),
      ],
    } as PivotGridDataSourceOptions;
  }
}
