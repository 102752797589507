<kendo-upload
  [autoUpload]="autoUpload"
  [batch]="batch"
  [withCredentials]="withCredentials"
  [saveField]="saveField"
  [saveHeaders]="saveHeaders"
  [saveMethod]="saveMethod"
  [saveUrl]="saveUrl"
  [responseType]="responseType"
  [removeField]="removeField"
  [removeHeaders]="removeHeaders"
  [removeMethod]="removeMethod"
  [removeUrl]="removeUrl"
  [concurrent]="concurrent"
  [multiple]="multiple"
  [disabled]="disabled"
  [showFileList]="showFileList"
  (blur)="uploader_noop()"
  (focus)="uploader_noop()"
  (pause)="uploader_noop()"
  (remove)="uploader_noop()"
  (resume)="uploader_noop()"
  (uploadProgress)="uploader_noop()"
  (cancel)="uploader_cancel($event)"
  (clear)="uploader_clear($event)"
  (complete)="uploader_complete($event)"
  (error)="uploader_error($event)"
  (select)="uploader_select($event)"
  (upload)="uploader_upload($event)"
  (success)="uploader_success($event)"
></kendo-upload>
