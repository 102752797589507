import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-position-list',
  templateUrl: './employee-position-list.component.html',
  styleUrls: ['./employee-position-list.component.scss'],
})
export class EmployeePositionListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
