import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxMapComponent } from 'devextreme-angular/ui/map';
import { compact, flatten, isEmpty, uniq } from 'lodash-es';
import { of } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import {
  Config,
  ConfigApi,
  Consumer,
  ConsumerApi,
  Facility,
  FacilityApi,
  LoggerService,
  LoopBackFilter,
  TripManifest,
  TripManifestApi,
} from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-map',
  templateUrl: './dlg-map.component.html',
  styleUrls: ['./dlg-map.component.scss'],
})
export class DlgMapComponent extends ABaseComponent implements OnInit {
  markers;
  routes;
  loading = true;

  @ViewChild(DxMapComponent, { static: false }) map: DxMapComponent;

  constructor(
    private dialogRef: MatDialogRef<DlgMapComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { manifestId: number; recIds: string[] },
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();

    of(this.data)
      .pipe(
        tap(() => (this.loading = true)),
        switchMap(({ manifestId }) =>
          this.dss.getApi<TripManifestApi>(TripManifest).findById<TripManifest>(manifestId),
        ),
        switchMap(async manifest => {
          let records = oc(manifest).data([]);

          if (!isEmpty(this.data.recIds)) {
            records = records.filter(r => this.data.recIds.includes(r.id));
          }

          const fFilter: LoopBackFilter = {
            include: [
              {
                relation: 'contact',
                scope: {
                  fields: ['id'],
                  include: ['addresses', 'phones', 'emails'],
                },
              },
            ],
          } as LoopBackFilter;
          const cFilter: LoopBackFilter = {
            fields: { data: false },
            include: [
              {
                relation: 'person',
                scope: {
                  fields: ['firstname', 'lastname', 'contactId'],
                  include: [
                    {
                      relation: 'contact',
                      scope: {
                        fields: ['id'],
                        include: ['addresses', 'phones', 'emails'],
                      },
                    },
                  ],
                },
              },
            ],
          } as LoopBackFilter;

          const [facility, destinations, consumers, brokerTrips] = await Promise.all([
            this.config.tenant$
              .pipe(
                first(),
                switchMap(f => this.dss.getApi<FacilityApi>(Facility).findById<Facility>(f.id, { ...fFilter })),
              )
              .toPromise(),

            this.dss.getApi<ConfigApi>(Config).getAllDestinations().toPromise<any[]>(),

            this.dss
              .getApi<ConsumerApi>(Consumer)
              .find<Consumer>({
                ...cFilter,
                where: {
                  id: { inq: uniq(compact(records.map(r => r.c))) },
                },
              })
              .toPromise<any[]>(),

            this.dss
              .getApi<TripManifestApi>(TripManifest)
              .getBrokerTrips(manifest.id, uniq(compact(records.map(r => r.id))))
              .toPromise<any[]>(),
          ]);

          const destMap = new Map(destinations.map(d => [d.short, d]));
          const consMap = new Map(consumers.map(c => [c.id, c]));
          const tripsMap = new Map(brokerTrips.map(t => [t.tripId, t]));

          return records.map(r => {
            const rExt: any = r;

            rExt._consumer = consMap.get(r.c);
            rExt._brokerTrip = tripsMap.get(r.tId);
            rExt._originObj = destMap.get(r.o);
            rExt._destObj = destMap.get(r.d);

            const personName = rExt._brokerTrip
              ? `${oc(rExt)._brokerTrip.person.first()} ${oc(rExt)._brokerTrip.person.last()}`
              : `${oc(rExt)._consumer.person.firstname()} ${oc(rExt)._consumer.person.lastname()}`;

            const puLocation = rExt._brokerTrip
              ? oc(rExt)._brokerTrip.from.geodata.location()
              : rExt._originObj
                ? rExt._originObj.address
                : r.o === 'RESIDENCE'
                  ? null
                  : r.o === 'FACILITY'
                    ? null
                    : r.o;

            // @ts-ignore
            const puName = rExt._brokerTrip
              ? rExt._brokerTrip.from.name || rExt._brokerTrip.from.street
              : rExt._originObj
                ? rExt._originObj.name || rExt._originObj.address
                : r.o;

            const doLocation = rExt._brokerTrip
              ? oc(rExt)._brokerTrip.to.geodata.location()
              : rExt._destObj
                ? rExt._destObj.address
                : r.d === 'RESIDENCE'
                  ? null
                  : r.d === 'FACILITY'
                    ? null
                    : r.d;

            const doName = rExt._brokerTrip
              ? rExt._brokerTrip.to.name || rExt._brokerTrip.to.street
              : rExt._destObj
                ? rExt._destObj.name || rExt._destObj.address
                : r.d;

            rExt._markerFrom = rExt._brokerTrip
              ? {
                  location: puLocation,
                  tooltip: `${personName}<br/><strong>Origin</strong><br/>${puName}`,
                }
              : null;

            rExt._markerTo = rExt._brokerTrip
              ? {
                  location: doLocation,
                  tooltip: `${personName}<br/><strong>Destination</strong><br/>${doName}`,
                }
              : null;

            return rExt;
          });
        }),
        map(recs => [
          compact(flatten(recs.map(r => [oc(r)._markerFrom(), oc(r)._markerTo()]))),
          recs.map(r => ({ locations: [oc(r)._markerFrom.location(), oc(r)._markerTo.location()] })),
        ]),
        tap(([markers, routes]) => {
          this.markers = markers.filter(m => oc(m).location());
          this.routes = routes.filter(r => oc(r).locations([]).length);
          this.loading = false;
          console.log(markers, routes);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  map_onInitialized(e) {}
}
