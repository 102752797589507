import { Component, Inject, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { intersection, sortBy } from 'lodash-es';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { dxStoreLoadHooks } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { Employee, EmployeeApi, LoopBackAuth } from 'src/app/shared/sdk';
import { getUser } from 'src/app/store/reducers/sign';
import { AnyNote, LoggerService, MyUser, MyUserApi } from '../../../../sdk';
import { CommonService } from '../../../my-common/services/common.service';
import { ConfigService } from '../../../my-common/services/config.service';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { FullNamePipe } from '../../pipes/full-name.pipe';
import { UiService } from '../../services/ui.service';
import { AnyNoteFormComponent } from './any-note-form/any-note-form.component';

@Component({
  selector: 'app-any-notes-page',
  templateUrl: './any-notes-page.component.html',
  styleUrls: ['./any-notes-page.component.scss'],
  providers: [],
})
export class AnyNotesPageComponent extends ABaseComponent {
  _filter: any;
  @Input()
  set filter(filter: any) {
    if (filter) this.dso = this.buildDSO(filter);
    this._filter = filter;
  }
  @Input() objectType: string;
  @Input() objectId: number;
  @Input() extraFields: any = {};
  @Input() templateFormTop?: TemplateRef<any>;
  @Input() templateFormBottom?: TemplateRef<any>;
  @Input() templateNote?: TemplateRef<any>;
  @Input() subTitle: string = '';

  dso: DataSourceOptions;
  selectedNote: any;
  documentName: string = '';
  user: MyUser;
  notificationGroupUserList: string = '';
  signedUser$: Observable<MyUser>;
  myRoles$: Observable<string[]>;
  allowDeleting$: Observable<boolean>;

  tabs: any[] = [
    { title: 'Note', template: 'note' },
    { title: 'Comments', template: 'comments' },
    { title: 'Docs', template: 'docs' },
  ];

  constructor(
    private common: CommonService,
    private userApi: MyUserApi,
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    public config: ConfigService,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger);
    this.user = this.auth.getCurrentUserData();
    this.signedUser$ = this.common.store.pipe(select(getUser));
    this.myRoles$ = this.config.roles$;
    this.allowDeleting$ = this.myRoles$.pipe(switchMap(roles => of(!!intersection(roles, ['SU', 'ADMIN']).length)));
  }

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift({
      name: 'addNote',
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'before',
      sortIndex: 30,
      options: {
        icon: 'plus',
        text: 'Add Note',
        hint: 'Add Note',
        onClick: () => {
          if (!this._filter) return;
          this.ui
            .openEditDialog({
              modelId: null,
              title: 'Add Note' + this.subTitle,
              inputs: {
                fields: { ...this._filter, ...this.extraFields, createdById: this.user.id },
                templateFormTop: this.templateFormTop,
                templateFormBottom: this.templateFormBottom,
                dso: this.dso,
              },
              ModelClass: AnyNote,
              FormComponentClass: AnyNoteFormComponent,
            })
            .afterClosed()
            .toPromise()
            .then(note => {
              this.dso = this.buildDSO(this._filter);
            });
        },
      },
    });

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  async grid_onSelectionChanged(e) {
    this.selectedNote = e.selectedRowsData[0];
    this.documentName = `${this.noteFormat(this.selectedNote).slice(0, 34)} | document`;
    this.notificationGroupUserList = '';
    if (this.selectedNote.notificationGroupUserIds && this.selectedNote.notificationGroupUserIds.length) {
      const users = await this.dss
        .getApi<MyUserApi>(MyUser)
        .find<MyUser>({ where: { id: { inq: this.selectedNote.notificationGroupUserIds } } })
        .toPromise();
      this.notificationGroupUserList = users.map(u => u.username).join(', ');
    }
  }

  noteFormat(note) {
    return (note && `${moment(note.updatedAt).format('M/D/YYYY, h:mm A')} | ${note.subject}`) || '';
  }

  onEditNote() {
    this.ui
      .openEditDialog({
        modelId: this.selectedNote.id,
        title: 'Edit Note' + this.subTitle,
        inputs: {
          fields: { ...this._filter, ...this.extraFields, createdById: this.user.id },
          templateFormTop: this.templateFormTop,
          templateFormBottom: this.templateFormBottom,
          dso: this.dso,
        },
        ModelClass: AnyNote,
        FormComponentClass: AnyNoteFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(note => {
        this.selectedNote = { ...this.selectedNote, ...note };
        this.dso = this.buildDSO(this._filter);
      });
  }

  async addComment(comment) {
    if (!comment.value) return;
    const store = this.dss.getStore(AnyNote);
    const c = { createdAt: Date.now(), commentBy: this.user.username, comment: comment.value };
    this.selectedNote.comments = [c, ...(this.selectedNote.comments || [])];
    await store.update(this.selectedNote.id, { comments: this.selectedNote.comments });
    comment.value = '';
  }

  private buildDSO(filter: any) {
    const so = this.dss.getStoreOptions(AnyNote, null, false);
    so.customFilter = {
      where: filter,
      include: [
        'facility',
        'vehicle',
        'createdBy',
        'relatedNote',
        'resolutionManager',
        'autoRepairShop',
        'assignedFleetManager',
      ],
      order: 'updatedAt DESC',
    };
    const store = new CustomStore(so);
    dxStoreLoadHooks(store, undefined, async (args: any[], [notes]: any[]) => {
      const empls = await this.dss
        .getApi<EmployeeApi>(Employee)
        .find<Employee>({ where: { userId: { inq: notes.map(n => n.createdById) } }, include: ['person'] })
        .toPromise();
      const emplMap = empls.reduce((p, v) => ({ ...p, [v.userId]: new FullNamePipe(this.config).transform(v) }), {});
      return [notes.map(n => ({ ...n, fullName: emplMap[n.createdById] || n.createdBy.username }))];
    });
    return { store } as DataSourceOptions;
  }
}
