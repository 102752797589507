import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { defer } from 'rxjs';
import { from } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { share } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { ABaseComponent } from './a-base.component';
export var FORM_STATE;
(function (FORM_STATE) {
    FORM_STATE[FORM_STATE["NORMAL"] = 0] = "NORMAL";
    FORM_STATE[FORM_STATE["COLLAPSED"] = 1] = "COLLAPSED";
    FORM_STATE[FORM_STATE["FULL"] = 2] = "FULL";
    FORM_STATE[FORM_STATE["DISABLED"] = 3] = "DISABLED";
    FORM_STATE[FORM_STATE["SAVING"] = 4] = "SAVING";
    FORM_STATE[FORM_STATE["LOADING"] = 5] = "LOADING";
    FORM_STATE[FORM_STATE["ERROR"] = 6] = "ERROR";
})(FORM_STATE || (FORM_STATE = {}));
export class ABaseModelLoaderComponent extends ABaseComponent {
    constructor(logger, dss) {
        super(logger);
        this.logger = logger;
        this.dss = dss;
        //
        this.error = null;
        this.modelLoadingError = new EventEmitter();
        //
        this.loadingChange = new EventEmitter();
        this.modelIdChange = new EventEmitter();
        //
        this.beforeLoading = new EventEmitter();
        this.loaded = new EventEmitter();
        this.afterLoaded = new EventEmitter();
        //
        this.$modelId$ = new BehaviorSubject(null);
        this.$model$ = new BehaviorSubject(null);
        this.model$ = new Observable();
        this._state = new Set();
        this._loading = false;
        this.setState(FORM_STATE.NORMAL);
        this.buildObservables();
        this.model$.pipe(takeUntil(this.$onDestroy$)).subscribe();
    }
    get stateNormal() {
        return this._state.has(FORM_STATE.NORMAL);
    }
    get stateCollapsed() {
        return this._state.has(FORM_STATE.COLLAPSED);
    }
    get stateFull() {
        return this._state.has(FORM_STATE.FULL);
    }
    get stateDisabled() {
        return this._state.has(FORM_STATE.DISABLED);
    }
    get stateLoading() {
        return this._state.has(FORM_STATE.LOADING);
    }
    get stateSaving() {
        return this._state.has(FORM_STATE.SAVING);
    }
    get stateError() {
        return this._state.has(FORM_STATE.ERROR);
    }
    get loading() {
        return this._loading;
    }
    set loading(value) {
        if (value !== this._loading) {
            this._loading = value;
            this.loadingChange.emit(value);
        }
    }
    get modelId() {
        return this._modelId;
    }
    set modelId(value) {
        if (value !== this._modelId) {
            this._modelId = value;
            this.$modelId$.next(value);
            this.modelIdChange.emit(value);
        }
    }
    get errorMessage() {
        return this.error && this.error.message ? this.error.message : this.error;
    }
    get filter() {
        return {};
    }
    customHeaders(headers) {
        return headers;
    }
    get observeModels() {
        return [this.ModelClass];
    }
    get api() {
        return this.dss.getApi(this.ModelClass);
    }
    get store() {
        return this.dss.getStore(this.ModelClass);
    }
    ngOnChanges(changes) {
        // throw new Error('Method not implemented.');
    }
    ngOnInit() {
        super.ngOnInit();
        this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
            if (this.observeModels.map(model => model.getModelName()).includes(modelName)) {
                this.refresh();
            }
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.$modelId$.unsubscribe();
        this.$model$.unsubscribe();
    }
    setState(state) {
        const groups = [
            [FORM_STATE.ERROR, FORM_STATE.NORMAL],
            [FORM_STATE.COLLAPSED, FORM_STATE.FULL],
        ];
        groups.filter(grp => grp.includes(state)).forEach(grp => grp.forEach(s => this.unsetState(s)));
        this._state.add(state);
    }
    unsetState(state) {
        this._state.delete(state);
    }
    refresh() {
        this.$modelId$.next(this._modelId);
    }
    buildObservables() {
        const prepareObserver = {
            next: (id) => {
                this.error = null;
                this.loading = true;
                this.beforeLoading.emit(id);
            },
        };
        const finallyObserver = {
            next: (model) => {
                this.loading = false;
                this.afterLoaded.emit(model);
            },
        };
        const handleError = (err) => {
            this.error = err;
            this.modelLoadingError.emit(err);
            return of(null);
        };
        const beforeLoadingFn$ = (id) => {
            // this.onModelLoading(id);
            return from(this.beforeModelLoadingAsync(id)).pipe(map(() => id), catchError(handleError));
        };
        const afterLoadedFn$ = (model) => {
            // this.onModelLoaded(model);
            return from(this.afterModelLoadedAsync(model)).pipe(map(() => model), catchError(handleError));
        };
        const loadModelFn$ = (id) => id
            ? this.api.findById(id, this.filter, this.customHeaders).pipe(
            // tap(() => console.log('loadModel')),
            map(model => this.dss.models.fixModelNestedTypes(model)), 
            // tap(console.log),
            catchError(handleError))
            : of(null);
        const processLoadingFn$ = (modelId) => defer(() => of(modelId).pipe(
        // prepare
        tap(prepareObserver), switchMap(beforeLoadingFn$), 
        //
        switchMap(loadModelFn$), tap((model) => {
            this.model = model;
            this.$model$.next(model);
            this.loaded.emit(model);
        }), 
        // finally
        tap(finallyObserver), switchMap(afterLoadedFn$)));
        this.model$ = this.$modelId$.asObservable().pipe(tap((id) => (this._modelId = id)), switchMap(processLoadingFn$), share());
    }
    beforeModelLoadingAsync(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('onModelLoadingAsync: ', id);
        });
    }
    afterModelLoadedAsync(model) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('onModelLoadedAsync: ', model);
        });
    }
}
