<!--<as-split direction="horizontal">-->
<!--  <as-split-area [size]="60">-->
<!--    <app-vehicle-list (mySelected)="list_onSelected($event)"></app-vehicle-list>-->
<!--  </as-split-area>-->

<!--  <as-split-area [size]="40">-->
<!--    <app-vehicle-details [modelId]="selectedId$ | async"></app-vehicle-details>-->
<!--  </as-split-area>-->
<!--</as-split>-->

<kendo-splitter>
  <kendo-splitter-pane [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-vehicle-list
      (mySelected)="list_onSelected($event)"
      (markers)="list_markers($event)"
      [templateFormTop]="templateFormTop"
      [templateFormBottom]="templateFormBottom"
      [extraFields]="extraFields"></app-vehicle-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane size="40%" [collapsible]="true" [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-vehicle-details-tabs
      [vehicle]="selected$ | async"
      [markers]="markers$ | async"
      [templateFormTop]="templateFormTop"
      [templateFormBottom]="templateFormBottom"
      [extraFields]="extraFields"></app-vehicle-details-tabs>
  </kendo-splitter-pane>
</kendo-splitter>

<ng-template #templateFormTop let-form="form">
  <div [formGroup]="form">
    <div class="dx-field">
      <label class="_dx-field-label">Note Category:</label>
      <div class="_dx-field-value">
        <dx-select-box
          #noteCategory
          formControlName="noteCategory"
          [dataSource]="[
            'Maintenance/Repair Note',
            'Accident/Incident Related Note',
            'Generic Vehicle Note'
          ]"></dx-select-box>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateFormBottom let-form="form">
  <div [formGroup]="form">
    <ng-container *ngIf="form.value.noteCategory === 'Maintenance/Repair Note'">
      <div class="dx-field">
        <label class="_dx-field-label">Service Type:</label>
        <div class="_dx-field-value">
          <dx-select-box formControlName="serviceType" [dataSource]="['Maintenance', 'Repair']"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Date Off Duty:</label>
        <div class="_dx-field-value"><dx-date-box formControlName="dateOffDuty"></dx-date-box></div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Date Returned On Duty:</label>
        <div class="_dx-field-value"><dx-date-box formControlName="dateReturnedOnDuty"></dx-date-box></div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">AutoRepair Shop:</label>
        <div class="_dx-field-value">
          <dx-select-box
            formControlName="autoRepairShopId"
            [dataSource]="autoRepairShopDso$ | async"
            [displayExpr]="autoRepairShop_displayExpr"
            valueExpr="id"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Assigned Fleet Manager:</label>
        <div class="_dx-field-value">
          <dx-select-box
            formControlName="assignedFleetManagerId"
            [dataSource]="assignedFleetManagerDso$ | async"
            [displayExpr]="assignedFleetManager_displayExpr"
            valueExpr="id"></dx-select-box>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

