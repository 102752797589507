import {
  Employee,
  MyUser
} from '../index';

declare var Object: any;
export interface EmployeeDayServiceInterface {
  "requestID": string;
  "dateTime"?: Date;
  "date"?: any;
  "timeInt"?: number;
  "marker"?: string;
  "flag"?: boolean;
  "value"?: string;
  "meta"?: any;
  "id"?: number;
  "employeeId"?: number;
  "userId"?: number;
  employee?: Employee;
  user?: MyUser;
}

export class EmployeeDayService implements EmployeeDayServiceInterface {
  "requestID": string;
  "dateTime": Date;
  "date": any;
  "timeInt": number;
  "marker": string;
  "flag": boolean;
  "value": string;
  "meta": any;
  "id": number;
  "employeeId": number;
  "userId": number;
  employee: Employee;
  user: MyUser;
  constructor(data?: EmployeeDayServiceInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeeDayService`.
   */
  public static getModelName() {
    return "EmployeeDayService";
  }

  /**
  * This method creates an instance of EmployeeDayService for dynamic purposes.
  **/
  public static factory(data: EmployeeDayServiceInterface): EmployeeDayService{
    return new EmployeeDayService(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeeDayService',
      plural: 'EmployeeDayServices',
      path: 'EmployeeDayServices',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "date": {
          name: 'date',
          type: 'any'
        },
        "timeInt": {
          name: 'timeInt',
          type: 'number'
        },
        "marker": {
          name: 'marker',
          type: 'string'
        },
        "flag": {
          name: 'flag',
          type: 'boolean'
        },
        "value": {
          name: 'value',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
      }
    }
  }
}
