import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { Employee, VehicleGeotabEvent } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import moment from 'moment';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import CustomStore from 'devextreme/data/custom_store';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';
export class EmployeeDetailsTabEventsComponent extends ABaseModelPaneWToolbarComponent {
    constructor(logger, ui, dss, dialog, helper, docHelper) {
        super(logger, ui, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.helper = helper;
        this.docHelper = docHelper;
        this.dso$ = of([]);
        this.getGeoTabLocationCellValue = (v) => `${v.latitude},${v.longitude}`;
        this.getMapURL = (location) => `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
        this.getDetailsCellValue = (v) => {
            if (v.rule == 'Idling')
                return 'IDLE for more than 30 minutes';
            return v.diagnostic || `Speed:${v.speed}, Limit:${v.speedLimit}`;
        };
        this.showLoop_onClick = (v) => {
            this.dialog.open(ShowLoopDialogComponent, {
                panelClass: 'loop-dialog',
                backdropClass: 'loop-dialog',
                width: '80%',
                height: '80%',
                hasBackdrop: true,
                data: {
                    vin: v.vin,
                    date: v.loop.startDate,
                    loopId: v.loop.loopId,
                    vehicle: {
                        text: `Time: ${moment(v.dateTime).format('lll')}<br/>${v.rule}<br/>${this.getDetailsCellValue(v)}<br/>`,
                        latitude: v.latitude,
                        longitude: v.longitude,
                        icon: 'marker-current.png',
                    },
                    title: `${v.tenant.name} ${v.rule} ${moment(v.dateTime).format('M/D/YYYY h:mm A')}`,
                },
            });
        };
        this.caption = 'GeoTab Events';
    }
    get ModelClass() {
        return Employee;
    }
    get FormComponent() {
        return EmployeeFormComponent;
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.dso$ = this.buildDataSource(model);
        });
    }
    buildDataSource(model) {
        const so = this.dss.getStoreOptions(VehicleGeotabEvent, undefined, false);
        so.customHeaders = headersAllTenantsAppend;
        so.customFilter = { where: { employeeId: model.id }, include: ['tenant'] };
        const dso = { store: new CustomStore(so) };
        return of(dso);
    }
}
