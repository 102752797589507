import * as tslib_1 from "tslib";
import { AfterViewInit, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DxLookupComponent } from 'devextreme-angular/ui/lookup';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
//
import { Consumer, DocumentType, Employee, Facility, LoggerService, MyUserApi, Person, Vehicle, } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { getFQNByModelName, getModelByFQN } from '../../../../shared/modules/ui/components/custom-fields/utils';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';
import { ABaseDocumentFormComponentWithCustomsFields } from '../../classes/a-base-document-form-with-cf.component';
import { HelperService } from '../../services/helper.service';
import { ObjectTitleComponent } from 'src/app/shared/modules/ui/components/object-title/object-title.component';
export class DocumentFormComponent extends ABaseDocumentFormComponentWithCustomsFields {
    constructor(logger, fb, uploadHelper, dss, helper, docHelper, myUserApi) {
        super(logger, fb, uploadHelper, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.uploadHelper = uploadHelper;
        this.dss = dss;
        this.helper = helper;
        this.docHelper = docHelper;
        this.myUserApi = myUserApi;
        this.documentTypeName = '';
        this.objectName = '';
        this.buildDataSources();
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.objectTitle)
            this.objectTitle.objectTitle$.subscribe(title => {
                if (title && title !== '...') {
                    this.objectName = title;
                    this.setName();
                }
            });
    }
    get objectId() {
        return this._objectId;
    }
    set objectId(value) {
        this._objectId = value;
        this.form && this.form.get('objectId').reset(value);
    }
    get objectType() {
        return this._objectType;
    }
    set objectType(value) {
        this._objectType = value;
        this.form && this.form.get('objectType').reset(value);
        this.buildDataSources();
    }
    get documentTypeId() {
        return this._documentTypeId;
    }
    set documentTypeId(value) {
        this._documentTypeId = value;
        this.form && this.form.get('documentTypeId').reset(value);
    }
    set documentName(value) {
        this._documentName = value;
        this.setName();
    }
    get objectLookupTitle() {
        return 'Select ' + this.form.get('objectType').value;
    }
    get dateFields() {
        return [];
    }
    onDocTypeSelectionChanged(e) {
        const objectType = e.selectedItem ? getModelByFQN(e.selectedItem.objectFQN).getModelName() : null;
        if (e.selectedItem && objectType !== this.form.get('objectType').value) {
            this.form.get('objectId').reset();
        }
        this.form.get('objectType').setValue(objectType);
        // console.log(e.selectedItem);
        if (e.selectedItem) {
            this.documentTypeName = e.selectedItem.name;
            this.setName();
            this.form.get('notes').setValue(e.selectedItem.defaultNotes);
        }
        this.buildObjectDataSource(this.form.get('objectType').value, this.form.get('objectId').value);
    }
    setName() {
        if (this._documentName || (this.objectName && this.documentTypeName)) {
            const val = this._documentName ||
                `${this.objectName} - ${this.documentTypeName} - UPL ${moment().format('YYYYMMDDHHmmss')}`;
            this.form.get('name').setValue(val);
        }
    }
    setFormValues(model, isReset = true, resetValidators = true) {
        super.setFormValues(model, isReset, resetValidators);
        if (this.form && !model) {
            this.form.get('objectId').reset(this.objectId);
            this.form.get('objectType').reset(this.objectType);
        }
    }
    processFormValueAsync(data) {
        const _super = Object.create(null, {
            processFormValueAsync: { get: () => super.processFormValueAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            data = yield _super.processFormValueAsync.call(this, data);
            const newData = Object.assign({}, data);
            // TODO: set owner on server
            if (!newData.ownerId) {
                newData.ownerId = Number(this.myUserApi.getCurrentToken().userId);
            }
            return newData;
        });
    }
    buildDataSources() {
        const dso = {
            store: this.dss.getStore(DocumentType, null, false),
            filter: this.objectType
                ? [
                    ['objectFQN', '=', getFQNByModelName(this.objectType)],
                    ['inactive', '<>', true],
                ]
                : ['inactive', '<>', true],
            sort: [
                { selector: 'objectFQN', desc: true },
                { selector: 'name', desc: true },
            ],
            group: 'objectFQN',
            postProcess: this.docHelper.docTypeDSGrouping,
        };
        this.documentTypeDataSource = this.dss.getDataSourceFromDSO(dso);
        this.documentTypeDisplayExpr = (e) => (e ? `${e.name}` : '');
        this.documentTypeSearchExpr = ['name', 'objectFQN'];
    }
    buildObjectDataSource(objectType, objectId) {
        const isNew = !this.objectDS;
        this.form.get('objectId').enable();
        this.objectLookup && this.objectLookup.instance && this.objectLookup.instance.beginUpdate();
        switch (objectType) {
            case Facility.getModelName():
                this.objectDS = this.dss.getDataSource(Facility);
                this.objectSearchExpr = ['name', 'shortname'];
                this.objectDisplayExpr = (o) => (o ? `[${o.shortname}] ${o.name}` : '');
                break;
            case Employee.getModelName():
                this.objectDS = this.dss.getDataSource(Employee);
                this.objectSearchExpr = ['person_firstname', 'person_lastname'];
                this.objectDisplayExpr = (o) => o ? `[${o.employeePosition_name}] ${o.person_firstname} ${o.person_lastname}` : '';
                break;
            case Vehicle.getModelName():
                this.objectDS = this.dss.getDataSource(Vehicle);
                this.objectSearchExpr = ['internalId'];
                this.objectDisplayExpr = (o) => (o ? `#${o.internalId}` : '');
                break;
            case Consumer.getModelName():
                this.objectDS = this.dss.getDataSource(Consumer);
                this.objectSearchExpr = ['person_firstname', 'person_lastname'];
                this.objectDisplayExpr = (o) => (o ? `${o.person_firstname} ${o.person_lastname}` : '');
                break;
            case Person.getModelName():
                this.objectDS = this.dss.getDataSource(Person);
                this.objectSearchExpr = ['firstname', 'lastname'];
                this.objectDisplayExpr = (o) => (o ? `${o.firstname} ${o.lastname}` : '');
                break;
            default:
                this.form.get('objectId').disable();
                this.objectDS = new DataSource([]);
                this.objectSearchExpr = '';
                this.objectDisplayExpr = '';
        }
        if (this.objectLookup && this.objectLookup.instance) {
            // if (!isNew) this.objectLookup.instance.reset();
        }
        this.objectLookup && this.objectLookup.instance && this.objectLookup.instance.endUpdate();
    }
}
