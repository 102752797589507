<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Person Info</div>

    <div class="dx-field">
      <div class="dx-field-label">First Name *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="firstname">
          <dx-validator name="First Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Last Name *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="lastname">
          <dx-validator name="Last Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <ng-container *ngIf="stateFull">
      <div class="dx-field">
        <div class="dx-field-label">Middle Name</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="middlename"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Nickname</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="nickname"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Sex</div>
        <div class="dx-field-value">
          <dx-select-box
            [dataSource]="personSexesDS"
            displayExpr="Name"
            valueExpr="ID"
            formControlName="sex"></dx-select-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">DOB</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="dob" applyValueMode="useButtons"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value">
          <dx-text-area formControlName="notes"></dx-text-area>
        </div>
      </div>

      <!-- /// -->

      <div class="dx-fieldset-header">Other Info</div>

      <ng-container formGroupName="data">
        <div class="dx-field">
          <div class="dx-field-value">
            <div>
              <dx-check-box formControlName="er" text="Emergency Contact"></dx-check-box>
            </div>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Title</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="title"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Native Language</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="nativeLang"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Native Language Name</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="nativeLangName"></dx-text-box>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!--<mat-divider></mat-divider>-->

    <ng-container formGroupName="contact">
      <!--<mat-divider></mat-divider>-->

      <!--<div class="dx-fieldset-header">Contact Info</div>

      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value">
          <dx-text-area formControlName="notes"></dx-text-area>
        </div>
      </div>-->

      <ng-container formArrayName="phones">
        <br />

        <app-form-array-group
          entityName="phone"
          icon="phone"
          [formArray]="phonesFormArray"
          (addItem)="addFormArrayItem('contact.phones')"
          (removeItem)="removeFormArrayItem('contact.phones', $event)"></app-form-array-group>
      </ng-container>

      <!--<mat-divider></mat-divider>-->

      <ng-container formArrayName="emails">
        <br />

        <app-form-array-group
          entityName="email"
          icon="email"
          [formArray]="emailsFormArray"
          (addItem)="addFormArrayItem('contact.emails')"
          (removeItem)="removeFormArrayItem('contact.emails', $event)"></app-form-array-group>
      </ng-container>

      <!--<mat-divider></mat-divider>-->

      <ng-container formArrayName="addresses">
        <br />

        <app-form-array-group
          entityName="place"
          icon="place"
          [formArray]="addressesFormArray"
          (addItem)="addFormArrayItem('contact.addresses')"
          (removeItem)="removeFormArrayItem('contact.addresses', $event)"></app-form-array-group>
      </ng-container>
    </ng-container>

    <!-- /// -->
    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <!--    <p class="alert-danger" *ngIf="errorMessage">-->
    <!--      {{ errorMessage }}-->
    <!--    </p>-->
  </div>
</dx-validation-group>
