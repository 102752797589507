import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-offline-overlay',
  templateUrl: './offline-overlay.component.html',
  styleUrls: ['./offline-overlay.component.scss'],
  animations: [
    trigger('visible', [
      transition('void => *', [style({ opacity: 1 }), animate('100ms linear')]),
      transition('* => void', [style({ opacity: 0 }), animate('100ms linear')]),
    ]),
  ],
})
export class OfflineOverlayComponent {}
