import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi, TCustomHeaders, TCustomOptions } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../models/AuthService';


/**
 * Api services for the `AuthService` model.
 */
@Injectable()
export class AuthServiceApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Assign system consumer to the auth one
   *
   * @param {object} data Request data.
   *
   *  - `broker` – `{string}` - 
   *
   *  - `memberId` – `{string}` - 
   *
   *  - `tenantId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public assignSystemClientToAuthOne(broker: any, memberId: any, tenantId: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/assignSystemClientToAuthOne";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof broker !== 'undefined' && broker !== null) _urlParams.broker = broker;
    if (typeof memberId !== 'undefined' && memberId !== null) _urlParams.memberId = memberId;
    if (typeof tenantId !== 'undefined' && tenantId !== null) _urlParams.tenantId = tenantId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Save Authorization
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public saveAuthorization(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/saveAuthorization";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete Authorization
   *
   * @param {string} authId 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public dropAuthorization(authId: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/dropAuthorization";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof authId !== 'undefined' && authId !== null) _urlParams.authId = authId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get Rate
   *
   * @param {string} date 
   *
   * @param {string} code 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public getRate(date: any, code: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/getRate";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    if (typeof code !== 'undefined' && code !== null) _urlParams.code = code;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Filter Auths
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public filterAuths(auths: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/filterAuths";
    let _routeParams: any = {};
    let _postBody: any = {
      auths: auths
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Submit Authorization
   *
   * @param {boolean} validate 
   *
   * @param {string} mode 
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public submitClaim(data: any, validate: any = {}, mode: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/submitClaim";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof validate !== 'undefined' && validate !== null) _urlParams.validate = validate;
    if (typeof mode !== 'undefined' && mode !== null) _urlParams.mode = mode;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update Auth Member Ds
   *
   * @param {object} data Request data.
   *
   *  - `yyyymm` – `{number}` - 
   *
   *  - `codes` – `{any}` - 
   *
   *  - `claimId` – `{string}` - 
   *
   *  - `dsCollection` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AuthService` object.)
   * </em>
   */
  public updateAuthMemberDs(yyyymm: any, codes: any, claimId: any, dsCollection: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/AuthService/updateAuthMemberDs";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        yyyymm: yyyymm,
        codes: codes,
        claimId: claimId,
        dsCollection: dsCollection
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AuthService`.
   */
  public getModelName() {
    return "AuthService";
  }
}
