import { Pipe, PipeTransform } from '@angular/core';
import trim from 'lodash-es/trim';

@Pipe({
  name: 'trimArr',
})
export class TrimArrPipe implements PipeTransform {
  transform(values: string[]): string[] {
    return values.map(item => trim(item));
  }
}
