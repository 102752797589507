<div mat-dialog-title *ngIf="data?.title">{{ data?.title }}</div>

<div mat-dialog-content>
  <dx-data-grid
    #grid
    height="400"
    [dataSource]="ds"
    [columns]="gridColumns"
    [remoteOperations]="true"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [selection]="gridSelection"
    [paging]="gridPaging"
    [filterRow]="gridFilterRow"
    [scrolling]="gridScrolling"></dx-data-grid>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    [disabled]="grid?.selectedRowKeys?.length === 0"
    [mat-dialog-close]="grid?.selectedRowKeys"
    cdkFocusInitial>
    Assign
  </button>
</div>
