<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Body Type</div>
        <div class="dx-field-value-static">
          {{ model?.type }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Has Lift/Ramp</div>
        <div class="dx-field-value-static">
          {{ model?.hasLiftRamp === 'None' ? 'No' : model?.hasLiftRamp }}
        </div>
      </div>

      <div class="dx-field" style="flex: auto">
        <div class="dx-field-label" title="Wheelchair Accessible">Wheelchair Accessible</div>
        <div class="dx-field-value-static">
          {{ model?.wheelchairAccessible | yesNo }}
        </div>
      </div>

      <div class="dx-field" style="flex: auto">
        <div class="dx-field-label" title="Stretcher Accessible">Stretcher Accessible</div>
        <div class="dx-field-value-static">
          {{ model?.stretcherAccessible | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">CDL</div>
        <div class="dx-field-value-static">
          {{ model?.cdl | yesNo }}
        </div>
      </div>

      <div class="dx-field" style="flex: auto">
        <div class="dx-field-label" title="Passenger Capacity">Passenger Capacity</div>
        <div class="dx-field-value-static">
          {{ model?.passengerCapacity }}
        </div>
      </div>

      <div class="dx-field" style="flex: auto">
        <div class="dx-field-label" title="Wheelchair Capacity">Wheelchair Capacity</div>
        <div class="dx-field-value-static">
          {{ model?.wheelchairCapacity }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Possible Configs</div>
        <div class="dx-field-value-static">
          {{ model?.possibleConfigs }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Emergency Exit Door</div>
        <div class="dx-field-value-static">
          {{ model?.emergencyExitDoor | yesNo }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Fuel</div>
        <div class="dx-field-value-static">
          {{ model?.fuel }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Engine Type</div>
        <div class="dx-field-value-static">
          {{ model?.engineType }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Tire Size</div>
        <div class="dx-field-value-static">
          {{ model?.tireSize }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Body Company</div>
        <div class="dx-field-value-static">
          {{ model?.bodyCompany }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Gross Weight</div>
        <div class="dx-field-value-static">
          {{ model?.grossWeight }}
        </div>
      </div>
    </div>
  </ng-container>
</app-details-tab>
