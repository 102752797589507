<div mat-dialog-content>
  <mat-form-field>
    <input matInput type="number" placeholder="Trip Number" [(ngModel)]="tripNumber" cdkFocusInitial />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="tripNumber">Set</button>
</div>
