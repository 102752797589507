import { environment } from '../../../../environments/environment';

export const REASSIGN_MODE = {
  ALL: 'ALL',
  SELECTED: 'SELECTED',
  FROM_EMPLOYEE: 'FROM_EMPLOYEE',
  SET_TRIP_NUM: 'SET_TRIP_NUM',
};

export const NOTIFY_MODE = {
  ALL: 'ALL',
  SELECTED: 'SELECTED',
  FROM_EMPLOYEE: 'FROM_EMPLOYEE',
};

export const PRINT_MODE = {
  PRINT_ATTENDANCE_ALL: 'PRINT_ATTENDANCE_ALL',
  PRINT_ATTENDANCE_BY_DRIVER: 'PRINT_ATTENDANCE_BY_DRIVER',

  ATTENDANCE: 'ATTENDANCE',
  DISPATCHER_LOG: 'DISPATCHER_LOG',
  ROUTE_ADHERENCE_LOG: 'ROUTE_ADHERENCE_LOG',
  //
  MANIFEST: 'MANIFEST',
  MANIFEST_PER_DRIVER: 'MANIFEST_PER_DRIVER',
  //
  MERCY: 'MERCY',
  MERCY_PER_DRIVER: 'MERCY_PER_DRIVER',
  //
  MERCY_SIGN_IN_FORM: 'MERCY_SIGN_IN_FORM',
  MERCY_SIGN_IN_FORM_PER_DRIVER: 'MERCY_SIGN_IN_FORM_PER_DRIVER',
  MERCY_SIGN_IN_FORM_OVERALL: 'MERCY_SIGN_IN_FORM_OVERALL',
  MERCY_SIGN_IN_FORM_NATIVE: 'MERCY_SIGN_IN_FORM_NATIVE',

  MERCY_SIGN_IN_FORM_WO_TIMES: 'MERCY_SIGN_IN_FORM_WO_TIMES',
  MERCY_SIGN_IN_FORM_WO_CALC_TIMES: 'MERCY_SIGN_IN_FORM_WO_CALC_TIMES',
  MERCY_SIGN_IN_FORM_PER_DRIVER_WO_CALC_TIMES: 'MERCY_SIGN_IN_FORM_PER_DRIVER_WO_CALC_TIMES',

  MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE: 'MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE',
  MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED: 'MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED',
  //
  VEHICLE_INSPECTION_SHEET: 'VEHICLE_INSPECTION_SHEET',
  //
  DRIVERS_DAILY_BUNDLE: 'DRIVERS_DAILY_BUNDLE',

  MERCY_PER_DRIVER_WITH_DROP_OFF: 'MERCY_PER_DRIVER_WITH_DROP_OFF',
  MEALS_DELIVERY_SIGNATURE: 'MEALS_DELIVERY_SIGNATURE',
  MEALS_DELIVERY_SIGNATURE_NEW: 'MEALS_DELIVERY_SIGNATURE_NEW',
};

export const WEEKDAYS: any[] = [
  { ID: 'MONDAY', Name: 'MONDAY' },
  { ID: 'TUESDAY', Name: 'TUESDAY' },
  { ID: 'WEDNESDAY', Name: 'WEDNESDAY' },
  { ID: 'THURSDAY', Name: 'THURSDAY' },
  { ID: 'FRIDAY', Name: 'FRIDAY' },
  { ID: 'SATURDAY', Name: 'SATURDAY' },
  { ID: 'SUNDAY', Name: 'SUNDAY' },
];

export const DESTINATIONS: any[] = [
  { ID: 'RESIDENCE', Name: 'RESIDENCE', Group: 'FACILITY' },
  { ID: 'FACILITY', Name: 'FACILITY', Group: 'FACILITY' },
];

export const DESTINATIONS_FOR_BASE: any[] = [{ ID: 'RESIDENCE', Name: 'RESIDENCE', Group: 'FACILITY' }];

export const SERVICE_TYPE = {
  PARATRANSIT: 'PARATRANSIT',
  MEALS: 'MEALS',
  ADC_TRIP: 'ADC_TRIP',
  APPT_TRIP: 'APPT_TRIP',
  EVENT_TRIP: 'EVENT_TRIP',
  SCHOOL: 'SCHOOL',
  // ...(hasAmbTrips() ? { AMB_TRIP: 'AMB_TRIP' } : {}),
};

export const DIRECTION_MARKERS = {
  TO_ADC: 'TO_ADC',
  FROM_ADC: 'FROM_ADC',
  TO_APPT: 'TO_APPT',
  FROM_APPT: 'FROM_APPT',
  TO_EVENT: 'TO_EVENT',
  FROM_EVENT: 'FROM_EVENT',
  TO_MEALS: 'TO_MEALS',
  FROM_MEALS: 'FROM_MEALS',
  TO_SCHOOL: 'TO_SCHOOL',
  FROM_SCHOOL: 'FROM_SCHOOL',
  // ...(hasAmbTrips() ? { TO_AMB: 'TO_AMB', FROM_AMB: 'FROM_AMB' } : {}),
  UNKNOWN: 'UNKNOWN',
};
