/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-utilisation2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/vehicle-list/vehicle-list.component.ngfactory";
import * as i3 from "../../components/vehicle-list/vehicle-list.component";
import * as i4 from "./vehicle-utilisation2.component";
var styles_VehicleUtilisation2Component = [i0.styles];
var RenderType_VehicleUtilisation2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleUtilisation2Component, data: {} });
export { RenderType_VehicleUtilisation2Component as RenderType_VehicleUtilisation2Component };
export function View_VehicleUtilisation2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-list", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.list_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VehicleListComponent_0, i2.RenderType_VehicleListComponent)), i1.ɵdid(1, 114688, null, 0, i3.VehicleListComponent, [], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_VehicleUtilisation2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-utilisation2", [], null, null, null, View_VehicleUtilisation2Component_0, RenderType_VehicleUtilisation2Component)), i1.ɵdid(1, 114688, null, 0, i4.VehicleUtilisation2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleUtilisation2ComponentNgFactory = i1.ɵccf("app-vehicle-utilisation2", i4.VehicleUtilisation2Component, View_VehicleUtilisation2Component_Host_0, {}, {}, []);
export { VehicleUtilisation2ComponentNgFactory as VehicleUtilisation2ComponentNgFactory };
