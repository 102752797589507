import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AssignedToFacility,
  AssignedToFacilityApi,
  Employee,
  EmployeeApi,
  EmployeeView,
  Facility,
  FacilityApi,
  LoggerService,
  MyUser,
  MyUserApi,
} from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import groupBy from 'lodash-es/groupBy';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-assign-employee-form',
  templateUrl: './assign-employee-form.component.html',
  styleUrls: ['./assign-employee-form.component.scss'],
})
export class AssignEmployeeFormComponent extends ABaseComponent implements OnInit {
  form: FormGroup;
  error = null;

  tagBoxAssignedDisabled = false;
  tagBoxVisibleFromDisabled = false;

  @Input() userId: number | string;

  $reloadEmployee$: Subject<any> = new Subject();
  $employee$: BehaviorSubject<Employee> = new BehaviorSubject<Employee>(null);

  facilityDso: DataSourceOptions = [];
  eFacilityDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  eUserDso$: Observable<DataSourceOptions> = of([]);

  $reloadAssigned$: Subject<any> = new Subject();
  $assignedFacilities$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected userApi: MyUserApi,
    protected employeeApi: EmployeeApi,
    protected assignedToFacilityApi: AssignedToFacilityApi,
    private dss: DataSourceService,
  ) {
    super(logger);

    this.buildForm();

    this.facilityDso = this.buildFacilityDataSource();
    this.eFacilityDso$ = of(this.buildEFacilityDataSource());
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.eUserDso$ = this.buildEUserDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$reloadEmployee$
      .pipe(
        startWith(true),
        tap(() => (this.tagBoxVisibleFromDisabled = true)),
        switchMap(() => this.userApi.getEmployee(this.userId).pipe(catchError(err => of(null)))),
        switchMap((e: Employee) =>
          e
            ? this.employeeApi
                .findById<Employee>(e.id, {
                  include: ['person', 'tenant', 'employeePosition'],
                })
                .pipe(catchError(err => of(null)))
            : of(null),
        ),
        tap(e => {
          this.$employee$.next(e);
          this.$reloadAssigned$.next(true);
        }),
        catchError(err => of(null)),
        tap(() => setTimeout(() => (this.tagBoxVisibleFromDisabled = false), 0)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$reloadAssigned$
      .pipe(
        startWith(true),
        tap(() => (this.tagBoxAssignedDisabled = true)),
        switchMap(() =>
          this.$employee$.value
            ? this.assignedToFacilityApi
                .find<AssignedToFacility>({ where: { employeeId: this.$employee$.value.id } })
                .pipe(map(recs => recs.map(r => r.facilityId)))
            : of([]),
        ),
        tap(ids => this.$assignedFacilities$.next(ids)),
        catchError(err => of(null)),
        tap(() => setTimeout(() => (this.tagBoxAssignedDisabled = false), 0)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected buildForm(): void {
    this.form = this.fb.group({
      employeeId: [],
      facilities: [],
      fromFacilities: [],
    });
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, EmployeeView, false);
    so.customHeaders = headersAllTenantsAppend;
    so.customFilter = {
      fields: {
        id: true,
        userId: true,
        tenantId: true,
        person_firstname: true,
        person_lastname: true,
        facility_shortname: true,
      },
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEUserDataSource() {
    const so = this.dss.getStoreOptions(MyUser, undefined, false);
    so.customFilter = {
      fields: { id: true, username: true },
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'username' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] }, name: { neq: 'N/A' } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      postProcess(records: any[]) {
        records = Object.entries(groupBy(records, 'type')) //
          .map(([key, items]) => ({ key, items }));
        return records;
      },
    } as DataSourceOptions;

    return dso;
  }

  private buildEFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { name: { neq: 'N/A' } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;

    return dso;
  }

  assignEmployee(e) {
    this.error = null;

    if (this.form.valid) {
      of(true)
        .pipe(
          switchMap(() => this.userApi.assignEmployee(this.userId, this.form.value.employeeId)),
          tap(() => this.$reloadEmployee$.next(true)),
        )
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
    } else {
      notify('Invalid form value', 'error');
    }
  }

  assignFacilities(e) {
    this.error = null;
    const currentEmployee = this.$employee$.value;

    if (this.form.valid && currentEmployee) {
      const assignedIds: number[] = this.form.value.facilities;

      of(true)
        .pipe(
          switchMap(() => this.employeeApi.assignToFacilities(currentEmployee.id, assignedIds)),
          tap(() => this.$reloadAssigned$.next(true)),
        )
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
    } else {
      notify('Invalid form value', 'error');
    }
  }

  assignBothFacilities(e) {
    this.error = null;
    const currentEmployee = this.$employee$.value;

    if (this.form.valid && currentEmployee) {
      const assignedIds: number[] = this.form.value.facilities;

      of(true)
        .pipe(
          switchMap(() => this.employeeApi.assignToFacilities(currentEmployee.id, assignedIds)),
          tap(() => this.$reloadAssigned$.next(true)),
          switchMap(() => this.employeeApi.visibleFromFacilities(currentEmployee.id, assignedIds)),
          tap(() => this.$reloadEmployee$.next(true)),
        )
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
    } else {
      notify('Invalid form value', 'error');
    }
  }

  visibleFromFacilities(e) {
    this.error = null;
    const currentEmployee = this.$employee$.value;

    if (this.form.valid && currentEmployee) {
      const fIds: number[] = this.form.value.fromFacilities;

      of(true)
        .pipe(
          switchMap(() => this.employeeApi.visibleFromFacilities(currentEmployee.id, fIds)),
          tap(() => this.$reloadEmployee$.next(true)),
        )
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
    } else {
      notify('Invalid form value', 'error');
    }
  }
}
