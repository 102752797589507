/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dlg-show-json.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/filter-json.pipe";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../../../node_modules/devextreme-angular/ui/scroll-view/devextreme-angular-ui-scroll-view.ngfactory";
import * as i5 from "devextreme-angular/core";
import * as i6 from "devextreme-angular/ui/scroll-view";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../../../../node_modules/ngx-json-viewer/ngx-json-viewer.ngfactory";
import * as i9 from "ngx-json-viewer";
import * as i10 from "./dlg-show-json.component";
var styles_DlgShowJsonComponent = [i0.styles];
var RenderType_DlgShowJsonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DlgShowJsonComponent, data: {} });
export { RenderType_DlgShowJsonComponent as RenderType_DlgShowJsonComponent };
export function View_DlgShowJsonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.FilterJson, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "mat-dialog-content", [["class", "mat-dialog-content"], ["style", "height: 500px; width: 600px;"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "dx-scroll-view", [], null, null, null, i4.View_DxScrollViewComponent_0, i4.RenderType_DxScrollViewComponent)), i1.ɵprd(512, null, i5.DxTemplateHost, i5.DxTemplateHost, []), i1.ɵprd(512, null, i5.WatcherHelper, i5.WatcherHelper, []), i1.ɵprd(512, null, i5.NestedOptionHost, i5.NestedOptionHost, []), i1.ɵdid(10, 7323648, null, 0, i6.DxScrollViewComponent, [i1.ElementRef, i1.NgZone, i5.DxTemplateHost, i5.WatcherHelper, i5.NestedOptionHost, i7.TransferState, i1.PLATFORM_ID], { useNative: [0, "useNative"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "ngx-json-viewer", [], null, null, null, i8.View_NgxJsonViewerComponent_0, i8.RenderType_NgxJsonViewerComponent)), i1.ɵdid(12, 573440, null, 0, i9.NgxJsonViewerComponent, [], { json: [0, "json"], expanded: [1, "expanded"] }, null), i1.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = true; _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.data.json)); var currVal_4 = true; _ck(_v, 12, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); }); }
export function View_DlgShowJsonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dlg-show-json", [], null, null, null, View_DlgShowJsonComponent_0, RenderType_DlgShowJsonComponent)), i1.ɵdid(1, 114688, null, 0, i10.DlgShowJsonComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DlgShowJsonComponentNgFactory = i1.ɵccf("app-dlg-show-json", i10.DlgShowJsonComponent, View_DlgShowJsonComponent_Host_0, {}, {}, []);
export { DlgShowJsonComponentNgFactory as DlgShowJsonComponentNgFactory };
