/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trip-manifest-employee-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/devextreme-angular/ui/data-grid/devextreme-angular-ui-data-grid.ngfactory";
import * as i4 from "devextreme-angular/core";
import * as i5 from "devextreme-angular/ui/data-grid";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../node_modules/devextreme-angular/ui/popup/devextreme-angular-ui-popup.ngfactory";
import * as i8 from "devextreme-angular/ui/popup";
import * as i9 from "./trip-manifest-employee-popup.component";
var styles_TripManifestEmployeePopupComponent = [i0.styles];
var RenderType_TripManifestEmployeePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripManifestEmployeePopupComponent, data: {} });
export { RenderType_TripManifestEmployeePopupComponent as RenderType_TripManifestEmployeePopupComponent };
function View_TripManifestEmployeePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TripManifestEmployeePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripManifestEmployeePopupComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 11); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TripManifestEmployeePopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "dx-data-grid", [], null, [[null, "onSelectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectionChanged" === en)) {
        var pd_0 = (_co.grid_onSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DxDataGridComponent_0, i3.RenderType_DxDataGridComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.IterableDifferHelper, i4.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(6, 7323648, [[2, 4], ["employeeGrid", 4]], 2, i5.DxDataGridComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.IterableDifferHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { columnChooser: [0, "columnChooser"], columns: [1, "columns"], dataSource: [2, "dataSource"], editing: [3, "editing"], filterRow: [4, "filterRow"], height: [5, "height"], hoverStateEnabled: [6, "hoverStateEnabled"], pager: [7, "pager"], paging: [8, "paging"], scrolling: [9, "scrolling"], searchPanel: [10, "searchPanel"], selection: [11, "selection"] }, { onSelectionChanged: "onSelectionChanged" }), i1.ɵqud(603979776, 4, { columnsChildren: 1 }), i1.ɵqud(603979776, 5, { sortByGroupSummaryInfoChildren: 1 }), i1.ɵpod(9, { enabled: 0 }), i1.ɵpod(10, { allowAdding: 0, allowUpdating: 1, allowDeleting: 2 }), i1.ɵpod(11, { visible: 0 }), i1.ɵpod(12, { visible: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 9, 0, false); var currVal_1 = _co.gridColumns; var currVal_2 = _co.gridDataSource; var currVal_3 = _ck(_v, 10, 0, false, false, false); var currVal_4 = _co.gridFilterRow; var currVal_5 = "100%"; var currVal_6 = true; var currVal_7 = _ck(_v, 11, 0, false); var currVal_8 = _co.gridPaging; var currVal_9 = _co.gridScrolling; var currVal_10 = _ck(_v, 12, 0, false); var currVal_11 = _co.gridSelection; _ck(_v, 6, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_TripManifestEmployeePopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripManifestEmployeePopupComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridEnabled; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TripManifestEmployeePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { employeePopup: 0 }), i1.ɵqud(671088640, 2, { employeeGrid: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 8, "dx-popup", [], null, [[null, "visibleChange"], [null, "onShowing"], [null, "onShown"], [null, "onHidden"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShowing" === en)) {
        var pd_1 = (_co.popup_onShowing($event) !== false);
        ad = (pd_1 && ad);
    } if (("onShown" === en)) {
        var pd_2 = (_co.popup_onShown($event) !== false);
        ad = (pd_2 && ad);
    } if (("onHidden" === en)) {
        var pd_3 = (_co.popup_onHidden($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_DxPopupComponent_0, i7.RenderType_DxPopupComponent)), i1.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i1.ɵprd(512, null, i4.WatcherHelper, i4.WatcherHelper, []), i1.ɵprd(512, null, i4.IterableDifferHelper, i4.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i4.NestedOptionHost, i4.NestedOptionHost, []), i1.ɵdid(7, 7323648, [[1, 4], ["employeePopup", 4]], 1, i8.DxPopupComponent, [i1.ElementRef, i1.NgZone, i4.DxTemplateHost, i4.WatcherHelper, i4.IterableDifferHelper, i4.NestedOptionHost, i6.TransferState, i1.PLATFORM_ID], { closeOnOutsideClick: [0, "closeOnOutsideClick"], dragEnabled: [1, "dragEnabled"], showTitle: [2, "showTitle"], title: [3, "title"], visible: [4, "visible"] }, { onHidden: "onHidden", onShowing: "onShowing", onShown: "onShown", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 3, { toolbarItemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TripManifestEmployeePopupComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i4.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null), (_l()(), i1.ɵand(0, [["popupContent", 2]], null, 0, null, View_TripManifestEmployeePopupComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = false; var currVal_2 = true; var currVal_3 = _co.title; var currVal_4 = _co.visible; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "content"; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_TripManifestEmployeePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trip-manifest-employee-popup", [], null, null, null, View_TripManifestEmployeePopupComponent_0, RenderType_TripManifestEmployeePopupComponent)), i1.ɵdid(1, 114688, null, 0, i9.TripManifestEmployeePopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripManifestEmployeePopupComponentNgFactory = i1.ɵccf("app-trip-manifest-employee-popup", i9.TripManifestEmployeePopupComponent, View_TripManifestEmployeePopupComponent_Host_0, { title: "title", gridDataSource: "gridDataSource", gridColumns: "gridColumns", gridSelection: "gridSelection", gridPaging: "gridPaging", gridFilterRow: "gridFilterRow", gridScrolling: "gridScrolling", visible: "visible" }, { selectionChanged: "selectionChanged", visibleChange: "visibleChange" }, []);
export { TripManifestEmployeePopupComponentNgFactory as TripManifestEmployeePopupComponentNgFactory };
