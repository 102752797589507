import { Pipe, PipeTransform } from '@angular/core';
import humanizeDuration from 'humanize-duration';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(minutes: number | undefined, options: any = {}): string {
    // return duration(minutes, unit).humanize();
    return !isNaN(minutes) ? humanizeDuration(minutes * 60_000, options) : '';
  }
}
