import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { OverlayRef } from '@angular/cdk/overlay/typings/overlay-ref';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
//
import Guid from 'devextreme/core/guid';
import { HideLoadingPanel, ShowLoadingPanel } from '../../../../store/actions/core';
import { AppState } from '../../../../store/reducers';
import { applyTimezoneOffsetDelta } from '../../../classes/utils/time.utils';
import {
  FormDialogDataConfig,
  FormDialogTemplateComponent,
} from '../components/form-dialog-template/form-dialog-template.component';
import { OfflineOverlayComponent } from '../components/offline-overlay/offline-overlay.component';

@Injectable()
export class UiService {
  private onlineOverlayRef: OverlayRef;

  constructor(
    @Inject(Store) public store: Store<AppState>,
    @Inject(MatDialog) protected dialog: MatDialog,
    @Inject(Overlay) protected overlay: Overlay,
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
  ) {}

  genId(): string {
    return '_' + new Guid().valueOf().replace(/-/g, '_');
  }

  openEditDialog<M>(
    options: FormDialogDataConfig<M>,
    config?: MatDialogConfig<FormDialogDataConfig<M>>,
  ): MatDialogRef<FormDialogTemplateComponent<M>> {
    const dialogRef = this.dialog.open<FormDialogTemplateComponent<M>, FormDialogDataConfig<M>>(
      FormDialogTemplateComponent,
      {
        ...{
          panelClass: 'edit-dialog',
          backdropClass: 'edit-dialog',
          minWidth: 650,
          disableClose: true,
          data: options,
        },
        ...config,
      },
    );

    dialogRef
      .afterClosed()
      .toPromise()
      .then(({ id }) => {
        if (id) {
          this.showSnackBarAfterSaved(id, { ...options, modelId: id });
        }
      });

    return dialogRef;
  }

  getFixedLocalDate(date: Date | string) {
    return date && applyTimezoneOffsetDelta(date, -1);
  }

  showOverlay() {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const scrollStrategy = this.overlay.scrollStrategies.block();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'offline-overlay',
      panelClass: 'offline-overlay',
      scrollStrategy,
      positionStrategy,
    });

    this.onlineOverlayRef && this.onlineOverlayRef.dispose();
    this.onlineOverlayRef = this.overlay.create(overlayConfig);
    this.onlineOverlayRef.attach(new ComponentPortal(OfflineOverlayComponent));
  }

  hideOverlay() {
    this.onlineOverlayRef && this.onlineOverlayRef.dispose();
  }

  showLoading() {
    this.store.dispatch(new ShowLoadingPanel());
  }

  hideLoading() {
    this.store.dispatch(new HideLoadingPanel());
  }

  private showSnackBarAfterSaved<M>(id: number, data: FormDialogDataConfig<M>): void {
    const snackBarRef = this.snackBar.open(`${data.ModelClass.getModelName()} has been saved.`, 'Edit', {
      duration: 7000,
    });

    const snackBarRef_actionSubscription = snackBarRef.onAction().subscribe(() => {
      this.openEditDialog({ ...data, modelId: id });
    });

    const snackBarRef_dismissSubscription = snackBarRef.afterDismissed().subscribe(() => {
      snackBarRef_dismissSubscription.unsubscribe();
      snackBarRef_actionSubscription.unsubscribe();
    });
  }
}
