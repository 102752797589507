import { Component, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { AutoRepairShop, LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';

@Component({
  selector: 'app-auto-repair-shop-grid',
  templateUrl: './auto-repair-shop-grid.component.html',
  styleUrls: ['./auto-repair-shop-grid.component.scss'],
})
export class AutoRepairShopGridComponent extends ABaseComponent {
  shopDso: DataSourceOptions = [];

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
  ) {
    super(logger);

    this.buildDataSource();
    this.shopDso = this.buildDataSource();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(AutoRepairShop, undefined, false);
    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return dso;
  }
}

