<app-any-notes-page
  [objectType]="objectType"
  [objectId]="objectId"
  [filter]="filter"
  [extraFields]="extraFields"
  [templateFormTop]="templateFormTop"
  [templateFormBottom]="templateFormBottom"
  [templateNote]="templateNote"></app-any-notes-page>

<ng-template #templateFormTop let-form="form">
  <div [formGroup]="form">
    <div class="dx-field">
      <label class="_dx-field-label">Note Category:</label>
      <div class="_dx-field-value">
        <dx-select-box
          #noteCategory
          formControlName="noteCategory"
          [dataSource]="['Safety/Compliance Note', 'Generic HR Note']"></dx-select-box>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateFormBottom let-form="form">
  <div [formGroup]="form">
    <ng-container *ngIf="form.value.noteCategory === 'Safety/Compliance Note'">
      <div class="dx-field">
        <label class="_dx-field-label">Note Type:</label>
        <div class="_dx-field-value">
          <dx-select-box
            formControlName="noteType"
            [dataSource]="[
              'Incident',
              'Auto Accident',
              'Misconduct/Disciplinary',
              'Traffic Violation',
              'Other'
            ]"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Related Vehicle:</label>
        <div class="_dx-field-value">
          <dx-select-box
            formControlName="vehicleId"
            [dataSource]="vehicleDso$ | async"
            displayExpr="internalId"
            valueExpr="id"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Date Of Event:</label>
        <div class="_dx-field-value"><dx-date-box formControlName="dateOfEvent"></dx-date-box></div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Date Of Acknowledgement:</label>
        <div class="_dx-field-value"><dx-date-box formControlName="dateOfAcknowledgement"></dx-date-box></div>
      </div>
      <div class="dx-field">
        <label class="_dx-field-label">Resolution Manager:</label>
        <div class="_dx-field-value">
          <dx-select-box
            formControlName="resolutionManagerId"
            [dataSource]="resolutionManagerDso$ | async"
            [displayExpr]="resolutionManager_displayExpr"
            valueExpr="id"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="_dx-field-value">
          <dx-check-box formControlName="employeeAtFault" text="At Fault"></dx-check-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="_dx-field-value">
          <dx-check-box formControlName="insuranceCompanyInformed" text="Was Insurance Company Informed"></dx-check-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="_dx-field-value">
          <dx-check-box formControlName="insuranceClaimCreated" text="Was Insurance Claim Created"></dx-check-box>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #templateNote let-item="item">
  <div class="dx-field">
    <div class="dx-field-label">Tenant</div>
    <div class="dx-field-value-static">{{ item.facility?.shortname }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Related Vehicle</div>
    <div class="dx-field-value-static">{{ item.vehicle?.internalId || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Note Category</div>
    <div class="dx-field-value-static">{{ item.noteCategory || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Note Type</div>
    <div class="dx-field-value-static">{{ item.noteType || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Date Of Event</div>
    <div class="dx-field-value-static">{{ item.dateOfEvent | date : 'longDate' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Date Of Acknowledgement</div>
    <div class="dx-field-value-static">{{ item.dateOfAcknowledgement | date : 'longDate' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Resolution Manager</div>
    <div class="dx-field-value-static">
      {{ item.resolutionManager?.person_firstname || '' }} {{ item.resolutionManager?.person_lastname || '' }}
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">At Fault</div>
    <div class="dx-field-value-static">{{ (item.employeeAtFault && 'Yes') || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Was Insurance Company Informed</div>
    <div class="dx-field-value-static">{{ (item.insuranceCompanyInformed && 'Yes') || '' }}</div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">Was Insurance Claim Created</div>
    <div class="dx-field-value-static">{{ (item.insuranceClaimCreated && 'Yes') || '' }}</div>
  </div>
</ng-template>

