<router-outlet></router-outlet>
<router-outlet name="popup"></router-outlet>
<dx-load-panel
  [visible]="loadingVisible$ | async"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
  shadingColor="rgba(0,0,0,0.4)"></dx-load-panel>
<!--<div kendoDialogContainer></div>-->
