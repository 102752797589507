import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnyNotesPageModule } from 'src/app/shared/modules/ui/components/any-notes-page/any-notes-page.module';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { VehicleAddFormComponent } from './components/vehicle-add-form/vehicle-add-form.component';
import { VehicleDetailsTabHistoryComponent } from './components/vehicle-details-tab-history/vehicle-details-tab-history.component';
import { VehicleDetailsTabLocationsComponent } from './components/vehicle-details-tab-locations/vehicle-details-tab-locations.component';
import { VehicleDetailsTabNotesComponent } from './components/vehicle-details-tab-notes/vehicle-details-tab-notes.component';
import { VehicleDetailsTabsComponent } from './components/vehicle-details-tabs/vehicle-details-tabs.component';
import { VehicleGridComponent } from './components/vehicle-grid/vehicle-grid.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { VehicleInMaintenanceComponent } from './containers/vehicle-in-maintenance/vehicle-in-maintenance.component';
import { VehicleChangeStatusFormComponent } from './components/vehicle-change-status-form/vehicle-change-status-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule, AnyNotesPageModule],
  declarations: [
    VehicleAddFormComponent,
    VehicleChangeStatusFormComponent,
    VehicleInMaintenanceComponent,
    VehicleListComponent,
    VehicleGridComponent,
    VehicleDetailsTabsComponent,
    VehicleDetailsTabHistoryComponent,
    VehicleDetailsTabNotesComponent,
    VehicleDetailsTabLocationsComponent,
  ],
  exports: [VehicleInMaintenanceComponent],
  entryComponents: [VehicleInMaintenanceComponent, VehicleAddFormComponent, VehicleChangeStatusFormComponent],
})
export class VehicleInMaintenanceModule {}

