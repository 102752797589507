import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService, Signature } from '../../../../shared/sdk';
import moment from 'moment/moment';

@Component({
  selector: 'app-dlg-edit-times',
  templateUrl: './dlg-edit-times.component.html',
  styleUrls: ['./dlg-edit-times.component.scss'],
})
export class DlgEditTimesComponent implements OnInit, AfterViewInit {
  value: {
    scheduledTime;
    arrivedTime;
    pickupTime;
    dropoffTime;
  };

  constructor(
    private dialogRef: MatDialogRef<DlgEditTimesComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      signature: Signature & { meta?: { partValidated?; fullValidated? } };
      useFieldLock?: boolean;
    },
    protected logger: LoggerService,
  ) {
    this.value = {
      scheduledTime: data.signature.scheduledTime ? moment(data.signature.scheduledTime, 'HH:mm:ss').toDate() : null,
      arrivedTime: data.signature.arrivedTime ? moment(data.signature.arrivedTime, 'HH:mm:ss').toDate() : null,
      pickupTime: data.signature.pickupTime ? moment(data.signature.pickupTime, 'HH:mm:ss').toDate() : null,
      dropoffTime: data.signature.dropoffTime ? moment(data.signature.dropoffTime, 'HH:mm:ss').toDate() : null,
    };
  }

  get o() {
    return this.data.signature;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}
}
