import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
//
import { CheckAuthFail } from '../../../../store/actions/sign';
import { AppState } from '../../../../store/reducers';
import { ServerError } from '../../../classes/ServerUnavailableError';
import { LoopBackAuth } from '../../../sdk/services/core';
import { MyUserApi } from '../../../sdk/services/custom';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';
import { ExtSdkErrorHandler } from '../../ext-sdk/services/ext-sdk-error.handler';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
    private userApi: MyUserApi,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkAuthAsync(url);
  }

  private checkAuthAsync(url: string): Observable<boolean> {
    // let error;
    return this.userApi.getCurrent().pipe(
      map(user => {
        // console.log(user);
        const isAuthenticated = user && this.userApi.isAuthenticated();
        return [false, isAuthenticated];
      }),
      catchError(error => {
        // console.error(error);

        // server unavailable
        const offline = error.code === ServerError.ServerUnavailableError().code || error.status === 504;
        return of([offline, false]);
      }),
      map(([offline, isAuthenticated]) => {
        if (!offline && !isAuthenticated) {
          this.store.dispatch(new CheckAuthFail('Auth Guard check fails'));
          // Store the attempted URL for redirecting
          // this.authService.redirectUrl = url;

          // Navigate to the login page with extras
          this.router.navigate(['/sign']);
        }
        return isAuthenticated;
      }),
    );

    // const user = await this.userApi.getCurrent()
    //   .toPromise()
    //   .catch((err) => {
    //     error = err;
    //     return null;
    //   })
    // ;
    //
    // if (this.userApi.isAuthenticated() && !!user)
    //   return true;
    //
    // // server unavailable
    // if (!user && error && error.code === ServerError.ServerUnavailableError().code) {
    //   // this.router.navigate(['/sign']);
    //   return false;
    // }
    //
    // this.store.dispatch(new CheckAuthFail('Auth Guard check fails'));
    //
    // // Store the attempted URL for redirecting
    // // this.authService.redirectUrl = url;
    //
    // // Navigate to the login page with extras
    // this.router.navigate(['/sign']);
    //
    // return false;
  }
}
