import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  public permission: NotificationPermission;

  constructor(private swPush: SwPush) {
    this.permission = this.isSupported ? 'default' : 'denied';
  }

  public get isSupported(): boolean {
    return 'Notification' in window;
  }

  public get isGranted(): boolean {
    return this.permission === 'granted';
  }

  async requestPermission(): Promise<void> {
    const self = this;
    if (self.isSupported) {
      self.permission = await Notification.requestPermission();
    }
  }

  create(title: string, options?: NotificationOptions): Observable<any> {
    const self = this;
    return new Observable(obs => {
      if (!self.isSupported) {
        console.log('Notifications are not available in this environment');
        return obs.complete();
      }

      if (!self.isGranted) {
        console.log("The user hasn't granted you permission to send push notifications");
        return obs.complete();
      }

      const _notify = new Notification(title, options);
      _notify.onshow = e =>
        obs.next({
          notification: _notify,
          event: e,
        });
      _notify.onclick = e =>
        obs.next({
          notification: _notify,
          event: e,
        });
      _notify.onerror = e =>
        obs.error({
          notification: _notify,
          event: e,
        });
      _notify.onclose = () => obs.complete();
    });
  }

  generateNotification(...args: Array<{ title: string; opts?: NotificationOptions }>): void {
    const self = this;
    args.forEach(({ title, opts }) => {
      self.create(title, opts).pipe(tap(console.log)).subscribe();
    });
  }

  error(error: Error, title?: string) {
    return this.generateNotification({
      title: title || 'Error',
      opts: {
        icon: '/assets/images/error-256.png',
        badge: '/assets/images/error-256.png',
        body: error.message,
        requireInteraction: true,
      },
    });
  }

  done(body: string, title?: string) {
    return this.generateNotification({
      title: title || 'Done',
      opts: {
        icon: '/assets/images/done-256.png',
        badge: '/assets/images/done-256.png',
        body,
        requireInteraction: true,
      },
    });
  }
}
