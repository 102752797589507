import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ConsumerView, ConsumerViewApi, Facility, LoggerService, SignatureView } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-change-adc-w-client',
  templateUrl: './dlg-change-adc-w-client.component.html',
  styleUrls: ['./dlg-change-adc-w-client.component.scss'],
})
export class DlgChangeAdcWithClientComponent implements OnInit {
  ds1: DataSourceOptions;
  gridColumns1: any[] = [{ dataField: 'shortname' }, { dataField: 'type' }, { dataField: 'legalName' }];

  gridSelection1 = { mode: 'single', showCheckBoxesMode: 'none' };
  gridPaging1 = { enabled: true, pageSize: 10 };
  gridFilterRow1 = { visible: true };
  gridScrolling1 = { mode: 'infinite' };

  ds2$: Observable<DataSourceOptions>;
  gridColumns2: any[] = [
    { dataField: 'mci' },
    { dataField: 'person_firstname' },
    { dataField: 'person_lastname' },
    {
      dataField: 'person_dob',
      dataType: 'date',
      format: 'shortDate',
      customizeText: this.config.customizeShortDateText,
      editorOptions: { type: 'date', displayFormat: 'shortDate' },
    },
  ];

  gridSelection2 = { mode: 'single', showCheckBoxesMode: 'none' };
  gridPaging2 = { enabled: true, pageSize: 20 };
  gridFilterRow2 = { visible: true };
  gridScrolling2 = { mode: 'infinite' };

  selectedTenantIds: number[];
  selectedConsumerIds: number[];
  selectedTenant: Facility;
  selectedConsumer: ConsumerView;

  constructor(
    private dialogRef: MatDialogRef<DlgChangeAdcWithClientComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sv: SignatureView;
    },
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.selectedTenantIds = [this.data.sv.tenantId];
    this.selectedConsumerIds = [this.data.sv.consumerId];

    this.buildDataSource1();
    this.buildDataSource2(this.data.sv.tenantId);
  }

  private buildDataSource1() {
    const so = this.dss.getStoreOptions(Facility, Facility, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = hAll;

    this.ds1 = {
      store: new CustomStore(so),
      sort: [{ selector: 'shortname' }],
    } as DataSourceOptions;
  }

  private buildDataSource2(tenantId: number) {
    this.ds2$ = of(true).pipe(
      switchMap(() =>
        this.dss
          .getApi<ConsumerViewApi>(ConsumerView)
          .find<ConsumerView>(
            { where: { and: [{ tenantId }, { mci: { neq: null } }, { mci: this.data.sv.consumer_mci }] } },
            hAll,
          ),
      ),
      map(([cv]) => {
        if (cv) {
          this.selectedConsumerIds = [cv.id];
          this.cd.detectChanges();
        }

        const so = this.dss.getStoreOptions(ConsumerView, ConsumerView, false) as LoopBackStoreOptions<any, any>;
        so.customHeaders = hAll;
        so.customFilter = { where: { tenantId } };

        return {
          store: new CustomStore(so),
          sort: [{ selector: 'person_firstname' }, { selector: 'person_lastname' }],
          filter: cv ? [['mci', '=', cv.mci]] : [],
        } as DataSourceOptions;
      }),
    );
  }

  grid1_onSelectionChanged(e: { selectedRowKeys: any[] }) {
    this.buildDataSource2(e.selectedRowKeys[0]);
    this.selectedTenant = e.selectedRowKeys[0];
  }

  grid2_onSelectionChanged(e) {
    this.selectedConsumer = e.selectedRowKeys[0];
  }
}
