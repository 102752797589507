import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnyNotesPageModule } from 'src/app/shared/modules/ui/components/any-notes-page/any-notes-page.module';
import { AnyNotesComponent } from 'src/app/shared/modules/ui/components/any-notes/any-notes.component';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { DlgVehicleDocumentGridComponent } from './components/dlg-vehicle-document-grid/dlg-vehicle-document-grid.component';
import { FormAcquisitionArrayComponent } from './components/form-acquisition-array/form-acquisition-array.component';
import { VehicleDetailsTabActivityComponent } from './components/vehicle-details-tab-activity/vehicle-details-tab-activity.component';
import { VehicleDetailsTabClaimsComponent } from './components/vehicle-details-tab-claims/vehicle-details-tab-claims.component';
import { VehicleDetailsTabDocumentComponent } from './components/vehicle-details-tab-document/vehicle-details-tab-document.component';
import { VehicleDetailsTabEquipmentComponent } from './components/vehicle-details-tab-equipment/vehicle-details-tab-equipment.component';
import { VehicleDetailsTabFinancialsComponent } from './components/vehicle-details-tab-financials/vehicle-details-tab-financials.component';
import { VehicleDetailsTabInfoComponent } from './components/vehicle-details-tab-info/vehicle-details-tab-info.component';
import { VehicleDetailsTabMaintenanceRecsComponent } from './components/vehicle-details-tab-maintenance-recs/vehicle-details-tab-maintenance-recs.component';
import { VehicleDetailsTabNotesComponent } from './components/vehicle-details-tab-notes/vehicle-details-tab-notes.component';
import { VehicleDetailsTabRoutesComponent } from './components/vehicle-details-tab-routes/vehicle-details-tab-routes.component';
import { VehicleDetailsTabSpecsComponent } from './components/vehicle-details-tab-specs/vehicle-details-tab-specs.component';
import { VehicleDetailsTabsComponent } from './components/vehicle-details-tabs/vehicle-details-tabs.component';
import { VehicleDetailsComponent } from './components/vehicle-details/vehicle-details.component';
import { VehicleDocumentGridComponent } from './components/vehicle-document-grid/vehicle-document-grid.component';
import { VehicleFormComponent } from './components/vehicle-form/vehicle-form.component';
import { VehicleGridComponent } from './components/vehicle-grid/vehicle-grid.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { VehicleToFacilityComponent } from './components/vehicle-to-facility/vehicle-to-facility.component';
import { VehicleComponent } from './containers/vehicle/vehicle.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule, AnyNotesPageModule],
  declarations: [
    AnyNotesComponent,
    DlgVehicleDocumentGridComponent,
    VehicleComponent,
    VehicleListComponent,
    VehicleGridComponent,
    VehicleDetailsComponent,
    VehicleFormComponent,
    VehicleDetailsTabsComponent,
    VehicleDetailsTabInfoComponent,
    VehicleToFacilityComponent,
    VehicleDetailsTabSpecsComponent,
    VehicleDetailsTabEquipmentComponent,
    VehicleDetailsTabMaintenanceRecsComponent,
    VehicleDetailsTabFinancialsComponent,
    VehicleDetailsTabClaimsComponent,
    VehicleDetailsTabDocumentComponent,
    VehicleDetailsTabActivityComponent,
    VehicleDetailsTabRoutesComponent,
    VehicleDetailsTabNotesComponent,
    VehicleDocumentGridComponent,
    FormAcquisitionArrayComponent,
  ],
  exports: [AnyNotesComponent, VehicleComponent, VehicleToFacilityComponent],
  entryComponents: [AnyNotesComponent, VehicleComponent, VehicleToFacilityComponent, VehicleFormComponent],
})
export class VehicleModule {}
