<dx-toolbar [items]="toolbarItems" style="margin: 5px">
  <!-- Templates -->

  <ng-container *dxTemplate="let data of 'info'">
    <!--    <ng-container *ngIf="manifest">-->
    <!--      <div>-->
    <!--        Service type: <strong>{{ manifest.serviceType || SERVICE_TYPE.PARATRANSIT }}</strong>-->
    <!--      </div>-->
    <!--    </ng-container>-->

    <ng-container *ngIf="selectedDate">
      <span>
        <strong>{{ selectedDate | date : 'EEEE' }}</strong
        >, <strong>{{ selectedDate | date : 'mediumDate' }}</strong>
      </span>
      <span>
        &nbsp;Total vehicles: <strong>{{ vehicleTotalSet.size }}</strong> &nbsp;Total drivers:
        <strong>{{ driverTotalSet.size }}</strong> &nbsp;Total clients:
        <strong>{{ consumerTotalSet.size }}</strong> &nbsp;Total Trips:
        <strong>{{ manifest?.data?.length }}</strong> &nbsp;
        <span *ngIf="showSubscriptionTrips">
          Total Subscription Trips:
          <strong>{{ totalSubscribtionTrips }}</strong> &nbsp;
        </span>
        Avg Trips Per Driver: <strong>{{ avgTrips }}</strong>
      </span>
      <span *ngIf="model"
        >; Clients for {{ model?.person?.firstname + ' ' + model?.person?.lastname }}:
        <strong>{{ consumerPerDriverMap.get(model?.id)?.size || 0 }}</strong>
      </span>
    </ng-container>
  </ng-container>
</dx-toolbar>

<div class="alert-warning" *ngIf="validationSummary?.length">
  <!--<h4>Manifest summary:</h4>-->
  <ul style="margin-block-start: 0; margin-block-end: 0">
    <li *ngFor="let item of validationSummary">{{ item?.error }}</li>
  </ul>
</div>

