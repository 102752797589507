<div class="title">Usage History for vehicle #{{ _vehicle.internalId }}</div>

<dx-data-grid height="100%" width="auto" [dataSource]="dso" [remoteOperations]="false">
  <!-- Settings -->

  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-export [enabled]="true" fileName="history-export"></dxo-export>

  <!-- Columns -->

  <dxi-column
    width="75px"
    caption="Date"
    dataField="date"
    dataType="date"
    sortOrder="desc"
    format="shortDate"></dxi-column>

  <dxi-column caption="Driver" dataField="driver" dataType="string"></dxi-column>

  <dxi-column caption="Facility" dataField="tenant.shortname" dataType="string"></dxi-column>

  <dxi-column caption="Miles Driven" dataField="distance"></dxi-column>

  <dxi-column
    caption="Location"
    cellTemplate="geoTabLocation_cell"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'geoTabLocation_cell'">
    <a *ngIf="cellInfo.data.loop" class="show-loop-link" (click)="showLoop_onClick(cellInfo.data)" title="loop">loop</a>
  </ng-container>
</dx-data-grid>

