<app-content-w-header header="Facility Info">
  <dx-scroll-view class="content">
    <form (ngSubmit)="form_ngSubmit($event)">
      <mat-card>
        <mat-card-content #target>
          <app-facility-form [modelId]="modelId$ | async"></app-facility-form>

          <p class="alert-danger" *ngIf="facilityForm?.errorMessage">
            {{ facilityForm?.errorMessage }}
          </p>
        </mat-card-content>

        <mat-card-actions>
          <!--          <span style="flex: auto"></span>-->

          <!--          <button mat-flat-button-->
          <!--                  type="button"-->
          <!--                  (click)="reset_onClick($event)"-->
          <!--          >Reset-->
          <!--          </button>-->

          <!--          <button mat-flat-button-->
          <!--                  type="submit"-->
          <!--                  color="primary"-->
          <!--                  cdkFocusInitial-->
          <!--                  [disabled]="facilityForm?.submitting"-->
          <!--          >Submit-->
          <!--          </button>-->

          <div class="dx-fieldset">
            <dx-toolbar>
              <dxi-item location="after">
                <dx-button
                  type="normal"
                  text="Reset"
                  (onClick)="reset_onClick($event)"
                  [disabled]="facilityForm?.form?.pristine || facilityForm?.submitting"></dx-button>
              </dxi-item>
              <dxi-item location="after">
                <dx-button
                  type="default"
                  text="Submit"
                  [useSubmitBehavior]="true"
                  [disabled]="facilityForm?.submitting"></dx-button>
              </dxi-item>
            </dx-toolbar>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>

    <dx-load-panel
      [position]="{ of: target }"
      [visible]="facilityForm?.loading || facilityForm?.submitting"
      [message]="facilityForm?.submitting ? 'Submitting...' : 'Loading...'"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="false"></dx-load-panel>
  </dx-scroll-view>
</app-content-w-header>
