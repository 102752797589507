import * as tslib_1 from "tslib";
import get from 'lodash-es/get';
import { combineLatest } from 'rxjs';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
//
import { Employee, EmployeeView, Facility, LoopBackFilter } from '../../../shared/sdk/models';
import { ConfigApi, EmployeeApi, EmployeeViewApi, FacilityApi, MyFileApi, VehicleApi, } from '../../../shared/sdk/services/custom';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { FullNamePipe } from '../../../shared/modules/ui/pipes/full-name.pipe';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import moment from 'moment';
export class HelperService {
    constructor(api, viewApi, vehicleApi, config, common, configApi, facilityApi, fileApi, dss) {
        this.api = api;
        this.viewApi = viewApi;
        this.vehicleApi = vehicleApi;
        this.config = config;
        this.common = common;
        this.configApi = configApi;
        this.facilityApi = facilityApi;
        this.fileApi = fileApi;
        this.dss = dss;
        this.displayExpr = (e, format) => {
            if (get(e, 'id') === this.selfEntity.id) {
                return ' self';
            }
            return new FullNamePipe(this.config).transform(e, format);
        };
    }
    /**
     * Custom entity for manifest registration (not persistable)
     */
    get selfEntity() {
        return { id: -1, person: { firstname: 'self', lastname: '' }, person_firstname: 'self', person_lastname: '' };
    }
    loadDetails$(employeeId, filter = {}) {
        if (employeeId === this.selfEntity.id) {
            return this.selfEntity;
        }
        const defaultFilter = {
            include: { person: { contact: ['addresses', 'phones'] } },
        };
        return this.api.findById(employeeId, Object.assign({}, defaultFilter, filter)).pipe(catchError(err => of(null)));
    }
    getLastDateOnDutyMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const stages = [{ $sort: { day: 1 } }, { $group: { _id: '$employeeId', last: { $last: '$$ROOT' } } }];
            const groups = yield gqlMongoLoad(this.dss, 'EmployeeOnDuty', {}, stages).pipe().toPromise();
            return groups.reduce((p, group) => (Object.assign({}, p, { [group._id]: { last: Object.assign({}, group.last, { date: moment(group.last.day + '13', 'YYYYMMDD hh') }) } })), {});
            // const stages = [
            //   { $sort: { dateTime: 1 } },
            //   { $group: { _id: '$employeeId', last: { $last: '$$ROOT' } } },
            //   { $project: { _id: 1, date: '$last.dateTime', internalId: '$last.meta.vehicle.internalId' } },
            // ];
            // const checkins = await gqlMongoLoad(this.dss, 'VehicleCheckUp', {}, stages).pipe().toPromise();
            // return checkins.reduce((p, last) => ({ ...p, [last._id]: { last, ...p[last._id] } }), {});
        });
    }
    printIdCard(e) {
        const f = oc(e).person.personalPhoto.avatar();
        return combineLatest([
            (f && this.fileApi.getReadSignedUrlByFile(f)) ||
                of(window.location.origin + '/assets/images/avatar-placeholder.png'),
            this.facilityApi.find({ where: { type: 'BASE' } }).pipe(switchMap(facility => {
                return this.configApi
                    .getConfig((headers) => headers.append('X-Current-Tenant', facility[0].id + ''))
                    .pipe(switchMap(config => {
                    console.log('config', config);
                    return combineLatest([
                        of(oc(config).data.companyName('')),
                        config.data.companyLogoSmall
                            ? this.fileApi.getReadSignedUrlByFile(config.data.companyLogoSmall)
                            : of(null),
                    ]);
                }));
            })),
        ]).pipe(switchMap(([avatarSrc, [companyName, companyLogoSrc]]) => {
            console.log('avatarSrc', avatarSrc, 'companyName', companyName, 'companyLogoSrc', companyLogoSrc);
            const cardHtml = `<div style="width: 8.5cm; height: 5.3cm; border: 1px dashed black; position: relative;">
            <img style="position: absolute; left: 0.5cm; top: 0.7cm; width: 3cm;"
     src="${avatarSrc}"/>

            <div style="position: absolute; left: 0.7cm; top: 3.9cm; font-weight: 600; font-size: 14pt;"></div>
            <div style="position: absolute; left: 3.8cm; top: 0.8cm; font-weight: 600; font-size: 14pt; line-height: 1.5em; text-align: center; width: 150px">
            <span style="font-weight: 300; font-size: 13pt;">
              ${companyLogoSrc ? `<img src="${companyLogoSrc}" style="height: 1cm; margin: 0 0 5px;"/>` : companyName}
            </span>
            <br>
            ${oc(e).person.firstname()}
            <br>
            ${oc(e).person.lastname()}
            <br>
            <span style="font-weight: 300; font-size: 13pt;">
              ${oc(e).employeePosition.name('')}
            </span>
            </div>
        </div>`;
            const html = `
          <html><style>* { box-sizing: border-box; font-family: sans-serif; }</style><body>
            <div style="display: flex; align-content: center; justify-content: center">
                <span style="flex: auto;"></span>

                ${cardHtml}
                ${cardHtml}

                <span style="flex: auto;"></span>
            </div>
          </body></html>`;
            return this.common.printHtml(document, html);
        }), catchError(err => of(null)));
    }
}
