<dx-data-grid height="100%" width="auto" [dataSource]="dso" [remoteOperations]="false">
  <!-- Settings -->

  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-export [enabled]="true" fileName="history-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="First Name" dataField="driver.person_firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="driver.person_lastname" dataType="string"></dxi-column>

  <dxi-column caption="Status" dataField="status" dataType="string"> </dxi-column>

  <dxi-column caption="Mode" dataField="mode" dataType="string"> </dxi-column>

  <dxi-column caption="Work Start Time" dataField="startTime" dataType="date" format="shortTime"></dxi-column>

  <dxi-column caption="Work Start Location" dataField="startLocationAddress" dataType="string"></dxi-column>

  <dxi-column caption="Work Finish Time" dataField="finishTime" dataType="date" format="shortTime"></dxi-column>

  <dxi-column caption="Work Finish Location" dataField="finishLocationAddress" dataType="string"></dxi-column>

  <!-- Templates -->
</dx-data-grid>

