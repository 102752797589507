import { NgModule } from '@angular/core';
//
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

config.autoAddCss = true;
config.autoReplaceSvg = true;
config.showMissingIcons = true;

library.add(far, fas, fab);
dom.i2svg();
dom.watch();

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class MyFontAwesomeModule {}
