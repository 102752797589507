<mat-card *ngIf="false" style="margin: 20px 20px">
  <div mat-dialog-title style="display: flex">
    <span>Batch Inquiry</span>

    <span style="flex: auto"></span>

    <!--    <button mat-icon-button-->
    <!--            type="button"-->
    <!--            [mat-dialog-close]="false"-->
    <!--            matTooltip="Close"-->
    <!--            color="primary"-->
    <!--            style="margin: -1em;"-->
    <!--    >-->
    <!--      <mat-icon aria-label="Close">close</mat-icon>-->
    <!--    </button>-->
  </div>

  <mat-dialog-content cdkScrollable>
    <br />

    <mat-form-field>
      <input
        pattern="\d+"
        minlength="8"
        matInput
        placeholder="Provider NPI Number"
        [(ngModel)]="providerNpi"
        #ctrlNpi="ngModel" />
      <mat-error *ngIf="!numericValidator(ctrlNpi.value)">· Non numeric characters not allowed</mat-error>
      <mat-error *ngIf="(providerNpi || '').toString().length < 8"
        >· Invalid provider NPI number that is less than 8 bytes
      </mat-error>
    </mat-form-field>

    <br />

    <mat-form-field>
      <input
        pattern="\d+"
        minlength="13"
        matInput
        placeholder="MA Provider Number/Loc"
        [(ngModel)]="providerMa"
        #ctrlMa="ngModel" />
      <mat-error *ngIf="!numericValidator(ctrlMa.value)">· Non numeric characters not allowed</mat-error>
      <mat-error *ngIf="(providerMa || '').toString().length < 13"
        >· Invalid provider number/loc that is less than 13 bytes
      </mat-error>
    </mat-form-field>

    <br />

    <mat-form-field>
      <input
        pattern="\d+"
        matInput
        placeholder="OON Provider Number/Loc"
        [(ngModel)]="providerOon"
        #ctrlOon="ngModel" />
      <mat-error *ngIf="!numericValidator(ctrlOon.value)">· Non numeric characters not allowed</mat-error>
    </mat-form-field>

    <br />

    <mat-form-field>
      <input
        pattern="\d+"
        matInput
        placeholder="Social Security Number"
        [(ngModel)]="ssnNumber"
        #ctrlSsnNumber="ngModel" />
      <mat-error *ngIf="!numericValidator(ctrlSsnNumber.value)">· Non numeric characters not allowed</mat-error>
    </mat-form-field>

    <br />

    <mat-form-field>
      <input
        pattern="\d+"
        [appLuhn]="true"
        matInput
        placeholder="Recipient Number"
        [(ngModel)]="recNumber"
        #ctrl2="ngModel" />
      <mat-icon matSuffix color="accent" *ngIf="ctrl2.value | luhn; else invalidLuhn">check_circle_outline</mat-icon>
      <ng-template #invalidLuhn>
        <mat-icon matSuffix color="error">check_circle_outline</mat-icon>
      </ng-template>
      <mat-error *ngIf="!numericValidator(ctrl2.value)">· Non numeric characters not allowed</mat-error>
      <mat-error *ngIf="!(ctrl2.value | luhn)">· Invalid recipient check digit</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        [matDatepicker]="pickerDob"
        placeholder="Recipient Date of Birth"
        [(ngModel)]="dob"
        #ctrlDOB="ngModel" />
      <mat-datepicker-toggle matSuffix [for]="pickerDob"></mat-datepicker-toggle>
      <mat-datepicker #pickerDob></mat-datepicker>
      <mat-error *ngIf="ctrlDOB.invalid">· Invalid Date value</mat-error>
    </mat-form-field>

    <br />

    <div style="display: flex; align-items: flex-start">
      <mat-form-field style="flex: auto; width: auto">
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="picker1"
          placeholder="Choose Start date"
          [(ngModel)]="fromDate"
          #ctrlFromDate="ngModel" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error *ngIf="ctrlFromDate.value > maxDate"
          >· Date of Service greater than end of the current month
        </mat-error>
        <mat-error *ngIf="ctrlFromDate.invalid">· Invalid Date value</mat-error>
      </mat-form-field>

      <span style="display: inline-block; padding: 1.5em 0.5em">&mdash;</span>

      <mat-form-field style="flex: auto; width: auto">
        <input
          matInput
          [max]="maxDate"
          [min]="fromDate"
          [matDatepicker]="picker2"
          placeholder="Choose End date"
          [(ngModel)]="toDate"
          #ctrlToDate="ngModel" />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error *ngIf="ctrlToDate.value > maxDate"
          >· Date of Service greater than end of the current month
        </mat-error>
        <mat-error *ngIf="ctrlFromDate.value > ctrlToDate.value"
          >· End Date should be greater or equal to Start Date
        </mat-error>
        <mat-error *ngIf="ctrlToDate.invalid">· Invalid Date value</mat-error>
      </mat-form-field>
    </div>

    <br />
  </mat-dialog-content>

  <mat-dialog-actions>
    <div style="display: flex">
      <span style="flex: auto"></span>

      <button mat-button type="button">Reset</button>

      <button mat-button type="submit" color="primary" cdkFocusInitial>Submit</button>
    </div>
  </mat-dialog-actions>
</mat-card>

<ng-container *ngIf="false">
  <form>
    <dx-validation-group [formGroup]="form">
      <input formControlName="test" />

      <br />
      <br />

      <!--<mat-form-field-->
      <!--floatLabel="always"-->
      <!--&gt;-->

      <mat-form-field>
        <app-weekday-checkbox placeholder="test ph" formControlName="weekcheck"></app-weekday-checkbox>
      </mat-form-field>

      <br />

      <mat-form-field>
        <app-weekday-number-box placeholder="test ph" formControlName="week"></app-weekday-number-box>
      </mat-form-field>

      <!--<mat-icon matSuffix>phone</mat-icon>-->
      <!--<mat-hint>Include area code</mat-hint>-->
      <!--</mat-form-field>-->

      <app-drop-down-grid-new
        formControlName="cns"
        [grid_columns]="grid_columns"
        [dataSource]="ds"
        [valueExpr]="'id'"
        [displayExpr]="consumerHelper.displayExpr"
        [(value)]="boxValue"
        [popup_width]="350"
        [popup_height]="300"></app-drop-down-grid-new>

      <dx-validation-summary></dx-validation-summary>

      <br />
      <br />

      <dx-button [useSubmitBehavior]="true" [text]="'Submit'"></dx-button>
    </dx-validation-group>

    <h3>Basic Buttons</h3>
    <div class="button-row">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button>
      <button mat-button color="warn">Warn</button>
      <button mat-button disabled>Disabled</button>
      <a mat-button routerLink=".">Link</a>
    </div>

    <h3>Raised Buttons</h3>
    <div class="button-row">
      <button mat-raised-button>Basic</button>
      <button mat-raised-button color="primary">Primary</button>
      <button mat-raised-button color="accent">Accent</button>
      <button mat-raised-button color="warn">Warn</button>
      <button mat-raised-button disabled>Disabled</button>
      <a mat-raised-button routerLink=".">Link</a>
    </div>

    <h3>Icon Buttons</h3>
    <div class="button-row">
      <button mat-icon-button>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="primary">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="accent">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button color="warn">
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <button mat-icon-button disabled>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
    </div>

    <h3>Fab Buttons</h3>
    <div class="button-row">
      <button mat-fab>Basic</button>
      <button mat-fab color="primary">Primary</button>
      <button mat-fab color="accent">Accent</button>
      <button mat-fab color="warn">Warn</button>
      <button mat-fab disabled>Disabled</button>
      <button mat-fab>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <a mat-fab routerLink=".">Link</a>
    </div>

    <h3>Mini Fab Buttons</h3>
    <div class="button-row">
      <button mat-mini-fab>Basic</button>
      <button mat-mini-fab color="primary">Primary</button>
      <button mat-mini-fab color="accent">Accent</button>
      <button mat-mini-fab color="warn">Warn</button>
      <button mat-mini-fab disabled>Disabled</button>
      <button mat-mini-fab>
        <mat-icon aria-label="Example icon-button with a heart icon">favorite</mat-icon>
      </button>
      <a mat-mini-fab routerLink=".">Link</a>
    </div>
  </form>

  <pre>{{ form.value | json }}</pre>

  <pre>
  test:
  untouched: {{ form.get('test').untouched }}
    touched: {{ form.get('test').touched }}
    pristine: {{ form.get('test').pristine }}
    dirty: {{ form.get('test').dirty }}
    invalid: {{ form.get('test').invalid }}
</pre
  >

  <pre>
  week:
  untouched: {{ form.get('week').untouched }}
    touched: {{ form.get('week').touched }}
    pristine: {{ form.get('week').pristine }}
    dirty: {{ form.get('week').dirty }}
    invalid: {{ form.get('week').invalid }}
</pre
  >

  <pre>
  box:
    {{ boxValue }}
</pre
  >
</ng-container>
