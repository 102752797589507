import {
  Facility,
  MyUser,
  Consumer,
  Employee
} from '../index';

declare var Object: any;
export interface Covid19ChecksInterface {
  "requestID": string;
  "dateTime": Date;
  "vdate"?: any;
  "arrivalTime": Date;
  "temperature": number;
  "tempAboveNorm"?: boolean;
  "isAbsent"?: boolean;
  "entryRefused"?: boolean;
  "ppeIssued"?: boolean;
  "meta"?: any;
  "id"?: number;
  "tenantId"?: number;
  "userId"?: number;
  "consumerId"?: number;
  "employeeId"?: number;
  tenant?: Facility;
  user?: MyUser;
  consumer?: Consumer;
  employee?: Employee;
}

export class Covid19Checks implements Covid19ChecksInterface {
  "requestID": string;
  "dateTime": Date;
  "vdate": any;
  "arrivalTime": Date;
  "temperature": number;
  "tempAboveNorm": boolean;
  "isAbsent": boolean;
  "entryRefused": boolean;
  "ppeIssued": boolean;
  "meta": any;
  "id": number;
  "tenantId": number;
  "userId": number;
  "consumerId": number;
  "employeeId": number;
  tenant: Facility;
  user: MyUser;
  consumer: Consumer;
  employee: Employee;
  constructor(data?: Covid19ChecksInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Covid19Checks`.
   */
  public static getModelName() {
    return "Covid19Checks";
  }

  /**
  * This method creates an instance of Covid19Checks for dynamic purposes.
  **/
  public static factory(data: Covid19ChecksInterface): Covid19Checks{
    return new Covid19Checks(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Covid19Checks',
      plural: 'Covid19Checks',
      path: 'Covid19Checks',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "arrivalTime": {
          name: 'arrivalTime',
          type: 'Date'
        },
        "temperature": {
          name: 'temperature',
          type: 'number'
        },
        "tempAboveNorm": {
          name: 'tempAboveNorm',
          type: 'boolean',
          default: false
        },
        "isAbsent": {
          name: 'isAbsent',
          type: 'boolean',
          default: false
        },
        "entryRefused": {
          name: 'entryRefused',
          type: 'boolean',
          default: false
        },
        "ppeIssued": {
          name: 'ppeIssued',
          type: 'boolean',
          default: false
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'consumerId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
      }
    }
  }
}
