import { AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
//
import { LoggerService } from '../../../shared/sdk';
import { Document } from '../../../shared/sdk/models';
import { DataSourceService } from '../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FormHelperService } from '../../../shared/modules/ui/services/form-helper.service';
import { UploadableFile, UploadHelperService } from '../../../shared/modules/ui/services/upload-helper.service';

export abstract class ABaseDocumentFormComponentWithUploader
  extends ABaseFormComponent<Document>
  implements AfterViewInit
{
  validationCallback = this.uploadHelper.validationCallback;

  @ViewChild(DxFileUploaderComponent, /* TODO: check static flag */ { static: false })
  uploader: DxFileUploaderComponent;
  @ViewChild('uploadValidator', /* TODO: check static flag */ { static: false }) uploadValidator: DxValidatorComponent;

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected uploadHelper: UploadHelperService,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Document>,
  ) {
    super(logger, fb, dss, helper);
  }

  ngAfterViewInit(): void {
    this.uploadHelper.handle(
      this.uploader.instance,
      {
        folder: 'documents',
        onUploaded: e => {
          this.form.markAsDirty();
        },
      },
      this.uploadValidator && this.uploadValidator.instance,
    );
  }

  protected async processFormValueAsync(data: any): Promise<any> {
    data = await super.processFormValueAsync(data);

    data.files = this.uploader.value
      .filter((uf: UploadableFile) => !!uf.uploaded)
      .map((uf: UploadableFile) => uf.uploaded);

    return data;
  }
}
