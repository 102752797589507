import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../../../shared/sdk/services/custom';

@Component({
  selector: 'app-dlg-add-comment',
  templateUrl: './dlg-add-comment.component.html',
  styleUrls: ['./dlg-add-comment.component.scss'],
})
export class DlgAddCommentComponent implements OnInit {
  value = '';

  constructor(
    private dialogRef: MatDialogRef<DlgAddCommentComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
  ) {
    this.value = data.value || '';
  }

  ngOnInit() {}
}
