<dx-data-grid
  [dataSource]="dso"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-editing [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <!-- Columns -->

  <dxi-column caption="Name" dataField="name" [allowHeaderFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Type" dataField="documentType_name" [allowHeaderFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Object Type" dataField="documentType_objectFQN" [allowHeaderFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    caption="File"
    dataField="files_0_originalFilename"
    cellTemplate="href_cell"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Uploaded At"
    dataField="files_0_uploadedAt"
    dataType="date"
    format="shortDateShortTime"
    [allowHeaderFiltering]="false"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'href_cell'">
    <a
      target="_blank"
      [href]="cellInfo.data.files_0_id | fileSignedUrl | async | sanitizeUrl"
      title="{{ cellInfo.displayValue }}"
      >{{ cellInfo.text }}</a>

  </ng-container>
</dx-data-grid>
