import { Component, Input, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { LoggerService, MyUser } from 'src/app/shared/sdk';
import { getUser } from 'src/app/store/reducers/sign';

@Component({
  selector: 'app-employee-ext-details-tabs',
  templateUrl: './employee-ext-details-tabs.component.html',
  styleUrls: ['./employee-ext-details-tabs.component.scss'],
})
export class EmployeeExtDetailsTabsComponent extends ABaseComponent implements OnInit {
  signedUser$: Observable<MyUser>;
  myRoles$: Observable<string[]>;
  tabs$: Observable<any[]>;

  @Input() modelId: number | string;

  constructor(protected logger: LoggerService, private common: CommonService, private config: ConfigService) {
    super(logger);

    this.signedUser$ = this.common.store.pipe(select(getUser));
    this.myRoles$ = this.config.roles$;
    this.buildItems();
  }

  private buildItems() {
    this.tabs$ = this.myRoles$.pipe(
      switchMap(roles => {
        return of([
          { title: 'Profile', template: 'profile' },
          { title: 'Employment', template: 'employee' },
          ...((roles.includes('HR_ADMIN') && [{ title: 'Payroll', template: 'payroll' }]) || []),
          { title: 'GeoTab Events', template: 'events' },
          { title: 'Docs', template: 'document' },
        ]);
      }),
      takeUntil(this.$onDestroy$),
    );
    this.tabs$.subscribe();
  }

  ngOnInit() {}
}
