import uniqBy from 'lodash-es/uniqBy';
import moment, { isMoment, Moment, utc } from 'moment';

export function asShortDate(date: any): string {
  return moment(date).format('YYYY-MM-DD');
}

export function asTime(date: any): string {
  return moment(date).format('HH:mm:ss');
}

export function asWeekday(date: any): string {
  return moment(date).format('dddd');
}

export function isoWeekdayToWeekday(day: number): string {
  return moment().isoWeekday(day).format('dddd');
}

export function startOfMonth(date: Date): string {
  return asShortDate(moment(date).startOf('month'));
}

export function endOfMonth(date: Date): string {
  return asShortDate(moment(date).endOf('month'));
}

export function applyTimezoneOffsetDelta(utcDate: Date | string, koef: number = 1): Date {
  const offset = new Date().getTimezoneOffset() * 60000;
  const _utc = utc(utcDate)[koef < 0 ? 'add' : 'subtract'](offset, 'milliseconds');
  return _utc.toDate();
}

export function fixLocalToUtcDate(localDate: Date): Date {
  const _utc = localDate.getTime() - localDate.getTimezoneOffset() * 60000;
  return new Date(_utc);
}

export function isoDateToShort(date: string) {
  const momentDate = moment.utc(date);
  if (
    momentDate.isValid() &&
    momentDate.utc().get('seconds') === 0 &&
    momentDate.utc().get('minutes') === 0 &&
    momentDate.utc().get('hours') === 0
  ) {
    return momentDate.utc().format('YYYY-MM-DD');
  }
}

export function discretizeDates(fromIncl: Moment | Date | string, toExcl: Moment | Date | string): string[] {
  const dates: string[] = [];

  let fromMoment = isMoment(fromIncl) ? fromIncl.clone() : moment(fromIncl);
  let toMoment = isMoment(toExcl) ? toExcl.clone() : moment(toExcl);

  fromMoment = fromMoment.startOf('day');
  toMoment = toMoment.startOf('day');

  do {
    dates.push(fromMoment.format('YYYY-MM-DD'));
    fromMoment.add(1, 'day').startOf('day');
  } while (toMoment.diff(fromMoment, 'hours') > 0);

  return dates;
}

export function discretizeMonths(
  fromIncl: Moment | Date | string,
  toExcl: Moment | Date | string,
  format = 'YYYY-MM',
): string[] {
  const dates = discretizeDates(fromIncl, toExcl);
  return uniqBy(dates, d => utc(d).format('YYYY-MM'))
    .sort()
    .map(d => utc(d).format(format));
}
