import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import moment from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { Employee, EmployeeApi, EmployeeWorkingTime, EmployeeWorkingTimeApi, LoggerService, } from 'src/app/shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';
import { HelperService } from '../../../services/helper.service';
import { PERSON_SEXES } from '../../../classes/enums';
import { asShortDate } from 'src/app/shared/classes/utils/time.utils';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
export class EmployeeWorkingTimeGridComponent extends ABaseComponent {
    constructor(logger, config, common, dss, helper, gridHelper, ui) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.common = common;
        this.dss = dss;
        this.helper = helper;
        this.gridHelper = gridHelper;
        this.ui = ui;
        this.sexes = PERSON_SEXES;
        this.weeks = [];
        this.days = [];
        this.dataSource = [];
        this.employees = [];
        this.workingTimes = [];
    }
    ngOnInit() {
        super.ngOnInit();
        this.generateWeeks();
        this.generateDays();
        this.loadData();
    }
    loadData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const [employees, workingTimes] = yield Promise.all([
                (!this.employees.length && this.getEmployees()) || this.employees,
                this.getEmployeeWorkingTimes(this.week),
            ]);
            this.employees = employees;
            this.workingTimes = workingTimes;
            this.buildData();
            this.ui.hideLoading();
        });
    }
    buildData() {
        let [wtMap, otMax, otMin] = this.workingTimes.reduce((p, v) => {
            const regMin = 40 * 60;
            const dd = this.days.filter(d => d.checked);
            const totalMinutes = dd.reduce((p, d) => v[d.fieldName] + p, 0);
            const [rm, om] = totalMinutes > regMin ? [regMin, totalMinutes - regMin] : [totalMinutes, 0];
            const days = dd.filter(d => v[d.fieldName] > 0).length;
            const otMax = Math.max(p[1], om);
            const otMin = om === 0 ? p[2] : Math.min(p[2], om);
            return [
                Object.assign({}, p[0], { [v.employeeId]: Object.assign({}, v, { totalMinutes, regularMinutes: rm, overtimeMinutes: om, days }) }),
                otMax,
                otMin,
            ];
        }, [{}, 0, Number.MAX_SAFE_INTEGER]);
        if (otMin > otMax)
            otMin = otMax;
        this.dataSource = this.employees
            .filter(e => wtMap[e.id])
            .map(e => (Object.assign({}, e, wtMap[e.id], { class: this.getOvertimeClass(wtMap[e.id].overtimeMinutes, otMax, otMin) })));
    }
    getOvertimeClass(overtimeMinutes, otMax, otMin) {
        if (!otMax || !overtimeMinutes)
            return '';
        const range = otMax - otMin;
        const quarter = range / 3;
        if (overtimeMinutes < otMin + quarter)
            return 'bg-success';
        else if (overtimeMinutes < otMin + 2 * quarter)
            return 'bg-warning';
        else
            return 'bg-danger';
    }
    generateWeeks() {
        const currentDate = moment(this.selectedDate);
        this.week = asShortDate(currentDate.startOf('week'));
        const previousMonth = currentDate.clone().subtract(1, 'months');
        const nextMonth = currentDate.clone().add(1, 'months');
        const startOfPreviousMonth = previousMonth.clone().startOf('month').startOf('week');
        const endOfNextMonth = nextMonth.clone().endOf('month').endOf('week');
        let current = startOfPreviousMonth.clone();
        while (current.isBefore(endOfNextMonth)) {
            const startOfWeek = current.clone().startOf('week');
            const endOfWeek = current.clone().endOf('week');
            const weekName = `${startOfWeek.format('YYYY')}-${startOfWeek.isoWeek()} ${startOfWeek.format('MMM DD')} - ${endOfWeek.format('MMM DD')}`;
            const weekValue = asShortDate(startOfWeek);
            this.weeks.push({ name: weekName, value: weekValue });
            current.add(1, 'week');
        }
    }
    generateDays() {
        this.days = [];
        for (let i = 0; i < 7; i++) {
            const [caption, fieldName, estimated] = this.getDay(i, true);
            const selected = moment(this.selectedDate).startOf('day');
            const day = moment(this.week).add(i - 1, 'days');
            const checked = !estimated ? true : day.isBefore(selected);
            this.days.push({ caption, fieldName, checked, estimated });
        }
    }
    repaint() {
        this.grid && this.grid.instance && this.grid.instance.repaint();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
    }
    grid_onToolbarPreparing(e) { }
    calendar_onValueChanged(e) {
        this.generateDays();
        this.loadData();
    }
    day_onChanged(event, day) {
        const inputElement = event.target;
        day.checked = inputElement.checked;
        this.buildData();
    }
    getDay(dayNum, full = false) {
        const day = moment(this.week).add(dayNum, 'days');
        const estimated = day.isAfter(moment().subtract(1, 'day')) ? ' (estimated)' : '';
        const ddd = day.format('ddd');
        const fieldName = `${ddd.toLocaleLowerCase()}Minutes`;
        const caption = `${ddd}, ${day.format('MMM DD')}${estimated}`;
        if (full)
            return [caption, fieldName, !!estimated];
        return caption;
    }
    getHours(minutes) {
        if (!minutes)
            return '00:00';
        let hh = Math.floor(minutes / 60);
        let mm = minutes % 60;
        return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`;
    }
    getEmployees() {
        return this.dss
            .getApi(Employee)
            .find({
            where: { employeePositionId: { inq: [39, 40, 273] } },
            include: ['employeePosition', 'person'],
        })
            .toPromise();
    }
    getEmployeeWorkingTimes(date) {
        return this.dss
            .getApi(EmployeeWorkingTime)
            .findAllByDate(date, headersAllTenantsAppend)
            .toPromise();
    }
}
