import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { oc } from 'ts-optchain';
import { ACL, Role } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';

@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss'],
})
export class AclComponent extends ABaseComponent implements OnInit {
  ds: DataSource;
  modelDso: any;
  roleDso: any;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    this.ds = dss.getDataSource(ACL);

    this.modelDso = ['*', ...dss.models.getModelNames().sort()];

    const rolesDSO = dss.getDataSourceOptions(Role);
    rolesDSO.postProcess = (data: Role[]) => {
      return [
        ...data
          .filter(r => !oc(r).name('').includes(':'))
          .map(r => oc(r).name(null))
          .sort(),
        '$everyone',
        '$authenticated',
        '$unauthenticated',
        '$owner',
        '$related',
      ];
    };

    this.roleDso = rolesDSO;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      // stateStoreGUID: 'f936b35f-9a3e-47b5-b8c8-586948bc29cb',
    });
  }
}
