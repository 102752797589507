import {
  MyUser,
  Employee,
  Vehicle
} from '../index';

declare var Object: any;
export interface VehicleCheckUpInterface {
  "requestID": string;
  "dateTime": Date;
  "deviceUid": string;
  "startedDateTime"?: Date;
  "completedDateTime"?: Date;
  "location"?: string;
  "inspectionExp"?: Date;
  "visibleLeaksUnderVehicle"?: boolean;
  "tiresConditionOk"?: boolean;
  "exteriorClean"?: boolean;
  "exteriorBodyDamage"?: boolean;
  "engineOilLevel"?: number;
  "coolantLevel"?: number;
  "emergencyDoorUnlocked"?: boolean;
  "engineStartDateTime"?: Date;
  "engineStartedWithNoIssues"?: boolean;
  "engineStartedWithIssuesComment"?: string;
  "warningGaugesOff"?: boolean;
  "odometer"?: number;
  "fuelLevel"?: number;
  "nextOilChange"?: number;
  "wiperFluidPresent"?: boolean;
  "wipersConditionOk"?: boolean;
  "headlightsAndTurnSignalsOk"?: boolean;
  "headlightsAndTurnSignalsProblems"?: string;
  "hornWorks"?: boolean;
  "kubotaKeyPresent"?: boolean;
  "cigaretteLighterWorks"?: boolean;
  "usbChargerOk"?: boolean;
  "usbChargingWirePresent"?: boolean;
  "serviceDoorOperational"?: boolean;
  "wheelchairLiftWorking"?: boolean;
  "electricRampWorking"?: boolean;
  "wcLiftHandPumpHandlePresent"?: boolean;
  "documentBinder"?: boolean;
  "wawaCard"?: string;
  "insuranceCardPresent"?: boolean;
  "registrationCardPresent"?: boolean;
  "registrationCardExp"?: Date;
  "accidentIncidentEnvelope"?: boolean;
  "driverHasDLonHands"?: boolean;
  "driverHasDMConHands"?: boolean;
  "beltCutterPresent"?: boolean;
  "firstAidKitPresent"?: boolean;
  "bloodBornePathogenKitPresent"?: boolean;
  "cleaningSuppliesPresent"?: boolean;
  "driverHasPPE"?: boolean;
  "fireExtinguisherPresent"?: boolean;
  "fireExtinguisherExp"?: Date;
  "fireExtinguisher2Present"?: boolean;
  "fireExtinguisher2Exp"?: Date;
  "thermometerPresent"?: boolean;
  "umbrella"?: boolean;
  "windshieldBrush"?: boolean;
  "handheldGpsPresent"?: boolean;
  "windshieldMountGpsHolder"?: boolean;
  "ezPassTransporterPresent"?: boolean;
  "emergencyTrianglesPresent"?: boolean;
  "jumperCablePresent"?: boolean;
  "seatBeltExtender"?: boolean;
  "broomPresent"?: boolean;
  "sanitizerDispenserWorksFilled"?: boolean;
  "snowShovel"?: boolean;
  "salt"?: boolean;
  "numberOfWheelchairHooks"?: number;
  "numberOfWheelchairBelts"?: number;
  "stepStoolPresent"?: boolean;
  "foldableWheelchairPresent"?: boolean;
  "foldableTransportChairPresent"?: boolean;
  "stairChairPresent"?: boolean;
  "stairwayWheelchairCarrierPresent"?: boolean;
  "stretcherPresent"?: boolean;
  "interiorClean"?: boolean;
  "IHaveDisinfectedSurfacesOfContact"?: boolean;
  "minutesOfEngineWarmUpIdleTime"?: number;
  "heatAcWorking"?: boolean;
  "frontHeatACWorking"?: boolean;
  "backHeatACWorking"?: boolean;
  "requestToSwapVehicle"?: boolean;
  "note"?: string;
  "engineIdleRunDurationTime"?: number;
  "durationTime"?: number;
  "resultedInSwap"?: boolean;
  "issuesNumber"?: number;
  "missingStuffNumber"?: number;
  "daysSinceLastCheck"?: number;
  "driversSupervisorOnDutyEmployeeId"?: number;
  "fleetManagerOnDutyEmployeeId"?: number;
  "safetyDirectorOnDutyEmployeeId"?: number;
  "vdate"?: number;
  "pictures"?: any;
  "meta"?: any;
  "summary"?: any;
  "utime"?: Date;
  "id"?: any;
  "userId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  user?: MyUser;
  employee?: Employee;
  vehicle?: Vehicle;
}

export class VehicleCheckUp implements VehicleCheckUpInterface {
  "requestID": string;
  "dateTime": Date;
  "deviceUid": string;
  "startedDateTime": Date;
  "completedDateTime": Date;
  "location": string;
  "inspectionExp": Date;
  "visibleLeaksUnderVehicle": boolean;
  "tiresConditionOk": boolean;
  "exteriorClean": boolean;
  "exteriorBodyDamage": boolean;
  "engineOilLevel": number;
  "coolantLevel": number;
  "emergencyDoorUnlocked": boolean;
  "engineStartDateTime": Date;
  "engineStartedWithNoIssues": boolean;
  "engineStartedWithIssuesComment": string;
  "warningGaugesOff": boolean;
  "odometer": number;
  "fuelLevel": number;
  "nextOilChange": number;
  "wiperFluidPresent": boolean;
  "wipersConditionOk": boolean;
  "headlightsAndTurnSignalsOk": boolean;
  "headlightsAndTurnSignalsProblems": string;
  "hornWorks": boolean;
  "kubotaKeyPresent": boolean;
  "cigaretteLighterWorks": boolean;
  "usbChargerOk": boolean;
  "usbChargingWirePresent": boolean;
  "serviceDoorOperational": boolean;
  "wheelchairLiftWorking": boolean;
  "electricRampWorking": boolean;
  "wcLiftHandPumpHandlePresent": boolean;
  "documentBinder": boolean;
  "wawaCard": string;
  "insuranceCardPresent": boolean;
  "registrationCardPresent": boolean;
  "registrationCardExp": Date;
  "accidentIncidentEnvelope": boolean;
  "driverHasDLonHands": boolean;
  "driverHasDMConHands": boolean;
  "beltCutterPresent": boolean;
  "firstAidKitPresent": boolean;
  "bloodBornePathogenKitPresent": boolean;
  "cleaningSuppliesPresent": boolean;
  "driverHasPPE": boolean;
  "fireExtinguisherPresent": boolean;
  "fireExtinguisherExp": Date;
  "fireExtinguisher2Present": boolean;
  "fireExtinguisher2Exp": Date;
  "thermometerPresent": boolean;
  "umbrella": boolean;
  "windshieldBrush": boolean;
  "handheldGpsPresent": boolean;
  "windshieldMountGpsHolder": boolean;
  "ezPassTransporterPresent": boolean;
  "emergencyTrianglesPresent": boolean;
  "jumperCablePresent": boolean;
  "seatBeltExtender": boolean;
  "broomPresent": boolean;
  "sanitizerDispenserWorksFilled": boolean;
  "snowShovel": boolean;
  "salt": boolean;
  "numberOfWheelchairHooks": number;
  "numberOfWheelchairBelts": number;
  "stepStoolPresent": boolean;
  "foldableWheelchairPresent": boolean;
  "foldableTransportChairPresent": boolean;
  "stairChairPresent": boolean;
  "stairwayWheelchairCarrierPresent": boolean;
  "stretcherPresent": boolean;
  "interiorClean": boolean;
  "IHaveDisinfectedSurfacesOfContact": boolean;
  "minutesOfEngineWarmUpIdleTime": number;
  "heatAcWorking": boolean;
  "frontHeatACWorking": boolean;
  "backHeatACWorking": boolean;
  "requestToSwapVehicle": boolean;
  "note": string;
  "engineIdleRunDurationTime": number;
  "durationTime": number;
  "resultedInSwap": boolean;
  "issuesNumber": number;
  "missingStuffNumber": number;
  "daysSinceLastCheck": number;
  "driversSupervisorOnDutyEmployeeId": number;
  "fleetManagerOnDutyEmployeeId": number;
  "safetyDirectorOnDutyEmployeeId": number;
  "vdate": number;
  "pictures": any;
  "meta": any;
  "summary": any;
  "utime": Date;
  "id": any;
  "userId": number;
  "employeeId": number;
  "vehicleId": number;
  user: MyUser;
  employee: Employee;
  vehicle: Vehicle;
  constructor(data?: VehicleCheckUpInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `VehicleCheckUp`.
   */
  public static getModelName() {
    return "VehicleCheckUp";
  }

  /**
  * This method creates an instance of VehicleCheckUp for dynamic purposes.
  **/
  public static factory(data: VehicleCheckUpInterface): VehicleCheckUp{
    return new VehicleCheckUp(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VehicleCheckUp',
      plural: 'VehicleCheckUps',
      path: 'VehicleCheckUps',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "deviceUid": {
          name: 'deviceUid',
          type: 'string'
        },
        "startedDateTime": {
          name: 'startedDateTime',
          type: 'Date'
        },
        "completedDateTime": {
          name: 'completedDateTime',
          type: 'Date'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "inspectionExp": {
          name: 'inspectionExp',
          type: 'Date'
        },
        "visibleLeaksUnderVehicle": {
          name: 'visibleLeaksUnderVehicle',
          type: 'boolean'
        },
        "tiresConditionOk": {
          name: 'tiresConditionOk',
          type: 'boolean'
        },
        "exteriorClean": {
          name: 'exteriorClean',
          type: 'boolean'
        },
        "exteriorBodyDamage": {
          name: 'exteriorBodyDamage',
          type: 'boolean'
        },
        "engineOilLevel": {
          name: 'engineOilLevel',
          type: 'number'
        },
        "coolantLevel": {
          name: 'coolantLevel',
          type: 'number'
        },
        "emergencyDoorUnlocked": {
          name: 'emergencyDoorUnlocked',
          type: 'boolean'
        },
        "engineStartDateTime": {
          name: 'engineStartDateTime',
          type: 'Date'
        },
        "engineStartedWithNoIssues": {
          name: 'engineStartedWithNoIssues',
          type: 'boolean'
        },
        "engineStartedWithIssuesComment": {
          name: 'engineStartedWithIssuesComment',
          type: 'string'
        },
        "warningGaugesOff": {
          name: 'warningGaugesOff',
          type: 'boolean'
        },
        "odometer": {
          name: 'odometer',
          type: 'number'
        },
        "fuelLevel": {
          name: 'fuelLevel',
          type: 'number'
        },
        "nextOilChange": {
          name: 'nextOilChange',
          type: 'number'
        },
        "wiperFluidPresent": {
          name: 'wiperFluidPresent',
          type: 'boolean'
        },
        "wipersConditionOk": {
          name: 'wipersConditionOk',
          type: 'boolean'
        },
        "headlightsAndTurnSignalsOk": {
          name: 'headlightsAndTurnSignalsOk',
          type: 'boolean'
        },
        "headlightsAndTurnSignalsProblems": {
          name: 'headlightsAndTurnSignalsProblems',
          type: 'string'
        },
        "hornWorks": {
          name: 'hornWorks',
          type: 'boolean'
        },
        "kubotaKeyPresent": {
          name: 'kubotaKeyPresent',
          type: 'boolean'
        },
        "cigaretteLighterWorks": {
          name: 'cigaretteLighterWorks',
          type: 'boolean'
        },
        "usbChargerOk": {
          name: 'usbChargerOk',
          type: 'boolean'
        },
        "usbChargingWirePresent": {
          name: 'usbChargingWirePresent',
          type: 'boolean'
        },
        "serviceDoorOperational": {
          name: 'serviceDoorOperational',
          type: 'boolean'
        },
        "wheelchairLiftWorking": {
          name: 'wheelchairLiftWorking',
          type: 'boolean'
        },
        "electricRampWorking": {
          name: 'electricRampWorking',
          type: 'boolean'
        },
        "wcLiftHandPumpHandlePresent": {
          name: 'wcLiftHandPumpHandlePresent',
          type: 'boolean'
        },
        "documentBinder": {
          name: 'documentBinder',
          type: 'boolean'
        },
        "wawaCard": {
          name: 'wawaCard',
          type: 'string'
        },
        "insuranceCardPresent": {
          name: 'insuranceCardPresent',
          type: 'boolean'
        },
        "registrationCardPresent": {
          name: 'registrationCardPresent',
          type: 'boolean'
        },
        "registrationCardExp": {
          name: 'registrationCardExp',
          type: 'Date'
        },
        "accidentIncidentEnvelope": {
          name: 'accidentIncidentEnvelope',
          type: 'boolean'
        },
        "driverHasDLonHands": {
          name: 'driverHasDLonHands',
          type: 'boolean'
        },
        "driverHasDMConHands": {
          name: 'driverHasDMConHands',
          type: 'boolean'
        },
        "beltCutterPresent": {
          name: 'beltCutterPresent',
          type: 'boolean'
        },
        "firstAidKitPresent": {
          name: 'firstAidKitPresent',
          type: 'boolean'
        },
        "bloodBornePathogenKitPresent": {
          name: 'bloodBornePathogenKitPresent',
          type: 'boolean'
        },
        "cleaningSuppliesPresent": {
          name: 'cleaningSuppliesPresent',
          type: 'boolean'
        },
        "driverHasPPE": {
          name: 'driverHasPPE',
          type: 'boolean'
        },
        "fireExtinguisherPresent": {
          name: 'fireExtinguisherPresent',
          type: 'boolean'
        },
        "fireExtinguisherExp": {
          name: 'fireExtinguisherExp',
          type: 'Date'
        },
        "fireExtinguisher2Present": {
          name: 'fireExtinguisher2Present',
          type: 'boolean'
        },
        "fireExtinguisher2Exp": {
          name: 'fireExtinguisher2Exp',
          type: 'Date'
        },
        "thermometerPresent": {
          name: 'thermometerPresent',
          type: 'boolean'
        },
        "umbrella": {
          name: 'umbrella',
          type: 'boolean'
        },
        "windshieldBrush": {
          name: 'windshieldBrush',
          type: 'boolean'
        },
        "handheldGpsPresent": {
          name: 'handheldGpsPresent',
          type: 'boolean'
        },
        "windshieldMountGpsHolder": {
          name: 'windshieldMountGpsHolder',
          type: 'boolean'
        },
        "ezPassTransporterPresent": {
          name: 'ezPassTransporterPresent',
          type: 'boolean'
        },
        "emergencyTrianglesPresent": {
          name: 'emergencyTrianglesPresent',
          type: 'boolean'
        },
        "jumperCablePresent": {
          name: 'jumperCablePresent',
          type: 'boolean'
        },
        "seatBeltExtender": {
          name: 'seatBeltExtender',
          type: 'boolean'
        },
        "broomPresent": {
          name: 'broomPresent',
          type: 'boolean'
        },
        "sanitizerDispenserWorksFilled": {
          name: 'sanitizerDispenserWorksFilled',
          type: 'boolean'
        },
        "snowShovel": {
          name: 'snowShovel',
          type: 'boolean'
        },
        "salt": {
          name: 'salt',
          type: 'boolean'
        },
        "numberOfWheelchairHooks": {
          name: 'numberOfWheelchairHooks',
          type: 'number'
        },
        "numberOfWheelchairBelts": {
          name: 'numberOfWheelchairBelts',
          type: 'number'
        },
        "stepStoolPresent": {
          name: 'stepStoolPresent',
          type: 'boolean'
        },
        "foldableWheelchairPresent": {
          name: 'foldableWheelchairPresent',
          type: 'boolean'
        },
        "foldableTransportChairPresent": {
          name: 'foldableTransportChairPresent',
          type: 'boolean'
        },
        "stairChairPresent": {
          name: 'stairChairPresent',
          type: 'boolean'
        },
        "stairwayWheelchairCarrierPresent": {
          name: 'stairwayWheelchairCarrierPresent',
          type: 'boolean'
        },
        "stretcherPresent": {
          name: 'stretcherPresent',
          type: 'boolean'
        },
        "interiorClean": {
          name: 'interiorClean',
          type: 'boolean'
        },
        "IHaveDisinfectedSurfacesOfContact": {
          name: 'IHaveDisinfectedSurfacesOfContact',
          type: 'boolean'
        },
        "minutesOfEngineWarmUpIdleTime": {
          name: 'minutesOfEngineWarmUpIdleTime',
          type: 'number'
        },
        "heatAcWorking": {
          name: 'heatAcWorking',
          type: 'boolean'
        },
        "frontHeatACWorking": {
          name: 'frontHeatACWorking',
          type: 'boolean'
        },
        "backHeatACWorking": {
          name: 'backHeatACWorking',
          type: 'boolean'
        },
        "requestToSwapVehicle": {
          name: 'requestToSwapVehicle',
          type: 'boolean'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "engineIdleRunDurationTime": {
          name: 'engineIdleRunDurationTime',
          type: 'number'
        },
        "durationTime": {
          name: 'durationTime',
          type: 'number'
        },
        "resultedInSwap": {
          name: 'resultedInSwap',
          type: 'boolean'
        },
        "issuesNumber": {
          name: 'issuesNumber',
          type: 'number'
        },
        "missingStuffNumber": {
          name: 'missingStuffNumber',
          type: 'number'
        },
        "daysSinceLastCheck": {
          name: 'daysSinceLastCheck',
          type: 'number'
        },
        "driversSupervisorOnDutyEmployeeId": {
          name: 'driversSupervisorOnDutyEmployeeId',
          type: 'number'
        },
        "fleetManagerOnDutyEmployeeId": {
          name: 'fleetManagerOnDutyEmployeeId',
          type: 'number'
        },
        "safetyDirectorOnDutyEmployeeId": {
          name: 'safetyDirectorOnDutyEmployeeId',
          type: 'number'
        },
        "vdate": {
          name: 'vdate',
          type: 'number'
        },
        "pictures": {
          name: 'pictures',
          type: 'any',
          default: <any>null
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "summary": {
          name: 'summary',
          type: 'any',
          default: <any>null
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
      }
    }
  }
}
