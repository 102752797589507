/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-details-tab-document.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/details-tab/details-tab.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/details-tab/details-tab.component";
import * as i4 from "../vehicle-document-grid/vehicle-document-grid.component.ngfactory";
import * as i5 from "../../services/helper.service";
import * as i6 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../document/services/helper.service";
import * as i8 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i9 from "../../../../shared/sdk/services/custom/Document";
import * as i10 from "../../../../shared/sdk/services/custom/DocumentView";
import * as i11 from "../../../../shared/sdk/services/custom/MyFile";
import * as i12 from "../vehicle-document-grid/vehicle-document-grid.component";
import * as i13 from "../../../../shared/sdk/services/custom/logger.service";
import * as i14 from "../../../../shared/modules/ui/services/ui.service";
import * as i15 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i18 from "../../../../shared/sdk/services/custom/MyUser";
import * as i19 from "./vehicle-details-tab-document.component";
var styles_VehicleDetailsTabDocumentComponent = [i0.styles];
var RenderType_VehicleDetailsTabDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleDetailsTabDocumentComponent, data: {} });
export { RenderType_VehicleDetailsTabDocumentComponent as RenderType_VehicleDetailsTabDocumentComponent };
export function View_VehicleDetailsTabDocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-details-tab", [], null, null, null, i2.View_DetailsTabComponent_0, i2.RenderType_DetailsTabComponent)), i1.ɵdid(1, 49152, null, 0, i3.DetailsTabComponent, [], { loading: [0, "loading"], toolbarItems: [1, "toolbarItems"], model: [2, "model"], errorMessage: [3, "errorMessage"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "app-vehicle-document-grid", [], null, null, null, i4.View_VehicleDocumentGridComponent_0, i4.RenderType_VehicleDocumentGridComponent)), i1.ɵprd(512, null, i5.HelperService, i5.HelperService, [i6.VehicleApi]), i1.ɵprd(512, null, i7.HelperService, i7.HelperService, [i8.UploadHelperService, i9.DocumentApi, i10.DocumentViewApi, i11.MyFileApi]), i1.ɵdid(6, 245760, null, 0, i12.VehicleDocumentGridComponent, [i13.LoggerService, i14.UiService, i15.DataSourceService, i16.MatDialog, i5.HelperService, i7.HelperService, i17.GridHelperService, i18.MyUserApi], { model: [0, "model"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.toolbarItems; var currVal_2 = _co.model; var currVal_3 = _co.errorMessage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.model; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_VehicleDetailsTabDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-vehicle-details-tab-document", [], null, null, null, View_VehicleDetailsTabDocumentComponent_0, RenderType_VehicleDetailsTabDocumentComponent)), i1.ɵprd(512, null, i5.HelperService, i5.HelperService, [i6.VehicleApi]), i1.ɵprd(512, null, i7.HelperService, i7.HelperService, [i8.UploadHelperService, i9.DocumentApi, i10.DocumentViewApi, i11.MyFileApi]), i1.ɵdid(3, 770048, null, 0, i19.VehicleDetailsTabDocumentComponent, [i13.LoggerService, i14.UiService, i15.DataSourceService, i16.MatDialog, i5.HelperService, i7.HelperService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var VehicleDetailsTabDocumentComponentNgFactory = i1.ɵccf("app-vehicle-details-tab-document", i19.VehicleDetailsTabDocumentComponent, View_VehicleDetailsTabDocumentComponent_Host_0, { loading: "loading", modelId: "modelId", caption: "caption" }, { modelLoadingError: "modelLoadingError", loadingChange: "loadingChange", modelIdChange: "modelIdChange", beforeLoading: "beforeLoading", loaded: "loaded", afterLoaded: "afterLoaded" }, []);
export { VehicleDetailsTabDocumentComponentNgFactory as VehicleDetailsTabDocumentComponentNgFactory };
