<dx-data-grid
  #grid
  width="100%"
  height="100%"
  [dataSource]="dso"
  [stateStoring]="grid_stateStoring"
  [searchPanel]="{ visible: false }"
  [headerFilter]="{ visible: true }"
  [filterRow]="{ visible: true }"
  [filterPanel]="{ visible: true }"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

  <dxo-export [enabled]="true" fileName="MTM Claims Has Old Version Copy"></dxo-export>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

  <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

  <dxo-load-panel [shading]="true"></dxo-load-panel>

  <!-- region Columns -->

  <dxi-column caption="trip id" dataField="trip id" dataType="string"></dxi-column>

  <dxi-column
    caption="service date"
    dataField="service date"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [customizeText]="config.customizeShortDateText"></dxi-column>

  <dxi-column caption="claim status" dataField="claim status" dataType="string"></dxi-column>

  <dxi-column caption="claim member" dataField="claim member" dataType="string"></dxi-column>

  <dxi-column caption="created" dataField="_claim._ctime" dataType="datetime" format="shortDateShortTime"></dxi-column>

  <dxi-column caption="replaced" dataField="_claim._rtime" dataType="datetime" format="shortDateShortTime"></dxi-column>

  <dxi-column caption="auth type" dataField="auth type" dataType="string"></dxi-column>

  <dxi-column caption="auth status" dataField="auth status" dataType="string"></dxi-column>

  <dxi-column caption="signature service type" dataField="signature service type" dataType="string"></dxi-column>

  <dxi-column caption="signature marker" dataField="signature marker" dataType="string"></dxi-column>

  <dxi-column caption="signature status" dataField="signature status" dataType="string"></dxi-column>

  <dxi-column
    caption="signature validation state"
    dataField="signature validation state"
    dataType="string"></dxi-column>

  <!-- endregion Columns -->

  <!-- region Templates -->

  <div *dxTemplate="let info of 'detail'">
    <dx-tab-panel>
      <dxi-item title="Claims">
        <dx-data-grid [dataSource]="info.data._recs">
          <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

          <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

          <div *dxTemplate="let info of 'detail'">
            <dx-tab-panel>
              <dxi-item title="Trip">
                <pre>{{ safeGetSource(info.data._trip) | filterJson | json }}</pre>
              </dxi-item>

              <dxi-item title="Claim">
                <pre>{{ safeGetSource(info.data._claim) | filterJson | json }}</pre>
              </dxi-item>

              <dxi-item title="Signature">
                <pre>{{ safeGetSource(info.data._sign) | filterJson | json }}</pre>
              </dxi-item>
            </dx-tab-panel>
          </div>
        </dx-data-grid>
      </dxi-item>
    </dx-tab-panel>
  </div>

  <!-- endregion Templates -->
</dx-data-grid>
