import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { compact, flatten, identity, isObjectLike, pickBy, set, uniqBy } from 'lodash-es';
import isEmpty from 'lodash-es/isEmpty';
import mapKeys from 'lodash-es/mapKeys';
import sortBy from 'lodash-es/sortBy';
import moment, { utc } from 'moment/moment';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from '../../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll, hasAmbTrips } from '../../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../../shared/modules/ui/components/abstract/a-base.component';
import { DlgSelectSignatureComponent } from '../../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { FullNamePipe } from '../../../../../shared/modules/ui/pipes/full-name.pipe';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import { Consumer, Employee, Facility, LoggerService, MyUser, SignatureApi, SignatureViewApi, Vehicle, } from '../../../../../shared/sdk';
import { getUser } from '../../../../../store/reducers/sign';
import { DlgEditServiceTypeComponent } from '../../../../billing/dialogs/dlg-edit-service-type/dlg-edit-service-type.component';
import { DlgEditTimesComponent } from '../../../../billing/dialogs/dlg-edit-times/dlg-edit-times.component';
import { DlgEditUnitsComponent } from '../../../../billing/dialogs/dlg-edit-units/dlg-edit-units.component';
import { DIRECTION_MARKERS, SERVICE_TYPE } from '../../../../trip-manifest/classes/enums';
import { DlgSelectDriverComponent } from '../../../../trip-manifest/dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgSelectVehicleComponent } from '../../../../trip-manifest/dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
export class ServiceValidationV2Component extends ABaseComponent {
    // @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
    // @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;
    constructor(logger, router, ui, common, config, dss, sss, pusher, notification, http, dialog, 
    // private gridHelper: GridHelperService,
    sapi, svapi) {
        super(logger);
        this.logger = logger;
        this.router = router;
        this.ui = ui;
        this.common = common;
        this.config = config;
        this.dss = dss;
        this.sss = sss;
        this.pusher = pusher;
        this.notification = notification;
        this.http = http;
        this.dialog = dialog;
        this.sapi = sapi;
        this.svapi = svapi;
        this.changes = [];
        this.editRowKey = null;
        this.$filterEvent$ = new BehaviorSubject(false);
        this.$cmpAddresses$ = new BehaviorSubject([]);
        this.facilityDso$ = of([]);
        this.consumerDso$ = of([]);
        this.employeeDso$ = of([]);
        this.vehicleDso$ = of([]);
        this.consumerSubDso$ = of([]);
        this.employeeSubDso$ = of([]);
        this.vehicleSubDso$ = of([]);
        this.serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
        this.directionMarkers = [...Object.values(DIRECTION_MARKERS), ...(hasAmbTrips() ? ['TO_AMB', 'FROM_AMB'] : [])];
        this.validationStates = [
            { v: 'PENDING', t: 'Not Validated' },
            { v: 'VALID', t: 'Valid' },
            { v: 'INVALID', t: 'Invalid' },
            { v: 'ISSUES', t: 'Issues' },
            { v: 'FULL_AUTO_VALIDATED', t: 'Full Auto Validated' },
            { v: 'PARTIAL_AUTO_VALIDATED', t: 'Partial Auto Validated' },
        ];
        //region filter
        this.selectedFromValue = moment().toDate();
        this.selectedToValue = moment().toDate();
        this.withDriverSigns = false;
        this.withEscortSigns = false;
        // broker?: string;
        // mco?: string;
        // hasClaim?: boolean;
        this.minPuDoDiff = 5;
        this.maxPuDoDiff = 120;
        this.maxPuLgtcPuDiff = 60;
        //endregion
        this.tripDsoMap = new Map();
        this.vehicleDsMap = {};
        this.driverDsMap = {};
        //
        // toolTipVisible: { [column: string]: { [id: string]: boolean } } = {};
        this.toolTipDataMap = {};
        this.getPersonName = (doc) => {
            return new FullNamePipe(this.config).transform(doc);
        };
        this.grid_stateStoring = this.sss.buildOptions('d5f344c3-35ee-4fd4-bb63-71809eef494f1', 1000, 'local', this.sss.resetState);
        this.dso = this.buildEmptyDataSource();
        this.facilityDso$ = this.buildFacilityDataSource();
        this.consumerDso$ = this.buildConsumerDataSource();
        this.employeeDso$ = this.buildEmployeeDataSource();
        this.vehicleDso$ = this.buildVehicleDataSource();
    }
    ngOnInit() {
        super.ngOnInit();
        this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
        this.$filterEvent$
            .pipe(filter(arg => arg), tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.grid.instance.endCustomLoading();
            this.grid.instance.clearFilter();
            this.grid.instance.clearSelection();
            // await this.grid.instance.deselectAll();
            const curState = Object.assign({}, this.grid.instance.state(), this.sss.resetState);
            // console.log('curState', curState);
            this.grid.instance.state(curState);
            // this.$items$.next([]);
            this.dso = this.buildEmptyDataSource();
            this.grid.instance.beginCustomLoading('Filtering...');
            this.grid.instance.repaint();
        })), switchMap(() => this.buildDataSource().pipe(catchError(err => {
            notify(err.message, 'error', 5000);
            return of(this.buildEmptyDataSource());
        }))), tap(dso => {
            this.grid.instance.endCustomLoading();
            this.dso = dso;
            // this.$items$.next(items);
            this.grid.instance.refresh();
        }), tap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.grid.instance.clearFilter();
            // this.grid.instance.clearSelection();
            // await this.grid.instance.deselectAll();
        })), takeUntil(this.$onDestroy$))
            .subscribe();
        this.$cmpAddresses$
            .pipe(filter(keyOrObjs => keyOrObjs.length > 0), 
        // delay(100),
        // mergeMap(identity),
        // tap(() => this.grid.instance.beginUpdate()),
        mergeMap(keyOrObjs => {
            return of(keyOrObjs.map(keyOrObj => {
                const key = isObjectLike(keyOrObj) ? keyOrObj.key : keyOrObj;
                const tripId = isObjectLike(keyOrObj) ? keyOrObj.tripId : undefined;
                return { key, tripId };
            })).pipe(tap(objs => {
                // this.gridItems
                //   .filter(item => objs.map(o => o.key).includes(item.id))
                //   .forEach(item => {
                //     item._addresses = {};
                //     item._addrMismatch = '...';
                //   });
                this.gridStore.push(objs.map(({ key, tripId }) => ({
                    type: 'update',
                    data: { _addresses: {}, _addrMismatch: '...' },
                    key,
                })));
            }), 
            // mergeMap(identity),
            // mergeMap(({ key, tripId }) =>
            //   this.sapi.getManifestAndTripAddresses(key, tripId, hAll).pipe(
            //     catchError(err => of(null)),
            //     tap(res => {
            //       const mismatch =
            //         res &&
            //         res.service &&
            //         res.trip &&
            //         (res.service.to || res.service.from) &&
            //         (res.trip.to || res.trip.from)
            //           ? {
            //               to: res.service.to !== res.trip.to,
            //               from: res.service.from !== res.trip.from,
            //               both: res.service.to !== res.trip.to && res.service.from !== res.trip.from,
            //               swapped: res.service.to === res.trip.from || res.service.from === res.trip.to,
            //             }
            //           : undefined;
            //
            //       const change = {
            //         type: 'update',
            //         data: {
            //           _addresses: res || {},
            //           _addrMismatch: mismatch
            //             ? mismatch.both
            //               ? mismatch.swapped
            //                 ? 'swapped'
            //                 : 'both'
            //               : mismatch.to
            //               ? 'dest'
            //               : mismatch.from
            //               ? 'origin'
            //               : 'valid'
            //             : '',
            //         },
            //         key,
            //       };
            //
            //       this.gridItems
            //         .filter(item => item.id === key)
            //         .forEach(item => {
            //           item._addresses = change.data._addresses;
            //           item._addrMismatch = change.data._addrMismatch;
            //         });
            //
            //       // this.gridStore.push([change]);
            //     }),
            //   ),
            // ),
            //
            // delay(100),
            // tap(() => this.grid.instance.beginUpdate()),
            mergeMap((objs) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return Promise.all(objs.map(({ key, tripId }) => this.sapi
                    .getManifestAndTripAddresses(key, tripId, hAll)
                    .pipe(catchError(err => of(null)), map(res => ({ key, tripId, res })))
                    .toPromise()));
            })), catchError(err => of([])), tap(results => {
                const changes = results.map(({ key, tripId, res }) => {
                    const mismatch = res &&
                        res.service &&
                        res.trip &&
                        (res.service.to || res.service.from) &&
                        (res.trip.to || res.trip.from)
                        ? {
                            to: res.service.to !== res.trip.to,
                            from: res.service.from !== res.trip.from,
                            both: res.service.to !== res.trip.to && res.service.from !== res.trip.from,
                            swapped: res.service.to === res.trip.from || res.service.from === res.trip.to,
                        }
                        : undefined;
                    return {
                        type: 'update',
                        data: {
                            _addresses: res || {},
                            _addrMismatch: mismatch
                                ? mismatch.both
                                    ? mismatch.swapped
                                        ? 'swapped'
                                        : 'both'
                                    : mismatch.to
                                        ? 'dest'
                                        : mismatch.from
                                            ? 'origin'
                                            : 'valid'
                                : '',
                        },
                        key,
                    };
                });
                // this.gridItems
                //   .filter(item => changes.map(o => o.key).includes(item.id))
                //   .forEach(item => {
                //     const change = changes.find(o => o.key === item.id);
                //     item._addresses = change.data._addresses;
                //     item._addrMismatch = change.data._addrMismatch;
                //   });
                this.gridStore.push(changes);
            }));
        }), catchError(err => of(console.error(err))), 
        // tap(() => this.grid.instance.endUpdate()),
        takeUntil(this.$onDestroy$))
            .subscribe();
        this.signedEmployee$ = this.common.store.select(getUser).pipe(switchMap(u => this.dss.getApi(MyUser).findById(u.id, { include: [{ employee: 'person' }] })), map(u => u.employee));
    }
    // region data sources
    buildEmptyDataSource() {
        // return { store: new ArrayStore({ data: [], key: 'id' }) } as DataSourceOptions;
        return new DataSource({ store: new ArrayStore({ data: [], key: 'id' }), key: 'id' });
    }
    buildDataSource() {
        return of(true).pipe(map(() => {
            const fromIncl = this.selectedFromValue;
            const toExcl = this.selectedToValue;
            const fromMoment = fromIncl && moment(fromIncl);
            const toMoment = toExcl && moment(toExcl).add(1, 'days');
            if (!fromMoment || !toMoment) {
                throw new Error('Period should be defined');
            }
            if (toMoment.diff(fromMoment, 'months') > 1) {
                throw new Error('Period should be less or equal to 1 month');
            }
            const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
            const strTo = toMoment && toMoment.format('YYYY-MM-DD');
            return {
                fromIncl: strFrom,
                toExcl: strTo,
                mci: this.mci,
                // mco: this.mco,
                // broker: this.broker,
                // tripDayClosed: this.tripDayClosed,
                dayServiceMarker: this.dayServiceMarker,
                validationState: this.validationState,
                serviceType: this.serviceType,
                directionMarker: this.directionMarker,
                hasTrip: this.hasTrip,
                // hasClaim: this.hasClaim,
                hasMissingData: this.hasMissingData,
                possibleIssues: this.possibleIssues,
                facilityIds: this.facilityId ? [this.facilityId] : [],
                driverIds: this.driverId ? [this.driverId] : [],
                clientIds: this.clientId ? [this.clientId] : [],
                minPuDoDiff: this.minPuDoDiff,
                maxPuDoDiff: this.maxPuDoDiff,
                maxPuLgtcPuDiff: this.maxPuLgtcPuDiff,
                withDriverSigns: this.withDriverSigns,
                withEscortSigns: this.withEscortSigns,
            };
        }), switchMap(_filter => this.pusher.rpc('GET_SERVICES_V2', Object.assign({}, _filter, { useRunService: true }), true, hAll)), map(coll => {
            const _self = this;
            this.dataCollection = coll;
            this.employeeSubDso$ = this.buildEmployeeSubDataSource();
            this.consumerSubDso$ = this.buildConsumerSubDataSource();
            this.vehicleSubDso$ = this.buildVehicleSubDataSource();
            return this.dss.createMongoStore(coll, undefined, {
                key: 'id',
            });
        }), switchMap((store) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const _self = this;
            return new ArrayStore({
                key: 'id',
                data: yield store.load(),
                // name: 'validateServices',
                // immediate: true,
                // flushInterval: 1000,
                onPush(changes) {
                    // console.log('changes:', changes);
                    // changes.filter(ch => ch.type === 'update').forEach(ch => void self.gridStore.update(ch.key, ch.data));
                    // changes
                    //   .filter(ch => ch.type === 'update')
                    //   .forEach(ch => {
                    //     const doc = _self.gridItems.find(itm => itm.id === ch.key);
                    //     _self.setIssueData({ ...doc, ...ch.data });
                    //   });
                },
                onUpdated(key, values) {
                    // console.log('onUpdated', key, values);
                    const doc = _self.gridItems.find(itm => itm.id === key);
                    _self.setIssueData(Object.assign({}, doc, values), true);
                },
            });
        })), map(store => {
            this.tripDsoMap.clear();
            return {
                store,
                reshapeOnPush: true,
                map: (doc) => {
                    this.tripDsoMap.set(doc.id, doc._trips);
                    doc.getNote = function () {
                        const _self = this;
                        const notes = [];
                        if (oc(_self).meta.skipEmployeeId())
                            notes.push('Skipped by driver');
                        return notes.join('.\n');
                    }.bind(doc);
                    // region locked
                    doc._tripIdLocked = function () {
                        const _self = this;
                        return (oc(_self).meta.partValidated() ||
                            oc(_self).meta.fullValidated() ||
                            oc(_self)._trips([]).length === 0 ||
                            oc(_self)._claimStatus() === 'Paid');
                    }.bind(doc);
                    // doc._lockedPu = function () {
                    //   const _self = this;
                    //   return _self._locked() && !!_self.pickupTime;
                    // }.bind(doc);
                    //
                    // doc._lockedDo = function () {
                    //   const _self = this;
                    //   return _self._locked() && !!_self.dropoffTime;
                    // }.bind(doc);
                    //
                    // doc._lockedFull = function () {
                    //   const _self = this;
                    //   return _self._lockedPu() && _self._lockedDo();
                    // }.bind(doc);
                    // endregion
                    // doc._trip = oc(doc)._trip(doc._possibleTrip);
                    [doc._trip, ...doc._trips].filter(identity).forEach(t => {
                        set(t, '__claim.__status', oc(t).__claim.__status(null));
                        set(t, '__miles', oc(t)['Trip Mileage']() || oc(t).miles());
                    });
                    //
                    this.setIssueData(doc, false);
                    return doc;
                },
                postProcess: (groupsOrItems) => {
                    // const data = flatten(groupsOrItems.map(itm => (itm.key && itm.items ? itm.items : [itm])));
                    // this.$cmpAddresses$.next(data.map(doc => ({ key: doc.id, tripId: oc(doc)._trip._tripId() })));
                    return groupsOrItems;
                },
            };
        }));
    }
    buildFacilityDataSource() {
        const so = this.dss.getStoreOptions(Facility, undefined, false);
        so.customFilter = {
            where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
            order: ['typeOrder DESC', 'type', 'shortname'],
        };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildConsumerDataSource() {
        const so = this.dss.getStoreOptions(Consumer, undefined, false);
        so.customHeaders = {
            'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1',
        };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildEmployeeDataSource() {
        const so = this.dss.getStoreOptions(Employee, undefined, false);
        so.customHeaders = {
            'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1',
        };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    buildVehicleDataSource() {
        const so = this.dss.getStoreOptions(Vehicle, undefined, false);
        // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
        // so.customHeaders = hAll;
        so.customHeaders = {
            'X-Current-Tenant': '-1',
        };
        const dso = {
            store: new CustomStore(so),
        };
        return of(dso);
    }
    //
    buildConsumerSubDataSource() {
        return gqlMongoLoad(this.dss, this.dataCollection, {
            select: { consumerId: true, consumer_person_firstname: true, consumer_person_lastname: true },
        }).pipe(map(docs => uniqBy(docs, 'consumerId').filter(d => d.consumerId)), map(docs => {
            docs.forEach(doc => {
                doc.name = doc.consumer_person_firstname + ' ' + doc.consumer_person_lastname;
            });
            docs = sortBy(docs, 'name');
            // const so = this.dss.getStoreOptions(ConsumerView, null, false) as LoopBackStoreOptions<any, any>;
            // so.customHeaders = headersAllTenantsAppend;
            const dso = {
                store: new ArrayStore({ data: docs, key: 'consumerId' }),
            };
            return dso;
        }));
    }
    buildEmployeeSubDataSource() {
        return gqlMongoLoad(this.dss, this.dataCollection, {
            select: { employeeId: true, employee_person_firstname: true, employee_person_lastname: true },
        }).pipe(map(docs => uniqBy(docs, 'employeeId').filter(d => d.employeeId)), map(docs => {
            docs.forEach(doc => {
                doc.name = doc.employee_person_firstname + ' ' + doc.employee_person_lastname;
            });
            docs = sortBy(docs, 'name');
            // const so = this.dss.getStoreOptions(EmployeeView, null, false) as LoopBackStoreOptions<any, any>;
            // so.customHeaders = headersAllTenantsAppend;
            const dso = {
                store: new ArrayStore({ data: docs, key: 'employeeId' }),
            };
            return dso;
        }));
    }
    buildVehicleSubDataSource() {
        return gqlMongoLoad(this.dss, this.dataCollection, {
            select: { vehicleId: true, vehicle_internalId: true },
        }).pipe(map(docs => uniqBy(docs, 'vehicleId').filter(d => d.vehicleId)), map(docs => {
            docs = sortBy(docs, 'vehicle_internalId');
            // const so = this.dss.getStoreOptions(Vehicle, null, false) as LoopBackStoreOptions<any, any>;
            // so.customHeaders = headersAllTenantsAppend;
            const dso = {
                store: new ArrayStore({ data: docs, key: 'vehicleId' }),
            };
            return dso;
        }));
    }
    //endregion
    setMissingData(doc, push = false) {
        const _missingData = {
            consumerId: !doc.consumerId ? 'Missing Client' : undefined,
            employeeId: !doc.employeeId ? 'Missing Driver' : undefined,
            vehicleId: !doc.vehicleId ? 'Missing Vehicle' : undefined,
            arrivedTime: isEmpty(doc.arrivedTime) ? 'Missing Arrived Time' : undefined,
            pickupTime: isEmpty(doc.pickupTime) ? 'Missing PU Time' : undefined,
            dropoffTime: isEmpty(doc.dropoffTime) ? 'Missing DO Time' : undefined,
            imgFileId: isEmpty(doc.imgFileId) ? 'Missing Signature Image' : undefined,
            marker: isEmpty(doc.marker) ? 'Missing Direction Marker' : undefined,
        };
        const _missingList = Object.entries(_missingData)
            .map(([, lbl]) => lbl)
            .filter(lbl => !isEmpty(lbl));
        doc._missingData = _missingData;
        doc._missingList = _missingList;
        if (push) {
            this.gridStore.push([
                {
                    type: 'update',
                    data: {
                        _missingData,
                        _missingList,
                    },
                    key: doc.id,
                },
            ]);
        }
    }
    setIssueData(doc, push = false) {
        this.setMissingData(doc, push);
        const arrTime = oc(doc).arrivedTime() ? moment(oc(doc).arrivedTime(), 'HH:mm:ss') : null;
        const puTime = oc(doc).pickupTime() ? moment(oc(doc).pickupTime(), 'HH:mm:ss') : null;
        const doTime = oc(doc).dropoffTime() ? moment(oc(doc).dropoffTime(), 'HH:mm:ss') : null;
        const lgtcPu = oc(doc)._trip.pu_time() ? moment(oc(doc)._trip.pu_time(), 'HH:mm') : null;
        const _issueData = [
            {
                field: ['marker'],
                issue: doc.marker === DIRECTION_MARKERS.UNKNOWN ? 'Direction Marker is UNKNOWN' : undefined,
            },
            {
                field: ['pickupTime', 'dropoffTime'],
                issue: puTime && doTime && doTime.diff(puTime, 'minutes', true) < this.minPuDoDiff
                    ? `DO and PU diff less than ${this.minPuDoDiff} min`
                    : undefined,
            },
            {
                field: ['pickupTime', 'dropoffTime'],
                issue: puTime && doTime && doTime.diff(puTime, 'minutes', true) > this.maxPuDoDiff
                    ? `DO and PU diff more than ${this.maxPuDoDiff} min`
                    : undefined,
            },
            {
                field: ['pickupTime'],
                issue: puTime && lgtcPu && Math.abs(lgtcPu.diff(puTime, 'minutes', true)) > this.maxPuLgtcPuDiff
                    ? `Broker PU (${oc(doc)._trip.pu_time()}) and Driver PU diff more than ${this.maxPuLgtcPuDiff} min`
                    : undefined,
            },
        ];
        const _issueFields = flatten(_issueData.filter(i => i.issue).map(i => i.field)).reduce((o, f) => (Object.assign({}, o, { [f]: true })), {});
        const _issueList = [...doc._missingList, ..._issueData.map(i => i.issue)].filter(lbl => !isEmpty(lbl));
        doc._issueData = _issueData;
        doc._issueFields = _issueFields;
        doc._issueList = _issueList;
        if (push) {
            this.gridStore.push([
                {
                    type: 'update',
                    data: {
                        _issueData,
                        _issueFields,
                        _issueList,
                    },
                    key: doc.id,
                },
            ]);
        }
        // console.log('getIssueData', doc);
    }
    get gridStore() {
        return oc(this.grid).instance() ? this.grid.instance.getDataSource().store() : undefined;
    }
    get gridItems() {
        return oc(this.grid).instance() ? this.grid.instance.getDataSource().items() : undefined;
    }
    storeUpdate(key, values, options = { setItem: false }) {
        if (options.setItem)
            this.gridItems.filter(i => i.id === key).forEach(i => Object.keys(values).forEach(k => (i[k] = values[k])));
        this.gridStore.push([{ type: 'update', data: values, key }]);
        return this.gridStore.update(key, values);
    }
    filter() {
        this.$filterEvent$.next(true);
    }
    // toggleTooltip(cellInfo: any, hover: boolean) {
    //   this.toolTipVisible[cellInfo.column.name][cellInfo.data.id] = hover;
    // }
    getPrevTimes(data, tooltip) {
        if (!this.toolTipDataMap['timesPuDo:' + data.id]) {
            const fromIncl = utc(data.vdate).startOf('week');
            const toIncl = utc(data.vdate).endOf('week');
            const toExcl = toIncl.clone().add(1, 'day');
            this.toolTipDataMap['timesPuDo:' + data.id] = of(data).pipe(switchMap(e => combineLatest([
                this.sapi.find({
                    where: {
                        and: [
                            { tenantId: e.tenantId },
                            { consumerId: e.consumerId },
                            { vdate: { lt: e.vdate } },
                            { weekday: utc(e.vdate).isoWeekday() - 1 },
                            { type: e.type },
                            { marker: e.marker },
                            { validationState: { nin: ['INVALID', 'PENDING'] } },
                        ],
                    },
                    order: 'vdate DESC',
                    limit: 1,
                }, hAll),
                this.sapi.find({
                    where: {
                        and: [
                            { vdate: { gte: fromIncl.format('YYYY-MM-DD') } },
                            { vdate: { lt: toExcl.format('YYYY-MM-DD') } },
                            { tenantId: e.tenantId },
                            { consumerId: e.consumerId },
                            { type: e.type },
                            { marker: e.marker },
                            { validationState: { nin: ['INVALID', 'PENDING'] } },
                        ],
                    },
                    order: 'vdate DESC',
                    limit: 1,
                }, hAll),
            ])), map(([signs1, signs2]) => [signs1[0], ...signs2]), map(signs => '<table>' +
                [
                    ['', ...compact(signs).map(s => utc(s.vdate).format('MM/DD ddd'))].map(i => `<td>${i}</td>`).join(''),
                    ['arr', ...compact(signs).map(s => moment(s.arrivedTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                    ['pu', ...compact(signs).map(s => moment(s.pickupTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                    ['do', ...compact(signs).map(s => moment(s.dropoffTime, 'HH:mm:ss').format('HH:mm'))]
                        .map(i => `<td>${i}</td>`)
                        .join(''),
                ]
                    .map(i => `<tr>${i}</tr>`)
                    .join('') +
                '</table>'), tap(() => setTimeout(() => {
                tooltip.instance.repaint();
            }, 10)), catchError(err => of('')), startWith('...'));
        }
        return this.toolTipDataMap['timesPuDo:' + data.id];
    }
    from_onValueChanged(e) {
        // console.log(e);
        this.selectedToValue = moment(e.value).toDate();
    }
    facility_onSelectionChanged(e) {
        // console.log(e.selectedItem);
        this.consumerDso$ = this.buildConsumerDataSource();
        this.employeeDso$ = this.buildEmployeeDataSource();
        this.vehicleDso$ = this.buildVehicleDataSource();
    }
    tripId_selectedItemChange(cellInfo, e) {
        // this runs on init grid to
        // console.log(cellInfo, e.selectedItem);
        // console.log('tripId_onSelectionChanged', oc(cellInfo).data._trip._tripId(), oc(e.selectedItem)._tripId());
        // runs on every initialization!!!
        // const newTrip = e.selectedItem;
        // this.$cmpAddresses$.next([{ key: cellInfo.key, tripId: oc(newTrip)._tripId() }]);
    }
    tripId_onValueChanged(cellInfo, e) {
        // this not runs on init grid
        // console.log('tripId_onValueChanged', oc(cellInfo).data._trip._tripId(), oc(e).value());
        const newTripId = e.value;
        const newTrip = cellInfo.data._trips.find(t => t._tripId === newTripId);
        //
        void this.storeUpdate(cellInfo.key, { _trip: oc(newTrip)(null) });
        // this.$cmpAddresses$.next([{ key: cellInfo.key, tripId: oc(newTrip)._tripId() }]);
        this.grid.instance.repaintRows([cellInfo.rowIndex]);
    }
    grid_onInitialized(e) {
        this.grid.instance.clearSelection();
    }
    grid_onCellClick(e) {
        if (e.rowType === 'data' && e.column.caption === 'Trip ID') {
            e.event.stopImmediatePropagation();
        }
    }
    grid_onSelectionChanged(e) {
        // console.log('grid_onSelectionChanged');
        // this.$cmpAddresses$.next(e.selectedRowKeys);
        // this.$cmpAddresses$.next(
        //   (e.currentSelectedRowKeys as number[])
        //     .map(key => [key, (e.selectedRowsData as any[]).find(data => data.id === key)])
        //     .map(([key, data]) => ({ key, tripId: oc(data)._trip._tripId() })),
        // );
    }
    grid_onDataErrorOccurred(e) {
        console.log(e);
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            // disabled: this.$showFromBroker$.value,
            name: 'selectAllValid',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-select',
                text: 'Select All Trustworthy Trips',
                hint: 'Select All Trustworthy Trips On This Page Only',
                onClick: this.grid_toolbar_selectAllValid_onClick.bind(this),
            },
        }, {
            // disabled: this.$showFromBroker$.value,
            name: 'setValidSelected',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-valid',
                text: 'Validate Selected Trips',
                hint: 'Validate Selected Trips',
                onClick: this.grid_toolbar_validateSelectedService_onClick.bind(this),
            },
        }, {
            // disabled: this.$showFromBroker$.value,
            name: 'setInvalidSelected',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-invalid',
                text: 'Invalidate Selected Trips',
                hint: 'Invalidate Selected Trips',
                onClick: this.grid_toolbar_invalidateSelectedService_onClick.bind(this),
            },
        }, {
            name: 'refresh',
            locateInMenu: 'auto',
            location: 'after',
            sortIndex: 99,
            widget: 'dxButton',
            showText: 'inMenu',
            options: {
                icon: 'refresh',
                text: 'Refresh',
                hint: 'Refresh',
                onClick: () => e.component.refresh(),
            },
        });
    }
    grid_onContextMenuPreparing(e) {
        if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
            // this.logger.log(e);
            // const eId = get(e.row.data, HelperService.REC_FIELD_MAP.employeeId);
            // const cId = get(e.row.data, HelperService.REC_FIELD_MAP.consumerId);
            const doc = e.row.data;
            const key = e.row.key;
            const confirmFn = () => doc.meta.partValidated || doc.meta.fullValidated
                ? this.signedEmployee$.pipe(switchMap(signed => confirm('<i>By Saving this record, ' +
                    `I ${oc(signed).person.firstname()} ${oc(signed).person.lastname()} ` +
                    `<br/>certify that provided information is verified and accurate, ` +
                    `<br/>and may be submitted for billing in current form.</i>`, 'Confirm changes')))
                : of(true);
            e.items = [
                {
                    text: 'Change Signature Image',
                    onItemClick: () => {
                        void this.isBiller$
                            .pipe(switchMap((inRole) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            // if (!inRole) {
                            //   notify('Access denied for the user', 'warning', 5000);
                            // } else
                            if (false && doc._locked() && !isEmpty(doc.imgFileId)) {
                                notify('Service is locked', 'warning', 5000);
                            }
                            else {
                                return this.dialog
                                    .open(DlgSelectSignatureComponent, {
                                    hasBackdrop: true,
                                    data: { signature: doc },
                                })
                                    .afterClosed()
                                    .pipe(switchMap(v => iif(() => !!oc(v).data(), of(v).pipe(tap(() => this.ui.showLoading()), switchMap(res => {
                                    // if (this.data.signature) {
                                    return this.sapi
                                        .patchAttributes(doc.id, { imgFileId: res.data.imgFileId }, hAll)
                                        .pipe(switchMap(() => this.storeUpdate(key, { imgFileId: res.data.imgFileId })));
                                }), 
                                //
                                catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                                    .toPromise();
                            }
                        })))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Arr/PU/DO Time',
                    onItemClick: () => {
                        // if (doc._locked()) {
                        //   notify('Service is locked', 'warning', 5000);
                        // } else
                        void this.dialog
                            .open(DlgEditTimesComponent, {
                            hasBackdrop: true,
                            data: { signature: doc, useFieldLock: false },
                        })
                            .afterClosed()
                            .pipe(switchMap(v => iif(() => !!v, defer(confirmFn).pipe(filter(identity), map(() => v), tap(() => this.ui.showLoading()), map(res => ({
                            // scheduledTime: res.scheduledTime ? moment(res.scheduledTime).format('HH:mm:ss') : null,
                            arrivedTime: res.arrivedTime ? moment(res.arrivedTime).format('HH:mm:ss') : null,
                            pickupTime: res.pickupTime ? moment(res.pickupTime).format('HH:mm:ss') : null,
                            dropoffTime: res.dropoffTime ? moment(res.dropoffTime).format('HH:mm:ss') : null,
                        })), 
                        // tap((res) => console.log(res)),
                        switchMap(values => {
                            return this.sapi
                                .patchAttributes(doc.id, values, hAll)
                                .pipe(switchMap(() => this.storeUpdate(key, values)));
                        }), 
                        //
                        catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                            .toPromise();
                    },
                },
                {
                    text: 'Change Vehicle',
                    onItemClick: () => {
                        if (false && doc._locked() && !isEmpty(doc.vehicleId)) {
                            notify('Service is locked', 'warning', 5000);
                        }
                        else
                            void this.dialog
                                .open(DlgSelectVehicleComponent, {
                                width: '450px',
                                maxHeight: '650px',
                                hasBackdrop: true,
                                data: {
                                    recIds: [doc.vehicleId],
                                    filter: {},
                                },
                            })
                                .afterClosed()
                                .pipe(filter(keys => keys !== false && keys && keys.length === 1), tap(() => this.ui.showLoading()), map(keys => keys[0]), switchMap(vId => {
                                return this.sapi.patchAttributes(doc.id, { vehicleId: vId }, hAll).pipe(
                                // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                                switchMap(s => this.storeUpdate(key, s)), tap(() => (this.vehicleSubDso$ = this.buildVehicleSubDataSource())));
                            }), 
                            //
                            catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                                .toPromise();
                    },
                },
                {
                    text: 'Change Driver',
                    onItemClick: () => {
                        if (false && doc._locked() && !isEmpty(doc.employeeId)) {
                            notify('Service is locked', 'warning', 5000);
                        }
                        else
                            void this.dialog
                                .open(DlgSelectDriverComponent, {
                                width: '450px',
                                maxHeight: '650px',
                                hasBackdrop: true,
                                data: {
                                    recIds: [doc.employeeId],
                                    filter: {
                                        or: [
                                            { tenantId: doc.tenantId },
                                            { tenantIds: { $json_e_c: { $: JSON.stringify(doc.tenantId) } } },
                                        ],
                                    },
                                    skipTenantCheck: true,
                                },
                            })
                                .afterClosed()
                                .pipe(filter(keys => keys !== false && keys && keys.length === 1), tap(() => this.ui.showLoading()), map(keys => keys[0]), switchMap(eId => {
                                return this.sapi.patchAttributes(doc.id, { employeeId: eId }, hAll).pipe(
                                // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                                switchMap(s => this.storeUpdate(key, s)), tap(() => (this.employeeSubDso$ = this.buildEmployeeSubDataSource())));
                            }), 
                            //
                            catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                                .toPromise();
                    },
                },
                {
                    text: 'Change Service Type',
                    onItemClick: () => {
                        if (false && doc._locked()) {
                            notify('Service is locked', 'warning', 5000);
                        }
                        else
                            void this.dialog
                                .open(DlgEditServiceTypeComponent, {
                                hasBackdrop: true,
                                data: { signature: doc },
                            })
                                .afterClosed()
                                .pipe(switchMap(v => iif(() => !!v, of(v).pipe(tap(() => this.ui.showLoading()), switchMap(st => {
                                return this.sapi.updateServiceType(doc.id, st, hAll).pipe(
                                // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                                switchMap(s => this.storeUpdate(key, s)));
                            }), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading())))))
                                .toPromise();
                    },
                },
                {
                    text: 'Change Meals Units',
                    onItemClick: () => {
                        if (false && doc._locked()) {
                            notify('Service is locked', 'warning', 5000);
                        }
                        else
                            void this.dialog
                                .open(DlgEditUnitsComponent, {
                                hasBackdrop: true,
                                data: { signature: doc },
                            })
                                .afterClosed()
                                .pipe(filter(v => !isEmpty(v)), tap(() => this.ui.showLoading()), switchMap(o => {
                                o = pickBy(mapKeys(o, (v, k) => ({
                                    W1759: 'HOT',
                                    W1760: 'FROZEN',
                                    W1761: 'SANDWICH',
                                    W1762: 'EMERGENCY',
                                    W1764: 'SPECIAL',
                                }[k] || k)), (v, k) => v > 0);
                                return this.sapi.patchAttributes(doc.id, { meta: { mealDroppedCountList: o } }, hAll).pipe(switchMap(() => this.svapi.findById(doc.id, {}, hAll)), switchMap(sv => this.storeUpdate(key, sv)));
                            }), 
                            //
                            catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                                .toPromise();
                    },
                },
            ];
        }
    }
    grid_onCellPrepared(e) {
        // console.log(e);
        // if (e.rowType === 'header') {
        //   if (e.column.name && !this.toolTipVisible[e.column.name]) {
        //     this.toolTipVisible[e.column.name] = {};
        //     this.toolTipData[e.column.name] = {};
        //   }
        // }
        //
        e.cellElement.classList.remove('cell-red', 'cell-green', 'cell-blue', 'cell-yellow', 'cell-violet', 'cell-trip-warning');
        if (e.rowType === 'data' && e.column.dataField === '_addrMismatch') {
            if (oc(e).data._addrMismatch('') === '...') {
                e.cellElement.classList.add('cell-blue');
            }
            else if (oc(e).data._addrMismatch('') === 'valid') {
                e.cellElement.classList.add('cell-green');
            }
            else if (!isEmpty(oc(e).data._addrMismatch(''))) {
                e.cellElement.classList.add('cell-trip-warning');
            }
        }
        if (e.rowType === 'data') {
            const _issuesFn = () => {
                if (e.data._issueList.length) {
                    e.cellElement.title = e.data._issueList.map(itm => '· ' + itm).join('\n');
                }
                // (e.cellElement as HTMLElement).classList.remove('cell-blue');
                if (oc(e).data._valid() && e.data._issueList.length === 0) {
                    e.cellElement.classList.add('cell-blue');
                    e.cellElement.title = [
                        'Client for this day has only 2 trips and 2 tripIDs',
                        'Trips for this client have proper assignment of flags TO_ADC and FROM_ADC and those alig with PUDO Time',
                    ]
                        .map(itm => '· ' + itm)
                        .join('\n');
                }
            };
            _issuesFn();
            e.watch(() => e.data._issueList, _issuesFn);
            //
            const _missingFn = () => {
                // (e.cellElement as HTMLElement).classList.remove('cell-violet');
                if (flatten(Object.entries(e.data._missingData)
                    .filter(([p, t]) => t)
                    .map(([p, t]) => ({
                    arrivedTime: 'ARR/PU/DO',
                    pickupTime: 'ARR/PU/DO',
                    dropoffTime: 'ARR/PU/DO',
                    consumerId: ['Client Last Name', 'Client First Name', 'MCI'],
                    employeeId: ['Employee Last Name', 'Employee First Name'],
                    vehicleId: 'Vehicle',
                    marker: 'Marker',
                    imgFileId: 'Signature',
                }[p] || p))).includes(e.column.caption)) {
                    e.cellElement.classList.add('cell-violet');
                }
            };
            _missingFn();
            e.watch(() => e.data._missingList, _missingFn);
            //
            const _lockedFn = () => {
                e.cellElement.classList.remove('cell-locked');
                if (e.data.meta.partValidated || e.data.meta.fullValidated) {
                    e.cellElement.classList.add('cell-locked');
                }
            };
            _lockedFn();
            e.watch(() => e.data.meta.partValidated, _lockedFn);
            e.watch(() => e.data.meta.fullValidated, _lockedFn);
            //
            if (e.column.dataField === 'validationState') {
                if (oc(e).data.validationState() === 'VALID')
                    e.cellElement.classList.add('cell-green');
                else if (oc(e).data.validationState() === 'INVALID')
                    e.cellElement.classList.add('cell-red');
                else if (oc(e).data.validationState() === 'ISSUES')
                    e.cellElement.classList.add('cell-yellow');
            }
            if (e.column.dataField === 'consumer_mci' ||
                e.column.dataField === 'consumer_person_lastname' ||
                e.column.dataField === 'consumer_person_firstname') {
                if (oc(e).data.consumer_status() === 'INACTIVE') {
                    e.cellElement.classList.add('cell-yellow');
                    e.cellElement.title = 'INACTIVE Client';
                }
            }
        }
    }
    grid_onSaving(e) {
        // console.log('grid_onSaving:', e);
        // this.$changes$.next(e.changes);
        // this.grid.instance.repaint();
        e.cancel = true;
        // this.ui.showLoading();
        // (e.component as DxDataGrid).beginCustomLoading('Updating...');
        e.promise = of(e.changes)
            .pipe(
        // tap(console.log),
        // tap(() => (e.component as DxDataGrid).beginCustomLoading('Updating...')),
        tap(() => this.ui.showLoading()), 
        //
        switchMap(changes => Promise.all(changes
            .filter(change => change.type === 'update')
            .map((change) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const doc = await this.gridStore.byKey(change.key);
            const doc = this.gridItems.find(itm => itm.id === change.key);
            // if (
            //   (doc.meta.partValidated || doc.meta.fullValidated) &&
            //   'validationState' in change.data &&
            //   change.data.validationState !== 'VALID'
            // ) {
            //   throw new Error('Cannot update locked record');
            // }
            yield this.sapi
                .patchAttributes(change.key, 
            // 'validationState' in change.data && change.data.validationState === 'VALID'
            //   ? {
            //       ...change.data,
            //       meta: {
            //         ...doc.meta,
            //         tripId: oc(doc)._trip._tripId(doc.vTripId),
            //       },
            //     }
            //   :
            change.data, hAll)
                .pipe(
            // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
            switchMap(s => this.storeUpdate(doc.id, s)))
                .toPromise();
        })))), tap(() => {
            this.changes = [];
            this.editRowKey = null;
            this.grid.instance.repaint();
        }), 
        //
        catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
            .toPromise();
    }
    //////////////////////////////////////////////////////
    grid_toolbar_selectAllValid_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.grid.instance.clearSelection();
            yield this.grid.instance.deselectAll();
            const keys = this.gridItems
                .filter(doc => doc._valid === true && doc.validationState !== 'VALID')
                .filter(doc => doc._issueList.length === 0)
                .map(doc => doc.id);
            yield this.grid.instance.selectRows(keys, false);
        });
    }
    grid_toolbar_validateSelectedService_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const selected = yield this.grid.instance.getSelectedRowsData();
            const visibleRows = this.grid.instance.getVisibleRows();
            const filtered = selected
                .filter(i => visibleRows.map(r => r.data.id).includes(i.id))
                .filter(i => i.validationState !== 'VALID');
            // console.log(selected);
            yield of(true)
                .pipe(tap(() => {
                this.grid.instance.beginCustomLoading('Updating...');
                this.grid.instance.beginUpdate();
            }), switchMap(() => Promise.all(filtered.map(doc => this.sapi
                .patchAttributes(doc.id, {
                validationState: 'VALID',
            }, hAll)
                .pipe(
            // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
            tap(s => void this.storeUpdate(doc.id, s)))
                .toPromise()))), 
            //
            catchError(err => of(notify(err.message, 'error', 5000))), tap(() => {
                this.grid.instance.endUpdate();
                this.grid.instance.endCustomLoading();
            }))
                .toPromise();
        });
    }
    grid_toolbar_invalidateSelectedService_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const selected = yield this.grid.instance.getSelectedRowsData();
            const visibleRows = this.grid.instance.getVisibleRows();
            const filtered = selected
                .filter(i => visibleRows.map(r => r.data.id).includes(i.id))
                .filter(i => !['INVALID'].includes(i.validationState));
            // console.log(selected);
            yield of(true)
                .pipe(tap(() => {
                this.grid.instance.beginCustomLoading('Updating...');
                this.grid.instance.beginUpdate();
            }), switchMap(() => Promise.all(filtered.map(doc => this.sapi
                .patchAttributes(doc.id, { validationState: 'INVALID' }, hAll)
                .pipe(
            // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
            tap(s => void this.storeUpdate(doc.id, s)))
                .toPromise()))), 
            //
            catchError(err => of(notify(err.message, 'error', 5000))), tap(() => {
                this.grid.instance.endUpdate();
                this.grid.instance.endCustomLoading();
            }))
                .toPromise();
        });
    }
    //////////////////////////////////////////////
    vehicleData(id) {
        const self = this;
        if (!id) {
            return of({});
        }
        if (!self.vehicleDsMap[id]) {
            self.vehicleDsMap[id] = {};
        }
        if (!self.vehicleDsMap[id].fullInstance) {
            const inst$ = this.dss.getApi(Vehicle).findById(id, {}, hAll);
            self.vehicleDsMap[id].fullInstance = inst$;
        }
        return self.vehicleDsMap[id].fullInstance;
    }
    driverData(id) {
        const self = this;
        if (!id) {
            return of({});
        }
        if (!self.driverDsMap[id]) {
            self.driverDsMap[id] = {};
        }
        if (!self.driverDsMap[id].fullInstance) {
            const inst$ = this.dss.getApi(Employee).findById(id, {
                include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
            }, hAll);
            self.driverDsMap[id].fullInstance = inst$;
        }
        return self.driverDsMap[id].fullInstance;
    }
}
