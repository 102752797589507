import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';

@Component({
  selector: 'app-trip-manifest-employee-popup',
  templateUrl: './trip-manifest-employee-popup.component.html',
  styleUrls: ['./trip-manifest-employee-popup.component.scss'],
})
export class TripManifestEmployeePopupComponent implements OnInit {
  @Input() title = 'Select Employee';
  @Input() gridDataSource: any;
  @Input() gridColumns: any[];
  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  @Output() selectionChanged: EventEmitter<any[]> = new EventEmitter();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('employeePopup', { static: true }) employeePopup: DxPopupComponent;
  @ViewChild('employeeGrid', { static: false }) employeeGrid: DxDataGridComponent;

  constructor() {
    this.grid_onSelectionChanged.bind(this);
  }

  private _gridEnabled = false;

  get gridEnabled(): boolean {
    return this._gridEnabled;
  }

  set gridEnabled(value: boolean) {
    if (this._gridEnabled !== value) {
      this._gridEnabled = value;
    }
  }

  private _visible = false;
  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    if (this._visible !== value) {
      this._visible = value;
      this.visibleChange.emit(value);
    }
  }

  ngOnInit() {}

  popup_onShowing(e) {}

  popup_onShown(e) {
    setTimeout(() => {
      this.gridEnabled = true;
    });
  }

  popup_onHidden(e) {
    setTimeout(() => {
      this.gridEnabled = false;
    });
  }

  grid_onSelectionChanged(e) {
    setTimeout(() => {
      const selectedKeys: any[] = e.selectedRowKeys;
      this.visible = false;

      this.selectionChanged.emit(selectedKeys);
    });
  }
}
