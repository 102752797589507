import isString from 'lodash-es/isString';
import uniq from 'lodash-es/uniq';
import { LoadOptions } from 'devextreme/data/load_options';
import compact from 'lodash-es/compact';
import { flatten, isObjectLike } from 'lodash-es';

export abstract class ALoadOptionsConverter {
  protected constructor(protected options: any) {}

  protected operatorMap = {
    and: 'and',
    or: 'or',
  };

  /**
   * [[field, '=', arr1], 'or', [field, '=', arr2], 'or' ...]
   */
  public static inq(field: string, arr: any[]) {
    return flatten(arr.map((itm, idx) => [...(idx === 0 ? [] : ['or']), [field, '=', itm]]));
  }

  abstract convert(loadOptions: LoadOptions): any;

  /**
   * Determine if LoadOptions filter is single rule.
   */
  protected isSingleFilterRule(filter: Array<any>): boolean {
    return filter.length === 3 && !isObjectLike(filter[0]) && isString(filter[1]);
  }

  /**
   * Convert single DevExtreme LoadOptions filter rule to filter where rule.
   */
  abstract singleFilterToWhereRule(filter: [string | null, string, any], not: boolean): any;

  /**
   * Convert DevExtreme LoadOptions filter to filter where
   */
  public loadOptionsFilterToWhere(filter: Array<any>, not = false): any {
    if (filter && this.isSingleFilterRule(filter)) {
      return this.singleFilterToWhereRule(filter as any, not);
    } else if (filter && filter.length) {
      const rules = filter.filter(rule => rule instanceof Array);
      const operators = uniq(filter.filter(rule => isString(rule)));

      if (operators.length > 1) {
        throw new Error(`Not valid filter rule (different operators): ${JSON.stringify(filter)}`);
      }

      if (rules.length === 0) {
        return undefined;
      }

      let operator = operators[0] || 'and';

      if (not) {
        switch (operator) {
          case 'and':
            operator = 'or';
            break;
          case 'or':
            operator = 'and';
            break;
        }
      }

      switch (operator) {
        case '!': {
          if (rules.length !== 1) {
            throw new Error(`Not valid filter rule: ${JSON.stringify(filter)}`);
          }
          return this.loadOptionsFilterToWhere(rules[0], !not);
        }
        case 'and': {
          const andRules = compact(rules.map(r => this.loadOptionsFilterToWhere(r, not)));
          return andRules.length ? { [this.operatorMap.and]: andRules } : undefined;
        }
        case 'or': {
          const orRules = compact(rules.map(r => this.loadOptionsFilterToWhere(r, not)));
          return orRules.length ? { [this.operatorMap.or]: orRules } : undefined;
        }
        default: {
          throw new Error(`Not processable operator: ${JSON.stringify(filter)}`);
        }
      }
    }
  }
}
