import {
  Facility,
  MyUser,
  Person,
  EmployeeEmPerson,
  Document,
  EmployeePosition
} from '../index';

declare var Object: any;
export interface EmployeeInterface {
  "status": string;
  "type"?: string;
  "interviewedAt"?: any;
  "hiredAt"?: any;
  "separatedAt"?: any;
  "data"?: any;
  "signImgFileId"?: string;
  "notes"?: string;
  "allowFastVehicleCheckout"?: boolean;
  "allowMultiCheckin"?: boolean;
  "tenantIds"?: any;
  "dtime"?: Date;
  "id"?: number;
  "tenantId"?: number;
  "userId"?: number;
  "personId"?: number;
  "employeePositionId"?: number;
  tenant?: Facility;
  user?: MyUser;
  person?: Person;
  emRelations?: EmployeeEmPerson[];
  emPeople?: Person[];
  trainedAtFacilities?: Facility[];
  assignedToFacilities?: Facility[];
  relatedDocuments?: Document[];
  employeePosition?: EmployeePosition;
}

export class Employee implements EmployeeInterface {
  "status": string;
  "type": string;
  "interviewedAt": any;
  "hiredAt": any;
  "separatedAt": any;
  "data": any;
  "signImgFileId": string;
  "notes": string;
  "allowFastVehicleCheckout": boolean;
  "allowMultiCheckin": boolean;
  "tenantIds": any;
  "dtime": Date;
  "id": number;
  "tenantId": number;
  "userId": number;
  "personId": number;
  "employeePositionId": number;
  tenant: Facility;
  user: MyUser;
  person: Person;
  emRelations: EmployeeEmPerson[];
  emPeople: Person[];
  trainedAtFacilities: Facility[];
  assignedToFacilities: Facility[];
  relatedDocuments: Document[];
  employeePosition: EmployeePosition;
  constructor(data?: EmployeeInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Employee`.
   */
  public static getModelName() {
    return "Employee";
  }

  /**
  * This method creates an instance of Employee for dynamic purposes.
  **/
  public static factory(data: EmployeeInterface): Employee{
    return new Employee(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Employee',
      plural: 'Employees',
      path: 'Employees',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "interviewedAt": {
          name: 'interviewedAt',
          type: 'any'
        },
        "hiredAt": {
          name: 'hiredAt',
          type: 'any'
        },
        "separatedAt": {
          name: 'separatedAt',
          type: 'any'
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "signImgFileId": {
          name: 'signImgFileId',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "allowFastVehicleCheckout": {
          name: 'allowFastVehicleCheckout',
          type: 'boolean',
          default: false
        },
        "allowMultiCheckin": {
          name: 'allowMultiCheckin',
          type: 'boolean',
          default: false
        },
        "tenantIds": {
          name: 'tenantIds',
          type: 'any',
          default: <any>null
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "employeePositionId": {
          name: 'employeePositionId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
                  keyFrom: 'personId',
          keyTo: 'id'
        },
        emRelations: {
          name: 'emRelations',
          type: 'EmployeeEmPerson[]',
          model: 'EmployeeEmPerson',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'employeeId'
        },
        emPeople: {
          name: 'emPeople',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'EmployeeEmPerson',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'employeeId'
        },
        trainedAtFacilities: {
          name: 'trainedAtFacilities',
          type: 'Facility[]',
          model: 'Facility',
          relationType: 'hasMany',
          modelThrough: 'TrainedAtFacility',
          keyThrough: 'facilityId',
          keyFrom: 'id',
          keyTo: 'employeeId'
        },
        assignedToFacilities: {
          name: 'assignedToFacilities',
          type: 'Facility[]',
          model: 'Facility',
          relationType: 'hasMany',
          modelThrough: 'AssignedToFacility',
          keyThrough: 'facilityId',
          keyFrom: 'id',
          keyTo: 'employeeId'
        },
        relatedDocuments: {
          name: 'relatedDocuments',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
        employeePosition: {
          name: 'employeePosition',
          type: 'EmployeePosition',
          model: 'EmployeePosition',
          relationType: 'belongsTo',
                  keyFrom: 'employeePositionId',
          keyTo: 'id'
        },
      }
    }
  }
}
