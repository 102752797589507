import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { ReportsFormComponent } from './components/reports-form/reports-form.component';
import { ReportsComponent } from './containers/reports/reports.component';
import { OldReportsFormComponent } from './components/old-reports-form/old-reports-form.component';
import { MealsGenerateInvoiceComponent } from './components/meals-generate-invoice/meals-generate-invoice.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule],
  declarations: [ReportsComponent, ReportsFormComponent, OldReportsFormComponent, MealsGenerateInvoiceComponent],
  exports: [ReportsComponent, MealsGenerateInvoiceComponent],
  entryComponents: [ReportsComponent],
})
export class ReportsModule {}
