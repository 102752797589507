import { NgModule } from '@angular/core';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxActionSheetModule } from 'devextreme-angular/ui/action-sheet';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCalendarModule } from 'devextreme-angular/ui/calendar';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDeferRenderingModule } from 'devextreme-angular/ui/defer-rendering';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { DxMultiViewModule } from 'devextreme-angular/ui/multi-view';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';
import { DxResponsiveBoxModule } from 'devextreme-angular/ui/responsive-box';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTileViewModule } from 'devextreme-angular/ui/tile-view';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxMapModule } from 'devextreme-angular/ui/map';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';

const MODULES = [
  DxAccordionModule,
  DxActionSheetModule,
  DxBoxModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDeferRenderingModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxMenuModule,
  DxMapModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxPopupModule,
  DxRangeSelectorModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxTileViewModule,
  DxSchedulerModule,
  DxToolbarModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class MyDevExtremeModule {}
