<dx-tab-panel
  [dataSource]="tabs"
  [selectedIndex]="0"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="false"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'details'">
    <dx-scroll-view>
      <app-document-details-tab [modelId]="modelId"></app-document-details-tab>
    </dx-scroll-view>
  </div>
</dx-tab-panel>
