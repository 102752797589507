import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-employee-document-grid',
  templateUrl: './dlg-employee-document-grid.component.html',
  styleUrls: ['./dlg-employee-document-grid.component.scss'],
})
export class DlgEmployeeDocumentGridComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgEmployeeDocumentGridComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
  ) {}

  ngOnInit() {}
}
