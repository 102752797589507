import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import { TripManifest, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as VehicleHelperService } from '../../../vehicle/services/helper.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-drop-down-grid-vehicles',
  templateUrl: './drop-down-grid-vehicles.component.html',
  styleUrls: ['./drop-down-grid-vehicles.component.scss'],
  providers: [HelperService, VehicleHelperService],
})
export class DropDownGridVehiclesComponent implements OnInit {
  @Input() ds: DataSourceOptions;
  @Input() gridColumns: any[] = [
    { dataField: 'internalId', caption: 'Internal Id' },
    //    {dataField: 'make', caption: 'Make'},
    //    {dataField: 'model', caption: 'Model'},
  ];

  @Input() manifest: TripManifest;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    protected logger: LoggerService,
    private dss: DataSourceService,
    public helper: HelperService,
    public vehicleHelper: VehicleHelperService,
    protected cd: ChangeDetectorRef,
  ) {
    this.buildDataSource();
  }

  ngOnInit() {}

  private buildDataSource() {
    this.ds = {
      store: this.dss.getStore(Vehicle, null, false),
      filter: [['state', '=', 'ACTIVE']],
    } as DataSourceOptions;
  }
}
