import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { of } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { HttpClient } from '@angular/common/http';
import { Facility, LoggerService, MyUserApi, MyUtilsApi, TripManifest } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService } from '../../services/helper.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { gqlMongoByKey, gqlMongoCount, gqlMongoLoad, } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import isEmpty from 'lodash-es/isEmpty';
import { oc } from 'ts-optchain';
import notify from 'devextreme/ui/notify';
import groupBy from 'lodash-es/groupBy';
import { utc } from 'moment';
import { DlgCalendarComponent } from '../../dialogs/dlg-calendar/dlg-calendar.component';
export class LgtcBrokerTripsComponent extends ABaseComponent {
    constructor(http, logger, config, common, ui, sss, dss, helper, gridHelper, dialog, api, userApi) {
        super(logger);
        this.http = http;
        this.logger = logger;
        this.config = config;
        this.common = common;
        this.ui = ui;
        this.sss = sss;
        this.dss = dss;
        this.helper = helper;
        this.gridHelper = gridHelper;
        this.dialog = dialog;
        this.api = api;
        this.userApi = userApi;
        this.facilityDso$ = of([]);
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '074416e3-5cf9-4b7a-bb3d-469ac85b65da',
        };
        this.isSU$ = this.config.isSU$;
        this.assignedHeaderFilter = [
            {
                text: 'Not Assigned',
                value: ['_manifestRecId', '=', null],
            },
            {
                text: 'Assigned',
                value: ['_manifestRecId', '<>', null],
            },
        ];
    }
    ngOnInit() {
        super.ngOnInit();
        this.dso = this.buildDataSource();
        this.facilityDso$ = this.buildFacilityDataSource();
    }
    ngAfterViewInit() { }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            flatToTreeObject: false,
            copyIdsOnSaving: false,
            selectRowOnEdit: false,
            notifyErrors: true,
        });
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            name: 'moveToManifest',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'after',
            sortIndex: 30,
            showText: 'inMenu',
            options: {
                icon: 'fas fa-copy',
                text: 'Move to registration',
                hint: 'Move trips to registration',
                onClick: this.grid_toolbar_moveToManifest_onClick.bind(this),
            },
        }, {
            name: 'checkTripsInManifest',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'after',
            sortIndex: 30,
            showText: 'inMenu',
            options: {
                icon: 'fas fa-check',
                text: 'Check trips in registration',
                hint: 'Check trips in registration',
                onClick: this.grid_toolbar_checkTripsInManifest_onClick.bind(this),
            },
        });
    }
    grid_onCellPrepared(e) {
        // console.log(e);
        if (e.rowType === 'data') {
            if (!isEmpty(oc(e).data._manifestRecId())) {
                e.cellElement.style.color = 'limegreen';
            }
        }
    }
    grid_toolbar_moveToManifest_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const trips = this.grid.instance.getSelectedRowsData();
            if (trips.length === 0) {
                notify('No trips selected', 'error', 5000);
                return;
            }
            this.ui.showLoading();
            of(trips)
                .pipe(switchMap((_trips) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const byDate = groupBy(trips, t => utc(t._date).format('YYYY-MM-DD'));
                yield Promise.all(Object.entries(byDate).map(([date, dTrips]) => tslib_1.__awaiter(this, void 0, void 0, function* () { return yield this.dss.getApi(TripManifest).moveTripsToManifest(date, dTrips).toPromise(); })));
            })), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.grid.instance.refresh()), tap(() => this.ui.hideLoading()), takeUntil(this.$onDestroy$))
                .subscribe();
        });
    }
    grid_toolbar_checkTripsInManifest_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialog
                .open(DlgCalendarComponent, {
                // width: '250px',
                hasBackdrop: true,
            })
                .afterClosed()
                .pipe(filter(date => !!date), tap((date) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    this.ui.showLoading();
                    yield this.dss.getApi(TripManifest).checkTripsOnManifest(date).toPromise();
                    this.grid.instance.refresh();
                }
                finally {
                    this.ui.hideLoading();
                }
            })))
                .toPromise()
                .catch(err => notify(err.message || err, 'error', 3000));
        });
    }
    buildDataSource() {
        const self = this;
        const col = 'LGTC_ExportsDataCache';
        const store = new CustomStore({
            useDefaultSearch: true,
            cacheRawData: false,
            load: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const aggregate = [
                // {
                //   $addFields: {_ctime: {$toDate: '$_id'}},
                // }
                ];
                return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
            }),
            byKey: (key) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoByKey(self.dss, col, key).toPromise();
            }),
            totalCount: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoCount(self.dss, col, loadOptions).toPromise();
            }),
        });
        const dso = {
            store,
            sort: [
                { selector: '_date', desc: true },
                { selector: '_tripId', desc: false },
            ],
            filter: ['_broker', '=', 'LGTC'],
        };
        return dso;
    }
    buildFacilityDataSource() {
        const store = this.dss.getStore(Facility);
        const dso = {
            store,
            filter: ['type', 'inq', ['ADC', 'BASE']],
            sort: [{ selector: 'type' }, { selector: 'name' }],
        };
        return of(dso);
    }
    manifestRecCustomizeText(cellInfo) {
        return isEmpty(cellInfo.value) ? '' : 'Y';
    }
}
