import * as tslib_1 from "tslib";
import { OnDestroy, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import sortBy from 'lodash-es/sortBy';
import { takeUntil } from 'rxjs/operators';
//
import { Consumer, Document, LoggerService, Person } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentFormComponent } from '../../../document/components/document-form/document-form.component';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';
export class ConsumerDetailsTabDocumentComponent extends ABaseModelPaneWToolbarComponent {
    constructor(logger, ui, dss, dialog, helper, docHelper, gridHelper) {
        super(logger, ui, dss);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.helper = helper;
        this.docHelper = docHelper;
        this.gridHelper = gridHelper;
        this.caption = 'Related Documents';
        this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
            if ([Document.getModelName()].includes(modelName)) {
                if (this.grid) {
                    this.grid.instance.refresh();
                }
            }
        });
    }
    get ModelClass() {
        return Consumer;
    }
    get FormComponent() {
        return ConsumerFormComponent;
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {});
    }
    grid_onEditingStart(e) {
        e.cancel = true;
        this.ui.openEditDialog({
            modelId: e.data.id,
            ModelClass: Document,
            FormComponentClass: DocumentFormComponent,
        });
    }
    grid_onToolbarPreparing(e) {
        // console.log(e);
        e.toolbarOptions.items.unshift({
            name: 'addConsumerDoc',
            widget: 'dxButton',
            locateInMenu: 'always',
            location: 'after',
            sortIndex: 30,
            // showText: 'inMenu',
            options: {
                // icon: '',
                text: 'Add Consumer Doc',
                hint: 'Add Consumer Doc',
                // disabled: !this.model,
                onClick: () => {
                    if (!this.model) {
                        return;
                    }
                    this.ui.openEditDialog({
                        modelId: null,
                        inputs: {
                            objectType: Consumer.getModelName(),
                            objectId: this.modelId,
                        },
                        ModelClass: Document,
                        FormComponentClass: DocumentFormComponent,
                    });
                },
            },
        });
        e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.dso = model ? this.buildDSO(model) : undefined;
        });
    }
    buildToolbarItems(model = null) {
        this.toolbarItems = [
            {
                location: 'before',
                locateInMenu: 'never',
                text: this.caption,
            },
        ];
    }
    buildDSO(model) {
        const filter = model
            ? [
                [
                    ['objectId', '=', model.id],
                    ['objectType', '=', Consumer.getModelName()],
                ],
                'or',
                [
                    ['objectId', '=', model.personId],
                    ['objectType', '=', Person.getModelName()],
                ],
            ]
            : undefined;
        return {
            store: this.dss.getStore(Document, null, false),
            filter,
        };
    }
}
