import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { Facility, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-change-adc',
  templateUrl: './dlg-change-adc.component.html',
  styleUrls: ['./dlg-change-adc.component.scss'],
})
export class DlgChangeAdcComponent implements OnInit {
  ds1: DataSourceOptions;
  gridColumns1: any[] = [{ dataField: 'shortname' }, { dataField: 'type' }, { dataField: 'legalName' }];

  gridSelection1 = { mode: 'single', showCheckBoxesMode: 'none' };
  gridPaging1 = { enabled: true, pageSize: 10 };
  gridFilterRow1 = { visible: true };
  gridScrolling1 = { mode: 'infinite' };
  selectedRowKeys: any[] = [];
  selectedFacility: Facility;

  constructor(
    private dialogRef: MatDialogRef<DlgChangeAdcComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      keys: any[];
    },
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
  ) {}

  ngOnInit() {
    this.selectedRowKeys = oc(this.data.keys)([]);
    this.buildDataSource1();
  }

  private buildDataSource1() {
    const so = this.dss.getStoreOptions(Facility, Facility, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    this.ds1 = {
      store: new CustomStore(so),
      sort: [{ selector: 'shortname' }],
    } as DataSourceOptions;
  }

  grid1_onSelectionChanged(e: { selectedRowKeys: number[]; selectedRowsData: Facility[] }) {
    this.selectedFacility = e.selectedRowsData[0];
  }
}
