import { Routes } from '@angular/router';
import { NotFoundComponent } from '../../core/containers/not-found/not-found.component';
import { AuthGuard } from '../../shared/modules/my-common/guards/auth.guard';
import { PendingChangesGuard } from '../../shared/modules/my-common/guards/pending-changes.guard';
import { ScheduleComponent } from '../schedule/containers/schedule/schedule.component';
import { TripsAuditComponent } from '../trips-audit/containers/trips-audit/trips-audit.component';
import { EmployeeSignStatsComponent } from './components/employee-sign-stats/employee-sign-stats.component';
import { FuelRefillGridComponent } from './components/fuel-refill-grid/fuel-refill-grid.component';
import { MealsClaimsPivotComponent } from './components/meals-claims-pivot/meals-claims-pivot.component';
import { BillingCommonV2Component } from './components/processing/billing-common-v2/billing-common-v2.component';
import { RemittancePivotComponent } from './components/remittance-pivot/remittance-pivot.component';
import { SignatureStatsComponent } from './components/signature-stats/signature-stats.component';
import { StatsComponent } from './components/stats/stats.component';
import { BillingComponent } from './containers/billing/billing.component';

export const BILLING_ROUTES = [
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    children: [
      // {path: 'list', component: ServicesComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard]},

      // {
      //   path: 'list',
      //   component: BillingCommonComponent,
      //   canActivate: [AuthGuard],
      //   canDeactivate: [PendingChangesGuard],
      // },

      {
        path: 'processing2',
        component: BillingCommonV2Component,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },

      { path: 'stats', component: StatsComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
      {
        path: 'unit-stats',
        component: SignatureStatsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'fuel-refill',
        component: FuelRefillGridComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'remittance-pivot',
        component: RemittancePivotComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'meals-claims-pivot',
        component: MealsClaimsPivotComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      { path: '', redirectTo: 'processing2', pathMatch: 'prefix' },
      { path: '**', component: NotFoundComponent },
    ],
  },
  {
    path: 'operations',
    component: BillingComponent,
    canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    children: [
      {
        path: 'unit-stats',
        component: SignatureStatsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'trips-audit',
        component: TripsAuditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'employee-sign-stats',
        component: EmployeeSignStatsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      { path: 'backup-drivers', component: ScheduleComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: 'unit-stats', pathMatch: 'prefix' },
      { path: '**', component: NotFoundComponent },
    ],
  },
] as Routes;
