import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { Employee, EmployeeView, Facility, Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { EmployeeApi, EmployeeViewApi, FacilityApi, LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import moment, { duration } from 'moment';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';

@Component({
  selector: 'app-clone-log-details-tab-created-schedules',
  templateUrl: './clone-log-details-tab-created-schedules.component.html',
  styleUrls: ['./clone-log-details-tab-created-schedules.component.scss'],
  providers: [],
})
export class CloneLogDetailsTabCreatedSchedulesComponent extends ABaseComponent {
  _log: any;

  @Input()
  set log(v: any) {
    this._log = v;
    this.changeHandle();
  }
  get log() {
    return this._log;
  }

  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  async changeHandle() {
    if (this.log) {
      if (this.log.createdSchedules && !this.log.createdSchedules.some(s => s.driver)) {
        this.ui.showLoading();
        const drivers = await this.dss
          .getApi<EmployeeViewApi>(EmployeeView)
          .find<EmployeeView>(
            { where: { employeeId: { inq: [...new Set(this.log.createdSchedules.map(e => e.driverId))] } } },
            headersAllTenantsAppend,
          )
          .toPromise();
        const drviersMap = drivers.reduce((p, d) => ({ ...p, [d.id]: d }), {});
        this.log.createdSchedules = this.log.createdSchedules.map(s => ({ ...s, driver: drviersMap[s.driverId] }));
        this.ui.hideLoading();
      }
      this.dso = this.log.createdSchedules || [];
    }
  }
}

