import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoopBackAuth } from '../shared/sdk';
import { CommonService } from '../shared/modules/my-common/services/common.service';
import { ConfigService } from '../shared/modules/my-common/services/config.service';
import { SignedUrlHttpInterceptorService } from './signed-url-http-interceptor.service';
import Guid from 'devextreme/core/guid';
import { ExtLoopBackAuth } from '../shared/modules/ext-sdk/services/ext-sdk-auth.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject(CommonService) private common: CommonService,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
    @Inject(ConfigService) protected config: ConfigService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // !!! googleapis storage will fail
    // req = req.clone({withCredentials: true});
    // req = req.clone({setHeaders: {'X-Access-Token': this.auth.getAccessTokenId()}});

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({setHeaders: {'Content-Type': 'application/json'}});
    // }

    req = req.clone({
      setHeaders: {
        'X-Request-Id': 'u' + this.auth.getCurrentUserId() + '/' + new Guid().valueOf(),
        'x-tenant-timezone': '' + this.config.get('timezone'),
        'x-client-timezone-offset': '' + (new Date().getTimezoneOffset() / 60) * -1,
        ...(req.headers.has('X-Access-Token') ? {} : { 'X-Access-Token': '' + this.auth.getAccessTokenId() }),
        ...(req.headers.has('X-Current-Tenant') ? {} : { 'X-Current-Tenant': '' + this.auth.getCurrentTenant() }),
      },
    });

    if (req.url.includes('ping') || req.url.includes('Containers') || req.url.includes('storage.googleapis.com')) {
      req = req.clone({ setHeaders: { 'ngsw-bypass': 'true' } });
    }

    return next.handle(req);
  }
}

export const HTTP_INTERCEPTOR_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DefaultHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SignedUrlHttpInterceptorService,
    multi: true,
  },
];
