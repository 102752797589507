<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Username</div>
      <div class="dx-field-value">
        <!--           *ngIf="!model; else editUser"-->
        <dx-text-box formControlName="username" [inputAttr]="{ autocomplete: 'off' }">
          <dx-validator name="Username">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

      <!--      <ng-template #editUser>-->
      <!--        <div class="dx-field-value-static">-->
      <!--          {{model?.username}}-->
      <!--        </div>-->
      <!--      </ng-template>-->
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Email</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="email" mode="email" [inputAttr]="{ autocomplete: 'off' }">
          <dx-validator name="Email">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="email"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="!model">
      <div class="dx-field-label">Password</div>
      <div class="dx-field-value">
        <dx-text-box
          formControlName="password"
          mode="password"
          [showClearButton]="true"
          [inputAttr]="{ autocomplete: 'off' }">
          <dx-validator name="Password">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Status</div>
      <div class="dx-field-value">
        <dx-lookup formControlName="status" [dataSource]="statuses" displayExpr="Name" valueExpr="ID">
          <dx-validator name="Status">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-lookup>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Email Verified</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="emailVerified" switchedOnText="YES" switchedOffText="NO"></dx-switch>
        </div>
      </div>
    </div>

    <!-- /// -->

    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <p class="alert-danger" *ngIf="error">
      {{ error }}
    </p>
  </div>
</dx-validation-group>
