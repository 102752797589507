import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee, LoggerService } from '../../../../../shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-dlg-employee-working-time',
  templateUrl: './dlg-employee-working-time.component.html',
  styleUrls: ['./dlg-employee-working-time.component.scss'],
})
export class DlgEmployeeWorkingTimeComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgEmployeeWorkingTimeComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public config: ConfigService,
  ) {}

  ngOnInit() {}
}

