import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { Facility } from '../../../../shared/sdk/models';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-cts-claims',
  templateUrl: './cts-claims.component.html',
  styleUrls: ['./cts-claims.component.scss'],
})
export class CtsClaimsComponent extends ABaseComponent implements OnInit {
  isBiller$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);
  status1Dso: DataSourceOptions;
  status2Dso: DataSourceOptions;

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'bfa9df95-f82b-4f1e-90d9-b9a16f650663',
    };
    this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
    this.status1Dso = this.buildStatus1DataSource();
    this.status2Dso = this.buildStatus2DataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  private buildDataSource() {
    const self = this;

    const aggregate = [
      // {
      //   $addFields: {_ctime: {$toDate: '$_id'}},
      // }
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate)
          .pipe(
            switchMap(async (docs: any[]) => {
              /*
                            {
                              const q = {
                                query: `query GetTripFacility($broker: String!, $tripIds: [String!])
                                       { getTripFacility(broker: $broker, tripIds: $tripIds) }`,
                                variables: {broker: 'MTM', tripIds: docs.map((doc) => doc._tripId)},
                              };

                              const tripsFacilities: any[] = await self.dss.graphql(q)
                                .pipe(
                                  map((data) => {
                                      return data && data.getTripFacility ? data.getTripFacility : undefined;
                                  }),
                                  map((items: string[]) => items.map((itm) => JSON.parse(itm))),
                                  catchError((err) => {
                                    console.error(err);
                                    return of(undefined);
                                  }),
                                ).toPromise();

                              (tripsFacilities || []).forEach(([tripId, facility]) => {
                                const doc = docs.find((_doc) => _doc._tripId === tripId);
                                if (doc) {
                                  doc._facility = facility;
                                }
                              });
                            }

                            {
                              const q = {
                                query: `query IsTripAssigned($broker: String!, $tripIds: [String!])
                                       { isTripAssigned(broker: $broker, tripIds: $tripIds) }`,
                                variables: {broker: 'MTM', tripIds: docs.map((doc) => doc._tripId)},
                              };

                              const tripsIsAssigned: any[] = await self.dss.graphql(q)
                                .pipe(
                                  map((data) => {
                                      return data && data.isTripAssigned ? data.isTripAssigned : undefined;
                                  }),
                                  map((items: string[]) => items.map((itm) => JSON.parse(itm))),
                                  catchError((err) => {
                                    console.error(err);
                                    return of(undefined);
                                  }),
                                ).toPromise();

                              (tripsIsAssigned || []).forEach(([tripId, assigned]) => {
                                const doc = docs.find((_doc) => _doc._tripId === tripId);
                                if (doc) {
                                  doc._assigned = assigned;
                                }
                              });
                            }
              */
              return docs;
            }),
          )
          .toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'ExportsClaimsDataCache';
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      filter: ['_broker', '=', 'CTS'],
      sort: [{ selector: '_date', desc: true }],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildStatus1DataSource() {
    const self = this;
    const aggregate = [
      {
        $match: { _broker: 'CTS' },
      },
      {
        $project: { 'Reimbursement Status': 1 },
      },
      {
        $group: { _id: '$Reimbursement Status' },
      },
      {
        $sort: { _id: 1 },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        loadOptions.take = 1000;
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'ExportsClaimsDataCache';
        loadOptions.take = 1000;
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });

    return {
      store,
    } as DataSourceOptions;
  }

  private buildStatus2DataSource() {
    const self = this;
    const aggregate = [
      {
        $match: { _broker: 'CTS' },
      },
      {
        $project: { 'Schedule Status': 1 },
      },
      {
        $group: { _id: '$Schedule Status' },
      },
      {
        $sort: { _id: 1 },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        loadOptions.take = 1000;
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'ExportsClaimsDataCache';
        loadOptions.take = 1000;
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'ExportsClaimsDataCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });

    return {
      store,
    } as DataSourceOptions;
  }
}
