import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import moment, { utc } from 'moment';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  EmployeeDayService,
  EmployeeDayServiceApi,
  LoggerService,
  LoopBackFilter,
  MobileDevice,
  MobileDeviceApi,
  MyUtilsApi,
  PostShiftReport,
  VehicleCheckUp,
  VehicleCheckUpApi,
} from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-daily-checks-details-tab-full',
  templateUrl: './daily-checks-details-tab-full.component.html',
  styleUrls: ['./daily-checks-details-tab-full.component.scss'],
})
export class DailyChecksDetailsTabFullComponent
  extends ABaseModelPaneWToolbarComponent<VehicleCheckUp, VehicleCheckUpApi>
  implements OnInit, OnChanges, OnDestroy
{
  showAdd = false;
  showEdit = false;

  ciFields: [string, (keyof VehicleCheckUp)[]][] = [
    [
      'Pre-Ignition Checks',
      [
        'location',
        'inspectionExp',
        'visibleLeaksUnderVehicle',
        'tiresConditionOk',
        'exteriorClean',
        'exteriorBodyDamage',
        'engineOilLevel',
        'coolantLevel',
        'emergencyDoorUnlocked',
      ],
    ],
    [
      'Cabin',
      [
        'engineStartedWithNoIssues',
        'warningGaugesOff',
        'odometer',
        'fuelLevel',
        'nextOilChange',
        'wiperFluidPresent',
        'wipersConditionOk',
        'headlightsAndTurnSignalsOk',
        'hornWorks',
        'kubotaKeyPresent',
        'cigaretteLighterWorks',
        'usbChargerOk',
        'usbChargingWirePresent',
        'serviceDoorOperational',
        'wheelchairLiftWorking',
        'electricRampWorking',
        'documentBinder',
        'wawaCard',
        'insuranceCardPresent',
        'registrationCardPresent',
        'registrationCardExp',
        'accidentIncidentEnvelope',
        'driverHasDLonHands',
        'driverHasDMConHands',
      ],
    ],
    [
      'Equipment',
      [
        'beltCutterPresent',
        'firstAidKitPresent',
        'bloodBornePathogenKitPresent',
        'fireExtinguisherPresent',
        'fireExtinguisherExp',
        'fireExtinguisher2Present',
        'fireExtinguisher2Exp',
        'cleaningSuppliesPresent',
        'sanitizerDispenserWorksFilled',
        'driverHasPPE',
        'thermometerPresent',
        'umbrella',
        'windshieldBrush',
        'handheldGpsPresent',
        'windshieldMountGpsHolder',
        'ezPassTransporterPresent',
        'emergencyTrianglesPresent',
        'jumperCablePresent',
        'seatBeltExtender',
        'broomPresent',
        'snowShovel',
        'salt',
        'numberOfWheelchairHooks',
        'numberOfWheelchairBelts',
        'stepStoolPresent',
        'foldableWheelchairPresent',
        'foldableTransportChairPresent',
        'stairChairPresent',
        'stairwayWheelchairCarrierPresent',
        'stretcherPresent',
        'interiorClean',
        'IHaveDisinfectedSurfacesOfContact',
        'minutesOfEngineWarmUpIdleTime',
        'heatAcWorking',
        'frontHeatACWorking',
        'backHeatACWorking',
        'wcLiftHandPumpHandlePresent',
        'issuesNumber',
      ],
    ],
  ];

  coFields: [string, (keyof PostShiftReport)[]][] = [
    [
      '',
      [
        'location',
        'shiftWithoutIncidents',
        'incidentOrAccident',
        'anybodyGotInjured',
        'vehicleDamage',

        'visibleLeaksUnderVehicle',
        'tiresConditionOk',
        'exteriorClean',
        'exteriorBodyDamage',
        'odometer',
        'warningGaugesOff',
        'thermometerPresent',
        'cleaningSuppliesPresent',
        'driverHasPPE',
        'handheldGpsPresent',
        'windshieldMountGpsHolder',
        'ezPassTransporterPresent',
        'numberOfWheelchairHooks',
        'numberOfWheelchairBelts',
        'stepStoolPresent',
        'foldableWheelchairPresent',
        'foldableTransportChairPresent',
        'stairChairPresent',
        'stairwayWheelchairCarrierPresent',
        'stretcherPresent',
        'interiorClean',
        'haveDisinfectedSeveralTime',
        'haveWashedExterior',
        'vehicleFueledUpToday',
        'anyVehicleIssuesToReport',
        'vehicleLeftLocked',
        'vehicleKeysLocation',
        'tabletLocation',
      ],
    ],
  ];

  fullDoc:
    | (VehicleCheckUp & {
        checkout: PostShiftReport & { _vehicleUsageDuration: number };
        _ci_picturesEntries;
        _co_picturesEntries;
        _mobileDevice: MobileDevice;
        _pi: Date;
        _po: Date;
        _shiftDuration: number;
      })
    | undefined;
  descAndReport: { ci?: any[]; co?: any[] } | undefined;
  descAndReportMap: { ci?: Map<string, any>; co?: Map<string, any> } | undefined;
  full = true;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    private pusher: PusherService,
  ) {
    super(logger, ui, dss);

    this.caption = 'Full Report';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected get ModelClass(): any {
    return VehicleCheckUp;
  }

  protected get filter(): LoopBackFilter {
    return { include: ['vehicle', { employee: ['person'] }, 'user'] };
  }

  protected async beforeModelLoadingAsync(id: number | string): Promise<void> {
    this.fullDoc = undefined;
    this.descAndReport = undefined;
    this.descAndReportMap = { ci: new Map(), co: new Map() };
    return super.beforeModelLoadingAsync(id);
  }

  protected async afterModelLoadedAsync(model: VehicleCheckUp): Promise<void> {
    if (this.modelId) {
      try {
        this.loading = true;

        this.fullDoc = await gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId).toPromise();
        this.descAndReport = await this.api.getAllFieldsDescriptions(this.modelId).toPromise();
        this.descAndReportMap = {
          ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm] as [string, any])),
          co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm] as [string, any])),
        };

        this.fullDoc._mobileDevice = await this.dss
          .getApi<MobileDeviceApi>(MobileDevice)
          .findOne<any>({
            where: {
              deviceUid: this.model.deviceUid,
            },
          })
          .toPromise();

        if (this.fullDoc.checkout) {
          this.fullDoc.checkout._vehicleUsageDuration = utc(this.fullDoc.checkout.completedDateTime).diff(
            this.fullDoc.startedDateTime,
            'minutes',
          );
        }

        const _moment = moment(this.model.dateTime);
        const shiftData: { pi: string; po: string } = await this.dss
          .getApi<EmployeeDayServiceApi>(EmployeeDayService)
          .getShiftData(this.model.employeeId, _moment.format('YYYY-MM-DD'), [
            'PUNCH-IN',
            'PUNCH-OUT',
            'PAUSE',
            'RESUME',
          ])
          .toPromise();

        this.fullDoc._pi = shiftData.pi ? moment(shiftData.pi).toDate() : undefined;
        this.fullDoc._po = shiftData.po ? moment(shiftData.po).toDate() : undefined;
        this.fullDoc._shiftDuration =
          this.fullDoc._po && this.fullDoc._pi ? moment(this.fullDoc._po).diff(this.fullDoc._pi, 'minutes') : undefined;
      } finally {
        this.loading = false;
      }
    }

    return super.afterModelLoadedAsync(model);
  }

  protected get FormComponent(): Type<ABaseFormComponent<VehicleCheckUp>> {
    return undefined;
  }

  protected buildToolbarItems(model: any = null) {
    super.buildToolbarItems(model);

    this.toolbarItems.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'Print Report',
        hint: 'Print Report',
        disabled: !model,
        onClick: () => this.printReport(),
      },
    });
  }

  printReport() {
    this.ui.showLoading();

    this.api
      .getReportPdfJob(this.modelId)
      .pipe(
        switchMap(jobId => this.pusher.requestResponse(jobId)),
        tap(({ bucket, filePdf, uri }) => window.open(uri)),
        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => {
          this.ui.hideLoading();
        }),
      )
      .toPromise()
      .finally();
  }
}
