import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import DxValidator from 'devextreme/ui/validator';
import { shuffle } from 'lodash-es';
import difference from 'lodash-es/difference';
import head from 'lodash-es/head';
import tail from 'lodash-es/tail';
import take from 'lodash-es/take';
import { iif, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { capitalizeFirstLetter } from '../../../../shared/classes/utils/string.utils';
import { isAlpha } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Employee, EmployeeApi, LoggerService, MyUserView, MyUserViewApi } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-create-account',
  templateUrl: './dlg-create-account.component.html',
  styleUrls: ['./dlg-create-account.component.scss'],
})
export class DlgCreateAccountComponent extends ABaseComponent implements OnInit {
  $reload$: Subject<any> = new Subject();

  account: {
    username?: string;
    email?: string;
    password?: string;
  } = {};

  @ViewChild('form', { static: false }) form: DxFormComponent;

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgCreateAccountComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      model: Employee;
    },
    private dss: DataSourceService,
    protected ui: UiService,
  ) {
    super(logger);
  }

  get employee(): Employee {
    return this.data.model;
  }

  ngOnInit() {
    super.ngOnInit();

    this.$reload$
      .pipe(
        startWith(true),
        tap(() => this.ui.showLoading()),
        switchMap(() =>
          this.dss.getApi<EmployeeApi>(Employee).findById<Employee>(this.employee.id, {
            include: [{ person: { contact: ['emails'] } }],
          }),
        ),
        tap(e => {
          try {
            const nameParts = e.person.firstname
              .split(' ')
              .filter(part => part.length > 1)
              .map(part => capitalizeFirstLetter(part));

            this.account.username = [
              isAlpha() ? 'AAA.' : '',
              head(nameParts),
              ...tail(nameParts).map(part => part[0]),
              e.person.lastname[0].toUpperCase(),
            ].join('');
          } catch {}

          this.account.email = oc(e).person.contact.emails[0].value();

          this.account.password = this.genPassword(6);
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  createUser() {
    void of(true)
      .pipe(
        tap(() => this.ui.showLoading()),
        switchMap(() => this.form.instance.validate().complete),

        switchMap(res =>
          iif(
            () => res.isValid,
            of(true).pipe(
              switchMap(() => this.dss.getApi<EmployeeApi>(Employee).createUser(this.employee.id, this.account)),
              switchMap(() =>
                alert(
                  `<label>username:</label> <strong>${this.account.username}</strong>` +
                    `<br>` +
                    `<label>password:</label> <strong>${this.account.password}</strong>`,
                  'Account created',
                ),
              ),
              tap(() => this.dialogRef.close(true)),
            ),
            of(false),
          ),
        ),

        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }

  asyncUsernameValidation = params => {
    // console.log('validationCallback: ', params);
    const username: string = params.value || '';
    const rule: any = params.rule || {};
    const validator: DxValidator = params.validator;

    return new Promise((resolve, reject) => {
      this.dss
        .getApi<MyUserViewApi>(MyUserView)
        .find<MyUserView>({
          where: { username: { like: username } },
          fields: { [MyUserView.getModelDefinition().idName]: true, username: true },
          limit: 1,
        })
        .toPromise()
        .then(([u]) => {
          !u ? resolve() : reject('Username already exists');
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  asyncEmailValidation = params => {
    // console.log('validationCallback: ', params);
    const email: string = params.value || '';
    const rule: any = params.rule || {};
    const validator: DxValidator = params.validator;

    return new Promise((resolve, reject) => {
      this.dss
        .getApi<MyUserViewApi>(MyUserView)
        .find<MyUserView>({
          where: { email: { like: email } },
          fields: { [MyUserView.getModelDefinition().idName]: true, email: true },
          limit: 1,
        })
        .toPromise()
        .then(([u]) => {
          !u ? resolve() : reject('Email already exists');
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  private genPassword(len: number) {
    let nums = shuffle('1234567890'.split(''));
    let chars = shuffle('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''));

    nums = nums.filter(n => !['1', '0'].includes(n));
    chars = chars.filter(n => !['I', 'L', 'O'].includes(n));

    const count1 = (len - (len % 3) + 3) / 3;
    const counts = [count1, count1, len - count1 * 2];

    const part0 = take(nums, counts[0]);
    const part1 = take(chars, counts[1]);
    const part2 = take(difference(chars, part1), counts[2]);

    return [...part2.map(c => c.toUpperCase()), ...part1.map(c => c.toLowerCase()), ...part0].join('');
  }
}
