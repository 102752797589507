import { oc } from 'ts-optchain';
import { isAlpha } from '../../../shared/classes/utils/utils';
const ɵ0 = (config) => oc(config).config.allowAdcSignatures(false), ɵ1 = (config) => oc(config).config.adcMealsCheck(false), ɵ2 = (config) => oc(config).config.adcCovidReporting(false);
export const SIDEBAR_ITEMS = [
    // {route: '/dashboard/alerts', title: 'Alerts', iconSet: 'far', fontIcon: 'bell'},
    {
        title: 'ADC Reports',
        iconSet: 'far',
        fontIcon: 'reports',
        tenantTypes: ['ADC'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            {
                route: '/dashboard/stats/facility-signatures',
                title: 'Attendance Report',
                iconSet: 'fas',
                fontIcon: 'adc',
                tenantTypes: ['ADC'],
                acl: ['CLERK', 'MANAGER'],
                fn: ɵ0,
            },
            {
                route: '/dashboard/stats/adc-meals',
                title: 'Meals  Report',
                iconSet: 'fas',
                fontIcon: 'meals',
                tenantTypes: ['ADC'],
                acl: ['CLERK', 'MANAGER'],
                fn: ɵ1,
            },
            {
                route: '/dashboard/stats/covid19-checks',
                title: 'COVID-19 Report',
                iconSet: 'fas',
                fontIcon: 'covid19',
                tenantTypes: ['ADC'],
                acl: ['CLERK', 'MANAGER'],
                fn: ɵ2,
            },
        ],
    },
    {
        title: 'Meals Reports',
        iconSet: 'far',
        fontIcon: 'reports',
        tenantTypes: ['MEALS'],
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            {
                route: '/dashboard/stats/meals-facility-stats',
                title: 'Units Delivered',
                iconSet: 'fas',
                fontIcon: 'meals',
                tenantTypes: ['MEALS'],
            },
            {
                route: '/dashboard/meals-gen-invoice',
                title: 'Generate Invoice',
                iconSet: 'fas',
                fontIcon: 'invoice',
                tenantTypes: ['MEALS'],
            },
        ],
    },
    {
        route: '/dashboard/operations/backup-drivers',
        title: 'Dashboard',
        iconSet: 'far',
        fontIcon: 'dashboard',
        tenantTypes: ['BASE'],
        acl: ['CLERK', 'MANAGER'],
        items: [
            {
                route: '/dashboard/operations/backup-drivers',
                title: 'Backup Drivers',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/vehicles-backup',
                title: 'Backup Vehicles',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/operations-new/tardiness-log',
                title: 'OPS Alert - Tardiness Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
            },
        ],
    },
    {
        route: '/dashboard/scheduling/manifest',
        title: 'Scheduling',
        iconSet: 'fas',
        fontIcon: 'settings',
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            {
                route: '/dashboard/scheduling/manifest',
                title: 'Manifest',
                iconSet: 'fas',
                fontIcon: 'tasks',
                acl: ['CLERK', 'MANAGER'],
            },
            {
                route: '/dashboard/scheduling/employee-schedule',
                title: 'Employee Schedule',
                iconSet: 'fas',
                fontIcon: 'workingSchedule',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/employee-working-hours',
                title: 'Weekly Hours',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
                enabledComponent: 'router',
            },
            {
                route: '/dashboard/scheduling/auto-dispatch',
                title: 'Auto Dispatch',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
                enabledComponent: 'autoDispatch',
            },
            {
                route: '/dashboard/scheduling/mtm-trips',
                title: 'MTM Trips',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
                enabledComponent: 'mtmTrips',
            },
            {
                route: '/dashboard/scheduling/router',
                title: 'Router',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
                enabledComponent: 'router',
            },
            {
                route: '/dashboard/scheduling/settings',
                title: 'Settings',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['ADMIN'],
            },
        ],
    },
    {
        route: '/dashboard/vehicles-locations',
        title: 'Fleet',
        iconSet: 'far',
        fontIcon: 'fleet',
        tenantTypesExcl: ['BASE'],
        acl: ['CLERK', 'MANAGER'],
        items: [
            {
                route: '/dashboard/vehicles-locations',
                title: 'Vehicles Locations',
                iconSet: 'fas',
                fontIcon: 'ambulance',
            },
        ],
    },
    {
        route: '/dashboard/vehicles-backup',
        title: 'Fleet',
        iconSet: 'far',
        fontIcon: 'fleet',
        tenantTypes: ['BASE'],
        acl: ['CLERK', 'MANAGER'],
        items: [
            // {
            //   route: '/dashboard/fleet/alert',
            //   title: 'Alert',
            //   iconSet: 'far',
            //   fontIcon: 'alert',
            //   tenantTypes: ['BASE'],
            // },
            {
                route: '/dashboard/vehicles-backup',
                title: 'Backup Vehicles',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/vehicles-in-maintenance',
                title: 'In Maintenance Vehicles',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/fleet/daily-checks',
                title: 'Daily Checks',
                iconSet: 'fas',
                fontIcon: 'assign',
                tenantTypes: ['BASE'],
                acl: ['CLERK', 'MANAGER'],
            },
            {
                route: '/dashboard/vehicles',
                title: 'All Vehicles',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/vehicles-locations',
                title: 'Vehicles Locations',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            // {
            //   route: '/dashboard/fleet',
            //   title: 'Allocation - By Assignment',
            //   iconSet: 'far',
            //   fontIcon: 'fleet',
            //   tenantTypes: ['BASE'],
            // },
            {
                route: '/dashboard/stats/vehicle',
                title: 'Allocation - By Signatures',
                iconSet: 'far',
                fontIcon: 'fleet',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/assign-vehicle',
                title: 'Assign Vehicles',
                iconSet: 'fas',
                fontIcon: 'assign',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/fleet/vehicle-utilisation',
                title: 'Vehicle Utilisation Pivot',
                iconSet: 'fas',
                fontIcon: 'utilisation',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/vehicle-utilisation',
                title: 'Vehicle Utilisation',
                iconSet: 'fas',
                fontIcon: 'utilisation',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/vehicle-docs',
                title: 'Vehicle Docs',
                iconSet: 'fas',
                fontIcon: 'vehicle',
                tenantTypes: ['BASE'],
                acl: ['CLERK', 'MANAGER'],
            },
            {
                route: '/dashboard/drivers-info',
                title: 'Drivers Info',
                iconSet: 'fas',
                fontIcon: 'drivers',
                tenantTypes: ['BASE'],
                acl: ['CLERK', 'MANAGER'],
            },
        ],
    },
    {
        route: '/dashboard/operations/backup-drivers',
        title: 'Operations',
        iconSet: 'far',
        fontIcon: 'database',
        tenantTypes: ['BASE'],
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            {
                route: '/dashboard/operations/backup-drivers',
                title: 'Backup Drivers',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/operations-new/adc-closed-days',
                title: 'ADC Closed Days',
                iconSet: 'fas',
                fontIcon: 'adc-closed-days',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/operations/unit-stats',
                title: 'Signatures',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/operations/employee-sign-stats',
                title: 'Attendance',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
            },
            // {
            //   route: '/dashboard/stats/vehicle',
            //   title: 'Fleet',
            //   iconSet: 'far', fontIcon: 'fleet',
            // },
            {
                route: '/dashboard/settings/fuel-card-txs',
                title: 'Fuel Card Transactions',
                iconSet: 'fas',
                fontIcon: 'card',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/billing/fuel-refill',
                title: 'Fuel Card Report',
                iconSet: 'fas',
                fontIcon: 'adc',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/operations-new/signatures',
                title: 'School Trip Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
            },
            //
            {
                route: '/dashboard/operations-new/service-validation-v2',
                title: 'Validate Services',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['SERVICE_VALIDATOR', 'BILLER'],
            },
            // {
            //   route: '/dashboard/operations-new/signatures-validation',
            //   title: 'Validate Services Old',
            //   iconSet: 'fas',
            //   fontIcon: 'operations',
            //   tenantTypes: ['BASE'],
            //   acl: ['SERVICE_VALIDATOR', 'BILLER'],
            // },
            {
                route: '/dashboard/operations/trips-audit',
                title: 'Trips Audit',
                iconSet: 'fas',
                fontIcon: 'ambulance',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/operations-new/tardiness-log',
                title: 'OPS Alert - Tardiness Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
            },
        ],
    },
    {
        route: '/dashboard/employees',
        title: 'Employees',
        iconSet: 'fas',
        fontIcon: 'employees',
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            {
                route: '/dashboard/employees',
                title: 'Base View',
                iconSet: 'fas',
                fontIcon: 'employees',
            },
            {
                route: '/dashboard/employees-ext',
                title: 'Extended View',
                iconSet: 'fas',
                fontIcon: 'employees',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/employees-perf',
                title: 'Performance View',
                iconSet: 'fas',
                fontIcon: 'employees',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/employee-docs',
                title: 'Employee Docs',
                iconSet: 'fas',
                fontIcon: 'employees',
                tenantTypes: ['BASE'],
                acl: ['CLERK', 'MANAGER'],
            },
        ],
    },
    {
        route: '/dashboard/consumers',
        title: 'Consumers',
        iconSet: 'far',
        fontIcon: 'clipboard-user',
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
    },
    {
        route: '/dashboard/eligibility',
        title: 'Eligibility',
        iconSet: 'far',
        fontIcon: 'clipboard-user',
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
    },
    {
        route: '/dashboard/service-auths/auths',
        title: 'Authorizations',
        iconSet: 'fas',
        fontIcon: 'fas-authorizations',
        tenantTypes: ['MEALS'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
    },
    {
        route: '/dashboard/service-auths/auths-grid',
        title: 'Authorizations',
        iconSet: 'far',
        fontIcon: 'auth',
        tenantTypes: ['BASE'],
        acl: ['MANAGER', 'BILLER'],
        items: [
            {
                route: '/dashboard/service-auths/unassigned-clients',
                title: 'Unassigned Clients',
            },
            {
                route: '/dashboard/service-auths/expired-auths',
                title: 'Authorizations Expirations',
            },
            {
                route: '/dashboard/service-auths/list-2',
                title: 'List 2',
                acl: ['SU'],
            },
        ],
    },
    {
        route: '/dashboard/all-consumers',
        title: 'Global Search',
        iconSet: 'far',
        fontIcon: 'database',
        tenantTypes: ['BASE'],
        acl: ['MANAGER'],
        items: [
            {
                route: '/dashboard/all-consumers',
                title: 'All Consumers',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/broker-trips/mtm',
                title: 'MTM Brokers Trips',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/broker-trips/cts',
                title: 'CTS Brokers Trips',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/broker-trips/lgtc',
                title: 'LGTC Brokers Trips',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/lgtc-clients',
                title: 'LGTC Clients',
                iconSet: 'fas',
                fontIcon: 'client',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/hha-records',
                title: 'HHA Records',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
        ],
    },
    {
        route: '/dashboard/settings',
        title: 'Settings',
        iconSet: 'fas',
        fontIcon: 'settings',
        acl: ['CLERK', 'MANAGER'],
        den: ['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'],
        items: [
            { route: '/dashboard/settings/general', title: 'General', iconSet: 'fas', fontIcon: 'general' },
            { route: '/dashboard/settings/facility', title: 'Facility', iconSet: 'fas', fontIcon: 'facility' },
            {
                route: '/dashboard/settings/devices',
                title: 'Devices',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/settings/fuel-cards',
                title: 'Fuel Cards',
                iconSet: 'fas',
                fontIcon: 'card',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/settings/destinations',
                title: 'Destinations',
                iconSet: 'fas',
                fontIcon: 'destinations',
            },
            {
                route: '/dashboard/settings/acquisitionContacts',
                title: 'Fleet Acquisition Contacts',
                iconSet: 'fas',
                fontIcon: 'acquisitionContacts',
                tenantTypes: ['BASE'],
            },
            {
                route: '/dashboard/document-types',
                title: 'Document Types',
                iconSet: 'fas',
                fontIcon: 'document-types',
                tenantTypes: ['BASE'],
                acl: ['SU'],
            },
            {
                route: '/dashboard/employee-positions',
                title: 'Employee Positions',
                iconSet: 'fas',
                fontIcon: 'positions',
                tenantTypes: ['BASE'],
                acl: ['SU'],
            },
            {
                route: '/dashboard/settings/notifications',
                title: 'Notifications',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/settings/auto-repair-shop',
                title: 'AutoRepair Shop',
                iconSet: 'fas',
                fontIcon: 'device',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
        ],
    },
    // {route: '/dashboard/assign-consumers', title: 'Assign Consumers',
    // tenantTypes: ['BASE'], acl: ['MANAGER'], iconSet: '', fontIcon: ''},
    {
        route: '/dashboard/import',
        title: 'Import',
        iconSet: 'fas',
        fontIcon: 'import',
        acl: ['BILLER', 'MANAGER'],
        tenantTypes: ['BASE', 'TC'],
        items: [
            {
                route: '/dashboard/import',
                title: 'Import Files',
                iconSet: 'fas',
                fontIcon: 'file-import',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/operations-new/exports-consistency-check',
                title: 'Import Consistency Check',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['BILLER'],
            },
        ],
    },
    // {
    //   route: '/dashboard/old-reports', title: 'Reports', iconSet: 'fas', fontIcon: 'file-excel'
    //   tenantTypes: ['BASE'],
    //   acl: ['MANAGER'],
    // },
    {
        route: '/dashboard/reports',
        title: 'Reports',
        iconSet: 'fas',
        fontIcon: 'file-excel',
        tenantTypes: ['BASE'],
        acl: ['MANAGER'],
    },
    {
        route: '/dashboard/logs',
        title: 'Logs',
        iconSet: 'far',
        fontIcon: 'database',
        tenantTypes: ['BASE'],
        acl: ['MANAGER'],
        items: [
            {
                route: '/dashboard/logs/file-uploads',
                title: 'File Uploads',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/logs/consumer-change',
                title: 'Clients Updates',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/trip-trace',
                title: 'AutoValidation Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/authentications-log',
                title: 'Authentications Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/gmaps-log',
                title: 'Router Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/mtm-api-logs',
                title: 'MTM Api Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/trips-log',
                title: 'МТМ ETD Webhook Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/operations-new/tardiness-log',
                title: 'OPS Alert - Tardiness Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
            {
                route: '/dashboard/logs/clon-log',
                title: 'Clone Log',
                iconSet: 'fas',
                fontIcon: 'operations',
                tenantTypes: ['BASE'],
                acl: ['MANAGER'],
            },
        ],
    },
    {
        route: '/dashboard/bill',
        title: 'Billing',
        iconSet: 'far',
        fontIcon: 'currency',
        tenantTypes: ['BASE'],
        acl: ['BILLER'],
        items: [
            {
                route: '/dashboard/billing/stats',
                title: 'Trips Stats',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/billing/unit-stats',
                title: 'Unit Stats',
                iconSet: 'fas',
                fontIcon: 'adc',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/billing/processing2',
                title: 'Processing',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/broker-trips/mtm-not-assigned',
                title: 'Cancel MTM Trips',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['BILLER'],
            },
            {
                route: '/dashboard/service-auths/auths-claims-processing',
                title: isAlpha() ? 'Auths Processing' : 'Meals Processing',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            // {
            //   route: '/dashboard/billing/list',
            //   title: 'Processing Old',
            //   iconSet: 'far',
            //   fontIcon: 'database',
            //   tenantTypes: ['BASE'],
            //   acl: ['MANAGER', 'BILLER'],
            // },
            {
                route: '/dashboard/bill/pivot',
                title: 'Billing Pivot',
                iconSet: 'far',
                fontIcon: 'currency',
                tenantTypes: ['BASE'],
                acl: ['BILLER'],
            },
            {
                route: '/dashboard/billing/remittance-pivot',
                title: 'Remittance Pivot',
                iconSet: 'far',
                fontIcon: 'currency',
                tenantTypes: ['BASE'],
                acl: ['BILLER'],
            },
            {
                route: '/dashboard/billing/meals-claims-pivot',
                title: 'Meal Claims Pivot',
                iconSet: 'far',
                fontIcon: 'currency',
                tenantTypes: ['BASE'],
                acl: ['BILLER'],
            },
            {
                route: '/dashboard/broker-claims/mtm-claims',
                title: 'MTM Claims',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/stats/approved-claims-pivot',
                title: 'MTM Payment Schedule',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
            {
                route: '/dashboard/broker-claims/cts-claims',
                title: 'CTS Claims',
                iconSet: 'far',
                fontIcon: 'database',
                tenantTypes: ['BASE'],
                acl: ['MANAGER', 'BILLER'],
            },
        ],
    },
    {
        route: '/dashboard/documents',
        title: 'Documents',
        iconSet: 'fas',
        fontIcon: 'documents',
        acl: ['SU'],
    },
    //
    {
        route: '/dashboard/facilities',
        title: 'Facilities',
        iconSet: 'fas',
        fontIcon: 'facilities',
        tenantIds: [-1],
        acl: ['SU'],
    },
    //
    {
        route: '/dashboard/users',
        title: 'User Management',
        iconSet: 'fas',
        fontIcon: 'users',
        tenantIds: [-1],
        acl: ['SU'],
        items: [
            { route: '/dashboard/users/roles', tenantIds: [-1], title: 'Roles', iconSet: 'fas', fontIcon: 'edit-user' },
            { route: '/dashboard/users/list', tenantIds: [-1], title: 'Users', iconSet: 'fas', fontIcon: 'user-list' },
            { route: '/dashboard/users/acl', tenantIds: [-1], title: 'ACL', iconSet: 'fas', fontIcon: 'edit-user' },
        ],
    },
    {
        route: '/dashboard/tasks',
        title: 'Task Manager',
        iconSet: 'fas',
        fontIcon: 'tasks',
        tenantIds: [-1],
        acl: ['SU'],
    },
    {
        route: '/dashboard/pers',
        title: 'PERS',
        iconSet: 'fas',
        fontIcon: 'pers',
        tenantTypes: ['TC'],
        acl: ['SU', 'MANAGER', 'CLERK'],
        items: [
            {
                route: '/dashboard/pers/legacy-activity-log',
                title: 'Legacy Activity Log',
                iconSet: 'fas',
                fontIcon: 'log',
                tenantTypes: ['TC'],
                acl: ['SU', 'MANAGER', 'CLERK'],
            },
        ],
    },
];
export { ɵ0, ɵ1, ɵ2 };
