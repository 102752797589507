/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-position.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/employee-position/employee-position-list/employee-position-list.component.ngfactory";
import * as i3 from "../../components/employee-position/employee-position-list/employee-position-list.component";
import * as i4 from "./employee-position.component";
var styles_EmployeePositionComponent = [i0.styles];
var RenderType_EmployeePositionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePositionComponent, data: {} });
export { RenderType_EmployeePositionComponent as RenderType_EmployeePositionComponent };
export function View_EmployeePositionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-position-list", [], null, null, null, i2.View_EmployeePositionListComponent_0, i2.RenderType_EmployeePositionListComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmployeePositionListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EmployeePositionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-position", [], null, null, null, View_EmployeePositionComponent_0, RenderType_EmployeePositionComponent)), i1.ɵdid(1, 114688, null, 0, i4.EmployeePositionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeePositionComponentNgFactory = i1.ɵccf("app-employee-position", i4.EmployeePositionComponent, View_EmployeePositionComponent_Host_0, {}, {}, []);
export { EmployeePositionComponentNgFactory as EmployeePositionComponentNgFactory };
