import {
  ObjectCFV
} from '../index';

declare var Object: any;
export interface CustomFieldInterface {
  "objectType"?: string;
  "objectId": number;
  "field": string;
  "label": string;
  "type": string;
  "optional": boolean;
  "order"?: number;
  "predefined"?: boolean;
  "meta"?: any;
  "id"?: number;
  forObjectType?: any;
  customFieldValues?: ObjectCFV[];
  documents?: any[];
}

export class CustomField implements CustomFieldInterface {
  "objectType": string;
  "objectId": number;
  "field": string;
  "label": string;
  "type": string;
  "optional": boolean;
  "order": number;
  "predefined": boolean;
  "meta": any;
  "id": number;
  forObjectType: any;
  customFieldValues: ObjectCFV[];
  documents: any[];
  constructor(data?: CustomFieldInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomField`.
   */
  public static getModelName() {
    return "CustomField";
  }

  /**
  * This method creates an instance of CustomField for dynamic purposes.
  **/
  public static factory(data: CustomFieldInterface): CustomField{
    return new CustomField(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomField',
      plural: 'CustomFields',
      path: 'CustomFields',
      idName: 'id',
      properties: {
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "field": {
          name: 'field',
          type: 'string'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "optional": {
          name: 'optional',
          type: 'boolean',
          default: false
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "predefined": {
          name: 'predefined',
          type: 'boolean',
          default: false
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        forObjectType: {
          name: 'forObjectType',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'objectId',
          keyTo: 'id'
        },
        customFieldValues: {
          name: 'customFieldValues',
          type: 'ObjectCFV[]',
          model: 'ObjectCFV',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'customFieldId'
        },
        documents: {
          name: 'documents',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'ObjectCFV',
          keyThrough: 'objectId',
          keyFrom: 'id',
          keyTo: 'customFieldId'
        },
      }
    }
  }
}
