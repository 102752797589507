import { Component, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { FormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Config, ConfigApi, LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { LoadConfig } from '../../../../store/actions/core';
import notify from 'devextreme/ui/notify';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { map, switchMap, tap } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { remove } from 'lodash-es';
import DataSource from 'devextreme/data/data_source';
import fromPairs from 'lodash-es/fromPairs';
import ArrayStore from 'devextreme/data/array_store';
import isString from 'lodash-es/isString';

@Component({
  selector: 'app-destinations-grid',
  templateUrl: './destinations-grid.component.html',
  styleUrls: ['./destinations-grid.component.scss'],
})
export class DestinationsGridComponent extends ABaseComponent {
  processing = false;
  disabled = true;
  dirty: boolean;

  destinationListDS$: Observable<DataSource>;
  private destinations: { group?: string; short: string; name: string; address: string; phone: string }[];

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
  ) {
    super(logger);

    this.buildDataSource();
  }

  private buildDataSource() {
    this.destinationListDS$ = this.dss
      .getApi<ConfigApi>(Config)
      .getConfigData()
      .pipe(
        map(configData => {
          if (configData) {
            this.destinations = [...configData.destinationList];

            const store = new ArrayStore({
              key: 'short',
              data: this.destinations,
              onInserting(values: any) {
                Object.entries(values).forEach(([k, v]) => (values[k] = isString(v) ? v.trim() : v));
              },
              onUpdating(key: string, values: any) {
                Object.entries(values).forEach(([k, v]) => (values[k] = isString(v) ? v.trim() : v));
              },
            });

            // const store = new CustomStore({
            //   key: 'short',
            //   byKey: async (key: string) => {
            //     // console.log(key);
            //     key = key.trim();
            //     return this.destinations.find(itm => itm.short === key);
            //   },
            //   load: async (options: LoadOptions) => {
            //     // console.log(options);
            //     return this.destinations;
            //   },
            //   totalCount: async (loadOptions: { filter?: any, group?: any }) => {
            //     // console.log(loadOptions);
            //     return this.destinations.length;
            //   },
            //   insert: async (values: any) => {
            //     values = fromPairs(Object.entries(values).map(([k, v]) => [k.trim(), v]));
            //     const itm = this.destinations.find(i => i.short === values.short);
            //     if (itm) throw new Error('Item already exists');
            //     this.destinations.unshift(values);
            //     return values;
            //   },
            //   update: async (key: string, values: any) => {
            //     key = key.trim();
            //     values = fromPairs(Object.entries(values).map(([k, v]) => [k.trim(), v]));
            //     const itmIdx = this.destinations.findIndex(i => i.short === key);
            //     if (itmIdx >= 0) {
            //       this.destinations[itmIdx] = {...this.destinations[itmIdx], ...values};
            //     }
            //   },
            //   remove: async (key: string) => {
            //     key = key.trim();
            //     remove(this.destinations, (itm) => itm.short === key);
            //   },
            // });

            return new DataSource(store);
          } else {
            this.destinations = null;
          }
        }),
      );

    // this.destinationListDS$ = this.common.store.pipe(
    //   select(getConfig),
    //   map(config => {
    //     if (config.data) {
    //       this.destinations = [...config.data.destinationList];
    //       const store = new CustomStore({
    //         key: 'short',
    //         byKey: async (key: any | string | number) => {
    //           console.log(key);
    //           return this.destinations.find(itm => itm.short === key);
    //         },
    //         load: async (options: LoadOptions) => {
    //           console.log(options);
    //
    //           return this.destinations;
    //         },
    //         totalCount: async (loadOptions: { filter?: any, group?: any }) => {
    //           console.log(loadOptions);
    //
    //           return this.destinations.length;
    //         },
    //         insert: async (values: any) => {
    //           this.destinations.unshift(values);
    //           return values;
    //         },
    //         update: async (key: any | string | number, values: any) => {
    //           const itmIdx = this.destinations.findIndex(i => i.short === key);
    //           if (itmIdx >= 0) {
    //             this.destinations[itmIdx] = {...this.destinations[itmIdx], ...values};
    //           }
    //         },
    //         remove: async (key: any | string | number) => {
    //           remove(this.destinations, (itm) => itm.short === key);
    //         },
    //       });
    //       return new DataSource(store);
    //     } else {
    //       this.destinations = null;
    //     }
    //   }),
    // );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return !this.dirty;
  }

  grid_onSaved(e) {
    this.disabled = false;
  }

  submit_onClick(e) {
    void of(true)
      .pipe(
        tap(() => (this.processing = true)),
        // delay(1),
        switchMap(async () => {
          if (this.destinations) {
            const value = this.destinations;
            await this.dss.getApi<ConfigApi>(Config).saveConfigData({ destinationList: value }).toPromise();
            this.common.store.dispatch(new LoadConfig());
            this.dirty = false;
            this.disabled = true;
            notify('Done!', 'success');
          } else {
            notify('No data', 'error');
          }
        }),
      )
      .toPromise()
      .catch(err => notify(err.message, 'error', 5000))
      .finally(() => (this.processing = false));
  }

  reset_onClick(e): void {
    setTimeout(() => {
      this.buildDataSource();
      this.dirty = false;
      this.disabled = true;
    });
  }
}
