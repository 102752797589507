import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService } from '../../../consumer/services/helper.service';
import { VehicleFormComponent } from '../../../vehicle/components/vehicle-form/vehicle-form.component';

@Component({
  selector: 'app-daily-checks-details-tab-vehicle',
  templateUrl: './daily-checks-details-tab-vehicle.component.html',
  styleUrls: ['./daily-checks-details-tab-vehicle.component.scss'],
})
export class DailyChecksDetailsTabVehicleComponent
  extends ABaseModelPaneWToolbarComponent<Vehicle>
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  isBiller$: Observable<boolean>;

  showAdd = false;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    private common: CommonService,
  ) {
    super(logger, ui, dss);

    this.caption = 'Vehicle Info';
    this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {}

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Vehicle>> {
    return VehicleFormComponent;
  }

  protected get observeModels(): any[] {
    return [this.ModelClass];
  }
}
