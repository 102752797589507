<div mat-dialog-title>Select tenant</div>

<mat-dialog-content>
  <div style="max-height: 300px; overflow: hidden">
    <dx-list
      height="300"
      [dataSource]="dso$ | async"
      [selectedItems]="[]"
      [disabled]="isSetTenantPending$ | async"
      valueExpr="id"
      displayExpr="shortname"
      itemTemplate="facilityItem"
      [grouped]="grouped"
      selectionMode="single"
      pageLoadMode="scrollBottom"
      (onSelectionChanged)="list_onSelectionChanged($event)"
    >
      <div *dxTemplate="let item of 'group'">
        <small class="f-group">{{ item.key }}</small>
      </div>
      <div *dxTemplate="let f of 'facilityItem'">
        <strong>{{ f.name }} </strong>
        <small>[{{ f.type }}: {{ f.shortname }}]</small>
      </div>
    </dx-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <span style="flex: auto"></span>

  <button mat-button *ngIf="isSU$ | async" type="button" color="primary" (click)="skip_click()">Skip</button>

  <button mat-button cdkFocusInitial type="button" color="primary" (click)="cancel_click()">Cancel</button>
</mat-dialog-actions>
