import { CdkScrollable } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { confirm } from 'devextreme/ui/dialog';
import isBoolean from 'lodash/isBoolean';
import { from, merge, Observable, of } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
//
import { subheadingHideShowAnimation } from '../../../../../animations';
import { LoggerService } from '../../../../sdk';
import { CommonService } from '../../../my-common/services/common.service';
import { ABaseFormComponent } from '../abstract/a-base-form.component';
import { FORM_STATE } from '../abstract/a-base-model-loader.component';
import { ABaseComponent } from '../abstract/a-base.component';

export interface FormDialogDataConfig<M> {
  modelId: number | string;
  ModelClass: Type<M> & any;
  FormComponentClass: Type<ABaseFormComponent<M>>;
  title?: string;
  inputs?: { [key: string]: any };
  headerBtns?: Array<{ icon?; title?; tooltip?; click: (e) => any }>;
}

@Component({
  selector: 'app-form-dialog-template',
  templateUrl: './form-dialog-template.component.html',
  styleUrls: ['./form-dialog-template.component.scss'],
  animations: [subheadingHideShowAnimation],
})
export class FormDialogTemplateComponent<M> extends ABaseComponent implements OnInit, OnDestroy {
  scrollOnTop = true;

  @ViewChild(CdkScrollable, { static: true }) scrollable: CdkScrollable;
  // @ViewChild('target', {read: ViewContainerRef}) target: ViewContainerRef;
  @ViewChild('target', { read: ViewContainerRef, static: true }) target: ViewContainerRef;

  formRef: ComponentRef<ABaseFormComponent<M>>;
  closeAfterSubmit = false;
  private confirmingExit = false;

  constructor(
    public dialogRef: MatDialogRef<FormDialogTemplateComponent<M>>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogDataConfig<M>,
    protected logger: LoggerService,
    private snackBar: MatSnackBar,
    protected dialog: MatDialog,
    private common: CommonService,
    private cfr: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    @Inject(Injector) private injector: Injector,
  ) {
    super(logger);
  }

  get formComponent(): ABaseFormComponent<M> {
    return oc(this.formRef).instance();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadComponent();

    // this.dialogRef.beforeClose().pipe(
    //  takeUntil(this.$onDestroy$),
    // ).subscribe((...args) => console.log(args));

    merge(this.dialogRef.backdropClick(), this.dialogRef.keydownEvents().pipe(filter(e => e.key === 'Escape')))
      .pipe(switchMap(this.closeWoSaving$.bind(this)), takeUntil(this.$onDestroy$))
      .subscribe();
  }

  loadComponent(): void {
    // setTimeout(() => {
    const componentFactory = this.cfr.resolveComponentFactory(this.data.FormComponentClass);
    this.target.clear();
    this.formRef = this.target.createComponent(componentFactory);

    if (this.data.modelId) {
      this.formRef.instance.modelId = this.data.modelId;
    }

    Object.entries(this.data.inputs || {}).forEach(([key, value]) => {
      this.formRef.instance[key] = value;
    });

    // this.formRef.changeDetectorRef.markForCheck();

    this.formRef.instance.validateFails
      .pipe(takeUntil(this.$onDestroy$))
      .subscribe(() => this.snackBar.open('There are invalid form fields!', undefined, { duration: 2000 }));

    this.scrollable
      .elementScrolled()
      .pipe(takeUntil(this.$onDestroy$))
      .subscribe((e: any) => {
        const onTop = e.target.scrollTop === 0;
        if (onTop !== this.scrollOnTop) {
          this.scrollOnTop = onTop;
          this.cd.detectChanges();
        }
      });
    // });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    // this.formRef && this.formRef.destroy();
  }

  showMore_click(e): void {
    if (this.formComponent) {
      this.formComponent.setState(this.formComponent.stateCollapsed ? FORM_STATE.FULL : FORM_STATE.COLLAPSED);
    }
  }

  form_ngSubmit(e): void {
    // console.log(e);

    if (this.formComponent) {
      of(true)
        .pipe(
          // tap(() => this.common.incPending()),
          switchMap(() => this.formComponent.submitFormAsync()),
          // tap(() => this.common.decPending()),
          takeUntil(this.$onDestroy$),
        )
        .subscribe(data => {
          if (this.closeAfterSubmit) {
            data && this.dialogRef.close(data);
          }
        });
    }
  }

  header_btn_click(e, handler) {
    handler.bind(this.formComponent)(e);
  }

  reset_click(e): void {
    this.formComponent && this.formComponent.reset();
  }

  cancel_click(e): void {
    this.dialogRef.close(false);
  }

  submit_click(e): void {
    this.closeAfterSubmit = false;
  }

  submitAndClose_click(e): void {
    this.closeAfterSubmit = true;
  }

  private canDeactivateForm(): Observable<boolean> {
    const res: any = oc(this.formComponent).canDeactivate() ? this.formComponent.canDeactivate() : undefined;

    if (res instanceof Promise) {
      return from(res);
    } else if (res instanceof Observable) {
      return res;
    } else if (isBoolean(res)) {
      return of(res);
    } else {
      return of(true);
    }
  }

  private closeWoSaving$(e) {
    return of(e).pipe(
      filter(() => !oc(this.formComponent).submitting(false) && !this.confirmingExit),
      tap(() => (this.confirmingExit = true)),
      switchMap(() => this.canDeactivateForm()),
      switchMap(can =>
        !can
          ? confirm('Are you sure you want to close editor? All unsaved changes will be lost.', 'Confirm close').catch(
            () => false,
          )
          : of(true),
      ),
      tap(dlgResult => {
        if (dlgResult) {
          this.dialogRef.close(false);
        }
      }),
      finalize(() => (this.confirmingExit = false)),
    );
  }
}
