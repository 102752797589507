
declare var Object: any;
export interface MyMessageInterface {
  "id"?: string;
}

export class MyMessage implements MyMessageInterface {
  "id": string;
  constructor(data?: MyMessageInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyMessage`.
   */
  public static getModelName() {
    return "MyMessage";
  }

  /**
  * This method creates an instance of MyMessage for dynamic purposes.
  **/
  public static factory(data: MyMessageInterface): MyMessage{
    return new MyMessage(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyMessage',
      plural: 'MyMessages',
      path: 'MyMessages',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
