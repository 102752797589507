import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AnyNotesPageModule } from 'src/app/shared/modules/ui/components/any-notes-page/any-notes-page.module';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { DocumentModule } from '../document/document.module';
import { DlgEmployeeDocumentGridComponent } from './components/dlg-employee-document-grid/dlg-employee-document-grid.component';
import { EmployeeDetailsTabDocumentComponent } from './components/employee-details-tab-document/employee-details-tab-document.component';
import { EmployeeDetailsTabEmployeeComponent } from './components/employee-details-tab-employee/employee-details-tab-employee.component';
import { EmployeeDetailsTabEventsComponent } from './components/employee-details-tab-events/employee-details-tab-events.component';
import { EmployeeDetailsTabNotesComponent } from './components/employee-details-tab-notes/employee-details-tab-notes.component';
import { EmployeeDetailsTabPayrollComponent } from './components/employee-details-tab-payroll/employee-details-tab-payroll.component';
import { EmployeePayrollEditFormComponent } from './components/employee-details-tab-payroll/employee-payroll-edit-form/employee-payroll-edit-form.component';
import { EmployeeDetailsTabProfileComponent } from './components/employee-details-tab-profile/employee-details-tab-profile.component';
import { EmployeeDetailsTabsComponent } from './components/employee-details-tabs/employee-details-tabs.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { EmployeeDocumentGridComponent } from './components/employee-document-grid/employee-document-grid.component';
import { EmployeeFormComponent } from './components/employee-form/employee-form.component';
import { EmployeeGridComponent } from './components/employee-grid/employee-grid.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
//
import { EmployeeComponent } from './containers/employee/employee.component';
import { DlgAssignFacilityComponent } from './dialogs/dlg-assign-facility/dlg-assign-facility.component';
import { PersonalPhotoComponent } from './components/personal-photo/personal-photo.component';
import { PersonalPhotoCropperComponent } from './components/personal-photo-cropper/personal-photo-cropper.component';
import { EmployeeExtGridComponent } from './components/employee-ext/employee-ext-grid/employee-ext-grid.component';
import { EmployeeExtListComponent } from './components/employee-ext/employee-ext-list/employee-ext-list.component';
import { EmployeeExtComponent } from './containers/employee-ext/employee-ext.component';
import { EmployeeExtDetailsComponent } from './components/employee-ext/employee-ext-details/employee-ext-details.component';
import { EmployeeExtDetailsTabsComponent } from './components/employee-ext/employee-ext-details-tabs/employee-ext-details-tabs.component';
import { EmployeePerfComponent } from './containers/employee-perf/employee-perf.component';
import { EmployeePerfListComponent } from './components/employee-perf/employee-perf-list/employee-perf-list.component';
import { EmployeePerfGridComponent } from './components/employee-perf/employee-perf-grid/employee-perf-grid.component';
import { EmployeePerfDetailsComponent } from './components/employee-perf/employee-perf-details/employee-perf-details.component';
import { EmployeeHRCertsComponent } from './containers/employee-hr-certs/employee-hr-certs.component';
import { EmployeeHrCertsListComponent } from './components/employee-hr-certs/employee-hr-certs-list/employee-hr-certs-list.component';
import { EmployeeHrCertsGridComponent } from './components/employee-hr-certs/employee-hr-certs-grid/employee-hr-certs-grid.component';
import { EmployeeFleetComponent } from './containers/employee-fleet/employee-fleet.component';
import { EmployeeFleetGridComponent } from './components/employee-fleet/employee-fleet-grid/employee-fleet-grid.component';
import { EmployeePositionListComponent } from './components/employee-position/employee-position-list/employee-position-list.component';
import { EmployeePositionGridComponent } from './components/employee-position/employee-position-grid/employee-position-grid.component';
import { EmployeePositionComponent } from './containers/employee-position/employee-position.component';
import { EmployeeWorkingTimeGridComponent } from './components/employee-working-time/employee-working-time-grid/employee-working-time-grid.component';
import { DlgEmployeeWorkingTimeComponent } from './components/employee-working-time/dlg-employee-working-time/dlg-employee-working-time.component';
import { DlgCreateAccountComponent } from './dialogs/dlg-create-account/dlg-create-account.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule, AnyNotesPageModule],
  declarations: [
    PersonalPhotoComponent,
    PersonalPhotoCropperComponent,
    EmployeeComponent,
    EmployeeListComponent,
    EmployeeGridComponent,
    EmployeeDetailsComponent,
    EmployeeDetailsTabsComponent,
    EmployeeDetailsTabProfileComponent,
    EmployeeDetailsTabNotesComponent,
    EmployeeDetailsTabEmployeeComponent,
    EmployeeDetailsTabPayrollComponent,
    EmployeeDetailsTabDocumentComponent,
    EmployeeDocumentGridComponent,
    EmployeeDetailsTabEventsComponent,
    EmployeeFormComponent,
    EmployeePayrollEditFormComponent,
    DlgAssignFacilityComponent,
    DlgCreateAccountComponent,
    DlgEmployeeDocumentGridComponent,

    EmployeeExtComponent,
    EmployeeExtListComponent,
    EmployeeExtGridComponent,
    EmployeeExtDetailsComponent,
    EmployeeExtDetailsTabsComponent,

    EmployeePerfComponent,
    EmployeePerfListComponent,
    EmployeePerfGridComponent,
    EmployeePerfDetailsComponent,

    EmployeeHRCertsComponent,
    EmployeeHrCertsListComponent,
    EmployeeHrCertsGridComponent,

    EmployeeFleetComponent,
    EmployeeFleetGridComponent,

    EmployeePositionComponent,
    EmployeePositionListComponent,
    EmployeePositionGridComponent,
    EmployeeWorkingTimeGridComponent,
    DlgEmployeeWorkingTimeComponent,
  ],
  exports: [
    EmployeeComponent,
    EmployeeExtComponent,
    EmployeePerfComponent,
    EmployeeWorkingTimeGridComponent,
    EmployeeHRCertsComponent,
    EmployeeFleetComponent,
    EmployeePositionComponent,
    EmployeeDocumentGridComponent,
    DlgEmployeeWorkingTimeComponent,
  ],
  entryComponents: [
    PersonalPhotoCropperComponent,
    EmployeeComponent,
    EmployeeExtComponent,
    EmployeePerfComponent,
    EmployeeWorkingTimeGridComponent,
    EmployeeHRCertsComponent,
    EmployeeFleetComponent,
    EmployeePositionComponent,
    EmployeeFormComponent,
    DlgAssignFacilityComponent,
    DlgCreateAccountComponent,
    EmployeePayrollEditFormComponent,
    DlgEmployeeWorkingTimeComponent,
  ],
})
export class EmployeeModule {}
