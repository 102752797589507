import { Component, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//
import { CustomField, DocumentType, LoggerService, LoopBackFilter } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentTypeFormComponent } from '../document-type-form/document-type-form.component';

@Component({
  selector: 'app-document-type-details-tab',
  templateUrl: './document-type-details-tab.component.html',
  styleUrls: ['./document-type-details-tab.component.scss'],
})
export class DocumentTypeDetailsTabComponent extends ABaseModelPaneWToolbarComponent<DocumentType> {
  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger, ui, dss);

    this.caption = 'Document Type Info';
  }

  protected get ModelClass(): any {
    return DocumentType;
  }

  protected get FormComponent(): Type<ABaseFormComponent<DocumentType>> {
    return DocumentTypeFormComponent;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['customFields'],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, CustomField];
  }
}
