import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService, MyUtilsApi, PostShiftReport, VehicleCheckUp, VehicleCheckUpApi } from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-daily-checks-details-tab-pics',
  templateUrl: './daily-checks-details-tab-pics.component.html',
  styleUrls: ['./daily-checks-details-tab-pics.component.scss'],
})
export class DailyChecksDetailsTabPicsComponent
  extends ABaseModelPaneWToolbarComponent<VehicleCheckUp, VehicleCheckUpApi>
  implements OnInit, OnChanges, OnDestroy
{
  showAdd = false;
  showEdit = false;

  fullDoc: (VehicleCheckUp & { checkout: PostShiftReport; _ci_picturesEntries; _co_picturesEntries }) | undefined;
  descAndReport: { ci?: any[]; co?: any[] } | undefined;
  descAndReportMap: { ci?: Map<string, any>; co?: Map<string, any> } | undefined;

  popupVisible = false;
  currentPic = '';

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    private pusher: PusherService,
    private utilsApi: MyUtilsApi,
  ) {
    super(logger, ui, dss);

    this.caption = 'Pictures';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected get ModelClass(): any {
    return VehicleCheckUp;
  }

  protected async beforeModelLoadingAsync(id: number | string): Promise<void> {
    this.fullDoc = undefined;
    this.descAndReport = undefined;
    this.descAndReportMap = { ci: new Map(), co: new Map() };
    return super.beforeModelLoadingAsync(id);
  }

  protected async afterModelLoadedAsync(model: VehicleCheckUp): Promise<void> {
    if (this.modelId) {
      try {
        this.loading = true;
        this.fullDoc = await gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId)
          .pipe(
            tap(doc => {
              doc._ci_picturesEntries = Object.entries(oc(doc).pictures({}));
              doc._co_picturesEntries = Object.entries(oc(doc).checkout.pictures({}));
            }),
          )
          .toPromise();
        this.descAndReport = await this.api.getAllFieldsDescriptions(this.modelId).toPromise();

        this.descAndReportMap = {
          ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm] as [string, any])),
          co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm] as [string, any])),
        };
      } finally {
        this.loading = false;
      }
    }

    return super.afterModelLoadedAsync(model);
  }

  protected get FormComponent(): Type<ABaseFormComponent<VehicleCheckUp>> {
    return undefined;
  }

  showPic(pic: string) {
    this.currentPic = pic;
    this.popupVisible = true;
  }
}
