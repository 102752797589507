import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { cloneDeep } from 'lodash-es';
import { PersonFormComponent } from 'src/app/modules/person/components/person-form/person-form.component';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { UploadHelperService } from 'src/app/shared/modules/ui/services/upload-helper.service';
import { MyFile, Person, Vehicle } from 'src/app/shared/sdk';
import { AnyNote } from 'src/app/shared/sdk/models/AnyNote';

@Component({
  selector: 'app-form-acquisition-array',
  templateUrl: './form-acquisition-array.component.html',
  styleUrls: ['./form-acquisition-array.component.scss'],
})
export class FormAcquisitionArrayComponent {
  @Input() vehicle: Vehicle;
  @Input() dealerDso: DataSourceOptions;
  @Input() creditorDso: DataSourceOptions;
  @Input() personDso: DataSourceOptions;

  @Input() formArray: FormArray;
  @Input() selectedForm: FormGroup;
  @Input() internalId: number;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() validateAsync: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();

  ACQUISITION_TYPE = ['Lease', 'Finance', 'Cash Purchase', 'Unloaded'];
  ACQUISITION_TERM_IN_MONTH = [36, 40, 42, 48, 60, 72, 78];
  ACQUISITION_FREQUENCY_OF_LOAN_PAYMEN = ['Annually', 'Monthly'];

  validationCallback = this.uploadHelper.validationCallback;

  constructor(protected fb: FormBuilder, protected ui: UiService, protected uploadHelper: UploadHelperService) {}

  handleFilesUploaded(e) {
    e.component.reset();
  }

  handleUploaded(e: Event, form: FormGroup) {
    const file: MyFile = this.uploadHelper.handleUploaded(e);
    form.controls.file.setValue([...(form.value.file || []), file]);
  }

  removeFile(idx: number, form: FormGroup) {
    form.controls.file.setValue((form.value.file || []).filter((f, i) => i != idx));
  }

  getAcquisitionName(form: FormGroup): string {
    const date = (form.value.date && ` - ${new Date(form.value.date).toLocaleDateString('en-US')}`) || '';
    return `${this.vehicle.internalId} - ${form.value.type || ''}${date}`;
  }

  getPersonName(form: FormGroup, field: string): string {
    if (!form.value[field]) return '';
    return `${form.value[field].firstname} ${form.value[field].lastname}`;
  }

  getAnyNotes(form: FormGroup): Array<AnyNote> {
    if (!form.value.id) return [];
    const acq = this.vehicle.acquisitions.find(itm => itm.id === form.value.id);
    return acq.anyNotes;
  }

  handleChange(event) {
    if (event.value === this.selectedForm) return;
    this.validateAsync.emit();
    // this.selectedForm.markAllAsTouched();
    if (this.selectedForm.valid) this.valueChanged.emit(event);
    else event.component.option('value', this.selectedForm);
  }

  editPerson_onClick(form: FormGroup, field: string) {
    const modelId = (form.value[field] && form.value[field].id) || null;
    this.ui
      .openEditDialog({
        modelId,
        ModelClass: Person,
        FormComponentClass: PersonFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(person => {
        if (person) {
          form.controls[field].setValue(person);
        }
      });
  }
}
