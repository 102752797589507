import { Inject, Injectable } from '@angular/core';
import { getCurrentScope } from '@sentry/browser';
import isUndefined from 'lodash-es/isUndefined';
import { AccessTokenInterface, InternalStorage, LoopBackAuth, SDKToken } from '../../../sdk';

export interface IExtAccessToken extends AccessTokenInterface {
  currentTenant?: number;
}

export class ExtSDKToken extends SDKToken {
  currentTenant: number = null;

  constructor(data?: IExtAccessToken) {
    super(data);
  }
}

@Injectable()
export class ExtLoopBackAuth extends LoopBackAuth {
  private _token: ExtSDKToken = new ExtSDKToken();

  constructor(@Inject(InternalStorage) protected storage: InternalStorage) {
    super(storage);
    this._token.currentTenant = this.load('currentTenant');
  }

  public getCurrentTenant(): number {
    getCurrentScope().setExtra('facility', 'id:' + this._token.currentTenant);
    return this._token.currentTenant;
  }

  public setCurrentTenant(value: number): void {
    getCurrentScope().setExtra('facility', 'id:' + value);
    this._token.currentTenant = value;
    this.save();
  }

  public save(): boolean {
    super.save();

    const today = new Date();
    const expires = new Date(today.getTime() + this.getToken().ttl * 1000);
    this.persist('currentTenant', !isUndefined(this._token.currentTenant) ? this._token.currentTenant : null, expires);
    return true;
  }

  public clear(): void {
    super.clear();

    Object.keys(this._token).forEach((prop: string) => this.storage.remove(`${this.prefix}${prop}`));
    this._token = new ExtSDKToken();
  }
}
