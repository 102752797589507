export const USER_STATUSES: any[] = [
  { ID: 'ACTIVE', Name: 'ACTIVE' },
  { ID: 'PENDING', Name: 'PENDING' },
  { ID: 'BLOCKED', Name: 'BLOCKED' },
];

export const PERSON_SEXES: any[] = [
  { ID: 'M', Name: 'Male' },
  { ID: 'F', Name: 'Female' },
];
