import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { Observable, of } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { HttpClient } from '@angular/common/http';
import { Facility, LoggerService, MyUserApi, MyUtilsApi, TripManifest, TripManifestApi } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService } from '../../services/helper.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import { getUser } from '../../../../store/reducers/sign';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import isEmpty from 'lodash-es/isEmpty';
import { oc } from 'ts-optchain';
import notify from 'devextreme/ui/notify';
import groupBy from 'lodash-es/groupBy';
import { utc } from 'moment';
import { DlgCalendarComponent } from '../../dialogs/dlg-calendar/dlg-calendar.component';

@Component({
  selector: 'app-lgtc-broker-trips',
  templateUrl: './lgtc-broker-trips.component.html',
  styleUrls: ['./lgtc-broker-trips.component.scss'],
})
export class LgtcBrokerTripsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;
  assignedHeaderFilter: any[];

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '074416e3-5cf9-4b7a-bb3d-469ac85b65da',
    };

    this.isSU$ = this.config.isSU$;

    this.assignedHeaderFilter = [
      {
        text: 'Not Assigned',
        value: ['_manifestRecId', '=', null],
      },
      {
        text: 'Assigned',
        value: ['_manifestRecId', '<>', null],
      },
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngAfterViewInit(): void {}

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        name: 'moveToManifest',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-copy',
          text: 'Move to registration',
          hint: 'Move trips to registration',
          onClick: this.grid_toolbar_moveToManifest_onClick.bind(this),
        },
      },
      {
        name: 'checkTripsInManifest',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-check',
          text: 'Check trips in registration',
          hint: 'Check trips in registration',
          onClick: this.grid_toolbar_checkTripsInManifest_onClick.bind(this),
        },
      },
    );
  }

  grid_onCellPrepared(e) {
    // console.log(e);

    if (e.rowType === 'data') {
      if (!isEmpty(oc(e).data._manifestRecId())) {
        (e.cellElement as HTMLElement).style.color = 'limegreen';
      }
    }
  }

  async grid_toolbar_moveToManifest_onClick() {
    const trips: any[] = this.grid.instance.getSelectedRowsData();

    if (trips.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    this.ui.showLoading();
    of(trips)
      .pipe(
        switchMap(async _trips => {
          const byDate = groupBy(trips, t => utc(t._date).format('YYYY-MM-DD'));

          await Promise.all(
            Object.entries(byDate).map(
              async ([date, dTrips]) =>
                await this.dss.getApi<TripManifestApi>(TripManifest).moveTripsToManifest(date, dTrips).toPromise(),
            ),
          );
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.grid.instance.refresh()),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  async grid_toolbar_checkTripsInManifest_onClick() {
    this.dialog
      .open(DlgCalendarComponent, {
        // width: '250px',
        hasBackdrop: true,
      })
      .afterClosed()
      .pipe(
        filter(date => !!date),
        tap(async date => {
          try {
            this.ui.showLoading();

            await this.dss.getApi<TripManifestApi>(TripManifest).checkTripsOnManifest(date).toPromise();

            this.grid.instance.refresh();
          } finally {
            this.ui.hideLoading();
          }
        }),
      )
      .toPromise()
      .catch(err => notify(err.message || err, 'error', 3000));
  }

  private buildDataSource() {
    const self = this;
    const col = 'LGTC_ExportsDataCache';
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const aggregate = [
          // {
          //   $addFields: {_ctime: {$toDate: '$_id'}},
          // }
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      sort: [
        { selector: '_date', desc: true },
        { selector: '_tripId', desc: false },
      ],
      filter: ['_broker', '=', 'LGTC'],
    };
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'name' }],
    };
    return of(dso);
  }

  manifestRecCustomizeText(cellInfo: any): string {
    return isEmpty(cellInfo.value) ? '' : 'Y';
  }
}
