import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import DevExpress from 'devextreme/bundles/dx.all';
//
import { Document, Employee, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-employee-details-tab-employee',
  templateUrl: './employee-details-tab-employee.component.html',
  styleUrls: ['./employee-details-tab-employee.component.scss'],
  providers: [DocumentHelperService],
})
export class EmployeeDetailsTabEmployeeComponent
  extends ABaseModelPaneWToolbarComponent<Employee>
  implements OnInit, OnChanges, OnDestroy
{
  noticeDSO: DataSourceOptions;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    public docHelper: DocumentHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger, ui, dss);

    this.caption = 'Employee Info';
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Employee>> {
    return EmployeeFormComponent;
  }

  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.noticeDSO = this.buildNoticeDSO(model);
  }

  private buildNoticeDSO(model: Employee): DataSourceOptions {
    const filter = model
      ? [
          ['objectId', '=', model.id],
          ['objectType', '=', Employee.getModelName()],
          ['documentType_name', '=', 'NOTICE'],
        ]
      : undefined;

    return {
      store: this.dss.getStore(Document, null, false),
      filter,
    } as DataSourceOptions;
  }

  onNoticesToolbarPreparing(e) {
    // e.toolbarOptions.items.unshift({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     icon: 'add',
    //     onClick: () => notify('Notice Add button pressed', 'info', 3000),
    //   }
    // });
  }
}
