import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumer-details-tabs',
  templateUrl: './consumer-details-tabs.component.html',
  styleUrls: ['./consumer-details-tabs.component.scss'],
})
export class ConsumerDetailsTabsComponent implements OnInit {
  tabs: any[] = [
    {
      title: 'Profile',
      template: 'profile',
    },
    // {
    //   title: 'Service Order',
    // },
    // {
    //   title: 'Attendance',
    // },
    // {
    //   title: 'Notes',
    // },
    // {
    //   title: 'Incidents',
    // },
    {
      title: 'Docs',
      template: 'document',
    },
    // {
    //   title: 'Alerts',
    // },
    {
      title: 'Instructions',
      template: 'instructions',
    },
  ];

  @Input() modelId: number | string;

  constructor() {}

  ngOnInit() {}
}
