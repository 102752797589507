import { Component, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//
import { Document, LoggerService, LoopBackFilter } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentFormComponent } from '../document-form/document-form.component';

@Component({
  selector: 'app-document-details-tab',
  templateUrl: './document-details-tab.component.html',
  styleUrls: ['./document-details-tab.component.scss'],
})
export class DocumentDetailsTabComponent extends ABaseModelPaneWToolbarComponent<Document> {
  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger, ui, dss);

    this.caption = 'Document Info';
  }

  protected get ModelClass(): any {
    return Document;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Document>> {
    return DocumentFormComponent;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['documentType', 'owner', 'forObject', { customFieldValues: ['customField'] }],
    };
  }

  // protected async afterModelLoadedAsync(model: Document): Promise<any> {
  //   await super.afterModelLoadedAsync(model);
  //
  //   if (model) {
  //     const o = await getObjectAsync(model.objectType, model.objectId, this.dss);
  //     model.forObject['_toString'] = objectToString(o.forObject, o.objectType);
  //     model.customFieldValues.forEach((cfv: ObjectCFV) =>
  //       cfv['_value'] = getObjectCFVValueByCF(cfv, cfv.customField));
  //   }
  // }
}
