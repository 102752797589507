<dx-responsive-box>
  <dxi-row [ratio]="0" [shrink]="0" [baseSize]="'auto'"></dxi-row>
  <dxi-row [ratio]="1" [baseSize]="'auto'"></dxi-row>
  <dxi-col [ratio]="1" [baseSize]="'auto'"></dxi-col>

  <dxi-item [shrink]="0">
    <dxi-location [row]="0" [col]="0"></dxi-location>

    <app-trip-manifest-main-toolbar #toolbar></app-trip-manifest-main-toolbar>
  </dxi-item>

  <dxi-item>
    <dxi-location [row]="1" [col]="0"></dxi-location>

    <ng-container [ngSwitch]="toolbar.selectedIndex">
      <ng-container *ngSwitchCase="0">
        <app-trip-manifest-assignment
          [modelId]="toolbar.selectedDriver"
          [selectedDate]="toolbar.selectedDate"
          (manifestCreated)="onManifestCreated($event)"
          (manifestDeleted)="onManifestDeleted($event)"></app-trip-manifest-assignment>
      </ng-container>

      <ng-container *ngSwitchCase="1">
        <app-trip-manifest-manifest
          [modelId]="toolbar.selectedDriver"
          [selectedDate]="toolbar.selectedDate"></app-trip-manifest-manifest>
      </ng-container>

      <ng-container *ngSwitchCase="3">
        <!-- Stats -->
      </ng-container>
    </ng-container>
  </dxi-item>
</dx-responsive-box>
