import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-authentications-log',
  templateUrl: './authentications-log.component.html',
  styleUrls: ['./authentications-log.component.scss'],
})
export class AuthenticationsLogComponent extends ABaseComponent implements OnInit, AfterViewInit {
  // isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7776d037-26fa-4e37-ab41-14076ee8b4b9',
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
  }

  grid_onInitialized(e) {}

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      ...{ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' },
      options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() },
    });
  }

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const col = 'AuthLog';
    const aggregate = [{ $sort: { time: -1 } }];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    return { store } as DataSourceOptions;
  }

  calculateYesFilterExpression = function (val, op, target) {
    return [this.dataField, ((op === '<>' || op === 'notcontains') && '<>') || '=', 'yes'.includes(val.toLowerCase())];
  };
}
