import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { EmployeeView } from '../../../../shared/sdk/models';
import { EmployeeViewApi, LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
export class CloneLogDetailsTabCreatedSchedulesComponent extends ABaseComponent {
    constructor(logger, ui, dss, dialog) {
        super(logger);
        this.logger = logger;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
    }
    set log(v) {
        this._log = v;
        this.changeHandle();
    }
    get log() {
        return this._log;
    }
    changeHandle() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.log) {
                if (this.log.createdSchedules && !this.log.createdSchedules.some(s => s.driver)) {
                    this.ui.showLoading();
                    const drivers = yield this.dss
                        .getApi(EmployeeView)
                        .find({ where: { employeeId: { inq: [...new Set(this.log.createdSchedules.map(e => e.driverId))] } } }, headersAllTenantsAppend)
                        .toPromise();
                    const drviersMap = drivers.reduce((p, d) => (Object.assign({}, p, { [d.id]: d })), {});
                    this.log.createdSchedules = this.log.createdSchedules.map(s => (Object.assign({}, s, { driver: drviersMap[s.driverId] })));
                    this.ui.hideLoading();
                }
                this.dso = this.log.createdSchedules || [];
            }
        });
    }
}
