import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import isEmpty from 'lodash-es/isEmpty';
import moment, { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { environment } from '../../../../environments/environment';
import { Consumer, ConsumerView, LoopBackFilter } from '../../../shared/sdk/models';
import { ConsumerApi, ConsumerViewApi } from '../../../shared/sdk/services/custom';
import { QrCodePipe } from '../../../shared/modules/my-common/pipes/qr-code.pipe';
import { CommonService } from '../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../../shared/modules/my-common/services/pusher.service';
import { FullNamePipe } from '../../../shared/modules/ui/pipes/full-name.pipe';
import { UiService } from '../../../shared/modules/ui/services/ui.service';

@Injectable()
export class HelperService {
  displayExpr = (c: Consumer | ConsumerView, format?): string => {
    return new FullNamePipe(this.config).transform(c, format);
  };

  attendanceNoteDisplayExpr = (c: ConsumerView): string => {
    const info = [
      [
        c.lastNote_infoBy ? `by ${c.lastNote_infoBy}` : '',
        c.lastNote_infoDate ? `on ${utc(c.lastNote_infoDate).format('YYYY/MM/DD')}` : '',
      ]
        .filter(chnk => !isEmpty(chnk))
        .join(' '),
      c.lastNote_followUpDate ? `Follow up on ${utc(c.lastNote_followUpDate).format('YYYY/MM/DD')}` : '',
    ]
      .filter(chnk => !isEmpty(chnk))
      .join(', ');

    return c.lastNote_id
      ? [
          moment(c.lastNote_dateTime).format('YYYY/MM/DD HH:mm'),
          c.lastNote_author,
          c.lastNote_text + (!isEmpty(info) ? ` (${info})` : ''),
        ].join(' : ')
      : '';
  };

  constructor(
    @Inject(ConsumerApi) public api: ConsumerApi,
    @Inject(ConsumerViewApi) public viewApi: ConsumerViewApi,
    @Inject(ConfigService) public config: ConfigService,
    private pusher: PusherService,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(CommonService) private common: CommonService,
    private ui: UiService,
  ) {}

  loadDetails$(consumerId: number, filter: LoopBackFilter = {}): Observable<Consumer> {
    const defaultFilter = {
      include: [
        'program',
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC' },
        },
        { person: { contact: ['addresses', 'phones', 'emails'] } },
      ],
    } as LoopBackFilter;

    return this.api.findById<Consumer>(consumerId, { ...defaultFilter, ...filter }).pipe(catchError(err => of(null)));
  }

  downloadMtmTranspForm(
    consumerId: any,
    settings: { date?: string; manifestRecId?: string; baseUrl?: string },
  ): Observable<void> {
    settings = { ...settings, baseUrl: environment.apiBaseUrl };
    return this.api.mtmTranspRequestPdfJob(consumerId, JSON.stringify(settings)).pipe(
      switchMap(jobId => this.pusher.requestResponse(jobId)),
      tap(({ bucket, filePdf, uri }) => window.open(uri)),
    );

    // const resp = await this.http.get(url, {
    //   responseType: 'blob',
    //   withCredentials: false,
    // }).toPromise();
    //
    // const src = URL.createObjectURL(resp);
    //
    // await this.common.print(document, src);
  }

  getMtmTranspForm(consumerId: any, settings: { date?: string; manifestRecId?: string }) {
    this.ui.showLoading();
    return this.downloadMtmTranspForm(consumerId, settings).pipe(
      catchError(err => of(notify(err.message || err, 'error', 8000))),
      tap(() => this.ui.hideLoading()),
    );
  }

  printQrCard(consumerId: number) {
    const filter = {
      include: ['person'],
    } as LoopBackFilter;

    return this.api.findById<Consumer>(consumerId, filter).pipe(
      switchMap(c => {
        const base64_1 = new QrCodePipe().transform(c.mci, { size: 100 });
        // const base64_2 = new QrCodePipe().transform(c.mci, {size: 200});
        // const base64_3 = new QrCodePipe().transform(c.mci, {size: 300});

        const cardHtml = `<div style="width: 8.5cm; height: 5.3cm; border: 1px dashed black; position: relative;">
            <img style="position: absolute;
    left: 0.5cm;
    top: 0.7cm;
    width: 3cm;"
     src="${base64_1}"/>

            <div style="position: absolute;
                left: 0.7cm;
                top: 3.9cm;
                font-weight: 600;
                font-size: 13pt;">${
                  this.config.get('consumerMoreFields', []).includes('facilityID') && !isEmpty(oc(c).facilityID(''))
                    ? 'FID: ' + oc(c).facilityID('-')
                    : '# ' +
                      oc(c)
                        .mci('')
                        .substring(oc(c).mci('').length - 4)
                }</div>

            <div style="position: absolute;
    left: 3.8cm;
    top: 0.8cm;
    font-weight: 600;
    font-size: 13pt;
    line-height: 1.4em;">
            ${oc(c).person.firstname()}
            <br>
            &nbsp;&nbsp;&nbsp;${oc(c).person.lastname()}
            <br>
            <br>
            <span
             style="font-weight: normal; font-size: 11pt;"
             >${oc(c).person.data.nativeLangName('')}</span>
            </div>
        </div>`;

        const cardHtml2 = `<div style="width: 8.5cm; height: 5.3cm; border: 1px dashed black; position: relative;">
            <img style="position: absolute;
    left: 0.5cm;
    top: 0.7cm;
    width: 3cm;"
     src="${base64_1}"/>

            <div style="position: absolute;
                left: 0.7cm;
                top: 3.9cm;
                font-weight: 600;
                font-size: 13pt;">${
                  this.config.get('consumerMoreFields', []).includes('facilityID') && !isEmpty(oc(c).facilityID(''))
                    ? 'FID: ' + oc(c).facilityID('-')
                    : '# ' +
                      oc(c)
                        .mci('')
                        .substring(oc(c).mci('').length - 4)
                }</div>

            <div style="position: absolute;
    left: 3.8cm;
    top: 0.8cm;
    font-weight: 600;
    font-size: 13pt;
    line-height: 1.4em;">
              ${oc(c).person.firstname()}
              <br>
              &nbsp;&nbsp;&nbsp;${oc(c).person.lastname()}
              <br>
              <span style="font-weight: normal; font-size: 11pt;">${oc(c).person.data.nativeLangName('')}</span>
            </div>

            <div style="position: absolute;
    left: 3.8cm;
    bottom: 0.3cm;
    font-weight: normal;
    font-size: 10pt;
    line-height: 1em;">
              <span style="font-size: 9pt;">
                Tris QR card is your personal transport ID. Present it to driver upon vehicle entry for scanning.
              </span>
              <br>
              <span style="font-size: 10pt;">
                If lost call <strong>215-660-4911</strong>
              </span>
            </div>
        </div>`;

        const cardHtml3 = `<div style="width: 8.5cm; height: 5.3cm; border: 1px dashed black; position: relative;">
            <img style="position: absolute;
    left: 0.5cm;
    top: 0.7cm;
    width: 3cm;"
     src="${base64_1}"/>

            <div style="position: absolute;
                left: 0.7cm;
                top: 3.9cm;
                font-weight: 600;
                font-size: 13pt;">${
                  this.config.get('consumerMoreFields', []).includes('facilityID') && !isEmpty(oc(c).facilityID(''))
                    ? 'FID: ' + oc(c).facilityID('-')
                    : '# ' +
                      oc(c)
                        .mci('')
                        .substring(oc(c).mci('').length - 4)
                }</div>

            <div style="position: absolute;
    left: 3.8cm;
    top: 0.8cm;
    font-weight: 600;
    font-size: 13pt;
    line-height: 1.4em;">
              ${oc(c).person.firstname()}
              <br>
              &nbsp;&nbsp;&nbsp;${oc(c).person.lastname()}
              <br>
              <span style="font-weight: normal; font-size: 11pt;">${oc(c).person.data.nativeLangName('')}</span>
            </div>

            <div style="position: absolute;
    left: 3.8cm;
    bottom: 0.3cm;
    font-weight: normal;
    font-size: 10pt;
    line-height: 1em;">
              <span style="font-size: 9pt;">
                This card has to remain with you, lending it to others for use or storage can
                be considered as fraud waste and abuse under applicable law.
              </span>
              <br>
              <span style="font-size: 10pt;">
                If lost call <strong>215-660-4911</strong>
              </span>
            </div>
        </div>`;

        const html = `<html><style>
* {
    box-sizing: border-box;
    font-family: sans-serif;
}
</style><body>

    <!--div style="display: flex; align-content: center; justify-content: center">
        <span style="flex: auto;"></span>

        ${cardHtml}
        ${cardHtml}

        <span style="flex: auto;"></span>
    </div>
    <br-->

    <div style="display: flex; align-content: center; justify-content: center">
        <span style="flex: auto;"></span>

        ${cardHtml2}
        ${cardHtml3}

        <span style="flex: auto;"></span>
    </div>
</body></html>`;

        return this.common.printHtml(document, html);
      }),
      catchError(err => of(null)),
    );
  }
}
