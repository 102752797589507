import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
  @Output() mySelected: EventEmitter<any[]> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSelected(keys: any[]) {
    this.mySelected.emit(keys);
  }
}
