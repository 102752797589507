<app-content-w-header header="Fleet Stats" title="data from signatures">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="display: flex">
      <!--      <dx-toolbar>-->
      <!--        <dxi-item [location]="'before'" locateInMenu="auto">-->
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>
      <!--        </dxi-item>-->

      <!--        <dxi-item location="after" locateInMenu="auto">-->
      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
      <!--        </dxi-item>-->

      <span style="flex: auto"></span>

      <!--      <div><dx-check-box-->
      <!--        text="Show Column Totals"-->
      <!--        [(value)]="showColumnTotals"></dx-check-box></div>-->
      <!--      <div><dx-check-box-->
      <!--        text="Show Column Grand Totals"-->
      <!--        [(value)]="showColumnGrandTotals"></dx-check-box></div>-->

      <div><dx-check-box text="Show Row Totals" [(value)]="showRowTotals"></dx-check-box></div>

      <div><dx-check-box text="Show Row Grand Totals" [(value)]="showRowGrandTotals"></dx-check-box></div>

      <!--      </dx-toolbar>-->
    </div>
  </mat-card>

  <div class="content">
    <ng-container *ngFor="let alert of alerts">
      <div style="color: darkred">{{ alert }}</div>
    </ng-container>

    <dx-pivot-grid
      width="100%"
      height="100%"
      [dataSource]="dso"
      [allowExpandAll]="true"
      [allowSortingBySummary]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [showColumnTotals]="showColumnTotals"
      [showRowTotals]="showRowTotals"
      [showColumnGrandTotals]="showColumnGrandTotals"
      [showRowGrandTotals]="showRowGrandTotals"
      [stateStoring]="grid_stateStoring"
      (onCellPrepared)="onCellPrepared($event)">
      <dxo-export [enabled]="true" [fileName]="exportFileName"></dxo-export>

      <dxo-field-panel
        [showDataFields]="false"
        [showRowFields]="true"
        [showColumnFields]="true"
        [showFilterFields]="true"
        [allowFieldDragging]="true"
        [visible]="true">
      </dxo-field-panel>

      <dxo-scrolling [useNative]="true"></dxo-scrolling>
    </dx-pivot-grid>
  </div>
</app-content-w-header>
