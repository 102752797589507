import {
  Employee
} from '../index';

declare var Object: any;
export interface EmployeeWorkingTimeInterface {
  "weekNum": string;
  "employeeId": number;
  "status"?: string;
  "monMinutes": number;
  "tueMinutes": number;
  "wedMinutes": number;
  "thuMinutes": number;
  "friMinutes": number;
  "satMinutes": number;
  "sunMinutes": number;
  "totalMinutes": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "indexes"?: any;
  "id"?: any;
  employee?: Employee;
}

export class EmployeeWorkingTime implements EmployeeWorkingTimeInterface {
  "weekNum": string;
  "employeeId": number;
  "status": string;
  "monMinutes": number;
  "tueMinutes": number;
  "wedMinutes": number;
  "thuMinutes": number;
  "friMinutes": number;
  "satMinutes": number;
  "sunMinutes": number;
  "totalMinutes": number;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": number;
  "indexes": any;
  "id": any;
  employee: Employee;
  constructor(data?: EmployeeWorkingTimeInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeeWorkingTime`.
   */
  public static getModelName() {
    return "EmployeeWorkingTime";
  }

  /**
  * This method creates an instance of EmployeeWorkingTime for dynamic purposes.
  **/
  public static factory(data: EmployeeWorkingTimeInterface): EmployeeWorkingTime{
    return new EmployeeWorkingTime(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeeWorkingTime',
      plural: 'EmployeeWorkingTimes',
      path: 'EmployeeWorkingTimes',
      idName: 'id',
      properties: {
        "weekNum": {
          name: 'weekNum',
          type: 'string'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "monMinutes": {
          name: 'monMinutes',
          type: 'number',
          default: 0
        },
        "tueMinutes": {
          name: 'tueMinutes',
          type: 'number',
          default: 0
        },
        "wedMinutes": {
          name: 'wedMinutes',
          type: 'number',
          default: 0
        },
        "thuMinutes": {
          name: 'thuMinutes',
          type: 'number',
          default: 0
        },
        "friMinutes": {
          name: 'friMinutes',
          type: 'number',
          default: 0
        },
        "satMinutes": {
          name: 'satMinutes',
          type: 'number',
          default: 0
        },
        "sunMinutes": {
          name: 'sunMinutes',
          type: 'number',
          default: 0
        },
        "totalMinutes": {
          name: 'totalMinutes',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "indexes": {
          name: 'indexes',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
      }
    }
  }
}
