/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-details-tab-maintenance-recs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/details-tab/details-tab.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/details-tab/details-tab.component";
import * as i4 from "@angular/common";
import * as i5 from "./vehicle-details-tab-maintenance-recs.component";
import * as i6 from "../../../../shared/modules/my-common/services/config.service";
import * as i7 from "../../../../shared/sdk/services/custom/logger.service";
import * as i8 from "../../../../shared/modules/ui/services/ui.service";
import * as i9 from "../../../consumer/services/helper.service";
import * as i10 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i11 from "@angular/material/dialog";
var styles_VehicleDetailsTabMaintenanceRecsComponent = [i0.styles];
var RenderType_VehicleDetailsTabMaintenanceRecsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleDetailsTabMaintenanceRecsComponent, data: {} });
export { RenderType_VehicleDetailsTabMaintenanceRecsComponent as RenderType_VehicleDetailsTabMaintenanceRecsComponent };
function View_VehicleDetailsTabMaintenanceRecsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dx-fieldset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "dx-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "dx-field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last Mechanical Check"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "dx-field-value-static"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model == null) ? null : _co.model.lastMechanicalCheck); _ck(_v, 6, 0, currVal_0); }); }
export function View_VehicleDetailsTabMaintenanceRecsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-details-tab", [], null, null, null, i2.View_DetailsTabComponent_0, i2.RenderType_DetailsTabComponent)), i1.ɵdid(1, 49152, null, 0, i3.DetailsTabComponent, [], { loading: [0, "loading"], toolbarItems: [1, "toolbarItems"], model: [2, "model"], errorMessage: [3, "errorMessage"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VehicleDetailsTabMaintenanceRecsComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.toolbarItems; var currVal_2 = _co.model; var currVal_3 = _co.errorMessage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.model; _ck(_v, 3, 0, currVal_4); }, null); }
export function View_VehicleDetailsTabMaintenanceRecsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-details-tab-maintenance-recs", [], null, null, null, View_VehicleDetailsTabMaintenanceRecsComponent_0, RenderType_VehicleDetailsTabMaintenanceRecsComponent)), i1.ɵdid(1, 770048, null, 0, i5.VehicleDetailsTabMaintenanceRecsComponent, [i6.ConfigService, i7.LoggerService, i8.UiService, i9.HelperService, i10.DataSourceService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleDetailsTabMaintenanceRecsComponentNgFactory = i1.ɵccf("app-vehicle-details-tab-maintenance-recs", i5.VehicleDetailsTabMaintenanceRecsComponent, View_VehicleDetailsTabMaintenanceRecsComponent_Host_0, { loading: "loading", modelId: "modelId", caption: "caption" }, { modelLoadingError: "modelLoadingError", loadingChange: "loadingChange", modelIdChange: "modelIdChange", beforeLoading: "beforeLoading", loaded: "loaded", afterLoaded: "afterLoaded" }, []);
export { VehicleDetailsTabMaintenanceRecsComponentNgFactory as VehicleDetailsTabMaintenanceRecsComponentNgFactory };
