<app-content-w-header header="School Trip Log">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset" style="">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <dx-select-box
        [dataSource]="schoolDestDS$ | async"
        valueExpr="short"
        displayExpr="name"
        [(value)]="school"
        [showClearButton]="true"
        placeholder="School"></dx-select-box>

      <!--      <dx-select-box-->
      <!--        [dataSource]="facilityDso$ | async"-->
      <!--        displayExpr="name"-->
      <!--        valueExpr="id"-->
      <!--        [(value)]="facilityId"-->
      <!--        placeholder="Facility"-->
      <!--        [showClearButton]="true"-->
      <!--        (onSelectionChanged)="facility_onSelectionChanged($event)"-->
      <!--      ></dx-select-box>-->

      <!--      <dx-select-box-->
      <!--        [dataSource]="serviceTypes"-->
      <!--        [(value)]="serviceType"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Service Type"-->
      <!--        width="110px"-->
      <!--      ></dx-select-box>-->

      <!--      <dx-select-box-->
      <!--        [dataSource]="['CTS', 'MTM', 'LGTC']"-->
      <!--        [(value)]="broker"-->
      <!--        [showClearButton]="true"-->
      <!--        placeholder="Broker"-->
      <!--        width="80px"-->
      <!--      ></dx-select-box>-->

      <!--      <app-employee-drop-down-grid-->
      <!--        [(value)]="driverId"-->
      <!--        [filter]="[]"-->
      <!--        [dataSource]="employeeDso$ | async"-->
      <!--        [grid_columns]="[-->
      <!--                    {dataField: 'tenantId', caption: 'Tenant',-->
      <!--                      lookup: {-->
      <!--                        dataSource: facilityDso$ | async,-->
      <!--                        valueExpr: 'id',-->
      <!--                        displayExpr: 'shortname'-->
      <!--                      }-->
      <!--                    },-->
      <!--                    {dataField: 'person_firstname', caption: 'Firstname'},-->
      <!--                    {dataField: 'person_lastname', caption: 'Lastname'}-->
      <!--                  ]"-->
      <!--        [showClearButton]="true"-->
      <!--      ></app-employee-drop-down-grid>-->

      <!--      <app-consumer-drop-down-grid-->
      <!--        [(value)]="clientId"-->
      <!--        [filter]="[]"-->
      <!--        [dataSource]="consumerDso$ | async"-->
      <!--        [grid_columns]="[-->
      <!--              {dataField: 'mci', caption: 'MCI'},-->
      <!--              {dataField: 'tenantId', caption: 'Tenant',-->
      <!--                lookup: {-->
      <!--                  dataSource: facilityDso$ | async,-->
      <!--                  valueExpr: 'id',-->
      <!--                  displayExpr: 'shortname'-->
      <!--                }-->
      <!--              },-->
      <!--              {dataField: 'person_firstname', caption: 'Firstname'},-->
      <!--              {dataField: 'person_lastname', caption: 'Lastname'}-->
      <!--            ]"-->
      <!--        [showClearButton]="true"-->
      <!--      ></app-consumer-drop-down-grid>-->

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>
  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="dso$ | async"
    [stateStoring]="grid_stateStoring"
    [twoWayBindingEnabled]="false"
    [repaintChangesOnly]="true"
    [errorRowEnabled]="false"
    [searchPanel]="{ visible: false }"
    [headerFilter]="{ visible: true }"
    [filterRow]="{ visible: true }"
    [filterPanel]="{ visible: true }"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    (onCellPrepared)="grid_onCellPrepared($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
    (onSaved)="grid_onSaved($event)">
    <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

    <dxo-paging [pageSize]="config?.config?.itemsPerPage || 50"></dxo-paging>

    <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

    <dxo-export [enabled]="true" fileName="claims-export"></dxo-export>

    <!-- region Columns -->

    <!--<dxi-column type="selection" [sortIndex]="0"></dxi-column>-->

    <!--    <dxi-column caption="User"-->
    <!--                dataField="userId"-->
    <!--    ></dxi-column>-->

    <dxi-column caption="Facility" dataField="tenantId" dataType="string">
      <dxo-lookup [dataSource]="facilitySubDso$ | async" valueExpr="id" displayExpr="shortname"> </dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Date"
      dataField="vdate"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Client" dataField="consumerId">
      <dxo-lookup [dataSource]="consumerSubDso$ | async" valueExpr="id" [displayExpr]="consumerHelper.displayExpr">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="MCI" dataField="getMci"></dxi-column>

    <dxi-column caption="Client Last Name" dataField="getLastName"></dxi-column>

    <dxi-column caption="Client First Name" dataField="getFirstName"></dxi-column>

    <dxi-column caption="Employee" dataField="employeeId">
      <dxo-lookup [dataSource]="employeeSubDso$ | async" valueExpr="id" [displayExpr]="employeeHelper.displayExpr">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Vehicle" dataField="vehicleId">
      <dxo-lookup [dataSource]="vehicleSubDso$ | async" valueExpr="id" displayExpr="internalId"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Manifest Rec" dataField="manifestRecId"></dxi-column>

    <dxi-column caption="Marker" dataField="marker"></dxi-column>

    <!--    -->

    <dxi-column caption="Service Type" dataField="getServiceType" dataType="string"></dxi-column>

    <!--    <dxi-column caption="MCO"-->
    <!--                dataField="getMco"-->
    <!--                dataType="string"-->
    <!--    ></dxi-column>-->

    <dxi-column caption="Broker" dataField="_trip._broker"></dxi-column>

    <dxi-column caption="Trip ID" dataField="_trip._tripId" cellTemplate="tripId_cell"></dxi-column>

    <dxi-column caption="Trips per Day" dataField="_trips.length"></dxi-column>

    <dxi-column caption="Origin" dataField="getOrigin" dataType="string"></dxi-column>

    <dxi-column caption="Destination" dataField="getDestination" dataType="string"></dxi-column>

    <dxi-column
      caption="Signature"
      dataField="imgFileId"
      dataType="string"
      cellTemplate="signature_cell"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <!-- endregion Columns -->

    <!-- region Templates -->

    <ng-container *dxTemplate="let cellInfo of 'tripId_cell'">
      <dx-select-box
        [dataSource]="tripDsoMap.get(cellInfo.key)"
        valueExpr="_tripId"
        displayExpr="_tripId"
        [value]="cellInfo.data?._trip?._tripId"
        [placeholder]="cellInfo.data?._trips?.length === 0 ? 'No Trips' : 'Select...'"
        [showClearButton]="true"
        (selectedItemChange)="tripId_selectedItemChange(cellInfo, $event)"></dx-select-box>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'signature_cell'">
      <img
        style="max-height: 20px"
        [src]="cellInfo.value | signatureLink: 'FILE':'resize--20/trim' | async | sanitizeUrl" />
    </ng-container>

    <!-- endregion Templates -->
  </dx-data-grid>
</app-content-w-header>
