<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">
        {{ data?.title }}
      </div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <dx-map
    width="800px"
    height="600px"
    provider="google"
    [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
    [autoAdjust]="true"
    [zoom]="14"
    [markers]="data.markers"
    [routes]="data.routes">
  </dx-map>
</div>

