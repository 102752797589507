import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trip-manifest',
  templateUrl: './trip-manifest.component.html',
  styleUrls: ['./trip-manifest.component.scss'],
})
export class TripManifestComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
