<div class="payroll-details">
  <div class="payroll-toolbar dx-toolbar">
    <div class="dx-toolbar-label">Payroll</div>
    <button 
      class="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-text"
      (click)="onClickEditPayroll()"
    >Edit Payroll</button>
  </div>

  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Current Payment Arrangement</div>
      <div class="dx-field-value-static">
        {{ PAYMENT_ARRANGEMENT[payroll?.paymentArrangement]}}
      </div>
    </div>

    <div class="dx-field" *ngIf="payroll?.paymentArrangement === 'hourly'">
      <div class="dx-field-label">Current Rate</div>
      <div class="dx-field-value-static">
        {{ payroll.rate }} dollars per hour
      </div>
    </div>

    <div class="dx-field" *ngIf="payroll?.paymentArrangement === 'salary'">
      <div class="dx-field-label">Salary</div>
      <div class="dx-field-value-static">
        {{ payroll.rate }} dollars per year
      </div>
    </div>

    <div class="dx-field" *ngIf="payroll?.paymentArrangement === 'contractor'">
      <div class="dx-field-label">Rate per trip</div>
      <div class="dx-field-value-static">
        {{ payroll.rate }} dollars, plus {{ payroll.rate1 }} per mile
      </div>
    </div>

    <div class="dx-field" *ngIf="payroll?.paymentArrangement === 'contractor'">
      <div class="dx-field-label">Current Rate Period</div>
      <div class="dx-field-value-static">
        {{ payroll.start | date }} - {{ payroll.end | date }}
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Current Increase Approved By</div>
      <div class="dx-field-value-static">
        {{ payroll?.approvedBy }}
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Current Increase Notes</div>
      <div class="dx-field-value-static">
        {{ payroll?.notes }}
      </div>
    </div>
  </div>

  <div class="dx-fieldset">
    <div class="dx-fieldset-header">History</div>
    <dx-data-grid
      [dataSource]="historyDSO"
      [showColumnLines]="false"
      [showRowLines]="false"
      [showBorders]="false">
      <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

      <dxo-group-panel [visible]="false"></dxo-group-panel>

      <dxo-header-filter [visible]="false"></dxo-header-filter>

      <dxo-filter-row [visible]="false"></dxo-filter-row>

      <dxo-paging [pageSize]="10"></dxo-paging>

      <!-- Columns -->

      <dxi-column caption="Payment Arrangement" dataField="paymentArrangement" [allowHeaderFiltering]="false"></dxi-column>

      <dxi-column caption="Rate" dataField="rateText" [allowHeaderFiltering]="false"></dxi-column>

      <dxi-column caption="Period" dataField="period" [allowHeaderFiltering]="false"></dxi-column>

      <dxi-column caption="Approved By" dataField="approvedBy" [allowHeaderFiltering]="false"></dxi-column>

      
      <!-- Templates -->

    </dx-data-grid>
  </div>
</div>