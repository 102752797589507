/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trips-audit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@progress/kendo-angular-layout/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-layout";
import * as i4 from "@progress/kendo-angular-l10n";
import * as i5 from "../../components/trips-audit-list/trips-audit-list.component.ngfactory";
import * as i6 from "../../components/trips-audit-list/trips-audit-list.component";
import * as i7 from "../../components/trips-audit-details/trips-audit-details.component.ngfactory";
import * as i8 from "../../services/helper.service";
import * as i9 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i10 from "../../components/trips-audit-details/trips-audit-details.component";
import * as i11 from "../../../../shared/sdk/services/custom/logger.service";
import * as i12 from "../../../../shared/modules/my-common/services/config.service";
import * as i13 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i14 from "./trips-audit.component";
var styles_TripsAuditComponent = [i0.styles];
var RenderType_TripsAuditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripsAuditComponent, data: {} });
export { RenderType_TripsAuditComponent as RenderType_TripsAuditComponent };
export function View_TripsAuditComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), i1.ɵqud(402653184, 2, { details: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "kendo-splitter", [], [[2, "k-widget", null], [2, "k-splitter", null], [2, "k-splitter-flex", null], [2, "k-splitter-horizontal", null], [2, "k-splitter-vertical", null], [1, "dir", 0], [1, "role", 0]], null, null, i2.View_SplitterComponent_0, i2.RenderType_SplitterComponent)), i1.ɵprd(512, null, i3.SplitterService, i3.SplitterService, [i1.NgZone]), i1.ɵprd(256, null, i4.L10N_PREFIX, "kendo.spliter", []), i1.ɵprd(131584, null, i4.LocalizationService, i4.LocalizationService, [i4.L10N_PREFIX, [2, i4.MessageService], [2, i4.RTL]]), i1.ɵdid(6, 1753088, null, 1, i3.SplitterComponent, [i1.ElementRef, i3.SplitterService, i4.LocalizationService, i1.Renderer2, [8, null]], null, null), i1.ɵqud(603979776, 3, { panes: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "kendo-splitter-pane", [], [[2, "k-pane", null], [2, "k-pane-static", null], [2, "k-scrollable", null]], [[null, "sizeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sizeChange" === en)) {
        var pd_0 = (_co.splitter_sizeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SplitterPaneComponent_0, i2.RenderType_SplitterPaneComponent)), i1.ɵdid(9, 8437760, [[3, 4]], 0, i3.SplitterPaneComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { scrollable: [0, "scrollable"] }, { sizeChange: "sizeChange" }), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-trips-audit-list", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.list_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TripsAuditListComponent_0, i5.RenderType_TripsAuditListComponent)), i1.ɵdid(11, 114688, [[1, 4]], 0, i6.TripsAuditListComponent, [], null, { mySelected: "mySelected" }), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "kendo-splitter-pane", [["size", "40%"]], [[2, "k-pane", null], [2, "k-pane-static", null], [2, "k-scrollable", null]], [[null, "sizeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sizeChange" === en)) {
        var pd_0 = (_co.splitter_sizeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SplitterPaneComponent_0, i2.RenderType_SplitterPaneComponent)), i1.ɵdid(13, 8437760, [[3, 4]], 0, i3.SplitterPaneComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { size: [0, "size"], collapsible: [1, "collapsible"], scrollable: [2, "scrollable"] }, { sizeChange: "sizeChange" }), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "app-trips-audit-details", [], null, null, null, i7.View_TripsAuditDetailsComponent_0, i7.RenderType_TripsAuditDetailsComponent)), i1.ɵprd(512, null, i8.HelperService, i8.HelperService, [i9.VehicleApi]), i1.ɵdid(16, 770048, [[2, 4]], 0, i10.TripsAuditDetailsComponent, [i11.LoggerService, i12.ConfigService, i13.DataSourceService, i8.HelperService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = false; _ck(_v, 9, 0, currVal_10); _ck(_v, 11, 0); var currVal_14 = "40%"; var currVal_15 = true; var currVal_16 = false; _ck(_v, 13, 0, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.data; _ck(_v, 16, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).hostClasses; var currVal_1 = i1.ɵnov(_v, 6).hostClasses; var currVal_2 = i1.ɵnov(_v, 6).hostClasses; var currVal_3 = i1.ɵnov(_v, 6).horizontalHostClasses; var currVal_4 = i1.ɵnov(_v, 6).verticalHostClasses; var currVal_5 = i1.ɵnov(_v, 6).dir; var currVal_6 = i1.ɵnov(_v, 6).ariaRole; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 9).hostClass; var currVal_8 = i1.ɵnov(_v, 9).staticPaneClass; var currVal_9 = i1.ɵnov(_v, 9).scrollablePaneClass; _ck(_v, 8, 0, currVal_7, currVal_8, currVal_9); var currVal_11 = i1.ɵnov(_v, 13).hostClass; var currVal_12 = i1.ɵnov(_v, 13).staticPaneClass; var currVal_13 = i1.ɵnov(_v, 13).scrollablePaneClass; _ck(_v, 12, 0, currVal_11, currVal_12, currVal_13); }); }
export function View_TripsAuditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trips-audit", [], null, null, null, View_TripsAuditComponent_0, RenderType_TripsAuditComponent)), i1.ɵdid(1, 245760, null, 0, i14.TripsAuditComponent, [i11.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripsAuditComponentNgFactory = i1.ɵccf("app-trips-audit", i14.TripsAuditComponent, View_TripsAuditComponent_Host_0, {}, {}, []);
export { TripsAuditComponentNgFactory as TripsAuditComponentNgFactory };
