import { HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, startWith, switchMap } from 'rxjs/operators';
import { headersAllTenantsAppend } from '../../../classes/utils/utils';
import { MyUtilsApi } from '../../../sdk';

@Pipe({
  name: 'pictureLink',
})
export class PictureLinkPipe implements PipeTransform {
  constructor(private utilsApi: MyUtilsApi) {
  }

  transform(filename: string, convert?: string, bigLoader = false): Observable<string> {
    function customHeaders(headers: HttpHeaders) {
      return headersAllTenantsAppend(headers).append('ngsw-bypass', '1');
    }

    return of(filename).pipe(
      filter(fname => !isEmpty(fname)),
      distinctUntilChanged(),
      // delay(1000),
      switchMap(fname =>
        this.utilsApi.getPictureLink(fname, convert, customHeaders).pipe(
          // map(url => url + '&ngsw-bypass'),
          catchError(err => of('/assets/images/error-img.png')),
          startWith(bigLoader ? '/assets/images/big-loader.gif' : '/assets/images/loader.gif'),
        ),
      ),
      startWith(''),
    );
  }
}
