<dx-validation-group [formGroup]="form">
  <!--<div [formGroup]="form">-->
  <div class="dx-fieldset">
    <!--    <div class="dx-fieldset-header">Facility Info</div>-->

    <div class="dx-field">
      <div class="dx-field-label">Type *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="types"
          displayExpr="Name"
          valueExpr="ID"
          formControlName="type"
          [disabled]="(isSU$ | async) === false">
          <dx-validator name="Type">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Name *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="name">
          <dx-validator name="Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Legal Name *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="legalName">
          <dx-validator name="Legal Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Short Name *</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="shortname" [readOnly]="!(isSU$ | async)">
          <dx-validator name="Short Name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Geotab Zone Id</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="geotabZoneId"></dx-text-box>
      </div>
    </div>

    <ng-container *ngIf="stateFull">
      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value">
          <dx-text-area formControlName="notes"></dx-text-area>
        </div>
      </div>

      <ng-container formGroupName="data"> </ng-container>
    </ng-container>

    <!--<mat-divider></mat-divider>-->

    <ng-container formGroupName="contact" *ngIf="modelId">
      <!--<mat-divider></mat-divider>-->

      <!--<div class="dx-fieldset-header">Contact Info</div>

      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value">
          <dx-text-area formControlName="notes"></dx-text-area>
        </div>
      </div>-->

      <ng-container formArrayName="phones">
        <br />

        <app-form-array-group
          entityName="phone"
          icon="phone"
          [formArray]="phonesFormArray"
          (addItem)="addFormArrayItem('contact.phones')"
          (removeItem)="removeFormArrayItem('contact.phones', $event)"></app-form-array-group>
      </ng-container>

      <!--<mat-divider></mat-divider>-->

      <ng-container formArrayName="emails">
        <br />

        <app-form-array-group
          entityName="email"
          icon="email"
          [formArray]="emailsFormArray"
          (addItem)="addFormArrayItem('contact.emails')"
          (removeItem)="removeFormArrayItem('contact.emails', $event)"></app-form-array-group>
      </ng-container>

      <!--<mat-divider></mat-divider>-->

      <ng-container formArrayName="addresses">
        <br />

        <app-form-array-group
          entityName="place"
          icon="place"
          [formArray]="addressesFormArray"
          (addItem)="addFormArrayItem('contact.addresses')"
          (removeItem)="removeFormArrayItem('contact.addresses', $event)"></app-form-array-group>
      </ng-container>
    </ng-container>

    <br />
    <div class="dx-fieldset-header">Related Contacts</div>
    <ng-container formArrayName="emRelations">
      <br />

      <app-form-array-group
        entityName="relation"
        icon="supervisor_account"
        [labels]="labels$ | async"
        [formArray]="emRelationsFormArray"
        (addItem)="addFormArrayItem('emRelations')"
        (removeItem)="removeFormArrayItem('emRelations', $event)"></app-form-array-group>
    </ng-container>

    <!-- /// -->
    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <!--    <p class="alert-danger" *ngIf="errorMessage">-->
    <!--      {{ errorMessage }}-->
    <!--    </p>-->
  </div>
</dx-validation-group>
