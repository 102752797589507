<app-content-w-header header="МТМ ETD Webhook Log">
  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    [headerFilter]="{ visible: true }"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->
    <dxi-column caption="Updated At" dataField="date" dataType="date" format="shortDateShortTime"></dxi-column>
    <dxi-column caption="Caller" dataField="caller" dataType="string"></dxi-column>
    <dxi-column caption="Trip Number" dataField="trip.tId" dataType="string"></dxi-column>
    <dxi-column caption="Trip Type" dataField="doc.tripType" dataType="string"></dxi-column>
    <dxi-column caption="Trip Status" dataField="doc.tripStatus" dataType="string"></dxi-column>
    <dxi-column caption="MCI" dataField="doc._mci" dataType="string"></dxi-column>
    <dxi-column caption="Client Lastname" dataField="doc._lastname" dataType="string"></dxi-column>
    <dxi-column caption="Client Firstname" dataField="doc._firstname" dataType="string"></dxi-column>
    <dxi-column caption="Manifest Date" dataField="doc._date" dataType="date" format="shortDate"></dxi-column>
    <dxi-column
      caption="Manifest Time"
      dataField="trip.at"
      dataType="date"
      format="shortTime"
      [allowFiltering]="false"></dxi-column>
  </dx-data-grid>
</app-content-w-header>

