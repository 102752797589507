<div mat-dialog-content>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Payload</div>
    </div>
    <div class="dx-field">

        <dx-text-area [(ngModel)]="payload"
                      [stylingMode]="'outlined'"
                      [height]="'300px'"
        ></dx-text-area>

    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Close</button>
  <button mat-button (click)="push()">Push</button>
</div>
