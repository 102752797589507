<dx-tab-panel
  width="100%"
  height="100%"
  #tabPanel
  [dataSource]="tabs"
  [(selectedIndex)]="selectedIndex"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="true"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'notes'">
    <app-vehicle-details-tab-notes
      [vehicle]="vehicle"
      [templateFormTop]="templateFormTop"
      [templateFormBottom]="templateFormBottom"
      [extraFields]="extraFields"></app-vehicle-details-tab-notes>
  </div>

  <div *dxTemplate="let data of 'history'">
    <app-vehicle-details-tab-history [vehicle]="vehicle"></app-vehicle-details-tab-history>
  </div>

  <div *dxTemplate="let data of 'locations'">
    <app-vehicle-details-tab-locations [markers]="markers"></app-vehicle-details-tab-locations>
  </div>
</dx-tab-panel>

