import { Component, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import sortBy from 'lodash-es/sortBy';
import { Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Document, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService, MyUserApi } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentFormComponent } from '../../../document/components/document-form/document-form.component';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';

@Component({
  selector: 'app-vehicle-document-grid',
  templateUrl: './vehicle-document-grid.component.html',
  styleUrls: ['./vehicle-document-grid.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class VehicleDocumentGridComponent extends ABaseComponent implements OnInit, OnDestroy {
  dso: DataSourceOptions;

  _model: Vehicle;
  @Input()
  set model(v: Vehicle) {
    this._model = v;
    this.dso = v ? this.buildDSO(v) : undefined;
  }

  vehicleDso$: Observable<DataSourceOptions> = of([]);
  usersDso$: Observable<ArrayStore>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
    private gridHelper: GridHelperService,
    private userApi: MyUserApi,
  ) {
    super(logger);

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([Document.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.vehicleDso$ = this.buildVehicleDataSource();

    this.usersDso$ = this.userApi.getUsernames().pipe(map(arr => new ArrayStore(arr)));
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onEditingStart(e) {
    e.cancel = true;

    this.ui.openEditDialog({
      modelId: e.data.id,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
    });
  }

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift({
      name: 'addVehicleDoc',
      widget: 'dxButton',
      locateInMenu: 'auto',
      location: 'before',
      sortIndex: 30,
      // showText: 'inMenu',
      options: {
        icon: 'plus',
        text: 'Add Vehicle Doc',
        hint: 'Add Vehicle Doc',
        // disabled: !this.model,
        onClick: () => {
          if (!this._model) {
            return;
          }

          this.ui.openEditDialog({
            modelId: null,
            title: `${this._model.internalId} New Document`,
            inputs: {
              objectType: Vehicle.getModelName(),
              objectId: this._model.id,
            },
            ModelClass: Document,
            FormComponentClass: DocumentFormComponent,
          });
        },
      },
    });

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  private buildDSO(model: Vehicle): DataSourceOptions {
    const filter = model
      ? [
          [
            ['objectId', '=', model.id],
            ['objectType', '=', Vehicle.getModelName()],
          ],
        ]
      : undefined;

    return {
      store: this.dss.getStore(Document, null, false),
      filter,
    } as DataSourceOptions;
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false);
    (so as LoopBackStoreOptions).customHeaders = headersAllTenantsAppend;
    const store: CustomStore = new CustomStore(so);
    const dso: DataSourceOptions = { store };
    return of(dso);
  }
}
