import { Component, OnInit } from '@angular/core';
import head from 'lodash-es/head';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { oc } from 'ts-optchain';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  $selectedIds$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedId$: Observable<any> = new Observable();

  constructor() {
    this.selectedId$ = this.$selectedIds$.pipe(map(ids => head(oc(ids)([]))));
  }

  ngOnInit() {}

  onSelected(keys: any[]) {
    this.$selectedIds$.next(oc(keys)([]));
  }
}
