export const EMPLOYEE_STATUSES: any[] = [
  { ID: 'ACTIVE', Name: 'ACTIVE' },
  { ID: 'PENDING', Name: 'PENDING' },
  { ID: 'BLOCKED', Name: 'BLOCKED' },
  { ID: 'SEPARATED', Name: 'SEPARATED' },
  { ID: 'INACTIVE', Name: 'INACTIVE' },
  { ID: 'RESERVE', Name: 'RESERVE' },
];

export const EMPLOYEE_TYPES: any[] = [
  { ID: 'W-2', Name: 'W-2' },
  { ID: '1099', Name: '1099' },
  { ID: 'VOLUNTEER', Name: 'Volunteer' },
];

export const PERSON_SEXES: any[] = [
  { ID: 'M', Name: 'Male' },
  { ID: 'F', Name: 'Female' },
];

export const REPETITIVE_MODES: any[] = [
  { ID: 'SUBSCRIPTION', Name: 'SUBSCRIPTION' },
  { ID: 'SINGLE_RIDE', Name: 'SINGLE RIDE' },
];

export const WEEKDAYS: any[] = [
  { ID: 'mo', Name: 'Monday' },
  { ID: 'tu', Name: 'Tuesday' },
  { ID: 'we', Name: 'Wednesday' },
  { ID: 'th', Name: 'Thursday' },
  { ID: 'fr', Name: 'Friday' },
  { ID: 'sa', Name: 'Saturday' },
  { ID: 'su', Name: 'Sunday' },
];
