import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnyNotesPageModule } from 'src/app/shared/modules/ui/components/any-notes-page/any-notes-page.module';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { AutoDispatchDetailsTabsComponent } from './components/auto-dispatch-details-tabs/auto-dispatch-details-tabs.component';
import { AutoDispatchComponent } from './containers/auto-dispatch/auto-dispatch.component';
import { AutoDispatchListComponent } from './components/auto-dispatch-list/auto-dispatch-list.component';
import { AutoDispatchGridComponent } from './components/auto-dispatch-grid/auto-dispatch-grid.component';
import { AutoDispatchDetailsTabAcceptedComponent } from './components/auto-dispatch-details-tab-accepted/auto-dispatch-details-tab-accepted.component';
import { AutoDispatchDetailsTabDeniedComponent } from './components/auto-dispatch-details-tab-denied/auto-dispatch-details-tab-denied.component';
import { DxToastModule } from 'devextreme-angular';
import { AutoDispatchDetailsTabAcceptedAltComponent } from './components/auto-dispatch-details-tab-accepted-alt/auto-dispatch-details-tab-accepted-alt.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MyCommonModule,
    UiModule,
    AnyNotesPageModule,
    DxToastModule,
  ],
  declarations: [
    AutoDispatchComponent,
    AutoDispatchListComponent,
    AutoDispatchGridComponent,
    AutoDispatchDetailsTabsComponent,
    AutoDispatchDetailsTabAcceptedComponent,
    AutoDispatchDetailsTabAcceptedAltComponent,
    AutoDispatchDetailsTabDeniedComponent,
  ],
  exports: [AutoDispatchComponent],
  entryComponents: [AutoDispatchComponent],
})
export class AutoDispatchModule {}

