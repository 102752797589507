import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { LoopBackFilter, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService, VehicleApi } from '../../../../shared/sdk/services/custom';
export class VehicleAddFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, config, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.helper = helper;
        this.SUB_STATUSES = ['Universal', 'Emergency Only', 'Meals Only'];
        this.$windowTitle$ = new BehaviorSubject('Change Vehicle Status to “Backup”');
        this.resetableForm = false;
        this.collapsibleForm = false;
        this.vehicleDso$ = of([]);
        this.setState(FORM_STATE.COLLAPSED);
        this.vehicleDso$ = this.buildVehicleDataSource();
    }
    vehicle_onSelectionChanged(e) {
        this.modelId = e.selectedItem.id;
    }
    vehicle_displayExpression(v) {
        return v
            ? `${v.internalId} - ${v.make} ${v.model}${(v.wheelchairAccessible && ' - WC BUS') || ''}${(v.passengerCapacity && ` - ${v.passengerCapacity} Passengers`) || ''}`
            : null;
    }
    get ModelClass() {
        return Vehicle;
    }
    get dateFields() {
        return [];
    }
    get filter() {
        return {};
    }
    ngOnInit() {
        super.ngOnInit();
    }
    validateAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return !!this.modelId;
        });
    }
    afterSetFormValuesAsync(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    afterSubmittedAsync(data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    beforeSubmittingAsync(data) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, data);
            data.status = 'BACKUP';
        });
    }
    buildForm() {
        this.formConfigMap.set('', {
            id: [],
            status: [],
            subStatus: [],
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
    buildVehicleDataSource() {
        return this.dss.getApi(Vehicle).find({
            where: { state: 'ACTIVE', status: { neq: 'BACKUP' } },
            order: ['internalId ASC'],
        });
    }
}
