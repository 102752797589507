import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { AutoDispatchListComponent } from '../../components/auto-dispatch-list/auto-dispatch-list.component';
import { AutoDispatchLog } from 'src/app/shared/sdk';

@Component({
  selector: 'app-auto-dispatch',
  templateUrl: './auto-dispatch.component.html',
  styleUrls: ['./auto-dispatch.component.scss'],
})
export class AutoDispatchComponent extends ABaseComponent implements OnInit {
  $selectedArray$: BehaviorSubject<AutoDispatchLog[]> = new BehaviorSubject([]);
  selected$: Observable<AutoDispatchLog> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(AutoDispatchListComponent, { static: true }) grid: AutoDispatchListComponent;

  constructor(protected logger: LoggerService, private dss: DataSourceService) {
    super(logger);

    this.selected$ = this.$selectedArray$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(messages: AutoDispatchLog[]) {
    if (messages && messages.length) {
      this.$selectedArray$.next(messages);
    } else {
      this.$selectedArray$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}

