<app-content-w-header header="Fuel Cards">
  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [dataSource]="ds"
    [errorRowEnabled]="true"
    [stateStoring]="grid_stateStoring"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!-- Columns -->

    <dxi-column caption="Card Issuer" dataField="cardIssuer">
      <dxo-lookup [dataSource]="['WAWA WEX']"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Account Name" dataField="accountName">
      <dxo-lookup [dataSource]="accountNames"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Account Number" dataField="accountNumber">
      <dxo-lookup [dataSource]="accountNumbers"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Card Number" dataField="cardNumber" dataType="string"></dxi-column>

    <dxi-column caption="Card Name" dataField="cardName" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="status">
      <dxo-lookup [dataSource]="['ACTIVE', 'SUSPENDED', 'TERMINATED']"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Expiration Date"
      dataField="expirationDate"
      dataType="date"
      format="MM/yy"
      [editorOptions]="{
        type: 'date',
        displayFormat: 'MM/yy',
        calendarOptions: { maxZoomLevel: 'year', minZoomLevel: 'decade' }
      }"></dxi-column>

    <dxi-column caption="Assigned Facility" dataField="facilityId" dataType="string">
      <dxo-lookup [dataSource]="facilityDso" displayExpr="shortname" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Facility Assigned Date"
      dataField="facilityAssignedDate"
      dataType="date"
      format="shortDate"></dxi-column>

    <dxi-column caption="Assigned Employee" dataField="employeeId" editCellTemplate="driver_editCell">
      <dxo-lookup [dataSource]="employeeDso" valueExpr="id" [displayExpr]="empDisplayExpr"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Employee Assigned Date"
      dataField="employeeAssignedDate"
      dataType="date"
      format="shortDate"></dxi-column>

    <dxi-column caption="Note" dataField="note" dataType="string"></dxi-column>

    <dxi-column caption="Usage Type" dataField="usageType" dataType="string" [visible]="false"></dxi-column>

    <dxi-column caption="Department" dataField="department" dataType="string" [visible]="false"></dxi-column>

    <dxi-column caption="Emboss Line 1" dataField="embossLine1" dataType="string" [visible]="false"></dxi-column>

    <dxi-column caption="Emboss Line 2" dataField="embossLine2" dataType="string" [visible]="false"></dxi-column>

    <dxi-column caption="Emboss Line 3" dataField="embossLine3" dataType="string" [visible]="false"></dxi-column>

    <dxi-column
      caption="Authorization Profile"
      dataField="authorizationProfile"
      dataType="string"
      [visible]="false"></dxi-column>

    <dxi-column caption="Prompting" dataField="prompting" dataType="string" [visible]="false"></dxi-column>

    <dxi-column
      caption="Custom Vehicle / Asset ID"
      dataField="customVehicleAssetID"
      dataType="string"
      [visible]="false"></dxi-column>

    <dxi-column caption="Asset Type" dataField="assetType" dataType="string" [visible]="false"></dxi-column>

    <dxi-column
      caption="Last Issue Date"
      dataField="lastIssueDate"
      dataType="date"
      format="shortDate"
      [allowEditing]="false"
      [visible]="false"></dxi-column>

    <dxi-column caption="Last Used By" dataField="lastUsedUserId" [allowEditing]="false">
      <dxo-lookup [dataSource]="userDso$ | async" valueExpr="id" displayExpr="username"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Last Facility Reported" dataField="lastFacilityId" [allowEditing]="false">
      <dxo-lookup [dataSource]="facilityDso" valueExpr="id" displayExpr="shortname"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Last Vehicle Reported" dataField="lastVehicleId" [allowEditing]="false">
      <dxo-lookup [dataSource]="vehicleDso" valueExpr="id" displayExpr="internalId"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Last Date Reported"
      dataField="lastReportedDateTime"
      dataType="datetime"
      format="shortDateShortTime"
      [allowEditing]="false"></dxi-column>

    <dxi-column type="buttons" [showInColumnChooser]="false">
      <dxi-button name="print" text="QR" hint="Print QR Code" [onClick]="qrPrintClick"></dxi-button>
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'driver_editCell'">
      <app-drop-down-grid-new
        [valueExpr]="'id'"
        [dataSource]="employeeDso"
        [grid_columns]="[
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' }
        ]"
        [showClearButton]="true"
        [displayExpr]="employeeHelper.displayExpr"
        [value]="cellInfo.value"
        (valueChanged)="driverDropDown_onValueChanged(cellInfo, $event)"
        [popup_width]="350"
        [popup_height]="300"></app-drop-down-grid-new>
    </ng-container>
  </dx-data-grid>
</app-content-w-header>
