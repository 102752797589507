/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./object-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./object-title.component";
import * as i4 from "../../../../sdk/services/custom/SDKModels";
import * as i5 from "../../../my-common/services/config.service";
var styles_ObjectTitleComponent = [i0.styles];
var RenderType_ObjectTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ObjectTitleComponent, data: {} });
export { RenderType_ObjectTitleComponent as RenderType_ObjectTitleComponent };
export function View_ObjectTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.objectTitle$)) || ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_ObjectTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-object-title", [], null, null, null, View_ObjectTitleComponent_0, RenderType_ObjectTitleComponent)), i1.ɵdid(1, 638976, null, 0, i3.ObjectTitleComponent, [i4.SDKModels, i5.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ObjectTitleComponentNgFactory = i1.ɵccf("app-object-title", i3.ObjectTitleComponent, View_ObjectTitleComponent_Host_0, { modelName: "modelName", modelId: "modelId", field: "field" }, {}, []);
export { ObjectTitleComponentNgFactory as ObjectTitleComponentNgFactory };
