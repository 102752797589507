<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content style='height: 500px; width: 600px;'>
  <dx-scroll-view [useNative]='true'>
    <ngx-json-viewer
      [json]='data.json | filterJson'
      [expanded]='true'
    ></ngx-json-viewer>
  </dx-scroll-view>
</mat-dialog-content>

