import {
  Employee,
  Consumer,
  Vehicle,
  Address
} from '../index';

declare var Object: any;
export interface TripManifestRecInterface {
  "id"?: string;
  "t"?: string;
  "tr"?: number;
  "s"?: number;
  "rt"?: boolean;
  "o"?: string;
  "d"?: string;
  "dn"?: string;
  "b"?: string;
  "tId"?: string;
  "bn"?: string;
  "at"?: string;
  "put"?: string;
  "dot"?: string;
  "x"?: boolean;
  "ot"?: boolean;
  "na"?: boolean;
  "st"?: string;
  "mu1"?: number;
  "mu2"?: number;
  "rid"?: string;
  "dst"?: number;
  "dur"?: number;
  "e"?: number;
  "c"?: number;
  "v"?: number;
  "esc"?: number;
  "aid"?: number;
  employee?: Employee;
  consumer?: Consumer;
  vehicle?: Vehicle;
  escort?: Employee;
  address?: Address;
}

export class TripManifestRec implements TripManifestRecInterface {
  "id": string;
  "t": string;
  "tr": number;
  "s": number;
  "rt": boolean;
  "o": string;
  "d": string;
  "dn": string;
  "b": string;
  "tId": string;
  "bn": string;
  "at": string;
  "put": string;
  "dot": string;
  "x": boolean;
  "ot": boolean;
  "na": boolean;
  "st": string;
  "mu1": number;
  "mu2": number;
  "rid": string;
  "dst": number;
  "dur": number;
  "e": number;
  "c": number;
  "v": number;
  "esc": number;
  "aid": number;
  employee: Employee;
  consumer: Consumer;
  vehicle: Vehicle;
  escort: Employee;
  address: Address;
  constructor(data?: TripManifestRecInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `TripManifestRec`.
   */
  public static getModelName() {
    return "TripManifestRec";
  }

  /**
  * This method creates an instance of TripManifestRec for dynamic purposes.
  **/
  public static factory(data: TripManifestRecInterface): TripManifestRec{
    return new TripManifestRec(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TripManifestRec',
      plural: 'TripManifestRecs',
      path: 'TripManifestRecs',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "t": {
          name: 't',
          type: 'string'
        },
        "tr": {
          name: 'tr',
          type: 'number',
          default: 0
        },
        "s": {
          name: 's',
          type: 'number',
          default: 0
        },
        "rt": {
          name: 'rt',
          type: 'boolean',
          default: false
        },
        "o": {
          name: 'o',
          type: 'string'
        },
        "d": {
          name: 'd',
          type: 'string'
        },
        "dn": {
          name: 'dn',
          type: 'string'
        },
        "b": {
          name: 'b',
          type: 'string'
        },
        "tId": {
          name: 'tId',
          type: 'string'
        },
        "bn": {
          name: 'bn',
          type: 'string'
        },
        "at": {
          name: 'at',
          type: 'string'
        },
        "put": {
          name: 'put',
          type: 'string'
        },
        "dot": {
          name: 'dot',
          type: 'string'
        },
        "x": {
          name: 'x',
          type: 'boolean'
        },
        "ot": {
          name: 'ot',
          type: 'boolean'
        },
        "na": {
          name: 'na',
          type: 'boolean'
        },
        "st": {
          name: 'st',
          type: 'string'
        },
        "mu1": {
          name: 'mu1',
          type: 'number'
        },
        "mu2": {
          name: 'mu2',
          type: 'number'
        },
        "rid": {
          name: 'rid',
          type: 'string'
        },
        "dst": {
          name: 'dst',
          type: 'number'
        },
        "dur": {
          name: 'dur',
          type: 'number'
        },
        "e": {
          name: 'e',
          type: 'number'
        },
        "c": {
          name: 'c',
          type: 'number'
        },
        "v": {
          name: 'v',
          type: 'number'
        },
        "esc": {
          name: 'esc',
          type: 'number'
        },
        "aid": {
          name: 'aid',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'e',
          keyTo: 'id'
        },
        consumer: {
          name: 'consumer',
          type: 'Consumer',
          model: 'Consumer',
          relationType: 'belongsTo',
                  keyFrom: 'c',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'v',
          keyTo: 'id'
        },
        escort: {
          name: 'escort',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'esc',
          keyTo: 'id'
        },
        address: {
          name: 'address',
          type: 'Address',
          model: 'Address',
          relationType: 'belongsTo',
                  keyFrom: 'aid',
          keyTo: 'id'
        },
      }
    }
  }
}
