import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
//
import { DocumentTypeComponent } from './containers/document-type/document-type.component';
import { DocumentTypeListComponent } from './components/document-type-list/document-type-list.component';
import { DocumentTypeGridComponent } from './components/document-type-grid/document-type-grid.component';
import { DocumentTypeDetailsComponent } from './components/document-type-details/document-type-details.component';
import { DocumentTypeDetailsTabsComponent } from './components/document-type-details-tabs/document-type-details-tabs.component';
import { DocumentTypeDetailsTabComponent } from './components/document-type-details-tab/document-type-details-tab.component';
import { DocumentTypeFormComponent } from './components/document-type-form/document-type-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule],
  declarations: [
    DocumentTypeComponent,
    DocumentTypeListComponent,
    DocumentTypeGridComponent,
    DocumentTypeDetailsComponent,
    DocumentTypeDetailsTabsComponent,
    DocumentTypeDetailsTabComponent,
    DocumentTypeFormComponent,
  ],
  exports: [DocumentTypeComponent],
  entryComponents: [DocumentTypeComponent, DocumentTypeFormComponent],
})
export class DocumentTypeModule {}
