<div mat-dialog-title>
  <mat-toolbar>

    <mat-toolbar-row>
      <div class="caption">{{data.title}}</div>

      <button mat-icon-button
              type="button"
              [mat-dialog-close]="false"
              matTooltip="Close"
              color="primary"
      >
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>

  </mat-toolbar>
</div>

<mat-dialog-content>
  <dx-map
    width="100%"
    height="100%"
    provider="google"
    [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
    [autoAdjust]="true"
    [zoom]="14"
    [markers]="markers"
    [routes]="routes">
  </dx-map>
</mat-dialog-content>