/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/devextreme-angular/ui/map/devextreme-angular-ui-map.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/map";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../services/helper.service";
import * as i7 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i8 from "./vehicle-details.component";
import * as i9 from "../../../../shared/sdk/services/custom/logger.service";
import * as i10 from "../../../../shared/modules/my-common/services/config.service";
import * as i11 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_VehicleDetailsComponent = [i0.styles];
var RenderType_VehicleDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleDetailsComponent, data: {} });
export { RenderType_VehicleDetailsComponent as RenderType_VehicleDetailsComponent };
export function View_VehicleDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { toolbar: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "dx-map", [["height", "100%"], ["provider", "google"], ["width", "100%"]], null, null, null, i2.View_DxMapComponent_0, i2.RenderType_DxMapComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.IterableDifferHelper, i3.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(6, 7323648, [["map", 4]], 3, i4.DxMapComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.IterableDifferHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { apiKey: [0, "apiKey"], autoAdjust: [1, "autoAdjust"], center: [2, "center"], controls: [3, "controls"], height: [4, "height"], markers: [5, "markers"], provider: [6, "provider"], width: [7, "width"], zoom: [8, "zoom"] }, null), i1.ɵqud(603979776, 2, { centerChildren: 1 }), i1.ɵqud(603979776, 3, { markersChildren: 1 }), i1.ɵqud(603979776, 4, { routesChildren: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER; var currVal_1 = _co.autoAdjust; var currVal_2 = _co.mapCenter; var currVal_3 = true; var currVal_4 = "100%"; var currVal_5 = _co.markers; var currVal_6 = "google"; var currVal_7 = "100%"; var currVal_8 = _co.mapZoom; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_VehicleDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-vehicle-details", [], null, null, null, View_VehicleDetailsComponent_0, RenderType_VehicleDetailsComponent)), i1.ɵprd(512, null, i6.HelperService, i6.HelperService, [i7.VehicleApi]), i1.ɵdid(2, 770048, null, 0, i8.VehicleDetailsComponent, [i9.LoggerService, i10.ConfigService, i11.DataSourceService, i6.HelperService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VehicleDetailsComponentNgFactory = i1.ɵccf("app-vehicle-details", i8.VehicleDetailsComponent, View_VehicleDetailsComponent_Host_0, { loading: "loading", modelId: "modelId", markers: "markers" }, { modelLoadingError: "modelLoadingError", loadingChange: "loadingChange", modelIdChange: "modelIdChange", beforeLoading: "beforeLoading", loaded: "loaded", afterLoaded: "afterLoaded" }, []);
export { VehicleDetailsComponentNgFactory as VehicleDetailsComponentNgFactory };
