<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">

    <div class="dx-field">
      <div class="dx-field-label">Current Payment Arrangement</div>
      <div class="dx-field-value">
        <dx-select-box
          formControlName="paymentArrangement"
          [dataSource]="PAYMENT_ARRANGEMENTS"
          valueExpr="value"
          displayExpr="text"
        ></dx-select-box>
      </div>
    </div>
    
    <ng-container [ngSwitch]="form.get('paymentArrangement').value">
      <ng-container *ngSwitchCase="'hourly'">
        <div class="dx-field">
          <div class="dx-field-label">Current Rate (dollars per hour)</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="rate"></dx-text-box>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'salary'">
        <div class="dx-field">
          <div class="dx-field-label">Salary (dollars per year)</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="rate"></dx-text-box>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'contractor'">
        <div class="dx-field">
          <div class="dx-field-label">Rate per trip (dollars)</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="rate"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Rate per mile (dollars)</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="rate1"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Current Rate Period</div>
          <div class="dx-field-value rate-period">
            <dx-date-box formControlName="start"></dx-date-box>
            <span>&nbsp;&ndash;&nbsp;</span>
            <dx-date-box formControlName="end"></dx-date-box>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="dx-field">
      <div class="dx-field-label">Current Increase Approved By</div>
      <div class="dx-field-value">
        <dx-text-box formControlName="approvedBy"></dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Current Increase Notes</div>
      <div class="dx-field-value">
        <dx-text-area formControlName="notes"></dx-text-area>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Performance Stimuli</div>
      <div class="dx-field-value-static">
        <dx-check-box formControlName="stimuliEnabled" text="Enabled"></dx-check-box>
      </div>
    </div>

    <ng-container *ngIf="form.get('stimuliEnabled').value">
      <div class="dx-field">
        <div class="dx-field-label">Stimuli Amount (dollars)</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="stimuliAmount"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Stimuli After Trip number</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="stimuliAfterTripNumber"></dx-text-box>
        </div>
      </div>
    </ng-container>

  </div>

  <p>
    <dx-validation-summary></dx-validation-summary>
  </p>

  <!--    <p class="alert-danger" *ngIf="errorMessage">-->
  <!--      {{ errorMessage }}-->
  <!--    </p>-->
</dx-validation-group>
