/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signout-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./signout-form.component";
var styles_SignoutFormComponent = [i0.styles];
var RenderType_SignoutFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignoutFormComponent, data: {} });
export { RenderType_SignoutFormComponent as RenderType_SignoutFormComponent };
function View_SignoutFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_SignoutFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logged in as "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [".\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignoutFormComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["LOGOUT"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.isSubmitting; var currVal_5 = "primary"; _ck(_v, 8, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.signedUser == null) ? null : _co.signedUser.username); _ck(_v, 3, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_SignoutFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signout-form", [], null, null, null, View_SignoutFormComponent_0, RenderType_SignoutFormComponent)), i1.ɵdid(1, 114688, null, 0, i7.SignoutFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignoutFormComponentNgFactory = i1.ɵccf("app-signout-form", i7.SignoutFormComponent, View_SignoutFormComponent_Host_0, { isSubmitting: "isSubmitting", signedUser: "signedUser", error: "error" }, { mySubmit: "mySubmit" }, []);
export { SignoutFormComponentNgFactory as SignoutFormComponentNgFactory };
