import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import DataSource from 'devextreme/data/data_source';
import { PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import moment, { utc } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-approved-claims-pivot',
  templateUrl: './approved-claims-pivot.component.html',
  styleUrls: ['./approved-claims-pivot.component.scss'],
})
export class ApprovedClaimsPivotComponent extends ABaseComponent implements OnInit {
  dso = new DataSource([]);
  dataSource: PivotGridDataSourceOptions;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  grid_stateStoring: any;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = new Date();

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  collection = 'ApprovedMtmClaims_view';

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;

  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
    private ui: UiService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '4d63e4a8-a81f-4697-90c8-97b8c48fa6ee',
    };

    this.buildDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => {
          this.buildDataSource();
        }),
        // switchMap(() => this.buildDataSource().pipe(
        //   catchError((err) => {
        //     notify(err.message, 'error', 5000);
        //     return of(new ArrayStore({data: []}));
        //   }),
        //   )
        // ),
        // tap((as) => {
        //   this.dso = {
        //     store: as,
        //     fields: this.pivotFields,
        //   } as PivotGridDataSourceOptions;
        //   // this.grid.instance.refresh();
        // }),
        delay(1000),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildFilter() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    const fromInc = fromMoment && utc(fromMoment.format('YYYY-MM-DD')).toISOString();
    const toExcl = toMoment && utc(toMoment.format('YYYY-MM-DD')).toISOString();

    return { fromInc, toExcl };
  }

  private buildDataSource() {
    const filterObj = this.buildFilter();
    const _filter = [
      // ['STATUS', '=', 'Approved'],
      // ['_broker', '=', 'MTM'],
      ['SCHEDULEDPAYDATE', '>=', filterObj.fromInc],
      ['SCHEDULEDPAYDATE', '<', filterObj.toExcl],
    ];

    const store = this.dss.createMongoStore(this.collection, { SCHEDULEDPAYDATE: 'datetime' });

    this.dataSource = {
      remoteOperations: true,
      store,
      filter: _filter,
      fields: [
        { dataField: '_id', visible: false },

        {
          dataField: 'SCHEDULEDPAYDATE',
          caption: 'SCHEDULED PAY DATE',
          dataType: 'date',
          area: 'row',
          groupName: 'dateGroup',
          isMeasure: false,
        },
        {
          groupName: 'dateGroup',
          groupIndex: 0,
          // format: 'MM/dd/yyyy EE Z',
          format: {
            formatter(date) {
              return moment(date).utc().format('MM/DD/YYYY');
            },
            // parser (e) {
            //   var parts = e.split("."),
            //     day = Number(parts[2]),
            //     month = Number(parts[1] - 1),
            //     year = Number(parts[0]);
            //
            //   return new Date(year, month, day);
            // }
          },
          // selector(data) {
          //   console.log(data.SCHEDULEDPAYDATE);
          //   const year = new Date(data.SCHEDULEDPAYDATE).getUTCFullYear();
          //   const month = new Date(data.SCHEDULEDPAYDATE).getUTCMonth();
          //   const date = new Date(data.SCHEDULEDPAYDATE).getUTCDate();
          //   console.log(new Date(year, month, date));
          //   return new Date(year, month, date);
          //
          //   // const year = utc(data.SCHEDULEDPAYDATE).utc().get('year');
          //   // const month = utc(data.SCHEDULEDPAYDATE).utc().get('month');
          //   // const days = utc(data.SCHEDULEDPAYDATE).utc().get('days');
          //   // return new Date(year, month, days);
          // },
        },

        // {caption: 'Year', dataField: 'SCHEDULEDPAYDATE', dataType: 'date', groupInterval: 'year', displayFolder: 'Scheduled pay date'},
        // {caption: 'Month', dataField: 'SCHEDULEDPAYDATE', dataType: 'date', groupInterval: 'month', displayFolder: 'Scheduled pay date'},
        // {caption: 'Day of Week', dataField: 'SCHEDULEDPAYDATE', dataType: 'date', groupInterval: 'dayOfWeek', displayFolder: 'Scheduled pay date'},
        // // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'Scheduled pay date'},
        // {
        //   caption: 'Day',
        //   area: 'row',
        //   dataField: 'SCHEDULEDPAYDATE',
        //   dataType: 'date',
        //   groupInterval: 'day',
        //   displayFolder: 'Scheduled pay date',
        //   selector: (data) => {
        //     const dateMoment = utc(data.SCHEDULEDPAYDATE).utc();
        //     return [
        //       dateMoment.format('MM/DD/YYYY'),
        //       dateMoment.format('dd'),
        //     ].join('-');
        //   }
        // },

        {
          dataField: '_mci',
          dataType: 'string',
          caption: 'MCI',
          isMeasure: false,
        },
        {
          dataField: 'TRIP',
          dataType: 'string',
          caption: 'TRIP',
          isMeasure: false,
        },

        //

        {
          dataField: 'PAYMENT',
          dataType: 'number',
          format: {
            type: 'currency',
            precision: 2,
          },
          caption: 'PAYMENT',
          isMeasure: true,
          area: 'data',
          summaryType: 'sum',
        },
        {
          dataField: 'TRIPCOST',
          dataType: 'number',
          format: {
            type: 'currency',
            precision: 2,
          },
          caption: 'TRIP COST',
          isMeasure: true,
          summaryType: 'sum',
        },
        {
          dataField: 'CLAIMAMOUNT',
          dataType: 'number',
          format: {
            type: 'currency',
            precision: 2,
          },
          caption: 'CLAIM AMOUNT',
          isMeasure: true,
          summaryType: 'sum',
        },
      ],
    } as PivotGridDataSourceOptions;
  }

  filter() {
    this.$filterEvent$.next(true);
  }
}
