import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../../shared/modules/ui/services/form-helper.service';
import { EmployeePayroll, LoopBackFilter } from '../../../../../shared/sdk/models';
import { LoggerService } from '../../../../../shared/sdk/services/custom';
export class EmployeePayrollEditFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, config, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.helper = helper;
        this.$windowTitle$ = new BehaviorSubject('Edit Payroll');
        this.resetableForm = false;
        this.collapsibleForm = false;
        this.PAYMENT_ARRANGEMENTS = [
            { value: 'hourly', text: 'Hourly' },
            { value: 'salary', text: 'Salary' },
            { value: 'contractor', text: 'Contractor' },
        ];
        this.setState(FORM_STATE.COLLAPSED);
    }
    get ModelClass() {
        return EmployeePayroll;
    }
    get dateFields() {
        return [];
    }
    get filter() {
        return {};
    }
    ngOnInit() {
        super.ngOnInit();
    }
    validateAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return true;
        });
    }
    afterSetFormValuesAsync(model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    afterSubmittedAsync(data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    beforeSubmittingAsync(payroll) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, payroll);
            payroll.employeeId = this.employeeId;
            if (!this.model)
                return;
            const { paymentArrangement, rate, rate1, start, end, approvedBy, notes } = this.model;
            payroll.history = [
                ...(this.model.history || []),
                { paymentArrangement, rate, rate1, start, end, approvedBy, notes },
            ];
        });
    }
    buildForm() {
        this.formConfigMap.set('', {
            id: [],
            paymentArrangement: [],
            rate: [],
            rate1: [],
            start: [],
            end: [],
            stimuliEnabled: [],
            stimuliAmount: [],
            stimuliAfterTripNumber: [],
            approvedBy: [],
            notes: [],
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
}
