import {
  MyUser,
  Employee,
  Vehicle
} from '../index';

declare var Object: any;
export interface PostShiftReportInterface {
  "requestID": string;
  "dateTime": Date;
  "deviceUid": string;
  "startedDateTime"?: Date;
  "completedDateTime"?: Date;
  "location"?: string;
  "shiftWithoutIncidents"?: boolean;
  "incidentOrAccident"?: string;
  "anybodyGotInjured"?: boolean;
  "vehicleDamage"?: boolean;
  "visibleLeaksUnderVehicle"?: boolean;
  "tiresConditionOk"?: boolean;
  "exteriorClean"?: boolean;
  "exteriorBodyDamage"?: boolean;
  "odometer"?: number;
  "warningGaugesOff"?: boolean;
  "thermometerPresent"?: boolean;
  "cleaningSuppliesPresent"?: boolean;
  "driverHasPPE"?: boolean;
  "handheldGpsPresent"?: boolean;
  "windshieldMountGpsHolder"?: boolean;
  "ezPassTransporterPresent"?: boolean;
  "numberOfWheelchairHooks"?: number;
  "numberOfWheelchairBelts"?: number;
  "stepStoolPresent"?: boolean;
  "foldableWheelchairPresent"?: boolean;
  "foldableTransportChairPresent"?: boolean;
  "stairChairPresent"?: boolean;
  "stairwayWheelchairCarrierPresent"?: boolean;
  "stretcherPresent"?: boolean;
  "interiorClean"?: boolean;
  "haveDisinfectedSeveralTime"?: boolean;
  "haveWashedExterior"?: boolean;
  "vehicleFueledUpToday"?: boolean;
  "anyVehicleIssuesToReport"?: boolean;
  "vehicleLeftLocked"?: boolean;
  "vehicleKeysLocation"?: string;
  "tabletLocation"?: string;
  "note"?: string;
  "durationTime"?: number;
  "issuesNumber"?: number;
  "missingStuffNumber"?: number;
  "dailyUsageDuration"?: number;
  "changedDuringDay"?: boolean;
  "vdate"?: number;
  "pictures"?: any;
  "meta"?: any;
  "summary"?: any;
  "utime"?: Date;
  "id"?: any;
  "userId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  user?: MyUser;
  employee?: Employee;
  vehicle?: Vehicle;
}

export class PostShiftReport implements PostShiftReportInterface {
  "requestID": string;
  "dateTime": Date;
  "deviceUid": string;
  "startedDateTime": Date;
  "completedDateTime": Date;
  "location": string;
  "shiftWithoutIncidents": boolean;
  "incidentOrAccident": string;
  "anybodyGotInjured": boolean;
  "vehicleDamage": boolean;
  "visibleLeaksUnderVehicle": boolean;
  "tiresConditionOk": boolean;
  "exteriorClean": boolean;
  "exteriorBodyDamage": boolean;
  "odometer": number;
  "warningGaugesOff": boolean;
  "thermometerPresent": boolean;
  "cleaningSuppliesPresent": boolean;
  "driverHasPPE": boolean;
  "handheldGpsPresent": boolean;
  "windshieldMountGpsHolder": boolean;
  "ezPassTransporterPresent": boolean;
  "numberOfWheelchairHooks": number;
  "numberOfWheelchairBelts": number;
  "stepStoolPresent": boolean;
  "foldableWheelchairPresent": boolean;
  "foldableTransportChairPresent": boolean;
  "stairChairPresent": boolean;
  "stairwayWheelchairCarrierPresent": boolean;
  "stretcherPresent": boolean;
  "interiorClean": boolean;
  "haveDisinfectedSeveralTime": boolean;
  "haveWashedExterior": boolean;
  "vehicleFueledUpToday": boolean;
  "anyVehicleIssuesToReport": boolean;
  "vehicleLeftLocked": boolean;
  "vehicleKeysLocation": string;
  "tabletLocation": string;
  "note": string;
  "durationTime": number;
  "issuesNumber": number;
  "missingStuffNumber": number;
  "dailyUsageDuration": number;
  "changedDuringDay": boolean;
  "vdate": number;
  "pictures": any;
  "meta": any;
  "summary": any;
  "utime": Date;
  "id": any;
  "userId": number;
  "employeeId": number;
  "vehicleId": number;
  user: MyUser;
  employee: Employee;
  vehicle: Vehicle;
  constructor(data?: PostShiftReportInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PostShiftReport`.
   */
  public static getModelName() {
    return "PostShiftReport";
  }

  /**
  * This method creates an instance of PostShiftReport for dynamic purposes.
  **/
  public static factory(data: PostShiftReportInterface): PostShiftReport{
    return new PostShiftReport(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PostShiftReport',
      plural: 'PostShiftReports',
      path: 'PostShiftReports',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "deviceUid": {
          name: 'deviceUid',
          type: 'string'
        },
        "startedDateTime": {
          name: 'startedDateTime',
          type: 'Date'
        },
        "completedDateTime": {
          name: 'completedDateTime',
          type: 'Date'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "shiftWithoutIncidents": {
          name: 'shiftWithoutIncidents',
          type: 'boolean'
        },
        "incidentOrAccident": {
          name: 'incidentOrAccident',
          type: 'string'
        },
        "anybodyGotInjured": {
          name: 'anybodyGotInjured',
          type: 'boolean'
        },
        "vehicleDamage": {
          name: 'vehicleDamage',
          type: 'boolean'
        },
        "visibleLeaksUnderVehicle": {
          name: 'visibleLeaksUnderVehicle',
          type: 'boolean'
        },
        "tiresConditionOk": {
          name: 'tiresConditionOk',
          type: 'boolean'
        },
        "exteriorClean": {
          name: 'exteriorClean',
          type: 'boolean'
        },
        "exteriorBodyDamage": {
          name: 'exteriorBodyDamage',
          type: 'boolean'
        },
        "odometer": {
          name: 'odometer',
          type: 'number'
        },
        "warningGaugesOff": {
          name: 'warningGaugesOff',
          type: 'boolean'
        },
        "thermometerPresent": {
          name: 'thermometerPresent',
          type: 'boolean'
        },
        "cleaningSuppliesPresent": {
          name: 'cleaningSuppliesPresent',
          type: 'boolean'
        },
        "driverHasPPE": {
          name: 'driverHasPPE',
          type: 'boolean'
        },
        "handheldGpsPresent": {
          name: 'handheldGpsPresent',
          type: 'boolean'
        },
        "windshieldMountGpsHolder": {
          name: 'windshieldMountGpsHolder',
          type: 'boolean'
        },
        "ezPassTransporterPresent": {
          name: 'ezPassTransporterPresent',
          type: 'boolean'
        },
        "numberOfWheelchairHooks": {
          name: 'numberOfWheelchairHooks',
          type: 'number'
        },
        "numberOfWheelchairBelts": {
          name: 'numberOfWheelchairBelts',
          type: 'number'
        },
        "stepStoolPresent": {
          name: 'stepStoolPresent',
          type: 'boolean'
        },
        "foldableWheelchairPresent": {
          name: 'foldableWheelchairPresent',
          type: 'boolean'
        },
        "foldableTransportChairPresent": {
          name: 'foldableTransportChairPresent',
          type: 'boolean'
        },
        "stairChairPresent": {
          name: 'stairChairPresent',
          type: 'boolean'
        },
        "stairwayWheelchairCarrierPresent": {
          name: 'stairwayWheelchairCarrierPresent',
          type: 'boolean'
        },
        "stretcherPresent": {
          name: 'stretcherPresent',
          type: 'boolean'
        },
        "interiorClean": {
          name: 'interiorClean',
          type: 'boolean'
        },
        "haveDisinfectedSeveralTime": {
          name: 'haveDisinfectedSeveralTime',
          type: 'boolean'
        },
        "haveWashedExterior": {
          name: 'haveWashedExterior',
          type: 'boolean'
        },
        "vehicleFueledUpToday": {
          name: 'vehicleFueledUpToday',
          type: 'boolean'
        },
        "anyVehicleIssuesToReport": {
          name: 'anyVehicleIssuesToReport',
          type: 'boolean'
        },
        "vehicleLeftLocked": {
          name: 'vehicleLeftLocked',
          type: 'boolean'
        },
        "vehicleKeysLocation": {
          name: 'vehicleKeysLocation',
          type: 'string'
        },
        "tabletLocation": {
          name: 'tabletLocation',
          type: 'string'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "durationTime": {
          name: 'durationTime',
          type: 'number'
        },
        "issuesNumber": {
          name: 'issuesNumber',
          type: 'number'
        },
        "missingStuffNumber": {
          name: 'missingStuffNumber',
          type: 'number'
        },
        "dailyUsageDuration": {
          name: 'dailyUsageDuration',
          type: 'number'
        },
        "changedDuringDay": {
          name: 'changedDuringDay',
          type: 'boolean'
        },
        "vdate": {
          name: 'vdate',
          type: 'number'
        },
        "pictures": {
          name: 'pictures',
          type: 'any',
          default: <any>null
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "summary": {
          name: 'summary',
          type: 'any',
          default: <any>null
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
      }
    }
  }
}
