<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Status *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="statuses"
          (onSelectionChanged)="status_onSelectionChanged($event)"
          formControlName="status"
          #status>
          <dx-validator name="Status">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="status.value && vehicleStatuses[status.value].subStatuses.length">
      <div class="dx-field-label">Sub-Status *</div>
      <div class="dx-field-value">
        <dx-select-box
          [dataSource]="vehicleStatuses[status.value].subStatuses"
          formControlName="subStatus"></dx-select-box>
      </div>
    </div>
  </div>

  <p>
    <dx-validation-summary></dx-validation-summary>
  </p>

  <!--    <p class="alert-danger" *ngIf="errorMessage">-->
  <!--      {{ errorMessage }}-->
  <!--    </p>-->
</dx-validation-group>

