<dx-data-grid
  #grid
  [dataSource]="dso"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <!--<dxi-column caption="Person">-->

  <dxi-column caption="Facility" dataField="facility_shortname" dataType="string"></dxi-column>

  <dxi-column caption="First Name" dataField="person_firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="person_lastname" dataType="string"></dxi-column>

  <dxi-column caption="Nickname" dataField="person_nickname" dataType="string" [visible]="true"></dxi-column>

  <dxi-column caption="Sex" dataField="person_sex" dataType="string">
    <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column
    caption="DOB"
    dataField="person_dob"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Native Lang"
    dataField="person_data.nativeLang"
    dataType="string"
    [visible]="false"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <dxi-column
    caption="Native Lang Name"
    dataField="person_data.nativeLangName"
    dataType="string"
    [visible]="false"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>
  <!--</dxi-column>-->

  <dxi-column caption="Status" dataField="status" dataType="string">
    <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string">
    <dxo-lookup [dataSource]="types" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Position" dataField="employeePosition_name" dataType="string"></dxi-column>

  <dxi-column
    caption="Interviewed At"
    dataField="interviewedAt"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Hired At"
    dataField="hiredAt"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Separated At"
    dataField="separatedAt"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <!--<dxi-column caption="Assigned to" dataField="_assigned_facilities"-->
  <!--[allowFiltering]="true" [allowSorting]="false"-->
  <!--cellTemplate="facilities_cell"-->
  <!--&gt;</dxi-column>-->

  <!--<dxi-column dataField="_trained_facilities" caption="Trained at"-->
  <!--[allowFiltering]="true" [allowSorting]="false"-->
  <!--cellTemplate="facilities_cell"-->
  <!--&gt;</dxi-column>-->

  <dxi-column
    caption="Notes"
    dataField="notes"
    dataType="string"
    editCellTemplate="textArea_editCell"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Person Notes"
    dataField="person_notes"
    dataType="string"
    editCellTemplate="textArea_editCell"
    [visible]="false"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit" [visible]="isEditDeleteVisible"></dxi-button>
    <dxi-button name="delete" [visible]="isEditDeleteVisible"></dxi-button>
  </dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'facilities_cell'">
    <ng-container *ngIf="cellInfo.value?.length">
      <ng-container *ngFor="let item of cellInfo.value | parseJson; let isLast = last">
        <ng-container *ngIf="item?.id">
          [{{ item?.shortname }}]
          <ng-container *ngIf="!isLast">,</ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- /// -->

  <ng-container *dxTemplate="let cellInfo of 'textArea_editCell'">
    <dx-text-area [value]="cellInfo.value" (onValueChanged)="cellInfo.setValue($event.value)"></dx-text-area>
  </ng-container>
</dx-data-grid>
