import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import moment from 'moment';
import { utc } from 'moment/moment';
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { MongoLoadOptionsConverter } from '../../../../shared/classes/loopback-custom-store/generic/load-options-converters/MongoLoadOptionsConverter';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Consumer, ConsumerApi, Facility, LoggerService } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-renew-auths',
  templateUrl: './renew-auths.component.html',
  styleUrls: ['./renew-auths.component.scss'],
})
export class RenewAuthsComponent extends ABaseComponent implements OnInit {
  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  @ViewChild('grid', { static: true }) grid: DxDataGridComponent;

  grid_stateStoring: any;
  grid_detail_stateStoring: any;

  dso: DataSourceOptions;

  facilityDso: DataSourceOptions = [];
  consumerDso: DataSourceOptions = [];

  selectedDateValue?: Date = new Date();
  selectedCodes: any[] = ['W1759', 'W1760', 'W1761', 'W1762', 'W1764'];

  clientDso = {};

  codeDso: any;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public consumerHelper: ConsumerHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();

    this.facilityDso = this.buildFacilityDataSource();
    this.consumerDso = this.buildConsumerDataSource();

    this.codeDso = this.buildCodeDso();

    this.$filterEvent$
      .pipe(
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => (this.dso = this.buildDataSource())),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const self = this;

    const activeAuthMoment =
      this.selectedDateValue && moment(this.selectedDateValue).isValid()
        ? utc(moment(this.selectedDateValue).format('YYYY-MM-DD'))
        : utc(moment().format('YYYY-MM-DD'));

    // const col = 'ExportsConsumersCache';
    // const aggregate = [
    //   {
    //     $match: {
    //       _broker: 'NAVINET.AUTH',
    //     },
    //   },
    // ];

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },

          ...(this.selectedCodes.length > 0
            ? {
                Code: { $in: this.selectedCodes },
              }
            : {}),

          ...{
            StartDT: { $lte: { $date: { v: activeAuthMoment.clone().endOf('week').toDate().toISOString() } } },
            EndDT: { $gte: { $date: { v: activeAuthMoment.clone().startOf('week').toDate().toISOString() } } },
          },
        },
      },
      {
        $sort: {
          StartDT: -1,
          _ctime: -1,
        },
      },
      {
        $group: {
          _id: {
            _broker: '$_broker',
            _clientId: '$_clientId',
          },
          // docs: {
          //   $push: '$$CURRENT',
          // },
          last: {
            $first: '$$CURRENT',
          },
        },
      },
      {
        $sort: {
          'last.StartDT': -1,
          'last._ctime': -1,
        },
      },
    ];

    const store = new CustomStore({
      key: '_id',
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoLoad(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoCount(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      // update: async (key: any | string | number, values: any): Promise<any> => {
      //   console.log(key, values);
      //
      //   await this.dss
      //     .getApi<ConsumerApi>(Consumer)
      //     .assignSystemClientToAuthOne(key._broker, key._clientId, values.last._tenantId, headersAllTenantsAppend)
      //     .toPromise();
      // },
    });

    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildCodeDso() {
    const self = this;

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      {
        $match: {
          _inactive: { $ne: true },
        },
      },
      {
        $group: { _id: '$Code' },
      },
      {
        $sort: { _id: 1 },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> =>
        gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise(),
      totalCount: async (loadOptions: LoadOptions): Promise<number> =>
        gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise(),
      byKey: async (key: any | string | number): Promise<any> => gqlMongoByKey(self.dss, col, key).toPromise(),
    });
    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  getClientDso(authDoc) {
    const self = this;

    if (!this.clientDso[authDoc._clientId]) {
      const col = 'ExportsAuthsDataCache';
      const aggregate = [
        {
          $match: {
            _inactive: { $ne: true },
            _clientId: authDoc._clientId,
          },
        },
        {
          $sort: {
            StartDT: -1,
            _ctime: -1,
          },
        },
      ];

      const store = new CustomStore({
        useDefaultSearch: true,
        cacheRawData: false,
        load: async (loadOptions: LoadOptions): Promise<any> => {
          const q = new MongoLoadOptionsConverter().convert(loadOptions);
          return gqlMongoLoad(self.dss, col, loadOptions, [
            // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
            ...aggregate,
          ]).toPromise();
        },
        totalCount: async (loadOptions: LoadOptions): Promise<number> => {
          const q = new MongoLoadOptionsConverter().convert(loadOptions);
          return gqlMongoCount(self.dss, col, loadOptions, [
            // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
            ...aggregate,
          ]).toPromise();
        },
        byKey: async (key: any | string | number): Promise<any> => {
          return gqlMongoByKey(self.dss, col, key).toPromise();
        },
      });

      const dso: DataSourceOptions = {
        store,
        // sort: [{selector: '_date', desc: true}],
        // postProcess: (data: Array<any>): Array<any> => {
        //   return data;
        // },
      } as DataSourceOptions;

      this.clientDso[authDoc._clientId] = dso;
    }

    return this.clientDso[authDoc._clientId];
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return dso;
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;

    return dso;
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }
}
