import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
//
import {
  configGetConfig,
  configGetError,
  configGetErrorMessage,
  configGetPending,
  configReducer,
  ConfigState,
} from './core/config';
import {
  envVarsGetError,
  envVarsGetErrorMessage,
  envVarsGetPending,
  envVarsGetVars,
  envVarsReducer,
  EnvVarsState,
} from './core/env-vars';
import {
  generalGetAlerts,
  generalGetPendingCount,
  generalIsLoadPanelVisible,
  generalIsOnline,
  generalReducer,
  GeneralState,
} from './core/general';

export interface CoreState {
  general: GeneralState;
  envVars: EnvVarsState;
  config: ConfigState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
  general: generalReducer,
  envVars: envVarsReducer,
  config: configReducer,
};

/**
 * Selectors
 */

export const getCoreState = createFeatureSelector<CoreState>('core');

// General

export const selectGeneralState = createSelector(getCoreState, (state: CoreState) => state.general);
export const isLoadPanelVisible = createSelector(selectGeneralState, generalIsLoadPanelVisible);
export const getPendingCount = createSelector(selectGeneralState, generalGetPendingCount);
export const isOnline = createSelector(selectGeneralState, generalIsOnline);
export const getAlerts = createSelector(selectGeneralState, generalGetAlerts);

// Config

export const selectConfigState = createSelector(getCoreState, (state: CoreState) => state.config);
export const getConfig = createSelector(selectConfigState, configGetConfig);
export const getConfigPending = createSelector(selectConfigState, configGetPending);
export const getConfigError = createSelector(selectConfigState, configGetError);
export const getConfigErrorMessage = createSelector(selectConfigState, configGetErrorMessage);

// EnvVars

export const selectEnvVarsState = createSelector(getCoreState, (state: CoreState) => state.envVars);
export const getEnvVars = createSelector(selectEnvVarsState, envVarsGetVars);
export const getEnvVarsPending = createSelector(selectEnvVarsState, envVarsGetPending);
export const getEnvVarsError = createSelector(selectEnvVarsState, envVarsGetError);
export const getEnvVarsErrorMessage = createSelector(selectEnvVarsState, envVarsGetErrorMessage);
