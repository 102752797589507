/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-hr-certs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/vehicle-list/vehicle-list.component.ngfactory";
import * as i3 from "../../components/vehicle-list/vehicle-list.component";
import * as i4 from "./vehicle-hr-certs.component";
var styles_VehicleHRCertsComponent = [i0.styles];
var RenderType_VehicleHRCertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleHRCertsComponent, data: {} });
export { RenderType_VehicleHRCertsComponent as RenderType_VehicleHRCertsComponent };
export function View_VehicleHRCertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-list", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.list_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VehicleListComponent_0, i2.RenderType_VehicleListComponent)), i1.ɵdid(1, 114688, null, 0, i3.VehicleListComponent, [], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_VehicleHRCertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle", [], null, null, null, View_VehicleHRCertsComponent_0, RenderType_VehicleHRCertsComponent)), i1.ɵdid(1, 114688, null, 0, i4.VehicleHRCertsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleHRCertsComponentNgFactory = i1.ɵccf("app-vehicle", i4.VehicleHRCertsComponent, View_VehicleHRCertsComponent_Host_0, {}, {}, []);
export { VehicleHRCertsComponentNgFactory as VehicleHRCertsComponentNgFactory };
