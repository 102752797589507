<app-content-w-header header="Processing">
  <dx-tab-panel class="content" height="100%">
    <dxi-item title="Common Billing">
      <app-billing-common></app-billing-common>
    </dxi-item>

    <dxi-item title="Meals Billing">
      <app-billing-meals></app-billing-meals>
    </dxi-item>
  </dx-tab-panel>
</app-content-w-header>
