import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ComponentCanDeactivate } from '../../../../shared/modules/my-common/guards/pending-changes.guard';
import { AssignEmployeeFormComponent } from '../assign-employee-form/assign-employee-form.component';
import { PasswordFormComponent } from '../password-form/password-form.component';
import { RolesFormComponent } from '../roles-form/roles-form.component';
import { UsersFormComponent } from '../users-form/users-form.component';
import { MfaFormComponent } from '../mfa-form/mfa-form.component';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss'],
})
export class UsersEditComponent implements ComponentCanDeactivate, OnInit {
  modelId$: Observable<number | string | null>;

  @ViewChild(UsersFormComponent, { static: true }) userForm: UsersFormComponent;
  @ViewChild(MfaFormComponent, { static: true }) mfaForm: MfaFormComponent;
  @ViewChild('rolesForm', { static: false }) rolesForm: RolesFormComponent;
  @ViewChild('passwordForm', { static: false }) passwordForm: PasswordFormComponent;
  @ViewChild('assignEmployeeForm', { static: false }) assignEmployeeForm: AssignEmployeeFormComponent;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.modelId$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('id')),
      map(id => (Number(id) === 0 ? undefined : id)),
    );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.userForm.form.pristine;
  }

  form_ngSubmit(e): void {
    this.userForm &&
      this.userForm
        .submitFormAsync()
        .then(async user => {
          if (user) {
            notify('Done!', 'success');
            await this.router.navigate(['dashboard', 'users', user.id]);
          }
        })
        .catch(this.logger.error);
  }

  form_MfaSubmit(e): void {
    this.mfaForm &&
      this.mfaForm
        .submitFormAsync()
        .then(async user => {
          if (user) {
            notify('Done!', 'success');
            await this.router.navigate(['dashboard', 'users', user.id]);
          }
        })
        .catch(this.logger.error);
  }

  setRoles_onClick(e): void {
    this.rolesForm &&
      this.rolesForm
        .submitForm()
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
  }

  setPassword_onClick(e): void {
    this.passwordForm &&
      this.passwordForm
        .submitForm()
        .toPromise()
        .then(() => notify('Done!', 'success'))
        .catch(err => notify(err.message, 'error'));
  }

  reset_onClick(e): void {
    this.userForm && this.userForm.reset();
  }

  cancel_onClick(): void {
    this.router.navigate(['dashboard', 'users']);
  }
}
