export const VEHICLE_STATUSES = {
  ASSIGNED: { subStatuses: [] },
  BACKUP: { subStatuses: ['Universal', 'Emergency Only', 'Meals Only'] },
  'IN MAINTENANCE': { subStatuses: ['Active Work', 'Waiting For Parts', 'Waiting In Queue'] },
  ORDERED: { subStatuses: [] },
  'READY 2 DEPLOY': { subStatuses: [] },
  'JUST PURCHASED': { subStatuses: [] },
  DECOMMISSIONED: { subStatuses: [] },
  DONATED: { subStatuses: [] },
  SOLD: { subStatuses: [] },
  'FOR SALE': { subStatuses: [] },
  TOTALED: { subStatuses: [] },
  SWAPPED: { subStatuses: [] },
  SUSPENDED: { subStatuses: [] },
  'JUNK/PARTS': { subStatuses: [] },
  'GROUNDED - PLATES OFF': { subStatuses: [] },
  OTHER: { subStatuses: [] },
};
