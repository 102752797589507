import {
  MyUser,
  Employee,
  Vehicle
} from '../index';

declare var Object: any;
export interface FuelRefillInterface {
  "requestID": string;
  "dateTime": Date;
  "date"?: Date;
  "vdate"?: any;
  "amount": number;
  "odometer": number;
  "cardNum": string;
  "fuelType": string;
  "receiptImgFileId": string;
  "meta"?: any;
  "note"?: string;
  "id"?: number;
  "userId"?: number;
  "employeeId"?: number;
  "vehicleId"?: number;
  user?: MyUser;
  employee?: Employee;
  vehicle?: Vehicle;
}

export class FuelRefill implements FuelRefillInterface {
  "requestID": string;
  "dateTime": Date;
  "date": Date;
  "vdate": any;
  "amount": number;
  "odometer": number;
  "cardNum": string;
  "fuelType": string;
  "receiptImgFileId": string;
  "meta": any;
  "note": string;
  "id": number;
  "userId": number;
  "employeeId": number;
  "vehicleId": number;
  user: MyUser;
  employee: Employee;
  vehicle: Vehicle;
  constructor(data?: FuelRefillInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `FuelRefill`.
   */
  public static getModelName() {
    return "FuelRefill";
  }

  /**
  * This method creates an instance of FuelRefill for dynamic purposes.
  **/
  public static factory(data: FuelRefillInterface): FuelRefill{
    return new FuelRefill(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FuelRefill',
      plural: 'FuelRefills',
      path: 'FuelRefills',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "dateTime": {
          name: 'dateTime',
          type: 'Date'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "vdate": {
          name: 'vdate',
          type: 'any'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "odometer": {
          name: 'odometer',
          type: 'number'
        },
        "cardNum": {
          name: 'cardNum',
          type: 'string'
        },
        "fuelType": {
          name: 'fuelType',
          type: 'string'
        },
        "receiptImgFileId": {
          name: 'receiptImgFileId',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "vehicleId": {
          name: 'vehicleId',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        vehicle: {
          name: 'vehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'vehicleId',
          keyTo: 'id'
        },
      }
    }
  }
}
