/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-details-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/devextreme-angular/ui/scroll-view/devextreme-angular-ui-scroll-view.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/scroll-view";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../document-details-tab/document-details-tab.component.ngfactory";
import * as i7 from "../document-details-tab/document-details-tab.component";
import * as i8 from "../../../../shared/sdk/services/custom/logger.service";
import * as i9 from "../../../../shared/modules/ui/services/ui.service";
import * as i10 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../../../../node_modules/devextreme-angular/ui/tab-panel/devextreme-angular-ui-tab-panel.ngfactory";
import * as i13 from "devextreme-angular/ui/tab-panel";
import * as i14 from "./document-details-tabs.component";
var styles_DocumentDetailsTabsComponent = [i0.styles];
var RenderType_DocumentDetailsTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentDetailsTabsComponent, data: {} });
export { RenderType_DocumentDetailsTabsComponent as RenderType_DocumentDetailsTabsComponent };
function View_DocumentDetailsTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "dx-scroll-view", [], null, null, null, i2.View_DxScrollViewComponent_0, i2.RenderType_DxScrollViewComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(5, 7323648, null, 0, i4.DxScrollViewComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-document-details-tab", [], null, null, null, i6.View_DocumentDetailsTabComponent_0, i6.RenderType_DocumentDetailsTabComponent)), i1.ɵdid(7, 770048, null, 0, i7.DocumentDetailsTabComponent, [i8.LoggerService, i9.UiService, i10.DataSourceService, i11.MatDialog], { modelId: [0, "modelId"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.modelId; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_DocumentDetailsTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "dx-tab-panel", [], null, null, null, i12.View_DxTabPanelComponent_0, i12.RenderType_DxTabPanelComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.IterableDifferHelper, i3.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(5, 7323648, null, 1, i13.DxTabPanelComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.IterableDifferHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { animationEnabled: [0, "animationEnabled"], dataSource: [1, "dataSource"], loop: [2, "loop"], selectedIndex: [3, "selectedIndex"], showNavButtons: [4, "showNavButtons"], swipeEnabled: [5, "swipeEnabled"] }, null), i1.ɵqud(603979776, 1, { itemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentDetailsTabsComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i3.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.tabs; var currVal_2 = false; var currVal_3 = 0; var currVal_4 = false; var currVal_5 = false; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "details"; _ck(_v, 8, 0, currVal_6); }, null); }
export function View_DocumentDetailsTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-details-tabs", [], null, null, null, View_DocumentDetailsTabsComponent_0, RenderType_DocumentDetailsTabsComponent)), i1.ɵdid(1, 114688, null, 0, i14.DocumentDetailsTabsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentDetailsTabsComponentNgFactory = i1.ɵccf("app-document-details-tabs", i14.DocumentDetailsTabsComponent, View_DocumentDetailsTabsComponent_Host_0, { modelId: "modelId" }, {}, []);
export { DocumentDetailsTabsComponentNgFactory as DocumentDetailsTabsComponentNgFactory };
