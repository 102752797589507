/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consumer-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../consumer-grid/consumer-grid.component.ngfactory";
import * as i3 from "../../services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Consumer";
import * as i5 from "../../../../shared/sdk/services/custom/ConsumerView";
import * as i6 from "../../../../shared/modules/my-common/services/config.service";
import * as i7 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i8 from "@angular/common/http";
import * as i9 from "../../../../shared/modules/my-common/services/common.service";
import * as i10 from "../../../../shared/modules/ui/services/ui.service";
import * as i11 from "../consumer-grid/consumer-grid.component";
import * as i12 from "../../../../shared/sdk/services/custom/logger.service";
import * as i13 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i14 from "../../../../shared/modules/my-common/services/state-store.service";
import * as i15 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../../shared/modules/ui/services/push-notifications.service";
import * as i18 from "./consumer-list.component";
var styles_ConsumerListComponent = [i0.styles];
var RenderType_ConsumerListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsumerListComponent, data: {} });
export { RenderType_ConsumerListComponent as RenderType_ConsumerListComponent };
export function View_ConsumerListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { consumerGrid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-consumer-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ConsumerGridComponent_0, i2.RenderType_ConsumerGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.ConsumerApi, i5.ConsumerViewApi, i6.ConfigService, i7.PusherService, i8.HttpClient, i9.CommonService, i10.UiService]), i1.ɵdid(3, 245760, [[1, 4]], 0, i11.ConsumerGridComponent, [i12.LoggerService, i6.ConfigService, i10.UiService, i13.DataSourceService, i14.StateStoreService, i3.HelperService, i15.GridHelperService, i16.MatDialog, i7.PusherService, i17.PushNotificationsService], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_ConsumerListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consumer-list", [], null, null, null, View_ConsumerListComponent_0, RenderType_ConsumerListComponent)), i1.ɵdid(1, 114688, null, 0, i18.ConsumerListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsumerListComponentNgFactory = i1.ɵccf("app-consumer-list", i18.ConsumerListComponent, View_ConsumerListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { ConsumerListComponentNgFactory as ConsumerListComponentNgFactory };
