<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">Change Employee Status to “Backup”</div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="content">
  <dx-validation-group>
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Employee *</div>
        <div class="dx-field-value">
          <dx-select-box
            [(value)]="selected"
            [dataSource]="notBackUpEmployees$ | async"
            [displayExpr]="selectBoxdisplayExpression"
            placeholder="Select..."></dx-select-box>
        </div>
      </div>
    </div>
  </dx-validation-group>
</div>

<div mat-dialog-actions style="justify-content: end">
  <button mat-button color="primary" (click)="onSave()">Save</button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>

