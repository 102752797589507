import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { LoggerService } from '../../../../sdk/services/custom';

/** Data structure for holding week check values. */
export class WeekdayCheckMap {
  constructor(
    public mo: boolean,
    public tu: boolean,
    public we: boolean,
    public th: boolean,
    public fr: boolean,
    public sa: boolean,
    public su: boolean,
  ) {}
}

@Component({
  selector: 'app-weekday-checkbox',
  templateUrl: './weekday-checkbox.component.html',
  styleUrls: ['./weekday-checkbox.component.scss'],
  providers: [
    // {provide: MatFormFieldControl, useExisting: forwardRef(() => WeekdayCheckboxComponent)},
    { provide: MatFormFieldControl, useExisting: WeekdayCheckboxComponent },
  ],
})
export class WeekdayCheckboxComponent implements MatFormFieldControl<WeekdayCheckMap>, ControlValueAccessor, OnDestroy {
  static nextId = 0;
  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'app-weekday-check-box';
  @HostBinding('attr.id') id = `app-weekday-check-box-${WeekdayCheckboxComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    public logger: LoggerService,
  ) {
    // Setting the value accessor directly (instead of using
    // the providers) to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = fb.group({
      mo: false,
      tu: false,
      we: false,
      th: false,
      fr: false,
      sa: false,
      su: false,
    });

    this.parts.valueChanges.subscribe(parts => {
      this._onChange(new WeekdayCheckMap(parts.mo, parts.tu, parts.we, parts.th, parts.fr, parts.sa, parts.su));
    });

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  get value(): WeekdayCheckMap | null {
    const v = this.parts.value;
    return new WeekdayCheckMap(v.mo, v.tu, v.we, v.th, v.fr, v.sa, v.su);
  }

  @Input()
  set value(val: WeekdayCheckMap | null) {
    val = val || new WeekdayCheckMap(false, false, false, false, false, false, false);

    // setTimeout(() => {
    this.parts.setValue({
      mo: val.mo || false,
      tu: val.tu || false,
      we: val.we || false,
      th: val.th || false,
      fr: val.fr || false,
      sa: val.sa || false,
      su: val.su || false,
    });

    this.stateChanges.next();
    // });
  }

  private _placeholder: string;

  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set placeholder(plh) {
    // setTimeout(() => {
    this._placeholder = plh;
    this.stateChanges.next();
    // });
  }

  private _required = false;

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    // setTimeout(() => {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
    // });
  }

  private _disabled = false;

  get disabled() {
    return this._disabled;
  }

  @Input()
  set disabled(dis) {
    // setTimeout(() => {
    this._disabled = coerceBooleanProperty(dis);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
    // });
  }

  private _readonly = false;

  get readonly() {
    return this._readonly;
  }

  @Input()
  set readonly(val) {
    setTimeout(() => {
      this._readonly = coerceBooleanProperty(val);
      this.stateChanges.next();
    });
  }

  get empty() {
    const n = this.parts.value;
    return !n.mo && !n.tu && !n.we && !n.th && !n.fr && !n.sa && !n.su;
  }

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when select has been touched` */
  _onTouched = () => {};

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (!(event.target as Element).className.includes('mat-checkbox')) {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }
}
