import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-dlg-calendar',
  templateUrl: './dlg-calendar.component.html',
  styleUrls: ['./dlg-calendar.component.scss'],
})
export class DlgCalendarComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgCalendarComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  ngOnInit() {}

  calendar_onValueChanged(e) {
    // this.logger.log(e);
    const result = asShortDate(e.value);
    confirm('Are you sure?', 'Confirm selection').then(dialogResult => {
      if (dialogResult) {
        this.dialogRef.close(result);
      }
    });
  }
}
