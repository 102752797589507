import { createSelector } from '@ngrx/store';
import { Actions, LOGOUT, LOGOUT_FAIL, LOGOUT_SUCCESS } from '../../actions/sign';

export interface LogoutState {
  error: any | null;
  pending: boolean;
}

export const initialState: LogoutState = {
  error: null,
  pending: false,
};

export function logoutReducer(state = initialState, action: Actions): LogoutState {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case LOGOUT_FAIL: {
      return {
        ...state,
        error: action.payload || 'Server unavailable',
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const logoutGetPending = (state: LogoutState) => state.pending;
export const logoutGetError = (state: LogoutState) => state.error;
export const logoutGetErrorMessage = createSelector(logoutGetError, err => (err ? err.message || err : ''));
