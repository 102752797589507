<div>
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-sorting mode="none"></dxo-sorting>

    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <dxo-export [enabled]="true" fileName="HHA-Records" [allowExportSelectedData]="true"></dxo-export>

    <!-- region Columns -->

    <dxi-column caption="Payer ID" dataField="Payer ID" dataType="string"></dxi-column>

    <dxi-column caption="Payer" dataField="_payerId" dataType="string">
      <dxo-lookup [dataSource]="HHA_PAYERS" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="Agency ID" dataField="Agency ID" dataType="string"></dxi-column>

    <dxi-column caption="Patient ID" dataField="Patient ID" dataType="string"></dxi-column>

    <dxi-column caption="Admission ID" dataField="Admission ID" dataType="string"></dxi-column>

    <dxi-column caption="First Name" dataField="First Name" dataType="string"></dxi-column>

    <dxi-column caption="Middle Name" dataField="Middle Name" dataType="string"></dxi-column>

    <dxi-column caption="Last Name" dataField="Last Name" dataType="string"></dxi-column>

    <dxi-column caption="MR Number" dataField="MR Number" dataType="string"></dxi-column>

    <dxi-column caption="Gender" dataField="Gender" dataType="string"></dxi-column>

    <dxi-column
      caption="DOB"
      dataField="DOB"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Priority Code" dataField="Priority Code" dataType="string"></dxi-column>

    <dxi-column caption="Medicaid Number" dataField="Medicaid Number" dataType="string"></dxi-column>

    <dxi-column caption="Address">
      <dxi-column caption="Street 1" dataField="Street 1" dataType="string"></dxi-column>

      <dxi-column caption="Street 2" dataField="Street 2" dataType="string"></dxi-column>

      <dxi-column caption="City" dataField="City" dataType="string"></dxi-column>

      <dxi-column caption="State" dataField="State" dataType="string"></dxi-column>

      <dxi-column caption="Zip" dataField="Zip" dataType="string"></dxi-column>

      <dxi-column caption="Cross Street" dataField="Cross Street" dataType="string"></dxi-column>
    </dxi-column>

    <dxi-column caption="Home Phone" dataField="Home Phone" dataType="string"></dxi-column>

    <dxi-column caption="Phone 2" dataField="Phone 2" dataType="string"></dxi-column>

    <dxi-column caption="Phone 2 Description" dataField="Phone 2 Description" dataType="string"></dxi-column>

    <dxi-column caption="Phone 3" dataField="Phone 3" dataType="string"></dxi-column>

    <dxi-column caption="Phone 3 Description" dataField="Phone 3 Description" dataType="string"></dxi-column>

    <dxi-column caption="Status" dataField="Status" dataType="string"></dxi-column>

    <dxi-column
      caption="Start of Care Date"
      dataField="Start of Care Date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column
      caption="Discharge Date"
      dataField="Discharge Date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Emergency">
      <dxi-column caption="Emergency 1 Name" dataField="Emergency 1 Name" dataType="string"></dxi-column>

      <dxi-column caption="Emergency 1 Address" dataField="Emergency 1 Address" dataType="string"></dxi-column>

      <dxi-column
        caption="Emergency 1 Relationship"
        dataField="Emergency 1 Relationship"
        dataType="string"></dxi-column>

      <dxi-column caption="Emergency 1 Phone 1" dataField="Emergency 1 Phone 1" dataType="string"></dxi-column>

      <dxi-column caption="Emergency 1 Phone 2" dataField="Emergency 1 Phone 2" dataType="string"></dxi-column>

      <dxi-column caption="Emergency 2 Name" dataField="Emergency 2 Name" dataType="string"></dxi-column>

      <dxi-column caption="Emergency 2 Address" dataField="Emergency 2 Address" dataType="string"></dxi-column>

      <dxi-column
        caption="Emergency 2 Relationship"
        dataField="Emergency 2 Relationship"
        dataType="string"></dxi-column>

      <dxi-column caption="Emergency 2 Phone 1" dataField="Emergency 2 Phone 1" dataType="string"></dxi-column>

      <dxi-column caption="Emergency 2 Phone 2" dataField="Emergency 2 Phone 2" dataType="string"></dxi-column>
    </dxi-column>

    <dxi-column caption="Payer Coordinator" dataField="Payer Coordinator" dataType="string"></dxi-column>

    <dxi-column caption="Agency Coordinator" dataField="Agency Coordinator" dataType="string"></dxi-column>

    <dxi-column caption="Frequency" dataField="Frequency" dataType="string"></dxi-column>

    <dxi-column caption="Source Of Admission" dataField="Source Of Admission" dataType="string"></dxi-column>

    <dxi-column caption="Location" dataField="Location" dataType="string"></dxi-column>

    <dxi-column caption="Team" dataField="Team" dataType="string"></dxi-column>

    <dxi-column caption="Branch" dataField="Branch" dataType="string"></dxi-column>

    <dxi-column caption="Modified Date" dataField="Modified Date" dataType="datetime"></dxi-column>

    <dxi-column caption="Is Deletion" dataField="Is Deletion" dataType="boolean"></dxi-column>

    <dxi-column caption="Alternate Patient ID" dataField="Alternate Patient ID" dataType="string"></dxi-column>

    <!-- endregion -->

    <!-- region Templates -->

    <div *dxTemplate="let dem of 'detail'">
      <dx-tab-panel>
        <dxi-item title="HHA Auth Records">
          <dx-data-grid
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [showBorders]="true"
            [dataSource]="authDS(dem.data._patientId)"
            [twoWayBindingEnabled]="false"
            (onInitialized)="detailGrid_onInitialized($event)">
            <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

            <!-- region Columns -->

            <dxi-column caption="Payer ID" dataField="Payer ID" dataType="string"></dxi-column>

            <dxi-column caption="Payer" dataField="_payerId" dataType="string">
              <dxo-lookup [dataSource]="HHA_PAYERS" displayExpr="Name" valueExpr="ID"></dxo-lookup>
            </dxi-column>

            <dxi-column caption="Agency ID" dataField="Agency ID" dataType="string"></dxi-column>

            <dxi-column caption="Patient ID" dataField="Patient ID" dataType="string"></dxi-column>

            <dxi-column caption="Authorization ID" dataField="Authorization ID" dataType="string"></dxi-column>

            <dxi-column caption="Admission ID" dataField="Admission ID" dataType="string"></dxi-column>

            <dxi-column caption="Service Category" dataField="Service Category" dataType="string"></dxi-column>

            <dxi-column caption="Service Type" dataField="Service Type" dataType="string"></dxi-column>

            <dxi-column caption="Authorization Number" dataField="Authorization Number" dataType="string"></dxi-column>

            <dxi-column caption="Billing Service Code" dataField="Billing Service Code" dataType="string"></dxi-column>

            <dxi-column
              caption="From Date"
              dataField="From Date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column
              caption="To Date"
              dataField="To Date"
              dataType="date"
              format="shortDate"
              [customizeText]="config.customizeShortDateText"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

            <dxi-column caption="Authorization Type" dataField="Authorization Type" dataType="string"></dxi-column>

            <dxi-column
              caption="Hours Per Auth Period"
              dataField="Hours Per Auth Period"
              dataType="number"></dxi-column>

            <dxi-column caption="Invoice Limit" dataField="Invoice Limit" dataType="number"></dxi-column>

            <dxi-column caption="Notes" dataField="Notes" dataType="string"></dxi-column>

            <dxi-column caption="Modified Date" dataField="Modified Date" dataType="datetime"></dxi-column>

            <dxi-column caption="Is Deletion" dataField="Is Deletion" dataType="boolean"></dxi-column>

            <!-- endregion -->

            <!-- region Templates -->
            <!-- endregion -->
          </dx-data-grid>
        </dxi-item>
      </dx-tab-panel>
    </div>

    <!--  endregion  -->
  </dx-data-grid>
</div>
