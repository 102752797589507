import { Pipe, PipeTransform } from '@angular/core';
declare var QRious: any;

@Pipe({
  name: 'qrCode',
})
export class QrCodePipe implements PipeTransform {
  transform(
    value: string,
    options?: {
      background?: string; // "white"
      backgroundAlpha?: number; // 1.0
      element?: Element; // <canvas>
      foreground?: string; // "black"
      foregroundAlpha?: number; // 1.0
      // Error correction level of the QR code (L, M, Q, H)
      level?: 'L' | 'M' | 'Q' | 'H'; // "L"
      mime?: string; // "image/png"
      padding?: number; // null (auto)
      size?: number; // 100
    },
  ): string {
    // @link https://github.com/neocotic/qrious
    const defaultOpts = { level: 'Q' };
    const qr = new QRious({ ...defaultOpts, ...options, value: value || '' });
    return qr.toDataURL();
  }
}
