import { NgModule } from '@angular/core';
//
import { LayoutModule } from '@progress/kendo-angular-layout';
import { UploadService, UploadsModule } from '@progress/kendo-angular-upload';

// import {InputsModule} from '@progress/kendo-angular-inputs';
// import {ButtonsModule} from '@progress/kendo-angular-buttons';
// import {GridModule} from '@progress/kendo-angular-grid';
// import {DialogModule} from '@progress/kendo-angular-dialog';

const MODULES = [LayoutModule, UploadsModule];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class MyKendoModule {}
