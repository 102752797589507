import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee, LoggerService } from '../../../../../shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import moment from 'moment';

@Component({
  selector: 'app-dlg-overwrite-manifest-confirm',
  templateUrl: './dlg-overwrite-manifest-confirm.component.html',
  styleUrls: ['./dlg-overwrite-manifest-confirm.component.scss'],
})
export class DlgOverwriteManifestConfirm implements OnInit {
  overwriteSchedules: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<DlgOverwriteManifestConfirm, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public config: ConfigService,
  ) {}

  ngOnInit() {}

  confirmOverwriteManifestDialog() {
    if (this.overwriteSchedules) {
      // Handle the checkbox state if needed
      console.log('Checkbox is checked');
    }
    this.dialogRef.close({ overwriteSchedules: this.overwriteSchedules });
  }
}

