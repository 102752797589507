import { Component, Inject, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import intersection from 'lodash-es/intersection';
import { combineLatest, iif, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Employee, LoggerService, LoopBackAuth, LoopBackFilter, Person } from '../../../../shared/sdk';
import { DlgAssignFacilityComponent } from '../../dialogs/dlg-assign-facility/dlg-assign-facility.component';
import { DlgCreateAccountComponent } from '../../dialogs/dlg-create-account/dlg-create-account.component';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';

@Component({
  selector: 'app-employee-details-tab-profile',
  templateUrl: './employee-details-tab-profile.component.html',
  styleUrls: ['./employee-details-tab-profile.component.scss'],
})
export class EmployeeDetailsTabProfileComponent
  extends ABaseModelPaneWToolbarComponent<Employee>
  implements OnInit, OnChanges, OnDestroy
{
  canAssignFacility = false;
  canCreateAccount = false;

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    public helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger, ui, dss);

    this.caption = 'Person Info';

    this.model$
      .pipe(
        // tap(console.log),
        switchMap(model =>
          iif(
            () => !!model, // && !!model.userId,
            of(model).pipe(
              switchMap(m =>
                combineLatest([
                  this.config.tenantType$,
                  this.config.roles$,
                  // this.dss.getApi<MyUserApi>(MyUser).getRoles(m.userId)
                ]),
              ),
              map(
                ([
                  tType,
                  myRoles,
                  // eRoles
                ]) => {
                  // const onBASE = this.auth.getCurrentTenant() === 7; // TODO: hardcode
                  const onBASE = tType === 'BASE';
                  const hasRole1 =
                    intersection(myRoles, ['SU', 'ADMIN', 'HR_ADMIN', 'ASSIGN_FACILITY_TO_EMPLOYEE']).length > 0;
                  const hasRole2 = intersection(myRoles, ['SU', 'ADMIN', 'HR_ADMIN']).length > 0;
                  return [
                    onBASE && hasRole1,
                    // &&
                    // eRoles.includes('DRIVER') &&
                    // eRoles.filter((r: string) => !r.startsWith('$')).length === 1
                    onBASE && hasRole2,
                  ];
                },
              ),
            ),
            of([false, false]),
          ),
        ),
        tap(([can1, can2]) => {
          this.canAssignFacility = can1;
          this.canCreateAccount = can2;
        }),
        catchError(err => {
          console.error(err);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Employee>> {
    return EmployeeFormComponent;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        { person: [{ contact: ['addresses', 'phones', 'emails'] }, 'personalPhoto'] },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
        'user',
        'employeePosition',
      ],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, Person];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  reassignTenant_onClick() {
    void this.dialog
      .open(DlgAssignFacilityComponent, {
        hasBackdrop: true,
        width: '600px',
        minWidth: 400,
        data: {
          model: this.model,
          userId: oc(this.model).userId(),
        },
      })
      .afterClosed()
      .pipe(
        switchMap(v =>
          iif(
            () => !!v,
            of(v).pipe(
              tap(() => this.ui.showLoading()),
              catchError(err => of(notify(err.message, 'error', 5000))),
              tap(() => this.ui.hideLoading()),
            ),
          ),
        ),
      )
      .toPromise();
  }

  createAccount_onClick() {
    void this.dialog
      .open(DlgCreateAccountComponent, {
        hasBackdrop: true,
        width: '600px',
        minWidth: 400,
        data: {
          model: this.model,
        },
      })
      .afterClosed()
      .pipe(
        switchMap(v =>
          iif(
            () => !!v,
            of(v).pipe(
              tap(() => this.ui.showLoading()),
              tap(() => this.refresh()),
              tap(() => this.reassignTenant_onClick()),
              catchError(err => of(notify(err.message, 'error', 5000))),
              tap(() => this.ui.hideLoading()),
            ),
          ),
        ),
      )
      .toPromise();
  }
}
