import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService, Signature } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-cancel-service',
  templateUrl: './dlg-edit-service-status.component.html',
  styleUrls: ['./dlg-edit-service-status.component.scss'],
})
export class DlgEditServiceStatusComponent implements OnInit {
  STATUSES = [
    { v: null, t: 'Undefined' },
    { v: 'P1', t: 'P1 - Picked up with no incident' },
    { v: 'P2', t: 'P2 - Picked up with incident' },
    { v: 'XN1', t: 'XN1 - Cancelled prior to en-route' },
    { v: 'XB1', t: 'XB1 - Cancelled en-route' },
    { v: 'XB2', t: 'XB2 - Cancelled to driver' },
    { v: 'XB3', t: 'XB3 - Client not at place of pickup' },
    { v: 'XB4', t: 'XB4 - Client not ready for pickup' },
    { v: 'XN5', t: 'XN5 - Cancelled at validation' },
    { v: 'XB6', t: 'XB6 - Skipped by driver' },
  ];

  value: {
    status: string;
    note: string;
  };

  constructor(
    private dialogRef: MatDialogRef<DlgEditServiceStatusComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: Signature,
    protected logger: LoggerService,
  ) {
    this.value = {
      status: data.status,
      note: data.note,
    };
  }

  ngOnInit() {}
}
