import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
//
import { Employee } from '../../../../shared/sdk/models';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-employee-drop-down-grid',
  templateUrl: './employee-drop-down-grid.component.html',
  styleUrls: ['./employee-drop-down-grid.component.scss'],
  providers: [
    HelperService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeDropDownGridComponent),
      multi: true,
    },
  ],
})
export class EmployeeDropDownGridComponent implements OnInit, ControlValueAccessor {
  _onChange: any;
  _onTouched: any;

  @Input() dataSource: any;
  @Input() remoteOperations: any;
  @Input() grid_columns: any[] = [
    { dataField: 'person_firstname', caption: 'Firstname' },
    { dataField: 'person_lastname', caption: 'Lastname' },
    { dataField: 'person_nickname', caption: 'Nickname' },
  ];
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() width: any = 'auto';
  @Input() value: any;
  @Input() filter: any = [
    ['status', '=', 'ACTIVE'],
    // ['source', '=', null],
  ];

  @Input() showClearButton = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Output() grid_cellPrepared: EventEmitter<any> = new EventEmitter();

  constructor(private dss: DataSourceService, public config: ConfigService, public helper: HelperService) {
    this.buildDataSource();
  }

  ngOnInit() {
    this.valueChange.subscribe(value => this._onChange && this._onChange(value));
    this.focusOut.subscribe(() => this._onTouched && this._onTouched());
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private buildDataSource() {
    this.dataSource = this.dataSource || {
      store: this.dss.getStore(Employee, null, false),
      filter: this.filter,
    };
  }

  // private buildDataSource() {
  //   this.dataSource = {
  //     store: this.dss.getStore(Employee, null, false),
  //     filter: ['status', '=', 'ACTIVE'],
  //     postProcess: (data: any[]): any[] => {
  //       if (!data.find((item) => item.id === this.helper.selfEntity.id)) {
  //         data.unshift(this.helper.selfEntity);
  //       }
  //       return data;
  //     },
  //   };
  //
  //   const _store_byKey = this.dataSource.store.byKey;
  //   this.dataSource.store.byKey = (key: any): Promise<any> => {
  //     if (key === this.helper.selfEntity.id)
  //       return promiseAsDeferred(Promise.resolve(this.helper.selfEntity)).promise();
  //     else
  //       return _store_byKey.bind(this.dataSource.store)(key);
  //   };
  // }
}
