/* tslint:disable:member-ordering */
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { LoggerService } from '../../../../sdk/services/custom';

/** Data structure for holding week number values. */
export class WeekdayNumberMap {
  constructor(
    public mo: number,
    public tu: number,
    public we: number,
    public th: number,
    public fr: number,
    public sa: number,
    public su: number,
  ) {}
}

@Component({
  selector: 'app-weekday-number-box',
  templateUrl: './weekday-number-box.component.html',
  styleUrls: ['./weekday-number-box.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: forwardRef(() => WeekdayNumberBoxComponent) }],
})
export class WeekdayNumberBoxComponent
  implements MatFormFieldControl<WeekdayNumberMap>, ControlValueAccessor, OnDestroy
{
  static nextId = 0;

  parts: FormGroup;

  stateChanges = new Subject<void>();

  focused = false;

  errorState = false;

  controlType = 'app-weekday-number-box';

  @HostBinding('attr.id') id = `app-weekday-number-box-${WeekdayNumberBoxComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when select has been touched` */
  _onTouched = () => {};

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    public logger: LoggerService,
  ) {
    // Setting the value accessor directly (instead of using
    // the providers) to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = fb.group({
      mo: 0,
      tu: 0,
      we: 0,
      th: 0,
      fr: 0,
      sa: 0,
      su: 0,
    });

    this.parts.valueChanges.subscribe(parts => {
      this._onChange(new WeekdayNumberMap(parts.mo, parts.tu, parts.we, parts.th, parts.fr, parts.sa, parts.su));
    });

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  private _placeholder: string;
  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set placeholder(plh) {
    setTimeout(() => {
      this._placeholder = plh;
      this.stateChanges.next();
    });
  }

  private _required = false;
  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    setTimeout(() => {
      this._required = coerceBooleanProperty(req);
      this.stateChanges.next();
    });
  }

  private _disabled = false;
  get disabled() {
    return this._disabled;
  }

  @Input()
  set disabled(dis) {
    setTimeout(() => {
      this._disabled = coerceBooleanProperty(dis);
      this.stateChanges.next();
    });
  }

  private _readonly = false;
  get readonly() {
    return this._readonly;
  }

  @Input()
  set readonly(val) {
    setTimeout(() => {
      this._readonly = coerceBooleanProperty(val);
      this.stateChanges.next();
    });
  }

  get value(): WeekdayNumberMap | null {
    const n = this.parts.value;
    return new WeekdayNumberMap(n.mo, n.tu, n.we, n.th, n.fr, n.sa, n.su);
  }

  @Input()
  set value(val: WeekdayNumberMap | null) {
    val = val || new WeekdayNumberMap(0, 0, 0, 0, 0, 0, 0);

    setTimeout(() => {
      this.parts.setValue({
        mo: val.mo || 0,
        tu: val.tu || 0,
        we: val.we || 0,
        th: val.th || 0,
        fr: val.fr || 0,
        sa: val.sa || 0,
        su: val.su || 0,
      });

      this.stateChanges.next();
    });
  }

  get empty() {
    const n = this.parts.value;
    return n.mo === 0 && n.tu === 0 && n.we === 0 && n.th === 0 && n.fr === 0 && n.sa === 0 && n.su === 0;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }
}
