import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaust, exhaustMap, tap, timeout } from 'rxjs/operators';
import { delayAsync } from '../../../../shared/classes/utils/promise.utils';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { VehicleListComponent } from '../../components/vehicle-list/vehicle-list.component';
import { AutoRepairShop, Employee, EmployeeView, Vehicle } from 'src/app/shared/sdk';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle-in-maintenance.component.html',
  styleUrls: ['./vehicle-in-maintenance.component.scss'],
})
export class VehicleInMaintenanceComponent extends ABaseComponent implements OnInit {
  $selectedArray$: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
  $markersArray$: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
  selected$: Observable<Vehicle> = new Observable();
  markers$: Observable<Vehicle[]> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(VehicleListComponent, { static: true }) grid: VehicleListComponent;

  autoRepairShopDso$: Observable<DataSourceOptions> = of([]);
  assignedFleetManagerDso$: Observable<DataSourceOptions> = of([]);

  extraFields = {
    noteCategory: 'Maintenance/Repair Note',
    serviceType: 'Maintenance',
    dateOffDuty: new Date(),
    dateReturnedOnDuty: new Date(),
    autoRepairShopId: null,
    assignedFleetManagerId: [],
  };

  constructor(protected logger: LoggerService, private dss: DataSourceService) {
    super(logger);

    this.selected$ = this.$selectedArray$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
    this.markers$ = this.$markersArray$.pipe(map(ids => (ids && ids.length ? ids : [])));

    this.autoRepairShopDso$ = this.buildAutoRepairShopDsoDataSource();
    this.assignedFleetManagerDso$ = this.buildAssignedFleetManagerDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(vehicles: Vehicle[]) {
    if (vehicles && vehicles.length) {
      this.$selectedArray$.next(vehicles);
    } else {
      this.$selectedArray$.next([]);
    }
  }

  list_markers(markers: any[]) {
    if (markers && markers.length) {
      this.$markersArray$.next(markers);
    } else {
      this.$markersArray$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }

  autoRepairShop_displayExpr(e: AutoRepairShop) {
    return (e && `${e.name}`) || '';
  }

  assignedFleetManager_displayExpr(e: EmployeeView) {
    return (e && `${e.person_firstname} ${e.person_lastname}`) || '';
  }

  private buildAutoRepairShopDsoDataSource() {
    const so = this.dss.getStoreOptions(AutoRepairShop, undefined, false);
    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildAssignedFleetManagerDataSource() {
    const dso = this.dss.getDataSourceOptions(Employee);
    dso.filter = ['employeePosition_id', '=', 36];
    dso.sort = [{ selector: 'person_firstname' }, { selector: 'person_lastname' }];
    return of(dso);
  }
}

