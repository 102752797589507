/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/schedule-list/schedule-list.component.ngfactory";
import * as i3 from "../../components/schedule-list/schedule-list.component";
import * as i4 from "./schedule.component";
var styles_ScheduleComponent = [i0.styles];
var RenderType_ScheduleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleComponent, data: {} });
export { RenderType_ScheduleComponent as RenderType_ScheduleComponent };
export function View_ScheduleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule-list", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.list_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScheduleListComponent_0, i2.RenderType_ScheduleListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ScheduleListComponent, [], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ScheduleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule", [], null, null, null, View_ScheduleComponent_0, RenderType_ScheduleComponent)), i1.ɵdid(1, 114688, null, 0, i4.ScheduleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleComponentNgFactory = i1.ɵccf("app-schedule", i4.ScheduleComponent, View_ScheduleComponent_Host_0, {}, {}, []);
export { ScheduleComponentNgFactory as ScheduleComponentNgFactory };
