<div mat-dialog-content>
  <div>
    <mat-form-field>
      <input
        matInput
        type="number"
        placeholder="CI Odometer"
        [(ngModel)]="value.ciOdo"
        [disabled]="!value.ci"
        cdkFocusInitial />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput type="number" placeholder="CO Odometer" [(ngModel)]="value.coOdo" [disabled]="!value.co" />
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value" cdkFocusInitial>Ok</button>
</div>
