import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import {
  Employee,
  EmployeeDayService,
  EmployeeDayServiceApi,
  Facility,
  FacilityApi,
  LoggerService,
  Person,
  Signature,
  SignatureApi,
} from 'src/app/shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { StateStoreService } from 'src/app/shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../../classes/enums';

import { HelperService as EmployeeHelperService } from '../../../../employee/services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import moment from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { HelperService } from '../../../services/helper.service';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';

@Component({
  selector: 'app-employee-fleet-grid',
  templateUrl: './employee-fleet-grid.component.html',
  styleUrls: ['./employee-fleet-grid.component.scss'],
  providers: [HelperService],
})
export class EmployeeFleetGridComponent extends ABaseComponent implements OnInit {
  sexes = PERSON_SEXES;
  types = EMPLOYEE_TYPES;
  statuses = EMPLOYEE_STATUSES;

  dso: DataSourceOptions;

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private employeeHelper: EmployeeHelperService,
    private dss: DataSourceService,
    public helper: HelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
    const self = this;

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b64648cd-7c24-4455-a355-7ea404328414',
    };

    // this.dso = dss.getDataSourceOptions(Employee);

    const so = this.dss.getStoreOptions(Employee, Employee, false);
    so.customFilter = {
      where: { status: 'ACTIVE', employeePositionId: { inq: [39, 40] } },
      include: ['employeePosition', { person: { contact: ['phones', 'emails'] } }],
    };
    const store = new CustomStore(so);

    dxStoreLoadHooks(store, undefined, async (args: any[], [empls]: any[]) => {
      const [facilities, schedules, lastDateOnDutyMap, eds] = await Promise.all([
        this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise(),
        this.getDriverSchedulesToday(),
        this.employeeHelper.getLastDateOnDutyMap(),
        this.dss
          .getApi<EmployeeDayServiceApi>(EmployeeDayService)
          .find<EmployeeDayService>(
            { where: { date: moment().format('YYYY-MM-DD'), marker: 'PUNCH-IN:1' } },
            headersAllTenantsAppend,
          )
          .toPromise(),
      ]);

      const fMap = facilities.reduce((p, v) => ({ ...p, [v.id]: v }), {});
      let map = schedules.reduce((p, v) => ({ ...p, [v.driverId]: { schedule: v, ...p[v.driverId] } }), {});
      map = eds.reduce((p, ed) => ({ ...p, [ed.employeeId]: { ed, ...p[ed.employeeId] } }), map);
      return [
        empls.map(e => ({
          ...e,
          ...map[e.id],
          ...lastDateOnDutyMap[e.id],
          todaysVehicle:
            (lastDateOnDutyMap[e.id] &&
              moment(lastDateOnDutyMap[e.id].last.date).isSame(moment(), 'day') &&
              lastDateOnDutyMap[e.id].last.vehicle.internalId) ||
            '',
          facility: lastDateOnDutyMap[e.id] && fMap[lastDateOnDutyMap[e.id].last.tenantId],
        })),
      ];
    });

    this.dso = { store } as DataSourceOptions;

    this.dso.postProcess = (data: Array<any>) => {
      data.forEach(rec => (rec._anotherTenant = this.common.auth.getCurrentTenant() !== rec.tenantId));
      return data;
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent
      .pipe(
        tap(modelName => {
          if ([Employee.getModelName(), Person.getModelName()].includes(modelName)) {
            if (this.grid) this.grid.instance.refresh();
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  getPhoneCellValue = (e: Employee) => e.person.contact.phones[0] && e.person.contact.phones[0].value;

  getEmailCellValue = (e: Employee) => e.person.contact.emails[0] && e.person.contact.emails[0].value;

  getDriverSchedulesToday() {
    const stages = [{ $match: { day: moment().format('YYYY-MM-DD') } }];
    return gqlMongoLoad(this.dss, 'DriverSchedule', {}, stages).pipe().toPromise();
  }
}

