<!--<as-split-->
<!--  direction="horizontal"-->
<!--  (dragEnd)="split_dragEnd($event)"-->
<!--  (transitionEnd)="split_transitionEnd($event)"-->
<!--&gt;-->
<!--  <as-split-area [size]="60">-->
<!--    <app-employee-list (mySelected)="list_onSelected($event)"></app-employee-list>-->
<!--  </as-split-area>-->

<!--  <as-split-area [size]="40">-->
<!--    <app-employee-details [modelId]="selectedId$ | async"></app-employee-details>-->
<!--  </as-split-area>-->
<!--</as-split>-->

<kendo-splitter>
  <kendo-splitter-pane [scrollable]="false">
    <app-employee-ext-list (mySelected)="list_onSelected($event)"></app-employee-ext-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane [collapsible]="true" [scrollable]="false" size="40%">
    <app-employee-ext-details [modelId]="selectedId$ | async"></app-employee-ext-details>
  </kendo-splitter-pane>
</kendo-splitter>

