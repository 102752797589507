import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment-timezone';
import { ConfigService } from '../services/config.service';

@Pipe({
  name: 'local',
})
export class LocalPipe implements PipeTransform {
  constructor(private config: ConfigService) {}

  transform(value: Moment | Date | string | undefined, format: string, withTZ = false): string {
    if (withTZ) {
      const _tz: string = this.config.get('timezone');

      if (_tz) {
        return value ? moment.tz(value, _tz).format(format) : '';
      }
    }

    return value ? moment(value).format(format) : '';
  }
}
