import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { createSelector, select } from '@ngrx/store';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import { confirm } from 'devextreme/ui/dialog';
import { groupBy } from 'lodash-es';
import fromPairs from 'lodash-es/fromPairs';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';
import { Consumer, ConsumerView, EmployeeView, Facility, LoggerService, Program, } from '../../../../shared/sdk';
import { selectConfigState } from '../../../../store/reducers/core';
import { selectSignInfoState } from '../../../../store/reducers/sign';
import { PERSON_SEXES, REPETITIVE_MODES, WEEKDAYS } from '../../../employee/classes/enums';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { CONSUMER_LANGS, CONSUMER_STATUSES, CONSUMER_TRANSPORTATION_CODES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
export class ConsumerFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, helper, config, common, helperService, employeeHelper, uploadHelper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.helper = helper;
        this.config = config;
        this.common = common;
        this.helperService = helperService;
        this.employeeHelper = employeeHelper;
        this.uploadHelper = uploadHelper;
        this.modelWithDetails$ = new BehaviorSubject(null);
        this.employeesDso$ = of([]);
        this.persDevicesDS = [];
        this.validationCallback = this.uploadHelper.validationCallback;
        this.adcClientCACFPEligibilityList = [
            { v: 'N', t: 'N/A' },
            { v: 'A', t: 'A / V / 1 - Free' },
            { v: 'B', t: 'B / W / 2 - Reduced' },
            { v: 'C', t: 'C / X / 3 - Paid' },
        ];
        this.personSexesDS = PERSON_SEXES;
        this.consumerStatuses = CONSUMER_STATUSES;
        this.repetitiveModes = REPETITIVE_MODES;
        this.transpCodes = CONSUMER_TRANSPORTATION_CODES;
        this.consumerLangs = CONSUMER_LANGS;
        this.weekDays = WEEKDAYS;
        this.mealTypes = ['hot_W1759', 'frozen_W1760', 'sandwich_W1761', 'emergency_W1762', 'special_W1764'];
        this.mealTypeCodeMap = {
            hot_W1759: 'W1759',
            frozen_W1760: 'W1760',
            sandwich_W1761: 'W1761',
            emergency_W1762: 'W1762',
            special_W1764: 'W1764',
        };
        this.codeMealTypeMap = {
            W1759: 'hot_W1759',
            W1760: 'frozen_W1760',
            W1761: 'sandwich_W1761',
            W1762: 'emergency_W1762',
            W1764: 'special_W1764',
        };
        this.mealTypeConfigMap = {
            hot_W1759: 'meals_Hot_W1759_Enabled',
            frozen_W1760: 'meals_Frozen_W1760_Enabled',
            sandwich_W1761: 'meals_Sandwich_W1761_Enabled',
            emergency_W1762: 'meals_Emergency_W1762_Enabled',
            special_W1764: 'meals_Special_W1764_Enabled',
        };
        this.mealTypeTitleMap = {
            hot_W1759: 'Hot Meal (W1759)',
            frozen_W1760: 'Frozen Meal (W1760)',
            sandwich_W1761: 'Sandwich Meal (W1761)',
            emergency_W1762: 'Emergency Meal (W1762)',
            special_W1764: 'Special Meal (W1764)',
        };
        this.tooltips = {
            clientNotes: 'General notes concerning client, like<br>' +
                '<em>"For any questions, please contact Daughter Jane"</em> or<br>' +
                '<em>"Client moved as of 1/1/2019 to new address: ..."</em>',
            program: 'Client`s State Program - 95% of clients will have <strong>PA-CHC</strong> ' +
                '(Which is former OLTL-Waivers: Aging, Attendant Care, Independence, Or Dual Eligible Medicare & Medicaid)<br>' +
                '<strong>PA-OPTIONS</strong> - Another active state program, not under CHC<br>' +
                '<strong>NON-PROGRAM-YET</strong> - Clients in process of receiving program<br>' +
                '<strong>NOT-PROGRAM-ELIGIBLE</strong> - Clients not eligible for state programs<br>' +
                '<strong>PRIVATE PAY</strong> - Clients Paying out of Pocket<br>' +
                '<strong>SELF RIDE</strong> - Clients using their own means of transport<br>' +
                '<strong>OTHER COMPANY</strong> - Clients Transported by other company<br>',
            internalID: 'This ID comes from "2019 Clients" Google Sheets, ' + 'please make sure client is entered there and ID assigned.',
            clientType: 'Subscription or Single ride.<br>' +
                'For repetitive riders use <strong>"Subscription"</strong> value, ' +
                'these are all of the Adult Day Care ' +
                'and most of the clients receiving Dialysis services.<br>' +
                'For clients going to regular medical appointments use <strong>"Single Ride"</strong>.',
            weekDays: 'For Subscription Clients select week days they are attending facility, ' +
                'refer to notes from Broker and confirm days with Facility.',
            transpInstructions: 'Instructions for Drivers with details about pickup location, obstacles, etc.<br>' +
                'Sample: <em>"Client has 5 steps in front of the house, need assistance walking down the stairs."</em> ' +
                'or <em>"Call 10 min prior to arrival"</em> ' +
                'or <em>"Client using back door."</em> <br>' +
                'These Instructions are printed on manifest for the driver, ' +
                'never put in this field client`s medical condition or diagnosis.',
            transpCodes: 'Important flags Driver should be aware of and always keep in mind. ' +
                'These codes are printed on manifest for the driver.',
            attendanceNote: 'Internal note specifically addressing clients attendance, ' +
                'fill out this note every time client`s Status or ' +
                'attendance changes always specify date of note, ' +
                'for example <em>"2/1/2019 ON-HOLD - Traveling to India, will be back 6/1/2019"</em> ' +
                'or <em>"4/1/2019 Inactive - Deceased on 3/28/2019, informed by ADC"</em>',
            relatedContacts: 'Contact info of a person related to consumer, ' +
                'usually caregiver (Son, Daughter, Sister, Brother, Aid, PCP, etc).',
            brokerNotes: 'Most Recent Notes pulled from broker Trip records',
        };
        this.destinationDisplayExpr = data => (data ? `[${data.short}] ${data.name}` : '');
        this.employeesDso$ = this.buildEmployeeDataSource();
        this.destinationsDS$ = this.common.store.pipe(select(createSelector([selectSignInfoState, selectConfigState], (s1, s2) => [s1.currentTenant, s2.config])), switchMap(([tenantId, conf]) => combineLatest([
            !isNaN(tenantId) && tenantId > 0
                ? this.dss.models.getApi(Facility.getModelName()).findById(tenantId)
                : of(null),
            of(oc(conf).data.destinationList([])),
        ])), map(([tenant, destinationList]) => {
            const addition = oc(tenant).type() !== 'BASE' ? [{ short: 'FACILITY', name: '' }] : [];
            const sorted = sortBy(destinationList.filter(d => ['FACILITY', 'SCHOOL'].includes(d.group)).filter(d => d && d.short), ['short']);
            return [...addition, ...sorted];
        }));
        const store = this.dss.getStore(Program, undefined, false);
        this.programDso = {
            store,
            // filter: ['type', 'inq', ['ADC', 'BASE']],
            sort: [{ selector: 'order' }],
        };
        this.setState(FORM_STATE.COLLAPSED);
        this.config.tenantType$
            .pipe(tap(type => {
            const tabsTitles = ['General', 'Transport'];
            if (['BASE', 'MEALS'].includes(type))
                tabsTitles.push('Meals');
            if (['TC'].includes(type))
                tabsTitles.push('PERS');
            this.tabsTitles = tabsTitles;
        }), takeUntil(this.$onDestroy$))
            .subscribe();
    }
    get addressesFormArray() {
        return this.form.get('person.contact.addresses');
    }
    get phonesFormArray() {
        return this.form.get('person.contact.phones');
    }
    get emailsFormArray() {
        return this.form.get('person.contact.emails');
    }
    get emRelationsFormArray() {
        return this.form.get('emRelations');
    }
    get filter() {
        return {
            include: [
                {
                    relation: 'relatedNotes',
                    scope: { order: 'dateTime DESC', limit: 5 },
                },
                'program',
                { person: { contact: ['addresses', 'phones', 'emails'] } },
                { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
                'persDevices',
            ],
        };
    }
    get ModelClass() {
        return Consumer;
    }
    get dateFields() {
        return [
            'person.dob',
            'clientSince',
            'tcInformedConsentDocumentSignatureDate',
            'relatedNotes.0.infoDate',
            'relatedNotes.0.followUpDate',
        ];
    }
    get mongoDatePaths() {
        return [
            'persDevices.0.deliveredDate',
            'persDevices.0.initialLiveTestDate',
            'persDevices.0.installationDate',
            'persDevices.0.virtualTrainingDate',
            'persDevices.0.lastSuccessfulTestDate',
            'persDevices.0.lastSuccessfulTestingAdaptiveEquipment',
        ];
    }
    get age() {
        return moment().diff(this.form.get('person.dob').value, 'years', false);
    }
    ngOnInit() {
        super.ngOnInit();
        this.model$
            .pipe(switchMap(model => {
            if (model) {
                const mealsAuths$ = this.api.getMealsAuths(model.id, moment().format('YYYY-MM-DD'), this.customHeaders);
                const mealsEstimated$ = this.api.getMealsEstimated(model.id, moment().format('YYYY-MM-DD'), this.customHeaders);
                const brokerClients$ = of(true).pipe(switchMap(() => !isEmpty(model.mci)
                    ? gqlMongoLoad(this.dss, 'ExportsConsumersCache', {
                        filter: [['_mci', '=', model.mci]],
                    })
                    : of([])));
                return combineLatest([mealsAuths$, brokerClients$, mealsEstimated$]).pipe(map(([mealsAuths, brokerClients, mealsEstimated]) => (Object.assign({}, model, { _mealsAuths: fromPairs(Object.entries(groupBy(mealsAuths, 'Code')).map(([c, auths]) => [this.codeMealTypeMap[c], auths[0]])), _brokerClient: brokerClients.find(bc => !bc._broker.includes('.AUTH')), _authClient: brokerClients.find(bc => bc._broker.includes('.AUTH')), _mealsEstimated: fromPairs(Object.entries(mealsEstimated).map(([c, o]) => [this.codeMealTypeMap[c], o])) }))));
            }
            else {
                return of(null);
            }
        }), tap(modelWithDetails => this.modelWithDetails$.next(modelWithDetails)), takeUntil(this.$onDestroy$))
            .subscribe();
    }
    ngAfterViewInit() {
        // this.uploadHelper.handle(
        //   this.uploader1.instance,
        //   {
        //     folder: 'documents',
        //     onUploaded: e => {
        //       this.form.markAsDirty();
        //     },
        //   },
        //   oc(this.uploadValidator1).instance(),
        // );
        // this.uploadHelper.handle(
        //   this.uploader2.instance,
        //   {
        //     folder: 'documents',
        //     onUploaded: e => {
        //       this.form.markAsDirty();
        //     },
        //   },
        //   oc(this.uploadValidator2).instance(),
        // );
    }
    processFormValueAsync(data) {
        const _super = Object.create(null, {
            processFormValueAsync: { get: () => super.processFormValueAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            {
                const note = data._note;
                if (!isEmpty(note.text)) {
                    note.meta = oc(note).meta({});
                    note.source = oc(note).source('web');
                    data.relatedNotes = [note];
                }
                if (this.model && this.model.status !== data.status && isEmpty(note.text)) {
                    throw new Error('You should add note on changing client status');
                }
            }
            // {
            //   const file = head<UploadableFile>(
            //     oc(this.uploader1)
            //       .value([])
            //       .filter((uf: UploadableFile) => !!uf.uploaded),
            //   );
            //
            //   if (file) data.tcInformedConsentFile = file.gcBucket + '/' + file.gcFile;
            // }
            {
                const persDevice = data._persDevice;
                if (!isEmpty(oc(persDevice).status()) && !isEmpty(oc(persDevice).type())) {
                    // const file = head<UploadableFile>(
                    //   oc(this.uploader2)
                    //     .value([])
                    //     .filter((uf: UploadableFile) => !!uf.uploaded),
                    // );
                    //
                    // if (file) persDevice.shippingLabelFile = file.gcBucket + '/' + file.gcFile;
                    data.persDevices = [persDevice];
                }
            }
            const duplicates = !this.modelId
                ? yield this.dss
                    .getApi(ConsumerView)
                    .find({
                    where: Object.assign({}, (this.modelId ? { [Consumer.getModelDefinition().idName]: { neq: this.modelId } } : {}), { person_firstname: { like: (data.person.firstname || '').trim().toLowerCase() }, person_lastname: { like: (data.person.lastname || '').trim().toLowerCase() } }),
                    limit: 1,
                })
                    .toPromise()
                : [];
            if (duplicates.length) {
                const res = yield confirm('Client with the same name already exists.<br>Abort client creation?', 'Duplicate found');
                if (res === true)
                    throw new Error('This client already exists');
            }
            return _super.processFormValueAsync.call(this, data);
        });
    }
    addNoteClick(e, value) {
        this.addNoteEnabled = value;
    }
    buildEmployeeDataSource() {
        return this.dss.getApi(EmployeeView).find(
        // TODO: REMOVE HARDCODE!
        { where: { employeePositionId: { inq: [39, 40, 273] } }, order: ['person_lastname ASC'] }, hAll);
    }
    buildForm() {
        this.formConfigMap.set('person.contact.addresses', {
            id: [],
            street: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: [],
            contactId: [],
        });
        this.formConfigMap.set('person.contact.phones', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('person.contact.emails', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('person.contact', {
            id: [],
            notes: [],
            addresses: this.fb.array([]),
            phones: this.fb.array([]),
            emails: this.fb.array([]),
        });
        this.formConfigMap.set('person.data', {
            nativeLang: [],
            nativeLangName: [],
        });
        this.formConfigMap.set('person', {
            id: [],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            middlename: [],
            nickname: [],
            sex: [],
            dob: [],
            notes: [],
            contactId: [],
            data: this.fb.group(this.formConfigMap.get('person.data')),
            contact: this.fb.group(this.formConfigMap.get('person.contact')),
        });
        this.formConfigMap.set('emRelations', {
            id: [],
            relation: [],
            consumerId: [],
            personId: ['', Validators.required],
        });
        this.formConfigMap.set('data', {
            icd10: [],
            unableToSign: [false],
            adcClientShift: [],
            adcClientShiftPerDay: [],
            adcDailyRate: [],
            adcClientCACFPEligibility: ['N'],
            //
            sdac_01_iihrucDCg: [],
            sdac_02_lnfis: [],
            sdac_03_csdacsccu: [],
            sdac_04_apasdi: [],
            sdac_05_ncwoh: [],
            sdac_06_mhced: [],
            sdac_07_sil: [],
            sdac_08_finr: [],
            sdac_09_others: [],
            sdac_09_othersText: [],
        });
        this.formConfigMap.set('weekdayMeta', () => ({
            schedUnits: [],
            actualDay: [],
            actualUnits: [],
            schedTime: [],
            mealRequests: [],
        }));
        this.formConfigMap.set('mealTypeMeta', () => ({
            notes: [''],
            weekdayMeta: this.fb.group({
                mo: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                tu: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                we: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                th: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                fr: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                sa: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
                su: this.fb.group(this.formConfigMap.get('weekdayMeta')()),
            }),
        }));
        this.formConfigMap.set('mealMeta', {
            smsOnMealDropOff: [false],
            mealDeliveryI10s: [''],
            cuisinePreference: [''],
            foodAllergies: [''],
            mealGeneralNotes: [''],
            hot_W1759: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
            frozen_W1760: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
            sandwich_W1761: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
            emergency_W1762: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
            special_W1764: this.fb.group(this.formConfigMap.get('mealTypeMeta')()),
        });
        this.formConfigMap.set('_note', {
            id: [],
            text: [],
            author: [],
            infoBy: [],
            infoDate: [],
            followUpDate: [],
            source: ['web'],
            meta: [{}],
        });
        this.formConfigMap.set('_persDevice', {
            _id: [],
            status: ['ACTIVE'],
            type: [],
            make: [],
            model: [],
            notes: [],
            imei: [],
            phoneNumber: [],
            installationDetails: [],
            deliveredDate: [],
            deliveryConfirmation: [],
            shippingLabelFile: [],
            installationDate: [],
            virtualTrainingDate: [],
            virtualTrainingConductedBy: [],
            virtualTrainingNotes: [],
            initialLiveTestDate: [],
            lastSuccessfulTestingAdaptiveEquipment: [],
            lastSuccessfulTestDate: [],
        });
        this.formConfigMap.set('', {
            id: [],
            status: ['', Validators.required],
            internalID: [],
            facilityID: [],
            mci: [],
            autoDispatch: [true],
            keepStretcher: [],
            preferredEmployees: [],
            avoidEmployees: [],
            onBoardingDuration: [],
            offBoardingDuration: [],
            defaultDestination: [],
            daysNotes: [],
            brokerNotes: [],
            specialInstrs: [],
            transpInstrs: [],
            transpCodes: [[]],
            // notes: [],
            clientSince: [],
            repetitiveMode: ['SUBSCRIPTION'],
            weekDays: [{}],
            c10nAgency: [],
            cwName: [],
            cwPhone: [],
            cwFax: [],
            cwEmail: [],
            cwNote: [],
            legacyCustomerID: [],
            tcInformedConsentDocumentSignatureDate: [],
            tcInformedConsentFile: [],
            persLastSuccessfulTest: [],
            persAlertsLast30D: [],
            persAlertsLast90D: [],
            persAlertsLast12M: [],
            persNotes: [],
            personId: [],
            programId: [],
            data: this.fb.group(this.formConfigMap.get('data')),
            person: this.fb.group(this.formConfigMap.get('person')),
            mealMeta: this.fb.group(this.formConfigMap.get('mealMeta')),
            emRelations: this.fb.array([]),
            _note: this.fb.group(this.formConfigMap.get('_note')),
            _persDevice: this.fb.group(this.formConfigMap.get('_persDevice')),
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
    afterSubmittedAsync(data, obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.refresh();
        });
    }
    persDevice_displayExpr(e) {
        return e ? e.name || `${e.model} [${e.status}]` : '';
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.persDevicesDS = oc(model).persDevices([]);
            this.activePersDevice = this.persDevicesDS.find(pd => pd.status === 'ACTIVE');
            this.activePersDeviceId = oc(this.activePersDevice)._id();
        });
    }
    setPersDeviceFormValue(e) {
        setTimeout(() => {
            if (e.selectedItem)
                this.form.get('_persDevice').patchValue(e.selectedItem);
            else
                this.form.get('_persDevice').reset({ status: 'ACTIVE' });
        }, 0);
    }
    uploader1_onInitialized(event) {
        this.uploadHelper.handle(event.component, 
        // this.uploader1.instance,
        {
            folder: 'documents',
            onUploaded: e => {
                this.form.markAsDirty();
            },
        }, oc(this.uploadValidator1).instance());
    }
}
