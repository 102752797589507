import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//
import { confirm } from 'devextreme/ui/dialog';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import { LoggerService } from '../../../../shared/sdk';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dlg-calendar',
  templateUrl: './dlg-calendar.component.html',
  styleUrls: ['./dlg-calendar.component.scss'],
  providers: [HelperService],
})
export class DlgCalendarComponent implements OnInit {
  private dateManifestMap: Map<string, number> = new Map();

  constructor(
    private dialogRef: MatDialogRef<DlgCalendarComponent, { date: string; mode: string }>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private helper: HelperService,
    protected logger: LoggerService,
  ) {}

  ngOnInit() {
    this.helper.buildDateManifestMapAsync(10, 1).then(map => (this.dateManifestMap = map));
  }

  manifestExists({ date, view }): boolean {
    return view === 'month' ? this.dateManifestMap.has(asShortDate(date)) : false;
  }

  calendar_onValueChanged(e) {
    // this.logger.log(e);
    const result = { date: asShortDate(e.value), mode: this.data.mode };
    confirm('Are you sure?', 'Confirm selection').then(dialogResult => {
      if (dialogResult) {
        this.dialogRef.close(result);
      }
    });
  }
}
