import {
  Person
} from '../index';

declare var Object: any;
export interface PersonalPhotoInterface {
  "personId": number;
  "org"?: any;
  "avatar"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: any;
  person?: Person;
}

export class PersonalPhoto implements PersonalPhotoInterface {
  "personId": number;
  "org": any;
  "avatar": any;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": number;
  "id": any;
  person: Person;
  constructor(data?: PersonalPhotoInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PersonalPhoto`.
   */
  public static getModelName() {
    return "PersonalPhoto";
  }

  /**
  * This method creates an instance of PersonalPhoto for dynamic purposes.
  **/
  public static factory(data: PersonalPhotoInterface): PersonalPhoto{
    return new PersonalPhoto(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PersonalPhoto',
      plural: 'PersonalPhotos',
      path: 'PersonalPhotos',
      idName: 'id',
      properties: {
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "org": {
          name: 'org',
          type: 'any',
          default: <any>null
        },
        "avatar": {
          name: 'avatar',
          type: 'any',
          default: <any>null
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
                  keyFrom: 'personId',
          keyTo: 'id'
        },
      }
    }
  }
}
