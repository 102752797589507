<app-content-w-header header="ACL">
  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="ds"
    [errorRowEnabled]="true"
    (onInitialized)="grid_onInitialized($event)">
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

    <dxo-editing mode="cell" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!-- Columns -->

    <dxi-column caption="Model" dataField="model" dataType="string">
      <dxo-lookup [dataSource]="modelDso"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Property" dataField="property" dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Access Type" dataField="accessType" dataType="string">
      <dxo-lookup [dataSource]="['*', 'READ', 'REPLICATE', 'WRITE', 'EXECUTE']"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Permission" dataField="permission" dataType="string">
      <dxo-lookup [dataSource]="['DEFAULT', 'ALLOW', 'ALARM', 'AUDIT', 'DENY']"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Principal Type" dataField="principalType" dataType="string">
      <dxo-lookup [dataSource]="['ROLE', 'USER', 'APPLICATION', 'SCOPE']"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column caption="Principal ID" dataField="principalId" dataType="string">
      <dxo-lookup [dataSource]="roleDso"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
  </dx-data-grid>
</app-content-w-header>
