/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meals-generate-invoice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/content-w-header/content-w-header.component.ngfactory";
import * as i3 from "../../../../shared/modules/ui/components/content-w-header/content-w-header.component";
import * as i4 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/devextreme-angular/ui/select-box/devextreme-angular-ui-select-box.ngfactory";
import * as i8 from "@angular/forms";
import * as i9 from "devextreme-angular/ui/select-box";
import * as i10 from "devextreme-angular/core";
import * as i11 from "@angular/platform-browser";
import * as i12 from "@angular/common";
import * as i13 from "../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory";
import * as i14 from "devextreme-angular/ui/button";
import * as i15 from "./meals-generate-invoice.component";
import * as i16 from "../../../../shared/sdk/services/custom/logger.service";
import * as i17 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i18 from "../../../../shared/modules/my-common/services/common.service";
import * as i19 from "../../../../shared/modules/my-common/services/config.service";
import * as i20 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i21 from "../../../../shared/modules/ui/services/push-notifications.service";
var styles_MealsGenerateInvoiceComponent = [i0.styles];
var RenderType_MealsGenerateInvoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MealsGenerateInvoiceComponent, data: {} });
export { RenderType_MealsGenerateInvoiceComponent as RenderType_MealsGenerateInvoiceComponent };
export function View_MealsGenerateInvoiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "app-content-w-header", [["header", "Generate Invoice"]], null, null, null, i2.View_ContentWHeaderComponent_0, i2.RenderType_ContentWHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContentWHeaderComponent, [], { header: [0, "header"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 18, "mat-card", [["class", "toolbar subheader mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i5.MatCard, [[2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 16, "div", [["class", "dx-fieldset"], ["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "dx-select-box", [["displayExpr", "t"], ["label", "Week"], ["labelMode", "floating"], ["valueExpr", "v"]], null, [[null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.selectedWeekValue = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_DxSelectBoxComponent_0, i7.RenderType_DxSelectBoxComponent)), i1.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.DxSelectBoxComponent]), i1.ɵprd(512, null, i10.DxTemplateHost, i10.DxTemplateHost, []), i1.ɵprd(512, null, i10.WatcherHelper, i10.WatcherHelper, []), i1.ɵprd(512, null, i10.IterableDifferHelper, i10.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i10.NestedOptionHost, i10.NestedOptionHost, []), i1.ɵdid(11, 7323648, null, 2, i9.DxSelectBoxComponent, [i1.ElementRef, i1.NgZone, i10.DxTemplateHost, i10.WatcherHelper, i10.IterableDifferHelper, i10.NestedOptionHost, i11.TransferState, i1.PLATFORM_ID], { dataSource: [0, "dataSource"], disabled: [1, "disabled"], displayExpr: [2, "displayExpr"], placeholder: [3, "placeholder"], value: [4, "value"], valueExpr: [5, "valueExpr"] }, { valueChange: "valueChange", onBlur: "onBlur" }), i1.ɵqud(603979776, 1, { buttonsChildren: 1 }), i1.ɵqud(603979776, 2, { itemsChildren: 1 }), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["style", "display: inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "dx-button", [["style", "text-align: left"], ["text", "Generate"], ["type", "default"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.generate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_DxButtonComponent_0, i13.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i10.DxTemplateHost, i10.DxTemplateHost, []), i1.ɵprd(512, null, i10.WatcherHelper, i10.WatcherHelper, []), i1.ɵprd(512, null, i10.NestedOptionHost, i10.NestedOptionHost, []), i1.ɵdid(20, 7323648, null, 0, i14.DxButtonComponent, [i1.ElementRef, i1.NgZone, i10.DxTemplateHost, i10.WatcherHelper, i10.NestedOptionHost, i11.TransferState, i1.PLATFORM_ID], { disabled: [0, "disabled"], icon: [1, "icon"], text: [2, "text"], type: [3, "type"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(21, 0, null, 1, 0, "div", [["class", "content"], ["height", "100%"], ["width", "100%"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Generate Invoice"; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 14).transform(_co.weekDs$)); var currVal_3 = _co.dsLoading; var currVal_4 = "t"; var currVal_5 = (_co.dsLoading ? "Loading..." : "Select week..."); var currVal_6 = _co.selectedWeekValue; var currVal_7 = "v"; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.generating; var currVal_9 = (_co.generating ? "fas fa-spinner fa-spin" : "fas fa-file-pdf"); var currVal_10 = "Generate"; var currVal_11 = "default"; _ck(_v, 20, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_1); }); }
export function View_MealsGenerateInvoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-meals-generate-invoice", [], null, null, null, View_MealsGenerateInvoiceComponent_0, RenderType_MealsGenerateInvoiceComponent)), i1.ɵdid(1, 245760, null, 0, i15.MealsGenerateInvoiceComponent, [i16.LoggerService, i17.PusherService, i18.CommonService, i19.ConfigService, i20.DataSourceService, i21.PushNotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MealsGenerateInvoiceComponentNgFactory = i1.ɵccf("app-meals-generate-invoice", i15.MealsGenerateInvoiceComponent, View_MealsGenerateInvoiceComponent_Host_0, {}, {}, []);
export { MealsGenerateInvoiceComponentNgFactory as MealsGenerateInvoiceComponentNgFactory };
