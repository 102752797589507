import { Component, Input, OnInit } from '@angular/core';
import { utc } from 'moment';
import { Consumer, Note } from '../../../../shared/sdk/models';
import isEmpty from 'lodash-es/isEmpty';

@Component({
  selector: 'app-consumer-notes',
  templateUrl: './consumer-notes.component.html',
  styleUrls: ['./consumer-notes.component.scss'],
})
export class ConsumerNotesComponent implements OnInit {
  @Input() model: Consumer;
  @Input() wrap = true;

  constructor() {}

  ngOnInit() {}

  info(note: Note) {
    const info = note
      ? [
          [note.infoBy ? `by ${note.infoBy}` : '', note.infoDate ? `on ${utc(note.infoDate).format('YYYY/MM/DD')}` : '']
            .filter(chnk => !isEmpty(chnk))
            .join(' '),
          note.followUpDate ? `Follow up on ${utc(note.followUpDate).format('YYYY/MM/DD')}` : '',
        ]
          .filter(chnk => !isEmpty(chnk))
          .join(', ')
      : '';

    return !isEmpty(info) ? `(${info})` : '';
  }
}
