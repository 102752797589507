import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { hAll } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { FacilityView, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-facility',
  templateUrl: './dlg-facility.component.html',
  styleUrls: ['./dlg-facility.component.scss'],
})
export class DlgFacilityComponent implements OnInit {
  @Input() ds: DataSourceOptions | any[] = [];

  @Input() gridColumns: any[] = [
    { dataField: 'legalName', caption: 'Name' },
    { dataField: 'shortname', caption: 'Short' },
    { dataField: 'type', caption: 'Type', groupIndex: 0 },
  ];

  @Input() gridSelection = {
    mode: 'multiple',
    selectAllMode: 'page',
    showCheckBoxesMode: 'always',
    allowSelectAll: false,
  };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgFacilityComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { title: string },
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {}

  get title() {
    return this.data.title;
  }

  ngOnInit() {
    this.buildDataSource();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(FacilityView, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = hAll;
    so.customFilter = {
      where: { name: { neq: 'N/A' } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    this.ds = {
      store: new CustomStore(so),
    } as DataSourceOptions;
  }

  grid_onSelectionChanged(e) {}
}
