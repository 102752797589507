import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { isEmpty } from 'lodash-es';
import uniq from 'lodash-es/uniq';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ALoadOptionsConverter } from '../../../../../shared/classes/loopback-custom-store/generic/load-options-converters/ALoadOptionsConverter';
import { LoopBackStoreOptions } from '../../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { hasAmbTrips, headersAllTenantsAppend } from '../../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerApi,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  MyUtilsApi,
  Signature,
  SignatureApi,
  Vehicle,
  VehicleApi,
} from '../../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../../employee/services/helper.service';
import { SERVICE_TYPE } from '../../../../trip-manifest/classes/enums';

@Component({
  selector: 'app-billing-meals',
  templateUrl: './billing-meals.component.html',
  styleUrls: ['./billing-meals.component.scss'],
})
export class BillingMealsComponent extends ABaseComponent implements OnInit {
  $items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  dso$ = of(new DataSource([]));

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);
  consumerDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  facilitySubDso$: Observable<DataSourceOptions> = of([]);
  consumerSubDso$: Observable<DataSourceOptions> = of([]);
  employeeSubDso$: Observable<DataSourceOptions> = of([]);
  vehicleSubDso$: Observable<DataSourceOptions> = of([]);

  tripDsoMap: Map<number, any> = new Map();
  serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
  validationStates = [
    { v: 'PENDING', t: 'Not Validated' },
    { v: 'VALID', t: 'Valid' },
    { v: 'INVALID', t: 'Invalid' },
    { v: 'ISSUES', t: 'Issues' },

    { v: 'FULL_AUTO_VALIDATED', t: 'Full Auto Validated' },
    { v: 'PARTIAL_AUTO_VALIDATED', t: 'Partial Auto Validated' },
  ];

  ClaimStatuses = [
    'Billed',
    'Authorized',
    'Approved',
    'Processing',
    'Pending',
    'Transmitted',
    'Review',
    'Incomplete',
    'Paid',
    'Denied',
  ];

  grid_stateStoring: any;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  facilityId?: number;
  driverId?: number;
  clientId?: number;

  validationState?: string;
  mci?: string;
  broker?: string;
  serviceType = SERVICE_TYPE.MEALS;
  claimStatus?: string;
  mco?: string;
  hasUnusedTrips?: boolean;
  hasTripAssigned?: boolean;
  existTripIds?: boolean;
  hasClaim?: boolean;

  popover = {};
  // billingStatus?: string;
  // claimState?: string;
  // clientName?: string;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  // @ViewChild('from', {static: true}) fromDateBox: DxDateBoxComponent;
  // @ViewChild('to', {static: true}) toDateBox: DxDateBoxComponent;

  private vehicleDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  private driverDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  constructor(
    protected logger: LoggerService,
    private router: Router,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    private signatureApi: SignatureApi,
    private facilityApi: FacilityApi,
    private utilsApi: MyUtilsApi,
    private consumerApi: ConsumerApi,
    private vehicleApi: VehicleApi,
    private employeeApi: EmployeeApi,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '9df192b4-fe20-433d-a31e-990cb0b8e33b',
    };

    this.dso$ = this.$items$.pipe(
      map(items => {
        const aso: ArrayStoreOptions = {
          key: Signature.getModelDefinition().idName,
          data: items,
        } as ArrayStoreOptions;

        // console.log(recs.length);

        return new DataSource(new ArrayStore(aso));
      }),
      tap(() => {
        if (this.$items$.getValue()) {
          this.facilitySubDso$ = this.buildFacilitySubDataSource();
          this.consumerSubDso$ = this.buildConsumerSubDataSource();
          this.employeeSubDso$ = this.buildEmployeeSubDataSource();
          this.vehicleSubDso$ = this.buildVehicleSubDataSource();
        }
      }),
    );

    this.facilityDso$ = this.buildFacilityDataSource();
    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          this.grid.instance.clearFilter();
          this.grid.instance.clearSelection();
          await this.grid.instance.deselectAll();
          this.$items$.next([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
          ),
        ),
        tap(items => {
          this.$items$.next(items);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          this.grid.instance.clearFilter();
          this.grid.instance.clearSelection();
          await this.grid.instance.deselectAll();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      map(() => {
        const from = this.selectedFromValue;
        const to = this.selectedToValue;

        const fromMoment = from && moment(from);
        const toMoment = to && moment(to).add(1, 'days');

        if (!fromMoment || !toMoment) {
          throw new Error('Period should be defined');
        }

        if (toMoment.diff(fromMoment, 'months') > 1) {
          throw new Error('Period should be less or equal to 1 month');
        }
        const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromIncl: strFrom,
          toExcl: strTo,

          serviceType: this.serviceType,
          mco: this.mco,
          broker: this.broker,
          hasUnusedTrips: this.hasUnusedTrips,
          existTripIds: this.existTripIds,
          hasTrip: this.hasTripAssigned,
          hasClaim: this.hasClaim,
          claimStatus: this.claimStatus,
          mci: this.mci,
          validationState: this.validationState,

          facilityIds: this.facilityId ? [this.facilityId] : [],
          driverIds: this.driverId ? [this.driverId] : [],
          clientIds: this.clientId ? [this.clientId] : [],
        };
      }),

      switchMap(fltr => this.pusher.rpc('GET_CLAIMS', { ...fltr, useRunService: true }, true, headersAllTenantsAppend)),

      switchMap(url =>
        this.http.get(url, {
          responseType: 'json',
          withCredentials: false,
        }),
      ),

      map((recs: any[]) => {
        this.tripDsoMap.clear();

        (recs || []).forEach(r => {
          this.tripDsoMap.set(r.id, r._trips);

          r.getClaimStatus = function () {
            const _self = this;
            return oc(_self)._claim.STATUS() || oc(_self)._claim['Reimbursement Status']();
          }.bind(r);

          r.getLastName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's Last Name"]() ||
              oc(_self)._trip['Member Last Name']() ||
              oc(_self)._trip._lastname() ||
              oc(_self)._client.lastname()
            );
          }.bind(r);

          r.getFirstName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's First Name"]() ||
              oc(_self)._trip['Member First Name']() ||
              oc(_self)._trip._firstname() ||
              oc(_self)._client.firstname()
            );
          }.bind(r);

          r.getMci = function () {
            const _self = this;
            return oc(_self)._trip._mci() || oc(_self)._client.mci();
          }.bind(r);

          r.getBatchCount = function () {
            const _self = this;
            return oc(_self)._batchIds([]).length;
          }.bind(r);

          r.getBatchStatus = function () {
            const _self = this;
            return oc(_self)._currBatchStatus() || oc(_self)._lastBatchStatus();
          }.bind(r);

          r.getServiceType = function () {
            const _self = this;
            return oc(_self).meta.serviceType(SERVICE_TYPE.PARATRANSIT);
          }.bind(r);

          r.getMco = function () {
            const _self = this;
            return oc(_self)._client.mco();
          }.bind(r);

          r.getBroker = function () {
            const _self = this;
            return oc(_self)._client.broker();
          }.bind(r);

          r.getOrigin = function () {
            const _self = this;
            return oc(_self)._rec.o();
          }.bind(r);

          r.getDestination = function () {
            const _self = this;
            return oc(_self)._rec.d();
          }.bind(r);

          {
            const arrTime = oc(r).arrivedTime() ? moment(oc(r).arrivedTime(), 'HH:mm:ss') : null;
            const puTime = oc(r).pickupTime() ? moment(oc(r).pickupTime(), 'HH:mm:ss') : null;
            const doTime = oc(r).dropoffTime() ? moment(oc(r).dropoffTime(), 'HH:mm:ss') : null;

            const lgtcPu = oc(r)._trip.pu_time() ? moment(oc(r)._trip.pu_time(), 'HH:mm') : null;

            r._puDoWarnings = {
              warn1: puTime && doTime && doTime.diff(puTime, 'minutes', true) < 5,
              warn2: puTime && doTime && doTime.diff(puTime, 'minutes', true) > 120,
              warn3: puTime && lgtcPu && Math.abs(lgtcPu.diff(puTime, 'minutes', true)) > 60,
            };
            r._puDoWarnings.title = Object.entries(r._puDoWarnings)
              .filter(([k, v]) => !!v)
              .map(([k, v]) => {
                return {
                  warn1: 'DO and PU diff less than 5 min. ',
                  warn2: 'DO and PU diff more than 65 min. ',
                  warn3: `Broker PU (${oc(r)._trip.pu_time()}) and Driver PU diff more than 60 min. `,
                }[k];
              })
              .join('\n');
          }
        });

        // recs = recs.filter((r) => !this.mco || oc(r)._client.mco() === this.mco);
        return recs;
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  getTripDso(e) {
    // console.log(e);
    return [];
  }

  tripId_selectedItemChange(cellInfo, e) {
    // console.log(cellInfo, e);

    const ds = this.grid.instance.getDataSource();
    const as = ds.store() as ArrayStore;
    as.push([{ type: 'update', data: { _trip: e }, key: cellInfo.key }]);

    // this.grid.instance.repaint();
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildFacilitySubDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [
        ALoadOptionsConverter.inq('id', uniq((this.$items$.getValue() || []).map(r => r.tenantId))),
        // ['id', 'inq', uniq(this.$items$.getValue().map((r) => r.tenantId))],
      ],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerSubDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.consumerId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeSubDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.employeeId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleSubDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.vehicleId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  vehicleData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.vehicleDsMap[id]) {
      self.vehicleDsMap[id] = {};
    }

    if (!self.vehicleDsMap[id].fullInstance) {
      const inst$ = self.vehicleApi.findById(id, {}, headersAllTenantsAppend);

      self.vehicleDsMap[id].fullInstance = inst$;
    }

    return self.vehicleDsMap[id].fullInstance;
  }

  driverData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.driverDsMap[id]) {
      self.driverDsMap[id] = {};
    }

    if (!self.driverDsMap[id].fullInstance) {
      const inst$ = self.employeeApi.findById(
        id,
        {
          include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
        },
        headersAllTenantsAppend,
      );

      self.driverDsMap[id].fullInstance = inst$;
    }

    return self.driverDsMap[id].fullInstance;
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);

    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      // disabled: this.$showFromBroker$.value,
      name: 'buildClaims',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'always',
      options: {
        icon: 'fas fa-folder-plus',
        text: 'Build Billed Claims',
        hint: 'Build Billed Claims (Create fake billed claims)',
        onClick: this.grid_toolbar_buildBilledClaims_onClick.bind(this),
      },
    });
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      // this.logger.log(e);
    }
  }

  grid_onCellPrepared(e) {
    // console.log(e);

    if (e.rowType === 'data' && e.column.dataField === '_trip._tripId') {
      // const items = this.grid.instance.getDataSource().items();
      // const count = items.filter((i) => oc(i)._trip._tripId() === oc(e).data._trip._tripId()).length;
      // if (count > 1) (e.cellElement as HTMLElement).classList.add('cell-trip-warning');

      if (!oc(e).data.vTripId()) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      }
    }
  }

  grid_onSaved(e) {
    // this.grid.instance.repaint();
  }

  grid_toolbar_buildBilledClaims_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());

    const sItems = this.grid.instance.getSelectedRowsData();

    // console.log(this.grid.instance.getDataSource().sort());

    if (sItems.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    if (sItems.some(s => s.vServiceType !== SERVICE_TYPE.MEALS && isEmpty(s.vTripId))) {
      notify('There are some services with no TripID assigned', 'error', 5000);
      return;
    }

    const selectedPairs = sItems.map(s => [s.id, s.vTripId] as [number, string]);

    notify('Billed Claims Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Billed Claims Generation Done!',
      requireInteraction: true,
    };

    const ds = this.grid.instance.getDataSource();

    this.pusher
      .rpc('BUILD_BILLED_CLAIMS', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        switchMap(url =>
          this.http.get(url, {
            responseType: 'json',
            withCredentials: false,
          }),
        ),
        tap((claims: any[]) =>
          ds.store().push(
            claims.map(cl => ({
              type: 'update',
              data: { _claim: cl },
              key: cl._sId,
            })),
          ),
        ),
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(console.log);
  }
}
