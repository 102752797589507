import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AutoDispatchLog } from 'src/app/shared/sdk';

@Component({
  selector: 'app-auto-dispatch-details-tabs',
  templateUrl: './auto-dispatch-details-tabs.component.html',
  styleUrls: ['./auto-dispatch-details-tabs.component.scss'],
})
export class AutoDispatchDetailsTabsComponent implements OnInit {
  selectedIndex = 0;
  tabs: any[] = [
    { title: 'Requested', template: 'accepted' },
    { title: 'Denied', template: 'denied' },
    { title: 'Alternative Alg Re-run', template: 'acceptedAlt' },
  ];

  @Input() message: AutoDispatchLog;

  constructor() {}

  ngOnInit() {}

  repaint() {}
}

