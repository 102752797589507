<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="fullDoc">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Check In</div>
      <ng-container *ngFor="let pair of fullDoc?._ci_picturesEntries">
        <ng-container *ngIf="pair[1]?.length">
          <p [ngStyle]="{ color: descAndReportMap?.ci?.get(pair[0])?.value?.issues?.length ? 'red' : '' }">
            {{ descAndReportMap?.ci?.get(pair[0])?.desc?.title || pair[0] }}:
            {{ descAndReportMap?.ci?.get(pair[0])?.value?.value }}
          </p>
          <div style="display: flex; flex-wrap: wrap">
            <ng-container *ngFor="let pic of pair[1]">
              <div style="flex-basis: 25%">
                <img
                  style="width: 99%; border: 1px solid white; cursor: zoom-in"
                  [src]="pic | pictureLink: 'resize-200' | async | sanitizeUrl"
                  (click)="showPic(pic)" />
              </div>
            </ng-container>
          </div>
          <hr />
        </ng-container>
      </ng-container>

      <div class="dx-fieldset-header">Check Out</div>
      <ng-container *ngFor="let pair of fullDoc?._co_picturesEntries">
        <ng-container *ngIf="pair[1]?.length">
          <p [ngStyle]="{ color: descAndReportMap?.co?.get(pair[0])?.value?.issues?.length ? 'red' : '' }">
            {{ descAndReportMap?.co?.get(pair[0])?.desc?.title || pair[0] }}:
            {{ descAndReportMap?.co?.get(pair[0])?.value?.value }}
          </p>
          <div style="display: flex; flex-wrap: wrap">
            <ng-container *ngFor="let pic of pair[1]">
              <div style="flex-basis: 25%">
                <img
                  style="width: 99%; border: 1px solid white; cursor: zoom-in"
                  [src]="pic | pictureLink: 'resize-200' | async | sanitizeUrl"
                  (click)="showPic(pic)" />
              </div>
            </ng-container>
          </div>
          <hr />
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</app-details-tab>

<dx-popup
  [width]="'100%'"
  [height]="'100%'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupVisible">
  <div
    *dxTemplate="let data of 'content'"
    style="cursor: zoom-out; background-position: center; background-repeat: no-repeat; background-size: contain"
    [style.background-image]="'url(\'' + (currentPic | pictureLink: '':true | async) + '\')' | sanitizeStyle"
    (click)="popupVisible = false"></div>
</dx-popup>
