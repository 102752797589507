import * as tslib_1 from "tslib";
import { get } from 'lodash-es';
import moment from 'moment';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { DriverSchedule, DriverScheduleApi, MyUtils, MyUtilsApi } from 'src/app/shared/sdk';
const TIMEZONE = 'America/New_York';
export class HelperService {
    constructor(dss) {
        this.dss = dss;
        this.getFullAddress = a => (a && `${a.street}, ${a.city}, ${a.state}, ${a.zip}`) || '';
        this.getTo = a => (a.location ? `${a.location.lat}, ${a.location.lng}` : this.getFullAddress(a));
        this.getTravelDuration = ({ startFinish, from, to, selectedDate, tripTime }) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const date = moment(selectedDate).toISOString(true).split('T').shift();
            let time = moment.tz(`${date} ${tripTime}`, TIMEZONE).unix();
            if (time <= moment().unix()) {
                const week = 7 * 60 * 60 * 24;
                time = time + week + Math.floor((moment().unix() - time) / week) * week; // if time in the past, set time with coming weekday
            }
            const type = startFinish === 'start' ? 'arrive' : 'departure';
            let fromTo = [from, to];
            if (startFinish !== 'start')
                fromTo.reverse();
            const { routes } = yield this.dss
                .getApi(MyUtils)
                .directions(fromTo[0], fromTo[1], time, type)
                .toPromise();
            return Math.ceil((get(routes, '[0].legs[0].duration.value') || 0) / 60);
        });
    }
    isGoodToRecalculateStartTime(data) {
        const tripAddr = get(data, `firstTripConsumer.person.contact.addresses[0]`);
        const tripTime = get(data, `manifest.firstTrip.t`);
        const startLocationCoords = get(data, `schedule.startLocationCoords`);
        if (!tripAddr || !tripTime || !startLocationCoords)
            return '';
        const to = this.getTo(tripAddr);
        const startTripTime = data.schedule.startTripTime;
        const startTripLocation = data.schedule.startTripLocation;
        const titles = [];
        if (startTripTime !== tripTime)
            titles.push(`First Pickup Time changed (${startTripTime ? `${moment(startTripTime, 'HH:mm:ss').format('hh:mm A')} -> ` : ''}${moment(tripTime, 'HH:mm:ss').format('hh:mm A')})`);
        if (startTripLocation !== to) {
            const fromAddr = data.schedule.startTripLocationAddress;
            const toAddr = this.getFullAddress(tripAddr);
            titles.push(`First Pickup Location changed (${(fromAddr && `${fromAddr} -> `) || ''}${toAddr})`);
        }
        let ttls = titles.join('\n');
        const cName = `${get(data, `firstTripConsumer.person.firstname`)} ${get(data, `firstTripConsumer.person.lastname`)}`;
        if (ttls)
            ttls = `Required to recalculate Start Time because of Manifest changes for Client ${cName}:\n${ttls}`;
        const { lat, lng } = startLocationCoords;
        const startLocation = `${lat}, ${lng}`;
        if (startLocation !== data.schedule.startLocationCalculated)
            ttls = 'Required to recalculate Start Time because of Start Location Address changes';
        return ttls;
    }
    updateStartTime(data, tripDate, startPrepareDuration) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const startTripConsumerName = `${get(data, `firstTripConsumer.person.firstname`)} ${get(data, `firstTripConsumer.person.lastname`)}`;
            const tripAddr = get(data, `firstTripConsumer.person.contact.addresses[0]`);
            const startTripTime = get(data, `manifest.firstTrip.t`);
            const startLocationCoords = get(data, `schedule.startLocationCoords`);
            const { lat, lng } = startLocationCoords;
            const startTripLocation = this.getTo(tripAddr);
            const startTripLocationAddress = this.getFullAddress(tripAddr);
            const startFinish = 'start';
            const from = `${lat}, ${lng}`;
            const p = { startFinish, from, to: startTripLocation, selectedDate: tripDate, tripTime: startTripTime };
            const startTravelDuration = yield this.getTravelDuration(p);
            const duration = startTravelDuration + startPrepareDuration;
            const time = moment(startTripTime, 'HH:mm:ss').subtract(duration, 'minutes');
            const startTime = time.minutes(Math.floor(time.minutes() / 10) * 10).format('HH:mm:ss');
            const id = data.schedule.id;
            const d = Object.assign({ startTime, startTripTime, startTripLocation, startTripLocationAddress, startTravelDuration }, { startTripConsumerName, startLocationCalculated: from, startTimeCalculatedAt: new Date() });
            return yield this.dss.getApi(DriverSchedule).patchAttributes(id, d).toPromise();
        });
    }
}
