<div>
<!--  [stateStoring]="grid_stateStoring"-->
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  >
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-export [enabled]="true" fileName="CTS-Broker-Trips"></dxo-export>

    <dxo-search-panel [visible]='false'></dxo-search-panel>

    <!-- region Columns -->

    <dxi-column caption="Broker" dataField="_broker" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Date"
      dataField="_date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Trip Number" dataField="_tripId" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination Name"
      dataField="Destination Name"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination Address"
      dataField="Destination Address"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination City"
      dataField="Destination City"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination State"
      dataField="Destination State"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination Zip Code"
      dataField="Destination Zip Code"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Destination Phone Number"
      dataField="Destination Phone Number"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member Last Name"
      dataField="Member Last Name"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member First Name"
      dataField="Member First Name"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Medicaid Number" dataField="_mci" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Member Number" dataField="Member Number" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member Date of Birth"
      dataField="Member Date of Birth"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Special Needs" dataField="Special Needs" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Instructions-Directions"
      dataField="Instructions-Directions"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Appointment Date"
      dataField="Appointment Date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Appointment Time"
      dataField="Appointment Time"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Member Phone Number"
      dataField="Member Phone Number"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <!-- -->

    <dxi-column
      caption="MODE-WC-AMB-STR"
      dataField="MODE-WC-AMB-STR"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Number of Car Seats Required"
      dataField="Number of Car Seats Required"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="ESCORT" dataField="ESCORT Y-N" dataType="boolean" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Attendant" dataField="Attendant Y-N" dataType="boolean" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Trip Mileage" dataField="Trip Mileage" dataType="number" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Trip Cost"
      dataField="Trip Cost"
      dataType="number"
      *ngIf="isSU$ | async"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Inbound-Outbound"
      dataField="Inbound-Outbound"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <!-- -->

    <dxi-column
      caption="PICKUP LOCATION"
      dataField="PICKUP LOCATION"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Pickup Address"
      dataField="Pickup Address"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Pickup City" dataField="Pickup City" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Pickup State" dataField="Pickup State" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Pickup Zip Code"
      dataField="Pickup Zip Code"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Imported Date"
      dataField="_ctime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <dxi-column
      caption="Updated Date"
      dataField="_rtime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <!-- -->

    <dxi-column
      caption="Facility"
      dataType="string"
      cellTemplate="facilityCellTpl"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column
      caption="Assigned"
      dataType="boolean"
      cellTemplate="assignedCellTpl"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"></dxi-column>

    <!--  endregion  -->

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'facilityCellTpl'">
      {{ cellInfo.data._facility }}
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'assignedCellTpl'">
      <dx-check-box [value]="cellInfo.data._assigned" [readOnly]="true"></dx-check-box>
    </ng-container>
  </dx-data-grid>
</div>
