import { LoadOptions } from 'devextreme/data/load_options';
import isString from 'lodash-es/isString';
import isEmpty from 'lodash-es/isEmpty';
import isDate from 'lodash-es/isDate';
import moment, { utc } from 'moment';
import { ALoadOptionsConverter } from './ALoadOptionsConverter';
import escapeRegExp from 'lodash-es/escapeRegExp';
import { oc } from 'ts-optchain';
import isArray from 'lodash-es/isArray';
import fromPairs from 'lodash-es/fromPairs';

export class MongoLoadOptionsConverter extends ALoadOptionsConverter {
  protected operatorMap = {
    and: '$and',
    or: '$or',
  };

  constructor(options = {}) {
    super(options);
  }

  convert(loadOptions: LoadOptions): any {
    console.log('Mongo LoadOptions:', loadOptions);

    let order: any;
    let where: any;

    if (loadOptions.sort && !isEmpty(loadOptions.sort)) {
      order = (loadOptions.sort as Array<{ desc: boolean; selector: string }>)
        .filter(({ desc, selector }) => isString(selector))
        .map(({ desc, selector }) => ({ [selector]: desc ? -1 : 1 }))
        .reduce((o, rule) => ({ ...o, ...rule }), {});
    }

    if (loadOptions.filter && !isEmpty(loadOptions.filter)) {
      where = this.loadOptionsFilterToWhere(loadOptions.filter as any[]);
      // console.log(where);
    }

    //

    const filter: any = {
      ...(oc(loadOptions).skip() ? { skip: oc(loadOptions).skip() } : {}),
      ...(oc(loadOptions).take() ? { limit: oc(loadOptions).take() } : {}),
    };

    if (where && !isEmpty(where)) {
      filter.where = where;
    }

    if (order && !isEmpty(order)) {
      filter.sort = order;
    }

    if (!isEmpty(loadOptions.select)) {
      let fields = loadOptions.select;
      if (isString(fields)) fields = [fields];
      if (isArray(fields)) fields = fromPairs(fields.map(f => [f, true]));
      filter.fields = fields;
    }

    // console.log('loadOptions To Mongo:', this.loadOptions, filter);

    return !isEmpty(filter) ? filter : {};
  }

  singleFilterToWhereRule(filter: [string | null, string, any], not?: boolean): any {
    if (!filter || !this.isSingleFilterRule(filter)) {
      throw new Error(`Not valid filter rule: ${JSON.stringify(filter)}`);
    }

    const mapValueFn = (_operator, _value) => {
      switch (_operator) {
        case 'startswith':
          return `^${escapeRegExp(_value)}.*`;
        case 'endswith':
          return `.*${escapeRegExp(_value)}$`;
        case 'contains':
          return `.*${escapeRegExp(_value)}.*`;
        case 'notcontains':
          return `.*${escapeRegExp(_value)}.*`;
      }

      if (isDate(_value) || (false && isString(_value) && _value.length && moment(_value, true).isValid())) {
        if (
          moment(_value).toDate().getHours() === 0 &&
          moment(_value).toDate().getMinutes() === 0 &&
          moment(_value).toDate().getSeconds() === 0
        ) {
          _value = {
            $date: {
              v: moment(_value).utc(true).toDate().toISOString(),
            },
          };
        } else if (
          utc(_value).utc().get('hours') === 0 &&
          utc(_value).utc().get('minutes') === 0 &&
          utc(_value).utc().get('seconds') === 0
        ) {
          _value = {
            $date: {
              v: utc(_value).toDate().toISOString(),
            },
          };
        } else {
          _value = {
            $date: {
              // v: utc(_value).toDate().toISOString(),
              v: utc(_value).toDate().toISOString(),
              // o: utc(_value).toDate().getTimezoneOffset(),
            },
          };
        }
      }

      return _value;
    };

    // simple filter rule
    const operatorMap = {
      '=': '$eq',
      '<>': '$ne',
      '>': '$gt',
      '>=': '$gte',
      '<': '$lt',
      '<=': '$lte',
    };
    Object.assign(operatorMap, {
      startswith: '$regex',
      endswith: '$regex',
      contains: '$regex',
      notcontains: '$regex',
    });

    // tslint:disable-next-line:prefer-const
    let [field, operator, value] = filter;

    if (isString(value)) {
      value = value.toString().replace(/^\s+/g, '');
      value = value.toString().replace(/\s+$/g, '');
    }

    const [newField, newOperator, newValue] = [field, operatorMap[operator] || operator, mapValueFn(operator, value)];

    let where: any = {};

    if (['notcontains'].includes(operator)) {
      where = { [newField]: { $not: { [newOperator]: newValue, $options: 'i' } } };
    } else if (['$regex'].includes(newOperator)) {
      where = { [newField]: { [newOperator]: newValue, $options: 'i' } };
    } else {
      where = { [newField]: { [newOperator]: newValue } };
    }

    if (not) {
      const [k, v] = Object.entries(where)[0];
      where = { [k]: { $not: v } };
    }

    return where;
  }
}
