import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { FuelRefillApi } from '../../../sdk';
import isEmpty from 'lodash-es/isEmpty';
import { headersAllTenantsAppend } from '../../../classes/utils/utils';
import { HttpHeaders } from '@angular/common/http';

@Pipe({
  name: 'frReceiptImgLink',
})
export class FrReceiptImgLinkPipe implements PipeTransform {
  constructor(private fuelRefillApi: FuelRefillApi) {}

  transform(filename: string, convert?: string, bigLoader = false): Observable<string> {
    function customHeaders(headers: HttpHeaders) {
      return headersAllTenantsAppend(headers).append('ngsw-bypass', '1');
    }

    return of(filename).pipe(
      filter(fname => !isEmpty(fname)),
      distinctUntilChanged(),
      switchMap(fname =>
        this.fuelRefillApi.getReceiptLink(fname, convert, customHeaders).pipe(
          // map(url => url + '&ngsw-bypass'),
          catchError(err => of('/assets/images/error-img.png')),
          startWith(bigLoader ? '/assets/images/big-loader.gif' : '/assets/images/loader.gif'),
        ),
      ),
      startWith(''),
    );
  }
}
