import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSourceOptions } from 'devextreme/data/data_source';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AcquisitionRelatedContact } from 'src/app/shared/sdk/models/AcquisitionRelatedContact';
import { AcquisitionRelatedContactApi } from 'src/app/shared/sdk/services/custom/AcquisitionRelatedContact';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { FacilityView, LoopBackFilter, Person, Vehicle } from '../../../../shared/sdk/models';
import { FacilityViewApi, LoggerService, PersonApi, VehicleApi } from '../../../../shared/sdk/services/custom';
import { VEHICLE_STATUSES } from 'src/app/modules/fleet/classes/const';

@Component({
  selector: 'app-vehicle-add-form',
  templateUrl: './vehicle-add-form.component.html',
  styleUrls: ['./vehicle-add-form.component.scss'],
})
export class VehicleAddFormComponent extends ABaseFormComponent<Vehicle> implements OnInit {
  SUB_STATUSES = ['Universal', 'Emergency Only', 'Meals Only'];

  $windowTitle$ = new BehaviorSubject<string>('Change Vehicle Status to “In Maintenance”');

  resetableForm = false;
  collapsibleForm = false;

  vehicleDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    protected helper: FormHelperService<Vehicle>,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  vehicle_onSelectionChanged(e) {
    this.modelId = e.selectedItem.id;
  }

  vehicle_displayExpression(v: Vehicle | undefined) {
    return v
      ? `${v.internalId} - ${v.make} ${v.model}${(v.wheelchairAccessible && ' - WC BUS') || ''}${
          (v.passengerCapacity && ` - ${v.passengerCapacity} Passengers`) || ''
        }`
      : null;
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public async validateAsync(): Promise<boolean> {
    return !!this.modelId;
  }

  protected async afterSetFormValuesAsync(model, form: FormGroup) {}

  protected async afterSubmittedAsync(data: any, id: string | number): Promise<void> {}

  protected async beforeSubmittingAsync(data: any): Promise<void> {
    await super.beforeSubmittingAsync(data);
    data.status = 'IN MAINTENANCE';
    data.subStatus = VEHICLE_STATUSES['IN MAINTENANCE'].subStatuses[0];
  }

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      status: [],
      subStatus: [],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }

  private buildVehicleDataSource() {
    return this.dss.getApi<VehicleApi>(Vehicle).find<Vehicle>({
      where: { state: 'ACTIVE', status: { neq: 'IN MAINTENANCE' } },
      order: ['internalId ASC'],
    });
  }
}

