import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DxTabPanelComponent } from 'devextreme-angular/ui/tab-panel';
import { Observable, of } from 'rxjs';
import { Vehicle } from 'src/app/shared/sdk';

@Component({
  selector: 'app-vehicle-details-tabs',
  templateUrl: './vehicle-details-tabs.component.html',
  styleUrls: ['./vehicle-details-tabs.component.scss'],
})
export class VehicleDetailsTabsComponent implements OnInit {
  @Input() templateFormTop?: TemplateRef<any>;
  @Input() templateFormBottom?: TemplateRef<any>;
  @Input() extraFields: any;
  selectedIndex = 0;
  tabs: any[] = [
    { title: 'Notes', template: 'notes' },
    { title: 'Usage History', template: 'history' },
    { title: 'Locations', template: 'locations' },
  ];

  @Input() vehicle: Vehicle;
  @Input() markers: any[];

  constructor() {}

  ngOnInit() {}

  repaint() {}
}

