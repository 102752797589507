<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">New Password</div>
      <div class="dx-field-value">
        <dx-text-box mode="password" formControlName="password">
          <dx-validator name="Password">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <!-- /// -->

    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <p class="alert-danger" *ngIf="error">
      {{ error }}
    </p>
  </div>
</dx-validation-group>
