import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { Employee, EmployeeView, LoggerService, LoopBackAuth, Person } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-employee-grid',
  templateUrl: './employee-grid.component.html',
  styleUrls: ['./employee-grid.component.scss'],
  providers: [HelperService],
})
export class EmployeeGridComponent extends ABaseComponent implements OnInit {
  sexes = PERSON_SEXES;
  types = EMPLOYEE_TYPES;
  statuses = EMPLOYEE_STATUSES;

  dso: DataSourceOptions;

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<EmployeeView[]> = new EventEmitter<EmployeeView[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('8745a3e7-d193-4dda-a6a4-1b243289782a');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b64648cd-7c24-4455-a355-7ea404328305',
    };

    const dso = dss.getDataSourceOptions(Employee);
    const onBASE = this.auth.getCurrentTenant() === 7;
    if (!onBASE) dso.filter = ['status', '=', 'ACTIVE'];
    this.dso = dso;

    this.dso.postProcess = (data: Array<any>) => {
      data.forEach(rec => (rec._anotherTenant = this.common.auth.getCurrentTenant() !== rec.tenantId));
      return data;
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent
      .pipe(
        tap(modelName => {
          if ([Employee.getModelName(), Person.getModelName()].includes(modelName)) {
            if (this.grid) {
              this.grid.instance.refresh();
            }
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newEmployee',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-user-plus',
        text: 'New employee',
        hint: 'Create new employee',
        onClick: this.grid_toolbar_newEmployee_onClick.bind(this),
      },
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const title = `Edit: ${this.helper.displayExpr(e.data)}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Employee,
      FormComponentClass: EmployeeFormComponent,
      title,
    });
  }

  grid_toolbar_newEmployee_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Employee,
      FormComponentClass: EmployeeFormComponent,
    });
  }

  grid_onCellPrepared(e) {
    // console.log(e);

    if (e.rowType === 'data') {
      if (e.data._anotherTenant) {
        (e.cellElement as HTMLElement).style.color = 'darkgray';
      }
    }
  }

  isEditDeleteVisible(e) {
    return !e.row.data._anotherTenant;
  }
}
