import * as tslib_1 from "tslib";
import { SwPush } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class PushNotificationsService {
    constructor(swPush) {
        this.swPush = swPush;
        this.permission = this.isSupported ? 'default' : 'denied';
    }
    get isSupported() {
        return 'Notification' in window;
    }
    get isGranted() {
        return this.permission === 'granted';
    }
    requestPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const self = this;
            if (self.isSupported) {
                self.permission = yield Notification.requestPermission();
            }
        });
    }
    create(title, options) {
        const self = this;
        return new Observable(obs => {
            if (!self.isSupported) {
                console.log('Notifications are not available in this environment');
                return obs.complete();
            }
            if (!self.isGranted) {
                console.log("The user hasn't granted you permission to send push notifications");
                return obs.complete();
            }
            const _notify = new Notification(title, options);
            _notify.onshow = e => obs.next({
                notification: _notify,
                event: e,
            });
            _notify.onclick = e => obs.next({
                notification: _notify,
                event: e,
            });
            _notify.onerror = e => obs.error({
                notification: _notify,
                event: e,
            });
            _notify.onclose = () => obs.complete();
        });
    }
    generateNotification(...args) {
        const self = this;
        args.forEach(({ title, opts }) => {
            self.create(title, opts).pipe(tap(console.log)).subscribe();
        });
    }
    error(error, title) {
        return this.generateNotification({
            title: title || 'Error',
            opts: {
                icon: '/assets/images/error-256.png',
                badge: '/assets/images/error-256.png',
                body: error.message,
                requireInteraction: true,
            },
        });
    }
    done(body, title) {
        return this.generateNotification({
            title: title || 'Done',
            opts: {
                icon: '/assets/images/done-256.png',
                badge: '/assets/images/done-256.png',
                body,
                requireInteraction: true,
            },
        });
    }
}
PushNotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationsService_Factory() { return new PushNotificationsService(i0.ɵɵinject(i1.SwPush)); }, token: PushNotificationsService, providedIn: "root" });
