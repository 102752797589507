<dx-load-panel
  [position]="{ of: target }"
  [visible]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="false"
  [closeOnOutsideClick]="false">
</dx-load-panel>

<div #target class="panel-container" style="margin: 1em">
  <dx-toolbar [items]="toolbarItems"></dx-toolbar>

  <ng-container *ngIf="model; else elseBlock">
    <ng-content></ng-content>
  </ng-container>

  <ng-template #elseBlock>
    <p>{{ errorMessage || 'Not selected' }}</p>
  </ng-template>
</div>
