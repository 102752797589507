/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trips-audit-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../trips-audit-grid/trips-audit-grid.component.ngfactory";
import * as i3 from "../../services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i5 from "../../../trip-manifest/services/helper.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../../shared/sdk/services/custom/TripManifest";
import * as i8 from "../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../shared/modules/my-common/services/config.service";
import * as i10 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i11 from "../../../employee/services/helper.service";
import * as i12 from "../../../consumer/services/helper.service";
import * as i13 from "../../../vehicle/services/helper.service";
import * as i14 from "../../../../shared/modules/my-common/services/pusher.service";
import * as i15 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i16 from "../trips-audit-grid/trips-audit-grid.component";
import * as i17 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i18 from "@angular/material/dialog";
import * as i19 from "../../../../shared/sdk/services/custom/MyUtils";
import * as i20 from "../../../../shared/sdk/storage/storage.swaps";
import * as i21 from "../../../../shared/sdk/services/custom/Facility";
import * as i22 from "../../../../shared/sdk/services/core/auth.service";
import * as i23 from "./trips-audit-list.component";
var styles_TripsAuditListComponent = [i0.styles];
var RenderType_TripsAuditListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripsAuditListComponent, data: {} });
export { RenderType_TripsAuditListComponent as RenderType_TripsAuditListComponent };
export function View_TripsAuditListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-trips-audit-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TripsAuditGridComponent_0, i2.RenderType_TripsAuditGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.VehicleApi]), i1.ɵprd(512, null, i5.HelperService, i5.HelperService, [i6.HttpClient, i7.TripManifestApi, i8.CommonService, i9.ConfigService, i10.DataSourceService, "Window", i11.HelperService, i12.HelperService, i13.HelperService, i14.PusherService, i15.UploadHelperService]), i1.ɵdid(4, 245760, [[1, 4]], 0, i16.TripsAuditGridComponent, [i9.ConfigService, i10.DataSourceService, i3.HelperService, i5.HelperService, i17.GridHelperService, i18.MatDialog, i19.MyUtilsApi, i20.InternalStorage, i21.FacilityApi, i22.LoopBackAuth], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_TripsAuditListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-trips-audit-list", [], null, null, null, View_TripsAuditListComponent_0, RenderType_TripsAuditListComponent)), i1.ɵdid(1, 114688, null, 0, i23.TripsAuditListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripsAuditListComponentNgFactory = i1.ɵccf("app-trips-audit-list", i23.TripsAuditListComponent, View_TripsAuditListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { TripsAuditListComponentNgFactory as TripsAuditListComponentNgFactory };
