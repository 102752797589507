/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-drop-down-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/modules/ui/components/drop-down-grid-new/drop-down-grid-new.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../shared/modules/ui/components/drop-down-grid-new/drop-down-grid-new.component";
import * as i5 from "../../../../shared/sdk/services/custom/logger.service";
import * as i6 from "./employee-drop-down-grid.component";
import * as i7 from "../../services/helper.service";
import * as i8 from "../../../../shared/sdk/services/custom/Employee";
import * as i9 from "../../../../shared/sdk/services/custom/EmployeeView";
import * as i10 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i11 from "../../../../shared/modules/my-common/services/config.service";
import * as i12 from "../../../../shared/modules/my-common/services/common.service";
import * as i13 from "../../../../shared/sdk/services/custom/Config";
import * as i14 from "../../../../shared/sdk/services/custom/Facility";
import * as i15 from "../../../../shared/sdk/services/custom/MyFile";
import * as i16 from "../../../../shared/modules/my-common/services/datasource.service";
var styles_EmployeeDropDownGridComponent = [i0.styles];
var RenderType_EmployeeDropDownGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeDropDownGridComponent, data: {} });
export { RenderType_EmployeeDropDownGridComponent as RenderType_EmployeeDropDownGridComponent };
export function View_EmployeeDropDownGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-drop-down-grid-new", [], null, [[null, "valueChange"], [null, "valueChanged"], [null, "focusout"], [null, "grid_cellPrepared"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.valueChange.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChanged" === en)) {
        var pd_2 = (_co.valueChanged.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("focusout" === en)) {
        var pd_3 = (_co.focusOut.emit($event) !== false);
        ad = (pd_3 && ad);
    } if (("grid_cellPrepared" === en)) {
        var pd_4 = (_co.grid_cellPrepared.emit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_DropDownGridNewComponent_0, i2.RenderType_DropDownGridNewComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DropDownGridNewComponent]), i1.ɵdid(2, 114688, null, 0, i4.DropDownGridNewComponent, [i5.LoggerService], { dataSource: [0, "dataSource"], remoteOperations: [1, "remoteOperations"], valueExpr: [2, "valueExpr"], displayExpr: [3, "displayExpr"], placeholder: [4, "placeholder"], showClearButton: [5, "showClearButton"], width: [6, "width"], grid_columns: [7, "grid_columns"], popup_width: [8, "popup_width"], popup_height: [9, "popup_height"], disabled: [10, "disabled"], value: [11, "value"] }, { valueChange: "valueChange", valueChanged: "valueChanged", grid_cellPrepared: "grid_cellPrepared" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.remoteOperations; var currVal_2 = "id"; var currVal_3 = _co.helper.displayExpr; var currVal_4 = "Select Employee..."; var currVal_5 = _co.showClearButton; var currVal_6 = _co.width; var currVal_7 = _co.grid_columns; var currVal_8 = 350; var currVal_9 = 300; var currVal_10 = _co.disabled; var currVal_11 = _co.value; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_EmployeeDropDownGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-employee-drop-down-grid", [], null, null, null, View_EmployeeDropDownGridComponent_0, RenderType_EmployeeDropDownGridComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.EmployeeDropDownGridComponent]), i1.ɵprd(512, null, i7.HelperService, i7.HelperService, [i8.EmployeeApi, i9.EmployeeViewApi, i10.VehicleApi, i11.ConfigService, i12.CommonService, i13.ConfigApi, i14.FacilityApi, i15.MyFileApi, i16.DataSourceService]), i1.ɵdid(3, 114688, null, 0, i6.EmployeeDropDownGridComponent, [i16.DataSourceService, i11.ConfigService, i7.HelperService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var EmployeeDropDownGridComponentNgFactory = i1.ɵccf("app-employee-drop-down-grid", i6.EmployeeDropDownGridComponent, View_EmployeeDropDownGridComponent_Host_0, { dataSource: "dataSource", remoteOperations: "remoteOperations", grid_columns: "grid_columns", disabled: "disabled", invalid: "invalid", width: "width", value: "value", filter: "filter", showClearButton: "showClearButton" }, { valueChange: "valueChange", valueChanged: "valueChanged", focusOut: "focusOut", grid_cellPrepared: "grid_cellPrepared" }, []);
export { EmployeeDropDownGridComponentNgFactory as EmployeeDropDownGridComponentNgFactory };
