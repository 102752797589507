import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { oc } from 'ts-optchain';
import { ServerError } from '../../../classes/ServerUnavailableError';
import { ErrorHandler as SdkErrorHandler } from '../../../sdk/services/core/index';

export class ExtSdkErrorHandler extends SdkErrorHandler implements ErrorHandler {
  handleError(errorResponse): any {
    console.log(errorResponse);
    // if (errorResponse.status === 0 && errorResponse.type === ResponseType.Error) {
    if (oc(errorResponse).status() === 0) {
      return throwError(ServerError.ServerUnavailableError());
    } else {
      // console.error(oc(errorResponse).error());

      const err = new ServerError(
        oc(errorResponse).error.error.message() ||
          oc(errorResponse).error.message() ||
          oc(errorResponse).error.error() ||
          oc(errorResponse).error() ||
          'Server error',
        oc(errorResponse).error.error.statusCode() || oc(errorResponse).error.statusCode(),
        oc(errorResponse).error.error.code() || oc(errorResponse).error.code(),
      );
      return throwError(err || 'Server error');
    }
  }
}
