import jQuery from 'jquery';
import isArray from 'lodash-es/isArray';
import { from, Observable } from 'rxjs';

/**
 * Cast RxJs Observable to Jquery Promise
 */
export function observableAsDeferred(observable: Observable<any>): JQuery.Deferred<any> {
  return promiseAsDeferred(observable.toPromise());
}

/**
 * Cast Native Promise to Jquery Promise
 */
export function promiseAsDeferred(promise: PromiseLike<any>): JQuery.Deferred<any> {
  const deferred: JQuery.Deferred<any> = jQuery.Deferred();
  promise.then(
    args => deferred.resolve.apply(deferred, isArray(args) ? args : [args]),
    args => deferred.reject.apply(deferred, isArray(args) ? args : [args]),
  );

  return deferred;
}

/**
 * Cast Jquery Promise to Native Promise
 */
export function deferredAsPromise(promise: JQuery.Promise<any>): Promise<any> {
  return new Promise((resolve, reject) =>
    promise.then(
      (...args) => resolve(args),
      (...args) => reject(args),
    ),
  );
}

/**
 * Cast Jquery Promise to RxJs Observable
 */
export function deferredAsObservable(promise: JQuery.Promise<any>): Observable<any> {
  return from(deferredAsPromise(promise));
}

/**
 * setTimeout in promise style.
 */
export async function delayAsync(ms: number = 0): Promise<void> {
  await new Promise(resolve => setTimeout(() => resolve(), ms));
}
