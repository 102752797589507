import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//
import { MyUser } from '../../../../shared/sdk';

@Component({
  selector: 'app-signout-form',
  templateUrl: './signout-form.component.html',
  styleUrls: ['./signout-form.component.scss'],
})
export class SignoutFormComponent implements OnInit {
  @Input() isSubmitting: boolean;
  @Input() signedUser: MyUser;
  @Output() mySubmit = new EventEmitter<MyUser>();

  constructor() {}

  private _error: any = null;
  get error(): string {
    return (this._error && this._error.message) || this._error;
  }

  @Input()
  set error(value) {
    this._error = value;
  }

  ngOnInit() {}

  onLogout(): void {
    this.mySubmit.emit(this.signedUser);
  }
}
