import { Component, OnInit, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import {
  Config,
  ConfigApi,
  DayService,
  DayServiceApi,
  Facility,
  FacilityApi,
  LoggerService,
} from '../../../../shared/sdk';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { discretizeDates } from '../../../../shared/classes/utils/time.utils';
import isEmpty from 'lodash-es/isEmpty';
import { oc } from 'ts-optchain';
import sortBy from 'lodash-es/sortBy';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-day-services',
  templateUrl: './day-services.component.html',
  styleUrls: ['./day-services.component.scss'],
})
export class DayServicesComponent extends ABaseComponent implements OnInit {
  $items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  dso$: Observable<any> = of(new DataSource([]));

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();
  dayStatus = 'Any';
  facilityId?: number;
  dates: any[];

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '804c302d-7cdc-4777-833b-9257331625f1',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
    // this.dso$ = this.buildDataSource();
    this.dso$ = this.$items$.pipe(
      map(items => {
        const aso: ArrayStoreOptions = {
          key: DayService.getModelDefinition().idName,
          data: items,
        } as ArrayStoreOptions;

        // console.log(recs.length);

        return new DataSource(new ArrayStore(aso));
      }),
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.$items$.next([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
          ),
        ),
        tap(items => {
          this.$items$.next(items);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return combineLatest([
      this.dss
        .getApi<FacilityApi>(Facility)
        .find<Facility>({
          where: { type: { inq: ['ADC', 'MEALS', 'BASE'] } },
          order: ['type', 'name'],
        })
        .pipe(
          switchMap(async facilities => {
            const configs = await this.dss
              .getApi<ConfigApi>(Config)
              .find<Config>({}, headersAllTenantsAppend)
              .toPromise();

            for (const f of facilities) {
              (f as any)._config = configs.find(c => c.tenantId === f.id) || {};
            }
            return facilities;
          }),
        ),
      of(true).pipe(
        map(() => {
          const from = this.selectedFromValue;
          const to = this.selectedToValue;

          const fromMomentInc = from && moment(from);
          const toMomentExcl = to && moment(to).add(1, 'days');

          if (!fromMomentInc || !toMomentExcl) {
            throw new Error('Period should be defined');
          }

          if (toMomentExcl.diff(fromMomentInc, 'months', true) > 1) {
            throw new Error('Period should be less or equal to 1 month');
          }

          const strFromInc = fromMomentInc && fromMomentInc.format('YYYY-MM-DD');
          const strToExcl = toMomentExcl && toMomentExcl.format('YYYY-MM-DD');

          this.dates = discretizeDates(strFromInc, strToExcl);

          return { fromInc: strFromInc, toExcl: strToExcl };
        }),
        switchMap(({ fromInc, toExcl }) =>
          this.dss.getApi<DayServiceApi>(DayService).find<DayService>(
            {
              where: {
                and: [
                  { date: { gte: fromInc } },
                  { date: { lt: toExcl } },
                  ...(this.facilityId ? [{ tenantId: this.facilityId }] : []),
                  ...(this.dayStatus && this.dayStatus !== 'Any'
                    ? [
                        {
                          marker: {
                            Close: 'CLOSE_DAY',
                            Open: 'OPEN_DAY',
                          }[this.dayStatus],
                        },
                      ]
                    : [{ marker: { inq: ['CLOSE_DAY', 'OPEN_DAY'] } }]),
                ],
              },
            },
            headersAllTenantsAppend,
          ),
        ),
      ),
    ]).pipe(
      map(([facilities, items]) => {
        const _map = new Map();
        const fMap = new Map(facilities.map(f => [f.id, f]));

        items.forEach(i => {
          // const key = i.tenantId + '|' + i.marker;
          const key = i.tenantId;

          if (!_map.has(key)) {
            _map.set(key, {
              id: key,
              tenantId: i.tenantId,
              facility: fMap.get(i.tenantId),
            });
          }

          const o = _map.get(key);
          o[i.date] = {
            date: i.date,
            marker: i.marker,
            flag: i.flag,
            value: i.value,
            meta: i.meta,
          };
        });

        return sortBy(Array.from(_map.values()), 'facility.name');
      }),
      takeUntil(this.$onDestroy$),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'MEALS', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'name' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      // console.log(e);

      const date = oc(e).column.dataField();
      const marker = oc(e).value.marker();
      const flag = oc(e).value.flag();
      const daysOpen = oc(e).data.facility._config.data.adcDaysOpen({});

      if (moment(date, 'YYYY-MM-DD', true).isValid() && daysOpen[moment(date).format('dd').toLowerCase()] !== true) {
        (e.cellElement as HTMLElement).style.backgroundColor = 'LightSlateGrey';
      }

      if (!isEmpty(marker)) {
        (e.cellElement as HTMLElement).style.backgroundColor = {
          'CLOSE_DAY|1': 'PaleGreen',
          'CLOSE_DAY|0': 'LightSalmon',
          'OPEN_DAY|1': 'LightSalmon',
          'OPEN_DAY|0': 'PaleGreen',
        }[marker + '|' + (flag ? 1 : 0)];
      }
    }
  }
}
