import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  CancelEvent,
  ClearEvent,
  ErrorEvent,
  FileInfo,
  SelectEvent,
  SuccessEvent,
  UploadComponent,
  UploadEvent,
} from '@progress/kendo-angular-upload';
import { FileRestrictions } from '@progress/kendo-angular-upload/dist/es2015/types';
import { append, createElement, remove } from '@syncfusion/ej2-base';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-popups';
import { from, of, race, Subject } from 'rxjs';
import { catchError, delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UploadHelperService } from '../../../../shared/modules/ui/services/upload-helper.service';

interface ExtFileInfo extends FileInfo {
  $error$: Subject<ErrorEvent>;
  $success$: Subject<SuccessEvent>;
  $cancel$: Subject<CancelEvent>;
  $complete$: Subject<any>;
  $processed$: Subject<any>;
  elements: HTMLElement[];
}

@Component({
  selector: 'app-import-form-kendo',
  templateUrl: './import-form-kendo.component.html',
  styleUrls: ['./import-form-kendo.component.scss'],
})
export class ImportFormKendoComponent extends ABaseComponent implements OnInit, AfterViewInit {
  @ViewChild(UploadComponent, { static: true }) uploader: UploadComponent;

  @Input() autoUpload = true;
  batch = false;
  withCredentials = false;

  @Input() saveField = 'files';
  @Input() saveHeaders: HttpHeaders;
  @Input() saveMethod = 'PUT';
  @Input() saveUrl = UploadHelperService.SIGNED_URL_PLACEHOLDER;

  @Input() responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'text';

  @Input() removeField = 'fileNames';
  @Input() removeHeaders: HttpHeaders;
  @Input() removeMethod = 'DELETE';
  @Input() removeUrl = '/noop';

  @Input() concurrent = false;
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() showFileList = true;

  @Input() restrictions: FileRestrictions;

  @Input() args: { [k: string]: any } = {};

  @Input() action = 'import';

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    public config: ConfigService,
    private pusher: PusherService,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {}

  // region ui customize

  private getFileElements(f: FileInfo) {
    const selector = 'ul.k-upload-files li.k-file[data-uid="' + f.uid + '"]';
    const fileElmWrapper = document.querySelector(selector);

    return {
      wrapper: fileElmWrapper,
      info: fileElmWrapper && fileElmWrapper.querySelector('.k-file-information'),
      status: fileElmWrapper && fileElmWrapper.querySelector('.k-upload-status'),
    };
  }

  private createSpinner() {
    return createElement('span', { styles: 'position: relative; padding-left: 2em;' });
  }

  private createElm(tag, html, color) {
    return createElement(tag, { styles: `color: ${color};`, innerHTML: html });
  }

  // endregion

  // region event handlers

  uploader_noop() {}

  uploader_select(e: SelectEvent) {
    console.log('select', e);
  }

  uploader_clear(e: ClearEvent) {
    console.log('clear', e);
  }

  uploader_upload(e: UploadEvent) {
    console.log('upload', e);

    e.headers = e.headers.set('x-goog-meta-action', this.action).set('x-goog-meta-job_uuid', e.files[0].uid);
    // .set('x-goog-meta-job_uuid', JSON.stringify(e.files.map((f) => f.uid)))

    Object.entries(this.args).forEach(([k, v]) => {
      e.headers = e.headers.set(`x-goog-meta-arg-${k}`, JSON.stringify(v));
    });

    e.files.forEach((f: ExtFileInfo) => {
      f.$error$ = new Subject();
      f.$success$ = new Subject();
      f.$cancel$ = new Subject();
      f.$complete$ = new Subject();
      f.$processed$ = new Subject();
    });

    from(e.files)
      .pipe(
        switchMap((f: ExtFileInfo) => {
          (f.elements || []).forEach(elm => remove(elm));
          f.elements = [];

          return race<CancelEvent | SuccessEvent | ErrorEvent>([
            f.$cancel$.asObservable(),
            f.$success$.asObservable(),
            f.$error$.asObservable(),
          ]).pipe(
            delay(1),
            switchMap(event => {
              const { status: statusWrap, info: infoWrap } = this.getFileElements(f);

              if (event instanceof CancelEvent) {
                return of();
              } else if (event instanceof SuccessEvent) {
                infoWrap.innerHTML = '';
                const statusElm = this.createElm('span', 'Extracting data', 'gray');
                const infoElm = this.createElm('div', 'Processing uploaded file...', 'gray');
                const spinnerElm = this.createSpinner();

                if (statusWrap) {
                  append([statusElm, spinnerElm], statusWrap);
                  append([infoElm], infoWrap);
                  f.elements = [statusElm, spinnerElm, infoElm];
                  createSpinner({ target: spinnerElm, width: '15px' });
                  showSpinner(spinnerElm);
                }

                return this.pusher.requestResponse(f.uid).pipe(
                  map(res => {
                    return this.createElm('div', 'File uploaded and processed successfully', 'green');
                  }),
                  catchError((err: Error) => {
                    return of(this.createElm('div', err.message, 'red'));
                  }),
                  tap(_infoElm => {
                    hideSpinner(spinnerElm);
                    (f.elements || []).forEach(elm => remove(elm));
                    append([_infoElm], infoWrap);
                    f.elements = [_infoElm];
                  }),
                );
              } else if (event instanceof ErrorEvent) {
                const infoElm = this.createElm(
                  'div',
                  (event.response as any).message || event.response.statusText,
                  'red',
                );
                append([infoElm], infoWrap);
                f.elements = [infoElm];
                return of();
              }
              return of();
            }),
          );
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  uploader_cancel(e: CancelEvent) {
    console.log('cancel', e);
    e.files.forEach((f: ExtFileInfo) => {
      f.$cancel$.next(e);
      f.$cancel$.complete();
    });
  }

  uploader_error(e: ErrorEvent) {
    console.log('error', e);
    e.files.forEach((f: ExtFileInfo) => {
      f.$error$.next(e);
      f.$error$.complete();
    });
  }

  uploader_success(e: SuccessEvent) {
    console.log('success', e);
    e.files.forEach((f: ExtFileInfo) => {
      f.$success$.next(e);
      f.$success$.complete();
    });
  }

  uploader_complete(e: any) {
    console.log('complete', e);
  }

  // endregion
}
