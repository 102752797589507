/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../document-grid/document-grid.component.ngfactory";
import * as i3 from "../../services/helper.service";
import * as i4 from "../../../../shared/modules/ui/services/upload-helper.service";
import * as i5 from "../../../../shared/sdk/services/custom/Document";
import * as i6 from "../../../../shared/sdk/services/custom/DocumentView";
import * as i7 from "../../../../shared/sdk/services/custom/MyFile";
import * as i8 from "../document-grid/document-grid.component";
import * as i9 from "../../../../shared/sdk/services/custom/logger.service";
import * as i10 from "../../../../shared/modules/my-common/services/config.service";
import * as i11 from "../../../../shared/modules/ui/services/ui.service";
import * as i12 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i13 from "../../../../shared/modules/my-common/services/state-store.service";
import * as i14 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "./document-list.component";
var styles_DocumentListComponent = [i0.styles];
var RenderType_DocumentListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentListComponent, data: {} });
export { RenderType_DocumentListComponent as RenderType_DocumentListComponent };
export function View_DocumentListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-document-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DocumentGridComponent_0, i2.RenderType_DocumentGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.UploadHelperService, i5.DocumentApi, i6.DocumentViewApi, i7.MyFileApi]), i1.ɵdid(2, 245760, null, 0, i8.DocumentGridComponent, [i9.LoggerService, i10.ConfigService, i11.UiService, i12.DataSourceService, i13.StateStoreService, i3.HelperService, i14.GridHelperService, i15.MatDialog], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_DocumentListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-list", [], null, null, null, View_DocumentListComponent_0, RenderType_DocumentListComponent)), i1.ɵdid(1, 114688, null, 0, i16.DocumentListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentListComponentNgFactory = i1.ɵccf("app-document-list", i16.DocumentListComponent, View_DocumentListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { DocumentListComponentNgFactory as DocumentListComponentNgFactory };
