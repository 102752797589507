<div>
  <dx-data-grid
    #grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [stateStoring]="grid_stateStoring"
    [repaintChangesOnly]="true"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <!--    (onRowUpdating)="grid_onRowUpdating($event)"-->

    <dxo-load-panel [shading]="true"></dxo-load-panel>

    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-selection mode="multiple" selectAllMode="page" showCheckBoxesMode="always"></dxo-selection>

    <!-- region Columns -->

    <dxi-column
      caption="Broker"
      dataField="_broker"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Firstname"
      dataField="name.first"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column caption="Lastname" dataField="name.last" dataType="string" [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="DOB"
      dataField="dob"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>
    <!--                [calculateFilterExpression]="config.calculateDateFilterExpression"-->

    <dxi-column
      caption="Address"
      dataField="address.address"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="City"
      dataField="address.city"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="State"
      dataField="address.state"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Zip"
      dataField="address.zip"
      dataType="string"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="Phones"
      dataField="phones"
      cellTemplate="phonesCellTpl"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="false"
      [allowEditing]="false"></dxi-column>

    <dxi-column
      caption="System Clients"
      dataField="_consumerIds"
      cellTemplate="consumersCellTpl"
      editCellTemplate="consumersEditCellTpl"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowFiltering]="false"
      [allowHeaderFiltering]="true"
      [allowEditing]="true">
      <dxo-header-filter [dataSource]="consumersHeaderFilter"></dxo-header-filter>
    </dxi-column>

    <dxi-column type="buttons" caption="actions" [showInColumnChooser]="false">
      <dxi-button
        text="Create Consumer"
        hint="Create Consumer"
        icon="add"
        [visible]="noOneAssigned"
        [onClick]="createClientClick"></dxi-button>

      <dxi-button
        text="Update Consumer"
        hint="Update Consumer Data (broker notes, transp codes, weekdays)"
        icon="export"
        [visible]="someOneAssigned"
        [onClick]="updateClientClick"></dxi-button>

      <dxi-button
        text="Unassign Consumer"
        hint="Unassign Consumer"
        icon="clear"
        [visible]="someOneAssigned"
        [onClick]="unAssignClientClick"></dxi-button>
    </dxi-column>

    <!--  endregion  -->

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'phonesCellTpl'">
      {{ cellInfo.data.phones | join }}
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'consumersCellTpl'">
      <ng-container *ngFor="let cId of cellInfo.data._consumerIds">
        <app-object-title [modelName]="'Consumer'" [modelId]="cId"></app-object-title>
      </ng-container>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'consumersEditCellTpl'">
      <app-drop-down-grid-new
        [valueExpr]="'id'"
        [dataSource]="consumerDso"
        [grid_columns]="[
          {
            dataField: 'tenantId',
            caption: 'Tenant',
            lookup: {
              dataSource: facilityDso,
              valueExpr: 'id',
              displayExpr: 'shortname'
            }
          },
          { dataField: 'person_firstname', caption: 'Firstname' },
          { dataField: 'person_lastname', caption: 'Lastname' },
          { dataField: 'person_dob', caption: 'DOB' }
        ]"
        [showClearButton]="true"
        [displayExpr]="consumerHelper.displayExpr"
        [value]="cellInfo.data._consumerIds[0]"
        (valueChanged)="clientDropDown_onValueChanged(cellInfo, $event)"
        [popup_width]="350"
        [popup_height]="300"></app-drop-down-grid-new>
    </ng-container>
  </dx-data-grid>
</div>
