<dx-data-grid
  #grid
  height="100%"
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="vehicles-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Group" dataField="group" dataType="string" calculateSortValue="groupSort" sortOrder="asc"></dxi-column>
  
  <dxi-column caption="ID" dataField="internalId" dataType="number"></dxi-column>

  <dxi-column caption="Year Make Model" dataField="ymm" dataType="string"></dxi-column>

  <dxi-column caption="Sub-Status" dataField="subStatus" dataType="string"></dxi-column>

  <dxi-column caption="Passenger Capacity" dataField="passengerCapacity" dataType="number"></dxi-column>

  <dxi-column caption="Wheelchair Accessible" dataField="wheelchairAccessible" dataType="string"></dxi-column>

  <dxi-column caption="Checked-In By" dataField="isCheckedInToday" dataType="string"></dxi-column>

  <dxi-column caption="On Board Today" dataField="isOnBoardedToday" dataType="string"></dxi-column>

  <dxi-column caption="CDL" dataField="cdl" dataType="string"></dxi-column>

  <dxi-column caption="Body Type" dataField="type" dataType="string"></dxi-column>

  <dxi-column caption="Last Date on Duty" dataField="lastDate" dataType="date"></dxi-column>

  <dxi-column caption="Last Note" dataField="lastNote.subject" dataType="string"></dxi-column>
  
  <dxi-column caption="Location" cellTemplate="geoTabLocation_cell" [allowSearch]="false" [allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"></dxi-column>

  <dxi-column caption="Location Time" dataField="positionTime" calculateSortValue="positionTimeSort"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'geoTabLocation_cell'">
    <a *ngIf="cellInfo.data.lastPosition" target="_blank" class="show-loop-link" [href]="getMapURL(cellInfo.data.lastPosition)" title="link">location</a>&nbsp;
    <a *ngIf="cellInfo.data.lastLoop" class="show-loop-link" (click)="showLoop_onClick(cellInfo.data)" title="loop">loop</a>
  </ng-container>

</dx-data-grid>
