import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import { Employee, LoggerService, TripManifest } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { REASSIGN_MODE } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
export class ToolbarReassignBtnComponent {
    constructor(logger, helper, dss) {
        this.logger = logger;
        this.helper = helper;
        this.dss = dss;
        this.popupVisible = false;
        this.popup2Visible = false;
        this.gridColumns = [
            { dataField: 'person_firstname', caption: 'FirstName' },
            { dataField: 'person_lastname', caption: 'LastName' },
            { dataField: 'person_nickname', caption: 'Nickname' },
        ];
        this.processing = false;
        this.processingChange = new EventEmitter();
        this.reassigning = new EventEmitter();
        this.reassigned = new EventEmitter();
        this.buildRelatedDs(this.selectedDriverId);
        this.buildContextMenu();
    }
    ngOnChanges(changes) {
        if ('selectedDriverId' in changes) {
            this.buildRelatedDs(this.selectedDriverId);
        }
        if (['manifest', 'selectedDriverId', 'grid'].some(p => p in changes)) {
            this.buildContextMenu();
        }
    }
    ctx_onAction(data) {
        this.selectedAction = data.id;
        if (this.selectedAction === REASSIGN_MODE.SET_TRIP_NUM) {
            this.popup2Visible = true;
        }
        else {
            this.popupVisible = true;
        }
    }
    setTrip(num) {
        this.popup2Visible = false;
        const recIds = this.grid && this.grid.selectedRowKeys;
        if (!recIds || !recIds.length) {
            notify('No items selected', 'error', 3000);
            return;
        }
        this.setTripRecordsAsync(recIds, num).catch(err => notify(err.message || err, 'error', 3000));
    }
    popup_onSelectionChanged(ids) {
        let from;
        const target = head(ids);
        switch (this.selectedAction) {
            case REASSIGN_MODE.FROM_EMPLOYEE: {
                from = this.selectedDriverId;
                if (!from) {
                    notify('No from driver selected', 'error', 3000);
                    return;
                }
                break;
            }
            case REASSIGN_MODE.SELECTED: {
                from = this.grid && this.grid.selectedRowKeys;
                if (!from || !from.length) {
                    notify('No items selected', 'error', 3000);
                    return;
                }
                break;
            }
        }
        this.reassignRecordsAsync(from, target).catch(err => notify(err.message || err, 'error', 3000));
    }
    reassignRecordsAsync(from, toEmployeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.processing = true;
                this.processingChange.emit(true);
                this.reassigning.emit();
                yield this.helper.api.reassignManifest(this.manifest.id, from, toEmployeeId).toPromise();
            }
            catch (err) {
                notify(err.message || err, 'error', 3000);
            }
            finally {
                setTimeout(() => {
                    this.processing = false;
                    this.processingChange.emit(false);
                    this.reassigned.emit();
                });
            }
        });
    }
    setTripRecordsAsync(recIds, tripNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.processing = true;
                this.processingChange.emit(true);
                this.reassigning.emit();
                yield this.helper.api.setTripNumber(this.manifest.id, recIds, tripNumber).toPromise();
            }
            catch (err) {
                notify(err.message || err, 'error', 3000);
            }
            finally {
                setTimeout(() => {
                    this.processing = false;
                    this.processingChange.emit(false);
                    this.reassigned.emit();
                });
            }
        });
    }
    buildRelatedDs(employeeId) {
        this.driversDSO = {
            store: this.dss.getStore(Employee, null, false),
            filter: [
                this.helper.buildDriversFilter('employeePosition_name'),
                ['status', '=', 'ACTIVE'],
                employeeId ? ['id', '<>', employeeId] : undefined,
            ],
        };
    }
    buildContextMenu() {
        this.ctxMenuItems = [
            {
                text: 'Reassign driver trips',
                icon: 'fas fa-share',
                id: REASSIGN_MODE.FROM_EMPLOYEE,
                disabled: !get(this.manifest, 'id') || !this.selectedDriverId,
            },
            {
                text: 'Reassign selected trips',
                icon: 'fas fa-share',
                id: REASSIGN_MODE.SELECTED,
                disabled: false,
            },
            {
                text: 'Set trip number',
                icon: 'fas fa-any',
                id: REASSIGN_MODE.SET_TRIP_NUM,
                disabled: false,
            },
        ];
    }
}
