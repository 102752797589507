<app-content-w-header header="Consistency Check">
  <!--  <mat-card class="toolbar subheader"></mat-card>-->

  <dx-tab-panel class="content" height="100%">
    <dxi-item title="MTM Trip ID Duplicates">
      <app-consistency-check1></app-consistency-check1>
    </dxi-item>

    <dxi-item title="MTM Claim Duplicates">
      <app-consistency-check2></app-consistency-check2>
    </dxi-item>

    <dxi-item title="MTM Trips Has Old Version Copy">
      <app-consistency-check3></app-consistency-check3>
    </dxi-item>

    <dxi-item title="MTM Claims Has Old Version Copy">
      <app-consistency-check4></app-consistency-check4>
    </dxi-item>

    <!--    <dxi-item title="MTM Auth Service Date not equal Sign Date">-->
    <!--      <app-consistency-check5></app-consistency-check5>-->
    <!--    </dxi-item>-->

    <dxi-item title="Multi assigned trip Ids">
      <app-consistency-check6></app-consistency-check6>
    </dxi-item>
  </dx-tab-panel>
</app-content-w-header>
