import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { difference } from '@ngrx/store-devtools/src/utils';
import { Observable, iif } from 'rxjs';
import { switchMap } from 'rxjs/operators';
//
import { AppState } from '../../../../store/reducers';
import { LoopBackAuth } from '../../../sdk/services/core';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../services/config.service';
import { of } from 'rxjs';
import intersection from 'lodash-es/intersection';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
    public config: ConfigService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkAsync(url);
  }

  private checkAsync(url: string): Observable<boolean> {
    return this.config.roles$.pipe(
      switchMap(roles => {
        if (intersection(['FLEET_CLERK', 'FLEET_DIRECTOR', 'FLEET_ADMIN'], roles).length > 0) {
          this.router.navigate(['/dashboard/vehicles-backup']);
          return of(false);
        } else if (intersection(['CLERK', 'MANAGER'], roles).length > 0) {
          this.router.navigate(['/dashboard/scheduling/manifest']);
          return of(false);
        } else {
          this.router.navigate(['dashboard', 'empty']);
          return of(false);
        }
      }),
    );
  }
}
