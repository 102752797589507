import {
  Actions,
  CLEAR_ALERTS,
  DEC_PENDING,
  DROP_ALERT,
  HIDE_LOADING_PANEL,
  INC_PENDING,
  SET_ALERT,
  SET_OFFLINE,
  SET_ONLINE,
  SetAlert,
  SHOW_LOADING_PANEL,
} from '../../actions/core';

export interface GeneralState {
  loadPanelVisible: boolean;
  pendingCount: number;
  online: boolean;
  alerts: any;
}

export const initialState: GeneralState = {
  loadPanelVisible: false,
  pendingCount: 0,
  online: false,
  alerts: {},
};

export function generalReducer(state = initialState, action: Actions): GeneralState {
  switch (action.type) {
    case SET_ONLINE: {
      return {
        ...state,
        online: true,
      } as GeneralState;
    }

    case SET_OFFLINE: {
      return {
        ...state,
        online: false,
      } as GeneralState;
    }

    case INC_PENDING: {
      return {
        ...state,
        pendingCount: state.pendingCount + 1,
      } as GeneralState;
    }

    case DEC_PENDING: {
      return {
        ...state,
        pendingCount: Math.max(state.pendingCount - 1, 0),
      } as GeneralState;
    }

    case SHOW_LOADING_PANEL: {
      return {
        ...state,
        loadPanelVisible: true,
      } as GeneralState;
    }

    case HIDE_LOADING_PANEL: {
      return {
        ...state,
        loadPanelVisible: false,
      } as GeneralState;
    }

    case SET_ALERT: {
      return {
        ...state,
        alerts: { ...state.alerts, ...(action as SetAlert).payload },
      } as GeneralState;
    }

    case DROP_ALERT: {
      const alerts = { ...state.alerts };
      delete alerts[action.payload];
      return {
        ...state,
        alerts,
      } as GeneralState;
    }

    case CLEAR_ALERTS: {
      return {
        ...state,
        alerts: {},
      } as GeneralState;
    }

    default: {
      return state;
    }
  }
}

export const generalIsLoadPanelVisible = (state: GeneralState) => state.loadPanelVisible;
export const generalGetPendingCount = (state: GeneralState) => state.pendingCount;
export const generalIsOnline = (state: GeneralState) => state.online;
export const generalGetAlerts = (state: GeneralState) => state.alerts;
