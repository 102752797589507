import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AutoDispatchGridComponent } from '../auto-dispatch-grid/auto-dispatch-grid.component';

@Component({
  selector: 'app-auto-dispatch-list',
  templateUrl: './auto-dispatch-list.component.html',
  styleUrls: ['./auto-dispatch-list.component.scss'],
})
export class AutoDispatchListComponent implements OnInit {
  @Output() mySelected: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild(AutoDispatchGridComponent, { static: true }) grid: AutoDispatchGridComponent;

  constructor() {}

  ngOnInit() {}

  grid_onSelected(keys: number[]) {
    this.mySelected.emit(keys);
  }

  repaint() {
    this.grid.repaint();
  }
}

