export const VEHICLE_STATUSES: any[] = [
  { ID: 'ACTIVE', Name: 'ACTIVE' },
  { ID: 'INACTIVE', Name: 'INACTIVE' },
];

export const BASE_COLUMNS = [
  { dataType: 'string', cellTemplate: 'add_cell', width: '55px' },
  { dataField: 'internalId', caption: 'Internal ID', dataType: 'number' },
  {
    dataField: 'state',
    caption: 'State',
    dataType: 'string',
    lookup: { dataSource: VEHICLE_STATUSES, valueExpr: 'Name', displayExpr: 'ID' },
  },
  { dataField: 'lastDate', caption: 'Last Date On Duty', dataType: 'date' },
];
