import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { select } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { take } from 'rxjs/operators';
//
import { getConfig, getEnvVars } from '../../../../store/reducers/core';
import { Config } from '../../../sdk/models';
import { CommonService } from './common.service';
import { IEnvVars } from './config.service';

@Injectable()
export class ConfigResolver implements Resolve<[IEnvVars, Config]> {
  constructor(private common: CommonService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return combineLatest([this.common.store.pipe(select(getEnvVars)), this.common.store.pipe(select(getConfig))]).pipe(
      filter(([vars, config]: [IEnvVars, Config]) => !isEmpty(vars) && !isEmpty(config)),
      take(1),
    );
  }
}
