import { createSelector } from '@ngrx/store';
import { Actions, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_WITH_MFA } from '../../actions/sign';

export interface LoginState {
  error: any | null;
  pending: boolean;
}

export const initialState: LoginState = {
  error: null,
  pending: false,
};

export function loginReducer(state = initialState, action: Actions): LoginState {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        error: null,
        pending: true,
      } as LoginState;
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
      } as LoginState;
    }

    case LOGIN_WITH_MFA: {
      return {
        ...state,
        error: null,
        pending: false,
      } as LoginState;
    }

    case LOGIN_FAIL: {
      return {
        ...state,
        error: action.payload || 'Server unavailable',
        pending: false,
      } as LoginState;
    }

    default: {
      return state;
    }
  }
}

export const loginGetPending = (state: LoginState) => state.pending;
export const loginGetError = (state: LoginState) => state.error;
export const loginGetErrorMessage = createSelector(loginGetError, err => (err ? err.message || err : ''));
