import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import fromPairs from 'lodash-es/fromPairs';
import isNil from 'lodash-es/isNil';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerAddressView,
  LoggerService,
  PersDevice,
  PersDeviceApi,
  Person,
  Program,
} from '../../../../shared/sdk';
import { PERSON_SEXES } from '../../../employee/classes/enums';
import { CONSUMER_STATUSES, CONSUMER_TRANSPORTATION_CODES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-consumer-grid',
  templateUrl: './consumer-grid.component.html',
  styleUrls: ['./consumer-grid.component.scss'],
  providers: [HelperService],
})
export class ConsumerGridComponent extends ABaseComponent implements OnInit, OnDestroy {
  sexes = PERSON_SEXES;
  statuses = CONSUMER_STATUSES;
  transpCodes = CONSUMER_TRANSPORTATION_CODES;
  transpCodesMap = fromPairs(CONSUMER_TRANSPORTATION_CODES.map(({ ID, Name }) => [ID, Name]));
  // instructions: string[] = CONSUMER_INSTRUCTIONS.map(i => i.Name);

  dso$: Observable<DataSourceOptions> = of([]);
  programDso: DataSourceOptions = [];
  grid_stateStoring: any;

  modifiedSubscription: Subscription;

  $showFromBroker$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,

    private pusher: PusherService,
    private notification: PushNotificationsService,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('bc738af0-8f33-461c-a457-c28ef55413d2');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '94bbf2a7-8b8a-4482-8b1c-f846b7c07465',
    };

    this.dso$ = this.buildDataSource();

    const store = this.dss.getStore(Program);
    this.programDso = {
      store,
      sort: [{ selector: 'name' }],
    };
  }

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Consumer.getModelName(), Person.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });

    this.config.tenantType$.pipe(takeUntil(this.$onDestroy$)).subscribe(tenantType => {
      this.grid.instance.option('onToolbarPreparing', this.grid_onToolbarPreparing.bind(this));
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    if (this.grid && this.grid.instance) {
      this.grid.instance.beginUpdate();
      // this.grid.instance.updateDimensions();
      this.grid.instance.repaint();
      this.grid.instance.endUpdate();
    }
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    const items = [
      // {
      //   name: 'showFromBroker',
      //   locateInMenu: 'auto',
      //   widget: 'dxSwitch',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     width: '100%',
      //     switchedOnText: 'Broker Exports Mode',
      //     switchedOffText: 'Regular Mode',
      //     hint: 'Switch only exported or regular mode',
      //     value: this.$showFromBroker$.value,
      //     onValueChanged: this.grid_toolbar_modeSwitch_onChange.bind(this),
      //   }
      // },
      {
        name: 'exportConsumer',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-file-export',
          text: 'Export consumers',
          hint: 'Export consumers',
          onClick: this.grid_toolbar_exportConsumers_onClick.bind(this),
        },
      },
      {
        disabled: this.$showFromBroker$.value,
        name: 'newConsumer',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-user-plus',
          text: 'New consumer',
          hint: 'Create new consumer',
          onClick: this.grid_toolbar_newConsumer_onClick.bind(this),
        },
      },
      ...(this.config.tenantType === 'TC'
        ? [
            {
              name: 'requestDeviceStatus',
              locateInMenu: 'auto',
              widget: 'dxButton',
              location: 'after',
              sortIndex: 30,
              showText: 'inMenu',
              options: {
                icon: 'fas fa-user-check',
                text: 'Check All PERS',
                hint: 'Check All ACTIVE PERS Devices for this month',
                onClick: this.grid_toolbar_checkPers_onClick.bind(this),
              },
            },
          ]
        : []),
    ];

    e.toolbarOptions.items = e.toolbarOptions.items.filter(i => items.every(j => j.name !== i.name));
    e.toolbarOptions.items.unshift(...items);
  }

  grid_onSelectionChanged(e: any): void {
    this.mySelected.emit(e.selectedRowKeys);
  }

  grid_onRowRemoving(e: any): void {
    if (e.data.status !== 'INACTIVE') {
      e.cancel = alert(
        'Can not delete not INACTIVE client, please inactivate first,' +
          " don't forget to leave comment in attendance note.",
        'Can not delete',
      ).then(() => false);
    }
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;

    const subTitle = [
      moment.utc(e.data.person_dob).isValid()
        ? 'DOB:' + moment.utc(e.data.person_dob).utc().format('MM/DD/YYYY')
        : undefined,
      e.data.mci ? 'MCI:' + e.data.mci : undefined,
      e.data.status ? e.data.status : undefined,
      // e.data.activeMco ? e.data.activeMco : undefined,
      // e.data.activeBroker ? e.data.activeBroker : undefined,
    ]
      .filter(i => !isNil(i))
      .join(' - ');
    const title = `Edit: ${this.helper.displayExpr(e.data)}${subTitle.length ? ' - ' + subTitle : ''}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Consumer,
      FormComponentClass: ConsumerFormComponent,
      title,
      headerBtns: [
        {
          icon: 'fa-file-pdf',
          tooltip: 'Get MTM transportation form',
          click() {
            const settings = {};
            this.helperService.getMtmTranspForm(this.modelId, settings).toPromise().finally();
          },
        },
      ],
    });
  }

  grid_toolbar_newConsumer_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Consumer,
      FormComponentClass: ConsumerFormComponent,
    });
  }

  grid_toolbar_checkPers_onClick() {
    notify('Check All PERS Devices Status Requested...');

    const notificationOptions: NotificationOptions = {
      body: 'Check All PERS Devices Status Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('requestPersDevicesStatus', {
        noChecksAfterDate: moment().startOf('month').format('YYYY-MM-DD'),
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_exportConsumers_onClick() {
    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('EXPORT_CONSUMERS_REPORT', {
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap((uri: string) => window.open(uri)),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_modeSwitch_onChange(e) {
    this.$showFromBroker$.next(e.value);
    this.mySelected.emit([]);
  }

  private buildDataSource() {
    return this.$showFromBroker$.pipe(
      map(val => {
        const dso = this.dss.getDataSourceOptions(Consumer, ConsumerAddressView);
        // if (val) {
        //   dso.filter = [['source', '<>', null], ['source', '<>', '']];
        // } else {
        //   dso.filter = ['source', '=', null];
        // }
        return dso;
      }),
      tap(() => {
        this.grid && this.grid.instance && this.grid.instance.refresh();
      }),
    );
  }
}
