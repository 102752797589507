<dx-data-grid
  #grid
  width="100%"
  height="100%"
  [dataSource]="dso$ | async"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onRowRemoving)="grid_onRowRemoving($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing
    [allowAdding]="false"
    [allowUpdating]="($showFromBroker$ | async) === false"
    [allowDeleting]="true"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <!--<dxi-column caption="Person">-->
  <dxi-column caption="First Name" dataField="person_firstname" dataType="string"></dxi-column>

  <dxi-column caption="Last Name" dataField="person_lastname" dataType="string"></dxi-column>

  <dxi-column caption="Nickname" dataField="person_nickname" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Sex" dataField="person_sex" dataType="string" [visible]="false">
    <dxo-lookup [dataSource]="sexes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column
    caption="DOB"
    dataField="person_dob"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
  ></dxi-column>

  <dxi-column caption="City" dataField="contact_addresses_0_city" dataType="string"></dxi-column>
  <dxi-column caption="State" dataField="contact_addresses_0_state" dataType="string"></dxi-column>
  <dxi-column caption="ZIP" dataField="contact_addresses_0_zip" dataType="string"></dxi-column>
  <dxi-column caption="Street" dataField="contact_addresses_0_street" dataType="string"></dxi-column>

  <dxi-column
    caption="Native Lang"
    dataField="person_data.nativeLang"
    dataType="string"
    [visible]="false"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <dxi-column
    caption="Native Lang Name"
    dataField="person_data.nativeLangName"
    dataType="string"
    [visible]="false"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>
  <!--</dxi-column>-->

  <dxi-column caption="Status" dataField="status" dataType="string">
    <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Internal ID" dataField="internalID" dataType="number"></dxi-column>

  <dxi-column
    caption="Facility ID"
    dataField="facilityID"
    dataType="string"
    [visible]="config.get('consumerMoreFields', []).includes('facilityID')"></dxi-column>

  <dxi-column caption="Program" dataField="programId" dataType="string">
    <dxo-lookup [dataSource]="programDso" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Client Type" dataField="repetitiveMode" dataType="string"></dxi-column>

  <dxi-column caption="MCI" dataField="mci" dataType="string"></dxi-column>

  <dxi-column caption="MCO" dataField="activeMco" dataType="string"></dxi-column>

  <dxi-column caption="Broker" dataField="activeBroker" dataType="string"></dxi-column>

  <dxi-column caption="Default Destination" dataField="defaultDestination" dataType="string"></dxi-column>

  <dxi-column caption="Week Days" dataField="weekDays" cellTemplate="weekDays_cell"></dxi-column>

  <!--  <dxi-column caption="Week Days Notes"-->
  <!--              dataField="daysNotes"-->
  <!--              dataType="string"-->
  <!--  ></dxi-column>-->

  <!--<dxi-column caption="Notes">-->
  <dxi-column
    caption="Special Instructions"
    dataField="specialInstrs"
    editCellTemplate="textArea_editCell"
    [visible]="false"></dxi-column>

  <dxi-column caption="Transportation Instructions" dataField="transpInstrs"></dxi-column>

  <dxi-column
    caption="Transportation Codes"
    dataField="transpCodes"
    cellTemplate="tagBox_cell"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Broker Notes"
    dataField="brokerNotes"
    [visible]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Last Attendance Note"
    dataField="lastNote_text"
    dataType="string"
    [calculateDisplayValue]="helper.attendanceNoteDisplayExpr"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Person Notes"
    dataField="person_notes"
    editCellTemplate="textArea_editCell"
    [visible]="false"></dxi-column>
  <!--</dxi-column>-->

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'textArea_editCell'">
    <dx-text-area [value]="cellInfo.value" (onValueChanged)="cellInfo.setValue($event.value)"></dx-text-area>
  </ng-container>

  <!-- /// -->

  <ng-container *dxTemplate="let cellInfo of 'tagBox_cell'">
    <span [title]="cellInfo.value | join: ', '">
      <span class="badge" *ngFor="let item of cellInfo.value">{{ transpCodesMap[item] || item }}</span>
    </span>
  </ng-container>

  <!-- /// -->

  <ng-container *dxTemplate="let cellInfo of 'weekDays_cell'">
    {{ cellInfo.value | weekDays }}
  </ng-container>
</dx-data-grid>
