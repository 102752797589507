<app-content-w-header header="Fuel Cards Transactions">
  <dx-tab-panel class="content" width="100%" height="100%" [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex">
    <div *dxTemplate="let data of 'grid'">
      <dx-data-grid
        height="100%"
        width="100%"
        [columnResizingMode]="'widget'"
        [columnAutoWidth]="true"
        [dataSource]="gridDso"
        [twoWayBindingEnabled]="false"
        [stateStoring]="grid_stateStoring"
        [dateSerializationFormat]="null"
        (onInitialized)="grid_onInitialized($event)"
        (onToolbarPreparing)="grid_onToolbarPreparing($event)"
        (onCellPrepared)="grid_onCellPrepared($event)">
        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

        <!--        <dxo-selection-->
        <!--          mode="multiple"-->
        <!--          selectAllMode="page"-->
        <!--          showCheckBoxesMode="always"-->
        <!--        ></dxo-selection>-->

        <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

        <!-- Columns -->

        <dxi-column
          caption="Transaction Date"
          dataField="Transaction Date"
          dataType="date"
          format="shortDate"
          [customizeText]="config.customizeShortDateText"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Transaction Time"
          dataField="Transaction Time"
          dataType="datetime"
          format="shortTime"
          [editorOptions]="{ type: 'time', pickerType: 'native' }"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Transaction DateTime"
          dataField="_transactionDateTime"
          dataType="datetime"
          format="shortDateShortTime"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Posted Date"
          dataField="getPostedDate"
          dataType="date"
          format="shortDate"
          [customizeText]="config.customizeShortDateText"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Account Number"
          dataField="Account Number"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Account Name"
          dataField="Account Name"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Card Number"
          dataField="Card Number"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Emboss Line 2"
          dataField="Emboss Line 2"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Custom Vehicle/Asset ID"
          dataField="Custom Vehicle/Asset ID"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Units"
          dataField="Units"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Unit of Measure"
          dataField="Unit of Measure"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Unit Cost"
          dataField="Unit Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Total Fuel Cost"
          dataField="Total Fuel Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Service Cost"
          dataField="Service Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Other Cost"
          dataField="Other Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Total Non-Fuel Cost"
          dataField="Total Non-Fuel Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Gross Cost"
          dataField="Gross Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Exempt Tax"
          dataField="Exempt Tax"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Discount"
          dataField="Discount"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Net Cost"
          dataField="Net Cost"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Reported Tax"
          dataField="Reported Tax"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column caption="Transaction Fee">
          <dxi-column
            caption="Transaction Fee Type 1"
            dataField="Transaction Fee Type 1"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Amount 1"
            dataField="Transaction Fee Amount 1"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Type 2"
            dataField="Transaction Fee Type 2"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Amount 2"
            dataField="Transaction Fee Amount 2"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Type 3"
            dataField="Transaction Fee Type 3"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Amount 3"
            dataField="Transaction Fee Amount 3"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Type 4"
            dataField="Transaction Fee Type 4"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Amount 4"
            dataField="Transaction Fee Amount 4"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Type 5"
            dataField="Transaction Fee Type 5"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Transaction Fee Amount 5"
            dataField="Transaction Fee Amount 5"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>
        </dxi-column>

        <dxi-column
          caption="Product Class"
          dataField="Product Class"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Product"
          dataField="Product"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Product Description"
          dataField="Product Description"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Transaction Description"
          dataField="Transaction Description"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column caption="Merchant">
          <dxi-column
            caption="Merchant (Brand)"
            dataField="Merchant (Brand)"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant Name"
            dataField="Merchant Name"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant Address"
            dataField="Merchant Address"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant City"
            dataField="Merchant City"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant State / Province"
            dataField="Merchant State / Province"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant Postal Code"
            dataField="Merchant Postal Code"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Merchant Site ID"
            dataField="Merchant Site ID"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>
        </dxi-column>

        <dxi-column
          caption="Prompt 1 Type"
          dataField="Prompt 1 Type"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Prompt 1 Value"
          dataField="Prompt 1 Value"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Prompt 2 Type"
          dataField="Prompt 2 Type"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Prompt 2 Value"
          dataField="Prompt 2 Value"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Current Odometer"
          dataField="Current Odometer"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Adjusted Odometer"
          dataField="Adjusted Odometer"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Previous Odometer"
          dataField="Previous Odometer"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Distance Driven"
          dataField="Distance Driven"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Fuel Economy"
          dataField="Fuel Economy"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Cost Per Distance"
          dataField="Cost Per Distance"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Vehicle Description"
          dataField="Vehicle Description"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="VIN"
          dataField="VIN"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Tank Capacity"
          dataField="Tank Capacity"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="In Service Date"
          dataField="In Service Date"
          dataType="date"
          format="shortDate"
          [customizeText]="config.customizeShortDateText"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Start Odometer"
          dataField="Start Odometer"
          dataType="number"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column caption="Driver">
          <dxi-column
            caption="Driver Last Name"
            dataField="Driver Last Name"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Driver First Name"
            dataField="Driver First Name"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Driver Middle Name"
            dataField="Driver Middle Name"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Driver Department"
            dataField="Driver Department"
            dataType="string"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>
        </dxi-column>

        <dxi-column
          caption="Employee ID"
          dataField="Employee ID"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Transaction Ticket Number"
          dataField="Transaction Ticket Number"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Currency Exchange Rate"
          dataField="Currency Exchange Rate"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Rebate Code"
          dataField="Rebate Code"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Driver Prompt ID"
          dataField="Driver Prompt ID"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Vehicle Prompt ID"
          dataField="Vehicle Prompt ID"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Department"
          dataField="Department"
          dataType="string"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <!--    -->

        <dxi-column caption="Fuel Tax">
          <dxi-column
            caption="Federal Excise Exempt Fuel Tax"
            dataField="Federal Excise Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Excise Exempt Fuel Tax"
            dataField="State Excise Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Sales Exempt Fuel Tax"
            dataField="State Sales Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Special Exempt Fuel Tax"
            dataField="State Special Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Excise Exempt Fuel Tax"
            dataField="County Excise Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Special Exempt Fuel Tax"
            dataField="County Special Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Sales Exempt Fuel Tax"
            dataField="County Sales Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Excise Exempt Fuel Tax"
            dataField="City Excise Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Special Exempt Fuel Tax"
            dataField="City Special Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Sales Exempt Fuel Tax"
            dataField="City Sales Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Federal Excise Reported Fuel Tax"
            dataField="Federal Excise Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Excise Reported Fuel Tax"
            dataField="State Excise Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Sales Reported Fuel Tax"
            dataField="State Sales Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="State Special Reported Fuel Tax"
            dataField="State Special Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Excise Reported Fuel Tax"
            dataField="County Excise Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Sales Reported Fuel Tax"
            dataField="County Sales Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="County Special Reported Fuel Tax"
            dataField="County Special Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Excise Reported Fuel Tax"
            dataField="City Excise Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Special Reported Fuel Tax"
            dataField="City Special Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="City Sales Reported Fuel Tax"
            dataField="City Sales Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Total Exempt Fuel Tax"
            dataField="Total Exempt Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Total Reported Fuel Tax"
            dataField="Total Reported Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>

          <dxi-column
            caption="Total Reported Non-Fuel Tax"
            dataField="Total Reported Non-Fuel Tax"
            dataType="number"
            [allowSorting]="false"
            [allowHeaderFiltering]="false"></dxi-column>
        </dxi-column>

        <!-- -->

        <dxi-column
          caption="Has Receipt"
          dataField="_hasReceipt"
          dataType="boolean"
        ></dxi-column>

        <dxi-column
          caption="Imported Date"
          dataField="_ctime"
          dataType="datetime"
          format="shortDateShortTime"
          [allowSorting]="false"
          [allowHeaderFiltering]="false"
        ></dxi-column>

        <dxi-column caption="Imported By" dataField="_userId" [allowSorting]="false">
          <dxo-lookup [dataSource]="userDso$ | async" valueExpr="id" displayExpr="username"></dxo-lookup>
        </dxi-column>

        <!-- Templates -->
      </dx-data-grid>
    </div>

    <div *dxTemplate="let data of 'pivot'">
      <dx-box direction="col" width="100%" height="100%">
        <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
          <mat-card>
            <div class="dx-fieldset" style="display: flex">
              <div style="display: flex; align-items: center">
                <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
                <span>&nbsp;&ndash;&nbsp;</span>
                <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
              </div>

              <div style="display: inline-block">
                <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filterDs()"></dx-button>
              </div>
            </div>
          </mat-card>
        </dxi-item>

        <dxi-item [ratio]="1" [baseSize]="'auto'">
          <dx-pivot-grid
            width="100%"
            height="100%"
            [dataSource]="pivotDso"
            [allowExpandAll]="true"
            [allowSortingBySummary]="true"
            [allowSorting]="true"
            [allowFiltering]="true"
            [showColumnTotals]="showColumnTotals"
            [showRowTotals]="showRowTotals"
            [showColumnGrandTotals]="showColumnGrandTotals"
            [showRowGrandTotals]="showRowGrandTotals"
            [stateStoring]="pivot_stateStoring"
            (onCellPrepared)="pivot_onCellPrepared($event)">
            <dxo-field-panel
              [showDataFields]="false"
              [showRowFields]="true"
              [showColumnFields]="true"
              [showFilterFields]="true"
              [allowFieldDragging]="true"
              [visible]="true">
            </dxo-field-panel>

            <dxo-scrolling [useNative]="true"></dxo-scrolling>
          </dx-pivot-grid>
        </dxi-item>
      </dx-box>
    </div>
  </dx-tab-panel>
</app-content-w-header>
