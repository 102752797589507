<app-content-w-header header="Daily Checks">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset">
      <div style="display: flex; align-items: center">
        <dx-date-box #from width="100px" [(value)]="selectedFromValue"></dx-date-box>
        <span>&nbsp;&ndash;&nbsp;</span>
        <dx-date-box #to width="100px" [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
      </div>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter()"></dx-button>
      </div>
    </div>
  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    [dataSource]="ds"
    [errorRowEnabled]="true"
    [stateStoring]="grid_stateStoring"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
    (onSelectionChanged)="grid_onSelectionChanged($event)">
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

    <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!-- Columns -->

    <dxi-column type="buttons" caption="actions" [showInColumnChooser]="false">
      <dxi-button template="commentBtn">
        <ng-container *dxTemplate="let cellInfo of 'commentBtn'">
          <dx-button hint="Add comment" [icon]="'comment'" (onClick)="addComment($event, cellInfo)"></dx-button>
        </ng-container>
      </dxi-button>

      <dxi-button template="odoBtn">
        <ng-container *dxTemplate="let cellInfo of 'odoBtn'">
          <dx-button
            hint="Edit CI/CO odometer value"
            [icon]="'car'"
            (onClick)="editOdometer($event, cellInfo)"></dx-button>
        </ng-container>
      </dxi-button>

      <!--          <dxi-button template="printBtn">-->
      <!--            <div *dxTemplate="let cellInfo of 'printBtn'">-->
      <!--              <dx-button-->
      <!--                hint="Print"-->
      <!--                [icon]="cellInfo.data.pdfProcessing ? 'fas fa-circle-notch fa-spin' : 'print'"-->
      <!--                [disabled]="cellInfo.data.pdfProcessing"-->
      <!--                (onClick)="printReport($event, cellInfo)"-->
      <!--              ></dx-button>-->
      <!--            </div>-->
      <!--          </dxi-button>-->
    </dxi-column>

    <dxi-column caption="Alert" dataField="getAlert" dataType="string"></dxi-column>

    <dxi-column caption="Device ID" dataField="deviceUid" dataType="string"></dxi-column>

    <dxi-column caption="Location" dataField="vehicle.location" dataType="string"></dxi-column>

    <dxi-column caption="Vehicle" dataField="vehicleId" [allowSorting]="false">
      <dxo-lookup [dataSource]="vehicleDso$ | async" valueExpr="id" [displayExpr]="'internalId'"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Driver" dataField="getEmployeeName" dataType="string"></dxi-column>

    <!--    <dxi-column caption="Date"-->
    <!--                dataField="dateTime"-->
    <!--                dataType="date"-->
    <!--                format="shortDateShortTime"-->
    <!--    ></dxi-column>-->
    <!--                [editorOptions]="{type: 'date', displayFormat: 'shortDate'}"-->

    <dxi-column
      caption="Check In Time"
      dataField="completedDateTime"
      dataType="date"
      format="shortDateShortTime"></dxi-column>

    <dxi-column
      caption="Check Out Time"
      dataField="checkout.completedDateTime"
      dataType="date"
      format="shortDateShortTime"></dxi-column>

    <dxi-column
      caption="Expired Items"
      dataField="getExpired"
      dataType="number"
      cellTemplate="expiredCellTpl"></dxi-column>

    <dxi-column caption="Issues" dataField="getIssues" dataType="number" cellTemplate="issuesCellTpl"></dxi-column>

    <dxi-column
      caption="Missing Stuff"
      dataField="getMissingStuff"
      dataType="number"
      cellTemplate="missingStuffCellTpl"></dxi-column>

    <dxi-column caption="Interior Clean" dataField="getInteriorClean" dataType="string"></dxi-column>

    <dxi-column caption="Exterior Washed" dataField="getExteriorWashed" dataType="string"></dxi-column>

    <dxi-column caption="Desinfection" dataField="getDesinfection" dataType="string"></dxi-column>

    <dxi-column caption="C-In Location" dataField="getCheckInLocation" dataType="string"></dxi-column>

    <dxi-column caption="C-Out Location" dataField="getCheckOutLocation" dataType="string"></dxi-column>

    <dxi-column
      caption="Between CI and CO Duration"
      dataField="getCiCoDurationValue"
      dataType="number"
      calculateSortValue="getCiCoDurationValue"
      calculateDisplayValue="getCiCoDuration"></dxi-column>

    <dxi-column
      caption="C-In Duration"
      dataField="durationTime"
      dataType="number"
      calculateSortValue="durationTime"
      calculateDisplayValue="getCheckInDuration"></dxi-column>

    <dxi-column
      caption="C-Out Duration"
      dataField="checkout.durationTime"
      dataType="number"
      calculateSortValue="checkout.durationTime"
      calculateDisplayValue="getCheckOutDuration"></dxi-column>

    <dxi-column caption="C-In Odometer" dataField="odometer" dataType="number"></dxi-column>

    <dxi-column caption="C-Out Odometer" dataField="checkout.odometer" dataType="number"></dxi-column>

    <dxi-column caption="Daily Odometer" dataField="getDailyOdometer" dataType="number"></dxi-column>

    <dxi-column
      caption="Engine Idle Time"
      dataField="engineIdleRunDurationTime"
      dataType="number"
      calculateSortValue="engineIdleRunDurationTime"
      calculateDisplayValue="getEngineIdleTime"></dxi-column>

    <dxi-column caption="FM Comments" dataField="getFmComments" dataType="string"></dxi-column>

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'expiredCellTpl'">
      <span [title]="cellInfo?.value || [] | join: ', '">{{ cellInfo?.value?.length }}</span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'issuesCellTpl'">
      <span [title]="cellInfo?.value || [] | join: ', '">{{ cellInfo?.value?.length }}</span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'missingStuffCellTpl'">
      <span [title]="cellInfo?.value || [] | join: ', '">{{ cellInfo?.value?.length }}</span>
    </ng-container>
  </dx-data-grid>
</app-content-w-header>
