import { Pipe, PipeTransform } from '@angular/core';
import { Moment, utc } from 'moment';

@Pipe({
  name: 'utc',
})
export class UtcPipe implements PipeTransform {
  transform(value: Moment | Date | string | undefined, format: string): string {
    return value ? utc(value).utc().format(format) : '';
  }
}
