import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmployeeView } from 'src/app/shared/sdk';
//

@Component({
  selector: 'app-employee-ext-list',
  templateUrl: './employee-ext-list.component.html',
  styleUrls: ['./employee-ext-list.component.scss'],
})
export class EmployeeExtListComponent implements OnInit {
  @Output() mySelected: EventEmitter<EmployeeView[]> = new EventEmitter<EmployeeView[]>();

  constructor() {}

  ngOnInit() {}

  grid_onSelected(items: EmployeeView[]) {
    this.mySelected.emit(items);
  }
}

