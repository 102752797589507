import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { delay, exhaust, exhaustMap, tap, timeout } from 'rxjs/operators';
import { delayAsync } from '../../../../shared/classes/utils/promise.utils';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { VehicleDetailsComponent } from '../../components/vehicle-details/vehicle-details.component';
import { VehicleListComponent } from '../../components/vehicle-list/vehicle-list.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent extends ABaseComponent implements OnInit {
  $selectedIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  selectedId$: Observable<number> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(VehicleListComponent, { static: true }) grid: VehicleListComponent;
  @ViewChild(VehicleDetailsComponent, { static: true }) details: VehicleDetailsComponent;

  constructor(protected logger: LoggerService) {
    super(logger);

    this.selectedId$ = this.$selectedIds$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));

    this.$resize$
      .pipe(
        exhaustMap(() =>
          of(true).pipe(
            delay(1000),
            tap(() => {
              // this.grid.repaint();
              this.details.repaint();
            }),
          ),
        ),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(ids: number[]) {
    if (ids && ids.length) {
      this.$selectedIds$.next(ids);
    } else {
      this.$selectedIds$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}
