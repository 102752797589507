import { Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';

@Component({
  selector: 'app-vehicle-details-tab-document',
  templateUrl: './vehicle-details-tab-document.component.html',
  styleUrls: ['./vehicle-details-tab-document.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class VehicleDetailsTabDocumentComponent
  extends ABaseModelPaneWToolbarComponent<Vehicle>
  implements OnInit, OnDestroy
{
  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
  ) {
    super(logger, ui, dss);
    this.caption = 'Related Documents';
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Vehicle>> {
    return VehicleFormComponent;
  }

  protected buildToolbarItems(model: any = null): void {
    this.toolbarItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        text: this.caption,
      },
    ];
  }
}
