/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-hr-certs-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-hr-certs-grid/employee-hr-certs-grid.component.ngfactory";
import * as i3 from "../employee-hr-certs-grid/employee-hr-certs-grid.component";
import * as i4 from "../../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../../shared/modules/my-common/services/common.service";
import * as i7 from "../../../../../shared/modules/ui/services/ui.service";
import * as i8 from "../../../../../shared/modules/my-common/services/datasource.service";
import * as i9 from "../../../../../shared/modules/my-common/services/state-store.service";
import * as i10 from "../../../../../shared/modules/ui/services/grid-helper.service";
import * as i11 from "../../../services/helper.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "./employee-hr-certs-list.component";
var styles_EmployeeHrCertsListComponent = [i0.styles];
var RenderType_EmployeeHrCertsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeHrCertsListComponent, data: {} });
export { RenderType_EmployeeHrCertsListComponent as RenderType_EmployeeHrCertsListComponent };
export function View_EmployeeHrCertsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-hr-certs-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EmployeeHrCertsGridComponent_0, i2.RenderType_EmployeeHrCertsGridComponent)), i1.ɵdid(1, 245760, null, 0, i3.EmployeeHrCertsGridComponent, [i4.LoggerService, i5.ConfigService, i6.CommonService, i7.UiService, i8.DataSourceService, i9.StateStoreService, i10.GridHelperService, i11.HelperService, i12.MatDialog], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EmployeeHrCertsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-hr-certs-list", [], null, null, null, View_EmployeeHrCertsListComponent_0, RenderType_EmployeeHrCertsListComponent)), i1.ɵdid(1, 114688, null, 0, i13.EmployeeHrCertsListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeHrCertsListComponentNgFactory = i1.ɵccf("app-employee-hr-certs-list", i13.EmployeeHrCertsListComponent, View_EmployeeHrCertsListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { EmployeeHrCertsListComponentNgFactory as EmployeeHrCertsListComponentNgFactory };
