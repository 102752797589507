import {
  Facility,
  TripManifestRec
} from '../index';

declare var Object: any;
export interface TripManifestInterface {
  "requestID": string;
  "date": any;
  "weekday"?: number;
  "data"?: Array<any>;
  "meta"?: any;
  "daily_data"?: any;
  "serviceType"?: string;
  "isValidData"?: boolean;
  "dtime"?: Date;
  "id"?: number;
  "tenantId"?: number;
  tenant?: Facility;
  records?: TripManifestRec[];
}

export class TripManifest implements TripManifestInterface {
  "requestID": string;
  "date": any;
  "weekday": number;
  "data": Array<any>;
  "meta": any;
  "daily_data": any;
  "serviceType": string;
  "isValidData": boolean;
  "dtime": Date;
  "id": number;
  "tenantId": number;
  tenant: Facility;
  records: TripManifestRec[];
  constructor(data?: TripManifestInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `TripManifest`.
   */
  public static getModelName() {
    return "TripManifest";
  }

  /**
  * This method creates an instance of TripManifest for dynamic purposes.
  **/
  public static factory(data: TripManifestInterface): TripManifest{
    return new TripManifest(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TripManifest',
      plural: 'TripManifests',
      path: 'TripManifests',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'any'
        },
        "weekday": {
          name: 'weekday',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "daily_data": {
          name: 'daily_data',
          type: 'any',
          default: <any>null
        },
        "serviceType": {
          name: 'serviceType',
          type: 'string'
        },
        "isValidData": {
          name: 'isValidData',
          type: 'boolean',
          default: false
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        records: {
          name: 'records',
          type: 'TripManifestRec[]',
          model: 'TripManifestRec',
          relationType: 'embedsMany',
                  keyFrom: 'data',
          keyTo: 'id'
        },
      }
    }
  }
}
