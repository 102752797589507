<div class="menu-container">
  <dx-list
    width="100%"
    height="100%"

    [dataSource]="items$ | async"
    [grouped]="true"
    [collapsibleGroups]="true"
    [selectionMode]="'none'"
    (onContentReady)="list_onContentReady($event)"
    (onGroupRendered)="list_onGroupRendered($event)"
  >
    <div *dxTemplate="let item of 'group'" routerLink="{{ item.route }}" routerLinkActive="active">
      <div>
        <span class="icon-wrap" [matTooltip]="item.title"
          ><mat-icon [fontSet]="item.iconSet" [fontIcon]="'fa-' + item.fontIcon + ' fa-fw'" class="dx-icon"></mat-icon
        ></span>
        <span style="flex: auto">{{ item.title }}</span>
      </div>
    </div>

    <div *dxTemplate="let item of 'item'" routerLink="{{ item.route }}" routerLinkActive="active">
      <div>
        <span class="icon-wrap" [matTooltip]="item.title"
          ><mat-icon [fontSet]="item.iconSet" [fontIcon]="'fa-' + item.fontIcon + ' fa-fw'" class="dx-icon"></mat-icon
        ></span>
        <span style="flex: auto">{{ item.title }}</span>
      </div>
    </div>
  </dx-list>
</div>

<!--<mat-nav-list>-->

<!--<mat-accordion [displayMode]="'flat'" [multi]="true">-->
<!--<ng-container *ngFor="let item of items">-->
<!--<ng-container *ngIf="item?.acl | checkACL:myRoles">-->

<!--<ng-container *ngIf="item?.items?.length; else itemBlock">-->
<!--<mat-expansion-panel>-->
<!--<mat-expansion-panel-header>-->
<!--<mat-panel-title>-->
<!--<fa-icon [icon]="[item.iconSet, item.fontIcon]" [fixedWidth]="true" size="sm"-->
<!--style="margin-right: 0.2em"></fa-icon>-->
<!--{{ item.title }}-->
<!--</mat-panel-title>-->
<!--</mat-expansion-panel-header>-->

<!--<ng-container *ngFor="let groupItem of item.items">-->
<!--<ng-container *ngIf="groupItem?.acl | checkACL:myRoles">-->
<!--<a mat-list-item-->
<!--routerLink="{{groupItem.route}}"-->
<!--routerLinkActive="active selected"-->
<!--&gt;-->
<!--&lt;!&ndash;<mat-icon [fontSet]="groupItem.iconSet" [fontIcon]="'fa-' + groupItem.fontIcon + ' fw'" class="dx-icon"></mat-icon>&ndash;&gt;-->
<!--<fa-icon [icon]="[groupItem.iconSet, groupItem.fontIcon]" [fixedWidth]="true" size="sm"-->
<!--style="margin-right: 0.2em"></fa-icon>-->
<!--{{groupItem.title}}-->
<!--</a>-->
<!--</ng-container>-->
<!--</ng-container>-->

<!--</mat-expansion-panel>-->
<!--</ng-container>-->

<!--<ng-template #itemBlock>-->
<!--<a mat-list-item-->
<!--routerLink="{{item.route}}"-->
<!--routerLinkActive="active selected"-->
<!--&gt;-->
<!--&lt;!&ndash;<mat-icon [fontSet]="item.iconSet" [fontIcon]="'fa-' + item.fontIcon + ' fw'" class="dx-icon"></mat-icon>&ndash;&gt;-->
<!--<fa-icon [icon]="[item.iconSet, item.fontIcon]" [fixedWidth]="true" size="sm"-->
<!--style="margin-right: 0.2em"></fa-icon>-->
<!--{{item.title}}-->
<!--</a>-->
<!--</ng-template>-->

<!--</ng-container>-->
<!--</ng-container>-->
<!--</mat-accordion>-->

<!--</mat-nav-list>-->
