import {
  Facility,
  Employee,
  Vehicle,
  MyUser
} from '../index';

declare var Object: any;
export interface FuelCardInterface {
  "cardIssuer"?: string;
  "accountName"?: string;
  "accountNumber"?: string;
  "cardNumber"?: string;
  "cardName"?: string;
  "status"?: string;
  "usageType"?: string;
  "department"?: string;
  "embossLine1"?: string;
  "embossLine2"?: string;
  "embossLine3"?: string;
  "authorizationProfile"?: string;
  "prompting"?: string;
  "customVehicleAssetID"?: string;
  "assetType"?: string;
  "lastIssueDate"?: Date;
  "expirationDate"?: Date;
  "facilityAssignedDate"?: Date;
  "employeeAssignedDate"?: Date;
  "lastReportedDateTime"?: Date;
  "note"?: string;
  "meta"?: any;
  "dtime"?: Date;
  "id"?: any;
  "facilityId"?: number;
  "employeeId"?: number;
  "lastFacilityId"?: number;
  "lastEmployeeId"?: number;
  "lastVehicleId"?: number;
  "lastUsedUserId"?: number;
  facility?: Facility;
  employee?: Employee;
  lastReportedFacility?: Facility;
  lastReportedEmployee?: Employee;
  lastReportedVehicle?: Vehicle;
  lastUsedBy?: MyUser;
}

export class FuelCard implements FuelCardInterface {
  "cardIssuer": string;
  "accountName": string;
  "accountNumber": string;
  "cardNumber": string;
  "cardName": string;
  "status": string;
  "usageType": string;
  "department": string;
  "embossLine1": string;
  "embossLine2": string;
  "embossLine3": string;
  "authorizationProfile": string;
  "prompting": string;
  "customVehicleAssetID": string;
  "assetType": string;
  "lastIssueDate": Date;
  "expirationDate": Date;
  "facilityAssignedDate": Date;
  "employeeAssignedDate": Date;
  "lastReportedDateTime": Date;
  "note": string;
  "meta": any;
  "dtime": Date;
  "id": any;
  "facilityId": number;
  "employeeId": number;
  "lastFacilityId": number;
  "lastEmployeeId": number;
  "lastVehicleId": number;
  "lastUsedUserId": number;
  facility: Facility;
  employee: Employee;
  lastReportedFacility: Facility;
  lastReportedEmployee: Employee;
  lastReportedVehicle: Vehicle;
  lastUsedBy: MyUser;
  constructor(data?: FuelCardInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `FuelCard`.
   */
  public static getModelName() {
    return "FuelCard";
  }

  /**
  * This method creates an instance of FuelCard for dynamic purposes.
  **/
  public static factory(data: FuelCardInterface): FuelCard{
    return new FuelCard(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FuelCard',
      plural: 'FuelCards',
      path: 'FuelCards',
      idName: 'id',
      properties: {
        "cardIssuer": {
          name: 'cardIssuer',
          type: 'string'
        },
        "accountName": {
          name: 'accountName',
          type: 'string'
        },
        "accountNumber": {
          name: 'accountNumber',
          type: 'string'
        },
        "cardNumber": {
          name: 'cardNumber',
          type: 'string'
        },
        "cardName": {
          name: 'cardName',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "usageType": {
          name: 'usageType',
          type: 'string'
        },
        "department": {
          name: 'department',
          type: 'string'
        },
        "embossLine1": {
          name: 'embossLine1',
          type: 'string'
        },
        "embossLine2": {
          name: 'embossLine2',
          type: 'string'
        },
        "embossLine3": {
          name: 'embossLine3',
          type: 'string'
        },
        "authorizationProfile": {
          name: 'authorizationProfile',
          type: 'string'
        },
        "prompting": {
          name: 'prompting',
          type: 'string'
        },
        "customVehicleAssetID": {
          name: 'customVehicleAssetID',
          type: 'string'
        },
        "assetType": {
          name: 'assetType',
          type: 'string'
        },
        "lastIssueDate": {
          name: 'lastIssueDate',
          type: 'Date'
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "facilityAssignedDate": {
          name: 'facilityAssignedDate',
          type: 'Date'
        },
        "employeeAssignedDate": {
          name: 'employeeAssignedDate',
          type: 'Date'
        },
        "lastReportedDateTime": {
          name: 'lastReportedDateTime',
          type: 'Date'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "dtime": {
          name: 'dtime',
          type: 'Date',
          default: new Date(0)
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "lastFacilityId": {
          name: 'lastFacilityId',
          type: 'number'
        },
        "lastEmployeeId": {
          name: 'lastEmployeeId',
          type: 'number'
        },
        "lastVehicleId": {
          name: 'lastVehicleId',
          type: 'number'
        },
        "lastUsedUserId": {
          name: 'lastUsedUserId',
          type: 'number'
        },
      },
      relations: {
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        lastReportedFacility: {
          name: 'lastReportedFacility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'lastFacilityId',
          keyTo: 'id'
        },
        lastReportedEmployee: {
          name: 'lastReportedEmployee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'lastEmployeeId',
          keyTo: 'id'
        },
        lastReportedVehicle: {
          name: 'lastReportedVehicle',
          type: 'Vehicle',
          model: 'Vehicle',
          relationType: 'belongsTo',
                  keyFrom: 'lastVehicleId',
          keyTo: 'id'
        },
        lastUsedBy: {
          name: 'lastUsedBy',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'lastUsedUserId',
          keyTo: 'id'
        },
      }
    }
  }
}
