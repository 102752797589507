import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CookieBrowser } from '../../sdk';
import { ExtSdkModule } from '../ext-sdk/ext-sdk.module';

import { AuthGuard } from './guards/auth.guard';
import { DashboardGuard } from './guards/dashboard.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { AgePipe } from './pipes/age.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { IsoWeekdayPipe } from './pipes/iso-weekday.pipe';

import { JoinPipe } from './pipes/join.pipe';
import { LocalPipe } from './pipes/local.pipe';
import { LuhnPipe } from './pipes/luhn.pipe';
import { OnlyDigitsPipe } from './pipes/onlyDigits.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { ParseJsonPipe } from './pipes/parse-json.pipe';
import { PropPipe } from './pipes/prop.pipe';
import { QrCodePipe } from './pipes/qr-code.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { SignatureLinkPipe } from './pipes/signature-link.pipe';
import { SomeRolePipe } from './pipes/some-role.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { TrimArrPipe } from './pipes/trim-arr.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { UtcPipe } from './pipes/utc.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { TripPopupPipe } from './pipes/trip-popup.pipe';

import { CommonService } from './services/common.service';
import { ConfigService } from './services/config.service';
import { DataSourceService } from './services/datasource.service';
import { StateStoreService } from './services/state-store.service';
import { FileSignedUrlPipe } from './pipes/file-signed-url.pipe';
import { FrReceiptImgLinkPipe } from './pipes/fr-receipt-img-link.pipe';
import { PictureLinkPipe } from './pipes/picture-link.pipe';

@NgModule({
  imports: [CommonModule, HttpClientModule, ExtSdkModule],
  declarations: [
    SplitPipe,
    JoinPipe,
    TrimPipe,
    TrimArrPipe,
    ParseJsonPipe,
    LuhnPipe,
    YesNoPipe,
    OrderByPipe,
    QrCodePipe,
    PictureLinkPipe,
    SignatureLinkPipe,
    FrReceiptImgLinkPipe,
    FileSignedUrlPipe,
    UtcPipe,
    IsoWeekdayPipe,
    LocalPipe,
    AgePipe,
    HumanizePipe,
    TripPopupPipe,
    OnlyDigitsPipe,
    PropPipe,
    SomeRolePipe,
    ReversePipe,
  ],
  exports: [
    ExtSdkModule,

    SplitPipe,
    JoinPipe,
    TrimPipe,
    TrimArrPipe,
    ParseJsonPipe,
    LuhnPipe,
    YesNoPipe,
    OrderByPipe,
    QrCodePipe,
    PictureLinkPipe,
    SignatureLinkPipe,
    FrReceiptImgLinkPipe,
    FileSignedUrlPipe,
    UtcPipe,
    IsoWeekdayPipe,
    LocalPipe,
    AgePipe,
    HumanizePipe,
    TripPopupPipe,
    OnlyDigitsPipe,
    PropPipe,
    SomeRolePipe,
    ReversePipe,
  ],
  providers: [CookieBrowser],
})
export class MyCommonModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MyCommonModule,
      providers: [
        CommonService,
        ConfigService,
        DataSourceService,
        StateStoreService,

        AuthGuard,
        PendingChangesGuard,
        DashboardGuard,
      ],
    };
  }
}
