import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxMapComponent } from 'devextreme-angular/ui/map';
import DxMap from 'devextreme/ui/map';
import notify from 'devextreme/ui/notify';
import { chain } from 'lodash';
import { compact, entries, groupBy, merge, uniq } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import {
  Config,
  ConfigApi,
  Consumer,
  ConsumerApi,
  Facility,
  FacilityApi,
  LoggerService,
  LoopBackFilter,
  TripManifest,
  TripManifestApi,
} from '../../../../shared/sdk';

@Component({
  selector: 'app-manifest-map',
  templateUrl: './manifest-map.component.html',
  styleUrls: ['./manifest-map.component.scss'],
})
export class ManifestMapComponent extends ABaseComponent implements OnInit, AfterViewInit {
  markerBaseUrl = '/assets/images/';
  markers = [];
  routes = [];
  loading = true;
  @ViewChild(DxMapComponent, { static: false }) map: DxMapComponent;
  private $manifestId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private $recIds$: BehaviorSubject<{ old: any[]; new: any[] }> = new BehaviorSubject(null);
  private $reload$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private $markers$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
  ) {
    super(logger);
  }

  @Input()
  set manifestId(val: number) {
    if (this.$manifestId$.value !== val) {
      this.$manifestId$.next(val);
    }
  }

  @Input()
  set recIds(val: any[]) {
    this.$recIds$.next({ old: oc(this.$recIds$).value.new([]), new: oc(val)([]) });
  }

  ngOnInit() {
    super.ngOnInit();

    this.init();
  }

  ngAfterViewInit(): void {}

  map_onInitialized(e) {
    const _map = e.component as DxMap;

    // combineLatest([
    //   this.$markers$,
    // ]).pipe(
    //   switchMap(async ([markers]) => {
    //     console.log(markers);
    //
    //     await Promise.all(markers.del.map(m => _map.removeMarker(m)));
    //     await Promise.all(markers.add.map(m => _map.addMarker(m)));
    //   }),
    //   takeUntil(this.$onDestroy$),
    // ).subscribe();
  }

  init() {
    const fFilter: LoopBackFilter = {
      include: [
        {
          relation: 'contact',
          scope: {
            fields: ['id'],
            include: ['addresses', 'phones', 'emails'],
          },
        },
      ],
    } as LoopBackFilter;

    const cFilter: LoopBackFilter = {
      fields: { data: false },
      include: [
        {
          relation: 'person',
          scope: {
            fields: ['firstname', 'lastname', 'contactId'],
            include: [
              {
                relation: 'contact',
                scope: {
                  fields: ['id'],
                  include: ['addresses', 'phones', 'emails'],
                },
              },
            ],
          },
        },
      ],
    } as LoopBackFilter;

    combineLatest([
      this.$manifestId$.pipe(
        filter(manifestId => !!manifestId),
        switchMap(manifestId => this.dss.getApi<TripManifestApi>(TripManifest).findById<TripManifest>(manifestId)),
        // switchMap(manifest => {
        //   const records = oc(manifest).data([]);
        //
        //   return Promise.all([
        //     async () => manifest,
        //
        //     // this.dss.getApi<ConsumerApi>(Consumer)
        //     //   .find<Consumer>({
        //     //     ...cFilter, where: {
        //     //       id: {inq: uniq(compact(records.map(r => r.c)))},
        //     //     }
        //     //   }).toPromise(),
        //
        //     // this.dss.getApi<TripManifestApi>(TripManifest)
        //     //   .getBrokerTrips(manifest.id, uniq(compact(records.map(r => r.id)))).toPromise(),
        //   ]);
        // }),
      ),
      this.$recIds$,
      this.dss.getApi<ConfigApi>(Config).getAllDestinations(),
      this.config.tenant$.pipe(
        first(),
        switchMap(f => this.dss.getApi<FacilityApi>(Facility).findById<Facility>(f.id, { ...fFilter })),
      ),
      this.$reload$,
    ])
      .pipe(
        filter(([manifest]) => !!manifest),
        tap(() => (this.loading = true)),

        switchMap(([manifest, recIds, destinations, facility]) =>
          of(true).pipe(
            switchMap(async () => {
              // const toAdd = difference(oc(recIds).new([]), oc(recIds).old([]));
              // const toRemove = difference(oc(recIds).old([]), oc(recIds).new([]));

              const allRecs = oc(manifest).data([]);
              const filteredRecs = allRecs.filter(r => oc(recIds).new([]).includes(r.id));
              // const toAddRecords = allRecs.filter(r => toAdd.includes(r.id));
              // const toRemoveRecords = allRecs.filter(r => toRemove.includes(r.id));

              //

              const fAddress =
                oc(facility)
                  .contact.addresses([])
                  .find(a => isNil(a.label)) || oc(facility).contact.addresses([])[0];

              const fAddressStr = chain(['street', 'city', 'state', 'zip'])
                .map(k => oc(fAddress)[k]())
                .compact()
                .join(', ')
                .value();

              const fPhone =
                oc(facility)
                  .contact.phones([])
                  .find(p => isNil(p.label)) || oc(facility).contact.phones([])[0];

              //

              const [consumers, brokerTrips] = await Promise.all([
                this.dss
                  .getApi<ConsumerApi>(Consumer)
                  .find<Consumer>({
                    ...cFilter,
                    where: {
                      id: { inq: uniq(compact(filteredRecs.map(r => r.c))) },
                    },
                  })
                  .toPromise(),

                this.dss
                  .getApi<TripManifestApi>(TripManifest)
                  .getBrokerTrips(manifest.id, uniq(compact(filteredRecs.map(r => r.id))))
                  .toPromise(),
              ]);

              //

              const destMap = new Map(
                entries(groupBy(destinations, 'short')).map(([short, items]) => [short, merge({}, ...items)]),
              );
              const consMap = new Map(consumers.map(c => [c.id, c]));
              const tripsMap = new Map(brokerTrips.map(t => [t.tripId, t]));

              //

              return filteredRecs.map(r => {
                const rExt = {
                  ...r,
                  _consumer: consMap.get(r.c),
                  _brokerTrip: tripsMap.get(r.tId),
                  _originObj: destMap.get(r.o),
                  _destObj: destMap.get(r.d),
                  // _toAdd: toAdd.includes(r.id),
                  // _toRemove: toRemove.includes(r.id),
                };

                const mainAddress =
                  oc(rExt._consumer as Consumer)
                    .person.contact.addresses([])
                    .find(a => isNil(a.label)) || oc(rExt._consumer as Consumer).person.contact.addresses([])[0];

                const mainAddressStr = chain(['street', 'city', 'state', 'zip'])
                  .map(k => oc(mainAddress)[k]())
                  .compact()
                  .join(', ')
                  .value();

                const mainPhone =
                  oc(rExt._consumer as Consumer)
                    .person.contact.phones([])
                    .find(p => isNil(p.label)) || oc(rExt._consumer as Consumer).person.contact.phones([])[0];

                //

                const personName = rExt._brokerTrip
                  ? `${oc(rExt)._brokerTrip.person.first()} ${oc(rExt)._brokerTrip.person.last()}`
                  : `${oc(rExt)._consumer.person.firstname()} ${oc(rExt)._consumer.person.lastname()}`;

                const puLocation = rExt._brokerTrip
                  ? oc(rExt)._brokerTrip.from.geodata.location()
                  : rExt._originObj
                    ? rExt._originObj.address
                    : r.o === 'RESIDENCE'
                      ? mainAddress.location || mainAddressStr
                      : r.o === 'FACILITY'
                        ? fAddress.location || fAddressStr
                        : r.o;

                // prettier-ignore
                // @ts-ignore
                const puName = rExt._brokerTrip ? oc(rExt)._brokerTrip.from.name() || oc(rExt)._brokerTrip.from.street()
                  : rExt._originObj
                    ? (rExt._originObj.group ? rExt._originObj.group + ': ' : '') +
                      (rExt._originObj.name || rExt._originObj.address)
                    : r.o === 'RESIDENCE'
                      ? `RESIDENCE: ${mainAddressStr}`
                      : r.o === 'FACILITY'
                        ? `FACILITY: ${facility.name}<br/>${fAddressStr}`
                        : r.o;

                const doLocation = rExt._brokerTrip
                  ? oc(rExt)._brokerTrip.to.geodata.location()
                  : rExt._destObj
                    ? rExt._destObj.address
                    : r.d === 'RESIDENCE'
                      ? mainAddress.location || mainAddressStr
                      : r.d === 'FACILITY'
                        ? fAddress.location || fAddressStr
                        : r.d;

                // prettier-ignore
                // @ts-ignore
                const doName = rExt._brokerTrip ? oc(rExt)._brokerTrip.to.name() || oc(rExt)._brokerTrip.to.street()
                  : rExt._destObj
                    ? (rExt._destObj.group ? rExt._destObj.group + ': ' : '') +
                      (rExt._destObj.name || rExt._destObj.address)
                    : r.d === 'RESIDENCE'
                      ? `RESIDENCE: ${mainAddressStr}`
                      : r.d === 'FACILITY'
                        ? `FACILITY: ${facility.name}<br/>${fAddressStr}`
                        : r.d;

                // console.log('f:', fAddressStr, 'c:', mainAddressStr, 'pu:', puLocation, 'do:', doLocation);

                rExt._markerFrom = {
                  location: puLocation,
                  _type: rExt._originObj
                    ? rExt._originObj.group
                    : r.o === 'RESIDENCE'
                      ? 'RESIDENCE'
                      : r.o === 'FACILITY'
                        ? 'FACILITY'
                        : undefined,
                  _placeName: puName,
                  _personName: personName,
                };

                rExt._markerTo = {
                  location: doLocation,
                  _type: rExt._destObj
                    ? rExt._destObj.group
                    : r.d === 'RESIDENCE'
                      ? 'RESIDENCE'
                      : r.d === 'FACILITY'
                        ? 'FACILITY'
                        : undefined,
                  _placeName: doName,
                  _personName: personName,
                };

                // console.log('markers:', rExt._markerFrom, rExt._markerTo);

                return rExt;
              });
            }),

            map(recs => {
              const [
                markers,
                // markersToAdd,
                // markersToRemove,
              ] = [
                recs,
                // recs.filter((r) => r._toAdd),
                // recs.filter((r) => r._toRemove),
              ].map(_recs =>
                chain(_recs)
                  .map(r => [oc(r)._markerFrom(), oc(r)._markerTo()])
                  .flatten()
                  .filter(m => oc(m).location())
                  .groupBy(m => JSON.stringify(m.location))
                  .entries()
                  .map(([, pMarkers]) => ({
                    location: pMarkers[0].location,

                    tooltip:
                      pMarkers[0]._placeName + '<br/><hr/>' + uniq(pMarkers.map(m => m._personName)).join('<br/>'),

                    iconSrc: {
                      RESIDENCE: this.markerBaseUrl + 'marker-home.png',
                      FACILITY: this.markerBaseUrl + 'marker-adc.png',
                      SCHOOL: this.markerBaseUrl + 'marker-school.png',
                    }[pMarkers[0]._type || ''],
                  }))
                  .value(),
              );

              {
                const fAddress =
                  oc(facility)
                    .contact.addresses([])
                    .find(a => isNil(a.label)) || oc(facility).contact.addresses([])[0];

                const fAddressStr = chain(['street', 'city', 'state', 'zip'])
                  .map(k => oc(fAddress)[k]())
                  .compact()
                  .join(', ')
                  .value();

                if (fAddress) {
                  const fMarker = {
                    location: oc(fAddress).location() || fAddressStr,
                    tooltip: `FACILITY: ${facility.name}<br/>${fAddressStr}`,
                    iconSrc: this.markerBaseUrl + 'marker-adc.png',
                  };
                  markers.push(fMarker);
                  // markersToRemove.push(fMarker);
                  // markersToAdd.push(fMarker);
                }
              }

              // const routes = recs.map((r) => ({
              //   locations: [
              //     oc(r)._markerFrom.location(),
              //     oc(r)._markerTo.location(),
              //   ],
              // }));

              return {
                markers,
                // markersToRemove,
                // markersToAdd,
                // routes,
              };
            }),
            tap(
              ({
                markers,
                // markersToRemove,
                // markersToAdd,
                // routes,
              }) => {
                this.markers = markers.filter(m => oc(m).location());
                // this.routes = routes.filter(r => oc(r).locations([]).length > 1);

                // this.$markers$.next({del: markersToRemove, add: markersToAdd});

                // console.log('markers:', markers, markers.length);
                // console.log('routes:', routes, routes.length);
              },
            ),
          ),
        ),

        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => (this.loading = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
