import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import { Document, DocumentType, Employee, EmployeeApi, LoggerService, Person } from 'src/app/shared/sdk';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { StateStoreService } from 'src/app/shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';

import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import moment from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { DocumentFormComponent } from 'src/app/modules/document/components/document-form/document-form.component';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { DlgEmployeeDocumentGridComponent } from 'src/app/modules/employee/components/dlg-employee-document-grid/dlg-employee-document-grid.component';
import { LoadOptions } from 'devextreme/data/load_options';
import { API_BASE_URL } from 'src/app/config';
import { HelperService } from 'src/app/modules/employee/services/helper.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { EMPLOYEE_STATUSES } from '../../../classes/enums';

@Component({
  selector: 'app-employee-hr-certs-grid',
  templateUrl: './employee-hr-certs-grid.component.html',
  styleUrls: ['./employee-hr-certs-grid.component.scss'],
})
export class EmployeeHrCertsGridComponent extends ABaseComponent implements OnInit {
  statuses = EMPLOYEE_STATUSES;
  status = 'ACTIVE';
  filter = null;

  dso: DataSourceOptions;
  filterDso = [
    { title: 'Expried', value: [0, 'days'] },
    { title: 'Show Missing Documents', value: [0, '', 'missing'] },
    { title: 'Expiring Next Week', value: [1, 'weeks'] },
    { title: 'Expiring Next Two weeks', value: [2, 'weeks'] },
    { title: 'Expiring Next Month', value: [1, 'months'] },
    { title: 'Expiring Next Two Months', value: [2, 'months'] },
    { title: 'Expiring Next Three Months', value: [3, 'months'] },
    { title: 'Expiring Next Six Months', value: [6, 'months'] },
    { title: 'Expiring Next Year', value: [1, 'years'] },
  ];

  baseColumns = [
    { dataType: 'string', cellTemplate: 'add_cell', width: '55px' },
    { dataField: 'person.firstname', caption: 'First Name', dataType: 'string' },
    { dataField: 'person.lastname', caption: 'Last Name', dataType: 'string' },
    {
      dataField: 'status',
      caption: 'Status',
      dataType: 'string',
      lookup: { dataSource: EMPLOYEE_STATUSES, valueExpr: 'Name', displayExpr: 'ID' },
    },
    { dataField: 'employeePosition.name', caption: 'Position', dataType: 'string' },
    { dataField: 'person.dob', caption: 'Date Of Birth', dataType: 'date', format: 'shortDate' },
    { dataField: 'lastDate', caption: 'Last Date On Duty', dataType: 'date' },
  ];
  gridColumns: any[];
  columnsMap = {};
  lastDateOnDutyMap: any[];
  docTypes: any[];
  empls: any[];

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    private helper: HelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
    const self = this;

    this.buildDso();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_updateCellInfo(cellInfo) {
    const exist = !cellInfo.value || !(cellInfo.value.value || cellInfo.value.name);
    cellInfo.showBtn = exist;
    cellInfo.divClass =
      (exist && ((this.filter && this.filter[2] === 'missing' && this.isAlerted(cellInfo) && 'red') || 'gray')) || '';
    cellInfo.linkClass = this.isAlerted(cellInfo) ? 'yellow' : '';
    cellInfo.displayValue = (cellInfo.value && (cellInfo.value.name || this.dateFormat(cellInfo.value.value))) || '';
  }

  status_onSelectionChanged(e) {
    this.buildDso();
  }
  expiring_onSelectionChanged(e) {
    this.grid.instance.refresh();
  }

  addDoc_onClick(employee: Employee, col) {
    const { documentTypeId } = (col && this.columnsMap[col.caption]) || ({} as any);
    const name = new FullNamePipe(this.config).transform(employee);
    this.ui
      .openEditDialog({
        modelId: null,
        title: `${name} | New Document`,
        inputs: {
          objectType: Employee.getModelName(),
          objectId: employee.id,
          documentTypeId,
        },
        ModelClass: Document,
        FormComponentClass: DocumentFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(payroll => {
        if (payroll) this.buildDso();
      });
  }

  grid_exportFileName = () => `employees-ext-export-${moment().format('YYYY-MM-DD')}`;

  relatedDocs_onClick(employee: Employee) {
    const name = new FullNamePipe(this.config).transform(employee);
    void this.dialog
      .open<any, any, number>(DlgEmployeeDocumentGridComponent, {
        hasBackdrop: true,
        data: {
          title: `${name} | Related Documents`,
          model: employee,
        },
      })
      .afterClosed()
      .toPromise()
      .then(payroll => {
        this.buildDso();
      });
  }

  dateFormat = date => moment(date).format('MM/DD/YYYY');

  isAlerted = cellInfo => {
    const { expAlert, missingDocAlert } = this.columnsMap[cellInfo.column.caption] || ({} as any);
    return this.isFiltered(cellInfo.data, cellInfo.value, expAlert, missingDocAlert);
  };

  isFiltered = (employee, doc, expAlert, missingDocAlert) => {
    if (!this.filter) return false;
    const [i, key, type] = this.filter;
    if (type === 'missing') return employee.status === 'ACTIVE' && missingDocAlert && !doc;
    if (!doc || !doc.value || !expAlert) return false;
    const until = moment().add(i, key).format('YYYY-MM-DD');
    const d = moment(doc.value).format('YYYY-MM-DD');
    return d <= until;
  };

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      onGridRefresh: () => this.buildDso(),
      notifyErrors: true,
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  async buildDso() {
    this.empls = null;
    const docTypesFilter = { where: { inactive: 0, objectFQN: 'EMPLOYEE' }, include: ['customFields'] };
    const emplFilter = {
      where: { status: this.status },
      include: ['employeePosition', 'person', { relatedDocuments: ['files', 'customFieldValues', 'customFields'] }],
    };
    // const so = this.dss.getStoreOptions(Employee, Employee, false);
    // so.customFilter = {
    //   include: [
    //     'employeePosition',
    //     'person',
    //     {
    //       relation: 'relatedDocuments',
    //       scope: { include: ['files', 'customFieldValues', 'customFields'] },
    //     },
    //   ],
    //   where: { status: this.status },
    // };
    // const store = new CustomStore(so);
    const store = new CustomStore({ load: (loadOptions: LoadOptions) => [] });

    dxStoreLoadHooks(store, undefined, async (args: any[]) => {
      const makeEmpls = !this.empls;
      [this.lastDateOnDutyMap, this.docTypes, this.empls] = await Promise.all([
        this.lastDateOnDutyMap || this.helper.getLastDateOnDutyMap(),
        this.docTypes ||
          this.dss.getApi(DocumentType).find<DocumentType>(docTypesFilter, headersAllTenantsAppend).toPromise(),
        this.empls || this.dss.getApi<EmployeeApi>(Employee).find<Employee>(emplFilter).toPromise(),
      ]);

      // const docs = await this.dss.getApi(Document).find<Document>(docsFilter, headersAllTenantsAppend).toPromise();
      if (makeEmpls) {
        // let map = this.last.reduce((p, v) => ({ ...p, [v._id]: v }), {});
        const map = this.empls.reduce((p, { id, relatedDocuments }) => {
          const docs = relatedDocuments.reduce(
            (pr, { documentTypeId, customFields, customFieldValues, files, name }) => {
              const f = files.sort((a, b) => (a.id > b.id ? -1 : 1))[0];
              const lastFileId = f && f.id;
              const cfMap = (customFields || []).reduce((pcf, { id, field }) => ({ ...pcf, [id]: field }), {});
              const url = f && `${API_BASE_URL}/api/MyFiles/${f.id}/redirectToSignedUrl`;
              if (pr[`docName${documentTypeId}`] && pr[`docName${documentTypeId}`].lastFileId > lastFileId) return pr;
              return {
                ...pr,
                [`docName${documentTypeId}`]: { url, name: name.slice(0, 8), lastFileId },
                ...customFieldValues.reduce(
                  (pcf, { value, customFieldId }) => ({ ...pcf, [cfMap[customFieldId]]: { url, value } }),
                  {},
                ),
              };
            },
            {},
          );
          return {
            ...p,
            [id]: {
              ...{ ...p[id], ...docs },
              lastDate: this.lastDateOnDutyMap[id] && this.lastDateOnDutyMap[id].last.date,
            },
          };
        }, this.lastDateOnDutyMap);
        this.empls = this.empls.map(e => ({ ...e, ...map[e.id] }));
      }

      if (!this.gridColumns) {
        this.gridColumns = [...this.baseColumns];
        this.docTypes.forEach(({ name, customFields, id: documentTypeId, expAlert, missingDocAlert }) => {
          let dataField =
            (customFields.find(({ type }) => type === 'EXPIRATION_DATE') || {}).field || `docName${documentTypeId}`;
          const isExp = dataField !== `docName${documentTypeId}`;
          this.columnsMap[name] = { dataField, name, documentTypeId, expAlert, missingDocAlert };
          this.gridColumns.push({
            dataField,
            caption: name,
            ...((isExp && { dataType: 'date', format: 'shortDate' }) || { dataType: 'string' }),
            cellTemplate: 'exp_cell',
            calculateFilterExpression: (filterValue, selectedFilterOperation) => {
              if (!filterValue) return;
              return [
                `${dataField}.${isExp ? 'value' : 'name'}`,
                selectedFilterOperation || '=',
                isExp ? moment(filterValue).format('YYYY-MM-DD') : filterValue,
              ];
            },
            calculateDisplayValue: v => (v[dataField] && (v[dataField].name || v[dataField].value)) || '',
          });
        });
        this.grid_stateStoring = this.sss.buildOptions(`24bbfe0a-b13e-42ad-a1a4-22b77e1543f4`, 1000, 'local');
      }

      const columns = Object.values(this.columnsMap) as any[];
      if (this.filter)
        return [
          this.empls.filter(emp =>
            columns.find(({ dataField, expAlert, missingDocAlert }) =>
              this.isFiltered(emp, emp[dataField], expAlert, missingDocAlert),
            ),
          ),
        ];

      return [this.empls];
    });

    this.dso = { store } as DataSourceOptions;
  }
}
