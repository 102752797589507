<dx-data-grid width="auto" [dataSource]="dso" [remoteOperations]="false">
  <!-- Settings -->

  <dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowUpdating]="false"></dxo-editing>

  <dxo-export [enabled]="true" fileName="employee-perf-export"></dxo-export>

  <!-- Columns -->

  <dxi-column width="75px" caption="Date" dataField="date" dataType="date" sortOrder="desc" format="shortDate"></dxi-column>

  <dxi-column caption="Hours Worked" dataField="onDutyDuration" dataType="string"></dxi-column>

  <dxi-column caption="Miles Driven" dataField="distance" dataType="number"></dxi-column>

  <dxi-column caption="Driving Time" dataField="onDutyDrivingDuration" dataType="string"></dxi-column>

  <dxi-column caption="Idle Time" dataField="onDutyIdleDuration" dataType="string"></dxi-column>

  <dxi-column caption="Compliance" dataField="compliance" dataType="number" cellTemplate="geoTabComplianceEvents_cell"></dxi-column>

  <dxi-column caption="Safety" dataField="safety" dataType="number" cellTemplate="geoTabSafetyEvents_cell"></dxi-column>

  <dxi-column caption="Vehicles Used" dataField="internalId" dataType="string"></dxi-column>

  <dxi-column caption="Transports Performed" dataField="signaturesCount" dataType="string"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'geoTabComplianceEvents_cell'">
    <a *ngIf="cellInfo.data.compliance" class="link"
      [id]="'grid_compliance' + cellInfo.data.id"
      style="cursor: pointer"
      (click)="showEvents_onClick('#grid_compliance' + cellInfo.data.id, cellInfo.data, 'Compliance')"
      title="Click">{{cellInfo.data.compliance}}</a>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'geoTabSafetyEvents_cell'">
      <a *ngIf="cellInfo.data.safety" class="link"
        [id]="'details_safety' + cellInfo.data.id"
        style="cursor: pointer"
        (click)="showEvents_onClick('#details_safety' + cellInfo.data.id, cellInfo.data, 'Safety')"
        title="Click">{{cellInfo.data.safety}}</a>
  </ng-container>

</dx-data-grid>


<dx-popup [width]="700" [height]="280" [showTitle]="true" title="Events" [dragEnabled]="false" [closeOnOutsideClick]="true"
  [showCloseButton]="true" [(visible)]="eventsPopupVisible">
  <dxo-position at="bottom" my="center" [of]="eventsPopupPositionOf" collision="fit"></dxo-position>
  <div *dxTemplate="let data of 'content'">
    <ng-container *ngTemplateOutlet="popupContent"></ng-container>
  </div>
</dx-popup>

<ng-template #popupContent>
  <dx-data-grid [dataSource]="eventsDataSource" [height]="'100%'" [columnChooser]="{ enabled: false }" [searchPanel]="{ visible: false }"
      [hoverStateEnabled]="true" [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }" [pager]="{ visible: false }"
      [filterRow]="false">

      <dxi-column width="75px" caption="Date" dataField="dateTime" dataType="date" sortOrder="desc" format="shortTime"></dxi-column>

      <dxi-column width="75px" caption="Type" dataField="eventType" dataType="string"></dxi-column>

      <dxi-column width="175px" caption="Event" dataField="rule" dataType="string"></dxi-column>

      <dxi-column caption="Details" [calculateCellValue]="getDetailsCellValue"></dxi-column>

  </dx-data-grid>
</ng-template>