import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnyNotesPageModule } from 'src/app/shared/modules/ui/components/any-notes-page/any-notes-page.module';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { CloneLogListComponent } from './components/clone-log-list/clone-log-list.component';
import { CloneLogGridComponent } from './components/clone-log-grid/clone-log-grid.component';
import { CloneLogDetailsTabsComponent } from './components/clone-log-details-tabs/clone-log-details-tabs.component';
import { CloneLogDetailsTabCreatedSchedulesComponent } from './components/clone-log-details-tab-created-schedules/clone-log-details-tab-created-schedules.component';
import { CloneLogComponent } from './containers/clone-log/clone-log.component';
import { DlgManifestGridComponent } from './components/dlg-manifest-grid/dlg-manifest-grid.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule, AnyNotesPageModule],
  declarations: [
    CloneLogComponent,
    CloneLogListComponent,
    CloneLogGridComponent,
    CloneLogDetailsTabsComponent,
    CloneLogDetailsTabCreatedSchedulesComponent,
    DlgManifestGridComponent,
  ],
  exports: [CloneLogComponent],
  entryComponents: [CloneLogComponent, DlgManifestGridComponent],
})
export class CloneLogModule {}

