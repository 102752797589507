import { Inject, Injectable } from '@angular/core';
import { Document, DocumentType, DocumentView } from '../../../shared/sdk/models';
import { DocumentApi, DocumentViewApi, MyFileApi } from '../../../shared/sdk/services/custom';
import { UploadHelperService } from '../../../shared/modules/ui/services/upload-helper.service';
import groupBy from 'lodash-es/groupBy';
import { Observable } from 'rxjs';

@Injectable()
export class HelperService {
  displayExpr = (d: Document | DocumentView): string => {
    return d.name;
  };

  constructor(
    @Inject(UploadHelperService) protected uploadHelper: UploadHelperService,
    @Inject(DocumentApi) public api: DocumentApi,
    @Inject(DocumentViewApi) public viewApi: DocumentViewApi,
    @Inject(MyFileApi) public fileApi: MyFileApi,
  ) {}

  getDownloadUrl(d: DocumentView): Observable<string> {
    // return this.uploadHelper.getDownloadUrl(d.files_0_container, d.files_0_filename);
    return this.fileApi.getReadSignedUrl(d.files_0_id);
  }

  docTypeDSGrouping(docTypes: DocumentType[]) {
    return Object.entries(groupBy(docTypes, 'objectFQN')).map(([key, items]) => ({ key, items }));
  }
}
