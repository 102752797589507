import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee, LoggerService } from '../../../../../shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-dlg-routes-map',
  templateUrl: './dlg-routes-map.component.html',
  styleUrls: ['./dlg-routes-map.component.scss'],
})
export class DlgRoutesMapComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgRoutesMapComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public config: ConfigService,
  ) {}

  ngOnInit() {}
}

