import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import {
  Employee,
  Facility,
  FuelCard,
  FuelRefill,
  FuelRefillApi,
  FuelRefillView,
  LoggerService,
  Vehicle,
} from '../../../../shared/sdk';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment, { utc } from 'moment';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import CustomStore from 'devextreme/data/custom_store';
import isEmpty from 'lodash-es/isEmpty';
import { oc } from 'ts-optchain';
import notify from 'devextreme/ui/notify';
import escapeRegExp from 'lodash-es/escapeRegExp';
import uniq from 'lodash-es/uniq';
import {
  dxStoreLoadHooks,
  dxStoreTotalCountHooks,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { LoadOptions } from 'devextreme/data/load_options';
import { MatDialog } from '@angular/material/dialog';
import { DlgEditAmountComponent } from '../../dialogs/dlg-edit-amount/dlg-edit-amount.component';
import { PivotGridDataSourceField, PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import { DlgEditCardNumberComponent } from '../../dialogs/dlg-edit-card-number/dlg-edit-card-number.component';
import isNumber from 'lodash-es/isNumber';
import identity from 'lodash-es/identity';
import isString from 'lodash-es/isString';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-fuel-refill-grid',
  templateUrl: './fuel-refill-grid.component.html',
  styleUrls: ['./fuel-refill-grid.component.scss'],
})
export class FuelRefillGridComponent extends ABaseComponent implements OnInit, AfterViewInit {
  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'id', visible: false },
      { dataField: 'userId', visible: false },
      { dataField: 'employeeId', visible: false },
      { dataField: 'vehicleId', visible: false },
      { dataField: 'requestID', visible: false },
      { dataField: 'dateTime', visible: false },
      { dataField: 'date', visible: false },
      { dataField: 'vdate', visible: false },
      { dataField: 'fuelType', visible: false },
      { dataField: 'receiptImgFileId', visible: false },
      { dataField: 'meta', visible: false },
      { dataField: 'note', visible: false },
      { dataField: 'ctime', visible: false },
      { dataField: 'utime', visible: false },
      { dataField: 'dtime', visible: false },
      { dataField: 'dtime_str', visible: false },
      { dataField: 'meta', visible: false },

      { dataField: 'employee_tenantId', visible: false },
      { dataField: 'employee_status', visible: false },
      { dataField: 'employee_person_firstname', visible: false },
      { dataField: 'employee_person_lastname', visible: false },

      { dataField: '_fc', visible: false },

      { caption: 'Year', dataField: 'vdate', dataType: 'date', groupInterval: 'year', displayFolder: 'date' },
      { caption: 'Month', dataField: 'vdate', dataType: 'date', groupInterval: 'month', displayFolder: 'date' },
      {
        caption: 'Day of Week',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        area: 'column',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'day',
        displayFolder: 'date',
        selector: data => {
          const dateMoment = utc(data.vdate);
          return [dateMoment.format('MM/DD/YYYY'), dateMoment.format('dd')].join('-');
        },
      },

      {
        caption: 'Tenant',
        area: 'row',
        dataField: 'facility_shortname',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Driver',
        area: 'row',
        dataField: 'employee_person_fullname',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: 'Vehicle',
        area: 'row',
        dataField: 'vehicle_internalId',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },

      { caption: 'Fuel Card', area: 'row', dataField: 'cardNum', dataType: 'string', isMeasure: false, expanded: true },

      {
        caption: 'Amount',
        area: 'data',
        dataField: 'amount',
        dataType: 'number',
        format: {
          type: 'currency',
          precision: 2,
        },
        summaryType: 'sum',
        isMeasure: true,
      },
      {
        caption: 'Odometer',
        dataField: 'odometer',
        dataType: 'number',
        format: {
          useGrouping: true,
        } as any,
        summaryType: 'max',
        isMeasure: true,
      },
    ];
  }

  gridDso: DataSourceOptions;
  pivotDso: PivotGridDataSourceOptions;

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;
  pivot_stateStoring: any;
  filterValue: any[] = [];

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();
  facilityId?: number;
  driverId?: number;
  vehicleId?: number;
  cardName?: string;
  cardNumber?: string;
  amount?: number;

  popover = {};
  popup = {};
  pageTotal = 0;

  selectedTabIndex = 0;
  tabs: any[] = [
    {
      title: 'Grid',
      template: 'grid',
    },
    {
      title: 'Pivot',
      template: 'pivot',
    },
  ];

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild(DxPivotGridComponent, { static: false }) pivot: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    public employeeHelper: EmployeeHelperService,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '80133a40-7a9a-471a-8fb8-2fc9cf41ca24',
    };
    this.pivot_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '19380b60-8257-4aa0-96c0-9793ff7a57f7',
    };

    this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(identity),
        tap(() => this.grid.instance.beginCustomLoading('...')),
        switchMap(() =>
          !isEmpty(this.cardName)
            ? this.dss
                .getApi(FuelCard)
                .findOne<FuelCard>({ where: { cardName: { regexp: `/${escapeRegExp(this.cardName)}/i` } } })
                .pipe(catchError(err => of(null)))
            : of(null),
        ),
        tap((fc: FuelCard | null) => {
          this.cardNumber = oc(fc).cardNumber();

          if (this.selectedTabIndex === 0) this.grid.instance.refresh();
          else if (this.selectedTabIndex === 1) this.pivot.instance.getDataSource().reload();
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {}

  private buildFilter() {
    const from = this.selectedFromValue;
    const to = this.selectedToValue;

    const fromMoment = from && moment(from);
    const toMoment = to && moment(to).add(1, 'days');

    const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
    const strTo = toMoment && toMoment.format('YYYY-MM-DD');

    const fltr = {
      fromInc: strFrom,
      toExcl: strTo,

      facilityId: this.facilityId,
      driverId: this.driverId,
      vehicleId: this.vehicleId,

      cardName: this.cardName,
      cardNumber: this.cardNumber,
      amount: this.amount,
    };

    return [
      ...(fltr.fromInc ? [['vdate', '>=', utc(fltr.fromInc).utc().toDate().toISOString()]] : []),
      ...(fltr.toExcl ? [['vdate', '<', utc(fltr.toExcl).utc().toDate().toISOString()]] : []),

      ...(!isEmpty(fltr.cardNumber) ? [['cardNum', '=', fltr.cardNumber]] : []),
      ...(!isNaN(fltr.amount) ? [['amount', '=', fltr.amount]] : []),

      ...(fltr.facilityId ? [['employee_tenantId', '=', fltr.facilityId]] : []),
      ...(fltr.driverId ? [['employeeId', '=', fltr.driverId]] : []),
      ...(fltr.vehicleId ? [['vehicleId', '=', fltr.vehicleId]] : []),
    ];
  }

  private buildDataSource() {
    const store = this.dss.getStore(FuelRefillView, undefined, false);

    dxStoreLoadHooks(
      store,
      async (obj: LoadOptions) => {
        const filterValue = this.buildFilter();
        if (filterValue.length) {
          obj.filter = [filterValue, 'and', obj.filter];
        }
        return [obj];
      },
      async ([obj], [recs]: [any[]]) => {
        const cardNums = uniq(recs.map(fr => fr.cardNum));

        const fcs = await this.dss
          .getApi(FuelCard)
          .find<FuelCard>({ where: { cardNumber: { inq: cardNums } } })
          .toPromise();

        recs.forEach(fr => {
          fr._fc = fcs.find(fc => fc.cardNumber === fr.cardNum);
        });

        return [recs];
      },
    );

    dxStoreTotalCountHooks(
      store,
      async (obj: LoadOptions) => {
        const filterValue = this.buildFilter();
        if (filterValue.length) {
          obj.filter = [filterValue, 'and', obj.filter];
        }
        return [obj];
      },
      async ([obj], [res]: [any[]]) => {
        return [res];
      },
    );

    this.pivotDso = {
      store,
      fields: this.pivotFields,
    } as PivotGridDataSourceOptions;

    this.gridDso = {
      store,
      sort: [{ selector: 'dateTime', desc: true }],
      postProcess: (data: any[]) => {
        this.pageTotal = data.map(i => i.amount).reduce((sum, i) => sum + i, 0);
        return data;
      },
    } as DataSourceOptions;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filterDs() {
    this.$filterEvent$.next(true);
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);

    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  grid_onInitialized(e) {
    this.filterValue = [];
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift();
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      // this.logger.log(e);
      e.items = [
        {
          text: 'Edit Amount',
          onItemClick: () => {
            void this.dialog
              .open<any, any, number>(DlgEditAmountComponent, {
                hasBackdrop: true,
                data: {
                  item: e.row.data,
                  value: e.row.data.amount,
                },
              })
              .afterClosed()
              .pipe(
                filter(amount => isNumber(amount)),
                switchMap(async amount => {
                  try {
                    this.ui.showLoading();

                    await this.dss
                      .getApi<FuelRefillApi>(FuelRefill)
                      .patchAttributes(e.row.key, { amount }, headersAllTenantsAppend)
                      .toPromise();

                    if (this.selectedTabIndex === 0) this.grid.instance.refresh();
                    else if (this.selectedTabIndex === 1) this.pivot.instance.getDataSource().reload();
                  } finally {
                    this.ui.hideLoading();
                  }
                }),
                catchError(err => of(notify(err.message || err, 'error', 5000))),
                tap(() => this.ui.hideLoading()),
                takeUntil(this.$onDestroy$),
              )
              .subscribe();
          },
        },
        {
          text: 'Edit Card Number',
          onItemClick: () => {
            void this.dialog
              .open<any, any, string>(DlgEditCardNumberComponent, {
                hasBackdrop: true,
                data: {
                  item: e.row.data,
                  value: e.row.data.cardNum,
                },
              })
              .afterClosed()
              .pipe(
                filter(cardNum => isString(cardNum)),
                switchMap(async cardNum => {
                  try {
                    this.ui.showLoading();

                    await this.dss
                      .getApi<FuelRefillApi>(FuelRefill)
                      .patchAttributes(e.row.key, { cardNum }, headersAllTenantsAppend)
                      .toPromise();

                    if (this.selectedTabIndex === 0) this.grid.instance.refresh();
                    else if (this.selectedTabIndex === 1) this.pivot.instance.getDataSource().reload();
                  } finally {
                    this.ui.hideLoading();
                  }
                }),
                catchError(err => of(notify(err.message || err, 'error', 5000))),
                tap(() => this.ui.hideLoading()),
                takeUntil(this.$onDestroy$),
              )
              .subscribe();
          },
        },
      ];
    }
  }

  pivot_onContextMenuPreparing(e) {
    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'D') {
      // console.log(e);

      const value = e.cell.value;
      if (!isNaN(value)) {
      }
    }
  }

  grid_onCellPrepared(e) {
    // console.log(e);
  }

  grid_onSaved(e) {
    // console.log(e);
  }

  calculateCustomSummary(options) {
    if (options.name === 'AmountRowsSummary') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.amount;
      }
    }
  }
}
