import {
  Facility,
  Person
} from '../index';

declare var Object: any;
export interface FacilityEmPersonInterface {
  "relation"?: string;
  "dtime"?: Date;
  "id"?: number;
  "facilityId"?: number;
  "personId"?: number;
  facility?: Facility;
  person?: Person;
}

export class FacilityEmPerson implements FacilityEmPersonInterface {
  "relation": string;
  "dtime": Date;
  "id": number;
  "facilityId": number;
  "personId": number;
  facility: Facility;
  person: Person;
  constructor(data?: FacilityEmPersonInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `FacilityEmPerson`.
   */
  public static getModelName() {
    return "FacilityEmPerson";
  }

  /**
  * This method creates an instance of FacilityEmPerson for dynamic purposes.
  **/
  public static factory(data: FacilityEmPersonInterface): FacilityEmPerson{
    return new FacilityEmPerson(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FacilityEmPerson',
      plural: 'FacilityEmPeople',
      path: 'FacilityEmPeople',
      idName: 'id',
      properties: {
        "relation": {
          name: 'relation',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
      },
      relations: {
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
                  keyFrom: 'personId',
          keyTo: 'id'
        },
      }
    }
  }
}
