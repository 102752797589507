import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
//
import { Consumer, LoggerService, LoopBackFilter, Person } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-consumer-details',
  templateUrl: './consumer-details.component.html',
  styleUrls: ['./consumer-details.component.scss'],
  providers: [HelperService],
})
export class ConsumerDetailsComponent
  extends ABaseModelLoaderComponent<Consumer>
  implements OnInit, OnChanges, OnDestroy
{
  constructor(protected logger: LoggerService, protected dss: DataSourceService, private helper: HelperService) {
    super(logger, dss);
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['person'],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, Person];
  }
}
