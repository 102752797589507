<dx-box direction="col">
  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
    <h1>You have {{ alertCount }} Active Alerts</h1>
  </dxi-item>

  <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
    <dx-list [grouped]="true" [items]="alerts$ | async"> </dx-list>
  </dxi-item>

  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="0">
    <dx-load-panel
      [position]="position"
      [(visible)]="loadingVisible"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [closeOnOutsideClick]="false">
    </dx-load-panel>
  </dxi-item>
</dx-box>
