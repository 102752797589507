<ng-container [formGroup]="formArray">
  <div class="dx-fieldset-header" *ngIf="controlsCount > 0">Custom Fields</div>

  <ng-container *ngFor="let ctrl of formArray.controls; let idx = index" [formGroupName]="idx + ''">
    <ng-container *ngTemplateOutlet="cfTpl; context: { $implicit: cf(idx), idx: idx }"></ng-container>

    <ng-template #cfTpl let-customField let-idx="idx">
      <div class="dx-field">
        <div class="dx-field-label">{{ customField?.label }}</div>
        <div class="dx-field-value" [ngSwitch]="customField?.type">
          <dx-text-box *ngSwitchCase="'STRING'" [formControlName]="customField?.field">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-text-box>

          <dx-date-box *ngSwitchCase="'DATE'" [formControlName]="customField?.field" type="date">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-date-box>

          <dx-date-box *ngSwitchCase="'EXPIRATION_DATE'" [formControlName]="customField?.field" type="date">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-date-box>

          <dx-date-box *ngSwitchCase="'TIME'" [formControlName]="customField?.field" type="time">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-date-box>

          <dx-date-box *ngSwitchCase="'DATETIME'" [formControlName]="customField?.field" type="datetime">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-date-box>

          <dx-number-box *ngSwitchCase="'NUMBER'" [formControlName]="customField?.field">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-number-box>

          <dx-check-box *ngSwitchCase="'CHECKBOX'" [formControlName]="customField?.field">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-check-box>

          <dx-select-box
            *ngSwitchCase="'DROPDOWN'"
            [formControlName]="customField?.field"
            [dataSource]="customField?.meta?.items || []">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-select-box>

          <ng-container *ngSwitchCase="'WEEKDAY_NUMBER_MAP'">
            <app-weekday-number-box [formControlName]="customField?.field"></app-weekday-number-box>

            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"
              [adapter]="weekdayNumberMap_adapterConfig(ctrl.get(customField?.field))"></dx-validator>
          </ng-container>

          <dx-text-box *ngSwitchDefault [formControlName]="customField?.field">
            <dx-validator
              [name]="customField?.label"
              [validationRules]="validationRulesFromField(customField)"></dx-validator>
          </dx-text-box>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
