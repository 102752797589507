export const BACKUP_DRIVER = 'BACKUP_DRIVER';
export const FLEET_MANAGER = 'FLEET_MANAGER';
export const SCHEDULE_STATUSES: any[] = [
  { ID: 'DRIVER', Name: 'Driver' },
  { ID: BACKUP_DRIVER, Name: 'Backup Driver' },
  { ID: 'ESCORT', Name: 'Escort' },
  { ID: 'MANAGER', Name: 'Manager' },
  { ID: FLEET_MANAGER, Name: 'Fleet Manager' },
];

export const SCHEDULE_MODES: any[] = [
  { ID: 'AUTO', Name: 'Auto' },
  { ID: 'MANUAL', Name: 'Manual' },
];

export const SCHEDULE_START_PREPARE_DURATION = 40; // minutes
export const SCHEDULE_FINISH_PREPARE_DURATION = 40; // minutes

