import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-show-json',
  templateUrl: './dlg-show-json.component.html',
  styleUrls: ['./dlg-show-json.component.scss'],
})
export class DlgShowJsonComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DlgShowJsonComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      json: any;
    },
  ) {}

  ngOnInit() {}
}
