import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { DlgVehicleDocumentGridComponent } from '../vehicle/components/dlg-vehicle-document-grid/dlg-vehicle-document-grid.component';
import { VehicleGridComponent } from './components/vehicle-grid/vehicle-grid.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
//
import { VehicleHRCertsComponent } from './containers/vehicle-hr-certs/vehicle-hr-certs.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule],
  declarations: [VehicleHRCertsComponent, VehicleListComponent, VehicleGridComponent],
  exports: [VehicleHRCertsComponent],
  entryComponents: [VehicleHRCertsComponent, DlgVehicleDocumentGridComponent],
})
export class VehicleHRCertsModule {}
