<dx-map
  *ngIf="!loading; else loadingTpl"
  #map
  width="100%"
  height="100%"
  provider="google"
  [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
  [controls]="true"
  [autoAdjust]="true"
  [zoom]="13"
  [markers]="markers"
  [routes]="routes"
  (onInitialized)="map_onInitialized($event)"></dx-map>

<ng-template #loadingTpl>
  <div style="display: flex; height: 100%">
    <div style="margin: auto">
      <dx-load-indicator></dx-load-indicator>
    </div>
  </div>
</ng-template>
