import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-position',
  templateUrl: './employee-position.component.html',
  styleUrls: ['./employee-position.component.scss'],
})
export class EmployeePositionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
