import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoggerService, MyUserApi } from '../../../../shared/sdk';
import { Facility, LoopBackFilter } from '../../../../shared/sdk/models';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { FACILITY_TYPES } from '../../classes/enums';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-facility-form',
  templateUrl: './facility-form.component.html',
  styleUrls: ['./facility-form.component.scss'],
})
export class FacilityFormComponent extends ABaseFormComponent<Facility> {
  types = FACILITY_TYPES;
  isSU$: Observable<boolean>;

  labels$: Observable<string[]> = of([]);

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Facility>,
    public config: ConfigService,
    private userApi: MyUserApi,
  ) {
    super(logger, fb, dss, helper);

    this.setState(FORM_STATE.COLLAPSED);

    this.isSU$ = this.userApi.getCurrent().pipe(switchMap(u => this.userApi.isSU(u.id)));

    this.labels$ = this.config.tenantType$.pipe(
      map(type => {
        return [
          ...(type === 'BASE' ? ['COO', 'Safety Director', 'Fleet Manager'] : []),
          'Director Contact',
          'Main SA Contact',
          'MERCY Account Executive Contact',
          'MERCY Transportation Supervisor Contact',
        ];
      }),
    );
  }

  get addressesFormArray(): FormArray {
    return this.form.get('contact.addresses') as FormArray;
  }

  get phonesFormArray(): FormArray {
    return this.form.get('contact.phones') as FormArray;
  }

  get emailsFormArray(): FormArray {
    return this.form.get('contact.emails') as FormArray;
  }

  get emRelationsFormArray(): FormArray {
    return this.form.get('emRelations') as FormArray;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        { contact: ['addresses', 'phones', 'emails'] },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
      ],
    };
  }

  protected get ModelClass(): any {
    return Facility;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected async beforeSubmittingAsync(data: any): Promise<void> {
    await super.beforeSubmittingAsync(data);

    // force request location
    if (data.contact && data.contact.addresses) {
      data.contact.addresses.forEach(a => {
        a.location = null;
        a.meta = { hash: null };
      });
    }

    if (!this.model) {
      delete data.contact;
    }
  }

  protected buildForm(): void {
    this.formConfigMap.set('contact.addresses', {
      id: [],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [],
      label: [],
      location: [],
      meta: [],
      contactId: [],
    });

    this.formConfigMap.set('contact.phones', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('contact.emails', {
      id: [],
      label: [],
      value: ['', Validators.required],
      contactId: [],
    });

    this.formConfigMap.set('contact', {
      id: [],
      notes: [],
      addresses: this.fb.array([]),
      phones: this.fb.array([]),
      emails: this.fb.array([]),
    });

    this.formConfigMap.set('emRelations', {
      id: [],
      relation: [],
      facilityId: [],
      personId: ['', Validators.required],
    });

    this.formConfigMap.set('data', {
      mentorEmail: [],
    });

    this.formConfigMap.set('', {
      id: [],
      legalName: ['', Validators.required],
      name: ['', Validators.required],
      shortname: ['', Validators.required],
      type: [],
      notes: [],
      contactId: [],
      geotabZoneId: [],
      data: this.fb.group(this.formConfigMap.get('data')),
      contact: this.fb.group(this.formConfigMap.get('contact')),
      emRelations: this.fb.array([]),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }
}
