import {
  Employee,
  Facility,
  GeoPoint
} from '../index';

declare var Object: any;
export interface DriverScheduleInterface {
  "requestID": string;
  "status"?: string;
  "mode"?: string;
  "day": string;
  "date"?: Date;
  "driverId": number;
  "facilityId": number;
  "startFacilityId"?: number;
  "startTime"?: string;
  "startTimeCalculatedAt"?: Date;
  "startLocationAddress"?: string;
  "startLocationCoords"?: GeoPoint;
  "startLocationCalculated"?: string;
  "startTripLocation"?: string;
  "startTripLocationAddress"?: string;
  "startTripConsumerName"?: string;
  "startTripTime"?: string;
  "startTravelDuration"?: number;
  "startInstructions"?: string;
  "finishFacilityId"?: number;
  "finishTime"?: string;
  "finishTimeCalculatedAt"?: Date;
  "finishLocationAddress"?: string;
  "finishLocationCoords"?: GeoPoint;
  "finishLocationCalculated"?: string;
  "finishTripLocation"?: string;
  "finishTripLocationAddress"?: string;
  "finishTripConsumerName"?: string;
  "finishTripTime"?: string;
  "finishTravelDuration"?: number;
  "finishInstructions"?: string;
  "meta"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: any;
  employee?: Employee;
  facility?: Facility;
}

export class DriverSchedule implements DriverScheduleInterface {
  "requestID": string;
  "status": string;
  "mode": string;
  "day": string;
  "date": Date;
  "driverId": number;
  "facilityId": number;
  "startFacilityId": number;
  "startTime": string;
  "startTimeCalculatedAt": Date;
  "startLocationAddress": string;
  "startLocationCoords": GeoPoint;
  "startLocationCalculated": string;
  "startTripLocation": string;
  "startTripLocationAddress": string;
  "startTripConsumerName": string;
  "startTripTime": string;
  "startTravelDuration": number;
  "startInstructions": string;
  "finishFacilityId": number;
  "finishTime": string;
  "finishTimeCalculatedAt": Date;
  "finishLocationAddress": string;
  "finishLocationCoords": GeoPoint;
  "finishLocationCalculated": string;
  "finishTripLocation": string;
  "finishTripLocationAddress": string;
  "finishTripConsumerName": string;
  "finishTripTime": string;
  "finishTravelDuration": number;
  "finishInstructions": string;
  "meta": any;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": number;
  "id": any;
  employee: Employee;
  facility: Facility;
  constructor(data?: DriverScheduleInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DriverSchedule`.
   */
  public static getModelName() {
    return "DriverSchedule";
  }

  /**
  * This method creates an instance of DriverSchedule for dynamic purposes.
  **/
  public static factory(data: DriverScheduleInterface): DriverSchedule{
    return new DriverSchedule(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DriverSchedule',
      plural: 'DriverSchedules',
      path: 'DriverSchedules',
      idName: 'id',
      properties: {
        "requestID": {
          name: 'requestID',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "mode": {
          name: 'mode',
          type: 'string',
          default: 'AUTO'
        },
        "day": {
          name: 'day',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "driverId": {
          name: 'driverId',
          type: 'number'
        },
        "facilityId": {
          name: 'facilityId',
          type: 'number'
        },
        "startFacilityId": {
          name: 'startFacilityId',
          type: 'number'
        },
        "startTime": {
          name: 'startTime',
          type: 'string'
        },
        "startTimeCalculatedAt": {
          name: 'startTimeCalculatedAt',
          type: 'Date'
        },
        "startLocationAddress": {
          name: 'startLocationAddress',
          type: 'string'
        },
        "startLocationCoords": {
          name: 'startLocationCoords',
          type: 'GeoPoint'
        },
        "startLocationCalculated": {
          name: 'startLocationCalculated',
          type: 'string'
        },
        "startTripLocation": {
          name: 'startTripLocation',
          type: 'string'
        },
        "startTripLocationAddress": {
          name: 'startTripLocationAddress',
          type: 'string'
        },
        "startTripConsumerName": {
          name: 'startTripConsumerName',
          type: 'string'
        },
        "startTripTime": {
          name: 'startTripTime',
          type: 'string'
        },
        "startTravelDuration": {
          name: 'startTravelDuration',
          type: 'number'
        },
        "startInstructions": {
          name: 'startInstructions',
          type: 'string'
        },
        "finishFacilityId": {
          name: 'finishFacilityId',
          type: 'number'
        },
        "finishTime": {
          name: 'finishTime',
          type: 'string'
        },
        "finishTimeCalculatedAt": {
          name: 'finishTimeCalculatedAt',
          type: 'Date'
        },
        "finishLocationAddress": {
          name: 'finishLocationAddress',
          type: 'string'
        },
        "finishLocationCoords": {
          name: 'finishLocationCoords',
          type: 'GeoPoint'
        },
        "finishLocationCalculated": {
          name: 'finishLocationCalculated',
          type: 'string'
        },
        "finishTripLocation": {
          name: 'finishTripLocation',
          type: 'string'
        },
        "finishTripLocationAddress": {
          name: 'finishTripLocationAddress',
          type: 'string'
        },
        "finishTripConsumerName": {
          name: 'finishTripConsumerName',
          type: 'string'
        },
        "finishTripTime": {
          name: 'finishTripTime',
          type: 'string'
        },
        "finishTravelDuration": {
          name: 'finishTravelDuration',
          type: 'number'
        },
        "finishInstructions": {
          name: 'finishInstructions',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'driverId',
          keyTo: 'id'
        },
        facility: {
          name: 'facility',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'facilityId',
          keyTo: 'id'
        },
      }
    }
  }
}
