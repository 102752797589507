import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DxButtonComponent } from 'devextreme-angular/ui/button';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { PivotGridDataSourceField, PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import * as ExcelJS from 'exceljs';
import { Borders, Fill } from 'exceljs';
import saveAs from 'file-saver';
import { chunk, fill, flatten, groupBy, intersection, isEmpty } from 'lodash-es';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import moment, { utc } from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { environment } from '../../../../../environments/environment';
import { combinations } from '../../../../shared/classes/utils/object.utils';
import { discretizeDates, discretizeMonths } from '../../../../shared/classes/utils/time.utils';
import { colNumToA1 } from '../../../../shared/classes/utils/utils';
import {
  Consumer,
  ConsumerView,
  ConsumerViewApi,
  Facility,
  FacilityApi,
  FacilitySignatureStats,
  FacilitySignatureStatsApi,
  LoggerService,
  Signature,
  SignatureApi,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';

enum PivotCaption {
  Year = 'Year',
  Month = 'Month',
  Day_of_Week = 'Day of Week',
  Day = 'Day',

  Tenant = 'Tenant',
  ADC_ID = 'ADC ID',
  Eligibility = 'Eligibility',
  Client = 'Client',
  MCI = 'MCI',
  DOB = 'DOB',
  Program = 'Program',
  Internal_ID = 'Internal ID',
  Signatures_Collected = 'Signatures Collected',
}

@Component({
  selector: 'app-adc-stats',
  templateUrl: './adc-stats.component.html',
  styleUrls: ['./adc-stats.component.scss'],
})
export class AdcStatsComponent extends ABaseComponent implements OnInit {
  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'id', visible: false },
      { dataField: 'tenantId', visible: false },
      { dataField: 'consumerId', visible: false },
      { dataField: 'vdate', visible: false },

      {
        caption: PivotCaption.Year,
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'year',
        displayFolder: 'date',
      },
      {
        caption: PivotCaption.Month,
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'month',
        displayFolder: 'date',
      },
      {
        caption: PivotCaption.Day_of_Week,
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: PivotCaption.Day, area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: PivotCaption.Day,
        displayFolder: 'date',
        area: 'column',

        selector: data => {
          const dateMoment = utc(data.vdate);
          return [dateMoment.format('MM/DD/YYYY'), dateMoment.format('dd')].join('-');
        },
      },

      { caption: PivotCaption.Tenant, dataField: '__tenant', dataType: 'string', isMeasure: false, visible: false },
      {
        caption: PivotCaption.ADC_ID,
        dataField: '__facilityID',
        dataType: 'string',
        isMeasure: false,
        expanded: false,
      },

      {
        caption: PivotCaption.Eligibility,
        area: 'row',
        dataField: '__clientEligibility',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: PivotCaption.Client,
        area: 'row',
        dataField: '__client',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: PivotCaption.MCI,
        area: 'row',
        dataField: '__mci',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: PivotCaption.DOB,
        area: 'row',
        dataField: '__dob',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: PivotCaption.Program,
        area: 'row',
        dataField: '__program_name',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },
      {
        caption: PivotCaption.Internal_ID,
        dataField: '__internalID',
        dataType: 'string',
        isMeasure: false,
        expanded: true,
      },

      {
        caption: PivotCaption.Signatures_Collected,
        area: 'data',
        dataField: 'count',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },
    ];
  }

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      this.tenantName,
      'Attendance For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join(' - '),
    ].join(' ');
  }

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  tenantName: string;
  private clients: ConsumerView[] | undefined;

  dso = {
    store: new ArrayStore({ data: [] }),
    fields: this.pivotFields,
  } as PivotGridDataSourceOptions;

  facilityDso$: Observable<DataSourceOptions> = of([]);

  facilitySet: Set<string>;

  grid_stateStoring: any;

  showColumnTotals = false;
  showRowTotals = false;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  selectedFromValue?: Date = moment().startOf('month').toDate();
  selectedToValue?: Date = moment().toDate();

  adcAttendanceProgress = false;
  adcAttendance1ExcelProgress = false;
  adcAttendance2ExcelProgress = false;
  cacfpDailyProgress = false;
  showAllOrAttended: 'ATTND' | 'ALL' = 'ATTND';

  attendanceReportingModeMap = {
    basic: {
      lbl: 'Email ADC Signatures (Attendance Only)',
      settings: {},
    },
    atnd_meals: {
      lbl: 'Email ADC Signatures (Attendance & Meals)',
      settings: { withMeals: true },
    },
    'ext_time_in-out': {
      lbl: 'Email ADC Signatures (Attendance with Time In/Out)',
      settings: { withTimes: true },
    },
    'ext_time_in-out-wo-note': {
      lbl: 'Email ADC Signatures (Attendance with Time In/Out No Note)',
      settings: { withTimes: true, noNote: true },
    },
    'ext_time_in-out-total': {
      lbl: 'Email ADC Signatures (Attendance with Time In/Out/Total)',
      settings: { withTimes: true, withTotal: true },
    },
  };

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;
  @ViewChild('adcAttendanceBtn', { static: true }) adcAttendanceBtn: DxButtonComponent;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    protected logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    private pusher: PusherService,
    public common: CommonService,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '0e6992ff-038e-4dc8-85a1-4bb6578281d4',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.config.tenant$
      .pipe(
        tap(facility => (this.tenantName = facility.legalName)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    const months = discretizeMonths(fromInc, toExcl);
    const days = discretizeDates(fromInc, toExcl);

    return combineLatest([this.config.roles$, this.dss.getApi<FacilityApi>(Facility).find<Facility>()]).pipe(
      switchMap(([roles, facilities]) =>
        combineLatest([
          of(roles),
          of(facilities),

          // this.dss.getViewApi<ConsumerViewApi>(Consumer)
          //   .find<ConsumerView>({}, (headers: HttpHeaders) => {
          //     // if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
          //     //   headers = headersAllTenantsAppend(headers);
          //     // }
          //     return headers;
          //   }),

          this.dss.getApi<FacilitySignatureStatsApi>(FacilitySignatureStats).find<FacilitySignatureStats>(
            {
              where: {
                and: [
                  ...(fromInc ? [{ vdate: { gte: fromInc } }] : []),
                  ...(toExcl ? [{ vdate: { lt: toExcl } }] : []),
                ],
              },
            },
            (headers: HttpHeaders) => {
              // if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
              //   headers = headersAllTenantsAppend(headers);
              // }
              return headers;
            },
          ),
        ]),
      ),

      switchMap(async ([roles, facilities, adcSignStats]) => {
        this.facilitySet = new Set(facilities.map(f => f.shortname));
        const facilitiesMap = new Map(facilities.map(f => [f.id, f] as [number, Facility]));

        const cIds = uniq(adcSignStats.map(stat => stat.consumerId));
        const clients = flatten(
          await Promise.all(
            chunk(cIds, 40).map(chnk =>
              this.dss
                .getViewApi<ConsumerViewApi>(Consumer)
                .find<ConsumerView>(
                  {
                    where: { id: { inq: chnk } },
                  },
                  (headers: HttpHeaders) => {
                    // if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                    //   headers = headersAllTenantsAppend(headers);
                    // }
                    return headers;
                  },
                )
                .toPromise(),
            ),
          ),
        );
        const clientsMap = new Map(clients.map(c => [c.id, c] as [number, ConsumerView]));

        this.clients = clients;

        let allStats = [];

        if (this.showAllOrAttended === 'ALL') {
          allStats = combinations(clients, days).map(([c, d]: [ConsumerView, string]) => {
            const existed = adcSignStats.find(
              stat => stat.consumerId === c.id && utc(stat.vdate).utc().format('YYYY-MM-DD') === d,
            );

            return (
              existed || {
                tenantId: c.tenantId,
                consumerId: c.id,
                vdate: d,
                count: 0,
              }
            );
          });
        } else {
          allStats = adcSignStats;
        }

        allStats.forEach(stat => {
          const facility = facilitiesMap.get(stat.tenantId);
          const client = clientsMap.get(stat.consumerId);

          (stat as any).__tenant = oc(facility).shortname('UNKNOWN');
          (stat as any).__client = client ? this.consumerHelper.displayExpr(client, '$L, $F') : 'UNKNOWN';

          (stat as any).__mci = oc(client).mci('UNKNOWN');
          (stat as any).__dob = oc(client).person_dob('UNKNOWN');
          (stat as any).__facilityID = oc(client).facilityID('UNKNOWN');
          (stat as any).__internalID = oc(client).internalID('UNKNOWN');
          (stat as any).__clientEligibility = months
            .map(m => oc(oc(client).eligibility({}))[m].value('NONE'))
            .join('/');
          (stat as any).__program_name = oc(client).program_name('UNKNOWN');
          (stat as any).__adcDailyRate = oc(client).data.adcDailyRate();
        });

        return allStats;
      }),

      map(stats => {
        const aso: ArrayStoreOptions = {
          key: 'id',
          data: stats,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  onCellPrepared(e) {
    // console.log(e);

    if (e.area === 'data' && e.cell.columnType === 'D' && e.cell.rowType === 'D') {
      // console.log(e);

      const value = e.cell.value;
      if (!isNaN(value) && value > 0) {
        e.cellElement.style.backgroundColor = 'lightGreen';
      } else if (value === 0) {
        // e.cell.text = '';
      }
    }
  }

  onExporting(e) {}

  mealsReportEnabled(...reportIds: string[]) {
    return intersection(oc(reportIds)([]), oc(this.config).config.adcMealsReportingMode([])).length > 0;
  }

  getAdcAttendancePdf(e: any, settings: any): void {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    if (!(fromMoment && toMoment && toMoment.diff(fromMoment, 'day') < 33)) {
      return notify('Selected period should less or equal to 1 month', 'error', 5000);
    }

    of(true)
      .pipe(
        tap(() => (this.adcAttendanceProgress = true)),
        tap(() => notify('Result will be send to ' + this.common.auth.getCurrentUserData().email)),

        switchMap(() => this.emailAdcAttendancePdf(fromInc, toExcl, settings || {})),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.adcAttendanceProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  getDailyCacfpPdf(ver?: number) {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    const fromIncl = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    of(true)
      .pipe(
        tap(() => (this.cacfpDailyProgress = true)),

        switchMap(() =>
          this.pusher.rpc(
            'ADC_MEALS_REQ',
            {
              from: fromIncl,
              to: toExcl,
              settings:
                ver === 2
                  ? { showCacfpCode: true, showNote: true, secondStaffSign: true }
                  : ver === 3
                    ? { showCacfpCode2: true, secondStaffSign: true }
                    : {},
              useRunService: true,
            },
            false,
          ),
        ),
        tap(({ uri }) => window.open(uri)),

        catchError(err => of(notify(err.message || err, 'error', 8000))),
        tap(() => (this.cacfpDailyProgress = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  getAdcAttendanceStatsGrid(e, wDailyRate: boolean) {
    if (wDailyRate) this.adcAttendance2ExcelProgress = true;
    else this.adcAttendance1ExcelProgress = true;

    void this.buildDataSource()
      .pipe(
        switchMap(async as => {
          const _from = this.selectedFromValue;
          const _to = this.selectedToValue;

          const data: any[] = await as.load();

          // const dates = uniq(data.map(itm => itm.vdate)).sort();
          const dates = discretizeDates(_from, moment(_to).add(1, 'day'));
          const byYear = groupBy(dates, d => moment(d).format('YYYY'));
          const byMonth = groupBy(dates, d => moment(d).format('MMM'));

          const clients = uniqBy(data, 'consumerId')
            .map(itm => itm.__client)
            .sort();
          // const clients = (oc(this.clients)([])).map(c => this.consumerHelper.displayExpr(c, '$L, $F')).sort();

          const byClient = groupBy(data, '__client');
          const byDate = groupBy(data, 'vdate');

          const workbook = new ExcelJS.Workbook();
          workbook.calcProperties.fullCalcOnLoad = true;

          const ws = workbook.addWorksheet('', {
            views: [{ state: 'frozen', xSplit: 2, ySplit: 4 }],
            // pageSetup: {fitToPage: true, fitToWidth: 1},
          });

          ws.addRow(['', `${Object.keys(byMonth).join('-')} ${Object.keys(byYear).join('-')} Attendance`]);

          let curMonth;
          ws.addRow([
            '',
            '',
            ...dates.map(d => {
              if (moment(d).month() !== curMonth) {
                curMonth = moment(d).month();
                return moment(d).format('MMM');
              }
              return '';
            }),
          ]);
          ws.addRow(['', '', ...dates.map(d => moment(d).format('dd'))]);
          ws.addRow(['', '', ...dates.map(d => moment(d).format('D')), 'Total', wDailyRate ? 'Total ($)' : '']);

          let rowIdx = 0;
          for (const client of clients) {
            rowIdx++;

            const clientData = oc(byClient[client])([]);
            const byClientByDate = groupBy(clientData, 'vdate');

            ws.addRow([
              rowIdx,
              client,
              ...dates.map(d => oc(byClientByDate[d])[0].count()),
              // clientData.reduce((s, i) => s + i.count, 0),
              dates.length
                ? {
                    formula: [
                      '=SUM(',
                      colNumToA1(3),
                      4 + rowIdx,
                      ':',
                      colNumToA1(3 + dates.length - 1),
                      4 + rowIdx,
                      ')',
                    ].join(''),
                  }
                : '',
              // wDailyRate ? Number(clientData
              //   .reduce((s, i) => s + i.count * (isNaN(i.__adcDailyRate) ? 0 : i.__adcDailyRate), 0)
              //   .toFixed(2)) : '',
              dates.length
                ? {
                    formula: `=${colNumToA1(3 + dates.length)}${4 + rowIdx}*${oc(clientData)[0].__adcDailyRate(0)}`,
                  }
                : '',
            ]);
          }

          ws.addRow([
            '',
            'Grand Total',
            // ...dates.map(d => (oc(byDate[d])([])).reduce((s, i) => s + i.count, 0)),

            ...fill(Array(dates.length + 2), '').map((itm, idx) =>
              rowIdx
                ? {
                    formula: ['=SUM(', colNumToA1(3 + idx), 5, ':', colNumToA1(3 + idx), 5 + rowIdx - 1, ')'].join(''),
                  }
                : '',
            ),

            // data.reduce((s, i) => s + i.count, 0),

            // wDailyRate ? Number(data
            //   .reduce((s, i) => s + i.count * (isNaN(i.__adcDailyRate) ? 0 : i.__adcDailyRate), 0)
            //   .toFixed(2)) : '',
          ]);

          // region styles

          let curCol = ws.getColumn(1);
          curCol.width = 5;
          curCol.font = { size: 9 };

          curCol = ws.getColumn(2);
          curCol.width = 30;
          curCol.font = { size: 9 };

          for (let cidx = 0; cidx < dates.length; cidx++) {
            curCol = ws.getColumn(3 + cidx);
            curCol.width = 3;
            curCol.font = { size: 9 };
            curCol.alignment = { horizontal: 'center' };
          }

          curCol = ws.getColumn(dates.length + 3);
          curCol.width = 5;
          curCol.font = { size: 9 };

          curCol = ws.getColumn(dates.length + 4);
          curCol.width = 10;
          curCol.alignment = { horizontal: 'right' };
          curCol.font = { size: 9 };

          ws.getCell(1, 2).font = { size: 12 };

          // borders

          const fillOpts = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD0D0D0' },
          } as Fill;

          const bordersOpts = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          } as Borders;

          for (let cidx = 0; cidx < 1 + dates.length + 2; cidx++) {
            ws.getCell(3, 2 + cidx).fill = fillOpts;
            ws.getCell(3, 2 + cidx).border = bordersOpts;

            ws.getCell(4, 2 + cidx).fill = fillOpts;
            ws.getCell(4, 2 + cidx).border = bordersOpts;

            ws.getCell(ws.rowCount, 2 + cidx).fill = fillOpts;
            ws.getCell(ws.rowCount, 2 + cidx).border = bordersOpts;

            for (let ridx = 0; ridx < ws.rowCount - 4; ridx++) {
              ws.getCell(ridx + 5, 2 + cidx).border = bordersOpts;
              ws.getCell(ridx + 5, 1).border = { bottom: { style: 'thin' } } as Borders;

              ws.getCell(ridx + 5, 2 + dates.length + 1).fill = fillOpts;
              ws.getCell(ridx + 5, 2 + dates.length + 2).fill = fillOpts;
              ws.getCell(ridx + 5, 2 + dates.length + 2).numFmt =
                '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';

              if (!isEmpty(ws.getCell(2, 2 + cidx).value)) {
                ws.getCell(ridx + 5, 2 + cidx).border = { ...bordersOpts, left: { style: 'double' } } as Borders;
              }
            }
          }

          if (!wDailyRate) ws.spliceColumns(2 + dates.length + 2, 1);

          // endregion

          await workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              `${Object.keys(byMonth).join('-')} ${Object.keys(byYear).join('-')} ADC Attendance Stats Report.xlsx`,
            );
          });
        }),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => {
          if (wDailyRate) this.adcAttendance2ExcelProgress = false;
          else this.adcAttendance1ExcelProgress = false;
        }),
      )
      .toPromise();
  }

  emailAdcAttendancePdf(from: string, to: string, settings: any): Observable<void> {
    settings = { ...settings, baseUrl: environment.apiBaseUrl };
    return this.dss
      .getApi<SignatureApi>(Signature)
      .adcAttendanceRequestPdfJob(from, to, JSON.stringify(settings))
      .pipe(
        switchMap(jobId => this.pusher.requestResponse(jobId)),
        // tap(({ bucket, filePdf, uri }) => window.open(uri)),
      );
  }
}
