import { Component, Inject, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import sortBy from 'lodash-es/sortBy';
import { takeUntil } from 'rxjs/operators';
//
import { Document, Employee, LoggerService, LoopBackFilter, Person } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { DocumentFormComponent } from '../../../document/components/document-form/document-form.component';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';

@Component({
  selector: 'app-employee-document-grid',
  templateUrl: './employee-document-grid.component.html',
  styleUrls: ['./employee-document-grid.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class EmployeeDocumentGridComponent extends ABaseComponent implements OnDestroy {
  dso: DataSourceOptions;

  _model: Employee;
  @Input()
  set model(v: Employee) {
    this._model = v;

    this.dso = v ? this.buildDSO(v) : undefined;
  }

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
    private gridHelper: GridHelperService,
    @Inject(ConfigService) public config: ConfigService,
  ) {
    super(logger);

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([Document.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onEditingStart(e) {
    e.cancel = true;

    this.ui.openEditDialog({
      modelId: e.data.id,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
    });
  }

  grid_onToolbarPreparing(e: any): void {
    // console.log(e);

    e.toolbarOptions.items.unshift({
      name: 'addEmployeeDoc',
      widget: 'dxButton',
      locateInMenu: 'always',
      location: 'after',
      sortIndex: 30,
      // showText: 'inMenu',
      options: {
        // icon: '',
        text: 'Add Employee Doc',
        hint: 'Add Employee Doc',
        // disabled: !this.model,
        onClick: () => {
          if (!this._model) {
            return;
          }
          const name = new FullNamePipe(this.config).transform(this._model);
          this.ui.openEditDialog({
            modelId: null,
            title: `${name} New Document`,
            inputs: {
              objectType: Employee.getModelName(),
              objectId: this._model.id,
            },
            ModelClass: Document,
            FormComponentClass: DocumentFormComponent,
          });
        },
      },
    });

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  private buildDSO(model: Employee): DataSourceOptions {
    const filter = model
      ? [
          [
            ['objectId', '=', model.id],
            ['objectType', '=', Employee.getModelName()],
          ],
          'or',
          [
            ['objectId', '=', model.personId],
            ['objectType', '=', Person.getModelName()],
          ],
        ]
      : undefined;

    return {
      store: this.dss.getStore(Document, null, false),
      filter,
    } as DataSourceOptions;
  }
}
