
declare var Object: any;
export interface BaseContainerInterface {
  "id"?: number;
}

export class BaseContainer implements BaseContainerInterface {
  "id": number;
  constructor(data?: BaseContainerInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `BaseContainer`.
   */
  public static getModelName() {
    return "BaseContainer";
  }

  /**
  * This method creates an instance of BaseContainer for dynamic purposes.
  **/
  public static factory(data: BaseContainerInterface): BaseContainer{
    return new BaseContainer(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BaseContainer',
      plural: 'BaseContainers',
      path: 'BaseContainers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
