<!--<as-split direction="horizontal">-->
<!--  <as-split-area [size]="60">-->
<!--    <app-document-list (mySelected)="onSelected($event)"></app-document-list>-->
<!--  </as-split-area>-->
<!--  <as-split-area [size]="40">-->
<!--    <app-document-details [modelId]="selectedId$ | async"></app-document-details>-->
<!--  </as-split-area>-->
<!--</as-split>-->

<kendo-splitter>
  <kendo-splitter-pane [scrollable]='false'>
    <app-document-list (mySelected)='onSelected($event)'></app-document-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane [collapsible]='true' [scrollable]='false' size='40%'>
    <app-document-details [modelId]='selectedId$ | async'></app-document-details>
  </kendo-splitter-pane>
</kendo-splitter>
