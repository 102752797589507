import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-edit-odometer',
  templateUrl: './dlg-edit-odometer.component.html',
  styleUrls: ['./dlg-edit-odometer.component.scss'],
})
export class DlgEditOdometerComponent implements OnInit {
  value: {
    ci: boolean;
    co: boolean;
    ciOdo?: number;
    coOdo?: number;
  };

  constructor(
    private dialogRef: MatDialogRef<DlgEditOdometerComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    protected logger: LoggerService,
  ) {
    this.value = data;
  }

  ngOnInit() {}
}
