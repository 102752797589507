import { Component, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import sortBy from 'lodash-es/sortBy';
import { takeUntil } from 'rxjs/operators';
//
import { AnyNote, Document, LoggerService } from '../../../../../sdk';
import { DataSourceService } from '../../../../my-common/services/datasource.service';
import { ABaseFormComponent } from '../../abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../services/grid-helper.service';
import { UiService } from '../../../services/ui.service';
import { HelperService as DocumentHelperService } from 'src/app/modules/document/services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import { DocumentFormComponent } from 'src/app/modules/document/components/document-form/document-form.component';
import { HelperService } from 'src/app/modules/employee/services/helper.service';
import { AnyNoteFormComponent } from '../any-note-form/any-note-form.component';

const GENERIC_DOCUMENT_TYPE_ID = 130;

@Component({
  selector: 'app-any-notes-page-documents',
  templateUrl: './any-notes-page-documents.component.html',
  styleUrls: ['./any-notes-page-documents.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class AnyNotesPageDocumentsComponent extends ABaseModelPaneWToolbarComponent<AnyNote> implements OnDestroy {
  @Input() objectType: string;
  @Input() objectId: number;
  @Input() documentName: string;
  dso: DataSourceOptions;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
    private gridHelper: GridHelperService,
  ) {
    super(logger, ui, dss);
    this.caption = 'Related Documents';

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([Document.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  protected get ModelClass(): any {
    return AnyNote;
  }

  protected get FormComponent(): Type<ABaseFormComponent<AnyNote>> {
    return AnyNoteFormComponent;
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onEditingStart(e) {
    e.cancel = true;

    this.ui.openEditDialog({
      modelId: e.data.id,
      ModelClass: Document,
      FormComponentClass: DocumentFormComponent,
    });
  }

  grid_onToolbarPreparing(e: any): void {
    e.toolbarOptions.items.unshift({
      name: 'addDoc',
      widget: 'dxButton',
      locateInMenu: 'always',
      location: 'after',
      sortIndex: 30,
      options: {
        text: 'Add Doc',
        hint: 'Add Doc',
        onClick: () => {
          this.ui
            .openEditDialog({
              modelId: null,
              inputs: {
                objectType: this.objectType,
                objectId: this.objectId,
                documentTypeId: GENERIC_DOCUMENT_TYPE_ID,
                documentName: this.documentName,
              },
              ModelClass: Document,
              FormComponentClass: DocumentFormComponent,
            })
            .afterClosed()
            .toPromise()
            .then(async doc => {
              if (!doc) return;
              const store = this.dss.getStore(AnyNote);
              this.model.documents = [...new Set([doc.id, ...(this.model.documents || [])])];
              await store.update(this.model.id, { documents: this.model.documents });
            });
        },
      },
    });

    e.toolbarOptions.items = sortBy(e.toolbarOptions.items, ['sortIndex']);
  }

  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);
    this.dso = this.buildDSO(model);
  }

  private buildDSO(model: AnyNote): DataSourceOptions {
    const filter = ['id', 'inq', model.documents || []];
    return { store: this.dss.getStore(Document, null, false), filter } as DataSourceOptions;
  }
}
