<app-content-w-header header="Devices">
  <kendo-splitter orientation="vertical" class="content">
    <kendo-splitter-pane #mapPane [scrollable]="false" size="40%" [collapsible]="true" [collapsed]="true">
      <dx-map
        #map
        width="100%"
        height="100%"
        provider="google"
        [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
        [controls]="true"
        [autoAdjust]="autoAdjust"
        [zoom]="mapZoom"
        [center]="mapCenter"
        [markers]="markers"></dx-map>
    </kendo-splitter-pane>

    <kendo-splitter-pane [scrollable]="false">
      <dx-data-grid
        height="100%"
        width="100%"
        [dataSource]="ds"
        [errorRowEnabled]="true"
        [stateStoring]="grid_stateStoring"
        (onInitialized)="grid_onInitialized($event)"
        (onSelectionChanged)="grid_onSelectionChanged($event)"
        (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
      >
        <!--        (onCellDblClick)="grid_onCellDblClick($event)"-->

        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

        <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

        <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

        <!-- region Columns -->

        <dxi-column caption="Device UID" dataField="deviceUid" dataType="string" [allowEditing]="false"></dxi-column>

        <dxi-column caption="Label" dataField="label" dataType="string"></dxi-column>

        <dxi-column caption="Status" dataField="status" dataType="string">
          <dxo-lookup [dataSource]="['ACTIVE', 'INACTIVE']"></dxo-lookup>
        </dxi-column>

        <dxi-column caption="Phone Number" dataField="phoneNumber" dataType="string"></dxi-column>

        <dxi-column caption="Model Number" dataField="modelNumber" dataType="string"></dxi-column>

        <dxi-column caption="Serial Number" dataField="serialNumber" dataType="string"></dxi-column>

        <dxi-column caption="IMEI" dataField="imei" dataType="string"></dxi-column>

        <dxi-column caption="Connection" dataField="connection" dataType="string">
          <dxo-lookup [dataSource]="['Cell+WiFi', 'WiFi Only']"></dxo-lookup>
        </dxi-column>

        <dxi-column caption="Device">
          <dxi-column
            caption="API Level"
            dataField="deviceInfo.apiLevel"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Device"
            dataField="deviceInfo.device"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Model"
            dataField="deviceInfo.model"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Brand"
            dataField="deviceInfo.brand"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Installation Id"
            dataField="deviceInfo.installationId"
            dataType="string"
            [allowEditing]="false"></dxi-column>
        </dxi-column>

        <dxi-column caption="Local Time">
          <dxi-column
            caption="Local Time"
            dataField="getLocalTime"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Local Time Zone"
            dataField="deviceTime.timeZone"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Is Automatic Time Enabled"
            dataField="deviceTime.isAutomaticTimeEnabled"
            dataType="boolean"
            [allowEditing]="false"></dxi-column>
        </dxi-column>

        <dxi-column caption="Geolocation">
          <dxi-column
            caption="Geotab Truck#"
            dataField="geotabLocation.vehicleId"
            dataType="string"
            [allowEditing]="false">
            <dxo-lookup [dataSource]="vehicleDso" valueExpr="id" displayExpr="internalId"></dxo-lookup>
          </dxi-column>

          <dxi-column
            caption="Geotab Location"
            dataField="getGeotabLocation"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Geotab Location Time"
            dataField="geotabLocation.date"
            dataType="datetime"
            format="shortDateShortTime"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Tablet Location"
            dataField="getTabletLocation"
            dataType="string"
            [allowEditing]="false"></dxi-column>

          <dxi-column
            caption="Tablet Location Time"
            dataField="geolocation.date"
            dataType="datetime"
            format="shortDateShortTime"
            [allowEditing]="false"></dxi-column>
        </dxi-column>

        <dxi-column caption="SIM Card" dataField="sim" dataType="string">
          <dxo-lookup [dataSource]="['ACTIVE', 'SUSPENDED', 'NO SIM']"></dxo-lookup>
        </dxi-column>

        <dxi-column caption="Assigned Location" dataField="assignedLocation" dataType="string"></dxi-column>

        <dxi-column caption="Assigned Employee" dataField="assignedEmployee" editCellTemplate="driver_editCell">
          <dxo-lookup [dataSource]="employeeDso" valueExpr="id" [displayExpr]="empDisplayExpr"></dxo-lookup>
        </dxi-column>

        <dxi-column caption="Note" dataField="note" dataType="string"></dxi-column>

        <dxi-column
          caption="Last Time Online"
          dataField="lastRequest"
          dataType="date"
          format="shortDateShortTime"
          [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
          [allowEditing]="false"></dxi-column>
        <!--                [customizeText]="customizeDateTimeText"-->

        <dxi-column
          caption="Last User (DispatchApp)"
          dataField="lastDispatchAppUser"
          dataType="string"
          [allowEditing]="false"></dxi-column>

        <dxi-column
          caption="Last Dispatcher (DispatchApp)"
          dataField="lastDispatchAppUserId"
          dataType="string"
          [allowEditing]="false">
          <dxo-lookup
            [dataSource]="employeeDso"
            valueExpr="userId"
            [displayExpr]="employeeHelper.displayExpr"></dxo-lookup>
        </dxi-column>

        <dxi-column
          caption="Last User (DriverApp)"
          dataField="lastDriverAppUser"
          dataType="string"
          [allowEditing]="false"></dxi-column>

        <dxi-column
          caption="Last Driver (DriverApp)"
          dataField="lastDriverAppUserId"
          dataType="string"
          [allowEditing]="false">
          <dxo-lookup
            [dataSource]="employeeDso"
            valueExpr="userId"
            [displayExpr]="employeeHelper.displayExpr"></dxo-lookup>
        </dxi-column>

        <dxi-column
          caption="Driver App Ver"
          dataField="driverAppVer"
          dataType="string"
          [allowEditing]="false"></dxi-column>

        <dxi-column
          caption="Dispatch App Ver"
          dataField="dispatchAppVer"
          dataType="string"
          [allowEditing]="false"></dxi-column>

        <dxi-column caption="Deployment Group" dataField="deploymentGroup" dataType="string">
          <dxo-lookup [dataSource]="['GROUP #1', 'GROUP #2', 'GROUP #3']"></dxo-lookup>
        </dxi-column>

        <dxi-column
          caption="Last CI Vehicle"
          dataField="lastVehicle"
          dataType="string"
          [allowEditing]="false"></dxi-column>

        <dxi-column
          caption="Last CI Time"
          dataField="lastCIDateTime"
          dataType="date"
          format="shortDateShortTime"
          [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
          [allowEditing]="false"></dxi-column>
        <!--                [customizeText]="customizeDateTimeText"-->

        <!--    endregion    -->

        <!--  region Templates  -->

        <ng-container *dxTemplate="let cellInfo of 'driver_editCell'">
          <app-drop-down-grid-new
            [valueExpr]="'id'"
            [dataSource]="employeeDso"
            [grid_columns]="[
              {
                dataField: 'tenantId',
                caption: 'Tenant',
                lookup: {
                  dataSource: facilityDso,
                  valueExpr: 'id',
                  displayExpr: 'shortname'
                }
              },
              { dataField: 'person_firstname', caption: 'Firstname' },
              { dataField: 'person_lastname', caption: 'Lastname' }
            ]"
            [showClearButton]="true"
            [displayExpr]="employeeHelper.displayExpr"
            [value]="cellInfo.value"
            (valueChanged)="driverDropDown_onValueChanged(cellInfo, $event)"
            [popup_width]="350"
            [popup_height]="300"></app-drop-down-grid-new>
        </ng-container>

        <!--    endregion    -->

      </dx-data-grid>
    </kendo-splitter-pane>
  </kendo-splitter>
</app-content-w-header>
