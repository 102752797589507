import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigProps, ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PRINT_MODE } from '../../classes/enums';

@Component({
  selector: 'app-toolbar-print-btn',
  templateUrl: './toolbar-print-btn.component.html',
  styleUrls: ['./toolbar-print-btn.component.scss'],
})
export class ToolbarPrintBtnComponent extends ABaseComponent implements OnInit {
  ctxMenuItems: any[];

  @Input() processing = false;

  @Output() action: EventEmitter<{ id: string; settings: any }> = new EventEmitter();

  constructor(
    protected logger: LoggerService,
    private config: ConfigService,
  ) {
    super(logger);
    this.buildContextMenu();
  }

  ctx_onAction = (data: any) => {
    this.action.emit({ id: data.id, settings: data.settings });
  };

  ngOnInit() {}

  private buildContextMenu() {
    const reportList = this.config.get(ConfigProps.reportList, []);

    this.ctxMenuItems = [
      {
        text: 'Print Attendance Report - All Clients',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM,
        settings: {
          hideCalcTimes: true,
          withPickUp: false,
          withDropOff: false,
          withSignatures: true,
          rowVehicleInfo: false,
          showInternalId: false,
          landscape: false,
        },
        disabled: !reportList.includes(PRINT_MODE.PRINT_ATTENDANCE_ALL),
      },

      // {
      //   text: 'Print Attendance Report - By Driver',
      //   icon: 'fas fa-print',
      //   id: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER,
      //   settings: {
      //     hideCalcTimes: true,
      //     withPickUp: false,
      //     withDropOff: false,
      //     withSignatures: true,
      //     rowVehicleInfo: false,
      //     showInternalId: false,
      //     landscape: false,
      //   },
      //   disabled: !reportList.includes(PRINT_MODE.PRINT_ATTENDANCE_BY_DRIVER),
      // },

      {
        beginGroup: true,
        text: 'Print Dispatcher`s Log',
        icon: 'fas fa-print',
        id: PRINT_MODE.DISPATCHER_LOG,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.DISPATCHER_LOG),
      },
      {
        text: 'Route Adherence Log',
        icon: 'fas fa-print',
        id: PRINT_MODE.ROUTE_ADHERENCE_LOG,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.ROUTE_ADHERENCE_LOG),
      },
      {
        beginGroup: true,
        text: 'Print Manifest',
        icon: 'fas fa-print',
        id: PRINT_MODE.MANIFEST,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.MANIFEST),
      },
      {
        text: 'Print Manifest By Driver',
        icon: 'fas fa-print',
        id: PRINT_MODE.MANIFEST_PER_DRIVER,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.MANIFEST_PER_DRIVER),
      },
      {
        beginGroup: true,
        text: 'Print ADC Signatures',
        icon: 'fas fa-print',
        id: PRINT_MODE.ATTENDANCE,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.ATTENDANCE),
      },
      {
        beginGroup: true,
        text: 'Print MERCY Signatures',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.MERCY),
      },
      {
        text: 'Print MERCY Signatures By Driver',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_PER_DRIVER,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.MERCY_PER_DRIVER),
      },
      {
        beginGroup: true,
        text: 'Print Vehicle Inspection Sheet',
        icon: 'fas fa-print',
        id: PRINT_MODE.VEHICLE_INSPECTION_SHEET,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.VEHICLE_INSPECTION_SHEET),
      },
      {
        beginGroup: true,
        text: 'Print Drivers Daily Bundle',
        icon: 'fas fa-print',
        id: PRINT_MODE.DRIVERS_DAILY_BUNDLE,
        settings: {},
        disabled: !reportList.includes(PRINT_MODE.DRIVERS_DAILY_BUNDLE),
      },
      {
        beginGroup: true,
        text: 'Print MERCY Signatures By Driver With Drop Off Time',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_PER_DRIVER_WITH_DROP_OFF,
        settings: { withDropOff: true, calcDropOff: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_PER_DRIVER_WITH_DROP_OFF),
      },
      {
        text: 'Print Mercy Signatures - All Clients No Times',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM,
        settings: { showFacilityID: false, hideAllTimes: true, hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_WO_TIMES),
      },
      {
        text: 'Mercy Signatures - All Clients (Times By Hand)',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM,
        settings: { showFacilityID: false, hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES),
      },
      {
        text: 'Mercy Signatures - By Driver (Times By Hand)',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER,
        settings: { showFacilityID: false, hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER_WO_CALC_TIMES),
      },
      {
        text: 'Selected Signatures - By Driver (Times By Hand)',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER,
        settings: { showFacilityID: false, hideCalcTimes: true, onlySelected: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER_WO_CALC_TIMES),
      },
      {
        text: 'Mercy Signatures - One Client (Times By Hand)',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE,
        settings: { showFacilityID: false, hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE),
      },
      {
        text: 'Mercy Signatures - One Client (Filled Up)',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED,
        settings: { showFacilityID: false, hideCalcTimes: false, withSignatures: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED),
      },
      {
        beginGroup: true,
        text: 'Sign-In Form - All Clients',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM,
        settings: { hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM),
      },
      {
        text: 'Sign-In Form - By Driver',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER,
        settings: { hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER),
      },
      {
        text: 'Sing-In Form - Empty Page',
        icon: 'fas fa-print',
        id: PRINT_MODE.MERCY_SIGN_IN_FORM_OVERALL,
        settings: { hideCalcTimes: true },
        disabled: !reportList.includes(PRINT_MODE.MERCY_SIGN_IN_FORM_OVERALL),
      },
      // {
      //   beginGroup: true,
      //   id: PRINT_MODE.MEALS_DELIVERY_SIGNATURE_NEW,
      //   text: 'Print Meals Delivery By Driver',
      //   icon: 'fas fa-print',
      //   settings: {},
      // },
      // {
      //   beginGroup: true,
      //   id: 'MEALS_BILLING_FILE__KEYSTONE',
      //   text: 'Meals Billing File - Keystone',
      //   icon: 'far fa-file-excel',
      //   settings: {},
      // }, {
      //   id: 'MEALS_BILLING_FILE__ALL',
      //   text: 'Meals Billing File - All',
      //   icon: 'far fa-file-excel',
      //   settings: {},
      // }
    ];

    this.config.tenant$.pipe(takeUntil(this.$onDestroy$)).subscribe(tenant => {
      // TODO: remove hardcode!!!
      if (['MEALS'].includes(tenant.type)) {
        this.ctxMenuItems.push({
          text: 'Print Meals Delivery By Driver',
          icon: 'fas fa-print',
          settings: {},
          id: PRINT_MODE.MEALS_DELIVERY_SIGNATURE,
        });
      }

      // TODO: remove hardcode!!!
      if (['FSCC'].includes(tenant.shortname.toUpperCase())) {
        this.ctxMenuItems.push({
          text: 'Print FSC - SignIn Form',
          icon: 'fas fa-print',
          settings: { showFacilityID: true },
          id: PRINT_MODE.MERCY_SIGN_IN_FORM_NATIVE,
        });
      }

      // TODO: remove hardcode!!!
      if (['99_ADC'].includes(tenant.shortname.toUpperCase())) {
        this.ctxMenuItems.push({
          text: 'Print 99 ADC - SignIn Form',
          icon: 'fas fa-print',
          settings: { showFacilityID: false },
          id: PRINT_MODE.MERCY_SIGN_IN_FORM_NATIVE,
        });
      }

      // TODO: remove hardcode!!!
      if (['GOLDEN_ADC'].includes(tenant.shortname.toUpperCase())) {
        this.ctxMenuItems.push({
          text: 'Sign-In Form - All Clients (Facility ID Order)',
          icon: 'fas fa-print',
          settings: { showFacilityID: true },
          id: PRINT_MODE.MERCY_SIGN_IN_FORM_NATIVE,
        });
      }
    });
  }
}
