import {
  Facility,
  Acquisition,
  Document,
  AnyNote
} from '../index';

declare var Object: any;
export interface VehicleInterface {
  "internalId": number;
  "state": string;
  "status": string;
  "subStatus"?: string;
  "location"?: string;
  "make": string;
  "model": string;
  "year": number;
  "vin": string;
  "titleNumber"?: string;
  "licenseState": string;
  "licensePlateNumber": string;
  "registrationExp"?: any;
  "inspectionExp"?: any;
  "color"?: string;
  "note"?: string;
  "fuel"?: string;
  "type": string;
  "wheelchairAccessible"?: boolean;
  "stretcherAccessible"?: boolean;
  "hasLiftRamp"?: string;
  "passengerCapacity"?: number;
  "cdl"?: boolean;
  "wheelchairCapacity"?: number;
  "possibleConfigs"?: string;
  "hvacUnits"?: string;
  "emergencyExitDoor"?: boolean;
  "engineType"?: string;
  "tireSize"?: string;
  "bodyCompany"?: string;
  "grossWeight"?: number;
  "autoEmBraking"?: boolean;
  "laneDepWarning"?: boolean;
  "configNote"?: string;
  "seatBeltCutter"?: boolean;
  "firstAidKit"?: boolean;
  "bloodBornePathogenKit"?: boolean;
  "emTriangles"?: boolean;
  "umbrella"?: boolean;
  "cleaningSupplies"?: boolean;
  "broom"?: boolean;
  "ppe"?: boolean;
  "fireExtinguisherInstalled"?: boolean;
  "fireExtinguisherExp"?: any;
  "fireExtinguisher2Installed"?: boolean;
  "fireExtinguisher2Exp"?: any;
  "wcHooksNum"?: number;
  "wcSeatBeltsNum"?: number;
  "wsMountGPSHolder"?: boolean;
  "ezHandGPS"?: boolean;
  "stepStool"?: boolean;
  "jumpCables"?: boolean;
  "ezPassTransponder"?: boolean;
  "seatBeltExt"?: boolean;
  "thermometer"?: boolean;
  "snowShovel"?: boolean;
  "wsBrush"?: boolean;
  "sanitizerDispenser"?: boolean;
  "liftHandPumpHandle"?: boolean;
  "salt"?: boolean;
  "kubotaKey"?: boolean;
  "usbCharger"?: boolean;
  "usbChargingWire"?: boolean;
  "foldableWheelchair"?: boolean;
  "foldableTransportChair"?: boolean;
  "stairChair2Men"?: boolean;
  "stairwayWCCarrier"?: boolean;
  "buCameraInstalled"?: boolean;
  "gpsUnitInstalled"?: boolean;
  "gpsBypassUsed"?: boolean;
  "gpsSerialNumber"?: string;
  "gpsUnitModel"?: string;
  "gpsInstalledDate"?: any;
  "cameraInstalled"?: boolean;
  "cameraModel"?: string;
  "cameraSerialNumber"?: string;
  "cameraInstalledDate"?: any;
  "covid19Shield"?: boolean;
  "lastMechanicalCheck"?: any;
  "lastOutsideWash"?: any;
  "lastInsideCleanup"?: any;
  "nextTransmOilChangeMiles"?: number;
  "acquisitionDate"?: any;
  "purchaseDate"?: any;
  "seller"?: string;
  "titleDate"?: any;
  "acquisitionType"?: string;
  "ownership"?: string;
  "ownershipStatus"?: string;
  "creditorName"?: string;
  "contractNumber"?: string;
  "contractInterest"?: number;
  "contractLimitations"?: number;
  "originalAmount"?: number;
  "originalDate"?: any;
  "maturityDate"?: any;
  "contractMonthlyAmount"?: number;
  "subLeaseMonthlyRate"?: number;
  "subLeaseDailyRate"?: number;
  "vinTail"?: string;
  "titleNumberTail"?: string;
  "assignedFacility"?: string;
  "meta"?: any;
  "tenantIds"?: any;
  "dtime"?: Date;
  "id"?: number;
  "tenantId"?: number;
  tenant?: Facility;
  acquisitions?: Acquisition[];
  relatedDocuments?: Document[];
  anyNotes?: AnyNote[];
}

export class Vehicle implements VehicleInterface {
  "internalId": number;
  "state": string;
  "status": string;
  "subStatus": string;
  "location": string;
  "make": string;
  "model": string;
  "year": number;
  "vin": string;
  "titleNumber": string;
  "licenseState": string;
  "licensePlateNumber": string;
  "registrationExp": any;
  "inspectionExp": any;
  "color": string;
  "note": string;
  "fuel": string;
  "type": string;
  "wheelchairAccessible": boolean;
  "stretcherAccessible": boolean;
  "hasLiftRamp": string;
  "passengerCapacity": number;
  "cdl": boolean;
  "wheelchairCapacity": number;
  "possibleConfigs": string;
  "hvacUnits": string;
  "emergencyExitDoor": boolean;
  "engineType": string;
  "tireSize": string;
  "bodyCompany": string;
  "grossWeight": number;
  "autoEmBraking": boolean;
  "laneDepWarning": boolean;
  "configNote": string;
  "seatBeltCutter": boolean;
  "firstAidKit": boolean;
  "bloodBornePathogenKit": boolean;
  "emTriangles": boolean;
  "umbrella": boolean;
  "cleaningSupplies": boolean;
  "broom": boolean;
  "ppe": boolean;
  "fireExtinguisherInstalled": boolean;
  "fireExtinguisherExp": any;
  "fireExtinguisher2Installed": boolean;
  "fireExtinguisher2Exp": any;
  "wcHooksNum": number;
  "wcSeatBeltsNum": number;
  "wsMountGPSHolder": boolean;
  "ezHandGPS": boolean;
  "stepStool": boolean;
  "jumpCables": boolean;
  "ezPassTransponder": boolean;
  "seatBeltExt": boolean;
  "thermometer": boolean;
  "snowShovel": boolean;
  "wsBrush": boolean;
  "sanitizerDispenser": boolean;
  "liftHandPumpHandle": boolean;
  "salt": boolean;
  "kubotaKey": boolean;
  "usbCharger": boolean;
  "usbChargingWire": boolean;
  "foldableWheelchair": boolean;
  "foldableTransportChair": boolean;
  "stairChair2Men": boolean;
  "stairwayWCCarrier": boolean;
  "buCameraInstalled": boolean;
  "gpsUnitInstalled": boolean;
  "gpsBypassUsed": boolean;
  "gpsSerialNumber": string;
  "gpsUnitModel": string;
  "gpsInstalledDate": any;
  "cameraInstalled": boolean;
  "cameraModel": string;
  "cameraSerialNumber": string;
  "cameraInstalledDate": any;
  "covid19Shield": boolean;
  "lastMechanicalCheck": any;
  "lastOutsideWash": any;
  "lastInsideCleanup": any;
  "nextTransmOilChangeMiles": number;
  "acquisitionDate": any;
  "purchaseDate": any;
  "seller": string;
  "titleDate": any;
  "acquisitionType": string;
  "ownership": string;
  "ownershipStatus": string;
  "creditorName": string;
  "contractNumber": string;
  "contractInterest": number;
  "contractLimitations": number;
  "originalAmount": number;
  "originalDate": any;
  "maturityDate": any;
  "contractMonthlyAmount": number;
  "subLeaseMonthlyRate": number;
  "subLeaseDailyRate": number;
  "vinTail": string;
  "titleNumberTail": string;
  "assignedFacility": string;
  "meta": any;
  "tenantIds": any;
  "dtime": Date;
  "id": number;
  "tenantId": number;
  tenant: Facility;
  acquisitions: Acquisition[];
  relatedDocuments: Document[];
  anyNotes: AnyNote[];
  constructor(data?: VehicleInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Vehicle`.
   */
  public static getModelName() {
    return "Vehicle";
  }

  /**
  * This method creates an instance of Vehicle for dynamic purposes.
  **/
  public static factory(data: VehicleInterface): Vehicle{
    return new Vehicle(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Vehicle',
      plural: 'Vehicles',
      path: 'Vehicles',
      idName: 'id',
      properties: {
        "internalId": {
          name: 'internalId',
          type: 'number'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "subStatus": {
          name: 'subStatus',
          type: 'string',
          default: ''
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "make": {
          name: 'make',
          type: 'string'
        },
        "model": {
          name: 'model',
          type: 'string'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "vin": {
          name: 'vin',
          type: 'string'
        },
        "titleNumber": {
          name: 'titleNumber',
          type: 'string'
        },
        "licenseState": {
          name: 'licenseState',
          type: 'string'
        },
        "licensePlateNumber": {
          name: 'licensePlateNumber',
          type: 'string'
        },
        "registrationExp": {
          name: 'registrationExp',
          type: 'any'
        },
        "inspectionExp": {
          name: 'inspectionExp',
          type: 'any'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "fuel": {
          name: 'fuel',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "wheelchairAccessible": {
          name: 'wheelchairAccessible',
          type: 'boolean',
          default: false
        },
        "stretcherAccessible": {
          name: 'stretcherAccessible',
          type: 'boolean',
          default: false
        },
        "hasLiftRamp": {
          name: 'hasLiftRamp',
          type: 'string',
          default: 'None'
        },
        "passengerCapacity": {
          name: 'passengerCapacity',
          type: 'number'
        },
        "cdl": {
          name: 'cdl',
          type: 'boolean'
        },
        "wheelchairCapacity": {
          name: 'wheelchairCapacity',
          type: 'number'
        },
        "possibleConfigs": {
          name: 'possibleConfigs',
          type: 'string'
        },
        "hvacUnits": {
          name: 'hvacUnits',
          type: 'string'
        },
        "emergencyExitDoor": {
          name: 'emergencyExitDoor',
          type: 'boolean'
        },
        "engineType": {
          name: 'engineType',
          type: 'string'
        },
        "tireSize": {
          name: 'tireSize',
          type: 'string'
        },
        "bodyCompany": {
          name: 'bodyCompany',
          type: 'string'
        },
        "grossWeight": {
          name: 'grossWeight',
          type: 'number'
        },
        "autoEmBraking": {
          name: 'autoEmBraking',
          type: 'boolean'
        },
        "laneDepWarning": {
          name: 'laneDepWarning',
          type: 'boolean'
        },
        "configNote": {
          name: 'configNote',
          type: 'string'
        },
        "seatBeltCutter": {
          name: 'seatBeltCutter',
          type: 'boolean'
        },
        "firstAidKit": {
          name: 'firstAidKit',
          type: 'boolean'
        },
        "bloodBornePathogenKit": {
          name: 'bloodBornePathogenKit',
          type: 'boolean'
        },
        "emTriangles": {
          name: 'emTriangles',
          type: 'boolean'
        },
        "umbrella": {
          name: 'umbrella',
          type: 'boolean'
        },
        "cleaningSupplies": {
          name: 'cleaningSupplies',
          type: 'boolean'
        },
        "broom": {
          name: 'broom',
          type: 'boolean'
        },
        "ppe": {
          name: 'ppe',
          type: 'boolean'
        },
        "fireExtinguisherInstalled": {
          name: 'fireExtinguisherInstalled',
          type: 'boolean'
        },
        "fireExtinguisherExp": {
          name: 'fireExtinguisherExp',
          type: 'any'
        },
        "fireExtinguisher2Installed": {
          name: 'fireExtinguisher2Installed',
          type: 'boolean'
        },
        "fireExtinguisher2Exp": {
          name: 'fireExtinguisher2Exp',
          type: 'any'
        },
        "wcHooksNum": {
          name: 'wcHooksNum',
          type: 'number'
        },
        "wcSeatBeltsNum": {
          name: 'wcSeatBeltsNum',
          type: 'number'
        },
        "wsMountGPSHolder": {
          name: 'wsMountGPSHolder',
          type: 'boolean'
        },
        "ezHandGPS": {
          name: 'ezHandGPS',
          type: 'boolean'
        },
        "stepStool": {
          name: 'stepStool',
          type: 'boolean'
        },
        "jumpCables": {
          name: 'jumpCables',
          type: 'boolean'
        },
        "ezPassTransponder": {
          name: 'ezPassTransponder',
          type: 'boolean'
        },
        "seatBeltExt": {
          name: 'seatBeltExt',
          type: 'boolean'
        },
        "thermometer": {
          name: 'thermometer',
          type: 'boolean'
        },
        "snowShovel": {
          name: 'snowShovel',
          type: 'boolean'
        },
        "wsBrush": {
          name: 'wsBrush',
          type: 'boolean'
        },
        "sanitizerDispenser": {
          name: 'sanitizerDispenser',
          type: 'boolean'
        },
        "liftHandPumpHandle": {
          name: 'liftHandPumpHandle',
          type: 'boolean'
        },
        "salt": {
          name: 'salt',
          type: 'boolean'
        },
        "kubotaKey": {
          name: 'kubotaKey',
          type: 'boolean'
        },
        "usbCharger": {
          name: 'usbCharger',
          type: 'boolean'
        },
        "usbChargingWire": {
          name: 'usbChargingWire',
          type: 'boolean'
        },
        "foldableWheelchair": {
          name: 'foldableWheelchair',
          type: 'boolean'
        },
        "foldableTransportChair": {
          name: 'foldableTransportChair',
          type: 'boolean'
        },
        "stairChair2Men": {
          name: 'stairChair2Men',
          type: 'boolean'
        },
        "stairwayWCCarrier": {
          name: 'stairwayWCCarrier',
          type: 'boolean'
        },
        "buCameraInstalled": {
          name: 'buCameraInstalled',
          type: 'boolean'
        },
        "gpsUnitInstalled": {
          name: 'gpsUnitInstalled',
          type: 'boolean'
        },
        "gpsBypassUsed": {
          name: 'gpsBypassUsed',
          type: 'boolean'
        },
        "gpsSerialNumber": {
          name: 'gpsSerialNumber',
          type: 'string'
        },
        "gpsUnitModel": {
          name: 'gpsUnitModel',
          type: 'string'
        },
        "gpsInstalledDate": {
          name: 'gpsInstalledDate',
          type: 'any'
        },
        "cameraInstalled": {
          name: 'cameraInstalled',
          type: 'boolean'
        },
        "cameraModel": {
          name: 'cameraModel',
          type: 'string'
        },
        "cameraSerialNumber": {
          name: 'cameraSerialNumber',
          type: 'string'
        },
        "cameraInstalledDate": {
          name: 'cameraInstalledDate',
          type: 'any'
        },
        "covid19Shield": {
          name: 'covid19Shield',
          type: 'boolean'
        },
        "lastMechanicalCheck": {
          name: 'lastMechanicalCheck',
          type: 'any'
        },
        "lastOutsideWash": {
          name: 'lastOutsideWash',
          type: 'any'
        },
        "lastInsideCleanup": {
          name: 'lastInsideCleanup',
          type: 'any'
        },
        "nextTransmOilChangeMiles": {
          name: 'nextTransmOilChangeMiles',
          type: 'number'
        },
        "acquisitionDate": {
          name: 'acquisitionDate',
          type: 'any'
        },
        "purchaseDate": {
          name: 'purchaseDate',
          type: 'any'
        },
        "seller": {
          name: 'seller',
          type: 'string'
        },
        "titleDate": {
          name: 'titleDate',
          type: 'any'
        },
        "acquisitionType": {
          name: 'acquisitionType',
          type: 'string'
        },
        "ownership": {
          name: 'ownership',
          type: 'string'
        },
        "ownershipStatus": {
          name: 'ownershipStatus',
          type: 'string'
        },
        "creditorName": {
          name: 'creditorName',
          type: 'string'
        },
        "contractNumber": {
          name: 'contractNumber',
          type: 'string'
        },
        "contractInterest": {
          name: 'contractInterest',
          type: 'number'
        },
        "contractLimitations": {
          name: 'contractLimitations',
          type: 'number'
        },
        "originalAmount": {
          name: 'originalAmount',
          type: 'number'
        },
        "originalDate": {
          name: 'originalDate',
          type: 'any'
        },
        "maturityDate": {
          name: 'maturityDate',
          type: 'any'
        },
        "contractMonthlyAmount": {
          name: 'contractMonthlyAmount',
          type: 'number'
        },
        "subLeaseMonthlyRate": {
          name: 'subLeaseMonthlyRate',
          type: 'number'
        },
        "subLeaseDailyRate": {
          name: 'subLeaseDailyRate',
          type: 'number'
        },
        "vinTail": {
          name: 'vinTail',
          type: 'string'
        },
        "titleNumberTail": {
          name: 'titleNumberTail',
          type: 'string'
        },
        "assignedFacility": {
          name: 'assignedFacility',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "tenantIds": {
          name: 'tenantIds',
          type: 'any',
          default: <any>null
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        acquisitions: {
          name: 'acquisitions',
          type: 'Acquisition[]',
          model: 'Acquisition',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vehicleId'
        },
        relatedDocuments: {
          name: 'relatedDocuments',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
        anyNotes: {
          name: 'anyNotes',
          type: 'AnyNote[]',
          model: 'AnyNote',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vehicleId'
        },
      }
    }
  }
}
