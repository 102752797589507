import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import DxDataGrid from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Employee, Facility, MyUser, MyUserView, Role } from '../../../../shared/sdk/models';
import { EmployeeApi, FacilityApi, LoggerService, MyUserApi } from '../../../../shared/sdk/services/custom';
import { USER_STATUSES } from '../../classes/enums';
import { DlgFacilityComponent } from '../../dialogs/dlg-facility/dlg-facility.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent extends ABaseComponent implements OnInit {
  statuses = USER_STATUSES;
  facilities: Facility[];

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);
  roleDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;
  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    private router: Router,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private dialog: MatDialog,
    private gridHelper: GridHelperService,
    private userApi: MyUserApi,
    private facilityApi: FacilityApi,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('9e4b46af-e65e-409a-a1d9-acdf2296613b');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'dbfddcf0-ba9a-47b1-a7cf-6629da6966b8',
    };

    this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
    this.roleDso$ = this.buildRoleDataSource();

    this.blockIconClick = this.blockIconClick.bind(this);
    this.activateIconClick = this.activateIconClick.bind(this);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent.pipe(takeUntil(this.$onDestroy$)).subscribe(modelName => {
      if ([MyUser.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });
  }

  grid_onInitialized(e) {
    const gridInst: DxDataGrid = e.component;
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
    // gridInst.option(this.gridHelper.defaultRichRemotePagedGridOptions);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        name: 'newUser',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-user-plus',
          text: 'New User',
          hint: 'Create New User',
          onClick: this.grid_toolbar_new_onClick.bind(this),
        },
      },
      {
        name: 'blockUser',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-ban',
          text: 'Block User',
          hint: 'Block User',
          onClick: this.grid_toolbar_block_onClick.bind(this),
        },
      },
      {
        name: 'assignFacilityLogin',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-tenant',
          text: 'Enable User Login',
          hint: 'Enable User Login',
          onClick: this.grid_toolbar_assignFacilityLogin_onClick.bind(this),
        },
      },
      {
        name: 'removeFacilityLogin',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-tenant',
          text: 'Disable User Login',
          hint: 'Disable User Login',
          onClick: this.grid_toolbar_removeFacilityLogin_onClick.bind(this),
        },
      },
    );
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    this.router.navigate(['dashboard', 'users', e.key]);

    // this.ui.openEditDialog({
    //   modelId: e.key,
    //   ModelClass: MyUser,
    //   FormComponentClass: UsersFormComponent,
    //   title: `Edit User`,
    // });
  }

  grid_toolbar_new_onClick() {
    this.router.navigate(['dashboard', 'users', 0]);

    // this.ui.openEditDialog({
    //   modelId: null,
    //   ModelClass: MyUser,
    //   FormComponentClass: UsersFormComponent,
    // });
  }

  grid_toolbar_block_onClick() {
    const selectedKeys = this.grid.instance.getSelectedRowKeys();

    this.grid.instance.beginCustomLoading('...');
    of(selectedKeys)
      .pipe(
        switchMap((keys: number[]) =>
          Promise.all(
            keys.map(async key =>
              this.userApi
                .block(key)
                .pipe(
                  tap(() => {
                    const ds = this.grid.instance.getDataSource();
                    ds.store().push([{ type: 'update', data: { status: 'BLOCKED' }, key }]);
                  }),
                  catchError(err => of(notify(err.message, 'error'))),
                )
                .toPromise(),
            ),
          ),
        ),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // this.ui.openEditDialog({
    //   modelId: null,
    //   ModelClass: MyUser,
    //   FormComponentClass: UsersFormComponent,
    // });
  }

  grid_toolbar_assignFacilityLogin_onClick() {
    this.showAssignEmployeeToFacilities(false);
  }

  grid_toolbar_removeFacilityLogin_onClick() {
    this.showAssignEmployeeToFacilities(true);
  }

  showAssignEmployeeToFacilities(remove = false) {
    this.dialog
      .open(DlgFacilityComponent, {
        width: '550px',
        maxHeight: '650px',
        hasBackdrop: true,
        data: { title: remove ? 'Remove Facility Login' : 'Assign Facility Login' },
      })
      .afterClosed()
      .pipe(
        filter(keys => keys !== false && keys && keys.length > 0),
        switchMap(async fIds => {
          this.ui.showLoading();
          const eIds = ((await this.grid.instance.getSelectedRowsData()) as any[]).map(row => row.employee_id);

          await Promise.all(
            eIds.map(async eId =>
              this.dss
                .getApi<EmployeeApi>(Employee)
                .assignToFacilities(eId, fIds, remove ? '{"remove":true}' : '{"append":true}')
                .toPromise(),
            ),
          );

          await this.grid.instance.refresh();
        }),
        catchError(err => of(notify(err.message || err, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_onLogoutUser({ data: user }) {
    this.grid.instance.beginCustomLoading('...');
    this.userApi
      .logoutUser(user.id)
      .pipe(
        first(),
        tap(() => {
          notify(
            `Logout the user ${user.person_firstname} ${user.person_lastname} ${user.email} successfully.`,
            'success',
          );
        }),
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  blockIconClick(e) {
    this.grid.instance.beginCustomLoading('...');
    this.userApi
      .block(e.row.key)
      .pipe(
        first(),
        tap(() => {
          const ds = this.grid.instance.getDataSource();
          ds.store().push([{ type: 'update', data: { status: 'BLOCKED' }, key: e.row.key }]);
        }),
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  activateIconClick(e) {
    this.grid.instance.beginCustomLoading('...');
    this.userApi
      .patchAttributes(e.row.key, { status: 'ACTIVE' })
      .pipe(
        first(),
        tap(() => {
          const ds = this.grid.instance.getDataSource();
          ds.store().push([{ type: 'update', data: { status: 'ACTIVE' }, key: e.row.key }]);
        }),
        catchError(err => of(notify(err.message, 'error'))),
        finalize(() => this.grid.instance.endCustomLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  isBlockIconVisible(e) {
    return e.row.data.status !== 'BLOCKED';
  }

  isActivateIconVisible(e) {
    return e.row.data.status === 'BLOCKED';
  }

  private buildDataSource() {
    const dso = this.dss.getDataSourceOptions(MyUser, MyUserView);
    // dso.select = {include: {employee: ['assignedToFacilities']}} as any;

    dso.reshapeOnPush = true;

    dso.postProcess = (data: any[]) => {
      data.forEach((rec: MyUserView) => {
        (rec as any)._visibleFromFacilities$ = (
          this.facilities
            ? of(this.facilities)
            : this.facilityApi.find<Facility>().pipe(tap(items => (this.facilities = items)))
        ).pipe(
          map(facilities =>
            facilities.filter(f => ((rec.employee_tenantIds as any[]) || []).includes(f.id)).map(f => f.shortname),
          ),
          // tap(console.log),
        );

        // (rec as any)._facility$ = this.userApi.getTenants(rec.id).pipe(
        //   switchMap((ids: number[]) =>
        //     of(ids).pipe(
        //       switchMap((id) => this.facilityApi.findById(id)),
        //       map((f: Facility) => f.shortname),
        //       toArray(),
        //     ),
        //   ),
        //   map((names) => names.join(', ')),
        // );

        // (rec as any)._roles$ = this.userApi.getRoles(rec.id).pipe(
        //   map((roles: string[]) => roles.join(', ')),
        // );

        // rec._roles = (rec._roles || []).sort();
        // rec._facilities = (rec._facilities || []).sort();
      });
      return data;
    };

    this.dso = dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      // filter: ['type', 'inq', ['ADC', 'BASE']],
      filter: ['name', 'neq', 'N/A'],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildRoleDataSource() {
    const store = this.dss.getStore(Role);
    const dso: DataSourceOptions = {
      store,
      sort: [{ selector: 'name' }],
    } as DataSourceOptions;
    return of(dso);
  }

  facilities_calculateValue = (rowData: MyUserView): string => {
    return (rowData._facilities || []).sort().join(', ');
  };

  visibleFromFacilities_calculateValue = (rowData: MyUserView): string => {
    const visibleFromFacilities$ = (rowData as any)._visibleFromFacilities$;

    return visibleFromFacilities$
      ? visibleFromFacilities$.pipe(map((items: string[]) => (items || []).join(', ')))
      : null;
  };

  facilities_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const column = this as any;
    // console.log(column.dataField, filterValue, selectedFilterOperation, target);

    if (selectedFilterOperation === '=') {
      const filterExpression = [['_facilityIds', '$json_e_c', { $: JSON.stringify(filterValue) }]];
      return filterExpression;
    }

    return column.defaultCalculateFilterExpression.apply(column, arguments);
  };

  calculateYesFilterExpression = function (val, op, target) {
    return [this.dataField, ((op === '<>' || op === 'notcontains') && '<>') || '=', 'yes'.includes(val.toLowerCase())];
  };

  visibleFromFacilities_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const column = this as any;
    // console.log(column.dataField, filterValue, selectedFilterOperation, target);

    if (selectedFilterOperation === '=') {
      const filterExpression = [['employee_tenantIds', '$json_e_c', { $: JSON.stringify(filterValue) }]];
      return filterExpression;
    }

    return column.defaultCalculateFilterExpression.apply(column, arguments);
  };

  roles_calculateValue = (rowData: MyUserView): string => {
    return (rowData._roles || []).sort().join(', ');
  };

  // roles_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
  //   const column = this as any;
  //   // console.log(column.dataField, filterValue, selectedFilterOperation, target);
  //
  //   if (selectedFilterOperation === '=') {
  //     const filterExpression = [['_roleIds', '$json_e_c', { $: JSON.stringify(filterValue) }]];
  //     return filterExpression;
  //   }
  //
  //   return column.defaultCalculateFilterExpression.apply(column, arguments);
  // };

  roles_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
    const column = this as any;
    // console.log(column.dataField, filterValue, selectedFilterOperation, target);

    if (isNil(filterValue)) {
      filterValue = [];
    }

    return column.defaultCalculateFilterExpression(filterValue, selectedFilterOperation, target);
  };
}
