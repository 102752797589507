import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
//
import { DocumentType, LoggerService } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';

@Component({
  selector: 'app-document-type-details',
  templateUrl: './document-type-details.component.html',
  styleUrls: ['./document-type-details.component.scss'],
})
export class DocumentTypeDetailsComponent
  extends ABaseModelLoaderComponent<DocumentType>
  implements OnInit, OnChanges, OnDestroy
{
  toolbarItems: any[];

  constructor(protected cd: ChangeDetectorRef, protected logger: LoggerService, protected dss: DataSourceService) {
    super(logger, dss);

    this.buildToolbarItems();

    this.afterLoaded
      .pipe(takeUntil(this.$onDestroy$))
      .subscribe((model: DocumentType) => this.buildToolbarItems(model));
  }

  protected get ModelClass(): any {
    return DocumentType;
  }

  private buildToolbarItems(model: DocumentType = null) {
    if (model) {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: `${model.name}`,
        },
        {
          location: 'center',
          locateInMenu: 'never',
          text: 'of',
        },
        {
          location: 'center',
          locateInMenu: 'never',
          text: `[${model.objectFQN}]`,
        },
      ];
    } else {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.errorMessage || 'Not selected',
        },
      ];
    }
  }
}
