/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./settings.component";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("app-settings", i3.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
