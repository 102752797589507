import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import get from 'lodash-es/get';
import { Employee, LoopBackFilter, TripManifest } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-trip-manifest-sub-toolbar',
  templateUrl: './trip-manifest-sub-toolbar.component.html',
  styleUrls: ['./trip-manifest-sub-toolbar.component.scss'],
  providers: [HelperService],
})
export class TripManifestSubToolbarComponent extends ABaseModelLoaderComponent<Employee> implements OnChanges, OnInit {
  toolbarItems: any[];

  consumerTotalSet: Set<number> = new Set();
  vehicleTotalSet: Set<number> = new Set();
  driverTotalSet: Set<number> = new Set();
  avgTrips: string;
  consumerPerDriverMap: Map<number, Set<number>> = new Map();
  totalSubscribtionTrips: number;

  @Input() selectedDate: Date;
  @Input() manifest: TripManifest;
  @Input() validationSummary: any[];
  @Input() showSubscriptionTrips: boolean = false;

  constructor(
    public helper: HelperService,
    protected cd: ChangeDetectorRef,
    protected logger: LoggerService,
    protected dss: DataSourceService,
  ) {
    super(logger, dss);

    this.buildToolbarItems();
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['person'],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if ('manifest' in changes) {
      const recs = get(this.manifest, 'data', []); //.filter(r => r.e && r.s !== -1);
      this.consumerTotalSet = this.helper.buildConsumerTotalSet(recs);
      this.vehicleTotalSet = this.helper.buildVehicleTotalSet(recs);
      this.driverTotalSet = this.helper.buildDriverTotalSet(recs);
      if (this.showSubscriptionTrips)
        this.totalSubscribtionTrips = (this.manifest.data || []).filter(
          r => r.__consumer && r.__consumer.repetitiveMode === 'SUBSCRIPTION',
        ).length;
      if (this.vehicleTotalSet.size)
        this.avgTrips = (get(this.manifest, 'data', []).length / this.vehicleTotalSet.size).toFixed(2);
      this.consumerPerDriverMap = this.helper.buildConsumerPerDriverMap(get(this.manifest, 'data', null));
    }
  }

  ngOnInit() {}

  private buildToolbarItems() {
    this.toolbarItems = [
      {
        location: 'before',
        template: 'info',
        locateInMenu: 'never',
      },
    ];
  }
}

