<div mat-dialog-content>
  <dx-map
    #map
    *ngIf="!loading; else loadingTpl"
    width="100%"
    height="500"
    provider="google"
    [apiKey]="config.vars.GOOGLE_MAPS_API_KEY_FOR_BROWSER"
    [controls]="true"
    [autoAdjust]="true"
    [zoom]="13"
    [markers]="markers"
    [routes]="routes"
    (onInitialized)="map_onInitialized($event)"></dx-map>

  <ng-template #loadingTpl>
    <div style="display: flex">
      <div style="margin: auto">
        <dx-load-indicator></dx-load-indicator>
      </div>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Close</button>
  <!--  <button mat-button [mat-dialog-close]="false">Cancel</button>-->
  <!--  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button>-->
</div>
