import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//
import notify from 'devextreme/ui/notify';
//
import { MyUser } from '../../../../shared/sdk';

import getBrowserFingerprint from 'get-browser-fingerprint';

@Component({
  selector: 'app-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
})
export class SigninFormComponent implements OnInit {
  form: FormGroup;
  passHide = true;
  @Output() mySubmit = new EventEmitter<MyUser>();

  constructor(private fb: FormBuilder) {
    this.buildForm();
  }

  @Input()
  set mfaSecret(value: string) {
    if (value) this.form.get('mfaSecret').reset(value);
  }

  @Input() signedUser: MyUser;

  private _isSubmitting = false;
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @Input()
  set isSubmitting(value) {
    this._isSubmitting = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private _error: any = null;
  get error(): string {
    return (this._error && this._error.message) || this._error;
  }

  @Input()
  set error(value) {
    this._error = value;
    this.error && notify(this.error, 'error', 5000);
  }

  ngOnInit(): void {}

  form_ngSubmit(): boolean {
    if (this.form.valid) {
      [this.form]
        .filter((form: FormGroup) => form.valid)
        .map((form: FormGroup) => new MyUser(form.value))
        .forEach((user: MyUser) => this.mySubmit.emit(user));
    }
    return false;
  }

  reset_onClick(): void {
    this.form.reset();
  }

  getErrorMessage(field: string, name: string) {
    return this.form.get(field).hasError('required') ? `${name} is required` : '';
  }

  getEmail() {
    const { email } = this.signedUser;
    const [start, end] = email.split('@');
    return `${start.slice(0, 3)}*@*****${end.slice(-7)}`;
  }

  private buildForm(): void {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      mfaSecret: [''],
      mfaToken: [''],
      mfaRememberForThisBrowser: [''],
      browserFingerprint: getBrowserFingerprint(),
    });
  }
}
