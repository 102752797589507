import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi, TCustomHeaders, TCustomOptions } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Facility } from '../../models/Facility';
import { Contact } from '../../models/Contact';
import { FacilityEmPerson } from '../../models/FacilityEmPerson';
import { Person } from '../../models/Person';
import { TrainedAtFacility } from '../../models/TrainedAtFacility';
import { Employee } from '../../models/Employee';
import { AssignedToFacility } from '../../models/AssignedToFacility';
import { Document } from '../../models/Document';


/**
 * Api services for the `Facility` model.
 */
@Injectable()
export class FacilityApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation contact.
   *
   * @param {any} id Facility id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getContact(id: any, refresh: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/contact";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for emRelations.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emRelations
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public findByIdEmRelations(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for emRelations.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emRelations
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdEmRelations(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for emRelations.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emRelations
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public updateByIdEmRelations(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for emPeople.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public findByIdEmPeople(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for emPeople.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdEmPeople(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for emPeople.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public updateByIdEmPeople(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Add a related item by id for emPeople.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public linkEmPeople(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove the emPeople relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkEmPeople(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check the existence of emPeople relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for emPeople
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public existsEmPeople(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "HEAD";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for trainedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public findByIdTrainedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for trainedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdTrainedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for trainedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public updateByIdTrainedEmployees(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Add a related item by id for trainedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public linkTrainedEmployees(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove the trainedEmployees relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkTrainedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check the existence of trainedEmployees relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for trainedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public existsTrainedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "HEAD";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for assignedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public findByIdAssignedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for assignedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAssignedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for assignedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public updateByIdAssignedEmployees(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Add a related item by id for assignedEmployees.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public linkAssignedEmployees(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove the assignedEmployees relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkAssignedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check the existence of assignedEmployees relation to an item by id.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for assignedEmployees
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public existsAssignedEmployees(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "HEAD";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for relatedDocuments.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for relatedDocuments
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public findByIdRelatedDocuments(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for relatedDocuments.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for relatedDocuments
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRelatedDocuments(id: any, fk: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for relatedDocuments.
   *
   * @param {any} id Facility id
   *
   * @param {any} fk Foreign key for relatedDocuments
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public updateByIdRelatedDocuments(id: any, fk: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PUT";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries emRelations of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getEmRelations(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in emRelations of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createEmRelations(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all emRelations of this model.
   *
   * @param {any} id Facility id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteEmRelations(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts emRelations of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countEmRelations(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries emPeople of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getEmPeople(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in emPeople of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createEmPeople(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all emPeople of this model.
   *
   * @param {any} id Facility id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteEmPeople(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts emPeople of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countEmPeople(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries trainedEmployees of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getTrainedEmployees(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in trainedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createTrainedEmployees(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all trainedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteTrainedEmployees(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts trainedEmployees of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countTrainedEmployees(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries assignedEmployees of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getAssignedEmployees(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in assignedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createAssignedEmployees(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all assignedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAssignedEmployees(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts assignedEmployees of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countAssignedEmployees(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries relatedDocuments of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getRelatedDocuments(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in relatedDocuments of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createRelatedDocuments(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all relatedDocuments of this model.
   *
   * @param {any} id Facility id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRelatedDocuments(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts relatedDocuments of Facility.
   *
   * @param {any} id Facility id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countRelatedDocuments(id: any, where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Set `current_tenant` cookie value.
   *
   * @param {number} tenantId 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `` – `{}` - 
   */
  public setTenant(tenantId: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/setTenant";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof tenantId !== 'undefined' && tenantId !== null) _urlParams.tenantId = tenantId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get valid signatures dates
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getSignsDates(where: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/getSignsDates";
    let _routeParams: any = {};
    let _postBody: any = {
      where: where
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Persist model entity with related entities
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public myCreateWithRelated(data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/myCreateWithRelated";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check wheres filters and update/delete. all in one transaction. Stop after first filter model exists.
   *
   * @param {object} data Request data.
   *
   *  - `wheres` – `{any}` - filter array
   *
   *  - `data` – `{any}` - data array according to filter array order. null - for delete, data object - for update.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public checkModifyDelete(wheres: any = {}, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/checkModifyDelete";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        wheres: wheres,
        data: data
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Get model related tenant
   *
   * @param {any} id Facility id
   *
   * @param {object} filter 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public myGetTenant(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/myGetTenant";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update model entity with related entities
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public mySaveWithRelated(id: any, data: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/mySaveWithRelated";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} from String in ISO format (ISO 8601), in the form of (YYYY-MM-DDTHH:mm:ss.sssZ)
   *
   * @param {string} to Optional. String in ISO format (ISO 8601), in the form of (YYYY-MM-DDTHH:mm:ss.sssZ)
   *
   * @param {any} fields Fields for which to receive changes. For example: `["status", "internalID", "person.firstname", "person.lastname", ...]`
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getChanges(from: any, to: any = {}, fields: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/getChanges";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof from !== 'undefined' && from !== null) _urlParams.from = from;
    if (typeof to !== 'undefined' && to !== null) _urlParams.to = to;
    if (typeof fields !== 'undefined' && fields !== null) _urlParams.fields = fields;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {any} id Facility id
   *
   * @param {string} date Optional. String in ISO format (ISO 8601), in the form of (YYYY-MM-DDTHH:mm:ss.sssZ)
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public getRevision(id: any, date: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "GET";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/getRevision";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof date !== 'undefined' && date !== null) _urlParams.date = date;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in emRelations of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createManyEmRelations(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emRelations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in emPeople of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createManyEmPeople(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/emPeople";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in trainedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createManyTrainedEmployees(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/trainedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in assignedEmployees of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createManyAssignedEmployees(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/assignedEmployees";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in relatedDocuments of this model.
   *
   * @param {any} id Facility id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Facility` object.)
   * </em>
   */
  public createManyRelatedDocuments(id: any, data: any[] = [], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<any> {
    let _method: string = "POST";
    let _url: string = (customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath()) + "/" + LoopBackConfig.getApiVersion() +
    "/Facilities/:id/relatedDocuments";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Facility`.
   */
  public getModelName() {
    return "Facility";
  }
}
