import { Component, Inject, OnDestroy, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import DevExpress from 'devextreme/bundles/dx.all';
//
import { Employee, LoggerService, LoopBackFilter } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-employee-details-tab-document',
  templateUrl: './employee-details-tab-document.component.html',
  styleUrls: ['./employee-details-tab-document.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class EmployeeDetailsTabDocumentComponent
  extends ABaseModelPaneWToolbarComponent<Employee>
  implements OnDestroy
{
  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
    private gridHelper: GridHelperService,
    @Inject(ConfigService) public config: ConfigService,
  ) {
    super(logger, ui, dss);
    this.caption = 'Related Documents';
  }

  protected get ModelClass(): any {
    return Employee;
  }

  protected get filter(): LoopBackFilter {
    return { include: ['person'] };
  }

  protected get FormComponent(): Type<ABaseFormComponent<Employee>> {
    return EmployeeFormComponent;
  }

  protected buildToolbarItems(model: any = null): void {
    this.toolbarItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        text: this.caption,
      },
    ];
  }
}

