<dx-responsive-box>
  <dxi-row [ratio]="0" [shrink]="0" [baseSize]="'auto'"></dxi-row>
  <dxi-row [ratio]="1" [shrink]="1" [baseSize]="'auto'"></dxi-row>
  <dxi-row [ratio]="0" [shrink]="0" [baseSize]="'auto'"></dxi-row>

  <dxi-col [ratio]="1" [shrink]="1" [baseSize]="'auto'"></dxi-col>

  <!-- Header -->
  <dxi-item [ratio]="0" [shrink]="0">
    <dxi-location [row]="0" [col]="0"></dxi-location>

    <app-toolbar
      class="dx-theme-generic dx-theme-generic-typography"
      [menuToggleEnabled]="true"
      [title]="config.get('appTitle', 'Dispatch App')"
      (menuToggle)="menuOpened = !menuOpened"
    ></app-toolbar>
  </dxi-item>

  <!-- Content-->
  <!-- Left Sidebar-->
  <dxi-item>
    <dxi-location [row]="1" [col]="0"></dxi-location>

    <dx-drawer
      position="before"
      template="template"
      [closeOnOutsideClick]="false"
      [openedStateMode]="'shrink'"
      [revealMode]="'expand'"
      [minSize]="0"
      [shading]="false"
      [(opened)]="menuOpened"
    >
      <ng-container *dxTemplate="let data of 'template'">
        <app-sidebar class="dx-theme-generic dx-theme-generic-typography dx-swatch-dark"></app-sidebar>
      </ng-container>

      <main>
        <router-outlet></router-outlet>
      </main>
    </dx-drawer>
  </dxi-item>

  <!-- Footer-->
  <dxi-item>
    <dxi-location [row]="2" [col]="0"></dxi-location>
  </dxi-item>
</dx-responsive-box>
