<app-content-w-header header="MTM Api Log">
  <mat-card class="toolbar subheader">
    <dx-date-box width="100px" [(value)]="selectedDate" dateSerializationFormat="yyyy-MM-dd"
                 (onValueChanged)="onDateValueChanged($event)"></dx-date-box>
  </mat-card>

  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    [headerFilter]="{ visible: true }"

    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  >

    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <!-- region Columns -->

    <!--    <dxi-column caption='Facility' dataField='sign.tenantId'>-->
    <!--      <dxo-lookup [dataSource]='facilityDso$ | async' valueExpr='id' displayExpr='shortname'></dxo-lookup>-->
    <!--    </dxi-column>-->

    <dxi-column
      caption="Date"
      dataField="date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    ></dxi-column>

    <!-- -->
    <dxi-column caption="Facility" dataField="sign.tenantId" dataType="string">
      <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="trip ID" dataField="__trip._tripId" dataType="string"></dxi-column>
    <dxi-column caption="MCI" dataField="__trip._mci" dataType="string"></dxi-column>
    <dxi-column caption="Client Lastname" dataField="__trip._lastname" dataType="string"></dxi-column>
    <dxi-column caption="Client Firstname" dataField="__trip._firstname" dataType="string"></dxi-column>

    <dxi-column caption="Vehicle" dataField="sign.vehicle_internalId" dataType="string"></dxi-column>
    <dxi-column caption="Driver Lastname" dataField="sign.employee_person_lastname" dataType="string"></dxi-column>
    <dxi-column caption="Driver Firstname" dataField="sign.employee_person_firstname" dataType="string"></dxi-column>

    <dxi-column caption="Statuses" dataField="_statuses" dataType="string"
                [allowFiltering]="false"
                [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column caption="Statuses String" dataField="statusesString" dataType="string"></dxi-column>

    <dxi-column dataField="callsCount" dataType="number"></dxi-column>
    <dxi-column dataField="hasFailed" dataType="boolean"></dxi-column>
    <dxi-column dataField="allFailed" dataType="boolean"></dxi-column>

    <!--  endregion   -->

    <!-- region Templates -->

    <div *dxTemplate="let info of 'detail'">
      <dx-tab-panel>

        <dxi-item title="Api Calls">
          <dx-data-grid
            [dataSource]="info.data.calls"
            [dateSerializationFormat]="null"
          >

            <dxo-editing
              mode="none"
              [allowAdding]="false"
              [allowUpdating]="false"
              [allowDeleting]="false"
            ></dxo-editing>

            <!--  region Columns          -->

            <dxi-column dataField="url" dataType="string"></dxi-column>

            <dxi-column dataField="failed" dataType="boolean"></dxi-column>

            <dxi-column
              caption="Call Datetime"
              dataField="datetime"
              dataType="date"
              format="MM/dd/yyyy HH:mm:ss"
              [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
            ></dxi-column>

            <dxi-column caption="Params">

              <dxi-column caption="status" dataField="params.status">
                <dxo-lookup [dataSource]="mtmGpsV2Statuses" valueExpr="id" displayExpr="t"></dxo-lookup>
              </dxi-column>

              <dxi-column
                caption="dateTimeCollected"
                dataField="params.dateTimeCollected"
                dataType="date"
                format="MM/dd/yyyy HH:mm:ss"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
              ></dxi-column>

              <dxi-column caption="tripInfo.0.tripId" dataField="params.tripInfo.0.tripId"
                          dataType="string"></dxi-column>

              <dxi-column
                caption="tripInfo.0.etaUTC"
                dataField="params.tripInfo.0.etaUTC"
                dataType="date"
                format="MM/dd/yyyy HH:mm:ss"
                [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"
              ></dxi-column>

              <dxi-column caption="lon" dataField="params.lon" dataType="number"></dxi-column>
              <dxi-column caption="lat" dataField="params.lat" dataType="number"></dxi-column>

              <dxi-column caption="driverLicenseNumber" dataField="params.driverLicenseNumber"
                          dataType="string"></dxi-column>
              <dxi-column caption="vin" dataField="params.vin" dataType="string"></dxi-column>

            </dxi-column>
            <!--  endregion          -->

          </dx-data-grid>
        </dxi-item>

        <dxi-item title="Raw Data">
          <ngx-json-viewer [json]="info.data" [expanded]="false"></ngx-json-viewer>
        </dxi-item>

      </dx-tab-panel>
    </div>

    <!--  endregion   -->

  </dx-data-grid>
</app-content-w-header>
