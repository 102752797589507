<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-field"><div class="dx-field-label">Multi-Factor Authentication</div></div>

    <div class="dx-field">
      <div class="dx-field-label">Email</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="mfaEmailEnabled" switchedOnText="YES" switchedOffText="NO"></dx-switch>
        </div>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">SMS</div>
      <div class="dx-field-value">
        <div>
          <dx-switch formControlName="mfaSmsEnabled" switchedOnText="YES" switchedOffText="NO"></dx-switch>
        </div>
      </div>
    </div>

    <!-- /// -->

    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <p class="alert-danger" *ngIf="error">
      {{ error }}
    </p>
  </div>
</dx-validation-group>
