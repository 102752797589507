<app-content-w-header [header]="'Settings' + (form.dirty ? ' *' : '')" xmlns="http://www.w3.org/1999/html">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <form
      [formGroup]="form"
      (ngSubmit)="form_ngSubmit($event)"
      style="flex: auto; display: flex; flex-direction: column">
      <div style="flex: auto; position: relative">
        <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
          <dx-scroll-view>
            <mat-card>
              <div class="dx-fieldset">
                <!--                <div class="dx-fieldset-header">Settings-->
                <!--                  <span *ngIf="form.dirty"> *</span>-->
                <!--                </div>-->

                <div class="dx-field">
                  <div class="dx-field-label">Timezone</div>
                  <div class="dx-field-value">
                    <dx-select-box formControlName="timezone" [items]="timezoneList$ | async">
                      <dx-validator name="Timezone">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">FullName Format</div>
                  <div class="dx-field-value">
                    <dx-text-box formControlName="fullNameFormat">
                      <dx-validator name="FullName Format">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Report FullName Format</div>
                  <div class="dx-field-value">
                    <dx-text-box formControlName="reportFullNameFormat">
                      <dx-validator name="Report FullName Format">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Grid Items per Page</div>
                  <div class="dx-field-value">
                    <dx-select-box formControlName="itemsPerPage" [items]="[10, 20, 50, 100]">
                      <dx-validator name="Grid Items per Page">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Available Reports</div>
                  <div class="dx-field-value">
                    <dx-tag-box
                      formControlName="reportList"
                      [showSelectionControls]="true"
                      applyValueMode="useButtons"
                      [dataSource]="availableReports"
                      displayExpr="name"
                      valueExpr="ID"
                      itemTemplate="customItem">
                      <div *dxTemplate="let item of 'customItem'">
                        <div class="custom-item">
                          <div class="report-name">
                            {{ item.name }}
                          </div>
                          <div class="report-desc">
                            {{ item.desc }}
                          </div>
                        </div>
                      </div>
                    </dx-tag-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Auto Sequence inside Trip</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        formControlName="tripAutoSequence"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Driver List</div>
                  <div class="dx-field-value">
                    <dx-tag-box
                      formControlName="driverList"
                      [displayExpr]="'name'"
                      [valueExpr]="'name'"
                      [items]="driverList$ | async"></dx-tag-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Vehicle Location List</div>
                  <div class="dx-field-value">
                    <dx-tag-box formControlName="vehicleLocationList" [acceptCustomValue]="true"></dx-tag-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Consumer More Fields</div>
                  <div class="dx-field-value">
                    <dx-tag-box
                      formControlName="consumerMoreFields"
                      [displayExpr]="'title'"
                      [valueExpr]="'ID'"
                      [items]="[{ title: 'Facility ID', ID: 'facilityID' }]"></dx-tag-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Manifest Auto Cloning</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-switch
                        formControlName="adcManifestAutoClone"
                        switchedOnText="YES"
                        switchedOffText="NO"></dx-switch>
                    </div>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">ADC Days Open</div>
                  <div class="dx-field-value">
                    <mat-form-field style="margin-top: -10px">
                      <app-weekday-checkbox formControlName="adcDaysOpen"></app-weekday-checkbox>
                    </mat-form-field>
                  </div>
                </div>

                <div class="dx-fieldset-header">Reports Header</div>

                <fieldset>
                  <div class="dx-field">
                    <div class="dx-field-label">Company Name</div>
                    <div class="dx-field-value">
                      <dx-text-box formControlName="companyName"></dx-text-box>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Upload Company Logo Small</div>
                    <div class="dx-field-value">
                      <img
                        class="company-logo-small"
                        *ngIf="form.get('companyLogoSmall').value"
                        [src]="form.get('companyLogoSmall').value | fileSignedUrl | async" />
                      <dx-file-uploader #uploadCompanyLogoSmall> </dx-file-uploader>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Upload Company Logo Large</div>
                    <div class="dx-field-value">
                      <img
                        class="company-logo-large"
                        *ngIf="form.get('companyLogoLarge').value"
                        [src]="form.get('companyLogoLarge').value | fileSignedUrl | async" />
                      <dx-file-uploader #uploadCompanyLogoLarge> </dx-file-uploader>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div class="dx-field">
                    <div class="dx-field-label">ADC COVID-19 Reporting</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="adcCovidReporting"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Show Uncollected Temperature Prompt
                      <i
                        class="fas fa-info-circle"
                        title="Allows ADC Stuff to quickly identify clients who's temperature was not collected by showing the 't' icon"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="showUncollectedTemperaturePrompt"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">ADC COVID-19 Reporting Mode</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-select-box
                          formControlName="adcCovidReportingMode"
                          [items]="['none', 'basic', 'extended', 'final']">
                          <dx-validator name="ADC COVID-19 Reporting Mode">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                          </dx-validator>
                        </dx-select-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">ADC COVID-19 Measures</div>
                    <div class="dx-field-value">
                      <dx-text-box formControlName="adcCovid19Measures"></dx-text-box>
                    </div>
                  </div>
                </fieldset>

                <!--                -->

                <fieldset>
                  <!--                  <legend style="width: 45%"></legend>-->

                  <div class="dx-field">
                    <div class="dx-field-label" style="text-decoration: underline">
                      ADC Time Compliance
                      <i
                        class="fas fa-info-circle"
                        title="Allows facility to check if client stays in ADC appropriate time"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          #adcTimeComplianceCtrl
                          formControlName="adcTimeCompliance"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          (onValueChanged)="adcTimeCompliance_onValueChanged($event)"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      ADC Signatures Extended Mode
                      <i
                        class="fas fa-info-circle"
                        title="Allows to track facility arrival and departure times on ADC signature screen and enables extended attendance reports"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="adcSignatureExtendedMode"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="!adcTimeComplianceCtrl.value"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Show Timer Prompt on Main Screen
                      <i
                        class="fas fa-info-circle"
                        title="Allow quickly to identify clients who is ready to go home"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="showAdcTimeInFacilityPrompt"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="!adcTimeComplianceCtrl.value"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Enable "Time in Facility" Screen
                      <i
                        class="fas fa-info-circle"
                        title="Allows ADC stuff to see actual time client spent in facility"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enableTimeInFacilityScreen"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="!adcTimeComplianceCtrl.value"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      ADC Shift Time [4 hours / 6 hours]
                      <i
                        class="fas fa-info-circle"
                        title="Choose the period of time clients have to stay in facility"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-select-box
                          formControlName="shiftRemainingTimeReminder"
                          [items]="[
                            { v: 4, t: '4 hours' },
                            { v: 6, t: '6 hours' }
                          ]"
                          displayExpr="t"
                          valueExpr="v"
                          [readOnly]="!adcTimeComplianceCtrl.value"></dx-select-box>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="dx-fieldset-header">ADC Tablet</div>

                <fieldset>
                  <div class="dx-field">
                    <div class="dx-field-label">Enable Mobile Dispatch</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enableMobileDispatch"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Show inactive/deceased clients</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="showInactiveClients"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Show >2 hour trip alert</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="showGt2hourTripAlert"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Allow Edit Client Data on Tab</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="allowEditClientOnTab"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Frequent Update on Tab</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="frequentUpdate"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Require Certification on Transportation Signature Update</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="certificationOnSignModify"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Allow collection of Transport signatures not collected by Driver</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="allowCollectSignsNotByDriver"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Allow edit of UnValidated Transport Signatures on Dispatch App</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="allowEditUnvalidatedSignsOnDApp"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Allow edit of AutoValidated Transport Signatures on Dispatch App</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="allowEditAutoValidatedSignsOnDApp"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Allow Ambulette Trips</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="allowAmbuletteTrips"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <legend style="text-decoration: underline">ADC Signatures & Attendance</legend>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Collect ADC Signatures on the Tablet
                      <i
                        class="fas fa-info-circle"
                        title="Allows ADC to gather signature on the tablet, print attendance reports, and generate attendance excel files"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          #allowAdcSignaturesCtrl
                          formControlName="allowAdcSignatures"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          (onValueChanged)="allowAdcSignatures_changed($event)"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      ADC Signature by QR enabled
                      <i class="fas fa-info-circle" title="Allows contactless method of collecting signatures"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="adcSignatureByQr"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      QR ADC Arrival Time Override
                      <i class="fas fa-info-circle" title="Works for Extended Mode Signatures Only"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="qrAdcArrivalTimeOverride"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      QR ADC Departure Time Override
                      <i class="fas fa-info-circle" title="Works for Extended Mode Signatures Only"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="qrAdcDepartureTimeOverride"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      ADC Daily Shifts
                      <i class="fas fa-info-circle" title="Number of shifts in a day"></i>
                    </div>
                    <div class="dx-field-value">
                      <dx-select-box formControlName="adcDailyShifts" [items]="[1, 2, 3]"></dx-select-box>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Show Attendance Export
                      <i class="fas fa-info-circle" title="Shows Export button on Attendance Report page"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="adcAttendanceSummary"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">
                      Show Attendance Export with $
                      <i
                        class="fas fa-info-circle"
                        title="Shows Export ($) button on Attendance Report page, in order to generate correct reports, each client has to have 'ADC Daily Rate' amount entered"></i>
                    </div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="adcAttendanceSummary$"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <!--                -->
                <fieldset>
                  <!--                  <legend style="width: 45%"></legend>-->

                  <div class="dx-field">
                    <div class="dx-field-label" style="text-decoration: underline">ADC Signatures Meals Check</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          #adcMealsCheckCtrl
                          formControlName="adcMealsCheck"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="allowAdcSignaturesCtrl.value !== true"></dx-switch>
                        <!--                        (onInitialized)="adcMealsCheck_initialized($event)"-->
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Meals Shifts</div>
                    <div class="dx-field-value">
                      <div style="display: inline-block">
                        <app-weekday-number-box
                          formControlName="adcMealsShifts"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-weekday-number-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Meals Shift 1 Times</div>
                    <div class="dx-field-value">
                      <div style="display: inline-block">
                        <app-time-grid
                          formControlName="adcMealsShift1Times"
                          [labels]="['Breakfast', 'AM Snack', 'Lunch']"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-time-grid>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Meals Shift 2 Times</div>
                    <div class="dx-field-value">
                      <div style="display: inline-block">
                        <app-time-grid
                          formControlName="adcMealsShift2Times"
                          [labels]="['Lunch', 'PM Snack', 'Supper']"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-time-grid>
                      </div>
                    </div>
                  </div>

                  <div style="text-decoration: underline">Default Meals Check Values</div>

                  <div class="dx-field">
                    <div class="dx-field-label">S1 Breakfast / S2 Lunch</div>
                    <div class="dx-field-value">
                      <mat-form-field style="margin-top: -10px">
                        <app-weekday-checkbox
                          formControlName="adcMealsDefaultBreakfastPerDayCheck"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-weekday-checkbox>
                      </mat-form-field>

                      <div>
                        <dx-switch
                          formControlName="adcMealsDefaultBreakfastCheck"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="adcMealsCheckCtrl.value !== true"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">S1 AM Snack / S2 PM Snack</div>
                    <div class="dx-field-value">
                      <mat-form-field style="margin-top: -10px">
                        <app-weekday-checkbox
                          formControlName="adcMealsDefaultSnackPerDayCheck"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-weekday-checkbox>
                      </mat-form-field>

                      <div>
                        <dx-switch
                          formControlName="adcMealsDefaultSnackCheck"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="adcMealsCheckCtrl.value !== true"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">S1 Lunch / S2 Supper</div>
                    <div class="dx-field-value">
                      <mat-form-field style="margin-top: -10px">
                        <app-weekday-checkbox
                          formControlName="adcMealsDefaultLunchPerDayCheck"
                          [readonly]="adcMealsCheckCtrl.value !== true"></app-weekday-checkbox>
                      </mat-form-field>

                      <div>
                        <dx-switch
                          formControlName="adcMealsDefaultLunchCheck"
                          switchedOnText="YES"
                          switchedOffText="NO"
                          [readOnly]="adcMealsCheckCtrl.value !== true"></dx-switch>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <!--                -->

                <fieldset>
                  <legend>
                    <div style="text-decoration: underline">ADC Reports</div>
                  </legend>

                  <div class="dx-field">
                    <div class="dx-field-label">Attendance Report</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-select-box
                          formControlName="adcAttendanceReportingMode"
                          [items]="[
                            { t: 'None', v: 'none' },
                            { t: 'Attendance Only', v: 'basic' },
                            { t: 'Attendance & Meals', v: 'atnd_meals' },
                            { t: 'Attendance with Time In/Out', v: 'ext_time_in-out' },
                            { t: 'Attendance with Time In/Out No Note', v: 'ext_time_in-out-wo-note' },
                            { t: 'Attendance with Time In/Out/Total', v: 'ext_time_in-out-total' }
                          ]"
                          valueExpr="v"
                          displayExpr="t">
                          <dx-validator name="Attendance Report">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                          </dx-validator>
                        </dx-select-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Meals Report</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="adcMealsReportingMode"
                          [showSelectionControls]="true"
                          applyValueMode="useButtons"
                          [dataSource]="adcMealsReportingModeDs"
                          displayExpr="t"
                          valueExpr="v"
                        ></dx-tag-box>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">CACFP Number</div>
                    <div class="dx-field-value">
                      <dx-text-box formControlName="cacfpNumber"></dx-text-box>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">COVID-19 Report</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-select-box
                          formControlName="adcCovidReportingMode2"
                          [items]="[
                            { t: 'None', v: 'none' },
                            { t: 'Full Weekly', v: 'full_weekly' }
                          ]"
                          valueExpr="v"
                          displayExpr="t">
                          <dx-validator name="Meals Report">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                          </dx-validator>
                        </dx-select-box>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <!--                -->

                <div class="dx-fieldset-header">Driver Tablet</div>

                <fieldset>
                  <!--                  <legend>-->
                  <!--                    <div style="text-decoration: underline">Driver Tablet</div>-->
                  <!--                  </legend>-->

                  <div class="dx-field">
                    <div class="dx-field-label">Driver Can Collect Signatures</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="driverCanCollectSignatures"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Driver Can Collect Temperature</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="driverCanCollectTemperature"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Enable MTM Integration</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enableMtmIntegration"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Signature by QR enabled</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="signatureByQr"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Enable Goetab Zone Entry Dropoff Reminder</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enableGeotabZoneDropOffReminder"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Enable Premature GeoZone DropOff Notification</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enablePrematureGeoZoneDropOffNotification"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Take Driver Temp on Vehicle Check-In</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="fleetSetupCovid19Mode"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Broadcast Post Check-In Message</div>
                    <div class="dx-field-value">
                      <dx-text-area formControlName="broadcastPostCIMsg"></dx-text-area>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Broadcast Message Acknowledgement Button Text</div>
                    <div class="dx-field-value">
                      <dx-text-box formControlName="broadcastMsgAckBtnTxt"></dx-text-box>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Enforce Driver Schedule</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="enforceDriverSchedule"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Automatically Calculate Driver Start Time</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="automaticallyCalculateDriverStartTime"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Trip cancel reasons dropdown</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-tag-box
                          formControlName="tripCancelReasons"
                          [showSelectionControls]="true"
                          applyValueMode="useButtons"
                          [dataSource]="tripCancelReasonsDs"
                          displayExpr="t"
                          valueExpr="v"
                        ></dx-tag-box>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="dx-field">
                  <div class="dx-field-label">Service Type</div>
                  <div class="dx-field-value">
                    <div>
                      <dx-select-box
                        formControlName="serviceType"
                        #serviceTypeCtrl
                        [items]="['MEALS', 'PARATRANSIT', 'MIXED']">
                        <dx-validator name="Service Type">
                          <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                      </dx-select-box>
                    </div>
                  </div>
                </div>

                <!--                -->

                <ng-container *ngIf="serviceTypeCtrl.value === 'MEALS' || serviceTypeCtrl.value === 'MIXED'">
                  <fieldset>
                    <legend>
                      <div style="text-decoration: underline">Meal Delivery Settings</div>
                    </legend>

                    <fieldset>
                      <legend>
                        <div style="text-decoration: underline">Meal Types Allowed On Schedule</div>
                      </legend>

                      <div class="dx-field">
                        <div class="dx-field-label">W1759 Home Delivered Meals - Hot Entrée Meals</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="meals_Hot_W1759_Enabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label">W1760 Home Delivered Meals - Frozen Entrée Meals</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="meals_Frozen_W1760_Enabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label">W1761 Home Delivered Meals - Sandwich Meals</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="meals_Sandwich_W1761_Enabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label">W1762 Home Delivered Meals - Emergency Pack Meals</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="meals_Emergency_W1762_Enabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label">W1764 Home Delivered Meals - Special Meal Meals</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="meals_Special_W1764_Enabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <ng-container *ngIf="config.isSU$ | async">
                      <fieldset formGroupName="mealPrepInvoicingRates">
                        <legend>
                          <div style="text-decoration: underline">Meal Prep Invoicing Rates</div>
                        </legend>

                        <div class="dx-field">
                          <div class="dx-field-label">Keystone</div>
                          <div class="dx-field-value">
                            <dx-number-box formControlName="keystone"></dx-number-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">UPMC</div>
                          <div class="dx-field-value">
                            <dx-number-box formControlName="upmc"></dx-number-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">PAHW</div>
                          <div class="dx-field-value">
                            <dx-number-box formControlName="pahw"></dx-number-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Unauthorized Discount (%)</div>
                          <div class="dx-field-value">
                            <dx-number-box formControlName="unauthorizedDiscount"></dx-number-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Ineligible Discount (%)</div>
                          <div class="dx-field-value">
                            <dx-number-box formControlName="ineligibleDiscount"></dx-number-box>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset formGroupName="mealInvoicingInfo">
                        <legend>
                          <div style="text-decoration: underline">Meal Invoicing Info</div>
                        </legend>

                        <div class="dx-field">
                          <div class="dx-field-label">Invoicing Entity Name</div>
                          <div class="dx-field-value">
                            <dx-text-box formControlName="invoicingEntityName"></dx-text-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Invoicing Entity Address</div>
                          <div class="dx-field-value">
                            <dx-text-box formControlName="invoicingEntityAddress"></dx-text-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Invoicing Entity Phone</div>
                          <div class="dx-field-value">
                            <dx-text-box formControlName="invoicingEntityPhone"></dx-text-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Invoicing Entity Email</div>
                          <div class="dx-field-value">
                            <dx-text-box formControlName="invoicingEntityEmail"></dx-text-box>
                          </div>
                        </div>

                        <div class="dx-field">
                          <div class="dx-field-label">Invoicing Terms</div>
                          <div class="dx-field-value">
                            <dx-text-box formControlName="invoicingTerms"></dx-text-box>
                          </div>
                        </div>
                      </fieldset>
                    </ng-container>

                    <fieldset>
                      <legend>
                        <div style="text-decoration: underline">Meal Delivery Confirmation</div>
                      </legend>

                      <div class="dx-field">
                        <div class="dx-field-label">Signature By Picture Enabled</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="signatureByPictureEnabled"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>

                      <div class="dx-field">
                        <div class="dx-field-label">Enable Text Message To Client On Meal DropOff</div>
                        <div class="dx-field-value">
                          <div>
                            <dx-switch
                              formControlName="smsToClientOnMealDropOff"
                              switchedOnText="YES"
                              switchedOffText="NO"></dx-switch>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </fieldset>
                </ng-container>

                <!--                -->

                <div class="dx-field">
                  <div class="dx-field-label">Bypass Code</div>
                  <div class="dx-field-value">
                    <dx-text-box formControlName="bypassCode" [readOnly]="true"></dx-text-box>
                  </div>
                </div>

                <div class="dx-fieldset-header">Fleet Setup</div>

                <fieldset>
                  <div class="dx-field">
                    <div class="dx-field-label">Winter Ready</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="fleetSetupWinterReady"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Disinfection Required</div>
                    <div class="dx-field-value">
                      <div>
                        <dx-switch
                          formControlName="fleetSetupDisinfectionRequired"
                          switchedOnText="YES"
                          switchedOffText="NO"></dx-switch>
                      </div>
                    </div>
                  </div>

                  <div class="dx-field">
                    <div class="dx-field-label">Days before Auto switch from Backup to Assigned Status</div>
                    <div class="dx-field-value">
                      <dx-number-box formControlName="backup2AssignedAfter"></dx-number-box>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="dx-fieldset">
                <dx-validation-summary></dx-validation-summary>

                <!--                <p class="alert-danger" *ngIf="(errorMessage$ | async)">-->
                <!--                  {{ errorMessage$ | async }}-->
                <!--                </p>-->

                <dx-toolbar>
                  <dxi-item location="after">
                    <dx-button type="normal" text="Reset" (onClick)="reset_onClick($event)"></dx-button>
                  </dxi-item>

                  <dxi-item location="after">
                    <dx-button
                      type="default"
                      text="Submit"
                      [useSubmitBehavior]="true"
                      [icon]="processing ? 'fas fa-circle-notch fa-spin' : null"
                      [disabled]="processing"
                    ></dx-button>
                  </dxi-item>
                </dx-toolbar>
              </div>
            </mat-card>
          </dx-scroll-view>
        </div>
      </div>
    </form>
  </div>
</app-content-w-header>

