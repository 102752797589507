import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import head from 'lodash-es/head';
import isEmpty from 'lodash-es/isEmpty';
import { LoggerService } from '../../../../sdk/services/custom';

@Component({
  selector: 'app-drop-down-grid',
  templateUrl: './drop-down-grid.component.html',
  styleUrls: ['./drop-down-grid.component.scss'],
})
export class DropDownGridComponent implements OnInit, ControlValueAccessor {
  opened = false;
  _gridSelectedRowsData: any[] = [];
  @Input() dataSource: any;
  @Input() placeholder = 'Select...';
  @Input() showClearButton = true;
  @Input() width: any;
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() valueExpr: any;
  @Input() displayExpr: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Input() popupWidth: any;
  @Input() popupHeight: any;
  @Input() gridColumns: any;
  @Input() gridSelection: any = { mode: 'single', showCheckBoxesMode: 'none' };
  @Output() cellPrepared: EventEmitter<any> = new EventEmitter();

  @ViewChild(DxDropDownBoxComponent, { static: true }) dropDownBox: DxDropDownBoxComponent;
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(public logger: LoggerService) {}

  _value: any;
  get value(): any {
    return this._value;
  }

  @Input()
  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this._gridSelectedRowKeys = (value && [value]) || [];
      this._gridSelectedRowsData = [];
    }
  }

  _gridSelectedRowKeys: number[] = [];

  get gridSelectedRowKeys(): number[] {
    // console.log('get_gridSelectedRowKeys', this._gridSelectedRowKeys);
    return this._gridSelectedRowKeys;
  }

  set gridSelectedRowKeys(keys: number[]) {
    // console.log('set_gridSelectedRowKeys', keys);
    const value = isEmpty(keys) ? null : head(keys);
    this._gridSelectedRowKeys = keys;
    this._value = value;
  }

  get gridBoxValue(): number {
    // console.log('get_gridBoxValue', this.value);
    return this._value;
  }

  set gridBoxValue(value: number) {
    // console.log('set_gridBoxValue', value);

    this._value = value;
    this._gridSelectedRowKeys = (value && [value]) || [];
    this._gridSelectedRowsData = value ? this._gridSelectedRowsData : [];
  }

  ngOnInit() {}

  writeValue(value: any): void {
    throw new Error('Method not implemented.');
  }

  registerOnChange(fn: any): void {
    throw new Error('Method not implemented.');
  }

  registerOnTouched(fn: any): void {
    throw new Error('Method not implemented.');
  }

  setDisabledState(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  grid_onSelectionChanged(e) {
    this._gridSelectedRowsData = e.selectedRowsData;

    if (this.opened) {
      this.opened = false;

      if (this.dropDownBox && this.dropDownBox.instance) {
        this.dropDownBox.instance.focus();
      }
    }
  }

  dropDownBox_onValueChanged(e) {
    setTimeout(() => {
      e.data = head(this._gridSelectedRowsData);
      this.valueChange.emit(e.value);
      this.valueChanged.emit(e);
    });
  }
}
