
declare var Object: any;
export interface AuthServiceInterface {
  "id"?: string;
}

export class AuthService implements AuthServiceInterface {
  "id": string;
  constructor(data?: AuthServiceInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AuthService`.
   */
  public static getModelName() {
    return "AuthService";
  }

  /**
  * This method creates an instance of AuthService for dynamic purposes.
  **/
  public static factory(data: AuthServiceInterface): AuthService{
    return new AuthService(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AuthService',
      plural: 'AuthService',
      path: 'AuthService',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
