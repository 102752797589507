import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import orderBy from 'lodash-es/orderBy';
import { utc } from 'moment';
import moment from 'moment/moment';
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { MongoLoadOptionsConverter } from '../../../../shared/classes/loopback-custom-store/generic/load-options-converters/MongoLoadOptionsConverter';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { LoggerService } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrls: ['./authorizations.component.scss'],
})
export class AuthorizationsComponent extends ABaseComponent implements OnInit {
  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public consumerHelper: ConsumerHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '865212c6-88c2-4f8d-b8f1-cfb7b1d29524',
    };

    this.grid_detail_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'fade160b-2020-4771-9a9b-71414fdecb16',
    };
  }

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;
  grid_detail_stateStoring: any;

  dso: DataSourceOptions;

  selectedDateValue?: Date = new Date();
  importedBeforeDateValue?: Date = new Date();

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();

    this.$filterEvent$
      .pipe(
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(async () => (this.dso = this.buildDataSource())),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const self = this;

    const activeAuthMoment =
      this.selectedDateValue && moment(this.selectedDateValue).isValid()
        ? utc(moment(this.selectedDateValue).format('YYYY-MM-DD'))
        : null;

    const importedBeforeMoment =
      this.importedBeforeDateValue && moment(this.importedBeforeDateValue).isValid()
        ? moment(this.importedBeforeDateValue)
        : null;

    const col = 'ExportsAuthsDataCache';
    const aggregate = [
      // {
      //   $lookup: {
      //     from: 'ExportsConsumersCache',
      //     localField: '_clientId',
      //     foreignField: '_clientId',
      //     as: 'member'
      //   }
      // },
      // {
      //   $addFields: {
      //     member: {
      //       $arrayElemAt: [
      //         '$member',
      //         0.0
      //       ]
      //     }
      //   }
      // },
      {
        $match: {
          _inactive: { $ne: true },
          _tenantId: this.common.auth.getCurrentTenant(),
          ...(activeAuthMoment
            ? {
                StartDT: { $lte: { $date: { v: activeAuthMoment.toDate().toISOString() } } },
                EndDT: { $gte: { $date: { v: activeAuthMoment.toDate().toISOString() } } },
              }
            : {}),
          // ...(importedBeforeMoment ? {
          //   _ctime: {$lte: {$date: {v: importedBeforeMoment.toDate().toISOString()}}},
          // } : {}),
        },
      },
      {
        $sort: {
          StartDT: -1,
          _ctime: -1,
        },
      },
      {
        $group: {
          _id: {
            MedicaidID: '$MedicaidID',
            Code: '$Code',
            StartDT: '$StartDT',
            EndDT: '$EndDT',
          },
          docs: {
            $push: '$$CURRENT',
          },
          last: {
            $first: '$$CURRENT',
          },
          count: {
            $sum: 1.0,
          },
        },
      },
      {
        $sort: {
          'last.StartDT': -1,
          'last._ctime': -1,
        },
      },
    ];

    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoLoad(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const q = new MongoLoadOptionsConverter().convert(loadOptions);
        return gqlMongoCount(self.dss, col, loadOptions, [
          // ...(!isEmpty(q.where) ? [{$match: q.where}] : []),
          ...aggregate,
        ]).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      // sort: [{selector: '_date', desc: true}],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  grid_onContextMenuPreparing(e) {}

  grid_onCellPrepared(e) {
    // console.log(e);
    const dataField = (e.column.dataField || '').split('.')[1] || '';

    if (e.rowType === 'data' && !dataField.startsWith('_')) {
      const docs = orderBy(e.data.docs, ['StartDT', '_ctime']);
      const curIdx = docs.findIndex(d => d._id === e.data.last._id);
      const prevIdx = curIdx - 1;

      // console.log(e, docs);
      if (prevIdx >= 0 && docs[curIdx][dataField] !== docs[prevIdx][dataField])
        (e.cellElement as HTMLElement).style.color = 'blue';
      // else if (prevIdx < 0)
      //   (e.cellElement as HTMLElement).style.color = 'blue';
    }
  }

  grid_detail_onCellPrepared(e, docs: any[]) {
    const dataField = e.column.dataField || '';

    if (e.rowType === 'data' && !dataField.startsWith('_')) {
      docs = orderBy(docs, ['StartDT', '_ctime']);

      const curIdx = docs.findIndex(d => d._id === e.data._id);
      const prevIdx = curIdx - 1;

      // console.log(e, docs);
      if (prevIdx >= 0 && docs[curIdx][dataField] !== docs[prevIdx][dataField])
        (e.cellElement as HTMLElement).style.color = 'blue';
    }
  }
}
