import { Inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExtSDKModels } from '../../../../modules/ext-sdk/services/ext-sdk-models.service';
import { BaseLoopBackApi, SDKModels } from '../../../../sdk';
import { errorMapFn, LoopBackStoreOptions } from './LoopBackStoreOptions';

export class LoopBackExtendedStoreOptions<T, V> extends LoopBackStoreOptions<T, V> {
  constructor(
    @Inject(SDKModels) protected models: ExtSDKModels,
    protected loopBackApi: BaseLoopBackApi,
    protected loopBackViewApi: BaseLoopBackApi,
  ) {
    super(models, loopBackApi, loopBackViewApi);
  }

  /**
   *
   */
  insert = (values: any): Promise<any> =>
    (this.loopBackApi as any)
      .myCreateWithRelated(values, this.applyHeaders.bind(this), this.customOptions)
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Insert Error');
          return of(null);
        }),
      )
      .toPromise();

  /**
   *
   */
  update = (key: any | string | number, values: any): Promise<any> =>
    (this.loopBackApi as any)
      .mySaveWithRelated(key, values, this.applyHeaders.bind(this), this.customOptions)
      .pipe(
        catchError(err => {
          errorMapFn(err, 'Data Update Error');
          return of(null);
        }),
      )
      .toPromise();
}
