import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxMapComponent } from 'devextreme-angular/ui/map';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { locale } from 'devextreme/localization';
import isEmpty from 'lodash-es/isEmpty';
import merge from 'lodash-es/merge';
import moment, { duration } from 'moment-timezone';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { Employee, EmployeeView, Facility, LoggerService, MobileDevice, MyUser, Vehicle } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DlgPushToMobileComponent } from '../../dialogs/dlg-push-to-mobile/dlg-push-to-mobile.component';

@Component({
  selector: 'app-devices-grid',
  templateUrl: './devices-grid.component.html',
  styleUrls: ['./devices-grid.component.scss'],
})
export class DevicesGridComponent extends ABaseComponent implements OnInit {
  ds: DataSource;

  markerBaseUrl = '/assets/images/';
  mapCenter: any;
  mapZoom = 14;
  autoAdjust = true;
  markers: any[] = [];

  grid_stateStoring: any;

  facilityDso: any;
  userDso: any;
  employeeDso: any;
  vehicleDso: any;

  @ViewChild(DxMapComponent, { static: false }) map: DxMapComponent;

  customizeDateTimeText = cellInfo => {
    return cellInfo.value
      ? new Intl.DateTimeFormat(locale(), {
          dateStyle: 'short',
          timeStyle: 'short',
          timeZone: this.config.get('timezone'),
        } as any).format(cellInfo.value)
      : '';
  };

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private dialog: MatDialog,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    public employeeHelper: EmployeeHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '80d8cbf3-9b0d-44f2-ae11-33269693d1df',
    };

    this.ds = this.buildDataSource();
    this.facilityDso = this.buildFacilityDataSource();
    this.userDso = this.buildUserDataSource();
    this.employeeDso = this.buildEmployeeDataSource();
    this.vehicleDso = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {});
  }

  grid_onSelectionChanged(e) {
    // console.log('grid_onSelectionChanged:', e);
    const [item] = e.selectedRowsData as MobileDevice[];
    const items = e.selectedRowsData as MobileDevice[];
    if (!oc(item).incognito() && !/^Mike[`']s Tab.*/gi.test(oc(item).label(''))) {
      this.mapCenter = oc(item).geotabLocationPoint()
        ? {
            lat: (oc(item).geolocationPoint().lat + oc(item).geotabLocationPoint().lat) / 2,
            lng: (oc(item).geolocationPoint().lng + oc(item).geotabLocationPoint().lng) / 2,
          }
        : oc(item).geolocationPoint();
      this.autoAdjust = false;
      this.mapZoom = 17;
      this.markers = this.markers.map(m =>
        merge({}, m, {
          tooltip: {
            isShown: items.map(i => i.deviceUid).includes(m._deviceUid),
          },
          iconSrc: items.map(i => i.deviceUid).includes(m._deviceUid)
            ? this.markerBaseUrl + 'marker-selected-device.png'
            : null,
        }),
      );
    }
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      const doc = e.row.data;
      const key = e.row.key;

      e.items = [
        {
          text: 'Push to mobile',
          onItemClick: () => {
            void this.dialog
              .open(DlgPushToMobileComponent, {
                width: '600px',
                hasBackdrop: true,
                data: { mobile: doc },
              })
              .afterClosed()
              .toPromise();
          },
        },
      ];
    }
  }

  grid_onCellDblClick(e) {
    if (e.rowType === 'data' && ['getGeotabLocation', 'getTabletLocation'].includes(e.column.dataField)) {
      const isGeotab = e.column.dataField === 'getGeotabLocation';

      if ((isGeotab && oc(e.data).geotabLocationPoint()) || (!isGeotab && oc(e.data).geolocationPoint())) {
        this.mapCenter = isGeotab ? oc(e.data).geotabLocationPoint() : oc(e.data).geolocationPoint();
        this.autoAdjust = false;
        this.mapZoom = 17;
        // this.markers = this.markers
        //   .map((m) => merge({}, m, {
        //     tooltip: {
        //       isShown: [e.data].map(i => i.deviceUid).includes(m._deviceUid),
        //     },
        //   }));
      }
    }
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(MobileDevice, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.useRegExp = true;
    so.noSql = true;
    const store: CustomStore = new CustomStore(so);
    return new DataSource({
      store,
      postProcess: (data: Array<MobileDevice>) => {
        this.autoAdjust = true;

        this.markers = [
          ...data
            .filter(itm => !itm.incognito && !/^Mike[`']s Tab.*/gi.test(itm.label))
            .filter(itm => !isEmpty(itm.geolocationPoint))
            .map(itm => ({
              _deviceUid: itm.deviceUid,
              tooltip: {
                text:
                  `${itm.label || itm.deviceUid}` +
                  // `<br/><em>last time location:</em> ${moment(itm.lastTimeGeolocation).format('lll')}` +
                  // `<br/>${duration(moment(itm.lastTimeGeolocation).diff(moment())).humanize(true)}` +

                  `<br/><em>last time location:</em> ${moment(itm.geolocation.date).format('lll')}` +
                  `<br/>${duration(moment(itm.geolocation.date).diff(moment())).humanize(true)}` +
                  `<br/>geotab: false`,
                // `<hr/><br/><em>last time online:</em> ${moment(itm.lastRequest).format('lll')}` +
                // `<br/>${duration(moment(itm.lastRequest).diff(moment())).humanize(true)}`
                isShown: false,
              },
              location: itm.geolocationPoint,
            })),

          ...data
            .filter(itm => !itm.incognito && !/^Mike[`']s Tab.*/gi.test(itm.label))
            .filter(itm => !isEmpty(itm.geotabLocationPoint))
            .map(itm => ({
              _deviceUid: itm.deviceUid,
              tooltip: {
                text:
                  `${itm.label || itm.deviceUid}` +
                  // `<br/><em>last time location:</em> ${moment(itm.lastTimeGeotabLocation).format('lll')}` +
                  // `<br/>${duration(moment(itm.lastTimeGeotabLocation).diff(moment())).humanize(true)}` +

                  `<br/><em>last time location:</em> ${moment(itm.geotabLocation.date).format('lll')}` +
                  `<br/>${duration(moment(itm.geotabLocation.date).diff(moment())).humanize(true)}` +
                  `<br/>geotab: true`,
                // `<hr/><br/><em>last time online:</em> ${moment(itm.lastRequest).format('lll')}` +
                // `<br/>${duration(moment(itm.lastRequest).diff(moment())).humanize(true)}`
                isShown: false,
              },
              location: itm.geotabLocationPoint,
            })),
        ];

        data.forEach(rec => {
          (rec as any).getLocalTime = function () {
            const _self = this;

            const time = oc(_self).deviceTime.time();
            const timezone = oc(_self).deviceTime.timeZone();

            return time && timezone ? moment(time).tz(timezone).format('LT') : '';
          }.bind(rec);

          (rec as any).getGeotabLocation = function () {
            const _self = this;
            return oc(_self).geotabLocationPoint() ? JSON.stringify(oc(_self).geotabLocationPoint()) : '';
          }.bind(rec);

          (rec as any).getTabletLocation = function () {
            const _self = this;
            return oc(_self).geolocationPoint() ? JSON.stringify(oc(_self).geolocationPoint()) : '';
          }.bind(rec);
        });

        return data;
      },
    } as DataSourceOptions);
  }

  private buildUserDataSource() {
    const store = this.dss.getStore(MyUser);
    return {
      store,
      sort: [{ selector: 'username' }],
    } as DataSourceOptions;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    return {
      store,
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, EmployeeView, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = headersAllTenantsAppend;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'internalId' }],
    } as DataSourceOptions;
  }

  empDisplayExpr(e: EmployeeView) {
    return [...[e.facility_shortname ? [`${e.facility_shortname}:`] : []], e.person_firstname, e.person_lastname].join(
      ' ',
    );
  }

  driverDropDown_onValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }
}
