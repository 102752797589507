import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { LoggerService, MyUtilsApi } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { InternalStorage } from 'src/app/shared/sdk';
export class VehicleDetailsTabLocationsComponent extends ABaseComponent {
    constructor(logger, config, internalStorage, myUtilsAPI, ui, dss, dialog) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.internalStorage = internalStorage;
        this.myUtilsAPI = myUtilsAPI;
        this.ui = ui;
        this.dss = dss;
        this.dialog = dialog;
        this.mapCenter = { lat: 39.98887229222728, lng: -75.15665435443266 };
        this.mapZoom = 11;
        this.autoAdjust = true;
    }
    set markers(ms) {
        this._markers = ms;
        this.changeHandle();
    }
    get markers() {
        return this._markers;
    }
    changeHandle() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
}
