<dx-data-grid
  #grid
  [dataSource]="dso"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onDataErrorOccurred)="grid_onDataErrorOccurred($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <!--<dxi-column caption="Name">-->
  <dxi-column caption="Full Name" dataField="name" [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column caption="Short Name" dataField="shortname" [allowHeaderFiltering]="false"></dxi-column>
  <!--</dxi-column>-->

  <dxi-column caption="Type" dataField="type" [allowHeaderFiltering]="false">
    <dxo-lookup [dataSource]="types" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <!--<dxi-column caption="Address">-->
  <dxi-column
    caption="Street"
    dataField="contact_addresses_0_street"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column
    caption="City"
    dataField="contact_addresses_0_city"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column
    caption="State"
    dataField="contact_addresses_0_state"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column
    caption="Zip"
    dataField="contact_addresses_0_zip"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>
  <!--</dxi-column>-->

  <!--<dxi-column caption="Contacts">-->
  <dxi-column
    caption="Phone"
    dataField="contact_phones_0_value"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Email"
    dataField="contact_emails_0_value"
    [allowSorting]="true"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>
  <!--</dxi-column>-->

  <dxi-column
    caption="Notes"
    dataField="notes"
    editCellTemplate="textArea_editCell"
    [allowGrouping]="false"
    [allowSorting]="false"
    [allowSearch]="true"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'textArea_editCell'">
    <dx-text-area [value]="cellInfo.value" (onValueChanged)="cellInfo.setValue($event.value)"></dx-text-area>
  </ng-container>
</dx-data-grid>
