import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import moment from 'moment';
import { asShortDate } from '../../../../shared/classes/utils/time.utils';
import { Employee, LoggerService } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-trip-manifest-main-toolbar',
  templateUrl: './trip-manifest-main-toolbar.component.html',
  styleUrls: ['./trip-manifest-main-toolbar.component.scss'],
  providers: [HelperService, EmployeeHelperService],
})
export class TripManifestMainToolbarComponent implements OnInit, OnDestroy {
  toolbarItems: any[];

  tabItems: any[] = [
    { text: 'Assignments', route: 'assignments' },
    { text: 'Manifest', route: 'manifest' },
    // {text: 'Stats', route: 'stats'},
  ];

  driversDSO: DataSourceOptions;

  @Input() selectedDate: Date = new Date();
  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter();
  maxDate: Date = moment().add(1, 'month').toDate();

  @Input() selectedDriver: any;
  @Output() selectedDriverChange: EventEmitter<any> = new EventEmitter();

  @Input() selectedIndex = 0;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();

  private dateManifestMap: Map<string, number> = new Map();

  constructor(
    public helper: HelperService,
    public employeeHelper: EmployeeHelperService,
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {
    this.buildDataSource();
    this.buildItems();
  }

  ngOnInit() {
    this.buildDateManifestMap();
  }

  ngOnDestroy(): void {}

  buildDateManifestMap() {
    this.helper
      .buildDateManifestMapAsync(20, 6)
      .then(map => (this.dateManifestMap = map))
      .catch(this.logger.error);
  }

  manifestExist({ date, view }): boolean {
    return view === 'month' ? this.dateManifestMap.has(asShortDate(date)) : false;
  }

  dateBox_onValueChanged(e) {
    setTimeout(() => {
      this.selectedDate = e.value;
      this.selectedDateChange.emit(this.selectedDate);
    });
  }

  dropDownGrid_onValueChanged(e) {
    setTimeout(() => {
      this.selectedDriver = e.value;
      this.selectedDriverChange.emit(this.selectedDriver);
    });
  }

  tabs_onSelectionChanged(e) {
    setTimeout(() => {
      const value = e.component.option('selectedIndex');
      this.selectedIndex = value;
      this.selectedIndexChange.emit(value);
    });
  }

  private buildDataSource() {
    const dfs = this.helper.buildDriversFilter('employeePosition_name');
    this.driversDSO = {
      store: this.dss.getStore(Employee, null, false),
      filter: [...(dfs.length ? [dfs] : []), ['status', '=', 'ACTIVE']],
    };
  }

  private buildItems() {
    this.toolbarItems = [
      {
        location: 'before',
        template: 'manifestDateBox',
        locateInMenu: 'never',
      },
      {
        location: 'before',
        template: 'employeeDropDown',
        locateInMenu: 'never',
      },
      {
        location: 'after',
        template: 'tabs',
        locateInMenu: 'never',
      },
    ];
  }
}

