<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">Employee Info</div>

    <div class="dx-field">
      <div class="dx-field-label">Position *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="positionDS" displayExpr="name" valueExpr="id" formControlName="employeePositionId">
          <dx-validator name="Position">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Status *</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="statuses" displayExpr="Name" valueExpr="ID" formControlName="status">
          <dx-validator name="Status">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Employment Type</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="types" displayExpr="Name" valueExpr="ID" formControlName="type"></dx-select-box>
      </div>
    </div>

    <ng-container formGroupName="data">
      <div class="dx-field">
        <div class="dx-field-label">Employment Title</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="title"></dx-text-box>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stateFull">
      <div class="dx-field">
        <div class="dx-field-label">Interview Date</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="interviewedAt" applyValueMode="useButtons"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Date Hired</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="hiredAt" applyValueMode="useButtons"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Date Separated</div>
        <div class="dx-field-value">
          <dx-date-box formControlName="separatedAt" applyValueMode="useButtons"></dx-date-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Allow Fast Vehicle Checkout</div>
        <div class="dx-field-value">
          <div>
            <dx-switch formControlName="allowFastVehicleCheckout" switchedOnText="Yes" switchedOffText="No"></dx-switch>
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Allow Multi Vehicle Checkin</div>
        <div class="dx-field-value">
          <div>
            <dx-switch formControlName="allowMultiCheckin" switchedOnText="Yes" switchedOffText="No"></dx-switch>
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Notes</div>
        <div class="dx-field-value">
          <dx-text-area formControlName="notes"></dx-text-area>
        </div>
      </div>
    </ng-container>

    <!--<mat-divider></mat-divider>-->

    <ng-container formGroupName="person">
      <div class="dx-fieldset-header">Person Info</div>

      <div class="dx-field">
        <div class="dx-field-label">First Name *</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="firstname">
            <dx-validator name="First Name">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Last Name *</div>
        <div class="dx-field-value">
          <dx-text-box formControlName="lastname">
            <dx-validator name="Last Name">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </div>

      <ng-container *ngIf="stateFull">
        <div class="dx-field">
          <div class="dx-field-label">Middle Name</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="middlename"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Nickname</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="nickname"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Sex</div>
          <div class="dx-field-value">
            <dx-select-box
              [dataSource]="personSexesDS"
              displayExpr="Name"
              valueExpr="ID"
              formControlName="sex"></dx-select-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">DOB</div>
          <div class="dx-field-value">
            <dx-date-box formControlName="dob" applyValueMode="useButtons"></dx-date-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Notes</div>
          <div class="dx-field-value">
            <dx-text-area formControlName="notes"></dx-text-area>
          </div>
        </div>

        <!-- /// -->

        <div class="dx-fieldset-header">Other Info</div>

        <ng-container formGroupName="data">
          <div class="dx-field">
            <div class="dx-field-label">Native Language</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="nativeLang"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Native Language Name</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="nativeLangName"></dx-text-box>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-label">Driver License</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="dl"></dx-text-box>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--<mat-divider></mat-divider>-->

      <ng-container formGroupName="contact">
        <!--<mat-divider></mat-divider>-->

        <!--<div class="dx-fieldset-header">Contact Info</div>

        <div class="dx-field">
          <div class="dx-field-label">Notes</div>
          <div class="dx-field-value">
            <dx-text-area formControlName="notes"></dx-text-area>
          </div>
        </div>-->

        <ng-container formArrayName="phones">
          <br />

          <app-form-array-group
            entityName="phone"
            icon="phone"
            [formArray]="phonesFormArray"
            (addItem)="addFormArrayItem('person.contact.phones')"
            (removeItem)="removeFormArrayItem('person.contact.phones', $event)"></app-form-array-group>
        </ng-container>

        <!--<mat-divider></mat-divider>-->

        <ng-container formArrayName="emails">
          <br />

          <app-form-array-group
            entityName="email"
            icon="email"
            [formArray]="emailsFormArray"
            (addItem)="addFormArrayItem('person.contact.emails')"
            (removeItem)="removeFormArrayItem('person.contact.emails', $event)"></app-form-array-group>
        </ng-container>

        <!--<mat-divider></mat-divider>-->

        <ng-container formArrayName="addresses">
          <br />

          <app-form-array-group
            entityName="place"
            icon="place"
            [formArray]="addressesFormArray"
            (addItem)="addFormArrayItem('person.contact.addresses')"
            (removeItem)="removeFormArrayItem('person.contact.addresses', $event)"></app-form-array-group>
        </ng-container>
      </ng-container>
    </ng-container>

    <br />
    <mat-divider style="margin: 0 -34px"></mat-divider>
    <br /><br />

    <div class="dx-fieldset-header">Related Contacts</div>

    <ng-container formArrayName="emRelations">
      <br />

      <app-form-array-group
        entityName="relation"
        icon="supervisor_account"
        [formArray]="emRelationsFormArray"
        (addItem)="addFormArrayItem('emRelations')"
        (removeItem)="removeFormArrayItem('emRelations', $event)"></app-form-array-group>
    </ng-container>

    <!-- /// -->
    <p>
      <dx-validation-summary></dx-validation-summary>
    </p>

    <!--    <p class="alert-danger" *ngIf="errorMessage">-->
    <!--      {{ errorMessage }}-->
    <!--    </p>-->
  </div>
</dx-validation-group>
