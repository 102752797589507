<!--<dx-validation-group [formGroup]="form">-->
<div class="dx-fieldset">
  <div class="dx-field">
    <div class="dx-field-label">Roles</div>
    <div class="dx-field-value">

      <mat-radio-group [(ngModel)]="groupedBy">
        <mat-radio-button value="byFacility">By Facility</mat-radio-button>
        <mat-radio-button value="byRole">By Role</mat-radio-button>
      </mat-radio-group>

      <hr />

      <ng-container *ngIf="groupedBy === 'byFacility'">
        <ng-container *ngFor="let grp of ($tenantGroups$ | async)">
          <div>
            <ng-container *ngIf="grp[0]!='0'; else noTenantBlock">
              <strong>
                <app-object-title modelName="Facility" [modelId]="grp[0]"></app-object-title>
                <button mat-icon-button
                        color="primary" style="margin: -5px;"
                        (click)="removeRole(null, grp[0])"
                        title="Mark all tenant roles for remove"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
                :
              </strong>
            </ng-container>

            <ng-template #noTenantBlock>
              <strong>
                Global
                :
              </strong>
            </ng-template>

            <span
              *ngFor="let r of grp[1]"
              [ngClass]="{badge: true, toAdd: rolesToAdd[r + ':' + grp[0]]}"
              [ngStyle]="{ 'text-decoration': rolesForRemove[r + ':' + grp[0]] ? 'line-through' : 'none' }"
            >{{ r }}
              <button mat-icon-button color="warn" (click)="removeRole(r, grp[0])" title="Mark role for remove"><mat-icon>cancel</mat-icon></button>
          </span>

          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="groupedBy === 'byRole'">
        <ng-container *ngFor="let grp of ($roleGroups$ | async)">
          <div>

            <strong>
              {{ grp[0] }}
              <button mat-icon-button
                      color="primary" style="margin: -5px;"
                      (click)="removeRole(grp[0], null)"
                      title="Mark all roles for remove"
              >
                <mat-icon>cancel</mat-icon>
              </button>
              :
            </strong>

            <span
              *ngFor="let fId of grp[1]"
              [ngClass]="{badge: true, toAdd: rolesToAdd[grp[0] + ':' + fId]}"
              [ngStyle]="{ 'text-decoration': rolesForRemove[grp[0] + ':' + fId] ? 'line-through' : 'none' }"
            >
              <ng-container *ngIf="fId!='0'; else noTenantBlock">
                <app-object-title modelName="Facility" [modelId]="fId"></app-object-title>
              </ng-container>
              <ng-template #noTenantBlock>Global</ng-template>

              <button mat-icon-button color="warn" (click)="removeRole(grp[0], fId)" title="Mark role for remove"><mat-icon>cancel</mat-icon></button>
          </span>

          </div>
        </ng-container>
      </ng-container>

      <!--      <pre>{{ $userRoles$ | async |json }}</pre>-->

      <br />

      <ng-container *ngIf="addingRole; else noAdding">

        <mat-card>
          <mat-card-content>
            <label>Roles:</label>
            <dx-tag-box
              #roles
              [dataSource]="rolesDso"
              valueExpr="name"
              displayExpr="name"
              [searchEnabled]="true"
              [showSelectionControls]="true"
            ></dx-tag-box>

            <label>Facilities:</label>
            <dx-tag-box
              #facilities
              [dataSource]="facilityDso"
              valueExpr="id"
              displayExpr="name"
              itemTemplate="facilityItem"
              [searchEnabled]="true"
              [showSelectionControls]="true"
              [grouped]="true"
            >
              <div *dxTemplate="let f of 'facilityItem'">
                <strong>{{ f.name }}</strong> [{{ f.type }}: {{ f.shortname }}]
              </div>
            </dx-tag-box>
          </mat-card-content>

          <mat-card-actions>
            <button mat-button color="primary" (click)="setRoles(roles.value, facilities.value)">Apply</button>
            <button mat-button (click)="setRoles()">Cancel</button>
          </mat-card-actions>
        </mat-card>

      </ng-container>

      <ng-template #noAdding>
        <button mat-raised-button (click)="addRole()">
          Add role
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>

    </div>
  </div>

  <!-- /// -->

  <!--    <p>-->
  <!--      <dx-validation-summary></dx-validation-summary>-->
  <!--    </p>-->

  <p class="alert-danger" *ngIf="error">
    {{ error }}
  </p>
</div>
<!--</dx-validation-group>-->

