<dx-data-grid
  #grid
  height="100%"
  width="100%"
  [dataSource]="dso"
  [errorRowEnabled]="true"
  [stateStoring]="grid_stateStoring"
  [showRowLines]="true"
  [columnChooser]="{ enabled: false }"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)">
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>

  <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-selection [mode]="'multiple'" [selectAllMode]="'page'"></dxo-selection>

  <!-- Columns -->

  <dxi-column caption="ID" dataField="id" dataType="number" [visible]="false"></dxi-column>

  <dxi-column caption="Staff or Participant Name" dataField="getName" dataType="string"></dxi-column>

  <dxi-column caption="Staff or Participant" dataField="getStaffOrParticipant" dataType="string">
    <dxo-lookup [dataSource]="['Staff', 'Participant']"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Staff Title" dataField="getStaffTitle" dataType="string"></dxi-column>

  <dxi-column caption="Days of week" dataField="_daysOfWeek" dataType="string"></dxi-column>

  <dxi-column caption="Reporting Mode" dataField="getReportingMode" dataType="string"></dxi-column>

  <!-- Templates -->
</dx-data-grid>
