import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
//
import { Consumer, LoggerService, LoopBackFilter, Person } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';

@Component({
  selector: 'app-consumer-details-tab-instructions',
  templateUrl: './consumer-details-tab-instructions.component.html',
  styleUrls: ['./consumer-details-tab-instructions.component.scss'],
})
export class ConsumerDetailsTabInstructionsComponent
  extends ABaseModelPaneWToolbarComponent<Consumer>
  implements OnInit, OnChanges, OnDestroy
{
  constructor(protected logger: LoggerService, protected ui: UiService, protected dss: DataSourceService) {
    super(logger, ui, dss);

    this.caption = 'Consumer Instructions';
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Consumer>> {
    return ConsumerFormComponent;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        { person: 'contact' },
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC' },
        },
      ],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, Person];
  }

  protected buildToolbarItems(model: any = null): void {
    this.toolbarItems = [
      {
        location: 'before',
        locateInMenu: 'never',
        text: this.caption,
      },
    ];
  }
}
