import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { SERVICE_TYPE } from '../../../trip-manifest/classes/enums';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import {
  Config,
  ConfigApi,
  Consumer,
  ConsumerApi,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  MyUtilsApi,
  Signature,
  SignatureApi,
  Vehicle,
  VehicleApi,
} from '../../../../shared/sdk';
import { Router } from '@angular/router';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { oc } from 'ts-optchain';
import uniq from 'lodash-es/uniq';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import CustomStore from 'devextreme/data/custom_store';
import { hasAmbTrips, headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss'],
})
export class SignaturesComponent extends ABaseComponent implements OnInit {
  $items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  dso$ = of(new DataSource([]));

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);
  consumerDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  facilitySubDso$: Observable<DataSourceOptions> = of([]);
  consumerSubDso$: Observable<DataSourceOptions> = of([]);
  employeeSubDso$: Observable<DataSourceOptions> = of([]);
  vehicleSubDso$: Observable<DataSourceOptions> = of([]);

  tripDsoMap: Map<number, any> = new Map();
  serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
  schoolDestDS$;

  grid_stateStoring: any;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  facilityId?: number;
  driverId?: number;
  clientId?: number;

  serviceType? = SERVICE_TYPE.SCHOOL;
  school?: string;
  broker?: string;
  mci?: string;
  // mco?: string;
  // hasTrip?: boolean;
  // hasClaim?: boolean;

  // billingStatus?: string;
  // claimState?: string;
  // clientName?: string;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  private vehicleDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  private driverDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  constructor(
    protected logger: LoggerService,
    private router: Router,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    private signatureApi: SignatureApi,
    private facilityApi: FacilityApi,
    private utilsApi: MyUtilsApi,
    private consumerApi: ConsumerApi,
    private vehicleApi: VehicleApi,
    private employeeApi: EmployeeApi,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'ab63f708-db93-4201-9f14-9116c028aa0a',
    };

    this.dso$ = this.$items$.pipe(
      map(items => {
        const aso: ArrayStoreOptions = {
          key: Signature.getModelDefinition().idName,
          data: items,
        } as ArrayStoreOptions;

        // console.log(recs.length);

        return new DataSource(new ArrayStore(aso));
      }),
      tap(() => {
        if (this.$items$.getValue()) {
          this.facilitySubDso$ = this.buildFacilitySubDataSource();
          this.consumerSubDso$ = this.buildConsumerSubDataSource();
          this.employeeSubDso$ = this.buildEmployeeSubDataSource();
          this.vehicleSubDso$ = this.buildVehicleSubDataSource();
        }
      }),
    );

    this.facilityDso$ = this.buildFacilityDataSource();
    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();

    this.schoolDestDS$ = this.dss
      .getApi<ConfigApi>(Config)
      .getAllDestinations()
      .pipe(map(arr => arr.filter(i => i.group === 'SCHOOL')));
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.$items$.next([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
          ),
        ),
        tap(items => {
          this.$items$.next(items);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      map(() => {
        const from = this.selectedFromValue;
        const to = this.selectedToValue;

        const fromMoment = from && moment(from);
        const toMoment = to && moment(to).add(1, 'days');

        if (!fromMoment || !toMoment) {
          throw new Error('Period should be defined');
        }

        if (toMoment.diff(fromMoment, 'months') > 1) {
          throw new Error('Period should be less or equal to 1 month');
        }
        const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromIncl: strFrom,
          toExcl: strTo,

          serviceType: this.serviceType,
          broker: this.broker,
          mci: this.mci,
          // mco: this.mco,
          // hasTrip: this.hasTrip,
          // hasClaim: this.hasClaim,

          facilityIds: this.facilityId ? [this.facilityId] : [],
          driverIds: this.driverId ? [this.driverId] : [],
          clientIds: this.clientId ? [this.clientId] : [],
        };
      }),

      switchMap(fltr =>
        this.pusher.rpc('GET_SIGNATURES', { ...fltr, useRunService: true }, true, headersAllTenantsAppend),
      ),

      switchMap(url =>
        this.http.get(url, {
          responseType: 'json',
          withCredentials: false,
        }),
      ),

      map((recs: any[]) => {
        this.tripDsoMap.clear();

        recs.forEach(r => {
          this.tripDsoMap.set(r.id, r._trips);

          // r.getClaimStatus = (function () {
          //   const _self = this;
          //   return oc(_self)._claim.STATUS()
          //     || oc(_self)._claim['Reimbursement Status']();
          // }).bind(r);

          r.getLastName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's Last Name"]() ||
              oc(_self)._trip['Member Last Name']() ||
              oc(_self)._trip._lastname() ||
              oc(_self)._client.lastname()
            );
          }.bind(r);

          r.getFirstName = function () {
            const _self = this;
            return (
              oc(_self)._trip["Member's First Name"]() ||
              oc(_self)._trip['Member First Name']() ||
              oc(_self)._trip._firstname() ||
              oc(_self)._client.firstname()
            );
          }.bind(r);

          r.getMci = function () {
            const _self = this;
            return oc(_self)._trip._mci() || oc(_self)._client.mci();
          }.bind(r);

          // r.getBatchCount = (function () {
          //   const _self = this;
          //   return oc(_self)._batchIds([]).length;
          // }).bind(r);

          // r.getBatchStatus = (function () {
          //   const _self = this;
          //   return oc(_self)._currBatchStatus()
          //     || oc(_self)._lastBatchStatus()
          //     ;
          // }).bind(r);

          r.getServiceType = function () {
            const _self = this;
            return oc(_self).meta.serviceType(SERVICE_TYPE.PARATRANSIT);
          }.bind(r);

          // r.getMco = (function () {
          //   const _self = this;
          //   return oc(_self)._client.mco();
          // }).bind(r);

          r.getOrigin = function () {
            const _self = this;
            return oc(_self)._rec.o();
          }.bind(r);

          r.getDestination = function () {
            const _self = this;
            return oc(_self)._rec.d();
          }.bind(r);
        });

        // recs = recs.filter((r) => !this.mco || oc(r)._client.mco() === this.mco);
        recs = recs.filter(r => !this.school || [r.getOrigin(), r.getDestination()].includes(this.school));
        return recs;
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  // getTripDso(e) {
  //   // console.log(e);
  //   return [];
  // }

  tripId_selectedItemChange(cellInfo, e) {
    // console.log(cellInfo, e);

    const ds = this.grid.instance.getDataSource();
    const as = ds.store() as ArrayStore;
    as.push([{ type: 'update', data: { _trip: e }, key: cellInfo.key }]);

    // this.grid.instance.repaint();
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildFacilitySubDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: [
        ['type', 'inq', ['ADC', 'BASE']],
        ['id', 'inq', uniq(this.$items$.getValue().map(r => r.tenantId))],
      ],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerSubDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.consumerId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeSubDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.employeeId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleSubDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = headersAllTenantsAppend;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
      filter: [['id', 'inq', uniq(this.$items$.getValue().map(r => r.vehicleId))]],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  vehicleData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.vehicleDsMap[id]) {
      self.vehicleDsMap[id] = {};
    }

    if (!self.vehicleDsMap[id].fullInstance) {
      const inst$ = self.vehicleApi.findById(id, {}, headersAllTenantsAppend);

      self.vehicleDsMap[id].fullInstance = inst$;
    }

    return self.vehicleDsMap[id].fullInstance;
  }

  driverData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.driverDsMap[id]) {
      self.driverDsMap[id] = {};
    }

    if (!self.driverDsMap[id].fullInstance) {
      const inst$ = self.employeeApi.findById(
        id,
        {
          include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
        },
        headersAllTenantsAppend,
      );

      self.driverDsMap[id].fullInstance = inst$;
    }

    return self.driverDsMap[id].fullInstance;
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);

    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      // disabled: this.$showFromBroker$.value,
      name: 'buildSchoolReport',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-file-signature',
        text: 'Build Report for School',
        hint: 'Build School Report',
        onClick: this.grid_toolbar_buildSchoolReport_onClick.bind(this),
      },
    });
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      // this.logger.log(e);
      // const eId = get(e.row.data, HelperService.REC_FIELD_MAP.employeeId);
      // const cId = get(e.row.data, HelperService.REC_FIELD_MAP.consumerId);

      e.items = [];
    }
  }

  grid_onCellPrepared(e) {
    // console.log(e);
    // if (e.rowType === 'data' && e.column.dataField === '_trip._tripId') {
    //   const items = this.grid.instance.getDataSource().items();
    //   const count = items.filter((i) => oc(i)._trip._tripId() === oc(e).data._trip._tripId()).length;
    //   if (count > 1) (e.cellElement as HTMLElement).classList.add('cell-danger');
    // }
  }

  grid_onSaved(e) {
    // this.grid.instance.repaint();
  }

  grid_toolbar_buildSchoolReport_onClick() {
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_SCHOOL_REPORT_RPC', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap((uris: string[]) => uris.forEach(uri => window.open(uri))),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}
