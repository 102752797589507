import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { utc } from 'moment/moment';
import { oc } from 'ts-optchain';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-notify-coord',
  templateUrl: './dlg-notify-coord.component.html',
  styleUrls: ['./dlg-notify-coord.component.scss'],
})
export class DlgNotifyCoordComponent extends ABaseComponent implements OnInit {
  subject = 'Request for Renewed PERS Service Authorization';
  body =
    `Dear ${oc(this.auth).ServiceCoordinatorName()}, \n\n` +
    'I`m reaching out to notify you that the PERS Service Authorization for the participant listed ' +
    'below will expire on [date].\n' +
    'Could you please provide a renewed Service Authorization for this member or let us know ' +
    'how to proceed? \n' +
    `Participant Name: ${oc(this.auth).Name()} \n` +
    `Date of Birth: ${utc(oc(this.auth).MemberDOB()).format('L')} \n` +
    `MCI Number: ${oc(this.auth).MedicaidID()} \n\n` +
    'Thank you.';

  get auth() {
    return oc(this.data).cellInfo.data.last();
  }

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgNotifyCoordComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cellInfo: any;
    },
  ) {
    super(logger);
  }

  ngOnInit() {}

  submit() {
    alert('not implemented');
    this.dialogRef.close();
  }
}
