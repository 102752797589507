
declare var Object: any;
export interface MyUtilsInterface {
  "id"?: string;
}

export class MyUtils implements MyUtilsInterface {
  "id": string;
  constructor(data?: MyUtilsInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MyUtils`.
   */
  public static getModelName() {
    return "MyUtils";
  }

  /**
  * This method creates an instance of MyUtils for dynamic purposes.
  **/
  public static factory(data: MyUtilsInterface): MyUtils{
    return new MyUtils(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MyUtils',
      plural: 'MyUtils',
      path: 'MyUtils',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
