import { Pipe, PipeTransform } from '@angular/core';
import intersection from 'lodash-es/intersection';

@Pipe({
  name: 'weekDays',
})
export class WeekDaysPipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    return intersection(
      ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'],
      Object.entries(value || {})
        .filter(([, v]) => v)
        .map(([k]) => k.toUpperCase()),
    ).join(', ');
  }
}
