import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { StatsComponent } from './containers/stats/stats.component';
import { AdcStatsComponent } from './components/adc-stats/adc-stats.component';
import { VehicleAttendanceComponent } from './components/vehicle-attendance/vehicle-attendance.component';
import { AdcServiceStatsComponent } from './components/adc-service-stats/adc-service-stats.component';
import { Covid19ChecksComponent } from './components/covid19-checks/covid19-checks.component';
import { DlgSelectEmployeeComponent } from './dialogs/dlg-select-employee/dlg-select-employee.component';
import { Covid19ChecksWeeklyComponent } from './components/covid19-checks-weekly/covid19-checks-weekly.component';
import { Covid19ChecksDailyComponent } from './components/covid19-checks-daily/covid19-checks-daily.component';
import { ApprovedClaimsPivotComponent } from './components/approved-claims-pivot/approved-claims-pivot.component';
import { AdcMealsStatsComponent } from './components/adc-meals-stats/adc-meals-stats.component';
import { MealsFacilityStatsComponent } from './components/meals-facility-stats/meals-facility-stats.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [
    StatsComponent,
    AdcStatsComponent,
    VehicleAttendanceComponent,
    AdcServiceStatsComponent,
    Covid19ChecksComponent,
    DlgSelectEmployeeComponent,
    Covid19ChecksWeeklyComponent,
    Covid19ChecksDailyComponent,
    ApprovedClaimsPivotComponent,
    AdcMealsStatsComponent,
    MealsFacilityStatsComponent,
  ],
  exports: [AdcStatsComponent, AdcMealsStatsComponent, MealsFacilityStatsComponent, VehicleAttendanceComponent],
  entryComponents: [
    AdcStatsComponent,
    AdcMealsStatsComponent,
    MealsFacilityStatsComponent,
    VehicleAttendanceComponent,
    DlgSelectEmployeeComponent,
  ],
})
export class StatsModule {}
