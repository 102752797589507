<app-content-w-header header="AutoRepair Shop">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <dx-box direction="col" height="100%" width="100%">
      <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'"> </dxi-item>

      <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
        <dx-data-grid height="100%" [showBorders]="true" [showRowLines]="true" [dataSource]="shopDso">
          <!-- Settings -->

          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
          <dxo-search-panel [visible]="false"></dxo-search-panel>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-pager [visible]="false"></dxo-pager>

          <dxo-scrolling [mode]="'infinite'"></dxo-scrolling>
          <dxo-selection [mode]="'none'"></dxo-selection>

          <dxo-editing mode="cell" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

          <!-- Columns -->

          <dxi-column caption="Name" dataType="string" dataField="name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <dxi-column caption="Address" dataType="string" dataField="address"></dxi-column>

          <dxi-column caption="Phone" dataType="string" dataField="phone"></dxi-column>

          <dxi-column caption="Note" dataType="string" dataField="note"></dxi-column>

          <dxi-column caption="Main Contact" dataType="string" dataField="mainContact"></dxi-column>
        </dx-data-grid>
      </dxi-item>
    </dx-box>
  </div>
</app-content-w-header>

