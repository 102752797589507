import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxToolbarComponent } from 'devextreme-angular/ui/toolbar';
import { takeUntil } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackFilter, Vehicle } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { HelperService } from '../../services/helper.service';
import { VehicleDetailsTabsComponent } from '../vehicle-details-tabs/vehicle-details-tabs.component';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [HelperService],
})
export class VehicleDetailsComponent
  extends ABaseModelLoaderComponent<Vehicle>
  implements OnInit, OnChanges, OnDestroy
{
  toolbarItems: any[] = [];

  @ViewChild('toolbar', { static: true }) toolbar: DxToolbarComponent;
  @ViewChild('details', { static: true }) details: VehicleDetailsTabsComponent;

  constructor(protected logger: LoggerService, protected dss: DataSourceService, private helper: HelperService) {
    super(logger, dss);

    this.buildToolbarItems();

    this.afterLoaded.pipe(takeUntil(this.$onDestroy$)).subscribe((model: Vehicle) => this.buildToolbarItems(model));
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get filter(): LoopBackFilter {
    return {};
  }

  protected get observeModels(): any[] {
    return [this.ModelClass];
  }

  private buildToolbarItems(model: Vehicle = null) {
    if (model) {
      this.toolbarItems = [
        {
          location: 'before',
          locateInMenu: 'never',
          text: `#${model.internalId} - ${model.state}`,
        },
        {
          location: 'before',
          locateInMenu: 'never',
          html:
            // '<span style="font-size: .6em;">' +
            '<span>' +
            [
              model.type,
              `${model.year} ${model.make} ${model.model}`,
              `VIN ${oc(model)
                .vin('')
                .substr(oc(model).vin('').length - 6)}`,
            ].join(' - ') +
            '</span>',
        },
        //        {
        //          location: 'center',
        //          locateInMenu: 'never',
        //          text: this.helper.displayExpr(model),
        //        },
        //         {
        //           location: 'after',
        //           locateInMenu: 'never',
        //           text: model.state,
        //         },
      ];
    } else {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.errorMessage || 'Not selected',
        },
      ];
    }
  }

  repaint() {
    this.toolbar.instance.repaint();
    this.details.repaint();
  }
}
