import { HttpHeaders } from '@angular/common/http';
import { isEmpty } from 'lodash-es';
import { oc } from 'ts-optchain';
import { environment } from '../../../../environments/environment';
import { LoopBackConfig } from '../../sdk';
import { safeJsonParse } from './string.utils';

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[label as string] = true;

  return label as T;
}

export function getLuhnRemainder(val) {
  const CHARCODE_0 = '0'.charCodeAt(0);
  const MAPPING_EVEN = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

  let length = val.length;
  let accumulator = 0;
  let bit = 0;

  while (length-- > 0) {
    // tslint:disable-next-line:no-bitwise no-conditional-assignment
    accumulator += (bit ^= 1) ? val.charCodeAt(length) - CHARCODE_0 : MAPPING_EVEN[val.charCodeAt(length) - CHARCODE_0];
  }

  return accumulator % 10;
}

export function luhn(rawValue) {
  const value = '' + rawValue;

  return value + ((10 - getLuhnRemainder(value + '0')) % 10).toString();
}

export function luhnIsValid(value: string | number) {
  value = '' + value;
  return getLuhnRemainder(value) === 0;
}

export function colNumToA1(columnNumber: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // To store result (Excel column name)
  const charIdxArr: number[] = [];

  while (columnNumber > 0) {
    // Find remainder
    const rem = columnNumber % chars.length;

    // If remainder is 0, then a
    // 'Z' must be there in output
    if (rem === 0) {
      charIdxArr.push(chars.length - 1);
      columnNumber = Math.floor(columnNumber / chars.length) - 1;
    } else {
      // If remainder is non-zero
      charIdxArr.push(rem - 1);
      columnNumber = Math.floor(columnNumber / chars.length);
    }
  }

  // Reverse the string and print result
  return charIdxArr
    .reverse()
    .map(n => chars[n])
    .join('');
}

export function headersAllTenantsAppend(headers: HttpHeaders) {
  headers = headers.append('X-Current-Tenant', '-1');
  return headers;
}

export function hAll(headers: HttpHeaders) {
  return headersAllTenantsAppend(headers);
}

export function getAltOrCommonHost() {
  const altHost = oc(environment).apiAltUrl('');
  return isEmpty(altHost) ? LoopBackConfig.getPath() : altHost;
}

function getOptions() {
  const options = oc(environment).options('{}');
  return safeJsonParse<Partial<{ isAlpha; ambTrips }>>(options, {});
}

export function isAlpha() {
  return getOptions().isAlpha === true;
}

export function hasAmbTrips() {
  return getOptions().ambTrips === true;
}
