/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./facility-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../facility-grid/facility-grid.component.ngfactory";
import * as i3 from "../../services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Facility";
import * as i5 from "../../../../shared/sdk/services/custom/FacilityView";
import * as i6 from "../../../../shared/modules/my-common/services/config.service";
import * as i7 from "../facility-grid/facility-grid.component";
import * as i8 from "../../../../shared/sdk/services/custom/logger.service";
import * as i9 from "../../../../shared/modules/ui/services/ui.service";
import * as i10 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i11 from "../../../../shared/modules/my-common/services/state-store.service";
import * as i12 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i13 from "@angular/material/dialog";
import * as i14 from "./facility-list.component";
var styles_FacilityListComponent = [i0.styles];
var RenderType_FacilityListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FacilityListComponent, data: {} });
export { RenderType_FacilityListComponent as RenderType_FacilityListComponent };
export function View_FacilityListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-facility-grid", [], null, null, null, i2.View_FacilityGridComponent_0, i2.RenderType_FacilityGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.FacilityApi, i5.FacilityViewApi, i6.ConfigService]), i1.ɵprd(256, null, "Window", i7.ɵ0, []), i1.ɵdid(3, 245760, null, 0, i7.FacilityGridComponent, [i8.LoggerService, i6.ConfigService, i9.UiService, i10.DataSourceService, i11.StateStoreService, i3.HelperService, i12.GridHelperService, i13.MatDialog, "Window"], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_FacilityListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-facility-list", [], null, null, null, View_FacilityListComponent_0, RenderType_FacilityListComponent)), i1.ɵdid(1, 114688, null, 0, i14.FacilityListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FacilityListComponentNgFactory = i1.ɵccf("app-facility-list", i14.FacilityListComponent, View_FacilityListComponent_Host_0, {}, {}, []);
export { FacilityListComponentNgFactory as FacilityListComponentNgFactory };
