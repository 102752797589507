import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { UsersEditComponent } from './components/users-edit/users-edit.component';
import { UsersFormComponent } from './components/users-form/users-form.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersRolesComponent } from './components/users-roles/users-roles.component';
import { UsersComponent } from './containers/users/users.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { RolesFormComponent } from './components/roles-form/roles-form.component';
import { AssignEmployeeFormComponent } from './components/assign-employee-form/assign-employee-form.component';
import { AclComponent } from './components/acl/acl.component';
import { MfaFormComponent } from './components/mfa-form/mfa-form.component';
import { DlgFacilityComponent } from './dialogs/dlg-facility/dlg-facility.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [
    UsersComponent,
    UsersListComponent,
    UsersEditComponent,
    UsersFormComponent,
    UsersRolesComponent,
    PasswordFormComponent,
    RolesFormComponent,
    MfaFormComponent,
    AssignEmployeeFormComponent,
    AclComponent,
    DlgFacilityComponent,
  ],
  exports: [UsersComponent, DlgFacilityComponent],
  entryComponents: [UsersComponent, DlgFacilityComponent],
})
export class UsersModule {}
