import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { Observable, of, Subscription } from 'rxjs';
import {
  Consumer,
  ConsumerApi,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  InternalStorage,
  LoopBackAuth,
  MyUtilsApi,
  Person,
  Signature,
  SignatureApi,
  Vehicle,
  VehicleApi,
  VehicleGeotab,
  VehicleGeotabApi,
} from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { HelperService } from '../../services/helper.service';
import { HelperService as HelperManifestService } from '../../../trip-manifest/services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import moment, { duration } from 'moment-timezone';
import {
  dxStoreLoadHooks,
  gqlMongoDoc,
  gqlMongoLoad,
} from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { LoadOptions } from 'devextreme/data/load_options';
import CustomStore from 'devextreme/data/custom_store';
import flatMap from 'lodash-es/flatMap';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import DataSource from 'devextreme/data/data_source';
import dxSelectBox from 'devextreme/ui/select_box';
import * as geolib from 'geolib';
export const TIMEZONE = 'America/New_York';

const EVENTS = ['Pickups', 'Dropoffs'];
const SOURCES = ['Tablet GPS', 'Geotab GPS'];

@Component({
  selector: 'app-trips-audit-grid',
  templateUrl: './trips-audit-grid.component.html',
  styleUrls: ['./trips-audit-grid.component.scss'],
  providers: [HelperService, HelperManifestService],
})
export class TripsAuditGridComponent implements OnInit, OnDestroy {
  signatureDso$: Observable<DataSourceOptions> = of([]);
  facilityId = null;
  vehicleId = null;
  vehicles = [];
  vehiclesSelectBox: dxSelectBox;
  driverId = null;
  drivers = [];
  driversSelectBox: dxSelectBox;
  consumerId = null;
  consumers = [];
  consumersSelectBox: dxSelectBox;
  tripIdMap = {};
  signatures = [];

  markers = [];
  routes = [];
  events = [...EVENTS];
  selectedEvents = [...EVENTS];
  sources = [...SOURCES];

  markerBaseUrl = '/assets/images/';

  selectedDate = new Date();

  modifiedSubscription: Subscription;

  @Output() mySelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    public config: ConfigService,
    private dss: DataSourceService,
    public helper: HelperService,
    public manifestHelper: HelperManifestService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    private myUtilsAPI: MyUtilsApi,
    private internalStorage: InternalStorage,
    private facilityApi: FacilityApi,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {}

  async ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      if ([Vehicle.getModelName()].includes(modelName)) {
        if (this.grid) {
          this.grid.instance.refresh();
        }
      }
    });

    await this.buildVehicles();
    await this.buildDrivers();
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    // this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  async date__onSelectionChanged(e: any): Promise<void> {
    this.selectedDate = e.value;
    this.consumersSelectBox.reset();
    this.vehiclesSelectBox.reset();
    this.driversSelectBox.reset();
    await this.buildDso();
    this.buildConsumers();
    await this.buildVehicles();
    await this.buildDrivers();
  }

  async facility__onSelectionChanged(e: any): Promise<void> {
    this.facilityId = e.value;
    this.consumersSelectBox.reset();
    this.vehiclesSelectBox.reset();
    this.driversSelectBox.reset();
    await this.buildDso();
    this.buildConsumers();
    await this.buildVehicles();
    await this.buildDrivers();
  }

  async vehicle__onSelectionChanged(e: any): Promise<void> {
    this.vehicleId = e.value;
    if (e.event) {
      this.consumersSelectBox.reset();
      this.driversSelectBox.reset();
      await this.buildDso();
      this.buildConsumers();
      await this.buildDrivers();
      if (!this.vehicleId) await this.buildVehicles();
    }
  }

  vehicle__onInitialized(e: any): void {
    this.vehiclesSelectBox = e.component;
  }

  async driver__onSelectionChanged(e: any): Promise<void> {
    this.driverId = e.value;
    if (e.event) {
      this.consumersSelectBox.reset();
      this.vehiclesSelectBox.reset();
      await this.buildDso();
      this.buildConsumers();
      await this.buildVehicles();
      if (!this.driverId) await this.buildDrivers();
    }
  }

  driver__onInitialized(e: any): void {
    this.driversSelectBox = e.component;
  }

  async consumer__onSelectionChanged(e: any): Promise<void> {
    this.consumerId = e.value;
    if (e.event) await this.buildDso();
  }

  consumer__onInitialized(e: any): void {
    this.consumersSelectBox = e.component;
  }

  async grid_onSelectionChanged({ selectedRowsData: [signature] }: any) {
    if (!signature || !signature.meta.vehicle) return;
    const [positions, consumerHomeLocation, tenant] = await Promise.all([
      this.getMapData(signature),
      this.getConsumerHomeLocation(signature),
      this.dss.getApi(Facility).findById<Facility>(signature.tenantId).toPromise(),
    ]);

    let adcZonePoints = null;
    if (tenant.geotabZoneId) adcZonePoints = await this.getGeoTabZonePoints(tenant.geotabZoneId);

    this.prepareMapData({ tenant, positions, adcZonePoints, signature, consumerHomeLocation });
    this.mySelected.emit({ markers: this.markers, routes: this.routes });
  }

  grid_onToolbarPreparing(e) {
    const fso = this.dss.getStoreOptions(Facility, undefined, false);

    fso.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    e.toolbarOptions.items.unshift(
      {
        ...{ location: 'before', widget: 'dxDateBox' },
        options: {
          ...{ width: 95, value: this.selectedDate },
          onValueChanged: this.date__onSelectionChanged.bind(this),
        },
      },
      {
        ...{ location: 'before', widget: 'dxSelectBox' },
        options: {
          ...{ width: 160, placeholder: 'Facility', valueExpr: 'id', displayExpr: 'name', searchEnabled: true },
          ...{ showClearButton: true, dataSource: new CustomStore(fso) },
          onValueChanged: this.facility__onSelectionChanged.bind(this),
        },
      },
      {
        ...{ location: 'before', widget: 'dxSelectBox' },
        options: {
          ...{ width: 90, placeholder: 'Vehicle', valueExpr: 'id', displayExpr: 'internalId', searchEnabled: true },
          ...{ showClearButton: true },
          dataSource: new CustomStore({
            load: ({ searchValue: sv }) => this.vehicles.filter(v => !sv || ('' + v.internalId).startsWith(sv)),
          }),
          onValueChanged: this.vehicle__onSelectionChanged.bind(this),
          onInitialized: this.vehicle__onInitialized.bind(this),
        },
      },
      {
        ...{ location: 'before', widget: 'dxSelectBox' },
        options: {
          ...{ width: 140, placeholder: 'Driver', valueExpr: 'id', searchEnabled: true, showClearButton: true },
          displayExpr: v => v && `${v.firstname} ${v.lastname}`,
          dataSource: new CustomStore({
            load: ({ searchValue: sv }) =>
              this.drivers.filter(
                v => !sv || `${v.firstname} ${v.lastname}`.toLocaleLowerCase().includes(sv.toLocaleLowerCase()),
              ),
          }),
          onValueChanged: this.driver__onSelectionChanged.bind(this),
          onInitialized: this.driver__onInitialized.bind(this),
        },
      },
      {
        ...{ location: 'before', widget: 'dxSelectBox' },
        options: {
          ...{ width: 140, placeholder: 'Client', valueExpr: 'id', searchEnabled: true, showClearButton: true },
          displayExpr: v => v && `${v.firstname} ${v.lastname}`,
          dataSource: new CustomStore({
            load: ({ searchValue: sv }) =>
              this.consumers.filter(
                v => !sv || `${v.firstname} ${v.lastname}`.toLocaleLowerCase().includes(sv.toLocaleLowerCase()),
              ),
          }),
          onValueChanged: this.consumer__onSelectionChanged.bind(this),
          onInitialized: this.consumer__onInitialized.bind(this),
        },
      },
    );
  }

  private async buildDso() {
    this.signatures = [];
    if (this.facilityId || this.vehicleId || this.driverId) {
      this.signatures = await this.dss
        .getApi<SignatureApi>(Signature)
        .find<Signature>(
          {
            where: {
              ...(this.facilityId && { tenantId: this.facilityId }),
              ...(this.vehicleId && { vehicleId: this.vehicleId }),
              ...(this.driverId && { employeeId: this.driverId }),
              ...(this.consumerId && { consumerId: this.consumerId }),
              marker: { neq: 'DAY_ADC' },
              vdate: moment(this.selectedDate).format('YYYY-MM-DD'),
            },
            include: ['tenant', { employee: 'person' }, { consumer: { person: { contact: ['addresses'] } } }],
          },
          headersAllTenantsAppend,
        )
        .toPromise();
    }

    await this.buildTripIdMap();

    this.signatureDso$ = of(
      this.signatures.map(s => ({
        ...s,
        broker: (s.meta.tripId && this.tripIdMap[s.meta.tripId] && this.tripIdMap[s.meta.tripId]._broker) || '',
      })),
    );
  }

  private async buildVehicles() {
    if (this.signatures.length) {
      const vehiclesMap = this.signatures.reduce(
        (p, { meta: { vehicle: v } }) => ({ ...p, ...(v && { [v.id]: v }) }),
        {},
      );
      this.vehicles = Object.values(vehiclesMap).sort(
        (a: Vehicle, b: Vehicle) => (a.internalId < b.internalId && -1) || (a.internalId > b.internalId && 1) || 0,
      );
    } else {
      this.vehicles = await this.dss
        .getApi<VehicleApi>(Vehicle)
        .find<Vehicle>({ order: ['internalId'] }, headersAllTenantsAppend)
        .toPromise();
    }
    this.vehiclesSelectBox.getDataSource().reload();
  }

  private async buildDrivers() {
    if (this.signatures.length) {
      const driversMap = this.signatures.reduce(
        (p, { employee: e }) => ({ ...p, ...(e && { [e.id]: { ...e.person, id: e.id } }) }),
        {},
      );
      this.drivers = Object.values(driversMap);
    } else {
      this.drivers = (
        await this.dss
          .getApi<EmployeeApi>(Employee)
          .find<Employee>(
            { where: { employeePositionId: { inq: [39, 40] } }, include: ['person'] },
            headersAllTenantsAppend,
          )
          .toPromise()
      ).map(({ id, person: p }) => ({ ...p, id }));
    }
    this.drivers = this.drivers.sort(
      (a: Person, b: Person) =>
        (a.firstname < b.firstname && -1) ||
        (a.firstname > b.firstname && 1) ||
        (a.lastname < b.lastname && -1) ||
        (a.lastname > b.lastname && 1) ||
        0,
    );
    this.driversSelectBox.getDataSource().reload();
  }

  private buildConsumers() {
    const consumersMap = this.signatures.reduce(
      (p, { consumer: c }) => ({ ...p, ...(c && { [c.id]: { ...c.person, id: c.id } }) }),
      {},
    );
    this.consumers = Object.values(consumersMap).sort(
      (a: Person, b: Person) =>
        (a.firstname < b.firstname && -1) ||
        (a.firstname > b.firstname && 1) ||
        (a.lastname < b.lastname && -1) ||
        (a.lastname > b.lastname && 1) ||
        0,
    );
    this.consumersSelectBox.getDataSource().reload();
  }

  private async buildTripIdMap() {
    if (!this.signatures.length) return;
    const aggregate = [
      {
        $match: {
          _tripId: { $in: this.signatures.flatMap(s => (s.meta.tripId && [s.meta.tripId]) || []) },
        },
      },
    ];

    const res = await gqlMongoLoad(this.dss, 'ExportsDataCache', {}, aggregate).toPromise();
    this.tripIdMap = res.reduce((p, { _tripId, _broker }) => ({ ...p, [_tripId]: { _broker } }), {});
  }

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  async getMapData(signature) {
    const start = { $dateFromString: { dateString: moment(this.selectedDate).startOf('day').toISOString() } };
    const end = { $dateFromString: { dateString: moment(this.selectedDate).endOf('day').toISOString() } };
    const stages = [
      { $match: { internalId: signature.meta.vehicle.internalId, month: +moment(this.selectedDate).format('YYYYMM') } },
      {
        $project: {
          ...{ deviceId: 1, month: 1, internalId: 1 },
          positions: {
            $filter: {
              ...{ input: '$positions', as: 'position' },
              cond: { $and: [{ $gte: ['$$position.dateTime', start] }, { $lte: ['$$position.dateTime', end] }] },
            },
          },
        },
      },
    ];
    const data = await this.dss.getApi<VehicleGeotabApi>(VehicleGeotab).aggregate(stages).toPromise();
    return (data[0] || {}).positions || [];
  }

  async getConsumerHomeLocation({ id, marker, pickupTime }) {
    if (!pickupTime) return null;
    const { geocodeFrom, geocodeTo } = await this.dss
      .getApi<SignatureApi>(Signature)
      .getSignatureAddresses(id, headersAllTenantsAppend)
      .toPromise();
    return marker === 'TO_ADC' ? geocodeFrom : geocodeTo;
  }

  prepareMapData({ tenant, positions, adcZonePoints, signature, consumerHomeLocation }) {
    const { internalId } = signature.meta.vehicle;
    console.log('prepareMapData');
    const { markers: signMarkers, ePerson } = this.getSignatureMarkers(positions, signature);
    let markers = [];
    if (positions.length > 1) {
      markers = this.getStartFinishMarkers(positions, ePerson, internalId);
    }
    if (adcZonePoints) {
      const { latitude, longitude } = geolib.getCenter(adcZonePoints) || adcZonePoints[0];
      markers.push({
        iconSrc: `${this.markerBaseUrl}marker-facility.png`,
        location: `${latitude}, ${longitude}`,
        tooltip: { text: `${tenant.name}`, isShown: false },
      });
    }
    if (consumerHomeLocation) {
      markers.push(this.getConsumerHomeMarker(consumerHomeLocation, signature));
    }

    let routes = [];
    let loopRoutes = [];
    let loopNumber = 0;
    let loopMarkers = [];
    let locations = [];
    let isPrevInAdcZone = false;
    let { dateTime: firstDateTimeOfLoop } = positions[0];
    const tc = signature.pickupTime && moment.tz(`${signature.vdate} ${signature.pickupTime}`, TIMEZONE).utc().format();

    positions.forEach(({ latitude, longitude, dateTime, duration, speed }, i) => {
      const position = [latitude, longitude];
      locations.push(position);
      if (speed === 0 && duration > 90) {
        loopMarkers.push(this.getStopMarker({ latitude, longitude, dateTime, duration, ePerson, internalId }));
      }
      const isInAdcZone = adcZonePoints && geolib.isPointInPolygon({ latitude, longitude }, adcZonePoints);
      if ((isPrevInAdcZone && !isInAdcZone) || locations.length > 24 || i === positions.length - 1) {
        loopRoutes.push(this.getRoute(locations, loopNumber));
        locations = [position];
        if ((isPrevInAdcZone && !isInAdcZone) || i === positions.length - 1) {
          if (!tc || (firstDateTimeOfLoop < tc && tc < dateTime)) {
            routes = [...routes, ...loopRoutes];
            markers = [...markers, ...loopMarkers];
          }
          loopRoutes = [];
          loopMarkers = [];
          firstDateTimeOfLoop = dateTime;
          loopNumber++;
        }
      }
      isPrevInAdcZone = isInAdcZone;
    });

    this.routes = [...routes];
    this.markers = [...markers, ...signMarkers];
  }

  private getRoute(locations, loopNumber) {
    const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
    return {
      weight: 4,
      color: colors[loopNumber % colors.length],
      opacity: 0.8,
      mode: '',
      locations: [...locations],
    };
  }

  private getSignatureMarkers(positions, signature): any {
    const {
      marker,
      meta: {
        vehicle: { internalId },
      },
    } = signature;

    let ePerson = <Person>{};
    let markers = [];

    (signature.consumer
      ? [signature]
      : this.signatures.filter(
          s => s.meta && s.meta.vehicle && s.meta.vehicle.internalId === internalId && s.marker === marker,
        )
    ).forEach(s => {
      ePerson = (s.employee && s.employee.person) || ePerson;
      const cPerson = (s.consumer && s.consumer.person) || {};
      const { firstname, lastname } = cPerson;
      if (
        s.meta.pickUpDeviceLocation &&
        this.selectedEvents.includes('Pickups') &&
        s.pickupTime //&&
        // this.selectedClients.includes(`${firstname} ${lastname}`)
      ) {
        // if (this.selectedSources.includes('Tablet GPS')) {
        // markers.push(this.getSignatureMarker(true, s.meta.pickUpDeviceLocation, ePerson, cPerson, internalId));
        // }
        // if (this.selectedSources.includes('Geotab GPS')) {
        const tc = moment.tz(`${s.vdate} ${s.pickupTime}`, TIMEZONE).utc().format();
        const p = this.findStopPosition(positions, tc);
        if (p) markers.push(this.getSignatureMarkerTmp(true, p, ePerson, cPerson, internalId, tc));
        // }
      }

      if (
        s.meta.dropOffDeviceLocation &&
        this.selectedEvents.includes('Dropoffs') &&
        s.dropoffTime //&&
        // this.selectedClients.includes(`${firstname} ${lastname}`)
      ) {
        // if (this.selectedSources.includes('Tablet GPS')) {
        // markers.push(this.getSignatureMarker(false, s.meta.dropOffDeviceLocation, ePerson, cPerson, internalId));
        // }
        // if (this.selectedSources.includes('Geotab GPS')) {
        const tc = moment.tz(`${s.vdate} ${s.dropoffTime}`, TIMEZONE).utc().format();
        const p = this.findStopPosition(positions, tc);
        if (p) markers.push(this.getSignatureMarkerTmp(false, p, ePerson, cPerson, internalId, tc));
        // }
      }
    });
    return { markers, ePerson };
  }

  private findStopPosition(positions, time) {
    return (
      time &&
      positions.find(
        ({ dateTime, duration, speed }) => !speed && moment(dateTime).add(duration + 60, 'seconds') > moment(time),
      )
    );
  }

  private getConsumerHomeMarker({ formatted_address, geometry }, { consumer: { person } }): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-home.png`,
      location: `${geometry.location.lat}, ${geometry.location.lng}`,
      tooltip: {
        text: `<b>${person.firstname} ${person.lastname}</b>` + `<br/>${formatted_address.split(',').join('<br/>')}`,
        isShown: false,
      },
    };
  }

  private getSignatureMarker(isPickUp, { lat, lng, isGeoTab }, tc, ePerson, cPerson, internalId): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}.png`,
      location: `${lat}, ${lng}`,
      tooltip: {
        text:
          `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
          `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
          `<br/>${(isGeoTab && 'GeoTab') || ''}`,
        isShown: false,
      },
    };
  }

  private getSignatureMarkerTmp(
    isPickUp,
    { latitude, longitude, dateTime, duration, speed },
    ePerson,
    cPerson,
    internalId,
    tc,
  ): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}-tmp.png`,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} <b>${cPerson.firstname} ${cPerson.lastname}</b>` +
          `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
          `<br/><em>Arrival Time:</em> ${moment((tc > dateTime && dateTime) || tc).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
          `<br/><em>Speed:</em> ${speed}` +
          `<br/>GeoTab`,
        isShown: false,
      },
    };
  }

  private getStopMarker({ latitude, longitude, dateTime, duration, ePerson, internalId }): any {
    return {
      iconSrc: this.markerBaseUrl + `marker-stop${duration > 60 * 30 ? '-red' : ''}.png`,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}`,
        isShown: false,
      },
    };
  }
  private getStartFinishMarkers(positions: any, ePerson: Person, internalId): any[] {
    const isToday = moment(this.selectedDate).isSame(new Date(), 'day');
    this.selectedDate;
    return [
      { ...positions[0], iconSrc: this.markerBaseUrl + 'marker-start.png', isStart: true },
      {
        ...positions[positions.length - 1],
        iconSrc: this.markerBaseUrl + `marker-${isToday ? 'current' : 'finish'}.png`,
      },
    ].map(({ dateTime, speed, currentStateDuration, isDriving, latitude, longitude, iconSrc, isStart }) => ({
      iconSrc,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/><em>${isStart ? 'Start' : isToday ? '' : 'Finish'} Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>Driving:</em> ${(isDriving && 'yes') || 'not'}` +
          `<br/><em>Speed:</em> ${speed}` +
          `<br/><em>State Duration:</em> ${moment.duration(currentStateDuration, 'seconds').humanize()}`,
        isShown: false,
      },
    }));
  }

  async getGeoTabZonePoints(id: string, attempt = 0): Promise<{ [key: string]: any }> {
    try {
      const geoTabAuth = this.internalStorage.get('geoTabAuth') || (await this.myUtilsAPI.geoTabAuth().toPromise());
      this.internalStorage.set('geoTabAuth', geoTabAuth);

      const api = new GeotabApi(geoTabAuth);
      const [[zone]] = await api.multiCall([['Get', { typeName: 'Zone', search: { id } }]]);
      return (zone && zone.points.map(({ x, y }) => ({ latitude: y, longitude: x }))) || null;
    } catch (err) {
      const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
      if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.') throw msg;
      if (attempt) return {};
      this.internalStorage.remove('geoTabAuth');
      return await this.getGeoTabZonePoints(id, 1);
    }
  }
}
