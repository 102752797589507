import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LoggerService, LoopBackAuth } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { Covid19ChecksDailyComponent } from '../covid19-checks-daily/covid19-checks-daily.component';
import { Covid19ChecksWeeklyComponent } from '../covid19-checks-weekly/covid19-checks-weekly.component';
import moment from 'moment/moment';

@Component({
  selector: 'app-covid19-checks',
  templateUrl: './covid19-checks.component.html',
  styleUrls: ['./covid19-checks.component.scss'],
})
export class Covid19ChecksComponent extends ABaseComponent implements OnInit {
  weekDs: any[] = [];

  selectedDateValue1?: Date = new Date();
  selectedDateValue2?: Date = moment().startOf('week').toDate();

  // selectedTabIndex = this.config.config.adcCovidReportingMode2 === 'full_weekly' ? 1 : 0;

  // tabs: any[] = [
  //   {
  //     title: 'Daily',
  //     template: 'check1'
  //   },
  //   {
  //     title: 'Weekly',
  //     template: 'check2'
  //   },
  // ];

  @ViewChild(Covid19ChecksDailyComponent, { static: false }) grid1: Covid19ChecksDailyComponent;
  @ViewChild(Covid19ChecksWeeklyComponent, { static: false }) grid2: Covid19ChecksWeeklyComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
    public consumerHelper: ConsumerHelperService,
    public empHelper: EmployeeHelperService,
  ) {
    super(logger);
  }

  ngOnInit(): void {
    super.ngOnInit();

    let current = moment();
    for (let i = 0; i < 50; i++) {
      const value = current.startOf('week').toDate();
      const title = `${current.format('w')} (${current.startOf('week').format('M/D/YY')} - ${current
        .endOf('week')
        .format('M/D/YY')})`;
      this.weekDs.push({ v: value, t: title });
      current = current.subtract(1, 'week');
    }
  }

  filter1() {
    this.grid1.filter();
  }

  filter2() {
    this.grid2.filter();
  }
}
