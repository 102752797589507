import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//
import { Document, Employee, LoggerService } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';
export class EmployeeDetailsTabEmployeeComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, docHelper, dialog) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.docHelper = docHelper;
        this.dialog = dialog;
        this.caption = 'Employee Info';
    }
    get ModelClass() {
        return Employee;
    }
    get FormComponent() {
        return EmployeeFormComponent;
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.afterModelLoadedAsync.call(this, model);
            this.noticeDSO = this.buildNoticeDSO(model);
        });
    }
    buildNoticeDSO(model) {
        const filter = model
            ? [
                ['objectId', '=', model.id],
                ['objectType', '=', Employee.getModelName()],
                ['documentType_name', '=', 'NOTICE'],
            ]
            : undefined;
        return {
            store: this.dss.getStore(Document, null, false),
            filter,
        };
    }
    onNoticesToolbarPreparing(e) {
        // e.toolbarOptions.items.unshift({
        //   location: 'after',
        //   widget: 'dxButton',
        //   options: {
        //     icon: 'add',
        //     onClick: () => notify('Notice Add button pressed', 'info', 3000),
        //   }
        // });
    }
}
