<app-content-w-header header="COVID-19 Report">
  <mat-card class="toolbar subheader">
    <div *ngIf="this.config.config.adcCovidReportingMode2 !== 'full_weekly'" class="dx-fieldset" style="display: flex">
      <dx-date-box width="100px" [(value)]="selectedDateValue1"></dx-date-box>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter1()"></dx-button>
      </div>
    </div>

    <div *ngIf="this.config.config.adcCovidReportingMode2 === 'full_weekly'" class="dx-fieldset" style="display: flex">
      <!--      <dx-date-box-->
      <!--                   width="100px"-->
      <!--                   [(value)]="selectedDateValue2"-->
      <!--      ></dx-date-box>-->

      <dx-select-box [dataSource]="weekDs" valueExpr="v" displayExpr="t" [(value)]="selectedDateValue2"></dx-select-box>

      <div style="display: inline-block">
        <dx-button style="text-align: left" type="default" text="Filter" (onClick)="filter2()"></dx-button>
      </div>
    </div>
  </mat-card>

  <div class="content" width="100%" height="100%">
    <app-covid19-checks-daily
      *ngIf="this.config.config.adcCovidReportingMode2 !== 'full_weekly'"
      [selectedDateValue]="selectedDateValue1"></app-covid19-checks-daily>

    <app-covid19-checks-weekly
      *ngIf="this.config.config.adcCovidReportingMode2 === 'full_weekly'"
      [selectedDateValue]="selectedDateValue2"></app-covid19-checks-weekly>
  </div>

  <!--  <dx-tab-panel class="content"-->
  <!--                width="100%"-->
  <!--                height="100%"-->
  <!--                [dataSource]="tabs"-->
  <!--                [(selectedIndex)]="selectedTabIndex"-->
  <!--  >-->

  <!--    <div *dxTemplate="let data of 'check1'">-->
  <!--      <app-covid19-checks-daily-->
  <!--        [selectedDateValue]="selectedDateValue1"-->
  <!--      ></app-covid19-checks-daily>-->
  <!--    </div>-->

  <!--    <div *dxTemplate="let data of 'check2'">-->
  <!--      <app-covid19-checks-weekly-->
  <!--        [selectedDateValue]="selectedDateValue2"-->
  <!--      ></app-covid19-checks-weekly>-->
  <!--    </div>-->

  <!--  </dx-tab-panel>-->
</app-content-w-header>
