import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import sortBy from 'lodash-es/sortBy';
import moment, { utc } from 'moment';
import { Observable, of } from 'rxjs';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-trips-log',
  templateUrl: './trips-log.component.html',
  styleUrls: ['./trips-log.component.scss'],
})
export class TripsLogComponent extends ABaseComponent implements OnInit, AfterViewInit {
  // isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7776d037-26fa-4e37-ab41-34076ee8b4r2',
    };

    // this.isSU$ = this.common.store.pipe(
    //   select(getUser),
    //   switchMap(u => (!!u ? this.dss.getApi<MyUserApi>(MyUser).getRoles(u.id) : of([]))),
    //   map((roles: string[]) => roles.includes('SU')),
    // );
  }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
  }

  grid_onInitialized(e) {
    // this.gridHelper.handle(e.component, {
    //   flatToTreeObject: false,
    //   copyIdsOnSaving: false,
    //   selectRowOnEdit: false,
    //   notifyErrors: true,
    // });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      name: 'refresh',
      locateInMenu: 'auto',
      location: 'after',
      widget: 'dxButton',
      showText: 'inMenu',
      options: {
        icon: 'refresh',
        text: 'Refresh',
        hint: 'Refresh',
        onClick: () => e.component.refresh(),
      },
    });
  }

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const col = 'ManifestTripsLog';
    const aggregate = [{ $sort: { date: -1 } }];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    return {
      store,
      map(itm: any) {
        return itm;
      },
    } as DataSourceOptions;
  }
}

