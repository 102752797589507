import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SelectTenantDialogComponent } from '../../../modules/sign/components/select-tenant-dialog/select-tenant-dialog.component';
import { ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { ABaseComponent } from '../../../shared/modules/ui/components/abstract/a-base.component';
import { AlertApi, Employee, Facility, FacilityApi, LoggerService, MyUser, MyUserApi } from '../../../shared/sdk';
import { AppState } from '../../../store';
import { Logout } from '../../../store/actions/sign';
import { getCurrentTenant, getUser, isSignedIn } from '../../../store/reducers/sign';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends ABaseComponent implements OnInit {
  env = environment;

  @Input() title: string;

  @Input() menuToggleEnabled = false;

  @Output() menuToggle = new EventEmitter<boolean>();

  isSignedIn$: Observable<boolean>;
  signedUser$: Observable<MyUser>;
  signedEmployee$: Observable<Employee>;
  currentTenantId$: Observable<number>;
  currentTenant$: Observable<Facility>;
  isBiller$: Observable<boolean>;

  lastBillingDate$: Observable<string>;

  alertCount = 0;

  constructor(
    protected logger: LoggerService,
    private alertApi: AlertApi,
    private userApi: MyUserApi,
    private facilityApi: FacilityApi,
    private store: Store<AppState>,
    public config: ConfigService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.isSignedIn$ = this.store.select(isSignedIn);
    this.signedUser$ = this.store.select(getUser);
    this.signedEmployee$ = this.signedUser$.pipe(
      switchMap(u => this.userApi.findById<MyUser>(u.id, { include: [{ employee: 'person' }] })),
      map(u => u.employee),
    );
    this.currentTenantId$ = this.store.select(getCurrentTenant);
    this.currentTenant$ = this.currentTenantId$.pipe(
      switchMap(id => (id && id !== -1 ? this.facilityApi.findById<Facility>(id) : of(null))),
      catchError(err => of(null)),
    );
    this.isBiller$ = this.config.hasAnyRole$(['SU', 'BILLER']);

    this.lastBillingDate$ = timer(0, 60_000).pipe(map(() => `${moment().subtract(130, 'days').format('YYYY-MM-DD')}`));
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  selectTenant_onClick = () => {
    this.dialog.open(SelectTenantDialogComponent, {
      panelClass: 'tenant-dialog',
      backdropClass: 'tenant-dialog',
      width: '450px',
      hasBackdrop: true,
      data: {},
    });
  };

  login_onClick = () => {
    this.router.navigate(['/sign']);
  };

  loginOther_onClick = () => {
    this.router.navigate(['/sign'], { queryParams: { forceSign: '' } });
  };

  logout_onClick = () => {
    this.store.dispatch(new Logout());
  };

  ngOnInit() {
    super.ngOnInit();

    // TODO: enable toolbar alerts
    // this.subscriptions.push(
    //   interval(2000 /* ms */)
    //     .pipe(
    //       mergeMap(() =>
    //         this.alertApi.count({'hush': false, 'fixed': false})
    //           .pipe(
    //             tap(({count}) => this.alertCount = count)
    //           )
    //       )
    //     ).subscribe()
    // );
  }
}
