import {
  Employee,
  Person
} from '../index';

declare var Object: any;
export interface EmployeeEmPersonInterface {
  "relation"?: string;
  "dtime"?: Date;
  "id"?: number;
  "employeeId"?: number;
  "personId"?: number;
  employee?: Employee;
  person?: Person;
}

export class EmployeeEmPerson implements EmployeeEmPersonInterface {
  "relation": string;
  "dtime": Date;
  "id": number;
  "employeeId": number;
  "personId": number;
  employee: Employee;
  person: Person;
  constructor(data?: EmployeeEmPersonInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `EmployeeEmPerson`.
   */
  public static getModelName() {
    return "EmployeeEmPerson";
  }

  /**
  * This method creates an instance of EmployeeEmPerson for dynamic purposes.
  **/
  public static factory(data: EmployeeEmPersonInterface): EmployeeEmPerson{
    return new EmployeeEmPerson(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmployeeEmPerson',
      plural: 'EmployeeEmPeople',
      path: 'EmployeeEmPeople',
      idName: 'id',
      properties: {
        "relation": {
          name: 'relation',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "employeeId": {
          name: 'employeeId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
      },
      relations: {
        employee: {
          name: 'employee',
          type: 'Employee',
          model: 'Employee',
          relationType: 'belongsTo',
                  keyFrom: 'employeeId',
          keyTo: 'id'
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
                  keyFrom: 'personId',
          keyTo: 'id'
        },
      }
    }
  }
}
