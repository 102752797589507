/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-w-drop-down.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/button";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../../../node_modules/devextreme-angular/ui/context-menu/devextreme-angular-ui-context-menu.ngfactory";
import * as i7 from "devextreme-angular/ui/context-menu";
import * as i8 from "./button-w-drop-down.component";
import * as i9 from "../../services/ui.service";
var styles_ButtonWDropDownComponent = [i0.styles];
var RenderType_ButtonWDropDownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonWDropDownComponent, data: {} });
export { RenderType_ButtonWDropDownComponent as RenderType_ButtonWDropDownComponent };
export function View_ButtonWDropDownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { btn: 0 }), i1.ɵqud(402653184, 2, { menu: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "dx-button", [], [[8, "id", 0]], null, null, i2.View_DxButtonComponent_0, i2.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(6, 7323648, [[1, 4]], 0, i4.DxButtonComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { disabled: [0, "disabled"], hint: [1, "hint"], icon: [2, "icon"], text: [3, "text"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "dx-context-menu", [], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.ctx_onItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DxContextMenuComponent_0, i6.RenderType_DxContextMenuComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.IterableDifferHelper, i3.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(12, 7323648, [[2, 4]], 1, i7.DxContextMenuComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.IterableDifferHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { items: [0, "items"], position: [1, "position"], showEvent: [2, "showEvent"], target: [3, "target"] }, { onItemClick: "onItemClick" }), i1.ɵqud(603979776, 3, { itemsChildren: 1 }), i1.ɵpod(14, { at: 0, my: 1, offset: 2, collision: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.processing; var currVal_2 = _co.hint; var currVal_3 = (!_co.processing ? _co.icon : "fas fa-circle-notch fa-spin"); var currVal_4 = _co.text; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.menuItems; var currVal_6 = _ck(_v, 14, 0, "left bottom", "left top", "0 3", "fit"); var currVal_7 = "dxclick"; var currVal_8 = ("#" + _co.target); _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.target; _ck(_v, 2, 0, currVal_0); }); }
export function View_ButtonWDropDownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-w-drop-down", [], null, null, null, View_ButtonWDropDownComponent_0, RenderType_ButtonWDropDownComponent)), i1.ɵdid(1, 114688, null, 0, i8.ButtonWDropDownComponent, [i9.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonWDropDownComponentNgFactory = i1.ɵccf("app-button-w-drop-down", i8.ButtonWDropDownComponent, View_ButtonWDropDownComponent_Host_0, { menuItems: "menuItems", text: "text", hint: "hint", icon: "icon", processing: "processing" }, { action: "action" }, []);
export { ButtonWDropDownComponentNgFactory as ButtonWDropDownComponentNgFactory };
