<dx-tab-panel
  [dataSource]="tabs$ | async"
  [selectedIndex]="0"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="false"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'profile'">
    <dx-scroll-view>
      <app-employee-details-tab-profile [modelId]="modelId"></app-employee-details-tab-profile>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'notes'">
    <app-employee-details-tab-notes [modelId]="modelId"></app-employee-details-tab-notes>
  </div>

  <div *dxTemplate="let data of 'employee'">
    <dx-scroll-view>
      <app-employee-details-tab-employee [modelId]="modelId"></app-employee-details-tab-employee>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'payroll'">
    <dx-scroll-view>
      <app-employee-details-tab-payroll [modelId]="modelId"></app-employee-details-tab-payroll>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'events'">
    <dx-scroll-view>
      <app-employee-details-tab-events [modelId]="modelId"></app-employee-details-tab-events>
    </dx-scroll-view>
  </div>

  <div *dxTemplate="let data of 'document'">
    <dx-scroll-view>
      <app-employee-details-tab-document [modelId]="modelId"></app-employee-details-tab-document>
    </dx-scroll-view>
  </div>
</dx-tab-panel>
