import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import { ABaseFormComponent } from '../../../shared/modules/ui/components/abstract/a-base-form.component';
export class ABaseDocumentFormComponentWithUploader extends ABaseFormComponent {
    constructor(logger, fb, uploadHelper, dss, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.uploadHelper = uploadHelper;
        this.dss = dss;
        this.helper = helper;
        this.validationCallback = this.uploadHelper.validationCallback;
    }
    ngAfterViewInit() {
        this.uploadHelper.handle(this.uploader.instance, {
            folder: 'documents',
            onUploaded: e => {
                this.form.markAsDirty();
            },
        }, this.uploadValidator && this.uploadValidator.instance);
    }
    processFormValueAsync(data) {
        const _super = Object.create(null, {
            processFormValueAsync: { get: () => super.processFormValueAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            data = yield _super.processFormValueAsync.call(this, data);
            data.files = this.uploader.value
                .filter((uf) => !!uf.uploaded)
                .map((uf) => uf.uploaded);
            return data;
        });
    }
}
