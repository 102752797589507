import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { JobsComponent } from './components/jobs/jobs.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [JobsComponent],
  exports: [JobsComponent],
  entryComponents: [JobsComponent],
})
export class SuModule {}
