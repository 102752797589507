import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { PersonFormComponent } from './components/person-form/person-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MyCommonModule, UiModule],
  declarations: [PersonFormComponent],
  exports: [],
  entryComponents: [PersonFormComponent],
})
export class PersonModule {}
