import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { MyUserApi } from '../../../../shared/sdk/services/custom';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent implements OnInit {
  form: FormGroup;
  error = null;

  @Input() userId: number | string;

  constructor(protected fb: FormBuilder, protected userApi: MyUserApi) {
    this.buildForm();
  }

  ngOnInit() {}

  protected buildForm(): void {
    this.form = this.fb.group({
      password: ['', Validators.required],
    });
  }

  submitForm(): Observable<any> {
    this.error = null;

    if (this.form.valid) {
      return this.userApi.setUserPassword(this.userId, this.form.value.password).pipe();
    } else {
      return throwError(new Error('Invalid form value'));
    }
  }
}
