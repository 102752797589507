<app-details-tab [loading]="loading" [toolbarItems]="toolbarItems" [model]="model" [errorMessage]="errorMessage">
  <ng-container *ngIf="model">
    <div class="dx-fieldset">
      <div class="dx-field" *ngIf="model?.mci">
        <div class="dx-field-label">QR Code</div>
        <div class="dx-field-value-static">
          <img id="qrcode" [src]="model?.mci | qrCode" />
          <dx-button
            icon="fas fa-print"
            matTooltip="print qr code"
            (onClick)="helper.printQrCard(model?.id).toPromise()"></dx-button>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Signature</div>
        <div class="dx-field-value-static">
          <a mat-button (click)="changeSign_onClick($event)" *ngIf="model?.signImgFileId; else noSignatureBlock"
            ><img
              style="max-height: 30px; max-width: 150px;"
              [src]="model?.signImgFileId | signatureLink: 'FILE':'resize--30/trim' | async | sanitizeUrl"
          /></a>

          <ng-template #noSignatureBlock>
            <dx-button
              icon="fas fa-signature"
              matTooltip="Replace Signature"
              (onClick)="changeSign_onClick($event)"></dx-button>
          </ng-template>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Name</div>
        <div class="dx-field-value-static">
          {{ model | fullName }}
          <ng-container *ngIf="model?.person?.nickname"><em>aka</em> {{ model?.person?.nickname }}</ng-container>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">DOB</div>
        <div class="dx-field-value-static">
          <time>{{ model?.person?.dob | date: 'mediumDate' }}</time>
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.sex">
        <div class="dx-field-label">Sex</div>
        <div class="dx-field-value-static">
          {{ model?.person?.sex }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.data?.nativeLang">
        <div class="dx-field-label">Native Language</div>
        <div class="dx-field-value-static">
          {{ model?.person?.data?.nativeLang }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.data?.nativeLangName">
        <div class="dx-field-label">Native Language Name</div>
        <div class="dx-field-value-static">
          {{ model?.person?.data?.nativeLangName }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MCI</div>
        <div class="dx-field-value-static">
          {{ model?.mci }}
        </div>
      </div>

      <div class="dx-field" *ngIf="modelWithDetails$.value?._authClient">
        <div class="dx-field-label">Auth Member ID</div>
        <div class="dx-field-value-static">
          {{ modelWithDetails$.value?._authClient?._clientId }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Internal ID</div>
        <div class="dx-field-value-static">
          {{ model?.internalID }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Program</div>
        <div class="dx-field-value-static">
          {{ model?.program?.name }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Client Type</div>
        <div class="dx-field-value-static">
          {{ model?.repetitiveMode }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Week Days</div>
        <div class="dx-field-value-static">
          {{ model?.weekDays | weekDays }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">MCO</div>
        <div class="dx-field-value-static">
          {{ model?.activeMco }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Broker</div>
        <div class="dx-field-value-static">
          {{ model?.activeBroker }}
        </div>
      </div>

      <div class="dx-field" *ngIf="config.get('consumerMoreFields', []).includes('facilityID')">
        <div class="dx-field-label">Facility ID</div>
        <div class="dx-field-value-static">
          {{ model?.facilityID }}
        </div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.phones,
          type: 'phone',
          types: 'phones',
          header: 'Phone'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.emails,
          type: 'email',
          types: 'emails',
          header: 'Email'
        }
      "></ng-container>

    <ng-container
      *ngTemplateOutlet="
        fieldSet;
        context: {
          $implicit: model?.person?.contact?.addresses,
          type: 'address',
          types: 'addresses',
          header: 'Address'
        }
      "></ng-container>

    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Coordination Agency</div>
        <div class="dx-field-value-static">
          {{ model?.c10nAgency }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Case Worker Name</div>
        <div class="dx-field-value-static">
          {{ model?.cwName }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Case Worker Phone</div>
        <div class="dx-field-value-static">
          {{ model?.cwPhone }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Case Worker Fax</div>
        <div class="dx-field-value-static">
          {{ model?.cwFax }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Case Worker Email</div>
        <div class="dx-field-value-static">
          {{ model?.cwEmail }}
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Case Worker Note</div>
        <div class="dx-field-value-static">
          {{ model?.cwNote }}
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Notes</div>

      <div class="dx-field" *ngIf="model?.brokerNotes">
        <div class="dx-field-label">Broker Notes</div>
        <div class="dx-field-value-static">
          <pre>{{ model?.brokerNotes }}</pre>
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.relatedNotes?.length">
        <div class="_dx-field-label">Attendance Notes</div>
        <div class="_dx-field-value-static">
          <!--          {{ model?.notes }}-->
          <app-consumer-notes [model]="model"></app-consumer-notes>
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.notes">
        <div class="dx-field-label">Person Notes</div>
        <div class="dx-field-value-static">
          {{ model?.person?.notes }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.person?.contact?.notes">
        <div class="dx-field-label">Contact Notes</div>
        <div class="dx-field-value-static">
          {{ model?.person?.contact?.notes }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.specialInstrs">
        <div class="dx-field-label">Special Instructions</div>
        <div class="dx-field-value-static">
          {{ model?.specialInstrs }}
        </div>
      </div>

      <div class="dx-field" *ngIf="model?.transpInstrs">
        <div class="dx-field-label">Transportation Instructions</div>
        <div class="dx-field-value-static">
          <span class="badge" *ngFor="let item of model?.transpInstrs | split">{{ item }}</span>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div class="dx-fieldset-header">Related Contacts</div>

      <ng-container *ngFor="let emr of model?.emRelations; let idx = index">
        <div class="dx-field">
          <div class="dx-field-label">{{ emr?.relation }}</div>
          <div class="dx-field-value-static">
            <abbr *ngIf="emr?.person?.data?.er" title="Emergency Contact">ER</abbr>
            {{ emr?.person | fullName }}
            <span *ngIf="emr?.person?.contact?.phones?.length"><br /></span>
            <ng-container *ngFor="let phone of emr?.person?.contact?.phones; let isLast = last">
              <span
                >{{ phone?.label || 'Main' }}:&nbsp;<a href="tel:{{ phone?.value }}">{{ phone?.value }}</a></span
              >
              <span *ngIf="!isLast"><br /></span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-details-tab>

<!-- Templates -->

<ng-template #fieldSet let-items let-header="header" let-type="type" let-types="types">
  <div class="dx-fieldset">
    <!--<div class="dx-fieldset-header">{{header}}</div>-->

    <ng-container *ngFor="let item of items">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ item?.label || 'Main' }}
        </div>
        <div class="dx-field-value-static">
          <span
            class="fas"
            [ngClass]="{
              'fa-phone': type === 'phone',
              'fa-at': type === 'email',
              'fa-map-marker-alt': type === 'address'
            }"></span>
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'phone'">
              <a href="tel:{{ item?.value }}">
                {{ item?.value }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
              <a href="mailto:{{ item?.value }}">
                {{ item?.value }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="'address'">
              <address style="display: inline">
                {{ item?.street }}, {{ item?.city }}, {{ item?.state }}, {{ item?.zip }}
              </address>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!items?.length">
      <!--There are no {{types}}-->
    </ng-container>
  </div>
</ng-template>
