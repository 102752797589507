import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
//
import { ConfigProps, ConfigService } from '../../../shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  menuOpened = true;

  constructor(private title: Title, public config: ConfigService) {}

  ngOnInit(): void {
    this.title.setTitle(this.config.get(ConfigProps.appTitle, 'Portal'));
  }
}
