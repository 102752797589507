/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vehicle-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../vehicle-grid/vehicle-grid.component.ngfactory";
import * as i3 from "../vehicle-grid/vehicle-grid.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../shared/modules/ui/services/ui.service";
import * as i7 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i8 from "../../../../shared/modules/my-common/services/state-store.service";
import * as i9 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../shared/sdk/services/core/auth.service";
import * as i12 from "./vehicle-list.component";
var styles_VehicleListComponent = [i0.styles];
var RenderType_VehicleListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleListComponent, data: {} });
export { RenderType_VehicleListComponent as RenderType_VehicleListComponent };
export function View_VehicleListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-vehicle-grid", [], null, [[null, "mySelected"], [null, "markers"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("markers" === en)) {
        var pd_1 = (_co.grid_markers($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_VehicleGridComponent_0, i2.RenderType_VehicleGridComponent)), i1.ɵdid(2, 245760, [[1, 4]], 0, i3.VehicleGridComponent, [i4.LoggerService, i5.ConfigService, i6.UiService, i7.DataSourceService, i8.StateStoreService, i9.GridHelperService, i10.MatDialog, i11.LoopBackAuth], { templateFormTop: [0, "templateFormTop"], templateFormBottom: [1, "templateFormBottom"], extraFields: [2, "extraFields"] }, { mySelected: "mySelected", markers: "markers" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateFormTop; var currVal_1 = _co.templateFormBottom; var currVal_2 = _co.extraFields; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VehicleListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vehicle-list", [], null, null, null, View_VehicleListComponent_0, RenderType_VehicleListComponent)), i1.ɵdid(1, 114688, null, 0, i12.VehicleListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleListComponentNgFactory = i1.ɵccf("app-vehicle-list", i12.VehicleListComponent, View_VehicleListComponent_Host_0, { templateFormTop: "templateFormTop", templateFormBottom: "templateFormBottom", extraFields: "extraFields" }, { mySelected: "mySelected", markers: "markers" }, []);
export { VehicleListComponentNgFactory as VehicleListComponentNgFactory };
