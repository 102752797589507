import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
//
import { SignComponent } from './containers/sign/sign.component';
import { SigninFormComponent } from './components/signin-form/signin-form.component';
import { SignoutFormComponent } from './components/signout-form/signout-form.component';
import { SelectTenantPopupComponent } from './components/select-tenant-popup/select-tenant-popup.component';
import { SelectTenantDialogComponent } from './components/select-tenant-dialog/select-tenant-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MyCommonModule,
    UiModule,

    // RouterModule.forChild([{path: '', component: SignComponent}]),
  ],
  declarations: [
    SignComponent,
    SigninFormComponent,
    SignoutFormComponent,
    SelectTenantPopupComponent,
    SelectTenantDialogComponent,
  ],
  exports: [SignComponent],
  entryComponents: [SignComponent, SelectTenantDialogComponent],
})
export class SignModule {}
