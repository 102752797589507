<dx-tab-panel
  width="100%"
  height="100%"
  #tabPanel
  [dataSource]="tabs"
  [(selectedIndex)]="selectedIndex"
  [loop]="false"
  [animationEnabled]="false"
  [showNavButtons]="true"
  [swipeEnabled]="false">
  <div *dxTemplate="let data of 'createdSchedules'">
    <app-clone-log-details-tab-created-schedules [log]="log"></app-clone-log-details-tab-created-schedules>
  </div>
</dx-tab-panel>

