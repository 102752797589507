<app-content-w-header header="Generate Report">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset">
      <dx-toolbar>
        <dxi-item [location]="'before'" locateInMenu="auto">
          <div style="display: flex; align-items: center; min-width: 200px">
            <dx-date-box #from [(value)]="selectedFromValue"></dx-date-box>
            <span>&nbsp;&ndash;&nbsp;</span>
            <dx-date-box #to [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
          </div>
        </dxi-item>

        <dxi-item location="after" locateInMenu="auto">
          <div [attr.id]="'_' + items[0]?.items[0].type + '-trgt'" style="display: inline-block">
            <dx-button
              style="text-align: left"
              type="default"
              [disabled]="items[0]?.items[0]?.settings?.forPeriod && !isPeriodSelected"
              [text]="items[0]?.items[0]?.caption"
              (onClick)="genPeriodReport(items[0]?.items[0])"></dx-button>
          </div>

          <dx-popover
            [target]="'#_' + items[0]?.items[0].type + '-trgt'"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            minWidth="400px"
            maxWidth="600px"
            position="left">
            <div *dxTemplate="let data of 'content'">
              <div [innerHTML]="items[0]?.items[0].description | sanitizeHtml"></div>
            </div>
          </dx-popover>
        </dxi-item>
      </dx-toolbar>
    </div>
  </mat-card>

  <dx-scroll-view class="content">
    <!--<form #formCtrl="ngForm" [formGroup]="form">-->
    <!--      <div class="dx-fieldset-header">Generate Report</div>-->

    <ng-container *ngIf="(tenantType$ | async) === 'BASE'">
      <br /><br />

      <div class="grid">
        <ng-container *ngFor="let group of items; let gIdx = index">
          <!--          <mat-card [ngStyle]="{'grid-column': idx <= 2 ? 1 : 2, 'grid-row': idx % 3 + 1}">-->
          <mat-card>
            <div class="dx-fieldset">

              <ng-container *ngIf="group?.text">
                <div class="dx-fieldset-header">{{ group?.text }}</div>
              </ng-container>

              <ng-container *ngIf="!group?.roles || (group?.roles | someRole)">
                <div *ngFor="let item of group?.items; let iIdx = index">
                  <ng-container *ngIf="item?.type && !(gIdx === 0 && iIdx === 0)">
                    <br />
                    <!--        <button mat-raised-button color="primary"-->
                    <!--                [disabled]="item.forPeriod && !isPeriodSelected"-->
                    <!--                (click)="genPeriodReport(item)"-->
                    <!--        >{{item.caption}}</button>-->

                    <div [attr.id]="'_' + item?.type + '-trgt'" style="display: inline-block">
                      <dx-button
                        style="text-align: left"
                        type="default"
                        width="280px"
                        [disabled]="item?.settings?.forPeriod && !isPeriodSelected"
                        [text]="item.caption"
                        (onClick)="genPeriodReport(item)"></dx-button>
                    </div>

                    <dx-popover
                      [target]="'#_' + item?.type + '-trgt'"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      minWidth="400px"
                      maxWidth="600px"
                      position="right">
                      <div *dxTemplate="let data of 'content'">
                        <div [innerHTML]="item?.description | sanitizeHtml"></div>
                      </div>
                    </dx-popover>

                    <!--            <br>-->
                    <!--            <div class="itm-description">-->
                    <!--              <label>{{item.description}}</label>-->
                    <!--            </div>-->
                  </ng-container>
                </div>
              </ng-container>

            </div>
          </mat-card>
        </ng-container>
      </div>

      <br /><br />
    </ng-container>

    <!--</form>-->
  </dx-scroll-view>
</app-content-w-header>
