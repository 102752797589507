import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { of } from 'rxjs';
import { Employee, EmployeeApi, Facility, FacilityApi, InternalStorage, MyUtilsApi, Person, Signature, SignatureApi, Vehicle, VehicleApi, VehicleGeotab, VehicleGeotabApi, } from '../../../../shared/sdk';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { HelperService } from '../../services/helper.service';
import { HelperService as HelperManifestService } from '../../../trip-manifest/services/helper.service';
import moment from 'moment-timezone';
import { gqlMongoLoad, } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import CustomStore from 'devextreme/data/custom_store';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import * as geolib from 'geolib';
export const TIMEZONE = 'America/New_York';
const EVENTS = ['Pickups', 'Dropoffs'];
const SOURCES = ['Tablet GPS', 'Geotab GPS'];
export class TripsAuditGridComponent {
    constructor(config, dss, helper, manifestHelper, gridHelper, dialog, myUtilsAPI, internalStorage, facilityApi, auth) {
        this.config = config;
        this.dss = dss;
        this.helper = helper;
        this.manifestHelper = manifestHelper;
        this.gridHelper = gridHelper;
        this.dialog = dialog;
        this.myUtilsAPI = myUtilsAPI;
        this.internalStorage = internalStorage;
        this.facilityApi = facilityApi;
        this.auth = auth;
        this.signatureDso$ = of([]);
        this.facilityId = null;
        this.vehicleId = null;
        this.vehicles = [];
        this.driverId = null;
        this.drivers = [];
        this.consumerId = null;
        this.consumers = [];
        this.tripIdMap = {};
        this.signatures = [];
        this.markers = [];
        this.routes = [];
        this.events = [...EVENTS];
        this.selectedEvents = [...EVENTS];
        this.sources = [...SOURCES];
        this.markerBaseUrl = '/assets/images/';
        this.selectedDate = new Date();
        this.mySelected = new EventEmitter();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
                if ([Vehicle.getModelName()].includes(modelName)) {
                    if (this.grid) {
                        this.grid.instance.refresh();
                    }
                }
            });
            yield this.buildVehicles();
            yield this.buildDrivers();
        });
    }
    ngOnDestroy() {
        this.modifiedSubscription.unsubscribe();
    }
    repaint() {
        // this.grid && this.grid.instance && this.grid.instance.repaint();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
    }
    date__onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedDate = e.value;
            this.consumersSelectBox.reset();
            this.vehiclesSelectBox.reset();
            this.driversSelectBox.reset();
            yield this.buildDso();
            this.buildConsumers();
            yield this.buildVehicles();
            yield this.buildDrivers();
        });
    }
    facility__onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.facilityId = e.value;
            this.consumersSelectBox.reset();
            this.vehiclesSelectBox.reset();
            this.driversSelectBox.reset();
            yield this.buildDso();
            this.buildConsumers();
            yield this.buildVehicles();
            yield this.buildDrivers();
        });
    }
    vehicle__onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vehicleId = e.value;
            if (e.event) {
                this.consumersSelectBox.reset();
                this.driversSelectBox.reset();
                yield this.buildDso();
                this.buildConsumers();
                yield this.buildDrivers();
                if (!this.vehicleId)
                    yield this.buildVehicles();
            }
        });
    }
    vehicle__onInitialized(e) {
        this.vehiclesSelectBox = e.component;
    }
    driver__onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.driverId = e.value;
            if (e.event) {
                this.consumersSelectBox.reset();
                this.vehiclesSelectBox.reset();
                yield this.buildDso();
                this.buildConsumers();
                yield this.buildVehicles();
                if (!this.driverId)
                    yield this.buildDrivers();
            }
        });
    }
    driver__onInitialized(e) {
        this.driversSelectBox = e.component;
    }
    consumer__onSelectionChanged(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.consumerId = e.value;
            if (e.event)
                yield this.buildDso();
        });
    }
    consumer__onInitialized(e) {
        this.consumersSelectBox = e.component;
    }
    grid_onSelectionChanged({ selectedRowsData: [signature] }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!signature || !signature.meta.vehicle)
                return;
            const [positions, consumerHomeLocation, tenant] = yield Promise.all([
                this.getMapData(signature),
                this.getConsumerHomeLocation(signature),
                this.dss.getApi(Facility).findById(signature.tenantId).toPromise(),
            ]);
            let adcZonePoints = null;
            if (tenant.geotabZoneId)
                adcZonePoints = yield this.getGeoTabZonePoints(tenant.geotabZoneId);
            this.prepareMapData({ tenant, positions, adcZonePoints, signature, consumerHomeLocation });
            this.mySelected.emit({ markers: this.markers, routes: this.routes });
        });
    }
    grid_onToolbarPreparing(e) {
        const fso = this.dss.getStoreOptions(Facility, undefined, false);
        fso.customFilter = {
            where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
            order: ['typeOrder DESC', 'type', 'name'],
        };
        e.toolbarOptions.items.unshift(Object.assign({ location: 'before', widget: 'dxDateBox' }, { options: Object.assign({ width: 95, value: this.selectedDate }, { onValueChanged: this.date__onSelectionChanged.bind(this) }) }), Object.assign({ location: 'before', widget: 'dxSelectBox' }, { options: Object.assign({ width: 160, placeholder: 'Facility', valueExpr: 'id', displayExpr: 'name', searchEnabled: true }, { showClearButton: true, dataSource: new CustomStore(fso) }, { onValueChanged: this.facility__onSelectionChanged.bind(this) }) }), Object.assign({ location: 'before', widget: 'dxSelectBox' }, { options: Object.assign({ width: 90, placeholder: 'Vehicle', valueExpr: 'id', displayExpr: 'internalId', searchEnabled: true }, { showClearButton: true }, { dataSource: new CustomStore({
                    load: ({ searchValue: sv }) => this.vehicles.filter(v => !sv || ('' + v.internalId).startsWith(sv)),
                }), onValueChanged: this.vehicle__onSelectionChanged.bind(this), onInitialized: this.vehicle__onInitialized.bind(this) }) }), Object.assign({ location: 'before', widget: 'dxSelectBox' }, { options: Object.assign({ width: 140, placeholder: 'Driver', valueExpr: 'id', searchEnabled: true, showClearButton: true }, { displayExpr: v => v && `${v.firstname} ${v.lastname}`, dataSource: new CustomStore({
                    load: ({ searchValue: sv }) => this.drivers.filter(v => !sv || `${v.firstname} ${v.lastname}`.toLocaleLowerCase().includes(sv.toLocaleLowerCase())),
                }), onValueChanged: this.driver__onSelectionChanged.bind(this), onInitialized: this.driver__onInitialized.bind(this) }) }), Object.assign({ location: 'before', widget: 'dxSelectBox' }, { options: Object.assign({ width: 140, placeholder: 'Client', valueExpr: 'id', searchEnabled: true, showClearButton: true }, { displayExpr: v => v && `${v.firstname} ${v.lastname}`, dataSource: new CustomStore({
                    load: ({ searchValue: sv }) => this.consumers.filter(v => !sv || `${v.firstname} ${v.lastname}`.toLocaleLowerCase().includes(sv.toLocaleLowerCase())),
                }), onValueChanged: this.consumer__onSelectionChanged.bind(this), onInitialized: this.consumer__onInitialized.bind(this) }) }));
    }
    buildDso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.signatures = [];
            if (this.facilityId || this.vehicleId || this.driverId) {
                this.signatures = yield this.dss
                    .getApi(Signature)
                    .find({
                    where: Object.assign({}, (this.facilityId && { tenantId: this.facilityId }), (this.vehicleId && { vehicleId: this.vehicleId }), (this.driverId && { employeeId: this.driverId }), (this.consumerId && { consumerId: this.consumerId }), { marker: { neq: 'DAY_ADC' }, vdate: moment(this.selectedDate).format('YYYY-MM-DD') }),
                    include: ['tenant', { employee: 'person' }, { consumer: { person: { contact: ['addresses'] } } }],
                }, headersAllTenantsAppend)
                    .toPromise();
            }
            yield this.buildTripIdMap();
            this.signatureDso$ = of(this.signatures.map(s => (Object.assign({}, s, { broker: (s.meta.tripId && this.tripIdMap[s.meta.tripId] && this.tripIdMap[s.meta.tripId]._broker) || '' }))));
        });
    }
    buildVehicles() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.signatures.length) {
                const vehiclesMap = this.signatures.reduce((p, { meta: { vehicle: v } }) => (Object.assign({}, p, (v && { [v.id]: v }))), {});
                this.vehicles = Object.values(vehiclesMap).sort((a, b) => (a.internalId < b.internalId && -1) || (a.internalId > b.internalId && 1) || 0);
            }
            else {
                this.vehicles = yield this.dss
                    .getApi(Vehicle)
                    .find({ order: ['internalId'] }, headersAllTenantsAppend)
                    .toPromise();
            }
            this.vehiclesSelectBox.getDataSource().reload();
        });
    }
    buildDrivers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.signatures.length) {
                const driversMap = this.signatures.reduce((p, { employee: e }) => (Object.assign({}, p, (e && { [e.id]: Object.assign({}, e.person, { id: e.id }) }))), {});
                this.drivers = Object.values(driversMap);
            }
            else {
                this.drivers = (yield this.dss
                    .getApi(Employee)
                    .find({ where: { employeePositionId: { inq: [39, 40] } }, include: ['person'] }, headersAllTenantsAppend)
                    .toPromise()).map(({ id, person: p }) => (Object.assign({}, p, { id })));
            }
            this.drivers = this.drivers.sort((a, b) => (a.firstname < b.firstname && -1) ||
                (a.firstname > b.firstname && 1) ||
                (a.lastname < b.lastname && -1) ||
                (a.lastname > b.lastname && 1) ||
                0);
            this.driversSelectBox.getDataSource().reload();
        });
    }
    buildConsumers() {
        const consumersMap = this.signatures.reduce((p, { consumer: c }) => (Object.assign({}, p, (c && { [c.id]: Object.assign({}, c.person, { id: c.id }) }))), {});
        this.consumers = Object.values(consumersMap).sort((a, b) => (a.firstname < b.firstname && -1) ||
            (a.firstname > b.firstname && 1) ||
            (a.lastname < b.lastname && -1) ||
            (a.lastname > b.lastname && 1) ||
            0);
        this.consumersSelectBox.getDataSource().reload();
    }
    buildTripIdMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.signatures.length)
                return;
            const aggregate = [
                {
                    $match: {
                        _tripId: { $in: this.signatures.flatMap(s => (s.meta.tripId && [s.meta.tripId]) || []) },
                    },
                },
            ];
            const res = yield gqlMongoLoad(this.dss, 'ExportsDataCache', {}, aggregate).toPromise();
            this.tripIdMap = res.reduce((p, { _tripId, _broker }) => (Object.assign({}, p, { [_tripId]: { _broker } })), {});
        });
    }
    ///////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////
    getMapData(signature) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const start = { $dateFromString: { dateString: moment(this.selectedDate).startOf('day').toISOString() } };
            const end = { $dateFromString: { dateString: moment(this.selectedDate).endOf('day').toISOString() } };
            const stages = [
                { $match: { internalId: signature.meta.vehicle.internalId, month: +moment(this.selectedDate).format('YYYYMM') } },
                {
                    $project: Object.assign({ deviceId: 1, month: 1, internalId: 1 }, { positions: {
                            $filter: Object.assign({ input: '$positions', as: 'position' }, { cond: { $and: [{ $gte: ['$$position.dateTime', start] }, { $lte: ['$$position.dateTime', end] }] } }),
                        } }),
                },
            ];
            const data = yield this.dss.getApi(VehicleGeotab).aggregate(stages).toPromise();
            return (data[0] || {}).positions || [];
        });
    }
    getConsumerHomeLocation({ id, marker, pickupTime }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!pickupTime)
                return null;
            const { geocodeFrom, geocodeTo } = yield this.dss
                .getApi(Signature)
                .getSignatureAddresses(id, headersAllTenantsAppend)
                .toPromise();
            return marker === 'TO_ADC' ? geocodeFrom : geocodeTo;
        });
    }
    prepareMapData({ tenant, positions, adcZonePoints, signature, consumerHomeLocation }) {
        const { internalId } = signature.meta.vehicle;
        console.log('prepareMapData');
        const { markers: signMarkers, ePerson } = this.getSignatureMarkers(positions, signature);
        let markers = [];
        if (positions.length > 1) {
            markers = this.getStartFinishMarkers(positions, ePerson, internalId);
        }
        if (adcZonePoints) {
            const { latitude, longitude } = geolib.getCenter(adcZonePoints) || adcZonePoints[0];
            markers.push({
                iconSrc: `${this.markerBaseUrl}marker-facility.png`,
                location: `${latitude}, ${longitude}`,
                tooltip: { text: `${tenant.name}`, isShown: false },
            });
        }
        if (consumerHomeLocation) {
            markers.push(this.getConsumerHomeMarker(consumerHomeLocation, signature));
        }
        let routes = [];
        let loopRoutes = [];
        let loopNumber = 0;
        let loopMarkers = [];
        let locations = [];
        let isPrevInAdcZone = false;
        let { dateTime: firstDateTimeOfLoop } = positions[0];
        const tc = signature.pickupTime && moment.tz(`${signature.vdate} ${signature.pickupTime}`, TIMEZONE).utc().format();
        positions.forEach(({ latitude, longitude, dateTime, duration, speed }, i) => {
            const position = [latitude, longitude];
            locations.push(position);
            if (speed === 0 && duration > 90) {
                loopMarkers.push(this.getStopMarker({ latitude, longitude, dateTime, duration, ePerson, internalId }));
            }
            const isInAdcZone = adcZonePoints && geolib.isPointInPolygon({ latitude, longitude }, adcZonePoints);
            if ((isPrevInAdcZone && !isInAdcZone) || locations.length > 24 || i === positions.length - 1) {
                loopRoutes.push(this.getRoute(locations, loopNumber));
                locations = [position];
                if ((isPrevInAdcZone && !isInAdcZone) || i === positions.length - 1) {
                    if (!tc || (firstDateTimeOfLoop < tc && tc < dateTime)) {
                        routes = [...routes, ...loopRoutes];
                        markers = [...markers, ...loopMarkers];
                    }
                    loopRoutes = [];
                    loopMarkers = [];
                    firstDateTimeOfLoop = dateTime;
                    loopNumber++;
                }
            }
            isPrevInAdcZone = isInAdcZone;
        });
        this.routes = [...routes];
        this.markers = [...markers, ...signMarkers];
    }
    getRoute(locations, loopNumber) {
        const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
        return {
            weight: 4,
            color: colors[loopNumber % colors.length],
            opacity: 0.8,
            mode: '',
            locations: [...locations],
        };
    }
    getSignatureMarkers(positions, signature) {
        const { marker, meta: { vehicle: { internalId }, }, } = signature;
        let ePerson = {};
        let markers = [];
        (signature.consumer
            ? [signature]
            : this.signatures.filter(s => s.meta && s.meta.vehicle && s.meta.vehicle.internalId === internalId && s.marker === marker)).forEach(s => {
            ePerson = (s.employee && s.employee.person) || ePerson;
            const cPerson = (s.consumer && s.consumer.person) || {};
            const { firstname, lastname } = cPerson;
            if (s.meta.pickUpDeviceLocation &&
                this.selectedEvents.includes('Pickups') &&
                s.pickupTime //&&
            // this.selectedClients.includes(`${firstname} ${lastname}`)
            ) {
                // if (this.selectedSources.includes('Tablet GPS')) {
                // markers.push(this.getSignatureMarker(true, s.meta.pickUpDeviceLocation, ePerson, cPerson, internalId));
                // }
                // if (this.selectedSources.includes('Geotab GPS')) {
                const tc = moment.tz(`${s.vdate} ${s.pickupTime}`, TIMEZONE).utc().format();
                const p = this.findStopPosition(positions, tc);
                if (p)
                    markers.push(this.getSignatureMarkerTmp(true, p, ePerson, cPerson, internalId, tc));
                // }
            }
            if (s.meta.dropOffDeviceLocation &&
                this.selectedEvents.includes('Dropoffs') &&
                s.dropoffTime //&&
            // this.selectedClients.includes(`${firstname} ${lastname}`)
            ) {
                // if (this.selectedSources.includes('Tablet GPS')) {
                // markers.push(this.getSignatureMarker(false, s.meta.dropOffDeviceLocation, ePerson, cPerson, internalId));
                // }
                // if (this.selectedSources.includes('Geotab GPS')) {
                const tc = moment.tz(`${s.vdate} ${s.dropoffTime}`, TIMEZONE).utc().format();
                const p = this.findStopPosition(positions, tc);
                if (p)
                    markers.push(this.getSignatureMarkerTmp(false, p, ePerson, cPerson, internalId, tc));
                // }
            }
        });
        return { markers, ePerson };
    }
    findStopPosition(positions, time) {
        return (time &&
            positions.find(({ dateTime, duration, speed }) => !speed && moment(dateTime).add(duration + 60, 'seconds') > moment(time)));
    }
    getConsumerHomeMarker({ formatted_address, geometry }, { consumer: { person } }) {
        return {
            iconSrc: `${this.markerBaseUrl}marker-home.png`,
            location: `${geometry.location.lat}, ${geometry.location.lng}`,
            tooltip: {
                text: `<b>${person.firstname} ${person.lastname}</b>` + `<br/>${formatted_address.split(',').join('<br/>')}`,
                isShown: false,
            },
        };
    }
    getSignatureMarker(isPickUp, { lat, lng, isGeoTab }, tc, ePerson, cPerson, internalId) {
        return {
            iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}.png`,
            location: `${lat}, ${lng}`,
            tooltip: {
                text: `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
                    `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
                    `<br/>${(isGeoTab && 'GeoTab') || ''}`,
                isShown: false,
            },
        };
    }
    getSignatureMarkerTmp(isPickUp, { latitude, longitude, dateTime, duration, speed }, ePerson, cPerson, internalId, tc) {
        return {
            iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}-tmp.png`,
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} <b>${cPerson.firstname} ${cPerson.lastname}</b>` +
                    `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
                    `<br/><em>Arrival Time:</em> ${moment((tc > dateTime && dateTime) || tc).format('LT')}` +
                    `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
                    `<br/><em>Speed:</em> ${speed}` +
                    `<br/>GeoTab`,
                isShown: false,
            },
        };
    }
    getStopMarker({ latitude, longitude, dateTime, duration, ePerson, internalId }) {
        return {
            iconSrc: this.markerBaseUrl + `marker-stop${duration > 60 * 30 ? '-red' : ''}.png`,
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
                    `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}`,
                isShown: false,
            },
        };
    }
    getStartFinishMarkers(positions, ePerson, internalId) {
        const isToday = moment(this.selectedDate).isSame(new Date(), 'day');
        this.selectedDate;
        return [
            Object.assign({}, positions[0], { iconSrc: this.markerBaseUrl + 'marker-start.png', isStart: true }),
            Object.assign({}, positions[positions.length - 1], { iconSrc: this.markerBaseUrl + `marker-${isToday ? 'current' : 'finish'}.png` }),
        ].map(({ dateTime, speed, currentStateDuration, isDriving, latitude, longitude, iconSrc, isStart }) => ({
            iconSrc,
            location: `${latitude}, ${longitude}`,
            tooltip: {
                text: `${internalId} ${ePerson.firstname} ${ePerson.lastname}` +
                    `<br/><em>${isStart ? 'Start' : isToday ? '' : 'Finish'} Time:</em> ${moment(dateTime).format('LT')}` +
                    `<br/><em>Driving:</em> ${(isDriving && 'yes') || 'not'}` +
                    `<br/><em>Speed:</em> ${speed}` +
                    `<br/><em>State Duration:</em> ${moment.duration(currentStateDuration, 'seconds').humanize()}`,
                isShown: false,
            },
        }));
    }
    getGeoTabZonePoints(id, attempt = 0) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const geoTabAuth = this.internalStorage.get('geoTabAuth') || (yield this.myUtilsAPI.geoTabAuth().toPromise());
                this.internalStorage.set('geoTabAuth', geoTabAuth);
                const api = new GeotabApi(geoTabAuth);
                const [[zone]] = yield api.multiCall([['Get', { typeName: 'Zone', search: { id } }]]);
                return (zone && zone.points.map(({ x, y }) => ({ latitude: y, longitude: x }))) || null;
            }
            catch (err) {
                const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
                if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.')
                    throw msg;
                if (attempt)
                    return {};
                this.internalStorage.remove('geoTabAuth');
                return yield this.getGeoTabZonePoints(id, 1);
            }
        });
    }
}
