import { Component, ViewChild } from '@angular/core';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import { PivotGridDataSourceOptions } from 'devextreme/ui/pivot_grid/data_source';
import moment from 'moment/moment';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-meals-claims-pivot',
  templateUrl: './meals-claims-pivot.component.html',
  styleUrls: ['./meals-claims-pivot.component.scss'],
})
export class MealsClaimsPivotComponent extends ABaseComponent {
  @ViewChild(DxPivotGridComponent, { static: false }) grid: DxPivotGridComponent;

  dso: any;
  grid_stateStoring: any;

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  showColumnTotals = true;
  showRowTotals = true;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  collection = 'MealClaimsPivotData';
  exportFileName = 'Meal_Claims_Pivot_' + moment().format('YYYY-MM-DD HH:mm:ss');

  constructor(public logger: LoggerService, protected dss: DataSourceService) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'ecf37ade-94b1-414b-bc8e-135fb7f146c1',
    };

    this.dso = {
      remoteOperations: true,
      store: this.dss.createMongoStore(this.collection),
      fields: [
        { dataField: '_id', visible: false },
        { area: 'column', dataField: 'periodFrom', dataType: 'date', groupInterval: 'year' },
        { area: 'column', dataField: 'periodFrom', dataType: 'date', groupInterval: 'quarter' },
        { area: 'column', dataField: 'periodFrom', dataType: 'date', groupInterval: 'month' },
        // { dataField: 'periodFrom', dataType: 'date', groupInterval: 'day' },

        { dataField: 'code', caption: 'Code', dataType: 'string', isMeasure: false, area: 'row' },
        { dataField: 'tenant', caption: 'Tenant', dataType: 'string', isMeasure: false, area: 'row' },
        { dataField: 'client', caption: 'Client', dataType: 'string', isMeasure: false, area: 'row' },
        { dataField: 'mci', caption: 'MCI', dataType: 'string', isMeasure: false, area: 'row' },
        { dataField: 'memberId', caption: 'Member Id', dataType: 'string', isMeasure: false, area: 'row' },

        ...[
          ['signUnits', 'Signed'],
          ['authUnits', 'from Auths'],
          ['chcBilledUnits', 'billed CHC'],
          ['chcPaidUnits', 'paid CHC'],
          ['hhaUnits', 'from HHA'],
        ].map(i => ({
          dataField: i[0],
          caption: i[1],
          area: 'data',
          dataType: 'number',
          summaryType: 'sum',
          isMeasure: true,
        })),
      ],
    } as PivotGridDataSourceOptions;
  }
}
