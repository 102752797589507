<dx-validation-group [formGroup]="form">
  <div class="dx-fieldset">
    <ng-container *ngTemplateOutlet="templateFormTop; context: {form: form}"></ng-container>
    <!-- <div class="dx-field">
      <label class="_dx-field-label">Notification Group:</label>
      <div class="_dx-field-value">
        <dx-tag-box width="680px" [dataSource]="notificationGroupDso" displayExpr="username" valueExpr="id" formControlName="notificationGroupUserIds"></dx-tag-box>
      </div>
    </div> -->
    <div class="dx-field">
      <label class="_dx-field-label">Subject:</label>
      <div class="_dx-field-value">
        <dx-text-box formControlName="subject">
          <dx-validator name="Subject">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <label class="_dx-field-label">Description:</label>
      <div class="_dx-field-value">
        <dx-text-area formControlName="description">
          <dx-validator name="Description">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dx-validator>
        </dx-text-area>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="templateFormBottom; context: {form: form}"></ng-container>
    <div class="dx-field">
      <label class="_dx-field-label">Related Note:</label>
      <div class="_dx-field-value">
        <dx-select-box [dataSource]="dso" [displayExpr]="displayExpr" valueExpr="id" formControlName="relatedNoteId"></dx-select-box>
      </div>
    </div>
  </div>
</dx-validation-group>
