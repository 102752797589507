import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-trips',
  templateUrl: './broker-trips.component.html',
  styleUrls: ['./broker-trips.component.scss'],
})
export class BrokerTripsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
