<kendo-splitter>
  <kendo-splitter-pane [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-clone-log-list (mySelected)="list_onSelected($event)"></app-clone-log-list>
  </kendo-splitter-pane>

  <kendo-splitter-pane size="50%" [collapsible]="true" [scrollable]="false" (sizeChange)="splitter_sizeChange($event)">
    <app-clone-log-details-tabs [log]="selected$ | async"></app-clone-log-details-tabs>
  </kendo-splitter-pane>
</kendo-splitter>

