/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clone-log-details-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../clone-log-details-tab-created-schedules/clone-log-details-tab-created-schedules.component.ngfactory";
import * as i3 from "../clone-log-details-tab-created-schedules/clone-log-details-tab-created-schedules.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/ui/services/ui.service";
import * as i6 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../../../node_modules/devextreme-angular/ui/tab-panel/devextreme-angular-ui-tab-panel.ngfactory";
import * as i9 from "devextreme-angular/core";
import * as i10 from "devextreme-angular/ui/tab-panel";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./clone-log-details-tabs.component";
var styles_CloneLogDetailsTabsComponent = [i0.styles];
var RenderType_CloneLogDetailsTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloneLogDetailsTabsComponent, data: {} });
export { RenderType_CloneLogDetailsTabsComponent as RenderType_CloneLogDetailsTabsComponent };
function View_CloneLogDetailsTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-clone-log-details-tab-created-schedules", [], null, null, null, i2.View_CloneLogDetailsTabCreatedSchedulesComponent_0, i2.RenderType_CloneLogDetailsTabCreatedSchedulesComponent)), i1.ɵdid(2, 245760, null, 0, i3.CloneLogDetailsTabCreatedSchedulesComponent, [i4.LoggerService, i5.UiService, i6.DataSourceService, i7.MatDialog], { log: [0, "log"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.log; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CloneLogDetailsTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "dx-tab-panel", [["height", "100%"], ["width", "100%"]], null, [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = ((_co.selectedIndex = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DxTabPanelComponent_0, i8.RenderType_DxTabPanelComponent)), i1.ɵprd(512, null, i9.DxTemplateHost, i9.DxTemplateHost, []), i1.ɵprd(512, null, i9.WatcherHelper, i9.WatcherHelper, []), i1.ɵprd(512, null, i9.IterableDifferHelper, i9.IterableDifferHelper, [i1.IterableDiffers]), i1.ɵprd(512, null, i9.NestedOptionHost, i9.NestedOptionHost, []), i1.ɵdid(5, 7323648, [["tabPanel", 4]], 1, i10.DxTabPanelComponent, [i1.ElementRef, i1.NgZone, i9.DxTemplateHost, i9.WatcherHelper, i9.IterableDifferHelper, i9.NestedOptionHost, i11.TransferState, i1.PLATFORM_ID], { animationEnabled: [0, "animationEnabled"], dataSource: [1, "dataSource"], height: [2, "height"], loop: [3, "loop"], selectedIndex: [4, "selectedIndex"], showNavButtons: [5, "showNavButtons"], swipeEnabled: [6, "swipeEnabled"], width: [7, "width"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { itemsChildren: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CloneLogDetailsTabsComponent_1)), i1.ɵdid(8, 16384, null, 0, i9.DxTemplateDirective, [i1.TemplateRef, i1.ViewContainerRef, i9.DxTemplateHost, i1.Renderer2, i1.NgZone], { dxTemplateOf: [0, "dxTemplateOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.tabs; var currVal_2 = "100%"; var currVal_3 = false; var currVal_4 = _co.selectedIndex; var currVal_5 = true; var currVal_6 = false; var currVal_7 = "100%"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = "createdSchedules"; _ck(_v, 8, 0, currVal_8); }, null); }
export function View_CloneLogDetailsTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clone-log-details-tabs", [], null, null, null, View_CloneLogDetailsTabsComponent_0, RenderType_CloneLogDetailsTabsComponent)), i1.ɵdid(1, 114688, null, 0, i12.CloneLogDetailsTabsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CloneLogDetailsTabsComponentNgFactory = i1.ɵccf("app-clone-log-details-tabs", i12.CloneLogDetailsTabsComponent, View_CloneLogDetailsTabsComponent_Host_0, { log: "log" }, {}, []);
export { CloneLogDetailsTabsComponentNgFactory as CloneLogDetailsTabsComponentNgFactory };
