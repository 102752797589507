import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  pure: true,
})
export class SplitPipe implements PipeTransform {
  transform(value: string, separator: string = ','): string[] {
    return value && value.length ? value.split(separator) : [];
  }
}
