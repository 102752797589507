import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { LogsComponent } from './containers/logs/logs.component';
import { FileUploadsComponent } from './components/file-uploads/file-uploads.component';
import { PivotComponent } from './components/pivot/pivot.component';
import { ConsumerChangeComponent } from './components/consumer-change/consumer-change.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MyCommonModule, UiModule],
  declarations: [LogsComponent, FileUploadsComponent, PivotComponent, ConsumerChangeComponent],
  exports: [FileUploadsComponent, PivotComponent, ConsumerChangeComponent],
})
export class LogModule {}
