import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  Inject,
} from '@angular/core';
import { utc } from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import { AnyNote } from 'src/app/shared/sdk/models/AnyNote';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { ExtLoopBackAuth } from '../../../ext-sdk/services/ext-sdk-auth.service';
import { AnyNoteApi, LoopBackAuth, MyUser } from '../../../../sdk';
import { UiService } from '../../services/ui.service';
import { AnyNoteFormComponent } from '../any-notes-page/any-note-form/any-note-form.component';
import { DataSourceService } from '../../../my-common/services/datasource.service';

@Component({
  selector: 'app-any-notes',
  templateUrl: './any-notes.component.html',
  styleUrls: ['./any-notes.component.scss'],
})
export class AnyNotesComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() notes: AnyNote[];
  @Input() addDialogNoteInputs?: any;
  @Input() notesFormArrayName = 'anyNotes';
  @Input() configMap = {};
  @Input() wrap = true;

  @Output() validateAsync: EventEmitter<any> = new EventEmitter();

  addNoteEnabled: boolean;
  user: MyUser;

  constructor(
    protected fb: FormBuilder,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
    protected ui: UiService,
    private dss: DataSourceService,
  ) {
    this.user = this.auth.getCurrentUserData();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notes && this.addNoteEnabled) {
      this.addNoteEnabled = false;
      this.notesFormArray.removeAt(0);
      this.validateAsync.emit();
    }
  }

  get notesFormArray(): FormArray {
    return this.form.get(this.notesFormArrayName) as FormArray;
  }

  addNoteClick(e, value: boolean) {
    if (this.addDialogNoteInputs) {
      this.ui
        .openEditDialog({
          modelId: null,
          title: 'Add Note',
          inputs: { ...this.addDialogNoteInputs },
          ModelClass: AnyNote,
          FormComponentClass: AnyNoteFormComponent,
        })
        .afterClosed()
        .toPromise()
        .then(async n => {
          if (!n) return;
          const note = await this.dss
            .getApi<AnyNoteApi>(AnyNote)
            .findById<AnyNote>(n.id, { include: ['createdBy'] })
            .toPromise();
          this.notes.unshift(note);
        });
    } else {
      if (value) {
        const conf = {
          id: [],
          ...this.configMap,
          subject: [],
          description: [],
          createdById: this.user.id,
          createdAt: new Date(),
          updatedAt: new Date(),
        };
        const fg = this.fb.group(cloneDeep(conf));
        this.notesFormArray.insert(0, fg);
      } else this.notesFormArray.removeAt(0);
      this.addNoteEnabled = value;
    }
  }

  info(note: AnyNote) {
    const info = note
      ? [
          [
            note.createdBy ? `by ${note.createdBy}` : '',
            note.createdAt ? `on ${utc(note.createdAt).format('YYYY/MM/DD')}` : '',
          ]
            .filter(chnk => !isEmpty(chnk))
            .join(' '),
        ]
          .filter(chnk => !isEmpty(chnk))
          .join(', ')
      : '';

    return !isEmpty(info) ? `(${info})` : '';
  }
}

