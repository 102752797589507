<div>
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)">
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <!--    <dxo-sorting mode="none"></dxo-sorting>-->

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-export [enabled]="true" fileName="MTM-Claims" [allowExportSelectedData]="true"></dxo-export>

    <!-- Columns -->

    <dxi-column caption="Broker" dataField="_broker" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Date"
      dataField="_date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="true"></dxi-column>

    <dxi-column caption="Trip Number" dataField="_tripId" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Booking Id" dataField="Booking Id" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Last Name" dataField="Last Name" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="First Name" dataField="First Name" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Reimbursement Status" dataField="Reimbursement Status" dataType="string" [allowSorting]="true">
      <dxo-lookup [dataSource]="status1Dso" displayExpr="_id" valueExpr="_id"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Date"
      dataField="Date"
      dataType="date"
      format="shortDate"
      [customizeText]="config.customizeShortDateText"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Schedule Status" dataField="Schedule Status" dataType="string" [allowSorting]="true">
      <dxo-lookup [dataSource]="status2Dso" displayExpr="_id" valueExpr="_id"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="From" dataField="From" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="To" dataField="To" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Requested Time Origin"
      dataField="Requested Time Origin"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="Driver" dataField="Driver" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column caption="Run Name" dataField="Run Name" dataType="string" [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Vehicle Number"
      dataField="Vehicle Number"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Provider Cost"
      dataField="Provider Cost"
      dataType="number"
      *ngIf="isBiller$ | async"
      [allowSorting]="false">
      <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>

    <dxi-column
      caption="Disputed Cost"
      dataField="Disputed Cost"
      dataType="number"
      *ngIf="isBiller$ | async"
      [allowSorting]="false">
      <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>

    <dxi-column
      caption="Agreed Cost"
      dataField="Agreed Cost"
      dataType="number"
      *ngIf="isBiller$ | async"
      [allowSorting]="false">
      <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>

    <dxi-column
      caption="Reimbursement Check"
      dataField="Reimbursement Check"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Reimbursement Invoice"
      dataField="Reimbursement Invoice"
      dataType="string"
      [allowSorting]="false"></dxi-column>

    <dxi-column
      caption="Imported Date"
      dataField="_ctime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <dxi-column
      caption="Updated Date"
      dataField="_rtime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

    <!-- -->

    <!--    <dxi-column caption="Facility"-->
    <!--                dataType="string"-->
    <!--                cellTemplate="facilityCellTpl"-->
    <!--                [allowSearch]="false"-->
    <!--                [allowSorting]="false"-->
    <!--                [allowFiltering]="false"-->
    <!--                [allowHeaderFiltering]="false"-->
    <!--    ></dxi-column>-->

    <!--    <dxi-column caption="Assigned"-->
    <!--                dataType="boolean"-->
    <!--                cellTemplate="assignedCellTpl"-->
    <!--                [allowSearch]="false"-->
    <!--                [allowSorting]="false"-->
    <!--                [allowFiltering]="false"-->
    <!--                [allowHeaderFiltering]="false"-->
    <!--    ></dxi-column>-->

    <!-- Templates -->

    <ng-container *dxTemplate="let cellInfo of 'facilityCellTpl'">
      {{ cellInfo.data._facility }}
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'assignedCellTpl'">
      <dx-check-box [value]="cellInfo.data._assigned" [readOnly]="true"></dx-check-box>
    </ng-container>
  </dx-data-grid>
</div>
