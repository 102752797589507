import { Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { LoadOptions } from 'devextreme/data/load_options';
import moment, { duration } from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { HelperService } from '../../../services/helper.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../../classes/enums';
import { Employee, LoggerService, Person } from 'src/app/shared/sdk';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { StateStoreService } from 'src/app/shared/modules/my-common/services/state-store.service';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';

@Component({
  selector: 'app-employee-perf-grid',
  templateUrl: './employee-perf-grid.component.html',
  styleUrls: ['./employee-perf-grid.component.scss'],
  providers: [HelperService],
})
export class EmployeePerfGridComponent extends ABaseComponent implements OnInit {
  @ViewChild('eventsPopupContent', { static: false }) eventsPopupContent: TemplateRef<any>;
  @ViewChild('tardinessPopupContent', { static: false }) tardinessPopupContent: TemplateRef<any>;
  popupContent: TemplateRef<any>;

  sexes = PERSON_SEXES;
  types = EMPLOYEE_TYPES;
  statuses = EMPLOYEE_STATUSES;
  dateFrom = moment(new Date()).subtract(1, 'months');
  dateTo = moment(new Date());

  dso: DataSourceOptions;
  eventsDataSource: DataSourceOptions;
  tardinessDataSource: DataSourceOptions;
  popupVisible = false;
  popupPositionOf: string;
  popupTitle: string;

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  @Output() mySelectedRange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
    const self = this;

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b64648cd-7c24-4455-a355-7ea404328323',
    };

    // this.dso = dss.getDataSourceOptions(Employee);

    const so = this.dss.getStoreOptions(Employee, Employee, false);
    so.customFilter = { include: ['employeePosition', 'person'] };
    const store = new CustomStore(so);

    dxStoreLoadHooks(store, undefined, async (args: any[], [empls]: any[]) => {
      const [geotab, events, tardiness] = await Promise.all([
        this.getGeoTabCountsAggregate(empls),
        this.getEventCountsAggregate(empls),
        this.getTardinessCountsAggregate(empls),
      ]);

      let map = geotab.reduce(
        (p, v) => ({
          ...p,
          [v._id]: {
            ...v,
            distance: Math.ceil(v.distance),
            internalIds: v.internalIds.join(', '),
          },
        }),
        {},
      );
      map = events.reduce((p, v) => ({ ...p, [v._id]: { ...v, ...map[v._id] } }), map);
      map = tardiness.reduce((p, v) => ({ ...p, [v._id]: { ...v, ...map[v._id] } }), map);
      return [empls.filter(e => map[e.id]).map(e => ({ ...e, compliance: 0, safety: 0, tardiness: 0, ...map[e.id] }))];
    });

    this.dso = { store } as DataSourceOptions;

    this.dso.postProcess = (data: Array<any>) => {
      data.forEach(rec => (rec._anotherTenant = this.common.auth.getCurrentTenant() !== rec.tenantId));
      return data;
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent
      .pipe(
        tap(modelName => {
          if ([Employee.getModelName(), Person.getModelName()].includes(modelName)) {
            if (this.grid) {
              this.grid.instance.refresh();
            }
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
    this.mySelectedRange.emit({ dateFrom: this.dateFrom, dateTo: this.dateTo });
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  grid_onToolbarPreparing(e) {}

  calendar_onValueChanged(e) {
    this.grid.instance.refresh();
    this.mySelectedRange.emit({ dateFrom: this.dateFrom, dateTo: this.dateTo });
  }

  getDuration(seconds: number) {
    if (!seconds) return '00:00';
    const hours = Math.floor(duration(seconds, 'seconds').asHours());
    const mmss = moment.utc(duration(seconds - hours * 60 * 60, 'seconds').as('milliseconds')).format('mm:ss');
    return `${String(hours).padStart(2, '0')}:${mmss}`;
  }

  showPopup_onClick = async (id, v, type) => {
    if (type === 'Tardiness') {
      this.tardinessDataSource = await this.getTardinessAggregate(v.id);
      this.popupContent = this.tardinessPopupContent;
    } else {
      this.eventsDataSource = await this.getEventsAggregate(v.id, type);
      this.popupContent = this.eventsPopupContent;
    }
    this.popupTitle = `${type} for ${v.person.firstname} ${v.person.lastname}`;
    this.popupPositionOf = id;
    this.popupVisible = true;
  };

  getDetailsCellValue = v => {
    if (v.rule == 'Idling') return 'IDLE for more than 30 minutes';
    return v.diagnostic || `Speed:${v.speed}, Limit:${v.speedLimit}`;
  };

  calculateTardiness = ({ schedule: { startTime }, punchInTime }) =>
    (startTime && punchInTime && moment(punchInTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'minutes')) || '';

  getGeoTabCountsAggregate(empls: any[]) {
    const from = moment(this.dateFrom);
    const to = moment(this.dateTo);
    const stages = [
      { $match: { month: { $gte: +from.format('YYYYMM'), $lte: +to.format('YYYYMM') } } },
      { $unwind: '$days' },
      { $match: { 'days.day': { $gte: +from.format('YYYYMMDD'), $lte: +to.format('YYYYMMDD') } } },
      {
        $group: {
          _id: '$days.employeeId',
          days: { $sum: 1 },
          onDutyDuration: { $sum: '$days.onDutyDuration' },
          distance: { $sum: '$days.distance' },
          onDutyDrivingDuration: { $sum: '$days.onDutyDrivingDuration' },
          onDutyIdleDuration: { $sum: '$days.onDutyIdleDuration' },
          internalIds: { $addToSet: '$internalId' },
          signaturesCount: { $sum: '$days.signaturesCount' },
        },
      },
    ];

    return gqlMongoLoad(this.dss, 'VehicleGeotab', {}, stages).pipe().toPromise();
  }

  getEventCountsAggregate(empls: any[]) {
    const stages = [
      {
        $match: {
          $expr: {
            $and: [
              { $gte: ['$dateTime', { $dateFromString: { dateString: moment(this.dateFrom).toISOString() } }] },
              { $lte: ['$dateTime', { $dateFromString: { dateString: moment(this.dateTo).toISOString() } }] },
            ],
          },
        },
      },
      {
        $project: {
          employeeId: 1,
          compliance: { $cond: [{ $eq: ['$eventType', 'Compliance'] }, 1, 0] },
          safety: { $cond: [{ $eq: ['$eventType', 'Safety'] }, 1, 0] },
        },
      },
      {
        $group: { _id: '$employeeId', compliance: { $sum: '$compliance' }, safety: { $sum: '$safety' } },
      },
    ];
    return gqlMongoLoad(this.dss, 'VehicleGeotabEvent', {}, stages).pipe().toPromise();
  }

  getTardinessCountsAggregate(empls: any[]) {
    const stages = [
      {
        $match: {
          date: { $gte: moment(this.dateFrom).format('YYYY-MM-DD'), $lte: moment(this.dateTo).format('YYYY-MM-DD') },
        },
      },
      { $group: { _id: '$driver.id', tardiness: { $sum: 1 } } },
    ];
    return gqlMongoLoad(this.dss, 'MissingScheduledDrivers', {}, stages).pipe().toPromise();
  }

  getEventsAggregate(employeeId, type) {
    const stages = [
      {
        $match: {
          employeeId: employeeId,
          eventType: type,
          $expr: {
            $and: [
              { $gte: ['$dateTime', { $dateFromString: { dateString: moment(this.dateFrom).toISOString() } }] },
              { $lte: ['$dateTime', { $dateFromString: { dateString: moment(this.dateTo).toISOString() } }] },
            ],
          },
        },
      },
    ];
    return gqlMongoLoad(this.dss, 'VehicleGeotabEvent', {}, stages).pipe().toPromise();
  }

  getTardinessAggregate(employeeId) {
    const stages = [
      {
        $match: {
          'driver.id': employeeId,
          date: { $gte: moment(this.dateFrom).format('YYYY-MM-DD'), $lte: moment(this.dateTo).format('YYYY-MM-DD') },
        },
      },
      { $sort: { date: -1, createdAt: -1 } },
    ];
    return gqlMongoLoad(this.dss, 'MissingScheduledDrivers', {}, stages).pipe().toPromise();
  }
}

