import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService, MyUtilsApi, VehicleCheckUp } from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';
export class DailyChecksDetailsTabPicsComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, dialog, pusher, utilsApi) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.dialog = dialog;
        this.pusher = pusher;
        this.utilsApi = utilsApi;
        this.showAdd = false;
        this.showEdit = false;
        this.popupVisible = false;
        this.currentPic = '';
        this.caption = 'Pictures';
    }
    ngOnInit() {
        super.ngOnInit();
    }
    get ModelClass() {
        return VehicleCheckUp;
    }
    beforeModelLoadingAsync(id) {
        const _super = Object.create(null, {
            beforeModelLoadingAsync: { get: () => super.beforeModelLoadingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.fullDoc = undefined;
            this.descAndReport = undefined;
            this.descAndReportMap = { ci: new Map(), co: new Map() };
            return _super.beforeModelLoadingAsync.call(this, id);
        });
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.modelId) {
                try {
                    this.loading = true;
                    this.fullDoc = yield gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId)
                        .pipe(tap(doc => {
                        doc._ci_picturesEntries = Object.entries(oc(doc).pictures({}));
                        doc._co_picturesEntries = Object.entries(oc(doc).checkout.pictures({}));
                    }))
                        .toPromise();
                    this.descAndReport = yield this.api.getAllFieldsDescriptions(this.modelId).toPromise();
                    this.descAndReportMap = {
                        ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm])),
                        co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm])),
                    };
                }
                finally {
                    this.loading = false;
                }
            }
            return _super.afterModelLoadedAsync.call(this, model);
        });
    }
    get FormComponent() {
        return undefined;
    }
    showPic(pic) {
        this.currentPic = pic;
        this.popupVisible = true;
    }
}
