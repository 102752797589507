<h2 mat-dialog-title>Image will be assigned to current signature</h2>

<mat-dialog-content>
<!--  <mat-card>-->
<!--    <mat-checkbox [(ngModel)]="value.toClient">Assign to Client Default</mat-checkbox>-->
<!--    <br />-->
<!--    <mat-checkbox [(ngModel)]="value.toAllSignatures">Assign to All Client Signatures</mat-checkbox>-->
<!--  </mat-card>-->
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button color="primary" [mat-dialog-close]="value">Assign</button>
</mat-dialog-actions>
