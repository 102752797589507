<div>
  <dx-data-grid
    height="100%"
    width="100%"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    [dateSerializationFormat]="null"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

    <!-- Columns -->

    <dxi-column
      caption="Created Date"
      dataField="ctime"
      dataType="date"
      format="shortDateShortTime"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Action" dataField="action" dataType="string">
      <dxo-lookup [dataSource]="['CREATE', 'UPDATE', 'DELETE']"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Facility" dataField="tenantId" dataType="number">
      <dxo-lookup [dataSource]="facilityDso$ | async" valueExpr="id" displayExpr="shortname"> </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Client" dataField="entityId" dataType="number" [groupIndex]="0">
      <dxo-lookup
        [dataSource]="clientDso$ | async"
        valueExpr="id"
        [displayExpr]="consumerHelper.displayExpr"></dxo-lookup>
    </dxi-column>

    <dxi-column
      caption="Status Updated"
      dataField="_changed_status"
      dataType="boolean"
      [allowFiltering]="true"
      [allowSearch]="false"
      [allowSorting]="false"></dxi-column>

    <dxi-column caption="MCI Updated" dataField="_changed_mci" dataType="boolean"></dxi-column>

    <dxi-column caption="Status" dataField="_entity.status" dataType="string">
      <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption="MCI" dataField="_entity.mci" dataType="string"></dxi-column>

    <dxi-column caption="Last Name" dataField="_entity.person.lastname" dataType="string"></dxi-column>

    <dxi-column caption="First Name" dataField="_entity.person.firstname" dataType="string"></dxi-column>

    <dxi-column
      caption="Last Attendance Note"
      dataField="_entity.relatedNotes"
      dataType="object"
      [calculateDisplayValue]="lastAttendanceNoteExpr"></dxi-column>

    <dxi-column caption="User" dataField="userId" dataType="number">
      <dxo-lookup [dataSource]="usersDso$ | async" displayExpr="username" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <!-- Templates -->

    <div *dxTemplate="let cellInfo of 'detail'">
      <div class="dx-fieldset-header">
        {{ cellInfo.data._entity?.person?.firstname + ' ' + cellInfo.data._entity?.person?.lastname }}
      </div>

      <dx-tab-panel>
        <!--        <dxi-item title="Before Update" *ngIf="cellInfo.data.action === 'UPDATE'">-->
        <!--          <div style="margin: 1em">-->
        <!--            <pre>{{ cellInfo.data.entity.before | json }}</pre>-->
        <!--          </div>-->
        <!--        </dxi-item>-->

        <!--        <dxi-item title="After Update" *ngIf="cellInfo.data.action === 'UPDATE'">-->
        <!--          <div style="margin: 1em">-->
        <!--            <pre>{{ cellInfo.data.entity.after | json }}</pre>-->
        <!--          </div>-->
        <!--        </dxi-item>-->

        <dxi-item title="Fields Updated" *ngIf="cellInfo.data.action === 'UPDATE'">
          <div class="dx-fieldset" style="display: inline-block">
            <table style="border: none">
              <ng-container *ngFor="let item of getDiff(cellInfo.data)">
                <tr style="margin-bottom: 1em">
                  <td style="vertical-align: baseline">
                    <label>{{ item.title }}</label>
                  </td>
                  <td style="vertical-align: baseline; padding-bottom: 1em">
                    <div class="diff-value">
                      <div class="before">
                        <pre>{{ item.before }}</pre>
                      </div>
                    </div>
                    <div class="diff-value">
                      <div class="after">
                        <pre>{{ item.after }}</pre>
                      </div>
                    </div>
                  </td>
                </tr>

                <!--                <div class="dx-field">-->
                <!--                  <label class="dx-field-label">{{item.title}}</label>-->
                <!--                  <div class="dx-field-value">-->
                <!--                    <div class="diff-value">-->
                <!--                      <div class="before">-->
                <!--                        <pre>{{item.before}}</pre>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="diff-value">-->
                <!--                      <div class="after">-->
                <!--                        <pre>{{item.after}}</pre>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </ng-container>
            </table>

            <!--            <div style="margin: 1em">-->
            <!--              <pre>{{ cellInfo.data._diff | json }}</pre>-->
            <!--            </div>-->
          </div>
        </dxi-item>

        <dxi-item title="Difference" *ngIf="cellInfo.data.action === 'UPDATE'">
          <div style="margin: 1em">
            <pre>{{ cellInfo.data._diff | json }}</pre>
          </div>
        </dxi-item>

        <!--        -->

        <dxi-item title="Client Details" *ngIf="cellInfo.data.action !== 'UPDATE'">
          <div class="dx-fieldset" style="display: inline-block">
            <table style="border: none">
              <ng-container *ngFor="let item of getData(cellInfo.data)">
                <tr>
                  <td style="vertical-align: baseline">
                    <label>{{ item.title }}</label>
                  </td>
                  <td></td>
                  <td style="vertical-align: baseline; padding-bottom: 0.5em">
                    <pre>{{ item.value }}</pre>
                  </td>
                </tr>
              </ng-container>
            </table>

            <!--            <ng-container *ngFor="let item of getData(cellInfo.data)">-->
            <!--              <div class="dx-field">-->
            <!--                <label class="dx-field-label">{{item.title}}</label>-->
            <!--                <div class="dx-field-value">-->
            <!--                  <pre>{{item.value}}</pre>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </ng-container>-->
          </div>
          <!--          <div style="margin: 1em">-->
          <!--            <pre>{{ cellInfo.data.entity | json }}</pre>-->
          <!--          </div>-->
        </dxi-item>
      </dx-tab-panel>
    </div>
  </dx-data-grid>
</div>
