<dx-box direction="col" width="100%" height="100%">
  <dxi-item [ratio]="0" [shrink]="0" [baseSize]="'auto'">
    <dx-toolbar *ngIf="model; else noModel">
      <dxi-item
        location="before"
        locateInMenu="never"
        [text]="(model | fullName) + ' [' + model?.status + ']'"></dxi-item>
      <dxi-item location="after" locateInMenu="never" [text]="'MCI # ' + model?.mci"></dxi-item>
    </dx-toolbar>

    <ng-template #noModel>
      <dx-toolbar>
        <dxi-item location="center" locateInMenu="never" [text]="errorMessage || 'Not selected'"></dxi-item>
      </dx-toolbar>
    </ng-template>
  </dxi-item>

  <dxi-item [ratio]="1" [shrink]="1" [baseSize]="'auto'">
    <app-consumer-details-tabs [modelId]="modelId"></app-consumer-details-tabs>
  </dxi-item>
</dx-box>
