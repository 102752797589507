import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import DevExpress from 'devextreme';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import moment, { utc } from 'moment';
import { oc } from 'ts-optchain';
import { CustomField, ObjectCFV } from '../../../../sdk';
import { DataSourceService } from '../../../my-common/services/datasource.service';
import { getObjectCFVValueByCF } from './utils';
import get from 'lodash-es/get';
import AsyncRule = DevExpress.ui.AsyncRule;
import CustomRule = DevExpress.ui.CustomRule;
import { headersAllTenantsAppend } from '../../../../classes/utils/utils';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsComponent implements OnInit, OnChanges {
  @Input() formArray: FormArray;
  @Input() customFields: CustomField[] = [];
  @Input() customFieldValues: ObjectCFV[] = [];

  @Input() objectType: string;
  @Input() objectId: number;

  @ViewChildren(DxValidatorComponent) validators: QueryList<DxValidatorComponent>;

  weekdayNumberMap_callbacks = [];

  constructor(protected dss: DataSourceService, protected fb: FormBuilder) {
    this.buildForm();
    this.resetForm();
  }

  get controlsCount(): number {
    return this.formArray.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('formArray' in changes && !changes.formArray.previousValue && changes.formArray.currentValue) {
      this.buildForm();
    } else if ('customFields' in changes) {
      this.buildForm();
    }

    if ('customFieldValues' in changes) {
      this.resetForm();
    }
  }

  ngOnInit(): void {}

  resetForm() {
    if (!this.formArray) {
      return;
    }

    this.formArray.reset();

    (this.customFields || []).forEach((cf, idx) => {
      const cfv = this.cfv(idx);
      const cfvValue = cfv ? getObjectCFVValueByCF(cfv, cf) : null;
      const defaultValue = cf.meta.defaultValue;
      const cfGrp = this.formArray.at(idx);
      if (cfGrp) {
        cfGrp.reset({ [cf.field]: oc(cfvValue)(defaultValue) });
      }
    });
  }

  cf(idx: number): CustomField {
    return this.customFields[idx];
  }

  cfv(idx: number): ObjectCFV {
    return (this.customFieldValues || []).find(cfv => this.cf(idx).id === cfv.customFieldId);
  }

  validationRulesFromField(cf: CustomField) {
    // console.log(cf.label);

    const rules: Array<
      | DevExpress.ui.RequiredRule
      | DevExpress.ui.NumericRule
      | DevExpress.ui.RangeRule
      | DevExpress.ui.StringLengthRule
      | DevExpress.ui.CustomRule
      | DevExpress.ui.CompareRule
      | DevExpress.ui.PatternRule
      | DevExpress.ui.EmailRule
      | DevExpress.ui.AsyncRule
    > = [];

    if (cf.optional !== true) {
      rules.push({ type: 'required' });
    }

    if (oc(cf).meta.eqField('').length > 0) {
      rules.push({
        type: 'async',
        ignoreEmptyValue: true,
        validationCallback: async e => {
          // console.log(111, this.objectType && this.objectId);

          if (this.objectType && this.objectId) {
            const Model = await this.dss.models.get(this.objectType);

            const obj = await this.dss.models
              .getApi(this.objectType)
              .findById(
                this.objectId,
                {
                  ...(Model.getModelDefinition().relations.person ? { include: ['person'] } : {}),
                },
                headersAllTenantsAppend,
              )
              .toPromise();

            // console.log(obj, e.value);

            if (['DATE', 'EXPIRATION_DATE'].includes(cf.type)) {
              if (utc(get(obj, cf.meta.eqField)).format('L') !== moment(e.value).format('L')) {
                e.rule.message =
                  `${cf.label} value should be equal to related ` +
                  `${this.objectType}.${cf.meta.eqField}: ${utc(get(obj, cf.meta.eqField)).format('L')}`;
                return false;
              }
            } else if (get(obj, cf.meta.eqField) !== e.value) {
              e.rule.message =
                `${cf.label} value should be equal to related ` +
                `${this.objectType}.${cf.meta.eqField}: ${get(obj, cf.meta.eqField)}`;
              return false;
            }
          }

          return true;
        },
      } as AsyncRule);
    }

    // if (cf.type === 'EXPIRATION_DATE') {
    //   rules.push({
    //     message: cf.label + ' value expired',
    //     type: 'custom',
    //     ignoreEmptyValue: true,
    //     validationCallback: e => {
    //       return moment(e.value).diff(moment(), 'hours') > 0;
    //     },
    //   } as CustomRule);
    // }

    return rules;
  }

  weekdayNumberMap_adapterConfig(ctrl: AbstractControl) {
    return {
      ctrl,
      getValue() {
        const val = this.ctrl.value || {};

        const hasInvalidValue =
          [val.mo, val.tu, val.we, val.th, val.fr, val.sa, val.su].filter(v => isNaN(v)).length > 0;

        return hasInvalidValue ? null : true;
      },
      applyValidationResults: e => {
        // this.borderStyle = e.isValid ? "none" : "1px solid red";
      },
      validationRequestsCallbacks: this.weekdayNumberMap_callbacks,
    };
  }

  private buildForm() {
    if (!this.formArray) {
      return;
    }

    while (this.formArray.length) {
      this.formArray.removeAt(0);
    }

    (this.customFields || []).forEach((cf, idx) => {
      const cfv = this.cfv(idx);
      const cfvValue = cfv ? getObjectCFVValueByCF(cfv, cf) : null;
      const defaultValue = cf.meta.defaultValue;
      this.formArray.push(this.fb.group({ [cf.field]: [oc(cfvValue)(defaultValue)] }));
    });
  }
}
