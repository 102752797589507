import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyCommonModule } from '../../../my-common/my-common.module';
import { MyDevExtremeModule } from '../../../third-party/my-dev-extreme.module';
import { MyFontAwesomeModule } from '../../../third-party/my-font-awesome.module';
import { MyKendoModule } from '../../../third-party/my-kendo.module';
import { MyMaterialModule } from '../../../third-party/my-material.module';
import { MySyncfusionModule } from '../../../third-party/my-syncfusion.module';
import { UiModule } from '../../ui.module';
import { AnyNoteFormComponent } from './any-note-form/any-note-form.component';
import { AnyNotesPageDocumentsComponent } from './any-notes-page-documents/any-notes-page-documents.component';
import { AnyNotesPageComponent } from './any-notes-page.component';

@NgModule({
  imports: [
    MyFontAwesomeModule,
    MyMaterialModule,
    MyDevExtremeModule,
    MyKendoModule,
    MySyncfusionModule,
    MyCommonModule,

    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MyKendoModule,
    UiModule,
  ],
  declarations: [AnyNotesPageComponent, AnyNoteFormComponent, AnyNotesPageDocumentsComponent],
  exports: [
    MyFontAwesomeModule,
    MyMaterialModule,
    MyDevExtremeModule,
    MyKendoModule,
    MySyncfusionModule,
    MyCommonModule,

    AnyNotesPageComponent,
    AnyNoteFormComponent,
    AnyNotesPageDocumentsComponent,
    MyKendoModule,
  ],
  entryComponents: [AnyNotesPageComponent, AnyNoteFormComponent, AnyNotesPageDocumentsComponent],
})
export class AnyNotesPageModule {}
