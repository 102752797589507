
declare var Object: any;
export interface AutoRepairShopInterface {
  "name"?: string;
  "address"?: string;
  "phone"?: string;
  "note"?: string;
  "mainContact"?: string;
  "id"?: any;
}

export class AutoRepairShop implements AutoRepairShopInterface {
  "name": string;
  "address": string;
  "phone": string;
  "note": string;
  "mainContact": string;
  "id": any;
  constructor(data?: AutoRepairShopInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AutoRepairShop`.
   */
  public static getModelName() {
    return "AutoRepairShop";
  }

  /**
  * This method creates an instance of AutoRepairShop for dynamic purposes.
  **/
  public static factory(data: AutoRepairShopInterface): AutoRepairShop{
    return new AutoRepairShop(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AutoRepairShop',
      plural: 'AutoRepairShops',
      path: 'AutoRepairShops',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "mainContact": {
          name: 'mainContact',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
