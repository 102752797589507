<dx-data-grid
  [dataSource]="dso"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <!-- Columns -->

  <dxi-column caption="Name" dataField="name" dataType="string" [allowHeaderFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Object FQN" dataField="objectFQN" dataType="string">
    <dxo-lookup [dataSource]="docTypesObjectFQNs" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Visibility" dataField="visibility" dataType="string">
    <dxo-lookup [dataSource]="['public', 'internal']"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string">
    <dxo-lookup [dataSource]="['operational', 'financial', 'other']"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column caption="Inactive" dataField="inactive" dataType="boolean"> </dxi-column>
  <dxi-column caption="Exp Alert" dataField="expAlert" dataType="boolean"> </dxi-column>
  <dxi-column caption="Missing Doc Alert" dataField="missingDocAlert" dataType="boolean"> </dxi-column>
</dx-data-grid>
