import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
//
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService, MyUserApi, MyUtils, MyUtilsApi } from '../../../../shared/sdk';
import { getUser } from '../../../../store/reducers/sign';
import { HelperService } from '../../services/helper.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-mtm-broker-trips',
  templateUrl: './mtm-broker-trips.component.html',
  styleUrls: ['./mtm-broker-trips.component.scss'],
})
export class MtmBrokerTripsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  facilityDso$: Observable<DataSourceOptions> = of([]);

  constructor(
    private http: HttpClient,
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private dialog: MatDialog,
    private api: MyUtilsApi,
    private userApi: MyUserApi,
  ) {
    super(logger);

    // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '30c612ff-5616-470b-ae3b-fd94f5d9c1b8',
    };

    this.isSU$ = this.config.isSU$;
  }

  // calcCellDateValue(rowData: any): Date {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().toDate() : rowData[column.dataField];
  // }
  //
  // calcDisplayDateValue(rowData: any): string {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().format('YYYY-MM-DD') : rowData[column.dataField];
  // }

  ngOnInit() {
    super.ngOnInit();

    this.dso = this.buildDataSource();
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  ngAfterViewInit(): void {}

  private buildDataSource() {
    const self = this;
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        const col = 'ExportsDataCache';
        const aggregate = [
          // {
          //   $addFields: {_ctime: {$toDate: '$_id'}},
          // }
        ];
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate)
          .pipe(
            switchMap(async (docs: any[]) => {
              {
                const tripsFacilities: any[] = await self.dss
                  .getApi<MyUtilsApi>(MyUtils)
                  .getTripFacility({ broker: 'MTM', tripIds: docs.map(doc => doc._tripId) })
                  .pipe(catchError(err => of(undefined)))
                  .toPromise();

                (tripsFacilities || []).forEach(([tripId, facility]) => {
                  const doc = docs.find(_doc => _doc._tripId === tripId);
                  if (doc) {
                    doc._facility = facility;
                  }
                });
              }

              {
                const tripsIsAssigned: any[] = await self.dss
                  .getApi<MyUtilsApi>(MyUtils)
                  .isTripAssigned({ broker: 'MTM', tripIds: docs.map(doc => doc._tripId) })
                  .pipe(catchError(err => of(undefined)))
                  .toPromise();

                (tripsIsAssigned || []).forEach(([tripId, assigned]) => {
                  const doc = docs.find(_doc => _doc._tripId === tripId);
                  if (doc) {
                    doc._assigned = assigned;
                  }
                });
              }

              return docs;
            }),
          )
          .toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        const col = 'ExportsDataCache';
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        const col = 'ExportsDataCache';
        return gqlMongoCount(self.dss, col, loadOptions).toPromise();
      },
    });
    const dso: DataSourceOptions = {
      store,
      filter: ['_broker', '=', 'MTM'],
      sort: [{ selector: '_date', desc: true }],
      // postProcess: (data: Array<any>): Array<any> => {
      //   return data;
      // },
    } as DataSourceOptions;
    return dso;
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }
}
