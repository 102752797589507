<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">
        {{ data?.title }}
      </div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="content">
  <dx-data-grid
    [dataSource]="data.manifest.trips"
    [remoteOperations]="false"
    [errorRowEnabled]="false">

    <dxo-search-panel [visible]="false"></dxo-search-panel>    
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxi-column caption="Service Type" dataField="st"></dxi-column>
    <dxi-column caption="Origin" dataField="o"></dxi-column>
    <dxi-column caption="Destination" dataField="d"></dxi-column>
    <dxi-column caption="Broker" dataField="b"></dxi-column>
    <dxi-column caption="Trip ID" dataField="tId"></dxi-column>
    <dxi-column caption="Vehicle" dataField="v"></dxi-column>
    <dxi-column caption="Escort" dataField="esc"></dxi-column>
    <dxi-column caption="Trip" dataField="tr"></dxi-column>
    <dxi-column caption="Time" dataField="t" dataType="date" format="shortTime"></dxi-column>
    <dxi-column caption="Appointment Time" dataField="at" dataType="date" format="shortTime"></dxi-column>
    <dxi-column caption="#" dataField="s" dataType="number"></dxi-column>
    <dxi-column caption="Consumer" [calculateDisplayValue]="getConsumerFullName"></dxi-column>
    <dxi-column caption="Address" [calculateDisplayValue]="getFullAddress"></dxi-column>
    <dxi-column caption="Phone"  [calculateDisplayValue]="getMainPhone"></dxi-column>

  </dx-data-grid>
</div>
