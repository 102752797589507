import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { CloneLogListComponent } from '../../components/clone-log-list/clone-log-list.component';

@Component({
  selector: 'app-clone-log',
  templateUrl: './clone-log.component.html',
  styleUrls: ['./clone-log.component.scss'],
})
export class CloneLogComponent extends ABaseComponent implements OnInit {
  $selectedArray$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selected$: Observable<any> = new Observable();

  $resize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild(CloneLogListComponent, { static: true }) grid: CloneLogListComponent;

  constructor(protected logger: LoggerService, private dss: DataSourceService) {
    super(logger);

    this.selected$ = this.$selectedArray$.pipe(map(ids => (ids && ids.length ? ids[0] : null)));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list_onSelected(logs: any[]) {
    if (logs && logs.length) {
      this.$selectedArray$.next(logs);
    } else {
      this.$selectedArray$.next([]);
    }
  }

  splitter_sizeChange(e) {
    this.$resize$.next(true);
  }
}

