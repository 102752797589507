<div mat-dialog-content>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Arrived Time</div>
      <div class="dx-field-value">
        <dx-date-box
          [readOnly]="data.useFieldLock && o?.meta?.partValidated"
          [(ngModel)]="value.arrivedTime"
          type="time"
          pickerType="rollers"
          [showClearButton]="true"></dx-date-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Pickup Time</div>
      <div class="dx-field-value">
        <dx-date-box
          [readOnly]="data.useFieldLock && o?.meta?.partValidated"
          [(ngModel)]="value.pickupTime"
          type="time"
          pickerType="rollers"
          [showClearButton]="true"></dx-date-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">Dropoff Time</div>
      <div class="dx-field-value">
        <dx-date-box
          [readOnly]="data.useFieldLock && o?.meta?.fullValidated"
          [(ngModel)]="value.dropoffTime"
          type="time"
          pickerType="rollers"
          [showClearButton]="true"></dx-date-box>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value">Set</button>
</div>
