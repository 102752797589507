export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function safeJsonParse<T = any>(value: string, fallbackValue?: T) {
  try {
    return JSON.parse(value) as T;
  } catch {
    return fallbackValue as T;
  }
}
