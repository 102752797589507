import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { oc } from 'ts-optchain';
import { ComponentCanDeactivate } from '../../../../shared/modules/my-common/guards/pending-changes.guard';
import { ImportFormComponent } from '../../components/import-form/import-form.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  @ViewChild(ImportFormComponent, { static: false }) importForm: ImportFormComponent;
  deactivateWarning: string;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.deactivateWarning = oc(this).importForm.deactivateWarning();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return !this.importForm || !this.importForm.canDeactivate || this.importForm.canDeactivate();
  }
}
