import {
  MyUser,
  DocumentType,
  MyFile,
  ObjectCFV,
  CustomField,
  LastFileView
} from '../index';

declare var Object: any;
export interface DocumentInterface {
  "name": string;
  "notes"?: string;
  "meta"?: any;
  "objectType"?: string;
  "objectId": number;
  "dtime"?: Date;
  "ownerId"?: number;
  "id"?: number;
  "documentTypeId"?: number;
  owner?: MyUser;
  forObject?: any;
  documentType?: DocumentType;
  files?: MyFile[];
  customFieldValues?: ObjectCFV[];
  customFields?: CustomField[];
  lastFiles?: LastFileView[];
}

export class Document implements DocumentInterface {
  "name": string;
  "notes": string;
  "meta": any;
  "objectType": string;
  "objectId": number;
  "dtime": Date;
  "ownerId": number;
  "id": number;
  "documentTypeId": number;
  owner: MyUser;
  forObject: any;
  documentType: DocumentType;
  files: MyFile[];
  customFieldValues: ObjectCFV[];
  customFields: CustomField[];
  lastFiles: LastFileView[];
  constructor(data?: DocumentInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Document`.
   */
  public static getModelName() {
    return "Document";
  }

  /**
  * This method creates an instance of Document for dynamic purposes.
  **/
  public static factory(data: DocumentInterface): Document{
    return new Document(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Document',
      plural: 'Documents',
      path: 'Documents',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any',
          default: <any>null
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "documentTypeId": {
          name: 'documentTypeId',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'ownerId',
          keyTo: 'id'
        },
        forObject: {
          name: 'forObject',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'objectId',
          keyTo: 'id'
        },
        documentType: {
          name: 'documentType',
          type: 'DocumentType',
          model: 'DocumentType',
          relationType: 'belongsTo',
                  keyFrom: 'documentTypeId',
          keyTo: 'id'
        },
        files: {
          name: 'files',
          type: 'MyFile[]',
          model: 'MyFile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'documentId'
        },
        customFieldValues: {
          name: 'customFieldValues',
          type: 'ObjectCFV[]',
          model: 'ObjectCFV',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
        customFields: {
          name: 'customFields',
          type: 'CustomField[]',
          model: 'CustomField',
          relationType: 'hasMany',
          modelThrough: 'ObjectCFV',
          keyThrough: 'customFieldId',
          keyFrom: 'id',
          keyTo: 'objectId'
        },
        lastFiles: {
          name: 'lastFiles',
          type: 'LastFileView[]',
          model: 'LastFileView',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'documentId'
        },
      }
    }
  }
}
