import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  Consumer,
  ConsumerApi,
  EmployeeView,
  EmployeeViewApi,
  LoggerService,
  MyUser,
  MyUserApi,
} from '../../../../shared/sdk';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { Observable, of } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import moment from 'moment';
import { DlgManifestGridComponent } from 'src/app/modules/clone-log/components/dlg-manifest-grid/dlg-manifest-grid.component';

@Component({
  selector: 'app-dlg-restore-manifest',
  templateUrl: './dlg-restore-manifest.component.html',
  styleUrls: ['./dlg-restore-manifest.component.scss'],
  providers: [HelperService],
})
export class DlgRestoreManifestComponent implements OnInit {
  dso$: Observable<any[]> = of([]);
  @Input() gridColumns: any[] = [
    { dataField: 'ctime', caption: 'Date', dataType: 'datetime', allowFiltering: false },
    { dataField: 'device', caption: 'Device' },
    { dataField: 'user', caption: 'user' },
    { dataField: 'caller', caption: 'Tool' },
    { dataField: 'data.length', caption: 'Manifest', cellTemplate: 'manifest_cell', textAlign: 'center' },
  ];

  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgRestoreManifestComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      manifestId: number;
      date: string;
    },
    protected logger: LoggerService,
    private dss: DataSourceService,
    public config: ConfigService,
    private ui: UiService,
    public helper: HelperService,
    protected dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.buildDso();
  }

  dateDisplay() {
    return moment(this.data.date).format('dddd, MMM D, YYYY');
  }

  async restoreManifest([record]) {
    if (!record) {
      this.dialogRef.close(false);
      return;
    }
    console.log('restoreManifest', record);
    this.ui.showLoading();
    await this.helper.api.updateManifest(this.data.manifestId, record.data || []).toPromise();
    this.ui.hideLoading();
    this.dialogRef.close(true);
  }

  async showManifestDialog(row: any) {
    this.ui.showLoading();
    const [consumers, drivers] = await Promise.all([
      this.dss
        .getApi<ConsumerApi>(Consumer)
        .find<Consumer>(
          {
            where: { id: { inq: [...new Set(row.data.map(({ c }) => c))] } },
            include: [{ person: { contact: ['addresses', 'phones'] } }],
          },
          headersAllTenantsAppend,
        )
        .toPromise(),
      this.dss
        .getApi<EmployeeViewApi>(EmployeeView)
        .find<EmployeeView>(
          { where: { employeeId: { inq: [...new Set(row.data.map(({ e }) => e))] } } },
          headersAllTenantsAppend,
        )
        .toPromise(),
    ]);
    this.ui.hideLoading();

    const driversMap = drivers.reduce((p, d) => ({ ...p, [d.id]: d }), {});
    const consumersMap = consumers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const d = moment(row.ctime).format('M/D/YYYY h:mm A');
    const title = `Manifest for ${d}`;

    void this.dialog.open<any, any, number>(DlgManifestGridComponent, {
      hasBackdrop: true,
      data: { title, manifest: row, consumersMap, driversMap },
      minWidth: 1200,
    });
  }

  async buildDso() {
    this.ui.showLoading();
    const stages = [{ $match: { entityId: this.data.manifestId } }, { $sort: { ctime: -1 } }];
    const logRows = await gqlMongoLoad(this.dss, 'EntityActionLog_TripManifest', {}, stages).pipe().toPromise();
    const rows = logRows.map(v => ({ ...v, entity: undefined, ...v.entity.before }));
    const users: MyUser[] = await this.dss
      .getApi<MyUserApi>(MyUser)
      .find<MyUser>({ where: { id: { inq: [...new Set(rows.map(r => r.userId))] } } })
      .toPromise();
    const usersMap = users.reduce((p, u) => ({ ...p, [u.id]: u.username }), {});
    rows.forEach((r, i) => {
      r.device = (r.headers && ((r.headers['x-application-name'] && 'Tablet') || 'Web')) || 'System';
      r.user = usersMap[r.userId];
      r.caller = r.headers && r.headers['x-caller'];
    });
    this.dso$ = of(rows);
    this.ui.hideLoading();
  }

  grid_onSelectionChanged(e) {}
}

