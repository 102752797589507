import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoggerService, MyUserApi } from '../../../../shared/sdk';
import { Facility } from '../../../../shared/sdk/models';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { FACILITY_TYPES } from '../../classes/enums';
import { map } from 'rxjs/operators';
export class FacilityFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, helper, config, userApi) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.helper = helper;
        this.config = config;
        this.userApi = userApi;
        this.types = FACILITY_TYPES;
        this.labels$ = of([]);
        this.setState(FORM_STATE.COLLAPSED);
        this.isSU$ = this.userApi.getCurrent().pipe(switchMap(u => this.userApi.isSU(u.id)));
        this.labels$ = this.config.tenantType$.pipe(map(type => {
            return [
                ...(type === 'BASE' ? ['COO', 'Safety Director', 'Fleet Manager'] : []),
                'Director Contact',
                'Main SA Contact',
                'MERCY Account Executive Contact',
                'MERCY Transportation Supervisor Contact',
            ];
        }));
    }
    get addressesFormArray() {
        return this.form.get('contact.addresses');
    }
    get phonesFormArray() {
        return this.form.get('contact.phones');
    }
    get emailsFormArray() {
        return this.form.get('contact.emails');
    }
    get emRelationsFormArray() {
        return this.form.get('emRelations');
    }
    get filter() {
        return {
            include: [
                { contact: ['addresses', 'phones', 'emails'] },
                { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
            ],
        };
    }
    get ModelClass() {
        return Facility;
    }
    get dateFields() {
        return [];
    }
    beforeSubmittingAsync(data) {
        const _super = Object.create(null, {
            beforeSubmittingAsync: { get: () => super.beforeSubmittingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeSubmittingAsync.call(this, data);
            // force request location
            if (data.contact && data.contact.addresses) {
                data.contact.addresses.forEach(a => {
                    a.location = null;
                    a.meta = { hash: null };
                });
            }
            if (!this.model) {
                delete data.contact;
            }
        });
    }
    buildForm() {
        this.formConfigMap.set('contact.addresses', {
            id: [],
            street: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: [],
            label: [],
            location: [],
            meta: [],
            contactId: [],
        });
        this.formConfigMap.set('contact.phones', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('contact.emails', {
            id: [],
            label: [],
            value: ['', Validators.required],
            contactId: [],
        });
        this.formConfigMap.set('contact', {
            id: [],
            notes: [],
            addresses: this.fb.array([]),
            phones: this.fb.array([]),
            emails: this.fb.array([]),
        });
        this.formConfigMap.set('emRelations', {
            id: [],
            relation: [],
            facilityId: [],
            personId: ['', Validators.required],
        });
        this.formConfigMap.set('data', {
            mentorEmail: [],
        });
        this.formConfigMap.set('', {
            id: [],
            legalName: ['', Validators.required],
            name: ['', Validators.required],
            shortname: ['', Validators.required],
            type: [],
            notes: [],
            contactId: [],
            geotabZoneId: [],
            data: this.fb.group(this.formConfigMap.get('data')),
            contact: this.fb.group(this.formConfigMap.get('contact')),
            emRelations: this.fb.array([]),
        });
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
}
