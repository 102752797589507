<dx-data-grid
  #grid
  height="100%"
  [dataSource]="dso"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onContentReady)="grid_onContentReady($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="vehicles-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Internal ID" dataField="internalId" dataType="number"></dxi-column>

  <dxi-column caption="State" dataField="state" dataType="string">
    <dxo-lookup [dataSource]="STATES"></dxo-lookup>
  </dxi-column>

  <dxi-column
    caption="Status"
    [calculateDisplayValue]="grid_getStatusCellValue"
    [calculateFilterExpression]="grid_calculateFilterStatusExpression"
    dataField="status"
    dataType="string"
  ></dxi-column>

  <dxi-column caption="Location" dataField="location" dataType="string"></dxi-column>

  <dxi-column caption="Assigned Facility" dataField="assignedFacility" dataType="string"></dxi-column>

  <dxi-column caption="Make" dataField="make" dataType="string"></dxi-column>

  <dxi-column caption="Model" dataField="model" dataType="string"></dxi-column>

  <dxi-column caption="Year" dataField="year" dataType="number"></dxi-column>

  <dxi-column caption="VIN" dataField="vin" dataType="string"></dxi-column>

  <dxi-column caption="License State" dataField="licenseState" dataType="string"></dxi-column>

  <dxi-column caption="License Plate Number" dataField="licensePlateNumber" dataType="string"></dxi-column>

  <dxi-column caption="Type" dataField="type" dataType="string"></dxi-column>

  <dxi-column caption="Wheel Chair Accessible" dataField="wheelchairAccessible" dataType="boolean"></dxi-column>

  <dxi-column caption="Stretcher Accessible" dataField="stretcherAccessible" dataType="boolean"></dxi-column>

  <!---->

  <dxi-column caption="Gross Weight" dataField="grossWeight" dataType="number" [visible]="false"></dxi-column>

  <dxi-column
    caption="Passenger Capacity"
    dataField="passengerCapacity"
    dataType="number"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Wheelchair Capacity"
    dataField="wheelchairCapacity"
    dataType="number"
    [visible]="false"></dxi-column>

  <dxi-column caption="Possible Configs" dataField="possibleConfigs" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Has Lift/Ramp" dataField="hasLiftRamp" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="CDL" dataField="cdl" dataType="boolean" [visible]="false"></dxi-column>

  <dxi-column caption="Ownership" dataField="ownership" dataType="string" [visible]="false"></dxi-column>

  <dxi-column
    caption="Registration Exp."
    dataField="registrationExp"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Inspection Exp."
    dataField="inspectionExp"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Last Mechanical Check"
    dataField="lastMechanicalCheck"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column caption="Body Company" dataField="bodyCompany" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Tire Size" dataField="tireSize" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Fuel" dataField="fuel" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Engine Type" dataField="engineType" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="Seller" dataField="seller" dataType="string" [visible]="false"></dxi-column>

  <dxi-column
    caption="Acquisition Date"
    dataField="purchaseDate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column
    caption="Title Date"
    dataField="titleDate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [visible]="false"></dxi-column>

  <dxi-column caption="Note" dataField="note" dataType="string" [visible]="false"></dxi-column>

  <dxi-column caption="GPS">
    <dxi-column caption="GPS Unit Installed" dataField="gpsUnitInstalled" dataType="boolean"></dxi-column>

    <dxi-column caption="GPS Serial Number" dataField="gpsSerialNumber" dataType="string"></dxi-column>

    <dxi-column caption="GPS Unit Model" dataField="gpsUnitModel" dataType="string"></dxi-column>

    <dxi-column
      caption="GPS Installed Date"
      dataField="gpsInstalledDate"
      dataType="date"
      format="shortDate"></dxi-column>
  </dxi-column>

  <dxi-column caption="Camera">
    <dxi-column caption="Camera Installed" dataField="cameraInstalled" dataType="boolean"></dxi-column>

    <dxi-column caption="Camera Model" dataField="cameraModel" dataType="string"></dxi-column>

    <dxi-column caption="Camera Serial Number" dataField="cameraSerialNumber" dataType="string"></dxi-column>

    <dxi-column
      caption="Camera Installed Date"
      dataField="cameraInstalledDate"
      dataType="date"
      format="shortDate"></dxi-column>
  </dxi-column>

  <dxi-column caption="Equipment">
    <dxi-column caption="Seat-belt Cutter" dataField="seatBeltCutter" dataType="boolean"></dxi-column>

    <dxi-column caption="First Aid Kit" dataField="firstAidKit" dataType="boolean"></dxi-column>

    <dxi-column caption="Bloodborne Pathogen Kit" dataField="bloodBornePathogenKit" dataType="boolean"></dxi-column>

    <dxi-column caption="Emergency Triangles" dataField="emTriangles" dataType="boolean"></dxi-column>

    <dxi-column caption="Umbrella" dataField="umbrella" dataType="boolean"></dxi-column>

    <dxi-column caption="Cleaning Supplies" dataField="cleaningSupplies" dataType="boolean"></dxi-column>

    <dxi-column caption="Broom" dataField="broom" dataType="boolean"></dxi-column>

    <dxi-column caption="PPE - Gloves & Mask" dataField="ppe" dataType="boolean"></dxi-column>

    <dxi-column
      caption="Fire Extinguisher Installed"
      dataField="fireExtinguisherInstalled"
      dataType="boolean"></dxi-column>

    <dxi-column
      caption="Fire Extinguisher Exp. Date"
      dataField="fireExtinguisherExp"
      dataType="date"
      format="shortDate"
      [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

    <dxi-column caption="Step Stool" dataField="stepStool" dataType="boolean"></dxi-column>

    <dxi-column caption="SeatBelt Extender" dataField="seatBeltExt" dataType="boolean"></dxi-column>

    <dxi-column caption="Kubota Key" dataField="kubotaKey" dataType="boolean"></dxi-column>

    <dxi-column caption="Foldable Wheelchair" dataField="foldableWheelchair" dataType="boolean"></dxi-column>

    <dxi-column
      caption="Hand Sanitizer Dispenser Installed"
      dataField="sanitizerDispenser"
      dataType="boolean"></dxi-column>

    <dxi-column
      caption="Back-Up Camera Installed"
      dataField="buCameraInstalled"
      dataType="boolean"></dxi-column>
  </dxi-column>

  <dxi-column
    caption="Last CI Date"
    dataField="meta.lastCIDate"
    dataType="date"
    format="shortDate"
    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"></dxi-column>

  <dxi-column
    caption="Last CI Odometer"
    dataField="meta.lastCIOdometer"
    dataType="number"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <!-- Templates -->
</dx-data-grid>
