import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
//
import { LoopBackFilter, MyUser } from '../../../../shared/sdk/models';
import { LoggerService } from '../../../../shared/sdk/services/custom';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { FORM_STATE } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { FormHelperService } from '../../../../shared/modules/ui/services/form-helper.service';
import { USER_STATUSES } from '../../classes/enums';
export class UsersFormComponent extends ABaseFormComponent {
    constructor(logger, fb, dss, config, helper) {
        super(logger, fb, dss, helper);
        this.logger = logger;
        this.fb = fb;
        this.dss = dss;
        this.config = config;
        this.helper = helper;
        this.statuses = USER_STATUSES;
        this.setState(FORM_STATE.COLLAPSED);
    }
    get filter() {
        return {};
    }
    get ModelClass() {
        return MyUser;
    }
    get dateFields() {
        return [];
    }
    buildForm() {
        this.formConfigMap.set('', Object.assign({ id: [] }, (this.modelId
            ? {}
            : {
                // username: ['', [Validators.required]],
                password: ['', [Validators.required]],
            }), { username: ['', [Validators.required]], email: ['', Validators.required], status: ['', [Validators.required]], emailVerified: [''] }));
        this.form = this.fb.group(this.formConfigMap.get(''));
    }
    beforeModelLoadingAsync(id) {
        const _super = Object.create(null, {
            beforeModelLoadingAsync: { get: () => super.beforeModelLoadingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield _super.beforeModelLoadingAsync.call(this, id);
            this.buildForm();
        });
    }
}
